import { Directive, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
@Directive({
  selector: 'LowestScore',
})
export class LowestScoreDirective {

  calValueToLowestScore(value) {
    if (value == 2) {
      return 'Medium';
    } else if (value == 3) 
    {
      return 'High';
    } else {
      return 'Low';
    }
  }

  calLowestScoreToValue(value) {
    if (value == 'Medium') {
      return 2;
    } else if (value == 'High') {
      return 3;
    } else {
      return 1;
    }
  }

  calLowestScore(valueA, valueB, valueC = 99) {
    let min = Math.min(
      valueA,
      valueB,
      valueC
    );

    return min
  }

}
