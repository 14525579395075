import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { AuthenService } from 'src/app/services/authenservice.service';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import {
  AssumptionRedAlertModel,
  StrategyModel,
} from '../../model/MonitorReviewModel';
import { MonitorAndReviewService } from '../../services/monitorandreview.service';
declare let $;

@Component({
  selector: 'app-monitor-review',
  templateUrl: './monitor-review.component.html',
  styleUrls: ['./monitor-review.component.css'],
  providers: [NgbModalConfig, NgbModal],
})
export class MonitorReviewComponent implements OnInit, AfterViewChecked {
  constructor(
    public authserviceToken: AuthenService,
    config: NgbModalConfig,
    private modalService: NgbModal,
    public monitorAndReviewService: MonitorAndReviewService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {}

  titleBU = 'Monitor & Review';

  loading = false;
  page = 1;
  pageSize = 5;
  strategyNumber = '';
  strategyItem = {};
  strategyIndex = 0;
  strategyPage = 0;
  strategyLength = 0;
  selectedCurrentStatus = '';

  popupMessageAlert = '';
  popupTitleAlert = '';
  deleteIdStrategy = 0;

  DataMonitorReview: AssumptionRedAlertModel[] = [];
  DataStrategy: StrategyModel[] = [];

  DataViewsMonitorReview = [];

  isOpenPageMonitor = false;
  pushDataToPageMonitor: StrategyModel[] = [];

  Data_SessionStorage;
  empid = '';
  empRole = '';

  getDataBU = '';
  isRefresh = false;

  calculatedHeight = 500;

  ngOnInit(): void {
    this.Data_SessionStorage = JSON.parse(sessionStorage.datainfo);
    this.empid = this.Data_SessionStorage[0].employeE_ID;
  }

  returnEvent(event) {
    this.getDataBU = event;
    this.func_GetData();
    this.empRole = sessionStorage.getItem('buRole');
  }

  func_GetData() {
    let data = {
      'Bu': this.getDataBU
    };
    this.monitorAndReviewService
      .func_getDataListMonitorReview(data)
      .then((response) => {
        if (response.data.status) {
          this.DataMonitorReview = response.data.body.assumptionRedAlertList;
          this.DataStrategy = response.data.body.strategyList;
          this.DataMonitorReview = this.DataMonitorReview.map((item) => {
            return { ...item, EditStatus: false };
          });
          this.loading = false;
        } else {
          alert(response.data.errorMessage);
          this.loading = false;
        }
      })
      .catch((e) => {
        this.authserviceToken.CheckTokenExpire(e.response.status);
      });
  }

  func_ConfirmDeleteStrategy(_id) {
    this.deleteIdStrategy = _id;
    this.popupTitleAlert = 'Alert !'
    this.popupMessageAlert = 'Do you want to delete this Item ?';
    $('#modalConfirm').modal('show');
  }

  func_DeleteStrategy() {
    this.loading = true;
    this.popupMessageAlert = '';
    let modifiedStrategy = {
      'RiskModifiedBy': this.empid
    };
    this.monitorAndReviewService
      .func_delStrategy(this.deleteIdStrategy, modifiedStrategy)
      .then((response) => {
        if (response.data.status) {
          this.isRefresh = true;
          this.popupTitleAlert = 'Alert !'
          this.popupMessageAlert = 'Delete Success';
          $('#modalSuccess').modal('show')
          this.loading = false;
        } else {
          this.popupMessageAlert = 'error';
          this.isRefresh = true;
          $('#modalAlert').modal('show')
          this.loading = false;
        }
      })
      .catch((e) => {
        this.authserviceToken.CheckTokenExpire(e.response.status);
      });
  }

  func_MonitorStrategy(item) {
    this.router.navigate(['/Monitor-Review/Monitor/' + item.riskId]);
  }

  func_ReviewStrategy(item) {
    this.router.navigate(['/Monitor-Review/Review/' + item.riskId]);
  }

  func_DraftStrategy(item) {
    this.router.navigate(['/Register-Assessment/' + item.riskId]);
  }

  func_editstatus(item) {
    this.strategyIndex = this.DataMonitorReview.findIndex(
      (f) =>
        f.riskNo == item.riskNo && f.assumptionTitle == item.assumptionTitle
    );
    this.DataMonitorReview[this.strategyIndex].EditStatus = true;
  }

  ngAfterViewChecked() {
    this.setTableHeight();
  }

  setTableHeight() {
    const tableElement = document.querySelector('#redalert');
    let newCalculatedHeight = 39;
    if (tableElement) {
      const head = tableElement.querySelector('thead tr');
      const rows = tableElement.querySelectorAll('tbody tr');
      if (rows.length > 0) {
        if (head) {
          newCalculatedHeight = (head as HTMLElement).offsetHeight;
        }
        for (let i = 0; i < 5; i++) {
          newCalculatedHeight += rows[i] ? (rows[i] as HTMLElement).offsetHeight : 31;
        }
      }
      if(this.calculatedHeight != newCalculatedHeight){
        this.calculatedHeight = newCalculatedHeight;
        this.cdr.detectChanges();
      }
    }
  }
}
