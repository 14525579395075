<div class="container-fluid appform my-3">
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">

            <div class="col-12 col-md-12 col-lg-12 col-xl-12 pb-5 mt-5 mb-5">
                <div class="card col-12 mt-4 mx-0 px-3">

                    <div class="banner col-12 rounded">
                        <div class="col-12 pt-2">
                            <em class="fas fa-bars icon"></em>&nbsp;&nbsp;
                            Banner
                        </div>
                    </div>
                    <button type="button" class="btn btn-lg btn-banner-primary mb-3 mt-4"
                        style="width: 200px;color:white;background:orange;" (click)="func_addbanner('Add',[])">
                        <em class="fas fa-plus icon"></em>&nbsp;&nbsp;&nbsp;Add Banner
                    </button>

                    <div class="table-banner">
                        <table class="table table-bordered">
                            <caption style="display: none;" >This is a custom table</caption>
                            <thead style="text-align: center;">
                                <tr style="background-color:#033153;color:white;width: 1200px;">
                                    <th style="width: 50px;vertical-align: middle;">No.</th>
                                    <th style="width: 100px;vertical-align: middle;">Business Name</th>
                                    <th style="width: 350px;vertical-align: middle;">Image</th>
                                    <th style="width: 50px;vertical-align: middle;">Active</th>
                                    <th style="width: 50px;vertical-align: middle;">Edit</th>

                                </tr>
                            </thead>
                            <tbody style="background-color: white;width: 1200px;">
                                <tr *ngFor='let data of tablebanner index as i'>
                                    <td style="text-align: center;">{{i + 1}}</td>
                                    <td>{{data.businessName}}</td>
                                    <td style="text-align: center;">
                                        <span *ngFor="let item of data.itemAttSeq1 index as index">
                                            <img  alt="" src="{{ path }}/{{item.pathFile}}" class="rounded bordercss img-fix-showdisplay" *ngIf="index === 0">
                                        </span>
                                    </td>
                                    <td style="text-align: center;" *ngIf="data.delFlag == '0'">
                                        Active
                                    </td>
                                    <td style="text-align: center;" *ngIf="data.delFlag != '0'">Inactive</td>
                                    <td style="text-align: center;">
                                        <button type="button" class="btn" (click)="func_addbanner('Edit',data)">
                                            <em class="fas fa-edit" style="color: orange;font-size:16px;"></em>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="modal fade bd-Mianmenu" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" id="modaladdbanner">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="container-fluid appform my-3">
                            <div class="row">
                                <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1"
                                    style="font-size: 14px;">
                                    <div
                                      class="container col-12 col-md-12 col-lg-12 col-xl-12 pb-0 mt-0 mb-0 ">
                                      <div class="container">
                                            <div class="col-12 rounded modalbanner">
                                                <div class="col-12 pt-2">
                                                    <em class="fas fa-bars icon"></em>&nbsp;&nbsp;
                                                    <span *ngIf="Module === 'insert'">Create Banner</span> 
                                                    <span *ngIf="Module === 'update'">Edit Banner</span> 
                                                </div>
                                            </div>
                                            <div class="card mt-4">
                                                <div class="row col-12 mx-0 mb-4 px-0 d-flex justify-content-around">

                                                    <div class="row col-12 col-xl-4">
                                                        <div class="col-12 mb-2 px-3">
                                                            <strong class="formtext">Business ID</strong>
                                                        </div>
                                                        <div class="col-12 mb-2">
                                                            <input type="text" class="form-control m-0" [(ngModel)]="BusinessCode" disabled>
                                                        </div>
                                                    </div>

                                                    <div class="row col-12 col-xl-4">
                                                        <div class="col-12 mb-2 px-3">
                                                            <strong class="formtext">Business Name</strong>
                                                        </div>
                                                        <div class="col-12 mb-2">
                                                            <input type="text" class="form-control m-0" aria-label="Number" matInput [formControl]="myControlBUDD" [matAutocomplete]="auto2" [(ngModel)]="BusinessUnit" (keyup.enter)="func_selectCoorBU(BusinessUnit)">
                                                            <mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete">
                                                                <mat-option *ngFor="let option of filteredOptionsBUDD | async" [value]="option.text" (click)="func_selectCoorBU(option.text)">
                                                                {{option.text}}
                                                                </mat-option>
                                                            </mat-autocomplete>
                                                        </div>
                                                    </div>

                                                    <div class="row col-12 col-xl-4">
                                                        <div class="col-2 col-xl-12 px-3">
                                                            <label for="chkstatus"><strong class="formtext">Active</strong></label>
                                                        </div>
                                                        <div class="col-10 col-xl-12">
                                                            <input type="checkbox" id="chkstatus" [(ngModel)]="Status">
                                                        </div>
                                                    </div>

                                                </div>

                                                <div class="row col-12 mx-0 px-3 mb-4">
                                                    <div class="row col-12 col-xl-5 ml-0">
                                                        <div class="col-12 mb-2 px-3">
                                                            <input type="file" class="custom-file-input" id="inputGroupFile01" (change)="onSelectFile($event)">
                                                            <label class="custom-file-label" for="inputGroupFile01">
                                                                <span *ngIf="tempDisplayImage.length === 0">No file Choose</span>
                                                                <span *ngIf="tempDisplayImage.length !== 0">{{ tempDisplayImage[0].name }}</span>
                                                            </label>
                                                            <label>Standard size 1872 × 390 pixels</label>
                                                        </div>
                                                    </div>
                                                    <div class="row col-12 col-xl-3">
                                                        <div class="col-12 mb-2 px-3">
                                                            <img  alt="" src="../../../assets/logo/photo-1103595_1280.png" height="150px" *ngIf="url === null">
                                                            <img  alt="" [src]="url" class="img-fix-showdisplay-edit" *ngIf="url !== null">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 my-3 mx-0 text-center">
                                                <button type="button" class="btn mr-3" style="width: 100px;background-color:#9eb0ba;color:white;" (click)="func_closebtn()">Close</button>
                                                <button type="button" class="btn" style="width: 100px;background-color:#033153;color:white;" (click)="func_savebtn()">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-loading [loading]='loading'></app-loading>