
import { Component, OnInit, Inject, TemplateRef, ViewChild, Input, EventEmitter } from '@angular/core';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { Masterserve } from '../../services/master.service';
import { NavigationStart, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AuthenticationResult, InteractionStatus, InteractionType, Logger, PopupRequest } from '@azure/msal-browser';
import { v4 as uuidv4 } from 'uuid';
import { AppSettingService } from '../../app-setting-service';
import { AuthenService } from '../../services/authenservice.service';
import { PublicClientApplicationService } from '../../services/publicClientApplication.service';
import { ShareService } from 'src/app/services/share.service';
import DOMPurify from 'dompurify';
declare const $;
@Component({
  selector: 'app-main-process',
  templateUrl: './main-process.component.html',
  styleUrls: ['./main-process.component.css']
})

export class MainProcessComponent implements OnInit {
  private msalGuardConfig: MsalGuardConfiguration;
  private authRequestConfig: PopupRequest;

  @Input() path = false;


  apiToken = sessionStorage.getItem('apiToken') ? sessionStorage.getItem('apiToken') : '';
  buildversion = '';
  chkeditdate = false;
  tmpdate;
  title = 'PTTEP';
  status;
  status_pageIdentification;
  riskstep = false;

  alertflow = ''
  templink;
  tempfrommail = '1';
  mode = 'main'
  isIframe = false;
  loggedIn = false;
  NotloggedInAZ = false;
  loginRedirect = false;
  NotnameAZ = "";
  NotpassAZ = "";
  btnName = "";
  userinfo: any;
  Azureuserinfo: any;
  userdata: any;
  loginRole: any;
  showname = "";
  showBUName = "";
  showLoginBUName = "";
  dialogRef: MatDialogRef<any>;
  imgbanner = null;
  isLoginAll = false;
  isLoadingAll = false;
  dataconfign: any;
  // TimeOut
  time;
  timeValue;
  tablemianmanu;


  private readonly _destroying$ = new Subject<void>();

  constructor(
    public mserve: Masterserve,
    private broadcastService: MsalBroadcastService,
    public dataService: ShareService,
    private router: Router,
    public dialog: MatDialog,
    public appsettsv: AppSettingService,
    public authserviceToken: AuthenService,
    public genmsalService: PublicClientApplicationService,
    public authenService: AuthenService
  ) { }

  configdata = this.appsettsv.read();
  BASE_URL = this.configdata.api_url;
  pathbanner = this.BASE_URL + '/app/backend/Resources/FileUpload';
  authService: MsalService;
  public config: AppSettingService;
  msalInstanceData: any;
  ngOnInit(): void {
    
    this.router.events.pipe(filter(event => event instanceof NavigationStart)
    ).subscribe((event: NavigationStart) => {
      if(event.url == '/' )
      {
        this.status = 1;
      }
      else
      {
        this.status = 2;
      }
    });

    this.genmsalService.setNew();
    this.msalInstanceData = this.genmsalService.getpublicClientData();
    this.setinformationByConfig();
    this.checkstatus();
    this.isIframe = window !== window.parent && !window.opener;
    this.runProcess(this.configdata.production);
    this.setLog();
    this.dataService.data$.subscribe((data) => {
      this.showBUName = data;
    });
    sessionStorage.setItem('mainUrl', this.configdata.redirect_url);
    if (!this.apiToken) {
      this.checkAutoLogin();
    }
  }

  checkAutoLogin() {
    this.msalInstanceData.handleRedirectPromise()
      .then(() => {
        const accounts = this.msalInstanceData.getAllAccounts();

        if (accounts.length > 0) {
          const activeAccount = accounts[0];
          this.msalInstanceData.setActiveAccount(activeAccount);
          this.msalInstanceData.acquireTokenSilent({ scopes: ['user.read'] })
            .then(res => {
              this.isLoadingAll = true;
              this.authenService.getUserLogin(res.accessToken).then((res) => {
                this.authserviceToken.setTokenStoreg(res.data.accessToken);
                sessionStorage.setItem('apiToken', res.data.accessToken);
                sessionStorage.setItem('refreshToken', res.data.refreshToken);

                const accounts_aft = this.msalInstanceData.getAllAccounts();
                this.Azureuserinfo = accounts_aft;
                let formData = {
                  Email: accounts_aft[0].username,
                  SecurityCode: "1234"
                }
                this.GetRole_EmployeeService(formData);
              }).catch((err) => {
                //console.log(err);
                alert('ไม่สามารถ Login ได้');
                localStorage.clear()
                sessionStorage.clear()
                this.msalInstanceData.logout();
                this.isLoadingAll = false;
              })
            })
            .catch(error => {
              localStorage.clear()
              sessionStorage.clear()
              console.error('Error acquiring access token:', error);
            });
        } else {
          localStorage.clear()
          sessionStorage.clear()
        }
      })
      .catch((error) => {
        localStorage.clear()
        sessionStorage.clear()
        console.error('Authentication error:', error);
      });
  }

  setLog() {
    const logger = new Logger({
      loggerCallback: (logLevel, message, piiEnabled) => {
        //console.log('MSAL Logging: ', message);
      },
      correlationId: uuidv4(),
      piiLoggingEnabled: false
    });
    this.msalInstanceData.setLogger(logger);
  }

  runProcess(production: any) {
    this.checkAccount();
    this.runBroadcastService();
  }

  setinformationByConfig() {
    this.buildversion = this.configdata.buildversion;
    this.dataconfign = this.configdata.api_url;
  }
  runBroadcastService() {

    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.checkAccount();
      })
  }
  runauthService() {
    this.authService.handleRedirectObservable().subscribe({
      next: (response: AuthenticationResult) => {
        //console.log('Redirect success', response.accessToken);
      },
      error: (authError) => {}//console.log('Redirect Error', authError)
    })
  }

  func_GetBanner() {
    const _s = this;
    let datavalue = {
      SessionEmpID: 1,
      Module: "getlist",
      body: {
        BannerName: "",
        BusinessId: "",
        DelFlag: "",
      }
    }
    _s.mserve.GetBanner(datavalue).then((response) => {
      if (response.data.status) {
        //console.log('DataBanner : ', response.data.body);
        let groupdivision = JSON.parse(sessionStorage.datainfo);

        for (const element of response.data.body) {
          if (groupdivision.length > 0 && groupdivision[0].groupDivisionInfo != null && groupdivision[0].groupDivisionInfo[0].organizatioN_ID === element.businessId) {
            this.imgbanner = element;
            //console.log('imgbanner', this.imgbanner);
            break;
          }
          if ((groupdivision.length > 0 && groupdivision[0].divisionInfo != null) && (groupdivision[0].divisionInfo.organizatioN_ID === element.businessId)) {
            this.imgbanner = element;
            //console.log('imgbanner', this.imgbanner);
            break;
          }
        }

      } else {
        //console.log('false', response);
      }
    });
  }

  changeOfRoutes() {
    let pathname = window.location.pathname
    let temp = pathname.indexOf("/", pathname.indexOf("/") + 1);
    if (temp !== -1) {
      pathname = pathname.slice(0, temp);
    }

    if (pathname === '/Identification' || pathname === '/Assessment' || pathname === '/NewAssessment' || pathname === '/ReviewPage') {
      this.path = true;
    } else {
      this.path = false;
    }

    if (this.path === true) {
      if (pathname === '/Identification') {
        let element21 = document.getElementById("step2");
        element21.classList.remove("active");
        let element31 = document.getElementById("step3");
        element31.classList.remove("active");
      }
      else if (pathname === '/Assessment') {
        let element11 = document.getElementById("step2");
        element11.classList.add("active");
        let element22 = document.getElementById("step3");
        element22.classList.remove("active");
      }
      else if (pathname === '/NewAssessment') {
        let element12 = document.getElementById("step2");
        element12.classList.add("active");
        let element22 = document.getElementById("step3");
        element22.classList.remove("active");
      }
      else if (pathname === '/ReviewPage') {
        let element13 = document.getElementById("step2");
        element13.classList.add("active");
        let element23 = document.getElementById("step3");
        element23.classList.add("active");
      }
    }
  }
  changemenuHome(value, link) {
    this.templink = this.configdata.redirect_url;
    // this.status = value
    sessionStorage.removeItem('RiskID')
    sessionStorage.setItem('ChkBTNNextFromReview', '')
    sessionStorage.setItem('PageBack', '')
    sessionStorage.setItem('pagecritiria', '1')
    if (this.userdata.s_PERMISSSION_LEVEL !== null) {
      this.checkrole()
    }
    sessionStorage.setItem('OwnerLogin', 'true');
    this.router.navigate(['/']);
    
    // window.location.replace(this.templink);
  }
  changemenu(value, link) {
    this.templink = link;

    if (this.loggedIn === false && value !== 1 || this.loggedIn === false) {
      this.alertflow = 'Please login first'
      $('#modalAlertLogin').modal('show');
      this.status = 1
      this.router.navigate(['/']);
    } else {
      sessionStorage.removeItem('RiskID')
      sessionStorage.setItem('ChkBTNNextFromReview', '')
      sessionStorage.setItem('PageBack', '')
      sessionStorage.setItem('pagecritiria', '1')
      this.status = value
      if (this.userdata.s_PERMISSSION_LEVEL !== null) {
        this.checkrole()
      }


    }
  }

  chkmenu(empid) {
    let _s = this;
    let datavalue = {
      SessionEmpID: 1,
      Module: "getmenu",
      body: {
        EmpID: empid,
      }
    }

    _s.mserve.GetTopMenu(datavalue).then((response) => {
      if (response.data.status) {
        _s.tablemianmanu = response.data.body;
        //console.log('tablemianmanu', _s.tablemianmanu)
      } else {
        //console.log('false', response)
        //console.log(response.data.errorMessage);
      }
    }).catch((e) => {
      if (e.response) {
        this.authserviceToken.CheckTokenExpire(e.response.status)
      } else {
        //console.log(e)
      }
    });
  }

  checkstatus() {
    if (window.location.pathname === '/') {
      this.status = 1
    } else {
      this.status = 2
    }
  }

  checkrole() {
    let userdata_s_PERMISSSION_LEVEL = sessionStorage.getItem('userdata_s_PERMISSSION_LEVEL');
    if (userdata_s_PERMISSSION_LEVEL !== null) {
      if (userdata_s_PERMISSSION_LEVEL.includes("Co-ERM")) {
        this.loginRole = 'Co'
      }
      else if (userdata_s_PERMISSSION_LEVEL.includes("ERM")) {
        this.loginRole = 'ERM'
      }
      else if ((userdata_s_PERMISSSION_LEVEL.includes("Owner") || userdata_s_PERMISSSION_LEVEL.includes("OWNER"))
        && (userdata_s_PERMISSSION_LEVEL.includes("CO") || userdata_s_PERMISSSION_LEVEL.includes("Co"))) {
        this.loginRole = 'Co'
      }
      else if (userdata_s_PERMISSSION_LEVEL.includes("Owner") || userdata_s_PERMISSSION_LEVEL.includes("OWNER")) {
        this.loginRole = 'Owner'
      }
      else if (userdata_s_PERMISSSION_LEVEL.includes("CO") || userdata_s_PERMISSSION_LEVEL.includes("Co")) {
        this.loginRole = 'Co'
      }
      else if (userdata_s_PERMISSSION_LEVEL.includes("Staff")) {
        this.loginRole = 'Staff'
      }
    }

    sessionStorage.setItem('Role', this.loginRole);
    let firstOwnerLogin = sessionStorage.getItem('OwnerLogin');
    if (this.tempfrommail === '1') {
      if (this.loginRole === 'Owner' && !firstOwnerLogin) {
        sessionStorage.setItem('OwnerLogin', 'true');
        this.router.navigate(['/Approval', 1]);
        this.status = 2;
      } else if (this.templink === '/Approval/1') {
        sessionStorage.setItem('link', JSON.stringify(2));
      } else {
        sessionStorage.setItem('link', '');
      }

      if (!this.loginRedirect) {
        this.checkstatus();
        this.loginRedirect = true;
        this.tempfrommail = '1';
      }
    }

    switch (this.tempfrommail) {
      case '2':
        this.router.navigate(['/Approval', 1]);
        break;
      case '3':
        this.router.navigate(['/Approval', 2]);
        break;
      case '4':
        this.router.navigate(['/ConsolidateRisk']);
        break;
      case '5':
        this.router.navigate(['/Dashboard']);
        break;
      default:
        // Handle other cases if needed
        break;
    }
  }


  checkAccount() {
    const allAccountsLogin = this.msalInstanceData.getAllAccounts();
    if (this.apiToken) {
      if (allAccountsLogin.length !== 0) {
        let formData = {
          Email: allAccountsLogin[0].username,
          SecurityCode: "1234"
        }
        this.GetRole_EmployeeService(formData);
        this.Azureuserinfo = allAccountsLogin;
      } else {
        sessionStorage.clear()
        this.SetloginBtn()
      }
    } else {
      sessionStorage.clear()
      this.SetloginBtn()
    }
  }

  SetloginBtn() {
    this.userinfo = { 'btnName': 'LOGIN' };
    this.btnName = 'LOGIN';
    // this.chkmenu("");
    sessionStorage.setItem('redirectUrl', window.location.pathname);
    this.router.navigate(['/']);
    this.status = 1;
  }

  SetloginBtnNoPermission() {
    this.userinfo = { 'btnName': 'LOGIN' };
    this.btnName = 'LOGIN';
    // this.chkmenu("");
    this.status = 1;
  }


  GetRole_Employee() {

    this.loginRedirect = sessionStorage.length !== 0;
    let formData = {
      Email: "",
      SecurityCode: "1234"
    };
    this.mserve.GetRole_Employee(formData).then((response) => {
      if (response.data.status) {
        sessionStorage.setItem('configpass', '3rr@test');
        sessionStorage.setItem('loginmail', formData.Email);
        sessionStorage.setItem('datainfo', JSON.stringify(response.data.body));

        let temp;
        temp = JSON.parse(sessionStorage.datainfo);
        this.showLoginBUName = temp[0].groupDivisionInfo ? temp[0].groupDivisionInfo[0].abbreviation : 'CEO';
        sessionStorage.setItem('userdata_s_PERMISSSION_LEVEL', this.userdata.s_PERMISSSION_LEVEL);
        this.loggedIn = true;
        this.NotloggedInAZ = true;
        //console.log("true", response.data.body);
        this.showname = response.data.body[0].firstname?.toUpperCase();
        this.userdata = response.data.body[0];
        sessionStorage.setItem('userdata_s_PERMISSSION_LEVEL', this.userdata.s_PERMISSSION_LEVEL);
        this.chkmenu(this.userdata.employeE_ID);

        if (this.userdata.s_PERMISSSION_LEVEL) {
          this.checkrole();
          this.func_GetBanner();
        }
      } else {
        //console.log('Cannot Login');
      }
    }).catch((e) => {
      this.authserviceToken.CheckTokenExpire(e.response.status);
    });
  }
  
  private GwtMainRoleEmployee(formData: { Email: string; SecurityCode: string; }) {
    this.mserve.GetRole_Employee(formData).then((response) => {
      if (response.data.status === true) {
        sessionStorage.setItem('loginmail', this.NotnameAZ);
        sessionStorage.setItem('configpass', this.NotpassAZ);
        sessionStorage.setItem('datainfo', JSON.stringify(response.data.body));
        sessionStorage.setItem('RoleReview', '');
        let temp;
        temp = JSON.parse(sessionStorage.datainfo);

        if (temp[0].groupDivisionInfo !== null) {
          this.showLoginBUName = temp[0].groupDivisionInfo[0].abbreviation;
        } else {
          this.showLoginBUName = 'CEO';
        }

        this.loggedIn = true;
        this.NotloggedInAZ = true;

        this.showname = response.data.body[0].firstname?.toUpperCase();
        this.userdata = response.data.body[0];
        sessionStorage.setItem('userdata_s_PERMISSSION_LEVEL', this.userdata.s_PERMISSSION_LEVEL);
        this.chkmenu(this.userdata.employeE_ID);
        sessionStorage.setItem('userdata_s_PERMISSSION_LEVEL', this.userdata.s_PERMISSSION_LEVEL);
        if (this.userdata.s_PERMISSSION_LEVEL !== null) {
          this.checkrole();
          this.func_GetBanner();
        }


      } else {
        //console.log('false', response);
        alert('ไม่สามารถ Login ได้');
        sessionStorage.clear()
      }
    }).catch((error) => {
      //console.log(error);
      sessionStorage.clear()
    });
  }

  //v.1
  private GetRole_EmployeeService(formData: { Email: string; SecurityCode: string; }) {
    if(sessionStorage.datainfo)
    {
          let temp;
          temp = JSON.parse(sessionStorage.datainfo);
          this.showLoginBUName = temp[0].groupDivisionInfo ? temp[0].groupDivisionInfo[0].abbreviation : 'CEO';
          this.loggedIn = true;
          this.NotloggedInAZ = true;
          this.showname = temp[0].firstname?.toUpperCase();
          this.userdata = temp[0];
          this.chkmenu(this.userdata.employeE_ID);
          sessionStorage.setItem('userdata_s_PERMISSSION_LEVEL', this.userdata.s_PERMISSSION_LEVEL);
          this.chkmenu(this.userdata.employeE_ID);
          if (this.userdata.s_PERMISSSION_LEVEL) {
            this.checkrole();
            this.func_GetBanner();
          }
          this.isLoadingAll = false;
          if (this.isRedirect()) {
            let cleanPathRedirect = DOMPurify.sanitize(sessionStorage.getItem('redirectUrl'));
            sessionStorage.removeItem('redirectUrl');
            window.location.href = cleanPathRedirect;
          }

    }
    else
    {
      this.mserve.GetRole_Employee(formData).then((response) => {
        if (response.data.status) {
  
          sessionStorage.setItem('loginmail', response.data.body[0].emaiL_ID);
          sessionStorage.setItem('configpass', this.NotpassAZ);
          sessionStorage.setItem('datainfo', JSON.stringify(response.data.body));
          sessionStorage.setItem('RoleReview', '');
          let temp;
          temp = JSON.parse(sessionStorage.datainfo);
          this.showLoginBUName = temp[0].groupDivisionInfo ? temp[0].groupDivisionInfo[0].abbreviation : 'CEO';
          this.loggedIn = true;
          this.NotloggedInAZ = true;
          //console.log("true", response.data.body);
          this.showname = response.data.body[0].firstname?.toUpperCase();
          this.userdata = response.data.body[0];
          this.chkmenu(this.userdata.employeE_ID);
          sessionStorage.setItem('userdata_s_PERMISSSION_LEVEL', this.userdata.s_PERMISSSION_LEVEL);
          this.chkmenu(this.userdata.employeE_ID);
          if (this.userdata.s_PERMISSSION_LEVEL) {
            this.checkrole();
            this.func_GetBanner();
          }
          this.isLoadingAll = false;
          if (this.isRedirect()) {
            let cleanPathRedirect = DOMPurify.sanitize(sessionStorage.getItem('redirectUrl'));
            sessionStorage.removeItem('redirectUrl');
            window.location.href = cleanPathRedirect;
          }
        } else {
          this.loggedIn = false;
          this.isLoadingAll = false;
          alert('ไม่สามารถ Login ได้');
          sessionStorage.clear()
          this.SetloginBtnNoPermission();
        }
      }).catch((e) => {
        if (e.response) {
          this.isLoadingAll = false;
          this.authserviceToken.CheckTokenExpire(e.response.status)
        } else {
          this.isLoadingAll = false;
        }
      });
    }
    
  }

  isRedirect() {
    let cleanPathRedirect = DOMPurify.sanitize(sessionStorage.getItem('redirectUrl'));
    if (cleanPathRedirect && cleanPathRedirect != '/') {
      // if (cleanPathRedirect && cleanPathRedirect != '/' && cleanPathRedirect.toLowerCase().includes('summary-share')) {
      return true;
    }
    return false;
  }
  //v.old
  // private GetRole_EmployeeService(formData: { Email: string; SecurityCode: string; }) {
  //   this.mserve.GetRole_Employee(formData).then((response) => {
  //     if (response.data.status) {

  //       sessionStorage.setItem('loginmail', this.NotnameAZ);
  //       sessionStorage.setItem('configpass', this.NotpassAZ);
  //       sessionStorage.setItem('datainfo', JSON.stringify(response.data.body));
  //       sessionStorage.setItem('RoleReview', '');
  //       let temp;
  //       temp = JSON.parse(sessionStorage.datainfo);
  //       this.showLoginBUName = temp[0].groupDivisionInfo ? temp[0].groupDivisionInfo[0].abbreviation : 'CEO';
  //       this.loggedIn = true;
  //       this.NotloggedInAZ = true;
  //       //console.log("true", response.data.body);
  //       this.showname = response.data.body[0].firstname?.toUpperCase();
  //       this.userdata = response.data.body[0];
  //       this.chkmenu(this.userdata.employeE_ID);
  //       sessionStorage.setItem('userdata_s_PERMISSSION_LEVEL', this.userdata.s_PERMISSSION_LEVEL);
  //       if (this.userdata.s_PERMISSSION_LEVEL) {
  //         this.checkrole();
  //         this.func_GetBanner();
  //       }
  //       $('#modalLoginByPass').modal('hide');
  //     } else {
  //       //console.log('false', response);
  //       //console.log('ไม่สามารถ Login ได้');
  //     }
  //   }).catch((e) => {
  //     if (e.response) {
  //                       this.authserviceToken.CheckTokenExpire(e.response.status)
  //                   } else {
  //                       //console.log(e)
  //                   }
  //   });
  // }






  // async login() {
  //   const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
  //   if (isIE) {
  //     this.msalInstanceData.loginRedirect();
  //   } else {
  //     this.isLoginAll = true;
  //     const accounts = this.msalInstanceData.getAllAccounts();
  //     if (accounts.length === 0) {
  //       this.msalInstanceData.handleRedirectPromise();
  //       await this.msalInstanceData.loginPopup().then((res) => {
  //         //console.log(res.accessToken);
  //         const accounts_aft = this.msalInstanceData.getAllAccounts();
  //         //console.log(accounts_aft);
  //         let verifyTk = {
  //           AccessToken: res.accessToken
  //         }

  //         // this.GetRole_EmployeeService(formData);
  //         this.Azureuserinfo = accounts_aft;    

  //       });
  //     }
  //   }
  // }

  async login() {
    const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
    if (isIE) {
      this.msalInstanceData.loginRedirect();
    } else {
      this.isLoginAll = true;
      const accounts = this.msalInstanceData.getAllAccounts();
      if (accounts.length === 0) {
        this.isLoadingAll = true;
        await this.azureADLogin().then((res) => {
          this.authenService.getUserLogin(res.accessToken).then((res) => {
            this.authserviceToken.setTokenStoreg(res.data.accessToken);
            sessionStorage.setItem('apiToken', res.data.accessToken);
            sessionStorage.setItem('refreshToken', res.data.refreshToken);

            const accounts_aft = this.msalInstanceData.getAllAccounts();
            this.Azureuserinfo = accounts_aft;
            let formData = {
              Email: accounts_aft[0].username,
              SecurityCode: "1234"
            }
            this.GetRole_EmployeeService(formData);
          }).catch((err) => {
            //console.log(err);
            this.isLoadingAll = false;
          })
        }).catch((err) => {
          this.isLoadingAll = false;
        });
        // this.msalInstanceData.handleRedirectPromise();
        // await this.msalInstanceData.loginPopup().then((res) => {
        //   //console.log(res.accessToken);
        //   const accounts_aft = this.msalInstanceData.getAllAccounts();
        //   //console.log(accounts_aft);
        //   let verifyTk = {
        //     AccessToken: res.accessToken
        //   }

        //   // this.GetRole_EmployeeService(formData);
        //   this.Azureuserinfo = accounts_aft;    

        // });

      }
    }
  }

  public async azureADLogin(): Promise<any> {
    let authResult;
    this.msalInstanceData = this.genmsalService.getpublicClientData();
    this.msalGuardConfig = {
      interactionType: InteractionType.Popup,
      authRequest: {
        scopes: ['user.read'],
        domainHint: "pttep.com"
      }
    }
    this.authRequestConfig = { ...this.msalGuardConfig.authRequest } as PopupRequest
    await this.msalInstanceData.initialize()
    this.msalInstanceData.handleRedirectPromise();
    await this.msalInstanceData.loginPopup(this.authRequestConfig).then((res) => {
      authResult = res;
    }).catch(err => {
    });
    return authResult;
  }

  logout() {
    let Azureuserinfo = this.msalInstanceData.getAllAccounts(); //Version 2
    localStorage.clear();
    sessionStorage.clear();
    this.msalInstanceData.logoutRedirect({ account: Azureuserinfo[0] }).then(() => {
      location.reload();
    });


  }

  clearCookies() {
    const cookies: string[] = document.cookie.split(";");
    //console.log(cookies)
    for (const element of cookies) {
      const cookie: string = element;
      const eqPos: number = cookie.indexOf("=");
      const name: string = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    }
  }

  func_btn_RegisterNewRisk_BG(value, statusiden) {
    $('#modalSelectMethodology').modal('hide');
    if (this.loggedIn === false) {
      this.alertflow = 'Please login first'
      $('#modalAlertLogin').modal('show');
      this.status = 1
    } else {
      this.status = value
      this.status_pageIdentification = statusiden
      sessionStorage.setItem('statusflow', statusiden);
      sessionStorage.removeItem('RiskID')
      this.router.navigate(['/Identification', this.status_pageIdentification]);
    }
  }

  func_btn_FullAnalysisRisk_BG(value, statusiden) {
    $('#modalSelectMethodology').modal('hide');
    if (this.loggedIn === false) {
      this.alertflow = 'Please login first'
      $('#modalAlertLogin').modal('show');
      this.status = 1
    } else {
      this.status = value
      this.status_pageIdentification = statusiden
      sessionStorage.setItem('statusflow', statusiden);
      sessionStorage.removeItem('RiskID')
      this.router.navigate(['/Assessment', this.status_pageIdentification]);
    }
  }

  closeflow() {
    const _s = this;
    _s.NotpassAZ = '';
    $('#modalAlertLogin').modal('hide');
  }


  SelectMethodology() {
    $('#modalSelectMethodology').modal('show');
  }

  close() {
    $('#modalSelectMethodology').modal('hide');
  }

  ngAfterViewInit(): void {
    let pathnamesite = window.location.pathname

    if (pathnamesite === '/Todo') {
      this.tempfrommail = '2'
    }
    else if (pathnamesite === '/Myrisk') {
      this.tempfrommail = '3'
    }
    else if (pathnamesite === '/Consolidate') {
      this.tempfrommail = '4'
    }
    else if (pathnamesite === '/Riskmap') {
      this.tempfrommail = '5'
    }
  }

}


export interface DialogData { }
@Component({
  selector: 'app-dialog-elements',
  templateUrl: '../../components/_comp/dialog-elements.html',
})
export class Dialogelements {
  constructor(public dialogRef: MatDialogRef<Dialogelements>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  onNoClick() {
    this.dialogRef.close();
  }
}
