import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RiskMap } from '../../services/riskmap.service';
import { Masterserve } from '../../services/master.service';
import { Router } from '@angular/router';

declare let $;

import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { AuthenService } from 'src/app/services/authenservice.service';
import { MainProcessComponent } from '../main-process/main-process.component';

interface BUNode {
  name: string;
  value: string;
  children?: BUNode[];
  index: any
}

interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  value: string;
  level: number;
}

let TREE_DATA: BUNode[] = [

];

@Component({
  selector: 'app-dashboard',
  templateUrl: './page.dashboard.html',
  styleUrls: ['./page.dashboard.css'],
  providers: [RiskMap, NgbModal, Masterserve]
})

export class PageDashboard implements OnInit {

  constructor( public mserve: RiskMap, 
    private router: Router, 
    private appServicecomp: MainProcessComponent,
    private MServ: Masterserve, 
    public authserviceToken: AuthenService) {
    this.dataSource2.data = TREE_DATA;
  }

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
  public _transformer = (node: BUNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      value: node.value,
      level: level,
      index: node.index
    };
  }
  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.children);
  dataSource2 = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  dataSourceDynamic = []
  appService= this.appServicecomp;
  tempCurrentQuarterID = null;
  tempdatatreevalue = [];
  Data_SessionStorage;
  loading = true;
  loading2 = false;
  tempbu;
  tempindex;
  tempindexfromback;
  tableriskrating;
  QuarterDD;
  list = {
    risk: {
      impactcolor: [],
      impact: [],
      risktable: [
        {
          row: 1,
          col: [
            {
              col: 0, class: 'impact-x', item: [
                { title: 'Critical', bgcolor: '#1b190f', color: '#edf0f7', index: '', Risk_Id: '', BU: '' },
                { title: '&nbsp;', color: '#edf0f7' },
                { title: '-', bgcolor: '#0e223c', color: '#edf0f7' },
                { title: '&nbsp;', color: '#edf0f7' },
                { title: '-', bgcolor: '#5c2322', color: '#edf0f7' }
              ]
            },
            { col: 1, class: 'medium', item: [] },
            { col: 2, class: 'medium', item: [] },
            { col: 3, class: 'high', item: [] },
            { col: 4, class: 'high', item: [] },
            { col: 5, class: 'high', item: [] },
          ]
        },
        {
          row: 2,
          col: [
            {
              col: 0, class: 'impact-x', item: [
                { title: 'Serious', bgcolor: '#444128', color: '#edf0f7' },
                { title: '&nbsp;', color: '#edf0f7' },
                { title: '-', bgcolor: '#32598d', color: '#edf0f7' },
                { title: '&nbsp;', color: '#edf0f7' },
                { title: '-', bgcolor: '#aa504e', color: '#edf0f7' }
              ]
            },
            { col: 1, class: 'medium', item: [] },
            { col: 2, class: 'medium', item: [] },
            { col: 3, class: 'medium', item: [] },
            { col: 4, class: 'high', item: [] },
            { col: 5, class: 'high', item: [] },
          ]
        },
        {
          row: 3,
          col: [
            {
              col: 0, class: 'impact-x', item: [
                { title: 'Significant', bgcolor: '#8b8352', color: 'black' },
                { title: 'NPV/EMV (MMUSD)', color: '#4649f7' },
                { title: '-', bgcolor: '#4d85ce', color: 'black' },
                { title: 'NI (MMUSD)', color: '#ba2527' },
                { title: '-', bgcolor: '#cb8d8f', color: 'black' }
              ]
            },
            { col: 1, class: 'low', item: [] },
            { col: 2, class: 'medium', item: [] },
            { col: 3, class: 'medium', item: [] },
            { col: 4, class: 'medium', item: [] },
            { col: 5, class: 'high', item: [] },
          ]
        },
        {
          row: 4,
          col: [
            {
              col: 0, class: 'impact-x', item: [
                { title: 'Moderate', bgcolor: '#b9b696', color: 'black' },
                { title: '&nbsp;', color: '#edf0f7' },
                { title: '-', bgcolor: '#83a9db', color: 'black' },
                { title: '&nbsp;', color: '#edf0f7' },
                { title: '-', bgcolor: '#d6adb1', color: 'black' }
              ]
            },
            { col: 1, class: 'low', item: [] },
            { col: 2, class: 'low', item: [] },
            { col: 3, class: 'medium', item: [] },
            { col: 4, class: 'medium', item: [] },
            { col: 5, class: 'medium', item: [] },
          ]
        },
        {
          row: 5,
          col: [
            {
              col: 0, class: 'impact-x', item: [
                { title: 'Minor', bgcolor: '#cdccbe', color: 'black' },
                { title: '&nbsp;', color: '#edf0f7' },
                { title: '-', bgcolor: '#aad1e1', color: 'black' },
                { title: '&nbsp;', color: '#edf0f7' },
                { title: '-', bgcolor: '#e1cfd4', color: 'black' }]
            },
            { col: 1, class: 'low', item: [] },
            { col: 2, class: 'low', item: [] },
            { col: 3, class: 'low', item: [] },
            { col: 4, class: 'medium', item: [] },
            { col: 5, class: 'medium', item: [] },
          ]
        },
        {
          row: 0,
          col: [
            { col: 0, class: 'impact-y', item: [] },
            { col: 1, class: 'impact-y', item: [{ title: 'Rare', bgcolor: '#abd1e1', color: 'black' }] },
            { col: 2, class: 'impact-y', item: [{ title: 'Unlikely', bgcolor: '#83a9db', color: 'black' }] },
            { col: 3, class: 'impact-y', item: [{ title: 'Possible', bgcolor: '#85aadb', color: 'black' }] },
            { col: 4, class: 'impact-y', item: [{ title: 'Likely', bgcolor: '#325a8d', color: 'white' }] },
            { col: 5, class: 'impact-y', item: [{ title: 'Almost Certain', bgcolor: '#0d213b', color: 'white' }] },
          ]
        }
      ],
      riskitems: []
    },
    riskprofile: {
      year: [],
      quarter: []
    },
    businessUnit: {
      items: []
    },
    field: {
      Mitigate: '1',
      riskprofileYear: '',
      riskprofileQuarter: '1',
      riskprofileQuartervalue: '1',
      businessUnit: '',
    }
  }

  SelectBUName = "";
  isFromBack = false;

  ngOnInit(): void {
    const _s = this;
    let count = 0;
    // sessionStorage.setItem("RoleReview", '');
    this.appService.showBUName = ''
    this.SelectBUName = '';
    _s.mRiskProfile_Year();
    if (sessionStorage.getItem('PageBack') !== '') {
      let tempfileter = JSON.parse(sessionStorage.FilterRiskMap);
      _s.list.field.riskprofileQuarter = tempfileter[0].QuarterID
      _s.list.field.businessUnit = tempfileter[0].BusinessCode
      _s.list.field.riskprofileYear = tempfileter[0].Year
      _s.list.field.Mitigate = tempfileter[0].TypeMitigate
      _s.tempindexfromback = tempfileter[0].index
      _s.isFromBack = true
      _s.SelectBUName = sessionStorage.getItem('SelectBUName')
      this.select_clickactive(_s.list.field.Mitigate)
    }
    this.Data_SessionStorage = JSON.parse(sessionStorage.datainfo);
    //console.log('Data_SessionStorage => ', this.Data_SessionStorage)

    TREE_DATA = [];
    if (sessionStorage.Role === 'ERM') {
      if (this.Data_SessionStorage[0].ermInfo !== null) {
        this.Data_SessionStorage[0].ermInfo.forEach(function (ItemVal, ItemIndexff) {
          _s.list.businessUnit.items.push({
            text: ItemVal.abbreviation,
            value: ItemVal.organizatioN_ID,
            tags: [ItemIndexff],
            nodes: []
          })

          TREE_DATA.push({
            name: ItemVal.abbreviation,
            value: ItemVal.organizatioN_ID,
            children: [],
            index: ++count
          })
        });
      }
    }
    this.dataSource2.data = TREE_DATA;
    //console.log('dataSource2', this.dataSource2)

  }

  select_clickactive(value) {
    if (value === '1') {
      let element1 = document.getElementById("btn-1");
      element1.classList.add("selected");
      element1.classList.remove("not-selected");
      let element2 = document.getElementById("btn-2");
      element2.classList.remove("selected");
      element2.classList.add("not-selected");
      this.list.field.Mitigate = '1'
    } else {
      let element1 = document.getElementById("btn-2");
      element1.classList.add("selected");
      element1.classList.remove("not-selected");
      let element2 = document.getElementById("btn-1");
      element2.classList.remove("selected");
      element2.classList.add("not-selected");
      this.list.field.Mitigate = '2'
    }
  }
  select_click(value) {
    if (value === 1) {
      let element1 = document.getElementById("btn-1");
      element1.classList.add("selected");
      element1.classList.remove("not-selected");
      let element2 = document.getElementById("btn-2");
      element2.classList.remove("selected");
      element2.classList.add("not-selected");
      this.list.field.Mitigate = '1'
      this.clickBusinessUnit('', '', this.SelectBUName)
    } else {
      let element1 = document.getElementById("btn-2");
      element1.classList.add("selected");
      element1.classList.remove("not-selected");
      let element2 = document.getElementById("btn-1");
      element2.classList.remove("selected");
      element2.classList.add("not-selected");
      this.list.field.Mitigate = '2'
      this.clickBusinessUnit('', '', this.SelectBUName)
    }

  }

  mRiskProfile_Year() {
    const _s = this;
    _s.mserve.getQuarterYearDD({ 'Module': 'YearDD' }).then((response) => {
      if (response.data.status) {
        //console.log('YearDD : true', response)
        _s.list.riskprofile.year = response.data.body;
        let date = new Date();
        if (sessionStorage.getItem('PageBack') === '') {
          _s.list.field.riskprofileYear = date.getFullYear().toString();
        }
        _s.mRiskProfile_Quarter()
      } else {
        //console.log('YearDD : false', response)
      }
    }).catch((e) => { this.authserviceToken.CheckTokenExpire(e.response.status) })
  }
  mRiskProfile_Quarter() {
   
    const _s = this;
    _s.loading2 = !_s.loading;
    _s.mserve.getQuarterDD({ 'Module': 'QuarterDD', 'TextSearch1': _s.list.field.riskprofileYear }).then((response) => {
      if (response.data.status) {
      
        //console.log('QuarterDD : true', response)
        _s.list.riskprofile.quarter = response.data.body
        let d = new Date();
        let month = d.getMonth() + 1;
        if (sessionStorage.getItem('PageBack') === '') {
          let cal_Quarter = 0;
          _s.list.field.riskprofileQuarter = ''
          
          
          this.checkMonth(month, _s);
        }
        else {
          this.setRiskprofileQuarter(_s);
        }
        this.tempCurrentQuarterID = _s.list.field.riskprofileQuarter
        //console.log('_s.list.field.riskprofileQuarter', _s.list.field.riskprofileQuarter)
        this.func_GETDATA_RiskRating();
      } else {
        alert(response.data.errorMessage)
        //console.log('QuarterDD : false', response)
      }
    }).catch((e) => { 
      window.alert(e) })
  }

  private checkMonth(month: number, _s: this) {
    //console.log('setMonthI : true')
    if (month <= 3) {
      this.setMonthI(_s);
    } else if (month >= 4 && month <= 6) {
      this.setMonthII(_s);
    } else if (month >= 7 && month <= 9) {
      this.setMonthIII(_s);
    } else if (month >= 10 && month <= 12) {
      this.setMonthIV(_s);
    }

 
  }
    setMonthIII(_s:this) {
      //console.log('setMonthIII : true')
      //console.log('_s.list.field.riskprofileQuarter',this.list.field.riskprofileQuarter)
      _s.list.field.riskprofileQuarter = (this.list.riskprofile.quarter[2] !== undefined) ? this.list.riskprofile.quarter[2].text : this.list.riskprofile.quarter[0].text;
      //console.log('_s.list.field.riskprofileQuarter',_s.list.field.riskprofileQuarter)
      _s.list.field.riskprofileQuartervalue = (this.list.riskprofile.quarter[2] !== undefined) ? this.list.riskprofile.quarter[2].value : this.list.riskprofile.quarter[0].value;
    }
  private setMonthIV(_s: this) {
    //console.log('setMonthIV : true')
    _s.list.field.riskprofileQuarter = (this.list.riskprofile.quarter[3] !== undefined) ? this.list.riskprofile.quarter[3].text : this.list.riskprofile.quarter[0].text;
    _s.list.field.riskprofileQuartervalue = (this.list.riskprofile.quarter[3] !== undefined) ? this.list.riskprofile.quarter[3].value : this.list.riskprofile.quarter[0].value;
  }

  private setMonthII(_s: this) {
    //console.log('setMonthII : true')
    _s.list.field.riskprofileQuarter = (this.list.riskprofile.quarter[1] !== undefined) ? this.list.riskprofile.quarter[1].text : this.list.riskprofile.quarter[0].text;
    _s.list.field.riskprofileQuartervalue = (this.list.riskprofile.quarter[1] !== undefined) ? this.list.riskprofile.quarter[1].value : this.list.riskprofile.quarter[0].value;
  }

  private setMonthI(_s: this) {
    //console.log('setMonthI : true')
    //console.log(_s.list);
    //console.log('this.list',this.list);
    _s.list.field.riskprofileQuarter = this.list.riskprofile.quarter[0].text;
    _s.list.field.riskprofileQuartervalue = this.list.riskprofile.quarter[0].value;
  }

  private setRiskprofileQuarter(_s: this) {
    for (let item_quarter of _s.list.riskprofile.quarter) {
      if (_s.list.field.riskprofileQuarter == item_quarter.text) {
        _s.list.field.riskprofileQuarter = item_quarter.text;
        _s.list.field.riskprofileQuartervalue = item_quarter.value;
        break;
      }
    }
  }

  mBusinessUnit() {
    const _s = this;
    _s.loading2 = (_s.loading === true) ? false : true;
    let count = 0;
    //console.clear();
    this.checkYear(_s);

    let formdataRiskmapMenu = {
      SessionEmpID: this.Data_SessionStorage[0].employeE_ID,
      Email: sessionStorage.loginmail,
      Module: "GetMenu",
      SecurityCode: "1234",
      body: {
        QuarterID: _s.list.field.riskprofileQuartervalue,
        Role: sessionStorage.Role
      }
    }
    //console.log('Data Send :=>', formdataRiskmapMenu)
    _s.mserve.GetMenuRiskMap(formdataRiskmapMenu).then((response) => {
      if (response.data.status) {
        //console.log("GetMenuRiskMap");
        count = this.mBusinessUnit_II_Chk_status(response, _s, count);
      } else {
        alert(response.data.errorMessage)
        //console.log('Data RiskMapMenu : false', response)
      }

    }).catch((e) => {
      //console.log(e)
    })

  }

  private mBusinessUnit_II_Chk_status(response, _s: any, count: number) {
    //console.log("mBusinessUnit_II_Chk_status");
    let tempdata = response.data.body;
    //console.log('Data RiskMapMenu', response.data.body);

    for (let z = 0; z < tempdata.length; z++) {
      TREE_DATA = [];
      _s.list.businessUnit.items = [];
      this.dataSourceDynamic.push({ dataSource: new MatTreeFlatDataSource(this.treeControl, this.treeFlattener) });
      this.dataSourceDynamic[z].dataSource.data = TREE_DATA;

      if (tempdata[z].groupDivision_Level !== null) {

        count = this.mBusinessUnit_III_Chk_groupDivision(tempdata, z, _s, count);
      }

      if (tempdata[z].division_Level !== null) {

        count = this.mBusinessUnit_III_Chk_division_Level(tempdata, z, _s, count);
      }

      if (tempdata[z].department_Level !== null) {
        
        count = this.mBusinessUnit_III_Chk_department_Level(tempdata, z, _s, count);
      }

      this.dataSourceDynamic[z].dataSource.data = TREE_DATA;

    }
   
    if (_s.isFromBack) {
  
      _s.clickBusinessUnit(_s.list.field.businessUnit, 1, _s.SelectBUName);
    }
    else {
      let datavalue  = _s.tempdatatreevalue[0] ? _s.tempdatatreevalue[0].value:'';
      let datatempdatatreevalue  = _s.tempdatatreevalue[0] ? _s.tempdatatreevalue[0].text:null;
        _s.clickBusinessUnit(datavalue, 1,datatempdatatreevalue);
      
     
    }
    return count;
  }

  private mBusinessUnit_III_Chk_department_Level(tempdata: any, z: number, _s: this, count: number) {
    tempdata[z].department_Level.forEach(function (ItemValgrp, ItemIndexffgrp) {
      ItemValgrp.org_Menu.forEach(function (ItemVal, ItemIndexff) {
        if (ItemVal.coInfo.length != 0 || ItemVal.asset_Level.length != 0) {

          _s.tempdatatreevalue.push({
            text: ItemVal.abbreviation,
            value: ItemVal.organization_Code,
            tags: [ItemIndexff],
            nodes: []
          });

          _s.list.businessUnit.items.push({
            text: ItemVal.abbreviation,
            value: ItemVal.organization_Code,
            tags: [ItemIndexff],
            nodes: []
          });

          TREE_DATA.push({
            name: ItemVal.abbreviation,
            value: ItemVal.organization_Code,
            children: [],
            index: ++count
          });
          mBusinessUnit_IV_chkasset_Level(ItemVal, ItemIndexff, _s, count);
          mBusinessUnit_IV_department_Level(ItemVal);
        }
      });
    });
    return count;
    function mBusinessUnit_IV_chkasset_Level(ItemVal: any, ItemIndexff: any, _s: any, count: any) {
      if (ItemVal.asset_Level !== undefined) {
        ItemVal.asset_Level.forEach(function (ItemValasseslv1, ItemIndexasseslv1) {
          if (ItemValasseslv1.coInfo.length != 0) {
            _s.list.businessUnit.items[ItemIndexff].nodes.push({
              text: ItemValasseslv1.abbreviation,
              value: ItemValasseslv1.organization_Code,
              tags: [ItemIndexasseslv1],
              nodes: []
            });
  
            TREE_DATA[ItemIndexff].children.push({
              name: ItemValasseslv1.abbreviation,
              value: ItemValasseslv1.organization_Code,
              children: [],
              index: ++count
            });
          }
        });
      }
    }
    function mBusinessUnit_IV_department_Level(ItemVal: any) {
      if (ItemVal.department_Level !== undefined) {
        ItemVal.department_Level.forEach(function (ItemValorg, ItemIndexorg) {
          let num;
          if (_s.list.businessUnit.items.length === 0) {
            num = _s.list.businessUnit.items.length;
          } else {
            num = (_s.list.businessUnit.items.length) - 1;
          }
          _s.list.businessUnit.items[num].nodes.push({
            text: ItemValorg.abbreviation,
            value: ItemValorg.organization_Code,
            tags: [ItemIndexorg],
            nodes: []
          });

          TREE_DATA[num].children.push({
            name: ItemValorg.abbreviation,
            value: ItemValorg.organization_Code,
            children: [],
            index: ++count
          });

          mBusinessUnit_V_forEach1(ItemValorg, num);
          if (ItemValorg.department_Level !== undefined) {
            mBusinessUnit_V_forEach2(ItemValorg, num, ItemIndexorg);
          }
        });
      }
    }

    function mBusinessUnit_V_forEach2(ItemValorg: any, num: number, ItemIndexorg: any) {
      ItemValorg.department_Level.forEach(function (ItemValdelv3, ItemIndexdelv3) {
        _s.list.businessUnit.items[num].nodes[ItemIndexorg].nodes.push({
          text: ItemValdelv3.abbreviation,
          value: ItemValdelv3.organization_Code,
          tags: [ItemIndexdelv3],
          nodes: []
        });

        TREE_DATA[num].children[ItemIndexorg].children.push({
          name: ItemValdelv3.abbreviation,
          value: ItemValdelv3.organization_Code,
          children: [],
          index: ++count
        });
        this.mBusinessUnit_VI_asset_LevelforEach(ItemValdelv3, num, ItemIndexorg, _s, count);
      });
    }

    function mBusinessUnit_V_forEach1(ItemValorg: any, num: number) {
      ItemValorg.asset_Level.forEach(function (ItemValasseslv3, ItemIndexasseslv3) {
        let num3 = _s.list.businessUnit.items[num].nodes.length;
        //console.log(num3);
        if (_s.list.businessUnit.items[num].nodes.length === 0) {
          num3 = _s.list.businessUnit.items[num].nodes.length;
        } else {
          num3 = (_s.list.businessUnit.items[num].nodes.length) - 1;
        }
        _s.list.businessUnit.items[num].nodes[num3].nodes.push({
          text: ItemValasseslv3.abbreviation,
          value: ItemValasseslv3.organization_Code,
          tags: [ItemIndexasseslv3],
          nodes: []
        });

        TREE_DATA[num].children[num3].children.push({
          name: ItemValasseslv3.abbreviation,
          value: ItemValasseslv3.organization_Code,
          children: [],
          index: ++count
        });
      });
    }
  }

  mBusinessUnit_VI_asset_LevelforEach(ItemValdelv3: any, num: number, ItemIndexorg: any, _s: any, count: any) {
    ItemValdelv3.asset_Level.forEach(function (ItemValasseslv4, ItemIndexasseslv4) {
      //console.log(_s.list.businessUnit.items[num].nodes[(_s.list.businessUnit.items[num].nodes.length) - 1].nodes.length);
      let num4 = _s.list.businessUnit.items[num].nodes[0].nodes.length;
      //console.log(num4);
      if (_s.list.businessUnit.items[num].nodes[(_s.list.businessUnit.items[num].nodes.length) - 1].nodes.length === 0) {
        num4 = _s.list.businessUnit.items[num].nodes[(_s.list.businessUnit.items[num].nodes.length) - 1].nodes.length;
      } else {
        num4 = (_s.list.businessUnit.items[num].nodes[(_s.list.businessUnit.items[num].nodes.length) - 1].nodes.length) - 1;
      }
      _s.list.businessUnit.items[num].nodes[ItemIndexorg].nodes[num4].nodes.push({
        text: ItemValasseslv4.abbreviation,
        value: ItemValasseslv4.organization_Code,
        tags: [ItemIndexasseslv4],
        nodes: []
      });

      TREE_DATA[num].children[ItemIndexorg].children[num4].children.push({
        name: ItemValasseslv4.abbreviation,
        value: ItemValasseslv4.organization_Code,
        children: [],
        index: ++count
      });
    });
  }

  private mBusinessUnit_III_Chk_division_Level(tempdata: any, z: number, _s: this, count: number) {
    tempdata[z].division_Level.forEach(function (ItemValgrp, ItemIndexffgrp) {

      ItemValgrp.org_Menu.forEach(function (ItemVal, ItemIndexff) {
        if (ItemVal.coInfo.length != 0 || ItemVal.department_Level.length != 0 || ItemVal.asset_Level.length != 0) {
          _s.tempdatatreevalue.push({
            text: ItemVal.abbreviation,
            value: ItemVal.organization_Code,
            tags: [ItemIndexff],
            nodes: []
          });

          _s.list.businessUnit.items.push({
            text: ItemVal.abbreviation,
            value: ItemVal.organization_Code,
            tags: [ItemIndexff],
            nodes: []
          });

          TREE_DATA.push({
            name: ItemVal.abbreviation,
            value: ItemVal.organization_Code,
            children: [],
            index: ++count
          });

          mBusinessUnit_IV_chkasset_Level(ItemVal, ItemIndexff, _s, count);
          mBusinessUnit_IV_chkdepartment_Level(ItemVal);
        }
      });
    });
    return count;
    function mBusinessUnit_IV_chkasset_Level(ItemVal: any, ItemIndexff: any, _s: any, count: any) {
      if (ItemVal.asset_Level !== undefined) {
        ItemVal.asset_Level.forEach(function (ItemValasseslv1, ItemIndexasseslv1) {
          if (ItemValasseslv1.coInfo.length != 0) {
            _s.list.businessUnit.items[ItemIndexff].nodes.push({
              text: ItemValasseslv1.abbreviation,
              value: ItemValasseslv1.organization_Code,
              tags: [ItemIndexasseslv1],
              nodes: []
            });
  
            TREE_DATA[ItemIndexff].children.push({
              name: ItemValasseslv1.abbreviation,
              value: ItemValasseslv1.organization_Code,
              children: [],
              index: ++count
            });
          }
        });
      }
    }
    function mBusinessUnit_IV_chkdepartment_Level(ItemVal: any) {
      if (ItemVal.department_Level !== undefined) {
        ItemVal.department_Level.forEach(function (ItemValorg, ItemIndexorg) {
          if (ItemValorg.coInfo.length != 0 || ItemValorg.asset_Level.length != 0) {
            let num;
            if (_s.list.businessUnit.items.length === 0) {
              num = _s.list.businessUnit.items.length;
            } else {
              num = (_s.list.businessUnit.items.length) - 1;
            }
            _s.list.businessUnit.items[num].nodes.push({
              text: ItemValorg.abbreviation,
              value: ItemValorg.organization_Code,
              tags: [ItemIndexorg],
              nodes: []
            });

            TREE_DATA[num].children.push({
              name: ItemValorg.abbreviation,
              value: ItemValorg.organization_Code,
              children: [],
              index: ++count
            });
          mBusinessUnit_V_asset_LevelforEach(ItemValorg, num, _s, count);
            mBusinessUnit_V_chkdepartment_Level(ItemValorg, num, ItemIndexorg);
          }
        });
      function   mBusinessUnit_V_asset_LevelforEach(ItemValorg: any, num: number, _s: any, count: any) {
          ItemValorg.asset_Level.forEach(function (ItemValasseslv3, ItemIndexasseslv3) {
            if (ItemValasseslv3.coInfo.length != 0) {
              let num3 = _s.list.businessUnit.items[num].nodes.length;
              //console.log(num3);
              if (_s.list.businessUnit.items[num].nodes.length === 0) {
                num3 = _s.list.businessUnit.items[num].nodes.length;
              } else {
                num3 = (_s.list.businessUnit.items[num].nodes.length) - 1;
              }
              _s.list.businessUnit.items[num].nodes[num3].nodes.push({
                text: ItemValasseslv3.abbreviation,
                value: ItemValasseslv3.organization_Code,
                tags: [ItemIndexasseslv3],
                nodes: []
              });
      
              TREE_DATA[num].children[num3].children.push({
                name: ItemValasseslv3.abbreviation,
                value: ItemValasseslv3.organization_Code,
                children: [],
                index: ++count
              });
            }
          });
        }

      }
    }

    function mBusinessUnit_V_chkdepartment_Level(ItemValorg: any, num: number, ItemIndexorg: any) {
      if (ItemValorg.department_Level !== undefined) {
        ItemValorg.department_Level.forEach(function (ItemValdelv3, ItemIndexdelv3) {
          _s.list.businessUnit.items[num].nodes[ItemIndexorg].nodes.push({
            text: ItemValdelv3.abbreviation,
            value: ItemValdelv3.organization_Code,
            tags: [ItemIndexdelv3],
            nodes: []
          });

          TREE_DATA[num].children[ItemIndexorg].children.push({
            name: ItemValdelv3.abbreviation,
            value: ItemValdelv3.organization_Code,
            children: [],
            index: ++count
          });

          this.mBusinessUnit_VI_asset_LevelforEach(ItemValdelv3, num, ItemIndexorg, _s, count);
        });
      }
    }
  }

  mBusinessUnit_V_asset_LevelforEach(ItemValorg: any, num: number, _s: any, count: any) {
    ItemValorg.asset_Level.forEach(function (ItemValasseslv3, ItemIndexasseslv3) {
      if (ItemValasseslv3.coInfo.length != 0) {
        let num3 = _s.list.businessUnit.items[num].nodes.length;
        //console.log(num3);
        if (_s.list.businessUnit.items[num].nodes.length === 0) {
          num3 = _s.list.businessUnit.items[num].nodes.length;
        } else {
          num3 = (_s.list.businessUnit.items[num].nodes.length) - 1;
        }
        _s.list.businessUnit.items[num].nodes[num3].nodes.push({
          text: ItemValasseslv3.abbreviation,
          value: ItemValasseslv3.organization_Code,
          tags: [ItemIndexasseslv3],
          nodes: []
        });

        TREE_DATA[num].children[num3].children.push({
          name: ItemValasseslv3.abbreviation,
          value: ItemValasseslv3.organization_Code,
          children: [],
          index: ++count
        });
      }
    });
  }


  private mBusinessUnit_III_Chk_groupDivision(tempdata: any, z: number, _s: this, count: number) {
    tempdata[z].groupDivision_Level.forEach(function (ItemValgrp, ItemIndexffgrp) {
      ItemValgrp.org_Menu.forEach(function (ItemVal, ItemIndexff) {
        if (ItemVal.coInfo.length != 0 || ItemVal.division_Level.length != 0 || ItemVal.asset_Level.length != 0) {

          _s.tempdatatreevalue.push({
            text: ItemVal.abbreviation,
            value: ItemVal.organization_Code,
            tags: [ItemIndexff],
            nodes: []
          });

          _s.list.businessUnit.items.push({
            text: ItemVal.abbreviation,
            value: ItemVal.organization_Code,
            tags: [ItemIndexff],
            nodes: []
          });

          TREE_DATA.push({
            name: ItemVal.abbreviation,
            value: ItemVal.organization_Code,
            children: [],
            index: ++count
          });
          mBusinessUnit_IV_chkasset_Level(ItemVal, ItemIndexff, _s, count);
          mBusinessUnit_IV_Chk_division_Level(ItemVal);
        }
      });
    });
    return count;
    function mBusinessUnit_IV_chkasset_Level(ItemVal: any, ItemIndexff: any, _s: any, count: any) {
      if (ItemVal.asset_Level !== undefined) {
        ItemVal.asset_Level.forEach(function (ItemValasseslv1, ItemIndexasseslv1) {
          if (ItemValasseslv1.coInfo.length != 0) {
            _s.list.businessUnit.items[ItemIndexff].nodes.push({
              text: ItemValasseslv1.abbreviation,
              value: ItemValasseslv1.organization_Code,
              tags: [ItemIndexasseslv1],
              nodes: []
            });
  
            TREE_DATA[ItemIndexff].children.push({
              name: ItemValasseslv1.abbreviation,
              value: ItemValasseslv1.organization_Code,
              children: [],
              index: ++count
            });
          }
        });
      }
    }
    function mBusinessUnit_IV_Chk_division_Level(ItemVal: any) {
      if (ItemVal.division_Level !== undefined) {
        ItemVal.division_Level.forEach(function (ItemValorg, ItemIndexorg) {

          if (ItemValorg.coInfo.length != 0 || ItemValorg.department_Level.length != 0 || ItemValorg.asset_Level.length != 0) {
            let num;
            if (_s.list.businessUnit.items.length === 0) {
              num = _s.list.businessUnit.items.length;
            } else {
              num = (_s.list.businessUnit.items.length) - 1;
            }
            _s.list.businessUnit.items[num].nodes.push({
              text: ItemValorg.abbreviation,
              value: ItemValorg.organization_Code,
              tags: [ItemIndexorg],
              nodes: []
            });

            TREE_DATA[num].children.push({
              name: ItemValorg.abbreviation,
              value: ItemValorg.organization_Code,
              children: [],
              index: ++count
            });

            mBusinessUnit_V_asset_LevelforEach(ItemValorg, num, _s, count);
            if (ItemValorg.department_Level !== undefined) {
              mBusinessUnit_V_department_Level_forEach(ItemValorg, num);
            }
            function   mBusinessUnit_V_asset_LevelforEach(ItemValorg: any, num: number, _s: any, count: any) {
              ItemValorg.asset_Level.forEach(function (ItemValasseslv3, ItemIndexasseslv3) {
                if (ItemValasseslv3.coInfo.length != 0) {
                  let num3 = _s.list.businessUnit.items[num].nodes.length;
                  //console.log(num3);
                  if (_s.list.businessUnit.items[num].nodes.length === 0) {
                    num3 = _s.list.businessUnit.items[num].nodes.length;
                  } else {
                    num3 = (_s.list.businessUnit.items[num].nodes.length) - 1;
                  }
                  _s.list.businessUnit.items[num].nodes[num3].nodes.push({
                    text: ItemValasseslv3.abbreviation,
                    value: ItemValasseslv3.organization_Code,
                    tags: [ItemIndexasseslv3],
                    nodes: []
                  });
          
                  TREE_DATA[num].children[num3].children.push({
                    name: ItemValasseslv3.abbreviation,
                    value: ItemValasseslv3.organization_Code,
                    children: [],
                    index: ++count
                  });
                }
              });
            }
          }
        });
      }
    }

    function mBusinessUnit_V_department_Level_forEach(ItemValorg: any, num: number) {
      ItemValorg.department_Level.forEach(function (ItemValdelv3, ItemIndexdelv3) {

        if (ItemValdelv3.asset_Level.length != 0 || ItemValdelv3.coInfo.length != 0) {
          let num5;
          if (_s.list.businessUnit.items[num].nodes.length === 0) {
            num5 = _s.list.businessUnit.items[num].nodes.length;
          } else {
            num5 = (_s.list.businessUnit.items[num].nodes.length) - 1;
          }

          _s.list.businessUnit.items[num].nodes[num5].nodes.push({
            text: ItemValdelv3.abbreviation,
            value: ItemValdelv3.organization_Code,
            tags: [ItemIndexdelv3],
            nodes: []
          });

          TREE_DATA[num].children[num5].children.push({
            name: ItemValdelv3.abbreviation,
            value: ItemValdelv3.organization_Code,
            children: [],
            index: ++count
          });

          mBusinessUnit_VI_assetLevelforEach(ItemValdelv3, num, num5);
        }
      });
    }

    function mBusinessUnit_VI_assetLevelforEach(ItemValdelv3: any, num: number, num5: any) {
      ItemValdelv3.asset_Level.forEach(function (ItemValasseslv4, ItemIndexasseslv4) {

        if (ItemValasseslv4.coInfo.length != 0) {
          //console.log(_s.list.businessUnit.items[num].nodes[(_s.list.businessUnit.items[num].nodes.length) - 1].nodes.length);
          let num4;

          if (_s.list.businessUnit.items[num].nodes[(_s.list.businessUnit.items[num].nodes.length) - 1].nodes.length === 0) {
            num4 = _s.list.businessUnit.items[num].nodes[(_s.list.businessUnit.items[num].nodes.length) - 1].nodes.length;
          } else {
            num4 = (_s.list.businessUnit.items[num].nodes[(_s.list.businessUnit.items[num].nodes.length) - 1].nodes.length) - 1;
          }

          _s.list.businessUnit.items[num].nodes[num5].nodes[num4].nodes.push({
            text: ItemValasseslv4.abbreviation,
            value: ItemValasseslv4.organization_Code,
            tags: [ItemIndexasseslv4],
            nodes: []
          });

          TREE_DATA[num].children[num5].children[num4].children.push({
            name: ItemValasseslv4.abbreviation,
            value: ItemValasseslv4.organization_Code,
            children: [],
            index: ++count
          });
        }
      });
    }
  }

  private checkYear(_s: this) {
    let d = new Date();
    let cur_year = d.getFullYear();

    if (cur_year === parseInt(_s.list.field.riskprofileYear)) {
      if (_s.list.field.riskprofileQuarter.includes("WPB") === false) {
        this.setItem_Quarter1(_s);
      } else {
        this.setItem_Quarter2(_s);
      }
    }
    else {
      if (_s.list.field.riskprofileQuarter.includes("WPB") === false) {
        this.setItem_Quarter1(_s);
      } else {
        _s.list.field.riskprofileQuartervalue = _s.list.riskprofile.quarter[3].value;
      }
    }
  }

  private setItem_Quarter1(_s: this) {
    for (let item_quarter of _s.list.riskprofile.quarter) {
      if (_s.list.field.riskprofileQuarter == item_quarter.text) {
        _s.list.field.riskprofileQuartervalue = item_quarter.value;
        break;
      }
    }
  }
  private setItem_Quarter2(_s: this) {
    for (let item_quarter of _s.list.riskprofile.quarter) {
      if (_s.tempCurrentQuarterID == item_quarter.text) {
        _s.list.field.riskprofileQuartervalue = item_quarter.value;
        break;
      }
    }
  }

  clickBusinessUnit(value, indexid, name) {
    const _s = this;
    _s.SelectBUName = name;
    sessionStorage.setItem("SelectBUName", _s.SelectBUName);
    _s.loading2 = (_s.loading === true) ? false : true;
    if(value){
      if ( sessionStorage.getItem('PageBack') === '') {
          _s.list.field.businessUnit = value;
        }
    }
   
    let d = new Date();
    let cur_year = d.getFullYear();
    let tempQuarterID = null, tempWPBID = null, tempQuarterMaster = null

    if (cur_year === parseInt(_s.list.field.riskprofileYear)) {
      ({ tempQuarterID, tempQuarterMaster, tempWPBID } = this.clickBusinessUnit_II_EqualCur_year(_s, tempQuarterID, tempQuarterMaster, tempWPBID));
    }
    else {
      ({ tempQuarterID, tempQuarterMaster, tempWPBID } = this.clickBusinessUnit_II_NonEqualCur_year(_s, tempQuarterID, tempQuarterMaster, tempWPBID));
    }

    let tempObj = {
      QuarterID: tempQuarterID,
      BusinessCode: _s.list.field.businessUnit,
      Year: _s.list.field.riskprofileYear,
      TypeMitigate: _s.list.field.Mitigate,
      WPBID: tempWPBID,
      QuaterMaster: tempQuarterMaster
    }

    let tempObj1 = {
      QuarterID: tempQuarterMaster,
      BusinessCode: _s.list.field.businessUnit
    }
    //console.log(tempObj1);
    //console.log(tempObj);
    sessionStorage.setItem('PageBack', '')

    _s.mserve.getImpact2(tempObj1).then((response) => {
      if (response.data.status) {
        this.clickBusinessUnit_II_Response_status(response, _s);
      } else {
        alert(response.data.errorMessage);
        //console.log('getImpact : false', response)
      }
    }).catch((e) => { this.authserviceToken.CheckTokenExpire(e.response.status) })

    _s.mserve.getRiskitem(tempObj).then((response) => {
      if (response.data.status) {
        this.clickBusinessUnit_II_getRiskitem_Response_status(response, _s);
      } else {
        alert(response.data.errorMessage);
        //console.log('getRiskitem : false', response)
      }
    }).catch((e) => { 
      if(e.response){
        this.authserviceToken.CheckTokenExpire(e.response.status) 
      }else{
        //console.log(e);
      }
      
    
    })
  }

  private clickBusinessUnit_II_getRiskitem_Response_status(response, _s: this) {
    //console.log('getRiskitem : true', response);
    _s.list.risk.riskitems = response.data.body;
    this.clickBusinessUnit_III_risktableforEach(_s);
    if (_s.list.risk.impact.length > 0) {
      if (this.list.field.Mitigate == '1') {
        this.clickBusinessUnit_III_riskitemsforEach(_s, clickBusinessUnit_III_Impact_Category5, this.clickBusinessUnit_III_Impact_Category8);
      }
      else {
        _s.list.risk.riskitems.forEach(function (ItemVal, ItemIndex) {
          //console.log('y :' + (5 - ItemVal.Y_Impact_Mitigate) + '-- x :' + parseInt(ItemVal.X_Likelihood_Mitigate));
          if (ItemVal.Impact_Category == '1') {
            clickBusinessUnit_III_Chkequal1(ItemVal, ItemIndex, _s);
          }
          else if (ItemVal.Impact_Category != '1') {
            clickBusinessUnit_III_ChkNonequal1(ItemVal, ItemIndex, _s);
          }
        });

          function clickBusinessUnit_III_ChkNonequal1(ItemVal: any, ItemIndex: number, _s: any) {
          if ((ItemVal.Y_Impact_Mitigate != null && ItemVal.Y_Impact_Mitigate != 0) && ItemVal.X_Likelihood_Mitigate != null) {
            clickBusinessUnit_III_Impact_Category2(ItemVal, ItemIndex, _s);
          }
          else if ((ItemVal.Y_Impact_Mitigate == null || ItemVal.Y_Impact_Mitigate == 0) && ItemVal.X_Likelihood_Mitigate != null) {
            clickBusinessUnit_III_Impact_Category6(ItemVal, ItemIndex, _s);
          }
          else if ((ItemVal.Y_Impact_Mitigate != null && ItemVal.Y_Impact_Mitigate != 0) && ItemVal.X_Likelihood_Mitigate == null) {
            clickBusinessUnit_III_Impact_Category7(ItemVal, ItemIndex, _s);
          }
          else if ((ItemVal.Y_Impact_Mitigate == null || ItemVal.Y_Impact_Mitigate == 0) && ItemVal.X_Likelihood_Mitigate == null) {
            const bgColor = _s.list.risk.risktable[(5 - ItemVal.Y_Impact)].col[0].item[0].bgcolor;
            const Color = _s.list.risk.risktable[(5 - ItemVal.Y_Impact)].col[0].item[0].color;
            _s.list.risk.risktable[(5 - ItemVal.Y_Impact)].col[parseInt(ItemVal.X_Likelihood == null ? 0 : ItemVal.X_Likelihood)].item.push({
              index: (ItemIndex + 1).toString(),
              title: _s.list.risk.riskitems[ItemIndex].Risk_Name,
              bgcolor: bgColor,
              color: Color,
              Risk_Id: _s.list.risk.riskitems[ItemIndex].Risk_Id,
              BU: _s.list.risk.riskitems[ItemIndex].BU
            });
          }
        }
      }
    }
    
    function clickBusinessUnit_III_Chkequal1(ItemVal: any, ItemIndex: number, _s: any) {
      if ((ItemVal.Y_Impact_Mitigate != null && ItemVal.Y_Impact_Mitigate != 0) && ItemVal.X_Likelihood_Mitigate != null) {
        clickBusinessUnit_III_Impact_Category1(ItemVal, ItemIndex, _s);
      }
      else if ((ItemVal.Y_Impact_Mitigate == null || ItemVal.Y_Impact_Mitigate == 0) && ItemVal.X_Likelihood_Mitigate != null) {
        clickBusinessUnit_III_Impact_Category3(ItemVal, ItemIndex, _s);
      }
      else if ((ItemVal.Y_Impact_Mitigate != null && ItemVal.Y_Impact_Mitigate != 0) && ItemVal.X_Likelihood_Mitigate == null) {
        clickBusinessUnit_III_Impact_Category4(ItemVal, ItemIndex, _s);
      }
      else if ((ItemVal.Y_Impact_Mitigate == null || ItemVal.Y_Impact_Mitigate == 0) && ItemVal.X_Likelihood_Mitigate == null) {
        clickBusinessUnit_III_Impact_Category5(ItemVal, ItemIndex, _s);
      }
      function clickBusinessUnit_III_Impact_Category1(ItemVal: any, ItemIndex: number, _s: any) {
        const bgColor = _s.list.risk.risktable[(5 - ItemVal.Y_Impact_Mitigate)].col[0].item[(ItemVal.Impact_NPT_EMV == '1') ? 4 : 2].bgcolor;
        const Color = _s.list.risk.risktable[(5 - ItemVal.Y_Impact_Mitigate)].col[0].item[(ItemVal.Impact_NPT_EMV == '1') ? 4 : 2].color;
    
        _s.list.risk.risktable[(5 - ItemVal.Y_Impact_Mitigate)].col[parseInt(ItemVal.X_Likelihood_Mitigate)].item.push({
          index: (ItemIndex + 1).toString(),
    
          title: _s.list.risk.riskitems[ItemIndex].Risk_Name,
          bgcolor: bgColor,
          color: Color,
          Risk_Id: _s.list.risk.riskitems[ItemIndex].Risk_Id,
          BU: _s.list.risk.riskitems[ItemIndex].BU
        });
      }
      function clickBusinessUnit_III_Impact_Category4(ItemVal: any, ItemIndex: number, _s: any) {
        const bgColor = _s.list.risk.risktable[(5 - ItemVal.Y_Impact_Mitigate)].col[0].item[(ItemVal.Impact_NPT_EMV == '1') ? 4 : 2].bgcolor;
        const Color = _s.list.risk.risktable[(5 - ItemVal.Y_Impact_Mitigate)].col[0].item[(ItemVal.Impact_NPT_EMV == '1') ? 4 : 2].color;
        _s.list.risk.risktable[(5 - ItemVal.Y_Impact_Mitigate)].col[parseInt(ItemVal.X_Likelihood == null ? 0 : ItemVal.X_Likelihood)].item.push({
          index: (ItemIndex + 1).toString(),
          title: _s.list.risk.riskitems[ItemIndex].Risk_Name,
          bgcolor: bgColor,
          color: Color,
          Risk_Id: _s.list.risk.riskitems[ItemIndex].Risk_Id,
          BU: _s.list.risk.riskitems[ItemIndex].BU
        });
      }
    
      function  clickBusinessUnit_III_Impact_Category3(ItemVal: any, ItemIndex: number, _s: any) {
        const bgColor = _s.list.risk.risktable[(5 - ItemVal.Y_Impact)].col[0].item[(ItemVal.Impact_NPT_EMV == '1') ? 4 : 2].bgcolor;
        const Color = _s.list.risk.risktable[(5 - ItemVal.Y_Impact)].col[0].item[(ItemVal.Impact_NPT_EMV == '1') ? 4 : 2].color;
        _s.list.risk.risktable[(5 - ItemVal.Y_Impact)].col[parseInt(ItemVal.X_Likelihood_Mitigate)].item.push({
          index: (ItemIndex + 1).toString(),
          title: _s.list.risk.riskitems[ItemIndex].Risk_Name,
          bgcolor: bgColor,
          color: Color,
          Risk_Id: _s.list.risk.riskitems[ItemIndex].Risk_Id,
          BU: _s.list.risk.riskitems[ItemIndex].BU
        });
      }
  
     function  clickBusinessUnit_III_Impact_Category5(ItemVal: any, ItemIndex: number, _s: any) {
        const bgColor = _s.list.risk.risktable[(5 - ItemVal.Y_Impact)].col[0].item[(ItemVal.Impact_NPT_EMV == '1') ? 4 : 2].bgcolor;
        const Color = _s.list.risk.risktable[(5 - ItemVal.Y_Impact)].col[0].item[(ItemVal.Impact_NPT_EMV == '1') ? 4 : 2].color;
        _s.list.risk.risktable[(5 - ItemVal.Y_Impact)].col[parseInt(ItemVal.X_Likelihood == null ? 0 : ItemVal.X_Likelihood)].item.push({
          index: (ItemIndex + 1).toString(),
          title: _s.list.risk.riskitems[ItemIndex].Risk_Name,
          bgcolor: bgColor,
          color: Color,
          Risk_Id: _s.list.risk.riskitems[ItemIndex].Risk_Id,
          BU: _s.list.risk.riskitems[ItemIndex].BU
        });
      }
  
  
  
    }

    function clickBusinessUnit_III_Impact_Category2(ItemVal: any, ItemIndex: number, _s: any) {
      const bgColor = _s.list.risk.risktable[(5 - ItemVal.Y_Impact_Mitigate)].col[0].item[0].bgcolor;
      const Color = _s.list.risk.risktable[(5 - ItemVal.Y_Impact_Mitigate)].col[0].item[0].color;
      _s.list.risk.risktable[(5 - ItemVal.Y_Impact_Mitigate)].col[parseInt(ItemVal.X_Likelihood_Mitigate)].item.push({
        index: (ItemIndex + 1).toString(),
        title: _s.list.risk.riskitems[ItemIndex].Risk_Name,
        bgcolor: bgColor,
        color: Color,
        Risk_Id: _s.list.risk.riskitems[ItemIndex].Risk_Id,
        BU: _s.list.risk.riskitems[ItemIndex].BU
      });
    }

    function clickBusinessUnit_III_Impact_Category7(ItemVal: any, ItemIndex: number, _s: any) {
      const bgColor = _s.list.risk.risktable[(5 - ItemVal.Y_Impact_Mitigate)].col[0].item[0].bgcolor;
      const Color = _s.list.risk.risktable[(5 - ItemVal.Y_Impact_Mitigate)].col[0].item[0].color;
      _s.list.risk.risktable[(5 - ItemVal.Y_Impact_Mitigate)].col[parseInt(ItemVal.X_Likelihood == null ? 0 : ItemVal.X_Likelihood)].item.push({
        index: (ItemIndex + 1).toString(),
        title: _s.list.risk.riskitems[ItemIndex].Risk_Name,
        bgcolor: bgColor,
        color: Color,
        Risk_Id: _s.list.risk.riskitems[ItemIndex].Risk_Id,
        BU: _s.list.risk.riskitems[ItemIndex].BU
      });
    }

    function clickBusinessUnit_III_Impact_Category6(ItemVal: any, ItemIndex: number, _s: any) {
      const bgColor = _s.list.risk.risktable[(5 - ItemVal.Y_Impact)].col[0].item[0].bgcolor;
      const Color = _s.list.risk.risktable[(5 - ItemVal.Y_Impact)].col[0].item[0].color;
      _s.list.risk.risktable[(5 - ItemVal.Y_Impact)].col[parseInt(ItemVal.X_Likelihood_Mitigate)].item.push({
        index: (ItemIndex + 1).toString(),
        title: _s.list.risk.riskitems[ItemIndex].Risk_Name,
        bgcolor: bgColor,
        color: Color,
        Risk_Id: _s.list.risk.riskitems[ItemIndex].Risk_Id,
        BU: _s.list.risk.riskitems[ItemIndex].BU
      });
    }

    _s.loading = false;
    _s.loading2 = false;

    function clickBusinessUnit_III_Impact_Category5(ItemVal: any, ItemIndex: number, _s: any) {
      const bgColor = _s.list.risk.risktable[(5 - ItemVal.Y_Impact)].col[0].item[(ItemVal.Impact_NPT_EMV == '1') ? 4 : 2].bgcolor;
      const Color = _s.list.risk.risktable[(5 - ItemVal.Y_Impact)].col[0].item[(ItemVal.Impact_NPT_EMV == '1') ? 4 : 2].color;
      _s.list.risk.risktable[(5 - ItemVal.Y_Impact)].col[parseInt(ItemVal.X_Likelihood == null ? 0 : ItemVal.X_Likelihood)].item.push({
        index: (ItemIndex + 1).toString(),
        title: _s.list.risk.riskitems[ItemIndex].Risk_Name,
        bgcolor: bgColor,
        color: Color,
        Risk_Id: _s.list.risk.riskitems[ItemIndex].Risk_Id,
        BU: _s.list.risk.riskitems[ItemIndex].BU
      });
    }

  }


 

  


  clickBusinessUnit_III_Impact_Category8(ItemVal: any, ItemIndex: number, _s: any) {
    const bgColor = _s.list.risk.risktable[(5 - ItemVal.Y_Impact)].col[0].item[0].bgcolor;
    const Color = _s.list.risk.risktable[(5 - ItemVal.Y_Impact)].col[0].item[0].color;
    _s.list.risk.risktable[(5 - ItemVal.Y_Impact)].col[parseInt(ItemVal.X_Likelihood == null ? 0 : ItemVal.X_Likelihood)].item.push({
      index: (ItemIndex + 1).toString(),
      title: _s.list.risk.riskitems[ItemIndex].Risk_Name,
      bgcolor: bgColor,
      color: Color,
      Risk_Id: _s.list.risk.riskitems[ItemIndex].Risk_Id,
      BU: _s.list.risk.riskitems[ItemIndex].BU
    });
  }


 

  private clickBusinessUnit_III_riskitemsforEach(_s: this, clickBusinessUnit_III_Impact_Category5: (ItemVal: any, ItemIndex: number, _s: any) => void, clickBusinessUnit_III_Impact_Category8: (ItemVal: any, ItemIndex: number, _s: any) => void) {
    _s.list.risk.riskitems.forEach(function (ItemVal, ItemIndex) {
      //console.log('y :' + (5 - ItemVal.Y_Impact) + '-- x :' + parseInt(ItemVal.X_Likelihood));
      if (ItemVal.Impact_Category == '1') {
      clickBusinessUnit_III_Impact_Category5(ItemVal, ItemIndex, _s);
      }
      else {
        const bgColor = _s.list.risk.risktable[(5 - ItemVal.Y_Impact)].col[0].item[0].bgcolor;
        const Color = _s.list.risk.risktable[(5 - ItemVal.Y_Impact)].col[0].item[0].color;
        _s.list.risk.risktable[(5 - ItemVal.Y_Impact)].col[parseInt(ItemVal.X_Likelihood == null ? 0 : ItemVal.X_Likelihood)].item.push({
          index: (ItemIndex + 1).toString(),
          title: _s.list.risk.riskitems[ItemIndex].Risk_Name,
          bgcolor: bgColor,
          color: Color,
          Risk_Id: _s.list.risk.riskitems[ItemIndex].Risk_Id,
          BU: _s.list.risk.riskitems[ItemIndex].BU
        });
      }

     function clickBusinessUnit_III_Impact_Category5(ItemVal: any, ItemIndex: number, _s: any) {
        const bgColor = _s.list.risk.risktable[(5 - ItemVal.Y_Impact)].col[0].item[(ItemVal.Impact_NPT_EMV == '1') ? 4 : 2].bgcolor;
        const Color = _s.list.risk.risktable[(5 - ItemVal.Y_Impact)].col[0].item[(ItemVal.Impact_NPT_EMV == '1') ? 4 : 2].color;
        _s.list.risk.risktable[(5 - ItemVal.Y_Impact)].col[parseInt(ItemVal.X_Likelihood == null ? 0 : ItemVal.X_Likelihood)].item.push({
          index: (ItemIndex + 1).toString(),
          title: _s.list.risk.riskitems[ItemIndex].Risk_Name,
          bgcolor: bgColor,
          color: Color,
          Risk_Id: _s.list.risk.riskitems[ItemIndex].Risk_Id,
          BU: _s.list.risk.riskitems[ItemIndex].BU
        });
      }

    });
  }

  private clickBusinessUnit_III_risktableforEach(_s: this) {
    _s.list.risk.risktable.forEach(function (ItemVal, ItemIndex) {
      ItemVal.col.forEach(function (ItemVal2, ItemIndex2) {
        if (ItemIndex !== 5 && ItemIndex2 !== 0) {
          _s.list.risk.risktable[ItemIndex].col[ItemIndex2].item = [];
        }
      });
    });
  }

  private clickBusinessUnit_II_Response_status(response, _s: this) {
    //console.log('getImpact : true', response);
    _s.list.risk.impact = response.data.body;

    const setValue = function (item, para, index) {
      let temp = '';
      if (index !== 4) {
        if (item[para + '_t'] === null) temp = '=< ' + _s.numberWithCommas(item[para + '_f']);
      } else {
        if (item[para + '_t'] === null) temp = '> ' + _s.numberWithCommas(item[para + '_f']);
      }
      if (item[para + '_f'] === null) temp = _s.numberWithCommas(item[para + '_t']) + ' >';
      if (item[para + '_f'] !== null && item[para + '_t'] !== null) temp = '> ' + _s.numberWithCommas(item[para + '_f']) + ' - ' + _s.numberWithCommas(item[para + '_t']);
      return temp;
    };
    _s.list.risk.impact.forEach(function (ItemVal, ItemIndex) {
      _s.list.risk.risktable[((_s.list.risk.impact.length - 1) - ItemIndex)].col[0].item[0].title = ItemVal.ImpactCateItemName;
      _s.list.risk.risktable[((_s.list.risk.impact.length - 1) - ItemIndex)].col[0].item[2].title = setValue(ItemVal, 'NPV', ItemIndex);
      _s.list.risk.risktable[((_s.list.risk.impact.length - 1) - ItemIndex)].col[0].item[4].title = setValue(ItemVal, 'NI', ItemIndex);
    });
  }

  private clickBusinessUnit_II_NonEqualCur_year(_s: this, tempQuarterID: any, tempQuarterMaster: any, tempWPBID: any) {
    if (_s.list.field.riskprofileQuarter.includes("WPB") === false) {
      ({ tempQuarterID, tempQuarterMaster } = this.clickBusinessUnit_III_foritem_quarter_1(_s, tempQuarterID, tempQuarterMaster));
    } else {
      tempWPBID = this.clickBusinessUnit_III_foritem_quarter_2(_s, tempWPBID);
      tempQuarterMaster = _s.list.riskprofile.quarter[3].value;
    }
    return { tempQuarterID, tempQuarterMaster, tempWPBID };
  }

  private clickBusinessUnit_III_foritem_quarter_2(_s: this, tmpWPBID: any) {
    let tempWPBID = tmpWPBID;
    for (let item_quarter of this.list.riskprofile.quarter) {
      if (_s.list.field.riskprofileQuarter === item_quarter.text) {
        tempWPBID = item_quarter.value;
        break;
      }
    }
    return tempWPBID;
  }

  private clickBusinessUnit_III_foritem_quarter_1(_s: this, tmpQuarterID: any, tmpQuarterMaster: any) {
    let tempQuarterMaster = tmpQuarterMaster;
    let tempQuarterID = tmpQuarterID;
    for (let item_quarter of this.list.riskprofile.quarter) {
      if (_s.list.field.riskprofileQuarter === item_quarter.text) {
        tempQuarterID = item_quarter.value;
        tempQuarterMaster = item_quarter.value;
        break;
      }
    }
    return { tempQuarterID, tempQuarterMaster };
  }

  private clickBusinessUnit_II_EqualCur_year(_s: this, tempQuarterID: any, tempQuarterMaster: any, tempWPBID: any) {
    if (_s.list.field.riskprofileQuarter.includes("WPB") === false) {
      ({ tempQuarterID, tempQuarterMaster } = this.clickBusinessUnit_III_foritem_quarter_3(_s, tempQuarterID, tempQuarterMaster));
    } else {
      tempWPBID = this.clickBusinessUnit_III_foritem_quarter_2(_s, tempWPBID);
      tempQuarterMaster = this.clickBusinessUnit_III_foritem_quarter_5(_s, tempQuarterMaster);
    }
    return { tempQuarterID, tempQuarterMaster, tempWPBID };
  }

  private clickBusinessUnit_III_foritem_quarter_5(_s: this, tmpQuarterMaster: any) {
    let tempQuarterMaster = tmpQuarterMaster;
    for (let item_quarter of this.list.riskprofile.quarter) {
      if (_s.tempCurrentQuarterID === item_quarter.text) {
        tempQuarterMaster = item_quarter.value;
        break;
      }
    }
    return tempQuarterMaster;
  }

  private clickBusinessUnit_III_foritem_quarter_3(_s: this, tmpQuarterID: any, tmpQuarterMaster: any) {
    let tempQuarterID = tmpQuarterID;
    let tempQuarterMaster = tmpQuarterMaster;
    for (let item_quarter of this.list.riskprofile.quarter) {
      if (_s.list.field.riskprofileQuarter === item_quarter.text) {
        tempQuarterID = item_quarter.value;
        tempQuarterMaster = item_quarter.value;
        break;
      }
    }
    return { tempQuarterID, tempQuarterMaster };
  }

  func_ReveiewPage(riskid) {
    const _s = this;
    let tmpObjectFilter = [];
    tmpObjectFilter.push({
      QuarterID: _s.list.field.riskprofileQuarter,
      BusinessCode: _s.list.field.businessUnit,
      Year: _s.list.field.riskprofileYear,
      TypeMitigate: _s.list.field.Mitigate,
      index: _s.tempindex,
      SelectBUName: _s.SelectBUName
    });
    sessionStorage.setItem('FilterRiskMap', JSON.stringify(tmpObjectFilter))

    sessionStorage.setItem("RiskID", riskid);
    sessionStorage.setItem("RoleReview", 'Co');
    this.router.navigate(['/ReviewPage', 'Reviewfromdashboard']);
  }
  
  numberWithCommas(x) {
    // const roundedNumber: number = Math.round(x);
    // const formattedNumber: string = roundedNumber.toLocaleString('en-US');
    // return formattedNumber;
    if (x >= 1000) {
      return x.toLocaleString('en-US');
    } else {
      return x;
    }
  }

  loadDDL() {
    const _s = this;
    let d = new Date();
    let currentYear = d.getFullYear();
    let currentMonth = d.getMonth() + 1;
    _s.MServ.Master_DDL({ Module: "QuarterDD", TextSearch1: currentYear }).then((response) => {
      _s.QuarterDD = response.data.body;
      //console.log("QuarterDD : ", _s.QuarterDD)
      this.func_calQuarter(currentMonth);
    })
  }
  func_calQuarter(month) {
    const _s = this;
    _s.list.field.riskprofileQuarter = ''
    if (month >= 1 && month <= 3) {
      _s.list.field.riskprofileQuarter = this.QuarterDD[0].text
      _s.list.field.riskprofileQuartervalue = this.QuarterDD[0].value
    } else if (month >= 4 && month <= 6) {
      _s.list.field.riskprofileQuarter = this.QuarterDD[1].value
      _s.list.field.riskprofileQuartervalue = this.QuarterDD[1].value
    } else if (month >= 7 && month <= 9) {
      _s.list.field.riskprofileQuarter = this.QuarterDD[2].text
      _s.list.field.riskprofileQuartervalue = this.QuarterDD[2].value
    } else if (month >= 10 && month <= 12) {
      _s.list.field.riskprofileQuarter = this.QuarterDD[3].text
      _s.list.field.riskprofileQuartervalue = this.QuarterDD[3].value
    }
    this.tempCurrentQuarterID = _s.list.field.riskprofileQuarter
    //console.log('_s.list.field.riskprofileQuarter', _s.list.field.riskprofileQuarter)
  }
  func_GETDATA_RiskRating() {
    const _s = this;
    let formdata = {
      SessionEmpID: 1,
      Module: "getlist",
      body: {
        RiskRatingID: "",
        QuarterID: _s.list.field.riskprofileQuartervalue,
        RiskRatingCode: "Test0002",
        Likelihood: "2",
        Impact: "3",
        RiskRating: "",
        EscalationLevel: "1",
        DelFlag: "0",
        LikelihoodAndImpact: ""
      }
    }
    this.MServ.GetRiskRating(formdata).then((response) => {
      //console.log("RiskRating Data :", response.data.body)
      if (response.data.status) {
        this.tableriskrating = response.data.body;
        this.func_calExculationLevel();
      } else {
        //console.log('false', response)
        alert(response.data.errorMessage);
      }
    }).catch((e) => {
            if (e.response) {
                this.authserviceToken.CheckTokenExpire(e.response.status)
            } else {
                //console.log(e)
            }
        });
  }
  func_calExculationLevel() {
    const _s = this;
    let tempMedEsc = []
    _s.tableriskrating.forEach(function (ItemVal, ItemIndex) {
      if (ItemVal.riskRating === "Medium" && ItemVal.escalationLevel === "1") {
        tempMedEsc.push(ItemVal)
      }
    });
    //console.log('tempMedium : ', tempMedEsc)

    //console.log('_s.list.risk.risktable : ', _s.list.risk.risktable)


    this.setTempMedEsc(tempMedEsc, _s);

    //console.log('_s.list.risk.risktable with esclevel : ', _s.list.risk.risktable)
    
    this.checkSessionStorage();
  }

  private setTempMedEsc(tempMedEsc: any[], _s: this) {
    for (let item_tempMedEsc of tempMedEsc) {
      if (item_tempMedEsc.impact === "Minor") {
        this.setTempMedEsc_II_Chk_impact_Minor(item_tempMedEsc, _s);
      }
      else if (item_tempMedEsc.impact === "Moderate") {
        this.setTempMedEsc_II_Chk_impact_Moderate(item_tempMedEsc, _s);
      }
      else if (item_tempMedEsc.impact === "Significant") {
        this.setTempMedEsc_II_Chk_impact_Significant(item_tempMedEsc, _s);
      }
      else if (item_tempMedEsc.impact === "Serious") {
        this.setTempMedEsc_II_Chk_impact_Serious(item_tempMedEsc, _s);
      }
      else if (item_tempMedEsc.impact === "Critical") {
        this.setTempMedEsc_II_Chk_impact_Critical(item_tempMedEsc, _s);
      }
    }
  }

  private setTempMedEsc_II_Chk_impact_Critical(item_tempMedEsc: any, _s: this) {
    if (item_tempMedEsc.likelihood === "Rare") { _s.list.risk.risktable[0].col[1].class = "Escalation"; }
    else if (item_tempMedEsc.likelihood === "Unlikely") { _s.list.risk.risktable[0].col[2].class = "Escalation"; }
  }

  private setTempMedEsc_II_Chk_impact_Serious(item_tempMedEsc: any, _s: this) {
    if (item_tempMedEsc.likelihood === "Rare") { _s.list.risk.risktable[1].col[1].class = "Escalation"; }
    else if (item_tempMedEsc.likelihood === "Unlikely") { _s.list.risk.risktable[1].col[2].class = "Escalation"; }
    else if (item_tempMedEsc.likelihood === "Possible") { _s.list.risk.risktable[1].col[3].class = "Escalation"; }
  }

  private setTempMedEsc_II_Chk_impact_Significant(item_tempMedEsc: any, _s: this) {
    if (item_tempMedEsc.likelihood === "Unlikely") { _s.list.risk.risktable[2].col[2].class = "Escalation"; }
    else if (item_tempMedEsc.likelihood === "Possible") { _s.list.risk.risktable[2].col[3].class = "Escalation"; }
    else if (item_tempMedEsc.likelihood === "Likely") { _s.list.risk.risktable[2].col[4].class = "Escalation"; }
  }

  private setTempMedEsc_II_Chk_impact_Moderate(item_tempMedEsc: any, _s: this) {
    if (item_tempMedEsc.likelihood === "Possible") { _s.list.risk.risktable[3].col[3].class = "Escalation"; }
    else if (item_tempMedEsc.likelihood === "Likely") { _s.list.risk.risktable[3].col[4].class = "Escalation"; }
    else if (item_tempMedEsc.likelihood === "Almost Certain") { _s.list.risk.risktable[3].col[5].class = "Escalation"; }
  }

  private setTempMedEsc_II_Chk_impact_Minor(item_tempMedEsc: any, _s: this) {
    if (item_tempMedEsc.likelihood === "Likely") { _s.list.risk.risktable[4].col[4].class = "Escalation"; }
    else if (item_tempMedEsc.likelihood === "Almost Certain") { _s.list.risk.risktable[4].col[5].class = "Escalation"; }
  }

  ngAfterViewInit(): void {
    const _s = this
    scroll(0, 390)
  }

  checkSessionStorage() {
    const _s = this;
    if (sessionStorage.loginmail != ""){
      _s.mBusinessUnit();
    }
    else{
      setTimeout(this.checkSessionStorage, 500);
    }
  }
}
