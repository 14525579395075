import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import DOMPurify from 'dompurify';

@Directive({
  selector: '[safehtml]',
})
export class SafeHtmlDirective {
  private readonly CONFIG = {
    ADD_ATTR: [],
    FORBID_ATTR: []
  };

  constructor(private renderer: Renderer2 ,private el: ElementRef) { }

  @Input() set safehtml(html: string) {
    if (!html) {
      html = '';
    };
    const cleanHtml = DOMPurify.sanitize(html, this.CONFIG);
    this.renderer.setProperty(this.el.nativeElement, 'innerHTML', cleanHtml);
  }
}
