<div class="container-fluid appform my-3">
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">
            <div class="col-12 col-md-12 col-lg-12 col-xl-12 pb-5 mt-5 mb-5">
                <div class="px-0 pb-2">
                    <div class="card col-12 mt-4 mx-0 p-3">

                        <div class="row">
                            <div class="coordinator col-2 ml-3 mb-3 rounded">
                                <div class="col-0 pt-2">
                                    <em class="fas fa-cog"></em>&nbsp;&nbsp;E-mail Maintain
                                </div>
                            </div>

                            <div class="col-3 ml-3 mb-3">
                                <select [(ngModel)]="selectedRisk" (change)="changeType($event.target.value)" id="risk" name="risk" style="height: 40px;" class="form-control" >
                                    <option value="" disabled>Select Risks</option>
                                    <option value="1">Process Risks/Event Risks</option>
                                    <option value="2">Strategic Risks</option>
                                </select>
                            </div>

                            <div class="col-2 ml-3 mb-3 rounded">
                                <!-- <div>
                                    <button type="button" class="btn" 
                                    style="height: 40px; width: 100px; background-color: #033153; color: white;"
                                    (click)="func_GetData()"
                                    >
                                        Select
                                    </button>
                                </div> -->
                            </div>
                        </div>

                        <div class="col-12 mt-5 px-0 table-responsive">
                            <table class="table-bordered" style="width:100%;">
                                <caption style="display: none;" >This is a custom table</caption>
                                <thead style="text-align:center; background-color:#033153; color:white;">
                                    <tr>
                                        <th>E-Mail ID</th>
                                        <th>Process</th>
                                        <th>Subject</th>
                                        <th>Edit</th>
                                    </tr>
                                </thead>
                                <tbody style="background-color:white;">
                                    <tr *ngFor="let item of DataEmail index as index1">
                                        <td style="text-align: center;">M-R{{ item.Status }}</td>
                                        <td>{{ item.Description }}</td>
                                        <td>{{ item.Subject }}</td>
                                        <td style="text-align: center;">
                                            <button type="button" class="btn" (click)="func_editEmail(item)">
                                                <em class="far fa-edit" style="color: orange;font-size:18px;"></em>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>

                <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" id="modaleditemail">
                    <div class="modal-dialog modal-xl">
                        <div class="modal-content">
                            <div class="col-12 col-md-12 col-lg-12 col-xl-12 p-2">
                                <div class="container col-12 col-md-12 col-lg-12 col-xl-12 pb-0 mt-0 mb-0 ">
                                    
                                    <div class="col-12 rounded modalEmail">
                                        <div class="col-0 pt-2">
                                            <em class="fas fa-bars icon"></em>&nbsp;&nbsp;E-mail Maintain
                                        </div>
                                    </div>

                                    <div class="card mt-4">
                                        <div class="row col-12 col-xl-12 mb-4 px-0 mx-0">

                                            <div class="col-12 mt-2">
                                                <strong class="formtext mr-4">E-Mail ID</strong>
                                                <input type="text" [(ngModel)]="TemplateEmail.emailID" disabled>
                                            </div>

                                            <div class="col-12 mt-4">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <strong class="formtext">Subject</strong>
                                                    </div>
                                                    <div class="col-12 pt-2">
                                                        <input type="text" class="form-control" [(ngModel)]="TemplateEmail.subject">
                                                    </div>
                                                </div>
                                            </div>

                                              <div class="col-12 mt-4">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <strong class="formtext">Body</strong>
                                                    </div>  

                                                    <div *ngFor='let data of Alltable' class="col-12 mb-4">
                                                        <div class="col-12 mt-3 mb-3">
                                                            <div class="col-12">
                                                                <div class="col-12 mb-2 px-0">
                                                                    <strong class="formtext">{{data.impactCateItemName}}</strong>
                                                                </div>
                                                            </div>
                                                            <div class="col-12">
                                                                <div class="col-12 mb-2 px-0">
                                                                    <div [formGroup]="data.form1" style="background-color:white">
                                                                        <div [ngxSummernote]="config" formControlName="html">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 mb-2 px-0">
                                                                    <div [formGroup]="data.form2" style="background-color:white">
                                                                        <div [ngxSummernote]="config" formControlName="html">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 mb-2 px-0">
                                                                    <div [formGroup]="data.form3" style="background-color:white">
                                                                        <div [ngxSummernote]="config" formControlName="html">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-12 mt-4">
                                                <strong style="font-size:16px;color:red;">***Remark ห้ามแก้ไข ข้อมูลจุดที่เป็นข้อความสีแดงและสีน้ำเงิน</strong>
                                            </div>

                                        </div>
                                    </div>

                                     <div class="col-12 mb-3" style="text-align: center;">
                                        <button type="button" class="btn mr-3" style="width:100px;background-color:#9eb0ba;color:white;" (click)="Close_Modal()">Close</button>
                                        <button type="button" class="btn" style="width:100px;background-color:#033153;color:white;" (click)="func_save()">Save</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-modal-alert [message]="popupMessageAlert" [titleSuccess]="popupTitleSuccess"></app-modal-alert>
<app-loading [loading]='loading'></app-loading>