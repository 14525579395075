<div class="container-fluid appform my-3">
    <div class="row">
      <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">

        <div class="text-center">
            <div class="spinner-grow loading" role="status" id="loader" [hidden]="loading === false">
                <span class="sr-only"></span>
            </div>
        </div>

        <div class="col-12 col-md-12 col-lg-12 col-xl-12 mb-3 px-0" id="showdisplay" [hidden]="loading === true">

            <div class="px-0 pb-2">
                
                <div class="row col-12 col-xl-12 px-0 mx-0" style="border:1.5px solid #93b8c5">
                    <div class="row col-11 col-xl-11 px-0 mx-0" style="border:1.5px solid #93b8c5">
                        <div class="col-4 col-xl-3 p-4" style="text-align: center; background: #077ab4;color: white;">
                            <h4 class="HeaderRiskname m-0">Risk Name/Risk ID<span class="text-danger">*</span></h4>
                            <h4 class="HeaderRiskname m-0">{{ Register_Data.Regis_RiskNo }}</h4>
                        </div>

                        <div class="col-9 px-0">
                            <input type="text" id="RegisRiskname" class="form-control border-0 HeaderRiskname-input" style="font-size:30px;" [(ngModel)]="Register_Data.Regis_Riskname" (blur)="func_runRegisFirst()">
                        </div>
                    </div>
                    <div class="row col-1 col-xl-1 px-0 mx-0" style="border:1.5px solid #93b8c5;">

                        <div class="row col-2 px-0"></div>
                        <div class="row col-10 px-0" style="margin-left: 16px;">
                            <div class="col-12 px-0 tablerisk-card">
                                <div class="card-body row m-0 p-1" >
                                  <div class="col-12 p-0">
                                    <table class="custom" id="test2" >
                                        <caption style="display: none;" >This is a custom table</caption>
                                        <tr>
                                            <th></th>
                                          </tr>
                                      <tr *ngFor="let item1 of  list.risk.risktable; index as index1;">
                                        <td *ngFor="let item2 of item1.col; index as index2;" class="box">
                                          <div class="content text-center" class="{{item2.class}}">
                                            <ng-container *ngFor="let item3 of item2.item; index as index3;">
                                              <span *ngIf="item2.class === 'high'">
                                                <span class="badge badge-pill badge-light txt-title" [ngStyle] = "{'background-color': item3.bgcolor, 'color': item3.color}" data-toggle="tooltip" data-placement="top"><strong>{{item3.title}}</strong></span>
                                              </span>
                                              <span *ngIf="item2.class === 'medium'">
                                                <span class="badge badge-pill badge-light txt-title" [ngStyle] = "{'background-color': item3.bgcolor, 'color': item3.color}" data-toggle="tooltip" data-placement="top"><strong>{{item3.title}}</strong></span>
                                              </span>
                                              <span *ngIf="item2.class === 'low'">
                                                <span class="badge badge-pill badge-light txt-title" [ngStyle] = "{'background-color': item3.bgcolor, 'color': item3.color}" data-toggle="tooltip" data-placement="top"><strong>{{item3.title}}</strong></span>
                                              </span>
                                              <span *ngIf="item2.class === 'Escalation'">
                                                <span class="badge badge-pill badge-light txt-title" [ngStyle] = "{'background-color': item3.bgcolor, 'color': item3.color}" data-toggle="tooltip" data-placement="top"><strong>{{item3.title}}</strong></span>
                                              </span>

                                            </ng-container>
                                          </div>
                                        </td>
                                      <tr>
                                    </table>
                                  </div>
                                </div>
                              </div>
                        </div>
                    </div>
                </div>

                <div class="card mt-4 pt-4">

                    <div class="col-12 col-xl-12 mb-4">
                        <div class="col-12 col-xl-12">
                            <h5><img  alt="" src="../../../assets/logo/Vector-Smart-Object2.gif" height="30px" width="40px">&nbsp;&nbsp;&nbsp;<span class="header-top">Risk Status</span></h5>
                        </div>
                        <div class="row col-12 col-xl-12 mt-3 mb-3 mx-0 px-3 d-flex">
                            <div class="row col-12 col-xl-3">
                                <div class="col-12 mb-2 px-0">
                                    <strong class="headerin">Status</strong>
                                    <select class="form-control mt-2" [(ngModel)]="Register_Data.Regis_Status" (change)="clearReason()">
                                        <option value="">Please Select</option>
                                            <option *ngFor="let item of StatusIden" value="{{ item.value }}" [disabled]="item.value === '3' && checkDDstatus === false">{{ item.text }}</option>
                                    </select>
                                </div>
                                <!-- <div class="col-12 px-0">
                                    <select class="form-control" [(ngModel)]="Register_Data.Regis_Status" (change)="clearReason()">
                                        <option value="">Please Select</option>
                                            <option *ngFor="let item of StatusIden" value="{{ item.value }}" [disabled]="item.value === '3' && checkDDstatus === false">{{ item.text }}</option>
                                    </select>
                                </div> -->
                            </div>
                            <div class="row col-12 col-xl-5 ml-4" *ngIf="Register_Data.Regis_Status === '4' || Register_Data.Regis_Status === '2'">
                                <div class="col-12 px-0">
                                    <strong class="headerin">Reason/Detail(s)<label style="color:red;">*</label></strong>
                                </div>
                                <div class="col-12 px-0">
                                    <!-- <input type="text" class="form-control" style="height: 50px;"> -->
                                    <textarea class="form-control" 
                                    autosize 
                                    id="CloseComment" rows="3" 
                                    [(ngModel)]="Risk_Close_Reason"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-xl-12 mb-4">
                        <div class="col-12 col-xl-12">
                            <h5><img  alt="" src="../../../assets/logo/Vector-Smart-Object2.gif" height="30px" width="40px">&nbsp;&nbsp;&nbsp;<span class="header-top">Connected to</span></h5>
                        </div>
                        <div class="row col-12 col-xl-12 mt-3 mb-3 mx-0 px-0 d-flex justify-content-around">
                            <div class="row col-12 col-xl-3">
                                <div class="col-12 mb-2 px-0">
                                    <strong class="headerin">Risk Category<label style="color:red;">*</label></strong>
                                </div>
                                <div class="col-12 px-0">
                                    <select class="form-control" [(ngModel)]="Register_Data.Regis_Category">
                                        <option value="">Please Select</option>
                                        <option *ngFor="let data of RiskCategory" value="{{ data.value }}">{{ data.text }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row col-12 col-xl-4">
                                <div class="col-12 mb-2 px-0">
                                    <strong class="headerin">Corporate Target/Objective</strong>
                                </div>
                                <div class="col-5 pl-0">
                                    <select class="form-control" [(ngModel)]="Register_Data.Regis_ObjectiveHead" (change)="func_selectObjective(Register_Data.Regis_ObjectiveHead, 'update')">
                                        <option value="">Please Select</option>
                                        <option *ngFor="let data of CorpTargetHead" value="{{ data.value }}">{{ data.text }}</option>
                                    </select>
                                </div>
                                <div class="col-7">
                                    <select class="form-control" disabled *ngIf="Register_Data.Regis_ObjectiveHead === ''"></select>
                                    <select class="form-control" [(ngModel)]="Register_Data.Regis_Objective" *ngIf="Register_Data.Regis_ObjectiveHead !== ''">
                                        <option value="">Please Select</option>
                                        <option *ngFor="let data of CorpTargetItem" value="{{ data.value }}">{{ data.text }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row col-12 col-xl-5">
                                <div class="col-12 mb-2 px-0">
                                    <strong class="headerin">Business Unit KPI</strong>
                                </div>
                                <div class="col-12 px-0">
                                    <input type="text" class="form-control" placeholder="Please Specify" [(ngModel)]="Register_Data.Regis_KPI" style="margin-top:7px;height: 50px;">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mb-4 mt-5">
                        <div class="col-12 mb-3">
                            <h5><img alt="" src="../../../assets/logo/Vector-Smart-Object.gif" height="35" width="35">&nbsp;&nbsp;&nbsp;<span class="header-top">To Assess, Mitigate</span></h5>
                        </div>
                        <div class="row col-12 col-xl-12 mx-0 px-0">
                            <div class="col-12 col-xl-4">
                                <table class="table-bordered" style="width: 100%;">
                                    <caption style="display: none;" >This is a custom table</caption>
                                    <thead> 
                                        <tr class="rootcause">
                                            <th colspan="2" class="th-header">
                                                <div class="row col-12 mx-0 px-0">
                                                    <div class="col-8 px-0 py-1">
                                                        <span style="font-size: 22px;">2.1 Root Cause<label style="color:red;">*</label>&nbsp;&nbsp;&nbsp;</span>
                                                        <span class="btn-group dropup helper" style="bottom:18px;right:10px;">
                                                            <span data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><em class="fas fa-info-circle" style="color:white;"></em>
                                                                <div class="dropdown-menu dropdown-menu-left custom-width" style="background-color: rgba(0, 156, 217, 1);">
                                                                    <div class="px-1" style="width:100%;color:white;" >
                                                                        <h6 class="m-0" *ngFor="let item of Instruction">
                                                                            <span  *ngIf="item.area === '006' && item.delFlag !== '1'">{{ item.instructionName }}</span>
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                            </span>
                                                        </span>

                                                    </div>
                                                    <div class="col-4 px-0" style="text-align: right;">
                                                        <button type="button" class="btn btn-sm" (click)="func_rootcause()"><em class="fas fa-plus-circle icon_add" style="color:white; font-size: 25px;"></em></button>
                                                    </div>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="rootcausein" *ngIf="Assert_RootCauseTable.length !== 0">
                                        <tr *ngFor="let data of Assert_RootCauseTable index as index">
                                            <td style="width: 40px;" style="text-align: center;">
                                                <button class="btn btn-sm" style="color: red;"(click)="funcDeleteConfirm(data.id,'rootcause')"><em class="far fa-trash-alt icon-in-table"></em></button>
                                            </td>
                                            <td (click)="func_editrootcause(data.id)" [ngClass]="{'highlight': data.highlight == 'Y'}" style="cursor: pointer;">{{ index+1 }}. {{ data.title }}</td>
                                        </tr>
                                    </tbody>
                                    <tbody class="rootcausein" *ngIf="Assert_RootCauseTable.length === 0">
                                        <tr>
                                            <td>&nbsp;</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-12 col-xl-1 pt-4" style="text-align: center;" style="color:#00adef">
                                <em class="far fa-arrow-alt-circle-right img-rotate" style="font-size: 60px;"></em>
                            </div>
                            <div class="col-12 col-xl-2 px-0" style="text-align: center;">
                                <table style="text-align: center;width: 100%;">
                                    <caption style="display: none;" >This is a custom table</caption>
                                    <thead> 
                                        <tr class="riskname">
                                            <th class="th-header"><span style="font-size: 22px;">Risk Name</span></th>
                                        </tr>
                                    </thead>
                                    <tbody class="rootcausein">
                                        <tr>
                                            <td><label class="p-1">{{ Register_Data.Regis_Riskname }}</label></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-12 col-xl-1 pt-4" style="text-align: center;" style="color:#bd9e5e">
                                <em class="far fa-arrow-alt-circle-right img-rotate" style="font-size: 60px;"></em>
                            </div>
                            <div class="col-12 col-xl-4">
                                <table class="table-bordered" style="width: 100%;">
                                    <caption style="display: none;" >This is a custom table</caption>
                                    <thead> 
                                        <tr class="impact">
                                            <th colspan="2"  class="th-header">
                                                <div class="row col-12 mx-0 px-0">
                                                    <div class="col-8 px-0 py-1">
                                                        <span style="font-size: 22px;">2.2 Impact<label style="color:red;">*</label>&nbsp;&nbsp;&nbsp;</span>

                                                        <span class="btn-group dropup helper" style="bottom:18px;right:10px;">
                                                            <span data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><em class="fas fa-info-circle" style="color:white;"></em>
                                                                <div class="dropdown-menu dropdown-menu-left custom-width" style="background-color: rgba(0, 156, 217, 1);">
                                                                    <div class="px-1" style="width:100%;color:white;" >
                                                                        <h6 class="m-0" *ngFor="let item of Instruction">
                                                                            <span  *ngIf="item.area === '007' && item.delFlag !== '1'">{{ item.instructionName }}</span>
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                            </span>
                                                        </span>

                                                    </div>
                                                    <div class="col-4 px-0" style="text-align: right;">
                                                        <button type="button" class="btn btn-sm" (click)="func_impact()"><em class="fas fa-plus-circle icon_add" style="color:white; font-size: 25px;"></em></button>
                                                    </div>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="rootcausein" *ngIf="Assert_ImpactTable.length !== 0">
                                        <tr *ngFor="let data of Assert_ImpactTable; index as index">
                                            <td style="width: 40px;" style="text-align: center;">
                                                <button class="btn btn-sm" style="color: red;" (click)="funcDeleteConfirm(data.id,'impact')"><em class="far fa-trash-alt icon-in-table"></em></button>
                                            </td>
                                            <td (click)="func_editimpact(data.id)" [ngClass]="{'highlight': data.highlight == 'Y'}" style="cursor: pointer;">{{ index+1 }}. {{ data.title }}</td>
                                        </tr>
                                    </tbody>
                                    <tbody class="rootcausein" *ngIf="Assert_ImpactTable.length === 0">
                                        <tr>
                                            <td>&nbsp;</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-xl-12 mb-4">
                        <div class="col-12 col-xl-12">
                            <h5><img  alt="" src="../../../assets/logo/Vector-Smart-Object2.gif" height="30px" width="40px">&nbsp;&nbsp;&nbsp;<span class="header-top">File Attachment</span></h5>
                        </div>
                        <div class="col-4 input-group mt-3 mb-3">
                            <div class="custom-file">
                                <input type="file" class="custom-file-input" id="inputGroupFile02" (change)="func_AddFile($event)" multiple accept=".doc, .docx, .xls, .xlsx, .ppt, .pptx, .pdf, .png, .jpg, .jpeg, .zip, .rar">
                                <label class="custom-file-label" for="inputGroupFile02">
                                    <span *ngIf="tempDisplayFile.length === 0">No file Choose</span>
                                    <span *ngIf="tempDisplayFile.length !== 0">Selected {{ tempDisplayFile.length }} Files</span>
                                </label>
                            </div>
                        </div>

                    </div>

                    <div class="col-6 mt-4 mx-0 px-3 pb-4">
                        <div class="mt-2" style="text-align: center;">
                            <table class="table-bordered form-table" style="width: 100%;table-layout: fixed;">
                                <caption style="display: none;" >This is a custom table</caption>
                                <thead style="text-align: center;font-size: 22px;">
                                    <tr>
                                        <th style="width: 10%;background-color: #9eb0ba;">No.</th>
                                        <th style="width: 90%;background-color: #00aeef;">Name</th>
                                    </tr>
                                </thead>
                                <tbody style="font-size:20px">
                                    <tr *ngFor="let data of Register_Data.AttachFiles index as index">
                                        <td style="width: 40px;" style="text-align: center;">
                                            <button class="btn btn-sm" style="color: red;" (click)="RemoveFile(data)"><em class="far fa-trash-alt icon-in-table"></em></button>
                                        </td>
                                        <td>
                                            <!-- <a href="{{ data.pathFile }}">{{ data.fileName }}</a> -->
                                            <span style="cursor: pointer; color:#017BFE" (click)="downloadfile(data.pathFile)">{{ data.fileName }}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="mt-4">
                    <div class="col-12 px-0">
                        <div class="row col-12 col-xl-12 px-0 mx-0 mb-3">
                            <strong style="font-size: 24px;">Scope, Context, Criteria</strong>

                        </div>
                        <div class="row col-12 mx-0 px-0 d-flex justify-content-around">
                            <div class="col-12 col-xl-12 px-0">
                                <textarea class="form-control" (blur)="autoGrowTextZone($event)" id="RegisContext" rows="5" [(ngModel)]="Register_Data.Regis_Context"></textarea>
                            </div>
                            <div class="col-12 col-xl-12 mt-3 mx-0 px-0" style="text-align: center;">
                                    <button type="button" class="btn btn-back mr-3" (click)="func_back()"><strong>< BACK</strong></button>
                                    <button type="button" class="btn btn btn-save mr-3" (click)="func_UPDATEDATA(false,isSaveEditHistory)"><em class="far fa-save"></em><strong>&nbsp;&nbsp;&nbsp;SAVE</strong></button>
                                    <button type="button" class="btn btn-next" (click)="func_UPDATEDATA(true,isSaveEditHistory)"><strong>NEXT ></strong></button>
                            </div>
                        </div>
                    </div>
                </div>     
            </div>
        </div>

    </div>
  </div>

    <div class="modal fade hide bd-Rootcause px-0" tabindex="-1" role="dialog" aria-hidden="true" id="modalrootcasue">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">           
                
                <div class="modal-body container-fluid appform my-3">
                    <div class="row" style="min-width:1100px;">
                        <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">

                            <div class="col-12 col-md-12 col-lg-12 col-xl-12 pb-5 mt-5 mb-5 ">
                                <div class="">
                                    <div class="col-12 mt-5" style="text-align: center;">
                                        <h2 style="text-align: left;color: #0176ac;">2.1 Root Cause</h2>
                                        <hr style=" border-top: 6px solid #0176ac; ">
                                    </div>

                                    <div class="card mt-4">
                                        <div class="row col-12 mx-0 px-0 d-flex justify-content-around">

                                            <div class="row col-12 col-md-12 col-xl-6 form-group px-0">
                                                <div class="col-12">
                                                    <strong class="headerin">Root Cause :<label style="color: red;">*</label></strong>
                                                </div>
                                                <div class="col-12">                                        
                                                    <input type="text" class="form-control" [(ngModel)]="Register_Rootcause.Root_RootCause" style="height: 50px;">
                                                </div>
                                            </div>

                                            <div class="row col-12 col-md-6 col-xl-3 form-group px-0">
                                                <div class="col-12">
                                                    <strong class="headerin">Likelihood :<label style="color: red;">*</label></strong>
                                                    <div class="btn-group dropup helper ml-1">
                                                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><em class="fas fa-info-circle"></em>
                                                            <div class="dropdown-menu dropdown-menu-left custom-width" style="background-color: rgba(0, 156, 217, 1);">
                                                                <div class="px-1" style="width:100%;color:white;" >
                                                                    <h6 class="m-0" *ngFor="let item of Instruction">
                                                                        <span  *ngIf="item.area === '001' && item.delFlag !== '1'">{{ item.instructionName }} <a href="#" (click)="View_Cirtiria('2')" class="link">Click here</a></span>
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <select class="form-control" [(ngModel)]="Register_Rootcause.Root_RootLikelyhood" (change)="changelihood()">
                                                        <option value="">Please Select</option>
                                                        <option *ngFor="let data of likelyhoodLevel" value="{{ data.value }}">{{ data.text }}</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="row col-12 col-md-6 col-xl-3 form-group px-0">
                                                <div class="col-12">
                                                    <strong class="headerin">Mitigation type :<label style="color: red;">*</label></strong>
                                                </div>
                                                <div class="col-12">
                                                    <select class="form-control" [(ngModel)]="Register_Rootcause.Root_RootMitigationType" (change)="func_Changeroottype()">
                                                        <option value="">Please Select</option>
                                                        <option *ngFor="let data of MitigationtypeRootcause" value="{{ data.title }}">{{ data.title }}</option>
                                                    </select>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="card col-12 mt-4 mx-0 px-3 pb-4">
                                        <strong class="headerin">Root Cause Mitigation Plan:<label style="color: red;">*</label></strong>
                                        <div class="mt-2" style="text-align: center;">
                                            <table class="table-bordered form-table" style="width: 100%;">
                                                <caption style="display: none;" >This is a custom table</caption>
                                                <thead style="text-align: center; font-size: 22px;"> 
                                                    <tr style="background-color: #00aeef;color: #00338D;">
                                                        <th style="width: 10px;background-color: #9eb0ba;">No.</th>
                                                        <th style="width: 500px;background-color: #00aeef;">Mitigation Plan<label style="color: red;">*</label></th>
                                                        <th style="width: 50px;background-color: #023154;">Mitigation Owner<label style="color: red;">*</label></th>
                                                        <th style="width: 180px;background-color: #023154;">Due Date<label style="color: red;">*</label></th>
                                                        <th style="width: 50px;background-color: #023154;" [hidden]="checkstatusupdate === 'new'">Mitigation Progress</th>
                                                        <th style="width: 200px;background-color: #023154;" [hidden]="checkstatusupdate === 'new'">Mitigation Status/Justification</th>
                                                        <th style="width: 100px;background-color: #023154;">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let data of temptable index as index" (click)="myFunctionoff()">
                                                        <td style="text-align: center;">

                                                            <div>
                                                                {{ index+1 }}.
                                                            </div>
                                                        </td>
                                                        <td style="cursor:pointer; text-align: left;"> 
                                                            <div *ngIf="data.display === true">
                                                                {{ data.plan }}
                                                            </div>
                                                            <div *ngIf="data.display === false && idedit === data.plan">
                                                                <input type="text" class="form-control" style="font-size: 16px;" [(ngModel)]="Edit_Rootcause.RootPlan">
                                                            </div>
                                                        </td>
                                                        <td style="text-align: center;">
                                                            <div *ngIf="data.display === true">
                                                                {{ data.ownertext }}
                                                            </div>
                                                            <div *ngIf="data.display === false && idedit === data.plan">
                                                                <input type="text" class="form-control" style="font-size: 16px;text-align: center;" aria-label="Number" matInput [formControl]="myControl" [matAutocomplete]="auto" [(ngModel)]="Edit_Rootcause.RootOwnertext">
                                                                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                                                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option.text">
                                                                    {{option.text}}
                                                                    </mat-option>
                                                                </mat-autocomplete>
                                                            </div>
                                                        </td>
                                                        <td style="text-align: center;" style="min-width: 180px;">
                                                          
                                                            <div *ngIf="data.display === true">
                                                                {{ data.Duedate }}
                                                            </div>
                                                            <div class="input-group" style="align-items: center;" *ngIf="data.display === false && idedit === data.plan">
                                                                
                                                                     
                                                                <input id="dateOfBirth1" autocomplete="off" 
                                                            [readonly]="true" class="form-control" name="dp2" 
                                                            [matDatepicker]="picker1" style="text-align: center;font-size: 16px;max-width: 0px;padding: 0px;border: 0px;margin-left: 3.5rem;" 
                                                            (dateChange)="onDateSelectEdit($event)" [matDatepickerFilter]="myFilter">
                                                            {{ Edit_Rootcause.RootDateEdit }}
                                                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                                            <mat-datepicker #picker1 ></mat-datepicker>


                                                            
                                                            </div>
                                                        </td>
                                                        <td style="text-align: center;" [hidden]="checkstatusupdate === 'new'">
                                                            <div *ngIf="data.display === true">
                                                                <strong><span>{{ data.Progess }}</span>%</strong><br>
                                                                <div class="slidecontainer">
                                                                    <input type="range" min="0" max="100" value="{{ data.Progess }}" class="slider" disabled>
                                                                </div>
                                                            </div>
                                                            <div class="slidecontainer" *ngIf="data.display === false && idedit === data.plan">
                                                                <strong>
                                                                   
                                                                    <span id="rootedit" style="display:none;"></span>
                                                                    <span style="display:none;">{{ data.Progess }}</span>
                                                                        <input name="RootProgress" id="RootProgress" type="text" class="form-control-static" style="font-size: 16px;width: 30%;" 
                                                                        (keypress)="numberOnly($event)" [(ngModel)]="Edit_Rootcause.RootProgress" value="{{ data.Progess }}" (blur)="selectedEditRagedInput()" />&nbsp;%
                                                                        
                                                                </strong>                           
                                                                <input type="range" min="0" max="100" value="{{ data.Progess }}" class="slider" id="myRangerootedit" (click)="selectedEditRaged()">
                                                            </div>
                                                        </td>
                                                        <td [hidden]="checkstatusupdate === 'new'">
                                                            <div *ngIf="data.display === true">
                                                                {{ data.justification }}
                                                            </div>
                                                            <div *ngIf="data.display === false && idedit === data.plan">
                                                                <input type="text" class="form-control" style="font-size: 16px;" [(ngModel)]="Edit_Rootcause.RootJustification">
                                                            </div>
                                                        </td>
                                                        <td style="text-align: center;">
                                                            <button class="btn btn-sm" (click)="function_editRootcause(data.plan , data.tempid)" *ngIf="data.display === true"  [disabled]="Register_Rootcause.Root_RootMitigationType === 'Take' || Register_Rootcause.Root_RootMitigationType ===  '' ">
                                                                <em class="far fa-edit text-warning icon-in-table"></em>
                                                            </button>
                                                            <button type="button" class="btn" (click)="function_addeditRootcause(data.plan , data.tempid)" *ngIf="data.display === false && idedit === data.plan">
                                                                <em class="far fa-save icon-in-table text-success"></em>
                                                            </button>
                                                            <button class="btn btn-sm" (click)="func_deleterootcauseMitiplan(data.plan , data.tempid)" [disabled]="Register_Rootcause.Root_RootMitigationType === 'Take' || Register_Rootcause.Root_RootMitigationType ===  '' ">
                                                                <em class="far fa-trash-alt icon-in-table" style="color: red"></em>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                   
                                                    <tr style="height:48px;cursor:pointer;" (click)="myFunction()" *ngIf="Register_Rootcause.Root_RootMitigationType !== 'Take'">
                                                        <td></td>
                                                        <td>
                                                            <div *ngIf="valueadd == true">
                                                                <input type="text" class="form-control" style="font-size: 16px;" (blur)="chkstatus()" [(ngModel)]="Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Name" [disabled]="Register_Rootcause.Root_RootMitigationType === ''">
                                                            </div>
                                                        </td>                                                       
                                                        <td style="width:50px;">
                                                        
                                                            <div *ngIf="valueadd == true" >
                                                                <input type="text" class="form-control m-0" style="font-size: 16px;text-align:center;" aria-label="Number" matInput [formControl]="myControl" [matAutocomplete]="auto"
                                                                [(ngModel)]="Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_OwmerText" [hidden]="Register_Rootcause.Root_RootMitigationType === ''" (blur)="chkstatus()">
                                                                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                                                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option.text">
                                                                    {{option.text}}
                                                                    </mat-option>
                                                                </mat-autocomplete>
                                                                <input type="text" class="form-control m-0"  style="font-size: 16px;text-align:center;"  disabled [hidden]="Register_Rootcause.Root_RootMitigationType === 'Treat'">
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="input-group" *ngIf="valueadd == true">
                                                                     
                                                                <input id="dateOfBirth2" autocomplete="off" [readonly]="true" class="form-control" name="dp2" [matDatepicker]="picker" style="width: 70px;text-align: center;font-size: 16px;" 
                                                                 [disabled]="Register_Rootcause.Root_RootMitigationType === ''"(dateChange)="onDateSelect($event)" [matDatepickerFilter]="myFilter"> 
                                                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                                    <mat-datepicker #picker ></mat-datepicker>
                                                         
                                                            </div>
                                                        </td>
                                                        <td style="text-align: center;" [hidden]="checkstatusupdate === 'new'">
                                                       
                                                            <div class="slidecontainer" [hidden]="valueadd == false">
                                                                <strong><span id="root" style="display: none;"></span></strong><!-- <br> -->
                                                                <input name="DemoRootProgress" id="DemoRootProgress" type="text" class="form-control-static" style="font-size: 16px;width: 30%;" 
                                                                        (keypress)="numberOnly($event)" (blur)="selectedRagedInput()" />&nbsp;%
                                                                <input type="range" min="0" max="100" value="0" style="padding: 0px 0px 0px 0px;"  class="slider" id="myRangeroot"  (click)="selectedRaged('demo')" [disabled]="Register_Rootcause.Root_RootMitigationType === ''">
                                                            </div>
                                                        </td>
                                                        <td [hidden]="checkstatusupdate === 'new'">
                                                       
                                                            <div *ngIf="valueadd == true">
                                                                <input type="text" class="form-control" style="font-size: 16px;"  [(ngModel)]="Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Justification" [disabled]="Register_Rootcause.Root_RootMitigationType === ''">
                                                            </div>
                                                        </td>
                                                        <td style="text-align: center;">
                                                      
                                                            <div *ngIf="valueadd == true">
                                                                <button type="button" class="btn btn-sm text-success" (click)="func_addtablerootcause()" [hidden]="Register_Rootcause.Root_RootMitigationType === '' || (Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Name === '' && Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Owner === '') ">
                                                                    <em class="far fa-save icon-in-table"></em>
                                                                </button>
                                                            </div>
                                                        </td>
                                                     

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
         
                                    <div class="card">
                                        <div class="row col-12 mx-0 my-4">
                                            <div class="col-12 mb-3 px-0">
                                                <strong class="headerin"><img  alt="" src="../../../assets/logo/Vector-Smart-Object.gif" height="35" width="35">&nbsp;&nbsp;&nbsp;Root Cause Description:</strong>
                                            </div>
                                            <div class="col-12">
                                              
                                                <textarea class="form-control" rows="3" (blur)="autoGrowTextZone($event)" [(ngModel)]="Register_Rootcause.RootCause_Description"></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card mt-4">
                                        <div class="row col-12 mx-0">
                    
                                            <div class="row col-12 col-md-12 col-xl-4 form-group px-0">
                                                <div class="col-12">
                                                    <strong class="headerin">Likelihood after Mitigated:<label style="color: red;">*</label></strong>
                                                    <div class="btn-group dropup helper ml-1">
                                                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><em class="fas fa-info-circle"></em>
                                                            <div class="dropdown-menu dropdown-menu-left custom-width" style="background-color: rgba(0, 156, 217, 1);">
                                                                <div class="px-1" style="width:100%;color:white;" >
                                                                    <h6 class="m-0" *ngFor="let item of Instruction">
                                                                        <span  *ngIf="item.area === '002' && item.delFlag !== '1'">{{ item.instructionName }} <a href="#" (click)="View_Cirtiria('2')" class="link">Click here</a></span>
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 mt-2">
                                                    <select class="form-control" [(ngModel)]="Register_Rootcause.Root_AfterMitigated" [disabled]="Register_Rootcause.Root_RootMitigationType === 'Take' || Register_Rootcause.Root_RootMitigationType ===  '' ">
                                                        <option value="">Please Select</option>
                                                        <option *ngFor="let data of likelyhoodLevelAfter" value="{{ data.value }}" [disabled]="data.value > Register_Rootcause.Root_RootLikelyhood">{{ data.text }}</option>
                                                    </select>
                                                </div>
                                            </div>
                    
                                        </div>
                                    </div>
             
                    
                                    <div class="card">
                          
                                        <div class="mt-4">
                                            <div class="row col-12 mx-0">
                    
                                                <div class="row col-12 col-md-12 col-xl-6 form-group px-0">
                                                    <div class="col-12">
                                                        <strong class="headerin">KRI Name:</strong>
                                                        <div class="btn-group dropup helper ml-1">
                                                            <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><em class="fas fa-info-circle"></em>
                                                                <div class="dropdown-menu dropdown-menu-left custom-width" style="background-color: rgba(0, 156, 217, 1);">
                                                                    <div class="px-1" style="width:100%;color:white;" >
                                                                        <h6 class="m-0" *ngFor="let item of Instruction">
                                                                            <span  *ngIf="item.area === '003' && item.delFlag !== '1'">{{ item.instructionName }}</span>
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 mt-2">
                                                    <input type="text" class="form-control" [(ngModel)]="Register_Rootcause.Root_KRIName">
                                                    </div>
                                                </div>
                    
                                            </div>
                                        </div>
                 
                                        <div class="mt-4">
                                            <div class="row col-12 mx-0 d-flex justify-content-around px-0">
                                                <div class="col-12 mb-3">
                                                    <strong class="headerin">KRI Threshold:</strong>
                                                    <div class="btn-group dropup helper ml-1">
                                                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><em class="fas fa-info-circle"></em>
                                                            <div class="dropdown-menu dropdown-menu-left custom-width" style="background-color: rgba(0, 156, 217, 1);">
                                                                <div class="px-1" style="width:100%;color:white;" >
                                                                    <h6 class="m-0" *ngFor="let item of Instruction">
                                                                        <span  *ngIf="item.area === '004' && item.delFlag !== '1'">{{ item.instructionName }}</span>
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                    
                                                <div class="row col-12 col-md-12 col-xl-6 form-group px-4">
                                                    <div class="col-12" style="background: #72b704;">
                                                        <strong style="color: white;font-size: 20px;">Green</strong>  
                                                    </div>
                                                    <div class="col-12 px-0" style="border: 1.5px solid #72b704;">
                                                        <input type="text" class="form-control" [(ngModel)]="Register_Rootcause.Root_KRIThreshold_Green">
                                                    </div>
                                                </div>
                    
                                                <div class="row col-12 col-md-12 col-xl-6 form-group px-4">
                                                    <div class="col-12 bg-red">
                                                        <strong style="font-size: 20px;">Red</strong>
                                                    </div>
                                                    <div class="col-12 px-0" style="border: 1.5px solid #dc3545;">
                                                        <input type="text" class="form-control" [(ngModel)]="Register_Rootcause.Root_KRIThreshold_Red">
                                                    </div>
                                                </div>
                    
                                            </div>
                                        </div>
                      
                                        <div class="mt-4" [hidden]="checkstatusupdate === 'new'">
                                            <div class="row col-12 mx-0">
                                                <div class="col-12 mb-3 px-0">
                                                    <strong class="headerin">KRI Status:</strong>
                                                </div>
                                                
                                                <div class="row col-12 col-md-12 col-xl-6 form-group px-4">
                                                    <div class="col-4">
                                                       
                                                        <label class="container_radio hover_radio1">
                                                            <div class="rounded active" style="text-align: center;" style="border:1px solid #72b704;" (click)="selectedradio(0)" id="rdo1">&nbsp;</div>
                                                            <input type="radio" name="radio" (click)="selectedradio(0)" value="0" [(ngModel)]="Register_Rootcause.Root_KRIStatus">
                                                            <span class="checkmark chk1 mt-1"></span>
                                                        </label>
                                                    </div>
                                                    <div class="col-4">
                                                       
                                                        <label class="container_radio hover_radio2">
                                                            <div class="rounded active2" style="text-align: center;" style="border:1px solid #ffff00;" (click)="selectedradio(1)" id="rdo2">&nbsp;</div>
                                                            <input type="radio" name="radio" (click)="selectedradio(1)" value="1" [(ngModel)]="Register_Rootcause.Root_KRIStatus">
                                                            <span class="checkmark chk2 mt-1"></span>
                                                        </label>
                                                    </div>
                                                    <div class="col-4">
                                                        
                                                        <label class="container_radio hover_radio3">
                                                            <div class="rounded active3" style="text-align: center;" style="border:1px solid red;" (click)="selectedradio(2)" id="rdo3">&nbsp;</div>
                                                            <input type="radio" name="radio" (click)="selectedradio(2)" value="2" [(ngModel)]="Register_Rootcause.Root_KRIStatus">
                                                            <span class="checkmark chk3 mt-1"></span>
                                                        </label>
                                                    </div>
                                                </div>
                    
                                            </div>
                                        </div>
                                       
                                        <div class="row col-12 mx-0 my-4" [hidden]="checkstatusupdate === 'new'">
                                            <div class="col-12 mb-3 px-0">
                                                <strong class="headerin">KRI Status/KRI Justification:</strong>
                                            </div>
                                            <div class="col-12">
                                                <textarea class="form-control" rows="3" [(ngModel)]="Register_Rootcause.Root_Justification"></textarea>
                                            </div>
                                        </div>
                                       
                                    </div>
                                                    
                                </div>
                            </div>
                    
                            <div class="col-12 my-3 mx-0 text-center">
                                <button type="button" class="btn btn-back mr-3"  (click)="func_closeroot()"><em class="far fa-window-close" style="font-size:26px;"></em><strong>&nbsp;&nbsp;Close</strong></button>
                                <button type="button" class="btn btn btn-save" (click)="func_save_RootCause()"><em class="far fa-save" style="font-size: 26px;"></em><strong>&nbsp;&nbsp;&nbsp;Save</strong></button>
                            </div>
                    
                        </div>
                    </div>
                </div>
                <app-loading [loading]='loading2'></app-loading>

            </div>
        </div>
    </div>

    <div class="modal fade hide bd-Impact px-0" tabindex="-1" role="dialog"  aria-hidden="true" id="modalimpact">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
              
                <div class="modal-body container-fluid appform my-3">
                        <div class="row" style="min-width:1100px;">
                        <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">
                      
                            <div class=" col-12 col-md-12 col-lg-12 col-xl-12 pb-5 mt-5 mb-5">
                
                                <div class="">
                    
                                    <div class="col-12 mt-5" style="text-align: center;">
                                        <h2 style="text-align: left;color: #0176ac;">2.2 Impact</h2>
                                        <hr style=" border-top: 6px solid #0176ac; ">
                                    </div>
                   
                                    <div class="card mt-4">
                 
                                        <div class="row col-12 mx-0 px-0">
                                            <div class="row col-4  form-group mx-0 px-0">
                                                <div class="col-12">
                                                    <strong class="headerin">Impact Category :<label style="color: red;">*</label></strong>
                                                </div>
                                            </div>
                                            <div class="row col-8  form-group mx-0 px-0">
                                                <div class="col-12" style="display: none;">
                                                    <strong class="headerin">Impact Description :<label style="color: red;">*</label></strong>
                                                </div>
                                            </div>

                                            <div class="col-12 col-md-12 col-xl-4 form-group px-0">
                                                <div class="col-12">
                                                    <select class="form-control" [(ngModel)]="Register_Impact.Impact_Category" (change)="func_selectImpactCate(Register_Impact.Impact_Category)">
                                                        <option value="">Please Select</option>
                                                        <option *ngFor="let data of ImpactCategory index as index" value="{{ data.value }}">{{ data.text }}</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="col-12 col-md-6 col-xl-2 form-group" [hidden]="Register_Impact.Impact_Category !== '1'">
                                                <select class="form-control" [(ngModel)]="Register_Impact.Impact_NPTEMV" (change)="func_ChageNI_NPV()">
                                                    <option value="">Please Select</option>
                                                    <option *ngFor="let data of Financial" value="{{ data.value }}">{{ data.text }}</option>
                                                </select>
                                            </div>
                                            <div class="col-12 col-md-6 col-xl-2 form-group" [hidden]="Register_Impact.Impact_Category !== '1'">
                                                <input type="number" class="form-control" style="text-align: right;" min="0" [(ngModel)]="Register_Impact.Impact_Total_Amont" (blur)="func_Calculate()">
                                            </div>
                                            <div class="col-12 col-md-6 col-xl-1 form-group pt-1" [hidden]="Register_Impact.Impact_Category !== '1'">
                                                <label >(MM USD)</label>
                                            </div>
                                            
                                            <div class="col-12 col-md-6 col-xl-8 form-group" style="display: none;">
                                                <input type="text" class="form-control" [(ngModel)]="Register_Impact.Impact_Description">
                                            </div>
                                        </div>

                                        <div class="row col-12 mx-0 px-0 d-flex justify-content-around">
                                            <div class="row col-12 col-md-12 col-xl-4 form-group px-0">
                                                <div class="col-12">
                                                    <strong class="headerin">Impact Level :<label style="color: red;">*</label></strong>
                                                    <div class="btn-group dropup helper ml-1">
                                                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><em class="fas fa-info-circle"></em>
                                                            <div class="dropdown-menu dropdown-menu-left custom-width" style="background-color: rgba(0, 156, 217, 1);" show>
                                                                <div class="px-1" style="width:100%;color:white;" >
                                                                    <h6 class="m-0" *ngFor="let item of Instruction">
                                                                        <span *ngIf="item.area === '008' && item.delFlag !== '1'">{{ item.instructionName }} <a href="#" (click)="View_Cirtiria('1')" class="link">Click here</a></span>
                                                                    </h6>
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <select class="form-control" [(ngModel)]="Register_Impact.Impact_Level" (change)="changeImpact()" [disabled]="Register_Impact.Impact_Category === '1'">
                                                        <option value="">Please Select</option>
                                                        <option *ngFor="let data of ImpactLevel" value="{{ data.value }}">{{ data.text }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="row col-12 col-md-12 col-xl-4 form-group px-0">
                                                <div class="formtext col-12">
                                                    <strong class="headerin">Mitigation Type :<label style="color: red;">*</label></strong>
                                                </div>
                                                <div class="col-12">
                                                    <select class="form-control" [(ngModel)]="Register_Impact.Impact_Migation_Type" (change)="func_ChangeImpactType()">
                                                        <option value="">Please Select</option>
                                                        <option *ngFor="let data of MitigationTypeImpact" value="{{ data.title }}">{{ data.title }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="row col-12 col-md-12 col-xl-4 form-group px-0"></div>
                                        </div>
                    
                                    </div>

                                    <div class="card">
                                        <div class="row col-12 mx-0 my-4">
                                            <div class="col-12 mb-3 px-0">
                                                <strong class="headerin"><img  alt="" src="../../../assets/logo/Vector-Smart-Object.gif" height="35" width="35">
                                                    &nbsp;&nbsp;&nbsp;Impact Description:<span class="text-danger" [hidden]="Register_Impact.Impact_Category === '1'">*</span></strong>
                                            </div>
                                            <div class="col-12">
                                                <textarea class="form-control" rows="3" (blur)="autoGrowTextZone($event)" [(ngModel)]="Register_Impact.Impact_Description"></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card col-12 mt-4 mx-0 px-3 pb-4">
                                        <strong class="headerin">Impact Mitigation Plan:<label style="color: red;">*</label></strong>
                                        <div class="mt-2" style="text-align: center;">
                                            <table class="table-bordered form-table" style="width: 100%;">
                                                <caption style="display: none;" >This is a custom table</caption>
                                                <thead style="text-align: center; font-size: 22px;"> 
                                                    <tr style="background-color: #00aeef;">
                                                        <th style="width: 10px;background-color: #9eb0ba;">No.</th>
                                                        <th style="width: 500px;background-color: #00aeef;">Mitigation Plan<label style="color: red;">*</label></th>
                                                        <th style="width: 50px;background-color: #023154;">Mitigation Owner<label style="color: red;">*</label></th>
                                                        <th style="width: 180px;background-color: #023154;">Due Date<label style="color: red;">*</label></th>
                                                        <th style="width: 50px;background-color: #023154;" [hidden]="checkstatusupdate === 'new'">Mitigation Progress</th>
                                                        <th style="width: 200px;background-color: #023154;" [hidden]="checkstatusupdate === 'new'">Mitigation Status/Justification</th>
                                                        <th style="width: 120px;background-color: #023154;">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let data of temptableImpact index as index" (click)="myFunctionoff()">
                                                        <td style="text-align: center;">
                                                            <div>
                                                                {{ index+1 }}.
                                                            </div>

                                                        </td>
                                                        <td style="cursor:pointer; text-align:start;"> 
                                                            <div *ngIf="data.display === true">
                                                                {{ data.mitigation }}
                                                            </div>
                                                            <div *ngIf="data.display === false">
                                                                <input type="text" class="form-control" style="font-size: 16px;" [(ngModel)]="Edit_Impact.ImpactPlan">
                                                            </div>
                                                        </td>
                                                        <td style="text-align: center;" style="width:50px;">
                                                            <div *ngIf="data.display === true">
                                                                {{ data.ownertext }}
                                                            </div>
                                                            <div *ngIf="data.display === false">
                                                                <input type="text" class="form-control" style="font-size: 16px;text-align:center;" aria-label="Number" matInput [formControl]="myControl2" [matAutocomplete]="auto2" [(ngModel)]="Edit_Impact.ImpactOwnertext">
                                                                <mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete">
                                                                    <mat-option *ngFor="let option of filteredOptions2 | async" [value]="option.text">
                                                                    {{option.text}}
                                                                    </mat-option>
                                                                </mat-autocomplete>
                                                            </div>
                                                        </td>
                                                        <td style="text-align: center;">
                                                            <div *ngIf="data.display === true">
                                                                {{ data.Duedate }}
                                                            </div>
                                                            <div class="input-group" style="align-items: center;" *ngIf="data.display === false">
                                                                <!-- <input id="dateOfBirth3" autocomplete="off" [readonly]="true" class="form-control" style="font-size: 16px;width:70px;text-align:center;" name="dpimpact" ngbDatepicker #dpimpact="ngbDatepicker" [(ngModel)]="Edit_Impact.ImpactDate" [minDate]="minDate">
                                                                <div class="input-group-append">
                                                                    <button class="btn btn-sm calendar" (click)="dpimpact.toggle()" type="button" style="color: #00aeef;"><em class="far fa-calendar-alt" style="font-size: 20px;"></em></button>
                                                                </div> -->

                                                                <input id="dateOfBirth3" autocomplete="off" 
                                                                [readonly]="true" class="form-control"  name="dpimpact" 
                                                                [matDatepickerFilter]="myFilter"[matDatepicker]="picker5" style="text-align: center;font-size: 16px;max-width: 0px;padding: 0px;border: 0px;margin-left: 3.5rem;" 
                                                                (dateChange)="onDateSelectMigrationEdit($event)" [disabled]="Register_Impact.Impact_Migation_Type ===''">
                                                                {{ Edit_Impact.ImpactDateEdit }}
                                                                <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
                                                                            <mat-datepicker #picker5 ></mat-datepicker>
                                                            </div>
                                                        </td>
                                                        <td style="text-align: center;" [hidden]="checkstatusupdate === 'new'">
                                                            <div *ngIf="data.display === true">
                                                                <strong><span>{{ data.Progess }}</span>%</strong><br>
                                                                <div class="slidecontainer">
                                                                    <input type="range" min="0" max="100" value="{{ data.Progess }}" class="slider" disabled>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div class="slidecontainer" *ngIf="data.display === false">
                                                                    <strong>
                                                                        <span id="demoImpactedit" style="display: none;"></span>
                                                                        <span style="display: none;">{{ data.Progess }}</span>

                                                                        <input name="ImpactProgress" id="ImpactProgress" type="text" class="form-control-static" style="font-size: 16px;width: 30%;" 
                                                                        (keypress)="numberOnly($event)" [(ngModel)]="Edit_Impact.ImpactProgress" value="{{ data.Progess }}" (blur)="selectedEditRagedImpactInput()" />&nbsp;%
                                                                    </strong>
                                                                    <input type="range" min="0" max="100" value="{{ data.Progess }}" class="slider" id="myRangeImpactedit" (click)="selectedEditRagedImpact('demo')" style="width: 150px;" >
                                                                </div>
                                                            </div>

                                                        </td>
                                                        <td style="text-align: center;" [hidden]="checkstatusupdate === 'new'">
                                                            <div  *ngIf="data.display === true">
                                                                {{ data.justification }}
                                                            </div>
                                                            <div *ngIf="data.display === false">
                                                                <input type="text" class="form-control" style="font-size: 16px;" [(ngModel)]="Edit_Impact.ImpactJustification"> 
                                                            </div>
                                                        </td>
                                                        <td style="text-align: center;">
                                                            <button class="btn btn-sm" (click)="function_editImpct(data.mitigation , data.tempid)" *ngIf="data.display === true"  [disabled]="Register_Impact.Impact_Migation_Type === 'Take' || Register_Impact.Impact_Migation_Type ===  '' ">
                                                                <em class="far fa-edit text-warning icon-in-table"></em>
                                                            </button>
                                                             <button type="button" class="btn" (click)="function_addeditImpact(data.mitigation , data.tempid)" *ngIf="data.display === false">
                                                                <em class="far fa-save icon-in-table text-success"></em>
                                                            </button>
                                                            <button class="btn btn-sm" (click)="func_deleteimpactMitiplan(data.mitigation , data.tempid)" [disabled]="Register_Impact.Impact_Migation_Type === 'Take' || Register_Impact.Impact_Migation_Type ===  '' ">
                                                                <em class="far fa-trash-alt icon-in-table" style="color: red;"></em>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr (click)="myFunction()" style="cursor:pointer;" *ngIf="Register_Impact.Impact_Migation_Type !== 'Take'">
                                                        <td style="height:48px;"></td>
                                                        <td>
                                                            <div *ngIf="valueadd == true">
                                                                <input type="text" class="form-control" style="font-size: 16px;" (blur)="chkstatusimpact()" [(ngModel)]="Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Name" [disabled]="Register_Impact.Impact_Migation_Type ===''">
                                                            </div>
                                                        </td>
                                                        <td style="width:50px;">
                                                            <div *ngIf="valueadd == true">
                                                                <input type="text" class="form-control" style="font-size: 16px;text-align:center;" aria-label="Number" matInput [formControl]="myControl2" [matAutocomplete]="auto2" 
                                                                [(ngModel)]="Register_Impact.Impact_Mitigation[0].Impact_Mitigation_OwnerText" [hidden]="Register_Impact.Impact_Migation_Type === ''" (blur)="chkstatusimpact()">
                                                                <mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete">
                                                                    <mat-option *ngFor="let option of filteredOptions2 | async" [value]="option.text">
                                                                    {{option.text}}
                                                                    </mat-option>
                                                                </mat-autocomplete>
                                                                <input type="text" class="form-control" style="font-size: 16px;text-align:center;" disabled [hidden]="Register_Impact.Impact_Migation_Type !== ''">
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="input-group" *ngIf="valueadd == true">
                                                                <!-- <input id="dateOfBirth4" autocomplete="off" [readonly]="true" class="form-control" style="font-size: 16px;width:70px;text-align:center;" name="dp4" ngbDatepicker #dp4="ngbDatepicker" [minDate]="minDate"
                                                                [(ngModel)]="Register_Impact.Impact_Mitigation[0].Impact_Mitigation_DueDate" [disabled]="Register_Impact.Impact_Migation_Type ===''">
                                                                <div class="input-group-append">
                                                                    <button class="btn btn-sm calendar" (click)="dp4.toggle()" type="button" style="color: #00aeef;"><em class="far fa-calendar-alt" style="font-size: 20px;"></em></button>
                                                                </div> -->
                                                                <input id="dateOfBirth4" autocomplete="off" [readonly]="true" class="form-control" name="dp4" [matDatepicker]="picker4" style="width: 70px;text-align: center;font-size: 16px;" 
                                                                (dateChange)="onDateSelectMigration($event)" [matDatepickerFilter]="myFilter"  [disabled]="Register_Impact.Impact_Migation_Type ===''">
                                                                            <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                                                                            <mat-datepicker #picker4 ></mat-datepicker>
                                                            </div>
                                                        </td>
                                                        <td style="text-align: center;" [hidden]="checkstatusupdate === 'new'">
                                                            <div class="slidecontainer" [hidden]="valueadd == false">
                                                                <strong style="display: none;"><span id="demoImpact"></span>%</strong><br>
                                                                <input name="DemoImpactProgress" id="DemoImpactProgress" type="text" class="form-control-static" style="font-size: 16px;width: 30%;" 
                                                                (keypress)="numberOnly($event)" (blur)="selectedRagedImpactInput()" />&nbsp;%
                                                                <input type="range" min="0" max="100" value="0" class="slider" id="myRangeImpact" (click)="selectedRagedImpact('demo')" [disabled]="Register_Impact.Impact_Migation_Type ===''">
                                                            </div>
                                                        </td>
                                                        <td [hidden]="checkstatusupdate === 'new'">
                                                            <div *ngIf="valueadd == true">
                                                                <input type="text" class="form-control" style="font-size: 16px;" [(ngModel)]="Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Justification" [disabled]="Register_Impact.Impact_Migation_Type ===''">
                                                            </div>
                                                        </td>
                                                        <td style="text-align: center;">
                                                            <div *ngIf="valueadd == true">
                                                                <button type="button" class="btn btn-sm text-success" (click)="func_addtableimpact()" [hidden]="Register_Impact.Impact_Migation_Type ==='' || (Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Name === '' && Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Owner === '') ">
                                                                    <em class="far fa-save icon-in-table"></em>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="card mt-4">
                                        <div class="row col-12 mx-0">
                                        <!-- ROW 2 -->
                                        <div class="row col-12 mx-0 px-0 d-flex justify-content-around">
                                            <div class="row col-12 col-md-12 col-xl-4 form-group px-0">
                                                <div class="col-12">
                                                    <strong class="headerin">Impact Level after Mitigated:<label style="color: red;">*</label></strong>
                                                    <div class="btn-group dropup helper ml-1">
                                                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><em class="fas fa-info-circle"></em>
                                                            <div class="dropdown-menu dropdown-menu-left custom-width" style="background-color: rgba(0, 156, 217, 1);">
                                                                <div class="px-1" style="width:100%;color:white;" >
                                                                    (MM USD)<h6 class="m-0" *ngFor="let item of Instruction">
                                                                        <span  *ngIf="item.area === '009' && item.delFlag !== '1'">{{ item.instructionName }} <a href="#" (click)="View_Cirtiria('1')" class="link">Click here</a></span>
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <!-- (change)="changeImpact()" -->
                                                    <!-- <select class="form-control" id="AfterMitigated" [(ngModel)]="Register_Impact.Impact_After_Mitigated" [disabled]="Register_Impact.Impact_Category === '1' || Register_Impact.Impact_Migation_Type === 'Take' || Register_Impact.Impact_Migation_Type ===  ''"> 
                                                        <option value="">Please Select</option>                                  
                                                        <option *ngFor="let data of ImpactLevelAfter" value="{{ data.value }}" [disabled]="data.value > Register_Impact.Impact_Level">{{ data.text }}</option>
                                                    </select> -->

                                                    <select class="form-control" id="AfterMitigated" [(ngModel)]="Register_Impact.Impact_After_Mitigated"  [disabled]="this.isSelectImpactLevelAfter"> 
                                                        <option value="">Please Select</option>                                  
                                                        <option *ngFor="let data of ImpactLevelAfter" value="{{ data.value }}" [disabled]="isDisableAfterMitigated(data.value)">{{ data.text }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="row col-12 col-md-12 col-xl-4 form-group px-0">
                                                <div class="formtext col-12">
                                                    <strong class="headerin" style="visibility: hidden;">Mitigation Type :<label style="color: red;">*</label></strong>
                                                </div>
                                                <div class="col-12 col-md-6 col-xl-4 mt-2 form-group" [hidden]="isHiddenAfterMitigatedValue()">
                                                    <input type="number" [disabled]="chk_amount()" id="AfterMitigatedValue" class="form-control" style="text-align: right;" min="0" [(ngModel)]="Impact_After_Total_Amont" (blur)="func_Calculate2()">
                                                </div>
                                                <div class="col-12 col-md-6 col-xl-4 mt-2 form-group pt-1" 
                                                 [hidden]="isHiddenAfterMitigatedValue()">
                                                    <label >(MM USD)</label>
                                                </div>
                                            </div>
                                            <div class="row col-12 col-md-12 col-xl-4 form-group px-0"></div>
                                        </div>
                                        <!----------->
                    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                            <div class="col-12 my-3 mx-0 text-center">
                                <button type="button" class="btn btn-back mr-3" (click)="func_closeImpact()"><em class="far fa-window-close" style="font-size:26px;"></em><strong>&nbsp;&nbsp;Close</strong></button>
                                <button type="button" class="btn btn-save" (click)="func_save_Impact()"><em class="far fa-save" style="font-size:26px;"></em><strong>&nbsp;&nbsp;&nbsp;Save</strong></button>
                            </div>
                        </div>
                    </div>


                </div>
                <app-loading [loading]='loading2'></app-loading>

            </div>
        </div>
    </div>
    <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalConfirmDeleteFile">
        <div class="modal-dialog modal-dialog-centered" role="document"> 
        <div class="modal-content">
            <span class="bg-warning">&nbsp;</span>
            <div class="modal-header">
                <div class="row col-12 text-warning">
                    <div class="col-2">
                        <em class="fas fa-exclamation-triangle" style="font-size:50px;"></em>
                    </div>
                    <div class="col-10 pt-2">
                        <h4 class="modal-title">Alert !</h4>
                    </div>
                </div>
            </div>
            <div class="modal-body text-body">
                <div class="col-12" style="text-align: left;">
                    <span style="font-size:18px;">Are you sure you want to delete it ?</span>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-warning text-white w-25" (click)="funcYesDeleteFile()">Yes</button>
                <button type="button" class="btn btn-secondary w-25" (click)="funcNoDeleteFile()">No</button>
            </div>
        </div>
        </div>
    </div>

    <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalConfirm">
        <div class="modal-dialog modal-dialog-centered" role="document"> 
        <div class="modal-content">
            <span class="bg-warning">&nbsp;</span>
            <div class="modal-header">
                <div class="row col-12 text-warning">
                    <div class="col-2">
                        <em class="fas fa-exclamation-triangle" style="font-size:50px;"></em>
                    </div>
                    <div class="col-10 pt-2">
                        <h4 class="modal-title">Alert !</h4>
                    </div>
                </div>
            </div>
            <div class="modal-body text-body">
                <div class="col-12" style="text-align: left;">
                    <span style="font-size:18px;">Are you sure you want to delete it ?</span>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-warning text-white w-25" (click)="funcYes()">Yes</button>
                <button type="button" class="btn btn-secondary w-25" (click)="funcNo()">No</button>
            </div>
        </div>
        </div>
    </div>

    <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalConfirmClose">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <span class="bg-warning">&nbsp;</span>
                <div class="modal-header">
                    <div class="row col-12 text-warning">
                        <div class="col-2">
                            <em class="fas fa-exclamation-triangle" style="font-size:50px;"></em>
                        </div>
                        <div class="col-10 pt-2">
                            <h4 class="modal-title">Alert !</h4>
                        </div>
                    </div>
                </div>
                <div class="modal-body text-body border-0">
                    <div class="col-12" style="text-align: left;">
                        <span style="font-size: 18px;">All enter or edit information will be lost</span><br>
                        <span style="font-size: 18px;">Are you sure you want to close ?</span>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-warning text-white w-25" (click)="funcYesClose()">Yes</button>
                    <button type="button" class="btn btn-secondary w-25" (click)="funcNoClose()">No</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalAlert5">
        <div class="modal-dialog modal-dialog-centered" role="document">  
            <div class="modal-content">
                <span class="bg-danger">&nbsp;</span>
                <div class="modal-header">
                    <div class="row col-12 text-danger">
                        <div class="col-2">
                            <em class="far fa-times-circle" style="font-size:50px;"></em>
                        </div>
                        <div class="col-10 pt-2">
                            <h4 class="modal-title">Alert !</h4>
                        </div>
                    </div>
                </div>
                <div class="modal-body text-body">
                    <div class="col-12" style="text-align: left;">
                        <span style="font-size:18px;">{{ alertdate }}</span>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger w-25" (click)="closealertdate()">OK</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalAlertdanger5">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <span class="bg-danger">&nbsp;</span>
                <div class="modal-header">
                    <div class="row col-12 text-danger">
                        <div class="col-2">
                            <em class="far fa-times-circle" style="font-size:50px;"></em>
                        </div>
                        <div class="col-10 pt-2">
                            <h4 class="modal-title">Alert !</h4>
                        </div>
                    </div>
                </div>
                <div class="modal-body text-body border-0">
                    <div class="col-12" style="text-align: left;">
                        <span style="font-size:18px;">{{ alertflow }}</span>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger w-25" (click)="closeflow()">OK</button>
                </div>
            </div>
        </div>
    </div>

        <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalBU5">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Please Select Business Unit for Register Risk Profile</h5>
                    </div>
                    <div class="modal-body">
                        <div class="col-12" *ngIf="ShowWPB === true">
                            <div class="row">
                                <div class="col-4 pt-2">
                                    <strong style="font-size:18px;">Select Type</strong>
                                </div>
                                <div class="col-8 px-0">
                                    <select class="form-control" [(ngModel)]="SelectWPB">
                                        <option value="1">Normal</option>
                                        <option value="2">WPB</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row col-12 mx-0 px-0">
                            <div class="col-4 pt-2">
                                <strong style="font-size:18px;">Business Unit Name</strong>
                            </div>
                            <div class="col-8 px-0 pt-2">

                                <input type="text" class="form-control m-0" aria-label="Number" matInput [formControl]="myControlBUDD" [matAutocomplete]="auto1" [(ngModel)]="tempselectbu"  style="font-size:16px;">
                                <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete">
                                    <mat-option *ngFor="let option of filteredOptionBUDD | async" [value]="option.text">
                                    {{option.text}}: {{option.fullname}}
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="col-12" style="text-align: center;">
                            <button type="button" style="width:100px;" class="btn btn-primary mr-2" (click)="func_OkBU()">OK</button>
                            <button type="button" style="width:100px;" class="btn btn-secondary" (click)="goHome()">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalAlertBU">
        <div class="modal-dialog modal-dialog-centered" role="document"> 
            <div class="modal-content">
                <span class="bg-danger">&nbsp;</span>
                <div class="modal-header">
                    <div class="row col-12 text-danger">
                        <div class="col-2">
                            <em class="far fa-times-circle" style="font-size:50px;"></em>
                        </div>
                        <div class="col-10 pt-2">
                            <h4 class="modal-title">Alert !</h4>
                        </div>
                    </div>
                </div>
                <div class="modal-body text-body">
                    <div class="col-12" style="text-align: left;">
                        <span style="font-size:18px;">{{ alert }}</span>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger w-25" (click)="close()">OK</button>
                </div>
            </div>
        </div>
    </div>

  <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalCommentCloseAlert">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <span class="bg-info">&nbsp;</span>
            <div class="modal-header">
                <div class="row col-12 text-info">
                    <div class="col-2">
                      <em class="fas fa-info-circle" style="font-size:50px;"></em>
                    </div>
                    <div class="col-10 pt-2">
                        <h4 class="modal-title">Alert !</h4>
                    </div>
                </div>
            </div>
            <div class="modal-body text-body border-0">
                <div class="col-12" style="text-align: left;">
                    <span style="font-size:18px;">Comment</span>
                    <input type="text" class="form-control" id="CloseComment" [(ngModel)]="CloseComment" (input)="CloseComment=$event.target.value">
                    <p id="Consolecomment" [hidden]="true" style="color: red;">Risk status close, Comment is required.</p>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-info w-25 text-white" (click)="func_CloseCommentYes(CloseCommentFlag)">Yes</button>
                <button type="button" class="btn btn-secondary w-25" (click)="func_CloseCommentNo()" data-dismiss="modal">No</button>
            </div>
        </div>
    </div>
  </div>  <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalCommentCloseOtherAlert">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <span class="bg-info">&nbsp;</span>
            <div class="modal-header">
                <div class="row col-12 text-info">
                    <div class="col-2">
                      <em class="fas fa-info-circle" style="font-size:50px;"></em>
                    </div>
                    <div class="col-10 pt-2">
                        <h4 class="modal-title">Alert !</h4>
                    </div>
                </div>
            </div>
            <div class="modal-body text-body border-0">
                <div class="col-12" style="text-align: left;">
                    <span style="font-size:18px;">Comment</span>
                    <textarea class="form-control" 
                                    autosize
                                    id="Risk_Close_Reason" rows="3" 
                                    [(ngModel)]="Risk_Close_Reason"
                                    (input)="Risk_Close_Reason=$event.target.value"
                                    ></textarea>
                    <!-- <input type="text" class="form-control" id="Risk_Close_Reason" [(ngModel)]="Risk_Close_Reason" (input)="Risk_Close_Reason=$event.target.value"> -->
                    <p id="Consolecomment2" [hidden]="true" style="color: red;">Risk status close, Comment is required.</p>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-info w-25 text-white" (click)="func_CommentCloseOtherYes(CloseCommentFlag)">Yes</button>
                <button type="button" class="btn btn-secondary w-25" (click)="func_CommentCloseOtherNo()" data-dismiss="modal">No</button>
            </div>
        </div>
    </div>
  </div>
</div>