import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
declare const $;

@Component({
  selector: 'app-modal-alert',
  templateUrl: './modal-alert.component.html',
  styleUrls: ['./modal-alert.component.css']
})

export class ModalAlertComponent implements OnInit {

  constructor() { }

  alertflow = '';
  @Input() title: string;
  @Input() titleSuccess: string = 'Success';
  @Input() message: string = 'Success';
  @Input() isRefresh: boolean = false;
  @Output() confirmed = new EventEmitter<void>();
  @Output() canceled = new EventEmitter<void>();
  @Output() success = new EventEmitter<void>();

  ngOnInit(): void {
  }

  closeAlert() {
    $('#modalAlert').modal('hide');
    if (this.isRefresh == true)
    {
      window.location.reload();
    }
  }

  closeAlertSuccess() {
    $('#modalSuccess').modal('hide');
    //console.log("isRefresh : ", this.isRefresh);
    this.success.emit();
    if (this.isRefresh == true)
    {
      window.location.reload();
    }
  }

  confirm() {
    this.confirmed.emit();
    $('#modalConfirm').modal('hide');
  }

  cancel() {
    this.canceled.emit();
    $('#modalConfirm').modal('hide');
  }
}
