<div class="container-fluid appform my-3">
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">
            <div class="col-12 col-md-12 col-lg-12 col-xl-12 pb-5 mt-5 mb-5">

                <div class="px-0 pb-2">
                    <div class="card col-12 mt-4 mx-0 px-3">
                        <div class="financial col-12 mb-3 rounded">
                            <div class="col-0 pt-2">
                                <em class="fas fa-bars icon"></em>&nbsp;&nbsp;
                                Business Unit Financial
                            </div>
                        </div>

                        <div class="col-12 px-0" style="text-align: right;">
                            <!-- <button type="button" class="btn btn-lg mb-3"
                                style="color:white;background:green;margin: 5px;" (click)="func_OpenEditFinancialScale()"><em
                                class="fas fa-edit icon"></em>&nbsp;&nbsp;&nbsp;Edit Financial Scale
                            </button> -->
                            <button type="button" class="btn btn-lg mb-3"
                                style="color:white;background:green;margin: 5px;"
                                (click)="func_OpenEditFinancialScale('NI')"><em
                                    class="fas fa-edit icon"></em>&nbsp;&nbsp;&nbsp;Edit Financial Scale (NI)
                            </button>

                            <button type="button" class="btn btn-lg mb-3"
                                style="color:white;background:green;margin: 5px;"
                                (click)="func_OpenEditFinancialScale('NPV')"><em
                                    class="fas fa-edit icon"></em>&nbsp;&nbsp;&nbsp;Edit Financial Scale (NPV)
                            </button>

                            <button type="button" class="btn btn-lg mb-3"
                                style="color:white;background:green;margin: 5px;" (click)="func_importEx()"><em
                                    class="fas fa-plus icon"></em>&nbsp;&nbsp;&nbsp;Import Excel
                            </button>
                        </div>
                        <div class="col-12 px-0 mb-3">
                            <ul class="nav nav-pills mb-1" id="pills-tab" role="tablist">
                                <li class="nav-item" style="margin: 1px 1px 1px 0px;"
                                    *ngFor="let item of DataFG index as index1">
                                    <a class="nav-link active" data-toggle="pill" id="BUCO{{ index1 + 1 }}" href="#"
                                        role="tab" (click)="SelectFG(item , index1)" *ngIf="index1 === 0">{{ item.title
                                        }}</a>
                                    <a class="nav-link" data-toggle="pill" id="BUCO{{ index1 + 1 }}" href="#" role="tab"
                                        (click)="SelectFG(item , index1)" *ngIf="index1 !== 0">{{ item.title }}</a>
                                </li>
                            </ul>

                            <table class="table-bordered" style="width:100%;">
                                <caption style="display: none;">This is a custom table</caption>
                                <thead style="text-align:center; background-color:#033153; color:white;">
                                    <tr>
                                        <th style="width: 80px;vertical-align: middle;">FG</th>
                                        <th style="width: 80px;vertical-align: middle;">DIV</th>
                                        <th style="width: 80px;vertical-align: middle;">DEPT</th>
                                        <th style="width: 80px;vertical-align: middle;">Asset Name</th>
                                        <th style="width: 80px;vertical-align: middle;">NI</th>
                                        <th style="width: 80px;vertical-align: middle;">NPV/EMV</th>
                                        <th style="width: 10px;vertical-align: middle;">Edit</th>
                                    </tr>
                                </thead>
                                <tbody style="background-color:white;">
                                    <tr *ngFor="let item of OptimizedData index as index1">
                                        <td style="text-align: center;">{{ item.FG }}</td>
                                        <td style="text-align: center;">{{ item.DIV }}</td>
                                        <td style="text-align: center;">{{ item.DEPT }}</td>
                                        <td style="text-align: center;">{{ item.Asses }}</td>
                                        <td style="text-align: center;">{{ item.ni }}</td>
                                        <td style="text-align: center;">{{ item.npV_EMV }}</td>
                                        <td style="text-align: center;">
                                            <button type="button" class="btn" (click)="func_addfinancial(item,'Edit')"
                                                *ngIf="item.businessID !== undefined && tempchkyear == currentyear">
                                                <em class="fas fa-edit" style="color:orange;font-size:18px;"></em>
                                            </button>
                                            <button type="button" class="btn" (click)="func_addfinancial(item,'Add')"
                                                *ngIf="item.businessID == undefined && tempchkyear == currentyear">
                                                <em class="fas fa-edit" style="color:orange;font-size:18px;"></em>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="modal fade bd-Risk Financial" tabindex="-1" role="dialog"
                    aria-labelledby="myLargeModalLabel" aria-hidden="true" id="modaladdfinancial">
                    <div class="modal-dialog modal-xl">
                        <div class="modal-content">
                            <div class="container-fluid appform my-3">
                                <div class="row">
                                    <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">
                                        <div class="container col-12 col-md-12 col-lg-12 col-xl-12 pb-0 mt-0 mb-0 ">
                                            <div class="container">

                                                <div class="col-12 mb-3 rounded modalfinancial">
                                                    <div class="col-0 pt-2">
                                                        <em class="fas fa-bars icon"></em>&nbsp;&nbsp;Financial Impact
                                                        Criteria
                                                    </div>
                                                </div>
                                                <div class="card mt-4">
                                                    <div class="col-12 col-xl-12 mb-4">

                                                        <div class="row col-12 mx-3 px-0">

                                                            <div class="row col-12 col-xl-3">
                                                                <div class="col-12 my-2">
                                                                    <strong class="formtext">Year</strong>
                                                                </div>
                                                                <div class="col-12">
                                                                    <select class="form-control"
                                                                        [(ngModel)]="model.Year"
                                                                        (change)="InertEditSelectedYear('')" disabled>
                                                                        <option value="">Please Select</option>
                                                                        <option *ngFor="let data of YearDD"
                                                                            [value]="data.text"
                                                                            [disabled]="data.text != currentyear">
                                                                            {{data.text}}</option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div class="row col-12 col-xl-3">
                                                                <div class="col-12 my-2">
                                                                    <strong class="formtext">Quarter</strong>
                                                                </div>
                                                                <div class="col-12">
                                                                    <select class="form-control"
                                                                        [(ngModel)]="model.Quarter" disabled>
                                                                        <option value="">Please Select</option>
                                                                        <option *ngFor="let data of QuarterDD"
                                                                            [value]="data.value">{{data.text}}</option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div class="row col-12 col-xl-3">
                                                                <div class="col-12 my-2">
                                                                    <strong class="formtext">Business ID</strong>
                                                                </div>
                                                                <div class="col-12">
                                                                    <input type="text" class="form-control m-0"
                                                                        [(ngModel)]="BusinessCode" disabled>
                                                                </div>
                                                            </div>

                                                            <div class="row col-12 col-xl-3">
                                                                <div class="col-12 my-2">
                                                                    <strong class="formtext">Business Name</strong>
                                                                </div>
                                                                <div class="col-12">
                                                                    <input type="text" class="form-control m-0"
                                                                        aria-label="Number" matInput
                                                                        [formControl]="myControlBUDD"
                                                                        [matAutocomplete]="auto2"
                                                                        [(ngModel)]="BusinessUnit"
                                                                        (keyup.enter)="func_selectCoorBU(BusinessUnit)">
                                                                    <mat-autocomplete autoActiveFirstOption
                                                                        #auto2="matAutocomplete">
                                                                        <mat-option
                                                                            *ngFor="let option of filteredOptionsBUDD | async"
                                                                            [value]="option.text"
                                                                            (click)="func_selectCoorBU(option.text)">
                                                                            {{option.text}}
                                                                        </mat-option>
                                                                    </mat-autocomplete>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div class="row col-12 mx-3 px-0 mt-2">

                                                            <div class="row col-12 col-xl-3">
                                                                <div class="col-12 my-2">
                                                                    <strong class="formtext">NI:</strong>
                                                                </div>
                                                                <div class="col-12">
                                                                    <input type="text" class="form-control"
                                                                        placeholder="NI" [(ngModel)]="NI">
                                                                </div>
                                                            </div>

                                                            <div class="row col-12 col-xl-5">
                                                                <div class="col-12 my-2">
                                                                    <strong class="formtext">NPV/EMV:</strong>
                                                                </div>
                                                                <div class="row col-12">
                                                                    <div class="col-7 col-xl-7">
                                                                        <input type="text" class="form-control"
                                                                            placeholder="NPV/EMV" [(ngModel)]="NPV_EMV">
                                                                    </div>
                                                                    <div class="col-5 col-xl-5">
                                                                        <strong class="formtext">(MM USD)</strong>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="col-12 my-3 mx-0 text-center">
                                                    <button type="button" class="btn mr-3"
                                                        style="width: 100px;background-color:#9eb0ba;color:white;"
                                                        (click)="func_closebtn()">Close</button>
                                                    <button type="button" class="btn"
                                                        style="width: 100px;background-color:#033153;color:white;"
                                                        (click)="func_savebtn()">Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade bd-Risk Coordinators" tabindex="-1" role="dialog"
                    aria-labelledby="myLargeModalLabel" aria-hidden="true" id="modalimportexcel">
                    <div class="modal-dialog modal-xl">
                        <div class="modal-content">
                            <div class="container-fluid appform my-3">
                                <div class="row">
                                    <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">
                                        <div class="container col-12 col-md-12 col-lg-12 col-xl-12 pb-0 mt-0 mb-0 ">
                                            <div class="container">
                                                <div class="col-12 mb-3 rounded modalfinancial">
                                                    <div class="col-0 pt-2">
                                                        <em class="fas fa-bars icon"></em>&nbsp;&nbsp;Export Excel
                                                    </div>
                                                </div>
                                                <div class="card mt-4">
                                                    <div class="col-12 col-xl-12 mb-4 px-0">

                                                        <div class="row col-12 mb-4">
                                                            <div class="row col-12 col-xl-12">
                                                                <div class="col-4">
                                                                    <input type="file" (change)="onFileChange($event)"
                                                                        #inputFile />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row col-12 mb-4">
                                                            <div class="row col-12 col-xl-12">
                                                                <div class="col-5">
                                                                    <button type="button" class="btn mr-3"
                                                                        style="color:white;background:green;"
                                                                        (click)="func_getdataExport()">Template
                                                                        Excel</button>
                                                                    <button type="button" class="btn btn-danger"
                                                                        (click)="removeData()">Remove Data</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="table-coordinator">
                                                    <table class="table table-bordered">
                                                        <caption style="display: none;">This is a custom table</caption>
                                                        <thead style="text-align: center;">
                                                            <tr style="background-color:#033153;color:white;">
                                                                <th *ngFor="let key of keys">
                                                                    {{key}}
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody style="background-color: white;">
                                                            <tr *ngFor="let item of dataSheet | async">
                                                                <td *ngFor="let key of keys">
                                                                    {{item[key]}}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="col-12 my-3 text-center">
                                                    <button type="button" class="btn mr-3"
                                                        style="width:100px;background-color:#9eb0ba;color:white;"
                                                        (click)="func_closeImportEx()">Close</button>
                                                    <button type="button" class="btn"
                                                        style="width:100px;background-color:#033153;color:white;"
                                                        (click)="importexcel()">Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Modal Edit Financial Scale -->
                <div class="modal fade bd-Risk Coordinators" tabindex="-1" role="dialog"
                    aria-labelledby="myLargeModalLabel" aria-hidden="true" id="modalFinancialScale">
                    <div class="modal-dialog modal-xl">
                        <div class="modal-content">
                            <div class="container-fluid appform my-3">
                                <div class="row">
                                    <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">
                                        <div class="container col-12 col-md-12 col-lg-12 col-xl-12 pb-0 mt-0 mb-0 ">
                                            <div class="container">
                                                <div class="col-12 mb-3 rounded modalfinancial">
                                                    <div class="col-0 pt-2">
                                                        <em class="fas fa-bars icon"></em>&nbsp;&nbsp;Edit Financial
                                                        Scale
                                                    </div>
                                                </div>
                                                <!-- FORM -->
                                                <div class="card mt-4">
                                                    <div class="col-12 col-xl-12 mb-4 px-0">
                                                        <div class="row col-12 mx-3 px-0" [hidden]="1 == 1">

                                                            <div class="row col-12 col-xl-3">
                                                                <div class="col-12 my-2">
                                                                    <strong class="formtext">Year</strong>
                                                                </div>
                                                                <div class="col-12">
                                                                    <select class="form-control"
                                                                        [(ngModel)]="model.Year"
                                                                        (change)="InertEditSelectedYear('')" disabled>
                                                                        <option value="">Please Select</option>
                                                                        <option *ngFor="let data of YearDD"
                                                                            [value]="data.text"
                                                                            [disabled]="data.text != currentyear">
                                                                            {{data.text}}</option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div class="row col-12 col-xl-3">
                                                                <div class="col-12 my-2">
                                                                    <strong class="formtext">Quarter</strong>
                                                                </div>
                                                                <div class="col-12">
                                                                    <!-- [disabled]="model.Year === ''" -->
                                                                    <select class="form-control"
                                                                        [(ngModel)]="model.Quarter" disabled>
                                                                        <option value="">Please Select</option>
                                                                        <!-- QuarterDD2 -->
                                                                        <option *ngFor="let data of QuarterDD"
                                                                            [value]="data.value">{{data.text}}</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row col-12 mx-3 px-0">
                                                            <div class="row col-12 col-xl-3">
                                                                <div class="col-12 my-2">
                                                                    <strong class="formtext"
                                                                        style="font-weight: bold;">{{ model.type
                                                                        }}</strong>
                                                                </div>
                                                                <div class="col-12">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row col-12 mx-3 px-0">
                                                            <div *ngFor='let data of financialScale index as index'
                                                                class="col-12 col-xl-12">
                                                                <div
                                                                    class="row col-12 col-xl-12 mt-3 mb-3 mx-0 px-0 d-flex justify-content-around">
                                                                    <div class="col-2 mb-2 px-0">
                                                                        <strong
                                                                            class="formtext">{{data.ImpactCateItemName}}</strong>
                                                                    </div>
                                                                    <div class="col-2">
                                                                        <div class="input-group">
                                                                            <div class="input-group-prepend"
                                                                                style="height: 35px">
                                                                                <span class="input-group-text"
                                                                                    id="basic-addon1"
                                                                                    *ngIf="index === 0">></span>
                                                                                <span class="input-group-text"
                                                                                    id="basic-addon1"
                                                                                    *ngIf="index !== 0 && index !== 4">></span>
                                                                                <span class="input-group-text"
                                                                                    id="basic-addon1"
                                                                                    *ngIf="index === 4">≤</span>
                                                                            </div>

                                                                            <input type="text"
                                                                                class="form-control input-group-text"
                                                                                [(ngModel)]="financialScale[index === 4 ? 3 : index].ValEditScreenPercent1"
                                                                                (keypress)="numberOnly($event)"
                                                                                [disabled]="index === 4">

                                                                        </div>
                                                                    </div>
                                                                    <div class="col-2">
                                                                        <div class="input-group">
                                                                            <div class="input-group-prepend"
                                                                                style="height: 35px">
                                                                                <span class="input-group-text"
                                                                                    id="basic-addon1"
                                                                                    *ngIf="index !== 0 && index !== 4">-</span>
                                                                            </div>
                                                                            <input type="text"
                                                                                class="form-control input-group-text"
                                                                                disabled
                                                                                *ngIf="index !== 0 && index !== 4"
                                                                                [(ngModel)]="financialScale[index-1].ValEditScreenPercent1">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-4">

                                                                    </div>
                                                                </div>
                                                                <!-- <div class="row col-12 col-xl-12 mt-3 mb-3 mx-0 px-0 d-flex justify-content-around">
                                                    <div class="row col-12 col-xl-2">
                                                        <div class="col-12 mb-2 px-0">
                                                            <strong class="formtext">{{data.impactCateItemName}}</strong>
                                                        </div>
                                                    </div>
                                                    <div class="row col-12 col-xl-10">
                                                        <div class="col-12 mb-2 px-0">
                                                            <input type="text" class="form-control" [(ngModel)]="financialScale[index].decimalCalPercent1"> - <input type="text" class="form-control" [(ngModel)]="financialScale[index].decimalCalPercent2">
                                                        </div>
                                                    </div>
                                                </div> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- End FORM -->
                                                <div class="col-12 my-3 text-center">
                                                    <button type="button" class="btn mr-3"
                                                        style="width:100px;background-color:#9eb0ba;color:white;"
                                                        (click)="func_closeEditFinancialScale()">Close</button>
                                                    <button type="button" class="btn"
                                                        style="width:100px;background-color:#033153;color:white;"
                                                        (click)="func_EditFinancialScale()">Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!---------- End Modal ------------->


                <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalAlertdanger5">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <span class="bg-danger">&nbsp;</span>
                            <div class="modal-header">
                                <div class="row col-12 text-danger">
                                    <div class="col-2">
                                        <em class="far fa-times-circle" style="font-size:50px;"></em>
                                    </div>
                                    <div class="col-10 pt-2">
                                        <h4 class="modal-title">Alert !</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-body text-body border-0">
                                <div class="col-12" style="text-align: left;">
                                    <span style="font-size:18px;">{{ alertflow }}</span>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-danger w-25" (click)="closeAlert()">OK</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading [loading]='loading'></app-loading>