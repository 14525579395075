import {  Component, OnInit, ViewChild } from '@angular/core';
import { formatDate } from '@angular/common';
import { MonitorAndReviewService } from 'src/app/StrategicRisks/services/monitorandreview.service';
import { RiskfromStrategyComponent } from 'src/app/StrategicRisks/shared/components/riskfrom-strategy/riskfrom-strategy.component';
import { StrategicAssumptionComponent } from 'src/app/StrategicRisks/shared/components/strategic-assumption/strategic-assumption.component';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActionLogModel } from 'src/app/StrategicRisks/shared/model/ActionLogModel';

declare let $;

@Component({
  selector: 'app-register-assessment-review',
  templateUrl: './register-assessment-review.component.html',
  styleUrls: ['./register-assessment-review.component.css'],
})
export class RegisterAssessmentReviewComponent
  implements OnInit
{
  @ViewChild(RiskfromStrategyComponent)
  RiskfromStrategy: RiskfromStrategyComponent;
  @ViewChild(StrategicAssumptionComponent)
  StrategicAssumption: StrategicAssumptionComponent;
  constructor(
    public service: MonitorAndReviewService,
    private router: Router,
    private routerParam: ActivatedRoute
  ) {}
  id: string;
  data: any;
  riskStartDate;
  riskEndDate;
  no_End_Date;
  riskName;
  Data_SessionStorage;
  empid = '';
  empRole = '';
  createBy = '';
  closeButtonEdit = false;
  actionLogLst: ActionLogModel[] = [];
  mainForm = new FormGroup({
    riskId: new FormControl(null),
    riskName: new FormControl(null, Validators.required),
    riskStartDate: new FormControl(null, Validators.required),
    riskEndDate: new FormControl(null, Validators.required),
    noEndDate: new FormControl(false, Validators.required)
  })

  dataAlert :{title:string,message:string,isRefresh:boolean,status?:string};
  public message: any;
  ngOnInit(): void {
    this.id = this.routerParam.snapshot.paramMap.get('Id');
    this.Data_SessionStorage = JSON.parse(sessionStorage.datainfo);
    this.empid = this.Data_SessionStorage[0].employeE_ID;
    this.empRole = sessionStorage.getItem('buRole');
    this.getdata(this.id);
    this.actionLogLst = [];
  }
  getdata(id) {
    this.getReview(id);
  }
  setDate(value) {
    let [day, month, year] = formatDate(
      new Date(value),
      'dd:MM:yyyy:HH:mm:ss',
      'en-US'
    )
      .split(':')
      .map((m) => m);
    return day + '/' + month + '/' + (Number(year));
  }
  onNoEndDateChange(event)
  {
    if(event.target.checked)
    {
      this.mainForm.controls.riskEndDate.setValue(null);
      this.mainForm.controls.riskEndDate.disable();
    }
    else
    {
      this.mainForm.controls.riskEndDate.enable();
      this.mainForm.controls.riskEndDate.markAllAsTouched();
    }
  }

  
  buildActionLog(section){
    let logData = new ActionLogModel;
    logData.riskId = Number(this.mainForm.controls.riskId.getRawValue()) ?? null;
    logData.createDate = (new Date()).toLocaleString('en-US', { timeZone: 'Asia/Bangkok' });
    
    logData.createBy = this.empid;
    if(logData.riskId)
    {
      if(section == 'Revise')
      {
        logData.action = section;
      }
      else
      {
        logData.section = section;
        logData.action = 'Revise';
      }
    }
    return logData
  }

  childAddLogEvent(event) {
    let childLog = this.buildActionLog(event);
    let indexlog = this.actionLogLst.findIndex(w => w.section == event);
    if(indexlog != -1)
    {
      this.actionLogLst[indexlog].createDate = (new Date()).toLocaleString('en-US', { timeZone: 'Asia/Bangkok' });
    }
    else
    {
      this.actionLogLst.push(childLog);
    }
  }




  getReview(param) {
    this.service
      .GetReviewById(param)
      .then((response) => {
        if (response.data.status) {
          this.data = response.data.body;
          this.mainForm.patchValue(response.data.body);
          this.mainForm.controls.noEndDate.setValue(response.data.body.no_End_Date)
          if(this.mainForm.controls.noEndDate.getRawValue())
          {
            this.mainForm.controls.riskEndDate.setValue(null);
            this.mainForm.controls.riskEndDate.disable();
          }
          if (this.empid == this.data.riskRegisterBy || !this.empRole.includes('Staff')) {
            this.closeButtonEdit = true;
          }
          else
          {
            this.mainForm.disable();
          }
          this.createBy = this.data.riskRegisterBy;
          this.riskName =this.data.riskName;
          this.riskStartDate =this.data.riskStartDate?this.setDate(this.data.riskStartDate):'';
          let setriskEndDate =this.data.riskEndDate?this.setDate(this.data.riskEndDate):'';
          this.riskEndDate =this.data.no_End_Date?'':setriskEndDate;
          this.no_End_Date = this.data.no_End_Date;
        } else {
          alert(response.data.errorMessage);
        }
      })
      .catch((e) => {});
  }
  fun_ConfirmReview(data){
    if(this.actionLogLst.length == 0)
    {
      this.actionLogLst.push(this.buildActionLog("Revise"));
    }
    this.data.strategicRiskLogList = this.actionLogLst;
    this.service
      .ConfirmReview(data)
      .then((response) => {
        if (response.data.status) {
          this.dataAlert ={title:'Alert !',message:'Revise Success',isRefresh:true};
          $('#modalSuccess').modal('show');
        } else {
          this.dataAlert ={title:'Alert !',message:response.data.errorMessage,isRefresh:false};
          $('#modalAlert').modal('show');
        }
      })
      .catch((e) => {
        this.dataAlert ={title:'Alert !',message:e.message,isRefresh:false};
        $('#modalAlert').modal('show');
      });
  }
  fun_Back(){
    this.router.navigate(['/Monitor-Review']); 
  }
  fun_modalAlertError(item1,item2){
    let itemMessage ='';
    if(!item1.status){
      itemMessage= item1.message;
    }
    else if(!item2.status){
      itemMessage= item2.message;
    }
    this.dataAlert ={title:'Alert !',message:itemMessage,isRefresh:false};
    $('#modalAlert').modal('show');
  }
   actionEvents(event:string):void {
    let item1:{status:boolean,message:string} =this.RiskfromStrategy.getform();
    let item2:{status:boolean,message:string} =this.StrategicAssumption.getform();

    switch (event) {
      case 'Confirm':  
          if (item1.status && item2.status) {
            this.data = this.RiskfromStrategy.getData(this.data);
            this.data = this.StrategicAssumption.getData(this.data);
            this.data.riskStatus = 'Revised';
            this.data.riskEndDate = this.mainForm.controls.riskEndDate.getRawValue() ? moment(this.mainForm.controls.riskEndDate.getRawValue()).format("YYYY-MM-DD") : null;
            this.data.no_End_Date = this.mainForm.controls.noEndDate.getRawValue();
            return  this.fun_ConfirmReview(this.data);
          }else{
            this.dataAlert ={title:'Alert !',message:'Please fill-in the information.',isRefresh:false};
            return $('#modalAlert').modal('show');
          }
      case 'Back':  
           return this.fun_Back(); 
      case 'checkfromAssumptions':  
          if(this.mainForm.invalid){
            this.dataAlert ={title:'Alert !',message:'Please fill-in the information.',isRefresh:false};
            return $('#modalAlert').modal('show');
          } else if (item1.status && item2.status) {
            this.dataAlert ={title:'Alert !',message:'Confirm to Revise this Strategy ?',isRefresh:false,status:'Confirm'};
            return $('#modalConfirm').modal('show');
          }else{
            return this.fun_modalAlertError(item1,item2);
          }
      case 'checkfromBack':  
          if(this.closeButtonEdit){
            this.dataAlert ={title:'Alert !',message:'All enter or edit information will be lost. Are you sure you want to close ?',isRefresh:false,status:'Back'};
            return $('#modalConfirm').modal('show');
          }else{
            return this.fun_Back(); 
          }
      default: 
          this.dataAlert ={title:'Alert !',message:'Please fill-in the information.',isRefresh:false};
          return $('#modalAlert').modal('show'); 
     } 
  }
}
