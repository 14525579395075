import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { DatePipe } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class ExcelService2 {

  constructor(private datePipe: DatePipe) {
  }

  async generateExcel() {
    const title = 'Car Sell Report';
    const header = ['Year', 'Month', 'Make', 'Model', 'Quantity', 'Pct'];
    const data = [
      [2007, 1, 'Volkswagen ', 'Volkswagen Passat', 1267, 10],
      [2007, 1, 'Toyota ', 'Toyota Rav4', 819, 6.5],
      [2007, 1, 'Toyota ', 'Toyota Avensis', 787, 6.2],
      [2007, 1, 'Volkswagen ', 'Volkswagen Golf', 720, 5.7],
      [2007, 1, 'Toyota ', 'Toyota Corolla', 691, 5.4],
      [2007, 1, 'Peugeot ', 'Peugeot 307', 481, 3.8],
      [2008, 1, 'Toyota ', 'Toyota Prius', 217, 2.2],
      [2008, 1, 'Skoda ', 'Skoda Octavia', 216, 2.2],
      [2008, 1, 'Peugeot ', 'Peugeot 308', 135, 1.4],
      [2008, 2, 'Ford ', 'Ford Mondeo', 624, 5.9],
      [2008, 2, 'Volkswagen ', 'Volkswagen Passat', 551, 5.2],
      [2008, 2, 'Volkswagen ', 'Volkswagen Golf', 488, 4.6],
      [2008, 2, 'Volvo ', 'Volvo V70', 392, 3.7],
      [2008, 2, 'Toyota ', 'Toyota Auris', 342, 3.2],
      [2008, 2, 'Volkswagen ', 'Volkswagen Tiguan', 340, 3.2],
      [2008, 2, 'Toyota ', 'Toyota Avensis', 315, 3],
      [2008, 2, 'Nissan ', 'Nissan Qashqai', 272, 2.6],
      [2008, 2, 'Nissan ', 'Nissan X-Trail', 271, 2.6],
      [2008, 2, 'Mitsubishi ', 'Mitsubishi Outlander', 257, 2.4],
      [2008, 2, 'Toyota ', 'Toyota Rav4', 250, 2.4],
      [2008, 2, 'Ford ', 'Ford Focus', 235, 2.2],
      [2008, 2, 'Skoda ', 'Skoda Octavia', 225, 2.1],
      [2008, 2, 'Toyota ', 'Toyota Yaris', 222, 2.1],
      [2008, 2, 'Honda ', 'Honda CR-V', 219, 2.1],
      [2008, 2, 'Audi ', 'Audi A4', 200, 1.9],
      [2008, 2, 'BMW ', 'BMW 3-serie', 184, 1.7],
      [2008, 2, 'Toyota ', 'Toyota Prius', 165, 1.6],
      [2008, 2, 'Peugeot ', 'Peugeot 207', 144, 1.4]
    ];

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Car Data');

    const titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Comic Sans MS', family: 4, size: 16, underline: 'double', bold: true };
    worksheet.addRow([]);


    worksheet.addRow([]);

    const headerRow = worksheet.addRow(header);

    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: 'FF0000FF' }
      };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    });

    data.forEach(d => {
      const row = worksheet.addRow(d);
      const qty = row.getCell(5);
      let color = 'FF99FF99';
      if (+qty.value < 500) {
        color = 'FF9999';
      }

      qty.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: color }
      };
    }
    );

    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 30;
    worksheet.addRow([]);

    const footerRow = worksheet.addRow(['This is system generated excel sheet.']);
    footerRow.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFCCFFE5' }
    };
    footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

    worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);

    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'CarData.xlsx');
    });

  }

  async generateExcel_Custom1(Tabledata) {
    const title = 'Risk Profile';
    const header = ['RiskID', 'Risk Quarter', 'Risk Name', 'Business Unit', 'Status', 'Likelihood', 'Impact Level', 'Risk Rating', 'KRI Status'];
    const tempdata = [
      ['Volkswagen', 'Passat', 20],
      ['BMW', 'LOGO', 25],
      ['Toyota', 'Volk', 20],
    ];

    const data = [];
    Tabledata.forEach(function (ItemVal, ItemIndex) {
      data.push([
        ItemVal.riskid,
        ItemVal.riskquarter,
        ItemVal.riskname,
        ItemVal.riskbu,
        ItemVal.riskstatus,
        ItemVal.risklikelihood,
        ItemVal.riskimpactlevel,
        ItemVal.riskrating,
        ItemVal.riskKRIstatus
      ])
    });


    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Risk_Profile');

    const titleRow = worksheet.addRow([title]);
    titleRow.font = { size: 20, bold: true };
    worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet.mergeCells('A1:J1');

    const headerRow = worksheet.addRow(header);
    headerRow.font = { size: 16, bold: true, 'color': { 'argb': 'ffffff' } };
    worksheet.getRow(2).alignment = { vertical: 'middle', horizontal: 'center' };

    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '17a2b8' },
        bgColor: { argb: '17a2b8' }
      };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    });

    data.forEach((d, index) => {
      
      const row = worksheet.addRow(d);
      row.font = { size: 14 };
      row.alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.getCell('C' + (3 + index)).alignment = { vertical: 'middle', horizontal: 'left' };
      row.eachCell((cell, number) => {
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      });
    });

    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 100;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 20;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 20;
    worksheet.getColumn(9).width = 20;
    worksheet.addRow([]);

    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Risk_Profile.xlsx');
    });

  }

  async generateExcel_Custom2(Tabledata) {
    const title = 'Root Cause Mitigation Plan';
    const header = ['Root Cause', 'Mitigation', 'Plan', 'Owner', 'Due Date', 'Progress Justification'];
    const data = [];
    Tabledata.forEach(function (ItemVal, ItemIndex) {
      ItemVal.progress = (ItemVal.progress === null) ? '0' : ItemVal.progress
      data.push([
        ItemVal.header,
        ItemVal.mitigationID,
        ItemVal.plan,
        ItemVal.owner,
        ItemVal.date,
        ItemVal.progress + '%',
      ])
    });

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Root_Cause_Mitigation_Plan');

    const titleRow = worksheet.addRow([title]);
    titleRow.font = { size: 20, bold: true };
    worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet.mergeCells('A1:F1');

    const headerRow = worksheet.addRow(header);
    headerRow.font = { size: 16, bold: true, 'color': { 'argb': 'ffffff' } };
    worksheet.getRow(2).alignment = { vertical: 'middle', horizontal: 'center' };

    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '28a745' },
        bgColor: { argb: '28a745' }
      };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    });

    let count = 0;
    data.forEach((d, index) => {
      
      const row = worksheet.addRow(d);
      row.font = { size: 14 };
      row.alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.getCell('C' + (3 + index)).alignment = { vertical: 'middle', horizontal: 'left' };

      row.eachCell((cell, number) => {
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      });
    });

    data.forEach((d, index) => {
      if (index !== 0) {
        if (data[index - 1][0] === data[index][0]) {
          count++;
        }
        else if (data[index - 1][0] !== data[index][0] && count !== 0) {
          worksheet.mergeCells('A' + (3 + ((index - 1) - count)) + ': A' + (3 + (index - 1)));
          count = 0;
        }
      }
    });

    worksheet.getColumn(1).width = 20;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 100;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 30;
    worksheet.addRow([]);

    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Root_Cause_Mitigation_Plan.xlsx');
    });

  }

  async generateExcel_Custom3(Tabledata) {
    const title = 'Impact Mitigation Plan';
    const header = ['Impact', 'Mitigation', 'Plan', 'Owner', 'Due Date', 'Progress Justification'];
    const data = [];
    Tabledata.forEach(function (ItemVal, ItemIndex) {
      data.push([
        ItemVal.header,
        ItemVal.mitigationID,
        ItemVal.plan,
        ItemVal.owner,
        ItemVal.date,
        ItemVal.progress + '%',
      ])
    });

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Root_Cause_Mitigation_Plan');

    const titleRow = worksheet.addRow([title]);
    titleRow.font = { size: 20, bold: true, 'color': { 'argb': 'ffffff' } };
    worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet.mergeCells('A1:F1');
    worksheet.getCell('A1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'ffc107' },
      bgColor: { argb: 'ffc107' }
    };
    worksheet.getCell('A1').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

    const headerRow = worksheet.addRow(header);
    headerRow.font = { size: 16, bold: true, 'color': { 'argb': 'ffffff' } };
    worksheet.getRow(2).alignment = { vertical: 'middle', horizontal: 'center' };

    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'ffc107' },
        bgColor: { argb: 'ffc107' }
      };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    });

    let count = 0;
    data.forEach((d, index) => {
      const row = worksheet.addRow(d);
      row.font = { size: 14 };
      row.alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.getCell('C' + (3 + index)).alignment = { vertical: 'middle', horizontal: 'left' };

      row.eachCell((cell, number) => {
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      });

    });

    data.forEach((d, index) => {
      if (index !== 0) {
        if (data[index - 1][0] === data[index][0]) {
          count++;
        }
        else if (data[index - 1][0] !== data[index][0] && count !== 0) {
          worksheet.mergeCells('A' + (3 + ((index - 1) - count)) + ': A' + (3 + (index - 1)));
          count = 0;
        }
      }
    });

    worksheet.getColumn(1).width = 25;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 100;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 30;
    worksheet.addRow([]);

    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Impact_Mitigation_Plan.xlsx');
    });

  }
  
  
 
  async generateExcel_MergeAll(table_RiskProfile, Tabledata) {

  // Excel Title, Header, Data
    // const title = ['','','','','Risk Profile','','','','','' ,'','','Root Cause Mitigation Plan','','','' ,'' ,'','','Impact Mitigation Plan','','',''];
    const title = ['Risk Profile','','','','','','','','','','Root Cause','','','','','','Impact','',''];
    // const header = ['RiskID', 'Version', 'Risk Name', 'Business Unit', 'Status', 'Likelihood', 'Impact Level', 'Risk Rating', 'Risk Type','', 'Root Cause', 'Likelihood Level', 'Mitigation Plan', 'Owner', 'Due Date', 'Progress Justification', '', 'Impact', 'Impact Level', 'Mitigation Plan', 'Owner', 'Due Date', 'Progress Justification'];
    const header = ['RiskID', 'Version', 'Risk Name', 'Business Unit', 'Workflow Status', 'Risk Status', 'Likelihood', 'Impact Level', 'Risk Rating', 'Risk Type', 'Root Cause', 'Likelihood Level', 'Mitigation Plan', 'Owner', 'Due Date', 'Progress Justification', 'Impact','Description','(MM USD)', 'Impact Level', 'Mitigation Plan', 'Owner', 'Due Date', 'Progress Justification'];
    const data = [];
   
    var count = 0;
    var countim = 0;

    Tabledata.forEach(function(ItemVal, ItemIndex) {
      if (ItemVal.rootCause !== null && ItemVal.rootCause !== undefined) {
        ItemVal.rootCause.forEach(function(ItemValroot, ItemIndexroot) {

            if (ItemValroot.rootCause_Mitigation !== null) {
            ItemValroot.rootCause_Mitigation.forEach(function(ItemValrootmi, ItemIndexrootmi) {
                  if (ItemVal.risk_Co_Id !== null) {
                    data.push([ 
                      ItemVal.risk_Co_Id,
                      ItemVal.version,
                      ItemVal.risk_Name,
                      ItemVal.abbreviation,
                      ItemVal.risk_Status_Name,
                      ItemVal.risk_Status,                      
                      ItemVal.risk_Likelihood,
                      ItemVal.risk_Impact,
                      ItemVal.risk_Rating,
                      ItemVal.riskWF,
                      // '',
                      ItemValroot.rootCause_Category,
                      // ItemIndexrootmi + 1,
                      // ItemValrootmi.rootCause_Id,
                      ItemValroot.rootCause_Likelihood,
                      ItemValrootmi.rootCause_Mitigation_Name,
                      ItemValrootmi.rootCause_Mitigation_Owner,
                      ItemValrootmi.rootCause_Mitigation_DueDate,
                      ItemValrootmi.rootCause_Mitigation_Progress + '%',
                      // '',

                      '',
                      '',//ohm
                      '',//ohm
                      '',
                      '',
                      '',
                      '',
                      '',
                    ]);
                    count++
                    countim++
                  }
                  else {
                    data.push([ 
                      ItemVal.risk_Staff_Id,
                      ItemVal.version,
                      ItemVal.risk_Name,
                      ItemVal.abbreviation,
                      ItemVal.risk_Status_Name,
                      ItemVal.risk_Status,
                      ItemVal.risk_Likelihood,
                      ItemVal.risk_Impact,
                      ItemVal.risk_Rating,
                      ItemVal.riskWF,
                      // '',
                      ItemValroot.rootCause_Category,
                      // ItemIndexrootmi + 1,
                       // ItemValrootmi.rootCause_Id,
                      ItemValroot.rootCause_Likelihood,
                      ItemValrootmi.rootCause_Mitigation_Name,
                      ItemValrootmi.rootCause_Mitigation_Owner,
                      ItemValrootmi.rootCause_Mitigation_DueDate,
                      ItemValrootmi.rootCause_Mitigation_Progress + '%',
                      // '',

                      '',
                      '',//ohm
                      '',//ohm
                      '',
                      '',
                      '',
                      '',
                      '',
                    ]);
                    count++
                    countim++
                  }
              });
            }
            else {
              if (ItemVal.risk_Co_Id !== null) {
                data.push([ 
                  ItemVal.risk_Co_Id,
                  ItemVal.version,
                  ItemVal.risk_Name,
                  ItemVal.abbreviation,
                  ItemVal.risk_Status_Name,
                  ItemVal.risk_Status,
                  ItemVal.risk_Likelihood,
                  ItemVal.risk_Impact,
                  ItemVal.risk_Rating,
                  ItemVal.riskWF,
                  // '',
                  ItemValroot.rootCause_Category,
                  ItemValroot.rootCause_Likelihood,
                  '',
                  '',
                  '',
                  '',

                  // '',

                  '',
                  '',//ohm
                  '',//ohm
                  '',
                  '',
                  '',
                  '',
                  '',
                ]);
                count++
                countim++
              }
              else {
                data.push([ 
                  ItemVal.risk_Staff_Id,
                  ItemVal.version,
                  ItemVal.risk_Name,
                  ItemVal.abbreviation,
                  ItemVal.risk_Status_Name,
                  ItemVal.risk_Status,
                  ItemVal.risk_Likelihood,
                  ItemVal.risk_Impact,
                  ItemVal.risk_Rating,
                  ItemVal.riskWF,
                  // '',
                  ItemValroot.rootCause_Category,
                  ItemValroot.rootCause_Likelihood,
                  '',
                  '',
                  '',
                  '',

                  // '',

                  '',
                  '',//ohm
                  '',//ohm
                  '',
                  '',
                  '',
                  '',
                  '',
                ]);
                count++
                countim++
              }
            }

        });
      } 

      if (ItemVal.impact !== null && ItemVal.impact !== undefined) {
        ItemVal.impact.forEach(function(ItemValim, ItemIndexim) {
          var tempcount = 0;
            if (data[ (data.length - countim) +  ItemIndexim ] !== undefined) {
                data[ (data.length - countim) +  ItemIndexim ][16] = ItemValim.impact_Category
                data[ (data.length - countim) +  ItemIndexim ][17] = (ItemValim.impact_Category == 'Financial'? ItemValim.impact_NPT_EMV : ItemValim.impact_Description)
                data[ (data.length - countim) +  ItemIndexim ][18] = (ItemValim.impact_Total_Amont !== null? ItemValim.impact_Total_Amont : '')

                if (ItemValim.impact_Mitigation !== null) {
                  ItemValim.impact_Mitigation.forEach(function(ItemValimmi, ItemIndeximmi) {
                      if (data[ (data.length - countim) +  (ItemIndexim + ItemIndeximmi) ] !== undefined) { 
                          data[ (data.length - countim) +  (ItemIndexim + ItemIndeximmi) ][19] = ItemValim.impact_Level //ItemValimmi.impact_Id
                          data[ (data.length - countim) +  (ItemIndexim + ItemIndeximmi) ][20] = ItemValimmi.impact_Mitigation_Name
                          data[ (data.length - countim) +  (ItemIndexim + ItemIndeximmi) ][21] = ItemValimmi.impact_Mitigation_Owner
                          data[ (data.length - countim) +  (ItemIndexim + ItemIndeximmi) ][22] = ItemValimmi.impact_Mitigation_DueDate
                          data[ (data.length - countim) +  (ItemIndexim + ItemIndeximmi) ][23] = ItemValimmi.impact_Mitigation_Progress + '%'
                          tempcount++
                      }
                      else{
                        if (ItemVal.risk_Co_Id !== null) {
                          data.push([ 
                            ItemVal.risk_Co_Id,
                            ItemVal.version,
                            ItemVal.risk_Name,
                            ItemVal.abbreviation,
                            ItemVal.risk_Status_Name,
                            ItemVal.risk_Status,
                            ItemVal.risk_Likelihood,
                            ItemVal.risk_Impact,
                            ItemVal.risk_Rating,
                            ItemVal.riskWF,
                            // '',
                            '',
                            '',
                            '',
                            '',
                            '',
                            '',
          
                            // '',
          
                            ItemValim.impact_Category,
                            ItemValim.impact_Description,   //ohm
                            ItemValim.impact_Total_Amont, //ohm
                            // ItemValimmi.impact_Id,
                            ItemValim.impact_Level,
                            ItemValimmi.impact_Mitigation_Name,
                            ItemValimmi.impact_Mitigation_Owner,
                            ItemValimmi.impact_Mitigation_DueDate,
                            ItemValimmi.impact_Mitigation_Progress + '%',
                          ]);
                        }
                        else {
                          data.push([ 
                            ItemVal.risk_Staff_Id,
                            ItemVal.version,
                            ItemVal.risk_Name,
                            ItemVal.abbreviation,
                            ItemVal.risk_Status_Name,
                            ItemVal.risk_Status,
                            ItemVal.risk_Likelihood,
                            ItemVal.risk_Impact,
                            ItemVal.risk_Rating,
                            ItemVal.riskWF,
                            // '',
                            '',
                            '',
                            '',
                            '',
                            '',
                            '',
          
                            // '',
          
                            ItemValim.impact_Category,
                            ItemValim.impact_Description,   //ohm
                            ItemValim.impact_Total_Amont, //ohm
                            // ItemValimmi.impact_Id,
                            ItemValim.impact_Level,
                            ItemValimmi.impact_Mitigation_Name,
                            ItemValimmi.impact_Mitigation_Owner,
                            ItemValimmi.impact_Mitigation_DueDate,
                            ItemValimmi.impact_Mitigation_Progress + '%',
                          ]);
                        }
                      }
                  });
                }
                else {
                    // data[ (data.length - countim) +  ItemIndexim ][18] = ''
                    // data[ (data.length - countim) +  ItemIndexim ][19] = ''
                    data[ (data.length - countim) +  ItemIndexim ][19] = ItemValim.impact_Level
                    data[ (data.length - countim) +  ItemIndexim ][20] = ''
                    data[ (data.length - countim) +  ItemIndexim ][21] = ''
                    data[ (data.length - countim) +  ItemIndexim ][22] = ''
                    data[ (data.length - countim) +  ItemIndexim ][23] = ''
                    tempcount++
                }
            }
            else {
              if (ItemValim.impact_Mitigation !== null) {
                ItemValim.impact_Mitigation.forEach(function(ItemValimmi, ItemIndeximmi) {
                    if (ItemVal.risk_Co_Id !== null) {
                      data.push([ 
                        ItemVal.risk_Co_Id,
                        ItemVal.version,
                        ItemVal.risk_Name,
                        ItemVal.abbreviation,
                        ItemVal.risk_Status_Name,
                        ItemVal.risk_Status,
                        ItemVal.risk_Likelihood,
                        ItemVal.risk_Impact,
                        ItemVal.risk_Rating,
                        ItemVal.riskWF,
                        // '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',

                        // '',

                        ItemValim.impact_Category,
                        ItemValim.impact_Description,   //ohm
                        ItemValim.impact_Total_Amont, //ohm
                        // ItemValimmi.impact_Id,
                        ItemValim.impact_Level,
                        ItemValimmi.impact_Mitigation_Name,
                        ItemValimmi.impact_Mitigation_Owner,
                        ItemValimmi.impact_Mitigation_DueDate,
                        ItemValimmi.impact_Mitigation_Progress + '%',
                      ]);
                    }
                    else {
                      data.push([ 
                        ItemVal.risk_Staff_Id,
                        ItemVal.version,
                        ItemVal.risk_Name,
                        ItemVal.abbreviation,
                        ItemVal.risk_Status_Name,
                        ItemVal.risk_Status,
                        ItemVal.risk_Likelihood,
                        ItemVal.risk_Impact,
                        ItemVal.risk_Rating,
                        ItemVal.riskWF,
                        // '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',

                        // '',

                        ItemValim.impact_Category,
                        ItemValim.impact_Description,   //ohm
                        ItemValim.impact_Total_Amont, //ohm
                        // ItemValimmi.impact_Id,
                        ItemValim.impact_Level,
                        ItemValimmi.impact_Mitigation_Name,
                        ItemValimmi.impact_Mitigation_Owner,
                        ItemValimmi.impact_Mitigation_DueDate,
                        ItemValimmi.impact_Mitigation_Progress + '%',
                      ]);
                    }
                });
              }
              else {
                if (ItemVal.risk_Co_Id !== null) {
                  data.push([ 
                    ItemVal.risk_Co_Id,
                    ItemVal.version,
                    ItemVal.risk_Name,
                    ItemVal.abbreviation,
                    ItemVal.risk_Status_Name,
                    ItemVal.risk_Status,
                    ItemVal.risk_Likelihood,
                    ItemVal.risk_Impact,
                    ItemVal.risk_Rating,
                    ItemVal.riskWF,
                    // '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',

                    // '',

                    ItemValim.impact_Category,
                    ItemValim.impact_Description,   //ohm
                    ItemValim.impact_Total_Amont, //ohm
                    ItemValim.impact_Level,
                    '',
                    '',
                    '',
                    '',
                  ]);
                }
                else {
                  data.push([ 
                    ItemVal.risk_Staff_Id,
                    ItemVal.version,
                    ItemVal.risk_Name,
                    ItemVal.abbreviation,
                    ItemVal.risk_Status_Name,
                    ItemVal.risk_Status,
                    ItemVal.risk_Likelihood,
                    ItemVal.risk_Impact,
                    ItemVal.risk_Rating,
                    ItemVal.riskWF,
                    // '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',

                    // '',

                    ItemValim.impact_Category,
                    ItemValim.impact_Description,   //ohm
                    ItemValim.impact_Total_Amont, //ohm last
                    ItemValim.impact_Level,
                    '',
                    '',
                    '',
                    '',
                  ]);
                }
              }
          }
            countim = countim - 1
        });
      }
      count = 0;
      countim = 0;
      
      if ((ItemVal.rootCause === null && ItemVal.impact === null) || (ItemVal.rootCause === undefined && ItemVal.impact === undefined)) {
          if (ItemVal.risk_Co_Id !== null) {
            data.push([ 
              ItemVal.risk_Co_Id,
              ItemVal.version,
              ItemVal.risk_Name,
              ItemVal.abbreviation,
              ItemVal.risk_Status_Name,
              ItemVal.risk_Status,
              ItemVal.risk_Likelihood,
              ItemVal.risk_Impact,
              ItemVal.risk_Rating,
              ItemVal.riskWF,
              // '',
              '',
              '',
              '',
              '',
              '',
              '',

              // '',

              '',
              '',//ohm
              '',//ohm
              '',
              '',
              '',
              '',
              '',
            ]);
            // count++
            // countim++
          }
          else {
            data.push([ 
              ItemVal.risk_Staff_Id,
              ItemVal.version,
              ItemVal.risk_Name,
              ItemVal.abbreviation,
              ItemVal.risk_Status_Name,
              ItemVal.risk_Status,
              ItemVal.risk_Likelihood,
              ItemVal.risk_Impact,
              ItemVal.risk_Rating,
              ItemVal.riskWF,
              // '',
              '',
              '',
              '',
              '',
              '',
              '',

              // '',

              '',
              '',//ohm
              '',//ohm
              '',
              '',
              '',
              '',
              '',
            ]);
            // count++
            // countim++
          }
      }

    });


    //console.log('data',data)

    // Create workbook and worksheet
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Report_Risk_Item');

    

    // Add Row and formatting #TITLE
    const titleRow = worksheet.addRow(title);
    titleRow.font = { size: 20, bold: true, 'color': {'argb': 'ffffff'} };
    
    worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet.getCell('A1').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    worksheet.getCell('A1').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '17a2b8' }, bgColor: { argb: '17a2b8' } };

    worksheet.getCell('K1').alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet.getCell('K1').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    worksheet.getCell('K1').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '28a745' }, bgColor: { argb: '28a745' } };

    worksheet.getCell('Q1').alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet.getCell('Q1').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    worksheet.getCell('Q1').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffc107' }, bgColor: { argb: 'ffc107' } };

    worksheet.mergeCells('A1:J1');
    worksheet.mergeCells('K1:P1');
    worksheet.mergeCells('Q1:X1');
    // const subTitleRow = worksheet.addRow(['Date : ' + this.datePipe.transform(new Date(), 'medium')]);
    // ------------------------------------------------

    // Add Header Row #HEADER
    const headerRow = worksheet.addRow(header);
    headerRow.font = { size: 16, bold: true, 'color': {'argb': 'ffffff'} };
    worksheet.getRow(2).alignment = { vertical: 'middle', horizontal: 'center' };
    // Cell Style : Fill and Border 
    headerRow.eachCell((cell, number) => {
      if (number <= 10) {
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '17a2b8' },
            bgColor: { argb: '17a2b8' }
        };
      }
      if (number >= 11 && number <= 16) {
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '28a745' },
            bgColor: { argb: '28a745' }
        };
      }
      if (number >= 17 && number <= 24) {
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'ffc107' },
            bgColor: { argb: 'ffc107' }
        };
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    });
    // ------------------------------------------------


    // Add Data and Conditional Formatting #DATA
    var count = 0;
    var countRoot = 0;
    var countImpact = 0; 
    
    data.forEach((d , index) => {
      const row = worksheet.addRow(d);
      row.font = { size: 14 };
      row.alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.getCell('C' + (3 + index) ).alignment = { vertical: 'middle', horizontal: 'left' };
      worksheet.getCell('L' + (3 + index) ).alignment = { vertical: 'middle', horizontal: 'left' };
      worksheet.getCell('T' + (3 + index) ).alignment = { vertical: 'middle', horizontal: 'left' };

      row.eachCell((cell, number) => {
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      });

    });

    //----- Merge Same header Row -----
      data.forEach((d , index) => {
        if (index !== 0) {
          if ((data[index - 1][0] === data[index][0])) {
            count++;
          }
          else if (data[index - 1][0] !== data[index][0] && count !== 0 ) {
            worksheet.mergeCells('A' + (3 + ((index - 1) - count)) + ': A' + (3 + (index - 1)));
            worksheet.mergeCells('B' + (3 + ((index - 1) - count)) + ': B' + (3 + (index - 1)));
            worksheet.mergeCells('C' + (3 + ((index - 1) - count)) + ': C' + (3 + (index - 1)));
            worksheet.mergeCells('D' + (3 + ((index - 1) - count)) + ': D' + (3 + (index - 1)));
            worksheet.mergeCells('E' + (3 + ((index - 1) - count)) + ': E' + (3 + (index - 1)));
            worksheet.mergeCells('F' + (3 + ((index - 1) - count)) + ': F' + (3 + (index - 1)));
            worksheet.mergeCells('G' + (3 + ((index - 1) - count)) + ': G' + (3 + (index - 1)));
            worksheet.mergeCells('H' + (3 + ((index - 1) - count)) + ': H' + (3 + (index - 1)));
            worksheet.mergeCells('I' + (3 + ((index - 1) - count)) + ': I' + (3 + (index - 1)));
            worksheet.mergeCells('J' + (3 + ((index - 1) - count)) + ': J' + (3 + (index - 1)));
            // worksheet.mergeCells('P' + (3 + ((index - 1) - count)) + ': P' + (3 + (index - 1)));
            count = 0;
          }

          if ((data.length - 1) === index && count !== 0) {
            worksheet.mergeCells('A' + (2 + index)  + ': A' + ((2 + index) + count));
            worksheet.mergeCells('B' + (2 + index)  + ': B' + ((2 + index) + count));
            worksheet.mergeCells('C' + (2 + index)  + ': C' + ((2 + index) + count));
            worksheet.mergeCells('D' + (2 + index)  + ': D' + ((2 + index) + count));
            worksheet.mergeCells('E' + (2 + index)  + ': E' + ((2 + index) + count));
            worksheet.mergeCells('F' + (2 + index)  + ': F' + ((2 + index) + count));
            worksheet.mergeCells('G' + (2 + index)  + ': G' + ((2 + index) + count));
            worksheet.mergeCells('H' + (2 + index)  + ': H' + ((2 + index) + count));
            worksheet.mergeCells('I' + (2 + index)  + ': I' + ((2 + index) + count));
            worksheet.mergeCells('J' + (2 + index)  + ': J' + ((2 + index) + count));
            // worksheet.mergeCells('P' + (2 + index)  + ': P' + ((2 + index) + count));
            count = 0;
          }
        }
      });
    // ------------------------------------------------
    

    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 100;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 30;
    worksheet.getColumn(7).width = 30;
    worksheet.getColumn(8).width = 30;
    worksheet.getColumn(9).width = 30;
    worksheet.getColumn(10).width = 30;
    worksheet.getColumn(11).width = 120;
    
    worksheet.getColumn(12).width = 30;
    worksheet.getColumn(13).width = 120;
    worksheet.getColumn(14).width = 30;
    worksheet.getColumn(15).width = 30;
    worksheet.getColumn(16).width = 30;
    worksheet.getColumn(17).width = 50;
    
    worksheet.getColumn(18).width = 120;

    worksheet.getColumn(19).width = 30;
    worksheet.getColumn(20).width = 30;
    worksheet.getColumn(21).width = 120;
    worksheet.getColumn(22).width = 30;
    worksheet.getColumn(23).width = 30;
    worksheet.getColumn(24).width = 30;
    worksheet.getColumn(25).width = 30;
    worksheet.getColumn(26).width = 30;
    worksheet.addRow([]);




    // Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data: any) => {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    fs.saveAs(blob, 'Report_Risk_Item.xlsx');
    });

  }


  async generateExcel_COBU(TempValue) {
    const title = ['BUID', 'BU NAME', 'CO Email', 'CO Name'];
    const header = [];
    const data = [];

    TempValue.forEach(function (ItemVal, ItemIndex) {
      data.push([
        ItemVal.deptID,
        ItemVal.deptName,
        ItemVal.email,
        ItemVal.fullName,
      ])
    });

   
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Excel_COBU');
    const titleRow = worksheet.addRow(title);
    data.forEach((d, index) => {
      const row = worksheet.addRow(d);
    });

    worksheet.getColumn(1).width = 20;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 30;
    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Excel_COBU.xlsx');
    });

  }

  async generateExcel_Financial(TempValue) {
    const title = ['BUID', 'BU NAME', 'NI', 'NPV/EMV'];
    const header = [];
    const data = [];

    TempValue.forEach(function (ItemVal, ItemIndex) {
      data.push([
        ItemVal.businessCode,
        ItemVal.businessUnit,
        ItemVal.ni,
        ItemVal.npV_EMV,
      ])
    });
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Financial_Impact');

    const titleRow = worksheet.addRow(title);
    data.forEach((d, index) => {
      const row = worksheet.addRow(d);
    });

    worksheet.getColumn(1).width = 20;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 30;

    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Financial_Impact.xlsx');
    });

  }


  private GetdataWithOutField(datafield) {
    let newarray = [];
    return newarray;
  }



  
 
}