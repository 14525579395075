<div class="container-fluid appform my-3 min-height-bg" style="background: #dce2f0;">
  <div class="row">

    <div class="text-center col-12">
      <div class="spinner-grow Pageloading" role="status" id="loader" [hidden]="Pageloading === false">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div class="min-width">

      <div class="card mt-4 py-4" [hidden]="Pageloading === true">
        <div class="row px-0 mx-0 justify-content-xl-center">

          <div class="col-12 col-xl-12">
            <div class="bd-blue rounded-0">

              <div class="row col-12 mx-0 px-0">

                <div class="col-2 px-0">
                  <div class="border-0 rounded-0 p-2">
                    <h2>Risk Map</h2>
                  </div>
                </div>

                <div class="row col-4 pt-3">
                  <div class="col-4" style="text-align: right;">
                    <h5>Business Unit</h5>
                  </div>
                  <div class="col-8">
                    <input type="text" class="form-control m-0" aria-label="Number" matInput
                      [formControl]="myControlBUDD" [matAutocomplete]="auto1" [(ngModel)]="tempselectbu"
                      (keyup.enter)="func_OkBU('')">
                    <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete">
                      <mat-option *ngFor="let option of filteredOptionBUDD | async" [value]="option.text"
                        (click)="func_OkBU('')">
                        {{option.text}}: {{option.fullname}}
                      </mat-option>
                    </mat-autocomplete>
                  </div>
                </div>

                <div class="row col-3 pt-3">
                  <div class="col-4">
                    <h5>Risk Type</h5>
                  </div>
                  <div class="col-8">
                    <select class="form-control" [(ngModel)]="Register_Data.Risk_Type" (change)="loadRisk()">
                      <option value="0">All</option>
                      <option value="1">Normal</option>
                      <option value="2">WPB</option>
                    </select>
                  </div>
                </div>

              </div>

            </div>
          </div>

          <div class="col-12 col-xl-12 mt-4">
            <div class="bd-blue rounded-0">

              <div class="col-12 col-xl-12 rotated" style="text-align: center;">
                <span>Qualitative (People, Property damage, Project cost & schedule,<br> Legal/Compliance, Enviroment,
                  Image/Reputation)</span>
              </div>

              <div class="row mx-0 px-0">
                <div class="col-1 col-xl-1"></div>
                <div class="col-7 col-xl-7 pr-0" style="text-align: right;">
                  <div class="mr-2" style="display: inline;">
                    <span style="font-size: 16px;font-weight: 600;">Risk Level :</span>
                  </div>
                  <div class="custom-control custom-checkboxs col-form-label custom-control-inline py-0">
                    <input type="checkbox" id="Low" class="custom-control-input green" checked disabled>
                    <h6 for="Low" class="custom-control-label">Low</h6>
                  </div>
                  <div class="custom-control custom-checkboxs col-form-label custom-control-inline py-0">
                    <input type="checkbox" id="Low" class="custom-control-input yellow" checked disabled>
                    <h6 for="Low" class="custom-control-label">Medium</h6>
                  </div>
                  <div class="custom-control custom-checkboxs col-form-label custom-control-inline py-0">
                    <input type="checkbox" id="Low" class="custom-control-input red" checked disabled>
                    <h6 for="Low" class="custom-control-label">High</h6>
                  </div>
                  <div class="ml-2 mr-2" style="display: inline;">
                    <span style="font-size: 16px;font-weight: 600;">Risk Status :</span>
                  </div>
                  <div class="custom-checkboxs col-form-label custom-control-inline py-0">
                    <div class="square"></div>
                    <h6 for="Previous" class="">&nbsp;Previous</h6>
                  </div>
                  <div class="custom-checkboxs col-form-label custom-control-inline py-0">
                    <div class="circle"></div>
                    <h6 for="Current" class="">&nbsp;Current</h6>
                  </div>
                  <div class="custom-checkboxs col-form-label custom-control-inline py-0">
                    <div class="circle" style="border: 2px solid #fb3434;background-color: white;"></div>
                    <h6 for="Current" class="">&nbsp;Propose to remove</h6>
                  </div>
                </div>
                <div class="col-12 col-xl-4"></div>
              </div>
              <div class="row mx-0 px-0">
                <div class="col-1 col-xl-1 px-0"></div>

                <div class="col-7 col-xl-7 px-0 tablerisk-card">
                  <div class="card-body row m-0 p-1">
                    <div class="col-12 p-0">
                      <table class="custom" id="test2">
                        <caption style="display: none;">This is a custom table</caption>
                        <tr>
                          <th></th>
                        </tr>
                        <tr *ngFor="let item1 of list.risk.risktable;">
                          <td *ngFor="let item2 of item1.col;" class="box">
                            <div class="content text-center" class="{{item2.class}}">
                              <ng-container *ngFor="let item3 of item2.item index as index3;">

                                <span *ngIf="item2.item.length <= 9; else elseBlock">

                                  <span *ngIf="item2.class === 'high'">
                                    <div class="btn-group dropup" (click)="func_ReveiewPage(item3.Risk_Id , item3)">
                                      <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span class="badge badge-pill badge-light txt-title"
                                          [ngClass]="{'IsStripes': item3.IsStripes === true, 'border-red': (item3.risk_Status_Workflow_ReConsole !== null && this.tempModule == 'TODO' && item3.IsStripes === false) || (item3.risk_Status != '1' && item3.IsStripes === false  && this.tempModule == 'TODO')}"
                                          [ngStyle]="{'background-color': item3.bgcolor, 'color': item3.color}"
                                          data-toggle="tooltip" data-placement="top" title = {{item3.hover_Text}}><strong>{{ item3.index
                                            }}</strong></span>
                                        <div class="dropdown-menu dropdown-menu-top speech-bubble">
                                          <h6 class="m-0" style="text-align: center;">{{item3.title}}</h6>
                                        </div>
                                      </div>
                                    </div>
                                  </span>
                                  <span *ngIf="item2.class === 'medium'">
                                    <div class="btn-group dropup" (click)="func_ReveiewPage(item3.Risk_Id , item3)">
                                      <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span class="badge badge-pill badge-light txt-title"
                                          [ngClass]="{'IsStripes': item3.IsStripes === true, 'border-red': (item3.risk_Status_Workflow_ReConsole !== null && this.tempModule == 'TODO' && item3.IsStripes === false) || (item3.risk_Status != '1' && item3.IsStripes === false  && this.tempModule == 'TODO')}"
                                          [ngStyle]="{'background-color': item3.bgcolor, 'color': item3.color}"
                                          data-toggle="tooltip" data-placement="top" title = {{item3.hover_Text}}><strong>{{ item3.index
                                            }}</strong></span>
                                        <div class="dropdown-menu dropdown-menu-top speech-bubble">
                                          <h6 class="m-0" style="text-align: center;">{{item3.title}}</h6>
                                        </div>
                                      </div>
                                    </div>
                                  </span>
                                  <span *ngIf="item2.class === 'low'">
                                    <div class="btn-group dropup" (click)="func_ReveiewPage(item3.Risk_Id , item3)">
                                      <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span class="badge badge-pill badge-light txt-title"
                                          [ngClass]="{'IsStripes': item3.IsStripes === true, 'border-red': (item3.risk_Status_Workflow_ReConsole !== null && this.tempModule == 'TODO' && item3.IsStripes === false) || (item3.risk_Status != '1' && item3.IsStripes === false  && this.tempModule == 'TODO')}"
                                          [ngStyle]="{'background-color': item3.bgcolor, 'color': item3.color}"
                                          data-toggle="tooltip" data-placement="top" title = {{item3.hover_Text}}><strong>{{ item3.index
                                            }}</strong></span>
                                        <div class="dropdown-menu dropdown-menu-top speech-bubble">
                                          <h6 class="m-0" style="text-align: center;">{{item3.title}}</h6>
                                        </div>
                                      </div>
                                    </div>
                                  </span>
                                  <span *ngIf="item2.class === 'Escalation'">
                                    <div class="btn-group dropup">
                                      <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span class="badge badge-pill badge-light txt-title"
                                          [ngClass]="{'IsStripes': item3.IsStripes === true, 'border-red': (item3.risk_Status_Workflow_ReConsole !== null && this.tempModule == 'TODO' && item3.IsStripes === false) || (item3.risk_Status != '1' && item3.IsStripes === false  && this.tempModule == 'TODO')}"
                                          [ngStyle]="{'background-color': item3.bgcolor, 'color': item3.color}"
                                          data-toggle="tooltip" data-placement="top" title = {{item3.hover_Text}}><strong>{{ item3.index
                                            }}</strong></span>
                                        <div class="dropdown-menu dropdown-menu-top speech-bubble">
                                          <h6 class="m-0" style="text-align: center;">{{item3.title}}</h6>
                                        </div>
                                      </div>
                                    </div>
                                  </span>
                                </span>
                                <ng-template #elseBlock>

                                  <span *ngIf="index3 <= 7">
                                    <span *ngIf="item2.class === 'high'">
                                      <div class="btn-group dropup" (click)="func_ReveiewPage(item3.Risk_Id , item3)">
                                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          <span class="badge badge-pill badge-light txt-title"
                                            [ngClass]="{'IsStripes': item3.IsStripes === true}"
                                            [ngStyle]="{'background-color': item3.bgcolor, 'color': item3.color}"
                                            data-toggle="tooltip" data-placement="top"><strong>{{ item3.index
                                              }}</strong></span>
                                          <div class="dropdown-menu dropdown-menu-top speech-bubble">
                                            <h6 class="m-0" style="text-align: center;">{{item3.title}}</h6>
                                          </div>
                                        </div>
                                      </div>
                                    </span>
                                    <span *ngIf="item2.class === 'medium'">
                                      <div class="btn-group dropup" (click)="func_ReveiewPage(item3.Risk_Id , item3)">
                                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          <span class="badge badge-pill badge-light txt-title"
                                            [ngClass]="{'IsStripes': item3.IsStripes === true}"
                                            [ngStyle]="{'background-color': item3.bgcolor, 'color': item3.color}"
                                            data-toggle="tooltip" data-placement="top"><strong>{{ item3.index
                                              }}</strong></span>
                                          <div class="dropdown-menu dropdown-menu-top speech-bubble">
                                            <h6 class="m-0" style="text-align: center;">{{item3.title}}</h6>
                                          </div>
                                        </div>
                                      </div>
                                    </span>
                                    <span *ngIf="item2.class === 'low'">
                                      <div class="btn-group dropup" (click)="func_ReveiewPage(item3.Risk_Id , item3)">
                                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          <span class="badge badge-pill badge-light txt-title"
                                            [ngClass]="{'IsStripes': item3.IsStripes === true}"
                                            [ngStyle]="{'background-color': item3.bgcolor, 'color': item3.color}"
                                            data-toggle="tooltip" data-placement="top"><strong>{{ item3.index
                                              }}</strong></span>
                                          <div class="dropdown-menu dropdown-menu-top speech-bubble">
                                            <h6 class="m-0" style="text-align: center;">{{item3.title}}</h6>
                                          </div>
                                        </div>
                                      </div>
                                    </span>
                                    <span *ngIf="item2.class === 'Escalation'">
                                      <div class="btn-group dropup" (click)="func_ReveiewPage(item3.Risk_Id , item3)">
                                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          <span class="badge badge-pill badge-light txt-title"
                                            [ngClass]="{'IsStripes': item3.IsStripes === true}"
                                            [ngStyle]="{'background-color': item3.bgcolor, 'color': item3.color}"
                                            data-toggle="tooltip" data-placement="top"><strong>{{ item3.index
                                              }}</strong></span>
                                          <div class="dropdown-menu dropdown-menu-top speech-bubble">
                                            <h6 class="m-0" style="text-align: center;">{{item3.title}}</h6>
                                          </div>
                                        </div>
                                      </div>
                                    </span>
                                  </span>

                                  <span *ngIf="index3 === 8">
                                    <span *ngIf="item2.class === 'high'">
                                      <div class="btn-group dropup">
                                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          <span class="badge badge-pill badge-light txt-title"
                                            style="background-color:#5e5e5e;color:white;" data-toggle="tooltip"
                                            data-placement="top"><strong>...</strong></span>
                                          <div class="dropdown-menu dropdown-menu-top speech-bubble2">
                                            <span class="m-0" style="text-align: center;"
                                              *ngFor="let itemtemp of item2.item index as indextemp">
                                              <span class="badge badge-pill badge-light txt-title"
                                                [ngClass]="{'IsStripes': item3.IsStripes === true}"
                                                [ngStyle]="{'background-color': itemtemp.bgcolor, 'color': itemtemp.color}"
                                                data-toggle="tooltip" data-placement="top" *ngIf="indextemp > 8">{{
                                                itemtemp.index }}</span>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </span>
                                    <span *ngIf="item2.class === 'medium'">
                                      <div class="btn-group dropdown">
                                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          <span class="badge badge-pill badge-light txt-title"
                                            style="background-color:#5e5e5e;color:white;" data-toggle="tooltip"
                                            data-placement="top"><strong>...</strong></span>
                                          <div class="dropdown-menu dropdown-menu-top speech-bubble2">
                                            <span class="m-0" style="text-align: center;"
                                              *ngFor="let itemtemp of item2.item index as indextemp">
                                              <span class="badge badge-pill badge-light txt-title"
                                                [ngClass]="{'IsStripes': item3.IsStripes === true}"
                                                [ngStyle]="{'background-color': itemtemp.bgcolor, 'color': itemtemp.color}"
                                                data-toggle="tooltip" data-placement="top" *ngIf="indextemp > 8">{{
                                                itemtemp.index }}</span>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </span>
                                    <span *ngIf="item2.class === 'low'">
                                      <div class="btn-group dropright">
                                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          <span class="badge badge-pill badge-light txt-title"
                                            style="background-color:#5e5e5e;color:white;" data-toggle="tooltip"
                                            data-placement="top"><strong>...</strong></span>
                                          <div class="dropdown-menu dropdown dropdown-menu-right speech-bubble2">
                                            <span class="m-0" style="text-align: center;"
                                              *ngFor="let itemtemp of item2.item index as indextemp">
                                              <span class="badge badge-pill badge-light txt-title"
                                                [ngClass]="{'IsStripes': item3.IsStripes === true}"
                                                [ngStyle]="{'background-color': itemtemp.bgcolor, 'color': itemtemp.color}"
                                                data-toggle="tooltip" data-placement="top" *ngIf="indextemp > 8">{{
                                                itemtemp.index }}</span>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </span>
                                    <span *ngIf="item2.class === 'Escalation'">
                                      <div class="btn-group dropup">
                                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          <span class="badge badge-pill badge-light txt-title"
                                            [ngClass]="{'IsStripes': item3.IsStripes === true}"
                                            [ngStyle]="{'background-color': item3.bgcolor, 'color': item3.color}"
                                            data-toggle="tooltip" data-placement="top"><strong>{{ item3.index
                                              }}</strong></span>
                                          <div class="dropdown-menu dropdown-menu-top speech-bubble">
                                            <h6 class="m-0" style="text-align: center;">{{item3.title}}</h6>
                                          </div>
                                        </div>
                                      </div>
                                    </span>
                                  </span>
                                </ng-template>
                                <div [ngStyle]="{'background-color': item3.bgcolor , color:item3.color }"
                                  *ngIf="item2.class === 'impact-y'" class="p-2">
                                  <span class="font-size">{{item3.title}}</span>
                                </div>
                                <div [ngStyle]="{'background-color': item3.bgcolor , color:item3.color}" class="p-0"
                                  *ngIf="item2.class === 'impact-x'">
                                  <span>{{item3.title}}</span>
                                </div>
                              </ng-container>
                            </div>
                          </td>
                        <tr>
                      </table>
                    </div>
                  </div>
                </div>

                <div class="col-4 col-xl-4 mt-1 px-0">
                  <div class="card-body row m-0 p-1 rounded" style="background-color: #3a5f92;">
                    <div class="col-12">
                      <h3 class="pl-2 pt-2" style="color: white;">KEY RISKS</h3>
                    </div>
                    <div class="col-12 scrollbar-key">
                      <ul class="list-group w-100" cdkDropList (cdkDropListDropped)="drop($event)">
                        <li class="border-0 rounded-0 p-2" style="font-size: 18px;color: white;"
                          *ngFor="let item of list.risk.riskitems; index as index" cdkDrag cdkDragLockAxis="y">
                          <div class="row px-0 mx-0 list-item">
                            <div class="col-1 px-0">
                              {{ (index+1) + '.' }}
                            </div>
                            <div class="col-11 px-0" (click)="func_ReveiewPagefromTodo(item.risk_Id , item)"
                              style="cursor:pointer;" *ngIf="page === '1'">
                              {{ item.risk_BusinessUnit_Name + ': ' + item.risk_Name }}
                            </div>
                            <div class="col-11 px-0" (click)="func_ReveiewPage(item.risk_Id , item)"
                              style="cursor:pointer;" *ngIf="page === '2'">
                              {{ item.risk_BusinessUnit_Name + ': ' + item.risk_Name }}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card mt-4 pb-4" [hidden]="Pageloading === true">
        <div class="row col-12 px-0 mx-0">
          <div class="col-md-12 px-0">
            <ul class="TodoList rounded mx-4 mt-4" style="background-color:#023154;font-size: 20px;">
              <li><a class="pageroute active" id="TodoList" (click)="changepage('1')">{{ pageroute[0].title }}</a></li>
              <li><a class="pageroute" id="RiskMap" (click)="changepage('2')">{{ pageroute[1].title }}</a></li>
            </ul>

            <div class="d-flex justify-content-center">
              <div class="spinner-border loading" role="status" id="loader" [hidden]="loading === false">
                <span class="sr-only">Loading...</span>
              </div>
            </div>

            <div class="px-4" id="showdisplay1" [hidden]="loading === true">
              <div class="card-body row col-12 col-xl-12 mt-4 pb-3 px-0 mx-0 d-flex justify-content-around"
                *ngIf="page === '1'">

                <div class="row col-9 col-xl-9 px-0 mx-0">
                  <button type="button" class="btn btn-sm p-2 mr-3 btn-func btn-sub-myrisk" (click)="func_Confirm()"
                    *ngIf="chkroleuser === 'Owner'">
                    <em class="fas fa-check-circle" style="font-size: 20px;">&nbsp;&nbsp;</em>
                    <strong>Confirm</strong>
                  </button>
                  <button type="button" class="btn btn-sm p-2 mr-3 btn-func btn-clear-myrisk" (click)="func_Clear()"
                    *ngIf="chkroleuser === 'Owner'">
                    <em class="fas fa-undo-alt" style="font-size: 20px;">&nbsp;&nbsp;</em>
                    <strong>Clear</strong>
                  </button>
                  <select class="p-2 ddl-quarter-myrisk" (change)="changeQuarter($event.target.value)" [hidden]="true"
                    *ngIf="chkroleuser === 'Owner'">
                    <option *ngFor="let item of LatestQuarterDDLYear" value="{{ item.value }}">{{ item.text }}</option>
                  </select>
                </div>

                <div class="col-3 col-xl-3 px-0">
                  <div class="input-group">
                    <input type="text" class="form-control border-0 m-0" placeholder="Search..."
                      [(ngModel)]="SearchMyrisk" (keyup.enter)="func_SearchMyRisk('1')">
                    <div class="input-group-append">
                      <button class="btn btn-sm btn-light" type="button" style="width:50px;"
                        (click)="func_SearchMyRisk('2')">
                        <em class="fas fa-times"></em>

                      </button>
                      <button class="btn btn-sm" type="button" style="background:#00adef;color:white;width:50px;"
                        (click)="func_SearchMyRisk('1')">
                        <em class="fa fa-search"></em>

                      </button>
                    </div>
                  </div>
                </div>

              </div>

              <div *ngIf="page === '1'">
                <table class="table-bordered table-hover table-form" style="width: 100%;">
                  <caption style="display: none;">This is a custom table</caption>
                  <thead style="text-align: center;font-size: 20px;">
                    <tr style="background-color:#023154;color:white;">
                      <th style="width: 10px;background: #9eb0ba;vertical-align: middle;">View</th>
                      <th style="width: 10px;background: #00aeef;" *ngIf="chkroleuser === 'Owner'">
                        <label for="chk1"><strong>Approval All</strong></label><br>
                        <input type="checkbox" title="Approval All" id="chk1" [(ngModel)]="valuecheckApproveAll"
                          (change)="ApproveAll(valuecheckApproveAll)" style="zoom:1.5;">
                      </th>
                      <th style="width: 10px;background:#bd9e5e;" *ngIf="chkroleuser === 'Owner'">
                        <label for="chk2"><strong>Reject All</strong></label><br>
                        <input type="checkbox" title="Reject All" id="chk2" [(ngModel)]="valuecheckRejectAll"
                          (change)="RejectAll(valuecheckRejectAll)" style="zoom:1.5;">
                      </th>
                      <th style="width: 200px;vertical-align: middle;background:#bd9e5e;"
                        *ngIf="chkroleuser === 'Owner'">Reason For Reject</th>
                      <th style="width: 100px;vertical-align: middle;">Risk ID</th>
                      <th style="width: 350px;vertical-align: middle;">Risk Name</th>
                      <th style="width: 50px;vertical-align: middle;">Business Unit</th>
                      <th style="width: 50px;vertical-align: middle;">Risk Type</th>
                      <th style="width: 50px;vertical-align: middle;">Risk Status</th>
                    </tr>
                  </thead>
                  <tbody style="font-size: 18px;vertical-align: top;">
                    <tr *ngFor="let data of listRiskTableItem index as index">
                      <td style="text-align: center;">
                        <button class="btn btn-sm" (click)="func_ReveiewPagefromTodo(data.risk_Id , data)">
                          <em class="fa fa-search" aria-hidden="true" style="color:#6c757d;font-size:18px;"></em>

                        </button>
                        <button class="btn px-0 mr-2 pad-btn"
                          (click)="func_EditFromToDo(data.risk_Id , data.risk_Status_Code , data)">
                          <em class="far fa-edit text-warning" style="font-size:18px;"></em>

                        </button>
                      </td>
                      <td style="text-align: center;" *ngIf="chkroleuser === 'Owner'"><input type="checkbox"
                          title="Approval" name="selectedApp{{ index + 1 }}"
                          (change)="SelectedApprove(data.risk_Id , index+1)"
                          [disabled]="data.risk_Status_Code === '0' || (data.risk_ViewMode === 'Y' && data.risk_AssignTo_ReConsole !== Data_SessionStorage[0].employeE_ID)"
                          style="zoom:1.5;"></td>
                      <td style="text-align: center;" *ngIf="chkroleuser === 'Owner'"><input type="checkbox"
                          title="Reject" name="selectedRej{{ index + 1 }}"
                          (change)="SelectedReject(data.risk_Id , index+1)"
                          [disabled]="data.risk_Status_Code === '0' || (data.risk_ViewMode === 'Y' && data.risk_AssignTo_ReConsole !== Data_SessionStorage[0].employeE_ID)"
                          style="zoom:1.5;"></td>
                      <td *ngIf="chkroleuser === 'Owner'">
                        <input type="text" class="form-control" style="font-size:16px;"
                          [(ngModel)]="Register_Data.Regis_Comment[index].comment"
                          (blur)='func_addComment(data.risk_Id)'>
                      </td>
                      <td style="text-align: left;">
                        <span *ngIf="Register_Data.Regis_Role === 'Staff'">{{ data.risk_Staff_Id }}</span>
                        <span
                          *ngIf="Register_Data.Regis_Role === 'Co' || Register_Data.Regis_Role === 'Owner' || Register_Data.Regis_Role === 'ERM'">{{
                          data.risk_Co_Id }}</span>
                      </td>
                      <td>{{ data.risk_Name }} </td>
                      <td style="text-align: left;">{{ data.risk_BusinessUnit_Name }}</td>
                      <td style="text-align: center;">
                        <span *ngIf="data.wpbid !== null; else elseBlock3">WPB</span>
                        <ng-template #elseBlock3>Normal</ng-template>
                      </td>
                      <td style="text-align: left;">
                        <span *ngIf="data.risk_Status === '1'">Active</span>
                        <span *ngIf="data.risk_Status === '2'">Close (Turn to problem)</span>
                        <span *ngIf="data.risk_Status === '3'">Close (Mitigation Completed)</span>
                        <span *ngIf="data.risk_Status === '4'">Close (Other)</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="px-4" id="showdisplay2" [hidden]="loading === true">
              <div class="card-body row col-sm-12 col-xl-12 mt-4 pb-3 px-0 mx-0 d-flex justify-content-around"
                *ngIf="page === '2'">

                <div class="row col-9 col-xl-9 px-0 mx-0">
                  <button type="button" class="btn btn-sm p-2 mr-3 btn-func btn-sub-myrisk"
                    (click)="func_ConfirmMyRiks()" [hidden]="chkroleuser === 'Owner'"
                    *ngIf="listSelect.length > 0">
                    <em class="fas fa-check-circle" style="font-size: 20px;">&nbsp;&nbsp;</em>
                    <strong>Submit</strong>
                  </button>
                  <button type="button" class="btn btn-success btn-sm p-2 mr-3 btn-func"
                    (click)="func_ConfirmRemoveRisks()" [hidden]="chkroleuser === 'Owner'"
                    *ngIf="tempSeletedRemove.length > 0">
                    <em class="fas fa-check-circle" style="font-size: 20px;">&nbsp;&nbsp;</em>
                    <strong>Submit</strong>
                  </button>
                  <button type="button" class="btn btn-sm p-2 mr-3 btn-func btn-clear-myrisk" style="width:140px;"
                    (click)="func_Clear_MyRisk()" [hidden]="chkroleuser === 'Owner'">
                    <em class="fas fa-undo-alt" style="font-size: 20px;">&nbsp;&nbsp;</em>
                    <strong>Clear</strong>
                  </button>
                  <button type="button" class="btn btn-sm p-2 mr-3 btn-func btn-Regisnew-myrisk"
                    (click)="SelectMethodology()" [hidden]="chkroleuser === 'Owner'">
                    <em class="fas fa-plus-circle" style="font-size: 20px;">&nbsp;&nbsp;</em>

                    <strong>Register New Risk</strong>
                  </button>

                  <select class="p-2 ddl-quarter-myrisk" (change)="changeQuarter($event.target.value)" [hidden]="true"
                    *ngIf="chkroleuser === 'Co' || chkroleuser === 'ERM'">
                    <option *ngFor="let item of LatestQuarterDDLYear" value="{{ item.value }}">{{ item.text }}</option>
                  </select>
                </div>

                <div class="col-3 col-xl-3 px-0">
                  <div class="input-group border rounded">
                    <input type="text" class="form-control border-0 m-0" placeholder="Search..."
                      [(ngModel)]="SearchMyrisk" (keyup.enter)="func_SearchMyRisk('1')">
                    <div class="input-group-append">
                      <button class="btn btn-sm btn-light" type="button" style="width:50px;"
                        (click)="func_SearchMyRisk('2')">
                        <em class="fas fa-times"></em>

                      </button>
                      <button class="btn btn-sm" type="button" style="background: #00adef;color:white;width:50px;"
                        (click)="func_SearchMyRisk('1')">
                        <em class="fa fa-search"></em>

                      </button>
                    </div>
                  </div>
                </div>

              </div>

              <div *ngIf="page === '2'">
                <table class="table-bordered table-hover table-form" style="width: 100%;">
                  <caption style="display: none;">This is a custom table</caption>
                  <thead style="text-align: center;font-size: 20px;">
                    <tr style="background-color: #023154;color:white;">
                      <th style="width: 20px;vertical-align: middle;background: #9eb0ba;">View / Update</th>
                      <th style="width: 60px;background: #00aeef;" [hidden]="chkroleuser === 'Owner'">
                        <label><strong>Select All</strong></label><br>
                        <input type="checkbox" id="chk3" [(ngModel)]="valuecheckSelectAll" [disabled]="disableSelectAll"
                          (change)="SelectAll(valuecheckSelectAll)" style="zoom:1.5;" name="selectAll">
                      </th>
                      <th style="width: 60px;background: #00aeef;" [hidden]="displayRemove">
                        <label><strong>Remove All</strong></label><br>
                        <input type="checkbox" id="chkRmA" [(ngModel)]="valuecheckRemoveAll" [disabled]="disableRemove"
                          (change)="SelectAllRemove(valuecheckRemoveAll)" style="zoom:1.5;">
                      </th>
                      <th style="width: 150px;vertical-align: middle; background: #bd9e5e;">Risk ID</th>
                      <th style="width: 600px;vertical-align: middle;">Risk Name</th>
                      <th style="width: 10px;vertical-align: middle;">Business Unit</th>
                      <th style="width: 100px;vertical-align: middle;">Status</th>
                      <th style="width: 100px;vertical-align: middle;">Risk Source</th>
                      <th style="width: 100px;vertical-align: middle;">Risk Type</th>
                      <th style="width: 100px;vertical-align: middle;">Risk Status</th>
                    </tr>
                  </thead>
                  <tbody style="font-size:18px;vertical-align: top;">
                    <tr *ngFor="let data of listRiskTableItem; index as index">
                      <td style="text-align: center;">
                        <button class="btn px-0 mr-2 pad-btn" (click)="func_ReveiewPage(data.risk_Id , data)">
                          <em class="fa fa-search" style="color:#6c757d;font-size:18px;"></em>

                        </button>
                        <button class="btn px-0 mr-2 pad-btn"
                          (click)="func_EditIdenti(data.risk_Id , data.risk_Status_Code , data)"
                          *ngIf=" data.Chkedit == true && chkroleuser != 'Owner'"
                          [disabled]="data.risk_ViewMode == 'Y'">
                          <em class="far fa-edit text-warning" style="font-size:18px;"></em>
                        </button>                    
                      
                        <button class="btn px-0 mr-2 pad-btn" (click)="func_del(data)"
                        *ngIf="data.ChkDelete == true && chkroleuser != 'Owner'"
                        [disabled]="data.risk_ViewMode == 'Y'">
                        <em class="far fa-trash-alt text-danger" style="font-size:18px;"></em>
                      </button>

                        <button class="btn px-0 mr-2 pad-btn" title="Remove from My Risk" (click)="func_Rework(data)"
                          *ngIf="chkroleuser != 'Owner' && data.ChkRework == true && data.risk_ViewMode !== 'Y' 
                          && !(data.risk_Status_Code == '7' ||data.risk_Status_Code == '10' 
                          || ((data.risk_Status_Code == '11' || data.risk_Status_Code == '12') && this.tempLevel != 'Group')
                          || (data.risk_Status_Code == '18' && this.tempLevel != 'Group')
                          || data.risk_Status_Code == '13' || data.isTempRiskStatusCode)">
                          <em class="fas fa-redo text-danger" style="font-size:18px;"></em>
                        </button>

                      </td>
                      <td style="text-align: center;" [hidden]="chkroleuser === 'Owner'">
                        <input type="checkbox" name="selectedAll{{ index + 1 }}"
                          (change)="Selected(data.risk_Id , index+1)" style="zoom:1.5;" [disabled]='(data.risk_ViewMode === "Y")
                                    || (data.chkbox == false && data.risk_Status_Code != "0" && data.risk_Status_Code != "23") 
                                    || ( tempselectbu != data.risk_BusinessUnit_Name && data.risk_Status_Code == 0 )                               
                                    || ( data.chkbox == true && data.risk_Status_Code === "10") 
                                    || ( data.chkbox == true && data.risk_Status_Code === "13") 
                                    || ( data.chkbox == true && data.risk_Status_Code === "4")  
                                    || ( data.chkbox == true && data.risk_Status_Code === "7") || disableSelectAll'>
                      </td>
                      <td style="text-align: center;" [hidden]="displayRemove">
                        <input type="checkbox" name="removeAll{{ index + 1 }}"
                        *ngIf="checkRemoveCheckboxIsShow(data)"
                        (change)="func_ReworkChk(data)" style="zoom:1.5;" [disabled]='(data.risk_ViewMode === "Y") || disableRemove'
                        [(ngModel)]="data.ChkRemoveAll">
                      </td>
                      <td style="text-align: left;">
                        <span *ngIf="data.risk_Co_Id !== null; else elseBlock">{{ data.risk_Co_Id }}</span>
                        <ng-template #elseBlock>{{ data.risk_Staff_Id }}</ng-template>
                      </td>
                      <td>{{ data.risk_Name }}</td>
                      <td style="text-align: left;">{{ data.risk_BusinessUnit_Name }}</td>
                      <td [ngClass]="{'txt-rejected': data.risk_Status_Code == '15'
                                || data.risk_Status_Code == '16'
                                || data.risk_Status_Code == '17'
                                || data.risk_Status_Code == '18'
                                || data.risk_Status_Code == '34'
                                || data.risk_Status_Code == '35'
                                || data.risk_Status_Code == '36'}" style="text-align: left; color: #bd9e5e;">{{
                        data.risk_Status_Name }}</td>
                      <td style="text-align: left;">
                       <span
                          *ngIf="this.BUWork === data.risk_BusinessUnit_Name && data.risk_Source === 'My Risk'; else elseBlock2">My Risk
                        </span>                           
                        <ng-template #elseBlock2>Consolidation</ng-template> 
                      </td>
                      <td style="text-align: left;">
                        <span *ngIf="data.wpbid !== null; else elseBlock3">WPB</span>
                        <ng-template #elseBlock3>Normal</ng-template>
                      </td>
                      <td style="text-align: left;">
                        <span *ngIf="data.risk_Status === '1'">Active</span>
                        <span *ngIf="data.risk_Status === '2'">Close (Turn to problem)</span>
                        <span *ngIf="data.risk_Status === '3'">Close (Mitigation Completed)</span>
                        <span *ngIf="data.risk_Status === '4'">Close (Other)</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalBUapp">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Please Select Business Unit for Register Risk Profile</h5>
        </div>
        <div class="modal-body">
          <div class="row col-12 mx-0 px-0">
            <div class="col-4 pt-2">
              <strong style="font-size:18px;">Business Unit Name</strong>
            </div>
            <div class="col-8 px-0 pt-2">
              <input type="text" class="form-control m-0" aria-label="Number" matInput [formControl]="myControlBUDD"
                [matAutocomplete]="auto1" [(ngModel)]="tempselectbu" style="font-size:16px;"
                (keyup.enter)="func_OkBU('')">
              <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptionBUDD | async" [value]="option.text">
                  {{option.text}}: {{option.fullname}}
                </mat-option>
              </mat-autocomplete>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-12" style="text-align: center;">
            <button type="button" style="width:100px;" class="btn btn-primary mr-2"
              (click)="func_OkBU('popup')">OK</button>
            <button type="button" style="width:100px;" class="btn btn-secondary" (click)="goHome()">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalAlertdanger5">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <span class="bg-danger">&nbsp;</span>
        <div class="modal-header">
          <div class="row col-12 text-danger">
            <div class="col-2">
              <em class="far fa-times-circle" style="font-size:50px;"></em>

            </div>
            <div class="col-10 pt-2">
              <h4 class="modal-title">Alert !</h4>
            </div>
          </div>
        </div>
        <div class="modal-body text-body border-0">
          <div class="col-12" style="text-align: left;">
            <span style="font-size:18px;">{{ alertflow }}</span>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger w-25" (click)="closeflow()">OK</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalAlertdangerApprove">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <span class="bg-danger">&nbsp;</span>
        <div class="modal-header">
          <div class="row col-12 text-danger">
            <div class="col-2">
              <em class="far fa-times-circle" style="font-size:50px;"></em>

            </div>
            <div class="col-10 pt-2">
              <h4 class="modal-title">Alert !</h4>
            </div>
          </div>
        </div>
        <div class="modal-body text-body border-0">
          <div class="col-12" style="text-align: left;">
            <span style="font-size:18px;">{{ alertflow }}</span>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger w-25" (click)="closeApprove()">OK</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalAlertsuccess5">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <span class="bg-success">&nbsp;</span>
        <div class="modal-header">
          <div class="row col-12 text-success">
            <div class="col-2">
              <em class="far fa-check-circle" style="font-size:50px;"></em>

            </div>
            <div class="col-10 pt-2">
              <h4 class="modal-title">Alert !</h4>
            </div>
          </div>
        </div>
        <div class="modal-body text-body border-0">
          <div class="col-12" style="text-align: left;">
            <span style="font-size:18px;">{{ alertflow }}</span>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-success w-25" (click)="closeflowsuccess()">OK</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalAlertWarning">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <span class="bg-warning">&nbsp;</span>
        <div class="modal-header">
          <div class="row col-12 text-warning">
            <div class="col-2">
              <em class="fas fa-exclamation-triangle" style="font-size:50px;"></em>

            </div>
            <div class="col-10 pt-2">
              <h4 class="modal-title">Alert !</h4>
            </div>
          </div>
        </div>
        <div class="modal-body text-body border-0">
          <div class="col-12" style="text-align: left;">
            <span style="font-size:18px;">Are you sure you want to delete this item?</span>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-warning w-25 text-white" (click)="ConfirmDel()">Yes</button>
          <button type="button" class="btn btn-secondary w-25" data-dismiss="modal">No</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalVersioning">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <span class="bg-info">&nbsp;</span>
        <div class="modal-header">
          <div class="row col-12 text-info">
            <div class="col-10 pt-2">
              <h4 class="modal-title">Version</h4>
            </div>
          </div>
        </div>
        <div class="modal-body text-body border-0">
          <div class="col-12">
            <div class="row">
              <div class="col-4 pt-2">
                <strong style="font-size:18px;">Year</strong>
              </div>
              <div class="col-8 px-0">
                <select class="form-control" [(ngModel)]="VersionYear" (change)="func_getQuarterVersion()">
                  <option value="">Please Select</option>
                  <option *ngFor="let item of DDLYear" value="{{ item.value }}">{{ item.text }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-12 mt-2">
            <div class="row">
              <div class="col-4 pt-2">
                <strong style="font-size:18px;">Quarter</strong>
              </div>
              <div class="col-8 px-0">
                <select class="form-control" [(ngModel)]="Quarter_Getlist" [disabled]="VersionYear == ''">
                  <option value="">Please Select</option>
                  <option *ngFor="let item of Quarter" value="{{ item.value }}">{{ item.text }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-info w-25 text-white" (click)="func_okversion()">Ok</button>
          <button type="button" class="btn btn-secondary w-25" data-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalCommentCR">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <span class="bg-info">&nbsp;</span>
        <div class="modal-header">
          <div class="row col-12 text-info">
            <div class="col-2">
              <em class="fas fa-info-circle" style="font-size:50px;"></em>

            </div>
            <div class="col-10 pt-2">
              <h4 class="modal-title">Alert !</h4>
            </div>
          </div>
        </div>
        <div class="modal-body text-body border-0">
          <div class="col-12" style="text-align: left;">
            <span style="font-size:18px;">Comment</span>
            <input type="text" class="form-control" [(ngModel)]="Consolecomment"
              (input)="Consolecomment=$event.target.value">
            <p id="Consolecomment" style="color: red;">Comment is required.</p>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-info w-25 text-white" (click)="func_CR_RE_Console()">Yes</button>
          <button type="button" class="btn btn-secondary w-25" data-dismiss="modal">No</button>
        </div>
      </div>
    </div>
  </div> -->
  <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalCommentCR">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <span class="bg-info">&nbsp;</span>
        <div class="modal-header">
          <div class="row col-12 text-info">
            <div class="col-0">
              <em class="fas fa-info-circle" style="font-size:50px;"></em>
            </div>
            <div class="col-10 pt-2">
              <h4 class="modal-title" data-toggle="tooltip" data-placement="top">Reason to Remove</h4>
            </div>
          </div>
        </div>
        <div class="modal-body text-body border-0">
          <table class="table-bordered table-hover table-form" style="width: 100%;">
            <caption style="display: none;">This is a custom table</caption>
            <thead style="text-align: center;font-size: 18px;">
              <tr style="background-color: #023154;color:white;">
                <th class="col-md-4" style="vertical-align: middle;">Risk Name</th>
                <th class="col-md-8" style="vertical-align: middle;">Comment</th>
              </tr>
            </thead>
            <tbody style="font-size:18px;vertical-align: top;">       
                <tr>
                  <td style="font-size:18px;">{{RiskFullName}}</td>
                  <td>
                    <input type="text" class="form-control" [(ngModel)]="Consolecomment"
                    (input)="Consolecomment=$event.target.value">
                    <p id="Consolecomment" style="color: red;margin-bottom: 0px; font-size: 15px;">Comment is required.</p>
                  </td>
                </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-info text-white" style="width: 150px;" (click)="func_CR_RE_Console()">Yes</button>
          <button type="button" class="btn btn-secondary"  style="width: 150px;" data-dismiss="modal" data-dismiss="modal">No</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalSelectMethodology">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Please Select Register Risk Profile Methodology</h5>
        </div>
        <div class="modal-body">
          <div class="row col-12 mx-0 px-0">
            <div class="col-4 pt-2">
              <strong style="font-size:18px;">Register Methodology</strong>
            </div>
            <div class="col-8 px-0 pt-2">
              <button type="button" class="btn mr-3 Preliminary"
                (click)="func_btn_RegisterNewRisk_BG(2,'new')">Preliminary</button>
              <button type="button" class="btn FullAnalysis" (click)="func_btn_FullAnalysisRisk_BG(2,'new')">Full
                Analysis</button>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-12" style="text-align: center;">
            <button type="button" style="width:100px;" class="btn btn-secondary"
              (click)="closeSelectMethodology()">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalAlertwarning5">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <span class="bg-warning">&nbsp;</span>
        <div class="modal-header">
          <div class="row col-12 text-warning">
            <div class="col-2">
              <em class="fas fa-exclamation-triangle" style="font-size:50px;"></em>
            </div>
            <div class="col-10 pt-2">
              <h4 class="modal-title">Alert !</h4>
            </div>
          </div>
        </div>
        <div class="modal-body text-body border-0">
          <div class="col-12" style="text-align: left;">
            <span style="font-size:18px;">This transaction will re-work flow from the start.</span><br>
            <span style="font-size:18px;">Are you sure you want to update?</span>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-warning w-25 text-white" (click)="Yeswarning()">YES</button>
          <button type="button" class="btn btn-secondary w-25" (click)="closewarning()">NO</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalCommentCRList" (keyup.esc)="dismissModalCommentCRList()">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <span class="bg-info">&nbsp;</span>
        <div class="modal-header">
          <div class="row col-12 text-info">
            <div class="col-0">
              <em class="fas fa-info-circle" style="font-size:50px;"></em>
            </div>
            <div class="col-10 pt-2">
              <h4 class="modal-title" data-toggle="tooltip" data-placement="top">Reason to Remove</h4>
            </div>
          </div>
        </div>
        <div class="modal-body text-body border-0">
          <table class="table-bordered table-hover table-form" style="width: 100%;">
            <caption style="display: none;">This is a custom table</caption>
            <thead style="text-align: center;font-size: 18px;">
              <tr style="background-color: #023154;color:white;">
                <th class="col-md-4" style="vertical-align: middle;">Risk Name</th>
                <th class="col-md-8" style="vertical-align: middle;">Comment</th>
              </tr>
            </thead>
            <tbody style="font-size:18px;vertical-align: top;">
              <ng-container *ngIf="mode === 'multiple'; else elseMode"> 
                <tr *ngFor="let data of tempSelectedRemoveAll">
                  <td style="font-size:18px;">
                    {{data.Risk_Name}}
                  </td>
                  <td>
                    <input type="text" class="form-control" [(ngModel)]="data.Risk_Submit_Reason"
                      (input)="data.Risk_Submit_Reason=$event.target.value">
                    <p *ngIf="data.isEmptyCommentLst" id="data.Risk_Submit_Reason" style="color: red;margin-bottom: 0px; font-size: 15px;">Comment is required.</p>
                  </td>
                </tr>
              </ng-container>
              <ng-template #elseMode>
                <tr>
                  <td style="font-size:18px;">{{RiskFullName}}</td>
                  <td>
                    <input type="text" class="form-control" [(ngModel)]="ConsolecommentLst"
                      (input)="ConsolecommentLst=$event.target.value">
                    <p *ngIf="isEmptyComment" id="ConsolecommentLst" style="color: red;margin-bottom: 0px; font-size: 15px;">Comment is required.</p>
                  </td>
                </tr>
              </ng-template>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-info text-white" style="width: 150px;" (click)="func_CR_RE_ConsoleList()">Yes</button>
          <button type="button" class="btn btn-secondary"  style="width: 150px;" data-dismiss="modal" (click)="dismissModalCommentCRList()">No</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalAllRemoveComment">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <span class="bg-info">&nbsp;</span>
        <div class="modal-header">
          <div class="row col-12 text-info">
            <div class="col-0">
              <em class="fas fa-info-circle" style="font-size:50px;"></em>
            </div>
            <div class="col-10 pt-2">
              <h4 class="modal-title" data-toggle="tooltip" data-placement="top">Confirm to Remove</h4>
            </div>
          </div>
        </div>
        <div class="modal-body text-body border-0">
          <table class="table-bordered table-hover table-form" style="width: 100%;">
            <caption style="display: none;">This is a custom table</caption>
            <thead style="text-align: center; font-size: 18px;">
              <tr style="background-color: #023154; color: white;">
                <th class="col-md-4" style="vertical-align: middle;">Risk Name</th>
                <th class="col-md-8" style="vertical-align: middle;">Comment</th>
              </tr>
            </thead>
            <tbody style="font-size: 18px; vertical-align: top;">
              <tr *ngFor="let data of tempSeletedRemove">
                <td style="font-size: 18px;">{{data.Risk_Name}}</td>
                <td>
                  <div style="display: flex; align-items: center;">
                    <input type="text" class="form-control" [(ngModel)]="data.Risk_Submit_Reason" [disabled]="!data.editRemoveCommentMode">
                    <button class="btn btn-sm" (click)="toggleEditRemoveComment(data)" *ngIf="!data.editRemoveCommentMode">
                      <em class="far fa-edit text-warning icon-in-table"></em>
                    </button>
                    <button type="button" class="btn" (click)="toggleEditRemoveComment(data)" *ngIf="data.editRemoveCommentMode" [disabled]="data.Risk_Submit_Reason === ''">
                      <em class="far fa-save icon-in-table text-success"></em>
                    </button>
                  </div>
                  <p *ngIf="data.Risk_Submit_Reason.length === 0 || data.Risk_Submit_Reason.trim() === ''" style="color: red;margin-bottom: 0px; font-size: 15px;">Comment is required.</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>        
        <div class="modal-footer">
          <button type="button" style="width:150px" class="btn btn-info text-white"
            (click)="func_ConfirmMyRiksCheckCompleteRemove()">Submit</button>
          <button type="button" style="width:150px" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalAlertAllRemoveComment">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <span class="bg-danger">&nbsp;</span>
            <div class="modal-header">
                <div class="row col-12 text-danger">
                    <div class="col-2">
                        <em class="far fa-times-circle" style="font-size:50px;"></em>
                    </div>
                    <div class="col-10 pt-2">
                        <h4 class="modal-title">Alert !</h4>
                    </div>
                </div>
            </div>
            <div class="modal-body text-body border-0">
                <div class="col-12" style="text-align: left;">
                    <span style="font-size:18px;">{{ alertflow }}</span>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger w-25" data-dismiss="modal" (click)="closeModalAlertAllRemoveComment()">OK</button>
            </div>
        </div>
    </div>
  </div>
</div>