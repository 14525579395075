import { OnInit, Component } from "@angular/core";
import { Masterserve } from '../../../services/master.service';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
declare let $;
import { AuthenService } from "src/app/services/authenservice.service";


@Component({
    selector: 'app-MasterRiskRating',
    templateUrl: './masterriskrating.html',
    styleUrls: ['./masterriskrating.css'],
    providers: [Masterserve, NgbModalConfig, NgbModal],
})

export class MasterRiskRating implements OnInit {

    SessionEmpID;
    Data_SessionStorage;
    Regis_RiskRating;
    tableriskrating;
    Module;
    isDisabled;
    EscalationLevel;
    isChecked;
    RiskRating_Getlist = ''
    myModel = false;
    LikelihoodDDL;
    ImpactDDL;
    RiskRatingDDL;
    YearDD;
    QuarterDD;

    model = {
        Year:null,
        Quarter:null
    }
    search = {
        Year:null,
        Quarter:null
    }
    QuarterDD_insert;

    currentYear;
    loading = false;

    constructor(public authserviceToken: AuthenService,config: NgbModalConfig, private modalService: NgbModal,
        public mserve: Masterserve) {
        config.backdrop = 'static';
        config.keyboard = false;
    }

    ngOnInit(): void {
        this.Data_SessionStorage = JSON.parse(sessionStorage.datainfo);
        //console.log('Data_SessionStorage => ',this.Data_SessionStorage);
        this.SessionEmpID = this.Data_SessionStorage[0].employeE_ID;

        let date = new Date();
        this.currentYear = date.getFullYear();
        this.search.Year = date.getFullYear();

        this.Regis_RiskRating = {
            RiskRatingID: '',
            QuarterID: '',
            RiskRatingCode: '',
            Likelihood: '',
            Impact: '',
            RiskRating: '',
            LikelihoodAndImpact: '',
            EscalationLevel: '',
            DelFlag: ''
        }

        this.loadDDL();

    }

    loadDDL() {
        let _s = this; 
          _s.mserve.Master_DDL({ Module: "YearDD" }).then((response) => {
            _s.YearDD = response.data.body
            //console.log('YearDD',_s.YearDD);
            _s.func_GetQuarter('1');
        })

        _s.mserve.Master_DDL({ Module: "RatingDD" }).then((response) => {
            _s.RiskRatingDDL = response.data.body
            //console.log('RiskRatingDDL',_s.RiskRatingDDL)
        })
    }

    func_GetQuarter (chk) {
        
        const _s = this;
        let d = new Date();
        let currentMonth = d.getMonth() + 1;
        _s.mserve.Master_DDL({ Module:"QuarterDD",TextSearch1: this.search.Year }).then((response) => {
            _s.QuarterDD = response.data.body
            //console.log("QuarterDD : ",_s.QuarterDD)
            if (chk == 1) {
                this.func_calQuarter(currentMonth);
            }
            else {
                this.search.Quarter = '';
                this.tableriskrating = [];
            }
        })
    }

    func_calQuarter (month) {
        
        this.search.Quarter = ''
        if (month >= 1 && month <= 3) {
            this.search.Quarter = this.QuarterDD[0].value;
        } else if (month >= 4 && month <= 6) {
            this.search.Quarter = this.QuarterDD[1].value;
        } else if (month >= 7 && month <= 9) {
            this.search.Quarter = this.QuarterDD[2].value;
        } else if (month >= 10 && month <= 12) {
            this.search.Quarter = this.QuarterDD[3].value;
        }
        this.func_GETMASTER_Likelihood();
    }

    func_GETMASTER_Likelihood() {
        
        let tempQuarter = (this.search.Year == this.currentYear) ? this.search.Quarter : this.QuarterDD[3].value;
        
        let formData = {
            Module: "PrelimiLikelihoodDD",
            TextSearch7:tempQuarter
        }
        this.mserve.Master_DDL(formData).then((response) => {
            //console.log("dataLikelihoodDD:", response.data.body)
            if (response.data.status ){
                this.LikelihoodDDL = response.data.body;
                this.func_GETMASTER_Impact();
            } else {
                //console.log('false', response)
            }
        });
    }

    func_GETMASTER_Impact() {
        
        let tempQuarter = (this.search.Year == this.currentYear) ? this.search.Quarter : this.QuarterDD[3].value;

        let formData = {
            Module: "PrelimiImpactDD",
            TextSearch7:tempQuarter
        }
        this.mserve.Master_DDL(formData).then((response) => {
            //console.log("dataImpact:", response.data.body)
            if (response.data.status ){
                this.ImpactDDL = response.data.body;
                this.func_GETDATA_RiskRating();
            } else {
                //console.log('false', response)
            }
        });
    }
    
    func_GETDATA_RiskRating() {
        
        let tempQuarter = this.search.Quarter 

        this.loading = true;
        let datavalue = {
            SessionEmpID: this.SessionEmpID,
            Module: "getlist",
            body: {
                RiskRatingID: "",
                QuarterID :tempQuarter,
                RiskRatingCode: "",
                Likelihood: "2",
                Impact: "3",
                RiskRating :this.RiskRating_Getlist,
                EscalationLevel: "1",
                DelFlag: "0",
                LikelihoodAndImpact: ""
            }
        }
        this.mserve.GetRiskRating(datavalue).then((response) => {
            //console.log("RiskRating Data :", response.data.body)
            if (response.data.status ){
                this.tableriskrating = response.data.body;
                this.loading = false;
            } else {
                //console.log('false', response)
                alert(response.data.errorMessage);
            }
        }).catch((e) => {
            this.authserviceToken.CheckTokenExpire(e.response.status)
            });
    }

    func_savebtn() {
        let _s = this;
        
        let datavalue = {
            SessionEmpID: this.SessionEmpID,
            Module: this.Module,
            body: {
                RiskRatingID: this.Regis_RiskRating.RiskRatingID,
                QuarterID: this.Regis_RiskRating.QuarterID,
                RiskRatingCode: this.Regis_RiskRating.RiskRatingCode,
                Likelihood: this.Regis_RiskRating.Likelihood,
                Impact: this.Regis_RiskRating.Impact,
                RiskRating: this.Regis_RiskRating.RiskRating,
                EscalationLevel: this.Regis_RiskRating.EscalationLevel ? '1' : '0',
                DelFlag: "0",
                likelihoodAndImpact: this.Regis_RiskRating.Likelihood + this.Regis_RiskRating.Impact,
            }
        }
        //console.log("datavalue", datavalue)
        this.mserve.GetRiskRating(datavalue).then((response) => {
            if (response.data.status ){
                //console.log("true", response.data.body)
                $('#modaladdriskrating').modal('hide')
                this.func_GETDATA_RiskRating();
            } else {
                //console.log('false', response)
                alert(response.data.ErrorMessage);
            }
        }).catch((e) => {
            this.authserviceToken.CheckTokenExpire(e.response.status)
            });
    }

    func_addriskrating(id, mode, item) {
        
        if (mode === 'Add') {
            this.Module = 'insert';

            this.model.Year = '';
            this.model.Quarter = '';
            this.LikelihoodDDL = [];
            this.ImpactDDL = [];

            this.Regis_RiskRating.RiskRatingID = '';
            this.Regis_RiskRating.QuarterID = '';
            this.Regis_RiskRating.RiskRatingCode = '';
            this.Regis_RiskRating.Likelihood = '';
            this.Regis_RiskRating.Impact = '';
            this.Regis_RiskRating.RiskRating = '';
            this.Regis_RiskRating.LikelihoodAndImpact = '';
            this.Regis_RiskRating.EscalationLevel = '';
            this.Regis_RiskRating.DelFlag = '';
            $('#modaladdriskrating').modal('show');
            this.myModel = false;
        }
        else { 
            if (item.riskRatingID === id) {
                this.Module = 'update';
                this.model.Quarter = '';
                this.model.Year = item.quarterYear;
                this.SelectedYear(item.quarterID);
                this.Regis_RiskRating.RiskRatingID = item.riskRatingID;
                this.Regis_RiskRating.RiskRatingCode = item.riskRatingCode;
                this.myModel = item.escalationLevel === "1";
                this.Regis_RiskRating.Likelihood = this.LikelihoodDDL.find(item_LikelihoodDDL => item.likelihood === item_LikelihoodDDL.text)?.value || '';
                this.Regis_RiskRating.Impact = this.ImpactDDL.find(item_ImpactDDL => item.impact === item_ImpactDDL.text)?.value || '';
              
                this.Regis_RiskRating.RiskRating = this.RiskRatingDDL.find(item_RiskRatingDDL => item.riskRating === item_RiskRatingDDL.text)?.value || '';
                this.isDisabled = this.Regis_RiskRating.RiskRating === "2";
              }
            $('#modaladdriskrating').modal('show')
        }
    }

    func_closebtn() {
        $('#modaladdriskrating').modal('hide')
    }


    onChange(value) {
        //console.log('Change Risk Rating' + value)
        
        for (let item_tableriskrating of  this.tableriskrating) {
            if (this.Regis_RiskRating.RiskRating === value) {
                if (this.Regis_RiskRating.RiskRating == "2") {
                    this.isDisabled = false
                    this.myModel = false;
                } else {
                    this.isDisabled = true
                    this.myModel = false;
                }
                //console.log(item_tableriskrating);
            }
        }
    }

    SelectedYear (value) {
        
        let _s = this;

        _s.mserve.Master_DDL({ Module:"QuarterDD" ,TextSearch1: _s.model.Year }).then((response) => {
            _s.QuarterDD_insert = response.data.body
            //console.log("QuarterDD_insert : ",_s.QuarterDD_insert);

            if (value !== '') {

                for (let item_QuarterDD_insert of _s.QuarterDD_insert){
                    if (value === item_QuarterDD_insert.value) {
                        _s.model.Quarter = item_QuarterDD_insert.value;
                        _s.Regis_RiskRating.QuarterID = item_QuarterDD_insert.value;
                        break;
                    }
                }
            } else {
                this.LikelihoodDDL = [];
                this.ImpactDDL = [];
                _s.model.Quarter = '';
            }
        });
    }
}