import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ShareService } from 'src/app/services/share.service';
import DOMPurify from 'dompurify';
declare const $;

@Component({
  selector: 'app-modal-selectbu',
  templateUrl: './modal-selectbu.component.html',
  styleUrls: ['./modal-selectbu.component.css'],
})
export class ModalSelectbuComponent implements OnInit {
  private _buCodeInput: string;
  private _registerBy: string;
  @Input() active: boolean = true;
  @Input() title: string = 'Register Risk Profile';

  @Output() returnEvent = new EventEmitter<string>();

  myControlBUDD = new UntypedFormControl();
  tempselectbu = '';
  dataService: ShareService;
  filteredOptionBUDD: Observable<string[]>;
  BusinessNameDD = [];
  tempChkBUDD = [];
  permissionBUDD = [];

  tempHeadActing = [];
  tempAssetBU = [];
  tempChildOrg = [];
  tempOwnerInfo = [];
  tempOrg = [];
  tempERM = [];
  tableriskrating;
  tempDelItem;
  Data_SessionStorage;

  link: any;
  empid: string;

  constructor(private injector: Injector) {
    this.dataService = injector.get<ShareService>(ShareService);
  }

  ngOnInit(): void {
    if (this.active) {
      if (sessionStorage.link) {
        this.link = JSON.parse(sessionStorage.link);
      }
      this.Data_SessionStorage = JSON.parse(sessionStorage.datainfo);
      this.empid = this.Data_SessionStorage[0].employeE_ID;

      this.checkRoleFromStoreg();
      this.tempHeadActing = this.Data_SessionStorage[0].headActing;
      this.getTempToBusinessNameDD();

      this.setUniqValues();

      this.BusinessNameDD = [];
      Array.prototype.push.apply(this.BusinessNameDD, this.tempChkBUDD);

      this.filteredOptionBUDD = this.myControlBUDD.valueChanges.pipe(
        startWith(''),
        map((value) => this._filterBUDD(value))
      );
    } else {
      $('#modalBU5').remove();
      $('.modal-backdrop').hide();
      $('body').css('overflow', 'auto');
      this.dataService.sendData(sessionStorage.getItem('buName'));
    }
  }

  private _filterBUDD(value: string): string[] {
    let tempFileter;
    const filterValue = value?.toLowerCase();
    if (filterValue !== '') {
      tempFileter = this.BusinessNameDD.filter((option) =>
        option.text?.toLowerCase().includes(filterValue)
      );
      if (tempFileter.length !== 0) {
        return tempFileter;
      }
    } else {
      return this.BusinessNameDD;
    }
  }

  private setUniqValues() {
    let uniq_values = [];
    this.permissionBUDD = this.BusinessNameDD;
    for (let x of this.BusinessNameDD) {
      if (uniq_values.indexOf(x.value) == -1) {
        uniq_values.push(x.value);
        this.tempChkBUDD.push(x);
      }
    }
  }

  private checkRoleFromStoreg() {
    let isRoleOwnerAndCo = ['Co', 'Owner'].includes(sessionStorage.Role);
    let isPermissionOwner = this.Data_SessionStorage[0].s_PERMISSSION_LEVEL
      ?.toLowerCase()
      .includes('owner');
    let isPermissionCo = this.Data_SessionStorage[0].s_PERMISSSION_LEVEL
      ?.toLowerCase()
      .includes('co');

    if (sessionStorage.Role === 'Staff') {
      this.setTempBu();
      this.sethildorg();
    } else if (
      sessionStorage.Role === 'Co' &&
      (this.Data_SessionStorage[0].s_PERMISSSION_LEVEL.includes('OWNER') ||
        this.Data_SessionStorage[0].s_PERMISSSION_LEVEL.includes('Owner'))
    ) {
      this.setTempBu();
      this.setTempOrg();
      this.sethildorg();
    } else if (sessionStorage.Role === 'Co') {
      this.setTempBu();
      this.setTempOrg();
      this.sethildorg();
    } else if (sessionStorage.Role === 'Owner') {
      this.setTempBu();
      this.SetTempOwnerInfo();
    } else if (sessionStorage.Role === 'ERM') {
      this.setTempOrg();
      this.SetTempERM();
      this.sethildorg();
    }
  }

  private SetTempERM() {
    this.tempERM = this.Data_SessionStorage[0].ermInfo
      ? this.Data_SessionStorage[0].ermInfo
      : [];
  }

  private SetTempOwnerInfo() {
    this.tempOwnerInfo = this.Data_SessionStorage[0].ownerInfo
      ? this.Data_SessionStorage[0].ownerInfo
      : [];
  }

  private setTempOrg() {
    this.tempOrg = this.Data_SessionStorage[0].coInfo
      ? this.Data_SessionStorage[0].coInfo
      : [];
  }

  private sethildorg() {
    this.tempChildOrg = this.Data_SessionStorage[0].childOrganizationInfo
      ? this.Data_SessionStorage[0].childOrganizationInfo
      : [];
  }

  private setTempBu() {
    this.tempAssetBU =
      this.Data_SessionStorage[0].assetInfo !== null
        ? this.Data_SessionStorage[0].assetInfo
        : [];
  }

  private getTempToBusinessNameDD() {
    for (let item1 of this.tempAssetBU) {
      this.BusinessNameDD.push({
        value: item1.asset_Code,
        text: item1.asset_Short,
        fullname: item1.asset_Name,
        state: 'Asset',
        level: item1.asset_Level,
        role: item1.asset_Coordinators,
      });
    }
    for (let item2 of this.tempOrg) {
      this.BusinessNameDD.push({
        value: item2.organizatioN_ID,
        text: item2.abbreviation,
        fullname: item2.organizatioN_NANE,
        state: 'Org',
        level: item2.organizatioN_LEVEL,
        role: item2.coordinator_Employee_Id,
      });
    }
    for (let item3 of this.tempERM) {
      this.BusinessNameDD.push({
        value: item3.organizatioN_ID,
        text: item3.abbreviation,
        fullname: item3.organizatioN_NANE,
        state: 'Corporate',
        level: item3.organizatioN_LEVEL,
        role: 'Co',
      });
    }
    if (this.tempHeadActing) {
      for (let item4 of this.tempHeadActing) {
        this.BusinessNameDD.push({
          value: item4.deptCode,
          text: item4.abbreviation,
          fullname: item4.deptName,
          state: 'Org',
          level: item4.organizatioN_LEVEL,
        });
      }
    }

    for (let item5 of this.tempChildOrg) {
      this.BusinessNameDD.push({
        value: item5.organizatioN_ID,
        text: item5.abbreviation,
        fullname: item5.name,
        state: 'Org',
        level: item5.organizatioN_LEVEL,
        role: '',
      });
    }
    for (let item6 of this.tempOwnerInfo) {
      this.BusinessNameDD.push({
        value: item6.organizatioN_ID,
        text: item6.abbreviation,
        fullname: item6.name,
        state: 'Org',
        level: item6.organizatioN_LEVEL,
        role: 'Owner',
      });
    }

    if (this.BusinessNameDD.length == 1) {
      this.dataService.sendData(this.BusinessNameDD[0].text);
      let buCode = this.BusinessNameDD[0].value;
      sessionStorage.setItem('buCode', this.BusinessNameDD[0].value);
      sessionStorage.setItem('buName', this.BusinessNameDD[0].text);
      this.checkRole(this.BusinessNameDD[0].value);
      this.returnEvent.emit(buCode);
      $('#modalBU5').modal('hide');
      $('#modalBU5').remove();
      $('.modal-backdrop').hide();
      $('body').css('overflow', 'auto');
      const modalOpenElement = document.querySelector(
        '.modal-open'
      ) as HTMLElement;
      if (modalOpenElement) {
        modalOpenElement.style.paddingRight = '0';
      }
    } else {
      $('#modalBU5').modal('show');
    }
  }

  func_OkBU() {
    this.dataService.sendData(this.tempselectbu);
    let buCode = this.BusinessNameDD.find((w) => w.text == this.tempselectbu);
    this.checkRole(buCode.value);

    sessionStorage.setItem('buCode', buCode.value);
    sessionStorage.setItem('buName', this.tempselectbu);
    this.returnEvent.emit(buCode.value);

    $('#modalBU5').modal('hide');
    $('#modalBU5').remove();
    $('.modal-backdrop').hide();
    $('body').css('overflow', 'auto');
    const modalOpenElement = document.querySelector(
      '.modal-open'
    ) as HTMLElement;
    if (modalOpenElement) {
      modalOpenElement.style.paddingRight = '0';
    }
  }

  checkRole(buCodeSelect) {
    let chkRole = sessionStorage.getItem('Role');
    if (chkRole == 'Owner') 
    {
      sessionStorage.setItem('buRole', 'Owner');
    } 
    else if (chkRole == 'ERM') 
    {
      sessionStorage.setItem('buRole', 'ERM');
    } 
    // else if (buCodeSelect == this.empid || buCodeSelect == 'Co') 
    else if (this.permissionBUDD.find(w => w.value == buCodeSelect && w.role == this.empid))
    {
      sessionStorage.setItem('buRole', 'Co');
    } 
    else 
    {
      sessionStorage.setItem('buRole', 'Staff');
    }
  }

  goHome() {
    let cleanPath = DOMPurify.sanitize(sessionStorage.getItem('mainUrl'));
    if (isValidUrl(cleanPath)) {
      window.location.replace(cleanPath);
    } else {
      console.error('Invalid URL provided:', cleanPath);
    }
  }
}

function isValidUrl(string) {
  try {
    const url = new URL(string);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch (_) {
    return false;
  }
}
