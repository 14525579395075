<div class="container-fluid appform my-3">
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">
            <div class="col-12 col-md-12 col-lg-12 col-xl-12 pb-5 mt-5 mb-5">
                <ul class="rounded" style="background-color:#023154;font-size: 20px;">
                    <li class="active" id="Tab1"><a (click)="ClickTab('1')"><strong>Impact Criteria</strong></a></li>
                    <li id="Tab2"><a (click)="ClickTab('2')"><strong>Likelihood</strong></a></li>
                </ul>
                <div class="card col-12 mt-4 px-3 py-3" *ngIf="page === '1'">
                    <div class="likelihoodcriteria col-12 mb-3 rounded">
                        <div class="col-0 pt-2" style="font-size: 22px;">
                            <em class="fas fa-bars icon"></em>&nbsp;&nbsp;
                            <strong>Impact Criteria</strong>
                        </div>
                    </div>
                    <div class="col-6 col-md-4 col-xl-2 mb-3 px-0" style="display: inline-flex;">
                        <span class="formtext mr-2 mt-1">Year</span>
                        <select class="form-control" style="width:50%;" [(ngModel)]="Year_Getlist" (change)="func_GetQuarter()">
                            <option *ngFor="let item of YearDD" value="{{ item.text }}">{{ item.text }}</option>
                        </select>
                    </div>

                    <div style="overflow-x: auto;">
                        <table class="tablefix" style="table-layout: fixed; width:100%;">
                            <caption style="display: none;" >This is a custom table</caption>
                            <thead style="text-align: center;background-color:#033153;color:white;font-size: 22px;">
                                <tr>
                                    <th style="width: 300px;">Rating</th>
                                    <th style="width: 300px;" *ngFor='let data of tableimpactCriteria'>
                                        {{data.impactCateName}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody style="background-color: white;font-size: 20px;">
                                <tr *ngFor='let data of Alltable; let i = index'>
                                    <td style="text-align: center;" style="vertical-align: top;">{{data.rat0}}  ({{ 5 - i }})</td>
                                    <td style="vertical-align: top;" *ngFor='let item2 of tableimpactCriteria; let y = index' [innerHTML]="fus_aaa(data,y)">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card col-12 mt-4 px-3 py-3" *ngIf="page === '2'">

                    <div class="likelihoodcriteria col-12 mb-3 rounded">
                        <div class="col-0 pt-2" style="font-size: 22px;">
                            <em class="fas fa-bars icon"></em>&nbsp;&nbsp;
                            <strong>Likelihood</strong>
                        </div>
                    </div>
                    <div class="col-6 col-md-4 col-xl-2 mb-3 px-0" style="display: inline-flex;">
                        <span class="formtext mr-2 mt-1">Year</span>
                        <select class="form-control" style="width:50%;" [(ngModel)]="Year_Getlist" (change)="func_GetQuarter()">
                            <option *ngFor="let item of YearDD" value="{{ item.text }}">{{ item.text }}</option>
                        </select>
                    </div>

                    <div class="table-responsive">
                        <table class="table-bordered" style="width:100%;">
                            <caption style="display: none;" >This is a custom table</caption>
                            <thead style="text-align: center;font-size: 22px;">
                                <tr style="background-color:#033153;color:white;" *ngFor='let data of tablelikeLihoodCriteria'>
                                    <th style="width: 150px;" rowspan="1">Rating</th>
                                    <th style="min-width: 320px;" *ngFor='let item of data.sResLikelihoodItem'>
                                        {{item.likelihoodItemName}}({{item.likelihoodItemCode}})
                                    </th>
                                </tr>
                            </thead>
                            <tbody style="background-color: white;font-size: 20px;">
                                <tr *ngFor='let data of tablelikeLihoodCriteria'>
                                    <td style="vertical-align: top;">{{data.likelihoodName}}</td>
                                    <td style="vertical-align: top;" *ngFor='let item of data.sResLikelihoodItem'>{{item.description}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>

<app-loading [loading]="loading"></app-loading>