import { OnInit, Component, Injectable } from "@angular/core";
import { NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Masterserve } from '../../../services/master.service';
import { ExcelService2 } from '../../../services/excel2.service';
declare let $;

import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AuthenService } from "src/app/services/authenservice.service";


@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

    readonly DELIMITER = '/';

    parse(value: string): NgbDateStruct | null {
        if (value) {
            let date = value.split(this.DELIMITER);
            return {
                day: (date[0], 10),
                month: (date[1], 10),
                year: (date[2], 10)
            };
        }
        return null;
    }

    format(date: NgbDateStruct | null): string {
        if (date !== null) {
            let day = date.day.toString()
            let month = date.month.toString()
            if (day.length < 2) {
                day = '0' + day
            }
            if (month.length < 2) {
                month = '0' + month
            }
            let newdate = day + this.DELIMITER + month + this.DELIMITER + date.year;
            return newdate;

        } else {
            return '';
        }
    }
}
//---------------------------------------------------------------------------------------------------------------------

@Component({
    selector: 'app-ReportRiskItem',
    templateUrl: './ReportRiskItem.html',
    styleUrls: ['./ReportRiskItem.css'],
    providers: [
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
    ]
})
export class ReportRiskItem implements OnInit {
    Data_SessionStorage;
    loading;
    alertflow;
    page = 1;
    pageSize = 15;
    valuecheckSelectAll = false;
    table_RiskProfile = [];
    table_RootCause = [];
    table_Impact = [];
    tempData;
    tempFistGETData;
    tempchkduplicateimpact = [];
    tempdata2 = [];
    chkroleuser;
    Financial;

    DataSearch = {
        Yearfrom: null,
        Yearto: '',
        Quarterfrom: '',
        Quarterto: ''
    }
    DDLQuarterfrom = [];
    DDLQuarterto = [];

    tempBU;
    Search = {
        BuName: '',
        RiskStatus: '',
        RiskKRIStatus: '',
        RiskCate: '',
        RiskRating: '',
        RiskName: '',
        Datefrom: '',
        Dateto: '',
        Tabletype: '0'
    }
    tempGetDDL;

    filteredOptionsBU: Observable<string[]>;
    myControlBU = new UntypedFormControl();

    likelyhoodLevel = [];
    ImpactLevel = [];
    DepartmentDD = [];
    RatingDD = [];
    ImpactCategoryDD = [];
    Risk_ByCategory = [];

    Version = null;
    VersionYear = '';
    VersionQuarter = '';
    DDLYear = [];
    DDLQuarter = [];
    tempCurrentQuarterID = null;
    tempCurrentQuarterIDValue = null;
    currentyear;
    inCludeConsolidate = false;
    constructor(public authserviceToken: AuthenService, public mserve: Masterserve, private excelService2: ExcelService2) { }
    ngOnInit(): void {
        this.Data_SessionStorage = JSON.parse(sessionStorage.datainfo);
        this.chkroleuser = sessionStorage.Role

        const pathnameURL = window.location.pathname;
        let temp = pathnameURL.indexOf("/", pathnameURL.indexOf("/") + 1);
        if (temp !== -1) {
            this.Version = pathnameURL.slice(temp, temp + 2);
        }

        if (this.Version === '/q') {
            $('#modalVersioning').modal('show');
        }

        this.GETDDL_YEAR();
    }

    func_GetDDL_Likelihood() {

        let formData = {
            Module: "LikelihoodLevelDD",
            TextSearch7: this.tempCurrentQuarterIDValue
        }
        this.mserve.Master_DDL(formData).then((response) => {
            //console.log("dataLikelihood_DD:", response.data.body)
            if (response.data.status) {
                this.likelyhoodLevel = response.data.body
                this.func_GetDDL_BusinessName();
            } else {
                //console.log('false', response)
            }
        })
    }
    func_GetDDL_BusinessName() {
        const _s = this;
        let formData = { Module: 'DepartmentDD' }
        this.mserve.Master_DDL(formData).then((response) => {
            //console.log("DepartmentDD :", response.data.body)
            if (response.data.status) {
                this.DepartmentDD = response.data.body
                this.check_In_BusinessName(_s);

                this.filteredOptionsBU = this.myControlBU.valueChanges.pipe(
                    startWith(''),
                    map(value => this._filterBU(value))
                );

                this.func_GetDDL_ImpactLevel();
            } else {
                //console.log('false', response)
            }
        })
    }
    private check_In_BusinessName(_s: this) {
        if (this.tempData !== null) {
            this.tempData.forEach(function (ItemVal,) {
                if ((ItemVal.impact !== null) && (ItemVal.impact !== undefined))
                    ItemVal.impact.forEach(function (ItemVal1,) {
                        check_In_BusinessName_subFN(ItemVal1);

                    });
            });

            this.check_In_BusinessNameII(_s);
        }

        function check_In_BusinessName_subFN(ItemVal1: any) {
            if ((ItemVal1.impact_Mitigation !== null) && (ItemVal1.impact_Mitigation !== undefined))
                ItemVal1.impact_Mitigation.forEach(function (Itemval2) {
                    check_In_BusinessName_Loop(Itemval2);
                });
        }

        function check_In_BusinessName_Loop(Itemval2: any) {
            for (let item_DepartmentDD of _s.DepartmentDD) {
                check_In_BusinessName_LoopII(item_DepartmentDD);
            }

            function check_In_BusinessName_LoopII(item_DepartmentDD: any) {
                if (Itemval2.impact_Mitigation_Owner === item_DepartmentDD.value) {
                    Itemval2.impact_Mitigation_Owner = item_DepartmentDD.text;
                }
            }
        }
    }

    private check_In_BusinessNameII(_s: this) {
        this.tempData.forEach(function (ItemVal) {
            if ((ItemVal.rootCause !== null) && (ItemVal.rootCause !== undefined))
                ItemVal.rootCause.forEach(function (ItemVal1) {
                    if ((ItemVal1.rootCause_Mitigation !== null) && (ItemVal1.rootCause_Mitigation !== undefined))
                        ItemVal1.rootCause_Mitigation.forEach(function (Itemval2) {
                            check_In_BusinessNameII_Loop(Itemval2);
                        });

                });
        });

        function check_In_BusinessNameII_Loop(Itemval2: any) {
            for (let item_DepartmentDD of _s.DepartmentDD) {
                if (Itemval2.rootCause_Mitigation_Owner === item_DepartmentDD.value) {
                    Itemval2.rootCause_Mitigation_Owner = item_DepartmentDD.text;
                }
            }
        }
    }

    func_GetDDL_ImpactLevel() {
        let formData = {
            Module: "ImpactLevelDD",
            TextSearch1: "20000000",
            TextSearch7: this.tempCurrentQuarterIDValue
        }
        this.mserve.Master_DDL(formData).then((response) => {
            //console.log("dataImpactLevel_DD:", response.data.body)
            if (response.data.status) {
                this.ImpactLevel = response.data.body
                if (this.tempGetDDL === '1') {
                    this.func_GETDDL_RiskLevel();
                } else {
                    this.func_GETMASTER_ImpactCategory();
                }
            } else {
                //console.log('false', response)
            }
        })
    }
    func_GETDDL_RiskLevel() {
        this.mserve.Master_DDL({ Module: "RatingDD" }).then((response) => {
            if (response.data.status) {
                //console.log('RatingDD : ', response.data.body)
                this.RatingDD = response.data.body;
                this.func_RiskCategoryDDL();
            } else {
                //console.log('false', response)
            }
        });
    }
    func_RiskCategoryDDL() {
        let chkDDper;
        chkDDper = (sessionStorage.Role === 'ERM') ? '' : '1';

        let formData = {
            Module: "RiskCateDD",
            TextSearch1: chkDDper,
            TextSearch7: this.tempCurrentQuarterIDValue
        }
        this.mserve.Master_DDL(formData).then((response) => {
            if (response.data.status) {
                //console.log('RiskCateDDL : ', response.data.body)
                this.Risk_ByCategory = response.data.body;
                this.func_GETMASTER_Financial();
            } else {
                //console.log('false', response)
            }
        });
    }
    func_GETMASTER_Financial() {
        let formData = {
            Module: "ImpactType",
        }
        this.mserve.Master_DDL(formData).then((response) => {
            //console.log("dataImpactfinancial_DD:", response.data.body)
            if (response.data.status) {
                this.Financial = response.data.body;
                this.func_GETMASTER_ImpactCategory();
            } else {
                //console.log('false', response)
            }
        })
    }
    func_GETMASTER_ImpactCategory() {
        let formData = {
            Module: "ImpactCateDD",
            TextSearch7: this.tempCurrentQuarterIDValue
        }
        this.mserve.Master_DDL(formData).then((response) => {
            //console.log("ImpactCategoryDD:", response.data.body)
            if (response.data.status) {
                this.ImpactCategoryDD = response.data.body;
                if (this.tempData !== null) {
                    this.func_MergeData2();
                }
                this.loading = false;
            } else {
                //console.log('false', response)
            }
        })
    }

    GETDDL_YEAR() {
        const _s = this;
        this.loading = true;
        _s.mserve.Master_DDL({ 'Module': 'YearDD' }).then((response) => {
            if (response.data.status === true) {
                //console.log('YearDD : true', response)
                this.DDLYear = response.data.body;

                if (this.Version === null) {
                    let d = new Date();
                    this.DataSearch.Yearfrom = d.getFullYear();
                    this.currentyear = d.getFullYear();
                    let currentMonth = d.getMonth() + 1;
                    _s.mserve.Master_DDL({ Module: "QuarterDD", TextSearch1: this.DataSearch.Yearfrom }).then((response) => {
                        _s.DDLQuarter = response.data.body
                        //console.log("Quarter : ", _s.DDLQuarter);
                        this.func_calQuarter(currentMonth);
                    });
                }
            } else {
                //console.log('YearDD : false', response)
            }
        })
    }
    func_calQuarter(month) {
        this.VersionQuarter = ''
        this.DataSearch.Quarterfrom = ''
        if (month >= 1 && month <= 3) {
            this.VersionQuarter = this.DDLQuarter[0].value;
            this.tempCurrentQuarterIDValue = this.DDLQuarter[0].value;
            this.DataSearch.Quarterfrom = this.DDLQuarter[0].text;
        } else if (month >= 4 && month <= 6) {
            this.VersionQuarter = this.DDLQuarter[1].value;
            this.tempCurrentQuarterIDValue = this.DDLQuarter[1].value;
            this.DataSearch.Quarterfrom = this.DDLQuarter[1].text;
        } else if (month >= 7 && month <= 9) {
            this.VersionQuarter = this.DDLQuarter[2].value;
            this.tempCurrentQuarterIDValue = this.DDLQuarter[2].value;
            this.DataSearch.Quarterfrom = this.DDLQuarter[2].text;
        } else if (month >= 10 && month <= 12) {
            this.VersionQuarter = this.DDLQuarter[3].value;
            this.tempCurrentQuarterIDValue = this.DDLQuarter[3].value;
            this.DataSearch.Quarterfrom = this.DDLQuarter[3].text;
        }
        this.tempCurrentQuarterID = this.DataSearch.Quarterfrom
        this.func_getData('1');
    }
    func_getQuarterVersion() {
        const _s = this;
        _s.mserve.Master_DDL({ Module: "QuarterDD", TextSearch1: this.VersionYear }).then((response) => {
            _s.DDLQuarter = response.data.body
            //console.log("Quarter : ", _s.DDLQuarter);
        });
    }

    func_getData(value) {

        const _s = this;
        this.tempGetDDL = value
        let tempdatefrom = '', tempdateto = '', chkdate = false

        if(this.DataSearch.Yearfrom === ''){
            this.alertflow = 'Please select Year';
            $('#modalAlertdanger5se').modal('show');
            chkdate = true
        } else if(this.DataSearch.Quarterfrom === ''){
            this.alertflow = 'Please select Quarter';
            $('#modalAlertdanger5se').modal('show');
            chkdate = true
        } else {
            if (this.Search.Datefrom !== '' && this.Search.Dateto === '') {
                this.alertflow = 'Please select Quarter To';
                $('#modalAlertdanger5se').modal('show');
                chkdate = true
            } else if (this.Search.Datefrom === '' && this.Search.Dateto !== '') {
                this.alertflow = 'Please select Quarter From';
                $('#modalAlertdanger5se').modal('show');
                chkdate = true
            } else if (this.Search.Datefrom !== '' && this.Search.Dateto !== '') {
                if (this.Search.Datefrom !== '') {
                    tempdatefrom = this.func_formatdate(this.Search.Datefrom)
                }
                if (this.Search.Dateto !== '') {
                    tempdateto = this.func_formatdate(this.Search.Dateto)
                }
            }
        }

        this.FnGetdataII_chkDate(chkdate, _s, tempdatefrom, tempdateto);
    }

    private FnGetdataII_chkDate(chkdate: boolean, _s: this, tempdatefrom: string, tempdateto: string) {
        if (chkdate === false) {
            this.loading = true;
            this.Search.BuName = (this.tempBU === '') ? '' : this.Search.BuName;
            let d = new Date();
            let cur_year = d.getFullYear();
            let tempQuarterID = null, tempWPBID = null, tempQuarterMaster = null;

            ({ tempQuarterID, tempQuarterMaster, tempWPBID } = this.FnGetdataIII_chkDate(cur_year, _s, tempQuarterID, tempQuarterMaster, tempWPBID));

            let formData = {
                SessionEmpID: this.Data_SessionStorage[0].employeE_ID,
                Email: this.Data_SessionStorage[0].emaiL_ID,
                SecurityCode: '1234',
                body: {
                    Risk_Business_Unit: this.Search.BuName,
                    Risk_Status: this.Search.RiskStatus,
                    Risk_KRI_Status: this.Search.RiskKRIStatus,
                    Risk_Category: this.Search.RiskCate,
                    Risk_Register_Date_From: tempdatefrom,
                    Risk_Register_Date_To: tempdateto,
                    Risk_Rating: this.Search.RiskRating,
                    Risk_Name: this.Search.RiskName.trim(),
                    Filter_Table: this.Search.Tabletype,
                    QuarterID: (this.VersionQuarter != '') ? this.VersionQuarter : tempQuarterID,
                    WPBID: tempWPBID,
                    QuaterMaster: tempQuarterMaster,
                    Role: sessionStorage.getItem('Role'),
                    InCludeConsolidate: this.inCludeConsolidate
                }
            };
            this.tempCurrentQuarterIDValue = (tempQuarterID != null) ? tempQuarterID : tempWPBID;
            //console.log(JSON.stringify(formData));
            this.mserve.ReportItems(formData).then((response) => {
                if (response.data.status) {
                    //console.log('Data : ', response.data.body);
                    this.tempData = response.data.body;
                    for (let item_tempData of this.tempData) {
                        item_tempData.riskWF = '';
                    }
                    //console.log('tempData : ', this.tempData);
                    this.func_GetDDL_Likelihood();
                } else {
                    this.tempData = null;
                    this.loading = false;
                    this.alertflow = response.data.errorMessage;
                    $('#modalAlertdanger5se').modal('show');
                    //console.log('false', response);
                }
            }).catch((e) => {
                this.authserviceToken.CheckTokenExpire(e.response.status);
            });
        }
    }

    private FnGetdataIII_chkDate(cur_year: number, _s: this, tempQuarterID: any, tempQuarterMaster: any, tempWPBID: any) {
        if (cur_year === parseInt(_s.DataSearch.Yearfrom)) {
            ({ tempQuarterID, tempQuarterMaster, tempWPBID } = this.FnGetdataIV_chkDate_CurrentYear(_s, tempQuarterID, tempQuarterMaster, tempWPBID));
        }
        else {
            ({ tempQuarterID, tempQuarterMaster, tempWPBID } = this.FnGetdataIV_chkDate_NonCurrentYear(_s, tempQuarterID, tempQuarterMaster, tempWPBID));
        }
        return { tempQuarterID, tempQuarterMaster, tempWPBID };
    }

    private FnGetdataIV_chkDate_NonCurrentYear(_s: this, tempQuarterID: any, tempQuarterMaster: any, tempWPBID: any) {
        if (_s.DataSearch.Quarterfrom.includes("WPB") === false) {
            //FnGetdataV_forloop_1
            ({ tempQuarterID, tempQuarterMaster } = this.FnGetdataV_forloop_1(_s, tempQuarterID, tempQuarterMaster));
        }
        else {
            //FnGetdataV_forloop_2
            tempWPBID = this.FnGetdataV_forloop_2(_s, tempWPBID);

            tempQuarterMaster = this.DDLQuarter[3].value;
        }
        return { tempQuarterID, tempQuarterMaster, tempWPBID };
    }



    private FnGetdataIV_chkDate_CurrentYear(_s: this, tempQuarterID: any, tempQuarterMaster: any, tempWPBID: any) {
        if (_s.DataSearch.Quarterfrom.includes("WPB") === false) {
            //FnGetdataV_forloop_1
            ({ tempQuarterID, tempQuarterMaster } = this.FnGetdataV_forloop_1(_s, tempQuarterID, tempQuarterMaster));

        }
        else {
            //FnGetdataV_forloop_2
            tempWPBID = this.FnGetdataV_forloop_2(_s, tempWPBID);
            //FnGetdataV_forloop_5
            tempQuarterMaster = this.FnGetdataV_forloop_5(_s, tempQuarterMaster);

        }
        return { tempQuarterID, tempQuarterMaster, tempWPBID };
    }

    private FnGetdataV_forloop_5(_s: this, tmpQuarterMaster: any) {
        let tempQuarterMaster = tmpQuarterMaster;
        for (let item_DDLQuarter of this.DDLQuarter) {
            if (_s.tempCurrentQuarterID === item_DDLQuarter.text) {
                tempQuarterMaster = item_DDLQuarter.value;
                break;
            }
        }
        return tempQuarterMaster;
    }

    private FnGetdataV_forloop_2(_s: this, tmpWPBID: any) {
        let tempWPBID = tmpWPBID;
        for (let item_DDLQuarter of this.DDLQuarter) {
            if (_s.DataSearch.Quarterfrom === item_DDLQuarter.text) {
                tempWPBID = item_DDLQuarter.value;
                break;
            }
        }
        return tempWPBID;
    }

    private FnGetdataV_forloop_1(_s: this, tmpQuarterID: any, tmpQuarterMaster: any) {
        let tempQuarterID = tmpQuarterID;
        let tempQuarterMaster = tmpQuarterMaster;
        for (let item_DDLQuarter of this.DDLQuarter) {
            if (_s.DataSearch.Quarterfrom === item_DDLQuarter.text) {
                tempQuarterID = item_DDLQuarter.value;
                tempQuarterMaster = item_DDLQuarter.value;
                break;
            }
        }
        return { tempQuarterID, tempQuarterMaster };
    }

    func_clear() {
        this.tempBU = '';
        this.Search.BuName = '';
        this.Search.RiskStatus = '';
        this.Search.RiskKRIStatus = '';
        this.Search.RiskCate = '';
        this.Search.RiskRating = '';
        this.Search.RiskName = '';
        this.Search.Tabletype = '0';
        this.inCludeConsolidate = false;

        this.DataSearch.Yearfrom = '';
        this.DataSearch.Yearto = '';
        this.DataSearch.Quarterfrom = '';
        this.DataSearch.Quarterto = '';
       
        this.GETDDL_YEAR();
    }
    func_MergeData2() {
        const _s = this;
        let rowcount = 1, rootcount = 1, impactcount = 1;
        _s.table_RiskProfile = [];

        _s.tempData.forEach(function (itemval, itemIndex) {

            Fn_MergeData2II_ChkRiskStatus(itemval);

            itemval.riskWF = (itemval.wpbid !== null) ? "WPB" : "Normal";

            Fn_MergeData2II_likelyhoodLevel(itemval);
            Fn_MergeData2II_ImpactLevel(itemval);
            Fn_MergeData2II_chkrootCause(itemval);
            Fn_MergeData2II_chkrootimpact(itemval);

           // itemval.risk_Quarter = itemval.Version;


            // if (itemval.risk_Co_Id != null) {
            //     itemval.risk_Quarter = itemval.risk_Co_Id.slice(0, 6)
            // }
            // else if (itemval.risk_Staff_Id != null) {
            //     itemval.risk_Quarter = itemval.risk_Staff_Id.slice(0, 6)
            // }
        });

        this.Fn_MergeData2II_foritemval1(_s);

        //console.log('tempDatanew', _s.tempData)
        _s.loading = false;

        function Fn_MergeData2II_chkrootimpact(itemval: any) {
            if (itemval.impact !== null && itemval.impact !== undefined) {
                itemval.impact.forEach(function (itemval1, itemIndex1) {
                    Fn_MergeData2III_ImpactLevel(itemval1);
                    Fn_MergeData2III_Financial(itemval1);

                });
            }

            function Fn_MergeData2III_Financial(itemval1: any) {
                for (let item_Financial of _s.Financial) {
                    if (itemval1.impact_NPT_EMV == item_Financial.value) {
                        itemval1.impact_NPT_EMV = item_Financial.text;
                        break;
                    }
                }
            }

            function Fn_MergeData2III_ImpactLevel(itemval1: any) {
                for (let item_ImpactLevel of _s.ImpactLevel) {
                    if (itemval1.impact_Level === item_ImpactLevel.value) {
                        itemval1.impact_Level = item_ImpactLevel.text;
                        break;
                    }
                }
            }
        }

        function Fn_MergeData2II_chkrootCause(itemval: any) {
            if (itemval.rootCause !== null && itemval.rootCause !== undefined) {
                itemval.rootCause.forEach(function (itemval1, itemIndex1) {
                    for (let item_likelyhoodLevel of _s.likelyhoodLevel) {
                        if (itemval1.rootCause_Likelihood === item_likelyhoodLevel.value) {
                            itemval1.rootCause_Likelihood = item_likelyhoodLevel.text;
                            break;
                        }
                    }
                });
            }
        }

        function Fn_MergeData2II_ImpactLevel(itemval: any) {
            for (let item_ImpactLevel of _s.ImpactLevel) {
                if (itemval.risk_Impact === item_ImpactLevel.value) {
                    itemval.risk_Impact = item_ImpactLevel.text;
                    break;
                }
            }
        }

        function Fn_MergeData2II_likelyhoodLevel(itemval: any) {
            for (let item_likelyhoodLevel of _s.likelyhoodLevel) {
                if (itemval.risk_Likelihood === item_likelyhoodLevel.value) {
                    itemval.risk_Likelihood = item_likelyhoodLevel.text;
                    break;
                }
            }
        }

        function Fn_MergeData2II_ChkRiskStatus(itemval: any) {
            if (itemval.risk_Status === '1') {
                itemval.risk_Status = 'Active';
            } else if (itemval.risk_Status === '2') {
                itemval.risk_Status = 'Close (Turn to problem)';
            } else if (itemval.risk_Status === '3') {
                itemval.risk_Status = 'Close (Mitigation Completed)';
            } else if (itemval.risk_Status === '4') {
                itemval.risk_Status = 'Close (Other)';
            }
        }
    }

    private Fn_MergeData2II_foritemval1(_s: this) {
        _s.tempData.forEach(function (itemval1, itemindex1) {
            if (itemval1.impact !== null && itemval1.impact !== undefined) {
                itemval1.impact.forEach(function (itemval2, itemIndex2) {
                    for (let item_ImpactCategoryDD of _s.ImpactCategoryDD) {
                        if (itemval2.impact_Category === item_ImpactCategoryDD.value) {
                            itemval2.impact_Category = item_ImpactCategoryDD.text;
                            break;
                        }
                    }
                });
            }
        });
    }

    funcCheckData2() {
        const _s = this;
        let rowcount = 1;
        let rowrootcount = 1;
        let rowimpactcount = 1;
        _s.tempdata2 = [];
        _s.table_RiskProfile.forEach(function (itemval, itemIndex) {
            _s.table_RiskProfile[itemIndex].rootcause.forEach(function (itemvalroot, itemIndexroot) {
                _s.table_RiskProfile[itemIndex].rootmitigation.forEach(function (itemvalrootmiti, itemIndexrootmiti) {
                    if (itemIndex === 42) {
                        if (_s.tempdata2.length === 0) {
                            _s.tempdata2.push({
                                riskid: itemval.riskid,
                                riskrun: itemval.riskrun,
                                riskquarter: itemval.riskquarter,
                                riskname: itemval.riskname,
                                riskbu: itemval.riskbu,
                                riskstatus: itemval.riskstatus,
                                risklikeli: itemval.risklikeli,
                                riskimpactlevel: itemval.riskimpactlevel,
                                riskrating: itemval.riskrating,
                                riskkri: itemval.riskkri,
                                rootCause_Category: itemvalroot.rootCause_Category,
                                rootcauseid: itemvalroot.rootCause_Id,
                                rootcausemitigationid: itemvalrootmiti.rootCause_Mitigation_Id,
                                rootcauseplan: itemvalrootmiti.rootCause_Mitigation_Name,
                                rootcauseowner: itemvalrootmiti.rootCause_Mitigation_Owner,
                                rootcausedate: itemvalrootmiti.rootCause_Mitigation_DueDate,
                                rootcauseprogress: itemvalrootmiti.rootCause_Mitigation_Progress,

                                same: '1',
                                rowcount: 1,
                                sameroot: '1',
                                rootcount: 1,
                                sameimpact: '1',
                                impactcount: 1
                            })
                        }
                        else if (((_s.tempdata2[_s.tempdata2.length - 1].riskid === itemvalroot.risk_Id) && (_s.tempdata2[_s.tempdata2.length - 1].rootcauseid === itemvalrootmiti.rootCause_Id))
                        ) {
                            _s.tempdata2.push({
                                riskid: itemval.riskid,
                                riskrun: itemval.riskrun,
                                rootCause_Category: itemvalroot.rootCause_Category,
                                rootcauseid: itemvalroot.rootCause_Id,

                                rootcausemitigationid: itemvalrootmiti.rootCause_Mitigation_Id,
                                rootcauseplan: itemvalrootmiti.rootCause_Mitigation_Name,
                                rootcauseowner: itemvalrootmiti.rootCause_Mitigation_Owner,
                                rootcausedate: itemvalrootmiti.rootCause_Mitigation_DueDate,
                                rootcauseprogress: itemvalrootmiti.rootCause_Mitigation_Progress,
                                same: '0',
                                rowcount: 1,

                                sameroot: '0',
                                rootcount: 1,

                                sameimpact: '0',
                                impactcount: 1
                            })
                            //for 1
                            funcCheckData2II_for1(itemvalroot);
                            //for 2
                            funcCheckData2II_for2(itemvalrootmiti);


                        }
                        else if (((_s.tempdata2[_s.tempdata2.length - 1].riskid !== itemvalroot.risk_Id) && (_s.tempdata2[_s.tempdata2.length - 1].rootcauseid !== itemvalrootmiti.rootCause_Id))
                        ) {
                            _s.tempdata2.push({
                                riskid: itemval.riskid,
                                riskrun: itemval.riskrun,
                                rootCause_Category: itemvalroot.rootCause_Category,
                                rootcauseid: itemvalroot.rootCause_Id,

                                rootcausemitigationid: itemvalrootmiti.rootCause_Mitigation_Id,
                                rootcauseplan: itemvalrootmiti.rootCause_Mitigation_Name,
                                rootcauseowner: itemvalrootmiti.rootCause_Mitigation_Owner,
                                rootcausedate: itemvalrootmiti.rootCause_Mitigation_DueDate,
                                rootcauseprogress: itemvalrootmiti.rootCause_Mitigation_Progress,

                                same: '0',
                                rowcount: 1,

                                sameroot: '0',
                                rootcount: 1,

                                sameimpact: '1',
                                impactcount: 1
                            })
                            //for 3
                            funcCheckData2II_for1(itemvalroot);
                            //for 4
                            funcCheckData2II_for2(itemvalrootmiti);

                        }
                        else {
                            _s.tempdata2.push({
                                riskid: itemval.riskid,
                                riskrun: itemval.riskrun,
                                rootCause_Category: itemvalroot.rootCause_Category,
                                rootcauseid: itemvalroot.rootCause_Id,

                                rootcausemitigationid: itemvalrootmiti.rootCause_Mitigation_Id,
                                rootcauseplan: itemvalrootmiti.rootCause_Mitigation_Name,
                                rootcauseowner: itemvalrootmiti.rootCause_Mitigation_Owner,
                                rootcausedate: itemvalrootmiti.rootCause_Mitigation_DueDate,
                                rootcauseprogress: itemvalrootmiti.rootCause_Mitigation_Progress,

                                same: '1',
                                rowcount: 1,

                                sameroot: '1',
                                rootcount: 1,

                                sameimpact: '1',
                                impactcount: 1
                            })
                        }

                    }

                });
            });
        });

        //console.log('tempdata2', this.tempdata2);

        function funcCheckData2II_for2(itemvalrootmiti: any) {
            for (let item_tempdata2 of _s.tempdata2) {
                if (item_tempdata2.sameroot === '1' && item_tempdata2.rootcauseid === itemvalrootmiti.rootCause_Id) {
                    item_tempdata2.rootcount = ++rowrootcount;
                }
            }
        }

        function funcCheckData2II_for1(itemvalroot: any) {
            for (let item_tempdata2 of _s.tempdata2) {
                if (item_tempdata2.same === '1' && item_tempdata2.riskid === itemvalroot.risk_Id) {
                    item_tempdata2.rowcount = ++rowcount;
                }
            }
        }

    }

    func_duplicate() {
        const _s = this;
        let count = 0;
        let temparr = [];
        Array.prototype.push.apply(temparr, _s.tempdata2);
        for (let i = 0; i < _s.tempdata2.length; i++) {
            if (i !== 0) {
                if (_s.tempdata2[i - 1].rootcausemitigationid === _s.tempdata2[i].rootcausemitigationid) {
                    _s.tempdata2.splice(i, 1)
                    //console.log(i)
                    count++;
                }
            }
        }
        //console.log('count ', count)
    }
    funcsort(value, value2) {
        const _s = this;
        let temp;
        let tempdata = value;
        let tempdata2 = value2;
        let tempmi = [];
        _s.tempchkduplicateimpact = [];

        if (value2.length !== 0) {
            tempdata2 = value2[value2.length - 1].mitigation
        }


        for (let i = 0; i < tempdata.length; i++) {
            if (i !== 0) {

                if (tempdata[i - 1].ImpactID >= tempdata[tempdata.length - 1].ImpactID && tempdata[i - 1].ImpactMiID >= tempdata[tempdata.length - 1].ImpactMiID) {
                    tempdata.splice(tempdata.length - 1, 1)
                    tempdata2.splice(tempdata2.length - 1, 1);

                    tempdata2.forEach(function (ItemVal, ItemIndex) {
                        tempmi.push(ItemVal)
                    })
                    value2[value2.length - 1].mitigation = tempmi;
                    _s.tempchkduplicateimpact = value2[value2.length - 1].mitigation
                    break;
                }

            }
        }


        if (tempdata.length >= 2) {
            for (let i = 0; i < tempdata.length - 1; i++) {
                if (tempdata[i].ImpactID >= tempdata[i + 1].ImpactID && tempdata[i].ImpactMiID >= tempdata[i + 1].ImpactMiID) {
                    temp = tempdata[i + 1]
                    tempdata[i + 1] = value[i]
                    tempdata[i] = temp
                }
            }
        }
        return tempdata
    }

    Export_excel() {
        this.excelService2.generateExcel_MergeAll(this.table_RiskProfile, this.tempData);
    }
    SelectBU() {
        const _s = this;


        this.DepartmentDD.forEach(function (itemval, itemindex) {
            if (_s.tempBU === itemval.text) {
                _s.Search.BuName = itemval.value
            }
        });
    }
    func_formatdate(data) {

        let stryear = data.year.toString();
        let strmonth = data.month.toString();
        let strday = data.day.toString();
        if (strday.length < 2) {
            strday = '0' + strday
        }
        if (strmonth.length < 2) {
            strmonth = '0' + strmonth
        }
        let newstr = stryear + '-' + strmonth + '-' + strday
        return newstr
    }
    closealertdanger() {
        $('#modalAlertdanger5se').modal('hide');
    }


    func_okversion() {

        const _s = this;
        $('#modalVersioning').modal('hide');
        setTimeout(function () { _s.func_getData('1'); }, 500)
    }


    func_selFromyear() {

        const _s = this;
        _s.DataSearch.Quarterfrom = ''
        _s.VersionQuarter = '';
        _s.mserve.Master_DDL({ Module: "QuarterDD", TextSearch1: _s.DataSearch.Yearfrom }).then((response) => {
            _s.DDLQuarter = response.data.body
            //console.log("DDLQuarter : ", _s.DDLQuarter);
        });
    }
    func_selToyear() {

        const _s = this;
        _s.DataSearch.Quarterto = ''
        _s.mserve.Master_DDL({ Module: "QuarterDD", TextSearch1: _s.DataSearch.Yearto }).then((response) => {
            _s.DDLQuarterto = response.data.body
            //console.log("QuarterTo : ", _s.DDLQuarterto);
        });
    }

    changeQuarter() {

        const _s = this;
        _s.VersionQuarter = '';
    }


    private _filterBU(value: string): string[] {
        let tempFileter;
        const filterValue = value?.toLowerCase();
        if (filterValue !== '') {
            tempFileter = this.DepartmentDD.filter(option => option.text?.toLowerCase().includes(filterValue));
            if (tempFileter.length !== 0) {
                return tempFileter;
            }
        } else {
            return this.DepartmentDD
        }
    }

} 