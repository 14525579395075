import { OnInit, Component, Injectable } from "@angular/core";
import {  NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Masterserve } from '../../services/master.service';
import { Router } from '@angular/router';
declare let $;

import {UntypedFormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { AuthenService } from "src/app/services/authenservice.service";
import { AppSettingService } from "src/app/app-setting-service";

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day : (date[0], 10),
        month : (date[1], 10),
        year : (date[2], 10)
      };
    }
    return null;
  }
  format(date: NgbDateStruct | null): string {
    if (date !== null) {
        let day = date.day.toString()
        let month = date.month.toString()
        if (day.length < 2) {
            day = '0' + day
        }
        if (month.length < 2) {
            month = '0' + month
        }
        let newdate  = day + this.DELIMITER + month + this.DELIMITER + date.year ;
        return  newdate;
         
    } else {
    
       return '';
    }
}
}
//---------------------------------------------------------------------------------------------------------------------

@Component({
    selector: 'app-SearchRisk',
    templateUrl: './page.searchrisk.html',
    styleUrls: ['./page.searchrisk.css'],
  
    providers: [
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
    ]
    //------------------------------------------------------------------------------------------
})
export class SearchRisk implements OnInit {
    chkroleuser;

 
    page = 1;
    pageSize = 20;

 
    Data_SessionStorage;
    DataSearch;
    loading;
    FilterTable;
    table_searchrisk = [];
    valuecheckSelectAll = false;
    Risk_ByCategory ;
    Risk_Level;
    valueRisk_ByCategory: string;
    alertflow;

   
     myControl = new UntypedFormControl();
     options = [];
     filteredOptions: Observable<string[]>;

     myControlEmployeeID = new UntypedFormControl();
     EmployeeDD_auto = [];
     filteredOptionsEmployeeIDDD: Observable<string[]>;
   

     tempBU = '';
     tempEmp;
     tempCurrentQuarterID = null;
     tempCurrentQuarterIDValue = null;

     BASE_URL = "";

    Version = null;
    VersionYear = '';
    VersionQuarter = '';
    DDLYear = [];
    DDLQuarter = [];

    DDLQuarterfrom = [];
    DDLQuarterto = [];


    constructor(public authserviceToken: AuthenService,
        public mserve: Masterserve,
        private router: Router,
        public appSettingService: AppSettingService
        ) { }
    ngOnInit(): void { 
        let configdata = this.appSettingService.read();
        this.BASE_URL = configdata.redirect_url;

        sessionStorage.setItem("RoleReview", '');
        const pathnameURL = window.location.pathname;
        let temp = pathnameURL.indexOf("/", pathnameURL.indexOf("/") + 1);
        if (temp !== -1) {
            this.Version  = pathnameURL.slice(temp , temp + 2);
        }

        if (this.Version === '/q') {
            $('#modalVersioning').modal('show');
        }
        this.DataSearch = {
            RiskCate :'',
            RiskName :'',
            RiskRequester :'',
            RiskID :'',
            RiskBU :'',
            RiskStatus :'',
            Datefrom :'',
            Dateto :'',
            LevelRisk :'',
            Escalation: false,
            TableType : '0',
            Yearfrom:'',
            Yearto:'',
            Quarterfrom:'',
            Quarterto:'',
            Childnode: false,
            Consolidation: false
        }

        this.Data_SessionStorage = JSON.parse(sessionStorage.datainfo);
        this.chkroleuser = sessionStorage.Role

        this.func_GETDDL_EMP();
        this.GETDDL_YEAR();
    }

    GETDDL_YEAR () {
        const _s = this;
        this.loading = true;
        _s.mserve.Master_DDL({ 'Module' : 'YearDD' }).then((response) => {
            if(response.data.status === true){
              this.DDLYear = response.data.body;

            if (this.Version === null) {
                let d = new Date();
                this.DataSearch.Yearfrom = d.getFullYear();
                let currentMonth = d.getMonth() + 1;
                _s.mserve.Master_DDL({ Module:"QuarterDD",TextSearch1: this.DataSearch.Yearfrom }).then((response) => {
                    _s.DDLQuarter = response.data.body
                    this.func_calQuarter(currentMonth);
                });
            } else {
                this.func_RiskCategoryDDL();
            }

            }else{
            }
        })
    }
    func_calQuarter (month) {
        this.VersionQuarter = ''
        this.DataSearch.Quarterfrom = ''
        //console.log(this.DDLQuarter[0]);
        let quater   = '';

        if (month >= 1 && month <= 3) {
            quater  ='Q1';
          
        } else if (month >= 4 && month <= 6) {
            quater  ='Q2';
            
        } else if (month >= 7 && month <= 9) {
            quater  ='Q3';
            
        } else if (month >= 10 && month <= 12) {
            quater  ='Q4';
            
        }
        let data  = this.DDLQuarter.filter(x=>x.text === quater)[0] ?  this.DDLQuarter.filter(x=>x.text === quater)[0]:''
        if(data!=''){
        this.VersionQuarter = data.value;
        this.tempCurrentQuarterIDValue = data.value;
        this.DataSearch.Quarterfrom = data.text;
        }


        this.tempCurrentQuarterID = this.DataSearch.Quarterfrom
        this.func_RiskCategoryDDL();
    }
    func_RiskCategoryDDL () {
        let chkDDper;
        chkDDper = (sessionStorage.Role === 'ERM') ? '' : '1';

        let formData = {
            Module:"RiskCateDD",
            TextSearch1:chkDDper,
            TextSearch7:this.tempCurrentQuarterIDValue //this.VersionQuarter
        }
        this.mserve.Master_DDL(formData).then((response) => {
            if (response.data.status ){
                this.Risk_ByCategory = response.data.body;
                this.func_GetDDL_BusinessName();
            } else {

            }
        });
    }
    func_GetDDL_BusinessName () {
        let formData = {
            Module :'DepartmentDD'
        }
        this.mserve.Master_DDL(formData).then((response) => {
            if (response.data.status ){
                for (let item_body of response.data.body){
                    this.options.push({ text :item_body.text , value :item_body.value })
                }
                 this.filteredOptions = this.myControl.valueChanges.pipe(
                    startWith(''),
                    map(value => this._filter(value))
                );
                this.func_GETDDL_RiskLevel();
            } else {
            }
        });
    }
    func_GETDDL_RiskLevel () {
        this.mserve.Master_DDL({ Module:"RatingDD" }).then((response) => {
            if (response.data.status ){
                this.Risk_Level = response.data.body;
                if (this.Version === null) {
                    this.func_search();
                }
            } else {
            }
          });
    }
    func_GETDDL_EMP () {
        const _s = this;
        _s.mserve.Master_DDL({ Module:"EmployeeDD" }).then((response) => {
            _s.EmployeeDD_auto = response.data.body;

            this.filteredOptionsEmployeeIDDD = this.myControlEmployeeID.valueChanges.pipe(
                startWith(''),
                map(value => this._filterEmployeeIDDD(value))
            );

        })
    }
    func_getQuarterVersion () {
        const _s = this;
          _s.mserve.Master_DDL({ Module:"QuarterDD",TextSearch1: this.VersionYear }).then((response) => {
            _s.DDLQuarter = response.data.body
        });
    }

    func_search () {

        const _s = this;
        if(_s.DataSearch.Yearfrom === '') {
            this.alertflow = 'Please select Version Year';
            $('#modalAlertdanger5se').modal('show');
        } else if((_s.DataSearch.Yearfrom === '0' && _s.DataSearch.Quarterfrom !== '0') || _s.DataSearch.Quarterfrom === '') {
            this.alertflow = 'Please select Version Quarter';
            $('#modalAlertdanger5se').modal('show');
        } else {
            let tempdatefrom = '' , tempdateto = '' , chkdate = false
            if (this.DataSearch.Datefrom !== '' && this.DataSearch.Dateto === '') {
                this.alertflow = 'Please select Quarter To';
                $('#modalAlertdanger5se').modal('show');
                chkdate = true
            } else if (this.DataSearch.Datefrom === '' && this.DataSearch.Dateto !== '') {
                this.alertflow = 'Please select Quarter From';
                $('#modalAlertdanger5se').modal('show');
                chkdate = true
            } else if (this.DataSearch.Datefrom !== '' && this.DataSearch.Dateto !== '') {
                if (this.DataSearch.Datefrom !== '') {
                    tempdatefrom = this.func_formatdate(this.DataSearch.Datefrom)
                }
                if (this.DataSearch.Dateto !== '') {
                    tempdateto = this.func_formatdate(this.DataSearch.Dateto)
                }
            }
            this.func_search_II(chkdate, _s, tempdatefrom, tempdateto);
        }
        
    }

    private func_search_II(chkdate: boolean, _s: this, tempdatefrom: string, tempdateto: string) {
        if (chkdate === false) {
            this.loading = true;
            this.DataSearch.RiskBU = (this.tempBU === '') ? '' : this.DataSearch.RiskBU;
            let d = new Date();
            let cur_year = d.getFullYear();
            let tempQuarterID = null, tempWPBID = null, tempQuarterMaster = null;
            if (cur_year === parseInt(_s.DataSearch.Yearfrom)) {
                ({ tempQuarterID, tempQuarterMaster, tempWPBID } = this.func_search_III_CurrentYear(_s, tempQuarterID, tempQuarterMaster, tempWPBID));
            }
            else {
                ({ tempQuarterID, tempQuarterMaster, tempWPBID } = this.func_search_III_NonCurrentYear(_s, tempQuarterID, tempQuarterMaster, tempWPBID));
            }

            let formData = this.func_search_III_formData(tempdatefrom, tempdateto, _s, tempQuarterID, tempWPBID, tempQuarterMaster);

            this.tempCurrentQuarterIDValue = (tempQuarterID != null) ? tempQuarterID : tempWPBID;

            this.mserve.RiskSearch(formData).then((response) => {
                this.func_search_III_RiskSearchStatus(response, func_search_III_Risk_Level);
            }).catch((e) => {
                this.authserviceToken.CheckTokenExpire(e.response.status);
            });
        }

        function func_search_III_Risk_Level(ItemVal: any) {
            for (let item_Risk_Level of _s.Risk_Level) {
                if (ItemVal.risk_Rating === item_Risk_Level.value) {
                    ItemVal.risk_Rating = item_Risk_Level.text;
                    break;
                }
            }
        }
    }

    private func_search_III_RiskSearchStatus(response, func_search_III_Risk_Level: (ItemVal: any) => void) {
        if (response.data.status) {
            if (response.data.body.length !== 0) {

                this.table_searchrisk = response.data.body;
                this.table_searchrisk.forEach(function (ItemVal, ItemIndexff) {
                    func_search_III_Risk_Level(ItemVal);
                });
                this.loading = false;
            } else {
                this.loading = false;
                this.table_searchrisk = response.data.body;
                this.alertflow = "No data found";
                $('#modalAlertdanger5se').modal('show');
            }
        } else {
            this.loading = false;
            this.table_searchrisk = [];
            if(response.data.errorMessage)
            {
                this.alertflow = response.data.errorMessage;
            }
          else
            {
                this.alertflow = "No data found";
            }
            $('#modalAlertdanger5se').modal('show');
        }
    }

    private func_search_III_NonCurrentYear(_s: this, tempQuarterID: any, tempQuarterMaster: any, tempWPBID: any) {
        if (_s.DataSearch.Quarterfrom.includes("WPB") === false) {
            ({ tempQuarterID, tempQuarterMaster } = this.func_search_IV_Loop1(_s, tempQuarterID, tempQuarterMaster));
        }
        else {
            tempWPBID = this.func_search_IV_Loop2(_s, tempWPBID);
            tempQuarterMaster = this.DDLQuarter[3].value;
        }
        return { tempQuarterID, tempQuarterMaster, tempWPBID };
    }

    private func_search_III_CurrentYear(_s: this, tempQuarterID: any, tempQuarterMaster: any, tempWPBID: any) {
        if (_s.DataSearch.Quarterfrom.includes("WPB") === false) {
            ({ tempQuarterID, tempQuarterMaster } = this.func_search_IV_Loop1(_s, tempQuarterID, tempQuarterMaster));
        }
        else {
            tempWPBID = this.func_search_IV_Loop2(_s, tempWPBID);
            tempQuarterMaster = this.func_search_IV_Loop3(_s, tempQuarterMaster);
        }
        return { tempQuarterID, tempQuarterMaster, tempWPBID };
    }

    private func_search_IV_Loop3(_s: this, tmpQuarterMaster: any) {
    let tempQuarterMaster   =  tmpQuarterMaster;
        for (let item_DDLQuarter of this.DDLQuarter) {
            if (_s.tempCurrentQuarterID === item_DDLQuarter.text) {
                tempQuarterMaster = item_DDLQuarter.value;
                break;
            }
        }
        return tempQuarterMaster;
    }

    private func_search_IV_Loop2(_s: this, tmpWPBID: any) {
        let tempWPBID   =  tmpWPBID;
        for (let item_DDLQuarter of this.DDLQuarter) {
            if (_s.DataSearch.Quarterfrom === item_DDLQuarter.text) {
                tempWPBID = item_DDLQuarter.value;
                break;
            }
        }
        return tempWPBID;
    }

    private func_search_IV_Loop1(_s: this, tmpQuarterID: any, tmpQuarterMaster: any) {
        let tempQuarterID   =  tmpQuarterID;
        let tempQuarterMaster   =  tmpQuarterMaster;
        for (let item_DDLQuarter of this.DDLQuarter) {
            if (_s.DataSearch.Quarterfrom === item_DDLQuarter.text) {
                tempQuarterID = item_DDLQuarter.value;
                tempQuarterMaster = item_DDLQuarter.value;
                break;
            }
        }
        return { tempQuarterID, tempQuarterMaster };
    }
   
    private func_search_III_formData(tempdatefrom: string, tempdateto: string, _s: this, tempQuarterID: any, tempWPBID: any, tempQuarterMaster: any) {
        let quarterId = '';
         if (this.VersionQuarter != '') {
            quarterId = this.VersionQuarter;
         } else {
            if (tempQuarterID) {
                quarterId= tempQuarterID;
            } else {
                quarterId = '0';
                tempQuarterMaster = '0';
            }
         }
        return {
            SessionEmpID: this.Data_SessionStorage[0].employeE_ID,
            Email: sessionStorage.loginmail,
            SecurityCode: '1234',
            body: {
                Risk_Category: this.DataSearch.RiskCate,
                Risk_Status: this.DataSearch.RiskStatus,
                Risk_Business_Unit: this.DataSearch.RiskBU,
                Risk_Name: this.DataSearch.RiskName,
                Risk_Register_By: this.DataSearch.RiskRequester,
                Risk_Register_Date_From: tempdatefrom,
                Risk_Register_Date_To: tempdateto,
                Risk_Running: this.DataSearch.RiskID,
                Risk_Rating: this.DataSearch.LevelRisk,
                Risk_Escalation: (this.DataSearch.Escalation === true) ? '1' : '0',
                Consolidate: (this.DataSearch.Consolidation === true) ? '1' : '0',
                Filter_Table: this.DataSearch.TableType,
                Child_Node: (_s.DataSearch.Childnode === true) ? 1 : 0,
                QuarterID: quarterId,
                WPBID: tempWPBID,
                QuaterMaster: tempQuarterMaster,
                Role: sessionStorage.Role,
                Year: this.DataSearch.Yearfrom
            }
        };
    }

    func_clear () {
        this.DataSearch.RiskCate        = '';
        this.DataSearch.RiskName        = '';
        this.DataSearch.RiskID          = '';
        this.DataSearch.RiskBU          = '';
        this.DataSearch.RiskStatus      = '';
        this.DataSearch.Datefrom        = '';
        this.DataSearch.Dateto          = '';
        this.DataSearch.RiskRequester   = '';
        this.DataSearch.LevelRisk       = '';
        this.DataSearch.Escalation      = false;
        this.tempBU                     = '';
        this.tempEmp                    = '';
        this.DataSearch.TableType       = '0';
        this.DataSearch.Childnode       = false;
        this.DataSearch.Consolidation  = false;
        this.DataSearch.Yearfrom        = '';
        this.DataSearch.Yearto          = '';
        this.DataSearch.Quarterfrom     = '';
        this.DataSearch.Quarterto       = '';
        this.GETDDL_YEAR();
    }
    func_ReveiewPage (riskid , filter) {
        
        
        if (filter === 'Staff') {
            sessionStorage.setItem("RoleReview", 'Staff');
        } else {
            sessionStorage.setItem("RoleReview", 'Co');
        }
        
        sessionStorage.setItem("RiskID", riskid);
        //window.open('ReviewPage/Reviewfromsearch');
        
        window.open(  this.BASE_URL+ '/ReviewPage/Reviewfromsearch','_blank').focus();
    }
    func_selectBU () {
        const _s = this;
        _s.DataSearch.Childnode = false;
        _s.options.forEach(function(val, Index) {
            if (val.text === _s.tempBU) {
                _s.DataSearch.RiskBU = val.value
            }
        });
    }
    func_selectEmp () {
        const _s = this;
        _s.EmployeeDD_auto.forEach(function(val, Index) {
            if (val.text === _s.tempEmp) {
                _s.DataSearch.RiskRequester = val.value
            }
        });
    }
    func_formatdate (data) {
        let stryear = data.year.toString();
        let strmonth = data.month.toString();
        let strday = data.day.toString();
        if (strday.length < 2) {
            strday = '0' + strday
        }
        if (strmonth.length < 2) {
            strmonth = '0' + strmonth
        }
        let newstr = stryear + '-' + strmonth + '-' + strday
        return newstr
    }
    closealertdanger () {
        $('#modalAlertdanger5se').modal('hide');
    }

    func_okversion () {
        const _s = this;
        $('#modalVersioning').modal('hide');
        setTimeout(function () {  _s.func_search(); }, 500)
    }
    func_selFromyear () {
        const _s = this;
        _s.DataSearch.Quarterfrom = ''
        _s.VersionQuarter = ''
        _s.mserve.Master_DDL({ Module:"QuarterDD",TextSearch1: _s.DataSearch.Yearfrom }).then((response) => {
            _s.DDLQuarter = response.data.body
            if(this.DataSearch.Yearfrom === 0) {
                this.DataSearch.Quarterfrom =0;
            }
        });
    }
    func_selToyear () {
        const _s = this;
        _s.DataSearch.Quarterto = ''
        _s.mserve.Master_DDL({ Module:"QuarterDD",TextSearch1: _s.DataSearch.Yearto }).then((response) => {
            _s.DDLQuarterto = response.data.body
        });
    }
    changeQuarter () {
        const _s = this;
        _s.VersionQuarter = '';
        let chkDDper;
        chkDDper = (sessionStorage.Role === 'ERM') ? '' : '1';
        let formData = {
            Module:"RiskCateDD",
            TextSearch1:chkDDper,
            TextSearch7:this.tempCurrentQuarterIDValue 
        }
        this.mserve.Master_DDL(formData).then((response) => {
            if (response.data.status ){
                this.Risk_ByCategory = response.data.body;
            } else {
            }
        });
    }
    chkBU () {
        
        if (this.tempBU == '') {
            this.DataSearch.Childnode = false;
        } 
    }

    private _filter(value: string): string[] {
        const filterValue = value?.toLowerCase();
        if (filterValue !== '') {
            return this.options.filter(option => option.text?.toLowerCase().includes(filterValue));
        } else {
            return this.options
        }
    }
    private _filterEmployeeIDDD(value: string): string[] {
        
        let tempFileter;
        const filterValue = value?.toLowerCase();
        if (filterValue !== '') {
            tempFileter = this.EmployeeDD_auto.filter(option1 => option1.text?.toLowerCase().includes(filterValue));
            if (tempFileter.length !== 0) {
                return tempFileter;
            }
        } else {
            return this.EmployeeDD_auto
        }
    }

} 