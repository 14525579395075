
import { Component, Injector, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Masterserve } from '../../services/master.service';
import { RiskMap } from '../../services/riskmap.service';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthenService } from 'src/app/services/authenservice.service';
import { MainProcessComponent } from '../main-process/main-process.component';
import { AppSettingService } from 'src/app/app-setting-service';
declare let $;

export interface DialogData {
}

@Component({
    selector: 'app-ReviewPage',
    templateUrl: './page.ReviewPage.html',
    styleUrls: ['./page.ReviewPage.css'],
    providers: [Masterserve, NgbModal, RiskMap]

})



export class ReviewPage implements OnInit {
    Data_SessionStorage;

    temprootmitagation = [];
    temprootkri = [];
    temprootkriFlag = false;
    tempImpactmitagation = [];
    tempSelectedApproved = [];
    tempSelectedApproved2 = [];
    empid;
    Impact_After_Total_Amont = '';
    tempflag;
    commentReject = '';
    Risk_Close_Reason = '';
    alertflow = '';

    loading = true;
    temptableActionHistory;
    Tempdata;
    Register_Data;
    Table_Rootcause;
    Table_Impact;
    Table_TitleRootcause;
    show_Category;
    statuspage;
    Register_Rootcause;
    Register_Impact;

    Assert_RootCauseTable = [];
    Assert_ImpactTable = [];

    likelyhood;
    likelyhoodAfter;
    ImpactLevel;
    ImpactLevelAfter;
    RiskCategory;
    ImpactCategory;
    CorpTargetHead;
    CorpTargetItem;
    likelyhoodLevel;
    likelyhoodLevelAfter;
    Financial;
    DDLBU;
    Quarter;
    temp_impact_Category;
    BASE_URL = "";

    temptable = [];
    MitigationtypeRootcause = [
        { title: 'Take' },
        { title: 'Treat' }
    ]
    temptableImpact = []
    MitigationTypeImpact = [
        { title: 'Take' },
        { title: 'Treat' },
        { title: 'Transfer' }
    ]

    data_risk_table;
    list = {
        risk: {
            impactcolor: [],
            impact: [],
            risktable: [
                {
                    row: 1,
                    col: [
                        { col: 1, class: 'medium', item: [] },
                        { col: 2, class: 'medium', item: [] },
                        { col: 3, class: 'high', item: [] },
                        { col: 4, class: 'high', item: [] },
                        { col: 5, class: 'high', item: [] },
                    ]
                },
                {
                    row: 2,
                    col: [
                        { col: 1, class: 'medium', item: [] },
                        { col: 2, class: 'medium', item: [] },
                        { col: 3, class: 'medium', item: [] },
                        { col: 4, class: 'high', item: [] },
                        { col: 5, class: 'high', item: [] },
                    ]
                },
                {
                    row: 3,
                    col: [
                        { col: 1, class: 'low', item: [] },
                        { col: 2, class: 'medium', item: [] },
                        { col: 3, class: 'medium', item: [] },
                        { col: 4, class: 'medium', item: [] },
                        { col: 5, class: 'high', item: [] },
                    ]
                },
                {
                    row: 4,
                    col: [
                        { col: 1, class: 'low', item: [] },
                        { col: 2, class: 'low', item: [] },
                        { col: 3, class: 'medium', item: [] },
                        { col: 4, class: 'medium', item: [] },
                        { col: 5, class: 'medium', item: [] },
                    ]
                },
                {
                    row: 5,
                    col: [
                        { col: 1, class: 'low', item: [] },
                        { col: 2, class: 'low', item: [] },
                        { col: 3, class: 'low', item: [] },
                        { col: 4, class: 'medium', item: [] },
                        { col: 5, class: 'medium', item: [] },
                    ]
                }

            ],
            riskitems: []
        },
        riskprofile: {
            year: [],
            quarter: []
        },
        businessUnit: {
            items: []
        },
        field: {
            Mitigate: 1,
            riskprofileYear: '',
            riskprofileQuarter: '',
            businessUnit: '',
        }
    }

    StatusIden = [
        { value: '1', text: 'Active' },
        { value: '2', text: 'Close (Turn to problem)' },
        { value: '3', text: 'Close (Mitigation Completed)' },
        { value: '4', text: 'Close (Other)' }
    ];
    authserviceToken: AuthenService;
    router: Router;
    constructor(
        public mserve: Masterserve,
        private _Activatedroute: ActivatedRoute,
        private appServicecomp: MainProcessComponent,
        private riskServe: RiskMap,
        public appSettingService: AppSettingService,

        private injector: Injector) {

        this.authserviceToken = injector.get<AuthenService>(AuthenService)
        this.router = injector.get<Router>(Router)
    }
    appService = this.appServicecomp;
    ngOnInit(): void {

        let configdata = this.appSettingService.read();
        this.BASE_URL = configdata.api_url;

        sessionStorage.setItem('link', '')
        this.Register_Data = {
            Regis_RiskNo: '',
            Regis_RiskID: '',
            Regis_Riskname: '',
            Regis_Likelyhood: '',
            Regis_Impact: '',
            Regis_Status: '',
            Regis_Category: '',
            Regis_ObjectiveHead: '',
            Regis_Objective: '',
            Regis_KPI: '',
            Regis_Context: '',
            Regis_BusinessUnit: '',
            Regis_Modified_By: '',
            Regis_Status_Workflow: '',
            Regis_Status_Submit: '',
            Regis_Role: '',
            tempQuarter: '',
            AttachFiles: []
        }
        //console.log("this.list.risk.risktable", this.list.risk.risktable)
        this.data_risk_table = [
            {
                row: 1,
                col: [
                    { col: 1, class: 'medium', item: [''] },
                    { col: 2, class: 'medium', item: [] },
                    { col: 3, class: 'high', item: [{ title: '12', bgcolor: '#873531', color: 'white' }] },
                    { col: 4, class: 'high', item: [{ title: '11', bgcolor: '#873531', color: 'white' }] },
                    { col: 5, class: 'high', item: [] },
                ]
            },
            {
                row: 2,
                col: [
                    { col: 1, class: 'medium', item: [] },
                    { col: 2, class: 'medium', item: [{ title: '2', bgcolor: '#204375', color: 'white' }] },
                    { col: 3, class: 'medium', item: [{ title: '1', bgcolor: '#204375', color: 'white' }, { title: '4', bgcolor: '#204375', color: 'white' }, { title: '7', bgcolor: '#453e25', color: 'white' }] },
                    { col: 4, class: 'high', item: [] },
                    { col: 5, class: 'high', item: [] },
                ]
            },
            {
                row: 3,
                col: [
                    { col: 1, class: 'low', item: [{ title: '5', bgcolor: '#204375', color: 'white' }] },
                    { col: 2, class: 'medium', item: [{ title: '3', bgcolor: '#204375', color: 'white' }, { title: '9', bgcolor: '#453e25', color: 'white' }] },
                    { col: 3, class: 'medium', item: [{ title: '8', bgcolor: '#453e25', color: 'white' }, { title: '10', bgcolor: '#873531', color: 'white' }] },
                    { col: 4, class: 'medium', item: [{ title: '6', bgcolor: '#453e25', color: 'white' }] },
                    { col: 5, class: 'high', item: [] },
                ]
            },
            {
                row: 4,
                col: [
                    { col: 1, class: 'low', item: [] },
                    { col: 2, class: 'low', item: [] },
                    { col: 3, class: 'medium', item: [] },
                    { col: 4, class: 'medium', item: [] },
                    { col: 5, class: 'medium', item: [] },
                ]
            },
            {
                row: 5,
                col: [
                    { col: 1, class: 'low', item: [] },
                    { col: 2, class: 'low', item: [] },
                    { col: 3, class: 'low', item: [] },
                    { col: 4, class: 'medium', item: [] },
                    { col: 5, class: 'medium', item: [] },
                ]
            },
        ]
        this.Register_Rootcause = {
            RootCause_Id: '',
            Root_RootCause: '',
            Root_RootLikelyhood: '',
            Root_RootMitigationType: '',
            Root_AfterMitigated: '',
            Root_KRIName: '',
            Root_KRIThreshold_Green: '',
            Root_KRIThreshold_Red: '',
            Root_KRIStatus: '',
            Root_Justification: '',
            Root_Mitigation: [
                {
                    RootCauseMitigation_Name: '',
                    RootCauseMitigation_Owner: '',
                    RootCauseMitigation_DueDate: '',
                    RootCauseMitigation_Progress: 0,
                    RootCauseMitigation_Justification: ''
                }
            ]
        }
        this.Register_Impact = {
            Impact_Id: '',
            Impact_Category: '',
            Impact_NPTEMV: '',
            Impact_Total_Amont: '',
            Impact_Description: '',
            Impact_Level: '',
            Impact_Migation_Type: '',
            Impact_After_Mitigated: '',
            Impact_Rating: '',
            Impact_Mitigation: [
                {
                    Risk_Id: '',
                    Impact_Id: '',
                    Impact_Mitigation_Id: '',
                    Impact_Mitigation_Name: '',
                    Impact_Mitigation_Owner: '',
                    Impact_Mitigation_DueDate: '',
                    Impact_Mitigation_Progress: 0,
                    Impact_Mitigation_Justification: ''
                }
            ]
        }
        this.Table_TitleRootcause = []
        this.Table_Impact = []
        this._Activatedroute.paramMap.subscribe(params => {
            this.statuspage = params.get('display');
        });

        this.Data_SessionStorage = JSON.parse(sessionStorage.datainfo);
        //console.log('Data_SessionStorage => ', this.Data_SessionStorage);

        if (this.statuspage === 'ReviewfromTodo') {
            this.tempSelectedApproved = JSON.parse(sessionStorage.DataApprove1);
            this.tempSelectedApproved2 = JSON.parse(sessionStorage.DataApprove2);
            //console.log('tempSelectedApproved', this.tempSelectedApproved);
            //console.log('tempSelectedApproved2', this.tempSelectedApproved2)
        }

        this.Register_Data.Regis_RiskID = JSON.parse(sessionStorage.RiskID);
        this.Register_Data.Regis_Modified_By = this.Data_SessionStorage[0].employeE_ID;

        if (sessionStorage.getItem('RoleReview') !== '') {
            this.Register_Data.Regis_Role = sessionStorage.getItem('RoleReview')
        } else {
            this.Register_Data.Regis_Role = sessionStorage.getItem('TranSecRole');
        }
        this.empid = this.Data_SessionStorage[0].employeE_ID


        this.func_GetDDL_BusinessName();
        this.loadDDL();
    }

    loadDDL() {
        let _s = this;
        _s.mserve.Master_DDL({ Module: "QuarterDD", TextSearch1: null }).then((response) => {
            _s.Quarter = response.data.body
            //console.log("Quarter : ", _s.Quarter)
            this.func_GETDATA()
        });
    }
    checkBack() {
        //console.log('Checkback')
        let prevPage = sessionStorage.getItem("menubackto");
        if (prevPage == "/TransferRisk") {
            this.router.navigate(['/TransferRisk']);
        } else {
            this.router.navigate(['/Delegate']);
        }
    }

    func_GETDATA() {
        let formData = {
            SessionEmpID: "0001",
            Module: "GET",
            body: {
                Risk_Id: this.Register_Data.Regis_RiskID,
                Risk_Name: "",
                Risk_Business_Unit: "",
                Risk_Status: "",
                Risk_Likelihood: "",
                Risk_Impact: "",
                Risk_Category: "",
                Risk_Objective: "",
                Risk_Unit_KPI: "",
                Risk_Context: "",
                Role: this.Register_Data.Regis_Role
            }
        }
        this.mserve.RegisterRisk(formData).then((response) => {
            if (response.data.status) {
                //console.log("Get Data RegisterRisk : ", response.data.body)
                this.Tempdata = response.data.body[0]
                this.appService.showBUName = this.Tempdata.abbreviation
                this.Register_Data.Regis_RiskNo = (this.Tempdata.risk_Co_Id !== null) ? this.Tempdata.risk_Co_Id : this.Tempdata.risk_Staff_Id
                this.Register_Data.Regis_Riskname = this.Tempdata.risk_Name
                this.Register_Data.Regis_Likelyhood = this.Tempdata.risk_Likelihood
                this.Register_Data.Regis_Impact = this.Tempdata.risk_Impact
                this.Register_Data.Regis_Status = this.Tempdata.risk_Status
                if (this.Tempdata.risk_Close_Reason) {
                    this.Risk_Close_Reason = this.Tempdata.risk_Close_Reason
                    //console.log('this.Risk_Close_Reason', this.Risk_Close_Reason)
                }
                this.Register_Data.Regis_Category = (this.Tempdata.risk_Category === null) ? '' : this.Tempdata.risk_Category;
                this.Register_Data.Regis_Objective = (this.Tempdata.risk_Objective === null) ? '' : this.Tempdata.risk_Objective;

                this.Register_Data.Regis_KPI = this.Tempdata.risk_Unit_KPI
                this.Register_Data.Regis_Context = this.Tempdata.risk_Context
                this.Register_Data.Regis_BusinessUnit = this.Tempdata.risk_Business_Unit
                this.Register_Data.Regis_ObjectiveHead = this.Tempdata.risk_Objective_Parent
                this.Register_Data.Regis_Status_Workflow = this.Tempdata.risk_Status_Workflow
                this.temptableActionHistory = this.Tempdata.risk_History

                this.Register_Data.tempQuarter = this.Tempdata.quarterID;
                this.Register_Data.AttachFiles = this.Tempdata.attachFiles;

                for (let i = 0; i < this.Tempdata.attachFiles.length; i++) {
                    let _path = this.Tempdata.attachFiles[i].pathFile;
                    var regex = /\\/g;
                    var _path2 = _path.replace(regex, "/");
                    // this.Register_Data.AttachFiles[i].pathFile = _path2.replace("ERR", this.BASE_URL + "/app/backend");
                    this.Register_Data.AttachFiles[i].pathFile = _path2.replace("ERR/RiskResources/FileUpload/", "");
                }

                // //console.log(this.Register_Data.AttachFiles);
                this.setQuarter();

                this.func_GETMASTER_RiskCategory()
                this.func_GETMASTER_ImpactCategory()
                this.func_GETMASTER_CorptargetHead()
                this.func_GETMASTER_Likelihood()
                this.func_GETMASTER_Financial()
                this.func_GETMASTER_ImpactLevel()
                this.func_PlotRiskMap()

                if (this.Tempdata.rootCause.length !== 0) {
                    this.Assert_RootCauseTable = [];

                    let maxRootCause = Math.max(...this.Tempdata.rootCause.map(o => o.rootCause_Likelihood));
                    this.setRootCauseI(maxRootCause);
                }

                this.temprootkri = []
                this.temprootkriFlag = false;

                this.setRootCauseII();

                if (this.Tempdata.rootCause_Mitigation !== 0) {
                    this.setRootCauseIII();
                    this.setRootCauseIV(1);
                    //console.log("this.temprootmitagation", this.temprootmitagation)
                }
                


                this.loading = false
                //console.log("DataSession RegisterRisk : ", this.Register_Data)
                //console.log("Table_TitleRootcause", this.Table_TitleRootcause)
                //console.log("Table_temprootmitagation", this.temprootmitagation)

            } else {
                alert(response.data.errorMessage);
                //console.log('false', response)
            }
        }).catch((e) => {
            this.authserviceToken.CheckTokenExpire(e.response.status)
        });
    }

    private setRootCauseI(maxRootCause: number) {
        for (let item_rootCause of this.Tempdata.rootCause) {
            let isMaxRootCause = 'N';
            if (item_rootCause.rootCause_Likelihood == maxRootCause) {
                isMaxRootCause = 'Y';
            }
            this.Assert_RootCauseTable.push({
                id: item_rootCause.rootCause_Id,
                title: item_rootCause.rootCause_Category,
                highlight: isMaxRootCause
            });
        }
    }

    private setRootCauseII() {
        for (let item_rootCause of this.Tempdata.rootCause) {
            this.Table_TitleRootcause.push({
                id: item_rootCause.rootCause_Id,
                rootcause: item_rootCause.rootCause_Category,
                mitigation: []
            });



            if (item_rootCause.rootCause_KRI_Name !== null) {
                this.temprootkriFlag = true;
                this.temprootkri.push({
                    rootcause: item_rootCause.rootCause_Category,
                    rootCause_KRI_Name: item_rootCause.rootCause_KRI_Name,
                    rootCause_KRI_Threshold_Green: item_rootCause.rootCause_KRI_Threshold_Green,
                    rootCause_KRI_Threshold_Red: item_rootCause.rootCause_KRI_Threshold_Red,
                    rootCause_KRI_Status: item_rootCause.rootCause_KRI_Status,
                    rootCause_KRI_Justification: item_rootCause.rootCause_KRI_Justification
                });
            }
        }
    }

    private setRootCauseIII() {
        for (let item_TitleRootcause_I3 of this.Table_TitleRootcause) {
            for (let item_rootCause_Mitigation_I2 of this.Tempdata.rootCause_Mitigation) {
                if (item_TitleRootcause_I3.id === item_rootCause_Mitigation_I2.rootCause_Id) {
                    item_TitleRootcause_I3.mitigation.push({
                        id: item_rootCause_Mitigation_I2.rootCause_Id,
                        plan: item_rootCause_Mitigation_I2.rootCause_Mitigation_Name,
                        owner: item_rootCause_Mitigation_I2.rootCause_Mitigation_Owner,
                        date: item_rootCause_Mitigation_I2.rootCause_Mitigation_DueDate,
                        progress: item_rootCause_Mitigation_I2.rootCause_Mitigation_Progress,
                        justification: item_rootCause_Mitigation_I2.rootCause_Mitigation_Justification
                    });
                }
            }
        }
    }

    private setRootCauseIV(num: number) {
        for (let item_TitleRootcause_I1 of this.Table_TitleRootcause) {
            for (let [index_I2] of item_TitleRootcause_I1.mitigation.entries()) {
                for (let item_DDLBU of this.DDLBU) {
                    if (item_TitleRootcause_I1.mitigation[index_I2].owner === item_DDLBU.value) {
                        item_TitleRootcause_I1.mitigation[index_I2].owner = item_DDLBU.text;
                        break;
                    }
                }

                if (this.temprootmitagation.length === 0) {
                    this.temprootmitagation.push({
                        samehead: '1',
                        header: item_TitleRootcause_I1.rootcause,
                        id: item_TitleRootcause_I1.mitigation[index_I2].id,
                        plan: item_TitleRootcause_I1.mitigation[index_I2].plan,
                        owner: item_TitleRootcause_I1.mitigation[index_I2].owner,
                        date: item_TitleRootcause_I1.mitigation[index_I2].date,
                        progress: item_TitleRootcause_I1.mitigation[index_I2].progress,
                        justification: item_TitleRootcause_I1.mitigation[index_I2].justification,
                        numcol: item_TitleRootcause_I1.mitigation.length,
                        number: num
                    });
                } else if (this.temprootmitagation[(this.temprootmitagation.length - 1)].id === item_TitleRootcause_I1.mitigation[index_I2].id) {
                    this.temprootmitagation.push({
                        samehead: '0',
                        header: item_TitleRootcause_I1.rootcause,
                        id: item_TitleRootcause_I1.mitigation[index_I2].id,
                        plan: item_TitleRootcause_I1.mitigation[index_I2].plan,
                        owner: item_TitleRootcause_I1.mitigation[index_I2].owner,
                        date: item_TitleRootcause_I1.mitigation[index_I2].date,
                        progress: item_TitleRootcause_I1.mitigation[index_I2].progress,
                        justification: item_TitleRootcause_I1.mitigation[index_I2].justification,
                        numcol: item_TitleRootcause_I1.mitigation.length,
                    });
                } else if (this.temprootmitagation[(this.temprootmitagation.length - 1)].id !== item_TitleRootcause_I1.mitigation[index_I2].id) {
                    this.temprootmitagation.push({
                        samehead: '1',
                        header: item_TitleRootcause_I1.rootcause,
                        id: item_TitleRootcause_I1.mitigation[index_I2].id,
                        plan: item_TitleRootcause_I1.mitigation[index_I2].plan,
                        owner: item_TitleRootcause_I1.mitigation[index_I2].owner,
                        date: item_TitleRootcause_I1.mitigation[index_I2].date,
                        progress: item_TitleRootcause_I1.mitigation[index_I2].progress,
                        justification: item_TitleRootcause_I1.mitigation[index_I2].justification,
                        numcol: item_TitleRootcause_I1.mitigation.length,
                        number: ++num
                    });
                }
            }
        }
        return num;
    }


    private setQuarter() {
        for (let item_Quarter of this.Quarter) {
            if (this.Register_Data.tempQuarter == item_Quarter.value) {
                this.Register_Data.tempYear = item_Quarter.text2;
                break;
            }
        }
    }

    func_GETMASTER_RiskCategory() {
        let formData = {
            Module: "RiskCateDD",
            TextSearch7: this.Register_Data.tempQuarter
        }
        this.mserve.Master_DDL(formData).then((response) => {
            //console.log("dataRiskCate_DD:", response.data.body)
            if (response.data.status) {
                this.RiskCategory = response.data.body

            } else {
                //console.log('false', response)
            }
        });
    }
    func_GETMASTER_ImpactCategory() {
        let formData = {
            Module: "ImpactCateDD",
            TextSearch7: this.Register_Data.tempQuarter
        }
        this.mserve.Master_DDL(formData).then((response) => {
            //console.log("dataImpactCategory_DD:", response.data.body)
            if (response.data.status) {
                this.ImpactCategory = response.data.body
            } else {
                //console.log('false', response)
            }
        }).catch((error) => {
            //console.log(error);
        });
    }
    func_GETMASTER_CorptargetHead() {
        let formData = {
            Module: "CorpTargetHeadDD",
            TextSearch1: this.Register_Data.tempYear
        }
        this.mserve.Master_DDL(formData).then((response) => {
            //console.log("dataCorpTargetHead_DD:", response.data.body)
            if (response.data.status) {
                this.CorpTargetHead = response.data.body
                this.func_GETMASTER_CorptargetItem(this.Register_Data.Regis_ObjectiveHead)
            } else {
                //console.log('false', response)
            }
        });
    }
    func_GETMASTER_CorptargetItem(value) {
        let tempcodevalue;
        for (let item_CorpTargetHead of this.CorpTargetHead) {
            if (value == item_CorpTargetHead.value) {
                tempcodevalue = item_CorpTargetHead.text1;
                break;
            }
        }
        let formData = {
            Module: "CorpTargetItemdDD",
            TextSearch1: tempcodevalue
        }
        this.mserve.Master_DDL(formData).then((response) => {
            //console.log("dataCorpTargetItem_DD:", response.data.body)
            if (response.data.status) {
                this.CorpTargetItem = response.data.body
            } else {
                //console.log('false', response)
            }
        });
    }
    func_GETMASTER_Likelihood() {
        let formData = {
            Module: "LikelihoodLevelDD",
            TextSearch7: this.Register_Data.tempQuarter
        }
        this.mserve.Master_DDL(formData).then((response) => {
            //console.log("dataLikelihood_DD:", response.data.body)
            if (response.data.status) {
                this.likelyhoodLevel = response.data.body
                this.likelyhoodLevelAfter = response.data.body
            } else {
                //console.log('false', response)
            }
        }).catch((error) => {
            //console.log(error);
        });
    }
    func_GETMASTER_Financial() {
        let formData = {
            Module: "ImpactType",
        }
        this.mserve.Master_DDL(formData).then((response) => {
            //console.log("dataImpactfinancial_DD:", response.data.body)
            if (response.data.status) {
                this.Financial = response.data.body;

                if (this.Tempdata.impact.length !== 0) {
                    this.Assert_ImpactTable = [];
                    this.show_Category = [];
                    let maxImpct = Math.max(...this.Tempdata.impact.map(o => o.impact_Level));
                    this.setImpactI(maxImpct);

                    this.Assert_ImpactTable = this.show_Category
                    this.setCategory();

                    if (this.Tempdata.impact_Mitigation !== 0) {
                        this.setImpactII();
                        this.setImpactIII(1);
                    }


                } else {
                    this.Assert_ImpactTable = [];
                }



            } else {
                //console.log('false', response)
            }
        }).catch((error) => {
            //console.log(error);
        });
    }

    private setImpactI(maxImpct: number) {
        for (let item_impact_I2 of this.Tempdata.impact) {
            let isMaxImpct = 'N';
            if (item_impact_I2.impact_Level == maxImpct) {
                isMaxImpct = 'Y';
            }
            if (item_impact_I2.impact_NPT_EMV !== null) {
                for (let item_Financial of this.Financial) {
                    if (item_impact_I2.impact_NPT_EMV === item_Financial.value) {
                        this.show_Category.push({
                            id: item_impact_I2.impact_Id,
                            title: item_Financial.text,
                            highlight: isMaxImpct
                        });
                        break;
                    }
                }
            }
            else {
                this.show_Category.push({
                    id: item_impact_I2.impact_Id,
                    title: item_impact_I2.impact_Description,
                    highlight: isMaxImpct
                });
            }
        }
    }

    private setImpactII() {
        for (let item_Impact_I3 of this.Table_Impact) {
            for (let item_impact_Mitigation_I2 of this.Tempdata.impact_Mitigation) {
                for (let item_DDLBU of this.DDLBU) {
                    if (item_impact_Mitigation_I2.impact_Mitigation_Owner === item_DDLBU.value) {
                        item_impact_Mitigation_I2.impact_Mitigation_Owner = item_DDLBU.text;
                        break;
                    }
                }

                if (item_Impact_I3.id === item_impact_Mitigation_I2.impact_Id) {
                    item_Impact_I3.mitigation.push({
                        id: item_impact_Mitigation_I2.impact_Id,
                        plan: item_impact_Mitigation_I2.impact_Mitigation_Name,
                        owner: item_impact_Mitigation_I2.impact_Mitigation_Owner,
                        date: item_impact_Mitigation_I2.impact_Mitigation_DueDate,
                        progress: item_impact_Mitigation_I2.impact_Mitigation_Progress,
                        justification: item_impact_Mitigation_I2.impact_Mitigation_Justification
                    });
                }
            }
        }
    }

    private setImpactIII(num: number) {
        for (let item_Impact of this.Table_Impact) {
            for (let [index_I2] of item_Impact.mitigation.entries()) {
                if (this.tempImpactmitagation.length === 0) {
                    this.tempImpactmitagation.push({
                        samehead: '1',
                        header: item_Impact.impact,
                        id: item_Impact.mitigation[index_I2].id,
                        plan: item_Impact.mitigation[index_I2].plan,
                        owner: item_Impact.mitigation[index_I2].owner,
                        date: item_Impact.mitigation[index_I2].date,
                        progress: item_Impact.mitigation[index_I2].progress,
                        justification: item_Impact.mitigation[index_I2].justification,
                        numcol: item_Impact.mitigation.length,
                        number: num
                    });
                } else if (this.tempImpactmitagation[(this.tempImpactmitagation.length - 1)].id === item_Impact.mitigation[index_I2].id) {
                    this.tempImpactmitagation.push({
                        samehead: '0',
                        header: item_Impact.impact,
                        id: item_Impact.mitigation[index_I2].id,
                        plan: item_Impact.mitigation[index_I2].plan,
                        owner: item_Impact.mitigation[index_I2].owner,
                        date: item_Impact.mitigation[index_I2].date,
                        progress: item_Impact.mitigation[index_I2].progress,
                        justification: item_Impact.mitigation[index_I2].justification,
                        numcol: item_Impact.mitigation.length,
                    });
                } else if (this.tempImpactmitagation[(this.tempImpactmitagation.length - 1)].id !== item_Impact.mitigation[index_I2].id) {
                    this.tempImpactmitagation.push({
                        samehead: '1',
                        header: item_Impact.impact,
                        id: item_Impact.mitigation[index_I2].id,
                        plan: item_Impact.mitigation[index_I2].plan,
                        owner: item_Impact.mitigation[index_I2].owner,
                        date: item_Impact.mitigation[index_I2].date,
                        progress: item_Impact.mitigation[index_I2].progress,
                        justification: item_Impact.mitigation[index_I2].justification,
                        numcol: item_Impact.mitigation.length,
                        number: ++num
                    });
                }
            }
        }
        return num;
    }

    private setCategory() {
        for (let item_show_Category of this.show_Category) {
            this.Table_Impact.push({
                id: item_show_Category.id,
                impact: item_show_Category.title,
                mitigation: []
            });
        }
    }



    func_GETMASTER_ImpactLevel() {
        let formData = {
            Module: "ImpactLevelDD",
            TextSearch1: this.Register_Data.Regis_BusinessUnit,
            TextSearch7: this.Register_Data.tempQuarter
        }
        this.mserve.Master_DDL(formData).then((response) => {
            //console.log("dataImpactLevel_DD:", response.data.body)
            if (response.data.status) {
                this.ImpactLevel = response.data.body
                this.ImpactLevelAfter = response.data.body
                this.loading = false;
            } else {
                //console.log('false', response)
            }
        });
    }
    func_GetDDL_BusinessName() {
        let formData = {
            Module: 'DepartmentDD'
        }
        this.mserve.Master_DDL(formData).then((response) => {
            //console.log("DDLPosition :", response.data.body)
            if (response.data.status) {
                this.DDLBU = response.data.body;
            } else {
                //console.log('false', response)
            }
        });
    }

    selectedRaged_RootCause(value) {
        let slider;
        let output;
        slider = document.getElementById("myRangeRo");
        output = document.getElementById("showRo");
        output.innerHTML = slider.value;

        slider.oninput = function () {
            output.innerHTML = this.value;
        }
    }
    selectedRaged_Impact(value) {
        let slider;
        let output;
        slider = document.getElementById("myRangeIm");
        output = document.getElementById("showIm");
        output.innerHTML = slider.value;

        slider.oninput = function () {
            output.innerHTML = this.value;
        }
    }
    func_Back() {
        //console.log('Checkback')
        let prevPage = sessionStorage.getItem("menubackto");
        if (prevPage == "/Assessment") {
            this.router.navigate(['/Assessment']);
        } else {
            this.router.navigate(['/Delegate']);
        }
    }
    func_close() {
        $('#modalrootcasue').modal('hide');
        $('#modalimpact').modal('hide');
    }

    func_todolist() {
        this.router.navigate(['/Approval', 2]);
    }

    func_ApproveFromReview() {
        const _s = this;
        if (this.tempSelectedApproved.length !== 0 || this.tempSelectedApproved2.length !== 0) {
            let formdataApprove = {
                SessionEmpID: this.empid,
                SecurityCode: '1234',
                Email: this.Data_SessionStorage[0].emaiL_ID,
                Module: 'APPROVE',
                body: {
                    Approve_By: this.empid,
                    ApproveTypeOrganization: this.tempSelectedApproved,
                    ApproveTypeAsset: this.tempSelectedApproved2
                }
            }
            _s.riskServe.RiskApprove(formdataApprove).then((response) => {
                if (response.data.status) {
                    $('#modalAlertConfirmReivew').modal('show');
                }
                else {
                    this.alertflow = response.data.errorMessage
                    $('#modalAlertdanger5').modal('show');
                    //console.log('Approve falied', response)
                }
            }).catch((e) => { this.authserviceToken.CheckTokenExpire(e.response.status) });
        }
    }
    func_RejectFromReview() {
        const _s = this;
        this.commentReject = '';
        this.Risk_Close_Reason = '';
        if (this.tempSelectedApproved.length !== 0 || this.tempSelectedApproved2.length !== 0) {
            if (this.tempSelectedApproved.length !== 0) {
                if (this.tempSelectedApproved[0].Comment === '') {
                    this.tempflag = '1'
                    $('#modalComment').modal('show');
                } else {
                    this.func_Confirmreject();
                }
            }
            else if (this.tempSelectedApproved2.length !== 0) {
                if (this.tempSelectedApproved2[0].Comment === '') {
                    this.tempflag = '2'
                    $('#modalComment').modal('show');
                } else {
                    this.func_Confirmreject();
                }
            }
        }
    }
    func_Confirmreject() {
        const _s = this;
        let formdataReject = {
            SessionEmpID: this.empid,
            SecurityCode: '1234',
            Email: this.Data_SessionStorage[0].emaiL_ID,
            Module: 'REJECT',
            body: {
                Reject_By: this.empid,
                RejectTypeOrganization: this.tempSelectedApproved,
                RejectTypeAsset: this.tempSelectedApproved2
            }
        }
        _s.riskServe.RiskReject(formdataReject).then((response) => {
            if (response.data.status) {
                $('#modalAlertConfirmReivew').modal('show');
            }
            else {
                this.alertflow = response.data.errorMessage
                $('#modalAlertdanger5').modal('show');
                //console.log('Reject falied', response)
            }
        }).catch((e) => { this.authserviceToken.CheckTokenExpire(e.response.status) });
    }
    func_editrootcause(id) {
        this.temptable = []
        for (let item_Tempdata of this.Tempdata.rootCause) {
            if (item_Tempdata.rootCause_Id === id) {
                this.Register_Rootcause.RootCause_Id = id
                this.Register_Rootcause.Root_RootCause = item_Tempdata.rootCause_Category
                this.Register_Rootcause.Root_RootLikelyhood = item_Tempdata.rootCause_Likelihood
                this.Register_Rootcause.Root_RootMitigationType = item_Tempdata.rootCause_Mitigation_Type
                this.Register_Rootcause.Root_AfterMitigated = (item_Tempdata.rootCause_After_Mitigated === null) ? '' : item_Tempdata.rootCause_After_Mitigated
                this.Register_Rootcause.Root_KRIName = item_Tempdata.rootCause_KRI_Name
                this.Register_Rootcause.Root_KRIThreshold_Green = item_Tempdata.rootCause_KRI_Threshold_Green
                this.Register_Rootcause.Root_KRIThreshold_Red = item_Tempdata.rootCause_KRI_Threshold_Red
                this.Register_Rootcause.Root_KRIStatus = item_Tempdata.rootCause_KRI_Status
                this.Register_Rootcause.Root_Justification = item_Tempdata.rootCause_KRI_Justification
            }
        }
        for (let item_rootCause_Mitigation of this.Tempdata.rootCause_Mitigation) {
            for (let item_DDLBU of this.DDLBU) {
                if (item_rootCause_Mitigation.rootCause_Mitigation_Owner === item_DDLBU.value) {
                    item_rootCause_Mitigation.rootCause_Mitigation_Owner = item_DDLBU.text
                    break;
                }
            }

            if (item_rootCause_Mitigation.rootCause_Id === id) {
                this.temptable.push({
                    id: item_rootCause_Mitigation.rootCause_Mitigation_Id,
                    plan: item_rootCause_Mitigation.rootCause_Mitigation_Name,
                    owner: item_rootCause_Mitigation.rootCause_Mitigation_Owner,
                    Duedate: item_rootCause_Mitigation.rootCause_Mitigation_DueDate,
                    Progess: item_rootCause_Mitigation.rootCause_Mitigation_Progress,
                    justification: item_rootCause_Mitigation.rootCause_Mitigation_Justification
                })
            }
        }
        this.selectedradio(this.Register_Rootcause.Root_KRIStatus)
        
        $('#modalrootcasue').modal('show')
    }
    selectedradio(value) {
        if (value === 1 || value === 'green') {
            let element = document.getElementById("rdo1");
            element.classList.add("selected1");
            let element2 = document.getElementById("rdo2");
            element2.classList.remove("selected2");
            let element3 = document.getElementById("rdo3");
            element3.classList.remove("selected3");
        } else if (value === 2 || value === 'yellow') {
            let element = document.getElementById("rdo1");
            element.classList.remove("selected1");
            let element2 = document.getElementById("rdo2");
            element2.classList.add("selected2");
            let element3 = document.getElementById("rdo3");
            element3.classList.remove("selected3");
        } else if (value === 3 || value === 'red') {
            let element = document.getElementById("rdo1");
            element.classList.remove("selected1");
            let element2 = document.getElementById("rdo2");
            element2.classList.remove("selected2");
            let element3 = document.getElementById("rdo3");
            element3.classList.add("selected3");
        } else {
            let element = document.getElementById("rdo1");
            element.classList.remove("selected1");
            let element2 = document.getElementById("rdo2");
            element2.classList.remove("selected2");
            let element3 = document.getElementById("rdo3");
            element3.classList.remove("selected3");
        }
    }
    func_editimpact(id) {
        this.temptableImpact = [] 
        //console.log('tem_Tempdata', this.Tempdata.impact)
        
        for (let itemimpact of this.Tempdata.impact) {
            if (itemimpact.impact_Id === id) {
                var ImpactCateID = this.ImpactCategory.find(x => x.value == itemimpact.impact_Category).text2;
                let formData = {
                    Module: "ImpactLevelDDv2",
                    TextSearch1: this.Register_Data.Regis_BusinessUnit,
                    TextSearch2: ImpactCateID,
                    TextSearch7: this.Register_Data.tempQuarter
                }
                this.mserve.Master_DDL(formData).then((response) => {
                    if (response.data.status) {
                        this.ImpactLevel = response.data.body;
                        this.ImpactLevelAfter = response.data.body;
                        this.temp_impact_Category = itemimpact.impact_Category;

                        this.Register_Impact.Impact_Id = id;
                        this.Register_Impact.Impact_Category = (itemimpact.impact_Category === null) ? '' : itemimpact.impact_Category;
                        this.Register_Impact.Impact_NPTEMV = itemimpact.impact_NPT_EMV;
                        this.Register_Impact.Impact_Total_Amont = itemimpact.impact_Total_Amont;
                        this.Register_Impact.Impact_Description = itemimpact.impact_Description;
                        this.Register_Impact.Impact_Level = (itemimpact.impact_Level === null) ? '' : itemimpact.impact_Level;
                        this.Register_Impact.Impact_Migation_Type = itemimpact.impact_Mitigation_Type;
                        this.Register_Impact.Impact_After_Mitigated = (itemimpact.impact_After_Mitigated === null) ? '' : itemimpact.impact_After_Mitigated;
                        this.Register_Impact.Impact_Rating = itemimpact.impact_Rating;
                        this.Impact_After_Total_Amont = (itemimpact.impact_After_Total_Amont === null) ? '' : itemimpact.impact_After_Total_Amont
                        
                        this.ImpactLevel = this.ImpactLevel.filter(x=> x.value == this.Register_Impact.Impact_Level);
                        this.ImpactLevelAfter = this.ImpactLevelAfter.filter(x=> x.value == this.Register_Impact.Impact_After_Mitigated);
                    } else {
                    }
                }).catch((e) => {
                    if (e.response) { this.authserviceToken.CheckTokenExpire(e.response.status) } else { }
                });
                
                break;
            }
        }
       
        for (let item_impact_Mitigation of this.Tempdata.impact_Mitigation) {
            if (item_impact_Mitigation.impact_Id === id) {
                this.temptableImpact.push({
                    id: item_impact_Mitigation.impact_Mitigation_Id,
                    mitigation: item_impact_Mitigation.impact_Mitigation_Name,
                    owner: item_impact_Mitigation.impact_Mitigation_Owner,
                    Duedate: item_impact_Mitigation.impact_Mitigation_DueDate,
                    Progess: item_impact_Mitigation.impact_Mitigation_Progress,
                    justification: item_impact_Mitigation.impact_Mitigation_Justification,
                    display: true
                })
            }
        }
        $("#AfterMitigated").val(this.Register_Impact.Impact_After_Mitigated);
              $('#modalimpact').modal('show');
    }
 
 
    func_PlotRiskMap() {
        //console.log('Data tablemap :', this.list.risk.risktable)
        for (let i = 0; i < 5; i++) {
            for (let ii = 0; ii <= 4; ii++) {
                this.list.risk.risktable[i].col[ii].item = []
            }
        }

        let Likelyhood = parseInt(this.Register_Data.Regis_Likelyhood);
        let Impact = parseInt(this.Register_Data.Regis_Impact);

        if (this.Tempdata.impact.length > 0 && this.Tempdata.rootCause.length > 0) {
            Likelyhood = Math.max(...this.Tempdata.rootCause.map(o => o.rootCause_Likelihood));
            Impact = Math.max(...this.Tempdata.impact.map(o => o.impact_Level));
        }
  
        for (let ii = 0; ii <= 4; ii++) {
            for (let i = 0; i <= 4; i++) {
                if ((Likelyhood - 1) === i && Impact === (ii + 1)) {
                    this.list.risk.risktable[(4 - ii)].col[i].item.push({
                        title: '1',
                        bgcolor: '#000',
                        color: '#fff'
                    })
                }
            }
        }
    }


    ConfirmOK() {
        const _s = this;
        $('#modalAlertConfirmReivew').modal('hide');
        setTimeout(function () {
            _s.router.navigate(['/Approval/1']);
        }, 500)
    }
    ConfirmOKAlert() {
        this.commentReject = ''
        this.Risk_Close_Reason = '';
        $('#modalAlertReivew').modal('hide');
        setTimeout(function () { $('#modalComment').modal('show'); }, 500)
    }
    ConfirmComment() {
        const _s = this;
        $('#modalComment').modal('hide');
        if (!this.commentReject.replace(/\s/g, '').length) {
            setTimeout(function () { $('#modalAlertReivew').modal('show'); }, 500)
        }
        else {
            if (this.tempflag === '1') {
                this.tempSelectedApproved[0].Comment = this.commentReject
            } else {
                this.tempSelectedApproved2[0].Comment = this.commentReject
            }
            this.func_Confirmreject();
        }
    }
    CloseComment() {
        $('#modalComment').modal('hide');
    }
    closeflow() {
        $('#modalAlertdanger5').modal('hide');
    }


    BackDashboard() {
        sessionStorage.setItem('PageBack', 'BackFromDashboard')
        this.router.navigate(['/Page-dashboard']);
    }
    BackCompareDashboard() {
        sessionStorage.setItem('PageBack', 'BackFromCompareDashboard')
        this.router.navigate(['/Compare-riskprofile']);
    }

    ngAfterViewInit(): void {
        const _s = this
        scroll(0, 390);
    }

    ngAfterContentChecked(): void {
        this.autogrow();
    }

    autogrow() {
        let textArea = document.getElementById("RegisContext")
        textArea.style.overflow = 'hidden';
        textArea.style.height = 'auto';
        textArea.style.height = textArea.scrollHeight + 'px';
    }

    autoGrowTextZone(e) {
        e.target.style.overflow = 'hidden';
        e.target.style.height = "0px";
        e.target.style.height = (e.target.scrollHeight) + "px";
    }

    downloadfile(path)
    {
        let splitted = []; 
        if(path.includes("\\"))
        {
            splitted = path.split("\\", 3); 
        }
        else if(path.includes("/"))
        {
            splitted = path.split("/", 3); 
        }
        
        let data = {
            path1: splitted[0],
            path2: splitted[1],
            filename: splitted[2],
            source: "RiskResources"
          }

        this.mserve.DownloadFile(data).then((response) => {
            if (response.data) {
                const blob = new Blob([response.data], { type: 'application/octet-stream' });

                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = data.filename ;
                a.click();
                window.URL.revokeObjectURL(url);
            } else {
              alert("ไม่สามารถดาวน์โหลดไฟล์ได้");
              //console.log('DownloadAssessment : false', response)
            }
          }).catch((e) => {
            alert("ไม่สามารถดาวน์โหลดไฟล์ได้");
            });
    }

}

