import { AppComponent } from './app.component';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import {INTERNAL_COMPONENTS} from './app-path';
import {EXTERNAL_COMPONENTS, PROVIDERS_COMPONENTS} from './app-module';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { AppRoutingModule } from './app-routing.module';
import { MsalModule, MsalInterceptor } from '@azure/msal-angular';
import { PublicClientApplication, InteractionType, BrowserCacheLocation } from '@azure/msal-browser';

import { HttpClientModule } from '@angular/common/http';

import { NgxSummernoteModule } from 'ngx-summernote';
import { ChartModule } from 'angular2-chartjs';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AppSettingService, appInitializerCallback } from '../app/app-setting-service';
import { AuthenService } from './services/authenservice.service';
import { PublicClientApplicationService } from './services/publicClientApplication.service';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MainProcessComponent } from './components/main-process/main-process.component';
import { Masterserve } from './services/master.service';
import { AxiosInterceptorService } from './services/axios-interceptor.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {AutosizeModule} from 'ngx-autosize';
import { SharedModule } from './shared/shared.module';
import { StrategicRisksSharedModule} from "./StrategicRisks/shared/shared.module";
import { StrategicRiskService } from './StrategicRisks/services/strategicrisk.service';
import { MasterService } from './StrategicRisks/services/master.service';
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
@NgModule({
    declarations: [
        AppComponent,
        INTERNAL_COMPONENTS,
        MainProcessComponent,
    ],
    providers: [
        PROVIDERS_COMPONENTS,
        MainProcessComponent,
        HttpClientModule,
        AuthenService,
        AppSettingService,
        PublicClientApplicationService,
        Masterserve,
        AxiosInterceptorService,
        StrategicRiskService,
        MasterService,
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerCallback,
            multi: true,
            useClass: MsalInterceptor,
            deps: [AppSettingService]
        },
    ],
    bootstrap: [AppComponent],
    imports: [
        EXTERNAL_COMPONENTS,
        FontAwesomeModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatToolbarModule,
        MatButtonModule,
        MatListModule,
        MatDialogModule,
        AppRoutingModule,
        ChartModule,
        DragDropModule,
        NgxSummernoteModule,
        MatProgressSpinnerModule,
        MatDatepickerModule,
        MatNativeDateModule,
        AutosizeModule,
        SharedModule,
        StrategicRisksSharedModule,
        MsalModule.forRoot(new PublicClientApplication({
            auth: {
                clientId: '',
                authority: '',
                redirectUri: '',
            },
            cache: {
                cacheLocation: BrowserCacheLocation.LocalStorage,
                storeAuthStateInCookie: true,
            },
        }), {
            interactionType: InteractionType.Popup,
            authRequest: {
                scopes: ['user.read']
            },
            loginFailedRoute: "/login-failed"
        }, {
            interactionType: InteractionType.Popup,
            protectedResourceMap: new Map<string, Array<string> | null>([
                ["https://graph.microsoft.com/v1.0/me", ["user.read"]]
            ])
        })
    ]
})
export class AppModule { 

 
}
