import { Component, Input, OnInit } from '@angular/core';
import { MasterService } from 'src/app/StrategicRisks/services/master.service';

@Component({
  selector: 'app-action-log',
  templateUrl: './action-log.component.html',
  styleUrls: ['./action-log.component.css']
})
export class ActionLogComponent implements OnInit {
  actionLogDataList = [];
  private _riskId: number [] = [] ;
  _isShowStrategyName: boolean = false;
  page = 1;
  pageSize = 10;
  usePagination = true;

  @Input() set riskId(value: number []) {
    if (value) {
      this._riskId = value;
      this.getData();
    }
  }

  @Input() set isShowStrategyName(value: boolean) {
    if (value != null) {
      this._isShowStrategyName = value;
    }
  }
  constructor(private masterSevice: MasterService) { }

  ngOnInit(): void {
    this.getData();
  }

  getData()
  {
    let formdata = {
      body: {
        RiskIds: this._riskId
      }
    }
    if(this._riskId.length > 0 && this._riskId[0] != undefined)
    {
      this.getActionLogById(formdata);
    }
  }
  

  getActionLogById(formdata)
  {
    this.masterSevice
    .getActionLogById(formdata)
    .then((response) => {
      if (response.data.status) {
        this.actionLogDataList = response.data.body;
      } else {
        alert(response.data.errorMessage);
      }
    })
    .catch((e) => {});
  }

}
