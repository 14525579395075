import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { LowestScoreDirective } from '../../directives/LowestScore.directive';
import { bindingStrategicTableModel } from '../../model/StrategicRiskModel';
import { MasterDirective } from '../../directives/Master.directive';

@Component({
  selector: 'app-riskfrom-strategy',
  templateUrl: './riskfrom-strategy.component.html',
  styleUrls: ['./riskfrom-strategy.component.css'],
})
export class RiskfromStrategyComponent implements OnInit {
  
  @Input() set data(value: []) {
    if (value) {
      this.setData(value);
    }
  }
  @Input() isCloseButtonEdit: boolean = false;
  
  @Output() addLog = new EventEmitter<string>();

  tabSelect1 = 1;
  tabSelect2 = 2;
  positionSetValue1 = [];
  positionSetValue2 = [];

  stategicAlignment = [];
  stategyContribution = [];
  form1: FormGroup;
  form2: FormGroup;
  form3: FormGroup;
  form4: FormGroup;
  eventForm1 = '';
  eventForm2 = '';
  eventForm3 = '';
  eventForm4 = '';
  selectValues = [];
  constructor(
    private _fb: FormBuilder,
    public lowestScore: LowestScoreDirective,
    public masterDirective: MasterDirective
  ) {
    this.form1 = this._fb.group({
      strategicDirection: new FormControl('', Validators.required),
      strategicDirectionRationale: new FormControl('', Validators.required),
      financial: new FormControl('', Validators.required),
      financialRationale: new FormControl('', Validators.required),
      zeroTolerance: new FormControl('', Validators.required),
      zeroToleranceRationale: new FormControl('', Validators.required),
      lowestScore: new FormControl('', Validators.required),
    });
    this.form2 = this._fb.group({
      growthSustainability: new FormControl('', Validators.required),
      growthSustainabilityRationale: new FormControl('', Validators.required),
      timingCashInfow: new FormControl('', Validators.required),
      timingCashInfowRationale: new FormControl('', Validators.required),
      valueProfit: new FormControl('', Validators.required),
      valueProfitRationale: new FormControl('', Validators.required),
      lowestScore: new FormControl('', Validators.required),
    });
    this.form3 = this._fb.group({
      capability: new FormControl('', Validators.required),
      capabilityRationale: new FormControl('', Validators.required),
      resources: new FormControl('', Validators.required),
      resourcesRationale: new FormControl('', Validators.required),
      lowestScore: new FormControl('', Validators.required),
    });
    this.form4 = this._fb.group({
      jointInvestment: new FormControl('', Validators.required),
      jointInvestmentRationale: new FormControl('', Validators.required),
      quickExit: new FormControl('', Validators.required),
      quickExitRationale: new FormControl('', Validators.required),
      lowestScore: new FormControl('', Validators.required),
    });
  }

  ngOnInit(): void {
    this.selectValues = this.masterDirective.getStrategicValues();
  }
  public getData(data: any) {
    data.stategicAlignment = this.form1.value;
    data.stategyContribution = this.form2.value;
    data.manageableChallenges = this.form3.value;
    data.manageablePortfolioRisk = this.form4.value;
    return data;
  }
  private setData(data: any) {
    this.form1.patchValue(data.stategicAlignment);
    this.form2.patchValue(data.stategyContribution);
    this.form3.patchValue(data.manageableChallenges);
    this.form4.patchValue(data.manageablePortfolioRisk);
    this.PositionValueTab1();
    this.PositionValueTab2();
  }
  PositionValueTab1(section = '') {
    if(section == 'Strategic Fit')
    {
      this.addLog.emit(section);
    }
    let _financial = this.form1.getRawValue().financial;
    let _strategicDirection = this.form1.getRawValue().strategicDirection;
    let _zeroTolerance = this.form1.getRawValue().zeroTolerance;

    let _growthSustainabilityl = this.form2.getRawValue().growthSustainability;
    let _timingCashInfow = this.form2.getRawValue().timingCashInfow;
    let _valueProfit = this.form2.getRawValue().valueProfit;
    let value: bindingStrategicTableModel = {
      xPosition: this.lowestScore.calLowestScore(
        _financial,
        _strategicDirection,
        _zeroTolerance
      ),
      yPosition: this.lowestScore.calLowestScore(
        _growthSustainabilityl,
        _timingCashInfow,
        _valueProfit
      ),
    };

    let _lowestScorex = this.selectValues.find(
      (f: any) => f.value === value.xPosition.toString()
    ).name;
    let _lowestScorey = this.selectValues.find(
      (f: any) => f.value === value.yPosition.toString()
    ).name;
    this.form1.controls.lowestScore.setValue(_lowestScorex);
    this.form2.controls.lowestScore.setValue(_lowestScorey);

    this.form1.value.strategicDirectionRationale = 'Rationale \n' + this.form1.value.strategicDirectionRationale;
    this.form1.value.financialRationale = 'Rationale \n' + this.form1.value.financialRationale;
    this.form1.value.zeroToleranceRationale = 'Rationale \n' + this.form1.value.zeroToleranceRationale;

    this.form2.value.growthSustainabilityRationale = 'Rationale \n' + this.form2.value.growthSustainabilityRationale;
    this.form2.value.timingCashInfowRationale = 'Rationale \n' + this.form2.value.timingCashInfowRationale;
    this.form2.value.valueProfitRationale = 'Rationale \n' + this.form2.value.valueProfitRationale;

    this.positionSetValue1 = [];
    this.positionSetValue1.push(value);
  }
  PositionValueTab2(section = "") {

    if(section == 'Execution Perspective')
    {
      this.addLog.emit(section);
    }

    let _capability = this.form3.getRawValue().capability;
    let _resources = this.form3.getRawValue().resources;

    let _jointInvestmenty = this.form4.getRawValue().jointInvestment;
    let _quickExit = this.form4.getRawValue().quickExit;

    let value: bindingStrategicTableModel = {
      xPosition: this.lowestScore.calLowestScore(_capability, _resources),
      yPosition: this.lowestScore.calLowestScore(_jointInvestmenty, _quickExit),
    };

    let _lowestScorex = this.selectValues.find(
      (f: any) => f.value === value.xPosition.toString()
    ).name;
    let _lowestScorey = this.selectValues.find(
      (f: any) => f.value === value.yPosition.toString()
    ).name;
    this.form3.controls.lowestScore.setValue(_lowestScorex);
    this.form4.controls.lowestScore.setValue(_lowestScorey);
    
    this.form3.value.capabilityRationale = 'Rationale \n' + this.form3.value.capabilityRationale;
    this.form3.value.resourcesRationale = 'Rationale \n' + this.form3.value.resourcesRationale;

    this.form4.value.jointInvestmentRationale = 'Rationale \n' + this.form4.value.jointInvestmentRationale;
    this.form4.value.quickExitRationale = 'Rationale \n' + this.form4.value.quickExitRationale;
    
    this.positionSetValue2 = [];
    this.positionSetValue2.push(value);
  }

  filterByid(item: any[], itemValue): any[] {
    return item.filter((p) => p.value == itemValue);
  }
  public getform(): { status: boolean; message: string } {
    let item: { status: boolean; message: string } = {
      status: true,
      message: '',
    };
    this.form1.markAllAsTouched();
    this.form2.markAllAsTouched();
    this.form3.markAllAsTouched();
    this.form4.markAllAsTouched();
    if (
      !this.form1.valid ||
      !this.form2.valid ||
      this.eventForm1 == 'form1add' ||
      this.eventForm2 == 'form2add'
    ) {
      item.status = false;
      item.message =
        'Please press the save button in Strategic Fit every time after adding or editing an item ';
    } else if (
      !this.form3.valid ||
      !this.form4.valid ||
      this.eventForm3 == 'form3add' ||
      this.eventForm4 == 'form4add'
    ) {
      item.status = false;
      item.message =
        'Please press the save button in Execution Perspective every time after adding or editing an item ';
    }
    return item;
  }
  get fb1() {
    return this.form1.value;
  }
  get fb2() {
    return this.form2.value;
  }
  get fb3() {
    return this.form3.value;
  }
  get fb4() {
    return this.form4.value;
  }
}
