import { Component, OnInit } from "@angular/core";
import { Masterserve } from '../../../services/master.service';
declare let $;

import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AuthenService } from "src/app/services/authenservice.service";

@Component({
    selector: 'app-ConfigAssetUnit',
    templateUrl: './ConfigAssetUnit.html',
    styleUrls: ['./ConfigAssetUnit.css'],
})
export class ConfigAssetUnit implements OnInit {
    Data_SessionStorage;

    ReplaceEmp;
    tempReplace;
    loading = false;
    RegisAssetUnit;
    status;
    ParentOrgLevelDD;
    tableAssetUnit = [];
    selorgname;
    selEmp;
    StatusDD = [];
    AddCo_QuarterDD = [];
    YearDD = [];
    QuarterDD = [];
    search;

    filteredOptionsOrgNameDD: Observable<string[]>;
    myControlOrgNameDD = new UntypedFormControl();
    OrgNameDD = [];

    filteredOptionsEmployeeIDDD: Observable<string[]>;
    myControlEmployeeID = new UntypedFormControl();
    EmployeeDD = [];

    myControlEmployeeReplace = new UntypedFormControl();
    EmployeeDD_autoReplace = [];
    filteredOptionsEmployeeIDDDReplace: Observable<string[]>;

    TempdataEmployee = [];
    DataFG = [];
    OptimizedData = [];
    TempdataAPICR = [];
    TempSelectFG = [];
    TempindexSelectFG = null;

    currentyear;
    tempchkyear;

    constructor(public authserviceToken: AuthenService, public mserve: Masterserve) { }
    ngOnInit(): void {
        const _s = this;

        this.Data_SessionStorage = JSON.parse(sessionStorage.datainfo);

        let date = new Date();
        _s.currentyear = date.getFullYear();
        _s.tempchkyear = date.getFullYear();

        this.RegisAssetUnit = {
            RegisAsset_Id: '',
            RegisAssetName: '',
            RegisAssetinitialsName: '',
            RegisParentOrg: '',
            RegisOrg: '',
            RegisStatus: '',
            RegisEmpID: '',
            RegisEmpName: '',
            RegisEmpEmail: '',
            RegisEmpBU: '',
            RegisEmpLevel: '',
            RegisDepID: '',
            RegisYear: '',
            RegisQuarter: '',
            RegisassetCode: '',
            ShowassesCode: ''
        }

        _s.ReplaceEmp = {
            EmpID: '',
            EmpName: '',
            EmpEmail: '',
            EmpBU: '',
            Emp_CoordinatorId: ''
        }


        this.search = {
            Quarter: "",
            Year: ""
        }

        this.StatusDD = [
            { value: '0', text: 'Active' },
            { value: '1', text: 'Inactive' }
        ]

        this.loadDDL();
        this.func_GetDDL_BusinessName();

    }


    func_GetDDL_BusinessName() {
        let formData = {
            Module: 'DepartmentDD'
        }
        this.mserve.Master_DDL(formData).then((response) => {
            if (response.data.status) {
                //console.log("OrgNameDD :", response.data.body)
                this.OrgNameDD = response.data.body
                this.filteredOptionsOrgNameDD = this.myControlOrgNameDD.valueChanges.pipe(
                    startWith(''),
                    map(value => this._filterOrgNameDD(value))
                );
            } else {
                //console.log('false', response)
            }
        });
    }

    onParentChange(inputValue) {
        this.filteredOptionsOrgNameDD = this.myControlOrgNameDD.valueChanges.pipe(
            startWith(''),
            map(value => this._filterOrgNameByParent(inputValue, value))
        ); 
    }

    func_GetData() {
        let formData = {
            SessionEmpID: this.Data_SessionStorage[0].employeE_ID,
            Module: "getlist",
            body: {
                Asset_Id: "",
                Asset_Name: "",
                Asset_Short: "",
                Asset_Code: "",
                Asset_Coordinators: "",
                Asset_Level: "",
                Asset_Org: "",
                ActveDate: ""
            }
        }
        this.mserve.GetAsset(formData).then((response) => {
            if (response.data.status) {
                //console.log("Get Data Config Asset : ", response.data.body)
                this.tableAssetUnit = response.data.body;
            } else {
                //console.log('false', response)
                alert(response.data.errorMessage);
            }
        }).catch((e) => {
            if (e.response) {
                this.authserviceToken.CheckTokenExpire(e.response.status)
            } else {
                //console.log(e)
            }
        });
    }

    loadDDL() {
        const _s = this;
        this.mserve.Master_DDL({ Module: "EmployeeDD" }).then((response) => {
            this.EmployeeDD = response.data.body
            //console.log('EmployeeDD : ', this.EmployeeDD)
            this.filteredOptionsEmployeeIDDD = this.myControlEmployeeID.valueChanges.pipe(
                startWith(''),
                map(value => this._filterEmployeeIDDD(value))
            );
        });

        this.mserve.Master_DDL({ Module: "CoorLevel" }).then((response) => {
            this.ParentOrgLevelDD = response.data.body
            //console.log('CoorLevel : ', this.ParentOrgLevelDD)
        });

        this.mserve.Master_DDL({ Module: "YearDD" }).then((response) => {
            this.YearDD = response.data.body
            //console.log("YearDD : ", this.YearDD)
        });

        let d = new Date();
        _s.search.Year = d.getFullYear();
        let currentMonth = d.getMonth() + 1;
        _s.mserve.Master_DDL({ Module: "QuarterDD", TextSearch1: _s.search.Year }).then((response) => {
            _s.QuarterDD = response.data.body;
            _s.AddCo_QuarterDD = response.data.body;
            //console.log("QuarterDD : ", _s.QuarterDD)
            this.func_calQuarter(currentMonth);
        });
    }

    func_calQuarter(month) {

        const _s = this;
        _s.search.Quarter = ''
        if (month >= 1 && month <= 3) {
            _s.search.Quarter = this.QuarterDD[0].value;
        } else if (month >= 4 && month <= 6) {
            _s.search.Quarter = this.QuarterDD[1].value;
        } else if (month >= 7 && month <= 9) {
            _s.search.Quarter = this.QuarterDD[2].value;
        } else if (month >= 10 && month <= 12) {
            _s.search.Quarter = this.QuarterDD[3].value;
        }
        _s.GetDataFG('1');
    }

    SelectedSearchYear(value) {

        let _s = this;
        _s.search.Quarter = "";
        _s.mserve.Master_DDL({ Module: "QuarterDD", TextSearch1: _s.search.Year }).then((response) => {
            _s.QuarterDD = response.data.body
            //console.log("QuarterDD : ", _s.QuarterDD)
        });
    }

    SelectedYear(value) {

        let _s = this;
        if (value === '') {
            _s.RegisAssetUnit.RegisQuarter = '';
        }
        _s.mserve.Master_DDL({ Module: "QuarterDD", TextSearch1: _s.RegisAssetUnit.RegisYear }).then((response) => {
            _s.AddCo_QuarterDD = response.data.body
            //console.log("AddCo_QuarterDD : ", _s.AddCo_QuarterDD)
            if (value !== '') {
                for (let item_AddCo_QuarterDD of _s.AddCo_QuarterDD) {
                    if (value === item_AddCo_QuarterDD.value) {
                        _s.RegisAssetUnit.RegisQuarter = item_AddCo_QuarterDD.value
                    }
                }
            }
        })
    }

    func_btnAddAssetUnit() {

        this.status = 'insert';
        this.selEmp = '';
        this.selorgname = '';
        this.RegisAssetUnit.RegisAsset_Id = '';
        this.RegisAssetUnit.RegisAssetName = '';
        this.RegisAssetUnit.RegisAssetinitialsName = '';
        this.RegisAssetUnit.RegisParentOrg = '';
        this.RegisAssetUnit.RegisOrg = '';
        this.RegisAssetUnit.RegisStatus = '';
        this.RegisAssetUnit.RegisEmpID = '';
        this.RegisAssetUnit.RegisEmpName = '';
        this.RegisAssetUnit.RegisEmpBU = '';
        this.RegisAssetUnit.RegisEmpEmail = '';
        this.RegisAssetUnit.RegisEmpLevel = '';
        this.RegisAssetUnit.RegisDepID = '';
        this.RegisAssetUnit.RegisYear = this.currentyear;
        this.RegisAssetUnit.RegisQuarter = this.search.Quarter;
        this.RegisAssetUnit.RegisassetCode = '';
        this.RegisAssetUnit.CoordinatorId = '';
        this.RegisAssetUnit.ShowassesCode = '';
        this.TempdataEmployee = [];
        $('#modaladdAssetUnit').modal('show');
    }

    func_edit(item) {

        const _s = this;
        this.status = 'edit';

        this.RegisAssetUnit.RegisAssetName = item.asset_Name;
        this.RegisAssetUnit.RegisAssetinitialsName = item.asset_Short;
        this.RegisAssetUnit.RegisParentOrg = item.asset_Level;
        this.RegisAssetUnit.ShowassesCode = item.asset_Code;
        this.RegisAssetUnit.RegisStatus = item.delFlag;

        this.RegisAssetUnit.RegisYear = (item.quarterYear === null) ? '' : _s.currentyear
        if (item.quarterYear !== null && item.quarterYear !== '') {
            _s.RegisAssetUnit.RegisQuarter = ''
            _s.SelectedYear(item.quarterID);
        }

        this.selorgname = '';
        this.selorgname = item.Parent;
        this.selectOrg();

        if(this.RegisAssetUnit.RegisassetCode != '') {
            this.TempdataEmployee = [];
            for (let item_CO of item.CO) {
                _s.RegisAssetUnit.CoordinatorId = item_CO.coid
                this.selectEmp(item_CO.coname);
            }
            $('#modaladdAssetUnit').modal('show');
        }
        else{
            alert('[Asset_Org] is empty.')
        }
    }

    func_Close() {
        const _s = this;
        $('#modaladdAssetUnit').modal('hide');
        _s.GetDataFG('2');
    }

    func_save(value) {

        const _s = this;

        if (_s.TempdataEmployee.length !== 0) {
            _s.TempdataEmployee.forEach(function (ItemVal, ItemIndex) {
                let formData = {
                    SessionEmpID: _s.Data_SessionStorage[0].employeE_ID,
                    Module: '',
                    body: []
                }

                if (ItemVal.Emp_CoordinatorId === '') {
                    formData.Module = 'insert'
                } else {
                    formData.Module = 'update'
                }

                formData.body.push({
                    ActveDate: "",
                    Asset_Name: _s.RegisAssetUnit.RegisAssetName,
                    Asset_Short: _s.RegisAssetUnit.RegisAssetinitialsName,
                    Asset_Level: _s.RegisAssetUnit.RegisParentOrg,
                    Asset_Code: _s.RegisAssetUnit.ShowassesCode,
                    Asset_Org: _s.RegisAssetUnit.RegisassetCode,
                    QuarterYear: _s.RegisAssetUnit.RegisYear,
                    QuarterID: _s.RegisAssetUnit.RegisQuarter,
                    DelFlag: _s.RegisAssetUnit.RegisStatus,
                    Asset_Id: ItemVal.Emp_CoordinatorId,
                    Asset_Coordinators: ItemVal.Emp_id,
                })

                //console.log('formData:', formData)
                _s.func_api(formData)
            });
        }
        else {
            alert('Please add at least 1 Coodinator')
        }

    }

    func_api(formData) {
        const _s = this;
        this.mserve.GetAsset(formData).then((response) => {
            if (response.data.status) {

                let formdataReplace = {
                    "SessionEmpID": _s.Data_SessionStorage[0].employeE_ID,
                    "SecurityCode": "1234",
                    "Module": "ReplaceCordinator",
                    "body": {
                        "Risk_Business_Unit": _s.RegisAssetUnit.ShowassesCode,
                        "Co_Old": "",
                        "Co_New": "",
                        "Type_Co": "Asset"
                    }
                }
                //console.log(JSON.stringify(formdataReplace))
                _s.mserve.ReplaceCordinator(formdataReplace).then((response) => {
                    if (response.data.status) {
                        this.RegisAssetUnit.RegisStatus = '';
                        $('#modaladdAssetUnit').modal('hide');
                        this.GetDataFG('2');
                    }
                    else {
                        //console.log('false', response)
                        alert(response.data.errorMessage);
                    }
                });
            } else {
                //console.log('false', response)
            }
        });
    }

    selectOrg() {
        this.RegisAssetUnit.RegisassetCode = '';
        for (let item_OrgNameDD of this.OrgNameDD) {
            if (this.selorgname === item_OrgNameDD.text) {
                this.RegisAssetUnit.RegisassetCode = item_OrgNameDD.value
            }
        }
    }

    selectEmp(data) {

        let _s = this;
        let tempid
        for (let item_EmployeeDD of _s.EmployeeDD) {
            if (data === item_EmployeeDD.text) {
                tempid = item_EmployeeDD.value
                break;
            }
        }

        if (data != '0') {
            let found = _s.EmployeeDD.find(x => x.value === tempid);
            _s.RegisAssetUnit.RegisEmpName = found.text
            _s.RegisAssetUnit.RegisEmpID = found.value
            _s.RegisAssetUnit.RegisEmpLevel = found.text4
            _s.RegisAssetUnit.RegisEmpEmail = found.text3
            _s.RegisAssetUnit.RegisEmpBU = found.text2
            _s.RegisAssetUnit.RegisOrg = found.text1
        } else {
            _s.RegisAssetUnit.RegisEmpName = ""
            _s.RegisAssetUnit.RegisEmpID = ""
            _s.RegisAssetUnit.RegisEmpLevel = ""
            _s.RegisAssetUnit.RegisEmpEmail = ""
            _s.RegisAssetUnit.RegisEmpBU = ""
            _s.RegisAssetUnit.RegisOrg = ""
            _s.RegisAssetUnit.CoordinatorId = ""
        }

        if (_s.RegisAssetUnit.RegisEmpName !== null && _s.RegisAssetUnit.RegisEmpName !== '') {
            this.TempdataEmployee.push({
                Emp_id: _s.RegisAssetUnit.RegisEmpID,
                Emp_name: _s.RegisAssetUnit.RegisEmpName,
                Emp_email: _s.RegisAssetUnit.RegisEmpEmail,
                Emp_bu: _s.RegisAssetUnit.RegisEmpBU,
                Emp_buID: _s.RegisAssetUnit.RegisOrg,
                Emp_CoordinatorId: _s.RegisAssetUnit.CoordinatorId

            });
            _s.RegisAssetUnit.RegisEmpID = '';
            _s.RegisAssetUnit.RegisEmpName = '';
            _s.RegisAssetUnit.RegisEmpEmail = '';
            _s.RegisAssetUnit.RegisEmpBU = '';
            _s.RegisAssetUnit.RegisOrg = '';
            _s.RegisAssetUnit.CoordinatorId = '';
        }

    }

    func_addEmp() {

        const _s = this;
        if (_s.RegisAssetUnit.RegisEmpName !== null && _s.RegisAssetUnit.RegisEmpName !== '') {
            this.TempdataEmployee.push({
                Emp_id: _s.RegisAssetUnit.RegisEmpID,
                Emp_name: _s.RegisAssetUnit.RegisEmpName,
                Emp_email: _s.RegisAssetUnit.RegisEmpEmail,
                Emp_bu: _s.RegisAssetUnit.RegisEmpBU,
                Emp_buID: _s.RegisAssetUnit.RegisOrg,
                Emp_CoordinatorId: _s.RegisAssetUnit.CoordinatorId

            });
            _s.RegisAssetUnit.RegisEmpID = '';
            _s.RegisAssetUnit.RegisEmpName = '';
            _s.RegisAssetUnit.RegisEmpEmail = '';
            _s.RegisAssetUnit.RegisEmpBU = '';
            _s.RegisAssetUnit.RegisOrg = '';
            _s.RegisAssetUnit.CoordinatorId = '';
        }

    }

    GetDataFG(chk) {

        const _s = this;
        this.loading = true;

        if (chk == 1) {
            this.TempSelectFG = [];
        }
        let formdata = {
            SessionEmpID: this.Data_SessionStorage[0].employeE_ID,
            SecurityCode: "1234",
            Email: this.Data_SessionStorage[0].emaiL_ID,
            body: {
                QuarterID: _s.search.Quarter
            }
        }
        _s.mserve.Get_Menu_BU_Config(formdata).then((response) => {
            if (response.data.status) {
                //console.log('DATA GET : ', response.data.body)
                this.TempdataAPICR = response.data.body;
                this.tempchkyear = this.search.Year;
                this.loading = false;
                this.GetHeaderBU();
            }
            else {
                alert(response.data.errorMessage);
                //console.log('false', response)
            }
        }).catch((e) => {
            if (e.response) {
                this.authserviceToken.CheckTokenExpire(e.response.status)
            } else {
                //console.log(e)
            }
        });
    }

    GetHeaderBU() {

        const _s = this;
        _s.DataFG = [];


        if (_s.TempdataAPICR[0].division_Level !== null) {
            _s.TempdataAPICR[0].division_Level.forEach(function (ItemVal, ItemIndex) {
                ItemVal.org_Menu.forEach(function (ItemVal2, ItemIndex2) {
                    _s.DataFG.push({
                        title: ItemVal2.abbreviation,
                        value: ItemVal2.organization_Code,
                        data: ItemVal2
                    })
                });
            });
        }

        if (_s.TempdataAPICR[0].groupDivision_Level !== null) {
            _s.TempdataAPICR[0].groupDivision_Level.forEach(function (ItemVal, ItemIndex) {
                ItemVal.org_Menu.forEach(function (ItemVal2, ItemIndex2) {
                    _s.DataFG.push({
                        title: ItemVal2.abbreviation,
                        value: ItemVal2.organization_Code,
                        data: ItemVal2
                    })
                });
            });
        }


        //console.log('DataFG : ', _s.DataFG);
        if (this.TempSelectFG.length === 0) {
            _s.SelectFG(_s.DataFG[0], '')
        } else {
            _s.SelectFG(_s.TempSelectFG[0], (this.TempindexSelectFG - 1))
        }
    }
    SelectFG (item , indexsel) {
        
        const _s = this;

        _s.OptimizedData = [];
        this.TempSelectFG = [];
        this.TempSelectFG.push({ title :item.title, value :item.value });

        _s.DataFG.forEach(function(ItemVal, ItemIndex) {
            if (item.value === ItemVal.value) {

                if (ItemVal.data.organization_Level === 'Division') {
                    _s.OptimizedData.push({
                        DIV: ItemVal.data.abbreviation,
                        CO: []
                    });
                    ItemVal.data.coInfo.forEach(function(ItemValco, ItemIndexco) {
                        _s.OptimizedData[_s.OptimizedData.length -1].CO.push({
                            coname: ItemValco.coordinator_EName,
                            coid: ItemValco.coordinatorId,
                        });
                    });

                    // Asset level1
                    if (ItemVal.data.asset_Level.length !== 0) {
                        ItemVal.data.asset_Level.forEach(function(ItemValass, ItemIndexass) {
                            _s.OptimizedData.push({
                                Parent:ItemVal.data.abbreviation,
                                Asses: ItemValass.abbreviation,
                                CO: []
                            });
                            ItemValass.coInfo.forEach(function(ItemValcoass, ItemIndexcoass) {
                                _s.OptimizedData[_s.OptimizedData.length -1].CO.push({
                                    coname: ItemValcoass.asset_Coordinators_EName,
                                    coid: ItemValcoass.asset_Id,
                                });
                                if (ItemIndexcoass === 0) {
                      
                                    _s.OptimizedData[_s.OptimizedData.length -1].asset_Name = ItemValcoass.asset_Name;
                                    _s.OptimizedData[_s.OptimizedData.length -1].asset_Short =  ItemValcoass.asset_Short;
                                    _s.OptimizedData[_s.OptimizedData.length -1].asset_Level = ItemValcoass.asset_Level;
                                    _s.OptimizedData[_s.OptimizedData.length -1].asset_Code = ItemValcoass.asset_Code;
                                    _s.OptimizedData[_s.OptimizedData.length -1].quarterID = ItemValcoass.quarterID;
                                    _s.OptimizedData[_s.OptimizedData.length -1].quarterYear = ItemValcoass.quarterYear;
                                    _s.OptimizedData[_s.OptimizedData.length -1].delFlag = ItemValcoass.delFlag;
                                }
                            });
                        });
                    }


                    if (ItemVal.data.department_Level.length !== 0) {
                        ItemVal.data.department_Level.forEach(function(ItemVal1, ItemIndex1) {
                            _s.OptimizedData.push({
                                DEPT: ItemVal1.abbreviation,
                                CO: []
                            });
                            ItemVal1.coInfo.forEach(function(ItemValco1, ItemIndexco1) {
                                _s.OptimizedData[_s.OptimizedData.length -1].CO.push({
                                    coname: ItemValco1.coordinator_EName,
                                    coid: ItemValco1.coordinatorId,
                                });
                            });

                            // Asset level2
                            if (ItemVal1.asset_Level.length !== 0) {
                                ItemVal1.asset_Level.forEach(function(ItemValass1, ItemIndexass1) {
                                    _s.OptimizedData.push({
                                        Parent:ItemVal1.abbreviation,
                                        Asses: ItemValass1.abbreviation,
                                        CO: []
                                    });
                                    ItemValass1.coInfo.forEach(function(ItemValcoass1, ItemIndexcoass1) {
                                        _s.OptimizedData[_s.OptimizedData.length -1].CO.push({
                                            coname: ItemValcoass1.asset_Coordinators_EName,
                                            coid: ItemValcoass1.asset_Id,
                                        });
                                        if (ItemIndexcoass1 === 0) {
                                           
                                            _s.OptimizedData[_s.OptimizedData.length -1].asset_Name = ItemValcoass1.asset_Name;
                                            _s.OptimizedData[_s.OptimizedData.length -1].asset_Short =  ItemValcoass1.asset_Short;
                                            _s.OptimizedData[_s.OptimizedData.length -1].asset_Level = ItemValcoass1.asset_Level;
                                            _s.OptimizedData[_s.OptimizedData.length -1].asset_Code = ItemValcoass1.asset_Code;
                                            _s.OptimizedData[_s.OptimizedData.length -1].quarterID = ItemValcoass1.quarterID;
                                            _s.OptimizedData[_s.OptimizedData.length -1].quarterYear = ItemValcoass1.quarterYear;
                                            _s.OptimizedData[_s.OptimizedData.length -1].delFlag = ItemValcoass1.delFlag;
                                        }
                                    });
                                });
                            }

                        });                        
                    }

                }

                if (ItemVal.data.organization_Level === 'Group') {
                    _s.OptimizedData.push({
                        FG: ItemVal.data.abbreviation,
                        CO: []
                    });
                    ItemVal.data.coInfo.forEach(function(ItemValco, ItemIndexco) {
                        _s.OptimizedData[_s.OptimizedData.length -1].CO.push({
                            coname: ItemValco.coordinator_EName,
                            coid: ItemValco.coordinatorId,
                        });
                    });

                    // Asset level1
                    if (ItemVal.data.asset_Level.length !== 0) {
                        ItemVal.data.asset_Level.forEach(function(ItemValass, ItemIndexass) {
                            _s.OptimizedData.push({
                                Parent:ItemVal.data.abbreviation,
                                Asses: ItemValass.abbreviation,
                                CO: []
                            });
                            ItemValass.coInfo.forEach(function(ItemValcoass, ItemIndexcoass) {
                                _s.OptimizedData[_s.OptimizedData.length -1].CO.push({
                                    coname: ItemValcoass.asset_Coordinators_EName,
                                    coid: ItemValcoass.asset_Id,
                                });
                                if (ItemIndexcoass === 0) {
            
                                    _s.OptimizedData[_s.OptimizedData.length -1].asset_Name = ItemValcoass.asset_Name;
                                    _s.OptimizedData[_s.OptimizedData.length -1].asset_Short =  ItemValcoass.asset_Short;
                                    _s.OptimizedData[_s.OptimizedData.length -1].asset_Level = ItemValcoass.asset_Level;
                                    _s.OptimizedData[_s.OptimizedData.length -1].asset_Code = ItemValcoass.asset_Code;
                                    _s.OptimizedData[_s.OptimizedData.length -1].quarterID = ItemValcoass.quarterID;
                                    _s.OptimizedData[_s.OptimizedData.length -1].quarterYear = ItemValcoass.quarterYear;
                                    _s.OptimizedData[_s.OptimizedData.length -1].delFlag = ItemValcoass.delFlag;
                                }
                            });
                        });
                    }


                    if (ItemVal.data.division_Level.length !== 0) {
                        ItemVal.data.division_Level.forEach(function(ItemVal1, ItemIndex1) {
                            if (ItemVal1.organization_Level == "Division") {
                                _s.OptimizedData.push({
                                    DIV: ItemVal1.abbreviation,
                                    CO: []
                                });
                                ItemVal1.coInfo.forEach(function(ItemValco1, ItemIndexco1) {
                                    _s.OptimizedData[_s.OptimizedData.length -1].CO.push({
                                        coname: ItemValco1.coordinator_EName,
                                        coid: ItemValco1.coordinatorId,
                                    });
                                });

                                // Asset level2
                                if (ItemVal1.asset_Level.length !== 0) {
                                    ItemVal1.asset_Level.forEach(function(ItemValass1, ItemIndexass1) {
                                        _s.OptimizedData.push({
                                            Parent:ItemVal1.abbreviation,
                                            Asses: ItemValass1.abbreviation,
                                            CO: []
                                        });
                                        ItemValass1.coInfo.forEach(function(ItemValcoass1, ItemIndexcoass1) {
                                            _s.OptimizedData[_s.OptimizedData.length -1].CO.push({
                                                coname: ItemValcoass1.asset_Coordinators_EName,
                                                coid: ItemValcoass1.asset_Id,
                                            });
                                            if (ItemIndexcoass1 === 0) {
                                            
                                                _s.OptimizedData[_s.OptimizedData.length -1].asset_Name = ItemValcoass1.asset_Name;
                                                _s.OptimizedData[_s.OptimizedData.length -1].asset_Short =  ItemValcoass1.asset_Short;
                                                _s.OptimizedData[_s.OptimizedData.length -1].asset_Level = ItemValcoass1.asset_Level;
                                                _s.OptimizedData[_s.OptimizedData.length -1].asset_Code = ItemValcoass1.asset_Code;
                                                _s.OptimizedData[_s.OptimizedData.length -1].quarterID = ItemValcoass1.quarterID;
                                                _s.OptimizedData[_s.OptimizedData.length -1].quarterYear = ItemValcoass1.quarterYear;
                                                _s.OptimizedData[_s.OptimizedData.length -1].delFlag = ItemValcoass1.delFlag;
                                            }
                                        });
                                    });
                                }


                                if (ItemVal1.department_Level.length !== 0) {
                                    ItemVal1.department_Level.forEach(function(ItemVal2, ItemIndex2) {
                                        _s.OptimizedData.push({
                                            DEPT: ItemVal2.abbreviation,
                                            CO: []
                                        });
                                        ItemVal2.coInfo.forEach(function(ItemValco2, ItemIndexco2) {
                                            _s.OptimizedData[_s.OptimizedData.length -1].CO.push({
                                                coname: ItemValco2.coordinator_EName,
                                                coid: ItemValco2.coordinatorId,
                                            });
                                        });

                                        // Asset level3
                                        if (ItemVal2.asset_Level.length !== 0) {
                                            ItemVal2.asset_Level.forEach(function(ItemValass2, ItemIndexass2) {
                                                _s.OptimizedData.push({
                                                    Parent:ItemVal2.abbreviation,
                                                    Asses: ItemValass2.abbreviation,
                                                    CO: []
                                                });
                                                ItemValass2.coInfo.forEach(function(ItemValcoass2, ItemIndexcoass2) {
                                                    _s.OptimizedData[_s.OptimizedData.length -1].CO.push({
                                                        coname: ItemValcoass2.asset_Coordinators_EName,
                                                        coid: ItemValcoass2.asset_Id,
                                                    });
                                                    if (ItemIndexcoass2 === 0) {
                                                   
                                                        _s.OptimizedData[_s.OptimizedData.length -1].asset_Name = ItemValcoass2.asset_Name;
                                                        _s.OptimizedData[_s.OptimizedData.length -1].asset_Short =  ItemValcoass2.asset_Short;
                                                        _s.OptimizedData[_s.OptimizedData.length -1].asset_Level = ItemValcoass2.asset_Level;
                                                        _s.OptimizedData[_s.OptimizedData.length -1].asset_Code = ItemValcoass2.asset_Code;
                                                        _s.OptimizedData[_s.OptimizedData.length -1].quarterID = ItemValcoass2.quarterID;
                                                        _s.OptimizedData[_s.OptimizedData.length -1].quarterYear = ItemValcoass2.quarterYear;
                                                        _s.OptimizedData[_s.OptimizedData.length -1].delFlag = ItemValcoass2.delFlag;
                                                    }
                                                });
                                            });
                                        }

                                    });   
                                }
                            }
                            if (ItemVal1.organization_Level == "Department") {
                                _s.OptimizedData.push({
                                    DEPT: ItemVal1.abbreviation,
                                    CO: []
                                });
                                ItemVal1.coInfo.forEach(function(ItemValco1, ItemIndexco1) {
                                    _s.OptimizedData[_s.OptimizedData.length -1].CO.push({
                                        coname: ItemValco1.coordinator_EName,
                                        coid: ItemValco1.coordinatorId,
                                    });
                                });

                                // Asset level2
                                if (ItemVal1.asset_Level.length !== 0) {
                                    ItemVal1.asset_Level.forEach(function(ItemValass1, ItemIndexass1) {
                                        _s.OptimizedData.push({
                                            Parent:ItemVal1.abbreviation,
                                            Asses: ItemValass1.abbreviation,
                                            CO: []
                                        });
                                        ItemValass1.coInfo.forEach(function(ItemValcoass1, ItemIndexcoass1) {
                                            _s.OptimizedData[_s.OptimizedData.length -1].CO.push({
                                                coname: ItemValcoass1.asset_Coordinators_EName,
                                                coid: ItemValcoass1.asset_Id,
                                            });
                                            if (ItemIndexcoass1 === 0) {
                                          
                                                _s.OptimizedData[_s.OptimizedData.length -1].asset_Name = ItemValcoass1.asset_Name;
                                                _s.OptimizedData[_s.OptimizedData.length -1].asset_Short =  ItemValcoass1.asset_Short;
                                                _s.OptimizedData[_s.OptimizedData.length -1].asset_Level = ItemValcoass1.asset_Level;
                                                _s.OptimizedData[_s.OptimizedData.length -1].asset_Code = ItemValcoass1.asset_Code;
                                                _s.OptimizedData[_s.OptimizedData.length -1].quarterID = ItemValcoass1.quarterID;
                                                _s.OptimizedData[_s.OptimizedData.length -1].quarterYear = ItemValcoass1.quarterYear;
                                                _s.OptimizedData[_s.OptimizedData.length -1].delFlag = ItemValcoass1.delFlag;
                                            }
                                        });
                                    });
                                }


                                if (ItemVal1.department_Level.length !== 0) {
                                    ItemVal1.department_Level.forEach(function(ItemVal2, ItemIndex2) {
                                        _s.OptimizedData.push({
                                            DEPT: ItemVal2.abbreviation,
                                            CO: []
                                        });
                                        ItemVal2.coInfo.forEach(function(ItemValco2, ItemIndexco2) {
                                            _s.OptimizedData[_s.OptimizedData.length -1].CO.push({
                                                coname: ItemValco2.coordinator_EName,
                                                coid: ItemValco2.coordinatorId,
                                            });
                                        });

                                        // Asset level3
                                        if (ItemVal2.asset_Level.length !== 0) {
                                            ItemVal2.asset_Level.forEach(function(ItemValass2, ItemIndexass2) {
                                                _s.OptimizedData.push({
                                                    Parent:ItemVal2.abbreviation,
                                                    Asses: ItemValass2.abbreviation,
                                                    CO: []
                                                });
                                                ItemValass2.coInfo.forEach(function(ItemValcoass2, ItemIndexcoass2) {
                                                    _s.OptimizedData[_s.OptimizedData.length -1].CO.push({
                                                        coname: ItemValcoass2.asset_Coordinators_EName,
                                                        coid: ItemValcoass2.asset_Id,
                                                    });
                                                    if (ItemIndexcoass2 === 0) {
                                                      
                                                        _s.OptimizedData[_s.OptimizedData.length -1].asset_Name = ItemValcoass2.asset_Name;
                                                        _s.OptimizedData[_s.OptimizedData.length -1].asset_Short =  ItemValcoass2.asset_Short;
                                                        _s.OptimizedData[_s.OptimizedData.length -1].asset_Level = ItemValcoass2.asset_Level;
                                                        _s.OptimizedData[_s.OptimizedData.length -1].asset_Code = ItemValcoass2.asset_Code;
                                                        _s.OptimizedData[_s.OptimizedData.length -1].quarterID = ItemValcoass2.quarterID;
                                                        _s.OptimizedData[_s.OptimizedData.length -1].quarterYear = ItemValcoass2.quarterYear;
                                                        _s.OptimizedData[_s.OptimizedData.length -1].delFlag = ItemValcoass2.delFlag;
                                                    }
                                                });
                                            });
                                        }

                                    });   
                                }
                            }
                        });                        
                    }

                }
            }
        });

        //console.log('OptimizedData' , _s.OptimizedData);

        $(document).ready(function() { 
            let e1 = document.getElementById("BUCO1" );
            e1.classList.add("active");
             if (_s.TempindexSelectFG !== null) {
                e1.classList.remove("active");
                let e2 = document.getElementById("BUCO" + _s.TempindexSelectFG );
                e2.classList.add("active");
            }
        });
        this.TempindexSelectFG = null;
        this.TempindexSelectFG = (indexsel + 1);
    }




    func_selectReplaceEmp(item) {

        const _s = this;
        _s.EmployeeDD_autoReplace.forEach(function (value, index) {
            if (value.Emp_name == item) {
                _s.ReplaceEmp.EmpID = value.Emp_id
                _s.ReplaceEmp.EmpName = value.Emp_name
                _s.ReplaceEmp.EmpEmail = value.Emp_email
                _s.ReplaceEmp.EmpBU = value.Emp_bu
                _s.ReplaceEmp.Emp_CoordinatorId = value.Emp_CoordinatorId
            }
        })
        //console.log('ReplaceEmp ', _s.ReplaceEmp)
    }
    Remove_emp(item) {

        const _s = this;
        this.EmployeeDD_autoReplace = [];
        for (let item_EmployeeDD of _s.EmployeeDD) {
            if (item_EmployeeDD.text != item.Emp_name) {
                _s.ReplaceEmp = {
                    Emp_id: item_EmployeeDD.value,
                    Emp_name: item_EmployeeDD.text,
                    Emp_email: item_EmployeeDD.text3,
                    Emp_bu: item_EmployeeDD.text2,
                    Emp_buID: item_EmployeeDD.text1,
                    Emp_CoordinatorId: ''
                }
                this.EmployeeDD_autoReplace.push(_s.ReplaceEmp)
            }
        }

        _s.ReplaceEmp.EmpID = ''
        _s.ReplaceEmp.EmpName = ''
        _s.ReplaceEmp.EmpEmail = ''
        _s.ReplaceEmp.EmpBU = ''
        _s.ReplaceEmp.Emp_CoordinatorId = ''

        if (_s.EmployeeDD_autoReplace.length == 1) {
            _s.ReplaceEmp.EmpID = _s.EmployeeDD_autoReplace[0].Emp_id
            _s.ReplaceEmp.EmpName = _s.EmployeeDD_autoReplace[0].Emp_name
            _s.ReplaceEmp.EmpEmail = _s.EmployeeDD_autoReplace[0].Emp_email
            _s.ReplaceEmp.EmpBU = _s.EmployeeDD_autoReplace[0].Emp_bu
            _s.ReplaceEmp.Emp_CoordinatorId = _s.EmployeeDD_autoReplace[0].Emp_CoordinatorId
        }

        //console.log('this.EmployeeDD_autoReplace ', this.EmployeeDD_autoReplace)
        this.filteredOptionsEmployeeIDDDReplace = this.myControlEmployeeReplace.valueChanges.pipe(
            startWith(''),
            map(value => this._filterEmployeeIDDDReplace(value))
        );

        $('#modaladdAssetUnit').modal('hide');
        setTimeout(function () { $('#modalDeleteCoAsset').modal('show'); }, 300);

        this.tempReplace = item

    }

    confirm_replace (){
        const _s = this;
        var item = this.tempReplace 

        if(_s.ReplaceEmp){
            let found = _s.EmployeeDD.find(x => x.value === _s.ReplaceEmp.EmpID);
            let isExisting = this.TempdataEmployee.find(x => x.Emp_id === found.value);

            if(!isExisting){
                _s.RegisAssetUnit.RegisEmpName = found.text
                _s.RegisAssetUnit.RegisEmpID = found.value
                _s.RegisAssetUnit.RegisEmpLevel = found.text4
                _s.RegisAssetUnit.RegisEmpEmail = found.text3
                _s.RegisAssetUnit.RegisEmpBU = found.text2
                _s.RegisAssetUnit.RegisOrg = found.text1
            }
        }else{
            _s.RegisAssetUnit.RegisEmpName = ""
            _s.RegisAssetUnit.RegisEmpID = ""
            _s.RegisAssetUnit.RegisEmpLevel = ""
            _s.RegisAssetUnit.RegisEmpEmail = ""
            _s.RegisAssetUnit.RegisEmpBU = ""
            _s.RegisAssetUnit.RegisOrg = ""
            _s.RegisAssetUnit.CoordinatorId = ""
        }

        if (_s.RegisAssetUnit.RegisEmpName !== null && _s.RegisAssetUnit.RegisEmpName !== '') {
            this.TempdataEmployee.push({
                Emp_id : _s.RegisAssetUnit.RegisEmpID,
                Emp_name : _s.RegisAssetUnit.RegisEmpName,
                Emp_email : _s.RegisAssetUnit.RegisEmpEmail,
                Emp_bu : _s.RegisAssetUnit.RegisEmpBU,
                Emp_buID : _s.RegisAssetUnit.RegisOrg,
                Emp_CoordinatorId : _s.RegisAssetUnit.CoordinatorId

            });
            _s.RegisAssetUnit.RegisEmpID = '';
            _s.RegisAssetUnit.RegisEmpName = '';
            _s.RegisAssetUnit.RegisEmpEmail = '';
            _s.RegisAssetUnit.RegisEmpBU = '';
            _s.RegisAssetUnit.RegisOrg = '';
            _s.RegisAssetUnit.CoordinatorId = '';
        }

        var formdataReplace = {
            "SessionEmpID" : _s.Data_SessionStorage[0].employeE_ID,
            "SecurityCode" : "1234",
            "Module" : "ReplaceCordinator",
            "body" : {
                "Risk_Business_Unit" : _s.RegisAssetUnit.ShowassesCode,
                "Co_Old" : item.Emp_id,
                "Co_New" : _s.ReplaceEmp.EmpID,
                "Type_Co" : "Asset"  
            }
        }
        //console.log(JSON.stringify(formdataReplace))
        _s.mserve.ReplaceCordinator(formdataReplace).then((response) => {
            if(response.data.status === true){

                var formbody = {
                    SessionEmpID :this.Data_SessionStorage[0].employeE_ID,
                    Module :'deleteitem',
                    body :[
                        { Asset_Id :item.Emp_CoordinatorId }
                    ]
                }
                _s.mserve.GetAsset(formbody).then((response) => {
                    if(response.data.status === true){
                        for (var i=0; i < this.TempdataEmployee.length; i++) {
                            if (item.Emp_id === this.TempdataEmployee[i].Emp_id) {
                                this.TempdataEmployee.splice(i,1);
                                break;
                            }
                        }
                        $('#modalDeleteCoAsset').modal('hide');
                        setTimeout(function(){ $('#modaladdAssetUnit').modal('show'); }, 300);

                        this.func_save('');
                        _s.TempdataEmployee.forEach(function(ItemVal, ItemIndex) {
                            if (ItemVal.Emp_CoordinatorId === '') {
                                ItemVal.Emp_CoordinatorId = '-1' 
                            }
                        });
                    }
                    else{
                        //console.log('false',response)
                        alert(response.data.errorMessage);
                    }
                });
            }
            else{
                //console.log('false',response)
                alert(response.data.errorMessage);  
            }
        });
    }

    close_remove_emp() {
        $('#modalDeleteCoAsset').modal('hide');
        setTimeout(function () { $('#modaladdAssetUnit').modal('show'); }, 300);
    }

    private _filterOrgNameDD(value: string): string[] {
        let tempFileter;
        const filterValue = value?.toLowerCase();
        if (filterValue !== '') {
            tempFileter = this.OrgNameDD.filter(option => option.text?.toLowerCase().includes(filterValue));
            if (tempFileter.length !== 0) {
                return tempFileter;
            }
        } else {
            return this.OrgNameDD
        }
    }
    
    private _filterOrgNameByParent(value: string, searchValue: string): string[] {
        let tempFileter;
        const filterValue = value?.toLowerCase();
        const filterSearchValue = searchValue?.toLowerCase();
        if (filterValue !== '') {
            tempFileter = this.OrgNameDD.filter(option => option.text2?.toLowerCase().includes(filterValue) && option.text?.toLowerCase().includes(filterSearchValue)
            && option.value.charAt(0).toLowerCase() !== 'a');
            if (tempFileter.length !== 0) {
                return tempFileter;
            }
        } else {
            return this.OrgNameDD
        }
    }

    private _filterEmployeeIDDD(value: string): string[] {
        let tempFileter;
        const filterValue = value?.toLowerCase();
        if (filterValue !== '') {
            tempFileter = this.EmployeeDD.filter(option1 => option1.text?.toLowerCase().includes(filterValue));
            if (tempFileter.length !== 0) {
                return tempFileter;
            }
        } else {
            return this.EmployeeDD
        }
    }
    private _filterEmployeeIDDDReplace(value: string): string[] {

        let tempFileter;
        if (value !== null) {
            const filterValue = value?.toLowerCase();
            if (filterValue !== '') {
                tempFileter = this.EmployeeDD_autoReplace.filter(option1 => option1.Emp_name?.toLowerCase().includes(filterValue));
                if (tempFileter.length !== 0) {
                    return tempFileter;
                }
            } else {
                return this.EmployeeDD_autoReplace
            }
        }
        else {
            return this.EmployeeDD_autoReplace
        }
    }

}