import { OnInit, Component, Injectable } from "@angular/core";
import { NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { RiskMap } from '../../services/riskmap.service';
import { Masterserve } from '../../services/master.service';
import { Chart } from 'chart.js';

declare let $;
declare const window: any;
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AuthenService } from "src/app/services/authenservice.service";

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: (date[0], 10),
        month: (date[1], 10),
        year: (date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    if (date !== null) {
      let day = date.day.toString()
      let month = date.month.toString()
      if (day.length < 2) {
        day = '0' + day
      }
      if (month.length < 2) {
        month = '0' + month
      }
      let newdate = day + this.DELIMITER + month + this.DELIMITER + date.year;
      return newdate;
    } else {
      return '';
    }
  }
}

@Component({
  selector: 'app-RiskProfileDashBoard',
  templateUrl: './RiskProfileDashBoard.html',
  styleUrls: ['./RiskProfileDashBoard.css'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }, RiskMap, Masterserve
  ]
})
export class RiskProfileDashBoard implements OnInit {
  chart1: any;

  typeChart1: any;
  dataChart1: any;
  optionsChart1: any;

  typeChart2: any;
  dataChart2: any;
  optionsChart2: any;

  typeChart3: any;
  dataChart3: any;
  optionsChart3: any;

  typeChart4: any;
  dataChart4: any;
  optionsChart4: any;


  loading = false;

  // DATA
  page = '1';
  YearDD = [];
  QuarterDD = [];
  Risk_Level = [];
  BUDD = [];
  RiskCategory = [];
  tempbu = '';
  ParentOrgLevelDD = [];
  Data_SessionStorage;
  alertflow;

  // DATA TAB1
  TempDataTab1 = [];
  CountBU = 0;
  CountTotalRisk = 0;
  CountHighRisk = 0;
  CountMediumRisk = 0;
  CountLowRisk = 0;
  CountOverdue = 0;
  CountInprogress = 0;
  CountComplete = 0;
  tempPieData = [];
  tempbarData = [];

  TempmyChart1;
  myChart1;
  myChart2;
  //-----------------

  // DATA TAB2
  TempDataTab2 = [];
  CountActive = 0;
  CountCloseInvalid = 0;
  CountMitigationComplete = 0;
  CountCloseOther = 0;
  tempbarCountActive = [];
  tempbarCountCloseInvalid = [];
  tempbarMitigationComplete = [];
  tempbarCountCloseOther = [];
  tempbarMitiLabel = []

  CountFGOverdue = 0;
  CountFGInprogress = 0;
  CountFGComplete = 0;
  tempbarfgCountOverdue = [];
  tempbarfgCountInprogress = [];
  tempbarfgComplete = [];
  tempbarMitiLabel2 = [];

  myChartstatus
  myChartstatus2
  //-----------------

  Search_Dashboard = {
    BULevel :'',
    BUUnit :'',
    Childnode :false,
    Year :'',
    Quarter :'',
    QuarterID :''
  }
  tempCurrentQuarterID;
  tempCurrentQuarterIDValue = null;


  myControl = new UntypedFormControl();
  filteredOptions: Observable<string[]>;

  constructor(public mserve: RiskMap,private MServ : Masterserve) { }
  ngOnInit(): void {
      const _s = this;
      this.myControl.disable();
      this.Data_SessionStorage = JSON.parse(sessionStorage.datainfo);
      //console.log('Data_SessionStorage => ',this.Data_SessionStorage)

      $(document).ready(function(){
          //debug_ger
          _s.func_tempg1();
          _s.func_g2();
      });

      //------------- Chart1 ---------------
      this.typeChart1 = 'pie';   ////// สามารถกำหนดเป็น 'line','bar','radar','pie','doughnut','polarArea','bubble','scatter'
      this.dataChart1 = {
        labels: [], //"Strategic/ Portfolio Risk", "Financial Risk/ Market Risk", "Stakeholder/ Political & Regulatory", "Operational Risk", "Major Project Management Risk"
        datasets: [
          {
            // label: "My Stats Chart",
            data: [], //10, 30, 80, 30, 40
            backgroundColor : [] //'#5b9ad5', '#f4b183', '#ffd993', '#bdd7ee', '#c5e0b4'
          }
        ]
      };
      this.optionsChart1 = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        animation: {
            duration: 3000,
        },
        plugins: {
          datalabels: {
              formatter: (value, ctx) => {
                let sum = 0;
                let dataArr = ctx.chart.data.datasets[0].data;
                dataArr.map(data => {
                    sum += data;
                });
                let percentage = (value*100 / sum).toFixed(0)+"%";
                return percentage;
            },
            color: 'white',
            font: {
              size: 16
            },
          },
          // labels: {
          //   // render 'label', 'value', 'percentage', 'image' or custom function, default is 'percentage'
          //   render: 'percentage',
          //   fontColor: '#fff',
          //   fontSize: 30,
          // }
        },
        // pieceLabel: {
        //   render: 'percentage',  // สามารถเปลี่ยนการตั้งค่าตามต้องการได้ 'value','label','percentage'
        //   fontSize: 16,
        //   fontStyle: 'bold',
        //   fontColor: '#FFF',
        //   fontFamily: '"db_heaventmed_cond"'
        // },
      };
      // -----------------------------------
      //------------- Chart2 ---------------
      this.typeChart2 = 'horizontalBar';   ////// สามารถกำหนดเป็น 'line','bar','radar','pie','doughnut','polarArea','bubble','scatter'
      this.dataChart2 = {
        labels: ["Overdue", "Completed", "In Progress"],
        datasets: [
          {
            borderColor :'#7d7c7c',
            borderWidth :1,
            label: "Value",
            data: [23, 95, 120],
            backgroundColor : ['#a83232', '#92d050', '#47c4ed']
          }
        ]
      };
      this.optionsChart2 = {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1.2,
        scales: {
            xAxes : [{
                ticks : {
                    min : 0
                }
            }]
        },
        legend: {
          display: false
        },
        animation: {
            duration: 5000,
        },
        plugin :[ChartDataLabels],
        plugins : {
          datalabels: {
            color: 'white',
            font: {
              size: 16
            },
          }
        }
      };
      // -----------------------------------
      //------------- Chart3 ---------------
      this.typeChart3 = 'bar';   ////// สามารถกำหนดเป็น 'line','bar','radar','pie','doughnut','polarArea','bubble','scatter'
      this.dataChart3 = {
        labels: ["January", "February", "March", "April", "May", "test"],
        datasets: [
              {   
                borderColor :'#7d7c7c',
                borderWidth :1,
                label: "Active",
                data: [5, 16, 13, 15, 18, 24],
                backgroundColor : [],
              },
              {
                borderColor :'#7d7c7c',
                borderWidth :1,
                label: "Close (Turn to problem)",
                data: [18, 16, 17, 17, 18, 20],
                backgroundColor : []
              },
              {
                borderColor :'#7d7c7c',
                borderWidth :1,
                label: "Close (Mitigation Complete)",
                data: [11, 16, 9, 10, 17, 21],
                backgroundColor : []
              }  ,
              {
                borderColor :'#7d7c7c',
                borderWidth :1,
                label: "Close (Other)",
                data: [11, 16, 9, 10, 17, 21],
                backgroundColor : []
              }  
          ]
      };
      //debug_ger
      for (let i=0; i < this.dataChart3.datasets.length; i++) {
          for (let ii=0; ii < this.dataChart3.datasets[i].data.length; ii++) {
              if (i === 0) {
                  this.dataChart3.datasets[i].backgroundColor.push('#3290a8')
              }
              else if (i === 1) {
                  this.dataChart3.datasets[i].backgroundColor.push('#ffcc00')
              }
              else if (i === 2) {
                  this.dataChart3.datasets[i].backgroundColor.push('#17ba06')
              }
              else {
                  this.dataChart3.datasets[i].backgroundColor.push('#a83232')
              }
          }
      }

      this.optionsChart3 = {
          responsive: true,
          maintainAspectRatio: false,
          aspectRatio: 0.5,
          animation: {
              duration: 3000,
          },
         
          legend: {
              display: true,
              position: "bottom",
              labels: {
                fontColor: "#333",
                fontSize: 16
              }
          },
          scales: {
              yAxes : [{
                  ticks : {
                      min : 0
                  }
              }]
          },
          plugins : {
            datalabels: {
              color: 'white',
              font: {
                size: 16
              },
            }
          }
      };

      this.typeChart4 = 'bar';  
      this.dataChart4 = {
        labels: ["January", "February", "March", "April", "May", "test"],
        datasets: [
              {   
                borderColor :'#7d7c7c',
                borderWidth :1,
                label: "In-Progress",
                data: [5, 16, 13, 15, 18, 24],
                backgroundColor : [],
              },
              {
                borderColor :'#7d7c7c',
                borderWidth :1,
                label: "Complete",
                data: [18, 16, 17, 17, 18, 20],
                backgroundColor : []
              },
              {
                borderColor :'#7d7c7c',
                borderWidth :1,
                label: "Overdue",
                data: [11, 16, 9, 10, 17, 21],
                backgroundColor : []
              }  
          ]
      };

      for (let i=0; i < this.dataChart4.datasets.length; i++) {
          for (let ii=0; ii < this.dataChart4.datasets[i].data.length; ii++) {
              if (i === 0) {
                  this.dataChart4.datasets[i].backgroundColor.push('#47c4ed')
              }
              else if (i === 1) {
                  this.dataChart4.datasets[i].backgroundColor.push('#92d050')
              }
              else {
                  this.dataChart4.datasets[i].backgroundColor.push('#a83232')
              }
          }
      }

      this.optionsChart4 = {
          responsive: true,
          maintainAspectRatio: false,
          aspectRatio: 0.5,
          animation: {
            duration: 5000,
        },
        legend: {
            display: true,
            position: "bottom",
            labels: {
              fontColor: "#333",
              fontSize: 16
            }
        },
        scales: {
            yAxes : [{
                ticks : {
                    min : 0
                }
            }]
        },
        plugins : {
          datalabels: {
            color: 'white',
            font: {
              size: 16
            },
          }
        }
      };
      


      this.loadDDL();
  }

  loadDDL() {
    const _s = this;
    this.loading = true;
    // DDLYEAR
    _s.mserve.getQuarterYearDD({Module : 'YearDD' }).then((response) => {
      if(response.data.status === true){
        //console.log('YearDD :', response.data.body);
        this.YearDD = response.data.body;
        this.loading = false;
      }
      else{
        //console.log('YearDD : false', response)
      }
    });

    _s.MServ.Master_DDL({ Module:"RatingDD" }).then((response) => {
      if (response.data.status === true) {
          //console.log('RatingDD : ',response.data.body)
          this.Risk_Level = response.data.body;
      } else {
          //console.log('false',response)
      }
    });

    _s.MServ.Master_DDL({ Module:"CoorLevel" }).then((response) => {
        this.ParentOrgLevelDD = response.data.body
        //console.log('CoorLevel : ',this.ParentOrgLevelDD)
    });

    this.MServ.Master_DDL({ Module :'DepartmentDD' }).then((response) => {
      //console.log("BUDD :",response.data.body)
      if (response.data.status === true) {
       
           this.filteredOptions = this.myControl.valueChanges.pipe(
              startWith(''),
              map(value => this._filter(value))
          );
         
      } else {
          //console.log('false',response)
      }
    });
  }

  
  func_GETMASTER_RiskCategory () {
      let chkDDper;
      chkDDper = (sessionStorage.Role === 'ERM') ? '' : '1';

      let formData = {
          Module:"RiskCateDD",
          TextSearch1:chkDDper,
          TextSearch7:this.tempCurrentQuarterIDValue //this.Search_Dashboard.Quarter
      }
      this.MServ.Master_DDL(formData).then((response) => {
          //console.log("dataRiskCate_DD:",response.data.body)
          if (response.data.status === true) {
              this.RiskCategory = response.data.body;
              
              for (let i=0; i<this.RiskCategory.length; i++) {
               
                
                this.tempPieData.push({ text: this.RiskCategory[i].text, color: this.RiskCategory[i].text2 , count: 0 })

              }
              

              this.func_cal_BU();
          } else {
              //console.log('false',response)
          }
      });  
  }

  ClickTab (chkpage) {
     
      const _s = this;
      if (chkpage === '1') {
          this.func_Clear(1);
          let element = document.getElementById("Tab1");
          element.classList.add("active");
          let element2 = document.getElementById("Tab2");
          element2.classList.remove("active");
          this.page = '1'
          $(document).ready(function(){
            _s.func_tempg1();
            _s.func_g2();
          });
          
      }
      else {
          this.func_Clear(2);
          let element = document.getElementById("Tab1");
          element.classList.remove("active");
          let element2 = document.getElementById("Tab2");
          element2.classList.add("active");
          this.page = '2'
          $(document).ready(function(){
            _s.func_g3();
            _s.func_g4();
          });
      }
  }

  func_selBU () {
   
    for (let i=0; i < this.BUDD.length; i++) {
      if (this.tempbu === this.BUDD[i].text) {
        this.Search_Dashboard.BUUnit = this.BUDD[i].value
      }
    }
  }

  func_selYear () {

    const _s = this;
    this.loading = true;
    this.myControl.disable();
    _s.Search_Dashboard.Quarter = '';
    this.Search_Dashboard.Childnode = false;
    _s.mserve.getQuarterDD({ Module:"QuarterDD" , 'TextSearch1' : this.Search_Dashboard.Year }).then((response) => {
      if(response.data.status === true){
        this.loading = false;
        //console.log("QuarterDD : ", response.data.body)
        this.QuarterDD = response.data.body;


        let d = new Date();
        let month = d.getMonth() + 1;
        if (sessionStorage.getItem('PageBack') === '') {
    
          if (month >= 1 && month <= 3) {
            _s.tempCurrentQuarterID = this.QuarterDD[0].text 
          } else if (month >= 4 && month <= 6) {
            _s.tempCurrentQuarterID = (this.QuarterDD[1] !== undefined) ? this.QuarterDD[1].text : this.QuarterDD[0].text 
          } else if (month >= 7 && month <= 9) {
            _s.tempCurrentQuarterID = (this.QuarterDD[2] !== undefined) ? this.QuarterDD[2].text : this.QuarterDD[0].text 
          } else if (month >= 10 && month <= 12) {
            _s.tempCurrentQuarterID = (this.QuarterDD[3] !== undefined) ?  this.QuarterDD[3].text : this.QuarterDD[0].text
          }
        }

      }
      else{
        //console.log('QuarterDD : false', response)
      }
    });
  }

  func_Clear (value) {
    const _s = this;
    this.tempbu = '';
    this.Search_Dashboard.BULevel = '';
    this.Search_Dashboard.BUUnit = '';
    this.Search_Dashboard.Childnode = false;
    this.Search_Dashboard.Year = '';
    this.Search_Dashboard.Quarter = '';
    this.myControl.disable();

    this.RiskCategory = [];
    this.TempDataTab1 = [];
    this.CountBU = 0;
    this.CountTotalRisk = 0;
    this.CountHighRisk = 0;
    this.CountMediumRisk = 0;
    this.CountLowRisk = 0;
    this.CountOverdue = 0;
    this.CountInprogress = 0;
    this.CountComplete = 0;
    this.tempPieData = [];
    this.tempbarData = [];

    this.TempDataTab2 = [];
    this.CountActive = 0;
    this.CountCloseInvalid = 0;
    this.CountMitigationComplete = 0;
    this.CountCloseOther = 0;
    this.tempbarCountActive = [];
    this.tempbarCountCloseInvalid = [];
    this.tempbarMitigationComplete = [];
    this.tempbarCountCloseOther = [];
    this.tempbarMitiLabel = []
    this.CountFGOverdue = 0;
    this.CountFGInprogress = 0;
    this.CountFGComplete = 0;
    this.tempbarfgCountOverdue = [];
    this.tempbarfgCountInprogress = [];
    this.tempbarfgComplete = [];
    this.tempbarMitiLabel2 = [];

    if (value == 1) {
        $(document).ready(function(){
            _s.func_tempg1();
            _s.func_g2();
        });
    } else {
        $(document).ready(function(){
            _s.func_g3();
            _s.func_g4();
        });
    }
  }

  func_search1 () {
    const _s = this;
    this.loading = true;


    let d = new Date();
    let cur_year = d.getFullYear();

    let tempQuarterID = null , tempWPBID = null, tempQuarterMaster = null
    if (cur_year === parseInt(_s.Search_Dashboard.Year)) {
        if (_s.Search_Dashboard.Quarter.includes("WPB") === false ) {
            for (let i=0;i< this.QuarterDD.length; i++) {
                if (_s.Search_Dashboard.Quarter === this.QuarterDD[i].text) {
                    tempQuarterID = this.QuarterDD[i].value;
                    tempQuarterMaster = this.QuarterDD[i].value;
                    break;
                }
            }
        } 
        else {
            for (let i=0;i< this.QuarterDD.length; i++) {
                if (_s.Search_Dashboard.Quarter === this.QuarterDD[i].text) {
                    tempWPBID = this.QuarterDD[i].value;
                    break;
                }
            }
            for (let i=0;i< this.QuarterDD.length; i++) {
                if (_s.tempCurrentQuarterID === this.QuarterDD[i].text) {
                    tempQuarterMaster = this.QuarterDD[i].value;
                    break;
                }
            }
        }
    } 
    else {
        if (_s.Search_Dashboard.Quarter.includes("WPB") === false ) {
            for (let i=0;i< this.QuarterDD.length; i++) {
                if (_s.Search_Dashboard.Quarter === this.QuarterDD[i].text) {
                    tempQuarterID = this.QuarterDD[i].value;
                    tempQuarterMaster = this.QuarterDD[i].value;
                    break;
                }
            }
        } 
        else {
            for (let i=0;i< this.QuarterDD.length; i++) {
                if (_s.Search_Dashboard.Quarter === this.QuarterDD[i].text) {
                    tempWPBID = this.QuarterDD[i].value;
                    break;
                }
            }
            tempQuarterMaster = this.QuarterDD[3].value
        }
    }

    let formdata = {
      SessionEmpID :this.Data_SessionStorage[0].employeE_ID, 
      Email :this.Data_SessionStorage[0].emaiL_ID,  
      body :{ 
          Risk_Business_Unit :_s.Search_Dashboard.BUUnit, 
          Child_Node :(_s.Search_Dashboard.Childnode === true) ? 1 : 0, 
   
          QuarterID :tempQuarterID,
          WPBID:tempWPBID,
          QuaterMaster :tempQuarterMaster
        }
    }
    this.tempCurrentQuarterIDValue = (tempQuarterID != null) ? tempQuarterID : tempWPBID
    //console.log(JSON.stringify(formdata))
    _s.mserve.ReportDashboardCategory(formdata).then((response) => {
        if (response.data.status === true) {
          //console.log("Data Tab1 :",response.data.body);
          this.TempDataTab1 = response.data.body;
          this.tempPieData = [];
          this.func_GETMASTER_RiskCategory();
        }
        else {
          this.loading = false;
          this.alertflow = (response.data.errorMessage === null) ? 'No data report!' : response.data.errorMessage;
          $('#modalAlertreportdash').modal('show');
        }
    });
  }

  func_search2 () {
    const _s = this;

    let d = new Date();
    let cur_year = d.getFullYear();

    let tempQuarterID = null , tempWPBID = null, tempQuarterMaster = null
    if (cur_year === parseInt(_s.Search_Dashboard.Year)) {
      if (_s.Search_Dashboard.Quarter.includes("WPB") === false ) {
        
        for (let i=0;i< this.QuarterDD.length; i++) {
          if (_s.Search_Dashboard.Quarter === this.QuarterDD[i].text) {
            tempQuarterID = this.QuarterDD[i].value;
            tempQuarterMaster = this.QuarterDD[i].value;
            break;
          }
        }
      } 
      else {
        for (let i=0;i< this.QuarterDD.length; i++) {
          if (_s.Search_Dashboard.Quarter === this.QuarterDD[i].text) {
            tempWPBID = this.QuarterDD[i].value;
            break;
          }
        }
        for (let i=0;i< this.QuarterDD.length; i++) {
          if (_s.tempCurrentQuarterID === this.QuarterDD[i].text) {
            tempQuarterMaster = this.QuarterDD[i].value;
            break;
          }
        }
      }
    } 
    else {
      if (_s.Search_Dashboard.Quarter.includes("WPB") === false ) {
        for (let i=0;i< this.QuarterDD.length; i++) {
          if (_s.Search_Dashboard.Quarter === this.QuarterDD[i].text) {
            tempQuarterID = this.QuarterDD[i].value;
            tempQuarterMaster = this.QuarterDD[i].value;
            break;
          }
        }
      } 
      else {
        for (let i=0;i< this.QuarterDD.length; i++) {
          if (_s.Search_Dashboard.Quarter === this.QuarterDD[i].text) {
            tempWPBID = this.QuarterDD[i].value;
            break;
          }
        }
        tempQuarterMaster =  _s.QuarterDD[3].value
      }
    }

    this.loading = true;
    let formdata = {
      body :{
          QuarterID :tempQuarterID,
          WPBID:tempWPBID,
          QuarterMasterID :tempQuarterMaster,
      }
    }
    //console.log(JSON.stringify(formdata))
    _s.mserve.ReportDashboardStatus(formdata).then((response) => {
        if (response.data.status === true) {
          //console.log("Data Tab2 :",response.data.body)
          this.TempDataTab2 = response.data.body;
          this.func_cal_status();
        }
        else {
          this.loading = false;
          this.alertflow = (response.data.errorMessage === null) ? 'No data report!' : response.data.errorMessage;
          $('#modalAlertreportdash').modal('show');
        }
    });
  }


  func_cal_BU () {
    let isDup = false
    let uniq_values = [];
    this.CountBU = 0;

    for(let x of this.TempDataTab1) {
      if(uniq_values.indexOf(x.risk_Business_Unit)!=-1) {
          isDup=true
      } 
      else {
        uniq_values.push(x.risk_Business_Unit)
        this.CountBU = this.CountBU + 1;
      }
    }
    //console.log('CountBU : ',this.CountBU);
    this.func_cal_totalRisk();
  }
  func_cal_totalRisk () {
    const _s = this;
    this.CountTotalRisk = 0;
    this.TempDataTab1.forEach(function (value, index){
      _s.CountTotalRisk = _s.CountTotalRisk + 1
    });
    //console.log('CountTotalRisk : ',this.CountTotalRisk);
    this.func_cal_LevelRisk();
  }
  func_cal_LevelRisk () {
    const _s = this;
    this.CountHighRisk = 0;
    this.CountMediumRisk = 0;
    this.CountLowRisk = 0;

    this.TempDataTab1.forEach(function (value, index){
        if (value.risk_Rating.toUpperCase() == "HIGH") {
          _s.CountHighRisk = _s.CountHighRisk + 1;
        }
        else if (value.risk_Rating.toUpperCase() == "MEDIUM") {
          _s.CountMediumRisk = _s.CountMediumRisk + 1;
        }
        else if (value.risk_Rating.toUpperCase() == "LOW") {
          _s.CountLowRisk = _s.CountLowRisk + 1;
        }
    });

    //console.log("HIGH",this.CountHighRisk);
    //console.log("MEDIUM",this.CountMediumRisk);
    //console.log("LOW",this.CountLowRisk);
    this.func_cal_RiskCate();
  }
  func_cal_RiskCate () {
    const _s = this;

    for (let i=0; i<this.TempDataTab1.length; i++) {
      for (let ii=0; ii<this.RiskCategory.length; ii++) {
          if (this.TempDataTab1[i].risk_Category == this.RiskCategory[ii].value) {
            this.TempDataTab1[i].risk_Category = this.RiskCategory[ii].text;
          }
      }
    }


    this.TempDataTab1.forEach(function(value, index){
      for (let i=0; i< _s.RiskCategory.length; i++) {
          if (value.risk_Category == _s.RiskCategory[i].text) {
            _s.tempPieData[i].count = _s.tempPieData[i].count + 1;
              break;
          }
      }
    });

    _s.dataChart1.datasets[0].data = [];
    _s.dataChart1.datasets[0].backgroundColor = [];
    _s.dataChart1.datasets[0].labels = [];
    this.tempPieData.forEach(function(value, index){
      if (value.count !== 0) {
        _s.dataChart1.datasets[0].labels.push( value.text )
        _s.dataChart1.datasets[0].data.push( value.count )
        _s.dataChart1.datasets[0].backgroundColor.push( (value.color == null) ? '#ffffff' : value.color)
      }
    });

    this.func_cal_MitigationProgress();


    //console.log('dataChart1',this.dataChart1)
    //console.log("tempPieData",this.tempPieData)

  }
  func_cal_MitigationProgress () {
    const _s = this;
    let chkday, chkmonth, chkyear;
    this.CountOverdue = 0;
    this.CountInprogress = 0;
    this.CountComplete = 0;

    let date = new Date();
    chkday = date.getDate();
    chkmonth = date.getMonth() + 1;
    chkyear = date.getFullYear();

    this.TempDataTab1.forEach(function(valuelv1, indexlv1){
        if (valuelv1.impact !== null) {
          valuelv1.impact.forEach(function(valuelv2, indexlv2){
            if (valuelv2.impact_Mitigation !== null) {
              valuelv2.impact_Mitigation.forEach(function(valuelv3, indexlv3){
                  if (valuelv3.impact_Mitigation_DueDate !== null) {
                        let day,month,year;
                        year = valuelv3.impact_Mitigation_DueDate.substr(6 , 4);
                        month = valuelv3.impact_Mitigation_DueDate.substr(3 , 2);
                        day = valuelv3.impact_Mitigation_DueDate.substr(0 , 2);

                        if (chkyear < parseInt(year)) {
                          if (valuelv3.impact_Mitigation_Progress != 100) {
                            _s.CountInprogress = _s.CountInprogress + 1
                          } else if (valuelv3.impact_Mitigation_Progress == 100) {
                            _s.CountComplete = _s.CountComplete + 1
                          } else {
                            _s.CountOverdue = _s.CountOverdue + 1
                          }
                        }
                        else if (chkyear == parseInt(year) ) {
                            if (chkmonth < parseInt(month)) {
                                if (valuelv3.impact_Mitigation_Progress != 100) {
                                  _s.CountInprogress = _s.CountInprogress + 1
                                } else if (valuelv3.impact_Mitigation_Progress == 100) {
                                  _s.CountComplete = _s.CountComplete + 1
                                } else {
                                  _s.CountOverdue = _s.CountOverdue + 1
                                }
                            }
                            else if (chkmonth == parseInt(month)) {
                                if (chkday <= parseInt(day)  && valuelv3.impact_Mitigation_Progress != 100) {
                                  _s.CountInprogress = _s.CountInprogress + 1
                                } else if (chkday <= parseInt(day)  && valuelv3.impact_Mitigation_Progress == 100) {
                                  _s.CountComplete = _s.CountComplete + 1
                                } else if (valuelv3.impact_Mitigation_Progress == 100) {
                                  _s.CountComplete = _s.CountComplete + 1
                                } else {
                                  _s.CountOverdue = _s.CountOverdue + 1
                                }
                            } else if (valuelv3.impact_Mitigation_Progress == 100) {
                              _s.CountComplete = _s.CountComplete + 1
                            } else {
                              _s.CountOverdue = _s.CountOverdue + 1
                            }
                        } else if (valuelv3.impact_Mitigation_Progress == 100) {
                          _s.CountComplete = _s.CountComplete + 1
                        } else {
                          _s.CountOverdue = _s.CountOverdue + 1
                        }
                  }
                  else {
                    _s.CountInprogress = _s.CountInprogress + 1
                  }
              });
            }
          });
        }
    });

    this.TempDataTab1.forEach(function(valuelv1, indexlv1){
      if (valuelv1.rootCause !== null) {
        valuelv1.rootCause.forEach(function(valuelv2, indexlv2){
          if (valuelv2.rootCause_Mitigation !== null) {
            valuelv2.rootCause_Mitigation.forEach(function(valuelv3, indexlv3){
                if (valuelv3.rootCause_Mitigation_DueDate !== null) {
                        let day,month,year;
                        year = valuelv3.rootCause_Mitigation_DueDate.substr(6 , 4);
                        month = valuelv3.rootCause_Mitigation_DueDate.substr(3 , 2);
                        day = valuelv3.rootCause_Mitigation_DueDate.substr(0 , 2);
                      

                        if (chkyear < parseInt(year)) {
                          if (valuelv3.rootCause_Mitigation_Progress != 100) {
                            _s.CountInprogress = _s.CountInprogress + 1
                          } else if (valuelv3.rootCause_Mitigation_Progress == 100) {
                            _s.CountComplete = _s.CountComplete + 1
                          }
                        }
                        else if (chkyear == parseInt(year)) {
                            if (chkmonth < parseInt(month)) {
                              if (valuelv3.rootCause_Mitigation_Progress != 100) {
                                _s.CountInprogress = _s.CountInprogress + 1
                              } else if (valuelv3.rootCause_Mitigation_Progress == 100) {
                                _s.CountComplete = _s.CountComplete + 1
                              } 
                            }
                            else if (chkmonth == parseInt(month)) {
                                if (chkday <= parseInt(day) && valuelv3.rootCause_Mitigation_Progress != 100) {
                                  _s.CountInprogress = _s.CountInprogress + 1
                                } else if (chkday <= parseInt(day) && valuelv3.rootCause_Mitigation_Progress == 100) {
                                  _s.CountComplete = _s.CountComplete + 1
                                } else if (valuelv3.rootCause_Mitigation_Progress == 100) {
                                  _s.CountComplete = _s.CountComplete + 1
                                } else {
                                  _s.CountOverdue = _s.CountOverdue + 1
                                }
                            } else if (valuelv3.rootCause_Mitigation_Progress == 100) {
                              _s.CountComplete = _s.CountComplete + 1
                            } else {
                              _s.CountOverdue = _s.CountOverdue + 1
                            }
                        } 
                        else if (valuelv3.rootCause_Mitigation_Progress == 100) {
                          _s.CountComplete = _s.CountComplete + 1
                        } 
                        else {
                          _s.CountOverdue = _s.CountOverdue + 1
                        }
                }
                else {
                  _s.CountInprogress = _s.CountInprogress + 1
                }
            });
          }
        });
      }
    });

    //console.log("CountOverdue",this.CountOverdue)
    //console.log("CountInprogress",this.CountInprogress)
    //console.log("CountComplete",this.CountComplete)

    this.tempbarData = [];
    this.tempbarData.push(this.CountOverdue)
    this.tempbarData.push(this.CountComplete)
    this.tempbarData.push(this.CountInprogress)
   

    this.func_g1();
    this.func_g2();

    this.loading = false;
  }


  func_cal_status () {
    const _s = this;
    let tempuniq = [];
    let uniq_values = [];
    let isDup;
    let tempctn = 0;

    _s.CountActive = 0;
    _s.CountCloseInvalid = 0;
    _s.CountMitigationComplete = 0;
    _s.CountCloseOther = 0;
    this.tempbarCountActive = [];
    this.tempbarCountCloseInvalid = [];
    this.tempbarMitigationComplete = [];
    this.tempbarCountCloseOther = [];
    this.tempbarMitiLabel = [];


    this.TempDataTab2.forEach(function(value , index){
        tempuniq.push({
            name: (value.abbreviation !== null) ? value.abbreviation : value.name,
            code: value.organizatioN_ID,
            status: value.riskItems
        });
    });

    
    for(let x=0; x<tempuniq.length; x++) {
      if (tempuniq[x].status !== null) {
        for (let xx = 0; xx < tempuniq[x].status.length; xx++){
          if (tempuniq[x].status[xx].risk_Status == 1) {
            _s.CountActive = _s.CountActive + 1
          }
          else if (tempuniq[x].status[xx].risk_Status == 2) {
            _s.CountCloseInvalid = _s.CountCloseInvalid + 1
          }
          else if (tempuniq[x].status[xx].risk_Status == 3) {
            _s.CountMitigationComplete = _s.CountMitigationComplete + 1
          }
          else if (tempuniq[x].status[xx].risk_Status == 4) {
            _s.CountCloseOther = _s.CountCloseOther + 1
          }
        }
      }
        this.tempbarMitiLabel.push(tempuniq[x].name)
        this.tempbarCountActive.push(_s.CountActive);
        this.tempbarCountCloseInvalid.push(_s.CountCloseInvalid);
        this.tempbarMitigationComplete.push(_s.CountMitigationComplete);
        this.tempbarCountCloseOther.push(_s.CountCloseOther);
        _s.CountActive = 0;
        _s.CountCloseInvalid = 0;
        _s.CountMitigationComplete = 0;
        _s.CountCloseOther = 0;
        tempctn = 0;
    }

    //console.log('CountActive',_s.tempbarCountActive);
    //console.log('CountCloseInvalid',_s.tempbarCountCloseInvalid);
    //console.log('CountMitigationComplete',_s.tempbarMitigationComplete);
    //console.log('CountCloseOther',_s.tempbarCountCloseOther);

    this.func_cal_MitigationProgressFG();
  }
  func_cal_MitigationProgressFG () {
    const _s = this;
    let tempuniq = [];
    let uniq_values = [];
    let isDup;
    let tempctn = 0;

    _s.CountFGOverdue = 0;
    _s.CountFGInprogress = 0;
    _s.CountFGComplete = 0;
    this.tempbarfgCountOverdue = [];
    this.tempbarfgCountInprogress = [];
    this.tempbarfgComplete = [];
    this.tempbarMitiLabel2 = [];

    let date = new Date();
    let chkday, chkmonth, chkyear;
    chkday = date.getDate();
    chkmonth = date.getMonth() + 1;
    chkyear = date.getFullYear();

    this.TempDataTab2.forEach(function(value , index){
        tempuniq.push({
            name: (value.abbreviation !== null) ? value.abbreviation : value.name,
            code: value.risk_Business_Unit,
            riskItems: value.riskItems,
          
        })
    });

  

    for(let x=0; x<tempuniq.length; x++) {
        if (tempuniq[x].riskItems !== null) {
          for (let xx = 0; xx < tempuniq[x].riskItems.length; xx++) {
              if (tempuniq[x].riskItems[xx].impact !== null) {
                tempuniq[x].riskItems[xx].impact.forEach(function(valuelv1 , indexlv1){
                  if (valuelv1.impact_Mitigation !== null) {
                    valuelv1.impact_Mitigation.forEach(function(valuelv2 , indexlv2){
                        if (valuelv2.impact_Mitigation_DueDate !== null) {
                            let day,month,year;
                            year = valuelv2.impact_Mitigation_DueDate.substr(6 , 4);
                            month = valuelv2.impact_Mitigation_DueDate.substr(3 , 2);
                            day = valuelv2.impact_Mitigation_DueDate.substr(0 , 2);
                            if (chkyear < parseInt(year)) {
                                if (valuelv2.impact_Mitigation_Progress != 100) {
                                  _s.CountFGInprogress = _s.CountFGInprogress + 1
                                } else if (valuelv2.impact_Mitigation_Progress == 100) {
                                  _s.CountFGComplete = _s.CountFGComplete + 1
                                } else {
                                  _s.CountFGOverdue = _s.CountFGOverdue + 1
                                }
                            }
                            else if (chkyear == parseInt(year)) {
                              if (chkmonth == parseInt(month)) {
                                  if (valuelv2.impact_Mitigation_Progress != 100) {
                                    _s.CountFGInprogress = _s.CountFGInprogress + 1
                                  } else if (valuelv2.impact_Mitigation_Progress == 100) {
                                    _s.CountFGComplete = _s.CountFGComplete + 1
                                  } else {
                                    _s.CountFGOverdue = _s.CountFGOverdue + 1
                                  }
                              }
                              else if (chkmonth < parseInt(month)) {
                                  if (chkday <= parseInt(day) && valuelv2.impact_Mitigation_Progress != 100) {
                                    _s.CountFGInprogress = _s.CountFGInprogress + 1
                                  } else if (chkday <= parseInt(day) && valuelv2.impact_Mitigation_Progress == 100) {
                                    _s.CountFGComplete = _s.CountFGComplete + 1
                                  } else if (valuelv2.impact_Mitigation_Progress == 100) {
                                    _s.CountFGComplete = _s.CountFGComplete + 1
                                  } else {
                                    _s.CountFGOverdue = _s.CountFGOverdue + 1
                                  }
                              } else if (valuelv2.impact_Mitigation_Progress == 100) {
                                _s.CountFGComplete = _s.CountFGComplete + 1
                              } else {
                                _s.CountFGOverdue = _s.CountFGOverdue + 1
                              }
                          } else if (valuelv2.impact_Mitigation_Progress == 100) {
                            _s.CountFGComplete = _s.CountFGComplete + 1
                          } else {
                            _s.CountFGOverdue = _s.CountFGOverdue + 1
                          }
                        }
                        else {
                          _s.CountFGOverdue = _s.CountFGOverdue + 1
                        }
                    });
                  }
                });
              }

              if (tempuniq[x].riskItems[xx].rootCause !== null) {
                tempuniq[x].riskItems[xx].rootCause.forEach(function(valuelv1 , indexlv1){
                  if (valuelv1.rootCause_Mitigation !== null) {
                    valuelv1.rootCause_Mitigation.forEach(function(valuelv2 , indexlv2){
                        if (valuelv2.rootCause_Mitigation_DueDate !== null) {
                            let day,month,year;
                            year = valuelv2.rootCause_Mitigation_DueDate.substr(6 , 4);
                            month = valuelv2.rootCause_Mitigation_DueDate.substr(3 , 2);
                            day = valuelv2.rootCause_Mitigation_DueDate.substr(0 , 2);
                            if (chkyear < parseInt(year)) {
                                if (valuelv2.rootCause_Mitigation_Progress != 100) {
                                  _s.CountFGInprogress = _s.CountFGInprogress + 1
                                } else if (valuelv2.rootCause_Mitigation_Progress == 100) {
                                  _s.CountFGComplete = _s.CountFGComplete + 1
                                } else {
                                  _s.CountFGOverdue = _s.CountFGOverdue + 1
                                }
                            }
                            else if (chkyear == parseInt(year)) {
                              if (chkmonth < parseInt(month)) {
                                  if (valuelv2.rootCause_Mitigation_Progress != 100) {
                                    _s.CountFGInprogress = _s.CountFGInprogress + 1
                                  } else if (valuelv2.rootCause_Mitigation_Progress == 100) {
                                    _s.CountFGComplete = _s.CountFGComplete + 1
                                  } else {
                                    _s.CountFGOverdue = _s.CountFGOverdue + 1
                                  }
                              }
                              else if (chkmonth == parseInt(month)) {
                                  if (chkday <= parseInt(day) && valuelv2.rootCause_Mitigation_Progress != 100) {
                                    _s.CountFGInprogress = _s.CountFGInprogress + 1
                                  } else if (chkday <= parseInt(day) && valuelv2.rootCause_Mitigation_Progress == 100) {
                                    _s.CountFGComplete = _s.CountFGComplete + 1
                                  } else if (valuelv2.rootCause_Mitigation_Progress == 100) {
                                    _s.CountFGComplete = _s.CountFGComplete + 1
                                  } else {
                                    _s.CountFGOverdue = _s.CountFGOverdue + 1
                                  }
                              } else if (valuelv2.rootCause_Mitigation_Progress == 100) {
                                _s.CountFGComplete = _s.CountFGComplete + 1
                              } else {
                                _s.CountFGOverdue = _s.CountFGOverdue + 1
                              }
                          } else if (valuelv2.rootCause_Mitigation_Progress == 100) {
                            _s.CountFGComplete = _s.CountFGComplete + 1
                          } else {
                            _s.CountFGOverdue = _s.CountFGOverdue + 1
                          }
                        }
                        else {
                          _s.CountFGOverdue = _s.CountFGOverdue + 1
                        }
                    });
                  }
                });
              }
            }
        }
        this.tempbarMitiLabel2.push(tempuniq[x].name)
        this.tempbarfgCountOverdue.push(_s.CountFGOverdue);
        this.tempbarfgCountInprogress.push(_s.CountFGInprogress);
        this.tempbarfgComplete.push(_s.CountFGComplete);
        this.CountFGOverdue = 0;
        this.CountFGInprogress = 0;
        this.CountFGComplete = 0;
    }

    //console.log('CountFGOverdue',_s.tempbarfgCountOverdue);
    //console.log('CountFGInprogress',_s.tempbarfgCountInprogress);
    //console.log('CountFGComplete',_s.tempbarfgComplete);

    this.func_g3();
    this.func_g4();
    
    this.loading = false;
  }

  func_tempg1 () {
    const canvas = <HTMLCanvasElement> document.getElementById('myChart');
    const ctx = canvas.getContext('2d');
    if(this.myChart1 != undefined) {
      this.myChart1.destroy();
      this.TempmyChart1.destroy();
    }
    this.TempmyChart1 = new Chart(ctx, {
      type: 'pie',
      data: {
          labels: ['No Data'],
          datasets: [{
              label: '# of Votes',
              data: [1],
              backgroundColor: ['#B7B7B7'],
              borderColor: '#FFFFFF',
              borderWidth: 1
          }]
      },
      options: {
        responsive: true,
        legend: {
          display: false
        },
        animation: {
            duration: 2000,
        },
        plugins: {
          datalabels: {
            labels: {
              title: null
          }
          }
        }
      }
    });

    this.TempmyChart1.update();
  }

  func_g1 () {
    const canvas = <HTMLCanvasElement> document.getElementById('myChart');
    const ctx = canvas.getContext('2d');
    this.TempmyChart1.destroy();
    if(this.myChart1 != undefined) {
      this.myChart1.destroy();
    }
    this.myChart1 = new Chart(ctx, {
      type: 'pie',
      data: {
          labels: this.dataChart1.datasets[0].labels,
          datasets: [{
              data: this.dataChart1.datasets[0].data,
              backgroundColor: this.dataChart1.datasets[0].backgroundColor,
              borderColor: '#FFFFFF',
              borderWidth: 3
          }]
      },
      options: {
        responsive: true,
        legend: {
          display: false
        },
        animation: {
            duration: 1000,
        },
        plugins: {
          datalabels: {
              formatter: (value, ctx) => {
                let sum = 0;
                let dataArr 
                dataArr = ctx.chart.data.datasets[0].data;
                dataArr.map(data => {
                    sum += data;
                });
                let percentage = (value*100 / sum).toFixed(0)+"%";
                return percentage;
            },
            color: 'white',
            font: {
              size: 16
            }
          }
        }
      }
    });

    this.myChart1.update();
  }

  func_g2 () {
    let maxvalue = Math.max(...this.tempbarData)

    const canvas = <HTMLCanvasElement> document.getElementById('myChart2');
    const ctx = canvas.getContext('2d');
    if(this.myChart2 != undefined) 
    this.myChart2.destroy();
    this.myChart2 = new Chart(ctx, {
      type: 'horizontalBar',
      data: {
          labels: ["Overdue", "Completed", "In Progress"],
          datasets: [
            {
              borderColor :'#7d7c7c',
              borderWidth :1,
              label: "Value",
              data: this.tempbarData,
              backgroundColor : ['#a83232', '#92d050', '#47c4ed']
            }
          ]
      },
      options: {
        responsive: true, 
        maintainAspectRatio: false,
        aspectRatio: 1.2,
        scales: {
          xAxes : [{
              ticks : {
                  max: (this.CountTotalRisk == 0) ? 10 : maxvalue,
                  min : 0,
                  stepSize: (maxvalue <= 20) ? 1 : (maxvalue % 2 == 0) ? 10 : 11 
              }
          }]
        },
        legend: {
          display: false
        },
        animation: {
            duration: 2000,
        },
        // plugin :[ChartDataLabels],
        plugins : {
          datalabels: {
            align: 'end',
            anchor: 'start',
            color: 'white',
            font: {
              size: 16
            },
            padding:{
              left:50
            }
          }
        }
      }
    });

    this.myChart2.update();
  }
  
  func_g3 () {
    const canvas = <HTMLCanvasElement> document.getElementById('myChart3');
    const ctx = canvas.getContext('2d');
    if(this.myChartstatus != undefined) 
    this.myChartstatus.destroy();
    this.myChartstatus = new Chart(ctx, {
      type: 'bar',
      data: {
          labels: this.tempbarMitiLabel,
          datasets: [
            {   
              borderColor :'#7d7c7c',
              borderWidth :1,
              label: "Active",
              data: this.tempbarCountActive,
              backgroundColor : [],
            },
            {
              borderColor :'#7d7c7c',
              borderWidth :1,
              label: "Close (Turn to problem)",
              data: this.tempbarCountCloseInvalid,
              backgroundColor : []
            },
            {
              borderColor :'#7d7c7c',
              borderWidth :1,
              label: "Close (Mitigation Complete)",
              data: this.tempbarMitigationComplete,
              backgroundColor : []
            },
            {
              borderColor :'#7d7c7c',
              borderWidth :1,
              label: "Close (Other)",
              data: this.tempbarCountCloseOther,
              backgroundColor : []
            }  
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 0.5,
        animation: {
            duration: 3000,
        },
        legend: {
            display: true,
            position: "bottom",
            labels: {
              fontColor: "#333",
              fontSize: 16
            }
        },
        scales: {
            yAxes : [{
                ticks : {
                    min : 0,
                    stepSize: 1
                }
            }]
        },
        plugins : {
          datalabels: {
            color: 'black',
            font: {
              size: 20
            },
          }
        }
      }
    });
    
    //console.log(this.myChartstatus.data.datasets);
    for (let i=0; i < this.myChartstatus.data.datasets.length; i++) {
      for (let ii=0; ii < this.myChartstatus.data.datasets[i].data.length; ii++) {
          if (i === 0) {
            this.myChartstatus.data.datasets[i].backgroundColor.push('#3290a8')
          }
          else if (i === 1) {
            this.myChartstatus.data.datasets[i].backgroundColor.push('#ffcc00')
          }
          else if (i === 2) {
            this.myChartstatus.data.datasets[i].backgroundColor.push('#17ba06')
          }
          else {
            this.myChartstatus.data.datasets[i].backgroundColor.push('#a83232')
          }
      }
    }
    this.myChartstatus.update();

  }
  
  func_g4 () {
    const canvas = <HTMLCanvasElement> document.getElementById('myChart4');
    const ctx = canvas.getContext('2d');
    if(this.myChartstatus2 != undefined) 
    this.myChartstatus2.destroy();
    this.myChartstatus2 = new Chart(ctx, {
      type: 'bar',
      data: {
          labels: this.tempbarMitiLabel2,
          datasets: [
            {   
              borderColor :'#7d7c7c',
              borderWidth :1,
              label: "In-Progress",
              data: this.tempbarfgCountInprogress,
              backgroundColor : [],
            },
            {
              borderColor :'#7d7c7c',
              borderWidth :1,
              label: "Complete",
              data: this.tempbarfgComplete,
              backgroundColor : []
            },
            {
              borderColor :'#7d7c7c',
              borderWidth :1,
              label: "Overdue",
              data: this.tempbarfgCountOverdue,
              backgroundColor : []
            }  
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 0.5,
        animation: {
            duration: 3000,
        },
        legend: {
            display: true,
            position: "bottom",
            labels: {
              fontColor: "#333",
              fontSize: 16
            }
        },
        scales: {
            yAxes : [{
                ticks : {
                    min : 0,
                    stepSize: 1
                }
            }]
        },
        plugins : {
          datalabels: {
            color: 'black',
            font: {
              size: 20
            },
          }
        }
      }
    });
    
    //console.log(this.myChartstatus2.data.datasets);
    for (let i=0; i < this.myChartstatus2.data.datasets.length; i++) {
      for (let ii=0; ii < this.myChartstatus2.data.datasets[i].data.length; ii++) {
          if (i === 0) {
            this.myChartstatus2.data.datasets[i].backgroundColor.push('#47c4ed')
          }
          else if (i === 1) {
            this.myChartstatus2.data.datasets[i].backgroundColor.push('#92d050')
          }
          else {
            this.myChartstatus2.data.datasets[i].backgroundColor.push('#a83232')
          }
      }
    }
    this.myChartstatus2.update();
  }


  controldisable () {
    this.myControl.enable();
    this.Search_Dashboard.BULevel = '';
    this.tempbu = '';
    this.BUDD = [];
    this.Search_Dashboard.Childnode = false;
  }

  func_selBULevel () {
    const _s = this;
    let tempmod;
    this.Search_Dashboard.Childnode = false;
    this.tempbu = '';
    this.BUDD = [];


    if (_s.Search_Dashboard.BULevel == '1') {
      tempmod = 'FilterFG'
    } else if (_s.Search_Dashboard.BULevel == '2') {
      tempmod = 'FilterDivision'
    } else if (_s.Search_Dashboard.BULevel == '3') {
      tempmod = 'FilterDepartment'
    } else if (_s.Search_Dashboard.BULevel == '4') {
      tempmod = 'FilterAsset'
    }

    let d = new Date();
    let cur_year = d.getFullYear();

    let tempQuarterID = null , tempWPBID = null, tempQuarterMaster = null
    if (cur_year === parseInt(_s.Search_Dashboard.Year)) {
        if (_s.Search_Dashboard.Quarter.includes("WPB") === false ) {
            for (let i=0;i< this.QuarterDD.length; i++) {
                if (_s.Search_Dashboard.Quarter === this.QuarterDD[i].text) {
                    tempQuarterID = this.QuarterDD[i].value;
                    tempQuarterMaster = this.QuarterDD[i].value;
                    break;
                }
            }
        } 
        else {
            for (let i=0;i< this.QuarterDD.length; i++) {
                if (_s.Search_Dashboard.Quarter === this.QuarterDD[i].text) {
                    tempWPBID = this.QuarterDD[i].value;
                    break;
                }
            }
            for (let i=0;i< this.QuarterDD.length; i++) {
                if (_s.tempCurrentQuarterID === this.QuarterDD[i].text) {
                    tempQuarterMaster = this.QuarterDD[i].value;
                    break;
                }
            }
        }
    } 
    else {
        if (_s.Search_Dashboard.Quarter.includes("WPB") === false ) {
            for (let i=0;i< this.QuarterDD.length; i++) {
                if (_s.Search_Dashboard.Quarter === this.QuarterDD[i].text) {
                    tempQuarterID = this.QuarterDD[i].value;
                    tempQuarterMaster = this.QuarterDD[i].value;
                    break;
                }
            }
        } 
        else {
            for (let i=0;i< this.QuarterDD.length; i++) {
                if (_s.Search_Dashboard.Quarter === this.QuarterDD[i].text) {
                    tempWPBID = this.QuarterDD[i].value;
                    break;
                }
            }
            tempQuarterMaster = this.QuarterDD[3].value
        }
    }









    let tempData = {
      Module:tempmod,
      TextSearch7:tempQuarterMaster
    }
    _s.MServ.Master_DDL(tempData).then((response) => {
      if (response.data.status === true) {
        //console.log('DataBU',response.data.body)
        this.BUDD = response.data.body;
      } 
      else {
        alert(response.data.errorMessage);
        //console.log('false',response)
      }
      
    });
  }

  closealertdanger () {
      $('#modalAlertreportdash').modal('hide');
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    if (filterValue !== '') {
        return this.BUDD.filter(option => option.text.toLowerCase().includes(filterValue));
    } else {
        return this.BUDD
    }
  }
} 