<div class="container-fluid appform my-3">
    <div class="text-center">
       <div class="spinner-grow loading" role="status" id="loader" [hidden]="loading === false">
           <span class="sr-only">Loading...</span>
       </div>
   </div>
   <div class="row" id="showdisplay" [hidden]="loading === true">
       <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">
           <div class="col-12 col-md-12 col-lg-12 col-xl-12 pb-5 mt-5 mb-5" [hidden]="page === 2">
               <div class="px-0" [hidden]="true">
                   <div class="card mt-4 py-4">
                       <div class="col-12 col-xl-12 mb-4">
                           <div class="searcrisk col-8 col-xl-3 rounded" style="text-align: center;">
                               <strong style="font-size: 22px;">Document Reference</strong>
                           </div>
                           <div class="row col-12 col-xl-12 mt-3 mb-3 mx-0 px-0 d-flex justify-content-around">
                               <div class="col-12 px-0">
                                   <div class="col-6">
                                       <div class="row">
                                           <div class="col-6 row mx-0 px-0">
                                               <div class="col-12 mb-2">
                                                   <strong class="formtext">Year</strong>
                                               </div>
                                               <div class="col-12 pl-0">
                                                   <select class="form-control" [(ngModel)]="DataSearch.Yearfrom" (change)="func_selFromyear()">
                                                       <option value="">Please Select</option>
                                                       <option *ngFor="let item of DDLYear" value="{{ item.value }}">{{ item.text }}</option>
                                                   </select>
                                               </div>
                                           </div>
                                           <div class="col-6 row mx-0 px-0">
                                               <div class="col-12 mb-2">
                                                   <strong class="formtext">Quarter</strong>
                                               </div>
                                               <div class="col-12 pr-0">
                                                   <select class="form-control" [(ngModel)]="DataSearch.Quarterfrom" [disabled]="DataSearch.Yearfrom === '' ">
                                                       <option value="">Please Select</option>
                                                       <option *ngFor="let item of DDLQuarter" value="{{ item.value }}">{{ item.text }}</option>
                                                   </select>
                                               </div>
                                           </div>
                                       </div>
                                   </div>
                               </div>

                               <div class="row col-12 col-xl-7"></div>
                           </div>
                       </div>

                       <div class="col-12" style="text-align: center;">
                           <button type="button" class="btn mr-2" style="background: #9eb0ba;color: white;font-size:20px;width:100px;" (click)="func_Clear()">Clear</button>
                           <button type="button" class="btn" style="background: #023154;color: white;font-size:20px;width:100px;" (click)="func_GETDATA()">Search</button>
                       </div>
                   </div>
               </div>            
               <div class="col-12 px-0 mb-2" style="text-align: right;">
                   <button type="button" class="btn btn-save" (click)="func_addcatalog()" *ngIf="loginRole === 'ERM'">
                       <em class="fas fa-plus icon"></em>&nbsp;&nbsp;&nbsp;Add Document Reference
                   </button>
               </div>

               <div class="card p-2 mt-2" *ngFor="let item of tempcatalog">
                   <div class="row col-12 px-0 mx-0">
                       <div class="col-12 col-xl-3 px-0" *ngIf="item.itemAttSeq1.length === 0">
                           <img  alt="" src="{{ item.img }}" width="100%" height="250">
                       </div>
                       <div class="col-12 col-xl-3 px-0" *ngIf="item.itemAttSeq1.length !== 0" style="text-align: center;" >
                           <span *ngFor="let item2 of item.itemAttSeq1">
                               <img  alt="" src="{{ path }}/{{ item2.pathFile }}" class="rounded bordercss img-fix-showdisplay">
                           </span>
                       </div>
                       <div class="row col-12 col-xl-9 mx-0 pr-0" style="font-size: 20px;">
                           <div class="col-12 col-xl-10">
                               <strong style="font-size:22px;color:#f48f0c;">{{ item.riskCatalogTitle }}</strong>
                           </div>
                           <div class="col-12 col-xl-2 pr-0" style="text-align: right;">
                               <button type="button" class="btn" (click)="func_edit(item)" *ngIf="loginRole === 'ERM'">
                                   <em class="fas fa-edit" style="color:orange;font-size:22px;"></em>
                               </button>
                               <button type="button" class="btn" (click)="RemoveDocument(item)" *ngIf="loginRole === 'ERM'">
                                   <em class="far fa-trash-alt text-danger" style="color:orange;font-size:22px;"></em>
                               </button>
                           </div>
                           <div class="col-12">
                               <p [innerHTML]="item.riskCatalogDescsubstr"></p>
                           </div>
                           <div class="col-12">
                               <span style="cursor: pointer;" target="_blank" class="mr-3 btn-file" *ngFor="let item3 of item.itemAttSeq2 index as index3" (click)="downloadfile(item3.pathFile)">File {{ item3.fileName }}</span>
                           </div>
                           <div class="col-12 col-xl-10">
                               <p>Create by : {{ item.createBy }} | Create Date : {{ item.createDateTime }}</p>
                           </div>
                           <div class="col-12 col-xl-2" style="text-align: right;">
                               <button type="button" class="btn btn-expaned" style="font-size: 20px;" (click)="func_ExpandCollapse(item ,1)">Expand</button>
                           </div>
                       </div>
                   </div>
               </div>
           </div>

           <div class="col-12 col-xl-12 pb-5 mt-5 mb-5 min-width" [hidden]="page === 1">
               <div class="px-0 pb-2">
                   <div class="card mt-4 py-4">

                       <div class="col-12 mb-4">
                           <strong style="font-size:24px;color:#f48f0c;">{{ this.RegisCatalog.RegisSubjectName }}</strong><br>
                           <hr>
                       </div>
                       <div class="col-12 mb-5" style="text-align: center;" *ngIf="RegisCatalog.RegisFileimg.length === 0">
                           <img  alt="" src="../../../assets/logo/photo-1103595_1280.png" width="680" height="350">
                       </div>
                       <div class="col-12 mb-5" style="text-align: center;" *ngIf="RegisCatalog.RegisFileimg.length !== 0">
                           <img  alt="" src="{{ path }}/{{ item.pathFile }}"  class="bordercss img-fix-showdisplayExpand" *ngFor="let item of RegisCatalog.RegisFileimg">
                       </div>
                       <div class="col-12 mb-4" style="font-size: 20px;">
                           <p class="pl-3">Create by : {{ this.RegisCatalog.RegisCreateby }} | Create Date : {{ this.RegisCatalog.RegisCreatedate }}</p>
                       </div>
                       <div class="col-12 mb-4" style="font-size: 20px;">
                           <p class="p-3 border" [safehtml]="RegisCatalog.RegisDescription">
                           </p>
                       </div>
                       <div class="row col-12 px-0 mx-0">
                           <div class="col-10">
                               <span style="cursor: pointer;"  target="_blank" class="mr-3 btn-file" *ngFor="let item of RegisCatalog.RegisFile index as index1" (click)="downloadfile(item.pathFile)">File {{ item.fileName }}</span>
                           </div>
                           <div class="col-2" style="text-align: right;">
                               <button type="button" class="btn btn-expaned" style="font-size: 20px;" (click)="func_ExpandCollapse('' , 2)">Collapse</button>
                           </div>
                       </div>
                   </div>
               </div>
           </div>

       </div>
   </div>
</div>

<div class="modal fade bd-Mianmenu" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" id="modaladdCatalog">
   <div class="modal-dialog modal-xl">
       <div class="modal-content">
           <div class="container-fluid appform my-3">
               <div class="row">
                   <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">
                       <div class="container col-12 col-md-12 col-lg-12 col-xl-12 pb-0 mt-0 mb-0 ">
                           <div class="container">
                               <div class="col-3 pt-2 rounded modalbanner">
                                   <em class="fas fa-bars icon"></em>
                                   <span *ngIf="statusbtn === 'insert'">&nbsp;&nbsp;Add Document Reference</span>
                                   <span *ngIf="statusbtn === 'edit'">&nbsp;&nbsp;Edit Document Reference</span>
                               </div>
                               <div class="card mt-4 py-3">
                                   <div class="row col-12 mb-4 mx-0 px-0">
                                       <div class="col-12 mb-2">
                                           <strong class="formtext">Document Reference Title</strong>
                                       </div>
                                       <div class="col-12">
                                           <input type="text" class="form-control"  [(ngModel)]="RegisCatalog.RegisSubjectName">
                                       </div>
                                   </div>
                                   <div class="row col-12 mb-4 mx-0 px-0">
                                       <div class="col-12 mb-2">
                                           <strong class="formtext">Description</strong>
                                       </div>
                                       <div class="col-12">
                                           <div [formGroup]="form1" style="background-color:white">
                                               <div [ngxSummernote]="config" formControlName="html">
                                               </div>
                                           </div>
                                       </div>
                                   </div>
                                   <div class="row col-12 mb-4 mx-0 px-0">
                                       <div class="col-12 mb-2">
                                           <strong class="formtext">Image</strong>
                                           <span style="color:red;">
                                           (.jpg, .jpeg, .png) maximum image size is 2.5 MB.
                                          </span>
                                       </div>
                                       <div class="col-4 input-group">
                                           <div class="custom-file">
                                               <input #imgInput type="file" class="custom-file-input" accept="image/png, image/jpg, image/jpeg" id="inputGroupFile01" (change)="func_AddImage($event)">
                                               <label class="custom-file-label" for="inputGroupFile01">
                                                   <span *ngIf="tempDisplayImage.length === 0">No file Choose</span>
                                                   <span *ngIf="tempDisplayImage.length !== 0">{{ tempDisplayImage[0].name }}</span>
                                               </label>
                                           </div>
                                       </div>
                                       <div class="col-7">
                                           <img  alt="" src="../../../assets/logo/photo-1103595_1280.png" height="250px" *ngIf="url === null">
                                           <img  alt="" [src]="url" class="bordercss img-fix-insertedit" *ngIf="url !== null">
                                       </div>
                                   </div>

                                   <div class="row col-12 mb-4 mx-0 px-0">
                                       <div class="col-12 mb-2">
                                           <strong class="formtext">File</strong>
                                           <span style="color:red;">
                                            maximum file size is 50 MB.
                                          </span>
                                       </div>
                                       <div class="col-4 input-group mb-3">
                                           <div class="custom-file">
                                               <input #fileInput type="file" class="custom-file-input" id="inputGroupFile02" (change)="func_AddFile($event)" multiple>
                                               <label class="custom-file-label" for="inputGroupFile02">
                                                   <span *ngIf="tempDisplayFile.length === 0">No file Choose</span>
                                                   <span *ngIf="tempDisplayFile.length !== 0">Selected {{ tempDisplayFile.length }} Files</span>
                                               </label>
                                           </div>
                                       </div>
                                       <div class="col-12">
                                           <div *ngFor="let item of tempDisplayFile; index as i">
                                               <span class="badge badge-custom"><strong>{{ item.name }}</strong></span>
                                               <button type="button" class="btn btn-sm btn-danger btn-custom" (click)="RemoveFile(item, i)">
                                                   <em class="fas fa-times"></em>
                                               </button>
                                           </div>
                                       </div>
                                   </div>


                               </div>
                               <div class="col-12 my-3 mx-0 text-center">
                                   <button type="button" class="btn mr-3" style="width: 100px;background-color:#9eb0ba;color:white;" (click)="func_close()">Close</button>
                                   <button type="button" class="btn" style="width: 100px;background-color:#033153;color:white;" (click)="func_save('insert')" *ngIf="statusbtn === 'insert'">Save</button>
                                   <button type="button" class="btn" style="width: 100px;background-color:#033153;color:white;" (click)="func_save('update')" *ngIf="statusbtn === 'edit'">Update</button>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
       </div>
   </div>
</div>
<div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalConfirm">
   <div class="modal-dialog modal-dialog-centered" role="document"> 
   <div class="modal-content">
       <span class="bg-warning">&nbsp;</span>
       <div class="modal-header">
           <div class="row col-12 text-warning">
               <div class="col-2">
                   <em class="fas fa-exclamation-triangle" style="font-size:50px;"></em>
               </div>
               <div class="col-10 pt-2">
                   <h4 class="modal-title">Alert !</h4>
               </div>
           </div>
       </div>
       <div class="modal-body text-body">
           <div class="col-12" style="text-align: left;">
               <span style="font-size:18px;">Are you sure you want to delete it ?</span>
           </div>
       </div>
       <div class="modal-footer">
           <button type="button" class="btn btn-warning text-white w-25" (click)="funcYes(statusbtn)">Yes</button>
           <button type="button" class="btn btn-secondary w-25" (click)="funcNo()">No</button>
       </div>
   </div>
   </div>
</div>

<div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalDeleteWarning">
   <div class="modal-dialog modal-dialog-centered" role="document">
       <div class="modal-content">
           <span class="bg-warning">&nbsp;</span>
           <div class="modal-header">
               <div class="row col-12 text-warning">
                   <div class="col-2">
                       <em class="fas fa-exclamation-triangle" style="font-size:50px;"></em>
                   </div>
                   <div class="col-10 pt-2">
                       <h4 class="modal-title">Alert !</h4>
                   </div>
               </div>
           </div>
           <div class="modal-body text-body border-0">
               <div class="col-12" align="left">
                   <span style="font-size:18px;">Are you sure you want to delete this item?</span>
               </div>
           </div>
           <div class="modal-footer">
               <button type="button" class="btn btn-warning w-25 text-white" (click)="func_save('delete')">Yes</button>
               <button type="button" class="btn btn-secondary w-25" data-dismiss="modal">No</button>
           </div>
       </div>
   </div>
</div>

<div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalAlert">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <span class="bg-danger">&nbsp;</span>
            <div class="modal-header">
                <div class="row col-12 text-danger">
                    <div class="col-2">
                        <em class="fas fa-exclamation-triangle" style="font-size:50px;"></em>
                    </div>
                    <div class="col-10 pt-2">
                        <h4 class="modal-title">Alert !</h4>
                    </div>
                </div>
            </div>
            <div class="modal-body text-body border-0">
                <div class="col-12" align="left">
                    <span style="font-size:18px;">{{alertText}}</span>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger w-25" data-dismiss="modal">OK</button>
            </div>
        </div>
    </div>
 </div>

 <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalAlertsuccess">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <span class="bg-success">&nbsp;</span>
        <div class="modal-header">
          <div class="row col-12 text-success">
            <div class="col-2">
              <em class="far fa-check-circle" style="font-size:50px;"></em>

            </div>
            <div class="col-10 pt-2">
              <h4 class="modal-title">Alert !</h4>
            </div>
          </div>
        </div>
        <div class="modal-body text-body border-0">
          <div class="col-12" style="text-align: left;">
            <span style="font-size:18px;">{{alertText}}</span>
          </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-success w-25" data-dismiss="modal">OK</button>
        </div>
      </div>
    </div>
  </div>