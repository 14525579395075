import { PageDashboard } from './components/dashboard/page.dashboard';
import { TreeViewComponent } from './components/_comp/treeview.component';
import { LoadingComponent } from './components/_comp/loading.component';
import { Approval } from './components/Approval/page.Approval';
import { ContactHelp } from './components/ContactHelp/page.ContactHelp';
import { Identification } from './components/Identification/page.Identification';
import { Assessment } from './components/Assessment/page.Assessment';
import { NewAssessment } from './components/NewAssessment/page.NewAssessment';
import { SearchRisk } from './components/SearchRisk/page.searchrisk';
import { ReviewPage } from './components/ReviewPage/page.ReviewPage';
import { ConfigTopMenu } from './components/master/ConfigTopMenu/configtopmenu';
import { MasterRiskCategory } from './components/master/MasterRiskCategory/masterriskcategory';
import { MasterLikelihood } from './components/master/MasterLikelihood/masterlikelihood';
import { MasterRiskRating } from './components/master/MasterRiskRating/masterriskrating';
import { MasterBuCoordinator } from './components/master/MasterBuCoordinator/masterbucoordinator';
import { MasterFinancial } from './components/master/MasterFinancial/masterfinancial';
import { ConsolidateRisk } from './components/ConsolidateRisk/page.consolidaterisk';
import { MasterInstruction } from './components/master/MasterInstruction/masterinstruction';
import { MasterBanner } from './components/master/MasterBanner/masterbanner';
import { Comparerisk } from './components/CompareRiskProfile/page.comparerisk';
import { MasterPermission } from './components/master/MasterPermission/masterpermission';
import { RiskCatalog } from './components/RiskCatalog/page.RiskCatalog';
import { RiskCriteria } from './components/RiskCriteria/page.RiskCriteria';
import { MasterImpactCate } from './components/master/MasterImpactCate/masterimpactcate';
import { MasterQuarter } from './components/master/MasterQuarter/masterquarter';
import { MasterContactUs } from './components/master/MasterContactUs/mastercontactus';
import { ReportRiskItem } from './components/Report/ReportRiskItem/ReportRiskItem';
import { CorporateTarget } from './components/master/MasterCorporateTarget/CorporateTarget';
import { ConfigAssetUnit } from './components/master/MasterConfigAssetUnit/ConfigAssetUnit';
import { ConfigWPB } from './components/master/MasterConfigWPB/ConfigWPB';
import { MasterEmail } from './components/master/MasterEmail/MasterEmail';
import { TransferRisk } from './components/TransferRisk/TransferRisk';
import { RiskProfileDashBoard } from './components/RiskProfileDashBoard/RiskProfileDashBoard';
import { Delegate } from './components/Delegate/page.Delegate';
import { RegisterAssessmentComponent } from './StrategicRisks/components/register-assessment/register-assessment.component';
import { MonitorReviewComponent } from './StrategicRisks/components/monitor-review/monitor-review.component';
import { SummaryShareComponent } from './StrategicRisks/components/summary-share/summary-share.component';
import { RegisterAssessmentReviewModule } from './StrategicRisks/components/monitor-review/register-assessment-review/module.exports';
import { RegisterAssessmentReviewComponent } from './StrategicRisks/components/monitor-review/register-assessment-review/register-assessment-review.component';
import {MasterAssumptionCategoryComponent } from './components/master/MasterAssumptionCategory/master-assumption-category.component';
import { MonitorComponent } from './StrategicRisks/components/monitor-review/monitor/monitor.component';
import { RegisterSummaryComponent } from './StrategicRisks/components/register-assessment/register-summary/register-summary.component';

export const INTERNAL_COMPONENTS = [
    PageDashboard,
    TreeViewComponent,
    LoadingComponent,
    Approval,
    ContactHelp,
    Identification,
    Assessment,
    NewAssessment,
    SearchRisk,
    ReviewPage,
    ConfigTopMenu,
    MasterRiskCategory,
    MasterLikelihood,
    MasterRiskRating,
    MasterBuCoordinator,
    MasterFinancial,
    ConsolidateRisk,
    MasterInstruction,
    MasterBanner,
    Comparerisk,
    MasterPermission,
    RiskCatalog,
    RiskCriteria,
    MasterImpactCate,
    MasterQuarter,
    MasterImpactCate,
    MasterContactUs,
    ReportRiskItem,
    CorporateTarget,
    ConfigAssetUnit,
    ConfigWPB,
    MasterEmail,
    TransferRisk,
    RiskProfileDashBoard,
    Delegate,
    RegisterAssessmentComponent,
    MonitorReviewComponent,
    SummaryShareComponent,
    RegisterAssessmentReviewModule,
    MasterAssumptionCategoryComponent,
    MonitorComponent,
    RegisterSummaryComponent
 
];

export const ROUTES_COMPONENTS = [
    { path: 'Page-dashboard', component: PageDashboard },
    { path: 'Approval/:status', component: Approval },
    { path: 'Approval/:status/:Quarter', component: Approval },
    { path: 'ContactHelp', component: ContactHelp },
    { path: 'Identification', component: Identification },
    { path: 'Identification/:display', component: Identification },
    { path: 'Assessment', component: Assessment},
    { path: 'Assessment/:display', component: Assessment},
    { path: 'NewAssessment', component: NewAssessment},
    { path: 'NewAssessment/:display', component: NewAssessment },
    { path: 'ReviewPage', component: ReviewPage },
    { path: 'ReviewPage/:display', component: ReviewPage },
    { path: 'SearchRisk', component: SearchRisk },
    { path: 'SearchRisk/:Quarter', component: SearchRisk },
    { path: 'ConfigTopMenu', component: ConfigTopMenu },
    { path: 'MasterRiskCategory', component: MasterRiskCategory },
    { path: 'MasterLikelihood', component: MasterLikelihood },
    { path: 'MasterRiskRating', component: MasterRiskRating },
    { path: 'MasterBuCoordinator', component: MasterBuCoordinator },
    { path: 'MasterFinancial', component: MasterFinancial },
    { path: 'ConsolidateRisk', component: ConsolidateRisk },
    { path: 'ConsolidateRisk/:Quarter', component: ConsolidateRisk },
    { path: 'MasterInstruction', component: MasterInstruction},
    { path: 'MasterBanner', component: MasterBanner},
    { path: 'Compare-riskprofile', component: Comparerisk},
    { path: 'MasterPermission', component: MasterPermission},
    { path: 'RiskCatalog', component: RiskCatalog},
    { path: 'RiskCriteria', component: RiskCriteria},
    { path: 'MasterImpactCate', component: MasterImpactCate},
    { path: 'MasterQuarter', component: MasterQuarter},
    { path: 'MasterContactUs', component: MasterContactUs},
    { path: 'ReportRiskItem', component: ReportRiskItem},
    { path: 'ReportRiskItem/:Quarter', component: ReportRiskItem},
    { path: 'CorporateTarget', component: CorporateTarget},
    { path: 'ConfigAssetUnit', component: ConfigAssetUnit},
    { path: 'ConfigWPB', component: ConfigWPB},
    { path: 'MasterEmail', component: MasterEmail},
    { path: 'TransferRisk', component: TransferRisk},
    { path: 'RiskProfileDashBoard', component: RiskProfileDashBoard},
    { path: 'Delegate', component: Delegate},
    { path: 'Register-Assessment', component: RegisterAssessmentComponent},
    { path: 'Register-Assessment/:Id', component: RegisterAssessmentComponent},
    { path: 'Monitor-Review', component: MonitorReviewComponent,},
    { path: 'Summary-Share', component: SummaryShareComponent},
    { path: 'Monitor-Review/Review/:Id', component: RegisterAssessmentReviewComponent},
    { path: 'MasterAssumtionCategory', component: MasterAssumptionCategoryComponent},
    { path: 'Register-Assessment/Summary/:Id', component: RegisterSummaryComponent},
    { path: 'Monitor-Review/Monitor/:Id', component: MonitorComponent},
];