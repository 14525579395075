import { Injectable } from '@angular/core';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { AuthenService } from './authenservice.service';

@Injectable({
    providedIn: 'root',
})
export class AxiosInterceptorService {
    private axiosInstance: any;
    constructor(public authenService: AuthenService) {
        // Create an instance of Axios
        this.axiosInstance = axios.create();

        // Add request interceptor
        this.axiosInstance.interceptors.request.use(
            (config: AxiosRequestConfig) => {
                // You can modify the request config here (e.g., add headers)
                let token = sessionStorage.getItem('apiToken') ? sessionStorage.getItem('apiToken') : '';
                config.headers['Authorization'] = `Bearer ${token}`
                //console.log('Request Interceptor:', config);
                return config;
            },
            (error) => {
                // Handle request errors
                console.error('Request Error Interceptor:', error);
                return Promise.reject(error);
            }
        );

        // Add response interceptor
        this.axiosInstance.interceptors.response.use(
            (response: AxiosResponse) => {
                // You can modify the response data here
                //console.log('Response Interceptor:', response);
                return response;
            },
            async (error) => {
                const originalRequest = error.config;
                if ((error.response.status === 401) && !originalRequest._retry) {
                    let apiToken = sessionStorage.getItem('apiToken') ? sessionStorage.getItem('apiToken') : '';
                    let refreshToken = sessionStorage.getItem('refreshToken') ? sessionStorage.getItem('refreshToken') : '';
                    try {
                        originalRequest._retry = true;
                        const tokenResponse: any = await authenService.getRefreshToken(apiToken, refreshToken);

                        if (!!tokenResponse?.data?.accessToken && !!tokenResponse?.data?.refreshToken) {
                            let accessToken = tokenResponse.data.accessToken;
                            sessionStorage.setItem('apiToken', accessToken);
                            originalRequest.headers['Authorization'] = `Bearer ${accessToken}`
                        } else {
                            return authenService.clearStorageLogout()
                        }
                        return this.axiosInstance(originalRequest);
                    } catch (error) {
                        authenService.clearStorageLogout()
                        return
                    }
                }
                // Handle response errors
                console.error('Response Error Interceptor:', error);
                return Promise.reject(error);
            }
        );
    }
    // You can expose the Axios instance for further customization if needed
    getAxiosInstance(): AxiosInstance {
        return this.axiosInstance;
    }
}