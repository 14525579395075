<div class="container-fluid appform my-3">
    <div class="col-12 text-center">
        <div class="spinner-grow loading" role="status" id="loader" [hidden]="loading === false">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div class="row" id="showdisplay" [hidden]="loading === true">
        <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1 min-width" style="font-size: 14px;" >
            <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-0" >
                <div class="px-0 pb-2" style="background: #eceff6;"> 
                    <div class="row col-12 px-0 mx-0" style="border:1.5px solid #93b8c5">
                        <div class="col-3 p-4" style="text-align: center; background-color: #077ab4;color: white;">
                            <h4 class="HeaderRiskname m-0">Risk Name<span *ngIf="Register_Data.Regis_RiskNo != '' ">/Risk ID:</span><span class="text-danger">*</span></h4>
                            <h4 class="HeaderRiskname m-0">{{ Register_Data.Regis_RiskNo }}</h4>
                        </div>
                        <div class="col-9 px-0">
                            <input type="text" class="form-control border-0 HeaderRiskname-input" style="font-size:30px;" placeholder="Enter Risk Name" [(ngModel)]="Register_Data.Regis_Riskname">
                        </div>
                    </div>
                    <div class="mt-4">
                        <div class="row col-12 mx-0 px-0 d-flex justify-content-around" style="background:#e2e9f3;">
                            <div class="row col-5 p-2 ml-0 mr-2" style="background-color:#eceff6;height:100%;">
                                <div class="row col-12 mb-4 blog-css">
                                    <div class="col-12 mb-3">
                                        <strong style="font-size:20px;color:#232091;">Preliminary Likelihood:<span class="text-danger">*</span></strong>
                                    </div>
                                    <div class="col-12">
                                        <select class="form-control" [(ngModel)]="Register_Data.Regis_Likelyhood" (change)="func_PlotRiskMap()" style="border: 2px solid #02adf0;">
                                            <option value="">Please Select</option>
                                            <option *ngFor="let data of Preliminarylikelyhood" value="{{ data.value }}">{{ data.text }}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="row col-12 mb-4 blog-css">
                                    <div class="col-12 mb-3">
                                        <strong style="font-size:20px;color:#232091;">Preliminary Impact:<span class="text-danger">*</span></strong>
                                    </div>
                                    <div class="col-12">
                                        <select class="form-control" [(ngModel)]="Register_Data.Regis_Impact" (change)="func_PlotRiskMap()" style="border: 2px solid #02adf0;">
                                            <option value="">Please Select</option>
                                            <option *ngFor="let data of PreliminaryImpactLevel" value="{{ data.value }}">{{ data.text }}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="row col-12 mb-4 blog-css" [hidden]="checkstatusupdate === 'edit'">
                                    <div class="col-12" style="margin-bottom: 80px;">
                                        <strong style="font-size:20px;color:#232091;">&nbsp;</strong>
                                    </div>
                                </div>

                                <div class="row col-12 mb-4 blog-css" [hidden]="checkstatusupdate !== 'edit'">
                                    <div class="col-12 mb-3">
                                        <strong style="font-size:20px;color:#232091;">Status:</strong>
                                    </div>
                                    <div class="col-12">
                                        <select class="form-control" [(ngModel)]="Register_Data.Regis_Status" style="border: 2px solid #02adf0;">
                                            <option value="">Please Select</option>
                                            <option *ngFor="let item of StatusIden" value="{{ item.value }}" [disabled]="item.value === '3' && checkDDstatus === false">{{ item.text }}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-12 bg-img-bot" style="text-align: right;">
                                    <img  alt="" src="../../../assets/logo/Vector-Smart-Object-IdenIcon1.png" class="img-form">
                                </div>

                            </div>
                            <div class="row col-6 px-0" style="background-color: #e2e9f3;">
                                <div class="col-12 px-0 tablerisk-card">
                                    <div class="card-body row m-0 p-1" >
                                      <div class="col-12 p-0">
                                        <table class="custom" id="test2" >
                                            <caption style="display: none;" >This is a custom table</caption>
                                            <tr>
                                                <th></th>
                                              </tr>
                                            <!-- list.risk.risktable ,data_risk_table -->
                                          <tr *ngFor="let item1 of  list.risk.risktable; index as index1;">
                                            <td *ngFor="let item2 of item1.col; index as index2;" class="box">
                                              <div class="content text-center" class="{{item2.class}}">
                                                <ng-container *ngFor="let item3 of item2.item; index as index3;">
                                                  <span *ngIf="item2.class === 'high'">
                                                    <span class="badge badge-pill badge-light txt-title" [ngStyle] = "{'background-color': item3.bgcolor, 'color': item3.color}" data-toggle="tooltip" data-placement="top"><strong>{{item3.title}}</strong></span>
                                                  </span>
                                                  <span *ngIf="item2.class === 'medium'">
                                                    <span class="badge badge-pill badge-light txt-title" [ngStyle] = "{'background-color': item3.bgcolor, 'color': item3.color}" data-toggle="tooltip" data-placement="top"><strong>{{item3.title}}</strong></span>
                                                  </span>
                                                  <span *ngIf="item2.class === 'low'">
                                                    <span class="badge badge-pill badge-light txt-title" [ngStyle] = "{'background-color': item3.bgcolor, 'color': item3.color}" data-toggle="tooltip" data-placement="top"><strong>{{item3.title}}</strong></span>
                                                  </span>
                                                  <span *ngIf="item2.class === 'Escalation'">
                                                    <span class="badge badge-pill badge-light txt-title" [ngStyle] = "{'background-color': item3.bgcolor, 'color': item3.color}" data-toggle="tooltip" data-placement="top"><strong>{{item3.title}}</strong></span>
                                                  </span>
                                                  <div [ngStyle] = "{'background-color': item3.bgcolor , color:item3.color }"  *ngIf="item2.class === 'impact-y'" class="p-2">
                                                    <span class="font-size">{{item3.title}}</span>
                                                  </div>
                                                  <div [ngStyle] = "{'background-color': item3.bgcolor , color:item3.color}" style="padding-top: 50px;" class="p-2" *ngIf="item2.class === 'impact-x'" >
                                                    <span>{{item3.title}}</span>
                                                  </div>
                                                </ng-container>
                                              </div>
                                            </td>
                                          <tr>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                            </div>
                            <div class="col-1 px-0 form-bg-select" style="background-color:#e2e9f3;width:20px;"></div>

                        </div>
                    </div>
                    <div class="col-12 height-blank"></div>
                    <div class="row col-12 px-0 mx-0" style="background:#e2e9f3;">
                        <div class="col-6 pl-0">
                            <button type="button" class="btn btn-left-side" (click)="func_RegisFirst()">
                                <div class="row col-12 mx-0 px-0">
                                    <div class="col-2">
                                        <em class="fas fa-arrow-circle-left" style="font-size:50px;"></em>
                                    </div>
                                    <div class="col-8 pt-1">
                                        <strong style="font-size:24px;" [hidden]="checkstatusupdate === 'edit'">Preliminary Register</strong>
                                        <strong style="font-size:24px;" [hidden]="checkstatusupdate !== 'edit'">Update Preliminary</strong>
                                    </div>
                                    <div class="col-2">
                                        <img  alt="" src="../../../assets/logo/Vector-Smart-Object-IdenIcon2.png" width="60">
                                    </div>
                                </div>
                            </button>
                        </div>

                        <div class="col-6 pr-0">
                            <button type="button" class="btn btn-right-side" (click)="func_SaveRegis()">
                                <div class="row col-12 mx-0 px-0">
                                    <div class="col-2">
                                        <img  alt="" src="../../../assets/logo/Vector-Smart-Object-IdenIcon3.png" width="60">
                                    </div>
                                    <div class="col-8 pt-1">
                                        <strong style="font-size:24px;" [hidden]="checkstatusupdate === 'edit'">Register & Provide More Info</strong>
                                        <strong style="font-size:24px;" [hidden]="checkstatusupdate !== 'edit'">Update & Provide More Info</strong>
                                    </div>
                                    <div class="col-2">
                                        <em class="fas fa-arrow-circle-right" style="font-size:50px;"></em>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>           
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalBU5">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Please Select Business Unit for Register Risk Profile</h5>
                </div>
                <div class="modal-body">
                    <div class="col-12" *ngIf="ShowWPB === true">
                        <div class="row">
                            <div class="col-4 pt-2">
                                <strong style="font-size:18px;">Select Type</strong>
                            </div>
                            <div class="col-8 px-0">
                                <select class="form-control" [(ngModel)]="SelectWPB">
                                    <option value="1">Normal</option>
                                    <option value="2">WPB</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row col-12 mx-0 px-0">
                        <div class="col-4 pt-2">
                            <strong style="font-size:18px;">Business Unit Name</strong>
                        </div>
                        <div class="col-8 px-0 pt-2">
                            <input type="text" class="form-control m-0" aria-label="Number" matInput [formControl]="myControlBUDD" [matAutocomplete]="auto1" [(ngModel)]="tempselectbu"  style="font-size:16px;">
                            <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete">
                                <mat-option *ngFor="let option of filteredOptionBUDD | async" [value]="option.text">
                                {{option.text}}: {{option.fullname}}
                                </mat-option>
                            </mat-autocomplete>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12" style="text-align: center;">
                        <button type="button" style="width:100px;" class="btn btn-primary mr-2" (click)="func_OkBU()">OK</button>
                        <button type="button" style="width:100px;" class="btn btn-secondary" (click)="goHome()">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalAlert5">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <span class="bg-danger">&nbsp;</span>
                <div class="modal-header">
                    <div class="row col-12 text-danger">
                        <div class="col-2">
                            <em class="far fa-times-circle" style="font-size:50px;"></em>
                        </div>
                        <div class="col-10 pt-2">
                            <h4 class="modal-title">Alert !</h4>
                        </div>
                    </div>
                </div>
                <div class="modal-body text-body border-0">
                    <div class="col-12" style="text-align: left;">
                        <span style="font-size:18px;">{{ alert }}</span>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger w-25" (click)="close()">OK</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalAlertdanger5">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <span class="bg-danger">&nbsp;</span>
                <div class="modal-header">
                    <div class="row col-12 text-danger">
                        <div class="col-2">
                            <em class="far fa-times-circle" style="font-size:50px;"></em>
                        </div>
                        <div class="col-10 pt-2">
                            <h4 class="modal-title">Alert !</h4>
                        </div>
                    </div>
                </div>
                <div class="modal-body text-body border-0">
                    <div class="col-12" style="text-align: left;">
                        <span style="font-size:18px;">{{ alertflow }}</span>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger w-25" (click)="closeflow()">OK</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalAlertsuccess5">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <span class="bg-success">&nbsp;</span>
                <div class="modal-header">
                    <div class="row col-12 text-success">
                        <div class="col-2">
                            <em class="far fa-check-circle" style="font-size:50px;"></em>
                        </div>
                        <div class="col-10 pt-2">
                            <h4 class="modal-title">Alert !</h4>
                        </div>
                    </div>
                </div>
                <div class="modal-body text-body border-0">
                    <div class="col-12" style="text-align: left;">
                        <span style="font-size:18px;">{{ alertflow }}</span>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-success w-25" (click)="closeflowsuccess()">OK</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalAlertwarning5">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <span class="bg-warning">&nbsp;</span>
                <div class="modal-header">
                    <div class="row col-12 text-warning">
                        <div class="col-2">
                            <em class="fas fa-exclamation-triangle" style="font-size:50px;"></em>
                        </div>
                        <div class="col-10 pt-2">
                            <h4 class="modal-title">Alert !</h4>
                        </div>
                    </div>
                </div>
                <div class="modal-body text-body border-0">
                    <div class="col-12" style="text-align: left;">
                        <span style="font-size:18px;">This transaction will re-work flow from the start.</span><br>
                        <span style="font-size:18px;">Are you sure you want to update?</span>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-warning w-25 text-white" (click)="Yeswarning()">YES</button>
                    <button type="button" class="btn btn-secondary w-25" (click)="closewarning()">NO</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalAlertwarning52">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <span class="bg-warning">&nbsp;</span>
                <div class="modal-header">
                    <div class="row col-12 text-warning">
                        <div class="col-2">
                            <em class="fas fa-exclamation-triangle" style="font-size:50px;"></em>
                        </div>
                        <div class="col-10 pt-2">
                            <h4 class="modal-title">Alert !</h4>
                        </div>
                    </div>
                </div>
                <div class="modal-body text-body border-0">
                    <div class="col-12" style="text-align: left;">
                        <span style="font-size:18px;">This transaction will re-work flow from the start.</span><br>
                        <span style="font-size:18px;">Are you sure you want to update?</span>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-warning w-25 text-white" (click)="Yeswarning2()">YES</button>
                    <button type="button" class="btn btn-secondary w-25" (click)="closewarning2()">NO</button>
                </div>
            </div>
        </div>
    </div>
</div>