<div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalAlert" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <span class="bg-danger">&nbsp;</span>
      <div class="modal-header">
        <div class="row col-12 text-danger">
          <div class="col-2">
            <em class="far fa-times-circle" style="font-size:50px;"></em>
          </div>
          <div class="col-10 pt-2">
            <h4 class="modal-title">Alert !</h4>
          </div>
        </div>
      </div>
      <div class="modal-body text-body border-0">
        <div class="col-12" style="text-align: left;">
          <span style="font-size:16px;">{{message}}</span>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger w-25" (click)="closeAlert()">OK</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalSuccess" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <span class="bg-success">&nbsp;</span>
      <div class="modal-header">
        <div class="row col-12 text-success">
          <div class="col-2">
            <em class="far fa-check-circle" style="font-size:50px;"></em>
          </div>
          <div class="col-10 pt-2">
            <h4 class="modal-title">{{titleSuccess}}</h4>
          </div>
        </div>
      </div>
      <div class="modal-body text-body border-0">
        <div class="col-12" style="text-align: left;">
          <span style="font-size:16px;">{{message}}</span>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success w-25" (click)="closeAlertSuccess()">OK</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalConfirm" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <span class="bg-warning">&nbsp;</span>
      <div class="modal-header">
        <div class="row col-12 text-warning">
          <div class="col-2">
            <em class="fas fa-exclamation-triangle" style="font-size:50px;"></em>
          </div>
          <div class="col-10 pt-2">
            <h4 class="modal-title">{{title}}</h4>
          </div>
        </div>
      </div>
      <div class="modal-body text-body border-0">
        <div class="col-12" style="text-align: left;">
          <span style="font-size:16px;">{{message}}</span>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-warning w-25" (click)="confirm()">Confirm</button>
        <button type="button" class="btn btn-secondary w-25" (click)="cancel()">Cancel</button>
      </div>
    </div>
  </div>
</div>