export class AssumptionRedAlertModel {
    riskNo: string;
    assumptionTitle: string; 
    riskCategoryName: string;  
    assumptionDescription: string;  
    currentStatus: string; 
    actionRed: string; 
    percentProgress: string;
    rationale: string;
    EditStatus?: boolean;
}

export class StrategyModel {
    riskId: string;
    riskNo: string; 
    riskName: string;  
    riskStatus: string;
    riskRegisterBy: string;
    assumptionList : AssumptionRedAlertModel[];
}
