<div class="container-fluid appform my-3">
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">
            <div class="col-12 col-md-12 col-lg-12 col-xl-12 pb-5 mt-5 mb-5">
                <div class="px-0 pb-2">
                    <div class="card col-12 mt-4 mx-0 px-3">
                        <div class="coordinator col-12 mb-3 rounded">
                            <div class="col-0 pt-2">
                                <em class="fas fa-bars icon"></em>&nbsp;&nbsp;
                                Quarter
                            </div>
                        </div>
                        <div class="row col-12 col-md-12 col-xl-12 mb-3 mx-0 px-0">
                        </div>
                        <button type="button" class="btn btn-lg btn-addcoordinator-primary mb-3"
                            style="width: 200px;color:white;background:orange;" (click)="func_addriskrating()"><em
                                class="fas fa-plus icon"></em>&nbsp;&nbsp;&nbsp;Add Quarter
                        </button>

                        <div class="table-coordinator">
                            <table class="table table-bordered" style="width:100%;">
                                <caption style="display: none;" >This is a custom table</caption>
                                <thead style="text-align: center;background-color:#033153;color:white;font-size:18px;">
                                    <tr>
                                        <th style="width: 100px;vertical-align: middle;">ID</th>
                                        <th style="width: 100px;vertical-align: middle;">YEAR</th>
                                        <th style="width: 100px;vertical-align: middle;">QUARTER</th>
                                        <th style="width: 100px;vertical-align: middle;">Bu-Coodinator</th>
                                        <th style="width: 100px;vertical-align: middle;">Business Unite Value</th>
                                        <th style="width: 100px;vertical-align: middle;">Impact Rating</th>
                                        <th style="width: 100px;vertical-align: middle;">Likelihood Rating</th>
                                        <th style="width: 100px;vertical-align: middle;">Risk Category</th>
                                        <th style="width: 100px;vertical-align: middle;">Escalation level</th>
                                        <th style="width: 100px;vertical-align: middle;">Start Quarter</th>
                                        <th style="width: 100px;vertical-align: middle;">End Quarter</th>

                                    </tr>
                                </thead>
                                <tbody style="background-color: white;font-size:16px;">
                                    <tr *ngFor="let data of tablequarter"> 
                                        <td style="text-align: center;">{{data.quarterCode}}</td>
                                        <td style="text-align: center;">{{data.year}}</td>
                                        <td style="text-align: center;">{{data.quarterName}}</td>
                                        <td *ngIf="data.buCoodinator == '1'; else elseBlock1"></td>
                                        <ng-template #elseBlock1>
                                            <td style="text-align: center;">
                                                <input 
                                                    type="radio" 
                                                    name="buCoodinator_{{data.quarterID}}" 
                                                    [value]="1" 
                                                    checked
                                                >
                                                
                                            </td>
                                        </ng-template>
                                        <td *ngIf="data.businessUniteValue == '1'; else elseBlock2"></td>
                                        <ng-template #elseBlock2>
                                            <td style="text-align: center;">
                                                <input 
                                                    type="radio"
                                                    name="businessUniteValue_{{data.quarterID}}" 
                                                    [value]="1" 
                                                    checked
                                                >
                                            </td>
                                        </ng-template>
                                        <td *ngIf="data.impactRating == '1'; else elseBlock3"></td>
                                        <ng-template #elseBlock3>
                                            <td style="text-align: center;">
                                                <input 
                                                    type="radio" 
                                                    name="impactRating_{{data.quarterID}}" 
                                                    [value]="1" 
                                                    checked
                                                >
                                            </td>
                                        </ng-template>
                                        <td *ngIf="data.likelihoodRating == '1'; else elseBlock4"></td>
                                        <ng-template #elseBlock4>
                                            <td style="text-align: center;">
                                                <input 
                                                    type="radio" 
                                                    name="likelihoodRating_{{data.quarterID}}" 
                                                    [value]="1" 
                                                    checked
                                                >
                                            </td>
                                        </ng-template>
                                        <td *ngIf="data.riskCategory == '1'; else elseBlock5"></td>
                                        <ng-template #elseBlock5>
                                            <td style="text-align: center;"> 
                                                <input 
                                                    type="radio" 
                                                    name="riskCategory_{{data.quarterID}}" 
                                                    [value]="1" 
                                                    checked
                                                >
                                            </td>
                                        </ng-template>
                                        <td *ngIf="data.riskRating == '1'; else elseBlock6"></td>
                                        <ng-template #elseBlock6>
                                            <td style="text-align: center;">
                                                <input 
                                                    type="radio" 
                                                    name="riskRating_{{data.quarterID}}" 
                                                    [value]="1"  
                                                    checked
                                                >
                                            </td>
                                        </ng-template>
                                        <td style="text-align: center;">{{data.startQuarter}}</td>
                                        <td style="text-align: center;">{{data.endQuarter}}</td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="modal fade bd-Risk Coordinators" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" id="modaladdcoordinator">
                    <div class="modal-dialog modal-xl">
                        <div class="modal-content">
                            <div class="container-fluid appform my-3">
                                <div class="row">
                                    <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">
                                        <div class="container col-12 col-md-12 col-lg-12 col-xl-12 pb-0 mt-0 mb-0 ">
                                            <div class="container">
                                                <div class="col-12 mb-3 rounded madalcoordinator">
                                                    <div class="col-0 pt-2">
                                                        <em class="fas fa-bars icon"></em>&nbsp;&nbsp;
                                                        Quarter
                                                    </div>
                                                </div>
                                                <div class="card mt-4">
                                                    <div class="col-12 col-xl-12 mb-4">
                                                        <div class="row col-12 mx-0 px-0 d-flex justify-content-around">
                                                            <div class="row col-12 col-xl-4">
                                                                <div class="col-12 mb-2 px-0">
                                                                    <strong class="formtext">Quater Start:</strong>
                                                                </div>
                                                                <div class="col-12 px-0 input-group">
                                                                    <input id="quaterfrom" class="form-control" name="quaterfrom" [(ngModel)]="QuaterStart" ngbDatepicker #quaterfrom="ngbDatepicker">
                                                                    <div class="input-group-append">
                                                                        <button class="btn btn-sm calendar" (click)="quaterfrom.toggle()" type="button" style="color: #00aeef;font-size: 22px;bottom: 5px;">
                                                                            <em class="far fa-calendar-alt icon_calendar"></em>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row col-12 col-xl-4">
                                                                <div class="col-12 mb-2 px-0">
                                                                    <strong class="formtext">Quater End:</strong>
                                                                </div>
                                                                <div class="col-12 px-0 input-group">
                                                                    <input id="quaterto" class="form-control" name="quaterto" [(ngModel)]="QuaterEnd" ngbDatepicker #quaterto="ngbDatepicker">
                                                                    <div class="input-group-append">
                                                                        <button class="btn btn-sm calendar" (click)="quaterto.toggle()" type="button" style="color: #00aeef;font-size: 22px;bottom: 5px;">
                                                                            <em class="far fa-calendar-alt icon_calendar"></em>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-xl-12 mb-4">
                                                        <div class="row col-12 mx-0 px-0 d-flex justify-content-around">
                                                            <div class="table-coordinator">
                                                                <table class="table table-bordered" style="text-align: center;">
                                                                    <caption style="display: none;" >This is a custom table</caption>
                                                                    <thead style="background-color:#033153;color:white;width: 1200px;" style="text-align: center;">
                                                                        <tr>
                                                                            <th style="width:100px;">No.</th>
                                                                            <th style="width:300px;">Master Name</th>
                                                                            <th style="width:150px;">Un Copy</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody style="background-color: white;width: 1200px;" style="text-align: center;">
                                                                        <tr>
                                                                            <td>1</td>
                                                                            <td style="text-align: left;">Bu-Coodinator</td>
                                                                            <td>
                                                                                <input 
                                                                                    type="checkbox"
                                                                                    [(ngModel)]="BuCoodinator"
                                                                                >
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>2</td>
                                                                            <td style="text-align: left;">Business Unite Value</td>
                                                                            <td>
                                                                                <input 
                                                                                    type="checkbox"
                                                                                    [(ngModel)]="BusinessUniteValue"
                                                                                >
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>3</td>
                                                                            <td style="text-align: left;">Impact Rating</td>
                                                                            <td>
                                                                                <input 
                                                                                    type="checkbox"
                                                                                    [(ngModel)]="ImpactRating"
                                                                                >
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>4</td>
                                                                            <td style="text-align: left;">Likelihood Rating</td>
                                                                            <td>
                                                                                <input 
                                                                                    type="checkbox"
                                                                                    [(ngModel)]="LikelihoodRating"
                                                                                >
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>5</td>
                                                                            <td style="text-align: left;">Risk Category</td>
                                                                            <td>
                                                                                <input 
                                                                                    type="checkbox"
                                                                                    [(ngModel)]="RiskCategory"
                                                                                >
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>6</td>
                                                                            <td style="text-align: left;">Escalation level</td>
                                                                            <td>
                                                                                <input 
                                                                                    type="checkbox"
                                                                                    [(ngModel)]="RiskRating"
                                                                                >
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 my-3 mx-0 text-center">
                                                    <button type="button" class="btn mr-3"
                                                        style="width: 100px;background-color:#9eb0ba;color:white;"
                                                        (click)="func_closebtn()">Close</button>
                                                    <button type="button" class="btn"
                                                        style="width: 100px;background-color:#033153;color:white;"
                                                        (click)="func_savebtn()">Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>