<div class="container-fluid appform my-3">
  <div class="row">
    <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">
      <div class="col-12 col-md-12 col-lg-12 col-xl-12 pb-5 mt-5 mb-5">
        <div class="px-0 pb-2">
          <div class="card col-12 mt-4 mx-0 p-3">
            <div class="row" id="Summary-Share">
              <div class="coordinator col-3 ml-3 mb-3 rounded" style="height: 50px;">
                <div class="col-0 pt-2" style="font-size: 22px;">
                  <em class="fas fa-file-signature"></em>&nbsp;&nbsp;Summary & Share
                </div>
              </div>
            </div>

            <div class="row" id="Summary-of-all-Strategies">
              <div class="row col-12 ml-4 mb-1 rounded">
                <div class="col-6 pt-2 headertext">
                  Summary of all Strategies
                </div>
                <div *ngIf="empCode == '40000034' && empRole.includes('ERM')" class="row col-6 px-0 mx-0">
                  <div class="col-4 pt-2 headertext">
                    Business Name
                  </div>
                  <div class="col-7">
                    <select class="form-control" [(ngModel)]="SearchKeyBU" (change)="funcSearch()">
                      <option value="">Select Business Name</option>
                      <option *ngFor="let data of DDL_BusinessName" value="{{ data.value }}">{{ data.text }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" style="padding-bottom: 50px;" id="Strategic-Fit">
              <div class="row col-4 pt-2 ml-3">
                <div class="col-10 pt-2 ml-3 pb-2" style="font-size: large; color: #08afed; font-weight: bold;">
                  <em class="fas fa-caret-right"></em>&nbsp;&nbsp;Strategic Fit
                </div>
              </div>
              <div class="col-11 pt-2 bggray">
                <div class="row" style="justify-content: center">
                  <div class="col-xl-7 order-xl-1 col-md-12 order-sm-2 pb-0 mt-0 mb-0">
                    <div class="col-12 mt-4 mx-0 p-3">
                      <div class="row liststrategyclick" (click)="func_ShortMasterStr('StrategicAlignment')"
                        style="margin-left: 1px; margin-right: 1px;">
                        <div class="col-11" style="font-size: large; font-weight: bold;">
                          Strategic Alignment
                        </div>
                        <div class="col-1"
                          style="font-size: large; font-weight: bold; display: flex; justify-content: end; align-items: center;">
                          <em class="far fa-caret-square-{{strIsShowStrategicAlignment}}"></em>
                        </div>
                      </div>
                      <div *ngIf="isShowStrategicAlignment == true" class="table-banner">
                        <table class="table-bordered" style="width:100%;">
                          <caption style="display: none;">This is a custom table</caption>
                          <thead style="text-align: center;background-color:#033153;color:white;font-size:18px;">
                            <tr>
                              <th style="vertical-align: middle;">No.</th>
                              <th style="vertical-align: middle;">Strategic Name</th>
                              <th style="vertical-align: middle;">Strategic Direction</th>
                              <th style="vertical-align: middle;">Financial </th>
                              <th style="vertical-align: middle;">Zero Tolerance</th>
                              <th style="vertical-align: middle;">Lowest Level</th>
                            </tr>
                            <tr>

                            </tr>
                          </thead>
                          <tbody style="background-color: white;font-size:16px;">
                            <tr *ngFor="let item of DataListStrategic index as index1">
                              <td style="text-align: center;">
                                {{index1 + 1}}
                              </td>
                              <td style="text-align: center;">
                                {{item.riskName}}
                              </td>
                              <td style="text-align: center;" title="{{item.strategicDirectionRationale}}"
                                *ngFor="let items of filterByid(selectValues, item.strategicDirection)">{{items.name}}
                              </td>
                              <td style="text-align: center;" title="{{item.financialRationale}}"
                                *ngFor="let items of filterByid(selectValues, item.financial)">{{items.name}}
                              </td>
                              <td style="text-align: center;" title="{{item.zeroToleranceRationale}}"
                                *ngFor="let items of filterByid(selectValues, item.zeroTolerance)">{{items.name}}
                              </td>
                              <td style="text-align: center;">
                                {{item.lowestScoreStrategicX}}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                    </div>
                    <div class="col-12 mt-4 mx-0 p-3">
                      <div class="row liststrategyclick" (click)="func_ShortMasterStr('StrategicContribution')"
                        style="margin-left: 1px; margin-right: 1px;">
                        <div class="col-11" style="font-size: large; font-weight: bold;">
                          Strategic Contribution
                        </div>
                        <div class="col-1"
                          style="font-size: large; font-weight: bold; display: flex; justify-content: end; align-items: center;">
                          <em class="far fa-caret-square-{{strIsShowStrategicContribution}}"></em>
                        </div>
                      </div>
                      <div *ngIf="isShowStrategicContribution == true" class="table-banner">
                        <table class="table-bordered" style="width:100%;">
                          <caption style="display: none;">This is a custom table</caption>
                          <thead style="text-align: center;background-color:#033153;color:white;font-size:18px;">
                            <tr>
                              <th style="vertical-align: middle;">No.</th>
                              <th style="vertical-align: middle;">Strategic Name</th>
                              <th style="vertical-align: middle;">Value Size / Profit Margin</th>
                              <th style="vertical-align: middle;">Timing of Cash Inflow</th>
                              <th style="vertical-align: middle;">Growth Opportunity / Sustainability target</th>
                              <th style="vertical-align: middle;">Lowest Level</th>
                            </tr>
                          </thead>
                          <tbody style="background-color: white;font-size:16px;">
                            <tr *ngFor="let item of DataListStrategic index as index1">
                              <td style="text-align: center;">
                                {{index1 + 1}}
                              </td>
                              <td style="text-align: center;">
                                {{item.riskName}}
                              </td>
                              <td style="text-align: center;" title="{{item.valueProfitRationale}}"
                                *ngFor="let items of filterByid(selectValues, item.valueProfit)">{{items.name}}
                              </td>
                              <td style="text-align: center;" title="{{item.timingCashInfowRationale}}"
                                *ngFor="let items of filterByid(selectValues, item.timingCashInfow)">{{items.name}}
                              </td>
                              <td style="text-align: center;" title="{{item.growthSustainabilityRationale}}"
                                *ngFor="let items of filterByid(selectValues, item.growthSustainability)">{{items.name}}
                              </td>
                              <td style="text-align: center;">
                                {{item.lowestScoreStrategicY}}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                    </div>
                  </div>
                  <div class="col-xl-5 order-xl-2 col-md-5 order-sm-1 mt-5">
                    <div style="padding-right: 5px; height: fit-content; max-height: 1000px;    ">
                      <app-strategic-table [datas]="positionSetValue1" [mode]="'multi'" [tabSelect]="'1'">
                      </app-strategic-table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" style="padding-bottom: 50px;" id="Strategic-Execution-Perspective">
              <div class="row col-4 pt-2 ml-3">
                <div class="col-10 pt-2 ml-3 pb-2" style="font-size: large; color: #08afed; font-weight: bold;">
                  <em class="fas fa-caret-right"></em>&nbsp;&nbsp;Strategic Execution Perspective
                </div>
              </div>
              <div class="col-11 pt-2 bggray">
                <div class="row" style="justify-content: center">
                  <div class="col-xl-7 order-xl-1 col-md-12 order-sm-2 pb-0 mt-0 mb-0">
                    <div class="col-12 mt-4 mx-0 p-3">
                      <div class="row liststrategyclick" (click)="func_ShortMasterStr('ManageableChallenge')"
                        style="margin-left: 1px; margin-right: 1px;">
                        <div class="col-11" style="font-size: large; font-weight: bold;">
                          Manageable Challenge
                        </div>
                        <div class="col-1"
                          style="font-size: large; font-weight: bold; display: flex; justify-content: end; align-items: center;">
                          <em class="far fa-caret-square-{{strIsShowManageableChallenge}}"></em>
                        </div>
                      </div>
                      <div *ngIf="isShowManageableChallenge == true" class="table-banner">
                        <table class="table-bordered" style="width:100%;">
                          <caption style="display: none;">This is a custom table</caption>
                          <thead style="text-align: center;background-color:#033153;color:white;font-size:18px;">
                            <tr>
                              <th style="vertical-align: middle;">No.</th>
                              <th style="vertical-align: middle;">Strategic Name</th>
                              <th style="vertical-align: middle;">Capability Development</th>
                              <th style="vertical-align: middle;">Resources Allocation</th>
                              <th style="vertical-align: middle;">Lowest Level</th>
                            </tr>
                          </thead>
                          <tbody style="background-color: white;font-size:16px;">
                            <tr *ngFor="let item of DataListManageable index as index1">
                              <td style="text-align: center;">
                                {{index1 + 1}}
                              </td>
                              <td style="text-align: center;">
                                {{item.riskName}}
                              </td>
                              <td style="text-align: center;" title="{{item.capabilityRationale}}"
                                *ngFor="let items of filterByid(selectValues, item.capability)">{{items.name}}
                              </td>
                              <td style="text-align: center;" title="{{item.resourcesRationale}}"
                                *ngFor="let items of filterByid(selectValues, item.resources)">{{items.name}}
                              </td>
                              <td style="text-align: center;">
                                {{item.lowestScoreManageableX}}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="col-12 mt-4 mx-0 p-3">
                      <div class="row liststrategyclick" (click)="func_ShortMasterStr('ManageablePortfolioRisk')"
                        style="margin-left: 1px; margin-right: 1px;">
                        <div class="col-11" style="font-size: large; font-weight: bold;">
                          Manageable Portfolio Risk
                        </div>
                        <div class="col-1"
                          style="font-size: large; font-weight: bold; display: flex; justify-content: end; align-items: center;">
                          <em class="far fa-caret-square-{{strIsShowManageablePortfolioRisk}}"></em>
                        </div>
                      </div>
                      <div *ngIf="isShowManageablePortfolioRisk == true" class="table-banner">
                        <table class="table-bordered" style="width:100%;">
                          <caption style="display: none;">This is a custom table</caption>
                          <thead style="text-align: center;background-color:#033153;color:white;font-size:18px;">
                            <tr>
                              <th style="vertical-align: middle;">No.</th>
                              <th style="vertical-align: middle;">Strategic Name</th>
                              <th style="vertical-align: middle;">Joint Investment</th>
                              <th style="vertical-align: middle;">Quick Exit to Limit Loss</th>
                              <th style="vertical-align: middle;">Lowest Level</th>
                            </tr>
                          </thead>
                          <tbody style="background-color: white;font-size:16px;">
                            <tr *ngFor="let item of DataListManageable index as index1">
                              <td style="text-align: center;">
                                {{index1 + 1}}
                              </td>
                              <td style="text-align: center;">
                                {{item.riskName}}
                              </td>
                              <td style="text-align: center;" title="{{item.jointInvestmentRationale}}"
                                *ngFor="let items of filterByid(selectValues, item.jointInvestment)">{{items.name}}
                              </td>
                              <td style="text-align: center;" title="{{item.quickExitRationale}}"
                                *ngFor="let items of filterByid(selectValues, item.quickExit)">{{items.name}}
                              </td>
                              <td style="text-align: center;">
                                {{item.lowestScoreManageableY}}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-5 order-xl-2 col-md-5 order-sm-1 mt-5">
                    <div style="padding-right: 5px; height: fit-content;">
                      <app-strategic-table [datas]="positionSetValue2" [mode]="'multi'" [tabSelect]="'2'">
                      </app-strategic-table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" style="padding-bottom: 50px;" id="Strategy">
              <div class="row col-4 pt-2 ml-3">
                <div class="col-10 pt-2 ml-3 pb-2" id="StrategyHeader" style="font-size: large; color: #08afed; font-weight: bold;">
                  <em class="fas fa-caret-right"></em>&nbsp;&nbsp;Risk from Strategy
                </div>
              </div>
              <div class="col-11 pt-2 bggray">
                <div class="row" style="justify-content: center">
                  <ng-container *ngFor="let item of DataListStrategicRiskTransaction index as index1">
                    <div class="row col-12" id="{{'Strategy'+index1}}">
                      <div class="col-12 pt-2" style="margin-left: auto; margin-right: auto;">
                        <div class="row liststrategyclick" (click)="func_ShortItem(item)">
                          <div class="col-11" style="font-size: large; font-weight: bold; ">
                            {{item.strategy}} : {{item.riskName}}
                          </div>
                          <div class="col-1"
                            style="font-size: large; font-weight: bold; display: flex; justify-content: end; align-items: center;">
                            <em class="far fa-caret-square-{{item.squareClass}}"></em>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="item.isShowStrategy == true" class="col-12 pt-2" style="margin-left: auto; margin-right: auto;">
                        <div class="row">
                          <div class="col-12">
                            <div class="col-12 mx-0 p-3">
                              <!-- <div class="col-12" style="font-size: large; color: #08afed; font-weight: bold;">
                                <em class="fas fa-caret-right"></em>&nbsp;&nbsp;Risk from Strategy
                              </div> -->
                              <div class="table-banner">
                                <table class="table-bordered" style="width:100%;">
                                  <caption style="display: none;">This is a custom table</caption>
                                  <thead style="text-align: center;background-color:#033153;color:white;font-size:18px;">
                                    <tr>
                                      <th style="vertical-align: middle;">No.</th>
                                      <th style="vertical-align: middle;">Risk Name / Description</th>
                                      <th style="vertical-align: middle;">(Residual) Likelihood</th>
                                      <th style="vertical-align: middle;">(Residual) Impact</th>
                                      <th style="vertical-align: middle;">(Residual) Risk Level</th>
                                      <th style="vertical-align: middle;">(If Mitigated) Likelihood</th>
                                      <th style="vertical-align: middle;">(If Mitigated) Impact</th>
                                      <th style="vertical-align: middle;">(If Mitigated) Risk Level</th>
                                      <th style="vertical-align: middle;">Mitigatable</th>
                                      <th style="vertical-align: middle;">Acceptable</th>
                                    </tr>
                                    <tr>
      
                                    </tr>
                                  </thead>
                                  <tbody style="background-color: white;font-size:16px;">
                                    <tr *ngFor="let item of item.riskStrategy index as index1">
                                      <td style="text-align: center;">
                                        {{index1 + 1}}
                                      </td>
                                      <td style="text-align: center;">
                                        {{item.riskName}}
                                      </td>
                                      <td style="text-align: center;"
                                        *ngFor="let items of filterByid(likelihoodValues, item.likelihoodInherent)">
                                        {{items.name}}
                                      </td>
                                      <td style="text-align: center;"
                                        *ngFor="let items of filterByid(impactValues, item.impactInherent)">
                                        {{items.name}}
                                      </td>
                                      <td style="text-align: center;"
                                        [ngClass]="{'low': item.riskLevelInherent == 1,'medium': item.riskLevelInherent == 0.5, 'high': item.riskLevelInherent == 0 , 'null': item.riskLevelInherent == null}">
                                        <input type="hidden" [ngModel]="item.riskLevelInherent">
                                      </td>
                                      <td style="text-align: center;"
                                        *ngFor="let items of filterByid(likelihoodValues, item.likelihoodIfMitigated)">
                                        {{items.name}}
                                      </td>
                                      <td style="text-align: center;"
                                        *ngFor="let items of filterByid(impactValues, item.impactIfMitigated)">
                                        {{items.name}}
                                      </td>
                                      <td style="text-align: center;vertical-align: top; "
                                        [ngClass]="{'low': item.riskLevelIfMitigated == 1,'medium': item.riskLevelIfMitigated == 0.5, 'high': item.riskLevelIfMitigated == 0, 'null': item.riskLevelIfMitigated == null}">
                                        <input type="hidden" [ngModel]="item.riskLevelIfMitigated">
                                      </td>
                                      <td style="text-align: center;"
                                        *ngFor="let items of filterByid(acceptableValues, item.mitigatable)">
                                        {{items.name}}
                                      </td>
                                      <td style="text-align: center;"
                                        *ngFor="let items of filterByid(acceptableValues, item.acceptable)">
                                        {{items.name}}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div class="col-12  ">
                              <div class="row justify-content-md-center pt-4">
                                <div class="col-6" style="height: fit-content; margin-left: 0px;">
                                  <app-strategy-table [datas]="item.riskStrategy">
                                  </app-strategy-table>
                                </div>
                                <div class="col-5" style="height: fit-content;">
                                  <app-accept-table [datas]="item.riskStrategy" [mode] ="'summaryshare'">
                                  </app-accept-table>
                                </div>
                              </div>
                            </div>
                            <!-- <div class="col-12 mt-4 mx-0 p-3">
                              <div class="col-12 pt-2" style="font-size: large; color: #08afed; font-weight: bold;">
                                <em class="fas fa-caret-right"></em>&nbsp;&nbsp;Strategic Assumptions
                              </div>
                              <div class="table-banner">
                                <table class="table-bordered" style="width:100%;">
                                  <caption style="display: none;">This is a custom table</caption>
                                  <thead style="text-align: center;background-color:#033153;color:white;font-size:18px;">
                                    <tr>
                                      <th style="vertical-align: middle;">Assumption No.</th>
                                      <th style="vertical-align: middle;">Category</th>
                                      <th style="vertical-align: middle;">Name / Description</th>
                                      <th style="vertical-align: middle;">Favorable Status (GREEN)</th>
                                      <th style="vertical-align: middle;">Unfavorable Status (RED)</th>
                                      <th style="vertical-align: middle;">Action If RED</th>
                                    </tr>
                                    <tr>
      
                                    </tr>
                                  </thead>
                                  <tbody style="background-color: white;font-size:16px;">
                                    <tr
                                      *ngFor="let items of item.riskAssumption | slice: (usePagination ? (item.page-1) * pageSize : 0) : (usePagination ? (item.page-1) * pageSize + pageSize : undefined) index as index1">
                                      <td style="text-align: center;">
                                        Assumption {{(item.page > 1 ? (item.page-1) * pageSize + index1 + 1 : index1 + 1)}}
                                      </td>
                                      <td style="text-align: center;">
                                        {{items.riskCategoryName}}
                                      </td>
                                      <td style="text-align: center;">
                                        {{items.assumptionDescription}}
                                      </td>
                                      <td style="text-align: center;">
                                        {{items.favorableStatus}}
                                      </td>
                                      <td style="text-align: center;">
                                        {{items.unfavorableStatus}}
                                      </td>
                                      <td style="text-align: center;">
                                        {{items.actionRed}}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div *ngIf="usePagination" class="pagination pt-5">
                                <ngb-pagination [collectionSize]="item.riskAssumption.length" [(page)]="item.page"
                                  [pageSize]="pageSize" [maxSize]="10" [rotate]="true" [ellipses]="false"
                                  [boundaryLinks]="true">
                                  <ng-template ngbPaginationPrevious>Prev</ng-template>
                                  <ng-template ngbPaginationNext>Next</ng-template>
                                </ngb-pagination>
                              </div>
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>

           

            <div class="row" style="padding-bottom: 50px;" id="Assumptions">
              <div class="row col-4 pt-2 ml-3">
                <div class="col-10 pt-2 ml-3 pb-2" id="AssumptionHeader" style="font-size: large; color: #08afed; font-weight: bold;">
                  <em class="fas fa-caret-right"></em>&nbsp;&nbsp;Strategic Assumptions
                </div>
              </div>
              <div class="col-11 pt-2 bggray">
                <div class="row" style="justify-content: center">
                  <ng-container *ngFor="let item of DataListStrategicRiskTransaction index as index2">
                    <div class="row col-12" id="{{'Assumption'+index2}}">
                      <div class="col-12 pt-2" style="margin-left: auto; margin-right: auto;">
                        <div class="row liststrategyclick" (click)="func_ShortAssumptionItem(item)">
                          <div class="col-11" style="font-size: large; font-weight: bold; ">
                            {{item.strategy}} : {{item.riskName}}
                          </div>
                          <div class="col-1"
                            style="font-size: large; font-weight: bold; display: flex; justify-content: end; align-items: center;">
                            <em class="far fa-caret-square-{{item.squareClassAssumption}}"></em>
                          </div>
                        </div>
                        <div *ngIf="item.isShowAssumption == true" class="col-12 pt-2">
                          <div class="col-12 mx-0 p-3">
                            <!-- <div class="col-12 pt-2" style="font-size: large; color: #08afed; font-weight: bold;">
                              <em class="fas fa-caret-right"></em>&nbsp;&nbsp;Strategic Assumptions
                            </div> -->
                            <div class="table-banner">
                              <table class="table-bordered" style="width:100%;">
                                <caption style="display: none;">This is a custom table</caption>
                                <thead style="text-align: center;background-color:#033153;color:white;font-size:18px;">
                                  <tr>
                                    <th style="vertical-align: middle;">Assumption No.</th>
                                    <th style="vertical-align: middle;">Category</th>
                                    <th style="vertical-align: middle;">Name / Description</th>
                                    <th style="vertical-align: middle;">Favorable Status (GREEN)</th>
                                    <th style="vertical-align: middle;">Unfavorable Status (RED)</th>
                                    <th style="vertical-align: middle;">Action If RED</th>
                                  </tr>
                                  <tr>
            
                                  </tr>
                                </thead>
                                <tbody style="background-color: white;font-size:16px;">
                                  <tr
                                    *ngFor="let items of item.riskAssumption | slice: (usePagination ? (item.page-1) * pageSize : 0) : (usePagination ? (item.page-1) * pageSize + pageSize : undefined) index as index2">
                                    <td style="text-align: center;">
                                      Assumption {{(item.page > 1 ? (item.page-1) * pageSize + index2 + 1 : index2 + 1)}}
                                    </td>
                                    <td style="text-align: center;">
                                      {{items.riskCategoryName}}
                                    </td>
                                    <td style="text-align: center;">
                                      {{items.assumptionDescription}}
                                    </td>
                                    <td style="text-align: center;">
                                      {{items.favorableStatus}}
                                    </td>
                                    <td style="text-align: center;">
                                      {{items.unfavorableStatus}}
                                    </td>
                                    <td style="text-align: center;">
                                      {{items.actionRed}}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div *ngIf="usePagination" class="pagination pt-5">
                              <ngb-pagination [collectionSize]="item.riskAssumption.length" [(page)]="item.page"
                                [pageSize]="pageSize" [maxSize]="10" [rotate]="true" [ellipses]="false"
                                [boundaryLinks]="true">
                                <ng-template ngbPaginationPrevious>Prev</ng-template>
                                <ng-template ngbPaginationNext>Next</ng-template>
                              </ngb-pagination>
                            </div>
                          </div>
                        </div>
                      </div>
                      
      
                    </div>
      
                  </ng-container>
                </div>
              </div>
            </div>
            

            <div class="row">
              <div class="col-12 pt-5">
                <div class="d-flex" style="justify-content: flex-end;">
                  <button type="button" class="btn btn-lg mb-3" style="color:white;background:black;margin: 5px;"
                    [routerLink]="['/Register-Assessment']">
                    Go back to Register Page
                  </button>
                  <button type="button" class="btn btn-lg mb-3" style="color:white;background:green;margin: 5px;"
                    (click)="func_exportToPDF()">
                    Export PDF
                  </button>
                  <button type="button" class="btn btn-lg mb-3" style="color:white;background:#093E9A;margin: 5px;"
                    (click)="func_ShareTo()">
                    Share Strategic Risk Register To
                  </button>
                </div>
              </div>
            </div>

            
          </div>
          <div class="row">
            <div class="col-12 col-md-12 col-lg-12 col-xl-12 ">
                <app-action-log [riskId]="this.DataLogRiskIdLst" [isShowStrategyName] = "true"></app-action-log>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<app-modal-selectbu [title]="titleBU" (returnEvent)="returnEvent($event)"></app-modal-selectbu>

<app-loading [loading]='loading'></app-loading>