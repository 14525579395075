import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as qs from 'qs'
import { AppSettingService } from '../app-setting-service';
import { Configmodel } from '../model/configmodel';
import { AxiosInterceptorService } from './axios-interceptor.service';


const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;',
        'Accept': 'q=0.8;application/json;q=0.9',
        'Access-Control-Allow-Origin': 'https://err-dev.pttep.com/',
        'Access-Control-Allow-Methods': 'POST, GET',
        'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
        'X-Frame-Options': 'DENY',
        'Content-Security-Policy': 'frame-ancestors \'none\''
    },
    emulateJSON: true
}

const config2 = {
    headers: {
        'Content-Type': 'multipart/form-data',
        'Accept': 'q=0.8;application/json;q=0.9',
        'Access-Control-Allow-Origin': 'https://err-dev.pttep.com/',
        'Access-Control-Allow-Methods': 'POST, GET',
        'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
        'X-Frame-Options': 'DENY',
        'Content-Security-Policy': 'frame-ancestors \'none\''
    },
    emulateJSON: true
}

const config3 = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

const config4 = { headers: { 'Content-Type': 'application/json', 'X-Frame-Options': 'DENY', 'Content-Security-Policy': 'frame-ancestors \'none\'' }, emulateJSON: true };

const configJson = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'q=0.8;application/json;q=0.9',
        'Access-Control-Allow-Origin': 'https://err-dev.pttep.com/',
        'Access-Control-Allow-Methods': 'POST, GET',
        'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
        'X-Frame-Options': 'DENY',
        'Content-Security-Policy': 'frame-ancestors \'none\''
    },
    emulateJSON: true,

}

const configBlob = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'q=0.8;application/json;q=0.9',
        'Access-Control-Allow-Origin': 'https://err-dev.pttep.com/',
        'Access-Control-Allow-Methods': 'POST, GET',
        'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
        'X-Frame-Options': 'DENY',
        'Content-Security-Policy': 'frame-ancestors \'none\''
    },
    emulateJSON: true,
    responseType: 'blob' as 'blob'
}

const configWithCsrf = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;',
        'Accept': 'q=0.8;application/json;q=0.9',
        'Access-Control-Allow-Origin': 'https://err-dev.pttep.com/',
        'Access-Control-Allow-Methods': 'POST, GET',
        'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
        'Access-Control-Allow-Credentials': 'true',
        'X-Frame-Options': 'DENY',
        'Content-Security-Policy': 'frame-ancestors \'none\''
    },
    emulateJSON: true,
    withCredentials: true
}

const config2WithCsrf = {
    headers: {
        'Content-Type': 'multipart/form-data',
        'Accept': 'q=0.8;application/json;q=0.9',
        'Access-Control-Allow-Origin': 'https://err-dev.pttep.com/',
        'Access-Control-Allow-Methods': 'POST, GET',
        'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
        'X-Frame-Options': 'DENY',
        'Content-Security-Policy': 'frame-ancestors \'none\''
    },
    emulateJSON: true,
    withCredentials: true
}

const configWithCredential = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;',
        'Accept': 'q=0.8;application/json;q=0.9',
        'Access-Control-Allow-Origin': 'https://err-dev.pttep.com/',
        'Access-Control-Allow-Methods': 'POST, GET',
        'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
        'X-Frame-Options': 'DENY',
        'Content-Security-Policy': 'frame-ancestors \'none\''
    },
    emulateJSON: true,
    withCredentials: true
}

@Injectable()
export class Masterserve {
    constructor(private http: HttpClient, public appSettingService: AppSettingService, public axiosInterceptorService: AxiosInterceptorService) {
    }
    axiosInstance = this.axiosInterceptorService.getAxiosInstance();
    configData: Configmodel;

    getData() {

        this.configData = this.appSettingService.read();
        return this.configData;
    }

    BASE_URL = this.getData().api_url;
    API_URL = {
        profiles: this.BASE_URL + '/api/profiles/',
        login: this.BASE_URL + '/auth/custom-token/',
        Getlist: this.BASE_URL + '/api/Master/GetRiskCategory',
        GetFinancial: this.BASE_URL + '/api/Master/GetFinancialImpact',
        GetInstruction: this.BASE_URL + '/api/Master/GetInstruction',
        GetTopMenu: this.BASE_URL + '/api/Master/GetTopMenu',
        Master_DDL: this.BASE_URL + '/api/Master/GetDDL',
        GetLikelihood: this.BASE_URL + '/api/Master/GetLikelihood',
        GetImpactCategory: this.BASE_URL + '/api/Master/GetImpactCategory',
        GetQuarter: this.BASE_URL + '/api/Master/GetQuarter',
        GetBUCoordinator: this.BASE_URL + '/api/Master/GetBUCoordinator',
        GetPerManagement: this.BASE_URL + '/api/Master/GetPerManagement',
        GetRiskRating: this.BASE_URL + '/api/Master/GetRiskRating',
        GetFileVersionInfo: this.BASE_URL + '/api/api/GetFileVersionInfo',
        GetCatalog: this.BASE_URL + '/api/Master/GetRiskCatalog',
        GetBanner: this.BASE_URL + '/api/Master/GetBanner',

        GetUserlogin: this.BASE_URL + '/api/GetUserlogin',
        Risk_Insert_Update_Get: this.BASE_URL + '/api/Risk_Insert_Update_Get',
        Risk_RootCause_Insert_Update_Get: this.BASE_URL + '/api/Risk_RootCause_Insert_Update_Get',
        Risk_Impact_Insert_Update_Get: this.BASE_URL + '/api/Risk_Impact_Insert_Update_Get',
        Risk_Workflow_Submit: this.BASE_URL + '/api/Risk_Workflow_Submit',
        GetContactUs: this.BASE_URL + '/api/Master/GetContactUs',
        Risk_Consolidate: this.BASE_URL + '/api/Risk_Consolidate',
        GetCorpTarget: this.BASE_URL + '/api/Master/GetCorpTarget',
        RiskSearch: this.BASE_URL + '/api/RiskSeacrh',
        UploadFileImg: this.BASE_URL + '/api/UploadMultiple',
        RiskUploadFile: this.BASE_URL + '/api/Risk_Attach_Files_Update',
        GetAsset: this.BASE_URL + '/api/Master/GetAsset',
        GetWPB: this.BASE_URL + '/api/Master/GetWPB',
        DeleteFile: this.BASE_URL + '/api/test/DeleteFile',
        RiskDeleteFile: this.BASE_URL + '/api/Risk_Attach_Files_Delete',
        ReportItems: this.BASE_URL + '/api/ReportItems',
        Get_Menu_BU_Config: this.BASE_URL + '/api/Get_Menu_BU_Config',
        GetTemplateEmail: this.BASE_URL + '/api/Master/GetTemplateEmail',
        Risk_Transfer: this.BASE_URL + '/api/Risk_Transfer',
        GetexportExcelData: this.BASE_URL + '/api/Master/GetBUCoordinator',
        CheckUserlogin: this.BASE_URL + '/api/CheckUserlogin',
        ReplaceCordinator: this.BASE_URL + '/api/Master/ReplaceCordinator',
        Delegate_RiskSeacrh: this.BASE_URL + '/api/Delegate_RiskSeacrh',
        GetOwner: this.BASE_URL + '/api/Master/GetOwner',
        Delegate: this.BASE_URL + '/api/Delegate',
        getCsrfToken: this.BASE_URL + '/api/Master/GetCsrfToken',
        getAntiForgeryToken: this.BASE_URL + '/api/Master/GetAntiForgeryToken',
        DownloadFile: this.BASE_URL + '/api/DownloadFile',
        getAssumptionCategory: this.BASE_URL + '/api/Master/GetAssumptionCategory'
    };

    ontest(param) {
        return this.axiosInstance.post('http://localhost:14302/api/Master/Gettest2', param, config4);
    }
    ontest1(param) {
        return this.axiosInstance.post(this.API_URL.login, param, config);
    }
    ongetGetFileVersionInfo(param) {
        return this.axiosInstance.get(this.API_URL.GetFileVersionInfo);
    }
    GetRiskCategory(param) {
        let data = qs.stringify(param)
        return this.axiosInstance.post(this.API_URL.Getlist, data, config);
    }
    GetFinancialImpact(param) {
        let data = qs.stringify(param)
        return this.axiosInstance.post(this.API_URL.GetFinancial, data, config);
    }
    GetInstruction(param) {
        let data = qs.stringify(param)
        return this.axiosInstance.post(this.API_URL.GetInstruction, data, config);
    }
    GetBanner(param) {
        let data = qs.stringify(param)
        return this.axiosInstance.post(this.API_URL.GetBanner, data, config);
    }
    GetTopMenu(param) {
        let data = qs.stringify(param)
        return this.axiosInstance.post(this.API_URL.GetTopMenu, data, config);
    }
    Master_DDL(param) {
        let data = qs.stringify(param)
        return this.axiosInstance.post(this.API_URL.Master_DDL, data, config);
    }
    async GetLikelihood(param) {
        let data = qs.stringify(param)
        return this.axiosInstance.post(this.API_URL.GetLikelihood, data, configWithCsrf);
    }

    GetImpactCategory(param) {
        let data = qs.stringify(param)
        return this.axiosInstance.post(this.API_URL.GetImpactCategory, data, config);
    }
    GetQuarter(param) {
        let data = qs.stringify(param)
        return this.axiosInstance.post(this.API_URL.GetQuarter, data, config);
    }
    GetBUCoordinator(param) {
        let data = qs.stringify(param)
        return this.axiosInstance.post(this.API_URL.GetBUCoordinator, data, config);
    }
    GetPerManagement(param) {
        let data = qs.stringify(param)
        return this.axiosInstance.post(this.API_URL.GetPerManagement, data, config);
    }
    GetRiskRating(param) {
        let data = qs.stringify(param)
        return this.axiosInstance.post(this.API_URL.GetRiskRating, data, config);
    }
    GetCatalog(param) {
        let data = qs.stringify(param)
        return this.axiosInstance.post(this.API_URL.GetCatalog, data, config);
    }

    GetRole_Employee(param) {
        let data = JSON.stringify(param)
        return this.axiosInstance.post(this.API_URL.GetUserlogin, data, configJson);
    }

    RegisterRisk(param) {
        let data = qs.stringify(param)
        return this.axiosInstance.post(this.API_URL.Risk_Insert_Update_Get, data, config);
    }
    RegisterRisk_Roocause(param) {
        let data = qs.stringify(param)
        return this.axiosInstance.post(this.API_URL.Risk_RootCause_Insert_Update_Get, data, config);
    }
    RegisterRisk_Impact(param) {
        let data = qs.stringify(param)
        return this.axiosInstance.post(this.API_URL.Risk_Impact_Insert_Update_Get, data, config);
    }
    SubmitRegisterRisk(param) {
        let data = qs.stringify(param)
        return this.axiosInstance.post(this.API_URL.Risk_Workflow_Submit, data, config);
    }
    Get_ContactUs(param) {
        let data = qs.stringify(param)
        return this.axiosInstance.post(this.API_URL.GetContactUs, data, config);
    }
    Get_Consolidate(param) {
        let data = qs.stringify(param)
        return this.axiosInstance.post(this.API_URL.Risk_Consolidate, data, config);
    }
    Get_Corporate(param) {
        let data = qs.stringify(param)
        return this.axiosInstance.post(this.API_URL.GetCorpTarget, data, config);
    }
    RiskSearch(param) {
        let data = qs.stringify(param)
        return this.axiosInstance.post(this.API_URL.RiskSearch, data, config);
    }
    async UploadFileImg(param) {
        await this.getCsrfToken()
        return this.axiosInstance.post(this.API_URL.UploadFileImg, param, config2WithCsrf);
    }

    async UploadFileMultiple(param) {
        await this.getCsrfToken()
        return this.axiosInstance.post(this.API_URL.UploadFileImg, param, config2WithCsrf);
    }

    async RiskUploadFile(param) {
        await this.getCsrfToken();
        return this.axiosInstance.post(this.API_URL.RiskUploadFile, param, config2WithCsrf);
    }
    GetAsset(param) {
        let data = qs.stringify(param)
        return this.axiosInstance.post(this.API_URL.GetAsset, data, config);
    }

    GetWPB(param) {
        let data = qs.stringify(param)
        return this.axiosInstance.post(this.API_URL.GetWPB, data, config);
    }
    async DeleteFile(param) {
        let data = qs.stringify(param)
        await this.getCsrfToken();
        return this.axiosInstance.post(this.API_URL.DeleteFile, data, configWithCsrf);
    }
    async RiskDeleteFile(param) {
        let data = qs.stringify(param)
        await this.getCsrfToken();
        return this.axiosInstance.post(this.API_URL.RiskDeleteFile, data, configWithCsrf);
    }
    ReportItems(param) {
        let data = qs.stringify(param)
        return this.axiosInstance.post(this.API_URL.ReportItems, data, config);
    }
    Get_Menu_BU_Config(param) {
        let data = qs.stringify(param)
        return this.axiosInstance.post(this.API_URL.Get_Menu_BU_Config, data, config);
    }
    GetTemplateEmail(param) {
        let data = qs.stringify(param)
        return this.axiosInstance.post(this.API_URL.GetTemplateEmail, data, config);
    }
    Risk_Transfer(param) {
        let data = qs.stringify(param)
        return this.axiosInstance.post(this.API_URL.Risk_Transfer, data, config);
    }
    GetexportExcelData(param) {
        let data = qs.stringify(param)
        return this.axiosInstance.post(this.API_URL.GetexportExcelData, data, config);
    }
    GetFinancialExport(param) {
        let data = qs.stringify(param)
        return this.axiosInstance.post(this.API_URL.GetFinancial, data, config);
    }
    CheckUserlogin(param) {
        let data = qs.stringify(param)
        return this.axiosInstance.post(this.API_URL.CheckUserlogin, data, config);
    }
    ReplaceCordinator(param) {
        let data = qs.stringify(param)
        return this.axiosInstance.post(this.API_URL.ReplaceCordinator, data, config);
    }
    Delegate_RiskSeacrh(param) {
        let data = qs.stringify(param)
        return this.axiosInstance.post(this.API_URL.Delegate_RiskSeacrh, data, config);
    }
    GetOwner(param) {
        let data = qs.stringify(param)
        return this.axiosInstance.post(this.API_URL.GetOwner, data, config);
    }
    Delegate(param) {
        let data = qs.stringify(param)
        return this.axiosInstance.post(this.API_URL.Delegate, data, config);
    }
    async getCsrfToken() {
        return await this.axiosInstance.post(this.API_URL.getCsrfToken, null, configWithCredential);
    }

    DownloadFile(param) {
        return this.axiosInstance.post(this.API_URL.DownloadFile, param, configBlob);
    }

    getAssumptionCategory(param) {
        let data = qs.stringify(param)
        return this.axiosInstance.post(this.API_URL.getAssumptionCategory, data, config);
    }
}
