<div class="container-fluid appform my-3">
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">
            <div class="col-12 col-md-12 col-lg-12 col-xl-12 pb-5 mt-5 mb-5">
                <div class="px-0 pb-2">
                    <div class="card col-12 mt-4 mx-0 px-3">
                        <div class="riskrating col-12 mb-3 rounded">
                            <div class="col-0 pt-2">
                                <em class="fas fa-bars icon"></em>&nbsp;&nbsp;Escalation level
                            </div>
                        </div>
                        <div class="row col-12 form-group">
                            <div class="row col-12 col-xl-4 mb-2 mx-0 px-0">
                                <div class="col-5 col-lg-3 col-xl-4 pt-1">
                                    <strong class="formtext">Risk Rating</strong>
                                </div>
                                <div class="col-7 col-lg-9 col-xl-8">
                                    <select class="form-control" [(ngModel)]="RiskRating_Getlist" (change)="func_GETDATA_RiskRating()">
                                        <option value="">Please Select</option>
                                        <option *ngFor="let item of RiskRatingDDL" value="{{ item.value }}">{{ item.text }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="table-riskrating">
                            <table class="table table-bordered">
                                <caption style="display: none;" >This is a custom table</caption>
                                <thead style="text-align: center;">
                                    <tr style="background-color:#033153;color:white;width: 1200px;">
                                        <th style="width: 150px;vertical-align: middle;">Likelihood</th>
                                        <th style="width: 150px;vertical-align: middle;">Impact</th>
                                        <th style="width: 150px;vertical-align: middle;">Likelihood x Impact</th>
                                        <th style="width: 150px;vertical-align: middle;">Risk Rating</th>
                                        <th style="width: 150px;vertical-align: middle;">Escalation level</th>
                                        <th style="width: 100px;vertical-align: middle;">Edit</th>
                                    </tr>
                                </thead>
                                <tbody style="background-color: white;font-size:16px;">
                                    <tr *ngFor='let data of tableriskrating; index as index'>
                                        <td style="text-align: center;">{{data.likelihood}}</td>
                                        <td style="text-align: center;">{{data.impact}}</td>
                                        <td style="text-align: center;">{{data.likelihoodAndImpact}}</td>
                                        <td style="text-align: center;">{{data.riskRating}}</td>
                                        <td style="text-align: center;" *ngIf="data.escalationLevel == '1'">
                                            <em class="fas fa-check-square" style="color: rgb(85, 209, 85);"></em>
                                        </td>
                                        <td style="text-align: center;" *ngIf="data.escalationLevel != '1'"></td>
                                        <td style="text-align: center;">
                                            <button type="button" class="btn" (click)="func_addriskrating(data.riskRatingID,'Edit',data)" *ngIf="search.Year == currentYear">
                                                <em class="fas fa-edit" style="color:orange;font-size:18px;"></em>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="modal fade bd-RiskRating" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" id="modaladdriskrating">
                    <div class="modal-dialog modal-xl">
                        <div class="modal-content">
                            <div class="container-fluid appform my-3">
                                <div class="row">
                                    <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">
                                        <div class="container col-12 col-md-12 col-lg-12 col-xl-12 pb-0 mt-0 mb-0 ">
                                            <div class="container">
                                                <div class="col-12 rounded modalriskrating">
                                                    <div class="col-0 pt-2">
                                                        <span *ngIf="Module == 'insert'; else elseBlock">Add Escalation level</span>
                                                        <ng-template #elseBlock>Edit Escalation level</ng-template>
                                                    </div>
                                                </div>
                                                <div class="card mt-4">
                                                    <div class="col-12 col-xl-12 mb-4">
                                                        <div class="row col-12 mx-0 px-0 d-flex justify-content-around">
                                                            <div class="row col-12 col-xl-3">
                                                                <div class="col-12 mb-2 px-0">
                                                                    <strong class="formtext">Year</strong>
                                                                </div>
                                                                <div class="col-12 px-0">
                                                                    <select class="form-control" [(ngModel)]="model.Year" (change)="SelectedYear('')" disabled>
                                                                        <option value="">Please Select</option>
                                                                        <option *ngFor="let data of YearDD" [value]="data.text" [disabled]="data.text != currentYear">{{data.text}}</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="row col-12 col-xl-3">
                                                                <div class="col-12 mb-2 px-0">
                                                                    <strong class="formtext">Quarter</strong>
                                                                </div>
                                                                <div class="col-12 px-0">
                                                                    <select class="form-control" [(ngModel)]="model.Quarter" [disabled]="model.Year === ''" (change)="func_GETMASTER_Likelihood()" disabled>
                                                                        <option value="">Please Select</option>
                                                                        <option *ngFor="let data of QuarterDD_insert" [value]="data.value">{{data.text}}</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="row col-12 col-xl-6"></div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-xl-12 mb-4">
                                                        <div class="row col-12 mx-0 px-0 d-flex justify-content-around">
                                                            <div class="row col-12 col-xl-3">
                                                                <div class="col-12 mb-2 px-0">
                                                                    <strong class="formtext">Likelihood Rating
                                                                    </strong>
                                                                </div>
                                                                <div class="col-12 px-0">
                                                                    <select class="form-control" [(ngModel)]="Regis_RiskRating.Likelihood" disabled>
                                                                        <option  value="">Please Select</option>
                                                                        <option *ngFor="let data of LikelihoodDDL" value="{{data.value}}">{{ data.text }}</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="row col-12 col-xl-3">
                                                                <div class="col-12 mb-2 px-0">
                                                                    <strong class="formtext">Impact Rating
                                                                    </strong>
                                                                </div>
                                                                <div class="col-12 px-0">
                                                                    <select class="form-control" [(ngModel)]="Regis_RiskRating.Impact" disabled>
                                                                        <option  value="">Please Select</option>
                                                                        <option *ngFor="let data of ImpactDDL" value="{{data.value}}">{{ data.text }}</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="row col-12 col-xl-3">
                                                                <div class="col-12 mb-2 px-0">
                                                                    <strong class="formtext">Risk Rating Name:</strong>
                                                                </div>
                                                                <div class="col-12 px-0">
                                                                    <select class="form-control" [(ngModel)]="Regis_RiskRating.RiskRating" (change)="onChange($event.target.value)">
                                                                        <option  value="">Please Select</option>
                                                                        <option *ngFor="let data of RiskRatingDDL" value="{{ data.value }}">{{ data.text }}</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="row col-12 col-xl-3">
                                                                <div class="col-12 px-0 pt-4">
                                                                    <input type="checkbox" 
                                                                    name="checkboxlevel"
                                                                    [(ngModel)]="Regis_RiskRating.EscalationLevel" 
                                                                    [checked]="myModel"
                                                                    [disabled]="Regis_RiskRating.RiskRating != '2'">&nbsp;
                                                                    <strong class="formtext">Escalation level</strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="col-12 my-3 mx-0 text-center">
                                                    <button type="button" class="btn mr-3" style="width: 100px;background-color:#9eb0ba;color:white;" (click)="func_closebtn()">Close</button>
                                                    <button type="button" class="btn" style="width: 100px;background-color:#033153;color:white;" (click)="func_savebtn()">Save</button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-loading [loading]='loading'></app-loading>