import { Component, Input } from '@angular/core';
import { bindingAcceptTableModel } from '../../model/StrategicRiskModel';
import { StrategyModel } from '../../model/StrategyModel';

@Component({
  selector: 'app-accept-table',
  templateUrl: './accept-table.component.html',
  styleUrls: ['./accept-table.component.css']
})
export class AcceptTableComponent{

  private _datas: StrategyModel[];
  public _mode: string = 'summary';
  public _height: string = '100%' ;
  public _width: string = '100%' ;
  @Input() set datas(value: StrategyModel[]){
    this._datas = value;
    this.render();
  }

  @Input() set mode(value: string){
    this._mode = value;
  }

  @Input() set width(value: string){
    this._width = value;
  }

  @Input() set height(value: string){
    this._height = value;
  }


  //example datas
  mockDataStrategic: bindingAcceptTableModel[] = [{xPosition: 1, yPosition: 1},{xPosition: 1, yPosition: 2},{xPosition: 2, yPosition: 1},{xPosition: 2, yPosition: 2}];


  dataTable:any = [
    [[],[]],
    [[],[]]
   ];


  render() {
    this.clearTable();
    this.plotTable();
  }


  plotTable()
  {
    if(this._datas)
    {
      for(let i = 0; i < this._datas.length; i++)
      {
        this.dataTable[Number(this._datas[i].mitigatable)][Number(this._datas[i].acceptable)].push({value: 1, number: i+1 });
      }
    }

  }

  clearTable()
  {
    this.dataTable = [
      [[],[]],
      [[],[]]
    ];
  }

}
