<div class="container-fluid appform my-3">
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1"  >

            <div class="col-12 col-md-12 col-lg-12 col-xl-12 pb-5 mt-5 mb-5">
                <div class="card col-12 mt-4 mx-0 px-3">

                    <div class="banner col-12 rounded">
                        <div class="col-12 pt-2">
                            <em class="fas fa-bars icon"></em>&nbsp;&nbsp;
                            Contact Us
                        </div>
                    </div>

                    <button type="button" class="btn btn-lg btn-banner-primary mt-4 mb-3" style="width: 200px;color:white;background:orange;" (click)="func_addContact()">
                        <em class="fas fa-plus icon"></em>&nbsp;&nbsp;&nbsp;Add Contact Us
                    </button>

                    <div class="table-banner">
                        <table class="table table-bordered" style="table-layout: fixed; width: 100%; ">
                            <caption style="display: none;" >This is a custom table</caption>
                            <thead style="text-align: center;background-color:#033153;color:white;font-size:18px;">
                                <tr>
                                    <th style="width: 50px;vertical-align: middle;">No</th>
                                    <!-- <th style="width: 50px;vertical-align: middle;">Image</th> -->
                                    <th style="width: 100px;vertical-align: middle;">Name</th>
                                    <th style="width: 100px;vertical-align: middle;">Position</th>
                                    <!-- <th style="width: 100px;vertical-align: middle;">Email</th> -->
                                    <!-- <th style="width: 100px;vertical-align: middle;">Tel</th> -->
                                    <th style="width: 100px;vertical-align: middle;">Focal Point BU</th>
                                    <th style="width: 100px;vertical-align: middle;">Status</th>
                                    <th style="width: 20px;vertical-align: middle;">Edit</th>
                                </tr>
                            </thead>
                            <tbody style="background-color: white;font-size:16px; ">
                                <tr *ngFor='let data1 of table_ContactUs index as index'>
                                    <td style="text-align: center;">{{ index + 1 }}</td>
                                    <!-- <td style="text-align: center;" *ngIf="data1.itemAttSeq1.length === 0">
                                        <img  alt="" src="{{ data1.img }}" width="70px">
                                    </td>
                                    <td style="text-align: center;" *ngIf="data1.itemAttSeq1.length !== 0">
                                        <img  alt="" src="{{ path }}/{{ data2.pathFile }}" width="70px" height="70px" style="border-radius:50%;border:1px solid#979797;" *ngFor="let data2 of data1.itemAttSeq1">
                                    </td> -->
                                    <td>{{data1.contactName}}</td>
                                    <td>{{data1.position}}</td>
                                    <!-- <td>{{data1.email}}</td>
                                    <td>{{data1.phone}}</td> -->
                                    <td>
                                        <span class="focaltalble" *ngFor="let item of data1.arrGroupMap">{{ item }}</span>
                                    </td>
                                    <td *ngIf="data1.delFlag === '0';else elseBlock" style="text-align: center;">Active</td>
                                    <ng-template #elseBlock><td style="text-align: center;">Inactive</td></ng-template>
                                    <td style="text-align: center;">
                                        <button type="button" class="btn" (click)="func_edit( data1.contactID )">
                                            <em class="fas fa-edit" style="color:orange;font-size:16px;"></em>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>

        </div>
    </div>

    <div class="modal fade bd-Mianmenu" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" id="modaladdcontactus">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="container-fluid appform my-3">
                    <div class="row">
                        <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">
                            <div class="container col-12 col-md-12 col-lg-12 col-xl-12 pb-0 mt-0 mb-0 ">
                                <div class="container">
                                    <div class="col-12 rounded modalbanner">
                                        <div class="col-12 pt-2">
                                            <em class="fas fa-bars icon"></em>
                                            <span *ngIf="status === 'Add'">&nbsp;&nbsp;Add Contact Us {{ Regis_ContactUs.ContactImage }}</span>
                                            <span *ngIf="status === 'Edit'">&nbsp;&nbsp;Edit Contact Us</span>
                                        </div>
                                    </div>
                                    <div class="card mt-4">

                                        <!-- <div class="row col-12 mx-0 mb-4 px-0 d-flex justify-content-around">

                                            <div class="row col-12 col-xl-4 ml-0">
                                                <div class="col-12 mb-2 px-3">
                                                    <strong class="formtext">Image</strong>
                                                </div>
                                                <div class="col-12 input-group mb-3">
                                                    <div class="custom-file">
                                                        <input type="file" class="custom-file-input" id="inputGroupFile01" (change)="onSelectFile($event)">
                                                        <label class="custom-file-label" for="inputGroupFile01">
                                                            <span *ngIf="tempDisplayImage.length === 0">Choose file</span>
                                                            <span *ngIf="tempDisplayImage.length !== 0">{{ tempDisplayImage[0].name }}</span>
                                                        </label>
                                                    </div>
                                                  </div>
                                            </div>
                                            <div class="col-12 col-xl-8 pl-5">
                                                <img  alt="" src="../../../../assets/logo/photo-1103595_1280.png" height="100px" *ngIf="url === null">
                                                <img  alt="" [src]="url" width="100px" height="100px" class="bordercss"  *ngIf="url !== null">
                                            </div>

                                        </div> -->

                                        <div class="row col-12 mx-0 mb-4 px-0 d-flex justify-content-around">

                                            <div class="row col-12 col-xl-3">
                                                <div class="col-12 mb-2 px-3">
                                                    <strong class="formtext">Name</strong>
                                                </div>
                                                <div class="col-12">
                                                    <input type="text" class="form-control" aria-label="Number" matInput [formControl]="myControlEmployeeID" [matAutocomplete]="autoEmp" [(ngModel)]="Regis_ContactUs.ContactName" (keyup.enter)="AddEmpname()">
                                                    <mat-autocomplete autoActiveFirstOption #autoEmp="matAutocomplete">
                                                        <mat-option *ngFor="let option of filteredOptionsEmployeeIDDD | async" [value]="option.text" (click)="AddEmpname()">
                                                        {{ option.text }}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </div>
                                            </div>
                                            <div class="row col-12 col-xl-3">
                                                <div class="col-12 mb-2 px-3">
                                                    <strong class="formtext">Position</strong>
                                                </div>
                                                <div class="col-12">
                                                    <input type="text" class="form-control" [(ngModel)]="Regis_ContactUs.ContactPosition" disabled>
                                                </div>
                                            </div>
                                            <div class="row col-12 col-xl-3">
                                                <div class="col-12 mb-2 px-3">
                                                    <strong class="formtext">Email</strong>
                                                </div>
                                                <div class="col-12">
                                                    <input type="text" class="form-control" [(ngModel)]="Regis_ContactUs.ContactEmail" disabled>
                                                </div>
                                            </div>
                                            <div class="row col-12 col-xl-3">
                                                <div class="col-12 mb-2 px-3">
                                                    <strong class="formtext">Telephone</strong>
                                                </div>
                                                <div class="col-12">
                                                    <input type="text" class="form-control" [(ngModel)]="Regis_ContactUs.ContactTel">
                                                </div>
                                            </div>

                                        </div>

                                        <div class="row col-12 mx-0 mb-4 px-0 d-flex justify-content-around">

                                            <div class="row col-12 col-xl-3">
                                                <div class="col-12 mb-2 px-3">
                                                    <strong class="formtext">Business Unit</strong>
                                                </div>
                                                <div class="col-12 input-group mb-3">                                               
                                                    <input type="text" class="form-control m-0" aria-label="Number" matInput [formControl]="myControl" [matAutocomplete]="auto" [(ngModel)]="Regis_ContactUs.ContactFocal" (keyup.enter)="AddFocal()">
                                                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option.text">
                                                        {{ option.text }}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                    <div class="input-group-append">
                                                      <button class="btn" type="button" (click)="AddFocal()" style="background-color:#033153;color:white;">Add</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row col-12 col-xl-9 pl-4">
                                                <div class="col-12">&nbsp;</div>
                                                <div *ngFor="let item of tempBU">
                                                    <span class="badge badge-custom mr-0"><strong>{{ item.text }}</strong></span>
                                                    <button type="button" class="btn btn-sm btn-danger btn-custom" (click)="RemoveFocal(item.text)">
                                                        <em class="fas fa-times"></em>
                                                    </button>
                                                </div>
                                            </div>

                                        </div>

                                        <div class="row col-12 mx-0 mb-4 px-0 d-flex justify-content-around">
                                            <div class="row col-12 col-xl-6">
                                                <div class="col-2">
                                                    <label for="status"><strong class="formtext">Status</strong></label>
                                                </div>
                                                <div class="col-10" style="padding-top: 4px;">
                                                    <input type="checkbox" id="status" [(ngModel)]="Regis_ContactUs.ContactStatus">
                                                </div>
                                            </div>
                                            <!-- <div class="row col-12 col-xl-3">
                                                <div class="col-4">
                                                    <label for="statuspri"><strong class="formtext">Priority</strong></label>
                                                </div>
                                                <div class="col-8" style="padding-top: 4px;">
                                                    <input type="checkbox" id="statuspri" [(ngModel)]="Regis_ContactUs.ContactPriority">
                                                </div>
                                            </div> -->
                                            <div class="row col-12 col-xl-6"></div>
                                        </div>

                                    </div>
                                    <div class="col-12 my-3 mx-0 text-center">
                                        <button type="button" class="btn mr-3" style="width: 100px;background-color:#9eb0ba;color:white;" (click)="func_closebtn()">Close</button>
                                        <button type="button" class="btn" style="width: 100px;background-color:#033153;color:white;" (click)="func_savebtn('insert')" *ngIf="status === 'Add'">Save</button>
                                        <button type="button" class="btn" style="width: 100px;background-color:#033153;color:white;" (click)="func_savebtn('update')" *ngIf="status === 'Edit'">Update</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalAlertdanger">
        <div class="modal-dialog modal-dialog-centered" role="document"> 
        <div class="modal-content">
            <span class="bg-danger">&nbsp;</span>
            <div class="modal-header">
                <div class="row col-12 text-danger">
                    <div class="col-2">
                        <em class="fas fa-times-circle" style="font-size:50px;"></em>
                    </div>
                    <div class="col-10 pt-2">
                        <h4 class="modal-title">Alert !</h4>
                    </div>
                </div>
            </div>
            <div class="modal-body text-body">
                <div class="col-12" style="text-align: left;">
                    <span style="font-size:18px;">{{ alert }}</span>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger text-white w-25" (click)="funcYes()">Ok</button>
            </div>
        </div>
        </div>
    </div>
</div>

<app-loading [loading]='loading'></app-loading>