import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RiskMap } from '../../services/riskmap.service';
import { Masterserve } from '../../services/master.service';
import { Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AuthenService } from 'src/app/services/authenservice.service';

@Component({
  selector: 'app-comparerisk',
  templateUrl: './page.comparerisk.html',
  styleUrls: ['./page.comparerisk.css'],
  providers: [RiskMap, Masterserve, NgbModal]
})

export class Comparerisk implements OnInit {

  constructor(private modalService: NgbModal, public mserve: RiskMap, private MServ: Masterserve, private router: Router, public authserviceToken: AuthenService) { }

  loading = true;
  list = {
    risk: {
      impactcolor: [],
      impact: [],
      risktable: [
        {
          row: 1,
          col: [
            {
              col: 0, class: 'impact-x', item: [
                { title: 'Critical', bgcolor: '#1b190f', color: '#edf0f7', index: '', show: true, Risk_Id: null, IsStripes: false, Risk_Co_Id: null },
                { title: '&nbsp;', color: '#edf0f7' },
                { title: '-', bgcolor: '#0e223c', color: '#edf0f7' },
                { title: '&nbsp;', color: '#edf0f7' },
                { title: '-', bgcolor: '#5c2322', color: '#edf0f7' }
              ]
            },
            { col: 1, class: 'medium', item: [] },
            { col: 2, class: 'medium', item: [] },
            { col: 3, class: 'high', item: [] },
            { col: 4, class: 'high', item: [] },
            { col: 5, class: 'high', item: [] },
          ]
        },
        {
          row: 2,
          col: [
            {
              col: 0, class: 'impact-x', item: [
                { title: 'Serious', bgcolor: '#444128', color: '#edf0f7' },
                { title: '&nbsp;', color: '#edf0f7' },
                { title: '-', bgcolor: '#32598d', color: '#edf0f7' },
                { title: '&nbsp;', color: '#edf0f7' },
                { title: '-', bgcolor: '#453e25', color: '#edf0f7' }
              ]
            },
            { col: 1, class: 'medium', item: [] },
            { col: 2, class: 'medium', item: [] },
            { col: 3, class: 'medium', item: [] },
            { col: 4, class: 'high', item: [] },
            { col: 5, class: 'high', item: [] },
          ]
        },
        {
          row: 3,
          col: [
            {
              col: 0, class: 'impact-x', item: [
                { title: 'Significant', bgcolor: '#8b8352', color: 'black' },
                { title: 'NPV/EMV (MMUSD)', color: '#4649f7' },
                { title: '-', bgcolor: '#4d85ce', color: 'black' },
                { title: 'NI (MMUSD)', color: '#ba2527' },
                { title: '-', bgcolor: '#cb8d8f', color: 'black' }
              ]
            },
            { col: 1, class: 'low', item: [] },
            { col: 2, class: 'medium', item: [] },
            { col: 3, class: 'medium', item: [] },
            { col: 4, class: 'medium', item: [] },
            { col: 5, class: 'high', item: [] },
          ]
        },
        {
          row: 4,
          col: [
            {
              col: 0, class: 'impact-x', item: [
                { title: 'Moderate', bgcolor: '#b9b696', color: 'black' },
                { title: '&nbsp;', color: '#edf0f7' },
                { title: '-', bgcolor: '#83a9db', color: 'black' },
                { title: '&nbsp;', color: '#edf0f7' },
                { title: '-', bgcolor: '#d6adb1', color: 'black' }
              ]
            },
            { col: 1, class: 'low', item: [] },
            { col: 2, class: 'low', item: [] },
            { col: 3, class: 'medium', item: [] },
            { col: 4, class: 'medium', item: [] },
            { col: 5, class: 'medium', item: [] },
          ]
        },
        {
          row: 5,
          col: [
            {
              col: 0, class: 'impact-x', item: [
                { title: 'Minor', bgcolor: '#cdccbe', color: 'black' },
                { title: '&nbsp;', color: '#edf0f7' },
                { title: '-', bgcolor: '#aad1e1', color: 'black' },
                { title: '&nbsp;', color: '#edf0f7' },
                { title: '-', bgcolor: '#e1cfd4', color: 'black' }]
            },
            { col: 1, class: 'low', item: [] },
            { col: 2, class: 'low', item: [] },
            { col: 3, class: 'low', item: [] },
            { col: 4, class: 'medium', item: [] },
            { col: 5, class: 'medium', item: [] },
          ]
        },
        {
          row: 0,
          col: [
            { col: 0, class: 'impact-y', item: [] },
            { col: 1, class: 'impact-y', item: [{ title: 'Rare', bgcolor: '#abd1e1', color: 'black' }] },
            { col: 2, class: 'impact-y', item: [{ title: 'Unlikely', bgcolor: '#83a9db', color: 'black' }] },
            { col: 3, class: 'impact-y', item: [{ title: 'Possible', bgcolor: '#85aadb', color: 'black' }] },
            { col: 4, class: 'impact-y', item: [{ title: 'Likely', bgcolor: '#325a8d', color: 'white' }] },
            { col: 5, class: 'impact-y', item: [{ title: 'Almost Certain', bgcolor: '#0d213b', color: 'white' }] },
          ]
        }
      ],
      riskitems: [],
      riskitems2: []
    },
    riskprofile: {
      year: [],
      quarter: []
    },
    businessUnit: {
      items: []
    },
    field: {
      Mitigate: 1,
      riskprofileYear: '',
      riskprofileQuarter: '',
      businessUnit: '',
    }
  }

  tempCurrentQuarterID_first = null;
  tempCurrentQuarterID_second = null;

  tempbu = ''
  Search = {
    YearHQ1: '',
    QuarterHQ1: '',
    YearHQ2: '',
    QuarterHQ2: '',
    RiskLevel: '',
    Escalation: false,
    BU: ''
  }
  tempQ1 = '';
  tempQ2 = '';

  YearDD;
  QuarterCal = [];
  QuarterDD;
  QuarterDD2;
  tableriskrating;
  Risk_Level;
  BUDD = [];
  myControl = new UntypedFormControl();
  filteredOptions: Observable<string[]>;


  tempfilter = [];


  ngOnInit(): void {
    const _s = this;
    _s.func_GetRatingDD();
  }

  func_GetRatingDD() {
    const _s = this;
    this.loading = true
    _s.MServ.Master_DDL({ Module: "RatingDD" }).then((response) => {
      if (response.data.status) {
        //console.log('RatingDD : ', response.data.body)
        this.Risk_Level = response.data.body;
        this.func_GetBU();
      } else {
        //console.log('false', response)
      }
    });
  }
  func_GetBU() {
    this.MServ.Master_DDL({ Module: 'DepartmentDD' }).then((response) => {
      //console.log("BUDD :", response.data.body)
      if (response.data.status) {
        this.BUDD = response.data.body
        this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value))
        );
        this.loadDDL();
      } else {
        //console.log('false', response)
      }
    });
  }
  loadDDL() {

    const _s = this;
    _s.mserve.getQuarterYearDD({ Module: 'YearDD' }).then((response) => {
      if (response.data.status) {
        //console.log('YearDD :', response.data.body);
        this.YearDD = response.data.body;

        _s.mserve.getQuarterDD({ 'Module': 'QuarterDD', 'TextSearch1': response.data.body[0].value }).then((response) => {
          //console.log('QuarterCal : ', response)
          _s.QuarterCal = response.data.body;
          let d = new Date();
          let currentMonth = d.getMonth() + 1;
          _s.func_calQuarter(currentMonth);
        }).catch((e) => {
          if (e.response) {
            this.authserviceToken.CheckTokenExpire(e.response.status);
          } else {
            //console.log(e);
          }
        });
      }
      else {
        //console.log('YearDD : false', response)
      }
    }).catch((e) => {
      if (e.response) {
        this.authserviceToken.CheckTokenExpire(e.response.status);
      } else {
        //console.log(e);
      }
    });
  }

  func_calQuarter(month) {

    const _s = this;
    _s.list.field.riskprofileQuarter = ''
    if (month >= 1 && month <= 3) {
      _s.list.field.riskprofileQuarter = this.QuarterCal[0].value;
    } else if (month >= 4 && month <= 6) {
      _s.list.field.riskprofileQuarter = this.QuarterCal[1].value;
    } else if (month >= 7 && month <= 9) {
      _s.list.field.riskprofileQuarter = this.QuarterCal[2].value;
    } else if (month >= 10 && month <= 12) {
      _s.list.field.riskprofileQuarter = this.QuarterCal[3].value;
    }

    if (sessionStorage.getItem('PageBack') !== '') {
      let tempfileter = JSON.parse(sessionStorage.FilterRiskMapCompare);
      _s.Search.YearHQ1 = tempfileter[0].left[0].Year
      _s.Search.QuarterHQ1 = tempfileter[0].left[0].QuarterHQ1Name

      _s.Search.YearHQ2 = tempfileter[0].right[0].Year
      _s.Search.QuarterHQ2 = tempfileter[0].right[0].QuarterHQ2Name

      this.tempbu = tempfileter[0].right[0].Buname
      this.Search.BU = tempfileter[0].right[0].BusinessCode
      _s.Search.RiskLevel = tempfileter[0].right[0].Risk_Level;
      _s.func_GetQuarter()
      _s.func_GetQuarter2()

    }
    else {
      this.func_GETDATA_RiskRating('');
    }

  }

  func_GETDATA_RiskRating(value) {
    const _s = this;
    _s.list.field.riskprofileQuarter = (value == 'Search') ? '' : _s.list.field.riskprofileQuarter
    let TempQuarter = (_s.list.field.riskprofileQuarter == '') ? _s.Search.QuarterHQ2 : _s.list.field.riskprofileQuarter

    if (_s.Search.QuarterHQ2 !== '') {
      for (let item_QuarterDD2 of this.QuarterDD2) {
        if (TempQuarter == item_QuarterDD2.text) {
          TempQuarter = item_QuarterDD2.value;
          break;
        }
      }
    }

    let formdata = {
      SessionEmpID: 1,
      Module: "getlist",
      body: {
        RiskRatingID: "",
        QuarterID: TempQuarter,
        RiskRatingCode: "Test0002",
        Likelihood: "2",
        Impact: "3",
        RiskRating: "",
        EscalationLevel: "1",
        DelFlag: "0",
        LikelihoodAndImpact: ""
      }
    }
    this.MServ.GetRiskRating(formdata).then((response) => {
      //console.log("RiskRating Data :", response.data.body)
      if (response.data.status) {
        this.tableriskrating = response.data.body;
        this.func_calExculationLevel(value);
      } else {
        //console.log('false', response)
        alert(response.data.errorMessage);
      }
    }).catch((e) => {
      if (e.response) {
        if (e.response) {
          this.authserviceToken.CheckTokenExpire(e.response.status);
        } else {
          //console.log(e);
        }

      } else {
        //console.log(e)
      }
    });
  }

  func_calExculationLevel(value) {
    const _s = this;
    let tempMedEsc = []
    _s.tableriskrating.forEach(function (ItemVal, ItemIndex) {
      if (ItemVal.riskRating === "Medium" && ItemVal.escalationLevel === "1") {
        tempMedEsc.push(ItemVal)
      }
    });
    if (tempMedEsc.length !== 0) {
      this.func_calExculationLevel_Med_MoreZero(tempMedEsc, _s);
    }
    else {
      this.func_calExculationLevel_Med_Zero(_s);
    }

    if (sessionStorage.getItem('PageBack') !== '') {
      let tempfileter = JSON.parse(sessionStorage.FilterRiskMapCompare);
      //console.log(tempfileter)
    }

    if (value == 'Search') {
      _s.loadRisk()
    } else {
      this.loading = false
    }
    //console.log('_s.list.risk.risktable with esclevel : ', _s.list.risk.risktable)

  }

  private func_calExculationLevel_Med_Zero(_s: this) {
    _s.list.risk.risktable[4].col[4].class = "medium";
    _s.list.risk.risktable[4].col[5].class = "medium";
    _s.list.risk.risktable[3].col[3].class = "medium";
    _s.list.risk.risktable[3].col[4].class = "medium";
    _s.list.risk.risktable[3].col[5].class = "medium";
    _s.list.risk.risktable[2].col[2].class = "medium";
    _s.list.risk.risktable[2].col[3].class = "medium";
    _s.list.risk.risktable[2].col[4].class = "medium";
    _s.list.risk.risktable[1].col[1].class = "medium";
    _s.list.risk.risktable[1].col[2].class = "medium";
    _s.list.risk.risktable[1].col[3].class = "medium";
    _s.list.risk.risktable[0].col[1].class = "medium";
    _s.list.risk.risktable[0].col[2].class = "medium";
  }

  private func_calExculationLevel_Med_MoreZero(tempMedEsc: any[], _s: this) {
    for (let item_tempMedEsc of tempMedEsc) {
      if (item_tempMedEsc.impact === "Minor") {
        this.minor_Condition(item_tempMedEsc, _s);
      }
      if (item_tempMedEsc.impact === "Moderate") {
        this.moderate_Condition(item_tempMedEsc, _s);
      }
      if (item_tempMedEsc.impact === "Significant") {
        this.significant_condition(item_tempMedEsc, _s);
      }

      if (item_tempMedEsc.impact === "Serious") {
        this.serious_condition(item_tempMedEsc, _s);
      }

      if (item_tempMedEsc.impact === "Critical") {
        this.critical_condition(item_tempMedEsc, _s);
      }

    }
  }

  private critical_condition(item_tempMedEsc: any, _s: this) {
    if (item_tempMedEsc.likelihood === "Rare") {
      _s.list.risk.risktable[0].col[1].class = "Escalation";
    }
    else if (item_tempMedEsc.likelihood === "Unlikely") {
      _s.list.risk.risktable[0].col[2].class = "Escalation";
    }
  }

  private serious_condition(item_tempMedEsc: any, _s: this) {
    if (item_tempMedEsc.likelihood === "Rare") {
      _s.list.risk.risktable[1].col[1].class = "Escalation";
    }
    else if (item_tempMedEsc.likelihood === "Unlikely") {
      _s.list.risk.risktable[1].col[2].class = "Escalation";
    }
    else if (item_tempMedEsc.likelihood === "Possible") {
      _s.list.risk.risktable[1].col[3].class = "Escalation";
    }
  }

  private significant_condition(item_tempMedEsc: any, _s: this) {
    if (item_tempMedEsc.impact === "Significant" && item_tempMedEsc.likelihood === "Unlikely") {
      _s.list.risk.risktable[2].col[2].class = "Escalation";
    }
    else if (item_tempMedEsc.impact === "Significant" && item_tempMedEsc.likelihood === "Possible") {
      _s.list.risk.risktable[2].col[3].class = "Escalation";
    }
    else if (item_tempMedEsc.impact === "Significant" && item_tempMedEsc.likelihood === "Likely") {
      _s.list.risk.risktable[2].col[4].class = "Escalation";
    }
  }

  private moderate_Condition(item_tempMedEsc: any, _s: this) {
    if (item_tempMedEsc.likelihood === "Possible") {
      _s.list.risk.risktable[3].col[3].class = "Escalation";
    }
    else if (item_tempMedEsc.likelihood === "Likely") {
      _s.list.risk.risktable[3].col[4].class = "Escalation";
    }
    else if (item_tempMedEsc.likelihood === "Almost Certain") {
      _s.list.risk.risktable[3].col[5].class = "Escalation";
    }
  }

  private minor_Condition(item_tempMedEsc: any, _s: this) {
    if (item_tempMedEsc.likelihood === "Likely") {
      _s.list.risk.risktable[4].col[4].class = "Escalation";
    }
    else if (item_tempMedEsc.likelihood === "Almost Certain") {
      _s.list.risk.risktable[4].col[5].class = "Escalation";
    }
  }

  loadRisk() {
    const _s = this;
    this.loadRisk_II_ClearRisktable(_s);

    let d = new Date();
    let cur_year = d.getFullYear();

    let tempQuarterID = null, tempWPBID = null, tempQuarterMaster = null;
    ({ tempQuarterID, tempQuarterMaster, tempWPBID } = this.loadRisk_setTempData(_s, cur_year, tempQuarterID, tempQuarterMaster, tempWPBID));

    let tempQuarterID2 = null, tempWPBID2 = null, tempQuarterMaster2 = null
    if (_s.Search.YearHQ2 !== '') {
      if (cur_year === parseInt(_s.Search.YearHQ2)) {
        if (_s.Search.QuarterHQ2.includes("WPB") === false) {
          ({ tempQuarterID2, tempQuarterMaster2 } = this.setTempQuarter(_s, tempQuarterID2, tempQuarterMaster2));
        }
        else {
          tempWPBID2 = this.setTempQuarterII(_s, tempWPBID2);
          tempQuarterMaster2 = this.setTempQuarterIII(_s, tempQuarterMaster2);
        }
      }
      else {
        if (_s.Search.QuarterHQ2.includes("WPB") === false) {
          ({ tempQuarterID2, tempQuarterMaster2 } = this.setTempQuarter(_s, tempQuarterID2, tempQuarterMaster2));
        } else {
          tempWPBID2 = this.setTempQuarterII(_s, tempWPBID2);
          tempQuarterMaster2 = _s.QuarterDD2[3].value
        }
      }
    }

    let tempObj = {
      QuarterID: tempQuarterMaster2,
      BusinessCode: this.Search.BU,
    }

    let tempObjRisk1 = this.setTempRisk1(tempQuarterID, _s, tempWPBID, tempQuarterMaster)
    this.tempfilter = [];
    this.tempfilter.push({ left: tempObjRisk1 })

    let tempObjRisk2 = this.setTempRisk2(tempQuarterID2, _s, tempWPBID2, tempQuarterMaster2)
    this.tempfilter.push({ right: tempObjRisk2 })

    this.loadRisk_II_ChkBusinessCode(tempObj, tempObjRisk1, tempObjRisk2, _s);
  }

  private loadRisk_II_ChkBusinessCode(tempObj: { QuarterID: any; BusinessCode: string; }, tempObjRisk1: { QuarterID: any; BusinessCode: string; Year: string; TypeMitigate: string; WPBID: any; QuaterMaster: any; Risk_Level: string; Risk_Escalation: string; }, tempObjRisk2: { QuarterID: any; BusinessCode: string; Year: string; TypeMitigate: string; WPBID: any; QuaterMaster: any; Risk_Level: string; Risk_Escalation: string; }, _s: this) {
    if (tempObj.BusinessCode === '') {
      alert('Please select BU');
    }
    else if ((tempObjRisk1.QuaterMaster === null || tempObjRisk2.QuaterMaster === null)) {
      alert('Please select both Year and Quarter that you need to compare');
    }
    else {
      this.loading = true;
      this.callGetimpact2Service(_s, tempObj);
      this.callGetRiskItemService(_s, tempObjRisk1, tempObjRisk2);
    }
  }

  private loadRisk_II_ClearRisktable(_s: this) {
    _s.list.risk.risktable.forEach(function (ItemVal, ItemIndex) {
      if (ItemIndex < 5) {
        _s.list.risk.risktable[ItemIndex].col[1].item = [];
        _s.list.risk.risktable[ItemIndex].col[2].item = [];
        _s.list.risk.risktable[ItemIndex].col[3].item = [];
        _s.list.risk.risktable[ItemIndex].col[4].item = [];
        _s.list.risk.risktable[ItemIndex].col[5].item = [];
      }
    });
  }

  private setTempQuarterII(_s: this, tempWPBID: any) {
    let tempWPBID2 = tempWPBID;
    for (let item_QuarterDD2 of this.QuarterDD2) {
      if (_s.Search.QuarterHQ2 === item_QuarterDD2.text) {
        tempWPBID2 = item_QuarterDD2.value;
        break;
      }
    }
    return tempWPBID2;
  }

  private setTempQuarter(_s: this, tempQuarterID: any, tempQuarterMaster: any) {
    let tempQuarterID2 = tempQuarterID;
    let tempQuarterMaster2 = tempQuarterMaster;
    for (let item_QuarterDD2 of this.QuarterDD2) {
      if (_s.Search.QuarterHQ2 === item_QuarterDD2.text) {
        tempQuarterID2 = item_QuarterDD2.value;
        tempQuarterMaster2 = item_QuarterDD2.value;
        break;
      }
    }
    return { tempQuarterID2, tempQuarterMaster2 };
  }

  private setTempQuarterIII(_s: this, tempQuarterMaster: any) {
    let tempQuarterMaster2 = tempQuarterMaster;
    for (let item_QuarterDD2 of this.QuarterDD2) {
      if (_s.tempCurrentQuarterID_second === item_QuarterDD2.text) {
        tempQuarterMaster2 = item_QuarterDD2.value;
        break;
      }
    }
    return tempQuarterMaster2;
  }


  private loadRisk_setTempData(_s: this, cur_year: number, tempQuarterID: any, tempQuarterMaster: any, tempWPBID: any) {

    if (_s.Search.YearHQ1 !== '') {
      if (cur_year === parseInt(_s.Search.YearHQ1)) {
        if (_s.Search.QuarterHQ1.includes("WPB") === false) {
          ({ tempQuarterID, tempQuarterMaster } = this.setTempQuarterMasterI(_s, tempQuarterID, tempQuarterMaster));
        } else {
          tempWPBID = this.setTempQuarterMasterII(_s, tempWPBID);
          tempQuarterMaster = this.setTempQuarterMasterIII(_s, tempQuarterMaster);
        }
      }
      else {
        if (_s.Search.QuarterHQ1.includes("WPB") === false) {
          ({ tempQuarterID, tempQuarterMaster } = this.setTempQuarterMasterIV(_s, tempQuarterID, tempQuarterMaster));
        } else {
          tempWPBID = this.setTempQuarterMasterII(_s, tempWPBID);
          tempQuarterMaster = _s.QuarterDD[3].value;
        }
      }
    }
    return { tempQuarterID, tempQuarterMaster, tempWPBID };
  }

  private setTempQuarterMasterI(_s: this, tmpQuarterID: any, tmpQuarterMaster: any) {
    let tempQuarterID = tmpQuarterID;
    let tempQuarterMaster = tmpQuarterMaster;
    for (let item_QuarterDD of this.QuarterDD) {
      if (_s.Search.QuarterHQ1 === item_QuarterDD.text) {
        tempQuarterID = item_QuarterDD.value;
        tempQuarterMaster = item_QuarterDD.value;
        break;
      }
    }
    return { tempQuarterID: tempQuarterID, tempQuarterMaster: tempQuarterMaster };
  }



  private setTempQuarterMasterIII(_s: this, tmpQuarterMaster: any) {
    let tempQuarterMaster = tmpQuarterMaster;
    for (let item_QuarterDD of this.QuarterDD) {
      if (_s.tempCurrentQuarterID_first === item_QuarterDD.text) {
        tempQuarterMaster = item_QuarterDD.value;
        break;
      }
    }
    return tempQuarterMaster;
  }

  private setTempQuarterMasterIV(_s: this, tmpQuarterID: any, tmpQuarterMaster: any) {
    let tempQuarterID = tmpQuarterID;
    let tempQuarterMaster = tmpQuarterMaster;
    for (let item_QuarterDD of this.QuarterDD) {
      if (_s.Search.QuarterHQ1 === item_QuarterDD.text) {
        tempQuarterID = item_QuarterDD.value;
        tempQuarterMaster = item_QuarterDD.value;
        break;
      }
    }
    return { tempQuarterID, tempQuarterMaster };
  }

  private setTempQuarterMasterII(_s: this, tmpWPBID: any) {
    let tempWPBID = tmpWPBID;
    for (let item_QuarterDD of this.QuarterDD) {
      if (_s.Search.QuarterHQ1 === item_QuarterDD.text) {
        tempWPBID = item_QuarterDD.value;
        break;
      }
    }
    return tempWPBID;
  }

  private callGetRiskItemService(_s: this, tempObjRisk1: { QuarterID: any; BusinessCode: string; Year: string; TypeMitigate: string; WPBID: any; QuaterMaster: any; Risk_Level: string; Risk_Escalation: string; }, tempObjRisk2: { QuarterID: any; BusinessCode: string; Year: string; TypeMitigate: string; WPBID: any; QuaterMaster: any; Risk_Level: string; Risk_Escalation: string; }) {
    _s.mserve.getRiskitem(tempObjRisk1).then((response) => {
      if (response.data.status) {
        //console.log('getRiskitem : true', response);
        _s.list.risk.riskitems = response.data.body;

        this.checkriskitems(_s);

        //console.log(' _s.list.risk.risktable', _s.list.risk.risktable);
        _s.func_getriskright(tempObjRisk2);
      }
      else {
        alert(response.data.errorMessage);
        //console.log('getRiskitem : false', response);
      }
    }).catch((e) => {
      if (e.response) {
        if (e.response) {
          this.authserviceToken.CheckTokenExpire(e.response.status);
        } else {
          //console.log(e);
        }
        ;
      } else {
        //console.log(e.errorMessage);
      }


    });
  }

  private checkriskitems(_s: this) {
    if (_s.list.risk.riskitems.length !== 0) {
      _s.list.risk.riskitems.forEach(function (ItemVal, ItemIndex) {
        //console.log('y :' + (5 - ItemVal.Y_Impact) + '-- x :' + parseInt(ItemVal.X_Likelihood));
        if (ItemVal.Impact_Category === '1') {
          settempQuarterMasterIII(_s, ItemVal, ItemIndex);
        }
        else {
          newMethod_1(_s, ItemVal, ItemIndex);
        }
      });
    }

    function newMethod_1(_s: any, ItemVal: any, ItemIndex: number) {
      const bgColor = _s.list.risk.risktable[(5 - ItemVal.Y_Impact)].col[0].item[0].bgcolor;
      const Color = _s.list.risk.risktable[(5 - ItemVal.Y_Impact)].col[0].item[0].color;
      _s.list.risk.risktable[(5 - ItemVal.Y_Impact)].col[parseInt(ItemVal.X_Likelihood)].item.push({
        index: (ItemIndex + 1).toString(),
        title: _s.list.risk.riskitems[ItemIndex].Risk_Name,
        bgcolor: bgColor,
        color: Color,
        show: true,
        Risk_Id: _s.list.risk.riskitems[ItemIndex].Risk_Id,
        IsStripes: true,
        Risk_Co_Id: _s.list.risk.riskitems[ItemIndex].Risk_Co_Id
      });
    }

    function settempQuarterMasterIII(_s: any, ItemVal: any, ItemIndex: number) {
      const bgColor = _s.list.risk.risktable[(5 - ItemVal.Y_Impact)].col[0].item[(ItemVal.Impact_NPT_EMV === '1') ? 4 : 2].bgcolor;
      const Color = _s.list.risk.risktable[(5 - ItemVal.Y_Impact)].col[0].item[(ItemVal.Impact_NPT_EMV === '1') ? 4 : 2].color;

      _s.list.risk.risktable[(5 - ItemVal.Y_Impact)].col[parseInt(ItemVal.X_Likelihood)].item.push({
        index: (ItemIndex + 1).toString(),
        title: _s.list.risk.riskitems[ItemIndex].Risk_Name,
        bgcolor: bgColor,
        color: Color,
        show: true,
        Risk_Id: _s.list.risk.riskitems[ItemIndex].Risk_Id,
        IsStripes: true,
        Risk_Co_Id: _s.list.risk.riskitems[ItemIndex].Risk_Co_Id
      });
    }


  }


  private callGetimpact2Service(_s: this, tempObj: { QuarterID: any; BusinessCode: string; }) {
    _s.mserve.getImpact2(tempObj).then((response) => {
      if (response.data.status) {
        //console.log('getImpact : true', response);
        if (response.data.body.length === 0) {
          _s.list.risk.impact = [];
          this.checkRisktable(_s);
        }
        else {
          _s.list.risk.impact = response.data.body;
          const setValue = this.checknumberWithCommas(_s);
          this.checkRiskImpact(_s, setValue);
        }
      }
      else {
        alert(response.data.errorMessage);
        //console.log('getImpact : false', response);
      }
    }).catch((e) => {
      if (e.response) {
        this.authserviceToken.CheckTokenExpire(e.response.status);
      } else {
        //console.log(e);
      }
      ;
    });
  }

  private checkRisktable(_s: this) {
    _s.list.risk.risktable.forEach(function (ItemVal, ItemIndex) {
      if (ItemIndex < 5) {
        _s.list.risk.risktable[ItemIndex].col[0].item[2].title = '-';
        _s.list.risk.risktable[ItemIndex].col[0].item[4].title = '-';
      }
    });
  }

  private checknumberWithCommas(_s: this) {
    return function (item, para, index) {
      let temp = '';
      if (index !== 4) {
        if (item[para + '_t'] === null) temp = '< ' + _s.numberWithCommas(item[para + '_f']);
      } else {
        if (item[para + '_t'] === null) temp = '> ' + _s.numberWithCommas(item[para + '_f']);
      }
      if (item[para + '_f'] === null) temp = _s.numberWithCommas(item[para + '_t']) + ' >';
      if (item[para + '_f'] !== null && item[para + '_t'] !== null) temp = _s.numberWithCommas(item[para + '_f']) + ' - ' + _s.numberWithCommas(item[para + '_t']);
      return temp;
    };
  }

  private checkRiskImpact(_s: this, setValue: (item: any, para: any, index: any) => string) {
    _s.list.risk.impact.forEach(function (ItemVal, ItemIndex) {
      _s.list.risk.risktable[((_s.list.risk.impact.length - 1) - ItemIndex)].col[0].item[0].title = ItemVal.ImpactCateItemName;
      _s.list.risk.risktable[((_s.list.risk.impact.length - 1) - ItemIndex)].col[0].item[2].title = setValue(ItemVal, 'NPV', ItemIndex);
      _s.list.risk.risktable[((_s.list.risk.impact.length - 1) - ItemIndex)].col[0].item[4].title = setValue(ItemVal, 'NI', ItemIndex);
    });
  }

  private setTempRisk2(tempQuarterID2: any, _s: this, tempWPBID2: any, tempQuarterMaster2: any) {
    return {
      QuarterID: tempQuarterID2,
      BusinessCode: _s.Search.BU,
      Year: _s.Search.YearHQ2,
      TypeMitigate: '1',
      WPBID: tempWPBID2,
      QuaterMaster: tempQuarterMaster2,
      Risk_Level: _s.Search.RiskLevel,
      Risk_Escalation: (_s.Search.Escalation === false) ? '0' : '1'
    };
  }

  private setTempRisk1(tempQuarterID: any, _s: this, tempWPBID: any, tempQuarterMaster: any) {
    return {
      QuarterID: tempQuarterID,
      BusinessCode: _s.Search.BU,
      Year: _s.Search.YearHQ1,
      TypeMitigate: '1',
      WPBID: tempWPBID,
      QuaterMaster: tempQuarterMaster,
      Risk_Level: _s.Search.RiskLevel,
      Risk_Escalation: (_s.Search.Escalation === false) ? '0' : '1'
    };
  }

  func_getriskright(tempObjRisk2) {
    const _s = this;
    _s.mserve.getRiskitem(tempObjRisk2).then((response) => {
      this.getRiskitem(response, _s);
    }).catch((e) => {
      if (e.response) {
        this.authserviceToken.CheckTokenExpire(e.response.status);
      } else {
        //console.log(e);
      }
    });
  }

  private getRiskitem(response: any, _s: this) {
    if (response.data.status) {
      //console.log('getRiskitem2 : true', response);
      _s.list.risk.riskitems2 = response.data.body;
      if (_s.list.risk.riskitems2.length !== 0) {
        _s.list.risk.riskitems2.forEach(function (ItemVal, ItemIndex) {
          //console.log('y :' + (5 - ItemVal.Y_Impact) + '-- x :' + parseInt(ItemVal.X_Likelihood));
          if (ItemVal.Impact_Category === '1') {
            is_Impact_Category(_s, ItemVal, ItemIndex);
          }
          else {
          isnot_Impact_Category(_s, ItemVal, ItemIndex);
          }
        });
      }

      //console.log(' _s.list.risk.risktable', _s.list.risk.risktable);
      _s.loading = false;
    }
    else {
      alert(response.data.errorMessage);
      //console.log('getRiskitem : false', response);
    }
    function  isnot_Impact_Category(_s: any, ItemVal: any, ItemIndex: number) {
      const bgColor = _s.list.risk.risktable[(5 - ItemVal.Y_Impact)].col[0].item[0].bgcolor;
      const Color = _s.list.risk.risktable[(5 - ItemVal.Y_Impact)].col[0].item[0].color;
      _s.list.risk.risktable[(5 - ItemVal.Y_Impact)].col[parseInt(ItemVal.X_Likelihood)].item.push({
        index: (ItemIndex + 1).toString(),
        title: _s.list.risk.riskitems2[ItemIndex].Risk_Name,
        bgcolor: bgColor,
        color: Color,
        show: true,
        Risk_Id: _s.list.risk.riskitems2[ItemIndex].Risk_Id,
        IsStripes: false,
        Risk_Co_Id: _s.list.risk.riskitems2[ItemIndex].Risk_Co_Id
      });
    }
  
    function  is_Impact_Category(_s: any, ItemVal: any, ItemIndex: number) {
      const bgColor = _s.list.risk.risktable[(5 - ItemVal.Y_Impact)].col[0].item[(ItemVal.Impact_NPT_EMV === '1') ? 4 : 2].bgcolor;
      const Color = _s.list.risk.risktable[(5 - ItemVal.Y_Impact)].col[0].item[(ItemVal.Impact_NPT_EMV === '1') ? 4 : 2].color;
  
      _s.list.risk.risktable[(5 - ItemVal.Y_Impact)].col[parseInt(ItemVal.X_Likelihood)].item.push({
        index: (ItemIndex + 1).toString(),
        title: _s.list.risk.riskitems2[ItemIndex].Risk_Name,
        bgcolor: bgColor,
        color: Color,
        show: true,
        Risk_Id: _s.list.risk.riskitems2[ItemIndex].Risk_Id,
        IsStripes: false,
        Risk_Co_Id: _s.list.risk.riskitems2[ItemIndex].Risk_Co_Id
      });
    }
  
  }



  func_GetQuarter() {
    const _s = this;
    this.loading = true;
    _s.mserve.getQuarterDD({ Module: "QuarterDD", 'TextSearch1': this.Search.YearHQ1 }).then((response) => {
      if (response.data.status) {
        //console.log("QuarterDD : ", response.data.body)
        this.QuarterDD = response.data.body;
        let d = new Date();
        let currentMonth = d.getMonth() + 1;
        _s.func_calQuarterFirst(currentMonth);
      }
      else {
        //console.log('QuarterDD : false', response)
      }
    }).catch((e) => {
      if (e.response) {
        this.authserviceToken.CheckTokenExpire(e.response.status);
      } else {
        //console.log(e);
      }
    });
  }
  func_calQuarterFirst(month) {
    const _s = this;
    let temp = sessionStorage.getItem('PageBack')
    if (temp == '' || temp == undefined) {
      this.loading = false;
    }
    let tempQ_first = ''
    if (month >= 1 && month <= 3) {
      tempQ_first = this.QuarterCal[0].text;
    } else if (month >= 4 && month <= 6) {
      tempQ_first = this.QuarterCal[1].text;
    } else if (month >= 7 && month <= 9) {
      tempQ_first = this.QuarterCal[2].text;
    } else if (month >= 10 && month <= 12) {
      tempQ_first = this.QuarterCal[3].text;
    }
    this.tempCurrentQuarterID_first = tempQ_first;
  }
  selQ1() {
    if (this.Search.QuarterHQ1 !== '') {
      for (let item_QuarterDD of this.QuarterDD) {
        if (this.Search.QuarterHQ1 === item_QuarterDD.text) {
          this.tempQ1 = item_QuarterDD.text;
          break;
        }
      }
    } else {
      this.tempQ1 = ''
    }
  }

  func_GetQuarter2() {
    const _s = this;
    this.loading = true;
    _s.mserve.getQuarterDD({ Module: "QuarterDD", 'TextSearch1': this.Search.YearHQ2 }).then((response) => {
      if (response.data.status) {
        //console.log("QuarterDD2 : ", response.data.body)
        this.QuarterDD2 = response.data.body;
        let d = new Date();
        let currentMonth = d.getMonth() + 1;
        _s.func_calQuarterSecond(currentMonth);
      }
      else {
        //console.log('QuarterDD : false', response)
      }
    }).catch((e) => {
      if (e.response) {
        this.authserviceToken.CheckTokenExpire(e.response.status);
      } else {
        //console.log(e);
      }
    });
  }
  func_calQuarterSecond(month) {
    const _s = this;
    let temp = sessionStorage.getItem('PageBack')
    if (temp == '' || temp == undefined) {
      this.loading = false;
    }
    let tempQ_second = ''
    if (month >= 1 && month <= 3) {
      tempQ_second = this.QuarterCal[0].text;
    } else if (month >= 4 && month <= 6) {
      tempQ_second = this.QuarterCal[1].text;
    } else if (month >= 7 && month <= 9) {
      tempQ_second = this.QuarterCal[2].text;
    } else if (month >= 10 && month <= 12) {
      tempQ_second = this.QuarterCal[3].text;
    }
    this.tempCurrentQuarterID_second = tempQ_second;
    if (temp !== '' && temp !== undefined) {
      this.func_GETDATA_RiskRating('Search')
      sessionStorage.setItem('FilterRiskMapCompare', '');
      sessionStorage.setItem('PageBack', '');
    }
  }
  selQ2() {
    if (this.Search.QuarterHQ2 !== '') {
      for (let item_QuarterDD2 of this.QuarterDD2) {
        if (this.Search.QuarterHQ2 === item_QuarterDD2.text) {
          this.tempQ2 = item_QuarterDD2.text;
          break;
        }
      }
    } else {
      this.tempQ2 = ''
    }
  }

  func_selBU() {
    for (let item_BUDD of this.BUDD) {
      if (this.tempbu === item_BUDD.text) {
        this.Search.BU = item_BUDD.value
      }
    }
  }

  func_Clear() {
    const _s = this;
    this.Search.YearHQ1 = '';
    this.Search.QuarterHQ1 = '';
    this.tempQ1 = '';
    this.Search.YearHQ2 = '';
    this.Search.QuarterHQ2 = '';
    this.tempQ2 = '';
    this.tempbu = '';
    this.Search.RiskLevel = '';
    this.Search.Escalation = false;
    this.Search.BU = '';

    _s.list.risk.impact = []
    _s.list.risk.risktable.forEach(function (ItemVal, ItemIndex) {
      if (ItemIndex < 5) {
        _s.list.risk.risktable[ItemIndex].col[0].item[2].title = '-';
        _s.list.risk.risktable[ItemIndex].col[0].item[4].title = '-';

        _s.list.risk.risktable[ItemIndex].col[1].item = []
        _s.list.risk.risktable[ItemIndex].col[2].item = []
        _s.list.risk.risktable[ItemIndex].col[3].item = []
        _s.list.risk.risktable[ItemIndex].col[4].item = []
        _s.list.risk.risktable[ItemIndex].col[5].item = []
      }
    });

    _s.list.risk.riskitems = [];
    _s.list.risk.riskitems2 = [];
  }

  func_changeCompare(item, Risk_Id) {
    const _s = this;
    for (let item_risk_I1 of _s.list.risk.risktable) {
      item_risk_I1.col.forEach((colItem, ii) => {
        if (ii !== 0) {
          colItem.item.forEach(item_risk_I3 => {
            if (item.toString() === item_risk_I3.index && item_risk_I3.Risk_Id === Risk_Id) {
              item_risk_I3.show = !item_risk_I3.show;
            }
          });
        }
      });
    }
    //console.log("result : ", _s.list.risk.risktable)
  }

  numberWithCommas(x) {
    // const roundedNumber: number = Math.round(x);
    // const formattedNumber: string = roundedNumber.toLocaleString('en-US');
    // return formattedNumber;
    if (x >= 1000) {
      return x.toLocaleString('en-US');
    } else {
      return x;
    }
  }

  Reviewpage(item) {
    const _s = this;
    let tmpObjectFilter = [{ left: [], right: [] }];
    tmpObjectFilter[0].left.push(this.tempfilter[0].left);
    tmpObjectFilter[0].left[0].QuarterHQ1Name = _s.Search.QuarterHQ1;
    tmpObjectFilter[0].right.push(this.tempfilter[1].right);
    tmpObjectFilter[0].right[0].QuarterHQ2Name = _s.Search.QuarterHQ2;
    tmpObjectFilter[0].right[0].Buname = this.tempbu

    sessionStorage.setItem('FilterRiskMapCompare', JSON.stringify(tmpObjectFilter))
    sessionStorage.setItem("RiskID", item.Risk_Id);
    sessionStorage.setItem("RoleReview", 'Co');
    this.router.navigate(['/ReviewPage', 'ReviewfromComparedashboard']);
  }

  private _filter(value: string): string[] {
    const filterValue = value?.toLowerCase();
    if (filterValue !== '') {
      return this.BUDD.filter(option => option.text?.toLowerCase().includes(filterValue));
    } else {
      return this.BUDD
    }
  }

  ngAfterViewInit(): void {
    const _s = this
  }
}



