import { Injectable } from "@angular/core";
import { AppSettingService } from "src/app/app-setting-service";
import { Configmodel } from "src/app/model/configmodel";
import { AxiosInterceptorService } from "src/app/services/axios-interceptor.service";
import { MasterService } from "./master.service";
export const config = {
    headers: {
        'Content-Type': 'application/json;charset=utf-8;',
        'Accept': 'q=0.8;application/json;q=0.9',
        'Access-Control-Allow-Origin': 'https://err-dev.pttep.com/',
        'Access-Control-Allow-Methods': 'POST, GET',
        'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
        'X-Frame-Options': 'DENY',
        'Content-Security-Policy': 'frame-ancestors \'none\''
    },
    emulateJSON: true
}
export const configWithCsrf = {
    headers: {
        'Content-Type': 'application/json;charset=utf-8;',
        'Accept': 'q=0.8;application/json;q=0.9',
        'Access-Control-Allow-Origin': 'https://err-dev.pttep.com/',
        'Access-Control-Allow-Methods': 'POST, GET',
        'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
        'X-Frame-Options': 'DENY',
        'Content-Security-Policy': 'frame-ancestors \'none\''
    },
    emulateJSON: true,
    withCredentials: true
}

@Injectable({
    providedIn: 'root'
  })
  export class MonitorAndReviewService {
  
    constructor(public appSettingService: AppSettingService, public axiosInterceptorService: AxiosInterceptorService, public masterserv: MasterService) 
    { 

    }
    axiosInstance = this.axiosInterceptorService.getAxiosInstance();
    configData: Configmodel;

    getData() {
        this.configData = this.appSettingService.read();
        return this.configData;
    }

    BASE_URL = this.getData().api_strategicrisk_url;
    API_URL = {
        monitorReviewGetList: this.BASE_URL + '/api/MonitorReview/GetList',
        deleteStrategy: this.BASE_URL + '/api/MonitorReview/Delete/',
        updateAssumption: this.BASE_URL + '/api/MonitorReview/UpdateAssumtionMonitor/',
        ConfirmReview: this.BASE_URL + '/api/MonitorReview/ConfirmReview',
        GetReviewById: this.BASE_URL + '/api/MonitorReview/GetReviewById'
    }

    async func_getDataListMonitorReview(_data) {
        let data = JSON.stringify(_data)
        await this.masterserv.getCsrfToken();
        return this.axiosInstance.post(this.API_URL.monitorReviewGetList, data, configWithCsrf);
    }

    async func_delStrategy(_id, modifiedStrategy) {
        let data = JSON.stringify(modifiedStrategy)
        await this.masterserv.getCsrfToken();
        return this.axiosInstance.post(this.API_URL.deleteStrategy + _id, data, configWithCsrf);
    }

    async func_updateAssumption(_id, dataUpdateAssumption) {
        let data = JSON.stringify(dataUpdateAssumption)
        await this.masterserv.getCsrfToken();
        return this.axiosInstance.post(this.API_URL.updateAssumption + _id, data, configWithCsrf);
    }
    GetReviewById(paramData) {
        return this.axiosInstance.get(this.API_URL.GetReviewById + '/' + paramData, config);
    }
    async ConfirmReview(paramData) {
        let data = JSON.stringify(paramData);
        await this.masterserv.getCsrfToken();
        return this.axiosInstance.post(this.API_URL.ConfirmReview, data, configWithCsrf);
      }
    
  }
