<div class="container-fluid appform my-3">
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">

            <div class="col-12 col-md-12 col-lg-12 col-xl-12 pb-5 mt-5 mb-5">
                <div class="card mt-4 py-4">
                    <div class="col-12 mb-4">
                        <div class="col-8 col-xl-3 rounded searcrisk" style="text-align: center;">
                            <strong>Search Risk</strong>
                        </div>
                        <div class="row col-12 col-xl-12 mt-3 mb-3 mx-0 px-0 d-flex justify-content-around">
                            <div class="row col-12 col-xl-6">
                                <div class="col-12 mb-2 px-0">
                                    <strong class="formtext">Risks by Category</strong>
                                </div>
                                <div class="col-12 px-0">
                                    <select class="form-control" [(ngModel)]="DataSearch.RiskCate">
                                        <option value="">Please Select</option>
                                        <option *ngFor="let data of Risk_ByCategory" [value]="data.value"> {{ data.text }} </option>
                                    </select>
                                </div>
                            </div>
                            <div class="row col-12 col-xl-3">
                                <div class="col-12 mb-2 px-0">
                                    <strong class="formtext">Risks Status</strong>
                                </div>
                                <div class="col-12 px-0">
                                    <select class="form-control" [(ngModel)]="DataSearch.RiskStatus">
                                        <option value="">Please Select</option>
                                        <option value="1">Active</option>
                                        <option value="2">Close (Turn to problem)</option>
                                        <option value="3">Close (Mitigation Completed)</option>
                                        <option value="4">Close (Other)</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row col-12 col-xl-3">
                                <div class="col-12 mb-2 px-0">
                                    <strong class="formtext">Business Unit</strong>
                                </div>
                                <div class="col-12 px-0">
                                    <input type="text" class="form-control m-0" aria-label="Number" matInput [formControl]="myControl" [matAutocomplete]="auto" [(ngModel)]="tempBU" placeholder="Please Select" (keyup.enter)="func_selectBU()" (blur)="chkBU()">
                                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option.text" (click)="func_selectBU()">
                                        {{option.text}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mb-4">
                        <div class="row col-12 col-xl-12 mt-3 mb-3 mx-0 px-0 d-flex justify-content-around">
                            <div class="row col-12 col-xl-6">
                                <div class="col-12 mb-2 px-0">
                                    <strong class="formtext">Risk Name</strong>
                                </div>
                                <div class="col-12 px-0">
                                    <input type="text" class="form-control" name="valueRisk_Name" autocomplete="off" [(ngModel)]="DataSearch.RiskName">
                                </div>
                            </div>
                            <div class="row col-12 col-xl-3">
                                <div class="col-12 mb-2 px-0">
                                    <strong class="formtext">Risk Level</strong>
                                </div>
                                <div class="col-12 px-0">
                                    <select class="form-control" [(ngModel)]="DataSearch.LevelRisk">
                                        <option value="">Please Select</option>
                                        <option *ngFor="let item of Risk_Level" value="{{ item.value }}">{{ item.text }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row col-12 col-xl-3">
                                <div class="col-12 mb-3 px-0"></div>
                                <div class="col-12 col-xl-6 px-0">
                                    <input type="checkbox" id="chk1" [(ngModel)]="DataSearch.Childnode" [disabled]='DataSearch.RiskBU == "" || tempBU == "" '>&nbsp;&nbsp;
                                    <label for="chk1" class="formtext">Child Node</label>
                                </div>
                                <div class="col-12 col-xl-6 px-0">
                                    <input type="checkbox" id="chk2" [(ngModel)]="DataSearch.Consolidation" [disabled]='DataSearch.RiskBU == "" || tempBU == "" '>&nbsp;&nbsp;
                                    <label for="chk2" class="formtext">Consolidation</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mb-4">
                        <div class="row col-12 col-xl-12 mt-3 mb-3 mx-0 px-0 d-flex justify-content-around">
                            <div class="row col-12 col-xl-6">                                
                                <div class="col-6 row mx-0 px-0">
                                    <div class="col-12 mb-2">
                                        <strong class="formtext">Version Year</strong>
                                    </div>
                                    <div class="col-12 pl-0">
                                        <select class="form-control" [(ngModel)]="DataSearch.Yearfrom" (change)="func_selFromyear()">
                                            <option value="">Please Select</option>
                                            <option value="0">All</option>
                                            <option *ngFor="let item of DDLYear" value="{{ item.value }}">{{ item.text }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-6 row mx-0 px-0">
                                    <div class="col-12 mb-2">
                                        <strong class="formtext">Version Quarter</strong>
                                    </div>
                                    <div class="col-12 pr-0">
                                        <select class="form-control" [(ngModel)]="DataSearch.Quarterfrom" [disabled]="DataSearch.Yearfrom === '' " (change)="changeQuarter()">
                                            <option value="">Please Select</option>
                                            <option value="0">All</option>
                                            <option *ngFor="let item of DDLQuarter" value="{{ item.text }}">{{ item.text }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row col-12 col-xl-3">
                                <div class="col-12 mb-2 px-0">
                                    <strong class="formtext">Risks ID</strong>
                                </div>
                                <div class="col-12 px-0">
                                    <input type="text" class="form-control" [(ngModel)]="DataSearch.RiskID">
                                </div>
                            </div>
                            <div class="row col-12 col-xl-3">
                                <div class="col-12 mb-3 px-0"></div>
                                <div class="col-12 px-0">
                                    <input type="checkbox" name="level" id="chk_Escalationlevel" [(ngModel)]="DataSearch.Escalation">&nbsp;
                                    <label class="formtext" for="chk_Escalationlevel">Escalation level</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" style="text-align: center;">
                        <button type="button" class="btn mr-2" (click)="func_clear()" style="background:#9eb0ba;color: white;font-size: 20px;width: 100px;">Clear</button>
                        <button type="button" class="btn" (click)="func_search()" style="background:#023154;color: white;font-size: 20px;width: 100px;">Search</button>
                    </div>
                </div>
                <div class="card col-12 mt-4 px-3">
                    <div class="table-responsive">
                        <table class="table-bordered" style="width:100%;">
                            <caption style="display: none;" >This is a custom table</caption>
                            <thead style="text-align: center;background-color: #023154;color:white;font-size:22px;">
                                <tr>
                                    <th style="width: 10px;  vertical-align: middle;">View</th>
                                    <th style="width: 120px; vertical-align: middle;">Risk ID</th>
                                    <th style="width: 50px; vertical-align: middle;">Status as of</th>
                                    <th style="width: 250px; vertical-align: middle;">Risk Name</th>
                                    <th style="width: 50px;  vertical-align: middle;">Risk Level</th>
                                    <th style="width: 80px;  vertical-align: middle;">Business Unit</th>
                                    <th style="width: 50px; vertical-align: middle;">Risk Type</th>
                                    <th style="width: 80px;  vertical-align: middle;">Risk Status</th>
                                    <th style="width: 20px;vertical-align: middle;">Workflow Status</th>
                                </tr>
                            </thead>
                            <tbody style="background-color: white;font-size:20px;vertical-align: top;">
                                <tr *ngFor="let data of table_searchrisk | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                                    <td style="text-align: center;">
                                        <button type="button" class="btn btn-sm" (click)="func_ReveiewPage(data.risk_Id , data.table_Type)">
                                            <em class="fas fa-search" style="color:#6c757d"></em>
                                        </button>
                                    </td>
                                    <td style="width: 120px;">
                                        <span *ngIf="data.risk_Co_Id !== null; else elseBlock">{{ data.risk_Co_Id }}</span>
                                        <ng-template #elseBlock>{{ data.risk_Staff_Id }}</ng-template>
                                    </td>
                                    <td style="width: 50px;">{{ data.year }}-{{ data.quarterName }}</td>
                                    <td style="width: 250px;">{{ data.risk_Name }}</td>
                                    <td style="width: 50px;">{{ data.risk_Rating }}</td>
                                    <td style="width: 80px;">{{ data.businessUnit }}</td>
                                    <td style="width: 50px;">
                                        <span *ngIf="data.wpbid !== null; else elseBlock3">WPB</span>
                                        <ng-template #elseBlock3>Normal</ng-template>
                                    </td>
                                    <td style="width: 80px;">
                                        <span *ngIf="data.risk_Status === '1'">Active</span>
                                        <span *ngIf="data.risk_Status === '2'">Close (Turn to problem)</span>
                                        <span *ngIf="data.risk_Status === '3'">Close (Mitigation Completed)</span>
                                        <span *ngIf="data.risk_Status === '4'">Close (Other)</span>
                                    </td>
                                    <td style="width: 20px;">{{ data.risk_Status_Workflow }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="pagination mt-4">
                        <ngb-pagination 
                        [collectionSize]="table_searchrisk.length" 
                        [(page)]="page"
                        [pageSize]="pageSize"
                        [maxSize]="5" 
                        [rotate]="true"
                        [ellipses]="false" 
                        [boundaryLinks]="true">
                        <ng-template ngbPaginationPrevious>Prev</ng-template>
                        <ng-template ngbPaginationNext>Next</ng-template>
                        </ngb-pagination>
                    </div>
                </div>
            </div>

            <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalAlertdanger5se">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <span class="bg-danger">&nbsp;</span>
                        <div class="modal-header">
                            <div class="row col-12 text-danger">
                                <div class="col-2">
                                    <em class="far fa-times-circle" style="font-size:50px;"></em>
                                </div>
                                <div class="col-10 pt-2">
                                    <h4 class="modal-title">Alert !</h4>
                                </div>
                            </div>
                        </div>
                        <div class="modal-body text-body border-0">
                            <div class="col-12" style="text-align: left;">
                                <span style="font-size:18px;">{{ alertflow }}</span>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger w-25" (click)="closealertdanger()">OK</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalVersioning">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <span class="bg-info">&nbsp;</span>
                        <div class="modal-header">
                            <div class="row col-12 text-info">
                                <div class="col-10 pt-2">
                                    <h4 class="modal-title">Version</h4>
                                </div>
                            </div>
                        </div>
                        <div class="modal-body text-body border-0">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-4 pt-2">
                                    <strong style="font-size:18px;">Year</strong>
                                    </div>
                                    <div class="col-8 px-0">
                                    <select class="form-control" [(ngModel)]="VersionYear" (change)="func_getQuarterVersion()">
                                        <option value="">Please Select</option>
                                        <option *ngFor="let item of DDLYear" value="{{ item.value }}">{{ item.text }}</option>
                                    </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mt-2">
                            <div class="row">
                                <div class="col-4 pt-2">
                                    <strong style="font-size:18px;">Quarter</strong>
                                </div>
                                <div class="col-8 px-0">
                                    <select class="form-control" [(ngModel)]="VersionQuarter" [disabled]="VersionYear == ''">
                                    <option value="">Please Select</option>
                                    <option *ngFor="let item of DDLQuarter" value="{{ item.value }}">{{ item.text }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info w-25 text-white" (click)="func_okversion()">Ok</button>
                            <button type="button" class="btn btn-secondary w-25" data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading [loading]='loading'></app-loading>