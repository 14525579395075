import {  Directive, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
@Directive({
  selector: 'StrategyDir',
})
export class StrategyDirective {
    riskLevelTable = [
        [1,1,1,0.5,0.5],
        [1,1,0.5,0.5,0.5],
        [1,0.5,0.5,0.5,0],
        [0.5,0.5,0.5,0,0],
        [0.5,0.5,0,0,0],
    ]
    
  calRiskLevel(likelihood, impact) {
    let riskLevel = this.riskLevelTable[Number(likelihood)-1][Number(impact)-1];
    return riskLevel
  }

}
