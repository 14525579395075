<div class="container-fluid appform my-3">
  <div class="row">
    <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">
      <div class="col-12 col-md-12 col-lg-12 col-xl-12 pb-5 mt-5 mb-5">
        <div class="px-0 pb-2">
          <div class="card col-12 mt-4 mx-0 p-3">
            <div class="row">
              <div class="coordinator col-3 ml-3 mb-3 rounded" style="height: 50px;">
                <div class="col-0 pt-2 signaturetext">
                  <em class="fas fa-file-signature"></em>&nbsp;&nbsp;Monitor & Review
                </div>
              </div>
            </div>
            <div class="row ">
              <div class=" col-12 ml-1 mb-1 rounded">
                <div class="col-12 pt-2 headertext">
                  <em class=""></em>&nbsp;&nbsp;Strategic Assumption Monitoring
                </div>
              </div>
            </div> 
            <div class="row ">
              <div class=" col-12 ml-1 mb-1 rounded">
                <div class="col-12 pt-2 headertext">
                  &nbsp;&nbsp;<em class="fa fa-play iconplay"></em>&nbsp;&nbsp;{{strategyNumber}}
                </div>
              </div>
            </div>
            <div class="col-12 mt-5 px-0 table-responsive">
              <table class="table-bordered" style="width:100%;">
                <caption style="display: none;">This is a custom table</caption>
                <thead style="text-align:center; background-color:#033153; color:white;">
                  <tr>
                    <th>Assumption No.</th>
                    <th>Category</th>
                    <th>Name/Description</th>
                    <th>Favorable Status (GREEN)</th>
                    <th>Unfavorable Status (RED)</th>
                    <th>Action If RED</th>
                    <th>Current Status</th>
                    <th>Rationale</th>
                    <th>% Progress</th>
                    <th>Edit</th>
                  </tr>
                </thead>
                <tbody style="background-color:white;">
                  <tr
                    *ngFor="let item of DataViewsMonitorReview | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize index as index1">
                    <td>Assumption {{ (page - 1) * pageSize + index1 + 1 }}</td>
                    <td>{{ item.riskCategoryName }}</td>
                    <td>{{ item.assumptionDescription }}</td>
                    <td>{{ item.favorableStatus }}</td>
                    <td>{{ item.unfavorableStatus }}</td>
                    <td>{{ item.actionRed }}</td>
                    <td style="text-align: center; width: 9%;">
                      <select [(ngModel)]="item.currentStatus" class="form-control"
                        [ngClass]="{'input-error': (item.currentStatusInvalid)}" [disabled]="!item.EditStatus">
                        <option value="Green">🟢 Green</option>
                        <option value="Red">🔴 Red</option>
                      </select>
                    </td>
                    <td style="text-align: center; width: 18%;">
                      <textarea class="form-control" [ngClass]="{'input-error': (item.rationaleInvalid)}"
                        [(ngModel)]="item.rationale" [disabled]="!item.EditStatus" autosize rows="1"></textarea>
                    </td>
                    <td style="text-align: center; width: 10%;">
                      <div *ngIf="item.EditStatus == false" style="display: flex; align-items: center;">
                        <input type="text" style="width: 90%;" class="form-control"
                          [ngClass]="{'input-error': (item.percentProgressInvalid)}" [(ngModel)]="item.percentProgress"
                          [disabled]="!item.EditStatus" (ngModelChange)="modelChanged($event)">&nbsp;%
                      </div>
                      <div *ngIf="item.EditStatus == true" style="display: flex; align-items: center;">
                        <input type="number" style="width: 90%;" class="form-control" min="0" max="100"
                          [ngClass]="{'input-error': (item.percentProgressInvalid)}" [(ngModel)]="percentProgress"
                          [disabled]="!item.EditStatus" (ngModelChange)="modelChanged($event)">&nbsp;%
                      </div>
                    </td>
                    <td style="text-align: center; width: 3%;">
                      <div *ngIf="empid == createBy || !empRole.includes('Staff')">
                        <button *ngIf="item.EditStatus == false" type="button" class="btn"
                          (click)="func_editstatus(item)">
                          <em class="far fa-edit" style="color: orange;font-size:18px;"></em>
                        </button>
                        <button *ngIf="item.EditStatus == true" type="button" class="btn"
                          (click)="func_saveUpdateAssumption(item)">
                          <em class="far fa-save" style="color: green;font-size:18px;"></em>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col-12 mt-4" style="display: flex; justify-content: flex-end;">
                <ngb-pagination *ngIf="DataViewsMonitorReview.length > 5"
                  [collectionSize]="DataViewsMonitorReview.length" [(page)]="page" [pageSize]="pageSize" [maxSize]="5"
                  [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
                  <ng-template ngbPaginationPrevious>Prev</ng-template>
                  <ng-template ngbPaginationNext>Next</ng-template>
                </ngb-pagination>
              </div>
            </div>
            <div class="row">
              <div class="col-12 mb-3" style="text-align: end;">
                <button *ngIf="strategyPage != 0" type="button" class="btn mr-3"
                  style="width:250px;background-color: darkblue;color:white;" (click)="func_PreviousAssumption()">
                  Go to Previous Assumption Page
                </button>
                <button *ngIf="strategyPage != strategyLength && DataStrategy.length > 1" type="button" class="btn mr-3"
                  style="width:250px;background-color: green;color:white;" (click)="func_nextAssumption()">
                  Go to Next Assumption Page
                </button>
                <button type="button" class="btn mr-3" style="width:250px;background-color: blue;color:white;"
                  (click)="func_review()">
                  Go to Review Page
                </button>
                <button type="button" class="btn" style="width:250px;background-color: black;color:white;"
                  (click)="Close_Modal()">Go back to Monitor & Review Page
                </button>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-12 col-lg-12 col-xl-12 ">
                <app-action-log [riskId]="[this.DataSelectStrategy[0].riskId]" [isShowStrategyName] = "false"></app-action-log>
            </div>
         </div>

        </div>
      </div>
    </div>
  </div>
</div>

<app-modal-alert [titleSuccess]="popupTitleAlert" [message]="popupMessageAlert"
  [isRefresh]="isRefresh"></app-modal-alert>
<app-loading [loading]='loading'></app-loading>