<div class="container-fluid appform my-3">
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">
            <div class="col-12 col-md-12 col-lg-12 col-xl-12 pb-5 mt-5 mb-5">
                <div class="px-0 pb-2">
                    <div class="card col-12 mt-4 mx-0 px-3">
                        <div class="mianmenu col-12 rounded">
                            <div class="col-12 pt-2">
                                <em class="fas fa-cog icon"></em>&nbsp;&nbsp;
                                Mian Menu
                            </div>
                        </div>
                        <button type="button" class="btn btn-mianmenu-primary mb-3" style="color:white;background:orange;" (click)="func_addmenu({},'Add')">
                            <em class="fas fa-plus icon"></em>&nbsp;&nbsp;&nbsp;Add Menu
                        </button>

                        <div class="table-mianmenu">
                            <table class="table table-bordered" style="width:100%;">
                                <caption style="display: none;" >This is a custom table</caption>
                                <thead style="text-align: center;background-color:#033153;color:white;font-size:18px;">
                                    <tr>
                                        <th style="width: 50px;vertical-align: middle;">ID</th>
                                        <th style="width: 100px;vertical-align: middle;">Main Menu</th>
                                        <th style="width: 250px;vertical-align: middle;">Parents</th>
                                        <th style="width: 120px;vertical-align: middle;">Link</th>
                                        <th style="width: 100px;vertical-align: middle;">Order</th>
                                        <th style="width: 100px;vertical-align: middle;">Status</th>
                                        <th style="width: 250px;vertical-align: middle;">Permission Group</th>
                                        <th style="width: 120px;vertical-align: middle;">Edit</th>
                                        <th style="width: 120px;vertical-align: middle;">Delete</th>
                                    </tr>
                                </thead>
                                <tbody style="background-color: white;font-size:16px;">
                                    <tr *ngFor='let data of tablemianmanu index as index'>
                                        <td style="text-align: center;">{{ data.menuID }}</td>
                                        <td>{{ data.menuName }}</td>
                                        <td>{{ data.parentsName }}</td>
                                        <td>{{ data.link }}</td>
                                        <td style="text-align: center;">{{ data.orderBy }}</td>
                                        <td style="text-align: center;" *ngIf="data.delFlag == '0'">
                                            <em class="fas fa-check-square" style="color: rgb(85, 209, 85);zoom: 1.5;"></em>
                                        </td>
                                        <td style="text-align: center;" *ngIf="data.delFlag != '0'">
                                        </td>
                                        <td>{{ data.permissionGroup }}</td>
                                        <td style="text-align: center;">
                                            <button type="button" class="btn" (click)="func_addmenu(data,'Edit')">
                                                <em class="fas fa-edit" style="color:orange;font-size:16px;"></em>
                                            </button>
                                        </td>
                                        <td style="text-align: center;">
                                            <button type="button" class="btn" (click)="func_addmenu(data,'Delete')">
                                                <em class="fas fa-trash-alt" style="color:red;font-size:16px;"></em>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="modal fade bd-Mianmenu" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true" id="modaladdmenu">
                            <div class="modal-dialog modal-xl">
                                <div class="modal-content">
                                    <div class="container-fluid appform my-3">
                                        <div class="row">
                                            <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1"
                                                style="font-size: 14px;">
                                                <div
                                                    class="container col-12 col-md-12 col-lg-12 col-xl-12 pb-0 mt-0 mb-0 ">
                                                    <div class="container">
                                                        <div class="col-12 rounded addmenu">
                                                            <div class="col-12 pt-2">
                                                                Mianmenu Management
                                                            </div>
                                                        </div>

                                                        <div class="card mt-4">
                                                            <div
                                                                class="row col-12 mx-0 px-0 d-flex justify-content-around">
                                                                <div
                                                                    class="row col-12 mx-0 px-0 d-flex justify-content-around">
                                                                    <div
                                                                        class="row col-12 col-md-12 col-xl-6 form-group px-0">
                                                                        <div class="formtext col-12">
                                                                            <strong class="formtext">Name</strong>
                                                                        </div>
                                                                        <div class="col-12">
                                                                            <input 
                                                                            type="text"
                                                                                class="form-control"
                                                                                id=""
                                                                                placeholder="Name"
                                                                                [(ngModel)]="MenuName">
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="row col-12 col-md-12 col-xl-6 form-group px-0">
                                                                        <div class="formtext col-12">
                                                                            <strong class="formtext">Parents</strong>
                                                                        </div>
                                                                        <div class="col-12">
                                                                            <select class="form-control" [(ngModel)]="Parents">
                                                                                <option value="">Please Select</option>
                                                                                <option *ngFor="let data of ParentsDD" [value]="data.value">{{data.text}}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row col-12 col-md-12 col-xl-6 form-group px-0">
                                                                        <div class="formtext col-12">
                                                                            <strong class="formtext">Link</strong>
                                                                        </div>
                                                                        <div class="col-12">
                                                                            <input 
                                                                            type="text"
                                                                                class="form-control"
                                                                                id=""
                                                                                placeholder="Link"
                                                                                [(ngModel)]="Link">
                                                                        </div>
                                                                    </div>
                                                                    <div class="row col-12 col-md-12 col-xl-2 form-group px-0">
                                                                        <div class="formtext col-12">
                                                                            <strong class="formtext">Order</strong>
                                                                        </div>
                                                                        <div class="col-12">
                                                                            <input 
                                                                            type="text"
                                                                            class="form-control"
                                                                            id=""
                                                                            placeholder="Order"
                                                                            [(ngModel)]="OrderBy">
                                                                        </div>
                                                                    </div>
                                                                    <div class="row col-12 col-md-12 col-xl-4 form-group px-0">
                                                                        <div class="formtext col-12">
                                                                            <strong class="formtext">Status</strong>
                                                                        </div>
                                                                        <div class="col-12">
                                                                            <select class="form-control" [(ngModel)]="Status">
                                                                                <option value="">Please Select</option>
                                                                                <option *ngFor="let data of statustopmenu" value="{{data.value}}"> {{data.title}}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row col-12 col-md-12 col-xl-4 form-group px-0">
                                                                        <div class="formtext col-12">
                                                                            <strong class="formtext">Icon</strong>
                                                                        </div>
                                                                        <div class="col-12">
                                                                            <input type="text" class="form-control" placeholder="Icon" [(ngModel)]="Icon">
                                                                        </div>
                                                                    </div>
                                                                    <div class="row col-12 col-md-12 col-xl-8 form-group px-0">
                                                                        <div class="formtext col-12">
                                                                            <strong class="formtext">NewTab</strong>
                                                                        </div>
                                                                        <div class="col-12">
                                                                            <input type="checkbox" [(ngModel)]="FlagTag" id="chkbox">
                                                                        </div>
                                                                    </div>
                                                                    <div class="row col-12 mx-0 mb-4 px-0 d-flex justify-content-around">
                                                                        <div class="row col-12 col-xl-3 px-0">
                                                                            <div class="col-12 mb-2 px-3">
                                                                                <strong class="formtext">Permission Group</strong>
                                                                            </div>
                                                                            <div class="col-12 input-group mb-3">
                                                                                <input type="text" class="form-control m-0" aria-label="Number" matInput [formControl]="PerGroupmyControl" [matAutocomplete]="auto" [(ngModel)]="Permission" (keyup.enter)="AddFocal()">
                                                                                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                                                                    <mat-option *ngFor="let option of PerGroupfilteredOptions | async" [value]="option.text" (click)="AddFocal()">
                                                                                    {{ option.text }}
                                                                                    </mat-option>
                                                                                </mat-autocomplete>
                                                                                <div class="input-group-append">
                                                                                  <button class="btn" type="button" (click)="AddFocal()" style="background-color:#033153;color:white;">Add</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row col-12 col-xl-9 pl-4">
                                                                            <div class="col-12">&nbsp;</div>
                                                                            <div *ngFor="let item of tempBU">
                                                                                <span class="badge badge-custom mr-0"><strong>{{ item }}</strong></span>
                                                                                <button type="button" class="btn btn-sm btn-danger btn-custom" (click)="RemoveFocal(item)">
                                                                                    <em class="fas fa-times"></em>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="row col-12 col-md-12 col-xl-6 form-group px-0">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 my-3 mx-0 text-center">
                                                            <button type="button" class="btn mr-3"
                                                                style="width: 100px;background-color:#9eb0ba;color:white;"
                                                                (click)="func_closebtn()">Close</button>
                                                            <button type="button" class="btn"
                                                                style="width: 100px;background-color:#033153;color:white;"
                                                                (click)="func_savebtn()">Save</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>