import { Directive, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
@Directive({
  selector: 'MasterDir',
})
export class MasterDirective {
  StrategicValues = [
    { value: '1', name: 'Low' },
    { value: '2', name: 'Medium' },
    { value: '3', name: 'High' },
  ];

  acceptableValues = [
    { value: 0, name: 'NO' },
    { value: 1, name: 'YES' },
  ];

  likelihoodValues = [
    { value: '1', name: 'Rare' },
    { value: '2', name: 'Unlikely' },
    { value: '3', name: 'Possible' },
    { value: '4', name: 'Likely' },
    { value: '5', name: 'Almost Certain' },
  ];

  impactValues = [
    { value: '1', name: 'Minor' },
    { value: '2', name: 'Moderate' },
    { value: '3', name: 'Significant' },
    { value: '4', name: 'Serious' },
    { value: '5', name: 'Critical' },
  ];

  riskLevelValues = [
    { value: '0', name: 'RED=0' },
    { value: '0.5', name: 'YELLOW=0.5' },
    { value: '1', name: 'GREEN=1' }
  ];

  getStrategicValues() {
    return this.StrategicValues;
  }

  getAcceptableValues() {
    return this.acceptableValues;
  }

  getLikelihoodValues() {
    return this.likelihoodValues;
  }

  getImpactValues() {
    return this.impactValues;
  }

  getRiskLevelValues() {
    return this.riskLevelValues;
  }
  
  isValidUrl(string) {
    try {
      const url = new URL(string);
      return url.protocol === "http:" || url.protocol === "https:";
    } catch (_) {
      return false;
    }
  }

}
