import { Component, EventEmitter, Injector, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { RiskMap } from '../../services/riskmap.service';

declare let $;
import { Masterserve } from '../../services/master.service';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AuthenService } from 'src/app/services/authenservice.service';
import { RefactorApproval } from 'src/app/model/approval';
import { MainProcessComponent } from '../main-process/main-process.component';
import { error } from 'console';
import { ShareService } from 'src/app/services/share.service';
import DOMPurify from 'dompurify';

@Component({
  selector: 'app-Approval',
  templateUrl: './page.Approval.html',
  styleUrls: ['./page.Approval.css'],
  providers: [RiskMap, NgbModal]
})

export class Approval implements OnInit {
  @Output() newItemEvent = new EventEmitter<string>();
  Data_SessionStorage;

  displayRemove = true;
  disableRemove = false;

  disableSelectAll = false;

  tempchkbox = true;
  MYRISK1 = [];
  MYRISK2 = [];
  MYRISK3 = [];

  MYRISK1_staff = [];
  MYRISK2_staff = [];
  MYRISK3_staff = [];

  Consolecomment: string = null;
  ConsolecommentLst: string = null;
  isEmptyComment: boolean = false;
  //flag for check dissmiss ModalCommentCRList from click blackdrop and esc key;
  isSubmittedModalCommentCRList: boolean = false;
  tempItemConsole: any = null;
  tempItemConsoleLst: any = null;
  tempLevel: any = null;
  chkrework: any = null;
  BUWork = '';

  link = '';
  Tempdata;
  chkroleuser;
  Register_Data;
  loading = false;
  chkbackmenu = 'backmenu';
  list = {
    risk: {
      impactcolor: [],
      impact: [],
      risktable: [
        {
          row: 1,
          col: [
            {
              col: 0, class: 'impact-x', item: [
                { title: 'Critical', bgcolor: '#1b190f', color: '#edf0f7', index: '', Risk_Id: null, IsStripes: false, table_Type: null, risk_Status_Workflow_ReConsole: '', hover_Text: '', risk_Status: '' },
                { title: '&nbsp;', color: '#edf0f7' },
                { title: '-', bgcolor: '#0e223c', color: '#edf0f7' },
                { title: '&nbsp;', color: '#edf0f7' },
                { title: '-', bgcolor: '#5c2322', color: '#edf0f7' }
              ]
            },
            { col: 1, class: 'medium', item: [] },
            { col: 2, class: 'medium', item: [] },
            { col: 3, class: 'high', item: [] },
            { col: 4, class: 'high', item: [] },
            { col: 5, class: 'high', item: [] },
          ]
        },
        {
          row: 2,
          col: [
            {
              col: 0, class: 'impact-x', item: [
                { title: 'Serious', bgcolor: '#444128', color: '#edf0f7' },
                { title: '&nbsp;', color: '#edf0f7' },
                { title: '-', bgcolor: '#32598d', color: '#edf0f7' },
                { title: '&nbsp;', color: '#edf0f7' },
                { title: '-', bgcolor: '#aa504e', color: '#edf0f7' }
              ]
            },
            { col: 1, class: 'medium', item: [] },
            { col: 2, class: 'medium', item: [] },
            { col: 3, class: 'medium', item: [] },
            { col: 4, class: 'high', item: [] },
            { col: 5, class: 'high', item: [] },
          ]
        },
        {
          row: 3,
          col: [
            {
              col: 0, class: 'impact-x', item: [
                { title: 'Significant', bgcolor: '#8b8352', color: 'black' },
                { title: 'NPV/EMV (MMUSD)', color: '#4649f7' },
                { title: '-', bgcolor: '#4d85ce', color: 'black' },
                { title: 'NI (MMUSD)', color: '#ba2527' },
                { title: '-', bgcolor: '#cb8d8f', color: 'black' }
              ]
            },
            { col: 1, class: 'low', item: [] },
            { col: 2, class: 'medium', item: [] },
            { col: 3, class: 'medium', item: [] },
            { col: 4, class: 'medium', item: [] },
            { col: 5, class: 'high', item: [] },
          ]
        },
        {
          row: 4,
          col: [
            {
              col: 0, class: 'impact-x', item: [
                { title: 'Moderate', bgcolor: '#b9b696', color: 'black' },
                { title: '&nbsp;', color: '#edf0f7' },
                { title: '-', bgcolor: '#83a9db', color: 'black' },
                { title: '&nbsp;', color: '#edf0f7' },
                { title: '-', bgcolor: '#d6adb1', color: 'black' }
              ]
            },
            { col: 1, class: 'low', item: [] },
            { col: 2, class: 'low', item: [] },
            { col: 3, class: 'medium', item: [] },
            { col: 4, class: 'medium', item: [] },
            { col: 5, class: 'medium', item: [] },
          ]
        },
        {
          row: 5,
          col: [
            {
              col: 0, class: 'impact-x', item: [
                { title: 'Minor', bgcolor: '#cdccbe', color: 'black' },
                { title: '&nbsp;', color: '#edf0f7' },
                { title: '-', bgcolor: '#aad1e1', color: 'black' },
                { title: '&nbsp;', color: '#edf0f7' },
                { title: '-', bgcolor: '#e1cfd4', color: 'black' }]
            },
            { col: 1, class: 'low', item: [] },
            { col: 2, class: 'low', item: [] },
            { col: 3, class: 'low', item: [] },
            { col: 4, class: 'medium', item: [] },
            { col: 5, class: 'medium', item: [] },
          ]
        },
        {
          row: 6,
          col: [
            { col: 0, class: 'impact-y', item: [] },
            { col: 1, class: 'impact-y', item: [{ title: 'Rare', bgcolor: '#abd1e1', color: 'black' }] },
            { col: 2, class: 'impact-y', item: [{ title: 'Unlikely', bgcolor: '#83a9db', color: 'black' }] },
            { col: 3, class: 'impact-y', item: [{ title: 'Possible', bgcolor: '#85aadb', color: 'black' }] },
            { col: 4, class: 'impact-y', item: [{ title: 'Likely', bgcolor: '#325a8d', color: 'white' }] },
            { col: 5, class: 'impact-y', item: [{ title: 'Almost Certain', bgcolor: '#0d213b', color: 'white' }] },
          ]
        }
      ],
      riskitems: []
    },
    riskprofile: {
      year: [],
      quarter: []
    },
    businessUnit: {
      items: []
    },
    field: {
      Mitigate: 1,
      riskprofileYear: '',
      riskprofileQuarter: '',
      businessUnit: '',
    }
  }

  Pageloading = true;
  alertflow = '';
  tempselectbu = '';

  data_department;
  tempModule = '';
  filter;
  treeData;
  pageroute;
  page;
  table_todolist;
  table_riskmap;
  valuecheckApproveAll = false;
  valuecheckRejectAll = false;
  valuecheckSelectAll = false;
  valuecheckRemoveAll = false;
  checkedApprove = false;
  checkedReject = false;
  tempSelectedApproved: any = [];
  tempSelectedApproved2: any = [];
  tempSelectedRejected: any = [];
  tempSelectedRejected2: any = [];

  tempSeletedRemove: any = [];

  tempSeleted: any = [];
  tempSeleted2: any = [];
  tempSeleted3: any = [];
  tempSeleted_staff: any = [];
  tempSeleted2_staff: any = [];
  tempSeleted3_staff: any = [];

  tempRiskID = [];
  tempRiskID2 = [];
  tempRiskID3 = [];
  tempRiskID_staff = [];
  tempRiskID2_staff = [];
  tempRiskID3_staff = [];

  empid;
  listRiskTableItem: any = [];
  Quarter = [];
  Quarter_Getlist;

  tempHeadActing = [];
  tempAssetBU = [];
  tempChildOrg = [];
  tempOwnerInfo = [];
  tempOrg = [];
  tempERM = [];
  tempChkBUDD = [];
  tableriskrating;
  tempDelItem;

  temp = [];
  showResult;
  SearchMyrisk = '';

  myControlBUDD = new UntypedFormControl();
  BusinessNameDD = [];
  filteredOptionBUDD: Observable<string[]>;

  Version = null;
  VersionYear = '';
  DDLYear = [];
  LatestQuarterDDLYear = [];

  tempUpdateId = null;
  tempUpdateFlow;
  TempUpdateData;
  mservedata: Masterserve
  dataService: ShareService

  RiskFullName = '';
  RiskName = '';
  RiskId = '';

  listSelect = [];
  mode = 'single'
  tempSelectedRemoveAll = [];
  constructor(
    public authserviceToken: AuthenService,
    private _Activatedroute: ActivatedRoute,
    public mserve: RiskMap,
    private appServicecomp: MainProcessComponent,
    private injector: Injector,
    private router: Router,
  ) {

    this.mservedata = injector.get<Masterserve>(Masterserve)
    this.dataService = injector.get<ShareService>(ShareService)
  }

  public collapseAll: boolean;
  public selectAll: boolean;
  appService = this.appServicecomp;
  ngOnInit(): void {

    const _s = this;

    this.appService.showBUName = ''
    this.SetSesstion();

    const pathnameURL = window.location.pathname;
    this.setVersion(pathnameURL);

    if (sessionStorage.link) {
      this.link = JSON.parse(sessionStorage.link);
    }

    this.SetTempUpdateFlow();

    this.SetRegister_Data();

    this.pageroute = [
      { title: "TO DO LIST" },
      { title: "MY RISK" }
    ]

    this.Data_SessionStorage = JSON.parse(sessionStorage.datainfo);
    this.chkroleuser = sessionStorage.Role

    this.checkRoleFromStoreg();

    this.tempHeadActing = this.Data_SessionStorage[0].headActing;
    this.getTempToBusinessNameDD();

    this.setUniqValues();

    this.BusinessNameDD = [];
    Array.prototype.push.apply(this.BusinessNameDD, this.tempChkBUDD);

    this.loadDDL();
    this.loadLatestQuarterDDL();

    if (this.Version === '/q') {
      $('#modalVersioning').modal('show');
    }

    this.func_GetDataUser();
    this.page = this._Activatedroute.snapshot.paramMap.get("status");
    this.checktab(this.page)

    this.filteredOptionBUDD = this.myControlBUDD.valueChanges.pipe(
      startWith(''),
      map(value => this._filterBUDD(value))
    );

    if (!sessionStorage.TempSelctBUMyrisk || !sessionStorage.ChkBTNNextFromReview) {
      if (this.BusinessNameDD.length !== 1 && this.Version === null) {

        setTimeout(function () { $('#modalBUapp').modal('show') }, 800)

      }
    }

    $('#modalBUapp').on('hidden.bs.modal', function (e) {
      if (_s.chkbackmenu === 'backmenu') {
        _s.appService.status = 1;
        _s.appService.checkrole();
        _s.router.navigate(['/']);
      }
    });

    $('#modalAlertdanger5').on('hidden.bs.modal', function (e) {
      _s.chkbackmenu = 'backmenu'
      $('#modalBUapp').modal('show');
    });

    $('#modalCommentCRList').on('hidden.bs.modal', () => {
      if(this.isSubmittedModalCommentCRList === false){
        this.dismissModalCommentCRList();
      }
    });

  }

  chkBackMenu(_s: any) {
    if (_s.chkbackmenu === 'backmenu') {
      _s.appService.status = 1;
      _s.appService.checkrole();
      _s.router.navigate(['/']);
    }
  }

  private setVersion(pathnameURL: string) {
    let temp = pathnameURL.indexOf("/", pathnameURL.indexOf("/") + 1);
    let tempQ = pathnameURL.indexOf("/", (temp + 1));
    if (tempQ !== -1) {
      this.Version = pathnameURL.slice(tempQ, 13);
    }
  }

  private setUniqValues() {
    let uniq_values = [];

    for (let x of this.BusinessNameDD) {
      if (uniq_values.indexOf(x.value) == -1) {
        uniq_values.push(x.value);
        this.tempChkBUDD.push(x);
      }
    }
  }

  private getTempToBusinessNameDD() {
    for (let item1 of this.tempAssetBU) {
      this.BusinessNameDD.push({ value: item1.asset_Code, text: item1.asset_Short, fullname: item1.asset_Name, state: 'Asset', level: item1.asset_Level });
    }
    for (let item2 of this.tempOrg) {
      this.BusinessNameDD.push({ value: item2.organizatioN_ID, text: item2.abbreviation, fullname: item2.organizatioN_NANE, state: 'Org', level: item2.organizatioN_LEVEL });
    }
    for (let item3 of this.tempERM) {
      this.BusinessNameDD.push({ value: item3.organizatioN_ID, text: item3.abbreviation, fullname: item3.organizatioN_NANE, state: 'Corporate', level: item3.organizatioN_LEVEL });
    }
    if (this.tempHeadActing) {
      for (let item4 of this.tempHeadActing) {
        this.BusinessNameDD.push({ value: item4.deptCode, text: item4.abbreviation, fullname: item4.deptName, state: 'Org', level: item4.organizatioN_LEVEL });
      }
    }

    for (let item5 of this.tempChildOrg) {
      this.BusinessNameDD.push({ value: item5.organizatioN_ID, text: item5.abbreviation, fullname: item5.name, state: 'Org', level: item5.organizatioN_LEVEL });
    }
    for (let item6 of this.tempOwnerInfo) {
      this.BusinessNameDD.push({ value: item6.organizatioN_ID, text: item6.abbreviation, fullname: item6.name, state: 'Org', level: item6.organizatioN_LEVEL });
    }
  }

  private checkRoleFromStoreg() {

    let isRoleOwnerAndCo = ['Co', 'Owner'].includes(sessionStorage.Role);
    let isPermissionOwner = this.Data_SessionStorage[0].s_PERMISSSION_LEVEL?.toLowerCase().includes('owner');
    let isPermissionCo = this.Data_SessionStorage[0].s_PERMISSSION_LEVEL?.toLowerCase().includes('co');

    if (sessionStorage.Role === 'Staff') {
      this.setTempBu();
      this.sethildorg();
    }
    else if (sessionStorage.Role === 'Co' && (this.Data_SessionStorage[0].s_PERMISSSION_LEVEL.includes("OWNER") || this.Data_SessionStorage[0].s_PERMISSSION_LEVEL.includes("Owner"))) {
      this.setTempBu();
      this.setTempOrg();
      this.sethildorg();
    }
    else if (sessionStorage.Role === 'Co') {
      this.setTempBu();
      this.setTempOrg();
      this.sethildorg();

    }
    else if (sessionStorage.Role === 'Owner') {
      this.setTempBu();
      this.SetTempOwnerInfo();
    }
    else if (sessionStorage.Role === 'ERM') {
      this.setTempOrg();
      this.SetTempERM();
      this.sethildorg();
    }
  }

  private SetTempERM() {

    this.tempERM = (this.Data_SessionStorage[0].ermInfo) ? this.Data_SessionStorage[0].ermInfo : [];
  }

  private SetTempOwnerInfo() {

    this.tempOwnerInfo = (this.Data_SessionStorage[0].ownerInfo) ? this.Data_SessionStorage[0].ownerInfo : [];
  }

  private setTempOrg() {

    this.tempOrg = (this.Data_SessionStorage[0].coInfo) ? this.Data_SessionStorage[0].coInfo : [];
  }

  private sethildorg() {

    this.tempChildOrg = (this.Data_SessionStorage[0].childOrganizationInfo) ? this.Data_SessionStorage[0].childOrganizationInfo : [];
  }

  private setTempBu() {

    this.tempAssetBU = (this.Data_SessionStorage[0].assetInfo !== null) ? this.Data_SessionStorage[0].assetInfo : [];
  }

  private SetRegister_Data() {
    this.Register_Data = {
      Regis_BusinessUnit: '',
      Regis_Role: '',
      Regis_Comment: [],
      Risk_Status_Workflow: '',
      Risk_Type: '0'
    };
  }

  private SetTempUpdateFlow() {
    this.tempUpdateFlow = {
      Regis_RiskNo: '',
      Regis_RiskID: '',
      Regis_Riskname: '',
      Regis_Likelyhood: '',
      Regis_Impact: '',
      Regis_Status: '1',
      Regis_Category: '',
      Regis_ObjectiveHead: '',
      Regis_Objective: '',
      Regis_KPI: '',
      Regis_Context: '',
      Regis_BusinessName: '',
      Regis_BusinessUnit: '',
      Regis_Register_By: '',
      Regis_Modified_By: '',
      Regis_Status_Workflow: '',
      Regis_Role: '',
      Regis_RiskType: '',
      tempQuarter: '',
    };
  }

  private SetSesstion() {
    sessionStorage.setItem("DataApprove1", '');
    sessionStorage.setItem("DataApprove2", '');
    sessionStorage.setItem("RiskStatus", '');
    sessionStorage.setItem("TranSecRole", null);
  }

  loadDDL() {
    let _s = this;
    _s.mserve.getQuarterYearDD({ 'Module': 'YearDD' }).then((response) => {
      if (response.data.status) {
        this.DDLYear = response.data.body
      }
    }).catch((e) => { this.authserviceToken.CheckTokenExpire(e.response.status) })


    if (this.Version === null) {
      let d = new Date();
      let currentYear = d.getFullYear();
      let currentMonth = d.getMonth() + 1;
      _s.mservedata.Master_DDL({ Module: "QuarterDD", TextSearch1: currentYear }).then((response) => {
        _s.Quarter = response.data.body
        this.func_calQuarter(currentMonth);
      }).catch((e) => {
        if (e.response) {
          this.authserviceToken.CheckTokenExpire(e.response.status)
        } else {
          //console.log(e);
        }

      });

    }
  }

  loadLatestQuarterDDL() {
    let _s = this;
    _s.mserve.getQuarterYearDD({ 'Module': 'LatestQuarterDD' }).then((response) => {
      if (response.data.status) {
        this.LatestQuarterDDLYear = response.data.body

        //console.log(this.LatestQuarterDDLYear);
      }
    }).catch((e) => { this.authserviceToken.CheckTokenExpire(e.response.status) })
  }

  changeQuarter(val) {
    this.Quarter_Getlist = val;
    this.loadRisk();
  }

  sendDataShowBuName(dataToSend: string) {

    this.dataService.sendData(dataToSend);
  }
  func_calQuarter(month) {
    this.Quarter_Getlist = ''
    this.setQualterGetList(month);

    if (!sessionStorage.TempSelctBUMyrisk) {
      if (!sessionStorage.ChkBTNNextFromReview) {
        if (this.BusinessNameDD.length === 1) {
          this.appService.showBUName = this.BusinessNameDD[0].text;
          this.tempselectbu = this.BusinessNameDD[0].text;

          sessionStorage.setItem('BUNameWork', this.BusinessNameDD[0].text);
          this.sendDataShowBuName(this.BusinessNameDD[0].text);




          if (this.BusinessNameDD[0].State === 'Asset') {
            this.Register_Data.Regis_RiskType = 'Asset'
          } else {
            this.Register_Data.Regis_RiskType = 'Organization'
          }
          this.Register_Data.Regis_BusinessUnit = this.BusinessNameDD[0].value;
          this.tempLevel = this.BusinessNameDD[0].level;
          this.changepage(this.page)
        }
      } else {
        this.appService.showBUName = sessionStorage.BUNameWork;
        this.tempselectbu = sessionStorage.BUNameWork;
        this.Register_Data.Regis_RiskType = sessionStorage.BUType;
        this.Register_Data.Regis_BusinessUnit = sessionStorage.BUValueWork;
        this.changepage(this.page)
      }
    }
    else {
      let temp2 = JSON.parse(sessionStorage.TempSelctBUMyrisk);

      this.appService.showBUName = temp2[0].BUName;
      this.tempselectbu = temp2[0].BUName;
      this.Register_Data.Regis_RiskType = temp2[0].Type;
      this.Register_Data.Regis_BusinessUnit = temp2[0].BUCode;
      this.tempLevel = temp2[0].Level
      this.changepage(this.page)

      sessionStorage.setItem("TempSelctBUMyrisk", '');
    }

    this.func_GETDATA_RiskRating();
  }
  private setQualterGetList(month: any) {

    let quater = '';
    if (month >= 1 && month <= 3) {
      quater = 'Q1'
    } else if (month >= 4 && month <= 6) {
      quater = 'Q2'
    } else if (month >= 7 && month <= 9) {
      quater = 'Q3'
    } else if (month >= 10 && month <= 12) {
      quater = 'Q4'
    }

    let quaterData = this.Quarter.filter(x => x.text === quater)[0] ? this.Quarter.filter(x => x.text === quater)[0].value : null;
    this.Quarter_Getlist = quaterData ? quaterData : this.Quarter_Getlist;
  }

  func_getQuarterVersion() {
    const _s = this;
    _s.mservedata.Master_DDL({ Module: "QuarterDD", TextSearch1: this.VersionYear }).then((response) => {
      _s.Quarter = response.data.body
    }).catch((e) => {
      if (e.response) {
        this.authserviceToken.CheckTokenExpire(e.response.status)
      } else {
        //console.log(e)
      }
    });

  }

  func_GETDATA_RiskRating() {
    const _s = this;
    let formdata = {
      SessionEmpID: 1,
      Module: "getlist",
      body: {
        RiskRatingID: "",
        QuarterID: this.Quarter_Getlist,
        RiskRatingCode: "Test0002",
        Likelihood: "2",
        Impact: "3",
        RiskRating: "",
        EscalationLevel: "1",
        DelFlag: "0",
        LikelihoodAndImpact: ""
      }
    }
    this.mservedata.GetRiskRating(formdata).then((response) => {
      if (response.data.status) {
        this.tableriskrating = response.data.body;
        this.func_calExculationLevel();
      } else {
        //console.log(response.data.errorMessage);
      }
    }).catch((e) => {
      if (e.response) {
        this.authserviceToken.CheckTokenExpire(e.response.status)
      } else {
        //console.log(e)
      }
    });
  }
  func_calExculationLevel() {
    const _s = this;
    let tempMedEsc = []
    _s.tableriskrating.forEach(function (ItemVal, ItemIndex) {
      if (ItemVal.riskRating === "Medium" && ItemVal.escalationLevel === "1") {
        tempMedEsc.push(ItemVal)
      }
    });


    for (let itemtempMedEsc of tempMedEsc) {

      if (itemtempMedEsc.impact === "Minor") {
        this.checkMinorLikelihood(itemtempMedEsc, _s);
      }
      else if (itemtempMedEsc.impact === "Moderate") {
        this.checkModerateLikelihood(itemtempMedEsc, _s);

      }
      else if (itemtempMedEsc.impact === "Significant") {
        this.checkSignificantLikelihood(itemtempMedEsc, _s);
      }
      else if (itemtempMedEsc.impact === "Serious") {
        this.checkSeriousLikelihood(itemtempMedEsc, _s);
      }
      else if (itemtempMedEsc.impact === "Critical") {
        this.checkCriticalLikelihood(itemtempMedEsc, _s);
      }

    }


  }

  private checkCriticalLikelihood(itemtempMedEsc: any, _s: this) {
    if (itemtempMedEsc.likelihood === "Rare") {
      _s.list.risk.risktable[0].col[1].class = "Escalation";
    }
    else if (itemtempMedEsc.likelihood === "Unlikely") {
      _s.list.risk.risktable[0].col[2].class = "Escalation";
    }
  }

  private checkSeriousLikelihood(itemtempMedEsc: any, _s: this) {
    if (itemtempMedEsc.likelihood === "Rare") {
      _s.list.risk.risktable[1].col[1].class = "Escalation";
    }
    else if (itemtempMedEsc.likelihood === "Unlikely") {
      _s.list.risk.risktable[1].col[2].class = "Escalation";
    }
    else if (itemtempMedEsc.likelihood === "Possible") {
      _s.list.risk.risktable[1].col[3].class = "Escalation";
    }
  }

  private checkSignificantLikelihood(itemtempMedEsc: any, _s: this) {
    if (itemtempMedEsc.likelihood === "Unlikely") {
      _s.list.risk.risktable[2].col[2].class = "Escalation";
    }
    else if (itemtempMedEsc.likelihood === "Possible") {
      _s.list.risk.risktable[2].col[3].class = "Escalation";
    }
    else if (itemtempMedEsc.likelihood === "Likely") {
      _s.list.risk.risktable[2].col[4].class = "Escalation";
    }
  }

  private checkModerateLikelihood(itemtempMedEsc: any, _s: this) {
    if (itemtempMedEsc.likelihood === "Possible") {
      _s.list.risk.risktable[3].col[3].class = "Escalation";
    }
    else if (itemtempMedEsc.likelihood === "Likely") {
      _s.list.risk.risktable[3].col[4].class = "Escalation";
    }
    else if (itemtempMedEsc.likelihood === "Almost Certain") {
      _s.list.risk.risktable[3].col[5].class = "Escalation";
    }
  }

  private checkMinorLikelihood(itemtempMedEsc: any, _s: this) {
    if (itemtempMedEsc.likelihood === "Likely") {
      _s.list.risk.risktable[4].col[4].class = "Escalation";
    } else if (itemtempMedEsc.likelihood === "Almost Certain") {
      _s.list.risk.risktable[4].col[5].class = "Escalation";
    }
  }

  func_GetDataUser() {
    this.Register_Data.Regis_Role = sessionStorage.Role;
    this.empid = this.Data_SessionStorage[0].employeE_ID

  }
  checktab(value) {

    const _s = this;
    if (this.link !== '') {
      this.page = this.link.toString()
    } else {
      this.page = value;
    }

    if (this.page === '1') {
      let element = document.getElementById("TodoList");
      element.classList.add("active");
      let element2 = document.getElementById("RiskMap");
      element2.classList.remove("active");
      this.valuecheckSelectAll = false;
    } else {
      let element11 = document.getElementById("RiskMap");
      element11.classList.add("active");
      let element21 = document.getElementById("TodoList");
      element21.classList.remove("active");
      this.valuecheckApproveAll = false;
      this.valuecheckRejectAll = false;
    }
  }
  changepage(value) {
    const _s = this;
    this.page = value;

    if (this.page === '1') {
      let element = document.getElementById("TodoList");
      element.classList.add("active");
      let element2 = document.getElementById("RiskMap");
      element2.classList.remove("active");
      this.valuecheckSelectAll = false;
    } else {
      let element11 = document.getElementById("RiskMap");
      element11.classList.add("active");
      let element21 = document.getElementById("TodoList");
      element21.classList.remove("active");
      this.valuecheckApproveAll = false;
      this.valuecheckRejectAll = false;
    }
    this.loadRisk()
  }

  loadRisk() {
    const _s = this;

    if (this.page === '1') {
      this.tempModule = 'TODO';
    } else {
      this.tempModule = 'MyRISK';
    }

    var tempObj = {
      BusinessCode: this.Register_Data.Regis_BusinessUnit,
      QuarterID: this.Quarter_Getlist
    }

    var tempObj2 = {
      FilterUser: this.Data_SessionStorage[0].employeE_ID,  //"EmployeeID ของ User ที่ Login",
      Module: this.tempModule,
      Role: this.Register_Data.Regis_Role,
      BUCode: this.Register_Data.Regis_BusinessUnit,
      QuarterID: this.Quarter_Getlist,
      Filter_Type: this.Register_Data.Risk_Type
    }

    var tempObj3 = {
      FilterUser: this.Data_SessionStorage[0].employeE_ID,  //"EmployeeID ของ User ที่ Login",
      Module: this.tempModule,
      Role: this.Register_Data.Regis_Role,
      BUCode: this.Register_Data.Regis_BusinessUnit,
      QuarterID: this.Quarter_Getlist,
      Filter_Type: this.Register_Data.Risk_Type
    }

    //console.log('para1', tempObj)
    //console.log('para2', tempObj2)
    //console.log('para3', tempObj3)

    if (this.Register_Data.Regis_BusinessUnit !== '') {
      this.loading = true

      //load data Impact
      _s.mserve.getImpact2(tempObj).then((response) => {
        if (response.data.status === true) {
          //console.log('getImpact : true', response)
          _s.list.risk.impact = response.data.body

          const setValue = function (item, para, index) {
            let temp = ''
            if (index !== 4) {
              if (item[para + '_t'] === null) temp = '=< ' + _s.numberWithCommas(item[para + '_f'])
            } else {
              if (item[para + '_t'] === null) temp = '> ' + _s.numberWithCommas(item[para + '_f'])
            }
            if (item[para + '_f'] === null) temp = _s.numberWithCommas(item[para + '_t']) + ' >'
            if (item[para + '_f'] !== null && item[para + '_t'] !== null) temp = '> ' + _s.numberWithCommas(item[para + '_f']) + ' - ' + _s.numberWithCommas(item[para + '_t'])
            return temp
          }
          _s.list.risk.impact.forEach(function (ItemVal, ItemIndex) {
            _s.list.risk.risktable[((_s.list.risk.impact.length - 1) - ItemIndex)].col[0].item[0].title = ItemVal.ImpactCateItemName
            _s.list.risk.risktable[((_s.list.risk.impact.length - 1) - ItemIndex)].col[0].item[2].title = setValue(ItemVal, 'NPV', ItemIndex)
            _s.list.risk.risktable[((_s.list.risk.impact.length - 1) - ItemIndex)].col[0].item[4].title = setValue(ItemVal, 'NI', ItemIndex)
          })
        } else {
          _s.loading = false;
          alert(response.data.errorMessage);
          //console.log('getImpact : false', response)
        }
      })

      //load data item
      _s.mserve.getRisktodo(tempObj2).then((response) => {
        if (response.data.status === true) {
          //console.log('getRiskMaptable : true', response)
          _s.list.risk.riskitems = response.data.body;


          _s.list.risk.risktable.forEach(function (ItemVal, ItemIndex) {
            ItemVal.col.forEach(function (ItemVal2, ItemIndex2) {
              if (ItemIndex !== 5 && ItemIndex2 !== 0) {
                _s.list.risk.risktable[ItemIndex].col[ItemIndex2].item = []
              }
            })
          })

          if (_s.list.risk.riskitems !== null) {


            _s.list.risk.riskitems.forEach(function (ItemVal, ItemIndex) {
              if (_s.list.risk.risktable[(5 - ItemVal.y_Impact)].col[0].item.length !== 0) {
                //console.log('y :' + (5 - ItemVal.y_Impact) + '-- x :' + parseInt(ItemVal.x_Likelihood))

                // if(module == 'TODO'){
                //   return ReConsole == null ? 'Approve' :'Remove from Consolidate'
                // }
                // else{
                //   return '';
                // }




                if (ItemVal.impact_Category === '1') {
                  //console.log("ItemVal.impact_Category === '1'", _s.list.risk.riskitems[ItemIndex])
                  const bgColor = _s.list.risk.risktable[(5 - ItemVal.y_Impact)].col[0].item[(ItemVal.impact_NPT_EMV === '1') ? 4 : 2].bgcolor
                  const Color = _s.list.risk.risktable[(5 - ItemVal.y_Impact)].col[0].item[(ItemVal.impact_NPT_EMV === '1') ? 4 : 2].color
                  _s.list.risk.risktable[(5 - ItemVal.y_Impact)].col[parseInt(ItemVal.x_Likelihood)].item.push({
                    index: (ItemIndex + 1).toString(),
                    title: _s.list.risk.riskitems[ItemIndex].risk_Name,
                    bgcolor: bgColor,
                    color: Color,
                    Risk_Id: _s.list.risk.riskitems[ItemIndex].risk_Id,
                    IsStripes: false,
                    table_Type: _s.list.risk.riskitems[ItemIndex].table_Type,
                    risk_Status_Workflow_ReConsole: _s.list.risk.riskitems[ItemIndex].risk_Status_Workflow_ReConsole,
                    hover_Text: chk_HoverText(_s.tempModule, _s, ItemIndex, false),
                    risk_Status: _s.list.risk.riskitems[ItemIndex].risk_Status

                    // hover_Text:this.hover_Text(this.tempModule,_s.list.risk.riskitems[ItemIndex].risk_Status_Workflow_ReConsole)
                    // Color
                  })
                }
                else {
                  const bgColor = _s.list.risk.risktable[(5 - ItemVal.y_Impact)].col[0].item[0].bgcolor
                  const Color = _s.list.risk.risktable[(5 - ItemVal.y_Impact)].col[0].item[0].color
                  // if (ItemIndex === 0) {
                  //de_bugger  
                  // for (var i=0; i<12; i++) {
                  //console.log("ItemVal.impact_Category !== '1'", _s.list.risk.riskitems[ItemIndex])
                  _s.list.risk.risktable[(5 - ItemVal.y_Impact)].col[parseInt(ItemVal.x_Likelihood)].item.push({
                    index: (ItemIndex + 1).toString(),
                    title: _s.list.risk.riskitems[ItemIndex].risk_Name,
                    bgcolor: bgColor,
                    color: Color,
                    Risk_Id: _s.list.risk.riskitems[ItemIndex].risk_Id,
                    IsStripes: false,
                    table_Type: _s.list.risk.riskitems[ItemIndex].table_Type,
                    risk_Status_Workflow_ReConsole: _s.list.risk.riskitems[ItemIndex].risk_Status_Workflow_ReConsole,
                    // hover_Text: _s.list.risk.riskitems[ItemIndex].risk_Status_Workflow_ReConsole == null ? 'Approve' :'Remove from Consolidate'
                    hover_Text: chk_HoverText(_s.tempModule, _s, ItemIndex, false),
                    risk_Status: _s.list.risk.riskitems[ItemIndex].risk_Status
                    // Color
                  })

                  // if (_s.list.risk.risktable[(5-ItemVal.y_Impact)].col[parseInt(ItemVal.x_Likelihood)].item.length > 9)  {
                  //   _s.TempBubbles.push({ 
                  //     index:(ItemIndex + 1).toString(),
                  //     title: _s.list.risk.riskitems[ItemIndex].Risk_Name,
                  //     bgcolor: bgColor,
                  //     color: Color
                  //     // Color
                  //   })
                  // }

                  // if (i === 8) {
                  //   _s.TempBubbles.push({ 
                  //     index:(ItemIndex + 1).toString(),
                  //     title: _s.list.risk.riskitems[ItemIndex].Risk_Name,
                  //     bgcolor: bgColor,
                  //     color: Color
                  //     // Color
                  //   })
                  // }

                  // }
                  // }

                }
              }
            })

            //Previous
            _s.list.risk.riskitems.forEach(function (ItemVal, ItemIndex) {
              if (_s.list.risk.risktable[(5 - ItemVal.risk_Previous_Impact)].col[0].item.length !== 0
                && (ItemVal.risk_Previous_Impact != ItemVal.y_Impact || ItemVal.risk_Previous_Likelihood != ItemVal.x_Likelihood)) {
                //console.log('y :' + (5 - ItemVal.risk_Previous_Impact) + '-- x :' + parseInt(ItemVal.risk_Previous_Likelihood))
                if (ItemVal.impact_Category === '1') {
                  const bgColor = _s.list.risk.risktable[(5 - ItemVal.risk_Previous_Impact)].col[0].item[(ItemVal.impact_NPT_EMV === '1') ? 4 : 2].bgcolor
                  const Color = _s.list.risk.risktable[(5 - ItemVal.risk_Previous_Impact)].col[0].item[(ItemVal.impact_NPT_EMV === '1') ? 4 : 2].color
                  _s.list.risk.risktable[(5 - ItemVal.risk_Previous_Impact)].col[parseInt(ItemVal.risk_Previous_Likelihood)].item.push({
                    index: (ItemIndex + 1).toString(),
                    title: _s.list.risk.riskitems[ItemIndex].risk_Name,
                    bgcolor: bgColor,
                    color: Color,
                    Risk_Id: _s.list.risk.riskitems[ItemIndex].risk_Id,
                    IsStripes: true,
                    table_Type: _s.list.risk.riskitems[ItemIndex].table_Type,
                    risk_Status_Workflow_ReConsole: _s.list.risk.riskitems[ItemIndex].risk_Status_Workflow_ReConsole,
                    // hover_Text: _s.list.risk.riskitems[ItemIndex].risk_Status_Workflow_ReConsole == null ? 'Approve' :'Remove from Consolidate'
                    hover_Text: chk_HoverText(_s.tempModule, _s, ItemIndex, true),
                    risk_Status: _s.list.risk.riskitems[ItemIndex].risk_Status
                    // Color
                  })
                }
                else {
                  const bgColor = _s.list.risk.risktable[(5 - ItemVal.risk_Previous_Impact)].col[0].item[0].bgcolor
                  const Color = _s.list.risk.risktable[(5 - ItemVal.risk_Previous_Impact)].col[0].item[0].color
                  _s.list.risk.risktable[(5 - ItemVal.risk_Previous_Impact)].col[parseInt(ItemVal.risk_Previous_Likelihood)].item.push({
                    index: (ItemIndex + 1).toString(),
                    title: _s.list.risk.riskitems[ItemIndex].risk_Name,
                    bgcolor: bgColor,
                    color: Color,
                    Risk_Id: _s.list.risk.riskitems[ItemIndex].risk_Id,
                    IsStripes: true,
                    table_Type: _s.list.risk.riskitems[ItemIndex].table_Type,
                    risk_Status_Workflow_ReConsole: _s.list.risk.riskitems[ItemIndex].risk_Status_Workflow_ReConsole,
                    // hover_Text: _s.list.risk.riskitems[ItemIndex].risk_Status_Workflow_ReConsole == null ? 'Approve' :'Remove from Consolidate'
                    hover_Text: chk_HoverText(_s.tempModule, _s, ItemIndex, true),
                    risk_Status: _s.list.risk.riskitems[ItemIndex].risk_Status
                    // Color
                  })
                }
              }
            })
          }

          // _s.loading = false;
          //console.log('ListRiskMapTable :==> ', _s.list.risk.risktable);

        } else {
          _s.loading = false;
          alert(response.data.errorMessage);
          //console.log('getRisktodo : false', response)
        }
      })

      //load data table
      _s.mserve.GetRiskUnit(tempObj3).then((response) => {
        if (response.data.status === true) {
          //console.log('GetRiskUnit : true', response)
          this.listRiskTableItem = response.data.body;
          this.Register_Data.Regis_Comment = [];

          if (this.listRiskTableItem != null) {
            for (var i = 0; i < this.listRiskTableItem.length; i++) {

              this.listRiskTableItem[i].comment = ''
              this.listRiskTableItem[i].checkApp = false;
              this.listRiskTableItem[i].checkRej = false;
              this.listRiskTableItem[i].ChkRework = false;
              this.listRiskTableItem[i].ChkDelete = false;
              this.listRiskTableItem[i].Chkedit = true;
              this.listRiskTableItem[i].ChkRemoveAll = false;
              this.Register_Data.Regis_Comment.push({ comment: '' });

              this.listRiskTableItem[i].chkbox = false;
              if (this.listRiskTableItem[i].risk_AssignTo !== null) {
                this.listRiskTableItem[i].risk_AssignTo = this.listRiskTableItem[i].risk_AssignTo.split(',');
                for (var x = 0; x < this.listRiskTableItem[i].risk_AssignTo.length; x++) {
                  if (this.listRiskTableItem[i].risk_AssignTo[x] == this.Data_SessionStorage[0].employeE_ID) {
                    this.listRiskTableItem[i].chkbox = true;
                    break;
                  } else {
                    this.listRiskTableItem[i].chkbox = false;
                    //break;
                  }
                }
              }

              //
              // debug for inspect risk_Status_Name
             
              if (this.listRiskTableItem[i].risk_Status_Code == 4 || this.listRiskTableItem[i].risk_Status_Code == 7 || this.listRiskTableItem[i].risk_Status_Code == 10 || this.listRiskTableItem[i].risk_Status_Code == 13) {
                if (this.listRiskTableItem[i].risk_Status_Code == 4) {
                  this.listRiskTableItem[i].risk_Status_Name = this.listRiskTableItem[i].risk_Status_Name + ' (Asset Level) '        
                }
                else if (this.listRiskTableItem[i].risk_Status_Code == 7) {
                  let CheckSubmitDep = this.listRiskTableItem[i].history.filter(his => his.action == "Submit" && his.bu_Level == "Department");          
                  if(CheckSubmitDep.length >0)this.listRiskTableItem[i].risk_Status_Name = this.listRiskTableItem[i].risk_Status_Name + ' (Department Level) '
                  else this.listRiskTableItem[i].risk_Status_Name = this.listRiskTableItem[i].risk_Status_Name + ' (Asset Level) ' 
                }
                else if (this.listRiskTableItem[i].risk_Status_Code == 10) {
                  this.listRiskTableItem[i].risk_Status_Name = this.listRiskTableItem[i].risk_Status_Name + ' (Division Level) '
                }
                else if (this.listRiskTableItem[i].risk_Status_Code == 13) {
                  this.listRiskTableItem[i].risk_Status_Name = this.listRiskTableItem[i].risk_Status_Name + ' (Function Group Level) ' 
                }

              }

              if(this.listRiskTableItem[i].table_Type == "Staff"){
                if (this.listRiskTableItem[i].risk_Status_Code == 4)this.listRiskTableItem[i].risk_Status_Name   = 'Waiting for Risk CO Consolidate​';
                else if (this.listRiskTableItem[i].risk_Status_Code == 5 && this.listRiskTableItem[i].transectionLevel=="Department")this.listRiskTableItem[i].risk_Status_Name   = 'Waiting for submit to Risk Owner (Department Level)​';
                else if (this.listRiskTableItem[i].risk_Status_Code == 5 && this.listRiskTableItem[i].transectionLevel=="Division")this.listRiskTableItem[i].risk_Status_Name   = 'Waiting for submit to Risk Owner (Division Level)​';
                else if (this.listRiskTableItem[i].risk_Status_Code == 5 && this.listRiskTableItem[i].transectionLevel=="Group")this.listRiskTableItem[i].risk_Status_Name   = 'Waiting for submit to Risk Owner (Function Group Level)​';
                else if (this.listRiskTableItem[i].risk_Status_Code == 7) this.listRiskTableItem[i].risk_Status_Name   = 'Waiting for Risk CO Consolidate​';
                else if (this.listRiskTableItem[i].risk_Status_Code == 10) this.listRiskTableItem[i].risk_Status_Name   = 'Waiting for Risk CO Consolidate​';                 
              }
            }
            _s.func_chk_status();
            _s.func_chk_delete();
            _s.func_chk_rework();
            _s.valuecheckApproveAll = false
            _s.valuecheckRejectAll = false;
            _s.valuecheckSelectAll = false;
            _s.loading = false;
            const find = this.listRiskTableItem.filter(x => x.ChkRework == true && x.risk_ViewMode !== 'Y')
            //console.log(this.listRiskTableItem)
            if ((find.length !== 0) && this.chkroleuser != 'Owner') {
              this.displayRemove = false;
            }
            //console.log('Register_Data : ', this.Register_Data)
            //console.log('listRiskTableItem : ', this.listRiskTableItem)

            // ข้อมูลการ SearchKey
            this.temp = [];
            this.SearchMyrisk = '';
            Array.prototype.push.apply(this.temp, this.listRiskTableItem);
            //--------------------
          }
          else {
            _s.loading = false;
          }

        }
        else {
          _s.loading = false;
          alert(response.data.errorMessage);
          //console.log('GetRiskUnit : false', response);
        }
      })

    }
  }


  func_chk_status() {
    for (let itemRiskTable of this.listRiskTableItem) {

      let closeInvalidApprove = (itemRiskTable.close_Invalid == 'Y' && itemRiskTable.close_Invalid_Approve == 'Y');
      let close_Mitigation = (itemRiskTable.close_Mitigation == 'Y' && itemRiskTable.close_Mitigation_Approve == 'Y');
      let closeOtherApprove = (itemRiskTable.close_Other == 'Y' && itemRiskTable.close_Other_Approve == 'Y');
      //Check_RoleOwnerAndMyrisk = (this.tempModule === 'MyRISK' && this.Register_Data.Regis_Role === "Owner");
      let chkBuItem = (this.tempselectbu != itemRiskTable.risk_BusinessUnit_Name)

      if (closeInvalidApprove || close_Mitigation || closeOtherApprove || (itemRiskTable.risk_ViewMode === 'Y') || chkBuItem) {
        itemRiskTable.Chkedit = false;
      }
      if((sessionStorage.Role == "Staff") && (itemRiskTable.risk_Source == "Consolidation")){
        //เงื่อนไข เพื่อปิดการ Edit ของ Staff เมื่อใบงานที่ Submit มา ถูก consolidate แล้ว
        itemRiskTable.Chkedit = false;
      }
    }
  }

  func_chk_delete() {
    //console.log("start func_chk_delete :", this.listRiskTableItem);
    //console.log("BUValueWork :", sessionStorage.BUValueWork); // this.Register_Data.Regis_BusinessUnit = sessionStorage.BUValueWork;
    for (var i = 0; i < this.listRiskTableItem.length; i++) {
      if (this.listRiskTableItem[i].risk_AssignTo != null) {
        if (this.listRiskTableItem[i].risk_AssignTo.indexOf(this.Data_SessionStorage[0].employeE_ID) != -1) {

          var checkStatusReject = true;
          if (this.listRiskTableItem[i].risk_Status_Code == "15" ||
            this.listRiskTableItem[i].risk_Status_Code == "16" ||
            this.listRiskTableItem[i].risk_Status_Code == "17" ||
            this.listRiskTableItem[i].risk_Status_Code == "18") {
            checkStatusReject = false;
          }
          if (this.listRiskTableItem[i].transectionLevel == "Department" && this.listRiskTableItem[i].bU_Flag != "Y" && checkStatusReject) {
            this.listRiskTableItem[i].ChkDelete = true;
          }
          else if (this.listRiskTableItem[i].transectionLevel == "Division" && this.listRiskTableItem[i].dI_Flag != "Y"&& checkStatusReject) {
            this.listRiskTableItem[i].ChkDelete = true;
          }
          else if (this.listRiskTableItem[i].transectionLevel == "Funcgroup" && this.listRiskTableItem[i].fG_Flag != "Y"&& checkStatusReject) {
            this.listRiskTableItem[i].ChkDelete = true;
          }
          else if (this.listRiskTableItem[i].transectionLevel == "Group" && this.listRiskTableItem[i].fG_Flag != "Y"&& checkStatusReject) {
            this.listRiskTableItem[i].ChkDelete = true;
          }
          else if (this.listRiskTableItem[i].transectionLevel == "Asset" && this.listRiskTableItem[i].asset_Flag != "Y"&& checkStatusReject) {
            this.listRiskTableItem[i].ChkDelete = true;
          }
          else if (this.listRiskTableItem[i].transectionLevel == "Corporate" && (this.listRiskTableItem[i].risk_Status_Code == "23" || this.listRiskTableItem[i].risk_Status_Code == "0")&& checkStatusReject) {
            this.listRiskTableItem[i].ChkDelete = true;
          }

        } else if ((this.listRiskTableItem[i].risk_AssignTo[0] == "") && (this.listRiskTableItem[i].bU_Flag != "Y") && (this.listRiskTableItem[i].dI_Flag != "Y") && (this.listRiskTableItem[i].fG_Flag != "Y") && (this.listRiskTableItem[i].fG_Flag != "Y") && (this.listRiskTableItem[i].asset_Flag != "Y")) {
          this.listRiskTableItem[i].ChkDelete = true;
        }
      }
      else if(this.listRiskTableItem[i].risk_Status_Code == "23" )//เช็คตอนที่สร้างใบงานแต่ risk_AssignTo เป็น Null (ถ้ากรอกจนครบ risk_AssignTo จะไม่ใช่ Null)
      {
        this.listRiskTableItem[i].ChkDelete = true;
      }
    }
  }

  func_chk_rework() {
    this.BUWork = sessionStorage.BUNameWork;

    

    if (this.listRiskTableItem != null) {
      for (var i = 0; i < this.listRiskTableItem.length; i++) {
        
        this.listRiskTableItem[i].isUserLevelApprove = this.checkUserLevelApprove(this.listRiskTableItem[i]);
        if (this.listRiskTableItem[i].risk_Status == "1") {// check active
          if (this.listRiskTableItem[i].history != null) {
            for (var ii = 0; ii < this.listRiskTableItem[i].history.length; ii++) {
              if (this.listRiskTableItem[i].history[ii].status_Id == "14" && this.Register_Data.Regis_Role === 'ERM') {
                this.listRiskTableItem[i].ChkRework = true
                break;
              }
              // == "Submit"
              else if (this.listRiskTableItem[i].history[ii].action != "Consolidate") {
                break;
              }
            }
          }
        }
      }
      for (var x = 0; x < this.listRiskTableItem.length; x++) {
        //console.log("this.tempLevel ",this.tempLevel);
        if (this.listRiskTableItem[x].risk_Status == "1") { // check active
          var temp_dI_Console = (this.listRiskTableItem[x].dI_Console !== null) ? this.listRiskTableItem[x].dI_Console.split(",") : "";
          var temp_fG_Console = (this.listRiskTableItem[x].fG_Console !== null) ? this.listRiskTableItem[x].fG_Console.split(",") : "";
          var temp_bU_Console = (this.listRiskTableItem[x].bU_Console !== null) ? this.listRiskTableItem[x].bU_Console.split(",") : "";

          //console.log("Risk_Status: ",this.listRiskTableItem[x].risk_Status_Code);

          if (this.tempLevel == 'Department') {
            for (var s = 0; s < temp_bU_Console.length; s++) {
              if (temp_bU_Console[s] == this.empid) {           
                if(this.BUWork === this.listRiskTableItem[x].risk_BusinessUnit_Name && this.listRiskTableItem[x].risk_Source === 'My Risk')
                  this.listRiskTableItem[x].ChkRework = false;
                else  
                //Consolidation
                if (this.BUWork === this.listRiskTableItem[x].risk_BusinessUnit_Name) 
                this.listRiskTableItem[x].ChkRework = false;
                else                  
                  if(this.listRiskTableItem[x].risk_Status_Code == 5 || this.listRiskTableItem[x].risk_Status_Code == 7 
                    || this.listRiskTableItem[x].risk_Status_Code == 6 || this.listRiskTableItem[x].risk_Status_Code == 16
                    || this.listRiskTableItem[x].risk_Status_Code == 10
                    || this.listRiskTableItem[x].risk_Status_Code == 11
                    || this.listRiskTableItem[x].risk_Status_Code == 12
                    || this.listRiskTableItem[x].risk_Status_Code == 13)
                  this.listRiskTableItem[x].ChkRework = true;
                else
                  this.listRiskTableItem[x].ChkRework = false;
                break;
              }
            }
          }
          // else if (this.tempLevel == 'Division') {
          //   for (var s = 0; s < temp_dI_Console.length; s++) {
          //     if (temp_dI_Console[s] == this.empid) {
          //       this.listRiskTableItem[x].ChkRework = true
          //       break;
          //     }
          //   }
          // }
          if (this.tempLevel == 'Division') {
            for (var s = 0; s < temp_dI_Console.length; s++) {
              if (temp_dI_Console[s] == this.empid) {           
                if(this.BUWork === this.listRiskTableItem[x].risk_BusinessUnit_Name && this.listRiskTableItem[x].risk_Source === 'My Risk')
                  this.listRiskTableItem[x].ChkRework = false;
                else  
                //Consolidation
                if (this.BUWork === this.listRiskTableItem[x].risk_BusinessUnit_Name) 
                this.listRiskTableItem[x].ChkRework = false;
                else                  
                  if(this.listRiskTableItem[x].risk_Status_Code == 8 || this.listRiskTableItem[x].risk_Status_Code == 10 
                    || this.listRiskTableItem[x].risk_Status_Code == 9 || this.listRiskTableItem[x].risk_Status_Code == 17
                    || this.listRiskTableItem[x].risk_Status_Code == 18
                    || this.listRiskTableItem[x].risk_Status_Code == 11
                    || this.listRiskTableItem[x].risk_Status_Code == 12
                    || this.listRiskTableItem[x].risk_Status_Code == 13)
                  this.listRiskTableItem[x].ChkRework = true;
                else
                  this.listRiskTableItem[x].ChkRework = false;
                break;
              }
            }
          }
          // else if (this.tempLevel == 'Group') {
          //   for (var ss = 0; ss < temp_fG_Console.length; ss++) {
          //     if (temp_fG_Console[ss] == this.empid) {
          //       this.listRiskTableItem[x].ChkRework = true
          //       break;
          //     }
          //   }
          // }
          if (this.tempLevel == 'Group') {
            for (var s = 0; s < temp_fG_Console.length; s++) {
              if (temp_fG_Console[s] == this.empid) {           
                if(this.BUWork === this.listRiskTableItem[x].risk_BusinessUnit_Name && this.listRiskTableItem[x].risk_Source === 'My Risk')
                  this.listRiskTableItem[x].ChkRework = false;
                else  
                //Consolidation
                  if (this.BUWork === this.listRiskTableItem[x].risk_BusinessUnit_Name) 
                  this.listRiskTableItem[x].ChkRework = false;
                  else                  
                    if(this.listRiskTableItem[x].risk_Status_Code == 11 || this.listRiskTableItem[x].risk_Status_Code == 13 
                      || this.listRiskTableItem[x].risk_Status_Code == 12 || this.listRiskTableItem[x].risk_Status_Code == 18)
                    this.listRiskTableItem[x].ChkRework = true;
                  else
                    this.listRiskTableItem[x].ChkRework = false;
                break;
              }
            }
          }
          // else if (this.listRiskTableItem[x].risk_BusinessUnit_Name != this.BUWork) {
          //   for (var sss = 0; sss < temp_bU_Console.length; sss++) {
          //     if (temp_bU_Console[sss] == this.empid) {
          //       this.listRiskTableItem[x].ChkRework = true
          //       break;
          //     }
          //   }
          // }

          // //Case own created risk
          // if (this.listRiskTableItem[x].risk_Register_By == this.Data_SessionStorage[0].employeE_ID) {
          //    //console.log('[x] ', x, 'this.listRiskTableItem[x].ChkRework =', false)
          //   this.listRiskTableItem[x].ChkRework = false

          
          // }

          //Case risk register as Function group level
          if (this.listRiskTableItem[x].transectionLevel == "Group" && this.listRiskTableItem[x].fG_Flag == "Y") {
             //console.log('transectionLevel = Group ', x, 'this.listRiskTableItem[x].ChkRework =', false)
            this.listRiskTableItem[x].ChkRework = false
          }
        }
      }
    }
  }

  func_Rework(item) {

    //console.log("func_Rework(item)");
    const _s = this;
    _s.chkrework = null
    let temp_dI_Console = this.ReworkSplitConsole(item.dI_Console);
    let temp_fG_Console = this.ReworkSplitConsole(item.fG_Console);
    let temp_bU_Console = this.ReworkSplitConsole(item.bU_Console);
    const flagFields = {
      'Department': 'bU_Flag',
      'Division': 'dI_Flag',
      'Funcgroup': null,
      'Group': null
    };

    if (item.risk_Name.length <= 25) {
          this.RiskName = item.risk_Name;
          this.RiskFullName = item.risk_Name;
        } else {
          this.RiskName = item.risk_Name.substring(0, 25) + '...'
          this.RiskFullName = item.risk_Name;
        }

    let isEmpIdFound = false;
    // const tempArray = this.func_rework_setTempArray2(_s, temp_bU_Console, temp_dI_Console, temp_fG_Console);

    // if (tempArray) {
    //   const flagField = flagFields[_s.tempLevel];
    //   if (!flagField || item[flagField] === 'Y') {
    //     isEmpIdFound = tempArray.includes(_s.empid);
    //   }
    // }
    //_s.chkrework = isEmpIdFound ? 'mode2' : 'mode1';

    //---------------------//
    //  _s.Register_Data.Regis_BusinessUnit == item.bU_Unit
    if (_s.tempLevel == 'Department' && item.bU_Flag == 'Y') {
      for (var s = 0; s < temp_bU_Console.length; s++) {
        if (_s.empid == temp_bU_Console[s]) {
          _s.chkrework = 'mode2'
          break;
        }
      }
    }
    // _s.Register_Data.Regis_BusinessUnit == item.dI_Unit
    else if (_s.tempLevel == 'Division' && item.dI_Flag == 'Y') {
      for (var s = 0; s < temp_dI_Console.length; s++) {
        if (_s.empid == temp_dI_Console[s]) {
          _s.chkrework = 'mode2'
          break;
        }
      }
    }
    // _s.Register_Data.Regis_BusinessUnit == item.fG_Unit
    else if ((_s.tempLevel == 'Funcgroup' || _s.tempLevel == 'Group') && item.fG_Flag == 'Y') {
      for (var s = 0; s < temp_fG_Console.length; s++) {
        if (_s.empid == temp_fG_Console[s]) {
          _s.chkrework = 'mode2'
          break;
        }
      }
    }
    else {
      _s.chkrework = 'mode1'
    }

    //----------------------//

    if (_s.chkrework == 'mode1') {
      this.func_Rework_process_mode1(item, _s);
    }
    else {
      this.tempItemConsole = item
      this.Consolecomment = ''; 
      $('#Consolecomment').hide();
      $('#modalCommentCR').modal('show');
    }
  }

  func_ReworkChk(item) {
    if (item.ChkRemoveAll) {
      const _s = this;
      _s.chkrework = null
      let temp_dI_Console = this.ReworkSplitConsole(item.dI_Console);
      let temp_fG_Console = this.ReworkSplitConsole(item.fG_Console);
      let temp_bU_Console = this.ReworkSplitConsole(item.bU_Console);

      if (_s.tempLevel == 'Department' && item.bU_Flag == 'Y') {
        for (var s = 0; s < temp_bU_Console.length; s++) {
          if (_s.empid == temp_bU_Console[s]) {
            _s.chkrework = 'mode2'
            break;
          }
        }
      }
      else if (_s.tempLevel == 'Division' && item.dI_Flag == 'Y') {
        for (var s = 0; s < temp_dI_Console.length; s++) {
          if (_s.empid == temp_dI_Console[s]) {
            _s.chkrework = 'mode2'
            break;
          }
        }
      }
      else if ((_s.tempLevel == 'Funcgroup' || _s.tempLevel == 'Group') && item.fG_Flag == 'Y') {
        for (var s = 0; s < temp_fG_Console.length; s++) {
          if (_s.empid == temp_fG_Console[s]) {
            _s.chkrework = 'mode2'
            break;
          }
        }
      }
      else {
        _s.chkrework = 'mode1'
      }

      if (_s.chkrework == 'mode1') {
        this.func_Rework_process_mode1(item, _s);
      } else {
        if (item.risk_Name.length <= 25) {
          this.RiskName = item.risk_Name;
          this.RiskFullName = item.risk_Name;
          this.RiskId = item.risk_Id
        } else {
          this.RiskName = item.risk_Name.substring(0, 25) + '...'
          this.RiskFullName = item.risk_Name;
          this.RiskId = item.risk_Id
        }
        this.tempItemConsoleLst = item
        this.ConsolecommentLst = '';
        this.mode = 'single';
        this.isSubmittedModalCommentCRList = false;
        $('#modalCommentCRList').modal('show');
      }

      this.disableSelectAll = true;
      
    } else {
      this.tempSeletedRemove = this.tempSeletedRemove.filter(x => x.Risk_Id !== item.risk_Id);
      
      item.ChkRemoveAll = false;

      if(this.tempSeletedRemove.length === 0 && ((this.listRiskTableItem.some(item => item.ChkRemoveAll)) === false)){
        this.disableSelectAll = false;
        this.valuecheckRemoveAll = false;
      }
    }

  }


  private func_Rework_process_mode1(item: any, _s: this) {
    let wf = '';
    const relevantActions = ['Consolidate', 'Corporate Risk Profile'];

    //remove .reverse()
    const relevantHistoryItem = item.history.find(historyItem => relevantActions.includes(historyItem.action));

    if (relevantHistoryItem) {
      wf = relevantHistoryItem.status_Id;
    }


    const tempcodebu = item.risk_BusinessUnit_Code.includes('A')
      ? this.Data_SessionStorage[0].coInfo.find(
        itemInfo => itemInfo.coordinator_Employee_Id === this.Data_SessionStorage[0].employeE_ID &&
          itemInfo.abbreviation === _s.tempselectbu
      )?.organizatioN_ID || ''
      : item.risk_BusinessUnit_Code;

    let formdata = {
      "SessionEmpID": _s.empid,
      "SecurityCode": "1234",
      "Module": "Reconsole",
      "body": {
        "ReConsolidateBy": _s.empid,
        "ReConsoleTransection": [
          {
            "Risk_Id": item.risk_Id,
            "Risk_Business_Unit": tempcodebu,
            "Risk_Status_Workflow": wf,
            "Risk_AssignTo": "",
            "comment": ""
          },
        ]
      }
    };
    this.loading = true;
    _s.mserve.RiskReConsolidate(formdata).then((response) => {
      if (response.data.status) {
        this.loading = false;
        _s.loadRisk();
      }
      else {
        this.alertflow = response.data.errorMessage;
        $('#modalAlertdangerApprove').modal('show');
      }
    }).catch((e) => {
      if (e.response) {
        this.authserviceToken.CheckTokenExpire(e.response.status);
      } else {
        //console.log(e);
      }
    });
  }

  private func_rework_setTempArray2(_s: this, temp_bU_Console: any, temp_dI_Console: any, temp_fG_Console: any) {

    let result = null;
    if (_s.tempLevel === 'Department') {
      result = temp_bU_Console;
    } else if (_s.tempLevel === 'Division') {
      result = temp_dI_Console;
    } else if (_s.tempLevel === 'Funcgroup' || _s.tempLevel === 'Group') {
      result = temp_fG_Console;
    }
    return result;
  }

  private ReworkSplitConsole(console: any) {
    return (console !== null) ? console.split(",") : "";
  }

  private findValueInArray(array, value) {
    return array.includes(value);
  }

  func_CR_RE_Console() {
    //console.log("Tempconsole", this.tempItemConsole)
    const _s = this;
    let item = this.tempItemConsole
    let comment = this.Consolecomment;
    if (comment == "") {
      $('#Consolecomment').show();
    }
    else {
      const riskStatusCodeMap = {
        'Department': 5,
        'Division': 8,
        'Funcgroup': 11,
        'Group': 11,
      };

      if (_s.chkrework === 'mode2' && riskStatusCodeMap.hasOwnProperty(_s.tempLevel)) {
        item.risk_Status_Code = riskStatusCodeMap[_s.tempLevel];
      }

      if (item.table_Type == "Transection") {
        this.func_CR_RE_Console_TransectionProc(item, _s);
      }
      else if (item.table_Type === "Staff") {
        let selectedStaffPush = {
          Risk_Id: item.risk_Id,
          Risk_Business_Unit: item.risk_BusinessUnit_Code,
          Risk_AssignTo: '',
          Risk_Type: item.risk_Type,
          Risk_Status_Workflow: item.risk_Status_Code,
          Risk_Submit_Action: "Reconsole",
          Risk_Submit_Reason: _s.Consolecomment
        };
        this.func_CR_RE_Console_StaffProc(item, selectedStaffPush);
      }
      $('#modalCommentCR').modal('hide');
      this.func_ConfirmMyRiksCheckComplete(null)
    }
  }

  func_CR_RE_ConsoleList() {
    const _s = this;
    let item = this.tempItemConsoleLst
    let comment = this.ConsolecommentLst;

    //single mode
    if (this.mode === 'single') {
      if (comment == "" || comment.trim() === '') {
        this.isEmptyComment = true;
        this.isSubmittedModalCommentCRList = false;
        $('#ConsolecommentLst').show();
      } else {
        this.func_CR_RE_Console_TransectionProcList(item, _s);
        item.ChkRemoveAll = true;
        item.hadRemoveCheckBox = true;
        this.isEmptyComment = false;
        this.isSubmittedModalCommentCRList = true;

        // //console.log('1hadRemoveCheckBox', item.hadRemoveCheckBox)
        // //console.log('1this.chkroleuser', this.chkroleuser)
        // //console.log('1data.ChkRework', item.ChkRework)
        // //console.log('1data.risk_ViewMode', item.risk_ViewMode)
        // //console.log('1data.risk_Status_Code', item.risk_Status_Code)
        // //console.log('1data.isUserLevelApprove', item.isUserLevelApprove)

        $('#modalCommentCRList').modal('hide');
      }
      //multiple mode
    } else {
      this.tempSelectedRemoveAll.forEach(item => {
        if (!item.Risk_Submit_Reason || item.Risk_Submit_Reason.trim() === '') {
          item.isEmptyCommentLst = true;
        } else {
          item.ChkRemoveAll = true;
          item.hadRemoveCheckBox = true;
          item.isEmptyCommentLst = false;

          // //console.log('2hadRemoveCheckBox', item.hadRemoveCheckBox)
          // //console.log('2this.chkroleuser', this.chkroleuser)
          // //console.log('2data.ChkRework', item.ChkRework)
          // //console.log('2data.risk_ViewMode', item.risk_ViewMode)
          // //console.log('2data.risk_Status_Code', item.risk_Status_Code)
          // //console.log('2data.isUserLevelApprove', item.isUserLevelApprove)

        }
      });

      if (_s.tempSelectedRemoveAll.some(item => item.isEmptyCommentLst)) {
        this.isSubmittedModalCommentCRList = false;
        $('#ConsolecommentLst').show();
      } else {
        this.func_CR_RE_Console_TransectionProcListSelectAll(_s);
        this.isSubmittedModalCommentCRList = true;

        $('#modalCommentCRList').modal('hide');
      }
    }
  }


  private func_CR_RE_Console_StaffProc(item: any, selectedStaffPush: { Risk_Id: any; Risk_Business_Unit: any; Risk_AssignTo: string; Risk_Type: any; Risk_Status_Workflow: any; Risk_Submit_Action: string; Risk_Submit_Reason: string; }) {
    if (item.risk_Type === 'Organization') {
      this.tempSeleted_staff.push(selectedStaffPush);

      const riskID = item.risk_Co_Id !== null ? item.risk_Co_Id : item.risk_Staff_Id;
      this.tempRiskID_staff.push({
        Riskrunning: riskID
      });

    } else if (item.risk_Type === 'Asset') {
      this.tempSeleted2_staff.push({
        selectedStaffPush
      });

      const riskID = item.risk_Co_Id !== null ? item.risk_Co_Id : item.risk_Staff_Id;
      this.tempRiskID2_staff.push({
        Riskrunning: riskID
      });

    } else if (item.risk_Type === 'Corporate') {
      this.tempSeleted3_staff.push({
        selectedStaffPush
      });
      const riskID = item.risk_Co_Id !== null ? item.risk_Co_Id : item.risk_Staff_Id;
      this.tempRiskID3_staff.push({
        Riskrunning: riskID
      });
    }
  }

  private func_CR_RE_Console_TransectionProc(item: any, _s: this) {
    if (item.risk_Type == 'Organization') {
      this.tempSeleted.push({
        Risk_Id: item.risk_Id,
        Risk_Business_Unit: item.risk_BusinessUnit_Code,
        Risk_AssignTo: '',
        Risk_Type: item.risk_Type,
        Risk_Status_Workflow: item.risk_Status_Code,
        Risk_Submit_Action: "Reconsole",
        Risk_Submit_Reason: _s.Consolecomment
      });

      const riskID = item.risk_Co_Id !== null ? item.risk_Co_Id : item.risk_Staff_Id;
      this.tempRiskID.push({
        Riskrunning: riskID
      });

    } else if (item.risk_Type == 'Asset') {
      this.tempSeleted2.push({
        Risk_Id: item.risk_Id,
        Risk_Business_Unit: item.risk_BusinessUnit_Code,
        Risk_AssignTo: '',
        Risk_Type: item.risk_Type,
        Risk_Status_Workflow: item.risk_Status_Code,
        Risk_Submit_Action: "Reconsole",
        Risk_Submit_Reason: _s.Consolecomment
      });

      const riskID = item.risk_Co_Id !== null ? item.risk_Co_Id : item.risk_Staff_Id;
      this.tempRiskID2.push({
        Riskrunning: riskID
      });


    } else if (item.risk_Type == 'Corporate') {
      this.tempSeleted3.push({
        Risk_Id: item.risk_Id,
        Risk_Business_Unit: item.risk_BusinessUnit_Code,
        Risk_AssignTo: '',
        Risk_Type: item.risk_Type,
        Risk_Status_Workflow: item.risk_Status_Code,
        Risk_Submit_Action: "Reconsole",
        Risk_Submit_Reason: _s.Consolecomment
      });

      const riskID = item.risk_Co_Id !== null ? item.risk_Co_Id : item.risk_Staff_Id;
      this.tempRiskID3.push({
        Riskrunning: riskID
      });

    }
  }

  func_CR_RE_Console_TransectionProcList(item: any, _s: this) {
    const riskStatusCodeMap = {
      'Department': 5,
      'Division': 8,
      'Funcgroup': 11,
      'Group': 11,
    };

    if (_s.chkrework === 'mode2' && riskStatusCodeMap.hasOwnProperty(_s.tempLevel)) {
      item.risk_Status_Code = riskStatusCodeMap[_s.tempLevel];
      item.isTempRiskStatusCode = true;
    } else {
      item.isTempRiskStatusCode = false;
    }
    if (item.risk_Type == 'Organization' || item.risk_Type == 'Asset' || item.risk_Type == 'Corporate') {
      this.tempSeletedRemove.push({
        Risk_Id: item.risk_Id,
        Risk_Business_Unit: item.risk_BusinessUnit_Code,
        Risk_AssignTo: '',
        Risk_Type: item.risk_Type,
        Risk_Status_Workflow: item.risk_Status_Code,
        Risk_Submit_Action: "Reconsole",
        Risk_Submit_Reason: _s.ConsolecommentLst,
        Risk_Name: item.risk_Name
      });

      const riskID = item.risk_Co_Id !== null ? item.risk_Co_Id : item.risk_Staff_Id;
      this.tempRiskID.push({
        Riskrunning: riskID
      });

      this.disableSelectAll = true;
    }
  }

  func_CR_RE_Console_TransectionProcListSelectAll(_s: this) {
    const riskStatusCodeMap = {
      'Department': 5,
      'Division': 8,
      'Funcgroup': 11,
      'Group': 11,
    };

    for (var i = 0; i < this.listRiskTableItem.length; i++) {
      const data = this.listRiskTableItem[i];
      //console.log(data);

      if ((_s.chkrework === 'mode2' && riskStatusCodeMap.hasOwnProperty(_s.tempLevel)) && (_s.checkRemoveCheckboxIsShow(data))) {
        data.risk_Status_Code = riskStatusCodeMap[_s.tempLevel];
        data.isTempRiskStatusCode = true;    
      } else{
        data.isTempRiskStatusCode = false;
      }

      // if (data.ChkRemoveAll) {
      //   if (data.risk_Type == 'Organization' || data.risk_Type == 'Asset' || data.risk_Type == 'Corporate') { 
      //     this.tempSeletedRemove.push({
      //       Risk_Id: data.risk_Id,
      //       Risk_Business_Unit: data.risk_BusinessUnit_Code,
      //       Risk_AssignTo: '',
      //       Risk_Type: data.risk_Type,
      //       Risk_Status_Workflow: data.risk_Status_Code,
      //       Risk_Submit_Action: "Reconsole",
      //       Risk_Submit_Reason: _s.ConsolecommentLst,
      //       Risk_Name: data.risk_Name
      //     });

      //     const riskID = data.risk_Co_Id !== null ? data.risk_Co_Id : data.risk_Staff_Id;
      //     this.tempRiskID.push({
      //       Riskrunning: riskID
      //     });

      //   }
      // }
    }
    _s.tempSeletedRemove = _s.tempSelectedRemoveAll;
    //console.log(this.tempSeletedRemove);

  }

  ApproveAll(value) {

    /* For checking IE Browser */
    // var ua = navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    // var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
    //de_bugger
    for (var i = 0; i < this.listRiskTableItem.length; i++) {
      var items;
      items = (document.getElementsByName("selectedApp" + (i + 1)));
      if (value == true && items[0].checked == false) {
        if (this.listRiskTableItem[i].risk_ViewMode === "Y" && this.listRiskTableItem[i].risk_AssignTo_ReConsole == this.Data_SessionStorage[0].employeE_ID) {
          items[0].checked = true;
          this.listRiskTableItem[i].checkApp = true;
        }
        else if (this.listRiskTableItem[i].risk_ViewMode !== "Y") {
          items[0].checked = true;
          this.listRiskTableItem[i].checkApp = true;
        }
      }
      else if (value == false && items[0].checked == true) {
        this.listRiskTableItem[i].checkApp = false;
        items[0].checked = false;
        this.tempSelectedApproved = [];
        this.tempSelectedApproved2 = [];
      }
    }

    if (this.valuecheckRejectAll === true) {
      this.valuecheckRejectAll = false;
      for (var i = 0; i < this.listRiskTableItem.length; i++) {
        items = (document.getElementsByName("selectedRej" + (i + 1)));
        this.listRiskTableItem[i].checkRej = false;
        items[0].checked = false;
      }
    } else {
      for (var i = 0; i < this.listRiskTableItem.length; i++) {
        items = (document.getElementsByName("selectedRej" + (i + 1)));
        this.listRiskTableItem[i].checkRej = false;
        items[0].checked = false;
      }
    }

  }

  SelectedApprove(id, selectnum) {

    let items, items2;
    items = (document.getElementsByName("selectedApp" + selectnum));
    items2 = (document.getElementsByName("selectedRej" + selectnum));
    this.valuecheckRejectAll = false

    if (items[0].checked && !items2[0].checked) {
      this.setApproverCheckApp(id, true, null);
    } else if (items[0].checked && items2[0].checked) {
      items2[0].checked = false
      this.setApproverCheckApp(id, true, false);
    }
    else {
      items2[0].checked = false
      this.setApproverCheckApp(id, false, false);
    }
  }
  private setApproverCheckApp(id: any, checkApp: any, checkRej: any) {

    for (let item of this.listRiskTableItem) {
      if (item.risk_Id === id) {
        item.checkApp = checkApp;
        item.checkRej = checkRej;
      }

    }
  }


  RejectAll(value) {

    /* For checking IE Browser */
    // var ua = navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    // var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;

    for (var i = 0; i < this.listRiskTableItem.length; i++) {
      var items;
      items = (document.getElementsByName("selectedRej" + (i + 1)));
      if (value == true && items[0].checked == false) {
        if (this.listRiskTableItem[i].risk_ViewMode === "Y" && this.listRiskTableItem[i].risk_AssignTo_ReConsole == this.Data_SessionStorage[0].employeE_ID) {
          items[0].checked = true;
          this.listRiskTableItem[i].checkRej = true;
        }
        else if (this.listRiskTableItem[i].risk_ViewMode !== "Y") {
          items[0].checked = true;
          this.listRiskTableItem[i].checkRej = true;
        }
      }
      else if (value == false && items[0].checked == true) {
        this.listRiskTableItem[i].checkRej = false;
        items[0].checked = false;
        this.tempSelectedRejected = [];
        this.tempSelectedRejected2 = [];
      }
    }

    if (this.valuecheckApproveAll === true) {
      this.valuecheckApproveAll = false;
      for (var i = 0; i < this.listRiskTableItem.length; i++) {
        items = (document.getElementsByName("selectedApp" + (i + 1)));
        this.listRiskTableItem[i].checkApp = false;
        items[0].checked = false;
      }
    } else {
      for (var i = 0; i < this.listRiskTableItem.length; i++) {
        items = (document.getElementsByName("selectedApp" + (i + 1)));
        this.listRiskTableItem[i].checkApp = false;
        items[0].checked = false;
      }
    }

    //console.log('listRiskTableItem : ', this.listRiskTableItem);
    //console.log('Data Selected Reject Org : ', this.tempSelectedRejected);
    //console.log('Data Selected Reject Asset : ', this.tempSelectedRejected2);
  }

  SelectedReject(id, selectnum) {

    var items, items2;
    items = (document.getElementsByName("selectedRej" + selectnum));
    items2 = (document.getElementsByName("selectedApp" + selectnum));
    this.valuecheckApproveAll = false;
    if (items[0].checked == true && items2[0].checked === false) {
      this.setRejectItem(id);
    } else if (items[0].checked == true && items2[0].checked === true) {
      items2[0].checked = false
      this.setRejectItem2(id);
    }
    else {
      //de_bugger
      items2[0].checked = false
      this.setRejectItem3(id);
    }
    //console.log('listRiskTableItem : ', this.listRiskTableItem);
    //console.log('Data Selected Reject Org : ', this.tempSelectedRejected);
    //console.log('Data Selected Reject Asset : ', this.tempSelectedRejected2);
  }

  private setRejectItem(id: any) {
    for (var i = 0; i < this.listRiskTableItem.length; i++) {

      if (this.listRiskTableItem[i].risk_Id === id) {
        this.listRiskTableItem[i].checkRej = true;
      }
    }
  }
  private setRejectItem2(id: any) {
    for (var i = 0; i < this.listRiskTableItem.length; i++) {
      if (this.listRiskTableItem[i].risk_Id === id) {
        this.listRiskTableItem[i].checkRej = true;
        this.listRiskTableItem[i].checkApp = false;
      }
    }
  }
  private setRejectItem3(id: any) {
    for (var i = 0; i < this.listRiskTableItem.length; i++) {
      if (this.listRiskTableItem[i].risk_Id === id) {
        this.listRiskTableItem[i].checkRej = false;
      }
    }
  }

  func_Confirm() {

    const _s = this;
    let checkstatus = true;

    //checkstatus = this.func_Confirm_ProcessitemlistRiskTableItem(_s, checkstatus);

    // if (checkstatus === true) {
    //   let chk2 = true
    //   if (this.tempSelectedApproved.length === 0 && this.tempSelectedApproved2.length === 0 && this.tempSelectedRejected.length === 0 && this.tempSelectedRejected2.length === 0) {
    //     this.alertflow = 'Please select item to Approve or Reject before confirm'
    //     $('#modalAlertdangerApprove').modal('show');

    //   }
    //   else if (chk2 === true) {
    //     if (this.tempSelectedApproved.length !== 0 || this.tempSelectedApproved2.length !== 0) {
    //       this.func_Confirm_ApprovedProcess(_s);
    //     }
    //     if (this.tempSelectedRejected.length !== 0 || this.tempSelectedRejected2.length !== 0) {
    //       this.func_Confirm_RejectedProcess(_s);
    //     }
    //   }
    // } else {
    //   this.alertflow = 'Please fill comment in item Reject'
    //   $('#modalAlertdangerApprove').modal('show');
    // }
    //*------------
    // Check and push in array
    // Check and push in array
    for (var i = 0; i < this.listRiskTableItem.length; i++) {
      // Check Approve
      if (this.listRiskTableItem[i].checkApp === true) {
        var xLikelihood = null;
        var yImpact = null;
        var riskSelect = _s.list.risk.riskitems.find(x => x.risk_Id == this.listRiskTableItem[i].risk_Id)
        if (riskSelect !== undefined) {
          xLikelihood = riskSelect.x_Likelihood;
          yImpact = riskSelect.y_Impact;
        }

        if (this.listRiskTableItem[i].risk_Type === 'Organization') {
          this.tempSelectedApproved.push({
            Risk_Id: this.listRiskTableItem[i].risk_Id,
            Risk_Business_Unit: this.listRiskTableItem[i].risk_BusinessUnit_Code,
            Risk_Status_Workflow: (this.listRiskTableItem[i].risk_Status_Workflow_ReConsole == null) ?
              this.listRiskTableItem[i].risk_Status_Code : this.listRiskTableItem[i].risk_Status_Workflow_ReConsole,
            Risk_Type: this.listRiskTableItem[i].risk_Type,
            Risk_AssignTo: "",
            Comment: this.listRiskTableItem[i].comment,
            Action: (this.listRiskTableItem[i].risk_Status_Workflow_ReConsole == null) ? null : 'Reconsole',
            table_Type: this.listRiskTableItem[i].table_Type,
            Risk_Latest_Approve_Likelihood: xLikelihood,
            Risk_Latest_Approve_Impact: yImpact
          });
        }
        else if (this.listRiskTableItem[i].risk_Type === 'Asset') {
          this.tempSelectedApproved2.push({
            Risk_Id: this.listRiskTableItem[i].risk_Id,
            Risk_Business_Unit: this.listRiskTableItem[i].risk_BusinessUnit_Code,
            // Risk_Status_Workflow :this.listRiskTableItem[i].risk_Status_Code,
            Risk_Status_Workflow: (this.listRiskTableItem[i].risk_Status_Workflow_ReConsole == null) ?
              this.listRiskTableItem[i].risk_Status_Code : this.listRiskTableItem[i].risk_Status_Workflow_ReConsole,
            Risk_Type: this.listRiskTableItem[i].risk_Type,
            Risk_AssignTo: "",
            Comment: this.listRiskTableItem[i].comment,
            Action: (this.listRiskTableItem[i].risk_Status_Workflow_ReConsole == null) ? null : 'Reconsole',
            table_Type: this.listRiskTableItem[i].table_Type,
            Risk_Latest_Approve_Likelihood: xLikelihood,
            Risk_Latest_Approve_Impact: yImpact
          })
        }
        checkstatus = true
      }

      // Check Reject
      if (this.listRiskTableItem[i].checkRej === true) {
        if (this.listRiskTableItem[i].comment !== '') {
          var id = this.listRiskTableItem[i].risk_Id;
          var item;
          if (_s.list.risk.riskitems != null) {
            item = _s.list.risk.riskitems.filter(x => x.risk_Id == id);
          }
          else {
            item = null;
          }

          if (this.listRiskTableItem[i].risk_Type === 'Organization') {

            this.tempSelectedRejected.push({
              Risk_Id: this.listRiskTableItem[i].risk_Id,
              Risk_Business_Unit: this.listRiskTableItem[i].risk_BusinessUnit_Code,
              // Risk_Status_Workflow :this.listRiskTableItem[i].risk_Status_Code,
              Risk_Status_Workflow: (this.listRiskTableItem[i].risk_Status_Workflow_ReConsole == null) ?
                this.listRiskTableItem[i].risk_Status_Code : this.listRiskTableItem[i].risk_Status_Workflow_ReConsole,
              Risk_Type: this.listRiskTableItem[i].risk_Type,
              Risk_AssignTo: "",
              Comment: this.listRiskTableItem[i].comment,
              Action: (this.listRiskTableItem[i].risk_Status_Workflow_ReConsole == null) ? null : 'Reconsole',
              Risk_Previous_Likelihood: (item != null) ? item[0].x_Likelihood : null,
              Risk_Previous_Impact: (item != null) ? item[0].y_Impact : null
            });
          }
          else if (this.listRiskTableItem[i].risk_Type === 'Asset') {
            this.tempSelectedRejected2.push({
              Risk_Id: this.listRiskTableItem[i].risk_Id,
              Risk_Business_Unit: this.listRiskTableItem[i].risk_BusinessUnit_Code,
              // Risk_Status_Workflow :this.listRiskTableItem[i].risk_Status_Code,
              Risk_Status_Workflow: (this.listRiskTableItem[i].risk_Status_Workflow_ReConsole == null) ?
                this.listRiskTableItem[i].risk_Status_Code : this.listRiskTableItem[i].risk_Status_Workflow_ReConsole,
              Risk_Type: this.listRiskTableItem[i].risk_Type,
              Risk_AssignTo: "",
              Comment: this.listRiskTableItem[i].comment,
              Action: (this.listRiskTableItem[i].risk_Status_Workflow_ReConsole == null) ? null : 'Reconsole',
              Risk_Previous_Likelihood: (item != null) ? item[0].x_Likelihood : null,
              Risk_Previous_Impact: (item != null) ? item[0].y_Impact : null
            })
          }
          checkstatus = true
        } else {
          checkstatus = false;
          break;
        }
      }
    }


    //de_bugger
    if (checkstatus === true) {
      var chk2 = true
      if (this.tempSelectedApproved.length === 0 && this.tempSelectedApproved2.length === 0 && this.tempSelectedRejected.length === 0 && this.tempSelectedRejected2.length === 0) {
        this.alertflow = 'Please select item to Approve or Reject before confirm'
        $('#modalAlertdangerApprove').modal('show');
        chk2 = false
      }
      else if (chk2 === true) {
        //---- CONFRIM APPROVE
        if (this.tempSelectedApproved.length !== 0 || this.tempSelectedApproved2.length !== 0) {
          _s.loading = true
          var formdataApprove = {
            SessionEmpID: this.empid,
            SecurityCode: '1234',
            Email: this.Data_SessionStorage[0].emaiL_ID,
            Module: 'APPROVE',
            body: {
              Approve_By: this.empid,
              ApproveTypeOrganization: this.tempSelectedApproved,
              ApproveTypeAsset: this.tempSelectedApproved2
            }
          }
          //console.log('formdataApprove :', JSON.stringify(formdataApprove))
          _s.mserve.RiskApprove(formdataApprove).then((response) => {
            if (response.data.status === true) {
              //console.log('Approved Complete')
              this.tempSelectedApproved = [];
              this.tempSelectedApproved2 = [];
              this.loadRisk();
              // alert('Approve success')
              this.alertflow = 'Confirm success'
              $('#modalAlertsuccess5').modal('show');
              _s.Pageloading = false;
              setTimeout(function () { _s.loading = false }, 500)
            }
            else {
              this.tempSelectedApproved = [];
              this.tempSelectedApproved2 = [];
              // this.loadRisk();
              _s.Pageloading = false;
              setTimeout(function () { _s.loading = false }, 500)

              this.alertflow = response.data.errorMessage
              $('#modalAlertdangerApprove').modal('show');
              //console.log('Approve falied', response)
            }
          });
        }
        //---- CONFRIM REJECT
        if (this.tempSelectedRejected.length !== 0 || this.tempSelectedRejected2.length !== 0) {
          _s.loading = true
          var formdataReject = {
            SessionEmpID: this.empid,
            SecurityCode: '1234',
            Email: this.Data_SessionStorage[0].emaiL_ID,
            Module: 'REJECT',
            body: {
              Reject_By: this.empid,
              RejectTypeOrganization: this.tempSelectedRejected,
              RejectTypeAsset: this.tempSelectedRejected2
            }
          }
          //console.log('formdataReject :', JSON.stringify(formdataReject))
          _s.mserve.RiskReject(formdataReject).then((response) => {
            if (response.data.status === true) {
              //console.log('Rejected Complete');
              this.tempSelectedRejected = [];
              this.tempSelectedRejected2 = [];
              this.loadRisk();
              this.alertflow = 'Confirm success'
              $('#modalAlertsuccess5').modal('show');
              _s.Pageloading = false;
              setTimeout(function () { _s.loading = false }, 500)
            }
            else {
              this.tempSelectedRejected = [];
              this.tempSelectedRejected2 = [];
              // this.loadRisk();
              _s.Pageloading = false;
              setTimeout(function () { _s.loading = false }, 500)

              this.alertflow = response.data.errorMessage
              $('#modalAlertdangerApprove').modal('show');
              //console.log('Reject falied', response)
            }
          });
        }
      }
    } else {
      this.alertflow = 'Please fill comment in item Reject'
      $('#modalAlertdangerApprove').modal('show');
    }

  }
  private func_Confirm_ProcessitemlistRiskTableItem(_s: this, checkstatus: boolean) {

    let initialCheckStatus = checkstatus;

    for (let itemlistRiskTableItem of this.listRiskTableItem) {
      if (itemlistRiskTableItem.checkApp) {
        this.func_Confirm_CheckApp(itemlistRiskTableItem);
      }
      if (itemlistRiskTableItem.checkRej) {
        if (itemlistRiskTableItem.comment !== '') {
          this.func_Confirm_CheckRejNoComment(itemlistRiskTableItem, _s);
        } else {
          initialCheckStatus = false;
          break;
        }
      }
    }
    return initialCheckStatus;
  }

  private func_Confirm_RejectedProcess(_s: this) {

    _s.loading = true;
    let formdataReject = {
      SessionEmpID: this.empid,
      SecurityCode: '1234',
      Email: this.Data_SessionStorage[0].emaiL_ID,
      Module: 'REJECT',
      body: {
        Reject_By: this.empid,
        RejectTypeOrganization: this.tempSelectedRejected,
        RejectTypeAsset: this.tempSelectedRejected2
      }
    };
    _s.mserve.RiskReject(formdataReject).then((response) => {
      const status = response.data.status;

      this.tempSelectedRejected = [];
      this.tempSelectedRejected2 = [];
      _s.Pageloading = false;
      setTimeout(function () { _s.loading = false; }, 500);

      if (status === true) {
        this.loadRisk();
        this.alertflow = 'Confirm success';
        $('#modalAlertsuccess5').modal('show');
      } else {
        this.alertflow = response.data.errorMessage;
        $('#modalAlertdangerApprove').modal('show');
      }

    }).catch((e) => {
      if (e.response) {
        this.authserviceToken.CheckTokenExpire(e.response.status);
      } else {
        //console.log(e);
      }
    });
  }

  private func_Confirm_ApprovedProcess(_s: this) {
    _s.loading = true;
    let formdataApprove = {
      SessionEmpID: this.empid,
      SecurityCode: '1234',
      Email: this.Data_SessionStorage[0].emaiL_ID,
      Module: 'APPROVE',
      body: {
        Approve_By: this.empid,
        ApproveTypeOrganization: this.tempSelectedApproved,
        ApproveTypeAsset: this.tempSelectedApproved2
      }
    };
    _s.mserve.RiskApprove(formdataApprove).then((response) => {
      const status = response.data.status;

      this.tempSelectedApproved = [];
      this.tempSelectedApproved2 = [];
      _s.Pageloading = false;
      setTimeout(function () { _s.loading = false; }, 500);

      if (status === true) {
        this.loadRisk();
        this.alertflow = 'Confirm success';
        $('#modalAlertsuccess5').modal('show');
      } else {
        this.alertflow = response.data.errorMessage;
        $('#modalAlertdangerApprove').modal('show');
      }


    }).catch((e) => {
      if (e.response) {
        this.authserviceToken.CheckTokenExpire(e.response.status);
      } else {
        //console.log(e);
      }
    });
  }

  private func_Confirm_CheckRejNoComment(itemlistRiskTableItem: any, _s: this) {
    let id = itemlistRiskTableItem.risk_Id;
    let item;
    if (_s.list.risk.riskitems != null) {
      item = _s.list.risk.riskitems.filter(x => x.risk_Id == id);
    }
    else {
      item = null;
    }
    const tempSelectedObj2 = {
      Risk_Id: itemlistRiskTableItem.risk_Id,
      Risk_Business_Unit: itemlistRiskTableItem.risk_BusinessUnit_Code,
      Risk_Status_Workflow: (itemlistRiskTableItem.risk_Status_Workflow_ReConsole == null) ?
        itemlistRiskTableItem.risk_Status_Code : itemlistRiskTableItem.risk_Status_Workflow_ReConsole,
      Risk_Type: itemlistRiskTableItem.risk_Type,
      Risk_AssignTo: "",
      Comment: itemlistRiskTableItem.comment,
      Action: (itemlistRiskTableItem.risk_Status_Workflow_ReConsole == null) ? null : 'Reconsole',
      Risk_Previous_Likelihood: (item != null) ? item[0].x_Likelihood : null,
      Risk_Previous_Impact: (item != null) ? item[0].y_Impact : null
    };
    if (itemlistRiskTableItem.risk_Type === 'Organization') {
      this.tempSelectedRejected.push(tempSelectedObj2);
    } else if (itemlistRiskTableItem.risk_Type === 'Asset') {
      this.tempSelectedRejected2.push(tempSelectedObj2);
    }
  }

  private func_Confirm_CheckApp(itemlistRiskTableItem: any) {

    const tempSelectedObj = {
      Risk_Id: itemlistRiskTableItem.risk_Id,
      Risk_Business_Unit: itemlistRiskTableItem.risk_BusinessUnit_Code,
      Risk_Status_Workflow: (itemlistRiskTableItem.risk_Status_Workflow_ReConsole == null) ?
        itemlistRiskTableItem.risk_Status_Code : itemlistRiskTableItem.risk_Status_Workflow_ReConsole,
      Risk_Type: itemlistRiskTableItem.risk_Type,
      Risk_AssignTo: "",
      Comment: itemlistRiskTableItem.comment,
      Action: (itemlistRiskTableItem.risk_Status_Workflow_ReConsole == null) ? null : 'Reconsole',
      table_Type: itemlistRiskTableItem.table_Type
    };

    if (itemlistRiskTableItem.risk_Type === 'Organization') {
      this.tempSelectedApproved.push(tempSelectedObj);
    } else if (itemlistRiskTableItem.risk_Type === 'Asset') {
      this.tempSelectedApproved2.push(tempSelectedObj);
    }
  }

  func_Clear() {
    for (let i10 = 0; i10 < this.listRiskTableItem.length; i10++) {
      this.listRiskTableItem[i10].comment = ''
      this.Register_Data.Regis_Comment[i10].comment = ''
      let items;
      items = (document.getElementsByName("selectedApp" + (i10 + 1)));
      items[0].checked = false;
      this.listRiskTableItem[i10].checkApp = false;


      let items2;
      items2 = (document.getElementsByName("selectedRej" + (i10 + 1)));
      items2[0].checked = false;
      this.listRiskTableItem[i10].checkRej = false;
    }

    this.valuecheckApproveAll = false;
    this.valuecheckRejectAll = false;

    this.tempSelectedApproved = [];
    this.tempSelectedApproved2 = [];
    this.tempSelectedRejected = [];
    this.tempSelectedRejected2 = [];

  }
  func_addComment(id) {

    for (let i11 = 0; i11 < this.listRiskTableItem.length; i11++) {
      if (this.listRiskTableItem[i11].risk_Id === id) {
        this.listRiskTableItem[i11].comment = this.Register_Data.Regis_Comment[i11].comment
      }
    }
  }

  SelectAllRemove(value) {
    if (value) {
      this.disableSelectAll = true;
    } else {
      this.disableSelectAll = false;
    }
    this.tempSeletedRemove = [];
    const _s = this;
    for (var i = 0; i < this.listRiskTableItem.length; i++) {
      const data = this.listRiskTableItem[i];
      if (this.chkroleuser != 'Owner' && data.ChkRework == true && data.risk_ViewMode !== 'Y') {
        const data = this.listRiskTableItem[i];
        if (this.chkroleuser != 'Owner' && data.ChkRework == true && data.risk_ViewMode !== 'Y') {
          let chkrework = null
          let temp_dI_Console = this.ReworkSplitConsole(data.dI_Console);
          let temp_fG_Console = this.ReworkSplitConsole(data.fG_Console);
          let temp_bU_Console = this.ReworkSplitConsole(data.bU_Console);

          if (_s.tempLevel == 'Department' && data.bU_Flag == 'Y') {
            for (var s = 0; s < temp_bU_Console.length; s++) {
              if (_s.empid == temp_bU_Console[s]) {
                chkrework = 'mode2'
                break;
              }
            }
          }
          else if (_s.tempLevel == 'Division' && data.dI_Flag == 'Y') {
            for (var s = 0; s < temp_dI_Console.length; s++) {
              if (_s.empid == temp_dI_Console[s]) {
                chkrework = 'mode2'
                break;
              }
            }
          }
          else if ((_s.tempLevel == 'Funcgroup' || _s.tempLevel == 'Group') && data.fG_Flag == 'Y') {
            for (var s = 0; s < temp_fG_Console.length; s++) {
              if (_s.empid == temp_fG_Console[s]) {
                chkrework = 'mode2'
                break;
              }
            }
          }
          else {
            chkrework = 'mode1'
          }

          if ((chkrework !== 'mode1') && (_s.checkRemoveCheckboxIsShow(data))) {
            data.ChkRemoveAll = value;
          }
        }
      }
    }

    if (value === true) {
      this.RiskName = 'Remove all';
      this.RiskFullName = 'Remove all';
      this.mode = 'multiple'
      this.ConsolecommentLst = '';
      this.tempSelectedRemoveAll = [];

      for (var i = 0; i < this.listRiskTableItem.length; i++) {
        const data = this.listRiskTableItem[i];
        //console.log(data);

        // if (data.ChkRemoveAll) {
          if ((data.risk_Type == 'Organization' || data.risk_Type == 'Asset' || data.risk_Type == 'Corporate') && (this.checkRemoveCheckboxIsShow(data))) {
              this.tempSelectedRemoveAll.push({
              Risk_Id: data.risk_Id,
              Risk_Business_Unit: data.risk_BusinessUnit_Code,
              Risk_AssignTo: '',
              Risk_Type: data.risk_Type,
              Risk_Status_Workflow: data.risk_Status_Code,
              Risk_Submit_Action: "Reconsole",
              Risk_Submit_Reason: '',
              Risk_Name: data.risk_Name,
              hadRemoveCheckBox: true
            });
            const riskID = data.risk_Co_Id !== null ? data.risk_Co_Id : data.risk_Staff_Id;
            this.tempRiskID.push({
            Riskrunning: riskID
            });
            data.hadRemoveCheckBox = true;
          // }
        }
      }
      this.isSubmittedModalCommentCRList = false;
      $('#modalCommentCRList').modal('show');
    } else {
      //
      this.tempSeletedRemove = [];
      this.tempSelectedRemoveAll = [];
    }
  }

  chkCanSelect(item) {
    if(!((item.chkbox == false && item.risk_Status_Code != "0" && item.risk_Status_Code != "23") 
          || ( this.tempselectbu != item.risk_BusinessUnit_Name && item.risk_Status_Code == 0 )                               
          || ( item.chkbox == true && item.risk_Status_Code === "10") 
          || ( item.chkbox == true && item.risk_Status_Code === "13") 
          || ( item.chkbox == true && item.risk_Status_Code === "4")  
          || ( item.chkbox == true && item.risk_Status_Code === "7")))
      {
        return true;
      }
      else
      {
        return false;
      }

  }

  SelectAll(value) {
    if (value) {
      this.disableRemove = true;
    } else {
      this.disableRemove = false;
    }
   
    for (var i = 0; i < this.listRiskTableItem.length; i++) {
      // var isCanSelect = this.chkCanSelect(this.listRiskTableItem[i]);
      // if (
      //   ((this.Data_SessionStorage[0].employeE_ID == this.listRiskTableItem[i].risk_Register_By && (this.listRiskTableItem[i].risk_Status_Code == 0)) ||
      //   (this.Data_SessionStorage[0].employeE_ID == this.listRiskTableItem[i].risk_Register_By && this.listRiskTableItem[i].risk_Status_Code == 23) || //&& this.listRiskTableItem[i].table_Type != "Transection"
      //   ((this.listRiskTableItem[i].chkbox == true && this.listRiskTableItem[i].risk_Status_Code != "4" && this.listRiskTableItem[i].risk_Status_Code != "7"))) // && this.listRiskTableItem[i].risk_Status_Code != "23" //&& this.listRiskTableItem[i].risk_Status_Code != "23"
      //   || isCanSelect
      // )
      if (this.chkCanSelect(this.listRiskTableItem[i]))
       {
        var items;
        items = (document.getElementsByName("selectedAll" + (i + 1)));
        if (value == true && items[0].checked == false) {
          items[0].checked = true;
          if (this.listRiskTableItem[i].risk_Type === 'Organization' && this.listRiskTableItem[i].table_Type === "Transection") {
            this.tempSeleted.push({
              Risk_Id: this.listRiskTableItem[i].risk_Id,
              Risk_Business_Unit: this.listRiskTableItem[i].risk_BusinessUnit_Code,
              Risk_AssignTo: '',
              Risk_Type: this.listRiskTableItem[i].risk_Type,
              Risk_Status_Workflow: this.listRiskTableItem[i].risk_Status_Code
            });

            if (this.listRiskTableItem[i].risk_Co_Id !== null) {
              this.tempRiskID.push({
                Riskrunning: this.listRiskTableItem[i].risk_Co_Id
              })
            } else {
              this.tempRiskID.push({
                Riskrunning: this.listRiskTableItem[i].risk_Staff_Id
              })
            }
          }
          else if (this.listRiskTableItem[i].risk_Type === 'Asset' && this.listRiskTableItem[i].table_Type === "Transection") {
            this.tempSeleted2.push({
              Risk_Id: this.listRiskTableItem[i].risk_Id,
              Risk_Business_Unit: this.listRiskTableItem[i].risk_BusinessUnit_Code,
              Risk_AssignTo: '',
              Risk_Type: this.listRiskTableItem[i].risk_Type,
              Risk_Status_Workflow: this.listRiskTableItem[i].risk_Status_Code
            });

            if (this.listRiskTableItem[i].risk_Co_Id !== null) {
              this.tempRiskID2.push({
                Riskrunning: this.listRiskTableItem[i].risk_Co_Id
              })
            } else {
              this.tempRiskID2.push({
                Riskrunning: this.listRiskTableItem[i].risk_Staff_Id
              })
            }
          }
          else if (this.listRiskTableItem[i].risk_Type === 'Corporate' && this.listRiskTableItem[i].table_Type === "Transection") {
            this.tempSeleted3.push({
              Risk_Id: this.listRiskTableItem[i].risk_Id,
              Risk_Business_Unit: this.listRiskTableItem[i].risk_BusinessUnit_Code,
              Risk_AssignTo: '',
              Risk_Type: this.listRiskTableItem[i].risk_Type,
              Risk_Status_Workflow: this.listRiskTableItem[i].risk_Status_Code
            });

            if (this.listRiskTableItem[i].risk_Co_Id !== null) {
              this.tempRiskID3.push({
                Riskrunning: this.listRiskTableItem[i].risk_Co_Id
              })
            } else {
              this.tempRiskID3.push({
                Riskrunning: this.listRiskTableItem[i].risk_Staff_Id
              })
            }
          }
          else if (this.listRiskTableItem[i].risk_Type === 'Organization' && this.listRiskTableItem[i].table_Type === "Staff") {
            this.tempSeleted_staff.push({
              Risk_Id: this.listRiskTableItem[i].risk_Id,
              Risk_Business_Unit: this.listRiskTableItem[i].risk_BusinessUnit_Code,
              Risk_AssignTo: '',
              Risk_Type: this.listRiskTableItem[i].risk_Type,
              Risk_Status_Workflow: this.listRiskTableItem[i].risk_Status_Code
            });

            if (this.listRiskTableItem[i].risk_Co_Id !== null) {
              this.tempRiskID_staff.push({
                Riskrunning: this.listRiskTableItem[i].risk_Co_Id
              })
            } else {
              this.tempRiskID_staff.push({
                Riskrunning: this.listRiskTableItem[i].risk_Staff_Id
              })
            }

          }
          else if (this.listRiskTableItem[i].risk_Type === 'Asset' && this.listRiskTableItem[i].table_Type === "Staff") {
            this.tempSeleted2_staff.push({
              Risk_Id: this.listRiskTableItem[i].risk_Id,
              Risk_Business_Unit: this.listRiskTableItem[i].risk_BusinessUnit_Code,
              Risk_AssignTo: '',
              Risk_Type: this.listRiskTableItem[i].risk_Type,
              Risk_Status_Workflow: this.listRiskTableItem[i].risk_Status_Code
            });

            if (this.listRiskTableItem[i].risk_Co_Id !== null) {
              this.tempRiskID2_staff.push({
                Riskrunning: this.listRiskTableItem[i].risk_Co_Id
              })
            } else {
              this.tempRiskID2_staff.push({
                Riskrunning: this.listRiskTableItem[i].risk_Staff_Id
              })
            }
          }
          else if (this.listRiskTableItem[i].risk_Type === 'Corporate' && this.listRiskTableItem[i].table_Type === "Staff") {
            this.tempSeleted3_staff.push({
              Risk_Id: this.listRiskTableItem[i].risk_Id,
              Risk_Business_Unit: this.listRiskTableItem[i].risk_BusinessUnit_Code,
              Risk_AssignTo: '',
              Risk_Type: this.listRiskTableItem[i].risk_Type,
              Risk_Status_Workflow: this.listRiskTableItem[i].risk_Status_Code
            });

            if (this.listRiskTableItem[i].risk_Co_Id !== null) {
              this.tempRiskID3_staff.push({
                Riskrunning: this.listRiskTableItem[i].risk_Co_Id
              })
            } else {
              this.tempRiskID3_staff.push({
                Riskrunning: this.listRiskTableItem[i].risk_Staff_Id
              })
            }
          }
        }
        else if (value == false && items[0].checked == true) {
          items[0].checked = false;
          this.tempSeleted = [];
          this.tempSeleted2 = [];
          this.tempSeleted3 = [];
          this.tempSeleted_staff = [];
          this.tempSeleted2_staff = [];
          this.tempSeleted3_staff = [];
          this.tempRiskID = [];
          this.tempRiskID2 = [];
          this.tempRiskID3 = [];
          this.tempRiskID_staff = [];
          this.tempRiskID2_staff = [];
          this.tempRiskID3_staff = [];
        }
        else {
          var items;
          items = (document.getElementsByName("selectedAll" + (i + 1)));
          if (value == true && items[0].checked == false) {
            items[0].checked = true;
            if (this.listRiskTableItem[i].risk_Type === 'Organization' && this.listRiskTableItem[i].table_Type === "Transection") {
              this.tempSeleted.push({
                Risk_Id: this.listRiskTableItem[i].risk_Id,
                Risk_Business_Unit: this.listRiskTableItem[i].risk_BusinessUnit_Code,
                Risk_AssignTo: '',
                Risk_Type: this.listRiskTableItem[i].risk_Type,
                Risk_Status_Workflow: this.listRiskTableItem[i].risk_Status_Code
              });

              if (this.listRiskTableItem[i].risk_Co_Id !== null) {
                this.tempRiskID.push({
                  Riskrunning: this.listRiskTableItem[i].risk_Co_Id
                })
              } else {
                this.tempRiskID.push({
                  Riskrunning: this.listRiskTableItem[i].risk_Staff_Id
                })
              }

            }
            else if (this.listRiskTableItem[i].risk_Type === 'Asset' && this.listRiskTableItem[i].table_Type === "Transection") {
              this.tempSeleted2.push({
                Risk_Id: this.listRiskTableItem[i].risk_Id,
                Risk_Business_Unit: this.listRiskTableItem[i].risk_BusinessUnit_Code,
                Risk_AssignTo: '',
                Risk_Type: this.listRiskTableItem[i].risk_Type,
                Risk_Status_Workflow: this.listRiskTableItem[i].risk_Status_Code
              });

              if (this.listRiskTableItem[i].risk_Co_Id !== null) {
                this.tempRiskID2.push({
                  Riskrunning: this.listRiskTableItem[i].risk_Co_Id
                })
              } else {
                this.tempRiskID2.push({
                  Riskrunning: this.listRiskTableItem[i].risk_Staff_Id
                })
              }
            }
            else if (this.listRiskTableItem[i].risk_Type === 'Corporate' && this.listRiskTableItem[i].table_Type === "Transection") {
              this.tempSeleted3.push({
                Risk_Id: this.listRiskTableItem[i].risk_Id,
                Risk_Business_Unit: this.listRiskTableItem[i].risk_BusinessUnit_Code,
                Risk_AssignTo: '',
                Risk_Type: this.listRiskTableItem[i].risk_Type,
                Risk_Status_Workflow: this.listRiskTableItem[i].risk_Status_Code
              });

              if (this.listRiskTableItem[i].risk_Co_Id !== null) {
                this.tempRiskID3.push({
                  Riskrunning: this.listRiskTableItem[i].risk_Co_Id
                })
              } else {
                this.tempRiskID3.push({
                  Riskrunning: this.listRiskTableItem[i].risk_Staff_Id
                })
              }
            }
            else if (this.listRiskTableItem[i].risk_Type === 'Organization' && this.listRiskTableItem[i].table_Type === "Staff") {
              this.tempSeleted_staff.push({
                Risk_Id: this.listRiskTableItem[i].risk_Id,
                Risk_Business_Unit: this.listRiskTableItem[i].risk_BusinessUnit_Code,
                Risk_AssignTo: '',
                Risk_Type: this.listRiskTableItem[i].risk_Type,
                Risk_Status_Workflow: this.listRiskTableItem[i].risk_Status_Code
              });

              if (this.listRiskTableItem[i].risk_Co_Id !== null) {
                this.tempRiskID_staff.push({
                  Riskrunning: this.listRiskTableItem[i].risk_Co_Id
                })
              } else {
                this.tempRiskID_staff.push({
                  Riskrunning: this.listRiskTableItem[i].risk_Staff_Id
                })
              }

            }
            else if (this.listRiskTableItem[i].risk_Type === 'Asset' && this.listRiskTableItem[i].table_Type === "Staff") {
              this.tempSeleted2_staff.push({
                Risk_Id: this.listRiskTableItem[i].risk_Id,
                Risk_Business_Unit: this.listRiskTableItem[i].risk_BusinessUnit_Code,
                Risk_AssignTo: '',
                Risk_Type: this.listRiskTableItem[i].risk_Type,
                Risk_Status_Workflow: this.listRiskTableItem[i].risk_Status_Code
              });

              if (this.listRiskTableItem[i].risk_Co_Id !== null) {
                this.tempRiskID2_staff.push({
                  Riskrunning: this.listRiskTableItem[i].risk_Co_Id
                })
              } else {
                this.tempRiskID2_staff.push({
                  Riskrunning: this.listRiskTableItem[i].risk_Staff_Id
                })
              }

            }
            else if (this.listRiskTableItem[i].risk_Type === 'Corporate' && this.listRiskTableItem[i].table_Type === "Staff") {
              this.tempSeleted3_staff.push({
                Risk_Id: this.listRiskTableItem[i].risk_Id,
                Risk_Business_Unit: this.listRiskTableItem[i].risk_BusinessUnit_Code,
                Risk_AssignTo: '',
                Risk_Type: this.listRiskTableItem[i].risk_Type,
                Risk_Status_Workflow: this.listRiskTableItem[i].risk_Status_Code
              });

              if (this.listRiskTableItem[i].risk_Co_Id !== null) {
                this.tempRiskID3_staff.push({
                  Riskrunning: this.listRiskTableItem[i].risk_Co_Id
                })
              } else {
                this.tempRiskID3_staff.push({
                  Riskrunning: this.listRiskTableItem[i].risk_Staff_Id
                })
              }
            }
          }
          else if (value == false && items[0].checked == true) {
            items[0].checked = false;
            this.tempSeleted = [];
            this.tempSeleted2 = [];
            this.tempSeleted3 = [];
            this.tempSeleted_staff = [];
            this.tempSeleted2_staff = [];
            this.tempSeleted3_staff = [];
            this.tempRiskID = [];
            this.tempRiskID2 = [];
            this.tempRiskID3 = [];
            this.tempRiskID_staff = [];
            this.tempRiskID2_staff = [];
            this.tempRiskID3_staff = [];
          }
        }
      }
    }

    //when checkbox selectAll is checked this value is true.
    if (value) {
      this.listSelect = [];
      for (var i = 0; i < this.listRiskTableItem.length; i++) {
        let checkBox;
        checkBox = (document.getElementsByName("selectedAll" + (i + 1)));
        if (checkBox[0].checked == true) {
          let dataRisk = {
            Risk_Id: this.listRiskTableItem[i].risk_Id,
            Risk_Business_Unit: this.listRiskTableItem[i].risk_BusinessUnit_Code,
            Risk_AssignTo: '',
            Risk_Type: this.listRiskTableItem[i].risk_Type,
            Risk_Status_Workflow: this.listRiskTableItem[i].risk_Status_Code
          }
          this.listSelect.push(dataRisk);
        }
      }
    } else {
      //case when checkbox selectAll is uncheck.
      this.listSelect = [];
    }

  }

  Selected(id, selectnum) {
    let items2;
    items2 = (document.getElementsByName("selectedAll" + selectnum));
    if (items2[0].checked) {
      for (let itemlistRiskTableItem of this.listRiskTableItem) {
        let datariska1 = {
          Risk_Id: itemlistRiskTableItem.risk_Id,
          Risk_Business_Unit: itemlistRiskTableItem.risk_BusinessUnit_Code,
          Risk_AssignTo: '',
          Risk_Type: itemlistRiskTableItem.risk_Type,
          Risk_Status_Workflow: itemlistRiskTableItem.risk_Status_Code
        }

        let risk_Id = itemlistRiskTableItem.risk_Id;
        let risk_Type = itemlistRiskTableItem.risk_Type;
        let table_Type = itemlistRiskTableItem.table_Type;
        let risk_Co_Id = itemlistRiskTableItem.risk_Co_Id;
        let risk_Staff_Id = itemlistRiskTableItem.risk_Staff_Id;

        if (table_Type === "Transection") {
          this.Selected_II_Transection(risk_Id, id, risk_Type, datariska1, risk_Co_Id, risk_Staff_Id);
        }
        else if (itemlistRiskTableItem.table_Type === "Staff") {
          this.Selected_II_Staff(itemlistRiskTableItem, id, datariska1, risk_Co_Id, risk_Staff_Id);
        }

        if (datariska1.Risk_Id === id) {
          this.listSelect.push(datariska1);
        }

      }
      
      this.disableRemove = true;

    } else {
      this.removeFromArrayByRiskId(this.tempSeleted, id);
      this.removeFromArrayByRiskId(this.tempRiskID, id);
      this.removeFromArrayByRiskId(this.tempSeleted2, id);
      this.removeFromArrayByRiskId(this.tempRiskID2, id);
      this.removeFromArrayByRiskId(this.tempSeleted3, id);
      this.removeFromArrayByRiskId(this.tempRiskID3, id);
      this.removeFromArrayByRiskId(this.tempSeleted_staff, id);
      this.removeFromArrayByRiskId(this.tempRiskID_staff, id);
      this.removeFromArrayByRiskId(this.tempSeleted2_staff, id);
      this.removeFromArrayByRiskId(this.tempRiskID2_staff, id);
      this.removeFromArrayByRiskId(this.tempSeleted3_staff, id);
      this.removeFromArrayByRiskId(this.tempRiskID3_staff, id);

      this.listSelect = this.listSelect.filter(x => x.Risk_Id !== id);
      if (this.listSelect.length === 0) {
        let checkBoxSelectAll
        checkBoxSelectAll = (document.getElementsByName("selectAll"))
        checkBoxSelectAll[0].checked = false;
        this.disableRemove = false;
      }
    }
  }

  private Selected_II_Staff(itemlistRiskTableItem: any, id: any, datariska1: { Risk_Id: any; Risk_Business_Unit: any; Risk_AssignTo: string; Risk_Type: any; Risk_Status_Workflow: any; }, risk_Co_Id: any, risk_Staff_Id: any) {
    if (itemlistRiskTableItem.risk_Id === id && itemlistRiskTableItem.risk_Type === 'Organization') {
      this.Selected_III_Staff_Organization(datariska1, risk_Co_Id, risk_Staff_Id);
    } else if (itemlistRiskTableItem.risk_Id === id && itemlistRiskTableItem.risk_Type === 'Asset') {
      this.Selected_III_Staff_Asset(datariska1, risk_Co_Id, risk_Staff_Id);
    } else if (itemlistRiskTableItem.risk_Id === id && itemlistRiskTableItem.risk_Type === 'Corporate') {
      this.Selected_III_Staff_Corporate(datariska1, risk_Co_Id, risk_Staff_Id);
    }
  }

  private Selected_III_Staff_Corporate(datariska1: { Risk_Id: any; Risk_Business_Unit: any; Risk_AssignTo: string; Risk_Type: any; Risk_Status_Workflow: any; }, risk_Co_Id: any, risk_Staff_Id: any) {
    this.tempSeleted3_staff.push(datariska1);
    this.tempRiskID3_staff.push({
      Riskrunning: risk_Co_Id !== null ? risk_Co_Id : risk_Staff_Id
    });
  }

  private Selected_III_Staff_Asset(datariska1: { Risk_Id: any; Risk_Business_Unit: any; Risk_AssignTo: string; Risk_Type: any; Risk_Status_Workflow: any; }, risk_Co_Id: any, risk_Staff_Id: any) {
    this.tempSeleted2_staff.push(datariska1);
    this.tempRiskID2_staff.push({
      Riskrunning: risk_Co_Id !== null ? risk_Co_Id : risk_Staff_Id
    });
  }

  private Selected_III_Staff_Organization(datariska1: { Risk_Id: any; Risk_Business_Unit: any; Risk_AssignTo: string; Risk_Type: any; Risk_Status_Workflow: any; }, risk_Co_Id: any, risk_Staff_Id: any) {
    this.tempSeleted_staff.push(datariska1);
    this.tempRiskID_staff.push({
      Riskrunning: risk_Co_Id !== null ? risk_Co_Id : risk_Staff_Id
    });
  }

  private Selected_II_Transection(risk_Id: any, id: any, risk_Type: any, datariska1: { Risk_Id: any; Risk_Business_Unit: any; Risk_AssignTo: string; Risk_Type: any; Risk_Status_Workflow: any; }, risk_Co_Id: any, risk_Staff_Id: any) {
    if (risk_Id === id && risk_Type === 'Organization') {
      this.Selected_III_Transection_Organization(datariska1, risk_Co_Id, risk_Staff_Id);
    } else if (risk_Id === id && risk_Type === 'Asset') {
      this.Selected_III_Transection_Asset(datariska1, risk_Co_Id, risk_Staff_Id);
    } else if (risk_Id === id && risk_Type === 'Corporate') {
      this.Selected_III_Transection_Corporate(datariska1, risk_Co_Id, risk_Staff_Id);
    }
  }

  private Selected_III_Transection_Corporate(datariska1: { Risk_Id: any; Risk_Business_Unit: any; Risk_AssignTo: string; Risk_Type: any; Risk_Status_Workflow: any; }, risk_Co_Id: any, risk_Staff_Id: any) {
    this.tempSeleted3.push(datariska1);
    this.tempRiskID3.push({
      Riskrunning: risk_Co_Id !== null ? risk_Co_Id : risk_Staff_Id
    });
  }

  private Selected_III_Transection_Asset(datariska1: { Risk_Id: any; Risk_Business_Unit: any; Risk_AssignTo: string; Risk_Type: any; Risk_Status_Workflow: any; }, risk_Co_Id: any, risk_Staff_Id: any) {
    this.tempSeleted2.push(datariska1);
    this.tempRiskID2.push({
      Riskrunning: risk_Co_Id !== null ? risk_Co_Id : risk_Staff_Id
    });
  }

  private Selected_III_Transection_Organization(datariska1: { Risk_Id: any; Risk_Business_Unit: any; Risk_AssignTo: string; Risk_Type: any; Risk_Status_Workflow: any; }, risk_Co_Id: any, risk_Staff_Id: any) {
    this.tempSeleted.push(datariska1);
    this.tempRiskID.push({
      Riskrunning: risk_Co_Id !== null ? risk_Co_Id : risk_Staff_Id
    });
  }

  removeFromArrayByRiskId(array, id) {
    const index = array.findIndex(item => item.Risk_Id === id);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  func_Clear_MyRisk() {

    for (let i = 0; i < this.listRiskTableItem.length; i++) {
      let items3;
      items3 = (document.getElementsByName("selectedAll" + (i + 1)));
      items3[0].checked = false;

      this.listRiskTableItem[i].ChkRemoveAll = false;
    }
    this.valuecheckSelectAll = false;
    this.tempSeleted = [];
    this.tempSeleted2 = [];
    this.tempSeleted3 = [];
    this.tempSeleted_staff = [];
    this.tempSeleted2_staff = [];
    this.tempSeleted3_staff = [];
    this.tempRiskID = [];
    this.tempRiskID2 = [];
    this.tempRiskID3 = [];
    this.tempRiskID_staff = [];
    this.tempRiskID2_staff = [];
    this.tempRiskID3_staff = [];

    this.valuecheckRemoveAll = false;
    this.tempSeletedRemove = [];

    this.listSelect = [];
    this.tempSelectedRemoveAll = [];
    this.disableRemove = false;
    this.disableSelectAll = false;
  }
  func_ConfirmMyRiks() {

    const _s = this;
    let riskid, role
    this.MYRISK1 = [];
    this.MYRISK2 = [];
    this.MYRISK3 = [];

    this.MYRISK1_staff = [];
    this.MYRISK2_staff = [];
    this.MYRISK3_staff = [];

    if (this.tempSeleted.length === 0 && this.tempSeleted2.length === 0 && this.tempSeleted3.length === 0 &&
      this.tempSeleted_staff.length === 0 && this.tempSeleted2_staff.length === 0 && this.tempSeleted3_staff.length === 0) {
      this.alertflow = 'Please select item to confirm'
      $('#modalAlertdangerApprove').modal('show');
    }
    else {

      if (sessionStorage.Role === 'Co' || sessionStorage.Role === 'ERM') {

        // this.func_ConfirmnMyRiskCOandERM(riskid, role);

        if (this.tempSeleted.length !== 0) {
          for (var i = 0; i < this.tempSeleted.length; i++) {
            riskid = this.tempSeleted[i].Risk_Id
            role = 'Co'
            this.func_GETDATA(riskid, role, '1', this.tempSeleted[i], this.tempRiskID[i])
          }
        }
        if (this.tempSeleted2.length !== 0) {
          for (var i = 0; i < this.tempSeleted2.length; i++) {
            riskid = this.tempSeleted2[i].Risk_Id
            role = 'Co'
            this.func_GETDATA(riskid, role, '2', this.tempSeleted2[i], this.tempRiskID2[i])
          }
        }
        if (this.tempSeleted3.length !== 0) {
          for (var i = 0; i < this.tempSeleted3.length; i++) {
            riskid = this.tempSeleted3[i].Risk_Id
            role = 'Co'
            this.func_GETDATA(riskid, role, '3', this.tempSeleted3[i], this.tempRiskID3[i])
          }
        }
        if (this.tempSeleted.length === 0 && this.tempSeleted2.length === 0 && this.tempSeleted3.length === 0) {

          this.func_ConfirmMyRiksCheckComplete('staff')
        }

      } else {
        this.func_ConfirmMyRiksCheckComplete('staff')
      }
    }
  }

  func_ConfirmRemoveRisks() {
    $('#modalAllRemoveComment').modal('show');
  }

  private func_ConfirmnMyRiskCOandERM(riskid: any, role: any) {
    if (this.tempSeleted.length !== 0) {

      for (let i19 = 0; i19 < this.tempSeleted.length; i19++) {
        riskid = this.tempSeleted[i19].Risk_Id;
        role = 'Co';
        this.func_GETDATA(riskid, role, '1', this.tempSeleted[i19], this.tempRiskID[i19]);
      }
    }
    if (this.tempSeleted2.length !== 0) {
      for (let i20 = 0; i20 < this.tempSeleted2.length; i20++) {
        riskid = this.tempSeleted2[i20].Risk_Id;
        role = 'Co';
        this.func_GETDATA(riskid, role, '2', this.tempSeleted2[i20], this.tempRiskID2[i20]);
      }
    }
    if (this.tempSeleted3.length !== 0) {
      for (let i21 = 0; i21 < this.tempSeleted3.length; i21++) {
        riskid = this.tempSeleted3[i21].Risk_Id;
        role = 'Co';
        this.func_GETDATA(riskid, role, '3', this.tempSeleted3[i21], this.tempRiskID3[i21]);
      }
    }
    if (this.tempSeleted.length === 0 && this.tempSeleted2.length === 0 && this.tempSeleted3.length === 0) {
      this.func_ConfirmMyRiksCheckComplete('staff');
    }
    return { riskid, role };
  }

  func_ConfirmMyRiksCheckComplete(chkstatus) {
    const _s = this;
    var statusConfirm = true;

    if (chkstatus === 'co') {
      if (this.MYRISK1.length !== this.tempSeleted.length) {
        statusConfirm = false;
      } else if (this.MYRISK2.length !== this.tempSeleted2.length) {
        statusConfirm = false;
      } else if (this.MYRISK3.length !== this.tempSeleted3.length) {
        statusConfirm = false;
      }

    }


    if (statusConfirm === true) {
      var temprole = ''
      _s.Pageloading = true;

      // SUBMIT CO
      if (this.tempSeleted.length !== 0 || this.tempSeleted2.length !== 0 || this.tempSeleted3.length !== 0) {
        var tempSubmitWorkflow = (_s.Register_Data.Regis_BusinessUnit == 'PTTEP') ? 'ERM' : 'Co';
        var formSubmitData = {
          SessionEmpID: this.empid,
          SecurityCode: '1234',
          Email: this.Data_SessionStorage[0].emaiL_ID,
          body: {
            Risk_Modified_By: this.empid,
            Risk_Submit_Workflow: tempSubmitWorkflow, //'Co'
            SubmitTypeOrganization: this.tempSeleted,
            SubmitTypeAsset: this.tempSeleted2,
            SubmitTypeCorporate: this.tempSeleted3
          }
        }
        //console.log(JSON.stringify(formSubmitData))
        _s.mserve.RiskSubmit(formSubmitData).then((response) => {
          if (response.data.status === true) {
            this.valuecheckSelectAll = false;
            this.tempSeleted = [];
            this.tempSeleted2 = [];
            this.tempSeleted3 = [];
            this.tempRiskID = [];
            this.tempRiskID2 = [];
            this.tempRiskID3 = [];
            this.loadRisk();
            _s.Pageloading = false;
            this.alertflow = 'Submit Complete'
            $('#modalAlertsuccess5').modal('show');
            //console.log('Submit Complete')
          } else {
            _s.Pageloading = false;
            // alert(response.data.errorMessage);
            this.alertflow = response.data.errorMessage
            $('#modalAlertdangerApprove').modal('show');
            //console.log('Submit falied', response)
          }
        });
      }

      // SUBMIT STAFF
      if (this.tempSeleted_staff.length !== 0 || this.tempSeleted2_staff.length !== 0 || this.tempSeleted3_staff.length !== 0) {
        var formSubmitData = {
          SessionEmpID: this.empid,
          SecurityCode: '1234',
          Email: this.Data_SessionStorage[0].emaiL_ID,
          body: {
            Risk_Modified_By: this.empid,
            Risk_Submit_Workflow: 'Staff',
            SubmitTypeOrganization: this.tempSeleted_staff,
            SubmitTypeAsset: this.tempSeleted2_staff,
            SubmitTypeCorporate: this.tempSeleted3_staff
          }
        }
        //console.log(JSON.stringify(formSubmitData))
        _s.mserve.RiskSubmit(formSubmitData).then((response) => {
          if (response.data.status === true) {
            this.valuecheckSelectAll = false;
            this.tempSeleted_staff = [];
            this.tempSeleted2_staff = [];
            this.tempSeleted3_staff = [];
            this.tempRiskID_staff = [];
            this.tempRiskID2_staff = [];
            this.tempRiskID3_staff = [];
            this.loadRisk();
            _s.Pageloading = false;
            this.alertflow = 'Submit Complete'
            $('#modalAlertsuccess5').modal('show');
            //console.log('Submit Complete')
          } else {
            _s.Pageloading = false;
            this.alertflow = response.data.errorMessage
            $('#modalAlertdangerApprove').modal('show');
            //console.log('Submit falied', response)
          }
        });
      }
    }
  }

  func_ConfirmMyRiksCheckCompleteRemove() {
    const _s = this;
    var statusConfirm = true;
    //validate Risk_Submit_Reason
    if (_s.tempSeletedRemove.some(item => !item.Risk_Submit_Reason || item.Risk_Submit_Reason.trim() === '')) {
      _s.alertflow = 'Please fill-in All Comments'
      $('#modalAlertAllRemoveComment').modal('show');
      //validate save button
    } else if (_s.tempSeletedRemove.some(item => item.editRemoveCommentMode)) {
      _s.alertflow = 'Please save All Comments'
      $('#modalAlertAllRemoveComment').modal('show');
    } else {
      $('#modalAllRemoveComment').modal('hide');
      if (statusConfirm === true) {
        var temprole = ''
        _s.Pageloading = true;
        
        var tempSelectedRemoveOrganization: any = [];
        var tempSelectedRemoveAsset: any = [];
        var tempSelectedRemoveCorporate: any = [];
        
        tempSelectedRemoveOrganization = this.tempSeletedRemove.filter(item => item.Risk_Type === 'Organization').map(item => ({ ...item }));
        tempSelectedRemoveAsset = this.tempSeletedRemove.filter(item => item.Risk_Type === 'Asset').map(item => ({ ...item }));
        tempSelectedRemoveCorporate = this.tempSeletedRemove.filter(item => item.Risk_Type === 'Corporate').map(item => ({ ...item }));

        // SUBMIT CO
        if (this.tempSeletedRemove.length !== 0) {
          var tempSubmitWorkflow = (_s.Register_Data.Regis_BusinessUnit == 'PTTEP') ? 'ERM' : 'Co';
          var formSubmitData = {
            SessionEmpID: this.empid,
            SecurityCode: '1234',
            Email: this.Data_SessionStorage[0].emaiL_ID,
            body: {
              Risk_Modified_By: this.empid,
              Risk_Submit_Workflow: tempSubmitWorkflow, //'Co'
              SubmitTypeOrganization: tempSelectedRemoveOrganization,
              SubmitTypeAsset: tempSelectedRemoveAsset,
              SubmitTypeCorporate: tempSelectedRemoveCorporate
            }
          }
          _s.mserve.RiskSubmit(formSubmitData).then((response) => {
            if (response.data.status === true) {
              this.valuecheckSelectAll = false;
              this.tempSeletedRemove = [];
              tempSelectedRemoveOrganization = [];
              tempSelectedRemoveAsset = [];
              tempSelectedRemoveCorporate = [];

              this.tempRiskID = [];
              this.loadRisk();
              _s.Pageloading = false;
              this.alertflow = 'Submit Complete'
              $('#modalAlertsuccess5').modal('show');
              //console.log('Submit Complete')
            } else {
              _s.Pageloading = false;
              this.tempRiskID = [];
              // alert(response.data.errorMessage);
              this.alertflow = response.data.errorMessage
              $('#modalAlertdangerApprove').modal('show');
              //console.log('Submit falied', response)
            }
          });
        }

      }
    }
  }

  func_ReveiewPage(riskid, item) {
    //console.log("Test", item);
    sessionStorage.setItem("RiskID", riskid);

    let temp = [];
    temp.push({ BUName: this.appService.showBUName, BUCode: this.Register_Data.Regis_BusinessUnit, Type: this.Register_Data.Regis_RiskType, Level: this.tempLevel });
    sessionStorage.setItem("TempSelctBUMyrisk", JSON.stringify(temp));

    //sessionStorage.setItem("TranSecRole", 'Staff');

    if (item.table_Type === "Staff") {
      //console.log("is Staff");
      sessionStorage.setItem("TranSecRole", 'Staff');
    }
    else {
      //console.log("is not  Staff");
      sessionStorage.setItem("TranSecRole", 'Co');
    }



    this.router.navigate(['/ReviewPage', 'Review']);
  }

  func_ReveiewPagefromTodo(riskid, item) {
    let temp = [];
    //console.log("SetTempBuName func_ReveiewPagefromTodo");
    temp.push({ BUName: this.appService.showBUName, BUCode: this.Register_Data.Regis_BusinessUnit, Type: this.Register_Data.Regis_RiskType });
    sessionStorage.setItem("TempSelctBUMyrisk", JSON.stringify(temp));

    for (let itemlistRiskTableItem of this.listRiskTableItem) {
      if (itemlistRiskTableItem.risk_Id === riskid && itemlistRiskTableItem.risk_Type === 'Organization') {
        this.tempSelectedApproved.push({
          Risk_Id: itemlistRiskTableItem.risk_Id,
          Risk_Business_Unit: itemlistRiskTableItem.risk_BusinessUnit_Code,
          Risk_Status_Workflow: itemlistRiskTableItem.risk_Status_Code,
          Risk_Type: itemlistRiskTableItem.risk_Type,
          Risk_AssignTo: "",
          Comment: ""
        });
      }
      else if (itemlistRiskTableItem.risk_Id === riskid && itemlistRiskTableItem.risk_Type === 'Asset') {
        this.tempSelectedApproved2.push({
          Risk_Id: itemlistRiskTableItem.risk_Id,
          Risk_Business_Unit: itemlistRiskTableItem.risk_BusinessUnit_Code,
          Risk_Status_Workflow: itemlistRiskTableItem.risk_Status_Code,
          Risk_Type: itemlistRiskTableItem.risk_Type,
          Risk_AssignTo: "",
          Comment: ""
        })
      }
    }

    sessionStorage.setItem("DataApprove1", JSON.stringify(this.tempSelectedApproved));
    sessionStorage.setItem("DataApprove2", JSON.stringify(this.tempSelectedApproved2));
    sessionStorage.setItem("RiskID", riskid);

    if (item.table_Type === "Staff") {
      sessionStorage.setItem("TranSecRole", 'Staff');
    } else {
      sessionStorage.setItem("TranSecRole", 'Co');
    }

    this.router.navigate(['/ReviewPage', 'ReviewfromTodo']);
  }

  func_EditFromToDo(riskid, Riskstatus, item) {
    sessionStorage.setItem("RiskID", riskid);
    sessionStorage.setItem("RiskStatus", Riskstatus);

    sessionStorage.setItem("TempUpdateStatus", "updatefromtodo");
    sessionStorage.setItem("TempRiskStatus", Riskstatus);
    sessionStorage.setItem("TempRiskAssignTo", item.risk_AssignTo.join(","));

    if (item.table_Type === "Staff") {
      sessionStorage.setItem("TranSecRole", 'Staff');
    } else {
      sessionStorage.setItem("TranSecRole", 'Co');
    }
    sessionStorage.setItem('statusflow', 'edit');
    this.router.navigate(['/Assessment']);
  }

  func_EditIdenti(riskid, Riskstatus, item) {


    if (Riskstatus !== '0' && Riskstatus !== '23') {
      this.tempUpdateFlow = item;
      this.tempUpdateId = riskid;

      $('#modalAlertwarning5').modal('show');
    }
    else {
      sessionStorage.setItem("RiskID", riskid);
      sessionStorage.setItem("RiskStatus", Riskstatus);

      if (item.table_Type === "Staff") {
        sessionStorage.setItem("TranSecRole", 'Staff');
      } else {
        sessionStorage.setItem("TranSecRole", 'Co');
      }
      sessionStorage.setItem('statusflow', 'edit');
      this.router.navigate(['/Assessment']);
    }
  }

  Yeswarning() {
    const _s = this;

    sessionStorage.setItem('statusflow', 'edit');

    $('#modalAlertwarning5').modal('hide');
    setTimeout(function () {

      _s.loading = true;
      let formData = {
        SessionEmpID: "0001",
        Module: "GET",
        body: {
          Risk_Id: _s.tempUpdateId,
          Risk_Name: "",
          Risk_Business_Unit: "",
          Risk_Status: "",
          Risk_Likelihood: "",
          Risk_Impact: "",
          Risk_Category: "",
          Risk_Objective: "",
          Risk_Unit_KPI: "",
          Risk_Context: "",
          Role: sessionStorage.Role,
          Risk_Type: 'Organization'
        }
      }

      _s.mservedata.RegisterRisk(formData).then((response) => {
        if (response.data.status) {
          _s.TempUpdateData = response.data.body[0];

          let item = _s.list.risk.riskitems.filter(x => x.risk_Id == _s.TempUpdateData.risk_Id);
          let modified_By = '';
          if(_s.Data_SessionStorage)
          {
            modified_By = _s.Data_SessionStorage[0].employeE_ID
          }
          else
          {
            _s.Data_SessionStorage = JSON.parse(sessionStorage.datainfo);
            modified_By = _s.Data_SessionStorage[0].employeE_ID;
          }

          let formData2 = {
            SessionEmpID: "0001",
            Module: 'UPDATE',
            body: {
              Risk_Id: _s.TempUpdateData.risk_Id,
              Risk_Name: _s.TempUpdateData.risk_Name,
              Risk_Business_Unit_Abbreviation: _s.TempUpdateData.abbreviation,
              Risk_Business_Unit: _s.TempUpdateData.risk_Business_Unit,
              Risk_Status: _s.TempUpdateData.risk_Status,
              Risk_Likelihood: _s.TempUpdateData.risk_Likelihood,
              Risk_Impact: _s.TempUpdateData.risk_Impact,
              Risk_Category: _s.TempUpdateData.risk_Category,
              Risk_Objective: _s.TempUpdateData.risk_Objective,
              Risk_Objective_Parent: _s.TempUpdateData.risk_Objective_Parent,
              Risk_Unit_KPI: _s.TempUpdateData.risk_Unit_KPI,
              Risk_Context: _s.TempUpdateData.risk_Context,
              Risk_Register_By: _s.TempUpdateData.risk_Register_By,
              Risk_Modified_By: modified_By,
              Risk_Status_Workflow: '0',
              Role: sessionStorage.Role,
              Risk_Type: '',
              QuarterID: _s.TempUpdateData.quarterID,
              WPBID: '',
              ReCall: '1',
              // Risk_Previous_Likelihood: item[0].x_Likelihood,
              // Risk_Previous_Impact: item[0].y_Impact
              Risk_Previous_Likelihood: null,
              Risk_Previous_Impact: null
            }
          }

          if(item && item.length > 0 )
          {
            formData2.body.Risk_Previous_Likelihood = item[0].x_Likelihood;
            formData2.body.Risk_Previous_Impact = item[0].y_Impact;
          }

          _s.mservedata.RegisterRisk(formData2).then((response) => {
            if (response.data.status) {
              sessionStorage.setItem("RiskID", _s.TempUpdateData.risk_Id);
              sessionStorage.setItem("RiskStatus", _s.TempUpdateData.risk_Status_Code);

              if (_s.tempUpdateFlow.table_Type === "Staff") {
                sessionStorage.setItem("TranSecRole", 'Staff');
              } else {
                sessionStorage.setItem("TranSecRole", 'Co');
              }

              _s.router.navigate(['/Assessment']);
            } else {
              _s.alertflow = response.data.errorMessage
              $('#modalAlertdanger5').modal('show');
            }
          });

        } else {
          //console.log(response.data.errorMessage);
        }
      }).catch((e) => {
        _s.authserviceToken.CheckTokenExpire(e.response.status)
      });
    }, 500)
  }
  closewarning() {
    $('#modalAlertwarning5').modal('hide');
  }

  private _filterBUDD(value: string): string[] {
    let tempFileter;
    const filterValue = value?.toLowerCase();
    if (filterValue !== '') {
      tempFileter = this.BusinessNameDD.filter(option => option.text?.toLowerCase().includes(filterValue));
      if (tempFileter.length !== 0) {
        return tempFileter;
      }
    } else {
      return this.BusinessNameDD
    }
  }

  func_OkBU(value) {

    const _s = this;
    let tempBU = [];
    if (_s.tempselectbu !== '') {
      _s.appService.showBUName = _s.tempselectbu
      this.dataService.sendData(_s.tempselectbu);
      for (let itemBusinessNameDD of _s.BusinessNameDD) {
        if (_s.tempselectbu === itemBusinessNameDD.text) {
          tempBU.push({ BuCode: itemBusinessNameDD.value, State: itemBusinessNameDD.state, level: itemBusinessNameDD.level })
          sessionStorage.setItem('BUNameWork', itemBusinessNameDD.text);
          this.sendDataShowBuName(_s.tempselectbu);
        }
      }
      if (tempBU[0].State === 'Asset') {
        _s.Register_Data.Regis_RiskType = 'Asset'
      } else if (tempBU[0].State === 'Org') {
        _s.Register_Data.Regis_RiskType = 'Organization'
      } else {
        _s.Register_Data.Regis_RiskType = 'Corporate'
      }
      _s.Register_Data.Regis_BusinessUnit = tempBU[0].BuCode;
      _s.tempLevel = tempBU[0].level;
      _s.changepage(_s.page)

      if (value === 'popup') {
        _s.chkbackmenu = 'ok'
        $('#modalBUapp').modal('hide')
      }
    }
    else {
      if (value === 'popup') {
        _s.chkbackmenu = 'ok'
        $('#modalBUapp').modal('hide')
        setTimeout(function () {
          _s.alertflow = 'Please select Business Unit Name'
          $('#modalAlertdanger5').modal('show')
        }, 500)
      }
    }
  }

  close() {
    $('#modalBUapp').modal('show');
    $('#modalAlert').modal('hide');
  }

  closeflow() {
    $('#modalAlertdanger5').modal('hide');
  }
  // goHome() {
  //   var path = sessionStorage.getItem('mainUrl');
  //   if (isValidUrl(path)) {
  //     window.location.replace(path);
  //   } else {
  //     console.error('Invalid URL provided:', path);
  //   }
  // }
  goHome() {
    let cleanPath = DOMPurify.sanitize(sessionStorage.getItem('mainUrl'));
    if (isValidUrl(cleanPath)) {
      window.location.replace(cleanPath);
    } else {
      console.error('Invalid URL provided:', cleanPath);
    }
  }
  closeApprove() {
    let _s = this
    _s.loading = false
    $('#modalAlertdangerApprove').modal('hide');
    _s.loadRisk();
  }

  closeflowsuccess() {
    $('#modalAlertsuccess5').modal('hide');
  }

  BacktoMenu() {
    const _s = this;
    _s.chkbackmenu = 'backmenu'
    $('#modalBUapp').modal('hide');
  }

  func_del(item) {
    this.tempDelItem = item
    $('#modalAlertWarning').modal('show');
  }

  ConfirmDel() {
    const _s = this;
    let item = this.tempDelItem;
    let formData = {
      SessionEmpID: "0001",
      Module: 'Delete',
      body: {
        Risk_Id: item.risk_Id,
        Risk_Name: item.risk_Name,
        Risk_Business_Unit: item.risk_BusinessUnit_Code,
        Risk_Status: '',
        Risk_Likelihood: '',
        Risk_Impact: '',
        Risk_Category: '',
        Risk_Objective: '',
        Risk_Objective_Parent: '',
        Risk_Unit_KPI: '',
        Risk_Context: '',
        Risk_Register_By: '',
        Risk_Status_Workflow: '',
        Role: (item.table_Type === 'Staff') ? 'Staff' : 'Co',
        Risk_Type: '',
        Delete_Flag: 'Y'
      }
    }
    this.mservedata.RegisterRisk(formData).then((response) => {
      if (response.data.status) {
        $('#modalAlertWarning').modal('hide');
        this.alertflow = 'Delete Success'
        $('#modalAlertsuccess5').modal('show');
        this.loadRisk();
      } else {
        //console.log(response.data.errorMessage);
      }
    });
  }

  numberWithCommas(x) {
    // const roundedNumber: number = Math.round(x);
    // const formattedNumber: string = roundedNumber.toLocaleString('en-US');
    // return formattedNumber;
    if (x >= 1000) {
      return x.toLocaleString('en-US');
    } else {
      return x;
    }
  }

  func_SearchMyRisk(value) {

    this.showResult = [];
    if (value === '2') {
      this.SearchMyrisk = '';
    }
    const filter = this.SearchMyrisk?.toUpperCase();
    if (filter === "") {
      this.showResult = this.temp;
    } else {
      for (let item_Detail of this.temp) {

        if (item_Detail.risk_Staff_Id?.toUpperCase().indexOf(filter) > -1 ||
          item_Detail.Risk_Co_Id?.toUpperCase().indexOf(filter) > -1 ||
          item_Detail.risk_Name?.toUpperCase().indexOf(filter) > -1 ||
          item_Detail.risk_BusinessUnit_Name?.toUpperCase().indexOf(filter) > -1) {
          this.showResult.push(item_Detail)
        }
      }
    }
    this.listRiskTableItem = this.showResult;
  }


  func_GETDATA(riskid, role, chkvalue, value, riskrun) {
    //de_bugger
    var chkreqroot = true, chkreqrootarr = [], chkreqImpact = true, chkreqImpactarr = [];
    const _s = this;
    var formData = {
      SessionEmpID: "0001",
      Module: "GET",
      body: {
        Risk_Id: riskid,
        Risk_Name: "",
        Risk_Business_Unit: "",
        Risk_Status: "",
        Risk_Likelihood: "",
        Risk_Impact: "",
        Risk_Category: "",
        Risk_Objective: "",
        Risk_Unit_KPI: "",
        Risk_Context: "",
        Role: role
      }
    }
    this.mservedata.RegisterRisk(formData).then((response) => {
      if (response.data.status === true) {
        this.Tempdata = response.data.body[0];
        //console.log("Get Data RegisterRisk : ", response.data.body)


        //de_bugger
        if (this.Tempdata.risk_Status !== '2') {
          // CHECK ROOTCAUSE
          if ((this.Tempdata.rootCause.length !== 0)) {

            for (var iiii = 0; iiii < this.Tempdata.rootCause.length; iiii++) {
              if (this.Tempdata.rootCause[iiii].rootCause_Mitigation_Type !== 'Take') {

                if (_s.Tempdata.rootCause_Mitigation.length !== 0) {

                  for (var i = 0; i < _s.Tempdata.rootCause.length; i++) {
                    for (var ii = 0; ii < _s.Tempdata.rootCause_Mitigation.length; ii++) {
                      if ((_s.Tempdata.rootCause[i].rootCause_Id === _s.Tempdata.rootCause_Mitigation[ii].rootCause_Id) && _s.Tempdata.rootCause[i].rootCause_Mitigation_Type !== 'Take') {
                        chkreqrootarr.push({ id: _s.Tempdata.rootCause[i].rootCause_Id, value: true })
                        break;
                      }
                      else if (_s.Tempdata.rootCause[i].rootCause_Mitigation_Type !== 'Take') {
                        chkreqrootarr.push({ id: _s.Tempdata.rootCause[i].rootCause_Id, value: false })
                      }
                    }
                  }

                  // เช็ครอบที่ 1
                  for (var i = 0; i < chkreqrootarr.length; i++) {
                    if (i !== 0) {
                      if ((chkreqrootarr[i].id === chkreqrootarr[i - 1].id)) {
                        var temid = chkreqrootarr[i].id
                        var tempval = chkreqrootarr[i].value

                        for (var ii = 0; ii < i; ii++) {
                          if (chkreqrootarr[ii].id === temid) {
                            chkreqrootarr[ii].id = temid
                            chkreqrootarr[ii].value = tempval
                          }
                        }

                      }
                    }
                  }

                  // เช็ครอบที่ 2
                  for (var i = 0; i < chkreqrootarr.length; i++) {
                    if (chkreqrootarr[i].value === false) {
                      chkreqroot = false;
                      break;
                    } else {
                      chkreqroot = true;
                    }
                  }



                  if (chkreqroot === false) {
                    _s.alertflow = 'Please Specify all Root Cause Mitigation Plan in Root Cause in Risk ID : ' + riskrun.Riskrunning
                    $('#modalAlertdangerApprove').modal('show');
                  }

                  for (var iii = 0; iii < _s.Tempdata.rootCause.length; iii++) {
                    if ((_s.Tempdata.rootCause[iii].rootCause_After_Mitigated === null || _s.Tempdata.rootCause[iii].rootCause_After_Mitigated === '') && chkreqroot === true && _s.Tempdata.rootCause[iii].rootCause_Mitigation_Type !== 'Take') {
                      this.alertflow = 'Please select Likelihood after Mitigated in Risk ID : ' + riskrun.Riskrunning
                      $('#modalAlertdangerApprove').modal('show');
                      chkreqroot = false
                    }
                  }
                } else {
                  _s.alertflow = 'Please Specify all Root Cause Mitigation Plan in Root Cause in Risk ID : ' + riskrun.Riskrunning
                  $('#modalAlertdangerApprove').modal('show');
                  chkreqroot = false
                }

              }
              else if (chkreqroot !== false) {
                chkreqroot = true;
              }
            }
          } else {
            _s.alertflow = 'Please Specify Root Cause in Risk ID : ' + riskrun.Riskrunning
            $('#modalAlertdangerApprove').modal('show');
            chkreqroot = false
            _s.func_Clear_MyRisk()
          }


          // CHECK IMPACT
          if (this.Tempdata.impact.length !== 0 && chkreqroot === true) {

            for (var iiii = 0; iiii < this.Tempdata.impact.length; iiii++) {
              if (this.Tempdata.impact[iiii].impact_Mitigation_Type !== 'Take') {

                if (_s.Tempdata.impact_Mitigation.length !== 0) {
                  for (var i = 0; i < _s.Tempdata.impact.length; i++) {
                    for (var ii = 0; ii < _s.Tempdata.impact_Mitigation.length; ii++) {
                      if ((_s.Tempdata.impact[i].impact_Id.includes(_s.Tempdata.impact_Mitigation[ii].impact_Id)) && _s.Tempdata.impact[i].impact_Mitigation_Type !== 'Take') {
                        chkreqImpactarr.push({ id: _s.Tempdata.impact[i].impact_Id, value: true })
                        break;
                      }
                      else if (_s.Tempdata.impact[i].impact_Mitigation_Type !== 'Take') {
                        chkreqImpactarr.push({ id: _s.Tempdata.impact[i].impact_Id, value: false })
                      }
                    }
                  }

                  // เช็ครอบที่ 1
                  for (var i = 0; i < chkreqImpactarr.length; i++) {
                    if (i !== 0) {
                      if ((chkreqImpactarr[i].id === chkreqImpactarr[i - 1].id)) {
                        var temid = chkreqImpactarr[i].id
                        var tempval = chkreqImpactarr[i].value

                        for (var ii = 0; ii < i; ii++) {
                          if (chkreqImpactarr[ii].id === temid) {
                            chkreqImpactarr[ii].id = temid
                            chkreqImpactarr[ii].value = tempval
                          }
                        }

                      }
                    }
                  }

                  // เช็ครอบที่ 2
                  for (var i = 0; i < chkreqImpactarr.length; i++) {
                    if (chkreqImpactarr[i].value === false) {
                      chkreqImpact = false;
                      break;
                    } else {
                      chkreqImpact = true;
                    }
                  }


                  if (chkreqImpact === false) {
                    _s.alertflow = 'Please Specify all Impact Mitigation Plan in Impact in Risk ID : ' + riskrun.Riskrunning
                    $('#modalAlertdangerApprove').modal('show');
                  }

                  for (var iii = 0; iii < _s.Tempdata.impact.length; iii++) {
                    if ((_s.Tempdata.impact[iii].impact_After_Mitigated === null || _s.Tempdata.impact[iii].impact_After_Mitigated === '') && chkreqImpact === true && _s.Tempdata.impact[iii].impact_Mitigation_Type !== 'Take') {
                      this.alertflow = 'Please select Impact Level after Mitigated in Risk ID : ' + riskrun.Riskrunning
                      $('#modalAlertdangerApprove').modal('show');
                      chkreqImpact = false
                    }
                  }
                } else {
                  _s.alertflow = 'Please Specify all Impact Mitigation Plan in Impact in Risk ID : ' + riskrun.Riskrunning
                  $('#modalAlertdangerApprove').modal('show');
                  chkreqImpact = false
                }

              }
              else if (chkreqImpact !== false) {
                chkreqImpact = true;
              }
            }

          } else if (chkreqroot === true) {
            _s.alertflow = 'Please Specify Impact in Risk ID : ' + riskrun.Riskrunning
            $('#modalAlertdangerApprove').modal('show');
            chkreqImpact = false
            _s.func_Clear_MyRisk()
          }
        }

        if (chkreqroot === true && chkreqImpact === true) {
          // FOR CO
          if (chkvalue === '1' && role === 'Co') {
            this.MYRISK1.push(value);
          }
          else if (chkvalue === '2' && role === 'Co') {
            this.MYRISK2.push(value);
          }
          else if (chkvalue === '3' && role === 'Co') {
            this.MYRISK3.push(value);
          }

          // FOR STAFF
          else if (chkvalue === '1' && role === 'Staff') {
            this.MYRISK1_staff.push(value);
          }
          else if (chkvalue === '2' && role === 'Staff') {
            this.MYRISK2_staff.push(value);
          }
          else if (chkvalue === '3' && role === 'Staff') {
            this.MYRISK3_staff.push(value);
          }

          this.func_ConfirmMyRiksCheckComplete('co')
        }



      } else {
        alert(response.data.errorMessage);
        //console.log('false', response)
      }
    });
  }

  getMitigationTypeStatusByRootCause(rootCauseId, chkreqrootarr) {
    const matchingMitigations = chkreqrootarr.filter(item => item.id === rootCauseId);
    if (matchingMitigations.length > 0) {
      const tempval = matchingMitigations[0].value;
      for (const item of matchingMitigations) {
        item.value = tempval;
      }
    }
  }

  func_okversion() {
    $('#modalVersioning').modal('hide');
    setTimeout(function () { $('#modalBUapp').modal('show') }, 800)

  }

  ngAfterViewInit(): void {
    const _s = this;
    setTimeout(function () { _s.Pageloading = false }, 500)
  }

  drop(event: CdkDragDrop<string[]>) {
    const _s = this;
    moveItemInArray(_s.list.risk.riskitems, event.previousIndex, event.currentIndex);

    if (_s.list.risk.riskitems) {

      _s.list.risk.riskitems.forEach(function (ItemVal, ItemIndex) {
        let index = (ItemIndex + 1);
        //console.log(index);
      })

      let tempObj = {
        body: {
          Risk_Items: _s.list.risk.riskitems
        }
      }

      this.Drop_CallService(_s, tempObj);
    }
  }

  private Drop_CallService(_s: this, tempObj: { body: { Risk_Items: any[]; }; }) {
    _s.mserve.setRiskMapSorting(tempObj).then((response) => {
      if (response.data.status) {
        _s.list.risk.risktable.forEach(function (ItemVal, ItemIndex) {
          ItemVal.col.forEach(function (ItemVal2, ItemIndex2) {
            if (ItemIndex !== 5 && ItemIndex2 !== 0) {
              _s.list.risk.risktable[ItemIndex].col[ItemIndex2].item = [];
            }
          });
        });

        _s.list.risk.riskitems.forEach(function (ItemVal, ItemIndex) {
          
          if (_s.list.risk.risktable[(5 - ItemVal.risk_Previous_Impact)].col[0].item.length !== 0
              && (ItemVal.risk_Previous_Impact != ItemVal.y_Impact || ItemVal.risk_Previous_Likelihood != ItemVal.x_Likelihood))
          {

            if (ItemVal.impact_Category === '1') {

              setPreviousBgColor1(_s, ItemVal, ItemIndex);

            }
            else {
              setPreviousBgColor2(_s, ItemVal, ItemIndex);

            }
          }



          if (_s.list.risk.risktable[(5 - ItemVal.y_Impact)].col[0].item.length !== 0) {
            if (ItemVal.impact_Category === '1') {

              if (ItemVal.impact_Category === '1') {
                setBgColor1(_s, ItemVal, ItemIndex);
              }
            }
            else {
              setBgColor2(_s, ItemVal, ItemIndex);

            }
          }
        });
      } else {
        _s.loading = false;
        //console.log(response.data.errorMessage);
      }
    }).catch((e) => {
      if (e.response) {
        this.authserviceToken.CheckTokenExpire(e.response.status);
      } else {
        //console.log(e);
      }
    });

    function setBgColor1(_s: any, ItemVal: any, ItemIndex: number) {

      //console.log("setBgColor1", _s.list.risk.riskitems[ItemIndex].risk_Name)



      const bgColor = _s.list.risk.risktable[(5 - ItemVal.y_Impact)].col[0].item[(ItemVal.impact_NPT_EMV === '1') ? 4 : 2].bgcolor;
      const Color = _s.list.risk.risktable[(5 - ItemVal.y_Impact)].col[0].item[(ItemVal.impact_NPT_EMV === '1') ? 4 : 2].color;
      _s.list.risk.risktable[(5 - ItemVal.y_Impact)].col[parseInt(ItemVal.x_Likelihood)].item.push({
        index: (ItemIndex + 1).toString(),
        title: _s.list.risk.riskitems[ItemIndex].risk_Name,
        bgcolor: bgColor,
        color: Color,
        Risk_Id: _s.list.risk.riskitems[ItemIndex].risk_Id,
        IsStripes: false,
        // IsStripes: _s.list.risk.riskitems[ItemIndex].IsStripes,
        risk_Status_Workflow_ReConsole: _s.list.risk.riskitems[ItemIndex].risk_Status_Workflow_ReConsole,
        risk_Status: _s.list.risk.riskitems[ItemIndex].risk_Status,
        hover_Text: chk_HoverText(_s.tempModule, _s, ItemIndex, false),
      });
    }
    function setBgColor2(_s: any, ItemVal: any, ItemIndex: number) {
      //console.log("setBgColor1", _s.list.risk.riskitems[ItemIndex])
      const bgColor = _s.list.risk.risktable[(5 - ItemVal.y_Impact)].col[0].item[0].bgcolor
      const Color = _s.list.risk.risktable[(5 - ItemVal.y_Impact)].col[0].item[0].color

      _s.list.risk.risktable[(5 - ItemVal.y_Impact)].col[parseInt(ItemVal.x_Likelihood)].item.push({
        index: (ItemIndex + 1).toString(),
        title: _s.list.risk.riskitems[ItemIndex].risk_Name,
        bgcolor: bgColor,
        color: Color,
        Risk_Id: _s.list.risk.riskitems[ItemIndex].risk_Id,
        IsStripes: false,

        // IsStripes: _s.list.risk.riskitems[ItemIndex].IsStripes,
        risk_Status_Workflow_ReConsole: _s.list.risk.riskitems[ItemIndex].risk_Status_Workflow_ReConsole,
        risk_Status: _s.list.risk.riskitems[ItemIndex].risk_Status,
        hover_Text: chk_HoverText(_s.tempModule, _s, ItemIndex, false),
      })
    }

    function setPreviousBgColor1(_s: any, ItemVal: any, ItemIndex: number) {

      const bgColor = _s.list.risk.risktable[(5 - ItemVal.risk_Previous_Impact)].col[0].item[(ItemVal.impact_NPT_EMV === '1') ? 4 : 2].bgcolor
      const Color = _s.list.risk.risktable[(5 - ItemVal.risk_Previous_Impact)].col[0].item[(ItemVal.impact_NPT_EMV === '1') ? 4 : 2].color
      _s.list.risk.risktable[(5 - ItemVal.risk_Previous_Impact)].col[parseInt(ItemVal.risk_Previous_Likelihood)].item.push({
        index: (ItemIndex + 1).toString(),
        title: _s.list.risk.riskitems[ItemIndex].risk_Name,
        bgcolor: bgColor,
        color: Color,
        Risk_Id: _s.list.risk.riskitems[ItemIndex].risk_Id,
        IsStripes: true,
        table_Type: _s.list.risk.riskitems[ItemIndex].table_Type,
        risk_Status_Workflow_ReConsole: _s.list.risk.riskitems[ItemIndex].risk_Status_Workflow_ReConsole,
        risk_Status: _s.list.risk.riskitems[ItemIndex].risk_Status,
        hover_Text: ''
      });
    }


    function setPreviousBgColor2(_s: any, ItemVal: any, ItemIndex: number) {

      const bgColor = _s.list.risk.risktable[(5 - ItemVal.risk_Previous_Impact)].col[0].item[0].bgcolor
      const Color = _s.list.risk.risktable[(5 - ItemVal.risk_Previous_Impact)].col[0].item[0].color
      _s.list.risk.risktable[(5 - ItemVal.risk_Previous_Impact)].col[parseInt(ItemVal.risk_Previous_Likelihood)].item.push({
        index: (ItemIndex + 1).toString(),
        title: _s.list.risk.riskitems[ItemIndex].risk_Name,
        bgcolor: bgColor,
        color: Color,
        Risk_Id: _s.list.risk.riskitems[ItemIndex].risk_Id,
        IsStripes: true,
        table_Type: _s.list.risk.riskitems[ItemIndex].table_Type,
        risk_Status_Workflow_ReConsole: _s.list.risk.riskitems[ItemIndex].risk_Status_Workflow_ReConsole,
        risk_Status: _s.list.risk.riskitems[ItemIndex].risk_Status,
        hover_Text: ''
      });
    }

  }


  SelectMethodology() {
    $('#modalSelectMethodology').modal('show');
  }

  closeSelectMethodology() {
    $('#modalSelectMethodology').modal('hide');
  }

  func_btn_RegisterNewRisk_BG(value, statusiden) {
    $('#modalSelectMethodology').modal('hide');
    sessionStorage.removeItem('RiskID');
    this.router.navigate(['/Identification', 'new']);
  }

  func_btn_FullAnalysisRisk_BG(value, statusiden) {
    $('#modalSelectMethodology').modal('hide');
    sessionStorage.setItem('statusflow', statusiden);
    sessionStorage.removeItem('RiskID')
    this.router.navigate(['/Assessment', 'new']);
  }

  toggleEditRemoveComment(data: any) {
    data.editRemoveCommentMode = !data.editRemoveCommentMode;
  }

  closeModalAlertAllRemoveComment() {
    $('#modalAlertAllRemoveComment').modal('hide');

    $('.modal').on("hidden.bs.modal", function (e) {
      if ($('.modal:visible').length) {
        $('body').addClass('modal-open');
      }
    });

  }

  dismissModalCommentCRList() {
    if(this.mode === "multiple"){
      //case select all
      //change all checked 'remove checkbox' to unchecked
      this.listRiskTableItem.forEach(item => {
        if (item.ChkRemoveAll) {
          item.ChkRemoveAll = false;
        }
      });
      //unchecked 'remove all checkbox'
      this.valuecheckRemoveAll = false;
      this.tempSelectedRemoveAll = [];
      
    } else {
      //case select each
      this.listRiskTableItem.forEach(item => {
        if (item.risk_Id === this.RiskId) {
          item.ChkRemoveAll = false;
        }
      });
      this.isEmptyComment = false
    }
    
    //unchecking last 'remove checkbox'
    if(this.tempSeletedRemove.length === 0 && ((this.listRiskTableItem.some(item => item.ChkRemoveAll)) === false)){
      this.disableSelectAll = false;
      this.valuecheckRemoveAll = false;
    }
    
  }

  checkRemoveCheckboxIsShow(data: any): boolean{
    //condition from ngIf 'remove checkbox'

    // //console.log('hadRemoveCheckBox', data.hadRemoveCheckBox)
    // //console.log('this.chkroleuser', this.chkroleuser)
    // //console.log('data.ChkRework', data.ChkRework)
    // //console.log('data.risk_ViewMode', data.risk_ViewMode)
    // //console.log('data.risk_Status_Code', data.risk_Status_Code)
    // //console.log('data.isUserLevelApprove', data.isUserLevelApprove)

    if(data.hadRemoveCheckBox || 
        (this.chkroleuser != 'Owner' && data.ChkRework == true && data.risk_ViewMode !== 'Y') && 
        ((data.risk_Status_Code == '7' || 
        data.risk_Status_Code == '10' || 
        ((data.risk_Status_Code == '11' || data.risk_Status_Code == '12') && this.tempLevel != 'Group') || 
        data.risk_Status_Code == '13' || 
        (data.risk_Status_Code == '18' && this.tempLevel != 'Group')) && 
        data.isUserLevelApprove))
    {
      return true
    } else {
      return false
    }
  }

  checkUserLevelApprove(data: any): boolean{
    var temp_fG_Console = (data.fG_Console !== null) ? data.fG_Console.split(",") : "";
    var temp_dI_Console = (data.dI_Console !== null) ? data.dI_Console.split(",") : ""; 
    var temp_bU_Console = (data.bU_Console !== null) ? data.bU_Console.split(",") : "";

    if(temp_fG_Console != null && temp_fG_Console.length > 0){
      for (var s1 = 0; s1 < temp_fG_Console.length; s1++) {
        if (temp_fG_Console[s1] == this.empid && data.fG_Flag == 'Y') { return true;}
      }
    } 
    
    if(temp_dI_Console != null && temp_dI_Console.length > 0){
      for (var s2 = 0; s2 < temp_dI_Console.length; s2++) {
        if (temp_dI_Console[s2] == this.empid && data.dI_Flag == 'Y') {  return true;}
      }
    } 
    
    if(temp_bU_Console != null && temp_bU_Console.length > 0){
      for (var s3 = 0; s3 < temp_bU_Console.length; s3++) {
        if (temp_bU_Console[s3] == this.empid && data.bU_Flag == 'Y') {  return true;}
      }
    }

    return false;
  }
}

function chk_HoverText(module: string, _s: any, ItemIndex: number, stripes: boolean): string {

  if (module == 'TODO') {
    return ((_s.list.risk.riskitems[ItemIndex].risk_Status_Workflow_ReConsole != null || _s.list.risk.riskitems[ItemIndex].risk_Status != '1') && stripes == false) ? 'Propose to remove' : '';
  }
  else return '';
}

function isValidUrl(string) {
  try {
    const url = new URL(string);
    return url.protocol === "http:" || url.protocol === "https:";
  } catch (_) {
    return false;
  }
}