import { Component, OnInit } from '@angular/core';
import { AuthenService } from 'src/app/services/authenservice.service';
import { SummaryService } from '../../services/summary.service';
import {
  SummaryModel,
  StrategicRiskTransaction,
  DataListStrategic,
  DataListManageable,
  DataListRiskStrategy,
  DataListRiskAssumption,
} from '../../model/SummaryModel';
import { bindingStrategicTableModel } from '../../shared/model/StrategicRiskModel';
import { LowestScoreDirective } from '../../shared/directives/LowestScore.directive';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { MasterDirective } from '../../shared/directives/Master.directive';
import { StrategyModel } from '../../shared/model/StrategyModel';
import { Masterserve } from 'src/app/services/master.service';
import DOMPurify from 'dompurify';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-summary-share',
  templateUrl: './summary-share.component.html',
  styleUrls: ['./summary-share.component.css'],
})
export class SummaryShareComponent implements OnInit {
  constructor(
    public authserviceToken: AuthenService,
    public summaryService: SummaryService,
    public masterDirective: MasterDirective,
    public lowestScore: LowestScoreDirective,
    public mserve: Masterserve
  ) { }

  titleBU = 'Summary & Share';

  loading = false;
  page = 1;
  pageSize = 10;
  getDataBU = '';
  squareClass = 'right';
  isShowStrategy = false;
  isShowAssumption = false;
  positionSetValue1 = [];
  positionSetValue2 = [];
  lowestScoreValueX;
  lowestScoreValueY;
  lowestScoreValueManageableX;
  lowestScoreValueManageableY;
  usePagination = true;

  DataLogRiskIdLst = [];

  selectValues = this.masterDirective.getStrategicValues();
  likelihoodValues = this.masterDirective.getLikelihoodValues();
  impactValues = this.masterDirective.getImpactValues();
  riskLevelValues = this.masterDirective.getRiskLevelValues();
  acceptableValues = this.masterDirective.getAcceptableValues();

  DataListSummary: SummaryModel[] = [];
  DataListStrategicRiskTransaction: StrategicRiskTransaction[] = [];
  DataListStrategic: DataListStrategic[] = [];
  DataListManageable: DataListManageable[] = [];
  DataListRiskStrategy: DataListRiskStrategy[] = [];
  DataListRiskAssumption: DataListRiskAssumption[] = [];
  dataStrategyList: StrategyModel[] = [];
  templateEmailItem: any = '';
  Data_SessionStorage;

  isShowStrategicAlignment: boolean = true;
  strIsShowStrategicAlignment: string = 'down';
  isShowStrategicContribution: boolean = true;
  strIsShowStrategicContribution: string = 'down';
  isShowManageableChallenge: boolean = true;
  strIsShowManageableChallenge: string = 'down';
  isShowManageablePortfolioRisk: boolean = true;
  strIsShowManageablePortfolioRisk: string = 'down';

  SearchKey = '';
  SearchKeyBU = '';
  showResult = [];
  ContactDetail;
  temp = [];

  DDL_BusinessName = [];
  filteredOptionsBuname: Observable<string[]>;
  myControlBuname = new UntypedFormControl();

  empRole;
  empCode;

  ngOnInit(): void {
    this.Data_SessionStorage = JSON.parse(sessionStorage.datainfo);

    this.func_GetDataMail();
  }

  returnEvent(event) {
    this.getDataBU = event;
    this.empRole = sessionStorage.getItem('buRole');
    this.empCode = sessionStorage.getItem('buCode');
    this.func_GetDDL_BusinessName();
    this.func_GetData();
  }

  func_GetDDL_BusinessName() {
    const _s = this;
    let formData = {
      Module: 'DepartmentDD',
    };
    this.mserve.Master_DDL(formData).then((response) => {
      if (response.data.status) {
        this.DDL_BusinessName = response.data.body;

        this.func_GETMASTER_ContactUs();

        this.filteredOptionsBuname = this.myControlBuname.valueChanges.pipe(
          startWith(''),
          map((value) => this._filterBUName(value))
        );
      } else {
        //console.log('false', response);
      }
    });
  }

  func_GETMASTER_ContactUs() {
    const _s = this;
    let formData = {
      SessionEmpID: '1',
      Module: 'getlist',
      body: {
        ContactName: 'TM001',
        Position: '1',
      },
    };
    this.mserve
      .Get_ContactUs(formData)
      .then((response) => {
        if (response.data.status) {
          this.ContactDetail = response.data.body;
          for (let item_ContactDetail of this.ContactDetail) {
            const avatarNumber = 1; // กำหนดตัวแปรเก็บเลขรูปภาพ
            item_ContactDetail.pic = `../../../assets/lg/avatar${avatarNumber}.jpg`;
          }

          _s.ContactDetail.forEach(function (val1, index1) {
            if (val1.arrGroupMap) {
              val1.arrGroupMap.forEach((val2, index2) => {
                const matchedItem = _s.DDL_BusinessName.find(
                  (item_DDL_BusinessName) =>
                    val2 === item_DDL_BusinessName.value
                );
                if (matchedItem) {
                  _s.ContactDetail[index1].arrGroupMap[index2] =
                    matchedItem.text;
                }
              });
            }
          });

          this.ContactDetail.forEach(function (ItemVal, ItemIndex) {
            if (ItemVal.delFlag !== '1') _s.temp.push(ItemVal);
          });
        }
      })
      .catch((e) => {
        this.authserviceToken.CheckTokenExpire(e.response.status);
      });
  }

  private _filterBUName(value: string) {
    const filterValue = value?.toLowerCase();
    if (filterValue !== '') {
      //console.log("filterValue : ", filterValue);
      return this.DDL_BusinessName.filter((option) =>
        option.text?.toLowerCase().includes(filterValue)
      );
    } else {
      return this.DDL_BusinessName;
    }
  }

  funcSearch() {
    let filterBu = this.SearchKeyBU?.toUpperCase();

    this.getDataBU = filterBu;
    this.func_GetData();
  }

  func_GetData() {
    this.summaryService
      .func_getDataListSummary(this.getDataBU)
      .then((response) => {
        if (response.data.status) {
          this.DataLogRiskIdLst = response.data.body.riskIds;
          this.DataListStrategicRiskTransaction = response.data.body.strategicRiskTransaction;
          this.DataListStrategic = response.data.body.strategicRiskTransaction.map((items) => {
              return {
                ...items,
                strategy: items.strategy,
                strategicDirection: items.strategicDirection,
                strategicDirectionRationale:
                  'Rationale \n' + items.strategicDirectionRationale,
                financial: items.financial,
                financialRationale: 'Rationale \n' + items.financialRationale,
                zeroTolerance: items.zeroTolerance,
                zeroToleranceRationale:
                  'Rationale \n' + items.zeroToleranceRationale,
                valueProfit: items.valueProfit,
                valueProfitRationale:
                  'Rationale \n' + items.valueProfitRationale,
                timingCashInfow: items.timingCashInfow,
                timingCashInfowRationale:
                  'Rationale \n' + items.timingCashInfowRationale,
                growthSustainability: items.growthSustainability,
                growthSustainabilityRationale:
                  'Rationale \n' + items.growthSustainabilityRationale,
              };
            });

          this.DataListManageable =
            response.data.body.strategicRiskTransaction.map((items) => {
              return {
                ...items,
                strategy: items.strategy,
                capability: items.capability,
                capabilityRationale: 'Rationale \n' + items.capabilityRationale,
                resources: items.resources,
                resourcesRationale: 'Rationale \n' + items.resourcesRationale,
                jointInvestment: items.jointInvestment,
                jointInvestmentRationale:
                  'Rationale \n' + items.jointInvestmentRationale,
                quickExit: items.quickExit,
                quickExitRationale: 'Rationale \n' + items.quickExitRationale,
              };
            });

          this.DataListStrategicRiskTransaction =
            this.DataListStrategicRiskTransaction.map((items, index) => {
              return {
                ...items,
                isShowStrategy: index == 0 ? true : false,
                isShowAssumption: index == 0 ? true : false,
                squareClass: index == 0 ? 'down' : 'right',
                squareClassAssumption: index == 0 ? 'down' : 'right',
                page: 1
              };
            });

          this.PositionValueXY();
          this.loading = false;
        } else {
          alert(response.data.errorMessage);
          this.loading = false;
        }
      })
      .catch((e) => {
        this.authserviceToken.CheckTokenExpire(e.response.status);
      });
  }

  func_GetDataMail() {
    let formData = {
      SessionEmpID: 1,
      body: {
        Module: 'getlist',
        Status: 1,
        Subject: '',
        Body1: '',
        Body2: '',
        Body3: '',
        Description: '',
        Type: 2,
      },
    };
    this.mserve
      .GetTemplateEmail(formData)
      .then((response) => {
        if (response.data.status) {
          let templateEmailItem = response.data.body.find(
            (f) => f.Status == 37
          );
          if (templateEmailItem) {
            this.templateEmailItem = templateEmailItem;
          }
        } else {
          //console.log('false', response);
          alert(response.data.errorMessage);
        }
      })
      .catch((e) => {
        this.authserviceToken.CheckTokenExpire(e.response.status);
      });
  }

  filterByid(item: any[], itemValue): any[] {
    return item.filter((p) => p.value == itemValue);
  }

  PositionValueXY() {
    this.positionSetValue1 = [];
    this.positionSetValue2 = [];

    this.DataListStrategic = this.DataListStrategic.map((items) => {
      let lowestScoreX = this.lowestScore.calLowestScore(
        items.financial,
        items.strategicDirection,
        Number(items.zeroTolerance)
      );
      this.lowestScoreValueX =
        this.lowestScore.calValueToLowestScore(lowestScoreX);

      let lowestScoreY = this.lowestScore.calLowestScore(
        items.valueProfit,
        items.timingCashInfow,
        Number(items.growthSustainability)
      );
      this.lowestScoreValueY =
        this.lowestScore.calValueToLowestScore(lowestScoreY);

      let value: bindingStrategicTableModel = {
        xPosition: lowestScoreX,
        yPosition: lowestScoreY,
      };

      this.positionSetValue1.push(value);

      return {
        ...items,
        lowestScoreStrategicX: this.lowestScoreValueX,
        lowestScoreStrategicY: this.lowestScoreValueY,
      };
    });

    this.DataListManageable = this.DataListManageable.map((items) => {
      let lowestScoreX = this.lowestScore.calLowestScore(
        items.capability,
        items.resources
      );
      this.lowestScoreValueX =
        this.lowestScore.calValueToLowestScore(lowestScoreX);

      let lowestScoreY = this.lowestScore.calLowestScore(
        items.jointInvestment,
        items.quickExit
      );
      this.lowestScoreValueY =
        this.lowestScore.calValueToLowestScore(lowestScoreY);

      let value: bindingStrategicTableModel = {
        xPosition: lowestScoreX,
        yPosition: lowestScoreY,
      };

      this.positionSetValue2.push(value);

      return {
        ...items,
        lowestScoreManageableX: this.lowestScoreValueX,
        lowestScoreManageableY: this.lowestScoreValueY,
      };
    });
  }

  func_ShortMasterStr(_str) {
    if (
      this.isShowStrategicAlignment == true &&
      _str.includes('StrategicAlignment')
    ) {
      this.isShowStrategicAlignment = false;
      this.strIsShowStrategicAlignment = 'right';
    } else if (
      this.isShowStrategicAlignment == false &&
      _str.includes('StrategicAlignment')
    ) {
      this.isShowStrategicAlignment = true;
      this.strIsShowStrategicAlignment = 'down';
    }

    if (
      this.isShowStrategicContribution == true &&
      _str.includes('StrategicContribution')
    ) {
      this.isShowStrategicContribution = false;
      this.strIsShowStrategicContribution = 'right';
    } else if (
      this.isShowStrategicContribution == false &&
      _str.includes('StrategicContribution')
    ) {
      this.isShowStrategicContribution = true;
      this.strIsShowStrategicContribution = 'down';
    }
    if (
      this.isShowManageableChallenge == true &&
      _str.includes('ManageableChallenge')
    ) {
      this.isShowManageableChallenge = false;
      this.strIsShowManageableChallenge = 'right';
    } else if (
      this.isShowManageableChallenge == false &&
      _str.includes('ManageableChallenge')
    ) {
      this.isShowManageableChallenge = true;
      this.strIsShowManageableChallenge = 'down';
    }

    if (
      this.isShowManageablePortfolioRisk == true &&
      _str.includes('ManageablePortfolioRisk')
    ) {
      this.isShowManageablePortfolioRisk = false;
      this.strIsShowManageablePortfolioRisk = 'right';
    } else if (
      this.isShowManageablePortfolioRisk == false &&
      _str.includes('ManageablePortfolioRisk')
    ) {
      this.isShowManageablePortfolioRisk = true;
      this.strIsShowManageablePortfolioRisk = 'down';
    }
  }

  func_ShortItem(_item) {
    let IndexStrategicRiskTransaction =
      this.DataListStrategicRiskTransaction.findIndex(
        (f) => f.riskId == _item.riskId
      );
    if (
      !this.DataListStrategicRiskTransaction[IndexStrategicRiskTransaction]
        .isShowStrategy
    ) {
      this.DataListStrategicRiskTransaction[
        IndexStrategicRiskTransaction
      ].isShowStrategy = true;
      this.DataListStrategicRiskTransaction[
        IndexStrategicRiskTransaction
      ].squareClass = 'down';
    } else if (
      this.DataListStrategicRiskTransaction[IndexStrategicRiskTransaction]
        .isShowStrategy
    ) {
      this.DataListStrategicRiskTransaction[
        IndexStrategicRiskTransaction
      ].isShowStrategy = false;
      this.DataListStrategicRiskTransaction[
        IndexStrategicRiskTransaction
      ].squareClass = 'right';
    }
  }

  func_ShortAssumptionItem(_item) {
    let IndexStrategicRiskTransaction =
      this.DataListStrategicRiskTransaction.findIndex(
        (f) => f.riskId == _item.riskId
      );
    if (
      !this.DataListStrategicRiskTransaction[IndexStrategicRiskTransaction]
        .isShowAssumption
    ) {
      this.DataListStrategicRiskTransaction[
        IndexStrategicRiskTransaction
      ].isShowAssumption = true;
      this.DataListStrategicRiskTransaction[
        IndexStrategicRiskTransaction
      ].squareClassAssumption = 'down';
    } else if (
      this.DataListStrategicRiskTransaction[IndexStrategicRiskTransaction]
        .isShowAssumption
    ) {
      this.DataListStrategicRiskTransaction[
        IndexStrategicRiskTransaction
      ].isShowAssumption = false;
      this.DataListStrategicRiskTransaction[
        IndexStrategicRiskTransaction
      ].squareClassAssumption = 'right';
    }
  }

  replaceSubject(subject) {
    subject = subject.replace('[PREFIX]', sessionStorage.getItem('buName'));
    return subject;
  }

  replaceBody(body) {
    const firstname = this.Data_SessionStorage[0].firstname;
    const lastname = this.Data_SessionStorage[0].lastname;
    body = body.replace('{Sender}', `${firstname} ${lastname}`);

    const pathname = window.location.href;
    body = body.replace('{Link}', pathname);
    return body;
  }

  replaceHtmlTagsForEmail(body) {
    body = body.replace(/<p>/g, '');

    body = body.replace(/<\/p>/g, '\n\n');

    body = body.replace(/<span style="white-space:pre">\s*<\/span>/g, '\t');

    body = body.replace(/<span.*?>/g, '');

    body = body.replace(/<\/span>/g, '');

    body = body.replace(/<font.*?>/g, '');

    body = body.replace(/<\/font>/g, '');

    body = body.replace(/<br>/g, '\n');

    body = body.replace(/&nbsp;/g, ' ');

    return body;
  }

  func_ShareTo() {
    const mailtoSubject = this.replaceSubject(this.templateEmailItem.Subject);
    const body1 = this.replaceBody(this.templateEmailItem.Body1 || '');
    const body2 = this.replaceBody(this.templateEmailItem.Body2 || '');
    const body3 = this.replaceBody(this.templateEmailItem.Body3 || '');
    const encodedBody = this.replaceHtmlTagsForEmail(
      `${body1}${body2}${body3}`
    );
    const mailtoLink = `mailto:?subject=${mailtoSubject}&body=${encodeURIComponent(
      encodedBody
    )}`;
    const cleanPath = DOMPurify.sanitize(mailtoLink);
    const newWindow = window.open(cleanPath, '_blank');
    if (newWindow) {
      newWindow.focus();
    }
  }

  func_exportToPDF() {
    this.loading = true;
    const oldIsShowStrategicAlignment = this.isShowStrategicAlignment;
    const oldStrIsShowStrategicAlignment = this.strIsShowStrategicAlignment;
    const oldIsShowStrategicContribution = this.isShowStrategicContribution;
    const oldStrIsShowStrategicContribution =
      this.strIsShowStrategicContribution;
    const oldIsShowManageableChallenge = this.isShowManageableChallenge;
    const oldStrIsShowManageableChallenge = this.strIsShowManageableChallenge;
    const oldIsShowManageablePortfolioRisk = this.isShowManageablePortfolioRisk;
    const oldStrIsShowManageablePortfolioRisk =
      this.strIsShowManageablePortfolioRisk;
    this.isShowStrategicAlignment = true;
    this.strIsShowStrategicAlignment = 'down';
    this.isShowStrategicContribution = true;
    this.strIsShowStrategicContribution = 'down';
    this.isShowManageableChallenge = true;
    this.strIsShowManageableChallenge = 'down';
    this.isShowManageablePortfolioRisk = true;
    this.strIsShowManageablePortfolioRisk = 'down';

    let IndexStrategicOpenList = this.DataListStrategicRiskTransaction.reduce(
      (acc, item, index) => {
        if (item.isShowStrategy) {
          acc.push(index);
        }
        if (item.isShowAssumption){
          acc.push(index);
        }
        return acc;
      },
      []
    );
    this.DataListStrategicRiskTransaction =
      this.DataListStrategicRiskTransaction.map((items) => {
        return {
          ...items,
          isShowStrategy: true,
          isShowAssumption: true,
          squareClass: 'down',
          squareClassAssumption: 'down',
        };
      });
    this.usePagination = false;

    setTimeout(() => {
      const pdf = new jsPDF();
      const pdfWidth = pdf.internal.pageSize.getWidth();

      const appendElement = async (argument: {
        elementIds: string[];
        padding?: number;
        paddingTop?: number;
        paddingLeft?: number;
        addPage?: boolean;
      }) => {
        const {
          elementIds = [],
          padding = 0,
          paddingTop = 0,
          paddingLeft = 0,
          addPage = false,
        } = argument;
        const newdiv = document.createElement('div');
        const newdivTmp = document.createElement('div');
        if (padding) {
          newdiv.style.paddingTop = padding + 'px';
          newdiv.style.paddingLeft = padding + 'px';
          newdivTmp.style.paddingTop = padding + 'px';
          newdivTmp.style.paddingLeft = padding + 'px';
        }
        if (paddingTop) {
          newdiv.style.paddingTop = paddingTop + 'px';
          newdivTmp.style.paddingTop = paddingTop + 'px';
        }
        if (paddingLeft) {
          newdiv.style.paddingLeft = paddingLeft + 'px';
          newdivTmp.style.paddingLeft = paddingLeft + 'px';
        }
        newdiv.style.paddingRight = '20px';
        newdiv.style.paddingBottom = '20px';
        newdivTmp.style.paddingRight = '20px';
        newdivTmp.style.paddingBottom = '20px';
        newdiv.style.width = '1800px';
        newdivTmp.style.width = '1800px';
        const checkHeight = async () => {
          document.body.appendChild(newdivTmp);
          const canvas2 = await html2canvas(newdivTmp);
          const getHeight = canvas2.height;
          document.body.removeChild(newdivTmp);
          return getHeight;
        };
        let newAppendItem: string[] = [];
        for (const item of elementIds) {
          const element = document.getElementById(item);
          newdivTmp.appendChild(element.cloneNode(true));
          const getHeight =
            elementIds.length > 1 && item == 'Strategic-Execution-Perspective'
              ? await checkHeight()
              : 0;
          if (getHeight > 2500) {
            newAppendItem.push(item);
          } else {
            newdiv.appendChild(element.cloneNode(true));
          }
        }
        //#region fix bug css box-shadow not show in pdf
        const liststrategyclickElements = newdiv.querySelectorAll('.liststrategyclick');
        liststrategyclickElements.forEach((liststrategyclickElement: HTMLElement) => {
          liststrategyclickElement.style.boxShadow = 'none';
        });
        const riskMatrixElements = newdiv.querySelectorAll('.riskmatrix');
        riskMatrixElements.forEach((riskMatrixElement: HTMLElement) => {
          riskMatrixElement.style.boxShadow = 'none';
        });
        const bggrayElements = newdiv.querySelectorAll('.bggray');
        bggrayElements.forEach((bggrayElement: HTMLElement) => {
          bggrayElement.style.boxShadow = 'none';
        });
        const bgwhiteElements = newdiv.querySelectorAll('.bgwhite');
        bgwhiteElements.forEach((bgwhiteElements: HTMLElement) => {
          bgwhiteElements.style.boxShadow = 'none';
        });
        //#endregion
        //#region fix bug clone dropdown not show value
        if (newdiv.querySelector("select") && newdiv.querySelector("select").options) {
          for (let i = 0; i < newdiv.querySelector("select").options.length; i++) {
            if (newdiv.querySelector("select").options[i].value == this.SearchKeyBU) {
              newdiv.querySelector("select").options[i].selected = true;
            }
          }
        }
        //#endregion
        document.body.appendChild(newdiv);
        const canvas = await html2canvas(newdiv);
        const imgData = canvas.toDataURL('image/png');
        if (addPage) pdf.addPage();
        if (canvas.height > 2500) {
          const pdfHeight = pdf.internal.pageSize.getHeight();
          const totalHeight = canvas.height / 8;
          const numPages = Math.ceil(totalHeight / pdfHeight);
          for (let i = 0; i < numPages; i++) {
            const startY = i * pdfHeight;
            pdf.addImage(imgData, 'PNG', 0, -startY, pdfWidth, totalHeight, undefined,'FAST');
            if (i < numPages - 1) {
              pdf.addPage();
            }
          }
        } else {
          pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, 0, undefined,'FAST');
        }
        document.body.removeChild(newdiv);
        if (newAppendItem.length > 0) {
          await appendElement({
            ...argument,
            elementIds: newAppendItem,
            addPage: true,
          });
        }
        return true;
      };

      const appendChildElement = async (argument: {
        elementIds: string[];
        padding?: number;
        paddingTop?: number;
        paddingLeft?: number;
        addPage?: boolean;
      }) => {
        const {
          elementIds = [],
          padding = 0,
          paddingTop = 0,
          paddingLeft = 0,
          addPage = false,
        } = argument;
        const newdiv = document.createElement('div');
        const newdivTmp = document.createElement('div');
        if (padding) {
          newdiv.style.paddingTop = padding + 'px';
          newdiv.style.paddingLeft = padding + 'px';
          newdivTmp.style.paddingTop = padding + 'px';
          newdivTmp.style.paddingLeft = padding + 'px';
        }
        if (paddingTop) {
          newdiv.style.paddingTop = paddingTop + 'px';
          newdivTmp.style.paddingTop = paddingTop + 'px';
        }
        if (paddingLeft) {
          newdiv.style.paddingLeft = paddingLeft + 'px';
          newdivTmp.style.paddingLeft = paddingLeft + 'px';
        }
        newdiv.style.paddingRight = '20px';
        newdiv.style.paddingBottom = '20px';
        newdivTmp.style.paddingRight = '20px';
        newdivTmp.style.paddingBottom = '20px';
        newdiv.style.width = '1800px';
        newdivTmp.style.width = '1800px';
        const checkHeight = async () => {
          document.body.appendChild(newdivTmp);
          const canvas2 = await html2canvas(newdivTmp);
          const getHeight = canvas2.height;
          document.body.removeChild(newdivTmp);
          return getHeight;
        };
        let newAppendItem: string[] = [];
        for (const item of elementIds) {
          const element = document.getElementById(item);
          newdivTmp.appendChild(element.cloneNode(true));
          const getHeight =
            elementIds.length > 1 && item == 'Strategic-Execution-Perspective'
              ? await checkHeight()
              : 0;
          if (getHeight > 2500) {
            newAppendItem.push(item);
          } else {
            newdiv.appendChild(element.cloneNode(true));
          }
        }
        //#region fix bug css box-shadow not show in pdf
        const liststrategyclickElements = newdiv.querySelectorAll('.liststrategyclick');
        liststrategyclickElements.forEach((liststrategyclickElement: HTMLElement) => {
          liststrategyclickElement.style.boxShadow = 'none';
        });
        const riskMatrixElements = newdiv.querySelectorAll('.riskmatrix');
        riskMatrixElements.forEach((riskMatrixElement: HTMLElement) => {
          riskMatrixElement.style.boxShadow = 'none';
        });
        const bggrayElements = newdiv.querySelectorAll('.bggray');
        bggrayElements.forEach((bggrayElement: HTMLElement) => {
          bggrayElement.style.boxShadow = 'none';
        });
        const bgwhiteElements = newdiv.querySelectorAll('.bgwhite');
        bgwhiteElements.forEach((bgwhiteElements: HTMLElement) => {
          bgwhiteElements.style.boxShadow = 'none';
        });
        //#endregion
        //#region fix bug clone dropdown not show value
        if (newdiv.querySelector("select") && newdiv.querySelector("select").options) {
          for (let i = 0; i < newdiv.querySelector("select").options.length; i++) {
            if (newdiv.querySelector("select").options[i].value == this.SearchKeyBU) {
              newdiv.querySelector("select").options[i].selected = true;
            }
          }
        }
        //#endregion
        document.body.appendChild(newdiv);
        const canvas = await html2canvas(newdiv);
        const imgData = canvas.toDataURL('image/png');
        if (addPage) pdf.addPage();

        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, 0);

        document.body.removeChild(newdiv);
        if (newAppendItem.length > 0) {
          await appendElement({
            ...argument,
            elementIds: newAppendItem,
            addPage: true,
          });
        }
        return true;
      };

      const appendAllElements = async () => {
        const status = await appendElement({
          elementIds: [
            'Summary-Share',
            'Summary-of-all-Strategies',
            'Strategic-Fit',
            'Strategic-Execution-Perspective',
          ],
          padding: 70,
        });
        var totalheight = 0
        var elemIdLst: string[] = [];
        if (status) {
          for (
            let i = 0;
            i < this.DataListStrategicRiskTransaction.length;
            i++
          ) {
            const elemId = `Strategy${i}`;

            totalheight += this.checkHeightElement(elemId);
            if(i == 0 )
            {
              pdf.addPage()
              totalheight += this.checkHeightElement('StrategyHeader');
              elemIdLst.push('StrategyHeader')
            }

            if(totalheight > 2500)
            {
              await appendChildElement({
                elementIds: elemIdLst,
                padding: 70,
                addPage: false,
              },);
              totalheight = 0;
              elemIdLst = [];
              totalheight += this.checkHeightElement(elemId);
              elemIdLst.push(elemId);
              pdf.addPage()
            }
            else
            {
              elemIdLst.push(elemId);
            }

            if( i == this.DataListStrategicRiskTransaction.length-1)
            {
              await appendChildElement({
                elementIds: elemIdLst,
                padding: 70,
                addPage: false,
              },);
              totalheight = 0;
              elemIdLst = [];
            }
          }

          totalheight = 0;
          elemIdLst = [];
          for (
            let i = 0;
            i < this.DataListStrategicRiskTransaction.length;
            i++
          ) {
            const elemId = `Assumption${i}`;

            totalheight += this.checkHeightElement(elemId);
            if(i == 0 )
            {
              pdf.addPage()
              totalheight += this.checkHeightElement("AssumptionHeader");
              elemIdLst.push('AssumptionHeader')
            }

            if(totalheight > 2500)
            {
              await appendChildElement({
                elementIds: elemIdLst,
                padding: 70,
                addPage: false,
              },);
              totalheight = 0;
              elemIdLst = [];
              totalheight += this.checkHeightElement(elemId);
              elemIdLst.push(elemId);
              pdf.addPage()
            }
            else
            {
              elemIdLst.push(elemId);
            }

            if( i == this.DataListStrategicRiskTransaction.length-1)
            {
              await appendChildElement({
                elementIds: elemIdLst,
                padding: 70,
                addPage: false,
              },);
            }
          }

        }

        let date = moment(new Date()).format("DDMMYYYY");
        let fileName = sessionStorage.getItem('buName') + '-' + 'StrategicRisk' + date + '.pdf'
        pdf.save(fileName);

        this.isShowStrategicAlignment = oldIsShowStrategicAlignment;
        this.strIsShowStrategicAlignment = oldStrIsShowStrategicAlignment;
        this.isShowStrategicContribution = oldIsShowStrategicContribution;
        this.strIsShowStrategicContribution = oldStrIsShowStrategicContribution;
        this.isShowManageableChallenge = oldIsShowManageableChallenge;
        this.strIsShowManageableChallenge = oldStrIsShowManageableChallenge;
        this.isShowManageablePortfolioRisk = oldIsShowManageablePortfolioRisk;
        this.strIsShowManageablePortfolioRisk =
          oldStrIsShowManageablePortfolioRisk;
        this.DataListStrategicRiskTransaction =
          this.DataListStrategicRiskTransaction.map((items, index) => {
            return {
              ...items,
              isShowStrategy: IndexStrategicOpenList.includes(index)
                ? true
                : false,
              squareClass: IndexStrategicOpenList.includes(index)
                ? 'down'
                : 'right',
              isShowAssumption: IndexStrategicOpenList.includes(index)
                ? true
                : false,
              squareClassAssumption: IndexStrategicOpenList.includes(index)
                ? 'down'
                : 'right',
                
            };
          });
        this.usePagination = true;
        this.loading = false;
      };
      appendAllElements();
    }, 0);
  }
  

  checkHeightElement(elementTemp) {
    const element = document.getElementById(elementTemp.toString());
    return element.offsetHeight;
  }
}
