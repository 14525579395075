<div class="container-fluid appform my-3">
  <div class="row">
    <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">
      <div class="col-12 col-md-12 col-lg-12 col-xl-12 pb-5 mt-5 mb-5">
        <div *ngIf="isOpenPageMonitor == false" class="px-0 pb-2">
          <div class="card col-12 mt-4 mx-0 p-3">
            <div class="row">
              <div class="coordinator col-3 ml-3 mb-3 rounded" style="height: 50px;">
                <div class="col-0 pt-2" style="font-size: 22px;">
                  <em class="fas fa-file-signature"></em>&nbsp;&nbsp;Monitor & Review
                </div>
              </div>
            </div>
            <div *ngIf="DataMonitorReview.length != 0" class="col-12 px-0 table-responsive">
              <label style="color: red;">RED ALERT: Strategic Assumption deviated from the favorable status</label>
              <div class="table-container" [style.max-height.px]="calculatedHeight" id="redalert">
                <table class="table-bordered" style="width:100%;">
                  <caption style="display: none;">This is a custom table</caption>
                  <thead style="text-align:center; background-color:#033153; color:white;">
                    <tr>
                      <th width="10%">Strategy</th>
                      <th width="10%">Assumption No.</th>
                      <th width="12%">Category</th>
                      <th width="20%">Name/Description</th>
                      <th width="7%">Current Status</th>
                      <th width="15%">Action If RED</th>
                      <th width="6%">% Progress</th>
                      <th width="20%">Rationale</th>
                    </tr>
                  </thead>
                  <tbody style="background-color:white;">
                    <tr *ngFor="let item of DataMonitorReview index as index1">
                      <td>{{ item.riskNo }}</td>
                      <td> Assumption {{index1 + 1}}</td>
                      <td>{{ item.riskCategoryName }}</td>
                      <td>{{ item.assumptionDescription }}</td>
                      <td style="text-align: center;">
                        <em class="fas fa-circle currentstatus-{{item.currentStatus | lowercase}}"></em>
                      </td>
                      <td>{{ item.actionRed }}</td>
                      <td style="text-align: center;">{{ item.percentProgress }} %</td>
                      <td>{{ item.rationale }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-12 mt-5 px-0 table-responsive">
              <div class="coordinator col-12 mb-3 rounded" style="width: 100%;">
                <div class="pt-2">Select Strategy</div>
              </div>
              <table class="table-bordered" style="width:100%; margin-top: -15px;">
                <caption style="display: none;">This is a custom table</caption>
                <tbody style="background-color:white;">
                  <tr
                    *ngFor="let item of DataStrategy | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize index as index1">
                    <td style="width: 10%;">{{ item.riskNo }}</td>
                    <td style="width: 10%;">{{ item.riskName }}</td>
                    <td style="width: 35%;">
                      <div *ngIf="item.riskStatus == 'Completed Assessment' || item.riskStatus == 'Revised'">
                        <button type="button" class="btn btn-lg mb-3" style="color:black;background:#D8E8FF;margin: 5px;box-shadow: 0px 1px 2px;border-radius:28px;"
                          (click)="func_MonitorStrategy(item)">
                          Strategic Assumption Monitoring
                        </button>
                        <button type="button" class="btn btn-lg mb-3" style="color:black;background:#FFD8E3;margin: 5px;box-shadow: 0px 1px 2px;border-radius:28px;"
                          (click)="func_ReviewStrategy(item)">
                          Assessment Review
                        </button>
                      </div>
                      <div *ngIf="item.riskStatus == 'Draft'">
                        <button type="button" class="btn btn-lg mb-3" style="color:black;background:#FFF7D8;margin: 5px;box-shadow: 0px 1px 2px;border-radius:28px;"
                          (click)="func_DraftStrategy(item)">
                          Waiting for Register
                        </button>
                      </div>
                    </td>
                    <td style="width: 10%; text-align: center;">
                      <button *ngIf="empid == item.riskRegisterBy || !empRole.includes('Staff')" type="button"
                        class="btn" (click)="func_ConfirmDeleteStrategy(item.riskId)"
                        style="height: 40px; width: 100px; background-color: red; color: white;">
                        <em class="far fa-trash-alt icon-in-table"></em>
                        Delete
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="pagination mt-4">
              <ngb-pagination [collectionSize]="DataStrategy.length" [(page)]="page" [pageSize]="pageSize" [maxSize]="5"
                [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
                <ng-template ngbPaginationPrevious>Prev</ng-template>
                <ng-template ngbPaginationNext>Next</ng-template>
              </ngb-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-modal-selectbu [title]="titleBU" (returnEvent)="returnEvent($event)"></app-modal-selectbu>
<app-modal-alert *ngIf="isOpenPageMonitor == false" (confirmed)="func_DeleteStrategy()" [title]="popupTitleAlert"
  [titleSuccess]="popupTitleAlert" [message]="popupMessageAlert" [isRefresh]="isRefresh"></app-modal-alert>

<app-loading [loading]='loading'></app-loading>