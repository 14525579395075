<table class="custom table" id="table1" [style.Width]="_width">
    <caption style="display: none;">This is a custom table</caption>
    <thead>
      
    </thead>
    <tbody>
        <tr>
            <th scope="row" class="rotate-box" style="background-color: #161617;">
                <div class ="rotate font-responsive" style="color: white;">
                    Critical
                </div>c
            </th>
            <td style="background-color:yellow;">
                <div class="box-dot">
                    <ng-container *ngFor="let item of dataTable[0][4] let index = index">
                        <span *ngIf="index < 8; else elseBlock">
                            <span class="badge badge-pill badge-light txt-title"
                            [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                            data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                            </span>
                        </span>
                        <ng-template #elseBlock>
                            <span *ngIf="index == 8">
                                <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span class="badge badge-pill badge-light txt-title dotexpand"
                                        [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                        data-toggle="tooltip" data-placement="top"><strong>...</strong>
                                    </span>
                                    <div class="dropdown-menu dropdown-menu-top speech-bubble1">
                                        <span *ngFor="let item of dataTable[0][4].slice(8)">
                                            <span class="badge badge-pill badge-light txt-title"
                                            [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                            data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                                            </span>
                                        </span>
                                    </div>
                                  </div>
                            </span>
                        </ng-template>
                    </ng-container>
                </div>
            </td>
            <td style="background-color:yellow;">
                <div class="box-dot">
                    <ng-container *ngFor="let item of dataTable[1][4] let index = index">
                        <span *ngIf="index < 8; else elseBlock">
                            <span class="badge badge-pill badge-light txt-title"
                            [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                            data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                            </span>
                        </span>
                        <ng-template #elseBlock>
                            <span *ngIf="index == 8">
                                <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span class="badge badge-pill badge-light txt-title dotexpand"
                                        [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                        data-toggle="tooltip" data-placement="top"><strong>...</strong>
                                    </span>
                                    <div class="dropdown-menu dropdown-menu-top speech-bubble1">
                                        <span *ngFor="let item of dataTable[1][4].slice(8)">
                                            <span class="badge badge-pill badge-light txt-title"
                                            [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                            data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                                            </span>
                                        </span>
                                    </div>
                                  </div>
                            </span>
                        </ng-template>
                    </ng-container>
                </div>
            </td>
            <td style="background-color:red;">
                <div class="box-dot">
                    <ng-container *ngFor="let item of dataTable[2][4] let index = index">
                        <span *ngIf="index < 8; else elseBlock">
                            <span class="badge badge-pill badge-light txt-title"
                            [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                            data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                            </span>
                        </span>
                        <ng-template #elseBlock>
                            <span *ngIf="index == 8">
                                <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span class="badge badge-pill badge-light txt-title dotexpand"
                                        [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                        data-toggle="tooltip" data-placement="top"><strong>...</strong>
                                    </span>
                                    <div class="dropdown-menu dropdown-menu-top speech-bubble1">
                                        <span *ngFor="let item of dataTable[2][4].slice(8)">
                                            <span class="badge badge-pill badge-light txt-title"
                                            [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                            data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                                            </span>
                                        </span>
                                    </div>
                                  </div>
                            </span>
                        </ng-template>
                    </ng-container>
                </div>
            </td>
            <td style="background-color:red;">
                <div class="box-dot">
                    <ng-container *ngFor="let item of dataTable[3][4] let index = index">
                        <span *ngIf="index < 8; else elseBlock">
                            <span class="badge badge-pill badge-light txt-title"
                            [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                            data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                            </span>
                        </span>
                        <ng-template #elseBlock>
                            <span *ngIf="index == 8">
                                <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span class="badge badge-pill badge-light txt-title dotexpand"
                                        [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                        data-toggle="tooltip" data-placement="top"><strong>...</strong>
                                    </span>
                                    <div class="dropdown-menu dropdown-menu-top speech-bubble1">
                                        <span *ngFor="let item of dataTable[3][4].slice(8)">
                                            <span class="badge badge-pill badge-light txt-title"
                                            [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                            data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                                            </span>
                                        </span>
                                    </div>
                                  </div>
                            </span>
                        </ng-template>
                    </ng-container>
                </div>
            </td>
            <td style="background-color:red;">
                <div class="box-dot">
                    <ng-container *ngFor="let item of dataTable[4][4] let index = index">
                        <span *ngIf="index < 8; else elseBlock">
                            <span class="badge badge-pill badge-light txt-title"
                            [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                            data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                            </span>
                        </span>
                        <ng-template #elseBlock>
                            <span *ngIf="index == 8">
                                <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span class="badge badge-pill badge-light txt-title dotexpand"
                                        [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                        data-toggle="tooltip" data-placement="top"><strong>...</strong>
                                    </span>
                                    <div class="dropdown-menu dropdown-menu-top speech-bubble1">
                                        <span *ngFor="let item of dataTable[4][4].slice(8)">
                                            <span class="badge badge-pill badge-light txt-title"
                                            [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                            data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                                            </span>
                                        </span>
                                    </div>
                                  </div>
                            </span>
                        </ng-template>
                    </ng-container>
                </div>
            </td>
          </tr>
      <tr>
        <th scope="row" class="rotate-box" style="background-color: #3A3836;">
            <div class="rotate font-responsive" style="color: white;">
                Serious
            </div>
        </th>
        <td style="background-color:yellow;">
            <div class="box-dot">
                <ng-container *ngFor="let item of dataTable[0][3] let index = index">
                <span *ngIf="index < 8; else elseBlock">
                    <span class="badge badge-pill badge-light txt-title"
                    [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                    </span>
                </span>
                <ng-template #elseBlock>
                    <span *ngIf="index == 8">
                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="badge badge-pill badge-light txt-title dotexpand"
                                [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                data-toggle="tooltip" data-placement="top"><strong>...</strong>
                            </span>
                            <div class="dropdown-menu dropdown-menu-top speech-bubble1">
                                <span *ngFor="let item of dataTable[0][3].slice(8)">
                                    <span class="badge badge-pill badge-light txt-title"
                                    [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                                    </span>
                                </span>
                            </div>
                          </div>
                    </span>
                </ng-template>
            </ng-container>
            </div>
        </td>
        <td style="background-color:yellow;">
            <div class="box-dot">
                <ng-container *ngFor="let item of dataTable[1][3] let index = index">
                <span *ngIf="index < 8; else elseBlock">
                    <span class="badge badge-pill badge-light txt-title"
                    [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                    </span>
                </span>
                <ng-template #elseBlock>
                    <span *ngIf="index == 8">
                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="badge badge-pill badge-light txt-title dotexpand"
                                [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                data-toggle="tooltip" data-placement="top"><strong>...</strong>
                            </span>
                            <div class="dropdown-menu dropdown-menu-top speech-bubble1">
                                <span *ngFor="let item of dataTable[1][3].slice(8)">
                                    <span class="badge badge-pill badge-light txt-title"
                                    [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                                    </span>
                                </span>
                            </div>
                          </div>
                    </span>
                </ng-template>
            </ng-container>
            </div>
        </td>
        <td style="background-color:yellow;">
            <div class="box-dot">
                <ng-container *ngFor="let item of dataTable[2][3] let index = index">
                <span *ngIf="index < 8; else elseBlock">
                    <span class="badge badge-pill badge-light txt-title"
                    [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                    </span>
                </span>
                <ng-template #elseBlock>
                    <span *ngIf="index == 8">
                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="badge badge-pill badge-light txt-title dotexpand"
                                [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                data-toggle="tooltip" data-placement="top"><strong>...</strong>
                            </span>
                            <div class="dropdown-menu dropdown-menu-top speech-bubble1">
                                <span *ngFor="let item of dataTable[2][3].slice(8)">
                                    <span class="badge badge-pill badge-light txt-title"
                                    [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                                    </span>
                                </span>
                            </div>
                          </div>
                    </span>
                </ng-template>
            </ng-container>
            </div>
        </td>
        <td style="background-color:red;">
            <div class="box-dot">
                <ng-container *ngFor="let item of dataTable[3][3] let index = index">
                <span *ngIf="index < 8; else elseBlock">
                    <span class="badge badge-pill badge-light txt-title"
                    [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                    </span>
                </span>
                <ng-template #elseBlock>
                    <span *ngIf="index == 8">
                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="badge badge-pill badge-light txt-title dotexpand"
                                [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                data-toggle="tooltip" data-placement="top"><strong>...</strong>
                            </span>
                            <div class="dropdown-menu dropdown-menu-top speech-bubble1">
                                <span *ngFor="let item of dataTable[3][3].slice(8)">
                                    <span class="badge badge-pill badge-light txt-title"
                                    [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                                    </span>
                                </span>
                            </div>
                          </div>
                    </span>
                </ng-template>
            </ng-container>
            </div>
        </td>
        <td style="background-color:red;">
            <div class="box-dot">
                <ng-container *ngFor="let item of dataTable[4][3] let index = index">
                <span *ngIf="index < 8; else elseBlock">
                    <span class="badge badge-pill badge-light txt-title"
                    [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                    </span>
                </span>
                <ng-template #elseBlock>
                    <span *ngIf="index == 8">
                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="badge badge-pill badge-light txt-title dotexpand"
                                [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                data-toggle="tooltip" data-placement="top"><strong>...</strong>
                            </span>
                            <div class="dropdown-menu dropdown-menu-top speech-bubble1">
                                <span *ngFor="let item of dataTable[4][3].slice(8)">
                                    <span class="badge badge-pill badge-light txt-title"
                                    [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                                    </span>
                                </span>
                            </div>
                          </div>
                    </span>
                </ng-template>
            </ng-container>
            </div>
        </td>
      </tr>
      <tr>
        <th scope="row" class="rotate-box" style="background-color: #74706F;">
            <div class ="rotate font-responsive">
                Significant
            </div>
        </th>
        <td style="background-color:green;">
            <div class="box-dot">
                <ng-container *ngFor="let item of dataTable[0][2] let index = index">
                <span *ngIf="index < 8; else elseBlock">
                    <span class="badge badge-pill badge-light txt-title"
                    [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                    </span>
                </span>
                <ng-template #elseBlock>
                    <span *ngIf="index == 8">
                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="badge badge-pill badge-light txt-title dotexpand"
                                [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                data-toggle="tooltip" data-placement="top"><strong>...</strong>
                            </span>
                            <div class="dropdown-menu dropdown-menu-top speech-bubble1">
                                <span *ngFor="let item of dataTable[0][2].slice(8)">
                                    <span class="badge badge-pill badge-light txt-title"
                                    [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                                    </span>
                                </span>
                            </div>
                          </div>
                    </span>
                </ng-template>
            </ng-container>
            </div>
        </td>
        <td style="background-color:yellow;">
            <div class="box-dot">
                <ng-container *ngFor="let item of dataTable[1][2] let index = index">
                <span *ngIf="index < 8; else elseBlock">
                    <span class="badge badge-pill badge-light txt-title"
                    [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                    </span>
                </span>
                <ng-template #elseBlock>
                    <span *ngIf="index == 8">
                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="badge badge-pill badge-light txt-title dotexpand"
                                [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                data-toggle="tooltip" data-placement="top"><strong>...</strong>
                            </span>
                            <div class="dropdown-menu dropdown-menu-top speech-bubble1">
                                <span *ngFor="let item of dataTable[1][2].slice(8)">
                                    <span class="badge badge-pill badge-light txt-title"
                                    [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                                    </span>
                                </span>
                            </div>
                          </div>
                    </span>
                </ng-template>
            </ng-container>
            </div>
        </td>
        <td style="background-color:yellow;">
            <div class="box-dot">
                <ng-container *ngFor="let item of dataTable[2][2] let index = index">
                <span *ngIf="index < 8; else elseBlock">
                    <span class="badge badge-pill badge-light txt-title"
                    [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                    </span>
                </span>
                <ng-template #elseBlock>
                    <span *ngIf="index == 8">
                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="badge badge-pill badge-light txt-title dotexpand"
                                [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                data-toggle="tooltip" data-placement="top"><strong>...</strong>
                            </span>
                            <div class="dropdown-menu dropdown-menu-top speech-bubble1">
                                <span *ngFor="let item of dataTable[2][2].slice(8)">
                                    <span class="badge badge-pill badge-light txt-title"
                                    [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                                    </span>
                                </span>
                            </div>
                          </div>
                    </span>
                </ng-template>
            </ng-container>
            </div>
        </td>
        <td style="background-color:yellow;">
            <div class="box-dot">
                <ng-container *ngFor="let item of dataTable[3][2] let index = index">
                <span *ngIf="index < 8; else elseBlock">
                    <span class="badge badge-pill badge-light txt-title"
                    [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                    </span>
                </span>
                <ng-template #elseBlock>
                    <span *ngIf="index == 8">
                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="badge badge-pill badge-light txt-title dotexpand"
                                [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                data-toggle="tooltip" data-placement="top"><strong>...</strong>
                            </span>
                            <div class="dropdown-menu dropdown-menu-top speech-bubble1">
                                <span *ngFor="let item of dataTable[3][2].slice(8)">
                                    <span class="badge badge-pill badge-light txt-title"
                                    [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                                    </span>
                                </span>
                            </div>
                          </div>
                    </span>
                </ng-template>
            </ng-container>
            </div>
        </td>
        <td style="background-color:red;">
            <div class="box-dot">
                <ng-container *ngFor="let item of dataTable[4][2] let index = index">
                <span *ngIf="index < 8; else elseBlock">
                    <span class="badge badge-pill badge-light txt-title"
                    [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                    </span>
                </span>
                <ng-template #elseBlock>
                    <span *ngIf="index == 8">
                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="badge badge-pill badge-light txt-title dotexpand"
                                [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                data-toggle="tooltip" data-placement="top"><strong>...</strong>
                            </span>
                            <div class="dropdown-menu dropdown-menu-top speech-bubble1">
                                <span *ngFor="let item of dataTable[4][2].slice(8)">
                                    <span class="badge badge-pill badge-light txt-title"
                                    [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                                    </span>
                                </span>
                            </div>
                          </div>
                    </span>
                </ng-template>
            </ng-container>
            </div>
        </td>
      </tr>
      <tr>
        <th scope="row" class="rotate-box" style="background-color: #B1ACAA;">
            <div class ="rotate font-responsive">
                Moderate
            </div>
        </th>
        <td style="background-color:green;">
            <div class="box-dot">
                <ng-container *ngFor="let item of dataTable[0][1] let index = index">
                <span *ngIf="index < 8; else elseBlock">
                    <span class="badge badge-pill badge-light txt-title"
                    [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                    </span>
                </span>
                <ng-template #elseBlock>
                    <span *ngIf="index == 8">
                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="badge badge-pill badge-light txt-title dotexpand"
                                [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                data-toggle="tooltip" data-placement="top"><strong>...</strong>
                            </span>
                            <div class="dropdown-menu dropdown-menu-top speech-bubble1">
                                <span *ngFor="let item of dataTable[0][1].slice(8)">
                                    <span class="badge badge-pill badge-light txt-title"
                                    [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                                    </span>
                                </span>
                            </div>
                          </div>
                    </span>
                </ng-template>
            </ng-container>
            </div>
        </td>
        <td style="background-color:green;">
            <div class="box-dot">
                <ng-container *ngFor="let item of dataTable[1][1] let index = index">
                <span *ngIf="index < 8; else elseBlock">
                    <span class="badge badge-pill badge-light txt-title"
                    [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                    </span>
                </span>
                <ng-template #elseBlock>
                    <span *ngIf="index == 8">
                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="badge badge-pill badge-light txt-title dotexpand"
                                [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                data-toggle="tooltip" data-placement="top"><strong>...</strong>
                            </span>
                            <div class="dropdown-menu dropdown-menu-top speech-bubble1">
                                <span *ngFor="let item of dataTable[1][1].slice(8)">
                                    <span class="badge badge-pill badge-light txt-title"
                                    [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                                    </span>
                                </span>
                            </div>
                          </div>
                    </span>
                </ng-template>
            </ng-container>
            </div>
        </td>
        <td style="background-color:yellow;">
            <div class="box-dot">
                <ng-container *ngFor="let item of dataTable[2][1] let index = index">
                <span *ngIf="index < 8; else elseBlock">
                    <span class="badge badge-pill badge-light txt-title"
                    [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                    </span>
                </span>
                <ng-template #elseBlock>
                    <span *ngIf="index == 8">
                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="badge badge-pill badge-light txt-title dotexpand"
                                [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                data-toggle="tooltip" data-placement="top"><strong>...</strong>
                            </span>
                            <div class="dropdown-menu dropdown-menu-top speech-bubble1">
                                <span *ngFor="let item of dataTable[2][1].slice(8)">
                                    <span class="badge badge-pill badge-light txt-title"
                                    [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                                    </span>
                                </span>
                            </div>
                          </div>
                    </span>
                </ng-template>
            </ng-container>
            </div>
        </td>
        <td style="background-color:yellow;">
            <div class="box-dot">
                <ng-container *ngFor="let item of dataTable[3][1] let index = index">
                <span *ngIf="index < 8; else elseBlock">
                    <span class="badge badge-pill badge-light txt-title"
                    [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                    </span>
                </span>
                <ng-template #elseBlock>
                    <span *ngIf="index == 8">
                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="badge badge-pill badge-light txt-title dotexpand"
                                [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                data-toggle="tooltip" data-placement="top"><strong>...</strong>
                            </span>
                            <div class="dropdown-menu dropdown-menu-top speech-bubble1">
                                <span *ngFor="let item of dataTable[3][1].slice(8)">
                                    <span class="badge badge-pill badge-light txt-title"
                                    [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                                    </span>
                                </span>
                            </div>
                          </div>
                    </span>
                </ng-template>
            </ng-container>
            </div>
        </td>
        <td style="background-color:yellow;">
            <div class="box-dot">
                <ng-container *ngFor="let item of dataTable[4][1] let index = index">
                <span *ngIf="index < 8; else elseBlock">
                    <span class="badge badge-pill badge-light txt-title"
                    [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                    </span>
                </span>
                <ng-template #elseBlock>
                    <span *ngIf="index == 8">
                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="badge badge-pill badge-light txt-title dotexpand"
                                [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                data-toggle="tooltip" data-placement="top"><strong>...</strong>
                            </span>
                            <div class="dropdown-menu dropdown-menu-top speech-bubble1">
                                <span *ngFor="let item of dataTable[4][1].slice(8)">
                                    <span class="badge badge-pill badge-light txt-title"
                                    [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                                    </span>
                                </span>
                            </div>
                          </div>
                    </span>
                </ng-template>
            </ng-container>
            </div>
        </td>
      </tr>
      <tr>
        <th scope="row" class="rotate-box"  style="background-color: #D1CECE;" >
            <div class ="rotate font-responsive">
                    Minor
            </div>
        </th>
        <td style="background-color:green;">
            <div class="box-dot">
                <ng-container *ngFor="let item of dataTable[0][0] let index = index">
                <span *ngIf="index < 8; else elseBlock">
                    <span class="badge badge-pill badge-light txt-title"
                    [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                    </span>
                </span>
                <ng-template #elseBlock>
                    <span *ngIf="index == 8">
                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="badge badge-pill badge-light txt-title dotexpand"
                                [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                data-toggle="tooltip" data-placement="top"><strong>...</strong>
                            </span>
                            <div class="dropdown-menu dropdown-menu-top speech-bubble1">
                                <span *ngFor="let item of dataTable[0][0].slice(8)">
                                    <span class="badge badge-pill badge-light txt-title"
                                    [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                                    </span>
                                </span>
                            </div>
                          </div>
                    </span>
                </ng-template>
            </ng-container>
            </div>
        </td>
        <td style="background-color:green;">
            <div class="box-dot">
                <ng-container *ngFor="let item of dataTable[1][0] let index = index">
                <span *ngIf="index < 8; else elseBlock">
                    <span class="badge badge-pill badge-light txt-title"
                    [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                    </span>
                </span>
                <ng-template #elseBlock>
                    <span *ngIf="index == 8">
                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="badge badge-pill badge-light txt-title dotexpand"
                                [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                data-toggle="tooltip" data-placement="top"><strong>...</strong>
                            </span>
                            <div class="dropdown-menu dropdown-menu-top speech-bubble1">
                                <span *ngFor="let item of dataTable[1][0].slice(8)">
                                    <span class="badge badge-pill badge-light txt-title"
                                    [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                                    </span>
                                </span>
                            </div>
                          </div>
                    </span>
                </ng-template>
            </ng-container>
            </div>
        </td>
        <td style="background-color:green;">
            <div class="box-dot">
                <ng-container *ngFor="let item of dataTable[2][0] let index = index">
                <span *ngIf="index < 8; else elseBlock">
                    <span class="badge badge-pill badge-light txt-title"
                    [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                    </span>
                </span>
                <ng-template #elseBlock>
                    <span *ngIf="index == 8">
                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="badge badge-pill badge-light txt-title dotexpand"
                                [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                data-toggle="tooltip" data-placement="top"><strong>...</strong>
                            </span>
                            <div class="dropdown-menu dropdown-menu-top speech-bubble1">
                                <span *ngFor="let item of dataTable[2][0].slice(8)">
                                    <span class="badge badge-pill badge-light txt-title"
                                    [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                                    </span>
                                </span>
                            </div>
                          </div>
                    </span>
                </ng-template>
            </ng-container>
            </div>
        </td>
        <td style="background-color:yellow;">
            <div class="box-dot">
                <ng-container *ngFor="let item of dataTable[3][0] let index = index">
                <span *ngIf="index < 8; else elseBlock">
                    <span class="badge badge-pill badge-light txt-title"
                    [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                    </span>
                </span>
                <ng-template #elseBlock>
                    <span *ngIf="index == 8">
                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="badge badge-pill badge-light txt-title dotexpand"
                                [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                data-toggle="tooltip" data-placement="top"><strong>...</strong>
                            </span>
                            <div class="dropdown-menu dropdown-menu-top speech-bubble1">
                                <span *ngFor="let item of dataTable[3][0].slice(8)">
                                    <span class="badge badge-pill badge-light txt-title"
                                    [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                                    </span>
                                </span>
                            </div>
                          </div>
                    </span>
                </ng-template>
            </ng-container>
            </div>
        </td>
        <td style="background-color:yellow;">
            <div class="box-dot">
                <ng-container *ngFor="let item of dataTable[4][0] let index = index">
                <span *ngIf="index < 8; else elseBlock">
                    <span class="badge badge-pill badge-light txt-title"
                    [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                    </span>
                </span>
                <ng-template #elseBlock>
                    <span *ngIf="index == 8">
                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="badge badge-pill badge-light txt-title dotexpand"
                                [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                data-toggle="tooltip" data-placement="top"><strong>...</strong>
                            </span>
                            <div class="dropdown-menu dropdown-menu-top speech-bubble1">
                                <span *ngFor="let item of dataTable[4][0].slice(8)">
                                    <span class="badge badge-pill badge-light txt-title"
                                    [ngClass]="{'dotblack': item.value == 1 , 'dotgray': item.value == 2}"
                                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                                    </span>
                                </span>
                            </div>
                          </div>
                    </span>
                </ng-template>
            </ng-container>
            </div>
        </td>
      </tr>
      <tr>
        <th style="height: 40px !important; width: 5% !important"></th>
        <th class="column-text-table" style="height: 40px !important; padding: 0rem!important; vertical-align:middle; background-color: #E5EDF9;">
            <div class ="font-responsive">
                Rare
            </div>
        </th>
        <th class="column-text-table" style="height: 40px !important; padding: 0rem!important; vertical-align:middle; background-color: #B9CEEF;">
            <div class ="font-responsive">
                Unlikely
            </div>
        </th>
        <th class="column-text-table" style="height: 40px !important; padding: 0rem!important; vertical-align:middle; background-color: #6FA0DD;">
            <div class ="font-responsive">
                Possible
            </div>
        </th>
        <th class="column-text-table" style="height: 40px !important; padding: 0rem!important; vertical-align:middle; background-color: #3070C0;">
            <div class ="font-responsive" style="color: white;">
                Likely
            </div>
        </th>
        <th class="column-text-table" style="height: 40px !important; padding: 0rem!important; vertical-align:middle; background-color: #42556B;">
            <div class ="font-responsive" style="color: white;">
                Almost certain
            </div>
        </th>
      </tr>
    </tbody>
</table>
