import { Component,OnInit } from "@angular/core";
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Masterserve } from '../../../services/master.service';
declare let $;

@Component({
    selector: 'app-MasterQuarter',
    templateUrl: './masterquarter.html',
    styleUrls: ['./masterquarter.css'],
    providers: [Masterserve, NgbModalConfig, NgbModal]
})

export class MasterQuarter implements OnInit {
    constructor(config: NgbModalConfig, private modalService: NgbModal,
        public mserve: Masterserve) {
          config.backdrop = 'static';
          config.keyboard = false; 
        }

    tablequarter;
    BusinessName;
    searchText;

    QuaterStart;
    QuaterEnd;
    LockMaster;
    BuCoodinator;
    BusinessUniteValue;
    ImpactRating;
    LikelihoodRating;
    RiskCategory;
    RiskRating;

    SessionEmpID;
    Data_SessionStorage;

    ngOnInit(): void {
        let _s = this;

        this.Data_SessionStorage = JSON.parse(sessionStorage.datainfo);
        //console.log('Data_SessionStorage => ',this.Data_SessionStorage);
        this.SessionEmpID = this.Data_SessionStorage[0].employeE_ID;

        this.func_Getlist();
    }

    func_Getlist () {
        const _s = this;
        let datavalue = {
            SessionEmpID:this.SessionEmpID,
            Module:"getlist",
            body:{
                QuarterID:"",
                QuarterCode:"TM001",
                Year:"2020",
                QuarterName:"q2",
                BuCoodinator:"0",
                BusinessUniteValue:"0",
                ImpactRating:"1",
                LikelihoodRating:"1",
                RiskCategory:"1",
                RiskRating:"0",
                StartQuarter:"",
                EndQuarter:"",
                LockDate:"",
                DelFlag:"0"
            }
        }

        _s.mserve.GetQuarter(datavalue).then((response) => {
            if(response.data.status === true){
                _s.tablequarter = response.data.body;
                for (let item_tablequarter of _s.tablequarter){
                    item_tablequarter.buCoodinator = parseInt(item_tablequarter.buCoodinator);
                    item_tablequarter.businessUniteValue = parseInt(item_tablequarter.businessUniteValue);
                    item_tablequarter.impactRating = parseInt(item_tablequarter.impactRating);
                    item_tablequarter.likelihoodRating = parseInt(item_tablequarter.likelihoodRating);
                    item_tablequarter.riskCategory = parseInt(item_tablequarter.riskCategory);
                    item_tablequarter.riskRating = parseInt(item_tablequarter.riskRating);
                }
                //console.log('data',_s.tablequarter)
            }else{
                //console.log('false',response)
                alert(response.data.errorMessage);
            }
        })
    }
    
    func_formatdate(date){
        let sdate = date.year + '-' + date.month + '-' + date.day
        return sdate;
    }
    func_qartername(month){
        if(month == 1 || month == 2 || month == 3){
            return 'Q1'
        }
        else if(month == 4 || month == 5 || month == 6){
            return 'Q2'
        }
        else if(month == 7 || month == 8 || month == 9){
            return 'Q3'
        }
        else if(month == 10 || month == 11 || month == 12){
            return 'Q4'
        }
    }
    func_addriskrating(){
        let _s = this;

        _s.QuaterStart = "";
        _s.QuaterEnd = "";
        _s.LockMaster = "";

        $('#modaladdcoordinator').modal('show');
    }
    func_savebtn(){
        let _s = this;
        let datavalue = {
            SessionEmpID:this.SessionEmpID,
            Module:"insert",
            body:{
                Year:_s.QuaterStart.year,
                QuarterName:_s.func_qartername(_s.QuaterStart.month),
                BuCoodinator:_s.BuCoodinator ? '1' : '0',
                BusinessUniteValue:_s.BusinessUniteValue ? '1' : '0',
                ImpactRating:_s.ImpactRating ? '1' : '0',
                LikelihoodRating:_s.LikelihoodRating ? '1' : '0',
                RiskCategory:_s.RiskCategory ? '1' : '0',
                RiskRating:_s.RiskRating ? '1' : '0',
                StartQuarter:_s.func_formatdate(_s.QuaterStart),
                EndQuarter:_s.func_formatdate(_s.QuaterEnd),
                LockDate:_s.func_formatdate(_s.LockMaster),
                DelFlag:"0"
            }
        }

        //console.log('datavalue',datavalue)

        _s.mserve.GetQuarter(datavalue).then((response) => {
            if(response.data.status === true){
                _s.ngOnInit()
                //console.log('data',_s.tablequarter)
            }else{
                //console.log('false',response)
                alert(response.data.errorMessage);
            }
        }).catch((error) => {
            //console.log(error);
        });

        $('#modaladdcoordinator').modal('hide');
    }
    func_closebtn(){
        $('#modaladdcoordinator').modal('hide');
    }
}