import { Component, OnInit, Injectable, ViewChild, ElementRef } from "@angular/core";
import { NgbModal, NgbModalConfig, NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Masterserve } from '../../../services/master.service';
import * as XLSX from 'xlsx';
import { ExcelService2 } from '../../../services/excel2.service';
declare let $;

import { UntypedFormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AuthenService } from "src/app/services/authenservice.service";

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

    readonly DELIMITER = '/';

    parse(value: string): NgbDateStruct | null {
        if (value) {
            let date = value.split(this.DELIMITER);
            return {
                day: (date[0], 10),
                month: (date[1], 10),
                year: (date[2], 10)
            };
        }
        return null;
    }

    format(date: NgbDateStruct | null): string {
        if (date !== null) {
            let day = date.day.toString()
            let month = date.month.toString()
            if (day.length < 2) {
                day = '0' + day
            }
            if (month.length < 2) {
                month = '0' + month
            }
            let newdate = day + this.DELIMITER + month + this.DELIMITER + date.year;
            return newdate;
        } else {
            return '';
        }
    }
}


@Component({
    selector: 'app-MasterBuCoordinator',
    templateUrl: './masterbucoordinator.html',
    styleUrls: ['./masterbucoordinator.css'],
    providers: [Masterserve, NgbModalConfig, NgbModal, { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }]
})

export class MasterBuCoordinator implements OnInit {
    myControlEmployeeID = new UntypedFormControl();
    EmployeeDD_auto = [];
    filteredOptionsEmployeeIDDD: Observable<string[]>;

    myControlBUDD = new UntypedFormControl();
    BUDD_auto = [];
    filteredOptionsBUDD: Observable<string[]>;

    myControlEmployeeReplace = new UntypedFormControl();
    EmployeeDD_autoReplace = [];
    filteredOptionsEmployeeIDDDReplace: Observable<string[]>;

    constructor(public authserviceToken: AuthenService, config: NgbModalConfig, private modalService: NgbModal, public mserve: Masterserve, private excelService2: ExcelService2) {
        config.backdrop = 'static';
        config.keyboard = false;
    }

    Data_SessionStorage;

    tempReplace;
    ReplaceEmp;
    SizeCollection;
    BusinessName;
    EmployeeDD;
    YearDD;
    Module;
    model;
    tablecoordinator;
    search;
    tablequarter;
    loading = false;
    tempdepart = '';
    QuarterDD;
    Quarter_Getlist;
    AddCo_QuarterDD;
    ExJson = [];
    keys: string[];
    dataSheet = new Subject();
    @ViewChild('inputFile') inputFile: ElementRef;
    Msgvalidate = ''; 
    ConfirmReplace='';
    TempdataEmployee = [];
    DataFG = [];
    DatainFG = [
        {
            FG: 'SBD',
            CO: 'User1',
            div: [
                {
                    DIV: 'SCS',
                    CO: 'User2',
                    dept: [
                        {
                            DEPT: 'SEP',
                            CO: 'User3'
                        }
                    ]
                }
            ]
        }
    ]
    OptimizedData = []
    TempdataAPICR = [];
    TempSelectFG = [];
    TempindexSelectFG = null;

    TempdataExcel;

    page = 1;
    pageSize = 20;

    currentyear;
    tempchkyear;

    ngOnInit(): void {
        let _s = this;
        this.Data_SessionStorage = JSON.parse(sessionStorage.datainfo);

        this.myControlBUDD.disable();

        let date = new Date();
        _s.currentyear = date.getFullYear();
        _s.tempchkyear = date.getFullYear();

        _s.model = {
            CoordinatorId: "",
            Year: "",
            QuarterID: "",
            EmpID: "",
            EmpName: "",
            EmpEmail: "",
            DeptID: "",
            Level: "",
            EmpBU: "",
            CoorBU: "",
            CoorBUID: "",
            CoorBULevel: "",
            Quarter: "",
            DelFlag: ""
        }

        _s.search = {
            BusinessId: "",
            searchText: "",
            Year: "",
            Quarter: ""
        }

        _s.ReplaceEmp = {
            EmpID: '',
            EmpName: '',
            EmpEmail: '',
            EmpBU: '',
            Emp_CoordinatorId: ''
        }

        _s.loadDDL();
    }

    loadDDL() {
        let _s = this;
        _s.mserve.Master_DDL({ Module: "EmployeeDD" }).then((response) => {
            _s.EmployeeDD = response.data.body;
            _s.EmployeeDD_auto = response.data.body;
            //console.log('_s.EmployeeDD', _s.EmployeeDD)
            this.filteredOptionsEmployeeIDDD = this.myControlEmployeeID.valueChanges.pipe(
                startWith(''),
                map(value => this._filterEmployeeIDDD(value))
            );

        })

        _s.mserve.Master_DDL({ Module: "DepartmentDD" }).then((response) => {
            _s.BUDD_auto = response.data.body;
            _s.BusinessName = response.data.body;
            //console.log('DepartmentDD', _s.BUDD_auto)

            this.filteredOptionsBUDD = this.myControlBUDD.valueChanges.pipe(
                startWith(''),
                map(value => this._filterBUDD(value))
            );

        })

        _s.mserve.Master_DDL({ Module: "YearDD" }).then((response) => {
            _s.YearDD = response.data.body
            //console.log("YearDD : ", _s.YearDD)
        })

        let d = new Date();
        _s.search.Year = d.getFullYear();
        let currentMonth = d.getMonth() + 1;
        _s.mserve.Master_DDL({ Module: "QuarterDD", TextSearch1: _s.search.Year }).then((response) => {
            _s.QuarterDD = response.data.body
            //console.log("QuarterDD : ", _s.QuarterDD)
            this.func_calQuarter(currentMonth);
        })

    }

    func_calQuarter(month) {

        this.search.Quarter = ''
        if (month >= 1 && month <= 3) {
            this.search.Quarter = this.QuarterDD[0].value;
        } else if (month >= 4 && month <= 6) {
            this.search.Quarter = this.QuarterDD[1].value;
        } else if (month >= 7 && month <= 9) {
            this.search.Quarter = this.QuarterDD[2].value;
        } else if (month >= 10 && month <= 12) {
            this.search.Quarter = this.QuarterDD[3].value;
        }

        this.GetDataFG('1');
    }

    SearchSelectedYear() {

        let _s = this;
        _s.search.Quarter = '';
        _s.mserve.Master_DDL({ Module: "QuarterDD", TextSearch1: _s.search.Year }).then((response) => {
            _s.QuarterDD = response.data.body;
            //console.log("QuarterDD : ", _s.QuarterDD);
        })
    }

    SelectedYear(value) {

        let _s = this;
        if (value == '') {
          
            _s.model.Quarter = '';
        }
        _s.mserve.Master_DDL({ Module: "QuarterDD", TextSearch1: _s.model.Year }).then((response) => {
            _s.AddCo_QuarterDD = response.data.body
            //console.log("AddCo_QuarterDD : ", _s.AddCo_QuarterDD)
            if (value !== '') {
                for (let item_AddCo_QuarterDD of _s.AddCo_QuarterDD) {
                    if (value === item_AddCo_QuarterDD.value) {
                        _s.model.Quarter = item_AddCo_QuarterDD.value
                    }
                }
            }

        })
    }

    func_getlist() {

        const _s = this;
        let datavalue = {
            SessionEmpID: this.Data_SessionStorage[0].employeE_ID,
            Module: "getlist",
            body: {
                QuarterID: _s.search.Quarter,
                EmpID: _s.search.searchText,
                EmpName: _s.search.searchText,
                DeptID: _s.search.BusinessId,
                Level: _s.search.searchText,
                CoorBU: _s.search.searchText,
                BULevel: _s.search.searchText
            }
        }
        _s.mserve.GetBUCoordinator(datavalue).then((response) => {
            if (response.data.status) {
                _s.tablecoordinator = response.data.body;
                //console.log('data', _s.tablecoordinator);
                this.SizeCollection = response.data.body.length;
            } else {
                //console.log('false', response)
                alert(response.data.errorMessage);
            }
        }).catch((e) => {
            if (e.response) {
                this.authserviceToken.CheckTokenExpire(e.response.status)
            } else {
                //console.log(e)
            }

        });
    }



    onFileChange(evt: any) {
        const target: DataTransfer = <DataTransfer>(evt.target);
        if (target.files.length !== 1) throw new Error('Cannot use multiple files');
        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
            const bstr: string = e.target.result;
            const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

            const wsname: string = wb.SheetNames[0];
            //console.log(wsname)
            const ws: XLSX.WorkSheet = wb.Sheets[wsname];
            //console.log(ws)

            let data = XLSX.utils.sheet_to_json(ws);
            let NewJson = XLSX.utils.sheet_to_json(ws, { header: 1 });
            //console.log('NewJson', NewJson);
            //console.log('data', data);
            reader.onloadend = (e) => {
                this.keys = Object.values(NewJson[0]);
            }
            let arrdataEx = [];
            for (let i = 1; i < NewJson.length; i++) {
                let dataEx = {
                    BU_ID: '',
                    BU_NAME: '',
                    CO_EMAIL: '',
                    CO_NAME: '',
                };
                dataEx.BU_ID = NewJson[i][0] == undefined ? '' : NewJson[i][0]
                dataEx.BU_NAME = NewJson[i][1] == undefined ? '' : NewJson[i][1]
                dataEx.CO_EMAIL = NewJson[i][2] == undefined ? '' : NewJson[i][2]
                dataEx.CO_NAME = NewJson[i][3] == undefined ? '' : NewJson[i][3]
                arrdataEx.push(dataEx);
            }
            //console.log('arrdataEx', arrdataEx)
            this.ExJson = arrdataEx;
        };
        reader.readAsBinaryString(target.files[0]);
    }

    removeData() {
        this.inputFile.nativeElement.value = '';
        this.ExJson = [];
        this.keys = null;
    }

    importexcel() {
        let _s = this;
        if (_s.validate()) {
            let datavalue = {
                SessionEmpID: this.Data_SessionStorage[0].employeE_ID,
                Module: "importExcel",
                body: [
                    {
                        QuarterID: _s.model.Quarter,
                        sBUCoorEx: _s.ExJson
                    }
                ]
            }
            //console.log('datavalue', datavalue)
            this.callServiceGetBUCoordinator(_s, datavalue);
        }
        else {
            alert(_s.Msgvalidate);
        }
    }

    private callServiceGetBUCoordinator(_s: this, datavalue: { SessionEmpID: any; Module: string; body: { QuarterID: any; sBUCoorEx: any[]; }[]; }) {
        _s.mserve.GetBUCoordinator(datavalue).then((response) => {
            if (response.data.status) {
                _s.model.Year = '';
            
                _s.model.Quarter = '';
                _s.inputFile.nativeElement.value = '';
                $('#modalimportexcel').modal('hide');
            } else {

                this.ExJson = [];
                for (let i = 0; i < response.data.body[0].sBUCoorEx.length; i++) {
                    let sss = response.data.body[0].sBUCoorEx;
                    let dataEx = {
                        BU_ID: '',
                        BU_NAME: '',
                        CO_EMAIL: '',
                        CO_NAME: '',
                    };
                    dataEx.BU_ID = sss[i].bU_ID ?? '';
                    dataEx.BU_NAME = sss[i].bU_NAME ?? '';
                    dataEx.CO_EMAIL = sss[i].cO_EMAIL ?? '';
                    dataEx.CO_NAME = sss[i].cO_NAME ?? '';

                    this.ExJson.push(dataEx);
                }
                alert(response.data.errorMessage);
            }
        }).catch((e) => {
            if (e.response) {
                this.authserviceToken.CheckTokenExpire(e.response.status)
            } else {
                //console.log(e)
            }
        });
    }

    func_selectEmp(data) {

        let _s = this;
        let tempid = null
        for (let item_EmployeeDD of _s.EmployeeDD) {
            if (data === item_EmployeeDD.text) {
                tempid = item_EmployeeDD.value
                break;
            }
        }

        if (tempid !== null) {
            if (data != '0') {
                let found = _s.EmployeeDD.find(x => x.value === tempid);
                _s.model.EmpName = found.text
                _s.model.EmpID = found.value
                _s.model.Level = found.text4
                _s.model.EmpEmail = found.text3
                _s.model.EmpBU = found.text2
                _s.model.DeptID = found.text1
            } else {
                _s.model.EmpName = ""
                _s.model.EmpID = ""
                _s.model.Level = ""
                _s.model.EmpEmail = ""
                _s.model.CoordinatorId = ""

                _s.model.DeptID = ""
            }

            if (_s.model.EmpName !== null && _s.model.EmpName !== '') {
                this.TempdataEmployee.push({
                    Emp_id: _s.model.EmpID,
                    Emp_name: _s.model.EmpName,
                    Emp_email: _s.model.EmpEmail,
                    Emp_bu: _s.model.EmpBU,
                    Emp_CoordinatorId: _s.model.CoordinatorId

                });
                _s.model.EmpID = '';
                _s.model.EmpName = '';
                _s.model.EmpEmail = '';
                _s.model.EmpBU = '';
                _s.model.CoordinatorId = '';
            }
        }
        else {
            _s.model.EmpName = ''
        }
    }

    func_addbuco(item, mode) {
    
        let _s = this;
        //console.log('item : ', item)
        if (mode === 'Add') {
            _s.Module = 'insert'
            _s.model.CoordinatorId = ''
            _s.model.QuarterID = ''
            _s.model.EmpID = ''
            _s.model.EmpName = ''
            _s.model.DeptID = ''
            _s.model.Level = ''
            _s.model.CoorBU = ''
            _s.tempdepart = ''
            _s.model.CoorBUID = ''
            _s.model.Year = ''
            _s.model.Quarter = ''
            _s.model.CoorBULevel = ''
            _s.model.EmpEmail = ''
            _s.model.EmpBU = ''
            _s.TempdataEmployee = [];
        } else {
            _s.model.EmpName = ''
            _s.Module = 'update'
            _s.model.QuarterID = item.quarterID
            _s.model.CoorBU = item.BUNAME
            _s.model.Year = (item.quarterYear === null) ? '' : _s.currentyear

            if (item.quarterYear !== null && item.quarterYear !== '') {
              
                _s.model.Quarter = ''
                _s.SelectedYear(this.search.Quarter);
            }
            for (let item_BUDD_auto of this.BUDD_auto) {
                if (item_BUDD_auto.text === item.BUNAME) {
                    _s.tempdepart = item_BUDD_auto.value;
                    _s.model.CoorBUID = item_BUDD_auto.text1;
                    _s.model.CoorBULevel = item_BUDD_auto.text2;
                    break;
                } else {
                    _s.tempdepart = ''
                    _s.model.CoorBUID = ''
                    _s.model.CoorBULevel = ''
                }
            }

            this.TempdataEmployee = [];
            for (let item_CO of item.CO) {
                _s.model.CoordinatorId = item_CO.coid
                this.func_selectEmp(item_CO.coname);
                this.func_addEmp();
            }

        }
        $('#modaladdcoordinator').modal('show');
    }

    func_importEx() {
        let _s = this;
        $('#modalimportexcel').modal('show');
    }

    func_savebtn() {
        let _s = this;
        //console.log('DataSave',this.TempdataEmployee)
        if (_s.TempdataEmployee.length !== 0) {
            _s.TempdataEmployee.forEach(function (ItemVal, ItemIndex) {
                let datavalue = {
                    SessionEmpID: _s.Data_SessionStorage[0].employeE_ID,
                    Module: '',
                    body: []
                }
                if (ItemVal.Emp_CoordinatorId === '') {
                    datavalue.Module = 'insert'
                } else {
                    datavalue.Module = 'update'
                }
                datavalue.body.push({
                    QuarterID: _s.model.Quarter,
                    DeptID: _s.model.DeptID,
                    Level: _s.model.Level,
                    CoorBU: _s.tempdepart,
                    BULevel: _s.model.CoorBULevel,
                    CoordinatorId: ItemVal.Emp_CoordinatorId,
                    EmpID: ItemVal.Emp_id,
                    EmpName: ItemVal.Emp_name,
                })

                //console.log('datavalue', datavalue)

            _s.func_api(datavalue);
            });
        }
        else {  
         
     
            _s.model.Quarter = ''
    
         
            _s.GetDataFG('2');
            $('#modaladdcoordinator').modal('hide');
        }
    }

    func_savebtn_after_replace() {
        let _s = this;
        //console.log('DataSave',this.TempdataEmployee)
        if (_s.TempdataEmployee.length !== 0) {
            _s.TempdataEmployee.forEach(function (ItemVal, ItemIndex) {
                let datavalue = {
                    SessionEmpID: _s.Data_SessionStorage[0].employeE_ID,
                    Module: '',
                    body: []
                }
                if (ItemVal.Emp_CoordinatorId === '') {
                    datavalue.Module = 'insert'
                } else {
                    datavalue.Module = 'update'
                }
                datavalue.body.push({
                    QuarterID: _s.model.Quarter,
                    DeptID: _s.model.DeptID,
                    Level: _s.model.Level,
                    CoorBU: _s.tempdepart,
                    BULevel: _s.model.CoorBULevel,
                    CoordinatorId: ItemVal.Emp_CoordinatorId,
                    EmpID: ItemVal.Emp_id,
                    EmpName: ItemVal.Emp_name,
                })

                //console.log('datavalue', datavalue)

            _s.func_api_get_after_replace(datavalue);
            });
        }
        else {  
  
            _s.GetDataFG('2');
            $('#modaladdcoordinator').modal('hide');
        }
    }

    func_api_get_after_replace(datavalue) {
        const _s = this;
        _s.mserve.GetBUCoordinator(datavalue).then((response) => {
            if (response.data.status) {

                let formdataReplace = {
                    "SessionEmpID": _s.Data_SessionStorage[0].employeE_ID,
                    "SecurityCode": "1234",
                    "Module": "ReplaceCordinator",
                    "body": {
                        "Risk_Business_Unit": _s.model.CoorBUID,
                        "Co_Old": "",
                        "Co_New": "",
                        "Type_Co": "Cordinator"
                    }
                }
                //console.log(JSON.stringify(formdataReplace))
                _s.mserve.ReplaceCordinator(formdataReplace).then((response) => {
                    if (response.data.status) {
                       
                        //console.log("this.ConfirmReplace",this.ConfirmReplace)
                   
                        _s.GetDataFG('2');
                        $('#modaladdcoordinator').modal('hide');
                    }
                    else {
                        //console.log('false', response)
                        alert(response.data.errorMessage);
                    }
                });
            } else {
                //console.log('false', response)
                alert(response.data.errorMessage);
            }
        }).catch((e) => {
            if (e.response) {
                this.authserviceToken.CheckTokenExpire(e.response.status)
            } else {
                //console.log(e)
            }
        });
    }

    func_api(datavalue) {
        const _s = this;
        _s.mserve.GetBUCoordinator(datavalue).then((response) => {
            if (response.data.status) {

                let formdataReplace = {
                    "SessionEmpID": _s.Data_SessionStorage[0].employeE_ID,
                    "SecurityCode": "1234",
                    "Module": "ReplaceCordinator",
                    "body": {
                        "Risk_Business_Unit": _s.model.CoorBUID,
                        "Co_Old": "",
                        "Co_New": "",
                        "Type_Co": "Cordinator"
                    }
                }
                //console.log(JSON.stringify(formdataReplace))
                _s.mserve.ReplaceCordinator(formdataReplace).then((response) => {
                    if (response.data.status) {
                     
                        //console.log("this.ConfirmReplace",this.ConfirmReplace)
                
                        _s.model.Quarter = ''
                   
                        _s.GetDataFG('2');
                        $('#modaladdcoordinator').modal('hide');
                    }
                    else {
                        //console.log('false', response)
                        alert(response.data.errorMessage);
                    }
                });
            } else {
                //console.log('false', response)
                alert(response.data.errorMessage);
            }
        }).catch((e) => {
            if (e.response) {
                this.authserviceToken.CheckTokenExpire(e.response.status)
            } else {
                //console.log(e)
            }
        });
    }

    func_closebtn() {
        const _s = this;
        $('#modaladdcoordinator').modal('hide');
        _s.GetDataFG('2');
    }

    func_closeImportEx() {
        $('#modalimportexcel').modal('hide');
    }

    func_clear() {
        let _s = this;
        _s.search.searchText = '';
    }

    func_selectCoorBU(value) {

        const _s = this;
        for (let item_BUDD_auto of this.BUDD_auto) {
            if (item_BUDD_auto.text === value) {
                _s.tempdepart = item_BUDD_auto.value;
                _s.model.CoorBUID = item_BUDD_auto.text1;
                _s.model.CoorBULevel = item_BUDD_auto.text2;
                break;
            }
        }
    }

    validate() {
        let _s = this;
        let s = true;
        if (_s.ExJson.length == 0) {
            _s.Msgvalidate = 'No File chosen';
            s = false;
        }
        return s
    }

    func_addEmp() {

        const _s = this;
        if (_s.model.EmpName !== null && _s.model.EmpName !== '') {
            this.TempdataEmployee.push({
                Emp_id: _s.model.EmpID,
                Emp_name: _s.model.EmpName,
                Emp_email: _s.model.EmpEmail,
                Emp_bu: _s.model.EmpBU,
                Emp_CoordinatorId: _s.model.CoordinatorId

            });
            _s.model.EmpID = '';
            _s.model.EmpName = '';
            _s.model.EmpEmail = '';
            _s.model.EmpBU = '';
            _s.model.CoordinatorId = '';
        }

    }
    GetDataFG(chk) {

        const _s = this;
        this.loading = true;

        if (chk == 1) {
            this.TempSelectFG = [];
        }
        let formdata = {
            SessionEmpID: this.Data_SessionStorage[0].employeE_ID,
            SecurityCode: "1234",
            Email: this.Data_SessionStorage[0].emaiL_ID,
            body: {
                QuarterID: _s.search.Quarter
            }
        }
        _s.mserve.Get_Menu_BU_Config(formdata).then((response) => {
            if (response.data.status) {
                //console.log('DATA GET : ', response.data.body)
                this.TempdataAPICR = response.data.body;
                this.tempchkyear = this.search.Year;
                this.loading = false;
                this.GetHeaderBU();
            }
            else {
                alert(response.data.errorMessage);
                //console.log('false', response)
            }
        }).catch((e) => {
            if (e.response) {
                this.authserviceToken.CheckTokenExpire(e.response.status)
            } else {
                //console.log(e)
            }
        });
    }
    GetHeaderBU() {

        const _s = this;
        _s.DataFG = [];

        if (_s.TempdataAPICR[0].division_Level !== null) {
            _s.TempdataAPICR[0].division_Level.forEach(function (ItemVal, ItemIndex) {
                ItemVal.org_Menu.forEach(function (ItemVal2, ItemIndex2) {
                    _s.DataFG.push({
                        title: ItemVal2.abbreviation,
                        value: ItemVal2.organization_Code,
                        data: ItemVal2
                    })
                });
            });
        }

        if (_s.TempdataAPICR[0].groupDivision_Level !== null) {
            _s.TempdataAPICR[0].groupDivision_Level.forEach(function (ItemVal, ItemIndex) {
                ItemVal.org_Menu.forEach(function (ItemVal2, ItemIndex2) {
                    _s.DataFG.push({
                        title: ItemVal2.abbreviation,
                        value: ItemVal2.organization_Code,
                        data: ItemVal2
                    })
                });
            });
        }


        //console.log('DataFG : ', _s.DataFG);
        if (this.TempSelectFG.length === 0) {
            _s.SelectFG(_s.DataFG[0], '')
        } else {
            _s.SelectFG(_s.TempSelectFG[0], (this.TempindexSelectFG - 1))
        }
    }

    SelectFG(item, indexsel) {
        const _s = this;
        _s.OptimizedData = []
        this.TempSelectFG = [];
        this.TempSelectFG.push({ title: item.title, value: item.value });
        this.ProcessFGByOrganizationLevel(_s, item);



        $(document).ready(function () {
            // your code here
            let e1 = document.getElementById("BUCO1");
            e1.classList.add("active");
            if (_s.TempindexSelectFG !== null) {
                e1.classList.remove("active");
                let e2 = document.getElementById("BUCO" + _s.TempindexSelectFG);
                e2.classList.add("active");
            }
        });
        this.TempindexSelectFG = null;
        this.TempindexSelectFG = (indexsel + 1);

    }




    private ProcessFGByOrganizationLevel(_s: any, item: any) {
        _s.DataFG.forEach(function (ItemVal, ItemIndex) {
            if (item.value === ItemVal.value) {

                if (ItemVal.data.organization_Level === 'Division') {
                    _s.OptimizedData.push({
                        DIV: ItemVal.data.abbreviation,
                        BUID: ItemVal.data.organization_Code,
                        BUNAME: ItemVal.data.abbreviation,
                        CO: [],
                    });
                    ItemVal.data.coInfo.forEach(function (ItemValco, ItemIndexco) {
                        _s.OptimizedData[_s.OptimizedData.length - 1].CO.push({
                            coname: ItemValco.coordinator_EName,
                            coid: ItemValco.coordinatorId
                        });
                        if (ItemIndexco === 0) {
                            _s.OptimizedData[_s.OptimizedData.length - 1].quarterID = ItemValco.quarterID;
                            _s.OptimizedData[_s.OptimizedData.length - 1].quarterYear = ItemValco.quarterYear;
                        }
                    });


                    if (ItemVal.data.department_Level.length !== 0) {
                        ItemVal.data.department_Level.forEach(function (ItemVal1, ItemIndex1) {
                            _s.OptimizedData.push({
                                DEPT: ItemVal1.abbreviation,
                                BUID: ItemVal1.organization_Code,
                                BUNAME: ItemVal1.abbreviation,
                                CO: []
                            });
                            ItemVal1.coInfo.forEach(function (ItemValco1, ItemIndexco1) {
                                _s.OptimizedData[_s.OptimizedData.length - 1].CO.push({
                                    coname: ItemValco1.coordinator_EName,
                                    coid: ItemValco1.coordinatorId
                                });
                                if (ItemIndexco1 === 0) {
                                    _s.OptimizedData[_s.OptimizedData.length - 1].quarterID = ItemValco1.quarterID;
                                    _s.OptimizedData[_s.OptimizedData.length - 1].quarterYear = ItemValco1.quarterYear;
                                }
                            });
                        });
                    }

                }

                if (ItemVal.data.organization_Level === 'Group') {
                    _s.OptimizedData.push({
                        FG: ItemVal.data.abbreviation,
                        BUID: ItemVal.data.organization_Code,
                        BUNAME: ItemVal.data.abbreviation,
                        CO: []
                    });
                    ItemVal.data.coInfo.forEach(function (ItemValco, ItemIndexco) {
                        _s.OptimizedData[_s.OptimizedData.length - 1].CO.push({
                            coname: ItemValco.coordinator_EName,
                            coid: ItemValco.coordinatorId
                        });
                        if (ItemIndexco === 0) {
                            _s.OptimizedData[_s.OptimizedData.length - 1].quarterID = ItemValco.quarterID;
                            _s.OptimizedData[_s.OptimizedData.length - 1].quarterYear = ItemValco.quarterYear;
                        }
                    });

                    
                    if (ItemVal.data.division_Level.length !== 0) {
                        ItemVal.data.division_Level.forEach(function (ItemVal1, ItemIndex1) {
                            if (ItemVal1.organization_Level == "Division") {
                                _s.OptimizedData.push({
                                    DIV: ItemVal1.abbreviation,
                                    BUID: ItemVal1.organization_Code,
                                    BUNAME: ItemVal1.abbreviation,
                                    CO: []
                                });
                                ItemVal1.coInfo.forEach(function (ItemValco1, ItemIndexco1) {
                                    _s.OptimizedData[_s.OptimizedData.length - 1].CO.push({
                                        coname: ItemValco1.coordinator_EName,
                                        coid: ItemValco1.coordinatorId
                                    });
                                    if (ItemIndexco1 === 0) {
                                        _s.OptimizedData[_s.OptimizedData.length - 1].quarterID = ItemValco1.quarterID;
                                        _s.OptimizedData[_s.OptimizedData.length - 1].quarterYear = ItemValco1.quarterYear;
                                    }
                                });


                                if (ItemVal1.department_Level.length !== 0) {
                                    ItemVal1.department_Level.forEach(function (ItemVal2, ItemIndex2) {
                                        _s.OptimizedData.push({
                                            DEPT: ItemVal2.abbreviation,
                                            BUID: ItemVal2.organization_Code,
                                            BUNAME: ItemVal2.abbreviation,
                                            CO: []
                                        });
                                        ItemVal2.coInfo.forEach(function (ItemValco2, ItemIndexco2) {
                                            _s.OptimizedData[_s.OptimizedData.length - 1].CO.push({
                                                coname: ItemValco2.coordinator_EName,
                                                coid: ItemValco2.coordinatorId
                                            });
                                            if (ItemIndexco2 === 0) {
                                                _s.OptimizedData[_s.OptimizedData.length - 1].quarterID = ItemValco2.quarterID;
                                                _s.OptimizedData[_s.OptimizedData.length - 1].quarterYear = ItemValco2.quarterYear;
                                            }
                                        });
                                    });
                                }
                            }
                            if (ItemVal1.organization_Level == "Department") {
                                _s.OptimizedData.push({
                                    DEPT: ItemVal1.abbreviation,
                                    BUID: ItemVal1.organization_Code,
                                    BUNAME: ItemVal1.abbreviation,
                                    CO: []
                                });
                                ItemVal1.coInfo.forEach(function (ItemValco1, ItemIndexco1) {
                                    _s.OptimizedData[_s.OptimizedData.length - 1].CO.push({
                                        coname: ItemValco1.coordinator_EName,
                                        coid: ItemValco1.coordinatorId
                                    });
                                    if (ItemIndexco1 === 0) {
                                        _s.OptimizedData[_s.OptimizedData.length - 1].quarterID = ItemValco1.quarterID;
                                        _s.OptimizedData[_s.OptimizedData.length - 1].quarterYear = ItemValco1.quarterYear;
                                    }
                                });
                            }
                        });
                    }

                }
            }
        });
    }


    func_selectReplaceEmp(item) {
        const _s = this;
        _s.EmployeeDD_autoReplace.forEach(function (value, index) {
            if (value.Emp_name == item) {
                _s.ReplaceEmp.EmpID = value.Emp_id
                _s.ReplaceEmp.EmpName = value.Emp_name
                _s.ReplaceEmp.EmpEmail = value.Emp_email
                _s.ReplaceEmp.EmpBU = value.Emp_bu
                _s.ReplaceEmp.Emp_CoordinatorId = value.Emp_CoordinatorId
            }
        })
        //console.log('ReplaceEmp ', _s.ReplaceEmp)
    }
    Remove_emp(item) {

        const _s = this;
        this.EmployeeDD_autoReplace = [];
        for (let item_EmployeeDD of _s.EmployeeDD) {
            if (item_EmployeeDD.text != item.Emp_name) {
                _s.ReplaceEmp = {
                    Emp_id: item_EmployeeDD.value,
                    Emp_name: item_EmployeeDD.text,
                    Emp_email: item_EmployeeDD.text3,
                    Emp_bu: item_EmployeeDD.text2,
                    Emp_buID: item_EmployeeDD.text1,
                    Emp_CoordinatorId: ''
                }
                this.EmployeeDD_autoReplace.push(_s.ReplaceEmp)
            }
        }

        _s.ReplaceEmp.EmpID = ''
        _s.ReplaceEmp.EmpName = ''
        _s.ReplaceEmp.EmpEmail = ''
        _s.ReplaceEmp.EmpBU = ''
        _s.ReplaceEmp.Emp_CoordinatorId = ''

        if (_s.EmployeeDD_autoReplace.length == 1) {
            _s.ReplaceEmp.EmpID = _s.EmployeeDD_autoReplace[0].Emp_id
            _s.ReplaceEmp.EmpName = _s.EmployeeDD_autoReplace[0].Emp_name
            _s.ReplaceEmp.EmpEmail = _s.EmployeeDD_autoReplace[0].Emp_email
            _s.ReplaceEmp.EmpBU = _s.EmployeeDD_autoReplace[0].Emp_bu
            _s.ReplaceEmp.Emp_CoordinatorId = _s.EmployeeDD_autoReplace[0].Emp_CoordinatorId
        }

        //console.log('this.EmployeeDD_autoReplace ', this.EmployeeDD_autoReplace)
        this.filteredOptionsEmployeeIDDDReplace = this.myControlEmployeeReplace.valueChanges.pipe(
            startWith(''),
            map(value => this._filterEmployeeIDDDReplace(value))
        );


        $('#modaladdcoordinator').modal('hide');
        setTimeout(function () { $('#modalDeleteCo').modal('show'); }, 300);


        this.tempReplace = item

    }
    confirm_replace() {
 this.ConfirmReplace = 'yes';
        const _s = this;
        let item = this.tempReplace

        if (_s.ReplaceEmp) {
            let found = _s.EmployeeDD.find(x => x.value === _s.ReplaceEmp.EmpID);
            let isExisting = this.TempdataEmployee.find(x => x.Emp_id === found.value);

            if (!isExisting) {
                this.newMethod_2(_s, found);
            }
        } else {
            this.newMethod_3(_s);
        }

        if (_s.model.EmpName !== null && _s.model.EmpName !== '') {
            this.newMethod_4(_s);
        }

        let formdataReplace = {
            "SessionEmpID": _s.Data_SessionStorage[0].employeE_ID,
            "SecurityCode": "1234",
            "Module": "ReplaceCordinator",
            "body": {
                "Risk_Business_Unit": _s.model.CoorBUID,
                "Co_Old": item.Emp_id,
                "Co_New": _s.ReplaceEmp.EmpID,
                "Type_Co": "Cordinator"
            }
        }
        //console.log(JSON.stringify(formdataReplace))
        this.callservice_ReplaceCordinator(_s, formdataReplace, item);
    }
    private callservice_ReplaceCordinator(_s: this, formdataReplace: { SessionEmpID: any; SecurityCode: string; Module: string; body: { Risk_Business_Unit: any; Co_Old: any; Co_New: any; Type_Co: string; }; }, item: any) {
        _s.mserve.ReplaceCordinator(formdataReplace).then((response) => {
            if (response.data.status) {

                let formbody = {
                    SessionEmpID: _s.Data_SessionStorage[0].employeE_ID,
                    Module: 'deleteitem',
                    body: [{
                        CoordinatorId: item.Emp_CoordinatorId
                    }]
                };
                _s.mserve.GetBUCoordinator(formbody).then((response) => {
                    if (response.data.status) {
                        _s.TempdataEmployee = _s.TempdataEmployee.filter(employee => employee.Emp_id !== item.Emp_id);
                      //console.log('ReplaceBuItem',item)
                        $('#modalDeleteCo').modal('hide');
                        setTimeout(function () { $('#modaladdcoordinator').modal('show'); }, 300);
                        this.func_savebtn_after_replace();
                        _s.TempdataEmployee.forEach(item => {
                            item.Emp_CoordinatorId = item.Emp_CoordinatorId === '' ? '-1' : item.Emp_CoordinatorId;
                        });

                    }
                    else {
                        //console.log('false', response);
                        alert(response.data.errorMessage);
                    }
                }).catch((e) => {
                    if (e.response) {
                        this.authserviceToken.CheckTokenExpire(e.response.status)
                    } else {
                        //console.log(e)
                    }
                });


            }
            else {
                //console.log('false', response);
                alert(response.data.errorMessage);
            }
        });
    }

    private newMethod_4(_s: this) {
        this.TempdataEmployee.push({
            Emp_id: _s.model.EmpID,
            Emp_name: _s.model.EmpName,
            Emp_email: _s.model.EmpEmail,
            Emp_bu: _s.model.EmpBU,
            Emp_CoordinatorId: _s.model.CoordinatorId
        });
        _s.model.EmpID = '';
        _s.model.EmpName = '';
        _s.model.EmpEmail = '';
        _s.model.EmpBU = '';
        _s.model.CoordinatorId = '';
    }

    private  newMethod_3(_s: this) {
        _s.model.EmpName = "";
        _s.model.EmpID = "";
        _s.model.Level = "";
        _s.model.EmpEmail = "";
        _s.model.CoordinatorId = "";
        _s.model.DeptID = "";
    }

    private newMethod_2(_s: this, found: any) {
        _s.model.EmpName = found.text;
        _s.model.EmpID = found.value;
        _s.model.Level = found.text4;
        _s.model.EmpEmail = found.text3;
        _s.model.EmpBU = found.text2;

        _s.model.DeptID = found.text1;
    }

    close_remove_emp() {
        $('#modalDeleteCo').modal('hide');
        setTimeout(function () { $('#modaladdcoordinator').modal('show'); }, 300);
    }

    func_formatdate(value) {
        let day = value.day.toString();
        let month = value.month.toString();
        let year = value.year.toString();
        if (day.length < 2) {
            day = '0' + day
        }
        if (month.length < 2) {
            month = '0' + month
        }
        let formatedate = day + '/' + month + '/' + year
        return formatedate
    }
    func_formatdateto_ngdate(value) {
        let editday = parseInt(value.substr(0, 2));
        let editmonth = parseInt(value.substr(3, 2));
        let edityear = parseInt(value.substr(6, 4));

        return {
            day: (editday),
            month: (editmonth),
            year: (edityear)
        };

    }
    func_getdataExport() {

        const _s = this;
        let formdata = {
            SessionEmpID: this.Data_SessionStorage[0].employeE_ID,
            Module: "exportexcel",
            body: {
                QuarterID: '',
                EmpID: '',
                EmpName: '',
                DeptID: '',
                Level: '',
                CoorBU: '',
                BULevel: ''
            }
        }
        _s.mserve.GetexportExcelData(formdata).then((response) => {
            if (response.data.status) {
                //console.log('data', response.data.body);
                this.TempdataExcel = response.data.body;
                this.excelService2.generateExcel_COBU(this.TempdataExcel);

            } else {
                //console.log('false', response)
                alert(response.data.errorMessage);
            }
        }).catch((e) => {
            if (e.response) {
                this.authserviceToken.CheckTokenExpire(e.response.status)
            } else {
                //console.log(e)
            }
        });
    }

    _filterEmployeeIDDD(value: string): string[] {
        let tempFileter;
        const filterValue = value?.toLowerCase();
        if (filterValue !== '') {
            tempFileter = this.EmployeeDD_auto.filter(option1 => option1.text.toLowerCase().includes(filterValue));
            if (tempFileter.length !== 0) {
                return tempFileter;
            }
        } else {
            return this.EmployeeDD_auto
        }
    }

    _filterBUDD(value: string): string[] {

        let tempFileter;
        if (value !== null) {
            const filterValue = value?.toLowerCase();
            if (filterValue !== '') {
                tempFileter = this.BUDD_auto.filter(option1 => option1.text?.toLowerCase().includes(filterValue));
                if (tempFileter.length !== 0) {
                    return tempFileter;
                }
            } else {
                return this.BUDD_auto
            }
        }
        else {
            return this.BUDD_auto
        }
    }

    _filterEmployeeIDDDReplace(value: string): string[] {
        let tempFileter;
        if (value !== null) {
            const filterValue = value?.toLowerCase();
            if (filterValue !== '') {
                tempFileter = this.EmployeeDD_autoReplace.filter(option1 => option1.Emp_name?.toLowerCase().includes(filterValue));
                if (tempFileter.length !== 0) {
                    return tempFileter;
                }
            } else {
                return this.EmployeeDD_autoReplace
            }
        }
        else {
            return this.EmployeeDD_autoReplace
        }
    }

    ngAfterViewInit(): void {

        const _s = this;
    }
}