import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Configmodel } from './model/configmodel';
export const appInitializerCallback = (service: AppSettingService) => {
  return () => {
    return service.load();
  };
};

@Injectable()
export class AppSettingService {
  private configuration: Configmodel;
  constructor(private httpClient: HttpClient) { }
  load(): Promise<any> {
    const headers = new HttpHeaders({
      'X-Frame-Options': 'DENY',
      'Content-Security-Policy': 'frame-ancestors \'none\'',
    });

    return this.httpClient
      .get<Configmodel>('./backendcfg/app-setting.json', { headers })
      .toPromise()
      .then(config => {
        this.configuration = config;
      
        return config;
      })
      .catch(error => {
        console.error('Error loading configuration:', error);
        throw error;
      });
  }
  read(): any {
    return this.configuration;
  }
}
