import { OnInit, Component, Injectable, ViewChild, ElementRef } from "@angular/core";
import { NgbModal, NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Masterserve } from '../../services/master.service';
declare let $;

import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AppSettingService } from 'src/app/app-setting-service';
import { AuthenService } from 'src/app/services/authenservice.service';

@Injectable()

export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day : (date[0], 10),
        month : (date[1], 10),
        year : (date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    if (date !== null) {
        let day = date.day.toString()
        let month = date.month.toString()
        if (day.length < 2) {
            day = '0' + day
        }
        if (month.length < 2) {
            month = '0' + month
        }
        let newdate  = day + this.DELIMITER + month + this.DELIMITER + date.year ;
        return  newdate;
    } else { 
       return '';
    }
}
}


@Component({
    selector: 'app-RiskCatalog',
    templateUrl: './page.RiskCatalog.html',
    styleUrls: ['./page.RiskCatalog.css'],
    providers: [
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
    ]
})
export class RiskCatalog implements OnInit {
    
    @ViewChild('imgInput') imgInputVar: ElementRef;   
    @ViewChild('fileInput') fileInputVar: ElementRef;   
    loginRole;
    tempDisplayImage = [];
    tempDisplayFile = [];
    tempImageID = [];
    tempfileID;
    page = 1;
    tempcatalog;
    RegisCatalog;
    RegisFile;
    Data_SessionStorage;
    statusbtn;
    DataSearch;
    loading;
    url = null;
    alertText = '';
    dis1 = {html: new UntypedFormControl("", Validators.required)};
    form1: UntypedFormGroup = new UntypedFormGroup(this.dis1);
    configNote;
    config: any = {
        airMode: false,
        tabDisable: true,
        popover: {
            table: [
            ["add", ["addRowDown", "addRowUp", "addColLeft", "addColRight"]],
            ["delete", ["deleteRow", "deleteCol", "deleteTable"]]
            ],
            image: [
            ["image", ["resizeFull", "resizeHalf", "resizeQuarter", "resizeNone"]],
            ["float", ["floatLeft", "floatRight", "floatNone"]],
            ["remove", ["removeMedia"]]
            ],
            link: [["link", ["linkDialogShow", "unlink"]]],
            air: [
            [
                "font",
                [
                "bold",
                "italic",
                "underline",
                "strikethrough",
                "superscript",
                "subscript",
                "clear"
                ]
            ]
            ]
        },
        height: "200px",
        toolbar: [
            ["fontsize", ["fontname", "fontsize", "color", "forecolor"]],
            ["para", ["style0", "ul", "ol", "paragraph", "height"]],
            ["insert", ["table", "link"]]
        ],
        codeviewFilter: true,
        codeviewIframeFilter: true
    };

    DDLYear;
    DDLQuarter;

    removeFileIndex = 0;

    constructor(public authserviceToken: AuthenService,private modalService: NgbModal, public mserve: Masterserve,public appSettingService:AppSettingService) {}
    configdata= this.appSettingService.read();
    BASE_URL =this.configdata.api_url;
    path = this.BASE_URL + '/app/backend/Resources/FileUpload';
    ngOnInit(): void {
        $('.modal').on("hidden.bs.modal", function (e) { 
            if ($('.modal:visible').length) { 
                $('body').addClass('modal-open');
            }
        });

        this.DataSearch = {
            keyCatalog :'',
            Yearfrom:'',
            Yearto:'',
            Quarterfrom:'',
            Quarterto:''
        }

        this.RegisCatalog = {
            RegisID :'',
            RegisSubjectName :'',
            RegisDescription :'',
            RegisImage :'',
            RegisCreateby :'',
            RegisCreatedate :'',
            RegisFileimg :[],
            RegisFile :[]
        }

        this.RegisFile = {
            ReqId :'',
            AttachFileID :'',
            DelFlag :''
        }

        this.tempcatalog = [];
        
        this.Data_SessionStorage = JSON.parse(sessionStorage.datainfo);
        //console.log('Data_SessionStorage => ',this.Data_SessionStorage)
        this.loginRole = sessionStorage.Role


        this.GETDDL_YEAR();
    }

    GETDDL_YEAR () {
        const _s = this;
        _s.mserve.Master_DDL({ 'Module' : 'YearDD' }).then((response) => {
            if(response.data.status === true){
              //console.log('YearDD : true', response)
              this.DDLYear = response.data.body;

                let d = new Date();
                this.DataSearch.Yearfrom = d.getFullYear();
                let currentMonth = d.getMonth() + 1;
                _s.mserve.Master_DDL({ Module:"QuarterDD",TextSearch1: this.DataSearch.Yearfrom }).then((response) => {
                    _s.DDLQuarter = response.data.body
                    //console.log("Quarter : ",_s.DDLQuarter);
                    this.func_calQuarter(currentMonth);
                });
            }else{
              //console.log('YearDD : false', response)
            }
        })
    }

    func_calQuarter (month) {
        
        this.DataSearch.Quarterfrom = ''
        let quater   = '';
        if (month >= 1 && month <= 3) {
            quater   = 'Q1';
        } else if (month >= 4 && month <= 6) {
            quater   = 'Q2';
        } else if (month >= 7 && month <= 9) {
            quater   = 'Q3';
        } else if (month >= 10 && month <= 12) {
            quater   = 'Q4';
        }
        let data  = this.DDLQuarter.filter(x=>x.text === quater)[0] ?  this.DDLQuarter.filter(x=>x.text === quater)[0]:''
        if(data!=''){
            this.DataSearch.Quarterfrom = data.value;
     
        }
        this.func_GETDATA();
    }

    func_GETDATA () {
        this.loading = true;
        //console.log("Path URL : ",this.BASE_URL)
        let formData = {
            SessionEmpID :"1",
            Module :'getlist',
            body :{
               RiskCatalogID :"",
               RiskCatalogTitle :"",
               RiskCatalogDesc :"",
               DelFlag :""
            }
        }
        this.mserve.GetCatalog(formData).then((response) => {
            //console.log("data :",response);
            if (response.data.status ){
                this.tempcatalog = response.data.body;
                for (let item_tempcatalog of this.tempcatalog){
                    item_tempcatalog.img = '../../../assets/logo/photo-1103595_1280.png';
                    if (item_tempcatalog.riskCatalogDesc !== null) {
                        item_tempcatalog.riskCatalogDescsubstr = this.func_Substr(item_tempcatalog.riskCatalogDesc);
                    } else {
                        item_tempcatalog.riskCatalogDescsubstr = '';
                    }
                }
                this.loading = false;
                //console.log("data Catalog :",this.tempcatalog)
            } else {
                //console.log('false',response)
                alert(response.data.errorMessage);
            }
          }).catch((e) => {
            this.authserviceToken.CheckTokenExpire(e.response.status)
            });
    }

    func_ExpandCollapse (item , value) {
        
        if (value === 1) {
            this.RegisCatalog.RegisSubjectName = item.riskCatalogTitle
            this.RegisCatalog.RegisDescription = item.riskCatalogDesc
            this.RegisCatalog.RegisCreateby = item.createBy
            this.RegisCatalog.RegisCreatedate = item.createDateTime
            this.RegisCatalog.RegisFileimg = item.itemAttSeq1
            this.RegisCatalog.RegisFile = item.itemAttSeq2
            this.page = 2;
        } else {
            this.RegisCatalog.RegisSubjectName = ''
            this.RegisCatalog.RegisDescription = ''
            this.RegisCatalog.RegisCreateby = ''
            this.RegisCatalog.RegisCreatedate = ''
            this.RegisCatalog.RegisFileimg = []
            this.RegisCatalog.RegisFile = []
            this.page = 1;
        }
    }

    func_addcatalog () {
        
        this.statusbtn = 'insert';
        this.RegisCatalog.RegisID = '';
        this.RegisCatalog.RegisSubjectName = '';
        this.RegisCatalog.RegisDescription = '';
        this.RegisCatalog.RegisImage = '';
        this.url = null;
        this.RegisCatalog.RegisFile = [];
        this.tempDisplayImage = [];
        this.tempDisplayFile = [];
        this.dis1 = {html: new UntypedFormControl("", Validators.required)};
        let a1: UntypedFormGroup = new UntypedFormGroup(this.dis1);
        this.form1 = a1;

        $('#modaladdCatalog').modal('show');
    }

    func_edit (item) {
        const _s = this;
        _s.RegisCatalog.RegisFileimg = [];
        _s.RegisCatalog.RegisFile = [];
        _s.tempDisplayImage = [];
        _s.tempDisplayFile = [];
        _s.tempImageID = [];
        this.RegisCatalog.RegisImage = '';
        this.url = null;

        this.statusbtn = 'edit';
        this.RegisCatalog.RegisID = item.riskCatalogID;
        this.RegisCatalog.RegisSubjectName = item.riskCatalogTitle;
        this.RegisCatalog.RegisDescription = item.riskCatalogDesc;

        this.dis1 = {html: new UntypedFormControl(this.RegisCatalog.RegisDescription, Validators.required)};
        let a1: UntypedFormGroup = new UntypedFormGroup(this.dis1);
        this.form1 = a1;

        

        item.itemAttSeq1.forEach(function(ItemVal, ItemIndex) {
            _s.tempDisplayImage.push({
                attachFileID: ItemVal.attachFileID,
                name :ItemVal.fileName,
                form: ItemVal.form,
                pathFile :ItemVal.pathFile,
                reqId: ItemVal.reqId,
                seqNo: ItemVal.seqNo,
                rootPath: ItemVal.rootPath
            })
            _s.tempImageID.push({
                attachFileID: ItemVal.attachFileID,
                name :ItemVal.fileName,
                form: ItemVal.form,
                pathFile :ItemVal.pathFile,
                reqId: ItemVal.reqId,
                seqNo: ItemVal.seqNo,
                rootPath: ItemVal.rootPath
            })
        });

        item.itemAttSeq2.forEach(function(ItemVal, ItemIndex) {
            _s.tempDisplayFile.push({
                attachFileID: ItemVal.attachFileID,
                name: ItemVal.fileName,
                form: ItemVal.form,
                pathFile: ItemVal.pathFile,
                reqId: ItemVal.reqId,
                seqNo: ItemVal.seqNo,
                rootPath: ItemVal.rootPath
            })
        });

        if (_s.tempDisplayImage.length !== 0) {
            this.RegisCatalog.RegisImage = _s.tempDisplayImage[0].name
            this.url = this.path + '/' + _s.tempDisplayImage[0].pathFile
        }
        $('#modaladdCatalog').modal('show');
    }

    func_save (tempmodule) {
        this.RegisCatalog.RegisDescription = this.form1.get('html').value;
        let formData = {
            SessionEmpID :this.Data_SessionStorage[0].employeE_ID,
            Module :tempmodule,
            body :{
            RiskCatalogID :this.RegisCatalog.RegisID,
            RiskCatalogTitle :this.RegisCatalog.RegisSubjectName,
            RiskCatalogDesc :this.RegisCatalog.RegisDescription,
            DelFlag :""
            }
        }
        if(tempmodule != 'delete' && ((!formData.body.RiskCatalogDesc && formData.body.RiskCatalogDesc == '' ) || (!formData.body.RiskCatalogTitle && formData.body.RiskCatalogTitle == '')))
        {
            this.alertText = "Please Enter ";
            var isError = false;
            if(!formData.body.RiskCatalogTitle && formData.body.RiskCatalogTitle == '')
            {
                this.alertText += " Document Reference Title"
                isError = true;
            }
            if(!formData.body.RiskCatalogDesc && formData.body.RiskCatalogDesc == '')
            {
                if(isError)
                {
                    this.alertText += ",";
                }
                this.alertText += " Description"
                isError = true;
            }

            $('#modalAlert').modal('show');
        }
        else
        {
            this.mserve.GetCatalog(formData).then((response) => {
                if (response.data.status ){
                    this.RegisFile.ReqId = response.data.reqId
                    let formDataImg = {
                        AttachFileID :"",
                        Form :"1", 
                        SeqNo :"1", 
                        ReqId :this.RegisFile.ReqId,   
                        DelFlag :""
                    }
                    let formDataIMG = new FormData();
                    let arListimg = this.RegisCatalog.RegisFileimg
                   
                    this.GetCatalog_II_chkRegisFileimg_length(formDataIMG, formDataImg, arListimg);
    
                    let formDataS = {
                        AttachFileID :"",
                        Form :"1", 
                        SeqNo :"2", 
                        ReqId :this.RegisFile.ReqId,   
                        DelFlag :""
                    }
                    let formDataM = new FormData();
                    let arList = this.RegisCatalog.RegisFile
                    this.GetCatalog_II_chkRegisFile_length(formDataM, formDataS, arList);
    
                    this.func_GETDATA();
                    $('#modaladdCatalog').modal('hide');
                    if(tempmodule === 'update')
                    {
                        this.alertText = "Update"
                    }
                    else if(tempmodule === 'insert')
                    {
                        this.alertText = "Add"
                    }
                    else if(tempmodule === 'delete') {
                        $('#modalDeleteWarning').modal('hide');
                        this.alertText = "Delete"
                    }
                    this.alertText += " Success"
                    $('#modalAlertsuccess').modal('show');
                } else {
                    //console.log('false',response)
                    alert(response.data.errorMessage);
                }
            }).catch((e) => {
                this.authserviceToken.CheckTokenExpire(e.response.status)
                });
        }

    }

    private GetCatalog_II_chkRegisFile_length(formDataM: FormData, formDataS: { AttachFileID: string; Form: string; SeqNo: string; ReqId: any; DelFlag: string; }, arList: any) {
        if (this.RegisCatalog.RegisFile.length !== 0) {
            formDataM.append('formData', JSON.stringify(formDataS));
            for (let item_arList of arList) {
                formDataM.append('files', item_arList, item_arList.name);
            }
            this.mserve.UploadFileMultiple(formDataM).then((response) => {
                if (response.data.status) {
                    this.func_GETDATA();
                } else {
                    //console.log('false', response);
                }
            }).catch((e) => {
                this.authserviceToken.CheckTokenExpire(e.response.status);
            });
            this.resetBlobFile();
        }
    }

    private GetCatalog_II_chkRegisFileimg_length(formDataIMG: FormData, formDataImg: { AttachFileID: string; Form: string; SeqNo: string; ReqId: any; DelFlag: string; }, arListimg: any) {
        if (this.RegisCatalog.RegisFileimg.length !== 0) {

            this.func_DelImage(this.tempImageID);

            formDataIMG.append('formData', JSON.stringify(formDataImg));
            for (let item_arListimg of arListimg) {
                formDataIMG.append('files', item_arListimg, item_arListimg.name);
            }
            this.mserve.UploadFileMultiple(formDataIMG).then((response) => {
                if (response.data.status) {
                    this.func_GETDATA();
                } else {
                    //console.log('false', response);
                }
            });
            this.resetBlobImageFile();
        }
    }

    func_close () {
        this.func_GETDATA();
        $('#modaladdCatalog').modal('hide');
        this.resetBlobFile();
        this.resetBlobImageFile();
    }

    func_AddImage (event) {
        this.RegisCatalog.RegisFileimg = [];
        this.tempDisplayImage = [];
        if (event.target.files && event.target.files[0]) {
            var fileName = event.target.files[0].name;
            var idxDot = fileName.lastIndexOf(".") + 1;
            var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();

            if((extFile=="jpg" || extFile=="jpeg" || extFile=="png") && event.target.files[0].size < 2500000)
            {
                this.RegisCatalog.RegisImage = event.target.files[0].name
                this.RegisCatalog.RegisFileimg.push(event.target.files[0])
                this.tempDisplayImage.push(event.target.files[0])
                let reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = (event) => { 
                    this.url = event.target.result;
                }
            }
            else
            {
                this.alertText = "Please upload only image file and maximum image size is 2.5 megabytes (MB)";
                $('#modalAlert').modal('show');
            }
        }
    }

    func_AddFile (event) {
        
        if (event.target.files && event.target.files[0]) {
            if (event.target.files[0].size > 52428800) {
                this.alertText = "File upload is over 50 megabytes (MB)!";
                $('#modalAlert').modal('show');
              }
              else
              {
                for (let item_files of event.target.files){
                    let checkFile = this.tempDisplayFile.find(x => x.name == item_files.name);
                    if(!checkFile)
                    {
                        this.RegisCatalog.RegisFile.push( item_files )
                        this.tempDisplayFile.push( item_files )
                    }
                    else
                    {
                        this.alertText = "File "+ item_files.name + " already exists.";
                        $('#modalAlert').modal('show');
                        // item_files.name = checkFile.length.toString() + item_files.name.toString();
                        // this.RegisCatalog.RegisFile.push( item_files )
                        // this.tempDisplayFile.push( item_files )
                    }
                    
                    
                    
                }
                this.resetBlobFile();
              }
        }
    }

    func_Substr (data) {
        if (data.length > 420) {
            let substr = data.substr(0, 420);
            return substr + "...";
        } else {
            return data
        }
    }

    func_Clear () {
        this.GETDDL_YEAR();
    }



    RemoveFile (item, index) {
        this.tempfileID = item;
        this.removeFileIndex = index;
        $('#modalConfirm').modal('show');
    }
    // funcYes () {
    //     let formData = this.tempfileID;
    //     this.RegisCatalog.RegisFile = [];
    //     this.mserve.DeleteFile(formData).then((response) => {
    //         if (response.data.status ){
                
    //              for (let i = 0; i < this.tempDisplayFile.length; i++) {
    //                 if (this.tempDisplayFile[i].attachFileID === this.tempfileID.attachFileID) {
    //                     this.tempDisplayFile.splice(i , 1);
    //                     break;
    //                 }
    //              }
    //              this.tempfileID = '';
    //             $('#modalConfirm').modal('hide');
    //         } else {
    //             //console.log('false',response);
    //         }
    //     }).catch((e) => {
    //         this.authserviceToken.CheckTokenExpire(e.response.status)
    //         });
    // }
    funcYes (statusbtn) {
        if(statusbtn === 'edit'){
            let formData = this.tempfileID;
            if(formData.attachFileID) {
                this.mserve.DeleteFile(formData).then((response) => {
                    if (response.data.status ){
                        // for (let i = 0; i < this.tempDisplayFile.length; i++) {
                        //     if (this.tempDisplayFile[i].attachFileID === this.tempfileID.attachFileID) {
                        //         this.tempDisplayFile.splice(i , 1);
                        //         break;
                        //     }
                        // }
                        this.tempDisplayFile.splice(this.removeFileIndex, 1);
                        if(this.RegisCatalog.RegisFile.length !== 0) {
                            this.RegisCatalog.RegisFile.splice(this.removeFileIndex, 1);
                        }
                        
                        this.tempfileID = '';
                        this.removeFileIndex = 0;
                        
                        this.tempfileID = '';
                        $('#modalConfirm').modal('hide');
                    } else {
                        //console.log('false',response);
                    }
                }).catch((e) => {
                    this.authserviceToken.CheckTokenExpire(e.response.status)
                });
            } else {
                this.tempDisplayFile.splice(this.removeFileIndex, 1);

                this.RegisCatalog.RegisFile = this.RegisCatalog.RegisFile.filter(x=>x.name !== this.tempfileID.name);
                
                this.tempfileID = '';
                this.removeFileIndex = 0;
                $('#modalConfirm').modal('hide');
            }
            
        } else {
            //case delete file from edit edit or else
            // let a = this.RegisCatalog.RegisFileimg;
            // this.RegisCatalog.RegisFile = [];
            this.tempDisplayFile.splice(this.removeFileIndex, 1);
            this.RegisCatalog.RegisFile.splice(this.removeFileIndex, 1);
            this.tempfileID = '';
            this.removeFileIndex = 0;
            $('#modalConfirm').modal('hide');
        }
    }
    funcNo () {
        $('#modalConfirm').modal('hide');
    }
    func_DelImage (item) {
        let formData = item[0]
        this.mserve.DeleteFile(formData).then((response) => {
        }).catch((e) => {
            this.authserviceToken.CheckTokenExpire(e.response.status)
            });
    }

    func_selFromyear () {
        
        const _s = this;
        _s.DataSearch.Quarterfrom = ''
        _s.mserve.Master_DDL({ Module:"QuarterDD",TextSearch1: _s.DataSearch.Yearfrom }).then((response) => {
            _s.DDLQuarter = response.data.body
            //console.log("DDLQuarter : ",_s.DDLQuarter);
        });
    }
    
    RemoveDocument(item) {
        this.RegisCatalog.RegisID = item.riskCatalogID;
        $('#modalDeleteWarning').modal('show');
    }
    
    resetBlobImageFile() {
        this.imgInputVar.nativeElement.value = "";
        this.RegisCatalog.RegisFileimg = [];
    }

    resetBlobFile() {
        this.fileInputVar.nativeElement.value ="";
    }

    downloadfile(path){
        let splitted=[];
        if(path.includes("\\"))
        {
            splitted = path.split("\\", 3); 
        }
        else if(path.includes("/"))
        {
            splitted = path.split("/", 3); 
        }

        let data = {
            path1: splitted[0],
            path2: splitted[1],
            filename: splitted[2],
            source: "Resources"
          }

        this.mserve.DownloadFile(data).then((response) => {
            if (response.data) {
                const blob = new Blob([response.data], { type: 'application/octet-stream' });

                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = data.filename ;
                a.click();
                window.URL.revokeObjectURL(url);
            } else {
              alert("ไม่สามารถดาวน์โหลดไฟล์ได้");
            }
          }).catch((e) => {
            alert("ไม่สามารถดาวน์โหลดไฟล์ได้");
            });
    }

    
} 