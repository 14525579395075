<ng-container [formGroup]="mainForm">
    <div class="row ml-2 mr-2">
        <div class="col-3 riskbox" style="background-color: #4198C5; color: white;">
            Strategy Name or Description
        </div>
        <input type="text" formControlName="riskName" class="col" style="font-size: 1vw;"
        [ngClass]="{'input-error': (this.mainForm.controls.riskName.invalid && this.mainForm.controls.riskName.touched)}">
        
    </div>
</ng-container>
<div class="container-fluid appform my-3 card" style="background-color:white ;padding: 80px; padding-top: 0px; height: fit-content;">
    <ng-container [formGroup]="mainForm">
        <div class="row mt-4">
            <span class="text">
                Implementation Period
            </span>

            <div class="ml-5" style="display: flex; margin-top: 15px">
                <input matInput class="form-control" style="background: white;"  [matDatepicker]="picker" autocomplete="off" [readonly]="true"  [max]="mainForm.controls.riskEndDate.value" 
                formControlName="riskStartDate" placeholder="Start Date"
                [ngClass]="{'input-error': (this.mainForm.controls.riskStartDate.invalid && this.mainForm.controls.riskStartDate.touched)}">
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </div>
            <div class="ml-5"  style="display: flex; margin-top: 15px">
                <input matInput class="form-control" style="background: white;" [matDatepicker]="picker1" autocomplete="off" [readonly]="true"  [min]="mainForm.controls.riskStartDate.value" 
                formControlName="riskEndDate" placeholder="End Date"
                [ngClass]="{'input-error': (this.mainForm.controls.riskEndDate.invalid && this.mainForm.controls.riskEndDate.touched) && this.mainForm.controls.noEndDate.value == false}">
                <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
            </div>

            <div>
                <div class="form-check ml-5" style="display: flex; margin-top: 18px">
                    <input class="form-check-input" type="checkbox" value="noEndDate" (change)="onNoEndDateChange($event)" formControlName="noEndDate" id="noEndDateCheckBox">
                    <label class="form-check-label mt-2" for="noEndDateCheckBox">
                        No End Date
                    </label>
                  </div>
            </div>
        </div>
    </ng-container>

    <div class="row text pt-3">
        Register & Assessment
    </div>

    <div class="row pt-3 pb-3">
        <button class="col-2 " (click)="StrategicFit()"
        [ngClass]="{'bgtab': this.tabSelect != 1 , 'bgselecttab': this.tabSelect == 1}">
            <span class="col-11">Strategic Fit</span>
            <i class="col-1 fas fa-angle-right" *ngIf="this.tabSelect != 1"></i>
            <i class="col-1 fas fa-angle-down" *ngIf="this.tabSelect == 1"></i>
        </button>
        &nbsp;&nbsp;
        <button class="col-2 bgtab" (click)="StrategicExecutionPerspective()"
        [ngClass]="{'bgtab': this.tabSelect != 2 , 'bgselecttab': this.tabSelect == 2}">
            <span class="col-11">Strategic Execution Perspective</span>
            <i class="fas fa-angle-right" *ngIf="this.tabSelect != 2"></i>
            <i class="col-1 fas fa-angle-down" *ngIf="this.tabSelect == 2"></i>
        </button>
        &nbsp;&nbsp;
        <button class="col-2 bgtab" (click)="RiskfromStrategy()"
        [ngClass]="{'bgtab': this.tabSelect != 3 , 'bgselecttab': this.tabSelect == 3}">
            <span class="col-11">Risk from Strategy</span>
            <i class="fas fa-angle-right" *ngIf="this.tabSelect != 3"></i>
            <i class="col-1 fas fa-angle-down" *ngIf="this.tabSelect == 3"></i>
        </button>
        &nbsp;&nbsp;
        <button class="col-2 bgtab" (click)="StrategicAssumption()"
        [ngClass]="{'bgtab': this.tabSelect != 4 , 'bgselecttab': this.tabSelect == 4}">
            <span class="col-11">Strategic Assumption</span>
            <i class="fas fa-angle-right" *ngIf="this.tabSelect != 4"></i>
            <i class="col-1 fas fa-angle-down" *ngIf="this.tabSelect == 4"></i>
        </button>
    </div>
    <div class="row bg" *ngIf="this.tabSelect == 1"> 
        <div class="col-6">
            <div class="row">
                <h5 style="color:red">All fields are required.</h5>
            </div>
            <div class="row">
                <h5>Strategic Alignment</h5>
            </div>
        <ng-container [formGroup]="dataForm">
            <div class="row  pt-3">
                <div class="col-6">
                    1.Strategic Direction
                </div>
                <div class="col-6">
                    Rationale
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <select  class="col-6 form-control" formControlName="strategicDirection" (change)="onSelctChange()" 
                    [ngClass]="{'input-error': (this.dataForm.controls.strategicDirection.invalid && this.dataForm.controls.strategicDirection.touched)}">
                        <option [ngValue]="null" [disabled]="true" >Please Select</option>
                        <option *ngFor="let item of selectValues" [ngValue]="item.value">{{item.name}}</option>
                    </select>
                </div>
                <div class="col-6">
                    <textarea autosize  rows="1" style="min-height: 2.5rem;" type="text" formControlName="strategicDirectionRationale" class="col-12 form-control"
                    [ngClass]="{'input-error': (this.dataForm.controls.strategicDirectionRationale.invalid && this.dataForm.controls.strategicDirectionRationale.touched)}">
                    </textarea>
                </div>
            </div>

            <div class="row  pt-3">
                <div class="col-6">
                    2.Financial
                </div>
                <div class="col-6">
                    Rationale
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <select   class="col-6 form-control" formControlName="financial" (change)="onSelctChange()"
                    [ngClass]="{'input-error': (this.dataForm.controls.financial.invalid && this.dataForm.controls.financial.touched)}">
                        <option [ngValue]="null" [disabled]="true" >Please Select</option>
                        <option *ngFor="let item of selectValues" [ngValue]="item.value">{{item.name}}</option>
                    </select>
                </div>
                <div class="col-6">
                    <textarea autosize rows="1" style="min-height: 2.5rem;" type="text" formControlName="financialRationale" class="col-12 form-control"
                    [ngClass]="{'input-error': (this.dataForm.controls.financialRationale.invalid && this.dataForm.controls.financialRationale.touched)}">
                    </textarea>
                </div>
            </div>

            <div class="row  pt-3">
                <div class="col-6">
                    3.Zero Tolerance
                </div>
                <div class="col-6">
                    Rationale
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <select   class="col-6 form-control" formControlName="zeroTolerance" (change)="onSelctChange()"
                    [ngClass]="{'input-error': (this.dataForm.controls.zeroTolerance.invalid && this.dataForm.controls.zeroTolerance.touched)}">
                        <option [ngValue]="null" [disabled]="true" >Please Select</option>
                        <option *ngFor="let item of selectValues" [ngValue]="item.value">{{item.name}}</option>
                    </select>
                </div>
                <div class="col-6">
                    <textarea autosize rows="1" style="min-height: 2.5rem;" type="text" formControlName="zeroToleranceRationale" class="col-12 form-control"
                    [ngClass]="{'input-error': (this.dataForm.controls.zeroToleranceRationale.invalid && this.dataForm.controls.zeroToleranceRationale.touched)}">
                    </textarea>
                </div>
            </div>

            <div class="row  pt-3">
                <div class="col-2  pt-2" style="font-weight: bold;">
                    Lowest Level
                </div>
                <div class="col-4">
                    <input type="text" formControlName="lowestScore1" class="form-control" style="background-color: lightgray!important; text-align:center; font-weight: bold;" disabled>
                </div>
                
            </div>

            <div class="row pt-5">
                <h5>Strategic Contribution</h5>
            </div>


            <div class="row  pt-3">
                <div class="col-6">
                    1.Value Size / Profit Margin
                </div>
                <div class="col-6">
                    Rationale
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <select   class="col-6 form-control" formControlName="valueProfit" (change)="onSelctChange()"
                    [ngClass]="{'input-error': (this.dataForm.controls.valueProfit.invalid && this.dataForm.controls.valueProfit.touched)}">
                        <option [ngValue]="null" [disabled]="true" >Please Select</option>
                        <option *ngFor="let item of selectValues" [ngValue]="item.value">{{item.name}}</option>
                    </select>
                </div>
                <div class="col-6">
                    <textarea autosize rows="1" style="min-height: 2.5rem;" type="text" formControlName="valueProfitRationale" class="col-12 form-control"
                    [ngClass]="{'input-error': (this.dataForm.controls.valueProfitRationale.invalid && this.dataForm.controls.valueProfitRationale.touched)}">
                    </textarea>
                </div>
            </div>

            <div class="row  pt-3">
                <div class="col-6">
                    2.Timing of Cash Inflow
                </div>
                <div class="col-6">
                    Rationale
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <select   class="col-6 form-control" formControlName="timingCashInfow" (change)="onSelctChange()"
                    [ngClass]="{'input-error': (this.dataForm.controls.timingCashInfow.invalid && this.dataForm.controls.timingCashInfow.touched)}">
                        <option [ngValue]="null" [disabled]="true" >Please Select</option>
                        <option *ngFor="let item of selectValues" [ngValue]="item.value">{{item.name}}</option>
                    </select>
                </div>
                <div class="col-6">
                    <textarea autosize rows="1" style="min-height: 2.5rem;" type="text" formControlName="timingCashInfowRationale" class="col-12 form-control"
                    [ngClass]="{'input-error': (this.dataForm.controls.timingCashInfowRationale.invalid && this.dataForm.controls.timingCashInfowRationale.touched)}">
                    </textarea>
                </div>
            </div>

            <div class="row  pt-3">
                <div class="col-6">
                    3.Growth Opportunity / Sustainability Target
                </div>
                <div class="col-6">
                    Rationale
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <select   class="col-6 form-control" formControlName="growthSustainability" (change)="onSelctChange()"
                    [ngClass]="{'input-error': (this.dataForm.controls.growthSustainability.invalid && this.dataForm.controls.growthSustainability.touched)}">
                        <option [ngValue]="null" [disabled]="true" >Please Select</option>
                        <option *ngFor="let item of selectValues" [ngValue]="item.value">{{item.name}}</option>
                    </select>
                </div>
                <div class="col-6">
                    <textarea autosize rows="1" style="min-height: 2.5rem;" type="text" formControlName="growthSustainabilityRationale" class="col-12 form-control"
                    [ngClass]="{'input-error': (this.dataForm.controls.growthSustainabilityRationale.invalid && this.dataForm.controls.growthSustainabilityRationale.touched)}">
                    </textarea>
                </div>
            </div>

            <div class="row  pt-3 pb-5">
                <div class="col-2 pt-2" style="font-weight: bold;">
                    Lowest Level
                </div>
                <div class="col-4">
                    <input type="text" formControlName="lowestScore2" class="form-control" style="background-color: lightgray!important; text-align:center; font-weight: bold;" disabled>
                </div>
                
            </div>

            <div class="col-12 pt-6 pl-0">
                <div >
                    <h5>
                        Reference for Level
                    </h5>
                </div>
                <div>
                    <div class="p-0">
                        <div class="row">
                            <div class="col-6">
                                <table class="table">
                                    <caption style="display: none;">This is a custom table</caption>
                                    <thead class="" style="background-color: #033153; color: white; ">
                                      <tr>
                                        <th scope="col">Risk Limits for Alignment Check</th>
                                        <th scope="col">Level</th>
                                      </tr>
                                    </thead>
                                    <tbody style="background-color:white;">
                                      <tr>
                                        <td>Within limit</td>
                                        <td>High</td>
                                      </tr>
                                      <tr>
                                        <td>Out of limit but exempted</td>
                                        <td>Medium</td>
                                      </tr>
                                      <tr>
                                        <td>Out of limit</td>
                                        <td>Low</td>
                                      </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-6">
                                <table class="table">
                                    <caption style="display: none;">This is a custom table</caption>
                                    <thead class="" style="background-color: #033153; color: white; ">
                                      <tr>
                                        <th scope="col">Strategy Contribution</th>
                                        <th scope="col">Level</th>
                                      </tr>
                                    </thead>
                                    <tbody style="background-color:white;">
                                      <tr>
                                        <td>High/Quick</td>
                                        <td>High</td>
                                      </tr>
                                      <tr>
                                        <td>Mid/Medium</td>
                                        <td>Medium</td>
                                      </tr>
                                      <tr>
                                        <td>Low/Long</td>
                                        <td>Low</td>
                                      </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        
                    </div>
                </div>

            </div>
            


        </ng-container>
        </div>
        <div class="col-6">
            <div class ="row">
                <div class="col-12" style ="text-align: -webkit-center; height:fit-content; padding-top: 2em;">
                    <app-strategic-table 
                    [datas] = "bindingStrategicTable"
                    [mode] = "'single'"
                    [tabSelect] = "tabSelect"
                    [width] = "'90%'"
                    [height] = "'100%'">
                    </app-strategic-table>
                </div>
            </div>
            <div class="row">
                <div class="col-12" style="padding-top: 6em;">
                    <div class="col-12" style="text-align: end; float:right; ">
                        <button type="button" class="btn btn-lg mb-3" 
                        style="color:white;background:rgb(0, 0, 0);margin: 5px;" (click)="StrategicExecutionPerspective()">Go to Execution Preference
                        </button>
        
                        <button *ngIf="this._mode == 2" type="button" class="btn btn-lg mb-3" 
                        style="color:white;background:green;margin: 5px;" (click)="save('Strategic Fit')">Save
                        </button>
        
                        <button *ngIf="this._mode == 1" type="button" (click)="backMonitor()" class="btn btn-lg mb-3" style="background-color:#9eb0ba;color:white;margin: 5px;" >
                            Back to Monitor & Review Page
                        </button>
                        
                    </div>
                </div>
            </div>
        </div>

<!-- 
        <div class="row col-12">
            <div class="col-6 pl-0">

            </div>

        </div> -->

    </div>

    <div class="row bg" *ngIf="this.tabSelect == 2"> 
        <div class="col-6">
            <div class="row">
                <h5 style="color:red">All fields are required.</h5>
            </div>
            <div class="row">
                <h5>Strategic Execution Perspective</h5>
            </div>
            <div class="row">
                <span style="font-size: 15px; font-weight: bold;">Manageable Challenges</span>
            </div>

            <ng-container [formGroup]="dataFormTab2">
                <div class="row  pt-3">
                    <div class="col-6">
                        1.Capability Development
                    </div>
                    <div class="col-6">
                        Rationale
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <select   class="col-6 form-control" formControlName="capability" (change)="onSelctChangeTab2()"
                        [ngClass]="{'input-error': (this.dataFormTab2.controls.capability.invalid && this.dataFormTab2.controls.capability.touched)}">
                            <option [ngValue]="null" [disabled]="true" >Please Select</option>
                            <option *ngFor="let item of selectValues" [ngValue]="item.value">{{item.name}}</option>
                        </select>
                    </div>
                    <div class="col-6">
                        <textarea autosize rows="1" style="min-height: 2.5rem;" type="text" formControlName="capabilityRationale" class="col-12 form-control"
                        [ngClass]="{'input-error': (this.dataFormTab2.controls.capabilityRationale.invalid && this.dataFormTab2.controls.capabilityRationale.touched)}">
                        </textarea>
                    </div>
                </div>

                <div class="row  pt-3">
                    <div class="col-6">
                        2.Resources Allocation
                    </div>
                    <div class="col-6">
                        Rationale
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <select class="col-6 form-control" formControlName="resources" (change)="onSelctChangeTab2()"
                        [ngClass]="{'input-error': (this.dataFormTab2.controls.resources.invalid && this.dataFormTab2.controls.resources.touched)}">
                            <option [ngValue]="null" [disabled]="true" >Please Select</option>
                            <option *ngFor="let item of selectValues" [ngValue]="item.value">{{item.name}}</option>
                        </select>
                    </div>
                    <div class="col-6">
                        <textarea autosize rows="1" style="min-height: 2.5rem;" type="text" formControlName="resourcesRationale" class="col-12 form-control"
                        [ngClass]="{'input-error': (this.dataFormTab2.controls.resourcesRationale.invalid && this.dataFormTab2.controls.resourcesRationale.touched)}">
                        </textarea>
                    </div>
                </div>

                <div class="row pt-3">
                    <div class="col-2  pt-2" style="font-weight: bold;">
                        Lowest Level
                    </div>
                    <div class="col-4">
                        <input type="text" class="form-control" style="background-color: lightgray!important; text-align:center; font-weight: bold;" formControlName="lowestScore1"  disabled>
                    </div>
                    
                </div>

                <div class="row pt-5">
                    <span style="font-size: 15px; font-weight: bold;">Manageable Portfolio Risk</span>
                </div>


                <div class="row  pt-3">
                    <div class="col-6">
                        1.Joint Investment
                    </div>
                    <div class="col-6">
                        Rationale
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <select class="col-6 form-control" formControlName="jointInvestment" (change)="onSelctChangeTab2()"
                        [ngClass]="{'input-error': (this.dataFormTab2.controls.jointInvestment.invalid && this.dataFormTab2.controls.jointInvestment.touched)}">
                            <option [ngValue]="null" [disabled]="true" >Please Select</option>
                            <option *ngFor="let item of selectValues" [ngValue]="item.value">{{item.name}}</option>
                        </select>
                    </div>
                    <div class="col-6">
                        <textarea autosize rows="1" style="min-height: 2.5rem;" formControlName="jointInvestmentRationale" class="col-12 form-control"
                        [ngClass]="{'input-error': (this.dataFormTab2.controls.jointInvestmentRationale.invalid && this.dataFormTab2.controls.jointInvestmentRationale.touched)}">
                        </textarea>
                    </div>
                </div>

                <div class="row  pt-3">
                    <div class="col-6">
                        2.Quick Exit to Limit Loss
                    </div>
                    <div class="col-6">
                        Rationale
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <select class="col-6 form-control" formControlName="quickExit" (change)="onSelctChangeTab2()"
                        [ngClass]="{'input-error': (this.dataFormTab2.controls.quickExit.invalid && this.dataFormTab2.controls.quickExit.touched)}">
                            <option [ngValue]="null" [disabled]="true" >Please Select</option>
                            <option *ngFor="let item of selectValues" [ngValue]="item.value">{{item.name}}</option>
                        </select>
                    </div>
                    <div class="col-6">
                        <textarea autosize rows="1" style="min-height: 2.5rem;" formControlName="quickExitRationale" class="col-12 form-control"
                        [ngClass]="{'input-error': (this.dataFormTab2.controls.quickExitRationale.invalid && this.dataFormTab2.controls.quickExitRationale.touched)}">
                        </textarea>
                    </div>
                </div>

                <div class="row  pt-3 pb-5">
                    <div class="col-2  pt-2" style="font-weight: bold;">
                        Lowest Level
                    </div>
                    <div class="col-4">
                        <input type="text" class="form-control" style="background-color: lightgray!important; text-align:center; font-weight: bold;" formControlName="lowestScore2" disabled>
                    </div>
                    
                </div>  
                <div class="col-12 pt-6 pl-0">
                    <div >
                        <h5 >
                            Reference for Level
                        </h5>
                    </div>
                    <div>
                        <div class="p-0">
                            <div class="row">
                                <div class="col-6">
                                    <table class="table">
                                        <caption style="display: none;">This is a custom table</caption>
                                        <thead class="" style=" background-color: #033153; color: white; ">
                                          <tr>
                                            <th scope="col">Manageale Challenges
                                            <th scope="col">Level</th>
                                          </tr>
                                        </thead>
                                        <tbody style="background-color:white;">
                                          <tr>
                                            <td>Independently manageable</td>
                                            <td>High</td>
                                          </tr>
                                          <tr>
                                            <td>Dependently manageable</td>
                                            <td>Medium</td>
                                          </tr>
                                          <tr>
                                            <td>Non-manageable</td>
                                            <td>Low</td>
                                          </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-6">
                                    <table class="table">
                                        <caption style="display: none;">This is a custom table</caption>
                                        <thead class="" style="background-color: #033153; color: white; ">
                                          <tr>
                                            <th scope="col">Manageable Portfolio Risk</th>
                                            <th scope="col">Level</th>
                                          </tr>
                                        </thead>
                                        <tbody style="background-color:white;">
                                          <tr>
                                            <td>Independently manageable</td>
                                            <td>High</td>
                                          </tr>
                                          <tr>
                                            <td>Dependently manageable</td>
                                            <td>Medium</td>
                                          </tr>
                                          <tr>
                                            <td>Non-manageable</td>
                                            <td>Low</td>
                                          </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            
                        </div>
                    </div>
    
                </div>
            </ng-container>

        </div>
        <div class="col-6" >
            <div class="row">
                <div class="col-12" style ="text-align: -webkit-center; height:fit-content; padding-top: 2em;">
                    <app-strategic-table 
                    [datas] = "bindingStrategicExecutionTable"
                    [mode] = "'single'"
                    [tabSelect] = "tabSelect"
                    [width] = "'90%'"
                    [height] = "'100%'">
                    </app-strategic-table>
                </div>
            </div>
            <div class="row">
                <div class="col-12 pt-5" style="text-align: end; float:right; padding-right:4em; padding-top: 6em!important;" >
    
                    <button type="button" class="btn btn-lg mb-3" 
                    style="color:white;background:rgb(0, 0, 0);margin: 5px;" (click)="RiskfromStrategy()">Go to Risk from Strategy
                    </button>
        
                    <button *ngIf="this._mode == 2" type="button" class="btn btn-lg mb-3" 
                    style="color:white;background:green;margin: 5px;" (click)="save('Strategic Execution Perspective')">Save
                    </button>
        
                    <button *ngIf="this._mode == 1" type="button" (click)="backMonitor()" class="btn btn-lg mb-3" style="background-color:#9eb0ba; color:white; margin: 5px;" >
                        Back to Monitor & Review Page
                    </button>
                    
                </div>
            </div>

        </div>

        
        <!-- <div class="row col-12">
            <div class="col-6 pl-0">

            </div>

            <div class="col-6">
                <div class="col-6">
    
                </div>
                <div class="col-12" style="text-align: end; float:right" >
    
                    <button type="button" class="btn btn-lg mb-3" 
                    style="color:white;background:rgb(0, 0, 0);margin: 5px;" (click)="RiskfromStrategy()">Go to Risk from Strategy
                    </button>
    
                    <button *ngIf="this._mode == 2" type="button" class="btn btn-lg mb-3" 
                    style="color:white;background:green;margin: 5px;" (click)="save()">Save
                    </button>

                    <button *ngIf="this._mode == 1" type="button" (click)="backMonitor()" class="btn btn-lg mb-3" style="background-color:#9eb0ba; color:white; margin: 5px;" >
                        Back to Monitor & Review Page
                    </button>
                    
                </div>
            </div>   
        </div> -->
        
    </div>

    <div class="row bg " *ngIf="this.tabSelect == 3">
        <ng-container [formGroup]="dataFormTab3">
            <ng-container formArrayName="dataRisk">
                <div class="col-6" style="padding-left: 0px;">
                    <div class="row" style="padding-left: 30px;">
                        <h5 style="color:red">All fields are required.</h5>
                    </div>
                    <div class = "col-12" style="overflow-y:auto; max-height: 1500px">
                        <div *ngFor="let item of dataRisk.controls; let index = index" [formGroupName]="index">
                            <div class="row">
                                <div class="col-10">
                                    <span>{{index+1}}. Risk Name or Description</span>
                                    <textarea autosize rows="1" type="text" formControlName="riskName" class="col-12 m-0 form-control"
                                    [ngClass]="{'input-error': (item.get('riskName').invalid && item.get('riskName').touched)}">
                                    </textarea>
                                </div>
                                <div class="col-2 pt-3 pl-0" style="text-align: right;" *ngIf="this._mode == 2">
                                   
                                    <button type="button" class="btn btn-lg mb-3" (click)="DeleteConfirm(index)"
                                    style="color:white; background:red; margin: 5px;" >
                                    <em class="far fa-trash-alt icon-in-table"></em>&nbsp;&nbsp;Delete
                                    </button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <table class="table table-bordered" id="tableTab3">
                                        <caption style="display: none;">This is a custom table</caption>
                                        <thead style="text-align: center; background-color: #033153; color: white;  font-size:22px">
                                        <tr>
                                            <th scope="col"></th>
                                            <th scope="col">Inherent</th>
                                            <th scope="col">If Mitigated</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td >Likelihood</td>
                                            <td>
                                                <select class="form-control" style="text-align: center;" formControlName="likelihoodInherent" (change)="onSelctChangeTab3(index)"
                                                [ngClass]="{'input-error': (item.get('likelihoodInherent').invalid && item.get('likelihoodInherent').touched)}">
                                                    <option [ngValue]="null" [disabled]="true" >Please Select</option>
                                                    <option *ngFor="let item of likelihoodValues" [ngValue]="item.value">{{item.name}}</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select class="form-control" style="text-align: center;" formControlName="likelihoodIfMitigated" (change)="onSelctChangeTab3_2(index)"
                                                [ngClass]="{'input-error': (item.get('likelihoodIfMitigated').invalid && item.get('likelihoodIfMitigated').touched)}">
                                                    <option [ngValue]="null" [disabled]="true" >Please Select</option>
                                                    <option *ngFor="let item of likelihoodValues" [ngValue]="item.value">{{item.name}}</option>
                                                </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Impact</td>
                                            <td>
                                                <select class="form-control" style="text-align: center;" formControlName="impactInherent" (change)="onSelctChangeTab3(index)"
                                                [ngClass]="{'input-error': (item.get('impactInherent').invalid && item.get('impactInherent').touched)}">
                                                    <option [ngValue]="null" [disabled]="true" >Please Select</option>
                                                    <option *ngFor="let item of impactValues" [ngValue]="item.value">{{item.name}}</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select class="form-control" style="text-align: center;" formControlName="impactIfMitigated" (change)="onSelctChangeTab3_2(index)"
                                                [ngClass]="{'input-error': (item.get('impactIfMitigated').invalid && item.get('impactIfMitigated').touched)}">
                                                    <option [ngValue]="null" [disabled]="true" >Please Select</option>
                                                    <option *ngFor="let item of impactValues" [ngValue]="item.value">{{item.name}}</option>
                                                </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Risk Level</td>

                                            <td>
                                                <div class="col-12" style="height: 2rem"
                                                [ngClass]="{'low': item.get('riskLevelInherent').value == 1,'medium': item.get('riskLevelInherent').value == 0.5, 'high': item.get('riskLevelInherent').value == 0}">&nbsp;
                                                </div>
                                            </td>
                                            
                                            <td>
                                                <div class="col-12" style="height: 2rem"
                                                [ngClass]="{'low': item.get('riskLevelIfMitigated').value == 1,'medium': item.get('riskLevelIfMitigated').value == 0.5, 'high': item.get('riskLevelIfMitigated').value == 0}">&nbsp;
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Mitigatable</td>
                                            <td></td>
                                            <td style="text-align: center;">
                                                <span *ngIf="item.get('mitigatable').value == 1"><strong>YES</strong></span>
                                                <span *ngIf="item.get('mitigatable').value == 0"><strong>NO</strong></span>
                                            </td>

                                        </tr>
                                        <tr>
                                            <td>Acceptable</td>
                                            <td></td>
                                            <td  style="text-align: center;">                                           
                                                <select class="form-control" style="text-align: center;" formControlName="acceptable" (change)="onSelctAcceptableChange(index)">
                                                    <option [ngValue]="null" [disabled]="true" >Please Select</option>
                                                    <option *ngFor="let item of acceptableValues" [ngValue]="item.value">{{item.name}}</option>
                                                </select>
                                            </td>

                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
    
                        </div>
                    </div>
                    <div class="row" *ngIf="this._mode == 2">
                        <div class="col-6 pt-5">
                            <button type="button" class="btn btn-lg mb-3"
                            style="color:white;background:orange;margin: 5px;" (click)="AddTableTab3()"><em class="fas fa-plus icon"></em>&nbsp;&nbsp;&nbsp;Add Next Risk
                            </button>
                        </div>
                    </div>


                </div>
            </ng-container>
        </ng-container>
        <div class="col-6">
            <div class ="row">
                <div class="col-12" style="height: fit-content; padding-top: 2em;">
                    <app-strategy-table 
                    [datas] = "dataStrategyList"
                    [width] = "'100%'"
                    [height] = "'100%'"
                    >
                    </app-strategy-table>
                </div>
            </div>
            
            <div class ="row" style="padding-top: 100px">
                <div class="col-12 pt-5" style="justify-content: center; height: fit-content; padding-bottom: 0px!important;">
                    <app-accept-table
                    [datas] = "dataStrategyList"
                    [width] = "'100%'"
                    [height] = "'100%'"
                    [mode] ="'register'"
                    >
                    </app-accept-table>
                </div>

            </div>

            

        </div>

        <div class="row col-12" style="padding-top: 100px;">
            <div class="col-6 pt-2">

            </div>
            <div class="col-6 pt-2" style="float:left;" >
                <div class="col" style="text-align: end;">

    
                    <button type="button" class="btn btn-lg mb-3" 
                    style="color:white;background:rgb(0, 0, 0);margin: 5px;" (click)="StrategicAssumption()">Go to Strategic Assumption
                    </button>
    
                    <button *ngIf="this._mode == 2" type="button" class="btn btn-lg mb-3" 
                    style="color:white;background:green;margin: 5px;" (click)="save('Risk from Strategy')">Save
                    </button>

                    <button *ngIf="this._mode == 1" type="button" (click)="backMonitor()" class="btn btn-lg mb-3" style="background-color:#9eb0ba;color:white; margin: 5px;" >
                        Back to Monitor & Review Page
                    </button>

                </div>
            </div>  
        </div>
        
    </div>

    <div class="row bg" *ngIf="this.tabSelect == 4"> 
        <div class="row" style="padding-left: 30px;">
            <h5 style="color:red">All fields are required.</h5>
        </div>
        <ng-container [formGroup]="dataFormTab4">
            <ng-container formArrayName="dataAssumpsion">        
                <div style="overflow-y:auto; height: 600px">
                    <div *ngFor="let item of dataAssumpsion.controls; let index = index" class="col-12"  [formGroupName]="index">
                        <div class="row">
                            <div class="col-11 pt-4">
                                <h6>Assumption {{index+1}}</h6>
                            </div>
                            <div class="col-1" style="text-align: right; padding: 0;" *ngIf="this._mode == 2">
                                <button type="button" class="btn btn-lg mb-3" (click)="DeleteConfirm(index)"
                                style="color:white;background:red;margin: 5px;" >
                                <em class="far fa-trash-alt icon-in-table"></em>&nbsp;&nbsp;&nbsp;Delete
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <table class="table table-bordered " id="tableTab4">
                                    <caption style="display: none;">This is a custom table</caption>
                                    <thead style="text-align: center; background-color: #033153; color: white; font-size: 18px;">
                                    <tr>
                                        <th scope="col">Category</th>
                                        <th scope="col">Name/Description</th>
                                        <th scope="col">Favorable Status<br>(GREEN)</th>
                                        <th scope="col">Unfavorable Status<br>(RED)</th>
                                        <th scope="col">Action If RED</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr style="vertical-align: top;">

                                        <td>
                                            <select class="form-control" formControlName="categoryId"
                                            [ngClass]="{'input-error': (item.get('categoryId').invalid && item.get('categoryId').touched)}">
                                                <option [ngValue]="null" [disabled]="true" >Please Select</option>
                                                <option *ngFor="let item of assumptionsValue" [ngValue]="item.assumptionCategory_ID">{{item.category_Name}}</option>
                                            </select>
                                        </td>
                                        <td>
                                            <textarea  autosize rows="1" style="min-height: 2.5rem;" formControlName="assumptionDescription" class="col-12 form-control" 
                                            [ngClass]="{'input-error': (item.get('assumptionDescription').invalid && item.get('assumptionDescription').touched)}">
                                            </textarea>
                                        </td>
                                        <td>
                                           
                                            <textarea  autosize rows="1" style="min-height: 2.5rem;" formControlName="favorableStatus" class="col-12 form-control"
                                            [ngClass]="{'input-error': (item.get('favorableStatus').invalid && item.get('favorableStatus').touched)}">
                                            </textarea>
                                        </td>
                                        <td>
                                            <textarea autosize rows="1" style="min-height: 2.5rem;" type="text" class="col-12 form-control" formControlName="unfavorableStatus"
                                            [ngClass]="{'input-error': (item.get('unfavorableStatus').invalid && item.get('unfavorableStatus').touched)}">
                                            </textarea>
                                        </td>
                                        <td>
                                            <textarea autosize rows="1" style="min-height: 2.5rem;" type="text" class="col-12 form-control" formControlName="actionRed"
                                            [ngClass]="{'input-error': (item.get('actionRed').invalid && item.get('actionRed').touched)}">
                                            </textarea>
                                        </td>
                                    </tr>
                                    
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    
                    </div>
                </div>

            </ng-container>
        </ng-container>
        <div class="row col-12">
            <div class="col-6 pt-5" *ngIf="this._mode == 2">
                <button type="button" class="btn btn-lg mb-3"
                style="color:white;background:orange;margin: 5px;" (click)="AddTableTab4()">
                <em class="fas fa-plus icon"></em>&nbsp;&nbsp;&nbsp;Add Next Assumption
                </button>
            </div>

            <div class="col" style="float: left;" >
                <div class="row pt-5">
                    <div class="col-12" style="text-align: end;">
                        <button *ngIf="this._mode == 2" type="button" class="btn btn-lg mb-3" 
                            style="color:white;background:green;margin: 5px;" (click)="save('Strategic Assumption')">Save
                        </button>
    
                        <button *ngIf="this._mode == 2" type="button" class="btn btn-lg mb-3" 
                            style="color:white;background:#0A4999;margin: 5px;" (click)="registerStrategy()">Go to Summary Page
                        </button>

                        <button *ngIf="this._mode == 1" type="button" (click)="backMonitor()" class="btn btn-lg mb-3" style="background-color:#9eb0ba;color:white; margin: 5px;" >
                            Back to Monitor & Review Page
                        </button>
                        
                    </div>
                </div>   
            </div>
            
        </div>
       
    </div>
</div>

<app-modal-alert (confirmed)="confirmFunc()" [title]="titleAlert" [message]="alertflow"></app-modal-alert>
<app-modal-selectbu [active] = "isActiveBuSelect" (returnEvent)="setBuCode($event)"></app-modal-selectbu>
<app-loading [loading]='this.isLoadingAll'></app-loading>