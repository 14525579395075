<div class="container-fluid appform my-3">
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">
            <div class="col-12 col-md-12 col-lg-12 col-xl-12 pb-5 mt-5 mb-5">
                <div class="px-0 pb-2">
                    <div class="card col-12 mt-4 mx-0 px-3">
                        <div class="riskCategory  col-12 mb-3 rounded">
                            <div class="col-0 pt-2">
                                <em class="fas fa-bars icon"></em>&nbsp;&nbsp;Risk Category 
                            </div>
                        </div>
                        <button type="button" class="btn btn-lg btn-addriskcatagory-primary mb-3" style="width: 200px;color:white;background:orange;" (click)="func_addriskCategory ({},'Add')">
                            <em class="fas fa-plus icon"></em>&nbsp;&nbsp;&nbsp;Add Risk Category 
                        </button>
                        <div class="table-riskCategory ">
                            <table class="table table-bordered" style="width:100%;">
                                <caption style="display: none;" >This is a custom table</caption>
                                <thead style="text-align: center;background-color:#033153;color:white;font-size:18px;">
                                    <tr>
                                        <th style="width: 50px;vertical-align: middle;">Year</th>
                                        <th style="width: 50px;vertical-align: middle;">Quarter</th>
                                        <th style="width: 100px;vertical-align: middle;">Risk Category </th>
                                        <th style="width: 80px;vertical-align: middle;">Corporate Category</th>
                                        <th style="width: 80px;vertical-align: middle;">Order Number</th>
                                        <th style="width: 80px;vertical-align: middle;">Active</th>
                                        <th style="width: 50px;vertical-align: middle;">Edit</th>
                                    </tr>
                                </thead>
                                <tbody style="background-color: white;font-size:16px;">
                                    <tr *ngFor='let data of tableriskCategory '>
                                        <td style="text-align: center;">{{data.quarterYear}}</td>
                                        <td style="text-align: center;">{{data.quarterName}}</td>
                                        <td>{{ data.riskCategoryName }}</td>
                                        <td style="text-align: center;">
                                            <span *ngIf="data.ermFlag === '0'">Yes</span>
                                            <span *ngIf="data.ermFlag === '1'">No</span>
                                        </td>
                                        <td style="text-align: center;">{{ data.orderNum }}</td>
                                        <td style="text-align: center;" *ngIf="data.delFlag === '0'; else elseBlock">Yes</td>
                                        <ng-template #elseBlock><td style="text-align: center;">No</td></ng-template>
                                        <td style="text-align: center;">
                                            <button type="button" class="btn" (click)="func_addriskCategory (data,'Edit')" *ngIf="tempchkyear == currentYear">
                                                <em class="fas fa-edit" style="color:orange;font-size:16px;"></em>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal fade bd-RiskCategory " tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" id="modaladdriskCategory">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="container-fluid appform my-3">
                                        <div class="row">
                                            <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">
                                                <div class="container col-12 col-md-12 col-lg-12 col-xl-12 pb-0 mt-0 mb-0 ">
                                                    <div class="container">
                                                        <div class="col-12 rounded addriskCategory ">
                                                            <div class="col-0 pt-2">RiskCategory Management</div>
                                                        </div>
                                                        <div class="card mt-4">
                                                            <div class="col-12 col-xl-12 mb-4">

                                                                <div class="row col-12 mx-0 px-0 mb-4 d-flex justify-content-around">
                                                                    <div class="row col-12 col-xl-3 px-0">
                                                                        <div class="col-12 mb-2 px-2">
                                                                            <strong class="formtext">Year</strong>
                                                                        </div>
                                                                        <div class="col-12 px-2">
                                                                            <select class="form-control" [(ngModel)]="model.Year" (change)="SelectedYear('')" [disabled]=' Module == "update" '>
                                                                                <option value="">Please Select</option>
                                                                                <option *ngFor="let data of YearDD" [value]="data.text" [disabled]=' data.text != currentYear '>{{data.text}}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row col-12 col-xl-3 px-0">
                                                                        <div class="col-12 mb-2 px-2">
                                                                            <strong class="formtext">Quarter</strong>
                                                                        </div>
                                                                        <div class="col-12 px-2">
                                                                            <select class="form-control" [(ngModel)]="model.Quarter" [disabled]=' Module == "update" || model.Year == "" '>
                                                                                <option value="">Please Select</option>
                                                                                <option *ngFor="let data of AddCo_QuarterDD" [value]="data.value">{{data.text}}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row col-12 col-xl-6"></div>
                                                                </div>

                                                                <div class="row col-12 mx-0 px-0 mb-4 d-flex justify-content-around">
                                                                    <div class="row col-12 col-xl-4 px-0">
                                                                        <div class="col-12 mb-2 px-2">
                                                                            <strong class="formtext">Risk Category Name:</strong>
                                                                        </div>
                                                                        <div class="col-12 px-2">
                                                                            <input type="text" class="form-control" id="" placeholder="RiskCategory Name" [(ngModel)]="RiskCategoryName"/>
                                                                            <div  style="color: red;"  *ngIf="!this.riskCateGoryNameValid">risk category name  is reqiure !</div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="row col-12 col-xl-4 px-0">
                                                                        <div class="col-12 mb-2 px-2">
                                                                            <strong class="formtext">Order Number:</strong>
                                                                        </div>
                                                                        <div class="col-12 px-2">
                                        
                                                                            <input type="number" class="form-control" id="" placeholder="OrderBy" [(ngModel)]="OrderNum"/>
                                                                            <div style="color: red;" *ngIf="!this.riskCateGoryOrderByValid">order number is reqiure !</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row col-12 col-xl-4"></div>
                                                                </div>

                                                                <div class="row col-12">
                                                                    <div class="row col-12 col-xl-3">
                                                                        <div class="col-12 mb-2 px-0 pt-4">
                                                                            <label for="chkbox">
                                                                                <strong class="formtext px-2">Active</strong>
                                                                            </label>&nbsp;
                                                                            <input type="checkbox" [(ngModel)]="DelFlag" id="chkbox">
                                                                        </div>
                                                                    </div>
                                                                    <div class="row col-12 col-xl-4">
                                                                        <div class="col-12 mb-2 px-0 pt-4">
                                                                            <label for="chkboxErm">
                                                                                <strong class="formtext px-2">Corporate Category</strong>
                                                                            </label>&nbsp;
                                                                            <input type="checkbox" [(ngModel)]="RiskErmFlag" id="chkboxErm">
                                                                           
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div class="col-12 my-3 mx-0 text-center">
                                                            <button type="button" class="btn mr-3" style="width: 100px;background-color:#9eb0ba;color:white;" (click)="func_closebtn()">Close</button>
                                                            <button type="button" class="btn" style="width: 100px;background-color:#033153;color:white;" (click)="func_savebtn()">Save</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-loading [loading]='loading'></app-loading>