import { Component, OnInit, Injectable, Inject, TemplateRef, ViewChild } from '@angular/core';
import { Masterserve } from './services/master.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
declare const $;



// add this import
import { Subject } from 'rxjs';


import { AppSettingService } from './app-setting-service';

import { AuthenService } from './services/authenservice.service';
import { PublicClientApplicationService } from './services/publicClientApplication.service';
import { MsalService } from '@azure/msal-angular';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [Masterserve]
})

@Injectable({ providedIn: 'root' })

export class AppComponent implements OnInit {

  apiToken = sessionStorage.getItem('apiToken') ? sessionStorage.getItem('apiToken') : '';
  buildversion = '';
  chkeditdate = false;
  tmpdate;
  title = 'PTTEP';
  status;
  status_pageIdentification;
  riskstep = false;
  path = false;
  alertflow = ''
  templink;
  tempfrommail = '1';
  mode ='main'
  isIframe = false;
  loggedIn = false;
  NotloggedInAZ = false;
  loginRedirect = false;
  NotnameAZ = "";
  NotpassAZ = "";
  btnName = "";
  userinfo: any;
  Azureuserinfo: any;
  userdata: any;
  loginRole: any;
  showname = "";
  showBUName = "";
  showLoginBUName = "";
  dialogRef: MatDialogRef<any>;
  imgbanner = null;
  isLoginAll = false;
  isLoadingAll = false;
  dataconfign: any;
  // TimeOut
  time;
  timeValue;
  tablemianmanu;

  private readonly _destroying$ = new Subject<void>();

  constructor(
    public mserve: Masterserve,
    public dialog: MatDialog,
    public appsettsv: AppSettingService,
    public authserviceToken: AuthenService,
    public genmsalService: PublicClientApplicationService,

  ) { }
  configdata = this.appsettsv.read();
  BASE_URL = this.configdata.api_url;
  pathbanner = this.BASE_URL + '/app/backend/Resources/FileUpload';
  authService: MsalService;
  public config: AppSettingService;
  msalInstanceData: any;
  ngOnInit(): void {
    
  }

  changeOfRoutes() {
    let pathname = window.location.pathname
    let temp = pathname.indexOf("/", pathname.indexOf("/") + 1);
    if (temp !== -1) {
      pathname = pathname.slice(0, temp);
    }

    if (pathname === '/Identification' || pathname === '/Assessment' || pathname === '/NewAssessment' || pathname === '/ReviewPage') {
      this.path = true;
    } else {
      this.path = false;
    }

    if (this.path === true) {
      if (pathname === '/Identification') {
        let element21 = document.getElementById("step2");
        element21.classList.remove("active");
        let element31 = document.getElementById("step3");
        element31.classList.remove("active");
      }
      else if (pathname === '/Assessment') {
        let element11 = document.getElementById("step2");
        element11.classList.add("active");
        let element22 = document.getElementById("step3");
        element22.classList.remove("active");
      }
      else if (pathname === '/NewAssessment') {
        let element12 = document.getElementById("step2");
        element12.classList.add("active");
        let element22 = document.getElementById("step3");
        element22.classList.remove("active");
      }
      else if (pathname === '/ReviewPage') {
        let element13 = document.getElementById("step2");
        element13.classList.add("active");
        let element23 = document.getElementById("step3");
        element23.classList.add("active");
      }
    }
  }

  setBuName(newItem){
    //console.log("Setaaaaaaaaaaaaaaa");
  }
 





}


