import { OnInit, Component, Injectable } from "@angular/core";
import { NgbModal, NgbModalConfig, NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Masterserve } from '../../../services/master.service';
import { AuthenService } from "src/app/services/authenservice.service";
declare let $;

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day : (date[0], 10),
        month : (date[1], 10),
        year : (date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
        if (date !== null) {
            let day = date.day.toString()
            let month = date.month.toString()
            if (day.length < 2) {
                day = '0' + day
            }
            if (month.length < 2) {
                month = '0' + month
            }
            let newdate   = day + this.DELIMITER + month + this.DELIMITER + date.year;
            return newdate;
        } else {
            return ''; 
        }
    }
}
//---------------------------------------------------------------------------------------------------------------------

@Component({
    selector: 'app-MasterLikelihood',
    templateUrl: './masterlikelihood.html',
    styleUrls: ['./masterlikelihood.css'],
    providers: [Masterserve, NgbModalConfig, NgbModal,{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }]
})
export class MasterLikelihood implements OnInit {

    constructor(public authserviceToken: AuthenService,config: NgbModalConfig, private modalService: NgbModal,
        public mserve: Masterserve) {
          config.backdrop = 'static';
          config.keyboard = false; 
        }

    tablelikeLihoodCriteria;
    Data_SessionStorage;

    SessionEmpID;
    Module;

    LikelihoodID;
    QuarterID;
    LikelihoodCode;
    LikelihoodName;

    LikelihoodItemID;
    LikelihoodItemCode;
    LikelihoodItemName;
    Description;
    DelFlag
    statustopmenu;
    YearDD = [];
    QuarterDD;
    Quarter_Getlist;
    Year_Getlist = null;
    currentYear;
    loading = false;


    ngOnInit(): void {
        let _s = this;

        this.Data_SessionStorage = JSON.parse(sessionStorage.datainfo);
        //console.log('Data_SessionStorage => ',this.Data_SessionStorage);
        this.SessionEmpID = this.Data_SessionStorage[0].employeE_ID;

        let date = new Date();
        this.currentYear = date.getFullYear();
        this.Year_Getlist = date.getFullYear();
        
        _s.statustopmenu = [
            { value:"0", title: "Active" },
            { value:"1", title: "InActive" },
        ]


        this.loadDDL();
    }

    loadDDL () {
        const _s = this;
        _s.mserve.Master_DDL({ Module:"YearDD" }).then((response) => {
            _s.YearDD = response.data.body
            //console.log("YearDD : ",_s.YearDD);
            _s.func_GetQuarter();
        });
    }

    func_GetQuarter () {
        
        const _s = this;
        let d = new Date();
        let currentMonth = d.getMonth() + 1;
        _s.mserve.Master_DDL({ Module:"QuarterDD",TextSearch1: this.Year_Getlist }).then((response) => {
            _s.QuarterDD = response.data.body
            //console.log("QuarterDD : ",_s.QuarterDD)
            this.func_calQuarter(currentMonth);
        })
    }

    func_calQuarter (month) {
        
        this.Quarter_Getlist = ''
        if (month >= 1 && month <= 3) {
            this.Quarter_Getlist = this.QuarterDD[0].value;
        } else if (month >= 4 && month <= 6) {
            this.Quarter_Getlist = this.QuarterDD[1].value;
        } else if (month >= 7 && month <= 9) {
            this.Quarter_Getlist = this.QuarterDD[2].value;
        } else if (month >= 10 && month <= 12) {
            this.Quarter_Getlist = this.QuarterDD[3].value;
        }
        this.func_Getlist();
    }

    func_Getlist () {
        const _s = this;
        let tempQuarter = (this.Year_Getlist == this.currentYear) ? this.Quarter_Getlist : this.QuarterDD[3].value;
        
        this.loading = true;
        let datavalue = {
            SessionEmpID:this.SessionEmpID,
            Module:"getlist",
            body:{
                LikelihoodID:"",
                QuarterID :tempQuarter,
                LikelihoodCode:"Test0002",
                LikelihoodName:"GetFinancialImpact1",
                DelFlag:"0",
                sReqLikelihoodItem:{
                    LikelihoodItemID:"",
                    LikelihoodItemCode:"",
                    LikelihoodItemName:"",
                    Description:"",
                    DelFlag:""
                }
            }
        }

        _s.mserve.GetLikelihood(datavalue).then((response) => {
            if(response.data.status === true){
                _s.tablelikeLihoodCriteria = response.data.body;
                //console.log('data',_s.tablelikeLihoodCriteria);
                this.loading = false;
            }else{
                //console.log('false',response)
                alert(response.data.errorMessage);
            }
        }).catch((e) => {
            this.authserviceToken.CheckTokenExpire(e.response.status)
            });
    }

    func_addlikelihood(item,mode){
        let _s = this;
        let _t = _s.tablelikeLihoodCriteria[0]

        _s.LikelihoodID = _t.likelihoodID
        _s.LikelihoodCode = _t.likelihoodCode
        _s.LikelihoodName = _t.likelihoodName
        _s.Description = _t.description
        
        if(mode === 'Add'){
            _s.Module = 'insert'
            _s.LikelihoodItemID = ''
            _s.LikelihoodItemCode = ''
            _s.LikelihoodItemName = ''
            _s.Description = ''
            _s.DelFlag = '0'
        }else{
            _s.Module = 'update'
            _s.LikelihoodItemID = item.likelihoodItemID
            _s.LikelihoodItemCode = item.likelihoodItemCode
            _s.LikelihoodItemName = item.likelihoodItemName
            _s.Description = item.description
            _s.DelFlag = item.delFlag
          }
            //console.log('_s.DelFlag',_s.DelFlag)
            //console.log('item.delFlag',item.delFlag)
          

        $('#modaladdlikelihood').modal('show')
    }

    func_savebtn() {
        let _s = this;
        let datavalue = {
            SessionEmpID:this.SessionEmpID,
            Module:_s.Module,
            body:{
                LikelihoodID:_s.LikelihoodID,
                QuarterID:"1",
                LikelihoodCode:_s.LikelihoodCode,
                LikelihoodName:_s.LikelihoodName,
                DelFlag:"0",
                sReqLikelihoodItem:[{
                    LikelihoodItemID:_s.LikelihoodItemID,
                    LikelihoodItemCode:_s.LikelihoodItemCode,
                    LikelihoodItemName:_s.LikelihoodItemName,
                    Description:_s.Description,
                    DelFlag:_s.DelFlag
                }]
            }
        }
        
        _s.mserve.GetLikelihood(datavalue).then((response) => {
            if(response.data.status === true){
                //console.log('data',_s.tablelikeLihoodCriteria)
                _s.DelFlag = '';
                $('#modaladdlikelihood').modal('hide');
                _s.func_Getlist();
            }else{
                //console.log('false',response)
                alert(response.data.errorMessage);
            }
        }).catch((e) => {
            this.authserviceToken.CheckTokenExpire(e.response.status)
            });
    }

    func_closebtn() {
        $('#modaladdlikelihood').modal('hide')
    }
}