<div class="container-fluid appform my-3">
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">
            <div class="col-12 col-md-12 col-lg-12 col-xl-12 pb-5 mt-5 mb-5">
                <div class="card mt-4 py-4">
                    <div class="col-12 mb-4">
                        <div class="col-2 rounded searcrisk" style="text-align: center;">
                            <strong>Report Risk Item</strong>
                        </div>
                        <div class="row col-12 col-xl-12 mt-3 mb-3 mx-0 px-0 d-flex justify-content-around">
                            <div class="row col-12 col-xl-3">
                                <div class="col-12 mb-2 px-0">
                                    <strong class="formtext">Business Unit</strong>
                                </div>
                                <div class="col-12 px-0">
                                    <input type="text" class="form-control m-0" aria-label="Number" matInput [formControl]="myControlBU" [matAutocomplete]="autoBU" placeholder="Please Select" [(ngModel)]="tempBU" (keyup.enter)="SelectBU()">
                                    <mat-autocomplete autoActiveFirstOption #autoBU="matAutocomplete">
                                        <mat-option *ngFor="let option of filteredOptionsBU | async" [value]="option.text" (click)="SelectBU()">
                                        {{option.text}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                            </div>
                            <div class="row col-12 col-xl-3">
                                <div class="col-12 mb-2 px-0">
                                    <strong class="formtext">Risks Status</strong>
                                </div>
                                <div class="col-12 px-0">
                                    <select class="form-control" [(ngModel)]="Search.RiskStatus">
                                        <option value="">Please Select</option>
                                        <option value="1">Active</option>
                                        <option value="2">Close (Turn to problem)</option>
                                        <option value="3">Close (Mitigation Completed)</option>
                                        <option value="4">Close (Other)</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row col-12 col-xl-3">
                                <div class="col-12 mb-2 px-0">
                                    <strong class="formtext">KRI Status</strong>
                                </div>
                                <div class="col-12 px-0">
                                    <select class="form-control" [(ngModel)]="Search.RiskKRIStatus">
                                        <option value="">Please Select</option>
                                        <option value="0">Green</option>
                                        <option value="1">Yellow</option>
                                        <option value="2">Red</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row col-12 col-xl-3">
                                <div class="col-12 mb-2 px-0">
                                    <strong class="formtext">Risk Category</strong>
                                </div>
                                <div class="col-12 px-0">
                                    <select class="form-control" [(ngModel)]="Search.RiskCate">
                                        <option value="">Please Select</option>
                                        <option *ngFor="let item of Risk_ByCategory" value="{{ item.value }}">{{ item.text }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 mb-4">
                        <div class="row col-12 col-xl-12 mt-3 mb-3 mx-0 px-0 d-flex justify-content-around">

                            <div class="row col-12 col-xl-6">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12 px-0">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-6 row mx-0 px-0">
                                                        <div class="col-12 mb-2">
                                                            <strong class="formtext">Year</strong>
                                                        </div>
                                                        <div class="col-12 pl-0">
                                                            <select class="form-control" [(ngModel)]="DataSearch.Yearfrom" (change)="func_selFromyear()">
                                                                <option value="">Please Select</option>
                                                                <option *ngFor="let item of DDLYear" value="{{ item.value }}">{{ item.text }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-6 row mx-0 px-0">
                                                        <div class="col-12 mb-2">
                                                            <strong class="formtext">Quarter</strong>
                                                        </div>
                                                        <div class="col-12 pr-0">
                                                            <select class="form-control" [(ngModel)]="DataSearch.Quarterfrom" [disabled]="DataSearch.Yearfrom === '' " (change)="changeQuarter()">
                                                                <option value="">Please Select</option>
                                                                <option *ngFor="let item of DDLQuarter" value="{{ item.text }}">{{ item.text }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row col-12 col-xl-3">
                                <div class="col-12 mb-2 px-0">
                                    <strong class="formtext">Risk Rating</strong>
                                </div>
                                <div class="col-12 px-0">
                                    <select class="form-control" [(ngModel)]="Search.RiskRating">
                                        <option value="">Please Select</option>
                                        <option *ngFor="let item of RatingDD" value="{{ item.value }}">{{ item.text }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row col-12 col-xl-3">
                                <div class="col-12 mb-2 px-0">
                                    <strong class="formtext">Risk Name</strong>
                                </div>
                                <div class="col-12 px-0">
                                    <input type="text" class="form-control" [(ngModel)]="Search.RiskName">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mb-4">
                        <div class="row col-12 col-xl-12 mt-3 mb-3 mx-0 px-0 d-flex justify-content-around">
                            <!-- <div class="row col-12 col-xl-3">
                                <div class="col-12 mb-2 px-0">
                                    <strong class="formtext">Table Type</strong>
                                </div>
                                <div class="col-12 px-0">
                                    <select class="form-control" [(ngModel)]="Search.Tabletype">
                                        <option value="0">All</option>
                                        <option value="1">Staff</option>
                                        <option value="2">Transection</option>
                                    </select>
                                </div>
                            </div> -->

                            <!-- <div class="row col-12 col-xl-3"> -->
                            <div class="row col-12 col-xl-6">
                                <div class="col-12 mb-2 px-0">
                                    <strong class="formtext">Include Consolidate Item</strong>
                                </div>
                                <div class="col-12 px-0">
                                    <input type="checkbox" class="form-control" [(ngModel)]="inCludeConsolidate" style="max-width: 15px;">
                                </div>
                            </div>
                            <div class="row col-12 col-xl-6"></div>
                        </div>
                    </div>
                    <div class="col-12" style="text-align: center;">
                        <button type="button" class="btn mr-2" (click)="func_clear()" style="background:#9eb0ba;color: white;font-size: 20px;width: 100px;">Clear</button>
                        <button type="button" class="btn" (click)="func_getData('2')" style="background:#023154;color: white;font-size: 20px;width: 100px;">Search</button>
                    </div>

                </div>

                
                <div class="col-12 px-0" style="text-align: right;">
                    <button type="button" class="btn" (click)="Export_excel()" style="background:#023154;color:white;width:100px;font-size:20px;">Export</button>
                </div>

                <div class="card p-2 mt-2 fix-height" style="overflow:auto;">

                        <div style="min-width: 4400px;">
                            <div class="row mx-0 px-0">

                                <div class="col-12 border border-dark">
                                    <div class="row text-white" style="font-size: 18px;" style="text-align: center;">
                                        <div class="col-4 border border-top-0 border-left-0 border-dark bg-info">
                                            <span>Risk Profile</span>
                                        </div>
                                        <div class="col-4 border border-top-0 border-dark bg-success">
                                            <span>Root Cause</span>
                                        </div>
                                        <div class="col-4 border border-top-0 border-right-0 border-dark bg-warning">
                                            <span>Impact</span>
                                        </div>
                                    </div>

                                    <div class="row text-white" style="font-size: 18px;" style="text-align: center;">
                                        <div class="col-4 border">
                                            <div class="row bg-info">
                                                <div class="col-2 px-0 border border-dark">Risk ID</div>
                                                <div class="col-1 px-0 border border-dark">Version</div>
                                                <div class="col-2 px-0 border border-dark">Risk Name</div>
                                                <div class="col-1 px-0 border border-dark">Business Unit</div>
                                                <div class="col-1 px-0 border border-dark">Workflow Status</div>
                                                <div class="col-1 px-0 border border-dark">Risk Status</div>
                                                <div class="col-1 px-0 border border-dark">Likelihood</div>
                                                <div class="col-1 px-0 border border-dark">Impact Level</div>
                                                <div class="col-1 px-0 border border-dark">Risk Rating</div>
                                                <div class="col-1 px-0 border border-dark">Risk Type</div>
                                            </div>
                                        </div>
                                        <div class="col-4 px-0 border  bg-success">
                                            <div class="row mx-0 px-0">
                                                <div class="col-12 px-0 border">
                                                    <div class="row mx-0 px-0">
                                                        <div class="col-3 px-0 border border-dark">Root Cause</div>
                                                        <div class="col-9 mx-0 px-0">
                                                            <div class="row mx-0 px-0">
                                                                <div class="col-2 px-0 border border-dark">Likelihood Level</div>
                                                                <div class="col-5 px-0 border border-dark">Mitigation Plan</div>
                                                                <div class="col-1 px-0 border border-dark">Owner</div>
                                                                <div class="col-2 px-0 border border-dark">Due Date</div>
                                                                <div class="col-2 px-0 border border-dark">Progress Justification</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4 px-0 border bg-warning">
                                            <div class="row mx-0 px-0">
                                                <div class="col-12 px-0 border">
                                                    <div class="row mx-0 px-0">
                                                        <div class="col-1 px-0 border border-dark">Impact</div>
                                                        <div class="col-1 px-0 border border-dark">Description</div>
                                                        <div class="col-1 px-0 border border-dark">(MM USD)</div>
                                                        <div class="col-9 mx-0 px-0">
                                                            <div class="row mx-0 px-0">
                                                                <div class="col-2 px-0 border border-dark">Impact Level</div>
                                                                <div class="col-5 px-0 border border-dark">Mitigation Plan</div>
                                                                <div class="col-1 px-0 border border-dark">Owner</div>
                                                                <div class="col-2 px-0 border border-dark">Due Date</div>
                                                                <div class="col-2 px-0 border border-dark">Progress Justification</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row" style="font-size: 16px;" *ngFor="let item of tempData index as indexlv1">
                                        <div class="col-12 border">
                                            <div class="row">
                                                <div class="col-4 px-0">
                                                    <div class="row mx-0 px-0">
                                                        <div class="col-2 px-0 border border-dark">
                                                            <span *ngIf="item.risk_Co_Id !== null; else elseBlock">{{ item.risk_Co_Id }}</span>
                                                            <ng-template #elseBlock>{{ item.risk_Staff_Id }}</ng-template>
                                                        </div>
                                                        <div class="col-1 px-0 border border-dark" style="text-align: center;">{{ item.version }}</div>
                                                        <div class="col-2 px-0 border border-dark">{{ item.risk_Name }}</div>
                                                        <div class="col-1 px-0 border border-dark">{{ item.abbreviation }}</div>
                                                        <div class="col-1 px-0 border border-dark" style="text-align: center;">{{ item.risk_Status_Name}}</div>
                                                        <div class="col-1 px-0 border border-dark" style="text-align: center;">{{ item.risk_Status }}</div>
                                                        <div class="col-1 px-0 border border-dark" style="text-align: center;">{{ item.risk_Likelihood }}</div>
                                                        <div class="col-1 px-0 border border-dark" style="text-align: center;">{{ item.risk_Impact }}</div>
                                                        <div class="col-1 px-0 border border-dark" style="text-align: center;">{{ item.risk_Rating }}</div>
                                                        <div class="col-1 px-0 border border-dark" style="text-align: center;">{{ item.riskWF }}</div>
                                                    </div>
                                                </div>
                                                <div class="col-4 px-0">
                                                    <div class="row mx-0 px-0">
                                                        <div class="col-12 px-0 border">

                                                            <div class="row mx-0 px-0" *ngFor="let item2 of item.rootCause index as indexlv2">
                                                                <div class="col-3 border border-dark">
                                                                    {{ item2.rootCause_Category }}
                                                                </div>
                                                                <div class="col-9 mx-0 px-0">
                                                                    <div class="col-2 px-0 border border-dark" style="text-align: center;" *ngIf="item2.rootCause_Mitigation == null">{{ item2.rootCause_Likelihood }}</div>
                                                                    <div class="row px-0 mx-0" *ngFor="let item3 of item2.rootCause_Mitigation index as indexlv3">
                                                                        <div class="col-2 px-0 border border-dark" style="text-align: center;" *ngIf="indexlv3 === 0">{{ item2.rootCause_Likelihood }}</div>
                                                                        <div class="col-5 px-0 border border-dark" *ngIf="indexlv3 === 0">{{ item3.rootCause_Mitigation_Name }}</div>
                                                                        <div class="col-1 px-0 border border-dark" *ngIf="indexlv3 === 0">{{ item3.rootCause_Mitigation_Owner }}</div>
                                                                        <div class="col-2 px-0 border border-dark" style="text-align: center;" *ngIf="indexlv3 === 0">{{ item3.rootCause_Mitigation_DueDate }}</div>
                                                                        <div class="col-2 px-0 border border-dark" style="text-align: center;" *ngIf="indexlv3 === 0">{{ item3.rootCause_Mitigation_Progress }}%</div>

                                                                        <div class="col-2 px-0 border border-dark border-top-0" style="text-align: center;" *ngIf="indexlv3 !== 0">{{ item2.rootCause_Likelihood }}</div>
                                                                        <div class="col-5 px-0 border border-dark border-top-0" *ngIf="indexlv3 !== 0">{{ item3.rootCause_Mitigation_Name }}</div>
                                                                        <div class="col-1 px-0 border border-dark border-top-0" *ngIf="indexlv3 !== 0">{{ item3.rootCause_Mitigation_Owner }}</div>
                                                                        <div class="col-2 px-0 border border-dark border-top-0" style="text-align: center;" *ngIf="indexlv3 !== 0">{{ item3.rootCause_Mitigation_DueDate }}</div>
                                                                        <div class="col-2 px-0 border border-dark border-top-0" style="text-align: center;" *ngIf="indexlv3 !== 0">{{ item3.rootCause_Mitigation_Progress }}%</div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-4 px-0">
                                                    <div class="row mx-0 px-0">
                                                        <div class="col-12 px-0 border">

                                                            <div class="row mx-0 px-0"  *ngFor="let itemim2 of item.impact index as indeximlv2">
                                                                <div class="col-1 border border-dark">
                                                                    {{ itemim2.impact_Category }}
                                                                </div>
                                                                <div class="col-1 border border-dark" style="text-align: center;" *ngIf="itemim2.impact_Category == 'Financial'">
                                                                    {{ itemim2.impact_NPT_EMV }}
                                                                </div>
                                                                <div class="col-1 border border-dark" style="text-align: center;" *ngIf="itemim2.impact_Category != 'Financial'">
                                                                    {{ itemim2.impact_Description }}
                                                                </div>
                                                                <div class="col-1 border border-dark" style="text-align: center;">
                                                                    {{ itemim2.impact_Total_Amont }}
                                                                </div>
                                                                <div class="col-9 mx-0 px-0">
                                                                    <div class="col-2 px-0 border border-dark" style="text-align: center;" *ngIf="itemim2.impact_Mitigation == null">{{ itemim2.impact_Level }}</div>
                                                                    <div class="row px-0 mx-0" *ngFor="let itemim3 of itemim2.impact_Mitigation index as indeximlv3">
                                                                        <div class="col-2 px-0 border border-dark" style="text-align: center;" *ngIf="indeximlv3 === 0">{{ itemim2.impact_Level }}</div>
                                                                        <div class="col-5 px-0 border border-dark" *ngIf="indeximlv3 === 0">{{ itemim3.impact_Mitigation_Name }}</div>
                                                                        <div class="col-1 px-0 border border-dark" *ngIf="indeximlv3 === 0">{{ itemim3.impact_Mitigation_Owner }}</div>
                                                                        <div class="col-2 px-0 border border-dark" style="text-align: center;" *ngIf="indeximlv3 === 0">{{ itemim3.impact_Mitigation_DueDate }}</div>
                                                                        <div class="col-2 px-0 border border-dark" style="text-align: center;" *ngIf="indeximlv3 === 0">{{ itemim3.impact_Mitigation_Progress }}%</div>
                                                                        <div class="col-2 px-0 border border-dark border-top-0" style="text-align: center;" *ngIf="indeximlv3 !== 0">{{ itemim2.impact_Level }}</div>
                                                                        <div class="col-5 px-0 border border-dark border-top-0" *ngIf="indeximlv3 !== 0">{{ itemim3.impact_Mitigation_Name }}</div>
                                                                        <div class="col-1 px-0 border border-dark border-top-0" *ngIf="indeximlv3 !== 0">{{ itemim3.impact_Mitigation_Owner }}</div>
                                                                        <div class="col-2 px-0 border border-dark border-top-0" style="text-align: center;" *ngIf="indeximlv3 !== 0">{{ itemim3.impact_Mitigation_DueDate }}</div>
                                                                        <div class="col-2 px-0 border border-dark border-top-0" style="text-align: center;" *ngIf="indeximlv3 !== 0">{{ itemim3.impact_Mitigation_Progress }}%</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
             <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalAlertdanger5se">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <span class="bg-danger">&nbsp;</span>
                        <div class="modal-header">
                            <div class="row col-12 text-danger">
                                <div class="col-2">
                                    <em class="far fa-times-circle" style="font-size:50px;"></em>
                                </div>
                                <div class="col-10 pt-2">
                                    <h4 class="modal-title">Alert !</h4>
                                </div>
                            </div>
                        </div>
                        <div class="modal-body text-body border-0">
                            <div class="col-12" style="text-align: left;">
                                <span style="font-size:18px;">{{ alertflow }}</span>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger w-25" (click)="closealertdanger()">OK</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalVersioning">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <span class="bg-info">&nbsp;</span>
                        <div class="modal-header">
                            <div class="row col-12 text-info">
                                <div class="col-10 pt-2">
                                    <h4 class="modal-title">Version</h4>
                                </div>
                            </div>
                        </div>
                        <div class="modal-body text-body border-0">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-4 pt-2">
                                    <strong style="font-size:18px;">Year</strong>
                                    </div>
                                    <div class="col-8 px-0">
                                    <select class="form-control" [(ngModel)]="VersionYear" (change)="func_getQuarterVersion()">
                                        <option value="">Please Select</option>
                                        <option *ngFor="let item of DDLYear" value="{{ item.value }}">{{ item.text }}</option>
                                    </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mt-2">
                            <div class="row">
                                <div class="col-4 pt-2">
                                    <strong style="font-size:18px;">Quarter</strong>
                                </div>
                                <div class="col-8 px-0">
                                    <select class="form-control" [(ngModel)]="VersionQuarter" [disabled]="VersionYear == ''">
                                        <option value="">Please Select</option>
                                        <option *ngFor="let item of DDLQuarter" value="{{ item.value }}">{{ item.text }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info w-25 text-white" (click)="func_okversion()">Ok</button>
                            <button type="button" class="btn btn-secondary w-25" data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-loading [loading]='loading'></app-loading>