import { Component, OnInit } from "@angular/core";
import { Masterserve } from '../../../services/master.service';
declare let $;

import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AppSettingService } from "src/app/app-setting-service";
import { AuthenService } from "src/app/services/authenservice.service";

@Component({
    selector: 'app-MasterContactUs',
    templateUrl: './mastercontactus.html',
    styleUrls: ['./mastercontactus.css'],
})
export class MasterContactUs implements OnInit {
    alert;
    tempDisplayImage = [];
    tempImageID = [];
    url = null;
    Regis_ContactUs;
    table_ContactUs;
    status;
    tempBU;
    tempBUvalue = [];
    loading = true;

    SessionEmpID;
    Data_SessionStorage;

    DDL_Employee = [];
    DDL_Position = [];

    myControl = new UntypedFormControl();
    options = [];
    filteredOptions: Observable<string[]>;

    filteredOptionsEmployeeIDDD: Observable<string[]>;
    myControlEmployeeID = new UntypedFormControl();

    filteredOptionsPositionDD: Observable<string[]>;
    myControlPositionID = new UntypedFormControl();

    constructor(public authserviceToken: AuthenService, public mserve: Masterserve, public appSettingService: AppSettingService) { }
    configdata = this.appSettingService.read();
    BASE_URL = this.configdata.api_url;
    path = this.BASE_URL + '/app/backend/Resources/FileUpload';

    ngOnInit(): void {
        $('.modal').on("hidden.bs.modal", function (e) {
            if ($('.modal:visible').length) {
                $('body').addClass('modal-open');
            }
        });

        this.Data_SessionStorage = JSON.parse(sessionStorage.datainfo);
        //console.log('Data_SessionStorage => ', this.Data_SessionStorage);
        this.SessionEmpID = this.Data_SessionStorage[0].employeE_ID;

        this.Regis_ContactUs = {
            ContactID: '',
            ContactImage: '',
            ContactName: '',
            ContactPosition: '',
            ContactEmail: '',
            ContactTel: '',
            ContactFocal: '',
            ContactStatus: true,
            FileImage: [],
            ContactPriority: false
        }

        this.func_GetPositionDDL();
    }

    func_GetPositionDDL() {
        const _s = this;
        let tempdata = []
        _s.mserve.Master_DDL({ Module: "EmployeeDD" }).then((response) => {
            //console.log("Data EmpDD :> ", response.data.body);
            _s.DDL_Employee = response.data.body;
            response.data.body.forEach(function (ItemVal, ItemIndex) {
                let value = (ItemVal.text5 !== null) ? ItemVal.text5 : ''
                if (tempdata.indexOf(value) !== -1) {
                    return true
                }
                else {
                    tempdata.push(value)
                }
            });
            tempdata.forEach(function (ItemVal, ItemIndex) {
                _s.DDL_Position.push({ text5: ItemVal })
            });
            //console.log('_s.DDL_Position', _s.DDL_Position);
            this.filteredOptionsEmployeeIDDD = this.myControlEmployeeID.valueChanges.pipe(
                startWith(''),
                map(value => this._filterEmp(value))
            );

            this.filteredOptionsPositionDD = this.myControlPositionID.valueChanges.pipe(
                startWith(''),
                map(value => this._filterPosition(value))
            );

            this.func_GetDDL_BusinessName();
        })
    }

    func_GetDDL_BusinessName() {
        let formData = {
            Module: 'DeptByBuDD'
        }
        this.mserve.Master_DDL(formData).then((response) => {
            //console.log("DeptByBuDD :", response.data.body)
            if (response.data.status) {
                this.options = response.data.body
                this.filteredOptions = this.myControl.valueChanges.pipe(
                    startWith(''),
                    map(value => this._filter(value))
                );
                this.func_GETMASTER_ContactUs();
            } else {
                //console.log('false', response)
            }
        })
    }

    func_GETMASTER_ContactUs() {
        let formData = {
            SessionEmpID: this.SessionEmpID,
            Module: "getlist",
            body: {
                ContactName: "TM001",
                Position: "1"
            }
        }
        this.mserve.Get_ContactUs(formData).then((response) => {
            //console.log("ContactUs Data :", response.data.body)
            if (response.data.status) {
                this.table_ContactUs = response.data.body;
                this.convertvalue();
                for (let item_table_ContactUs of this.table_ContactUs) {
                    item_table_ContactUs.img = '../../../assets/logo/photo-1103595_1280.png';
                }
                this.loading = false;
            } else {
                //console.log('false', response)
            }
        }).catch((e) => {
            this.authserviceToken.CheckTokenExpire(e.response.status)
        });
    }

    func_savebtn(status) {
        const _s = this;
        if (this.Regis_ContactUs.ContactName === '') {
            this.alert = "Please fill Name"
            $('#modalAlertdanger').modal('show');
        } else {
            this.loading = true;
            let formData = this.setFormData(status)

            this.callServiceGetContactUs(formData);
        }
    }

    convertvalue() {
        for (var i = 0; i < this.table_ContactUs.length; i++) {
            if (this.table_ContactUs[i].arrGroupMap !== null) {
                for (var ii = 0; ii < this.table_ContactUs[i].arrGroupMap.length; ii++) {
                    for (var x = 0; x < this.options.length; x++) {
                        if (this.table_ContactUs[i].arrGroupMap[ii] === this.options[x].value) {
                            this.table_ContactUs[i].arrGroupMap[ii] = this.options[x].text
                        }
                    }
                }
            }
        }
    }

    private callServiceGetContactUs(formData: { SessionEmpID: any; Module: any; body: { ContactID: any; ContactName: any; Position: any; Email: any; Phone: any; PicPath: string; GroupMap: any[]; DelFlag: string; FirstRow: string; }; }) {
        this.mserve.Get_ContactUs(formData).then((response) => {
            if (response.data.status) {
                let formDataImg = {
                    AttachFileID: "",
                    Form: "2",
                    SeqNo: "1",
                    ReqId: response.data.reqId,
                    DelFlag: ""
                };
                let formDataIMG = new FormData();
                let arListimg = this.Regis_ContactUs.FileImage;
                if (this.Regis_ContactUs.FileImage.length !== 0) {
                    this.func_DelImage(this.tempImageID);
                    formDataIMG.append('formData', JSON.stringify(formDataImg));
                    for (let item_arListimg of arListimg) {
                        formDataIMG.append('files', item_arListimg, item_arListimg.name);
                    }
                    this.mserve.UploadFileMultiple(formDataIMG).then((response) => {
                        if (response.data.status) {
                            this.func_GETMASTER_ContactUs();
                        } else {
                            //console.log('false', response);
                        }
                    }).catch((e) => {
                        this.authserviceToken.CheckTokenExpire(e.response.status);
                    });
                }

                $('#modaladdcontactus').modal('hide');
                this.func_GETMASTER_ContactUs();
                this.loading = false;
            } else {
                //console.log('false', response);
                alert(response.data.errorMessage);
            }
        }).catch((e) => {
            this.authserviceToken.CheckTokenExpire(e.response.status);
        });
    }

    private setFormData(status: any) {
        return {
            SessionEmpID: this.SessionEmpID,
            Module: status,
            body: {
                ContactID: this.Regis_ContactUs.ContactID,
                ContactName: this.Regis_ContactUs.ContactName,
                Position: this.Regis_ContactUs.ContactPosition,
                Email: this.Regis_ContactUs.ContactEmail,
                Phone: this.Regis_ContactUs.ContactTel,
                //PicPath: '',
                PicPath: this.Regis_ContactUs.path,
                GroupMap: this.tempBUvalue,
                DelFlag: (this.Regis_ContactUs.ContactStatus === true) ? '0' : '1',
                FirstRow: (this.Regis_ContactUs.ContactPriority === true) ? '0' : '1',
                Emp_Id : this.Regis_ContactUs.Emp_Id
            }

        };
    }


    func_edit(id) {
        this.tempBU = [];
        this.tempBUvalue = [];
        this.Regis_ContactUs.ContactFocal = '';
        this.tempDisplayImage = [];
        this.tempImageID = [];
        this.url = null;
        for (let item_ContactUs of this.table_ContactUs) {
            if (item_ContactUs.contactID === id) {
                if (item_ContactUs.itemAttSeq1.length !== 0) {
                    this.tempDisplayImage.push({
                        attachFileID: item_ContactUs.itemAttSeq1[0].attachFileID,
                        name: item_ContactUs.itemAttSeq1[0].fileName,
                        form: item_ContactUs.itemAttSeq1[0].form,
                        pathFile: item_ContactUs.itemAttSeq1[0].pathFile,
                        reqId: item_ContactUs.itemAttSeq1[0].reqId,
                        seqNo: item_ContactUs.itemAttSeq1[0].seqNo,
                        rootPath: item_ContactUs.itemAttSeq1[0].rootPath
                    });
                    Array.prototype.push.apply(this.tempImageID, this.tempDisplayImage);
                }

                if (this.tempDisplayImage.length !== 0) {
                    this.url = this.path + '/' + this.tempDisplayImage[0].pathFile
                }

                this.setRegisterContractUS(id, item_ContactUs);

                if (item_ContactUs.arrGroupMap !== null) {
                    Array.prototype.push.apply(this.tempBU, item_ContactUs.arrGroupMap);
                    for (var x=0;x<this.tempBU.length;x++) {
                        for (var xx=0; xx<this.options.length; xx++) {
                            if ( this.tempBU[x] === this.options[xx].text ) {
                                this.tempBU[x] = ({ text:this.options[xx].text, value:this.options[xx].value })
                                this.tempBUvalue.push(this.options[xx].value )
                            }
                        }
                    }
                }
                break;
            }
        }

        this.status = 'Edit'
        $('#modaladdcontactus').modal('show');
    }

    private setRegisterContractUS(id: any, item_ContactUs: any) {
        this.Regis_ContactUs.ContactID = id;
        this.Regis_ContactUs.ContactName = item_ContactUs.contactName;
        this.Regis_ContactUs.ContactPosition = item_ContactUs.position;
        this.Regis_ContactUs.ContactStatus = (item_ContactUs.delFlag === '0') ? true : false;
        this.Regis_ContactUs.ContactPriority = (item_ContactUs.firstRow === '0') ? true : false;
        this.Regis_ContactUs.ContactEmail = item_ContactUs.email;
        this.Regis_ContactUs.ContactTel = item_ContactUs.phone;
        this.Regis_ContactUs.Emp_Id = item_ContactUs.emp_Id;
    }

    onSelectFile(event) {
        this.Regis_ContactUs.FileImage = [];
        this.tempDisplayImage = [];
        if (event.target.files && event.target.files[0]) {
            this.Regis_ContactUs.ContactImage = event.target.files[0].name
            this.Regis_ContactUs.FileImage.push(event.target.files[0])
            this.tempDisplayImage.push(event.target.files[0])
            let reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = (event) => {
                this.url = event.target.result;
            }
        }
        //console.log("FileImage ", this.Regis_ContactUs.FileImage)
    }

    AddFocal() {
        let tempvalueBU = '';
        if (!this.Regis_ContactUs.ContactFocal.replace(/\s/g, '').length) {
            alert('Please fill Business Unit')
        }
        else if (this.Regis_ContactUs.ContactFocal === '') {
            alert('Please fill Business Unit')
        } else {
            for (let item_options of this.options) {
                if (this.Regis_ContactUs.ContactFocal === item_options.text) {
                    tempvalueBU = item_options.value;
                    break;
                }
            }
            this.tempBU.push({ text: this.Regis_ContactUs.ContactFocal, value: tempvalueBU })
            this.tempBUvalue.push(tempvalueBU)
            this.Regis_ContactUs.ContactFocal = ''
            //console.log('Data in tempBU : ', this.tempBU);
        }
    }

    RemoveFocal(value) {
        for (let i = 0; i < this.tempBU.length; i++) {
            if (this.tempBU[i].text === value) {
                this.tempBU.splice(i, 1);
                this.tempBUvalue.splice(i, 1);
            }
        }
    }

    func_addContact() {
        this.tempBU = [];
        this.status = 'Add';
        this.url = null;
        this.Regis_ContactUs.ContactID = '';
        this.Regis_ContactUs.ContactImage = '';
        this.Regis_ContactUs.ContactName = '';
        this.Regis_ContactUs.ContactPosition = '';
        this.Regis_ContactUs.ContactEmail = '';
        this.Regis_ContactUs.ContactTel = '';
        this.Regis_ContactUs.ContactFocal = '';
        this.Regis_ContactUs.Emp_Id = '';
        this.Regis_ContactUs.ContactStatus = true;
        this.tempDisplayImage = [];
        this.tempImageID = [];
        $('#modaladdcontactus').modal('show');
    }

    func_closebtn() {
        $('#modaladdcontactus').modal('hide');
    }

    func_DelImage(item) {
        let formData = item[0]
        this.mserve.DeleteFile(formData).then((response) => {
        }).catch((e) => {
            this.authserviceToken.CheckTokenExpire(e.response.status)
        });
    }

    funcYes() {
        $('#modalAlertdanger').modal('hide');
    }

    AddEmpname() {
        for (let item_DDLEmployee of this.DDL_Employee) {
            if (this.Regis_ContactUs.ContactName === item_DDLEmployee.text) {
                this.Regis_ContactUs.ContactEmail = item_DDLEmployee.text3
                this.Regis_ContactUs.ContactPosition = item_DDLEmployee.text5
                this.Regis_ContactUs.Emp_Id = item_DDLEmployee.value
            }
        }
    }

    private _filter(value: string) {
        const filterValue = value?.toLowerCase();
        if (filterValue !== '') {
            return this.options.filter(option => option.text?.toLowerCase().includes(filterValue));
        } else {
            return this.options
        }
    }

    private _filterEmp(value: string) {
        const filterValue = value?.toLowerCase();
        if (filterValue !== '') {
            return this.DDL_Employee.filter(option => option.text?.toLowerCase().includes(filterValue));
        } else {
            return this.DDL_Employee
        }
    }

    private _filterPosition(value: string) {
        const filterValue = value?.toLowerCase();
        if (filterValue !== '') {
            return this.DDL_Position.filter(option => option.text5?.toLowerCase().includes(filterValue));
        } else {
            return this.DDL_Position
        }
    }
}