import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { StrategyModel } from '../../shared/model/StrategyModel';
import { StrategicRiskService } from '../../services/strategicrisk.service';
import { bindingStrategicTableModel } from '../../shared/model/StrategicRiskModel';
import * as moment from 'moment';
import { LowestScoreDirective } from '../../shared/directives/LowestScore.directive';
import { AssumptionModel } from '../../shared/model/AssumptionModel';
import { Masterserve } from 'src/app/services/master.service';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { StrategyTableComponent } from '../../shared/components/strategy-table/strategy-table.component';
import { AcceptTableComponent } from '../../shared/components/accept-table/accept-table.component';
import { StrategyDirective } from '../../shared/directives/Strategy.directive';
import { MasterDirective } from '../../shared/directives/Master.directive';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import DOMPurify from 'dompurify';
import { ActionLogModel } from '../../shared/model/ActionLogModel';
import { Action } from 'rxjs/internal/scheduler/Action';
declare const $;

export enum mode {
  Viewer = 1,
  Editor = 2,
}
@Component({
  selector: 'app-register-assessment',
  templateUrl: './register-assessment.component.html',
  styleUrls: ['./register-assessment.component.css'],
})

export class RegisterAssessmentComponent implements OnInit {

  @ViewChild(StrategyTableComponent) childStrategyTable: StrategyTableComponent;
  @ViewChild(AcceptTableComponent) childAccepTable: AcceptTableComponent;
  private routeSubscription: Subscription;
  tabSelect = 0;
  // arraylist: number[] = [1];
  empid;
  buCode;
  _mode = mode.Editor;
  confirmEvent: string;
  deleteIndex;
  isLoadingAll: boolean =  false;
  isActiveBuSelect: boolean = true;
  navigateURL;
  moduleConfirm;

  selectValues = [];

  acceptableValues = [];

  likelihoodValues = [];

  impactValues = [];

  assumptionsValue =[];

  dataStrategyList: StrategyModel[] = [];
  bindingStrategicTable: bindingStrategicTableModel[] = [];
  bindingStrategicExecutionTable: bindingStrategicTableModel[] = [];
  actionLogLst: ActionLogModel[] = [];

  mainForm = new FormGroup({
    riskId: new FormControl(null),
    riskName: new FormControl(null, Validators.required),
    riskStartDate: new FormControl(null, Validators.required),
    riskEndDate: new FormControl(null, Validators.required),
    noEndDate: new FormControl(false)
  })

  dataForm = new FormGroup({
    strategicDirection: new FormControl(null, Validators.required),
    strategicDirectionRationale: new FormControl(null, Validators.required),
    financial: new FormControl(null, Validators.required),
    financialRationale: new FormControl(null, Validators.required),
    zeroTolerance: new FormControl(null, Validators.required),
    zeroToleranceRationale: new FormControl(null, Validators.required),
    lowestScore1: new FormControl(null, Validators.required),
    valueProfit: new FormControl(null, Validators.required),
    valueProfitRationale: new FormControl(null, Validators.required),
    timingCashInfow: new FormControl(null, Validators.required),
    timingCashInfowRationale: new FormControl(null, Validators.required),
    growthSustainability: new FormControl(null, Validators.required),
    growthSustainabilityRationale: new FormControl(null, Validators.required),
    lowestScore2: new FormControl(null, Validators.required),
  });

  dataFormTab2 = new FormGroup({
    capability: new FormControl(null, Validators.required),
    capabilityRationale: new FormControl(null, Validators.required),
    resources: new FormControl(null, Validators.required),
    resourcesRationale: new FormControl(null, Validators.required),
    lowestScore1: new FormControl(null, Validators.required),
    jointInvestment: new FormControl(null, Validators.required),
    jointInvestmentRationale: new FormControl(null, Validators.required),
    quickExit: new FormControl(null, Validators.required),
    quickExitRationale: new FormControl(null, Validators.required),
    lowestScore2: new FormControl(null, Validators.required),
  });

  dataFormTab3: FormGroup;
  dataFormTab4: FormGroup;
  alertflow = '';
  titleAlert = '';
  link: any;
  Data_SessionStorage: any;
  getDataBU;
  riskId='';

  constructor(public dialog: MatDialog, 
    private fb: FormBuilder, 
    public strategicService: StrategicRiskService, 
    public lowestScore: LowestScoreDirective,
    public strategyDirective: StrategyDirective,
    public masterDirective:  MasterDirective,
    public mserve: Masterserve,
    private router: Router,
    private routerParam: ActivatedRoute) {
    (this.dataFormTab3 = this.fb.group({
      dataRisk: this.fb.array([this.createRiskFormGroup()]),
    }));
    (this.dataFormTab4 = this.fb.group({
      dataAssumpsion: this.fb.array([this.createAssumpsionFormGroup()]),
    }));
    
  }

  ngOnInit(): void {

    this.routeSubscription = this.router.events.pipe(filter(event => event instanceof NavigationStart)
    ).subscribe((event: NavigationStart) => {
      if((event.url != '/Register-Assessment'  && !event.url.includes('/Register-Assessment')) && (this.mainForm.touched || this.dataForm.touched || this.dataFormTab2.touched || this.dataFormTab3.touched || this.dataFormTab4.touched))
      {
        this.navigateURL = event.url;
        this.titleAlert = 'Alert !'
        this.alertflow = 'All enter or edit information will be lost. Are you sure you want to close ?';
        this.moduleConfirm = 'Navigate';
        $('#modalConfirm').modal('show');
        let cleanPath = DOMPurify.sanitize(window.location.href);
        if(this.masterDirective.isValidUrl(cleanPath))
        {
          this.router.navigate([cleanPath]);
        }
        else
        {
          console.error('Invalid URL provided:', cleanPath);
        }
        
       
      }
    });

    this.getMaster();
    
    this.Data_SessionStorage = JSON.parse(sessionStorage.datainfo);
    this.empid = this.Data_SessionStorage[0].employeE_ID;
    this.getDataBU = sessionStorage.getItem('buCode');
    this.riskId = this.routerParam.snapshot.paramMap.get('Id');
    if(this.riskId)
    {
      this.isActiveBuSelect = false;
      this.getData();
      this.tabSelect = 1;
    }
  }

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  setBuCode(event) {
    this.buCode = event; 
  }

  getMaster()
  {
    let formdata = {
      "Module": 'getlist',
    }
    this.mserve.getAssumptionCategory(formdata).then((response) => {
      if(response.status)
      {
        this.assumptionsValue = response.data.body
        this.assumptionsValue = this.assumptionsValue.filter(f => f.active_Flag)
      }
    
    });
    
    this.selectValues = this.masterDirective.getStrategicValues();

    this.acceptableValues = this.masterDirective.getAcceptableValues();

    this.likelihoodValues = this.masterDirective.getLikelihoodValues();

    this.impactValues = this.masterDirective.getImpactValues();              
  }

  getData(){
    let formdata = {
      "SessionEmpID": '11',
      "body": {
        riskId: this.riskId,
      }
    }
    this.isLoadingAll = true;
    this.strategicService.getRegisterAssessment(formdata).then((response) => {
      if(response.status)
      {
        this.mainForm.patchValue(response.data.body);
        this.dataForm.patchValue(response.data.body);
        this.dataFormTab2.patchValue(response.data.body);
        this.buCode = response.data.body.riskBusinessUnit;
        
        if(response.data.body.strategyList.length > 0)
        {  
          let dataTab3 = this.dataFormTab3.get('dataRisk') as FormArray;
          dataTab3.removeAt(0);
          this.dataStrategyList = [];
          //console.log("strategyList", response.data.body.strategyList)
          for(let i = 0; i < response.data.body.strategyList.length; i++)
          {
            dataTab3.push(this.createRiskFormGroup(response.data.body.strategyList[i]));
            this.dataStrategyList.push(response.data.body.strategyList[i]);
            this.dataStrategyList[i].index = i;
          }

          if(this.childStrategyTable)
          {
            this.childStrategyTable.datas = this.dataStrategyList;
          }
        }

        if(response.data.body.assumptionList.length > 0)
        {  
          let dataTab4 = this.dataFormTab4.get('dataAssumpsion') as FormArray;
          dataTab4.removeAt(0);
          response.data.body.assumptionList.forEach((element) => {
          if(!this.assumptionsValue.find(w => w.assumptionCategory_ID == element.categoryId))
          {
            element.categoryId = null;
          }
          dataTab4.push(this.createAssumpsionFormGroup(element));
          });
        }

        if(this.mainForm.controls.noEndDate.getRawValue())
        {
          this.mainForm.controls.riskEndDate.setValue(null);
          this.mainForm.controls.riskEndDate.disable();
        }

        this.dataFormTab4.controls['dataAssumpsion'].patchValue(response.data.body.assumptionList);
        this.onSelctChange();
        this.onSelctChangeTab2();
        this.checkRole(response.data.body.riskRegisterBy);
        this.isLoadingAll = false;
      }
      else
      {
        this.isLoadingAll = false;
        //console.log("Get Fail")
      }
    });

  }

  checkRole(riskRegisterBy){
    let role = sessionStorage.getItem('buRole');
    if(role && role.toLocaleLowerCase().includes("staff") && riskRegisterBy != this.empid)
    {
      this._mode = mode.Viewer;
      this.mainForm.disable();
      this.dataForm.disable();
      this.dataFormTab2.disable();
      this.dataFormTab3.disable();
      this.dataFormTab4.disable();
    }
    else
    {
      this._mode = mode.Editor;
    }
  }

  
  get dataRisk() {
    return this.dataFormTab3.controls['dataRisk'] as FormArray;
  }
  get dataAssumpsion() {
    return this.dataFormTab4.controls['dataAssumpsion'] as FormArray;
  }

  private createRiskFormGroup(data: StrategyModel = null): FormGroup {
    if(data)
    {
      return new FormGroup({
        riskName: new FormControl(data.riskName, Validators.required),
        likelihoodInherent: new FormControl(data.likelihoodInherent, Validators.required),
        impactInherent: new FormControl(data.impactInherent, Validators.required),
        likelihoodIfMitigated: new FormControl(data.likelihoodIfMitigated, Validators.required),
        impactIfMitigated: new FormControl(data.impactIfMitigated, Validators.required),
        riskLevelInherent: new FormControl(data.riskLevelInherent, Validators.required),
        riskLevelIfMitigated: new FormControl(data.riskLevelIfMitigated, Validators.required),
        mitigatable: new FormControl(data.mitigatable, Validators.required),
        acceptable: new FormControl(data.acceptable, Validators.required),
      });
    }
    else
    {
      return new FormGroup({
        riskName: new FormControl(null, Validators.required),
        likelihoodInherent: new FormControl(null, Validators.required),
        impactInherent: new FormControl(null, Validators.required),
        likelihoodIfMitigated: new FormControl(null, Validators.required),
        impactIfMitigated: new FormControl(null, Validators.required),
        riskLevelInherent: new FormControl(null, Validators.required),
        riskLevelIfMitigated: new FormControl(null, Validators.required),
        mitigatable: new FormControl(null, Validators.required),
        acceptable: new FormControl(null, Validators.required),
      });
    }
  }

  private createAssumpsionFormGroup(data: AssumptionModel = null): FormGroup {
    if(data)
    {
      return new FormGroup({
        categoryId: new FormControl(data.categoryId, Validators.required),
        assumptionDescription: new FormControl(data.assumptionDescription, Validators.required),
        favorableStatus: new FormControl(data.favorableStatus, Validators.required),
        unfavorableStatus: new FormControl(data.unfavorableStatus, Validators.required),
        actionRed: new FormControl(data.actionRed, Validators.required),
      });
    }
    else
    {
      return new FormGroup({
        categoryId: new FormControl(null, Validators.required),
        assumptionDescription: new FormControl(null, Validators.required),
        favorableStatus: new FormControl(null, Validators.required),
        unfavorableStatus: new FormControl(null, Validators.required),
        actionRed: new FormControl(null, Validators.required),
      });
    }
  }

  public addRiskFormGroup() {
    const dataTab3 = this.dataFormTab3.get('dataRisk') as FormArray;
    dataTab3.push(this.createRiskFormGroup());
  }

  public removeOrClearRisk(i: number) {
    const dataTab3 = this.dataFormTab3.get('dataRisk') as FormArray;
    if (dataTab3.length > 1) {
      dataTab3.removeAt(i);
    } else {
      dataTab3.reset();
    }
  }

  public addAssumpsionFormGroup() {
    const dataTab4 = this.dataFormTab4.get('dataAssumpsion') as FormArray;
    dataTab4.push(this.createAssumpsionFormGroup());
  }

  public removeOrClearAssumpsion(i: number) {
    const dataTab4 = this.dataFormTab4.get('dataAssumpsion') as FormArray;
    if (dataTab4.length > 1) {
      dataTab4.removeAt(i);
    } else {
      dataTab4.reset();
    }
  }

  StrategicFit() {
    if(this.tabSelect == 1)
    {
      this.tabSelect = 0;
    }
    else
    {
      this.tabSelect = 1;
    }

  }

  StrategicExecutionPerspective() {
    if(this.tabSelect == 2)
    {
      this.tabSelect = 0;
    }
    else if ((this.dataForm.valid && this.checkMainFormValid()) || this._mode == mode.Viewer) {
      this.markAllFormUntouched();
      this.tabSelect = 2;
    } else {
      this.markAllFormTouched()
      this.alertflow = 'Please fill-in the information.';
      $('#modalAlert').modal('show');
    }
  }

  RiskfromStrategy() {
    if(this.tabSelect == 3)
    {
      this.tabSelect = 0;
    }
    else if((this.dataForm.valid && this.dataFormTab2.valid && this.checkMainFormValid()) || this._mode == mode.Viewer)
      {
        this.markAllFormUntouched();
        this.tabSelect = 3;
      }
      else
      {
        this.markAllFormTouched()
        this.alertflow = 'Please fill-in the information.';
        $('#modalAlert').modal('show');
      }
  }

  StrategicAssumption() {

    if(this.tabSelect == 4)
    {
      this.tabSelect = 0;
    }
    else if((this.dataForm.valid && this.dataFormTab2.valid && this.dataFormTab3.valid && this.checkMainFormValid()) || this._mode == mode.Viewer)
    {
      this.markAllFormUntouched();
      this.tabSelect = 4;
    }
    else
    {
      this.markAllFormTouched()
      this.alertflow = 'Please fill-in the information.';
      $('#modalAlert').modal('show');
    }
  }

  backMonitor()
  {
    this.router.navigate(['/Monitor-Review']);
  }

  AddTableTab4() {
    this.addAssumpsionFormGroup();
  }

  AddTableTab3() {
    this.addRiskFormGroup();
  }

  DeleteConfirm(index) {
    this.titleAlert = 'Alert !'

    this.alertflow = 'Do you want to delete this Item No.'+ (index+1)+ ' ?';

    let objData = null;
    let isNullObj = null;
    if(this.tabSelect == 3)
    {
      const dataTab3 = this.dataFormTab3.get('dataRisk') as FormArray;
      objData = dataTab3.controls[index].value;
    }
    else if(this.tabSelect == 4)
    {
      const dataTab4 = this.dataFormTab4.get('dataAssumpsion') as FormArray;
      objData = dataTab4.controls[index].value;
    }

    if(objData)
    {
      isNullObj = Object.values(objData).every(value => {
        if (value === null || value === undefined || value === '') {
          return true;
        }
        return false;
      });

      this.deleteIndex = index;

      if(isNullObj)
      {
        this.DeleteItemList();
      }
      else
      {
        this.moduleConfirm = 'Delete'
        $('#modalConfirm').modal('show');
      }
    }

  }

  confirmFunc()
  {
    if(this.moduleConfirm == 'Delete')
    {
      this.DeleteItemList();
    }
    else if(this.moduleConfirm == 'Navigate')
    {
      window.location.href = window.location.origin + this.navigateURL;
    }
  }

  DeleteItemList()
  {
    if(this.tabSelect == 3)
    {
      this.dataStrategyList.splice(this.deleteIndex, 1);
      this.removeOrClearRisk(this.deleteIndex);
      this.reIndex();
      this.childStrategyTable.datas = this.dataStrategyList;
      this.childAccepTable.datas = this.dataStrategyList;
    }
    else if(this.tabSelect == 4)
    {
      this.removeOrClearAssumpsion(this.deleteIndex);
    }
  }

  DeleteTab3(index)
  {
    this.dataStrategyList.splice(index, 1);
    this.removeOrClearRisk(index);
    this.reIndex();
    this.childStrategyTable.datas = this.dataStrategyList;
    this.childAccepTable.datas = this.dataStrategyList;
  }

  reIndex() {
    for (let i = 0; i < this.dataStrategyList.length; i++) {
      this.dataStrategyList[i].index = i;
    }
  }

  DeleteTableTab4(index) {
    this.removeOrClearAssumpsion(index);
  }

  // calLowestScore(value) {
  //   if (value == 2) {
  //     return 0.5;
  //   } else if (value == 3) {
  //     return 1;
  //   } else {
  //     return 0;
  //   }
  // }

  // calLowestScoreToValue(value) {
  //   if (value == 0.5) {
  //     return 2;
  //   } else if (value == 1) {
  //     return 3;
  //   } else {
  //     return 1;
  //   }
  // }

  // checkValueLowest() {
  //   if (this.tabSelect == 1) {
  //     if (
  //       this.dataForm.controls.lowestScore1.getRawValue() != null &&
  //       this.dataForm.controls.lowestScore2.getRawValue() != null
  //     ) {
  //       this.xPositionValueTab1 = this.calLowestScoreToValue(
  //         this.dataForm.controls.lowestScore1.getRawValue()
  //       );
  //       this.yPositionValueTab1 = this.calLowestScoreToValue(
  //         this.dataForm.controls.lowestScore2.getRawValue()
  //       );
  //     }
  //   } else {
  //     if (
  //       this.dataFormTab2.controls.lowestScore1.getRawValue() != null &&
  //       this.dataFormTab2.controls.lowestScore2.getRawValue() != null
  //     ) {
  //       this.xPositionValueTab2 = this.calLowestScoreToValue(
  //         this.dataFormTab2.controls.lowestScore1.getRawValue()
  //       );
  //       this.yPositionValueTab2 = this.calLowestScoreToValue(
  //         this.dataFormTab2.controls.lowestScore2.getRawValue()
  //       );
  //     }
  //   }
  // }

  onNoEndDateChange(event)
  {
    if(event.target.checked)
    {
      this.mainForm.controls.riskEndDate.setValue(null);
      this.mainForm.controls.riskEndDate.disable();
    }
    else
    {
      this.mainForm.controls.riskEndDate.enable();
    }
  }

  onSelctChange() {
    if (
      this.dataForm.controls.strategicDirection.getRawValue() != null &&
      this.dataForm.controls.financial.getRawValue() != null &&
      this.dataForm.controls.zeroTolerance.getRawValue() != null
    ) {
      let min = this.lowestScore.calLowestScore(
        this.dataForm.controls.strategicDirection.getRawValue(),
        this.dataForm.controls.financial.getRawValue(),
        this.dataForm.controls.zeroTolerance.getRawValue()
      );
      this.dataForm.controls.lowestScore1.setValue(this.lowestScore.calValueToLowestScore(min));
    }

    if (
      this.dataForm.controls.valueProfit.getRawValue() != null &&
      this.dataForm.controls.timingCashInfow.getRawValue() != null &&
      this.dataForm.controls.growthSustainability.getRawValue() != null
    ) {
      let min2 = this.lowestScore.calLowestScore(
        this.dataForm.controls.valueProfit.getRawValue(),
        this.dataForm.controls.timingCashInfow.getRawValue(),
        this.dataForm.controls.growthSustainability.getRawValue()
      )
      this.dataForm.controls.lowestScore2.setValue(this.lowestScore.calValueToLowestScore(min2));
    }

    if (
      this.dataForm.controls.lowestScore1.getRawValue() != null &&
      this.dataForm.controls.lowestScore2.getRawValue() != null
    ) {
      let value: bindingStrategicTableModel = {
        xPosition: this.lowestScore.calLowestScoreToValue(this.dataForm.controls.lowestScore1.getRawValue()),
        yPosition: this.lowestScore.calLowestScoreToValue(this.dataForm.controls.lowestScore2.getRawValue())
      }
      this.bindingStrategicTable = [];
      this.bindingStrategicTable.push(value);
    }
  }

  onSelctChangeTab2() {
    if (
      this.dataFormTab2.controls.capability.getRawValue() != null &&
      this.dataFormTab2.controls.resources.getRawValue() != null
    ) {
      let min = this.lowestScore.calLowestScore(
        this.dataFormTab2.controls.capability.getRawValue(),
        this.dataFormTab2.controls.resources.getRawValue()
      )
      this.dataFormTab2.controls.lowestScore1.setValue(this.lowestScore.calValueToLowestScore(min));
    }

    if (
      this.dataFormTab2.controls.jointInvestment.getRawValue() != null &&
      this.dataFormTab2.controls.quickExit.getRawValue() != null
    ) {
      let min2 = this.lowestScore.calLowestScore(
        this.dataFormTab2.controls.jointInvestment.getRawValue(),
        this.dataFormTab2.controls.quickExit.getRawValue()
      )
      this.dataFormTab2.controls.lowestScore2.setValue(
        this.lowestScore.calValueToLowestScore(min2)
      );
    }

    if (
      this.dataFormTab2.controls.lowestScore1.getRawValue() != null &&
      this.dataFormTab2.controls.lowestScore2.getRawValue() != null
    ) {
      let value: bindingStrategicTableModel = {
        xPosition: this.lowestScore.calLowestScoreToValue(this.dataFormTab2.controls.lowestScore1.getRawValue()),
        yPosition: this.lowestScore.calLowestScoreToValue(this.dataFormTab2.controls.lowestScore2.getRawValue())
      }
      this.bindingStrategicExecutionTable = [];
      this.bindingStrategicExecutionTable.push(value);
    }
  }

  onSelctChangeTab3(index) {
    let formData = this.dataFormTab3.get('dataRisk') as FormArray;
    let chkIndex = this.checkItemByIndex(index);
    if (chkIndex != null) {
      this.dataStrategyList[chkIndex].likelihoodInherent = formData
        .at(index)
        .get('likelihoodInherent')
        .getRawValue();
      this.dataStrategyList[chkIndex].impactInherent = formData
        .at(index)
        .get('impactInherent')
        .getRawValue();
    } else {
      let strategyData: StrategyModel = {
        index: index,
        strategyId: null,
        likelihoodInherent: formData.at(index).get('likelihoodInherent').getRawValue(),
        likelihoodIfMitigated: null,
        impactInherent: formData.at(index).get('impactInherent').getRawValue(),
        impactIfMitigated: null,
        riskLevelInherent: null,
        riskLevelIfMitigated: null,
      };
      this.dataStrategyList?.push(strategyData);
      
    }

    if (formData.at(index).get('likelihoodInherent').getRawValue() && formData.at(index).get('impactInherent').getRawValue()) 
    {
      formData.at(index).get('riskLevelInherent').setValue(this.strategyDirective.calRiskLevel(formData.at(index).get('likelihoodInherent').getRawValue(),formData.at(index).get('impactInherent').getRawValue()));
      this.childStrategyTable.datas = this.dataStrategyList;
    }

    this.checkMitigatable(index);
  }

  onSelctChangeTab3_2(index) {
    let formData = this.dataFormTab3.get('dataRisk') as FormArray;
    let chkIndex = this.checkItemByIndex(index);
    if (chkIndex != null) {
      this.dataStrategyList[chkIndex].likelihoodIfMitigated = formData.at(index).get('likelihoodIfMitigated').getRawValue();
      this.dataStrategyList[chkIndex].impactIfMitigated = formData.at(index).get('impactIfMitigated').getRawValue();
    } else {
      let strategyData: StrategyModel = {
        index: index,
        strategyId: null,
        likelihoodInherent: null,
        likelihoodIfMitigated: formData.at(index).get('likelihoodIfMitigated').getRawValue(),
        impactInherent: null,
        impactIfMitigated: formData.at(index).get('impactIfMitigated').getRawValue(),
        riskLevelInherent: null,
        riskLevelIfMitigated: null,
      };
      this.dataStrategyList?.push(strategyData);
      
    }

    if (formData.at(index).get('likelihoodIfMitigated').getRawValue() && formData.at(index).get('impactIfMitigated').getRawValue()) 
    {
      formData.at(index).get('riskLevelIfMitigated').setValue(this.strategyDirective.calRiskLevel(formData.at(index).get('likelihoodIfMitigated').getRawValue(),formData.at(index).get('impactIfMitigated').getRawValue()));
      this.childStrategyTable.datas = this.dataStrategyList;
    }
    this.checkMitigatable(index);
  }

  
  checkMitigatable(index) {
    let formData = this.dataFormTab3.get('dataRisk') as FormArray;
    let riskLevelInherent = formData.at(index).get('riskLevelInherent').getRawValue();
    let riskLevelIfMitigated = formData.at(index).get('riskLevelIfMitigated').getRawValue();
    let chkIndex = this.checkItemByIndex(index);
    if (riskLevelInherent != null && riskLevelIfMitigated != null) 
    {
      if(riskLevelInherent == riskLevelIfMitigated)
      {
        formData.at(index).get('mitigatable').setValue(0)
        formData.at(index).get('acceptable').setValue(0)

        this.dataStrategyList[chkIndex].mitigatable = 0;
        this.dataStrategyList[chkIndex].acceptable = 0;
        this.childAccepTable.datas = this.dataStrategyList;
      }
      else
      {
        formData.at(index).get('mitigatable').setValue(1)
        formData.at(index).get('acceptable').setValue(1)

        
        this.dataStrategyList[chkIndex].mitigatable = 1;
        this.dataStrategyList[chkIndex].acceptable = 1;
        this.childAccepTable.datas = this.dataStrategyList;
      }
    }
    
    
    
  }

  onSelctAcceptableChange(index) {
    let formData = this.dataFormTab3.get('dataRisk') as FormArray;
    let chkIndex = this.checkItemByIndex(index);
    this.dataStrategyList[chkIndex].acceptable = formData.at(index).get('acceptable').getRawValue();
    this.childAccepTable.datas = this.dataStrategyList;
  }


  checkMainFormValid()
  {
    if(this.mainForm.invalid)
    {
      this.markAllFormTouched();
      return false;
    }
    else{
      return true;
    }
  }

  checkValidByTab()
  {
    if(this.tabSelect == 1 && this.dataForm.invalid)
    {
      this.dataForm.markAllAsTouched();
      return false;
    }
    else if(this.tabSelect == 2 && this.dataFormTab2.invalid)
    {
      this.dataFormTab2.markAllAsTouched();
      return false;
    }
    else if(this.tabSelect == 3 && this.dataFormTab3.invalid)
    {
      this.dataFormTab3.markAllAsTouched();
      return false;
    }
    else if(this.tabSelect == 4 && this.dataFormTab4.invalid)
    {
      this.dataFormTab4.markAllAsTouched();
      return false;
    }
    return true;
  }

  checkAllFormValid()
  {
    if(this.mainForm.invalid)
    {
      this.mainForm.markAllAsTouched();
      this.alertflow = 'Please fill-in the information.';
      $('#modalAlert').modal('show');
      return false;
    }
    else if(this.dataForm.invalid)
    {
      this.dataForm.markAllAsTouched();
      this.alertflow = 'Please fill-in the information.';
      $('#modalAlert').modal('show');
      return false;
    }
    else if(this.dataFormTab2.invalid)
    {
      this.dataFormTab2.markAllAsTouched();
      this.alertflow = 'Please fill-in the information.';
      $('#modalAlert').modal('show');
      return false;
    }
    else if(this.dataFormTab3.invalid)
    {
      this.dataFormTab3.markAllAsTouched();
      this.alertflow = 'Please fill-in the information.';
      $('#modalAlert').modal('show');
      return false;
    }
    else if(this.dataFormTab4.invalid)
    {
      this.dataFormTab4.markAllAsTouched();
      this.alertflow = 'Please fill-in the information.';
      $('#modalAlert').modal('show');
      return false;
    }
    else{
      return true;
    }
  }

  markAllFormUntouched(){
    this.mainForm.markAsUntouched();
    this.dataForm.markAsUntouched();
    this.dataFormTab2.markAsUntouched();
    this.dataFormTab3.markAsUntouched();
    this.dataFormTab4.markAsUntouched();
  }

  markAllFormTouched(){
    this.mainForm.markAllAsTouched();
    this.dataForm.markAllAsTouched();
    this.dataFormTab2.markAllAsTouched();
    this.dataFormTab3.markAllAsTouched();
    this.dataFormTab4.markAllAsTouched();
  }
  
  save(module, isConfirmRegister = false)
  {
    if(this.checkMainFormValid() && this.checkValidByTab())
    {
      this.isLoadingAll = true;
      let startDate = new Date(this.mainForm.controls.riskStartDate.getRawValue())
      this.actionLogLst = [];
      this.actionLogLst.push(this.buildActionLog(module));
      let formdata = {
        "SessionEmpID": this.empid,
        "body": {
          riskId: this.mainForm.controls.riskId.getRawValue(),
          riskName: this.mainForm.controls.riskName.getRawValue(),
          riskStartDate: moment(startDate).format("YYYY-MM-DD"),
          riskEndDate: null,
          riskBusinessUnit: this.buCode,
          riskRegisterBy: this.empid,
          riskModifiedBy: null,
          riskStatus: 'Draft',
          noEndDate: this.mainForm.controls.noEndDate.getRawValue(),
          
          strategicDirection: this.dataForm.controls.strategicDirection.getRawValue(),
          strategicDirectionRationale: this.dataForm.controls.strategicDirectionRationale.getRawValue(),
          financial: this.dataForm.controls.financial.getRawValue(),
          financialRationale: this.dataForm.controls.financialRationale.getRawValue(),
          zeroTolerance: this.dataForm.controls.zeroTolerance.getRawValue(),
          zeroToleranceRationale: this.dataForm.controls.zeroToleranceRationale.getRawValue(),
          valueProfit: this.dataForm.controls.valueProfit.getRawValue(),
          valueProfitRationale: this.dataForm.controls.valueProfitRationale.getRawValue(),
          timingCashInfow: this.dataForm.controls.timingCashInfow.getRawValue(),
          timingCashInfowRationale: this.dataForm.controls.timingCashInfowRationale.getRawValue(),
          growthSustainability: this.dataForm.controls.growthSustainability.getRawValue(),
          growthSustainabilityRationale: this.dataForm.controls.growthSustainabilityRationale.getRawValue(),
  
          capability: this.dataFormTab2.controls.capability.getRawValue(),
          capabilityRationale: this.dataFormTab2.controls.capabilityRationale.getRawValue(),
          resources: this.dataFormTab2.controls.resources.getRawValue(),
          resourcesRationale: this.dataFormTab2.controls.resourcesRationale.getRawValue(),
          jointInvestment: this.dataFormTab2.controls.jointInvestment.getRawValue(),
          jointInvestmentRationale: this.dataFormTab2.controls.jointInvestmentRationale.getRawValue(),
          quickExit: this.dataFormTab2.controls.quickExit.getRawValue(),
          quickExitRationale: this.dataFormTab2.controls.quickExitRationale.getRawValue(),
  
          strategyList: this.dataFormTab3.value.dataRisk,
          assumptionList: this.dataFormTab4.value.dataAssumpsion,
          strategicRiskLogList: this.actionLogLst
        }
      }

      this.saveCondition(formdata);
  
  
      this.strategicService.insertUpdateRegisterAssessment(formdata).then((response) =>{
        if(response.status)
        {
          this.isLoadingAll = false;
          this.mainForm.controls.riskId.setValue(response.data.body.riskId)
          
          if(isConfirmRegister)
          {
            this.isLoadingAll = false;
            this.router.navigate(['/Register-Assessment/Summary', this.mainForm.controls.riskId.getRawValue()]);
          }
          else
          {
            this.isLoadingAll = false;
            this.alertflow = 'Save Success'
            $('#modalSuccess').modal('show');
          }
        }
        else
        {
          this.isLoadingAll = false;
          //console.log("CreateStrategicFit Fail")
        }
      });

    }
    else
    {
      this.isLoadingAll = false;
      this.alertflow = 'Please fill-in the information.';
      $('#modalAlert').modal('show');
    }
  }

  buildActionLog(section){
    let logData = new ActionLogModel;
    //console.log("ID:", this.mainForm.controls.riskId.getRawValue());
    
    logData.riskId = Number(this.mainForm.controls.riskId.getRawValue()) ?? null;
    logData.createDate = (new Date()).toLocaleString('en-US', { timeZone: 'Asia/Bangkok' });
    
    logData.createBy = this.empid;
    if(logData.riskId)
    {
      if(section == 'Waiting for Register')
      {
        logData.action = section;
      }
      else
      {
        logData.section = section;
        logData.action = 'Update';
      }
    }
    else
    {
      logData.action = 'Create';
    }
    return logData
  }

  saveCondition(formdata) {

    if(this.mainForm.controls.riskEndDate.getRawValue())
    {
      let endDate = new Date(this.mainForm.controls.riskEndDate.getRawValue())
      formdata.body.riskEndDate = moment(endDate).format("YYYY-MM-DD");
    }

    if(this.mainForm.controls.riskId.getRawValue())
    {
      formdata.body.riskRegisterBy = null;
      formdata.body.riskModifiedBy = this.empid;
    }
    else
    {
      formdata.body.riskId = 0;
    }
    
    if(formdata.body.strategyList.length == 1 && formdata.body.strategyList[0].riskName == null )
    {
      formdata.body.strategyList = null;
    }

    if(formdata.body.assumptionList.length == 1 && formdata.body.assumptionList[0].assumptionDescription == null )
    {
      formdata.body.assumptionList = null;
    }

    if(!formdata.body.riskBusinessUnit)
    {
      formdata.body.riskBusinessUnit = sessionStorage.getItem("buCode")
    }
  }

  registerStrategy()
  {
    if(this.checkAllFormValid())
    {
      this.save('Waiting for Register', true);
    }

  }

  checkItemByIndex(index) {
    let itemByIndex = this.dataStrategyList?.findIndex((x) => x.index == index);
    if (itemByIndex != -1) {
      return itemByIndex;
    }
    return null;
  }

}
