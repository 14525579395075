import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShareService {

  constructor() { }
  private dataSubject = new BehaviorSubject<string>(''); // ให้มีค่าเริ่มต้นเป็นสตริงว่าง
  data$ = this.dataSubject.asObservable();

  sendData(data: string) {
    this.dataSubject.next(data);
  }
}
