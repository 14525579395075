import { Component, OnInit } from "@angular/core";
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Masterserve } from '../../../services/master.service';
import { AuthenService } from "src/app/services/authenservice.service";
declare let $;


@Component({
selector: 'app-MasterRiskCategory',
  templateUrl: './masterriskcategory.html',
  styleUrls: ['./masterriskcategory.css'],
  providers: [Masterserve, NgbModalConfig, NgbModal]
})
export class MasterRiskCategory implements OnInit {

  loading = false;
  tableriskCategory ;
  statusriskCategory ;
  quarterCategory ;

  tempAllColor = [];
  SessionEmpID;
  Data_SessionStorage;
  Module;
  RiskCategoryID;
  RiskCategoryCode;
  RiskCategoryName;
  OrderNum;
  DelFlag;
  RiskErmFlag;
  YearDD;
  QuarterDD;
  AddCo_QuarterDD;
  Quarter_Getlist;
  isChecked;
  currentYear;
  tempchkyear;
  RiskCatColor;
  riskCateGoryNameValid = true;
  riskCateGoryOrderByValid = true;
  model = {
    Year:null,
    Quarter:""
  }

  search = {
    Year :null,
    Quarter :''
  }

    constructor(config: NgbModalConfig, private modalService: NgbModal,public mserve: Masterserve,public authserviceToken: AuthenService) {}
    ngOnInit(): void {
      let _s = this;

      this.Data_SessionStorage = JSON.parse(sessionStorage.datainfo);
      //console.log('Data_SessionStorage => ',this.Data_SessionStorage);
      this.SessionEmpID = this.Data_SessionStorage[0].employeE_ID;

      let date = new Date();
      this.currentYear = date.getFullYear();
      this.search.Year = date.getFullYear();
      this.model.Year = date.getFullYear();

      _s.statusriskCategory  = [
        { title: "Active",},
        { title: "InActive",},
      ]

      this.loadDDL();
    }

    loadDDL () {
      const _s = this;

      _s.mserve.Master_DDL({ Module:"YearDD" }).then((response) => {
        _s.YearDD = response.data.body
        //console.log("YearDD : ",_s.YearDD)
      })

      let d = new Date();
      let currentMonth = d.getMonth() + 1;
      _s.mserve.Master_DDL({ Module:"QuarterDD",TextSearch1: this.search.Year }).then((response) => {
          _s.QuarterDD = response.data.body;
          _s.AddCo_QuarterDD = response.data.body;
          //console.log("QuarterDD : ",_s.QuarterDD)
          this.func_calQuarter(currentMonth);
      })
    }

    func_calQuarter (month) {
        
        this.search.Quarter = ''
        if (month >= 1 && month <= 3) {
            this.search.Quarter = this.QuarterDD[0].value;
            this.model.Quarter = this.QuarterDD[0].value;
        } else if (month >= 4 && month <= 6) {
            this.search.Quarter = this.QuarterDD[1].value;
            this.model.Quarter = this.QuarterDD[1].value;
        } else if (month >= 7 && month <= 9) {
            this.search.Quarter = this.QuarterDD[2].value;
            this.model.Quarter = this.QuarterDD[2].value;
        } else if (month >= 10 && month <= 12) {
            this.search.Quarter = this.QuarterDD[3].value;
            this.model.Quarter = this.QuarterDD[3].value;
        }
        this.func_Getlist();
    }

    func_Getlist () {
      let _s = this;
      this.loading = true;
      let datavalue = {
        SessionEmpID :this.SessionEmpID,
        Module :"getlist",
        body :{
          RiskCategoryID :"",
          QuarterID :this.search.Quarter,
          RiskCategoryCode :"",
          RiskCategoryName :"",
          DelFlag :"0"
        }
      }

      _s.mserve.GetRiskCategory(datavalue).then((response) => {
          if(response.data.status === true){
              //console.log('Data RiskCategory : ',response.data.body);
              _s.tableriskCategory  = response.data.body;
              _s.tempchkyear = this.search.Year;

              _s.tableriskCategory .forEach(function(value,index){
                _s.tempAllColor.push(value.riskCatColor)
              });
              _s.tempAllColor.push('#ffffff') 
              _s.tempAllColor.push('#000000') 
              //console.log('tempAllColor',_s.tempAllColor)
              this.loading = false;
          }else{
            //console.log('false',response)
            alert(response.data.errorMessage);
          }
      }).catch((e) => {
        this.authserviceToken.CheckTokenExpire(e.response.status)
        });
    }

    func_addriskCategory  (item,mode) {
      
      let _s = this;
      if(mode === 'Add'){
        _s.Module = 'insert'
        _s.model.Year = this.currentYear;
        _s.model.Quarter = this.model.Quarter;
        _s.RiskCategoryID = '';
        _s.RiskCategoryCode = '';
        _s.RiskCategoryName = '';
        _s.OrderNum = '';
        _s.DelFlag = true;
        _s.RiskErmFlag = false;
        _s.RiskCatColor =  _s.func_getColor(); 
      }else{
        _s.Module = 'update'
        _s.RiskCategoryID = item.riskCategoryID
        _s.model.Year =  item.quarterYear
        _s.model.Quarter = '';
        _s.SelectedYear(item.quarterID);
        _s.RiskCategoryCode = item.riskCategoryCode
        _s.RiskCategoryName = item.riskCategoryName
        _s.OrderNum = item.orderNum;
        _s.DelFlag = (item.delFlag === '0') ? true : false
        _s.isChecked = (item.delFlag === '0') ? true : false
        _s.RiskErmFlag = (item.ermFlag === '0') ? true : false
        _s.RiskCatColor = item.riskCatColor
      }
      
      $('#modaladdriskCategory').modal('show')
    }
 checkFormsValid(_s:any){
  this.riskCateGoryNameValid  = _s.RiskCategoryName ? true:false;
  this.riskCateGoryOrderByValid  = _s.OrderNum ? true:false;
 }
    func_savebtn() {
let _s = this;
this.checkFormsValid(_s);
      if( this.riskCateGoryNameValid  &&  this.OrderNum  ){
         
      let datavalue = {
        SessionEmpID:this.SessionEmpID,
        Module:_s.Module,
        body:{
          RiskCategoryID:_s.RiskCategoryID,
          QuarterID:_s.model.Quarter,
          RiskCategoryCode:_s.RiskCategoryCode,
          RiskCategoryName:_s.RiskCategoryName,
          OrderNum:_s.OrderNum,
          DelFlag:_s.DelFlag ? '0' : '1',
          ErmFlag:_s.RiskErmFlag ? '0' : '1',
          RiskCatColor: _s.RiskCatColor
        }
      }
      _s.mserve.GetRiskCategory(datavalue).then((response) => {
          if(response.data.status === true){
              //console.log("true",response.data.body);
              $('#modaladdriskCategory').modal('hide');
              _s.func_Getlist();
          }else{
            //console.log('false',response)
            alert(response.data.ErrorMessage);
          }
      }).catch((e) => {
        this.authserviceToken.CheckTokenExpire(e.response.status)
        });
      }
     
    }

    func_closebtn() {
      $('#modaladdriskCategory').modal('hide')
    }

    SelectedYear (value) {
      
      let _s = this;
      _s.mserve.Master_DDL({ Module:"QuarterDD" ,TextSearch1: _s.model.Year }).then((response) => {
          _s.AddCo_QuarterDD = response.data.body
          //console.log("AddCo_QuarterDD : ",_s.AddCo_QuarterDD)
          if (value !== '') {
            for (let item_AddCo_QuarterDD of _s.AddCo_QuarterDD){
                  if (value ===item_AddCo_QuarterDD.value) {
                      _s.model.Quarter =item_AddCo_QuarterDD.value
                  }
              }
          } else {
              _s.model.Quarter = '';
          }
      });
    }

    SearchSelectedYear () {
      
      let _s = this;
      _s.search.Quarter = '';
      _s.mserve.Master_DDL({ Module:"QuarterDD" ,TextSearch1: _s.search.Year }).then((response) => {
          _s.QuarterDD = response.data.body
          //console.log("QuarterDD : ",_s.QuarterDD)
      });
    }

    //coverity
    // func_getColor () {
      
    //   const _s = this;
    //   let color = '#';
    //   for (let x = 0; x < 6; x++) {
    //       color += Math.floor(Math.random() * 10);
    //   }
    //   if (_s.tempAllColor.indexOf(color) != -1) {
    //     this.func_getColor();
    //   } else {
    //     return color;
    //   }

    // }

    func_getColor(): string {
      const _s = this;
      let color = '#';
      const array = new Uint8Array(6); // 6 bytes for 6 hexadecimal values
      window.crypto.getRandomValues(array);
    
      for (let i = 0; i < array.length; i++) {
        // Convert the random byte to a hex value and append to the color string
        // Ensure it is two digits long for single digit hex values
        color += ('0' + array[i].toString(16)).slice(-2);
      }
    
      if (_s.tempAllColor.indexOf(color) !== -1) {
        return this.func_getColor(); // Recursively call func_getColor() if the color already exists
      } else {
        return color; // Unique color found, return it
      }
    }
}