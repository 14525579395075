<div class="container-fluid appform my-3" >

        <div class="row" >
            <div class="col-12 col-md-12 col-lg-12 col-xl-12 pb-0 mt-0 mb-0">
                <div class="card mt-3 ">
                    <div class="col-12 mb-4">
                        <div class="col-12 mb-4">
                            <div class="row ">
                                <div class="coordinator col-4 ml-1 mb-1 rounded" style="height: 50px;">
                                  <div class="col-0 pt-2 signaturetext">
                                    <em class="fas fa-file-signature"></em>&nbsp;&nbsp;Monitor & Review
                                  </div>
                                </div>
                              </div>
                              <div class="row ">
                                <div class=" col-12 ml-1 mb-1 rounded">
                                  <div class="col-12 pt-2 headertext">
                                    <em class=""></em>&nbsp;&nbsp;Assessment Review
                                  </div>
                                </div>
                              </div> 
                              <div class="row ">
                                <div class=" col-12 ml-1 mb-1 rounded">
                                  <div class="col-12 pt-2 headertext">
                                    &nbsp;&nbsp;<em class="fa fa-play iconplay"></em>&nbsp;&nbsp;Strategy : {{riskName}}
                                  </div>
                                </div>
                              </div>
                              <div class="row mt-4">
                                <span class="text">
                                  Execution Period
                                </span>
                            
                                <div class="ml-5" style="display: flex; margin-top: 15px">
                                    <input  class="form-control" style="background: white;"  [value]="riskStartDate" placeholder="Start Date" disabled>
                                </div>
                                <div [formGroup]="mainForm" class="ml-5"  style="display: flex; margin-top: 15px">
                                  <input matInput class="form-control enddatebox" style="background-color:white;" placeholder="End Date" [matDatepicker]="picker1" autocomplete="off" [readonly]="true"  [min]="mainForm.controls.riskStartDate.value" formControlName="riskEndDate"  [ngClass]="{'input-error': (this.mainForm.controls.riskEndDate.invalid && this.mainForm.controls.riskEndDate.touched) && this.mainForm.controls.noEndDate.value == false}">
                                  <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                                  <mat-datepicker #picker1></mat-datepicker>
                              </div>
                              <div>
                                <div  [formGroup]="mainForm" class="form-check ml-5" style="display: flex; margin-top: 18px">
                                    <input class="form-check-input" type="checkbox" value="noEndDate" (change)="onNoEndDateChange($event)" formControlName="noEndDate" id="noEndDateCheckBox">
                                    <label class="form-check-label mt-2" for="noEndDateCheckBox">
                                        No End Date
                                    </label>
                                  </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

<app-riskfrom-strategy [data]="data" [isCloseButtonEdit]="closeButtonEdit" (addLog)="childAddLogEvent($event)"></app-riskfrom-strategy>
<app-strategic-assumption [data]="data" [isCloseButtonEdit]="closeButtonEdit" (addLog)="childAddLogEvent($event)"></app-strategic-assumption>
<app-confirm-revise (actionEvent)="actionEvents($event)" [DataAlert]="dataAlert" [isCloseButtonEdit]="closeButtonEdit"></app-confirm-revise>

<div class="row">
  <div class="col-12 col-md-12 col-lg-12 col-xl-12 " style="padding:30px">
      <app-action-log [riskId]="id" [isShowStrategyName] = "false"></app-action-log>
  </div>
</div>

