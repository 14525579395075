import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { StrategyModel } from '../../model/StrategyModel';
import { Masterserve } from 'src/app/services/master.service';
import { MasterDirective } from '../../directives/Master.directive';
import { StrategyDirective } from '../../directives/Strategy.directive';

@Component({
  selector: 'app-strategic-assumption',
  templateUrl: './strategic-assumption.component.html',
  styleUrls: ['./strategic-assumption.component.css'],
})
export class StrategicAssumptionComponent implements OnInit {
  @Input() set data(value: []) {
    if (value) {
      this.setData(value);
    }
  }
  @Input() showHeading: boolean = false;

  @Input() isCloseButtonEdit: boolean = false;

  @Output() addLog = new EventEmitter<string>();

  riskStrategyArrayTemp: any;
  riskAssumptionArrayTemp: any;
  dataStrategyList: StrategyModel[] = [];
  form1: FormGroup;
  form2: FormGroup;
  positionSetValue = [];
  assumptionsValue = [];
  assumptionsValueTemp = [];
  acceptableValues = [];
  likelihoodValues = [];
  impactValues = [];
  riskLevelTable = [];
  titleButton = 'Edit';
  titleButton2 = 'Edit';
  constructor(
    private _fb: FormBuilder,
    public mserve: Masterserve,
    public masterDirective: MasterDirective,
    public strategyDirective: StrategyDirective
  ) {
    this.form1 = this._fb.group({
      riskStrategy: this._fb.array([], [Validators.required]),
    });
    this.form2 = this._fb.group({
      riskAssumption: this._fb.array([], [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.acceptableValues = this.masterDirective.getAcceptableValues();
    this.likelihoodValues = this.masterDirective.getLikelihoodValues();
    this.impactValues = this.masterDirective.getImpactValues();
    this.getMaster();
  }
  getMaster() {
    let formdata = {
      Module: 'getlist',
    };
    this.mserve.getAssumptionCategory(formdata).then((response) => {
      if (response.status) {
        this.assumptionsValueTemp = response.data.body;
        this.assumptionsValue = this.assumptionsValueTemp.filter(
          (f) => f.active_Flag == true
        );
      }
    });
  }
  private createRiskStrategyFormGroup(): FormGroup {
    return new FormGroup({
      riskName: new FormControl(undefined, [Validators.required]),
      likelihoodInherent: new FormControl(undefined, [Validators.required]),
      likelihoodIfMitigated: new FormControl(undefined, [Validators.required]),
      impactInherent: new FormControl(undefined, [Validators.required]),
      impactIfMitigated: new FormControl(undefined, [Validators.required]),
      riskLevelInherent: new FormControl(null),
      riskLevelIfMitigated: new FormControl(null),
      mitigatable: new FormControl(undefined, [Validators.required]),
      acceptable: new FormControl(undefined, [Validators.required]),
      check: new FormControl(true, [Validators.required]),
    });
  }
  public addRiskStrategyFormGroup() {
    if (this.form1.valid || this.form1.value.riskStrategy.length == 0) {
      const rowform1 = this.form1.get('riskStrategy') as FormArray;
      rowform1.push(this.createRiskStrategyFormGroup());
      let indexof = this.riskStrategyArray.value.findIndex(
        (obj) => obj.riskName == undefined
      );
      this.fun_Riskfromtrategy(indexof, 'new');
    }
  }
  public removeRiskStrategy(i: number) {
    this.addLog.emit("Risk from Strategy")
    const rowform1 = this.form1.get('riskStrategy') as FormArray;
    rowform1.removeAt(i);
    if (i !== -1) {
      this.riskStrategyArrayTemp.splice(i, 1);
    }
    this.Riskfromtrategy();
  }
  private createRiskAssumptionFormGroup(): FormGroup {
    return new FormGroup({
      assumptionId: new FormControl(undefined),
      assumptionTitle: new FormControl(undefined),
      categoryId: new FormControl(undefined, [Validators.required]),
      assumptionDescription: new FormControl(undefined, [Validators.required]),
      favorableStatus: new FormControl(undefined, [Validators.required]),
      unfavorableStatus: new FormControl(undefined, [Validators.required]),
      actionRed: new FormControl(undefined, [Validators.required]),
      check: new FormControl(true, [Validators.required]),
    });
  }
  public addRiskAssumptionFormGroup() {
    if (this.form2.valid || this.form2.value.riskAssumption.length == 0) {
      const rowform2 = this.form2.get('riskAssumption') as FormArray;
      rowform2.push(this.createRiskAssumptionFormGroup());
      let indexof = this.riskAssumptionArray.value.length-1;
      this.fun_FormAssumptions(indexof, 'new');
    }
  }
  public removeRiskAssumption(i: number) {
    this.addLog.emit("Strategic Assumptions")
    const rowform2 = this.form2.get('riskAssumption') as FormArray;
    rowform2.removeAt(i);
    if (i !== -1) {
      this.riskAssumptionArrayTemp.splice(i, 1);
    }
  }

  public getData(data: any) {
    data.riskStrategy = this.riskStrategyArray.value;
    data.riskAssumption = this.riskAssumptionArray.value;
    return data;
  }
  public setData(data: any) {
    //console.log('setData : ', data);
    if (data.riskStrategy.length > 0) {
      data.riskStrategy.forEach((value) => {
        value.check = false;
        const rowform1 = this.form1.get('riskStrategy') as FormArray;
        rowform1.push(
          new FormGroup({
            riskName: new FormControl(value.riskName, [Validators.required]),
            likelihoodInherent: new FormControl(value.likelihoodInherent, [
              Validators.required,
            ]),
            likelihoodIfMitigated: new FormControl(
              value.likelihoodIfMitigated,
              [Validators.required]
            ),
            impactInherent: new FormControl(value.impactInherent, [
              Validators.required,
            ]),
            impactIfMitigated: new FormControl(value.impactIfMitigated, [
              Validators.required,
            ]),
            riskLevelInherent: new FormControl(value.riskLevelInherent, [
              Validators.required,
            ]),
            riskLevelIfMitigated: new FormControl(value.riskLevelIfMitigated, [
              Validators.required,
            ]),
            mitigatable: new FormControl(value.mitigatable, [
              Validators.required,
            ]),
            acceptable: new FormControl(value.acceptable, [
              Validators.required,
            ]),
            check: new FormControl(false, [Validators.required]),
          })
        );
      });
    }
    if (data.riskAssumption.length > 0) {
      data.riskAssumption.forEach((value) => {
        value.check = false;
        const rowform2 = this.form2.get('riskAssumption') as FormArray;
        rowform2.push(
          new FormGroup({
            assumptionId: new FormControl(value.assumptionId),
            assumptionTitle: new FormControl(value.assumptionTitle),
            categoryId: new FormControl(value.categoryId, [
              Validators.required,
            ]),
            assumptionDescription: new FormControl(
              value.assumptionDescription,
              [Validators.required]
            ),
            favorableStatus: new FormControl(value.favorableStatus, [
              Validators.required,
            ]),
            unfavorableStatus: new FormControl(value.unfavorableStatus, [
              Validators.required,
            ]),
            actionRed: new FormControl(value.actionRed, [Validators.required]),
            check: new FormControl(false, [Validators.required]),
          })
        );
      });
    }
    this.riskStrategyArrayTemp = this.riskStrategyArray.value;
    this.riskAssumptionArrayTemp = this.riskAssumptionArray.value;
    this.Riskfromtrategy();
  }
  Riskfromtrategy() {
    this.dataStrategyList = [];
    this.positionSetValue = [];
    this.riskStrategyArray.value.forEach((item, index: number) => {
      item.riskLevelInherent = this.strategyDirective
        .calRiskLevel(item.likelihoodInherent, item.impactInherent)
        .toString();
      item.riskLevelIfMitigated = this.strategyDirective
        .calRiskLevel(item.likelihoodIfMitigated, item.impactIfMitigated)
        .toString();
      let strategyData: StrategyModel = {
        index: index,
        strategyId: item.strategyId,
        likelihoodInherent: item.likelihoodInherent.toString(),
        likelihoodIfMitigated: item.likelihoodIfMitigated.toString(),
        impactInherent: item.impactInherent.toString(),
        impactIfMitigated: item.impactIfMitigated.toString(),
        riskLevelInherent: item.riskLevelInherent.toString(),
        riskLevelIfMitigated: item.riskLevelIfMitigated.toString(),
        mitigatable: Number(item.mitigatable),
        acceptable: Number(item.acceptable),
      };
      this.dataStrategyList?.push(strategyData);
    });
  }

  fun_Riskfromtrategy(id, action) {
    if (action == 'new') {
      this.titleButton = 'Edit';
      this.riskStrategyArray.value.forEach((item, index: number) => {
        if (index == id) {
          item.check = true;
        } else {
          item.check = false;
        }
      });
      this.riskStrategyArrayTemp = this.riskStrategyArray.value;
    }
    if (action == 'edit') {
      this.titleButton = 'Save';
      if (this.form1.valid) {
        this.riskStrategyArray.value.forEach((item, index: number) => {
          if (index == id) {
            item.check = true;
          } else {
            item.check = false;
          }
        });
      }
      this.riskStrategyArrayTemp = this.riskStrategyArray.value;
    }
    if (action == 'add') {
      this.titleButton = 'Edit';
      if (this.form1.valid) {
        this.riskStrategyArray.value.forEach((item, index: number) => {
          item.check = false;
        });
        this.Riskfromtrategy();
        this.riskStrategyArrayTemp = this.riskStrategyArray.value;
        this.addLog.emit("Risk from Strategy")
      }
      this.form1.markAllAsTouched();
    }
  }

  fun_FormAssumptions(id, action) {
    if (action == 'new') {
      this.titleButton2 = 'Edit';
      this.riskAssumptionArray.value.forEach((item, index: number) => {
        if (index == id) {
          item.check = true;
        } else {
          item.check = false;
        }
      });
      this.riskAssumptionArrayTemp = this.riskAssumptionArray.value;
    }
    if (action == 'edit') {
      this.titleButton2 = 'Save';
      if (this.form2.valid) {
        this.riskAssumptionArray.value.forEach((item, index: number) => {
          if (index == id) {
            item.check = true;
          } else {
            item.check = false;
          }
        });
      }
      this.riskAssumptionArrayTemp = this.riskAssumptionArray.value;
    }
    if (action == 'add') {
      this.titleButton2 = 'Edit';
      if (this.form2.valid) {
        this.riskAssumptionArray.value.forEach((item, index: number) => {
          item.check = false;
        });
        this.riskAssumptionArrayTemp = this.riskAssumptionArray.value;
        this.addLog.emit("Strategic Assumptions")
      }
      this.form2.markAllAsTouched();
    }
  }
  checkItemByIndex(index) {
    let itemByIndex = this.dataStrategyList?.findIndex((x) => x.index == index);
    if (itemByIndex != -1) {
      return itemByIndex;
    }
    return null;
  }

  onChangeData(index) {
    var formData = this.form1.get('riskStrategy') as FormArray;
    let chkIndex = this.checkItemByIndex(index);
    if (chkIndex != null) {
      this.riskStrategyArrayTemp[chkIndex].likelihoodIfMitigated = formData
        .at(index)
        .get('likelihoodIfMitigated')
        .getRawValue();
      this.riskStrategyArrayTemp[chkIndex].impactIfMitigated = formData
        .at(index)
        .get('impactIfMitigated')
        .getRawValue();
      this.riskStrategyArrayTemp[chkIndex].likelihoodInherent = formData
        .at(index)
        .get('likelihoodInherent')
        .getRawValue();
      this.riskStrategyArrayTemp[chkIndex].impactInherent = formData
        .at(index)
        .get('impactInherent')
        .getRawValue();
    } else {
      let strategyData: StrategyModel = {
        index: index,
        strategyId: null,
        likelihoodInherent: formData
          .at(index)
          .get('likelihoodInherent')
          .getRawValue(),
        likelihoodIfMitigated: formData
          .at(index)
          .get('likelihoodIfMitigated')
          .getRawValue(),
        impactInherent: formData.at(index).get('impactInherent').getRawValue(),
        impactIfMitigated: formData
          .at(index)
          .get('impactIfMitigated')
          .getRawValue(),
        riskLevelInherent: null,
        riskLevelIfMitigated: null,
      };
      this.dataStrategyList?.push(strategyData);
    }

    if (
      formData.at(index).get('likelihoodInherent').getRawValue() &&
      formData.at(index).get('impactInherent').getRawValue()
    ) {
      formData
        .at(index)
        .get('riskLevelInherent')
        .setValue(
          this.strategyDirective
            .calRiskLevel(
              formData.at(index).get('likelihoodInherent').getRawValue(),
              formData.at(index).get('impactInherent').getRawValue()
            )
            .toString()
        );

      this.riskStrategyArrayTemp[chkIndex].riskLevelInherent = formData
        .at(index)
        .get('riskLevelInherent')
        .getRawValue();
    }
    if (
      formData.at(index).get('likelihoodIfMitigated').getRawValue() &&
      formData.at(index).get('impactIfMitigated').getRawValue()
    ) {
      formData
        .at(index)
        .get('riskLevelIfMitigated')
        .setValue(
          this.strategyDirective
            .calRiskLevel(
              formData.at(index).get('likelihoodIfMitigated').getRawValue(),
              formData.at(index).get('impactIfMitigated').getRawValue()
            )
            .toString()
        );

      this.riskStrategyArrayTemp[chkIndex].riskLevelIfMitigated = formData
        .at(index)
        .get('riskLevelIfMitigated')
        .getRawValue();
    }
    let riskLevelInherent = formData
      .at(index)
      .get('riskLevelInherent')
      .getRawValue();
    let riskLevelIfMitigated = formData
      .at(index)
      .get('riskLevelIfMitigated')
      .getRawValue();
    if (riskLevelInherent != null && riskLevelIfMitigated != null) {
      if (riskLevelInherent == riskLevelIfMitigated) {
        formData.at(index).get('mitigatable').setValue(0);
        formData.at(index).get('acceptable').setValue(0);

        this.riskStrategyArrayTemp[chkIndex].mitigatable = 0;
        this.riskStrategyArrayTemp[chkIndex].acceptable = 0;
      } else {
        formData.at(index).get('mitigatable').setValue(1);
        formData.at(index).get('acceptable').setValue(1);

        this.riskStrategyArrayTemp[chkIndex].mitigatable = 1;
        this.riskStrategyArrayTemp[chkIndex].acceptable = 1;
      }
    }
  }
  public getform(): { status: boolean; message: string } {
    let item: { status: boolean; message: string } = {
      status: true,
      message: '',
    };
    this.riskStrategyArray.markAllAsTouched();
    this.riskAssumptionArray.markAllAsTouched();
    let checkriskStrategyArray = this.riskStrategyArrayTemp.some(
      (v) => v.check === true
    );
    let checkriskAssumptionArray = this.riskAssumptionArrayTemp.some(
      (v) => v.check === true
    );

    if(this.riskStrategyArrayTemp.length == 0)
    {
      item.status = false;
      item.message =
        'Please add Risk from Strategy';
    }
    else if(this.riskAssumptionArrayTemp == 0)
    {
      item.status = false;
      item.message =
        'Please add Strategic Assumptions';
    }
    else if (!this.riskStrategyArray.valid || checkriskStrategyArray) {
      item.status = false;
      item.message =
        'Please press the save button in Risk from Strategy every time after adding or editing an item ';
    } 
    else if (!this.riskAssumptionArray.valid || checkriskAssumptionArray) {
      item.status = false;
      item.message =
        'Please press the save button in Strategic Assumptions every time after adding or editing an item ';
    }
    return item;
  }
  auto_height(event) {
    event.target.style.height = '0px';
    event.target.style.height = event.target.scrollHeight + 25 + 'px';
  }
  filterByid(item: any[], itemValue): any[] {
    return item.filter((p) => p.value == itemValue);
  }
  filterAssumptions(item: any[]): any[] {
    return this.assumptionsValueTemp.filter(
      (p) => p.assumptionCategory_ID == item
    );
  }
  get riskStrategyArray(): FormArray {
    return this.form1.get('riskStrategy') as FormArray;
  }
  get riskAssumptionArray(): FormArray {
    return this.form2.get('riskAssumption') as FormArray;
  }
}
