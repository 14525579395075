<div class="container-fluid appform my-3">
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">

            <div class="col-12 col-md-12 col-lg-12 col-xl-12 pb-5 mt-5 mb-5">
                <div class="card col-12 mt-4 mx-0 p-3">

                    <div class="col-12 mb-3 rounded instruction">
                        <div class="col-12 pt-2">
                            <em class="fas fa-cog icon"></em>&nbsp;&nbsp;Instruction
                        </div>
                    </div>

                    <div class="col-12 px-0" style="text-align: right;">
                        <button type="button" class="btn mb-3" style="width: 200px;color:white;background:orange;" (click)="func_addinstruction({},'Add')">
                            <em class="fas fa-plus icon"></em>&nbsp;&nbsp;&nbsp;Add Instruction
                        </button>
                    </div>

                    <div class="col-12 px-0 table-responsive">
                        <table class="table-bordered" style="width:100%;">
                            <caption style="display: none;" >This is a custom table</caption>
                            <thead style="text-align: center;font-size: 20px;">
                            <tr style="background-color:#033153;color:white;text-align: center;font-size:18px;">
                                <th style="width: 50px;vertical-align: middle;">No</th>
                                <th style="width: 100px;vertical-align: middle;">Page</th>
                                <th style="width: 100px;vertical-align: middle;">Field</th>
                                <th style="width: 600px;vertical-align: middle;">Instruction</th>
                                <th style="width: 50px;vertical-align: middle;">Edit</th>
                                <th style="width: 50px;vertical-align: middle;">Delete</th>
                            </tr>
                            </thead>
                            <tbody style="background-color: white;font-size:18px;">
                                <tr *ngFor='let data of tableinstruction index as index'>
                                    <td style="text-align: center;">{{ index + 1 }}</td>
                                    <td style="text-align: center;">{{data.page}}</td>
                                    <td style="text-align: center;">{{data.field}}</td>
                                    <td>{{data.instructionName}}</td>
                                    <td style="text-align: center;">
                                        <button type="button" class="btn" (click)="func_addinstruction(data,'Edit')">
                                            <em class="fas fa-edit" style="color:orange;font-size:16px;"></em>
                                        </button>
                                    </td>
                                    <td style="text-align: center;">
                                        <button type="button" class="btn">
                                            <em class="fas fa-trash-alt" (click)="func_deleteinstruction(data)" style="color:red;font-size:16px;"></em>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="modal fade bd-Instruction" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" id="modaladdinstruction">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="container-fluid appform my-3">
                            <div class="row">
                                <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">
                                    <div class="container col-12 col-md-12 col-lg-12 col-xl-12 pb-0 mt-0 mb-0 ">
                                        <div class="container">
                                            <div class="col-12 rounded modalinstruction">
                                                <div class="col-12 pt-2">Instruction</div>
                                            </div>
                                            <div class="card mt-4">
                                                <div class="row col-12 mx-0 px-0 d-flex justify-content-around">

                                                    <div class="row col-12 mx-0 px-0">

                                                        <div class="row col-12 col-xl-6 mx-0 px-0">
                                                            <div class="col-12 mb-2 px-3">
                                                                <strong class="formtext">Page:<span style="color:red;">*</span></strong>
                                                            </div>
                                                            <div class="col-12">
                                                                <input  type="text" class="form-control" placeholder="Page" [(ngModel)]="Page">
                                                            </div>
                                                        </div>

                                                        <div class="row col-12 col-xl-6 mx-0 px-0">
                                                            <div class="col-12 mb-2 px-3">
                                                                <strong class="formtext">Field:<span style="color:red;">*</span></strong>
                                                            </div>
                                                            <div class="col-12">
                                                                <input  type="text" class="form-control" placeholder="Field" [(ngModel)]="Field">
                                                            </div>
                                                        </div>

                                                        <div class="row col-12 col-xl-12 mx-0 px-0">
                                                            <div class="col-12 mb-2 px-3">
                                                                <strong class="formtext">Instruction:<span style="color:red;">*</span></strong>
                                                            </div>
                                                            <div class="col-12">
                                                                <textarea rows="4" class="form-control" placeholder="Instruction" [(ngModel)]="InstructionName"></textarea>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div class="row col-12 mx-0 px-0 pt-2 mb-4">

                                                        <div class="row col-12 col-xl-3 mx-0 px-0">
                                                            <div class="col-12 mb-2 px-3">
                                                                <strong class="formtext">Status</strong>
                                                            </div>
                                                            <div class="col-12">
                                                                <select class="form-control" [(ngModel)]="status">
                                                                    <option value="">Please Select</option>
                                                                    <option *ngFor="let data of statusinstruction" value="{{ data.value }}">{{ data.title }}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 my-3 mx-0" style="text-align: center;">
                                                <button type="button" class="btn mr-3" style="width:100px;background-color:#9eb0ba;color:white;" (click)="func_closebtn()">Close</button>
                                                <button type="button" class="btn" style="width:100px;background-color:#033153;color:white;" (click)="func_savebtn()">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalAlertwarningDeleteItem">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <span class="bg-warning">&nbsp;</span>
                        <div class="modal-header">
                            <div class="row col-12 text-warning">
                                <div class="col-2">
                                    <em class="fas fa-exclamation-triangle" style="font-size:50px;"></em>
                                </div>
                                <div class="col-10 pt-2">
                                    <h4 class="modal-title">Alert !</h4>
                                </div>
                            </div>
                        </div>
                        <div class="modal-body text-body border-0">
                            <div class="col-12" style="text-align: left;">
                                <span style="font-size:18px;">Do you want to delete this Item {{"("+this.InstructionName+")"}}?</span><br>

                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-warning w-25 text-white" (click)="confirmDelete()">YES</button>
                            <button type="button" class="btn btn-secondary w-25" (click)="cancleDelete()">NO</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-loading [loading]='loading'></app-loading>