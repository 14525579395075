import { Injectable } from "@angular/core";
import { AppSettingService } from "src/app/app-setting-service";
import { Configmodel } from "src/app/model/configmodel";
import { AxiosInterceptorService } from "src/app/services/axios-interceptor.service";
export const config = {
    headers: {
        'Content-Type': 'application/json;charset=utf-8;',
        'Accept': 'q=0.8;application/json;q=0.9',
        'Access-Control-Allow-Origin': 'https://err-dev.pttep.com/',
        'Access-Control-Allow-Methods': 'POST, GET',
        'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
        'X-Frame-Options': 'DENY',
        'Content-Security-Policy': 'frame-ancestors \'none\''
    },
    emulateJSON: true
}

@Injectable({
    providedIn: 'root'
  })
  export class SummaryService {
  
    constructor(public appSettingService: AppSettingService, public axiosInterceptorService: AxiosInterceptorService) 
    { 

    }
    axiosInstance = this.axiosInterceptorService.getAxiosInstance();
    configData: Configmodel;

    getData() {
        this.configData = this.appSettingService.read();
        return this.configData;
    }

    BASE_URL = this.getData().api_strategicrisk_url + '/api/Summary';
    API_URL = {
        SummaryGetList: this.BASE_URL + '/GetSummaryByBu/'
    }

    func_getDataListSummary(_data) {
        return this.axiosInstance.get(this.API_URL.SummaryGetList + _data, config);
    }
    
  }