import { Component, EventEmitter, Input, Output } from '@angular/core';
@Component({
  selector: 'app-confirm-revise',
  templateUrl: './confirm-revise.component.html',
  styleUrls: ['./confirm-revise.component.css']
})
export class ConfirmReviseComponent {
  isOpenPageConfirm =false;
  title='Alert !'
  status =''
  message: string;
  isRefresh= false;
  @Output() actionEvent = new EventEmitter<string>();
  @Input() set DataAlert(value: {title:string,message:string,isRefresh:boolean,status:string}){
    if(value){
      this.setDataAlert(value);
    }
  }
  @Input() isCloseButtonEdit: boolean = false;

  setDataAlert(data){
    this.title=data.title;
    this.message=data.message;
    this.message=data.message;
    this.isRefresh=data.isRefresh;
    this.status=data.status;
  }

 Fun_Confirm(){
  this.status='checkfromAssumptions';
  this.actionEvent.emit(this.status);
  }
  Confirm(){
    this.actionEvent.emit(this.status);
  }
  Fun_Back(){
    this.status='checkfromBack';
    this.actionEvent.emit(this.status);
  }
}
