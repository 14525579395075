import { Component, OnInit, Injectable, Inject, Injector, Renderer2, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { NgbModal, NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
declare let $: any;
import { ActivatedRoute, Router } from '@angular/router';
import { Masterserve } from '../../services/master.service';
import { RiskMap } from '../../services/riskmap.service';
import { AppSettingService } from "src/app/app-setting-service";


import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AuthenService } from 'src/app/services/authenservice.service';
import { RefactorAssessment } from 'src/app/model/assessment';
import { MainProcessComponent } from '../main-process/main-process.component';
import { ShareService } from 'src/app/services/share.service';
import { formatDate } from '@angular/common';
import * as moment from 'moment';
import DOMPurify from 'dompurify';


@Injectable()



export class CustomDateParserFormatter extends NgbDateParserFormatter {
    constructor(private appServicecomp: MainProcessComponent,) { 
        super() 
    }
    readonly DELIMITER = '/';
    appService = this.appServicecomp;


    parse(value: string): NgbDateStruct | null {
        if (value) {
            let date = value.split(this.DELIMITER);
            return {
                day: (date[0], 10),
                month: (date[1], 10),
                year: (date[2], 10)
            };
        }
        return null;
    }


    format(date: NgbDateStruct | null): string {
        var currentDate = new Date;
        var pre_year = currentDate.getFullYear().toString();
        var pre_month = (currentDate.getMonth() + 1).toString();
        var pre_day = currentDate.getDate().toString();

        if (pre_month.length < 2) {
            pre_month = "0" + pre_month
        }
        if (pre_day.length < 2) {
            pre_day = "0" + pre_day
        }
        var string_currentDate = pre_year + "-" + pre_month + "-" + pre_day


        if (date !== null) {
            var str_year = date.year.toString();
            var str_mon = date.month.toString();
            var str_day = date.day.toString();

            if (str_day.length < 2) {
                str_day = "0" + str_day
            }
            if (str_mon.length < 2) {
                str_mon = "0" + str_mon
            }
            var datestring = str_year + '-' + str_mon + '-' + str_day


            if (this.appService.tmpdate === '' || this.appService.tmpdate === null) {
                this.appService.chkeditdate = false;
            }
            if (datestring < string_currentDate && this.appService.chkeditdate === false) {
                // alert('Please do not select the previous date of the current date');
                $('#modalAlert5').modal('show');
                return '';
            }
            else {
                var day = date.day.toString()
                var month = date.month.toString()
                if (day.length < 2) {
                    day = '0' + day
                }
                if (month.length < 2) {
                    month = '0' + month
                }
                this.appService.chkeditdate = false;
                var newdate = day + this.DELIMITER + month + this.DELIMITER + date.year;
                //   return date ? day + this.DELIMITER + month + this.DELIMITER + date.year : '';
            }

        }
        else {
            // return date ? day + this.DELIMITER + month + this.DELIMITER + date.year : '';
            return '';
        }
    }
}
export interface DialogData { }

@Component({
    selector: 'app-Assessment',
    templateUrl: './page.Assessment.html',
    styleUrls: ['./page.Assessment.css'],

    providers: [
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
        Masterserve, NgbModal, RiskMap
    ]
})

export class Assessment implements OnInit, AfterViewInit {

    @ViewChild('commentTextArea', { static: false }) textAreaCloseComment: ElementRef;
    Data_SessionStorage;
    myFilter = (d: Date | null): boolean => {
        if (!d) {
            return true; // ไม่มีวันที่ที่ถูกเลือก
        }

        const day = d.getDay();
        const today = new Date();
        const oneDay = 24 * 60 * 60 * 1000;
        let today2 = new Date(today.getTime() - oneDay);
        // ตรวจสอบว่าวันที่ที่เลือกไม่ใช่วันเสาร์ (6) หรือวันอาทิตย์ (0)
        // และไม่ใช่วันในอดีต
        const selectedDate = new Date(d);
        return day !== 0 && day !== 6 && selectedDate >= today2;
    };
    
    Quarter;
    Quarter_Getlist;
    alertflow = '';
    alertdate = 'Please do not select the previous date of the current date';
    tempcon_id = '';
    tempDeletevalue = '';
    checkstatusupdate;

    minDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
    isSaveEditHistory: number = 1;
    loading;
    loading2;
    valueadd = false;
    something = false;
    addsecond = false;
    displayprogress = true;
    statusbtn = false;
    isSelectImpactLevelAfter = true;

    Tempdata;
    tempmodule;
    Seformat_Date;
    idedit = '';
    tempTargetObjective = '';
    Register_Data;
    Register_Rootcause;
    Register_Impact;
    Edit_Impact;
    Edit_Rootcause;
    Assert_RootCauseTable = [];
    Assert_ImpactTable = [];
    temptable;
    MitigationtypeRootcause;
    rootrange;
    temp_mitigationRoottable;
    temptableImpact;
    MitigationTypeImpact;
    select_impactmitigationtype = '';
    myControl = new UntypedFormControl();
    options = [];
    filteredOptions: Observable<string[]>;
    myControl2 = new UntypedFormControl();
    filteredOptions2: Observable<string[]>;
    Financial_NI_NPV_EM;
    checkDDstatus;
    StatusIden = [
        { value: '1', text: 'Active' },
        { value: '2', text: 'Close (Turn to problem)' },
        { value: '3', text: 'Close (Mitigation Completed)' },
        { value: '4', text: 'Close (Other)' }
    ];

    tempDisplayFile = [];
    tempfileID;

    likelyhoodLevel;
    likelyhoodLevelAfter;
    ImpactLevel;
    ImpactLevelAfter;
    RiskCategory;
    ImpactCategory;
    Financial;
    CorpTargetHead;
    CorpTargetItem;
    Instruction;
    temp_impact_Category;

    data_risk_table;
    list = {
        risk: {
            impactcolor: [],
            impact: [],
            risktable: [
                {
                    row: 1,
                    col: [
                        { col: 1, class: 'medium', item: [] },
                        { col: 2, class: 'medium', item: [] },
                        { col: 3, class: 'high', item: [] },
                        { col: 4, class: 'high', item: [] },
                        { col: 5, class: 'high', item: [] },
                    ]
                },
                {
                    row: 2,
                    col: [
                        { col: 1, class: 'medium', item: [] },
                        { col: 2, class: 'medium', item: [] },
                        { col: 3, class: 'medium', item: [] },
                        { col: 4, class: 'high', item: [] },
                        { col: 5, class: 'high', item: [] },
                    ]
                },
                {
                    row: 3,
                    col: [
                        { col: 1, class: 'low', item: [] },
                        { col: 2, class: 'medium', item: [] },
                        { col: 3, class: 'medium', item: [] },
                        { col: 4, class: 'medium', item: [] },
                        { col: 5, class: 'high', item: [] },
                    ]
                },
                {
                    row: 4,
                    col: [
                        { col: 1, class: 'low', item: [] },
                        { col: 2, class: 'low', item: [] },
                        { col: 3, class: 'medium', item: [] },
                        { col: 4, class: 'medium', item: [] },
                        { col: 5, class: 'medium', item: [] },
                    ]
                },
                {
                    row: 5,
                    col: [
                        { col: 1, class: 'low', item: [] },
                        { col: 2, class: 'low', item: [] },
                        { col: 3, class: 'low', item: [] },
                        { col: 4, class: 'medium', item: [] },
                        { col: 5, class: 'medium', item: [] },
                    ]
                }
            ],
            riskitems: []
        },
        riskprofile: {
            year: [],
            quarter: []
        },
        businessUnit: {
            items: []
        },
        field: {
            Mitigate: 1,
            riskprofileYear: '',
            riskprofileQuarter: '',
            businessUnit: '',
        }
    }

    myControlBUDD = new UntypedFormControl();
    BusinessNameDD = [];
    filteredOptionBUDD: Observable<string[]>;
    tempAssetBU = [];
    tempChildOrg = [];
    tempERMInfo = [];
    tempOrg = [];

    ShowWPB = false;
    tablewpb = [];
    SelectWPB = '1';
    WBPID = null;
    BASE_URL = "";
    chkbackmenu = 'go';
    tempselectbu = '';
    tempChkRoleBUDD = [];
    alert = '';
    TempQuarterID = '';
    Impact_After_Total_Amont = '';
    tempChkBUDD = [];
    riskstatusfrom_myrisk = '';

    flagUpdatePrelim = false;

    CloseComment: string = null;
    Risk_Close_Reason: string = null;
    CloseCommentFlag = false;
    CloseCommentFlagSave = false;

    TempRiskStatus: string = null;
    TempRiskAssignTo: string = null;

    LikelyhoodPrevious = null;
    ImpactPrevious = null;

    authserviceToken: AuthenService;
    route: ActivatedRoute;
    Mapserve: RiskMap;
    router: Router;
    dataService: ShareService;
    renderer: Renderer2;
    el: ElementRef;


    constructor(
        private _Activatedroute: ActivatedRoute,

        public dialog: MatDialog,
        public mserve: Masterserve,
        private appServicecomp: MainProcessComponent,
        private injector: Injector,
        public appSettingService: AppSettingService
    ) {
        this.authserviceToken = injector.get<AuthenService>(AuthenService)
        this.route = injector.get<ActivatedRoute>(ActivatedRoute)
        this.Mapserve = injector.get<RiskMap>(RiskMap)
        this.router = injector.get<Router>(Router)
        this.dataService = injector.get<ShareService>(ShareService)
        this.renderer = injector.get<Renderer2>(Renderer2)
        this.el = injector.get<ElementRef>(ElementRef)

    }



    //path = this.BASE_URL + '/app/backend/Resources/FileUpload';

    appService = this.appServicecomp;
    ngOnInit(): void {

        let configdata = this.appSettingService.read();
        this.BASE_URL = configdata.api_url;


        //ngOnInit_II_(6)
        $('.modal').on("hidden.bs.modal", function (e) {
            if ($('.modal:visible').length) {
                $('body').addClass('modal-open');
            }
        });
        if (sessionStorage.getItem('statusflow') === 'edit') {
            this.checkstatusupdate = 'edit'

        } else {
            this.checkstatusupdate = 'new'
        }
        if (sessionStorage.getItem('TempRiskAssignTo') != null && sessionStorage.getItem('TempRiskStatus') != null) {
            this.TempRiskStatus = sessionStorage.getItem('TempRiskStatus')
            this.TempRiskAssignTo = sessionStorage.getItem('TempRiskAssignTo')
        }


        this.loading = true;

        this.func_GETMASTER_Instruction()
        this.Register_Data = {
            Regis_RiskNo: '',
            Regis_RiskID: '',
            Regis_Riskname: '',
            Regis_Likelyhood: '',
            Regis_Impact: '',
            Regis_Status: '',
            Regis_Category: '',
            Regis_ObjectiveHead: '',
            Regis_Objective: '',
            Regis_KPI: '',
            Regis_Context: '',
            Regis_BusinessName: '',
            Regis_BusinessUnit: '',
            Regis_Register_By: '',
            Regis_Modified_By: '',
            Regis_Status_Workflow: '',
            Regis_Role: '',
            tempQuarter: '',
            tempYear: '',
            AttachFiles: [],
            Risk_Previous_Likelihood: '',
            Risk_Previous_Impact: ''
        }

        this.Register_Rootcause = {
            RootCause_Id: '',
            Root_RootCause: '',
            Root_RootLikelyhood: '',
            Root_RootMitigationType: '',
            Root_AfterMitigated: '',
            Root_KRIName: '',
            Root_KRIThreshold_Green: '',
            Root_KRIThreshold_Red: '',
            Root_KRIStatus: '',
            Root_Justification: '',
            RootCause_Description: '',
            Root_Mitigation: [
                {
                    RootCauseMitigation_Name: '',
                    RootCauseMitigation_Owner: '',
                    RootCauseMitigation_DueDate: '',
                    RootCauseMitigation_Progress: 0,
                    RootCauseMitigation_Justification: '',
                    RootCauseMitigation_OwmerText: ''
                }
            ]
        }

        this.Register_Impact = {
            Impact_Id: '',
            Impact_Category: '',
            Impact_NPTEMV: '',
            Impact_Total_Amont: '',
            Impact_Description: '',
            Impact_Level: '',
            Impact_Migation_Type: '',
            Impact_After_Mitigated: '',
            Impact_Rating: '',
            Impact_Mitigation: [
                {
                    Risk_Id: '',
                    Impact_Id: '',
                    Impact_Mitigation_Id: '',
                    Impact_Mitigation_Name: '',
                    Impact_Mitigation_Owner: '',
                    Impact_Mitigation_DueDate: '',
                    Impact_Mitigation_Progress: 0,
                    Impact_Mitigation_Justification: '',
                    Impact_Mitigation_OwnerText: '',
                }
            ]
        }

        this.Edit_Impact = {
            ImpactPlan: '',
            ImpactOwner: '',
            ImpactDate: '',
            ImpactDateEdit: '',
            ImpactProgress: '',
            ImpactJustification: '',
            ImpactOwnertext: ''
        }
        this.Edit_Rootcause = {
            RootPlan: '',
            RootOwner: '',
            RootDate: '',
            RootDateEdit: '',
            RootProgress: '',
            RootJustification: '',
            RootOwnertext: ''
        }

        this.temptable = []
        this.MitigationtypeRootcause = [
            { title: 'Take' },
            { title: 'Treat' }
        ]
        this.temp_mitigationRoottable = []

        this.temptableImpact = []
        this.MitigationTypeImpact = [
            { title: 'Take' },
            { title: 'Treat' },
            { title: 'Transfer' }
        ]

        this._Activatedroute.paramMap.subscribe(params => {
            this.appService.status_pageIdentification = params.get('display');
        });
        this.ngOnInit_II_chkstatus_pageIdentification();

    }

    private ngOnInit_II_chkstatus_pageIdentification() {
        if (this.appService.status_pageIdentification !== 'new') {
            this.Data_SessionStorage = JSON.parse(sessionStorage.datainfo);

            this.Register_Data.Regis_RiskID = sessionStorage.RiskID ? JSON.parse(sessionStorage.RiskID) : null;
            this.Register_Data.Regis_Modified_By = this.Data_SessionStorage[0].employeE_ID;
            this.Register_Data.Regis_Role = sessionStorage.getItem('TranSecRole');
            this.loadDDL();
        }
        else {
            this.Data_SessionStorage = JSON.parse(sessionStorage.datainfo);
            this.func_GetDataUser();
            let d = new Date();
            let currentYear = d.getFullYear();
            let currentMonth = d.getMonth() + 1;
            this.mserve.Master_DDL({ Module: "QuarterDD", TextSearch1: currentYear }).then((response) => {
                this.Quarter = response.data.body;
                this.func_calQuarter(currentMonth);
            }).catch((e) => {

            });

            let sessionRoleStaft = (sessionStorage.Role === 'Staff');
            let sessionRoleOwner = (sessionStorage.Role === 'Owner');

            this.ngOnInit_III_sessionRole(sessionRoleStaft, sessionRoleOwner);
            this.ngOnInit_III_forloop_itemtempAssetBU();
            this.ngOnInit_III_forloop_itemtempOrg();
            this.ngOnInit_III_forloop_itemtempChildOrg();
            this.ngOnInit_III_forloop_itemtempERMInfo();
            let uniq_values = [];

            this.ngOnInit_III_forloop_BusinessNameDD(uniq_values);

            this.BusinessNameDD = [];

            Array.prototype.push.apply(this.BusinessNameDD, this.tempChkBUDD);

            //ngOnInit_III_route
            this.route.params.subscribe(val => {
                this.ngOnInit_III_route();
            });

            this.filteredOptionBUDD = this.myControlBUDD.valueChanges.pipe(
                startWith(''),
                map(value => this._filterBUDD(value))
            );

            const _s = this;

            this.ngOnInit_III_modalAlertBU(_s);
            this.ngOnInit_III_modalAlertBU5(_s);
          
            this.func_GetWPB();
            this.loading = false;
        }
    }
    sendDataShowBuName(dataToSend: string) {
        this.dataService.sendData(dataToSend);
    }
    // goHome() {

    //     var path = sessionStorage.getItem('mainUrl');
    //     window.location.replace(path);

    // }
    goHome() {

        let cleanPath = DOMPurify.sanitize(sessionStorage.getItem('mainUrl'));
        window.location.replace(cleanPath);

    }
    private ngOnInit_III_modalAlertBU5(_s: this) {

        $('#modalBU5').on('hidden.bs.modal', function (e) {

            if (_s.chkbackmenu === 'go') {
                let tempBU = [];
                if (_s.tempselectbu !== '') {
                    _s.appService.showBUName = _s.tempselectbu;
                    _s.dataService.sendData(_s.tempselectbu);
                    _s.Register_Data.Regis_BusinessName = _s.tempselectbu;
                    sessionStorage.setItem('BUNameWork', _s.tempselectbu);
                    _s.sendDataShowBuName(_s.tempselectbu);

                    ngOnInit_IV_forloop_sitemBusinessNameDD(tempBU);
                    ngOnInit_IV_chk_tempBU(tempBU);
                    _s.Register_Data.Regis_BusinessUnit = tempBU[0].BuCode;
                    sessionStorage.setItem('BUValueWork', tempBU[0].BuCode);
                }
                else {
                    _s.alert = 'Please select Business Name';
                    $('#modalAlertBU').modal('show');
                }

            } else {
                _s.appService.status = 1;
                _s.appService.checkrole();
                _s.router.navigate(['/']);
            }
        });

        function ngOnInit_IV_forloop_sitemBusinessNameDD(tempBU: any[]) {
            for (let _sitemBusinessNameDD of _s.BusinessNameDD) {
                if (_s.tempselectbu === _sitemBusinessNameDD.text) {
                    tempBU.push({ BuCode: _sitemBusinessNameDD.value, State: _sitemBusinessNameDD.state });
                    ngOnInit_IV_forloop_sitemtempChkRoleBUDD();

                }
            }
        }

        function ngOnInit_IV_forloop_sitemtempChkRoleBUDD() {
            for (let _sitemtempChkRoleBUDD of _s.tempChkRoleBUDD) {
                if ((_sitemtempChkRoleBUDD.text == _s.tempselectbu) && (_sitemtempChkRoleBUDD.role == _s.Register_Data.Regis_Register_By || _sitemtempChkRoleBUDD.role == 'Co')) {
                    _s.Register_Data.Regis_Role = 'Co';
                    sessionStorage.setItem("TranSecRole", 'Co');
                    break;
                }
                else if ((_sitemtempChkRoleBUDD.text == _s.tempselectbu) && (_sitemtempChkRoleBUDD.role != _s.Register_Data.Regis_Register_By)) {
                    _s.Register_Data.Regis_Role = 'Staff';
                    sessionStorage.setItem("TranSecRole", 'Staff');
                }
            }
        }

        function ngOnInit_IV_chk_tempBU(tempBU: any[]) {
            if (tempBU[0].State === 'Asset') {
                _s.Register_Data.Regis_RiskType = 'Asset';
                sessionStorage.setItem('BUType', _s.Register_Data.Regis_RiskType);
            } else if (tempBU[0].State === 'Org') {
                _s.Register_Data.Regis_RiskType = 'Organization';
                sessionStorage.setItem('BUType', _s.Register_Data.Regis_RiskType);
            } else {
                _s.Register_Data.Regis_RiskType = 'Corporate';
                sessionStorage.setItem('BUType', _s.Register_Data.Regis_RiskType);
            }
        }
    }

    private ngOnInit_III_modalAlertBU(_s: this) {
        $('#modalAlertBU').on('hidden.bs.modal', function (e) {
            if (_s.tempselectbu == '') {
                $('#modalBU5').modal('show');
            }
        });
    }

    private ngOnInit_III_route() {

        if (this.appService.status_pageIdentification === 'new') {
            this.checkstatusupdate = 'new';
            this.appService.showBUName = '';
            this.Register_Data.Regis_Riskname = '';

            if (this.BusinessNameDD.length == 1) {

                this.tempselectbu = this.BusinessNameDD[0].text;
                this.appService.showBUName = this.BusinessNameDD[0].text;
                this.Register_Data.Regis_BusinessName = this.BusinessNameDD[0].text;
                sessionStorage.setItem('BUNameWork', this.BusinessNameDD[0].text);

                this.sendDataShowBuName(this.BusinessNameDD[0].text);
                if (this.BusinessNameDD[0].state === 'Asset') {
                    this.Register_Data.Regis_RiskType = 'Asset';
                    sessionStorage.setItem('BUType', this.Register_Data.Regis_RiskType);
                } else if (this.BusinessNameDD[0].state === 'Org') {
                    this.Register_Data.Regis_RiskType = 'Organization';
                    sessionStorage.setItem('BUType', this.Register_Data.Regis_RiskType);
                } else {
                    this.Register_Data.Regis_RiskType = 'Corporate';
                    sessionStorage.setItem('BUType', this.Register_Data.Regis_RiskType);
                }
                this.ngOnInit_IV_forloop_itemtempChkRoleBUDD();
                this.Register_Data.Regis_BusinessUnit = this.BusinessNameDD[0].value;
                sessionStorage.setItem('BUValueWork', this.BusinessNameDD[0].value);
            }

        }
        else {
            sessionStorage.setItem('statusflow', 'edit');
            this.checkstatusupdate = 'edit';
            this.riskstatusfrom_myrisk = sessionStorage.getItem('RiskStatus');
        }
    }

    private ngOnInit_IV_forloop_itemtempChkRoleBUDD() {
        for (let itemtempChkRoleBUDD of this.tempChkRoleBUDD) {
            if ((itemtempChkRoleBUDD.text == this.tempselectbu) && (itemtempChkRoleBUDD.role == this.Register_Data.Regis_Register_By)) {
                this.Register_Data.Regis_Role = 'Co';
                sessionStorage.setItem("TranSecRole", 'Co');
                break;
            }
            else if ((itemtempChkRoleBUDD.text == this.tempselectbu) && (itemtempChkRoleBUDD.role != this.Register_Data.Regis_Register_By)) {
                this.Register_Data.Regis_Role = 'Staff';
                sessionStorage.setItem("TranSecRole", 'Staff');
            }
        }
    }

    private ngOnInit_III_forloop_BusinessNameDD(uniq_values: any[]) {

        for (let x of this.BusinessNameDD) {
            this.tempChkRoleBUDD.push(x);
            if (uniq_values.indexOf(x.value) == -1) {
                uniq_values.push(x.value);
                this.tempChkBUDD.push(x);
            }
        }
    }

    private ngOnInit_III_forloop_itemtempERMInfo() {
        for (let itemtempERMInfo of this.tempERMInfo) {
            this.BusinessNameDD.push({ value: itemtempERMInfo.organizatioN_ID, text: itemtempERMInfo.abbreviation, fullname: itemtempERMInfo.organizatioN_NANE, state: 'CORP', role: 'Co' });
        }
    }

    private ngOnInit_III_forloop_itemtempChildOrg() {

        for (let itemtempChildOrg of this.tempChildOrg) {
            this.BusinessNameDD.push({ value: itemtempChildOrg.organizatioN_ID, text: itemtempChildOrg.abbreviation, fullname: itemtempChildOrg.name, state: 'Org', role: '' });
        }
    }

    private ngOnInit_III_forloop_itemtempOrg() {
        for (let itemtempOrg of this.tempOrg) {
            this.BusinessNameDD.push({ value: itemtempOrg.organizatioN_ID, text: itemtempOrg.abbreviation, fullname: itemtempOrg.organizatioN_NANE, state: 'Org', role: itemtempOrg.coordinator_Employee_Id });
        }
    }

    private ngOnInit_III_forloop_itemtempAssetBU() {

        for (let itemtempAssetBU of this.tempAssetBU) {
            this.BusinessNameDD.push({ value: itemtempAssetBU.asset_Code, text: itemtempAssetBU.asset_Short, fullname: itemtempAssetBU.asset_Name, state: 'Asset', role: itemtempAssetBU.asset_Coordinators });
        }
    }

    private ngOnInit_III_sessionRole(sessionRoleStaft: boolean, sessionRoleOwner: boolean) {
        if (sessionStorage.Role === 'Staff') {
            this.ngOnInit_IV_sessionRole_1();
        }
        else if (sessionStorage.Role === 'Co' && (this.Data_SessionStorage[0].s_PERMISSSION_LEVEL.includes("OWNER") || this.Data_SessionStorage[0].s_PERMISSSION_LEVEL.includes("Owner"))) {
            this.ngOnInit_IV_sessionRole_2();
        }
        else if (sessionStorage.Role === 'Co') {
            this.ngOnInit_IV_sessionRole_3();
        }
        else if (sessionStorage.Role === 'Owner') {
            this.ngOnInit_IV_sessionRole_1();
        }
        else if (sessionStorage.Role === 'ERM') {
            this.ngOnInit_IV_sessionRole_4();
        }
    }

    private ngOnInit_IV_sessionRole_4() {

        this.tempOrg = (this.Data_SessionStorage[0].coInfo !== null) ? this.Data_SessionStorage[0].coInfo : [];
        this.tempERMInfo = (this.Data_SessionStorage[0].ermInfo !== null) ? this.Data_SessionStorage[0].ermInfo : [];
        this.tempChildOrg = (this.Data_SessionStorage[0].childOrganizationInfo !== null) ? this.Data_SessionStorage[0].childOrganizationInfo : [];
    }

    private ngOnInit_IV_sessionRole_3() {

        this.tempAssetBU = (this.Data_SessionStorage[0].assetInfo !== null) ? this.Data_SessionStorage[0].assetInfo : [];
        this.tempOrg = (this.Data_SessionStorage[0].coInfo !== null) ? this.Data_SessionStorage[0].coInfo : [];
        this.tempChildOrg = (this.Data_SessionStorage[0].childOrganizationInfo !== null) ? this.Data_SessionStorage[0].childOrganizationInfo : [];
    }

    private ngOnInit_IV_sessionRole_2() {

        this.tempAssetBU = (this.Data_SessionStorage[0].assetInfo !== null) ? this.Data_SessionStorage[0].assetInfo : [];
        this.tempOrg = (this.Data_SessionStorage[0].coInfo !== null) ? this.Data_SessionStorage[0].coInfo : [];
    }

    private ngOnInit_IV_sessionRole_1() {

        this.tempAssetBU = (this.Data_SessionStorage[0].assetInfo !== null) ? this.Data_SessionStorage[0].assetInfo : [];
        this.tempChildOrg = (this.Data_SessionStorage[0].childOrganizationInfo !== null) ? this.Data_SessionStorage[0].childOrganizationInfo : [];
    }

    func_runRegisFirst() {

        let Regis_Riskname;
        Regis_Riskname = document.getElementById('RegisRiskname');

        if (this.appService.status_pageIdentification === 'new' && Regis_Riskname.value != '') {
            let Regis_Riskname;
            Regis_Riskname = document.getElementById('RegisRiskname');

            let formData = {
                SessionEmpID: "0001",
                Module: "Insert",
                body: {
                    Risk_Id: '',
                    Risk_Name: Regis_Riskname.value,
                    Risk_Business_Unit_Abbreviation: this.Register_Data.Regis_BusinessName,
                    Risk_Business_Unit: this.Register_Data.Regis_BusinessUnit,
                    Risk_Status: '1',
                    Risk_Likelihood: this.Register_Data.Regis_Likelyhood,
                    Risk_Impact: this.Register_Data.Regis_Impact,
                    Risk_Category: this.Register_Data.Regis_Category,
                    Risk_Objective: this.Register_Data.Regis_Objective,
                    Risk_Objective_Parent: this.Register_Data.Regis_ObjectiveHead,
                    Risk_Unit_KPI: this.Register_Data.Regis_KPI,
                    Risk_Context: this.Register_Data.Regis_Context,
                    Risk_Register_By: this.Register_Data.Regis_Register_By,
                    Risk_Status_Workflow: '23',
                    Role: this.Register_Data.Regis_Role,
                    Risk_Type: this.Register_Data.Regis_RiskType,
                    QuarterID: this.Quarter_Getlist,
                    WPBID:  this.SelectWPB == '2'?this.WBPID:'',
                    ReCall: ''
                }
            }
            this.mserve.RegisterRisk(formData).then((response) => {

                if (response.status) {

                    sessionStorage.setItem('RiskID', JSON.stringify(response.data.return_Id));
                    sessionStorage.setItem("RiskStatus", '23');

                    if (this.Register_Data.Regis_Role !== "Staff") {
                        sessionStorage.setItem("TranSecRole", 'Co');
                    } else {
                        sessionStorage.setItem("TranSecRole", 'Staff');
                    }
                    this.router.navigate(['/Assessment']);
                } else {
                    this.alertflow = response.data.errorMessage
                    $('#modalAlertdanger5').modal('show');

                }
            }).catch((e) => {
                if (e.response) { this.authserviceToken.CheckTokenExpire(e.response.status) } else { }
            });
        }
        else {
            if (Regis_Riskname.value == '') {
                this.alertflow = 'Risk Name is required.'
                $('#modalAlertdanger5').modal('show');
                $('#RegisRiskname').focus();
            }
        }
    }

    func_GetDataUser() {
        this.Register_Data.Regis_Register_By = this.Data_SessionStorage[0].employeE_ID;
        this.Register_Data.Regis_Role = sessionStorage.getItem('Role');
    }

    func_GetWPB() {
        let formData = {
            SessionEmpID: "1",
            Module: "getlist",
            body: {
                Year: "",
                StartDate: "",
                EndDate: "",
            }
        }

        this.mserve.GetWPB(formData).then((response) => {
            if (response.data.status) {

                this.tablewpb = response.data.body;
                let d = new Date();
                let day = (d.getDate());
                let month = (d.getMonth() + 1);
                let year = (d.getFullYear());
                let date = year + '-' + month + '-' + day;

                for (let itemttablewpb of this.tablewpb) {
                    let tempStartday = null, tempStartmonth = null, tempStartyear = null;
                    let tempEndday = null, tempEndmonth = null, tempEndyear = null;
                    tempStartyear = itemttablewpb.startDate.substr(0, 4);
                    tempStartmonth = itemttablewpb.startDate.substr(5, 2);
                    tempStartday = itemttablewpb.startDate.substr(8, 2);
                    tempStartyear = parseInt(tempStartyear);
                    tempStartmonth = parseInt(tempStartmonth);
                    tempStartday = parseInt(tempStartday);

                    tempEndyear = itemttablewpb.endDate.substr(0, 4);
                    tempEndmonth = itemttablewpb.endDate.substr(5, 2);
                    tempEndday = itemttablewpb.endDate.substr(8, 2);
                    tempEndyear = parseInt(tempEndyear);
                    tempEndmonth = parseInt(tempEndmonth);
                    tempEndday = parseInt(tempEndday);
                    this.GetWPB_YearCheck(year,month,day,tempStartyear,tempEndyear, tempStartmonth,tempEndmonth,tempStartday,tempEndday,itemttablewpb)
                }


                let BusinessNameDD = (this.BusinessNameDD.length !== 1);
                //console.log("ShowWPB",this.ShowWPB)
                if (BusinessNameDD || this.ShowWPB) { 
                    setTimeout(function () { $('#modalBU5').modal('show') }, 500);
                }

                this.loading = false;
            }
            else {

                alert(response.data.errorMessage);
            }
        }).catch((e) => {
            if (e.response) { this.authserviceToken.CheckTokenExpire(e.response.status) } else { }
        });
    }

    private GetWPB_YearCheck(year: number, month: number, day: number,tempStartyear: any,tempEndyear: any, tempStartmonth: any,tempEndmonth: any,tempStartday: any,tempEndday: any, itemttablewpb: any)
    { 
        this.ShowWPB = false;
        if((year>=tempStartyear) && (year <= tempEndyear)){
            if((month>=tempStartmonth) && (month <= tempEndmonth)){
                if((day>=tempStartday) && (day <= tempEndday)){
                    this.ShowWPB = true;
                    this.SelectWPB = '2';
                    this.WBPID = itemttablewpb.wpbId;
                }
            }
        }
    }

    loadDDL() {
        let _s = this;


        _s.mserve.Master_DDL({ Module: "QuarterDD", TextSearch1: null }).then((response) => {
            _s.Quarter = response.data.body

            this.func_GETDATA('Getmaster')
        }).catch((e) => {
            if (e.response) { this.authserviceToken.CheckTokenExpire(e.response.status) } else { }
        });

    }

    func_calQuarter(month) {
        this.Quarter_Getlist = ''
        if (month >= 1 && month <= 3) {
            this.Quarter_Getlist = this.Quarter[0].value;
        } else if (month >= 4 && month <= 6) {
            this.Quarter_Getlist = this.Quarter[1].value;
        } else if (month >= 7 && month <= 9) {
            this.Quarter_Getlist = this.Quarter[2].value;
        } else if (month >= 10 && month <= 12) {
            this.Quarter_Getlist = this.Quarter[3].value;
        }
    }

    func_GETDATA(value) {

        const _s = this;
        let formData = {
            SessionEmpID: "0001",
            Module: "GET",
            body: {
                Risk_Id: this.Register_Data.Regis_RiskID,
                Risk_Name: "",
                Risk_Business_Unit: "",
                Risk_Status: "",
                Risk_Likelihood: "",
                Risk_Impact: "",
                Risk_Category: "",
                Risk_Objective_Parent: "",
                Risk_Objective: "",
                Risk_Unit_KPI: "",
                Risk_Context: "",
                Role: this.Register_Data.Regis_Role
            }
        }
        _s.loading = true;

        this.mserve.RegisterRisk(formData).then((response) => {
            //console.log("Response Data func_GETDATA :" + JSON.stringify(response.data));
            if (response.data.status) {

                this.func_GETDATA_II_SetRegister_Data(response);
                if (this.Tempdata.risk_Close_Reason) {
                    this.Risk_Close_Reason = this.Tempdata.risk_Close_Reason
                    //console.log('this.Risk_Close_Reason', this.Risk_Close_Reason)
                }
                if ((this.Tempdata.impact.length > 0 && this.Tempdata.rootCause.length > 0)
                    && this.LikelyhoodPrevious == null && this.ImpactPrevious == null) {
                    this.LikelyhoodPrevious = Math.max(...this.Tempdata.rootCause.map(o => o.rootCause_Likelihood));
                    this.ImpactPrevious = Math.max(...this.Tempdata.impact.map(o => o.impact_Level));
                }
                else {
                    this.LikelyhoodPrevious = null;
                    this.ImpactPrevious = null;
                }

                this.Register_Data.Risk_Previous_Likelihood = this.LikelyhoodPrevious;
                this.Register_Data.Risk_Previous_Impact = this.ImpactPrevious;
                this.Register_Data.Impact_After_Mitigated = this.Tempdata.impact.impact_After_Mitigated

                this.func_GETDATA_II_forloop_itemQuarter();

                this.func_GETMASTER_Financial()
                if (value === 'Getmaster') {
                    this.func_GETMASTER_ImpactCategory()
                    this.func_GETMASTER_Likelihood()
                    this.func_GETMASTER_RiskCategory()
                    this.func_GETMASTER_ImpactLevel()
                    this.func_GETMASTER_CorptargetHead()
                    this.func_GetImpactRiskMap()
                    this.func_GetDDL_BusinessName()

                }
                this.func_PlotRiskMap()
                this.func_CheckStatus();
                this.func_GETDATA_II_chk_rootCause();

                if (this.Register_Data.Regis_Likelyhood === null && this.Register_Data.Regis_Impact === null) {
                    this.flagUpdatePrelim = true;
                }

                _s.loading = false


            } else {
                alert(response.data.errorMessage);

            }
        });
    }

    private func_GETDATA_II_SetRegister_Data(response) {
        this.Tempdata = response.data.body[0];
        // //console.log("this.Tempdata124343", this.Tempdata);
        this.appService.showBUName = this.Tempdata.abbreviation;
        this.Register_Data.Regis_RiskNo = (this.Tempdata.risk_Co_Id !== null) ? this.Tempdata.risk_Co_Id : this.Tempdata.risk_Staff_Id;
        this.Register_Data.Regis_Riskname = this.Tempdata.risk_Name;
        this.Register_Data.Regis_Likelyhood = this.Tempdata.risk_Likelihood;
        this.Register_Data.Regis_Impact = this.Tempdata.risk_Impact;
        this.Register_Data.Regis_Status = this.Tempdata.risk_Status;
        this.Register_Data.Regis_Status_Workflow = this.Tempdata.risk_Status_Workflow;
        this.Register_Data.Regis_Category = (this.Tempdata.risk_Category === null) ? '' : this.Tempdata.risk_Category;
        this.Register_Data.Regis_Objective = (this.Tempdata.risk_Objective === null) ? '' : this.Tempdata.risk_Objective;
        this.Register_Data.Regis_KPI = this.Tempdata.risk_Unit_KPI;
        this.Register_Data.Regis_Context = this.Tempdata.risk_Context;
        this.Register_Data.Regis_BusinessName = this.Tempdata.abbreviation;
        this.Register_Data.Regis_BusinessUnit = this.Tempdata.risk_Business_Unit;
        this.Register_Data.Regis_ObjectiveHead = (this.Tempdata.risk_Objective_Parent === null) ? '' : this.Tempdata.risk_Objective_Parent;
        this.Register_Data.tempQuarter = this.Tempdata.quarterID;
        this.Register_Data.AttachFiles = this.Tempdata.attachFiles;
        this.replacePathFile();
        // for (let i = 0; i < this.Tempdata.attachFiles.length; i++) {
        //     let _path = this.Tempdata.attachFiles[i].pathFile;
        //     var regex = /\\/g;
        //     var _path2 = _path.replace(regex, "/");
        //    // this.Register_Data.AttachFiles[i].pathFile = _path2.replace("ERR", this.BASE_URL + "/document");
        //     this.Register_Data.AttachFiles[i].pathFile = _path2.replace("ERR/RiskResources/FileUpload/", "");
        // }
        // //console.log("_path Index " + JSON.stringify(this.Register_Data.AttachFiles));
    }

    private func_GETDATA_II_forloop_itemQuarter() {
        for (let itemQuarter of this.Quarter) {
            if (this.Register_Data.tempQuarter == itemQuarter.value) {
                this.Register_Data.tempYear = itemQuarter.text2;
                break;
            }
        }
    }

    private func_GETDATA_II_chk_rootCause() {
        if (this.Tempdata.rootCause.length !== 0) {
            this.Assert_RootCauseTable = [];

            let maxRootCause = Math.max(...this.Tempdata.rootCause.map(o => o.rootCause_Likelihood));
            for (let itemrootCause of this.Tempdata.rootCause) {
                let isMaxRootCause = 'N';
                if (itemrootCause.rootCause_Likelihood == maxRootCause) {
                    isMaxRootCause = 'Y';
                }
                this.Assert_RootCauseTable.push({
                    id: itemrootCause.rootCause_Id,
                    title: itemrootCause.rootCause_Category,
                    highlight: isMaxRootCause
                });
            }
        } else {
            this.Assert_RootCauseTable = [];
        }
    }

    func_CloseCommentYes(CloseCommentFlag) {
        const _s = this;

        //console.log("CloseComment", _s.Risk_Close_Reason)
        if (_s.CloseComment === null || _s.CloseComment === "") {
            $('#CloseComment').focus();
            $('#Consolecomment').removeAttr("hidden");
        }
        else {
            $('#modalCommentCloseAlert').modal('hide');
            setTimeout(function () {
                _s.func_UPDATEDATA(CloseCommentFlag);
            }, 300)
        }
    }
    
    reloadFile(){
        let formData = {
            SessionEmpID: "0001",
            Module: "GET",
            body: {
                Risk_Id: this.Register_Data.Regis_RiskID,
                Risk_Name: "",
                Risk_Business_Unit: "",
                Risk_Status: "",
                Risk_Likelihood: "",
                Risk_Impact: "",
                Risk_Category: "",
                Risk_Objective_Parent: "",
                Risk_Objective: "",
                Risk_Unit_KPI: "",
                Risk_Context: "",
                Role: this.Register_Data.Regis_Role
            }
        }
        this.mserve.RegisterRisk(formData).then((response) => {
            this.Tempdata = response.data.body[0];
            this.Register_Data.AttachFiles = this.Tempdata.attachFiles;
            this.replacePathFile();
        })
    }
    replacePathFile() {
        for (let i = 0; i < this.Tempdata.attachFiles.length; i++) {
            let _path = this.Tempdata.attachFiles[i].pathFile;
            var regex = /\\/g;
            var _path2 = _path.replace(regex, "/");
            this.Register_Data.AttachFiles[i].pathFile = _path2.replace("ERR/RiskResources/FileUpload/", "");
        }
    }
    func_CloseCommentNo() {
        const _s = this;
        _s.CloseComment = null;
    }

    func_CommentCloseOtherYes(CloseCommentFlag) {
        const _s = this;
        //console.log("Risk_Close_Reason", _s.Risk_Close_Reason)
        if (_s.Risk_Close_Reason === null || _s.Risk_Close_Reason === "") {
            $('#CloseComment').focus();
            $('#Consolecomment2').removeAttr("hidden");
        }
        else {
            $('#modalCommentCloseOtherAlert').modal('hide');
            setTimeout(function () {
                _s.func_UPDATEDATA(CloseCommentFlag);
            }, 300)
        }
    }

    func_CommentCloseOtherNo() {
        const _s = this;
        _s.Risk_Close_Reason = null;
    }

    func_UPDATEDATA(value,SaveEditHistory = 0) {
        const _s = this;
        let statusSavenochk;
        let chkasses, chkreqroot, chkreqrootarr = [], chkreqImpact, chkreqImpactarr = [];
        _s.CloseComment = _s.Risk_Close_Reason;
        //console.log(_s.Tempdata)
        if (this.Register_Data.Regis_Status == "2" && (_s.Risk_Close_Reason === null || _s.Risk_Close_Reason === "") && value) {
            _s.CloseCommentFlag = value;
            this.alertflow = 'Please fill-in Reason/Detail(s)'
            $('#modalAlertdanger5').modal('show');
            // $('#modalCommentCloseAlert').modal('show');
            return false;
        }
        else if (this.Register_Data.Regis_Status == "4" && (_s.Risk_Close_Reason === null || _s.Risk_Close_Reason === "") && value) {
            _s.CloseCommentFlag = value;
            this.alertflow = 'Please fill-in Reason/Detail(s)'
            $('#modalAlertdanger5').modal('show');
            // $('#modalCommentCloseOtherAlert').modal('show');
            return false;
        }


        if (this.Register_Data.Regis_Category === '' && value === true) {
            this.alertflow = 'Please select Risk Category'
            $('#modalAlertdanger5').modal('show');
        }

        else if (this.Tempdata?.rootCause.length === 0 && value === true) {
            this.alertflow = 'Please add Root Cause'
            $('#modalAlertdanger5').modal('show');
            chkasses = false;
        } else if (this.Tempdata?.impact.length === 0 && value === true) {
            this.alertflow = 'Please add Impact'
            $('#modalAlertdanger5').modal('show');
            chkasses = false;
        } else {
            chkasses = true
        }
        //console.log()
        chkreqroot = func_UPDATEDATA_II_rootCause(chkasses, value, _s, chkreqrootarr, chkreqroot);
        chkreqImpact = _s.func_UPDATEDATA_II_impact(chkasses, chkreqroot, value, _s, chkreqImpactarr, chkreqImpact);

        let data: RefactorAssessment = {
            statusSavenochk: statusSavenochk,
            chkasses: chkasses
        };
        function func_UPDATEDATA_II_rootCause(chkasses: any, value: any, _s: any, chkreqrootarr: any[], chkreqroot: any) {

            if ((_s.Tempdata?.rootCause.length !== 0 && chkasses) && value) {
                for (let itemrootCause of _s.Tempdata.rootCause) {
                    if (itemrootCause.rootCause_Mitigation_Type !== 'Take') {
                        chkreqroot = func_UPDATEDATA_III_chkrootCause_Mitigation(_s, chkreqrootarr, chkreqroot);
                    }
                    else if (chkreqroot) {
                        chkreqroot = true;
                    }
                }

            }

            function func_UPDATEDATA_III_chkrootCause_Mitigation(_s: any, chkreqrootarr: any[], chkreqroot: any) {
                if (_s.Tempdata.rootCause_Mitigation.length !== 0) {
                    func_UPDATEDATA_III_rootCause_itemrootCause_I1(_s, chkreqrootarr);
                    func_UPDATEDATA_III_rootCause_chkreqrootarr(chkreqrootarr);
                    chkreqroot = func_UPDATEDATA_III_rootCause_itemchkreqrootarr_I5(chkreqrootarr, chkreqroot);
                    function func_UPDATEDATA_III_rootCause_itemrootCause_I1(_s: any, chkreqrootarr: any[]) {
                        for (let itemrootCause_I1 of _s.Tempdata.rootCause_Mitigation) {
                            for (let itemrootCause_I2 of _s.Tempdata.rootCause_Mitigation) {
                                if ((itemrootCause_I1.rootCause_Id === itemrootCause_I2.rootCause_Id) && itemrootCause_I1.rootCause_Mitigation_Type !== 'Take') {
                                    chkreqrootarr.push({ id: itemrootCause_I1.rootCause_Id, value: true });
                                    break;
                                }
                                else if (itemrootCause_I1.rootCause_Mitigation_Type !== 'Take') {
                                    chkreqrootarr.push({ id: itemrootCause_I1.rootCause_Id, value: false });
                                }
                            }
                        }
                    }
                    function func_UPDATEDATA_III_rootCause_chkreqrootarr(chkreqrootarr: any[]) {
                        for (let i3 = 0; i3 < chkreqrootarr.length; i3++) {
                            if (i3 !== 0) {
                                if ((chkreqrootarr[i3].id === chkreqrootarr[i3 - 1].id)) {
                                    let temid = chkreqrootarr[i3].id;
                                    let tempval = chkreqrootarr[i3].value;

                                    for (let i4 = 0; i4 < i3; i4++) {
                                        if (chkreqrootarr[i4].id === temid) {
                                            chkreqrootarr[i4].id = temid;
                                            chkreqrootarr[i4].value = tempval;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    function func_UPDATEDATA_III_rootCause_itemchkreqrootarr_I5(chkreqrootarr_new: any[], chkreqroot_new: any) {
                        let chkreqroot = chkreqroot_new;
                        let chkreqrootarr = chkreqrootarr_new;
                        for (let itemchkreqrootarr_I5 of chkreqrootarr) {
                            if (itemchkreqrootarr_I5.value === false) {
                                chkreqroot = false;
                                break;
                            } else {
                                chkreqroot = true;
                            }
                        }
                        return chkreqroot;
                    }

                    function func_UPDATEDATA_III_rootCause_itemrootCause_I6(_s: any, chkreqroot_new: any) {
                        let chkreqroot = chkreqroot_new;

                        for (let itemrootCause_I6 of _s.Tempdata.rootCause) {
                            if ((itemrootCause_I6.rootCause_After_Mitigated === null || itemrootCause_I6.rootCause_After_Mitigated === '') && chkreqroot === true && itemrootCause_I6.rootCause_Mitigation_Type !== 'Take') {
                                this.alertflow = 'Please select Likelihood after Mitigated';
                                $('#modalAlertdanger5').modal('show');
                                chkreqroot = false;
                            }
                        }
                        return chkreqroot;
                    }

                    if (chkreqroot === false) {
                        _s.alertflow = 'Please add all Root Cause Mitigation Plan in Root Cause';
                        $('#modalAlertdanger5').modal('show');
                    }
                    chkreqroot = func_UPDATEDATA_III_rootCause_itemrootCause_I6(_s, chkreqroot);
                } else {
                    _s.alertflow = 'Please add all Root Cause Mitigation Plan in Root Cause';
                    $('#modalAlertdanger5').modal('show');
                    chkreqroot = false;
                }
                return chkreqroot;
            }
            return chkreqroot;
        }
        ({ chkreqroot, chkreqImpact, statusSavenochk } = this.func_UPDATEDATA_II_rootCause_valueFalse(value, _s, chkreqrootarr, chkreqroot, chkreqImpactarr, chkreqImpact, data));
        func_UPDATEDATA_II_valueFalse(chkreqroot, chkreqImpact, value, statusSavenochk, _s, this.Register_Data);
        function func_UPDATEDATA_II_valueFalse(chkreqroot: any, chkreqImpact: any, value: any, statusSavenochk: any, _s: any, Register_Data) {

            if ((chkreqroot === true && chkreqImpact === true) || value === false) {
                let statusflow;
                if (value === false) {
                                         statusflow = statusSavenochk;
                                   } else {
                    statusflow = '0';
                }
               if((_s.Tempdata.rootCause.length > 0) && (_s.Tempdata.impact.length > 0) )statusflow = '0';//ERR-114   
                let Likelyhood = Register_Data.Regis_Likelyhood;
                let Impact = Register_Data.Regis_Impact;
                if (_s.flagUpdatePrelim === true) {
                    if (_s.Tempdata.rootCause.length > 0) {
                        Likelyhood = Math.max(..._s.Tempdata.rootCause.map(o => o.rootCause_Likelihood));
                    }

                    if (_s.Tempdata.impact.length > 0) {
                        Impact = Math.max(..._s.Tempdata.impact.map(o => o.impact_Level));
                    }
                }

                _s.loading = true;
                let c = _s.CloseComment;
                let closeCommentOhther = _s.Risk_Close_Reason;
                let tmpAssignTo = null;

                ({ c, tmpAssignTo, statusflow } = _s.func_UPDATEDATA_III_valueFalse_chkCloseCommentFlagSave(c, tmpAssignTo, statusflow));
                let formData = {
                    SessionEmpID: "0001",
                    Module: "UPDATE",
                    body: {
                        Risk_Id: Register_Data.Regis_RiskID,
                        Risk_Name: Register_Data.Regis_Riskname,
                        Risk_Likelihood: Likelyhood,
                        Risk_Impact: Impact,
                        Risk_Status: Register_Data.Regis_Status,
                        Risk_Category: Register_Data.Regis_Category,
                        Risk_Objective_Parent: Register_Data.Regis_ObjectiveHead,
                        Risk_Objective: Register_Data.Regis_Objective,
                        Risk_Unit_KPI: Register_Data.Regis_KPI,
                        Risk_Context: Register_Data.Regis_Context,
                        Risk_Business_Unit_Abbreviation: Register_Data.Regis_BusinessName,
                        Risk_Business_Unit: Register_Data.Regis_BusinessUnit,
                        Risk_Modified_By: Register_Data.Regis_Modified_By,
                        Risk_Status_Workflow: statusflow,
                        Role: Register_Data.Regis_Role,
                        CloseComment: c,
                        TempRiskAssignTo: tmpAssignTo,
                        Risk_Previous_Likelihood: null,
                        Risk_Previous_Impact: null,
                        Risk_Close_Reason: closeCommentOhther,
                        isSaveEditHistory:SaveEditHistory
                    }
                };
                //console.log("SaveForm", formData);
                if(formData.body.Risk_Status == '3')
                {
                    formData.body.CloseComment = null;
                }

                _s.mserve.RegisterRisk(formData).then((response) => {
                    func_UPDATEDATA_III_valueFalse_RegisterRisk(response, _s, value);

                }).catch((e) => {
                    if (e.response) {
                        _s.authserviceToken.CheckTokenExpire(e.response.status);
                    } else {
                        //console.log(e)
                    }

                });

                function func_UPDATEDATA_III_valueFalse_RegisterRisk(response, _s: any, value: any) {
                    if (response.data.status) {
                        setTimeout(function () { _s.loading = false; }, 300);
                        _s.CloseCommentFlag = false;
                        _s.CloseCommentFlagSave = true;
                        if (value === true) {
                            sessionStorage.setItem("menubackto", "/Assessment");
                            _s.router.navigate(['/ReviewPage']);
                        }
                    } else {
                        _s.alertflow = response.data.errorMessage;
                        $('#modalAlertdanger5').modal('show');

                    }
                }
            }
        }

    }



    private func_UPDATEDATA_III_valueFalse_chkCloseCommentFlagSave(c: string, tmpAssignTo: any, statusflow: any) {
        if (this.CloseCommentFlagSave) {
            c = null;
        }
        if (this.TempRiskStatus != null && this.TempRiskAssignTo != null) {
            tmpAssignTo = this.TempRiskAssignTo;
            statusflow = this.TempRiskStatus;
        }
        return { c, tmpAssignTo, statusflow };
    }



    private func_UPDATEDATA_II_rootCause_valueFalse(value: any, _s: this, chkreqrootarr: any[], chkreqroot: any, chkreqImpactarr: any[], chkreqImpact: any, data: RefactorAssessment) {
        function refactorFunction(value, Tempdata) {
            let statusSavenochk = '23';
            let chkreqroot = false;
            let chkreqImpact = false;

            if (value) {
                if (Tempdata?.rootCause.length > 0 && Tempdata?.impact.length > 0) {
                    chkreqroot = true;
                    chkreqImpact = true;
                }

                if (chkreqroot && chkreqImpact) {
                    statusSavenochk = '0';
                }
            }


            return { chkreqroot, chkreqImpact, statusSavenochk };
        }

        return refactorFunction(value, this.Tempdata);
        // return { chkreqroot, chkreqImpact, statusSavenochk };

        function func_UPDATEDATA_III_itemimpact_I4(this: any, itemimpact_I4: any, chkreqImpact: boolean) {
            if (itemimpact_I4.impact_Mitigation_Type !== 'Take') {
                if (_s.Tempdata.impact_Mitigation.length !== 0) {
                    func_UPDATEDATA_III_rootCause_valueFalse_itemimpact_I(_s, chkreqImpactarr);
                    func_UPDATEDATA_III_impact_chkreqImpactarr(chkreqImpactarr);
                    chkreqImpact = func_UPDATEDATA_III_rootCause_valueFalse_itemchkreqImpactarr_I(chkreqImpactarr, chkreqImpact);
                    chkreqImpact = func_UPDATEDATA_III_rootCause_valueFalse_itemimpact_I3(_s, chkreqImpact);
                } else {
                    chkreqImpact = false;
                }
            } else if (chkreqImpact) {
                chkreqImpact = true;
            }
            return chkreqImpact;
        }

        function func_UPDATEDATA_III_impact_chkreqImpactarr(chkreqImpactarr: any[]) {
            for (let i = 0; i < chkreqImpactarr.length; i++) {
                if (i !== 0) {
                    if ((chkreqImpactarr[i].id === chkreqImpactarr[i - 1].id)) {
                        let temid = chkreqImpactarr[i].id;
                        let tempval = chkreqImpactarr[i].value;

                        for (let ii = 0; ii < i; ii++) {
                            if (chkreqImpactarr[ii].id === temid) {
                                chkreqImpactarr[ii].id = temid;
                                chkreqImpactarr[ii].value = tempval;
                            }
                        }

                    }
                }
            }
        }


        function func_UPDATEDATA_III_itemrootCauseI4(this: any, itemrootCauseI4: any, chkreqroot: boolean) {
            if (itemrootCauseI4.rootCause_Mitigation_Type !== 'Take') {
                if (_s.Tempdata.rootCause_Mitigation.length !== 0) {
                    func_UPDATEDATA_III_rootCause_valueFalse_itemrootCauseI(_s, chkreqrootarr);
                    func_UPDATEDATA_III_rootCause_valueFalse_chkreqrootarr(chkreqrootarr);
                    chkreqroot = func_UPDATEDATA_III_rootCause_valueFalse_itemchkreqrootarr(chkreqrootarr, chkreqroot);
                    chkreqroot = func_UPDATEDATA_III_rootCause_valueFalse_itemrootCauseI3(_s, chkreqroot);
                } else {
                    chkreqroot = false;
                }
            } else if (chkreqroot) {
                chkreqroot = true;
            }
            return chkreqroot;





        }
        function func_UPDATEDATA_III_rootCause_valueFalse_itemrootCauseI3(_s: any, chkreqroot: any) {
            for (let itemrootCauseI3 of _s.Tempdata.rootCause) {
                if ((itemrootCauseI3.rootCause_After_Mitigated === null || itemrootCauseI3.rootCause_After_Mitigated === '') && chkreqroot === true && itemrootCauseI3.rootCause_Mitigation_Type !== 'Take') {
                    chkreqroot = false;
                }
            }
            return chkreqroot;
        }
        function func_UPDATEDATA_III_rootCause_valueFalse_itemchkreqrootarr(chkreqrootarr: any[], chkreqroot: any) {
            let chkreqrootarr_new = chkreqrootarr;
            let chkreqroot_new = chkreqroot;
            for (let itemchkreqrootarr of chkreqrootarr_new) {
                if (itemchkreqrootarr.value === false) {
                    chkreqroot_new = false;
                    break;
                } else {
                    chkreqroot_new = true;
                }
            }
            return chkreqroot_new;
        }
        function func_UPDATEDATA_III_rootCause_valueFalse_chkreqrootarr(chkreqrootarr: any[]) {
            for (let i = 0; i < chkreqrootarr.length; i++) {
                if (i !== 0) {
                    if ((chkreqrootarr[i].id === chkreqrootarr[i - 1].id)) {
                        let temid = chkreqrootarr[i].id;
                        let tempval = chkreqrootarr[i].value;

                        for (let ii = 0; ii < i; ii++) {
                            if (chkreqrootarr[ii].id === temid) {
                                chkreqrootarr[ii].id = temid;
                                chkreqrootarr[ii].value = tempval;
                            }
                        }

                    }
                }
            }
        }
        function func_UPDATEDATA_III_rootCause_valueFalse_itemrootCauseI(_s: any, chkreqrootarr: any[]) {
            for (let itemrootCauseI of _s.Tempdata.rootCause) {
                for (let item_rootCause_Mitigation of _s.Tempdata.rootCause_Mitigation) {
                    if ((itemrootCauseI.rootCause_Id === item_rootCause_Mitigation.rootCause_Id) && itemrootCauseI.rootCause_Mitigation_Type !== 'Take') {
                        chkreqrootarr.push({ id: itemrootCauseI.rootCause_Id, value: true });
                        break;
                    }
                    else if (itemrootCauseI.rootCause_Mitigation_Type !== 'Take') {
                        chkreqrootarr.push({ id: itemrootCauseI.rootCause_Id, value: false });
                    }
                }
            }
        }
        function func_UPDATEDATA_III_rootCause_valueFalse_itemimpact_I3(_s: any, chkreqImpact: any) {
            for (let itemimpact_I3 of _s.Tempdata.impact) {
                if ((itemimpact_I3.impact_After_Mitigated === null || itemimpact_I3.impact_After_Mitigated === '') && chkreqImpact === true && itemimpact_I3.impact_Mitigation_Type !== 'Take') {
                    chkreqImpact = false;
                }
            }
            return chkreqImpact;
        }
        function func_UPDATEDATA_III_rootCause_valueFalse_itemimpact_I(_s: any, chkreqImpactarr: any[]) {
            for (let itemimpact_I of _s.Tempdata.impact) {
                for (let itemimpact_I2 of _s.Tempdata.impact_Mitigation) {
                    if ((itemimpact_I.impact_Id.includes(itemimpact_I2.impact_Id)) && itemimpact_I.impact_Mitigation_Type !== 'Take') {
                        chkreqImpactarr.push({ id: itemimpact_I.impact_Id, value: true });
                        break;
                    }
                    else if (itemimpact_I.impact_Mitigation_Type !== 'Take') {
                        chkreqImpactarr.push({ id: itemimpact_I.impact_Id, value: false });
                    }
                }
            }
        }

        function func_UPDATEDATA_III_rootCause_valueFalse_itemchkreqImpactarr_I(chkreqImpactarr: any[], chkreqImpact: any) {
            let chkreqImpact_new = chkreqImpact;
            for (let itemchkreqImpactarr_I of chkreqImpactarr) {
                if (itemchkreqImpactarr_I.value === false) {
                    chkreqImpact_new = false;
                    break;
                } else {
                    chkreqImpact_new = true;
                }
            }
            return chkreqImpact_new;
        }


    }






    private func_UPDATEDATA_II_impact(chkasses: any, chkreqroot: any, value: any, _s: this, chkreqImpactarr: any[], chkreqImpact: any) {
        if ((this.Tempdata?.impact.length !== 0 && chkasses && chkreqroot) && value) {
            for (let itemimpact_I7 of this.Tempdata.impact) {
                chkreqImpact = this.func_UPDATEDATA_III_itemimpact_I7(itemimpact_I7, _s, chkreqImpactarr, chkreqImpact);
            }
        }
        return chkreqImpact;
    }

    private func_UPDATEDATA_III_itemimpact_I7(itemimpact_I7: any, _s: this, chkreqImpactarr: any[], chkreqImpact: any) {
        if (itemimpact_I7.impact_Mitigation_Type !== 'Take') {
            if (_s.Tempdata.impact_Mitigation.length !== 0) {
                this.func_UPDATEDATA_III_impact_itemimpact_I8(_s, chkreqImpactarr);
                this.func_UPDATEDATA_III_impact_chkreqImpactarr(chkreqImpactarr);
                chkreqImpact = this.func_UPDATEDATA_III_impact_itemchkreqImpactarr(chkreqImpactarr, chkreqImpact);
                if (!chkreqImpact) {
                    _s.alertflow = 'Please add all Impact Mitigation Plan in Impact';
                    $('#modalAlertdanger5').modal('show');
                }
                chkreqImpact = this.func_UPDATEDATA_III_impact_itemimpact(_s, chkreqImpact);
            } else {
                _s.alertflow = 'Please add all Impact Mitigation Plan in Impact';
                $('#modalAlertdanger5').modal('show');
                chkreqImpact = false;
            }
        }
        else if (chkreqImpact) {
            chkreqImpact = true;
        }
        return chkreqImpact;
    }
    func_UPDATEDATA_III_impact_chkreqImpactarr(chkreqImpactarr: any[]) {
        for (let i = 0; i < chkreqImpactarr.length; i++) {
            if (i !== 0) {
                if ((chkreqImpactarr[i].id === chkreqImpactarr[i - 1].id)) {
                    let temid = chkreqImpactarr[i].id;
                    let tempval = chkreqImpactarr[i].value;

                    for (let ii = 0; ii < i; ii++) {
                        if (chkreqImpactarr[ii].id === temid) {
                            chkreqImpactarr[ii].id = temid;
                            chkreqImpactarr[ii].value = tempval;
                        }
                    }

                }
            }
        }
    }


    private func_UPDATEDATA_III_impact_itemimpact_I8(_s: this, chkreqImpactarr: any[]) {
        for (let itemimpact_I8 of _s.Tempdata.impact) {
            for (let itemimpact_Mitigation_I9 of _s.Tempdata.impact_Mitigation) {
                if ((itemimpact_I8.impact_Id.includes(itemimpact_Mitigation_I9.impact_Id)) && itemimpact_Mitigation_I9.impact_Mitigation_Type !== 'Take') {
                    chkreqImpactarr.push({ id: itemimpact_I8.impact_Id, value: true });
                    break;
                }
                else if (itemimpact_I8.impact_Mitigation_Type !== 'Take') {
                    chkreqImpactarr.push({ id: itemimpact_I8.impact_Id, value: false });
                }
            }
        }




    }












    private func_UPDATEDATA_III_impact_itemchkreqImpactarr(chkreqImpactarr_new: any[], chkreqImpact_new: any) {

        let chkreqImpactarr = chkreqImpactarr_new;
        let chkreqImpact = chkreqImpact_new;

        for (let itemchkreqImpactarr of chkreqImpactarr) {
            if (itemchkreqImpactarr.value === false) {
                chkreqImpact = false;
                break;
            } else {
                chkreqImpact = true;
            }
        }
        return chkreqImpact;
    }

    private func_UPDATEDATA_III_impact_itemimpact(_s: this, chkreqImpact: any) {
        for (let itemimpact of _s.Tempdata.impact) {
            if ((itemimpact.impact_After_Mitigated === null || itemimpact.impact_After_Mitigated === '') && chkreqImpact === true && itemimpact.impact_Mitigation_Type !== 'Take') {
                this.alertflow = 'Please select Impact Level after Mitigated';
                $('#modalAlertdanger5').modal('show');
                chkreqImpact = false;
            }
        }
        return chkreqImpact;
    }







    //END func_UPDATEDATA
    func_GETMASTER_Likelihood() {
        let formData = {
            Module: "LikelihoodLevelDD",
            TextSearch7: this.Register_Data.tempQuarter
        }
        this.mserve.Master_DDL(formData).then((response) => {
            if (response.data.status) {
                this.likelyhoodLevel = response.data.body
                this.likelyhoodLevelAfter = response.data.body
            } else {
            }
        }).catch((e) => {
            if (e.response) { this.authserviceToken.CheckTokenExpire(e.response.status) } else { }
        });
    }

    func_GETMASTER_ImpactCategory() {
        let formData = {
            Module: "ImpactCateDD",
            TextSearch7: this.Register_Data.tempQuarter
        }
        this.mserve.Master_DDL(formData).then((response) => {

            if (response.data.status) {
                this.ImpactCategory = response.data.body
            } else {

            }
        }).catch((e) => {
            if (e.response) { this.authserviceToken.CheckTokenExpire(e.response.status) } else { }
        });
    }

    func_GETMASTER_RiskCategory() {
        let chkDDper;
        chkDDper = (sessionStorage.Role === 'ERM') ? '' : '1';

        let formData = {
            Module: "RiskCateDD",
            TextSearch1: chkDDper,
            TextSearch7: this.Register_Data.tempQuarter
        }
        this.mserve.Master_DDL(formData).then((response) => {

            if (response.data.status) {
                this.RiskCategory = response.data.body
            } else {
            }
        }).catch((e) => {
            if (e.response) { this.authserviceToken.CheckTokenExpire(e.response.status) } else { }
        });
    }

    func_GETMASTER_ImpactLevel() {
        let formData = {
            Module: "ImpactLevelDD",
            TextSearch1: this.Register_Data.Regis_BusinessUnit,
            TextSearch7: this.Register_Data.tempQuarter
        }
        this.mserve.Master_DDL(formData).then((response) => {

            if (response.data.status) {
                this.ImpactLevel = response.data.body
                this.ImpactLevelAfter = response.data.body
            } else {
            }
        }).catch((e) => {
            if (e.response) { this.authserviceToken.CheckTokenExpire(e.response.status) } else { }
        });
    }

    func_GETMASTER_ImpactLevel_v2(ImpactCateID) {
        let formData = {
            Module: "ImpactLevelDDv2",
            TextSearch1: this.Register_Data.Regis_BusinessUnit,
            TextSearch2: ImpactCateID,
            TextSearch7: this.Register_Data.tempQuarter
        }
        this.mserve.Master_DDL(formData).then((response) => {
            if (response.data.status) {
                this.ImpactLevel = response.data.body
                this.ImpactLevelAfter = response.data.body
            } else {
            }
        }).catch((e) => {
            if (e.response) { this.authserviceToken.CheckTokenExpire(e.response.status) } else { }
        });
    }

    func_GETMASTER_Financial() {
        let formData = {
            Module: "ImpactType",
        }

        this.mserve.Master_DDL(formData).then((response) => {

            if (response.data.status) {
                this.Financial = response.data.body;
                this.func_GETMASTER_Financial_II();

            } else {

            }
        }).catch((e) => {
            if (e.response) { this.authserviceToken.CheckTokenExpire(e.response.status) } else { }
        });
    }

    private func_GETMASTER_Financial_II() {
        if (this.Tempdata.impact.length !== 0) {
            this.Assert_ImpactTable = [];
            let show_Category = [];
            let maxImpct = Math.max(...this.Tempdata.impact.map(o => o.impact_Level));

            for (let itemimpact_I2 of this.Tempdata.impact) {
                let isMaxImpct = 'N';
                if (itemimpact_I2.impact_Level == maxImpct) {
                    isMaxImpct = 'Y';
                }
                //func_GETMASTER_Financial_III
                this.func_GETMASTER_Financial_III(itemimpact_I2, show_Category, isMaxImpct);

            }

            this.Assert_ImpactTable = show_Category;
        } else {
            this.Assert_ImpactTable = [];
        }
    }

    private func_GETMASTER_Financial_III(itemimpact_I2: any, show_Category: any[], isMaxImpct: string) {
        if (itemimpact_I2.impact_NPT_EMV !== null) {
            for (let itemFinancial_I of this.Financial) {
                if (itemimpact_I2.impact_NPT_EMV === itemFinancial_I.value) {
                    show_Category.push({
                        id: itemimpact_I2.impact_Id,
                        title: itemFinancial_I.text,
                        highlight: isMaxImpct
                    });
                    break;
                }
            }
        }
        else {
            show_Category.push({
                id: itemimpact_I2.impact_Id,
                title: itemimpact_I2.impact_Description,
                highlight: isMaxImpct
            });
        }
    }

    func_GETMASTER_CorptargetHead() {
        let formData = {
            Module: "CorpTargetHeadDD",
            TextSearch1: this.Register_Data.tempYear
        }
        this.mserve.Master_DDL(formData).then((response) => {

            if (response.data.status) {
                this.CorpTargetHead = response.data.body;
                if (this.Register_Data.Regis_ObjectiveHead !== '') {
                    this.func_selectObjective(this.Register_Data.Regis_ObjectiveHead, 'getdata')
                }
            } else {

            }
        }).catch((e) => {
            if (e.response) { this.authserviceToken.CheckTokenExpire(e.response.status) } else { }
        });
    }
    clearReason() {
        // if (this.Register_Data.Regis_Status != "4") {
        //     this.Risk_Close_Reason = null
        // }
        this.Risk_Close_Reason = null;
        this.CloseComment = null;
    }
    func_GETMASTER_Instruction() {
        const _s = this;

        let formData = {
            SessionEmpID: 1,
            Module: "getlist",
            body: {
                InstructionID: "",
                Area: "1",
                InstructionName: "Test0002",
            }
        }
        _s.mserve.GetInstruction(formData).then((response) => {
            if (response.data.status) {

                this.Instruction = response.data.body;
            } else {

            }
        }).catch((e) => {
            if (e.response) { this.authserviceToken.CheckTokenExpire(e.response.status) } else { }
        });
    }

    func_GetImpactRiskMap() {
        //console.log("func_GetImpactRiskMap");
        let formData = {
            BusinessCode: this.Register_Data.Regis_BusinessUnit,
            QuarterID: this.Register_Data.tempQuarter
        }
        this.Mapserve.getImpact2(formData).then((response) => {
            if (response.data.status) {

                this.Financial_NI_NPV_EM = response.data.body;
                sessionStorage.setItem("Financial_NI_NPV_EM", JSON.stringify(response.data.body));
            } else {
            }
        }).catch((e) => { window.alert('timeout') })//this.authserviceToken.CheckTokenExpire(e.response.status)window.alert('timeout')//this.authserviceToken.CheckTokenExpire(e.response.status) });
    }

    func_GetDDL_BusinessName() {
        let formData = {
            Module: 'DepartmentDD'
        }
        this.mserve.Master_DDL(formData).then((response) => {
            if (response.data.status) {

                this.options = response.data.body
                this.filteredOptions = this.myControl.valueChanges.pipe(
                    startWith(''),
                    map(value => this._filter(value))
                );

                this.filteredOptions2 = this.myControl2.valueChanges.pipe(
                    startWith(''),
                    map(value => this._filter(value))
                );
            } else {

            }
        }).catch((e) => {
            if (e.response) { this.authserviceToken.CheckTokenExpire(e.response.status) } else { }
        });
    }

    func_save_RootCause() {
        const _s = this;
        let formData;

        let Root_RootMitigationType = (this.Register_Rootcause.Root_RootMitigationType !== 'Take' && this.temptable.length === 0 && (this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Name !== '' || this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Owner !== ''));
        let statusbtn = (this.statusbtn === true);

        if (this.Register_Rootcause.Root_RootCause === '') {
            this.alertflow = 'Please fill-in Root Cause'
            $('#modalAlertdanger5').modal('show');
        } else if (this.Register_Rootcause.Root_RootLikelyhood === '' || this.Register_Rootcause.Root_RootLikelyhood === null) {
            this.alertflow = 'Please select Likelihood'
            $('#modalAlertdanger5').modal('show');
        } else if (this.Register_Rootcause.Root_RootMitigationType === '' || this.Register_Rootcause.Root_RootMitigationType === null) {
            this.alertflow = 'Please select Mitigation type'
            $('#modalAlertdanger5').modal('show');
        }
        else if ((Root_RootMitigationType && statusbtn) || statusbtn) {
            this.alertflow = 'Please press the save button in Root Cause Mitigation Plan every time after adding or editing an item before saving Root Cause'
            $('#modalAlertdanger5').modal('show');
        }
        else if (this.Register_Rootcause.Root_RootMitigationType !== 'Take' && this.temptable.length === 0) {
            this.alertflow = 'Please add Root Cause Mitigation Plan'
            $('#modalAlertdanger5').modal('show');
        }
        else if (this.Register_Rootcause.Root_RootMitigationType !== 'Take' && this.Register_Rootcause.Root_AfterMitigated === '') {
            this.alertflow = 'Please select Likelihood after Mitigated'
            $('#modalAlertdanger5').modal('show');

        }
        else {
            //func_save_RootCause_II
            formData = this.func_save_RootCause_II(formData);

        }
    }

    private func_save_RootCause_II(formData: any) {
        this.loading2 = true;
        this.loading = true;
        if (this.Register_Rootcause.Root_RootMitigationType === 'Take') {
            formData = {
                SessionEmpID: "0001",
                Module: this.tempmodule,
                body: {
                    Risk_Id: this.Register_Data.Regis_RiskID,
                    RootCause_Id: this.Register_Rootcause.RootCause_Id,
                    RootCause_Category: this.Register_Rootcause.Root_RootCause,
                    RootCause_Likelihood: this.Register_Rootcause.Root_RootLikelyhood,
                    RootCause_Mitigation_Type: this.Register_Rootcause.Root_RootMitigationType,
                    RootCause_After_Mitigated: this.Register_Rootcause.Root_AfterMitigated,
                    RootCause_KRI_Name: this.Register_Rootcause.Root_KRIName,
                    RootCause_KRI_Threshold_Green: this.Register_Rootcause.Root_KRIThreshold_Green,
                    RootCause_KRI_Threshold_Red: this.Register_Rootcause.Root_KRIThreshold_Red,
                    RootCause_KRI_Status: this.Register_Rootcause.Root_KRIStatus,
                    RootCause_KRI_Justification: this.Register_Rootcause.Root_Justification,
                    RootCause_Description: this.Register_Rootcause.RootCause_Description,
                    Role: this.Register_Data.Regis_Role
                }
            };
        } else {
            formData = {
                SessionEmpID: "0001",
                Module: this.tempmodule,
                body: {
                    Risk_Id: this.Register_Data.Regis_RiskID,
                    RootCause_Id: this.Register_Rootcause.RootCause_Id,
                    RootCause_Category: this.Register_Rootcause.Root_RootCause,
                    RootCause_Likelihood: this.Register_Rootcause.Root_RootLikelyhood,
                    RootCause_Mitigation_Type: this.Register_Rootcause.Root_RootMitigationType,
                    RootCause_After_Mitigated: this.Register_Rootcause.Root_AfterMitigated,
                    RootCause_KRI_Name: this.Register_Rootcause.Root_KRIName,
                    RootCause_KRI_Threshold_Green: this.Register_Rootcause.Root_KRIThreshold_Green,
                    RootCause_KRI_Threshold_Red: this.Register_Rootcause.Root_KRIThreshold_Red,
                    RootCause_KRI_Status: this.Register_Rootcause.Root_KRIStatus,
                    RootCause_KRI_Justification: this.Register_Rootcause.Root_Justification,
                    RootCause_Description: this.Register_Rootcause.RootCause_Description,
                    RootCause_Mitigation: [],
                    Role: this.Register_Data.Regis_Role
                }
            };
            for (let item_temptable of this.temptable) {
                formData.body.RootCause_Mitigation.push({
                    Risk_Id: this.Register_Data.Regis_RiskID,
                    RootCause_Id: this.Register_Rootcause.RootCause_Id,
                    Mitigation_Id: "",
                    RootCause_Mitigation_Name: item_temptable.plan,
                    RootCause_Mitigation_Owner: item_temptable.owner,
                    RootCause_Mitigation_DueDate: item_temptable.Duedate,
                    RootCause_Mitigation_Progress: item_temptable.Progess,
                    RootCause_Mitigation_Justification: item_temptable.justification
                });
            }
        }

        this.mserve.RegisterRisk_Roocause(formData).then((response) => {

            if (response.data.status) {
                this.loading2 = false;
                $('#modalrootcasue').modal('hide');
                this.func_clearData();
                this.func_GETDATA('NoGetmaster');
                this.func_PlotRiskMap();
            } else {
                this.loading2 = false;
                this.loading = false;
                this.alertflow = response.data.errorMessage;
                $('#modalAlertdanger5').modal('show');
            }
        }).catch((e) => {
            this.authserviceToken.CheckTokenExpire(e.response.status);
        });
        return formData;
    }

    func_save_Impact() {
        const _s = this;
        //func_save_Impact_II_Impact_Migation_Type
        let Impact_Migation_Type = this.func_save_Impact_II_Impact_Migation_Type();
        let statusbtn = (this.statusbtn === true);
        //console.log('save', this.Impact_After_Total_Amont)
        if (this.Register_Impact.Impact_Category === '') {
            this.alertflow = 'Please select Impact Category'
            $('#modalAlertdanger5').modal('show');
        } else if (this.Register_Impact.Impact_Category === '1' && (this.Register_Impact.Impact_NPTEMV === '' || this.Register_Impact.Impact_Total_Amont === '' || this.Register_Impact.Impact_Total_Amont === null)) {
            this.alertflow = 'Please fill-in all Financial information'
            $('#modalAlertdanger5').modal('show');
        } else if (this.Register_Impact.Impact_Category !== '1' && this.Register_Impact.Impact_Description === '') {
            this.alertflow = 'Please fill-in Impact Description'
            $('#modalAlertdanger5').modal('show');
        } else if (this.Register_Impact.Impact_Level === '') {
            this.alertflow = 'Please select Impact Level'
            $('#modalAlertdanger5').modal('show');
        } else if (this.Register_Impact.Impact_Migation_Type === '') {
            this.alertflow = 'Please select Mitigation Type'
            $('#modalAlertdanger5').modal('show');
        }
        else if ((Impact_Migation_Type && statusbtn) || statusbtn) {
            this.alertflow = 'Please press the save button in Impact Mitigation Plan every time after adding or editing an item before saving Impact'
            $('#modalAlertdanger5').modal('show');
        }
        else if (this.Register_Impact.Impact_Migation_Type !== 'Take' && this.temptableImpact.length === 0) {
            this.alertflow = 'Please add Impact Mitigation Plan'
            $('#modalAlertdanger5').modal('show');
        }
        else if (this.Register_Impact.Impact_Migation_Type !== 'Take' && this.Register_Impact.Impact_After_Mitigated === '') {
            this.alertflow = 'Please select Impact Level after Mitigated'
            $('#modalAlertdanger5').modal('show');
        }
        else if (parseInt(this.Impact_After_Total_Amont) > parseInt(this.Register_Impact.Impact_Total_Amont)) {
            //console.log('save', this.Register_Impact.Impact_Total_Amont)
            this.alertflow = 'Impact Level Amount must be greater than Impact Level after Mitigated Amount'
            $('#modalAlertdanger5').modal('show');
        }
        // else if (this.Register_Impact.Impact_Category === '1' && (this.Impact_After_Total_Amont == '' || this.Impact_After_Total_Amont == null)) {
        //     this.alertflow = 'Please Select Impact Level after Mitigated Amount'
        //     $('#modalAlertdanger5').modal('show');
        // }

        else {
            this.func_save_Impact_II();
        }
    }

    private func_save_Impact_II_Impact_Migation_Type() {
        return this.Register_Impact.Impact_Migation_Type !== 'Take' && this.temptableImpact.length === 0 && (this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Name !== '' || this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Owner !== '');
    }

    private func_save_Impact_II() {
        this.loading2 = true;
        this.loading = true;
        let formData;
        if (this.Register_Impact.Impact_Migation_Type === 'Take') {
            formData = {
                SessionEmpID: "0001",
                Module: this.tempmodule,
                body: {
                    Risk_Id: this.Register_Data.Regis_RiskID,
                    Impact_Id: this.Register_Impact.Impact_Id,
                    Impact_Category: this.Register_Impact.Impact_Category,
                    Impact_NPT_EMV: this.Register_Impact.Impact_NPTEMV,
                    Impact_Total_Amont: this.Register_Impact.Impact_Total_Amont,
                    Impact_Description: this.Register_Impact.Impact_Description,
                    Impact_Level: this.Register_Impact.Impact_Level,
                    Impact_Rating: "",
                    Impact_Mitigation_Type: this.Register_Impact.Impact_Migation_Type,
                    Impact_After_Mitigated: this.Register_Impact.Impact_After_Mitigated,
                    Role: this.Register_Data.Regis_Role,
                    Impact_After_Total_Amont: this.Impact_After_Total_Amont
                }
            };
        } else {
            formData = {
                SessionEmpID: "0001",
                Module: this.tempmodule,
                body: {
                    Risk_Id: this.Register_Data.Regis_RiskID,
                    Impact_Id: this.Register_Impact.Impact_Id,
                    Impact_Category: this.Register_Impact.Impact_Category,
                    Impact_NPT_EMV: this.Register_Impact.Impact_NPTEMV,
                    Impact_Total_Amont: this.Register_Impact.Impact_Total_Amont,
                    Impact_Description: this.Register_Impact.Impact_Description,
                    Impact_Level: this.Register_Impact.Impact_Level,
                    Impact_Rating: "",
                    Impact_Mitigation_Type: this.Register_Impact.Impact_Migation_Type,
                    Impact_After_Mitigated: this.Register_Impact.Impact_After_Mitigated,
                    Impact_Mitigation: [],
                    Role: this.Register_Data.Regis_Role,
                    Impact_After_Total_Amont: this.Impact_After_Total_Amont
                }
            };
            for (let itemtemptableImpact of this.temptableImpact) {
                formData.body.Impact_Mitigation.push({
                    Risk_Id: this.Register_Data.Regis_RiskID,
                    Impact_Id: this.Register_Impact.Impact_Id,
                    Impact_Mitigation_Id: "",
                    Impact_Mitigation_Name: itemtemptableImpact.mitigation,
                    Impact_Mitigation_Owner: itemtemptableImpact.owner,
                    Impact_Mitigation_DueDate: itemtemptableImpact.Duedate,
                    Impact_Mitigation_Progress: itemtemptableImpact.Progess,
                    Impact_Mitigation_Justification: itemtemptableImpact.justification,
                    Impact_After_Total_Amont: this.Impact_After_Total_Amont
                });
            }
        }
        
        this.mserve.RegisterRisk_Impact(formData).then((response) => {
            if (response.data.status) {
                this.loading2 = false;
                $('#modalimpact').modal('hide');
                this.func_clearData();
                this.func_GETDATA('NoGetmaster');
                this.func_PlotRiskMap();
            } else {
                this.loading2 = false;
                this.loading = false;
                this.alertflow = response.data.errorMessage;
                $('#modalAlertdanger5').modal('show');
            }
        }).catch((e) => {
            this.authserviceToken.CheckTokenExpire(e.response.status);
        });
    }

    selectedRaged(value) {
        let slider;
        let output
        let DemoRootProgress;
        if (value !== 'new') {
            DemoRootProgress = document.getElementById("DemoRootProgress");
            slider = document.getElementById("myRangeroot");
            output = document.getElementById("root");
            output.innerHTML = slider.value;
            this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Progress = slider.value
            DemoRootProgress.value = slider.value;

            slider.oninput = function () {
                output.innerHTML = this.value;
            }
        } else {
            slider = document.getElementById("myRangeroot");
            output = document.getElementById("root");
            if (slider !== null && output !== null) {
                output.innerHTML = 0;
                this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Progress = 0

                slider.value = 0
                slider.oninput = function () {
                    output.innerHTML = this.value;
                }
            }
        }

    }
    selectedRagedInput() {
        this.displayprogress = false;
        let RootProgress;
        let slider;

        RootProgress = document.getElementById("DemoRootProgress");
        if (parseInt(RootProgress.value) >= 0 && parseInt(RootProgress.value) <= 100) {
            slider = document.getElementById("myRangeroot");
            slider.value = RootProgress.value
            this.Edit_Rootcause.RootProgress = parseInt(RootProgress.value, 10);
            this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Progress = RootProgress.value;
        }
        else {
            if (RootProgress.value == '' || RootProgress.value == null) {
                return false;
            }
            RootProgress.value = "";
            this.alertflow = 'Mitigation Progress must be between 0 and 100'
            $('#modalAlertdanger5').modal('show');
            return false
        }
    }
    selectedRagedImpactInput() {
        this.displayprogress = false;
        let RootProgress;
        let slider;

        RootProgress = document.getElementById("DemoImpactProgress");
        if (parseInt(RootProgress.value) >= 0 && parseInt(RootProgress.value) <= 100) {
            slider = document.getElementById("myRangeImpact");
            slider.value = RootProgress.value
            this.Edit_Impact.ImpactProgress = parseInt(RootProgress.value, 10);
            this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Progress = RootProgress.value;
        }
        else {
            if (RootProgress.value == '' || RootProgress.value == null) {
                return false;
            }
            RootProgress.value = "";
            this.alertflow = 'Mitigation Progress must be between 0 and 100'
            $('#modalAlertdanger5').modal('show');
            return false
        }
    }

    selectedEditRagedInput() {
        this.displayprogress = false;
        let RootProgress;
        let slider;

        RootProgress = document.getElementById("RootProgress");
        if (parseInt(RootProgress.value) >= 0 && parseInt(RootProgress.value) <= 100) {
            slider = document.getElementById("myRangerootedit");
            slider.value = RootProgress.value
            this.Edit_Rootcause.RootProgress = parseInt(RootProgress.value, 10);
        }
        else {
            if (RootProgress.value == '' || RootProgress.value == null) {
                return false;
            }
            RootProgress.value = "";
            this.alertflow = 'Mitigation Progress must be between 0 and 100'
            $('#modalAlertdanger5').modal('show');
            return false
        }
    }
    selectedEditRagedImpactInput() {
        this.displayprogress = false;
        let slider;
        let output

        output = document.getElementById("ImpactProgress");
        if (parseInt(output.value) >= 0 && parseInt(output.value) <= 100) {
            slider = document.getElementById("myRangeImpactedit");
            slider.value = output.value;
            this.Edit_Impact.ImpactProgress = parseInt(output.value, 10);
        }
        else {
            if (output.value == '' || output.value == null) {
                return false;
            }
            output.value = "";
            this.alertflow = 'Mitigation Progress must be between 0 and 100'
            $('#modalAlertdanger5').modal('show');
            return false
        }
    }

    selectedEditRaged() {
        this.displayprogress = false;
        let slider;
        let output
        slider = document.getElementById("myRangerootedit");
        output = document.getElementById("rootedit");
        output.innerHTML = slider.value;
        this.Edit_Rootcause.RootProgress = slider.value

        slider.oninput = function () {
            output.innerHTML = this.value;
        }
    }
    selectedRagedImpact(value) {
        let slider;
        let output
        let DemoImpactProgress
        if (value !== 'new') {
            DemoImpactProgress = document.getElementById("DemoImpactProgress");
            slider = document.getElementById("myRangeImpact");
            output = document.getElementById("demoImpact");
            output.innerHTML = slider.value;
            this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Progress = slider.value

            DemoImpactProgress.value = slider.value;

            slider.oninput = function () {
                output.innerHTML = this.value;
            }
        } else {
            slider = document.getElementById("myRangeImpact");
            output = document.getElementById("demoImpact");
            if (slider !== null && output !== null) {
                output.innerHTML = 0
                this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Progress = 0

                slider.value = 0
                slider.oninput = function () {
                    output.innerHTML = this.value;
                }
            }
        }

    }
    selectedEditRagedImpact(value) {
        this.displayprogress = false;
        let slider;
        let output
        slider = document.getElementById("myRangeImpactedit");
        output = document.getElementById("demoImpactedit");
        output.innerHTML = slider.value;
        this.Edit_Impact.ImpactProgress = slider.value

        slider.oninput = function () {
            output.innerHTML = this.value;
        }

    }
    selectedradio(value) {
        if (value == 0 || value == 'green') {
            let element = document.getElementById("rdo1");
            element.classList.add("selected1");
            let element2 = document.getElementById("rdo2");
            element2.classList.remove("selected2");
            let element3 = document.getElementById("rdo3");
            element3.classList.remove("selected3");
        } else if (value == 1 || value == 'yellow') {
            let element = document.getElementById("rdo1");
            element.classList.remove("selected1");
            let element2 = document.getElementById("rdo2");
            element2.classList.add("selected2");
            let element3 = document.getElementById("rdo3");
            element3.classList.remove("selected3");
        } else if (value == 2 || value == 'red') {
            let element = document.getElementById("rdo1");
            element.classList.remove("selected1");
            let element2 = document.getElementById("rdo2");
            element2.classList.remove("selected2");
            let element3 = document.getElementById("rdo3");
            element3.classList.add("selected3");
        } else {
            let element = document.getElementById("rdo1");
            element.classList.remove("selected1");
            let element2 = document.getElementById("rdo2");
            element2.classList.remove("selected2");
            let element3 = document.getElementById("rdo3");
            element3.classList.remove("selected3");
        }
    }
    func_back() {
        this.func_UPDATEDATA(false); //ERR-114
        this.router.navigate(['/Approval', '1']);
    }
    func_selectObjective(value, status) {
        let tempcodevalue;
        for (let itemCorpTargetHead of this.CorpTargetHead) {
            if (value == itemCorpTargetHead.value) {
                tempcodevalue = itemCorpTargetHead.text1;
                break;
            }
        }

        let formData = {
            Module: "CorpTargetItemdDD",
            TextSearch1: tempcodevalue
        }
        this.mserve.Master_DDL(formData).then((response) => {
            if (response.data.status) {
                this.CorpTargetItem = response.data.body
                if (status !== 'getdata') {
                    this.Register_Data.Regis_Objective = ''
                }
            } else {
            }
        }).catch((e) => {
            if (e.response) { this.authserviceToken.CheckTokenExpire(e.response.status) } else { }
        });
    }
    myFunction() {
        this.valueadd = true;
        for (let itemtemptableImpact of this.temptableImpact) {
            itemtemptableImpact.display = true
            if (typeof itemtemptableImpact.Duedate !== 'string') {
                itemtemptableImpact.Duedate = this.func_formatdate(itemtemptableImpact.Duedate)
            }
        }
        for (let itemtemptable of this.temptable) {
            itemtemptable.display = true
            if (typeof itemtemptable.Duedate !== 'string') {
                itemtemptable.Duedate = this.func_formatdate(itemtemptable.Duedate)
            }
        }
    }
    myFunctionoff() {
        this.valueadd = false;

        this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Name = ''
        this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Owner = ''
        this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_DueDate = ''
        this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Progress = ''
        this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Justification = ''
        this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_OwmerText = ''

        this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Name = ''
        this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Owner = ''
        this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_DueDate = ''
        this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Progress = ''
        this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Justification = ''
        this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_OwnerText = ''
        this.selectedRaged('new');
        this.selectedRagedImpact('new');
    }
    myFunction_addMitigation() {
        this.something = true;
    }
    func_formatdateRoot(value) {
        //console.log(value)
        let formatedate = moment(value).format('DD/MM/YYYY');
        //console.log(formatedate);
        // let day = value.day.toString();
        // let month = value.month.toString();
        // let year = value.year.toString();
        // if (day.length < 2) {
        //     day = '0' + day
        // }
        // if (month.length < 2) {
        //     month = '0' + month
        // }
        // let formatedate = day + '/' + month + '/' + year
        return formatedate
    }
    func_formatdate(value) {
        //console.log(value)
        let day = value.day.toString();
        let month = (value.month + 1).toString();
        let year = value.year.toString();
        if (day.length < 2) {
            day = '0' + day
        }
        if (month.length < 2) {
            month = '0' + month
        }
        let formatedate = day + '/' + month + '/' + year
        return formatedate
    }
    func_formatdateto_ngdate(value) {
        let editday = parseInt(value.substr(0, 2));
        let m = parseInt(value.substr(3, 2)) - 1;
        let editmonth = (m <= 0) ? 0 : m;
        let edityear = parseInt(value.substr(6, 4));

        return {
            day: (editday),
            month: (editmonth),
            year: (edityear)
        };
    }
    func_clearData() {
        this.Register_Rootcause.Root_RootCause = ''
        this.Register_Rootcause.Root_RootLikelyhood = ''
        this.Register_Rootcause.Root_RootMitigationType = ''
        this.Register_Rootcause.Root_AfterMitigated = ''
        this.Register_Rootcause.Root_KRIName = ''
        this.Register_Rootcause.Root_KRIThreshold_Green = ''
        this.Register_Rootcause.Root_KRIThreshold_Red = ''
        this.Register_Rootcause.Root_KRIStatus = ''
        this.Register_Rootcause.Root_Justification = ''
        this.Register_Rootcause.RootCause_Description = ''

        this.Register_Impact.Impact_Category = ''
        this.Register_Impact.Impact_NPTEMV = ''
        this.Register_Impact.Impact_Total_Amont = ''
        this.Register_Impact.Impact_Description = ''
        this.Register_Impact.Impact_Level = ''
        this.Register_Impact.Impact_Migation_Type = ''
        this.Register_Impact.Impact_After_Mitigated = ''
        this.Register_Impact.Impact_Rating = ''
    }
    chkstatus() {
        if (this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Name === '' && this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Owner === '') {
            this.statusbtn = false;
        } else {
            this.statusbtn = true;
        }
    }
    chkstatusimpact() {
        if (this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Name === '' && this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Owner === '') {
            this.statusbtn = false;
        } else {
            this.statusbtn = true;
        }
    }

    func_rootcause() {
        const _s = this;
        if (_s.Register_Data.Regis_RiskID !== null && _s.Register_Data.Regis_RiskID != '' && _s.Register_Data.Regis_RiskID != undefined){
            this.func_UPDATEDATA(false);
            $('#modalrootcasue').modal('show')
            this.myFunctionoff();
            this.selectedradio(10);
            this.valueadd = true;
            this.selectedRaged('new');

            this.temptable = []
            this.Register_Rootcause.RootCause_Id = ''
            this.Register_Rootcause.Root_RootMitigationType = ''
            this.tempmodule = 'insert'
            this.Register_Rootcause.Root_RootCause = ''
            this.Register_Rootcause.Root_RootLikelyhood = ''
            this.Register_Rootcause.Root_RootMitigationType = ''
            this.Register_Rootcause.Root_AfterMitigated = ''
            this.Register_Rootcause.Root_KRIName = ''
            this.Register_Rootcause.Root_KRIThreshold_Green = ''
            this.Register_Rootcause.Root_KRIThreshold_Red = ''
            this.Register_Rootcause.Root_KRIStatus = ''
            this.Register_Rootcause.Root_Justification = ''
            this.Register_Rootcause.RootCause_Description = ''

            this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Name = ''
            this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Owner = ''
            this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_DueDate = ''
            this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Progress = 0
            this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Justification = ''
            this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_OwmerText = ''
        }
    }
    func_editrootcause(id) {
        this.statusbtn = false;
        this.func_UPDATEDATA(false);
        this.temptable = []
        this.myFunctionoff();
        this.valueadd = true;
        this.selectedRaged('new');
        for (let itemrootCause of this.Tempdata.rootCause) {
            if (itemrootCause.rootCause_Id === id) {
                this.setRegisterRootCause(id, itemrootCause);
            }
        }
        this.setTemTable(id);

        this.selectedradio(this.Register_Rootcause.Root_KRIStatus)
        $('#modalrootcasue').modal('show')
        this.tempmodule = 'update'
    }
    private setTemTable(id: any) {
        for (let itemrootCause_Mitigation of this.Tempdata.rootCause_Mitigation) {
            if (itemrootCause_Mitigation.rootCause_Id === id) {
                for (let itemoptions_I2 of this.options) {
                    if (itemrootCause_Mitigation.rootCause_Mitigation_Owner === itemoptions_I2.value) {
                        itemrootCause_Mitigation.rootCause_Mitigation_Ownertext = itemoptions_I2.text;
                    }
                }
                this.temptable.push({
                    id: itemrootCause_Mitigation.rootCause_Mitigation_Id,
                    plan: itemrootCause_Mitigation.rootCause_Mitigation_Name,
                    owner: itemrootCause_Mitigation.rootCause_Mitigation_Owner,
                    Duedate: itemrootCause_Mitigation.rootCause_Mitigation_DueDate,
                    Progess: itemrootCause_Mitigation.rootCause_Mitigation_Progress,
                    justification: itemrootCause_Mitigation.rootCause_Mitigation_Justification,
                    display: true,
                    tempid: this.temptable.length,
                    ownertext: itemrootCause_Mitigation.rootCause_Mitigation_Ownertext
                });
            }
        }
    }

    private setRegisterRootCause(id: any, itemrootCause: any) {
        this.Register_Rootcause.RootCause_Id = id;
        this.Register_Rootcause.Root_RootCause = itemrootCause.rootCause_Category;
        this.Register_Rootcause.Root_RootLikelyhood = itemrootCause.rootCause_Likelihood;
        this.Register_Rootcause.Root_RootMitigationType = itemrootCause.rootCause_Mitigation_Type;
        this.Register_Rootcause.Root_AfterMitigated = (itemrootCause.rootCause_After_Mitigated === null) ? '' : itemrootCause.rootCause_After_Mitigated;
        this.Register_Rootcause.Root_KRIName = itemrootCause.rootCause_KRI_Name;
        this.Register_Rootcause.Root_KRIThreshold_Green = itemrootCause.rootCause_KRI_Threshold_Green;
        this.Register_Rootcause.Root_KRIThreshold_Red = itemrootCause.rootCause_KRI_Threshold_Red;
        this.Register_Rootcause.Root_KRIStatus = itemrootCause.rootCause_KRI_Status;
        this.Register_Rootcause.Root_Justification = itemrootCause.rootCause_KRI_Justification;
        this.Register_Rootcause.RootCause_Description = itemrootCause.rootCause_Description;
    }

    func_addtablerootcause() {

        if (this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_DueDate !== '' && this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_DueDate !== null) {
            this.Seformat_Date = this.func_formatdateRoot(this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_DueDate)
        } else {
            this.Seformat_Date = '';
        }

        if (this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Name === '') {
            this.alertflow = 'Please fill-in Mitigation Plan'
            $('#modalAlertdanger5').modal('show');

        } else if (this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_OwmerText === '') {
            this.alertflow = 'Please fill-in Mitigation Owner'
            $('#modalAlertdanger5').modal('show');

        } else if (this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_DueDate === '') {
            this.alertflow = 'Please fill-in Due Date'
            $('#modalAlertdanger5').modal('show');

        } else if (this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Name !== '' && this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_OwmerText !== '') {
            for (let itemoptions of this.options) {
                if (this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_OwmerText === itemoptions.text) {
                    this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Owner = itemoptions.value
                }
            }

            this.temptable.push({
                plan: this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Name,
                owner: this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Owner,
                Duedate: this.Seformat_Date,
                Progess: this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Progress,
                justification: this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Justification,
                display: true,
                tempid: this.temptable.length,
                ownertext: this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_OwmerText
            })

            this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Name = ''
            this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Owner = ''
            this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Justification = ''
            this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_DueDate = ''
            this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Progress = ''
            this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_OwmerText = ''
            this.myFunctionoff();
            this.selectedRaged('new');
            this.statusbtn = false;

        }

        let DemoRootProgress;
        DemoRootProgress = document.getElementById("DemoRootProgress");
        DemoRootProgress.value = '';
    }
    func_delAssessRootCause(id) {
        const _s = this;
        let formData = {
            SessionEmpID: '0001',
            Module: 'Delete',
            body: {
                Risk_Id: this.Register_Data.Regis_RiskID,
                RootCause_Id: id,
                RootCause_Category: '',
                RootCause_Likelihood: '',
                RootCause_Mitigation_Type: '',
                RootCause_After_Mitigated: '',
                RootCause_KRI_Name: '',
                RootCause_KRI_Threshold: '',
                RootCause_KRI_Status: '',
                RootCause_KRI_Justification: '',
                RootCause_Description: '',
                Role: this.Register_Data.Regis_Role,
                DeleteFag: 'ROOTCAUSE',
                Delete_RootCause_Id: id,
                Delete_RootCause_Mitigation_Id: ''
            }
        }
        this.mserve.RegisterRisk_Roocause(formData).then((response) => {
            if (response.data.status) {
                $('#modalConfirm').modal('hide');
                this.func_GETDATA('NoGetmaster');
                this.func_PlotRiskMap();
            } else {

            }
        }).catch((e) => {
            if (e.response) { this.authserviceToken.CheckTokenExpire(e.response.status) } else { }
        });
    }

    func_impact() {
        const _s = this;
        if (_s.Register_Data.Regis_RiskID !== null && _s.Register_Data.Regis_RiskID != '' && _s.Register_Data.Regis_RiskID != undefined){
            this.func_UPDATEDATA(false);
            $('#modalimpact').modal('show')
            this.tempmodule = 'insert'
            this.temptableImpact = []
            this.valueadd = true;
            this.selectedRagedImpact('new');

            this.Register_Impact.Impact_Id = ''
            this.Register_Impact.Impact_Category = ''
            this.Register_Impact.Impact_NPTEMV = ''
            this.Register_Impact.Impact_Total_Amont = ''
            this.Register_Impact.Impact_Description = ''
            this.Register_Impact.Impact_Level = ''
            this.Register_Impact.Impact_Migation_Type = ''
            this.Register_Impact.Impact_Rating = ''
            this.Impact_After_Total_Amont = '';
            this.Register_Impact.Impact_After_Mitigated = ''
            this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Name = ''
            this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Owner = ''
            this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_DueDate = ''
            this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Progress = 0
            this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Justification = ''
            this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_OwnerText = ''
        }
    }
    func_editimpact(id) {
        this.statusbtn = false;
        this.func_UPDATEDATA(false);
        this.temptableImpact = []
        this.myFunctionoff();
        this.valueadd = true;
        this.selectedRagedImpact('new');
        this.editDataImpact(id);

        this.editTempTable(id);
        $('#modalimpact').modal('show')
        this.tempmodule = 'UPDATE'
    }
    private editTempTable(id: any) {
        for (let itemimpact_Mitigation of this.Tempdata.impact_Mitigation) {
            if (itemimpact_Mitigation.impact_Id === id) {
                for (let itemoptions_I2 of this.options) {
                    if (itemimpact_Mitigation.impact_Mitigation_Owner === itemoptions_I2.value) {
                        itemimpact_Mitigation.impact_Mitigation_OwnerText = itemoptions_I2.text;
                    }
                }

                this.temptableImpact.push({
                    id: itemimpact_Mitigation.impact_Mitigation_Id,
                    mitigation: itemimpact_Mitigation.impact_Mitigation_Name,
                    owner: itemimpact_Mitigation.impact_Mitigation_Owner,
                    Duedate: itemimpact_Mitigation.impact_Mitigation_DueDate,
                    Progess: itemimpact_Mitigation.impact_Mitigation_Progress,
                    justification: itemimpact_Mitigation.impact_Mitigation_Justification,
                    display: true,
                    tempid: this.temptableImpact.length,
                    ownertext: itemimpact_Mitigation.impact_Mitigation_OwnerText
                });
            }
        }
    }

    private editDataImpact(id: any) {
        for (let itemimpact of this.Tempdata.impact) {
            if (itemimpact.impact_Id === id) {
                var ImpactCateID = this.ImpactCategory.find(x => x.value == itemimpact.impact_Category).text2;
                let formData = {
                    Module: "ImpactLevelDDv2",
                    TextSearch1: this.Register_Data.Regis_BusinessUnit,
                    TextSearch2: ImpactCateID,
                    TextSearch7: this.Register_Data.tempQuarter
                }
                this.mserve.Master_DDL(formData).then((response) => {
                    if (response.data.status) {
                        if((this.temp_impact_Category != itemimpact.impact_Category) || (!this.ImpactLevel || !this.ImpactLevelAfter))
                        {
                            this.ImpactLevel = response.data.body;
                            this.ImpactLevelAfter = response.data.body;
                            this.temp_impact_Category = itemimpact.impact_Category;
                        }

                        this.Register_Impact.Impact_Id = id;
                        this.Register_Impact.Impact_Category = (itemimpact.impact_Category === null) ? '' : itemimpact.impact_Category;
                        this.Register_Impact.Impact_NPTEMV = itemimpact.impact_NPT_EMV;
                        this.Register_Impact.Impact_Total_Amont = itemimpact.impact_Total_Amont;
                        this.Register_Impact.Impact_Description = itemimpact.impact_Description;
                        this.Register_Impact.Impact_Level = (itemimpact.impact_Level === null) ? '' : itemimpact.impact_Level;
                        this.Register_Impact.Impact_Migation_Type = itemimpact.impact_Mitigation_Type;
                        this.Register_Impact.Impact_After_Mitigated = (itemimpact.impact_After_Mitigated === null) ? '' : itemimpact.impact_After_Mitigated;
                        this.Register_Impact.Impact_Rating = itemimpact.impact_Rating;
                        this.Impact_After_Total_Amont = (itemimpact.impact_After_Total_Amont === null) ? '' : itemimpact.impact_After_Total_Amont
                        this.func_Calculate_financial();
                    } else {
                    }
                }).catch((e) => {
                    if (e.response) { this.authserviceToken.CheckTokenExpire(e.response.status) } else { }
                });
                
                break;
            }
        }

    }

    func_addtableimpact() {
        if (this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_DueDate !== '' && this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_DueDate !== null) {
            this.Seformat_Date = this.func_formatdateRoot(this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_DueDate)
        } else {
            this.Seformat_Date = '';
        }

        if (this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Name === '') {
            this.alertflow = 'Please fill-in Mitigation Plan'
            $('#modalAlertdanger5').modal('show');

        } else if (this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_OwnerText === '') {
            this.alertflow = 'Please fill-in Mitigation Owner'
            $('#modalAlertdanger5').modal('show');

        } else if (this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_DueDate === '') {
            this.alertflow = 'Please fill-in Due Date'
            $('#modalAlertdanger5').modal('show');

        } else if (this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Name !== '' && this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_OwmerText !== '') {
            for (let itemoptions of this.options) {
                if (this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_OwnerText === itemoptions.text) {
                    this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Owner = itemoptions.value
                }
            }
            this.temptableImpact.push({
                mitigation: this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Name,
                owner: this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Owner,
                Duedate: this.Seformat_Date,
                Progess: this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Progress,
                justification: this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Justification,
                display: true,
                tempid: this.temptableImpact.length,
                ownertext: this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_OwnerText
            })

            this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Name = ''
            this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Owner = ''
            this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Justification = ''
            this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_DueDate = ''
            this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Progress = ''
            this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_OwnerText = ''
            this.myFunctionoff();
            this.selectedRagedImpact('new');
            this.statusbtn = false;
        }

        let DemoImpactProgress;
        DemoImpactProgress = document.getElementById("DemoImpactProgress");
        DemoImpactProgress.value = '';

    }
    func_delAssessImpact(id) {
        const _s = this;
        let formData = {
            SessionEmpID: '0001',
            Module: 'Delete',
            body: {
                Risk_Id: this.Register_Data.Regis_RiskID,
                Impact_Id: id,
                Impact_Category: '',
                Impact_Likelihood: '',
                Impact_Mitigation_Type: '',
                Impact_After_Mitigated: '',
                Impact_KRI_Name: '',
                Impact_KRI_Threshold: '',
                Impact_KRI_Status: '',
                Impact_KRI_Justification: '',
                Role: this.Register_Data.Regis_Role,
                DeleteFag: 'Impact',
                Delete_Impact_Id: id,
                Delete_Impact_Mitigation_Id: ''
            }
        }
        this.mserve.RegisterRisk_Impact(formData).then((response) => {
            if (response.data.status) {
                $('#modalConfirm').modal('hide');
                this.func_GETDATA('NoGetmaster');
                this.func_PlotRiskMap();
            } else {

            }
        }).catch((e) => {
            if (e.response) { this.authserviceToken.CheckTokenExpire(e.response.status) } else { }
        });
    }

    function_editRootcause(value, tempid) {
        this.appService.chkeditdate = true;

        if (this.temptable.length !== 0) {
            this.function_editRootcause_processTempDate(tempid);
        }
        else {
            this.appService.tmpdate = this.Edit_Rootcause.RootDate
        }


        this.idedit = value
        this.displayprogress = true;
        this.statusbtn = true;
        for (let itemtemptable of this.temptable) {
            itemtemptable.display = true
        }
        for (let itemtemptable of this.temptable) {
            this.function_editRootcause_TempTableProcess(itemtemptable, tempid);
        }
    }
    private function_editRootcause_processTempDate(tempid: any) {
        for (let itemtemptable of this.temptable) {
            if (itemtemptable.tempid === tempid) {
                this.appService.tmpdate = itemtemptable.Duedate;
            }
        }
    }
    onDateSelect(event) {

        this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_DueDate = event.value
    }
    onDateSelectMigration(event) {

        this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_DueDate = event.value
    }
    onDateSelectMigrationEdit(event) {

        this.Edit_Impact.ImpactDate = event.value;
        this.Edit_Impact.ImpactDateEdit = this.func_formatdateRoot(event.value);
    }
    onDateSelectEdit(event) {

        this.Edit_Rootcause.RootDate = event.value;
        this.Edit_Rootcause.RootDateEdit = this.func_formatdateRoot(event.value);
    }
    private function_editRootcause_TempTableProcess(itemtemptable: any, tempid: any) {
        if (typeof itemtemptable.Duedate !== 'string' && itemtemptable.Duedate !== null) {
            itemtemptable.Duedate = this.func_formatdateRoot(itemtemptable.Duedate);
        } else if (itemtemptable.Duedate === null) {
            itemtemptable.Duedate = '';
        }
        if (itemtemptable.tempid === tempid) {

            itemtemptable.Duedate = (itemtemptable.Duedate !== '') ? this.func_formatdateto_ngdate(itemtemptable.Duedate) : '';

            itemtemptable.display = false;
            this.Edit_Rootcause.RootPlan = itemtemptable.plan;
            this.Edit_Rootcause.RootOwner = itemtemptable.owner;
            this.Edit_Rootcause.RootDate = itemtemptable.Duedate;
            this.Edit_Rootcause.RootDateEdit = this.func_formatdateRoot(itemtemptable.Duedate);
            this.Edit_Rootcause.RootProgress = itemtemptable.Progess;
            this.Edit_Rootcause.RootJustification = itemtemptable.justification;
            this.Edit_Rootcause.RootOwnertext = itemtemptable.ownertext;
        }
    }

    function_addeditRootcause(value, tempid) {
        if (this.Edit_Rootcause.RootPlan === '') {
            this.alertflow = 'Please fill-in Mitigation Plan'
            $('#modalAlertdanger5').modal('show');
            return false
        } else if (this.Edit_Rootcause.RootOwnertext === '') {
            this.alertflow = 'Please fill-in Mitigation Owner'
            $('#modalAlertdanger5').modal('show');
            return false
        } else if (this.Edit_Rootcause.RootDate === null || this.Edit_Rootcause.RootDate === '') {
            this.alertflow = 'Please fill-in Due Date'
            $('#modalAlertdanger5').modal('show');
            return false
        }

        let checckRootProgress = (parseInt(this.Edit_Rootcause.RootProgress) >= 0 && parseInt(this.Edit_Rootcause.RootProgress) <= 100);
        if (!checckRootProgress) {
            let CheckNullRootProgress = (this.Edit_Rootcause.RootProgress == '' || this.Edit_Rootcause.RootProgress == null);
            if (!CheckNullRootProgress) {
                this.alertflow = 'Mitigation Progress must be between 0 and 100'
                $('#modalAlertdanger5').modal('show');
                return false
            }
        }

        if (typeof this.Edit_Rootcause.RootDate !== 'string' && this.Edit_Rootcause.RootDate !== undefined && this.Edit_Rootcause.RootDate !== null) {
            this.Edit_Rootcause.RootDate = this.func_formatdateRoot(this.Edit_Rootcause.RootDate);
        } else {
            this.Edit_Rootcause.RootDate = '';
        }

        this.function_addeditRootcause_setItemOptions();
        this.function_addeditRootcause_setItemTableWpb(tempid);
        this.statusbtn = false;
    }
    private function_addeditRootcause_setItemOptions() {
        for (let itemoptions of this.options) {
            if (this.Edit_Rootcause.RootOwnertext === itemoptions.text) {
                this.Edit_Rootcause.RootOwner = itemoptions.value;
            }
        }
    }

    private function_addeditRootcause_setItemTableWpb(tempid: any) {
        for (let itemttablewpb of this.temptable) {
            if (itemttablewpb.tempid === tempid) {
                itemttablewpb.plan = this.Edit_Rootcause.RootPlan;
                itemttablewpb.owner = this.Edit_Rootcause.RootOwner;
                itemttablewpb.Duedate = this.Edit_Rootcause.RootDate;
                itemttablewpb.Progess = this.Edit_Rootcause.RootProgress;
                itemttablewpb.justification = this.Edit_Rootcause.RootJustification;
                itemttablewpb.ownertext = this.Edit_Rootcause.RootOwnertext;
                itemttablewpb.display = true;
            }
        }
    }

    func_deleterootcauseMitiplan(value, tempid) {
        for (let i = 0; i < this.temptable.length; i++) {
            if (this.temptable[i].tempid === tempid) {
                this.temptable.splice(i, 1);
                break;
            }
        }
        this.statusbtn = false;
    }
    func_Changeroottype() {
        this.Register_Rootcause.Root_AfterMitigated = ''
    }
    function_editImpct(value, tempid) {
        this.appService.chkeditdate = true;

        if (this.temptableImpact.length !== 0) {
            this.function_editImpct_processAppServiceTempDate(tempid);
        }
        else {
            this.appService.tmpdate = this.Edit_Impact.ImpactDate
        }

        this.idedit = value
        this.displayprogress = true;
        this.statusbtn = true;

        for (let itemtemptableImpact of this.temptableImpact) {
            itemtemptableImpact.display = true
        }

        this.function_editImpct_processItemTempTableImpact(tempid);
    }
    private function_editImpct_processAppServiceTempDate(tempid: any) {
        for (let itemtemptableImpact of this.temptableImpact) {
            if (itemtemptableImpact.tempid === tempid) {
                this.appService.tmpdate = itemtemptableImpact.Duedate;
            }
        }
    }

    private function_editImpct_processItemTempTableImpact(tempid: any) {
        for (let itemtemptableImpact of this.temptableImpact) {
            if (typeof itemtemptableImpact.Duedate !== 'string' && itemtemptableImpact.Duedate !== null) {
                itemtemptableImpact.Duedate = this.func_formatdateRoot(itemtemptableImpact.Duedate);
            } else if (itemtemptableImpact.Duedate === null) {
                itemtemptableImpact.Duedate = '';
            }

            if (itemtemptableImpact.tempid === tempid) {

                itemtemptableImpact.Duedate = (itemtemptableImpact.Duedate !== '') ? this.func_formatdateto_ngdate(itemtemptableImpact.Duedate) : '';

                itemtemptableImpact.display = false;
                this.Edit_Impact.ImpactPlan = itemtemptableImpact.mitigation;
                this.Edit_Impact.ImpactOwner = itemtemptableImpact.owner;
                this.Edit_Impact.ImpactDate = itemtemptableImpact.Duedate;
                this.Edit_Impact.ImpactDateEdit = this.func_formatdateRoot(itemtemptableImpact.Duedate);
                this.Edit_Impact.ImpactProgress = itemtemptableImpact.Progess;
                this.Edit_Impact.ImpactJustification = itemtemptableImpact.justification;
                this.Edit_Impact.ImpactOwnertext = itemtemptableImpact.ownertext;
            }
        }
    }

    function_addeditImpact(value, tempid) {
        if (this.Edit_Impact.ImpactPlan === '') {
            this.alertflow = 'Please fill-in Mitigation Plan'
            $('#modalAlertdanger5').modal('show');
            return false
        } else if (this.Edit_Impact.ImpactOwnertext === '') {
            this.alertflow = 'Please fill-in Mitigation Owner'
            $('#modalAlertdanger5').modal('show');
            return false
        } else if (this.Edit_Impact.ImpactDate === null || this.Edit_Impact.ImpactDate === '') {
            this.alertflow = 'Please fill-in Due Date'
            $('#modalAlertdanger5').modal('show');
            return false
        }


        let CheckImpactProgress = (parseInt(this.Edit_Impact.ImpactProgress) >= 0 && parseInt(this.Edit_Impact.ImpactProgress) <= 100);
        if (!CheckImpactProgress) {
            let checkNullImpactProgress = (this.Edit_Impact.ImpactProgress == '' || this.Edit_Impact.ImpactProgress == null);
            if (!checkNullImpactProgress) {
                this.alertflow = 'Mitigation Progress must be between 0 and 100'
                $('#modalAlertdanger5').modal('show');
                return false
            }
        }

        if (typeof this.Edit_Impact.ImpactDate !== 'string' && this.Edit_Impact.ImpactDate !== undefined && this.Edit_Impact.ImpactDate !== null) {
            this.Edit_Impact.ImpactDate = this.func_formatdateRoot(this.Edit_Impact.ImpactDate);
        } else {
            this.Edit_Impact.ImpactDate = '';
        }

        this.function_addeditImpact_processImpactOwner();

        this.function_addeditImpact_process2(tempid);
        this.statusbtn = false;
    }
    private function_addeditImpact_processImpactOwner() {
        for (let itemoptions of this.options) {
            if (this.Edit_Impact.ImpactOwnertext === itemoptions.text) {
                this.Edit_Impact.ImpactOwner = itemoptions.value;
            }
        }
    }

    private function_addeditImpact_process2(tempid: any) {
        for (let temptableImpact of this.temptableImpact) {
            if (temptableImpact.tempid === tempid) {
                temptableImpact.mitigation = this.Edit_Impact.ImpactPlan;
                temptableImpact.owner = this.Edit_Impact.ImpactOwner;
                temptableImpact.Duedate = this.Edit_Impact.ImpactDate;
                temptableImpact.Progess = this.Edit_Impact.ImpactProgress;
                temptableImpact.justification = this.Edit_Impact.ImpactJustification;
                temptableImpact.ownertext = this.Edit_Impact.ImpactOwnertext;
                temptableImpact.display = true;
            }
        }
    }

    func_deleteimpactMitiplan(value, tempid) {
        for (let i = 0; i < this.temptableImpact.length; i++) {
            if (this.temptableImpact[i].tempid === tempid) {
                this.temptableImpact.splice(i, 1);
                break;
            }
        }
        this.statusbtn = false;
    }
    func_selectImpactCate(value) {
        this.Register_Impact.Impact_NPTEMV = ''
        this.Register_Impact.Impact_Total_Amont = ''
        this.Register_Impact.Impact_Level = ''
        this.Register_Impact.Impact_Description = ''
        this.Register_Impact.Impact_Level = ''
        this.Register_Impact.Impact_After_Mitigated = ''

        var ImpactCateID = this.ImpactCategory.find(x => x.value == value).text2;
        this.func_GETMASTER_ImpactLevel_v2(ImpactCateID);
    }
    func_ChageNI_NPV() {
        this.Register_Impact.Impact_Total_Amont = ''
        this.Register_Impact.Impact_Level = ''
        this.Register_Impact.Impact_After_Mitigated = ''
        this.Register_Impact.Impact_After_Total_Amont = ''
        this.Impact_After_Total_Amont = ''
    }
    func_Calculate2() {
        if (this.Register_Impact.Impact_NPTEMV === '1') {
            this.func_Calculate_ProcessImpact_NPTEMV2();
        }
        else if (this.Register_Impact.Impact_NPTEMV !== '1') {
            this.func_Calculate_ProcessNotImpact_NPTEMV2();
        }
        $("#AfterMitigated").val(this.Register_Impact.Impact_After_Mitigated);

    }
    private func_Calculate_ProcessNotImpact_NPTEMV2() {
        var afterMitigatedValue = $("#AfterMitigatedValue").val();
        if (afterMitigatedValue === null || afterMitigatedValue === '') {
            this.Register_Impact.Impact_After_Mitigated = '';
        } else if (afterMitigatedValue <= this.Financial_NI_NPV_EM[0].NPV_f) {
            this.Register_Impact.Impact_After_Mitigated = this.Financial_NI_NPV_EM[0].ImpactCateItemCode;
        } else if (afterMitigatedValue >= this.Financial_NI_NPV_EM[1].NPV_f && afterMitigatedValue <= this.Financial_NI_NPV_EM[1].NPV_t) {
            this.Register_Impact.Impact_After_Mitigated = this.Financial_NI_NPV_EM[1].ImpactCateItemCode;
        } else if (afterMitigatedValue >= this.Financial_NI_NPV_EM[2].NPV_f && afterMitigatedValue <= this.Financial_NI_NPV_EM[2].NPV_t) {
            this.Register_Impact.Impact_After_Mitigated = this.Financial_NI_NPV_EM[2].ImpactCateItemCode;
        } else if (afterMitigatedValue >= this.Financial_NI_NPV_EM[3].NPV_f && afterMitigatedValue <= this.Financial_NI_NPV_EM[3].NPV_t) {
            this.Register_Impact.Impact_After_Mitigated = this.Financial_NI_NPV_EM[3].ImpactCateItemCode;
        } else if (afterMitigatedValue >= this.Financial_NI_NPV_EM[4].NPV_f) {
            this.Register_Impact.Impact_After_Mitigated = this.Financial_NI_NPV_EM[4].ImpactCateItemCode;
        }
    }

    private func_Calculate_ProcessImpact_NPTEMV2() {
        var afterMitigatedValue = $("#AfterMitigatedValue").val();
        if (afterMitigatedValue === null || afterMitigatedValue === '') {
            this.Register_Impact.Impact_After_Mitigated = '';
        } else if (afterMitigatedValue <= this.Financial_NI_NPV_EM[0].NI_f) {
            this.Register_Impact.Impact_After_Mitigated = this.Financial_NI_NPV_EM[0].ImpactCateItemCode;
        } else if (afterMitigatedValue >= this.Financial_NI_NPV_EM[1].NI_f && afterMitigatedValue <= this.Financial_NI_NPV_EM[1].NI_t) {
            this.Register_Impact.Impact_After_Mitigated = this.Financial_NI_NPV_EM[1].ImpactCateItemCode;
        } else if (afterMitigatedValue >= this.Financial_NI_NPV_EM[2].NI_f && afterMitigatedValue <= this.Financial_NI_NPV_EM[2].NI_t) {
            this.Register_Impact.Impact_After_Mitigated = this.Financial_NI_NPV_EM[2].ImpactCateItemCode;
        } else if (afterMitigatedValue >= this.Financial_NI_NPV_EM[3].NI_f && afterMitigatedValue <= this.Financial_NI_NPV_EM[3].NI_t) {
            this.Register_Impact.Impact_After_Mitigated = this.Financial_NI_NPV_EM[3].ImpactCateItemCode;
        } else if (afterMitigatedValue >= this.Financial_NI_NPV_EM[4].NI_f) {
            this.Register_Impact.Impact_After_Mitigated = this.Financial_NI_NPV_EM[4].ImpactCateItemCode;
        }
    }


    func_Calculate() {
        if (this.Register_Impact.Impact_NPTEMV === '1') {
            this.func_Calculate_ProcessImpact_NPTEMV();
        }
        else if (this.Register_Impact.Impact_NPTEMV !== '1') {
            this.func_Calculate_ProcessNotImpact_NPTEMV();
        }
    }

    func_Calculate_financial () {
        if (this.Register_Impact.Impact_Category === '1') {
            if (this.Register_Impact.Impact_NPTEMV === '1') {
                if (this.Register_Impact.Impact_Total_Amont === null || this.Register_Impact.Impact_Total_Amont === '') {
                    this.Register_Impact.Impact_Level = ''
                } else if (this.Register_Impact.Impact_Total_Amont <= this.Financial_NI_NPV_EM[0].NI_f) { // <= 0.13 , Minor
                    this.Register_Impact.Impact_Level = this.Financial_NI_NPV_EM[0].ImpactCateItemCode;
                } else if (this.Register_Impact.Impact_Total_Amont >= this.Financial_NI_NPV_EM[1].NI_f && this.Register_Impact.Impact_Total_Amont <= this.Financial_NI_NPV_EM[1].NI_t) { // 0.13 - 1.3 , Moderate
                    this.Register_Impact.Impact_Level = this.Financial_NI_NPV_EM[1].ImpactCateItemCode;
                } else if (this.Register_Impact.Impact_Total_Amont >= this.Financial_NI_NPV_EM[2].NI_f && this.Register_Impact.Impact_Total_Amont <= this.Financial_NI_NPV_EM[2].NI_t) { // 1.3 - 13 , Significant
                    this.Register_Impact.Impact_Level = this.Financial_NI_NPV_EM[2].ImpactCateItemCode;
                } else if (this.Register_Impact.Impact_Total_Amont >= this.Financial_NI_NPV_EM[3].NI_f && this.Register_Impact.Impact_Total_Amont <= this.Financial_NI_NPV_EM[3].NI_t) { // 13 - 130 , Serious
                    this.Register_Impact.Impact_Level = this.Financial_NI_NPV_EM[3].ImpactCateItemCode;
                } else if (this.Register_Impact.Impact_Total_Amont >= this.Financial_NI_NPV_EM[4].NI_f) { // >= 130 , Critical
                    this.Register_Impact.Impact_Level = this.Financial_NI_NPV_EM[4].ImpactCateItemCode;
                }
            }
            else if (this.Register_Impact.Impact_NPTEMV !== '1') {
                if (this.Register_Impact.Impact_Total_Amont === null || this.Register_Impact.Impact_Total_Amont === '') {
                    this.Register_Impact.Impact_Level = ''
                } else if (this.Register_Impact.Impact_Total_Amont <= this.Financial_NI_NPV_EM[0].NPV_f) { // <= 1.7 , Minor
                    this.Register_Impact.Impact_Level = this.Financial_NI_NPV_EM[0].ImpactCateItemCode;
                } else if (this.Register_Impact.Impact_Total_Amont >= this.Financial_NI_NPV_EM[1].NPV_f && this.Register_Impact.Impact_Total_Amont <= this.Financial_NI_NPV_EM[1].NPV_t) { // 1.7 - 17 , Moderate
                    this.Register_Impact.Impact_Level = this.Financial_NI_NPV_EM[1].ImpactCateItemCode;
                } else if (this.Register_Impact.Impact_Total_Amont >= this.Financial_NI_NPV_EM[2].NPV_f && this.Register_Impact.Impact_Total_Amont <= this.Financial_NI_NPV_EM[2].NPV_t) { // 17 - 170 , Significant
                    this.Register_Impact.Impact_Level = this.Financial_NI_NPV_EM[2].ImpactCateItemCode;
                } else if (this.Register_Impact.Impact_Total_Amont >= this.Financial_NI_NPV_EM[3].NPV_f && this.Register_Impact.Impact_Total_Amont <= this.Financial_NI_NPV_EM[3].NPV_t) { // 170 - 1700 , Serious
                    this.Register_Impact.Impact_Level = this.Financial_NI_NPV_EM[3].ImpactCateItemCode;
                } else if (this.Register_Impact.Impact_Total_Amont >= this.Financial_NI_NPV_EM[4].NPV_f) { // >= 1700 , Critical
                    this.Register_Impact.Impact_Level = this.Financial_NI_NPV_EM[4].ImpactCateItemCode;
                }
            }
        }
    }

    private func_Calculate_ProcessNotImpact_NPTEMV() {
        if (this.Register_Impact.Impact_Total_Amont === null || this.Register_Impact.Impact_Total_Amont === '') {
            this.Register_Impact.Impact_Level = '';
        } else if (this.Register_Impact.Impact_Total_Amont <= this.Financial_NI_NPV_EM[0].NPV_f) {
            this.Register_Impact.Impact_Level = this.Financial_NI_NPV_EM[0].ImpactCateItemCode;
        } else if (this.Register_Impact.Impact_Total_Amont >= this.Financial_NI_NPV_EM[1].NPV_f && this.Register_Impact.Impact_Total_Amont <= this.Financial_NI_NPV_EM[1].NPV_t) {
            this.Register_Impact.Impact_Level = this.Financial_NI_NPV_EM[1].ImpactCateItemCode;
        } else if (this.Register_Impact.Impact_Total_Amont >= this.Financial_NI_NPV_EM[2].NPV_f && this.Register_Impact.Impact_Total_Amont <= this.Financial_NI_NPV_EM[2].NPV_t) {
            this.Register_Impact.Impact_Level = this.Financial_NI_NPV_EM[2].ImpactCateItemCode;
        } else if (this.Register_Impact.Impact_Total_Amont >= this.Financial_NI_NPV_EM[3].NPV_f && this.Register_Impact.Impact_Total_Amont <= this.Financial_NI_NPV_EM[3].NPV_t) {
            this.Register_Impact.Impact_Level = this.Financial_NI_NPV_EM[3].ImpactCateItemCode;
        } else if (this.Register_Impact.Impact_Total_Amont >= this.Financial_NI_NPV_EM[4].NPV_f) {
            this.Register_Impact.Impact_Level = this.Financial_NI_NPV_EM[4].ImpactCateItemCode;
        }
    }

    private func_Calculate_ProcessImpact_NPTEMV() {
        if (this.Register_Impact.Impact_Total_Amont === null || this.Register_Impact.Impact_Total_Amont === '') {
            this.Register_Impact.Impact_Level = '';
        } else if (this.Register_Impact.Impact_Total_Amont <= this.Financial_NI_NPV_EM[0].NI_f) {
            this.Register_Impact.Impact_Level = this.Financial_NI_NPV_EM[0].ImpactCateItemCode;
        } else if (this.Register_Impact.Impact_Total_Amont >= this.Financial_NI_NPV_EM[1].NI_f && this.Register_Impact.Impact_Total_Amont <= this.Financial_NI_NPV_EM[1].NI_t) {
            this.Register_Impact.Impact_Level = this.Financial_NI_NPV_EM[1].ImpactCateItemCode;
        } else if (this.Register_Impact.Impact_Total_Amont >= this.Financial_NI_NPV_EM[2].NI_f && this.Register_Impact.Impact_Total_Amont <= this.Financial_NI_NPV_EM[2].NI_t) {
            this.Register_Impact.Impact_Level = this.Financial_NI_NPV_EM[2].ImpactCateItemCode;
        } else if (this.Register_Impact.Impact_Total_Amont >= this.Financial_NI_NPV_EM[3].NI_f && this.Register_Impact.Impact_Total_Amont <= this.Financial_NI_NPV_EM[3].NI_t) {
            this.Register_Impact.Impact_Level = this.Financial_NI_NPV_EM[3].ImpactCateItemCode;
        } else if (this.Register_Impact.Impact_Total_Amont >= this.Financial_NI_NPV_EM[4].NI_f) {
            this.Register_Impact.Impact_Level = this.Financial_NI_NPV_EM[4].ImpactCateItemCode;
        }
    }
    func_ChangeImpactType() {
        // this.Register_Impact.Impact_After_Mitigated = ''
    }

    private _filter(value: string): string[] {
        const filterValue = value?.toLowerCase();
        if (filterValue !== '') {
            return this.options.filter(option => option.text !== null && option.text.toLowerCase().includes(filterValue));
        } else {
            return this.options
        }
    }

    funcDeleteConfirm(id, value) {
        this.tempcon_id = id;
        this.tempDeletevalue = value;
        $('#modalConfirm').modal('show');
    }
    funcYes() {
        if (this.tempDeletevalue === 'rootcause') {
            this.func_delAssessRootCause(this.tempcon_id)
        } else {
            this.func_delAssessImpact(this.tempcon_id)
        }
    }
    funcNo() {
        $('#modalConfirm').modal('hide');
    }
    closealertdate() {
        this.Edit_Rootcause.RootDate = ''
        this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_DueDate = ''
        this.Edit_Impact.ImpactDate = ''
        this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_DueDate = ''
        $('#modalAlert5').modal('hide');
    }

    changelihood() {
        this.Register_Rootcause.Root_AfterMitigated = ''
    }
    changeImpact() {
        this.Register_Impact.Impact_After_Mitigated = ''
    }

    func_closeroot() {
        let tempdataclose = [];
        if (this.Register_Rootcause.RootCause_Id !== '') {
            for (let itemrootCause of this.Tempdata.rootCause) {
                if (this.Register_Rootcause.RootCause_Id === itemrootCause.rootCause_Id) {
                    tempdataclose.push(itemrootCause);
                }
            }
            this.func_closeroot_modalAction(tempdataclose);
        }
        else {
            $('#modalConfirmClose').modal('hide');
            $('#modalrootcasue').modal('hide');
        }
    }

    private func_closeroot_modalAction(tempdataclose: any[]) {
        if (tempdataclose[0].rootCause_Category !== this.Register_Rootcause.Root_RootCause) {
            $('#modalConfirmClose').modal('show');
        } else if (tempdataclose[0].rootCause_Likelihood !== this.Register_Rootcause.Root_RootLikelyhood) {
            $('#modalConfirmClose').modal('show');
        } else if (tempdataclose[0].rootCause_Mitigation_Type !== this.Register_Rootcause.Root_RootMitigationType) {
            $('#modalConfirmClose').modal('show');
        } else if (tempdataclose[0].rootCause_After_Mitigated !== this.Register_Rootcause.Root_AfterMitigated && tempdataclose[0].rootCause_After_Mitigated !== null) {
            $('#modalConfirmClose').modal('show');
        } else if (tempdataclose[0].rootCause_KRI_Name !== this.Register_Rootcause.Root_KRIName && tempdataclose[0].rootCause_KRI_Name !== null) {
            $('#modalConfirmClose').modal('show');
        } else if (tempdataclose[0].rootCause_KRI_Status !== this.Register_Rootcause.Root_KRIStatus && tempdataclose[0].rootCause_KRI_Status !== null) {
            $('#modalConfirmClose').modal('show');
        } else if (tempdataclose[0].rootCause_KRI_Justification !== this.Register_Rootcause.Root_Justification && tempdataclose[0].rootCause_KRI_Justification !== null) {
            $('#modalConfirmClose').modal('show');
        } else if (tempdataclose[0].rootCause_Description !== this.Register_Rootcause.RootCause_Description && tempdataclose[0].rootCause_Description !== null) {
            $('#modalConfirmClose').modal('show');
        }
        else {
            $('#modalConfirmClose').modal('hide');
            $('#modalrootcasue').modal('hide');
        }
    }

    func_closeImpact() {
        let tempdataclose = [];
        if (this.Register_Impact.Impact_Id !== '') {
            for (let itemimpact of this.Tempdata.impact) {
                if (this.Register_Impact.Impact_Id === itemimpact.impact_Id) {
                    tempdataclose.push(itemimpact);
                }
            }
            this.func_closeImpact_modalAction(tempdataclose);
        }
        else {
            $('#modalConfirmClose').modal('hide');
            $('#modalimpact').modal('hide');
        }

    }

    private func_closeImpact_modalAction(tempdataclose: any[]) {
        if (tempdataclose[0].impact_Category !== this.Register_Impact.Impact_Category) {
            $('#modalConfirmClose').modal('show');
        } else if (tempdataclose[0].impact_NPT_EMV !== this.Register_Impact.Impact_NPTEMV) {
            $('#modalConfirmClose').modal('show');
        } else if (tempdataclose[0].impact_Total_Amont !== this.Register_Impact.Impact_Total_Amont) {
            $('#modalConfirmClose').modal('show');
        } else if (tempdataclose[0].impact_Description !== this.Register_Impact.Impact_Description) {
            $('#modalConfirmClose').modal('show');
        } else if (tempdataclose[0].impact_Level !== this.Register_Impact.Impact_Level) {
            $('#modalConfirmClose').modal('show');
        } else if (tempdataclose[0].impact_Mitigation_Type !== this.Register_Impact.Impact_Migation_Type) {
            $('#modalConfirmClose').modal('show');
        } else if (tempdataclose[0].impact_After_Mitigated !== this.Register_Impact.Impact_After_Mitigated && tempdataclose[0].impact_After_Mitigated !== null) {
            $('#modalConfirmClose').modal('show');
        }
        else {
            $('#modalConfirmClose').modal('hide');
            $('#modalimpact').modal('hide');
        }
    }

    funcYesClose() {
        $('#modalConfirmClose').modal('hide');
        $('#modalrootcasue').modal('hide');
        $('#modalimpact').modal('hide');
    }

    funcNoClose() {
        $('#modalConfirmClose').modal('hide');
    }

    View_Cirtiria(value) {
        sessionStorage.setItem('pagecritiria', value)
        this.router.navigate([]).then(result => { window.open('/RiskCriteria', '_blank'); });
    }

    closeflow() {
        $('#modalAlertdanger5').modal('hide');
    }

    ngAfterViewInit(): void {
        const _s = this
        scroll(0, 390);


    }

    ngAfterContentChecked(): void {
        this.autogrow();
    }

    autogrow() {
        let textArea = document.getElementById("RegisContext")
        textArea.style.overflow = 'hidden';
        textArea.style.height = 'auto';

        let h = textArea.scrollHeight;
        if (h == 0) {
            h = 162;
        }
        textArea.style.height = h + 'px';
    }

    autoGrowTextZone(e) {
        //console.log(e);
        e.target.style.overflow = 'hidden';
        e.target.style.height = "0px";
        e.target.style.height = (e.target.scrollHeight) + "px";
    }
    autoGrowTextZoneRisk_Close_Reason() {
        //coverity
        // const textarea = this.el.nativeElement.querySelector('#Risk_Close_Reason');
        const textarea = this.textAreaCloseComment.nativeElement;
        textarea.style.overflow = 'hidden';
        textarea.style.height = 'auto';
        textarea.style.height = (textarea.scrollHeight + 2) + 'px';
    }

    func_PlotRiskMap() {

        for (let i = 0; i < 5; i++) {
            for (let ii = 0; ii <= 4; ii++) {
                this.list.risk.risktable[i].col[ii].item = []
            }
        }
        let Likelyhood = parseInt(this.Register_Data.Regis_Likelyhood);
        let Impact = parseInt(this.Register_Data.Regis_Impact);

        if (this.Tempdata.impact.length > 0 && this.Tempdata.rootCause.length > 0) {
            Likelyhood = Math.max(...this.Tempdata.rootCause.map(o => o.rootCause_Likelihood));
            Impact = Math.max(...this.Tempdata.impact.map(o => o.impact_Level));
        }

        for (let ii = 0; ii <= 4; ii++) {
            for (let i = 0; i <= 4; i++) {
                if ((Likelyhood - 1) === i && Impact === (ii + 1)) {
                    this.list.risk.risktable[(4 - ii)].col[i].item.push({
                        title: '1',
                        bgcolor: '#000',
                        color: '#fff'
                    })
                }
            }
        }
    }

    func_AddFile(event) {
        if (event.target.files && event.target.files[0]) {
            for (var i = 0; i < event.target.files.length; i++) {
                var fileSize = event.target.files[i].size / 1024 / 1024;
                if (fileSize > 50) {

                    //console.log("> 50 MB");
                    this.alertflow = "File upload is over 50 megabytes (MB)!"
                    $('#modalAlertdanger5').modal('show');
                }
                else {
                    this.loading = true;
                    this.tempDisplayFile.push(event.target.files[i])



                    let formDataFiles = new FormData();
                    var formData = {
                        Table_Type: this.Register_Data.Regis_Role,
                        Risk_Id: this.Register_Data.Regis_RiskID,
                        SessionEmpID: this.Data_SessionStorage[0].employeE_ID
                    }
                    formDataFiles.append('formData', JSON.stringify(formData));
                    formDataFiles.append('files', event.target.files[i], event.target.files[i].name);
                    this.mserve.RiskUploadFile(formDataFiles).then((response) => {
                        if (response.data.status === true) {
                            this.reloadFile()
                            this.loading = false;
                            //window.location.reload();
                        } else {
                            this.loading = false;
                            //console.log('false', response);
                        }
                    })
                }
            }
        }
        //console.log(this.tempDisplayFile);

        var input
        input = document.getElementById("inputGroupFile02");
        input.value = null;
    }


    RemoveFile(item) {

        this.tempfileID = item;
        $('#modalConfirmDeleteFile').modal('show');
    }

    funcYesDeleteFile() {

        let formData = this.tempfileID;
        this.mserve.RiskDeleteFile(formData).then((response) => {
            if (response.data.status) {

                this.tempfileID = '';
                this.tempDisplayFile.pop();
                $('#modalConfirmDeleteFile').modal('hide');
                // window.location.reload();
                this.reloadFile()
            } else {

            }
        }).catch((e) => {
            if (e.response) { this.authserviceToken.CheckTokenExpire(e.response.status) } else { }
        });
    }
    funcNoDeleteFile() {
        $('#modalConfirmDeleteFile').modal('hide');
    }

    func_CheckStatus() {

        const _s = this;
        let chkrootpro = true;
        _s.checkDDstatus = false
        let chkroot = false, chkimpact = false
        let chkrootcount = _s.Tempdata.rootCause.filter(x => x.rootCause_Mitigation_Type == "Treat" || x.rootCause_Mitigation_Type == "Transfer");
        if (chkrootcount.length > 0) {
            chkroot = this.func_CheckStatus_II_rootCause_forEach(_s, chkroot);
        }

        let chkimpactcount = _s.Tempdata.impact.filter(x => x.impact_Mitigation_Type == "Treat" || x.impact_Mitigation_Type == "Transfer");
        if (chkimpactcount.length > 0) {
            chkimpact = this.func_CheckStatus_II_impact_forEach(_s, chkimpact);
        }

        if (chkroot && chkimpactcount.length == 0) {
            chkimpact = true;
        }
        if (chkimpact && chkrootcount.length == 0) {
            chkroot = true;
        }

        if (chkrootcount.length == 0 && chkimpactcount.length == 0) {
            _s.checkDDstatus = false;
        }
        else {
            if (chkroot && chkimpact) {
                _s.checkDDstatus = true;
            } else {
                _s.checkDDstatus = false;
            }
        }

    }

    private func_CheckStatus_II_impact_forEach(_s: this, chkimpact: boolean) {
        _s.Tempdata.impact.forEach(function (itemsImpact, ItemIndex) {
            if (itemsImpact.impact_Mitigation_Type == "Treat" || itemsImpact.impact_Mitigation_Type == "Transfer") {
                let tempFileter = _s.Tempdata.impact_Mitigation.filter(x => x.impact_Id == itemsImpact.impact_Id && x.impact_Mitigation_Progress != "100");
                if (tempFileter.length !== 0) {
                    chkimpact = false;
                    return;
                }
                else {
                    chkimpact = true;
                }
            }
        });
        return chkimpact;
    }

    private func_CheckStatus_II_rootCause_forEach(_s: this, chkroot: boolean) {
        _s.Tempdata.rootCause.forEach(function (itemsRootcause, ItemIndex) {
            if (itemsRootcause.rootCause_Mitigation_Type == "Treat" || itemsRootcause.rootCause_Mitigation_Type == "Transfer") {
                let tempFileter = _s.Tempdata.rootCause_Mitigation.filter(x => x.rootCause_Id == itemsRootcause.rootCause_Id && x.rootCause_Mitigation_Progress != "100");
                if (tempFileter.length !== 0) {
                    chkroot = false;
                    return;
                }
                else {
                    chkroot = true;
                }
            }

        });
        return chkroot;
    }

    numberOnly(event) {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;

    }
    chk_amount() {
        if(this.Register_Impact.Impact_Migation_Type === '' || this.Register_Impact.Impact_Migation_Type === 'Take' ) this.isSelectImpactLevelAfter = true; 
        else if((this.Impact_After_Total_Amont == "") || (this.Impact_After_Total_Amont == null))this.isSelectImpactLevelAfter = false;
        else this.isSelectImpactLevelAfter = true; 
 
        if (!this.Register_Impact.Impact_Total_Amont && this.Register_Data.Impact_Category === "1") {
            this.Impact_After_Total_Amont = '';
            this.Register_Impact.Impact_After_Mitigated = '';
            return true;
        }
    }
    func_OkBU() {
        this.dataService.sendData(this.tempselectbu);
        this.chkbackmenu = 'go'
        $('#modalBU5').modal('hide');
    }
    BacktoMenu() {
        this.chkbackmenu = 'back'
        $('#modalBU5').modal('hide');
    }
    close() {
        $('#modalBU5').modal('show');
        $('#modalAlertBU').modal('hide');
    }

    private _filterBUDD(value: string): string[] {
        let tempFileter;
        const filterValue = value?.toLowerCase();
        if (filterValue !== '') {
            tempFileter = this.BusinessNameDD.filter(option => option.text != null && option.text.toLowerCase().includes(filterValue));
            if (tempFileter.length !== 0) {
                return tempFileter;
            }
        } else {
            return this.BusinessNameDD
        }
    }

    downloadfile(path)
    {
        let splitted = []; 
        if(path.includes("\\"))
        {
            splitted = path.split("\\", 3); 
        }
        else if(path.includes("/"))
        {
            splitted = path.split("/", 3); 
        }
        
        let data = {
            path1: splitted[0],
            path2: splitted[1],
            filename: splitted[2],
            source: "RiskResources"
          }

        this.mserve.DownloadFile(data).then((response) => {
            if (response.data) {
                const blob = new Blob([response.data], { type: 'application/octet-stream' });

                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = data.filename ;
                a.click();
                window.URL.revokeObjectURL(url);
            } else {
              alert("ไม่สามารถดาวน์โหลดไฟล์ได้");
              //console.log('DownloadAssessment : false', response)
            }
          }).catch((e) => {
            alert("ไม่สามารถดาวน์โหลดไฟล์ได้");
            });
    }
    isHiddenAfterMitigatedValue(){
        if(this.Register_Impact.Impact_Category !== '1' || this.Register_Impact.Impact_Migation_Type === 'Take' || this.Register_Impact.Impact_Migation_Type === ''){
            //clear AfterMitigatedValue value when hidden.
            this.Impact_After_Total_Amont = '';
            return true;
        }else{
            return false;
        }   
    }
    isDisableAfterMitigated(impactLevelAfterdataValue){
        if(impactLevelAfterdataValue > this.Register_Impact.Impact_Level){
            return true
        } else {
            return false;
        }
    }

}

@Component({
    selector: 'app-dialog-elements',
    templateUrl: '../_comp/dialog-elements.html',
})
export class Dialogelements {
    constructor(public dialogRef: MatDialogRef<Dialogelements>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

    onNoClick() {
        this.dialogRef.close();
    }
}
