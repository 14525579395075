import { Injectable } from '@angular/core';
import { Configmodel } from 'src/app/model/configmodel';
import { AppSettingService } from 'src/app/app-setting-service';
import { AxiosInterceptorService } from 'src/app/services/axios-interceptor.service';
import * as qs from 'qs';

export const configWithCredential = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;',
    Accept: 'q=0.8;application/json;q=0.9',
    'Access-Control-Allow-Origin': 'https://err-dev.pttep.com/',
    'Access-Control-Allow-Methods': 'POST, GET',
    'Access-Control-Allow-Headers':
      'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
    'X-Frame-Options': 'DENY',
    'Content-Security-Policy': "frame-ancestors 'none'",
  },
  emulateJSON: true,
  withCredentials: true,
};

export const config = {
  headers: {
      'Content-Type': 'application/json;charset=utf-8;',
      'Accept': 'q=0.8;application/json;q=0.9',
      'Access-Control-Allow-Origin': 'https://err-dev.pttep.com/',
      'Access-Control-Allow-Methods': 'POST, GET',
      'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
      'X-Frame-Options': 'DENY',
      'Content-Security-Policy': 'frame-ancestors \'none\''
  },
  emulateJSON: true
}

export const configPost =  {
  headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;',
      'Accept': 'q=0.8;application/json;q=0.9',
      'Authorization':"bearer "+ sessionStorage.getItem('apiToken'),
      'Access-Control-Allow-Origin': 'https://err-dev.pttep.com/',
      'Access-Control-Allow-Methods': 'POST, GET',
      'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
  },
  emulateJSON: true,
  withCredentials: true
}

@Injectable({
  providedIn: 'root'
})

export class MasterService {
  constructor(
    public appSettingService: AppSettingService,
    public axiosInterceptorService: AxiosInterceptorService
  ) {}
  axiosInstance = this.axiosInterceptorService.getAxiosInstance();
  configData: Configmodel;
  

  getData() {
    this.configData = this.appSettingService.read();
    return this.configData;
  }

  BASE_URL = this.getData().api_strategicrisk_url;
  API_URL = {
    getCsrfToken: this.BASE_URL + '/api/Master/GetCsrfToken',
    getActionLogById: this.BASE_URL + '/api/Master/GetActionLogByRiskId'
  };

  async getCsrfToken() {
    return await this.axiosInstance.post(
      this.API_URL.getCsrfToken,
      null,
      configWithCredential
    );
  }

  getActionLogById(paramData) {
    let data = qs.stringify(paramData)
    return this.axiosInstance.post(this.API_URL.getActionLogById, data, configPost);
}
}
