<table class="table" id="table1" [style.Width]="_width">
    <caption style="display: none;">This is a custom table</caption>
    <thead>
    
    </thead>
    <tbody>
    <tr>
            <th class="th-style">
                <div class ="col rotate-text" style="text-align: center;">
                        High
                </div>
            </th>
            <td style="background-color:yellow;">
                    <div class="box-dot">
                        <ng-container *ngFor="let item of dataTable[0][2] let index = index">
                        <span *ngIf="index < 8; else elseBlock">
                            <span class="badge badge-pill badge-light txt-title"
                            [ngClass]="{'dot': this._mode == 'single' , 'dotblack': this._mode == 'multi'}"
                            data-toggle="tooltip" data-placement="top"><strong>{{item.number}}</strong>
                        </span>
                        </span>
                        <ng-template #elseBlock>
                            <span *ngIf="index == 8">
                                <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span class="badge badge-pill badge-light txt-title dotexpand"
                                        [ngClass]="{'dot': this._mode == 'single' , 'dotblack': this._mode == 'multi'}"
                                        data-toggle="tooltip" data-placement="top"><strong>...</strong>
                                    </span>
                                    <div class="dropdown-menu dropdown-menu-top speech-bubble1">
                                        <span *ngFor="let item of dataTable[0][2].slice(8)">
                                            <span class="badge badge-pill badge-light txt-title"
                                            [ngClass]="{'dot': this._mode == 'single' , 'dotblack': this._mode == 'multi'}"
                                            data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                                            </span>
                                        </span>
                                    </div>
                                    </div>
                            </span>
                        </ng-template>
                    </ng-container>
                </div>
            </td>
            <td style="background-color:yellow;">
                <div class="box-dot">
                    <ng-container *ngFor="let item of dataTable[1][2]  let index = index">
                    <span *ngIf="index < 8; else elseBlock">
                        <span class="badge badge-pill badge-light txt-title"
                        [ngClass]="{'dot': this._mode == 'single' , 'dotblack': this._mode == 'multi'}"
                        data-toggle="tooltip" data-placement="top"><strong>{{item.number}}</strong>
                        </span>
                    </span>
                    <ng-template #elseBlock>
                        <span *ngIf="index == 8">
                            <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span class="badge badge-pill badge-light txt-title dotexpand"
                                    [ngClass]="{'dot': this._mode == 'single' , 'dotblack': this._mode == 'multi'}"
                                    data-toggle="tooltip" data-placement="top"><strong>...</strong>
                                </span>
                                <div class="dropdown-menu dropdown-menu-top speech-bubble1">
                                    <span *ngFor="let item of dataTable[1][2].slice(8)">
                                        <span class="badge badge-pill badge-light txt-title"
                                        [ngClass]="{'dot': this._mode == 'single' , 'dotblack': this._mode == 'multi'}"
                                        data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                                        </span>
                                    </span>
                                </div>
                                </div>
                        </span>
                    </ng-template>
                </ng-container>
            </div>
            </td>
            <td style="background-color:green;">
                <div class="box-dot">
                    <ng-container *ngFor="let item of dataTable[2][2]  let index = index">
                    <span *ngIf="index < 8; else elseBlock">
                        <span class="badge badge-pill badge-light txt-title"
                        [ngClass]="{'dot': this._mode == 'single' , 'dotblack': this._mode == 'multi'}"
                        data-toggle="tooltip" data-placement="top"><strong>{{item.number}}</strong>
                        </span>
                    </span>
                    <ng-template #elseBlock>
                        <span *ngIf="index == 8">
                            <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span class="badge badge-pill badge-light txt-title dotexpand"
                                    [ngClass]="{'dot': this._mode == 'single' , 'dotblack': this._mode == 'multi'}"
                                    data-toggle="tooltip" data-placement="top"><strong>...</strong>
                                </span>
                                <div class="dropdown-menu dropdown-menu-top speech-bubble1">
                                    <span *ngFor="let item of dataTable[2][2].slice(8)">
                                        <span class="badge badge-pill badge-light txt-title"
                                        [ngClass]="{'dot': this._mode == 'single' , 'dotblack': this._mode == 'multi'}"
                                        data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                                        </span>
                                    </span>
                                </div>
                                </div>
                        </span>
                    </ng-template>
                </ng-container>
            </div>
            </td>
    </tr>
      <tr>
        <th class="th-style">
            <div class="rotate" style="position:absolute;" *ngIf="this._tabSelect == '1'">
                <span>Strategic Contribution</span>
            </div>
            <div class="rotate" style="position:absolute;  margin-left: -6em!important" *ngIf="this._tabSelect == '2'" >
                <span>Manageable Portfolio Risk</span>
            </div>
            <div class ="col rotate-text" style="text-align: center;">
                    Medium
            </div>
        </th>
        <td style="background-color:yellow;">
            <div class="box-dot">
                <ng-container *ngFor="let item of dataTable[0][1] let index = index">
                <span *ngIf="index < 8; else elseBlock">
                    <span class="badge badge-pill badge-light txt-title"
                    [ngClass]="{'dot': this._mode == 'single' , 'dotblack': this._mode == 'multi'}"
                    data-toggle="tooltip" data-placement="top"><strong>{{item.number}}</strong>
                    </span>
                </span>
                <ng-template #elseBlock>
                    <span *ngIf="index == 8">
                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="badge badge-pill badge-light txt-title dotexpand"
                                [ngClass]="{'dot': this._mode == 'single' , 'dotblack': this._mode == 'multi'}"
                                data-toggle="tooltip" data-placement="top"><strong>...</strong>
                            </span>
                            <div class="dropdown-menu dropdown-menu-top speech-bubble1">
                                <span *ngFor="let item of dataTable[0][1].slice(8)">
                                    <span class="badge badge-pill badge-light txt-title"
                                    [ngClass]="{'dot': this._mode == 'single' , 'dotblack': this._mode == 'multi'}"
                                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                                    </span>
                                </span>
                            </div>
                          </div>
                    </span>
                </ng-template>
            </ng-container>
        </div>
        </td>
        <td style="background-color:yellow;">
            <div class="box-dot">
                <ng-container *ngFor="let item of dataTable[1][1]  let index = index">
                <span *ngIf="index < 8; else elseBlock">
                    <span class="badge badge-pill badge-light txt-title"
                    [ngClass]="{'dot': this._mode == 'single' , 'dotblack': this._mode == 'multi'}"
                    data-toggle="tooltip" data-placement="top"><strong>{{item.number}}</strong>
                    </span>
                </span>
                <ng-template #elseBlock>
                    <span *ngIf="index == 8">
                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="badge badge-pill badge-light txt-title dotexpand"
                                [ngClass]="{'dot': this._mode == 'single' , 'dotblack': this._mode == 'multi'}"
                                data-toggle="tooltip" data-placement="top"><strong>...</strong>
                            </span>
                            <div class="dropdown-menu dropdown-menu-top speech-bubble1">
                                <span *ngFor="let item of dataTable[1][1].slice(8)">
                                    <span class="badge badge-pill badge-light txt-title"
                                    [ngClass]="{'dot': this._mode == 'single' , 'dotblack': this._mode == 'multi'}"
                                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                                    </span>
                                </span>
                            </div>
                          </div>
                    </span>
                </ng-template>
            </ng-container>
        </div>
        </td>
        <td style="background-color:yellow;">
            <div class="box-dot">
                <ng-container *ngFor="let item of dataTable[2][1]  let index = index">
                <span *ngIf="index < 8; else elseBlock">
                    <span class="badge badge-pill badge-light txt-title"
                    [ngClass]="{'dot': this._mode == 'single' , 'dotblack': this._mode == 'multi'}"
                    data-toggle="tooltip" data-placement="top"><strong>{{item.number}}</strong>
                    </span>
                </span>
                <ng-template #elseBlock>
                    <span *ngIf="index == 8">
                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="badge badge-pill badge-light txt-title dotexpand"
                                [ngClass]="{'dot': this._mode == 'single' , 'dotblack': this._mode == 'multi'}"
                                data-toggle="tooltip" data-placement="top"><strong>...</strong>
                            </span>
                            <div class="dropdown-menu dropdown-menu-top speech-bubble1">
                                <span *ngFor="let item of dataTable[2][1].slice(8)">
                                    <span class="badge badge-pill badge-light txt-title"
                                    [ngClass]="{'dot': this._mode == 'single' , 'dotblack': this._mode == 'multi'}"
                                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                                    </span>
                                </span>
                            </div>
                          </div>
                    </span>
                </ng-template>
            </ng-container>
        </div>
        </td>
      </tr>
      <tr>
        <th class="th-style">
            <div class ="col rotate-text" style="text-align: center;">
                    Low
            </div>
        </th>
        <td style="background-color:red;">
            <div class="box-dot">
                <ng-container *ngFor="let item of dataTable[0][0]  let index = index">
                <span *ngIf="index < 8; else elseBlock">
                    <span class="badge badge-pill badge-light txt-title"
                    [ngClass]="{'dot': this._mode == 'single' , 'dotblack': this._mode == 'multi'}"
                    data-toggle="tooltip" data-placement="top"><strong>{{item.number}}</strong>
                    </span>
                </span>
                <ng-template #elseBlock>
                    <span *ngIf="index == 8">
                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="badge badge-pill badge-light txt-title dotexpand"
                                [ngClass]="{'dot': this._mode == 'single' , 'dotblack': this._mode == 'multi'}"
                                data-toggle="tooltip" data-placement="top"><strong>...</strong>
                            </span>
                            <div class="dropdown-menu dropdown-menu-top speech-bubble1">
                                <span *ngFor="let item of dataTable[0][0].slice(8)">
                                    <span class="badge badge-pill badge-light txt-title"
                                    [ngClass]="{'dot': this._mode == 'single' , 'dotblack': this._mode == 'multi'}"
                                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                                    </span>
                                </span>
                            </div>
                          </div>
                    </span>
                </ng-template>
            </ng-container>
        </div>
        </td>
        <td style="background-color:yellow;">
            <div class="box-dot">
                <ng-container *ngFor="let item of dataTable[1][0]  let index = index">
                <span *ngIf="index < 8; else elseBlock">
                    <span class="badge badge-pill badge-light txt-title"
                    [ngClass]="{'dot': this._mode == 'single' , 'dotblack': this._mode == 'multi'}"
                    data-toggle="tooltip" data-placement="top"><strong>{{item.number}}</strong>
                    </span>
                </span>
                <ng-template #elseBlock>
                    <span *ngIf="index == 8">
                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="badge badge-pill badge-light txt-title dotexpand"
                                [ngClass]="{'dot': this._mode == 'single' , 'dotblack': this._mode == 'multi'}"
                                data-toggle="tooltip" data-placement="top"><strong>...</strong>
                            </span>
                            <div class="dropdown-menu dropdown-menu-top speech-bubble1">
                                <span *ngFor="let item of dataTable[1][0].slice(8)">
                                    <span class="badge badge-pill badge-light txt-title"
                                    [ngClass]="{'dot': this._mode == 'single' , 'dotblack': this._mode == 'multi'}"
                                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                                    </span>
                                </span>
                            </div>
                          </div>
                    </span>
                </ng-template>
            </ng-container>
        </div>
        </td>
        <td style="background-color:yellow;">
            <div class="box-dot">
                <ng-container *ngFor="let item of dataTable[2][0]  let index = index">
                <span *ngIf="index < 8; else elseBlock">
                    <span class="badge badge-pill badge-light txt-title"
                    [ngClass]="{'dot': this._mode == 'single' , 'dotblack': this._mode == 'multi'}"
                    data-toggle="tooltip" data-placement="top"><strong>{{item.number}}</strong>
                    </span>
                </span>
                <ng-template #elseBlock>
                    <span *ngIf="index == 8">
                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="badge badge-pill badge-light txt-title dotexpand"
                                [ngClass]="{'dot': this._mode == 'single' , 'dotblack': this._mode == 'multi'}"
                                data-toggle="tooltip" data-placement="top"><strong>...</strong>
                            </span>
                            <div class="dropdown-menu dropdown-menu-top speech-bubble1">
                                <span *ngFor="let item of dataTable[2][0].slice(8)">
                                    <span class="badge badge-pill badge-light txt-title"
                                    [ngClass]="{'dot': this._mode == 'single' , 'dotblack': this._mode == 'multi'}"
                                    data-toggle="tooltip" data-placement="top"><strong>{{ item.number }}</strong>
                                    </span>
                                </span>
                            </div>
                          </div>
                    </span>
                </ng-template>
            </ng-container>
        </div>
        </td>
      </tr>
      <tr>
        <th style="height: 10% !important; width: 10% !important"></th>
        <th class="column-text-table" style="padding: 0rem!important; vertical-align:top;">
            <div class ="">
                Low
            </div>
        </th>
        <th class="column-text-table" style="padding: 0rem!important; vertical-align:top; width: 150px;">
            <div >
                Medium
            </div>
            <div *ngIf="this._tabSelect == '1'" style="font-size: 2vh; position: absolute; padding-top: 20px;">
                <span>Strategic Alignment</span> 
            </div>
            <div *ngIf="this._tabSelect == '2'" style="font-size: 2vh; position: absolute; padding-top: 20px;">
                <span>Manageable Challenge</span> 
            </div>
        </th>
        <th class="column-text-table" style="padding: 0rem!important; vertical-align:top;">
            <div class ="">
                High
            </div>
        </th>
      </tr>
    </tbody>
</table>