<div class="container-fluid appform my-3">
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">

            <div class="col-12 col-md-12 col-lg-12 col-xl-12 pb-5 mt-5 mb-5">
                <div class="card col-12 mt-4 mx-0 px-3">

                    <div class="likelihoodcriteria col-12 mb-3 rounded">
                        <div class="col-0 pt-2">
                            <em class="fas fa-bars icon"></em>&nbsp;&nbsp;
                            Impact Criteria
                        </div>
                    </div>
                    
                    <button type="button" class="btn btn-lg btn-addManualImpact-primary mb-3" style="width: 200px;color:white;background:orange;" (click)="func_addlikelihood ({},'Add')">
                        <em class="fas fa-plus icon"></em>&nbsp;&nbsp;&nbsp;Manual Assessment
                    </button>

                    <div class="table-likelihood">
                        <table class="table table-bordered">
                            <caption style="display: none;" >This is a custom table</caption>
                            <thead style="text-align: center;background-color:#033153;color:white;width:100%;font-size:18px;">
                                <tr>
                                    <th style="width: 50px;vertical-align: middle;">No</th>
                                    <th style="width: 500px;vertical-align: middle;">Impact Category </th>
                                    <th style="width: 50px;vertical-align: middle;">Active</th>
                                    <th style="width: 50px;vertical-align: middle;">Edit</th>
                                </tr>
                            </thead>

                            <tbody style="background-color: white;font-size:16px;">
                                <tr *ngFor='let data of tableimpactCriteria index as index'>
                                    <td style="text-align: center;">{{ index + 1 }}</td>
                                    <td>{{ data.impactCateName }}</td>
                                    <td style="text-align: center;" *ngIf="data.delFlag === '0'; else elseBlock">Yes</td>
                                    <ng-template #elseBlock><td style="text-align: center;">No</td></ng-template>
                                    <td style="text-align: center;">
                                        <button type="button" class="btn" (click)="func_addlikelihood(data,'Edit')">
                                            <em class="fas fa-edit" style="color:orange;font-size:16px;"></em>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="modal fade bd-Likelihood" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" id="modaladdlikelihood">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="container-fluid appform my-3">
                            <div class="row">

                                <div class="col-4 ml-3">
                                    <div class="pl-3 pt-2 rounded likelihood">
                                        <em class="fas fa-bars icon pt-1"></em>&nbsp;&nbsp;Impact
                                    </div>
                                </div>

                                <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">
                                    <div class="container col-12 col-md-12 col-lg-12 col-xl-12 pb-0 mt-0 mb-0 ">
                                        <div class="container">
                                            <div class="container px-0 pb-2">
                                                <div class="card col-12 mt-4 mx-0 px-3">
                                                    <div class="col-12 col-xl-12 mb-4">
                                                        <div class="row col-12 col-xl-12 mt-3 mb-3 mx-0 px-0 d-flex justify-content-around">           
                                                            <div class="col-12 col-lg-6 col-xl-4">
                                                                <div class="form-group row mx-0 px-0">
                                                                    <strong class="formtext pt-2">Impact:</strong>
                                                                    <div class="col-8">
                                                                        <input type="text" class="form-control" name="" [(ngModel)]="ImpactCateName">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 col-lg-6 col-xl-4">
                                                                <div class="form-group row">
                                                                    <label for="chkstatus"><strong class="formtext col-sm-3 pt-2">Active:</strong></label>
                                                                    <div class="col-6 pt-1">
                                                                        <input type="checkbox" [(ngModel)]="status" id="chkstatus">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div *ngFor='let data of Alltable' class="col-12 col-xl-12 mb-4">
                                                        <div class="row col-12 col-xl-12 mt-3 mb-3 mx-0 px-0 d-flex justify-content-around">
                                                            <div class="row col-12 col-xl-2">
                                                                <div class="col-12 mb-2 px-0" *ngIf="Module === 'insert'; else notModuleInsert">
                                                                    <input type="text" class="form-control" [placeholder]="data.impactCateItemName" [(ngModel)]="data.InputImpactCateItemName">
                                                                </div>
                                                                <ng-template #notModuleInsert>
                                                                    <div class="col-12 mb-2 px-0">
                                                                        <!-- <strong class="formtext">{{data.impactCateItemName}}</strong> -->
                                                                        <input type="text" class="form-control" [placeholder]="data.impactCateItemName" [(ngModel)]="data.impactCateItemName">
                                                                    </div>
                                                                </ng-template>
                                                            </div>
                                                            <div class="row col-12 col-xl-10">
                                                                <div class="col-12 mb-2 px-0">
                                                                    <div [formGroup]="data.form" style="background-color:white">
                                                                        <div [ngxSummernote]="config" formControlName="html">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 my-3 mx-0 text-center">
                                                        <button type="button" class="btn mr-3" style="width: 100px;background-color:#9eb0ba;color:white;" data-dismiss="modal" (click)="func_closebtn()">Close</button>
                                                        <button type="button" class="btn" style="width: 100px;background-color:#033153;color:white;" (click)="func_savebtn()">Save</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
             <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalAlertImpactCate">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <span class="bg-danger">&nbsp;</span>
                        <div class="modal-header">
                            <div class="row col-12 text-danger">
                                <div class="col-2">
                                    <em class="far fa-times-circle" style="font-size:50px;"></em>
                                </div>
                                <div class="col-10 pt-2">
                                    <h4 class="modal-title">Alert !</h4>
                                </div>
                            </div>
                        </div>
                        <div class="modal-body text-body border-0">
                            <div class="col-12" style="text-align: left;">
                                <span style="font-size:18px;">{{ alertflow }}</span>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger w-25" data-dismiss="modal" (click)="closeModalAlertImpactCate()">OK</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-loading [loading]='loading'></app-loading>