<div class="container-fluid appform my-3 pl-1">
  <div class="text-center">
    <div class="spinner-grow loading" role="status" id="loader" [hidden]="loading === false">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <div class="mt-2" id="showdisplay" [hidden]="loading === true">
    <div class="row col-12 mx-0 px-0 mt-2 justify-content-center min-width">
      <div class="card col-2 col-xl-2 mt-2">
        <div class="rounded-0 mb-2">
          <div class="border-0 rounded-0 p-2">
            <h6 style="color: #48b8e4;font-size: 24px;">RISK PROFILE</h6>
          </div>
          <div class="m-0 p-0">
            <div class="col-12 col-md-12 col-lg-12 m-0 py-1 px-1">
              <select class="form-control form-control-sm rounded-0 dropdown" [(ngModel)]="list.field.riskprofileYear"
                (change)="mRiskProfile_Quarter()">
                <option *ngFor="let data of list.riskprofile.year" [ngValue]="data.value"> {{ data.text }} </option>
              </select>
            </div>
            <div class="col-12 col-md-12 col-lg-12 m-0 py-1 px-1">
              <select class="form-control form-control-sm rounded-0 dropdown"
                [(ngModel)]="list.field.riskprofileQuarter" (change)="mBusinessUnit()">
                <option *ngFor="let data of list.riskprofile.quarter" value="{{data.text}}"> {{ data.text }}</option>
              </select>
            </div>
            <div class="row col-12 m-0 py-1 px-1">
              <div class="col-12 px-0">
                <button type="radio" class="btn btn-block selected" id="btn-1" (click)="select_click(1)">
                  <strong class="btn-dashboard">Before Mitigate</strong>
                </button>
              </div>
              <div class="col-12 px-0 mt-1">
                <button type="radio" class="btn btn-block not-selected" id="btn-2" (click)="select_click(2)">
                  <strong class="btn-dashboard">After Mitigate</strong>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="bd-blue rounded-0 mb-2 business-unit">
          <div class="border-0 rounded-0 pb-2">
            <h6 style="color: #48b8e4;font-size: 24px;">BUSINESS UNIT</h6>
          </div>
          <div class="row m-0 p-0">
            <div class="row col-12 m-0 py-1 px-1">
              <mat-tree [dataSource]="dataSource2" [treeControl]="treeControl">
                <mat-tree-node *matTreeNodeDef="let nodeitem" matTreeNodePadding class="border rounded hd"
                  (click)="clickBusinessUnit(nodeitem.value, nodeitem.index+100, nodeitem.name)"
                  style="cursor: pointer;" id="treeac{{ nodeitem.index + 100 }}">
                  <button mat-icon-button disabled></button>
                  <span>{{ nodeitem.name }}</span>
                </mat-tree-node>
                <mat-tree-node *matTreeNodeDef="let node;when: hasChild;" matTreeNodePadding class="border rounded hd"
                  (click)="clickBusinessUnit(node.value, node.index+100, node.name)" style="cursor: pointer;"
                  id="treeac{{ node.index + 100 }}">
                  <button class="mr-1" mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                    <mat-icon class="mat-icon-rtl-mirror">
                      <em class="fas fa-plus-circle" *ngIf="treeControl.isExpanded(node) === false"></em>
                      <em class="fas fa-minus-circle" *ngIf="treeControl.isExpanded(node) === true"></em>
                    </mat-icon>
                  </button>
                  <span>{{ node.name }}</span>
                </mat-tree-node>

              </mat-tree>
              <mat-tree [dataSource]="item.dataSource" [treeControl]="treeControl"
                *ngFor="let item of dataSourceDynamic">
                <mat-tree-node *matTreeNodeDef="let nodeitem" matTreeNodePadding class="border rounded hd"
                  (click)="clickBusinessUnit(nodeitem.value, nodeitem.index, nodeitem.name)" style="cursor: pointer;"
                  id="treeac{{ nodeitem.index }}">
                  <button mat-icon-button disabled></button>
                  <span>{{ nodeitem.name }}</span>
                </mat-tree-node>

                <mat-tree-node *matTreeNodeDef="let node;when: hasChild;" matTreeNodePadding class="border rounded hd"
                  (click)="clickBusinessUnit(node.value, node.index, node.name)" style="cursor: pointer;"
                  id="treeac{{ node.index }}">
                  <button class="mr-1" mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                    <mat-icon class="mat-icon-rtl-mirror">
                      <em class="fas fa-plus-circle" *ngIf="treeControl.isExpanded(node) === false"></em>
                      <em class="fas fa-minus-circle" *ngIf="treeControl.isExpanded(node) === true"></em>
                    </mat-icon>
                  </button>
                  <span>{{ node.name }}</span>
                </mat-tree-node>

              </mat-tree>
            </div>
          </div>
        </div>
      </div>
      <div class="card row col-10 col-xl-10 px-0 ml-2 mt-2">
        <div class="row col-12 mb-2 pt-4 mx-0 px-0">
          <div class="col-3 border-0 rounded-0">
            <h3>Risk Map : {{ SelectBUName }}</h3>
          </div>
        </div>

        <div class="col-9 rotated" style="text-align: center;">
          <span>Qualitative (People, Property damage, Project cost & schedule,<br> Legal/Compliance, Enviroment,
            Image/Reputation)</span>
        </div>

        <div class="row col-12 mx-0 px-0">
          <div class="col-1"></div>
          <div class="col-7 pr-0" style="text-align: right;">
            <div class="mr-2" style="display: inline;">
              <span style="font-size: 16px;font-weight: 600;">Risk Level :</span>
            </div>
            <div class="custom-control custom-checkboxs col-form-label custom-control-inline py-0">
              <input type="checkbox" id="Low" class="custom-control-input green" checked disabled>
              <h6 for="Low" class="custom-control-label">Low</h6>
            </div>
            <div class="custom-control custom-checkboxs col-form-label custom-control-inline py-0">
              <input type="checkbox" id="Low" class="custom-control-input yellow" checked disabled>
              <h6 for="Low" class="custom-control-label">Medium</h6>
            </div>
            <div class="custom-control custom-checkboxs col-form-label custom-control-inline py-0">
              <input type="checkbox" id="Low" class="custom-control-input red" checked disabled>
              <h6 for="Low" class="custom-control-label">High</h6>
            </div>
          </div>
          <div class="col-4"></div>
        </div>

        <div class="row col-12 mx-0 px-0">
          <div class="col-1 col-xl-1 px-0"></div>
          <div class="col-7 col-xl-7 pl-0 pr-1">
            <table class="custom" id="test2">
              <caption style="display: none;" >This is a custom table</caption>
              <tr>
                <th></th>
              </tr>
              <tr *ngFor="let item1 of  list.risk.risktable; index as index1;">
                <td *ngFor="let item2 of  item1.col; index as index2;" class="box">
                  <div class="content text-center" class="{{item2.class}}">
                    <ng-container *ngFor="let item3 of item2.item; index as index3;">
                      <span *ngIf="item2.class === 'high'">                      
                        <div class="btn-group dropup" (click)="func_ReveiewPage(item3.Risk_Id)">
                          <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="badge badge-pill badge-light txt-title"
                              [ngStyle]="{'background-color': item3.bgcolor, 'color': item3.color}"
                              data-toggle="tooltip" data-placement="top"><strong>{{item3.index}}</strong></span>
                            <div class="dropdown-menu dropdown-menu-top speech-bubble">
                              <h6 class="m-0" style="text-align: center;">{{item3.title}}</h6>
                            </div>
                          </div>
                        </div>
                      </span>
                      <span *ngIf="item2.class === 'medium'">                      
                        <div class="btn-group dropup" (click)="func_ReveiewPage(item3.Risk_Id)">
                          <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="badge badge-pill badge-light txt-title"
                              [ngStyle]="{'background-color': item3.bgcolor, 'color': item3.color}"
                              data-toggle="tooltip" data-placement="top"><strong>{{item3.index}}</strong></span>
                            <div class="dropdown-menu dropdown-menu-top speech-bubble">
                              <h6 class="m-0" style="text-align: center;">{{item3.title}}</h6>
                            </div>
                          </div>
                        </div>
                      </span>
                      <span *ngIf="item2.class === 'low'">                      
                        <div class="btn-group dropup" (click)="func_ReveiewPage(item3.Risk_Id)">
                          <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="badge badge-pill badge-light txt-title"
                              [ngStyle]="{'background-color': item3.bgcolor, 'color': item3.color}"
                              data-toggle="tooltip" data-placement="top"><strong>{{item3.index}}</strong></span>
                            <div class="dropdown-menu dropdown-menu-top speech-bubble">
                              <h6 class="m-0" style="text-align: center;">{{item3.title}}</h6>
                            </div>
                          </div>
                        </div>
                      </span>
                      <span *ngIf="item2.class === 'Escalation'">
                        <div class="btn-group dropup">
                          <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="badge badge-pill badge-light txt-title"
                              [ngStyle]="{'background-color': item3.bgcolor, 'color': item3.color}"
                              data-toggle="tooltip" data-placement="top"><strong>{{item3.index}}</strong></span>
                            <div class="dropdown-menu dropdown-menu-top speech-bubble">
                              <h6 class="m-0" style="text-align: center;">{{item3.title}}</h6>
                            </div>
                          </div>
                        </div>
                      </span>

                      <div [ngStyle]="{'background-color': item3.bgcolor , color:item3.color }"
                        *ngIf="item2.class === 'impact-y'" class="p-2">
                        <span class="font-size">{{item3.title}}</span>
                      </div>
                      <div [ngStyle]="{'background-color': item3.bgcolor , color:item3.color}" class="p-0"
                        *ngIf="item2.class === 'impact-x'">
                        <span>{{item3.title}}</span>
                      </div>
                    </ng-container>
                  </div>
                </td>
              <tr>
            </table>
          </div>
          <div class="col-4 col-xl-4 pl-0 pr-2 key-risk">
            <div class="card-body row m-0 p-1 rounded" style="background-color: #3a5f92;">
              <div class="col-12">
                <h3 class="pl-2 pt-2" style="color: white;">KEY RISKS</h3>
              </div>
              <div class="col-12 scrollbar-key">
                <ul class="list-group w-100">
                  <li class="border-0 rounded-0 p-2" style="font-size: 18px;color: white;"
                    *ngFor="let item of list.risk.riskitems; index as index">
                    <div class="row px-0 mx-0 list-item" (click)="func_ReveiewPage(item.Risk_Id)">
                      <div class="col-1 px-0">
                        {{ (index+1) + '.' }}
                      </div>
                      <div class="col-11 px-0">
                        {{ item.BU + ': ' + item.Risk_Name }}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-loading [loading]='loading2'></app-loading>