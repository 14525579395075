<div class="container-fluid appform my-3 px-0">
    <div class="col-12 py-3">

        <ul class="rounded" style="background-color:#023154;font-size: 20px;">
            <li class="active" id="Tab1"><a (click)="ClickTab('1')"><strong>Summary</strong></a></li>
            <li id="Tab2"><a (click)="ClickTab('2')"><strong>By Business Unit</strong></a></li>
        </ul>
        <div class="card col-12 mb-0 mt-2 py-3" *ngIf="page === '1'">
            <div class="col-12 mb-5" style="text-align: center;">
                <strong style="font-size: 30px;">Risk DashBoard</strong>
            </div>

            <div class="col-12">
                <div class="row">

                    <div class="col-12 col-xl-3 mt-1">
                        <div class="row">
                            <div class="col-12 col-xl-3">
                                <strong class="formtext">Year</strong>
                            </div>
                            <div class="col-12 col-xl-9">
                                <select class="form-control" [(ngModel)]="Search_Dashboard.Year" (change)="func_selYear()">
                                    <option value="">Please Select</option>
                                    <option *ngFor="let item of YearDD" value="{{ item.value }}">{{  item.text }}</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-xl-3 mt-1">
                        <div class="row">
                            <div class="col-12 col-xl-3">
                                <strong class="formtext">Quarter</strong>
                            </div>
                            <div class="col-12 col-xl-9">
                                <select class="form-control" [(ngModel)]="Search_Dashboard.Quarter" [disabled]="Search_Dashboard.Year === ''" (change)="controldisable()">
                                    <option value="">Please Select</option>
                                    <option *ngFor="let item of QuarterDD" value="{{ item.text }}">{{ item.text }}</option>
                                </select>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-12 mt-4">
                <div class="row">

                    <div class="col-12 col-xl-4">
                        <div class="row">
                            <div class="col-12 col-xl-4">
                                <strong class="formtext">Business Level</strong>
                            </div>
                            <div class="col-12 col-xl-8">
                                <select class="form-control" [(ngModel)]="Search_Dashboard.BULevel" [disabled]='Search_Dashboard.Quarter == "" ' (change)="func_selBULevel()">
                                    <option value="">Please Select</option>
                                    <option value="1">Group</option>
                                    <option value="2">Division</option>
                                    <option value="3">Department</option>
                                    <option value="4">Asset</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-xl-4">
                        <div class="row">
                            <div class="col-12 col-xl-4">
                                <strong class="formtext">Business Unit</strong>
                            </div>
                            <div class="col-12 col-xl-8">
                                <input type="text" class="form-control m-0" aria-label="Number" matInput [formControl]="myControl" [matAutocomplete]="auto" placeholder="Please Select" [(ngModel)]="tempbu" (keyup.enter)="func_selBU()">
                                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option.text" (click)="func_selBU()">
                                    {{option.text}}
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-xl-2 mt-1">
                        <div class="row">
                            <div class="col-12">
                                <input type="checkbox" id="chk1" [(ngModel)]="Search_Dashboard.Childnode" [disabled]='Search_Dashboard.Quarter == "" || Search_Dashboard.BULevel == "4" '>&nbsp;&nbsp;
                                <label for="chk1" class="formtext">Child Node</label>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-12 mt-5" style="text-align: center;">
                <button type="button" class="btn btn-secondary mr-3" style="width:100px;" (click)="func_Clear(1)">Clear</button>
                <button type="button" class="btn btn-primary" style="width:100px;" (click)="func_search1()">Search</button>
            </div>
        </div>

        <div class="card col-12 mt-5 py-4" *ngIf="page === '1'">
            <div class="col-12">
                <div class="row justify-content-center" style="text-align: center;">
                    <div class="col-12 col-xl-3 py-2 mt-1 rounded" style="background-color:#dc3545;">
                        <div class="row">
                            <div class="col-12">
                                <strong class="formtext2">{{ CountBU }}</strong>
                            </div>
                            <div class="col-12">
                                <strong class="formtext2">Business Unit</strong>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-1 px-0"></div>
                    <div class="col-12 col-xl-3 py-2 mt-1 bg-primary rounded">
                        <div class="row">
                            <div class="col-12">
                                <strong class="formtext2">{{ CountTotalRisk }}</strong>
                            </div>
                            <div class="col-12">
                                <strong class="formtext2">Total Risks</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 mt-4">
                <div class="row">
                    <div class="col-12 col-xl-6 p-5 mr-5">
                        <canvas id="myChart" width="600" height="600"></canvas>
                    </div>
                    <div class="col-12 col-xl-5">
                        <div class="col-12 formtext3" style="text-align: center;">
                            <div class="row justify-content-center">
                                <div class="col-3 mr-2 py-2 rounded" style="background-color:#dc3545;">
                                    <div class="row">
                                        <div class="col-12">
                                            <strong>{{ CountHighRisk }}</strong>
                                        </div>
                                        <div class="col-12">
                                            <strong>High Risks</strong>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3 mr-2 py-2 rounded" style="background-color:yellow;color:black;">
                                    <div class="row">
                                        <div class="col-12">
                                            <strong>{{ CountMediumRisk }}</strong>
                                        </div>
                                        <div class="col-12">
                                            <strong>Medium Risks</strong>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3 py-2 rounded" style="background-color:#28a745;">
                                    <div class="row">
                                        <div class="col-12">
                                            <strong>{{ CountLowRisk }}</strong>
                                        </div>
                                        <div class="col-12">
                                            <strong>Low Risks</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 mt-4 pl-4">
                            <div class="col-12 my-2">
                                <strong style="font-size: 28px;">By Risk Category</strong>
                            </div>
                            <div class="col-12 mt-4" style="font-size: 20px;">
                                <div class="col-12 my-4" *ngFor="let item of RiskCategory index as index1">
                                    <div class="row">
                                        <div class="square" [style]="{'background-color': item.text2}"></div>
                                        <div class="col-11">
                                            <strong class="formtext4">{{ index1+1 }}. {{ item.text }}</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card col-12 py-4" *ngIf="page === '1'">
            <div class="col-12">
                <strong style="font-size:28px;">Mitigation Progress</strong>
            </div>
            <div class="col-12 mt-5">
                <canvas id="myChart2"></canvas>
            </div>
        </div>

        <div class="card col-12 mb-0 mt-2 py-3" *ngIf="page === '2'">
            <div class="col-12 mb-5" style="text-align: center;">
                <strong style="font-size: 30px;">Risk DashBoard</strong>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-12 col-xl-4">
                        <div class="row">
                            <div class="col-12 col-xl-5">
                                <strong class="formtext">By Function Group</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 mt-4" >
                <div class="row">
                    <div class="col-12 col-xl-3 mt-1">
                        <div class="row">
                            <div class="col-12 col-xl-3">
                                <strong class="formtext">Year</strong>
                            </div>
                            <div class="col-12 col-xl-9">
                                <select class="form-control" [(ngModel)]="Search_Dashboard.Year" (change)="func_selYear()">
                                    <option value="">Please Select</option>
                                    <option *ngFor="let item of YearDD" value="{{ item.value }}">{{ item.text }}</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-xl-3 mt-1">
                        <div class="row">
                            <div class="col-12 col-xl-3">
                                <strong class="formtext">Quarter</strong>
                            </div>
                            <div class="col-12 col-xl-9">
                                <select class="form-control" [(ngModel)]="Search_Dashboard.Quarter" [disabled]="Search_Dashboard.Year === ''">
                                    <option value="">Please Select</option>
                                    <option *ngFor="let item of QuarterDD" value="{{ item.text }}">{{ item.text }}</option>
                                </select>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-12 mt-5" style="text-align: center;">
                <button type="button" class="btn btn-secondary mr-3" style="width:100px;"  (click)="func_Clear(2)">Clear</button>
                <button type="button" class="btn btn-primary" style="width:100px;" (click)="func_search2()">Search</button>
            </div>
        </div>

        <div class="card col-12 mt-5 py-4" *ngIf="page === '2'">
            <div class="col-12">
                <strong style="font-size:28px;">By Status</strong>
            </div>
            <div class="col-12 mt-5">
                <canvas id="myChart3"></canvas>
            </div>
        </div>

        <div class="card col-12 py-4" *ngIf="page === '2'">
            <div class="col-12">
                <strong style="font-size:28px;">Mitigation Progress</strong>
            </div>
            <div class="col-12 mt-5">
                <canvas id="myChart4"></canvas>
            </div>
        </div>

        <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalAlertreportdash">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <span class="bg-danger">&nbsp;</span>
                    <div class="modal-header">
                        <div class="row col-12 text-danger">
                            <div class="col-2">
                                <em class="far fa-times-circle" style="font-size:50px;"></em>
                            </div>
                            <div class="col-10 pt-2">
                                <h4 class="modal-title">Alert !</h4>
                            </div>
                        </div>
                    </div>
                    <div class="modal-body text-body border-0">
                        <div class="col-12" style="text-align: left;">
                            <span style="font-size:18px;">{{ alertflow }}</span>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger w-25" (click)="closealertdanger()">OK</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  <app-loading [loading]='loading'></app-loading>