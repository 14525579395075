<div class="container-fluid appform my-3">
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">
            <div class="col-12 col-md-12 col-lg-12 col-xl-12 pb-5 mt-5 mb-5">
                <div class="px-0 pb-2">
                    <div class="card col-12 mt-4 mx-0 px-3">
                        <div class="permission col-12 rounded">
                            <div class="col-12 pt-2">
                                <em class="fas fa-cog icon"></em>&nbsp;&nbsp;Permission Management
                            </div>
                        </div>
                        <div class="col-8 col-md-5 col-xl-3 pt-4 mb-2 px-0 rounded">
                            <div class="input-group rounded">
                                <div class="input-group-append" style="background: #dce2f0;">
                                    <button class="btn btn-sm" type="button" style="background: #00adef;color:white" (click)="func_SearchBox()">
                                        <em class="fa fa-search"></em>
                                    </button>
                                </div>
                                <input type="text" class="form-control m-0" placeholder="Check Permission" [(ngModel)]="search.searchBox">
                            </div>
                            <strong>{{search.Resmsg}}</strong>>
                        </div>
                        <div class="table-permission">
                            <table class="table table-bordered" style="width:100%;">
                                <caption style="display: none;" >This is a custom table</caption>
                                <thead style="text-align: center;background-color:#033153;color:white;font-size:18px;">
                                    <tr>
                                        <th style="width: 50px;vertical-align: middle;">No.</th>
                                        <th style="width: 250px;vertical-align: middle;">Group Name</th>
                                        <th style="width: 200px;vertical-align: middle;">Permission Level</th>
                                        <th style="width: 50px;vertical-align: middle;">Edit</th>
                                    </tr>
                                </thead>
                                <tbody style="background-color: white;font-size:16px;">
                                    <tr *ngFor='let data of tablegroup; let i = index'>
                                        <td style="text-align: center;">{{ i + 1 }}</td>
                                        <td>{{data.groupName}}</td>
                                        <td>{{data.permissionLevel}}</td>
                                        <td style="text-align: center;">
                                            <button type="button" class="btn" (click)="func_addpermission(i,data)">
                                                <em class="fas fa-edit" style="color:orange;font-size:16px;"></em>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal fade bd-Mianmenu" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true" id="modaladdpermission">
                            <div class="modal-dialog modal-xl">
                                <div class="modal-content">
                                    <div class="container-fluid appform my-3">
                                        <div class="row">
                                            <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1"
                                                style="font-size: 14px;">
                                                <div
                                                    class="container col-12 col-md-12 col-lg-12 col-xl-12 pb-0 mt-0 mb-0 ">
                                                    <div class="container">
                                                        <div class="col-12 rounded modalcoordinator">
                                                            <div class="col-12 pt-2" ng-model="Title">
                                                                {{Title}}
                                                            </div>
                                                        </div>
                                                        <div class="col-12 my-3 mx-0">
                                                            <button type="button" class="btn mr-3"
                                                                style="width: 100px;background-color:#9eb0ba;color:white;" (click)="func_addemployee()">Grant</button>
                                                            <button type="button" class="btn mr-3"
                                                                style="width: 100px;background-color:#9eb0ba;color:white;" (click)="func_delemployee()">Remove</button>
                                                        </div>
                                                        <div class="table-permission">
                                                            <table class="table table-bordered">
                                                                <caption style="display: none;" >This is a custom table</caption>
                                                                <thead style="text-align: center;">
                                                                    <tr
                                                                        style="background-color:#033153;color:white;width: 1200px;">
                                                                        <th
                                                                            style="width: 100px;vertical-align: middle;">
                                                                            Select<br>
                                                                            <input type="checkbox" id="chkselectall"
                                                                                [(ngModel)]="valuecheckSelectAll"
                                                                                (change)="SelectAll($event)">
                                                                        </th>
                                                                        <th
                                                                            style="width: 100px;vertical-align: middle;">
                                                                            Employee ID</th>
                                                                        <th
                                                                            style="width: 100px;vertical-align: middle;">
                                                                            Name</th>
                                                                        <th
                                                                            style="width: 100px;vertical-align: middle;">
                                                                            E-mail</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody style="background-color: white;width: 1200px;">
                                                                    <tr *ngFor='let data of tablecoordinator; let i = index;'>
                                                                        <td style="text-align: center;">
                                                                            <input type="checkbox" :id="'selectedAll' + i"
                                                                            name="selectedAll"  
                                                                            (change)="onChangeCheckBox($event,data)">
                                                                        </td>
                                                                        <td>{{data.empCode}}</td>
                                                                        <td>{{data.empName}}</td>
                                                                        <td>{{data.email}}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div class="col-12 my-3 mx-0 text-center">
                                                            <button type="button" class="btn mr-3" style="width: 100px;background-color:#9eb0ba;color:white;" (click)="func_closebtn()">Close</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal fade bd-Mianmenu" tabindex="-1" role="dialog"
                            aria-labelledby="myLargeModalLabel" aria-hidden="true" id="modaladdpermission1">
                            <div class="modal-dialog modal-xl">
                                <div class="modal-content">
                                    <div class="container-fluid appform my-3">
                                        <div class="row">
                                            <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1"
                                                style="font-size: 14px;">
                                                <div
                                                    class="container col-12 col-md-12 col-lg-12 col-xl-12 pb-0 mt-0 mb-0 ">
                                                    <div class="container">
                                                        <div class="col-12 rounded modalcoordinator">
                                                            <div class="col-12 pt-2">
                                                                Add Employee
                                                            </div>
                                                        </div>
                                                        <div class="card mt-4">
                                                            <div class="col-12 col-xl-12 mb-4">
                                                                <div class="row col-12 mx-0 px-0 d-flex justify-content-around">

                                                                    <div class="row col-12 col-xl-3">
                                                                        <div class="col-12 mb-2 px-0">
                                                                            <strong class="formtext">Employee ID:
                                                                            </strong>
                                                                        </div>
                                                                        <div class="col-12 px-0">
                                                                            <input type="text" class="form-control" name="" [(ngModel)]="model.EmpID" disabled>
                                                                        </div>
                                                                    </div>

                                                                    <div class="row col-12 col-xl-3">
                                                                        <div class="col-12 mb-2 px-0">
                                                                            <strong class="formtext">Employee Name:
                                                                            </strong>
                                                                        </div>
                                                                        <div class="col-12 px-0">
                                                                            <input type="text" class="form-control m-0" aria-label="Number" matInput [formControl]="myControlEmployeeID" [matAutocomplete]="auto2" [(ngModel)]="model.EmpName" (keyup.enter)="func_selectEmp(model.EmpID)">
                                                                            <mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete">
                                                                                <mat-option *ngFor="let option of filteredOptionsEmployeeIDDD | async" [value]="option.text" (click)="func_selectEmp(option.text)">
                                                                                {{option.text}}
                                                                                </mat-option>
                                                                            </mat-autocomplete>

                                                                        </div>
                                                                    </div>

                                                                    <div class="row col-12 col-xl-3">
                                                                        <div class="col-12 mb-2 px-0">
                                                                            <strong class="formtext">Business Unit:
                                                                            </strong>
                                                                        </div>
                                                                        <div class="col-12 px-0">
                                                                            <input type="text" class="form-control" name="" [(ngModel)]="model.Level" disabled>
                                                                        </div>
                                                                    </div>

                                                                    <div class="row col-12 col-xl-3">
                                                                        <div class="col-12 mb-2 px-0">
                                                                            <strong class="formtext">Email:
                                                                            </strong>
                                                                        </div>
                                                                        <div class="col-12 px-0">
                                                                            <input type="text" class="form-control" name="" [(ngModel)]="model.Email" disabled>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 my-3 mx-0 text-center">
                                                            <button type="button" class="btn mr-3"
                                                                style="width: 100px;background-color:#9eb0ba;color:white;"
                                                                (click)="func_empclose()">Close
                                                            </button>
                                                            <button type="button" class="btn"
                                                                style="width: 100px;background-color:#033153;color:white;"
                                                                (click)="Addemp()">Save
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>