import { OnInit, Component } from "@angular/core";
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Masterserve } from '../../../services/master.service';
import { DomSanitizer } from '@angular/platform-browser';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthenService } from "src/app/services/authenservice.service";
declare let $;


@Component({
    selector: 'app-MasterImpactCate',
    templateUrl: './masterimpactcate.html',
    styleUrls: ['./masterimpactcate.css'],
    providers: [Masterserve, NgbModalConfig, NgbModal]
})
export class MasterImpactCate implements OnInit {

    constructor(public authserviceToken: AuthenService,cf: NgbModalConfig, private modalService: NgbModal,
        public mserve: Masterserve, private sanitizer: DomSanitizer) {
          cf.backdrop = 'static';
          cf.keyboard = false; 
        }
    loading = true;
    tableimpactCriteria;
    Alltable;
    SessionEmpID;
    Module;

    Data_SessionStorage;

    ImpactCateID;
    QuarterID;
    ImpactCateCode;
    ImpactCateName;
    InputImpactCateItemName;
    DelFlag;
    status = false;
    YearDD = [];
    Year_Search;
    QuarterDD;
    Quarter_Getlist
    currentYear;
    
    alertflow = '';
    
    impactCateItemName1;
    dis1 = {html: new UntypedFormControl("", Validators.required)};
    form1: UntypedFormGroup = new UntypedFormGroup(this.dis1);

    impactCateItemName2;
    dis2 = {html: new UntypedFormControl("", Validators.required)};
    form2: UntypedFormGroup = new UntypedFormGroup(this.dis2);

    impactCateItemName3;
    dis3 = {html: new UntypedFormControl("", Validators.required)};
    form3: UntypedFormGroup = new UntypedFormGroup(this.dis3);

    impactCateItemName4;
    dis4 = {html: new UntypedFormControl("", Validators.required)};
    form4: UntypedFormGroup = new UntypedFormGroup(this.dis4);

    impactCateItemName5;
    dis5 = {html: new UntypedFormControl("", Validators.required)};
    form5: UntypedFormGroup = new UntypedFormGroup(this.dis5);


    configNote;
    config: any = {
        airMode: false,
        tabDisable: true,
        popover: {
            table: [
            ["add", ["addRowDown", "addRowUp", "addColLeft", "addColRight"]],
            ["delete", ["deleteRow", "deleteCol", "deleteTable"]]
            ],
            image: [
            ["image", ["resizeFull", "resizeHalf", "resizeQuarter", "resizeNone"]],
            ["float", ["floatLeft", "floatRight", "floatNone"]],
            ["remove", ["removeMedia"]]
            ],
            link: [["link", ["linkDialogShow", "unlink"]]],
            air: [
            [
                "font",
                [
                "bold",
                "italic",
                "underline",
                "strikethrough",
                "superscript",
                "subscript",
                "clear"
                ]
            ]
            ]
        },
        height: "200px",
        toolbar: [
            ["fontsize", ["fontname", "fontsize", "color", "forecolor"]],
            ["para", ["style0", "ul", "ol", "paragraph", "height"]],
            ["insert", ["table", "link"]]
        ],
        codeviewFilter: true,
        codeviewIframeFilter: true
    };

    ngOnInit(): void {
        let _s = this;

        this.Data_SessionStorage = JSON.parse(sessionStorage.datainfo);
        //console.log('Data_SessionStorage => ',this.Data_SessionStorage);
        this.SessionEmpID = this.Data_SessionStorage[0].employeE_ID;

        let date = new Date();
        this.currentYear = date.getFullYear();
        this.Year_Search = date.getFullYear();

        this.loadDLL();
    }

    loadDLL () {
        const _s = this;
        _s.mserve.Master_DDL({ Module:"YearDD" }).then((response) => {
            _s.YearDD = response.data.body
            //console.log("YearDD : ",_s.YearDD);
            _s.func_GetQuarter();
        });
    }

    func_GetQuarter () {
        
        const _s = this;
        let d = new Date();
        let currentMonth = d.getMonth() + 1;
        _s.mserve.Master_DDL({ Module:"QuarterDD",TextSearch1: this.Year_Search }).then((response) => {
            _s.QuarterDD = response.data.body
            //console.log("QuarterDD : ",_s.QuarterDD)
            this.func_calQuarter(currentMonth);
        })
    }

    func_calQuarter (month) {
        
        this.Quarter_Getlist = ''
        if (month >= 1 && month <= 3) {
            this.Quarter_Getlist = this.QuarterDD[0].value;
        } else if (month >= 4 && month <= 6) {
            this.Quarter_Getlist = this.QuarterDD[1].value;
        } else if (month >= 7 && month <= 9) {
            this.Quarter_Getlist = this.QuarterDD[2].value;
        } else if (month >= 10 && month <= 12) {
            this.Quarter_Getlist = this.QuarterDD[3].value;
        }
        this.func_Getlist();
    }

    func_Getlist () {
        const _s = this;
        let tempQuarter = (this.Year_Search == this.currentYear) ? this.Quarter_Getlist : this.QuarterDD[3].value;

        _s.loading = true;
        let datavalue = {
            SessionEmpID:this.SessionEmpID,
            Module:"getlist",
            body:{
                ImpactCateID:"",
                QuarterID:tempQuarter,
                ImpactCateCode:"Test0002",
                ImpactCateName:"GetFinancialImpact1",
                DelFlag:"0",
                sReqImpactCateItem:{
                    ImpactCateItemID:"",
                    ImpactCateItemCode:"00066",
                    ImpactCateItemName:"GetFinancialImpactItem1",
                    Description:"",
                    DelFlag:"0"
                }
            }
        }

        this.mserve.GetImpactCategory(datavalue).then((response) => {
            if(response.data.status === true){
                _s.tableimpactCriteria = response.data.body;
                //console.log('data',_s.tableimpactCriteria);
                _s.loading = false;
            }else{
                //console.log('false',response)
                alert(response.data.errorMessage);
            }
        }).catch((e) => {
            this.authserviceToken.CheckTokenExpire(e.response.status)
            });
    }

    func_addlikelihood(item,mode){
        let _s = this;
        let _t = _s.tableimpactCriteria[0]
        
        _s.ImpactCateID = item.impactCateID
        _s.ImpactCateCode = item.impactCateCode
        _s.ImpactCateName = item.impactCateName

        if(mode === 'Add'){
            _s.Module = 'insert'

            _s.status = true;

            _s.impactCateItemName1 = "Minor"
            this.dis1 = {html: new UntypedFormControl("", Validators.required)};
            let a1: UntypedFormGroup = new UntypedFormGroup(this.dis1);
            this.form1 = a1;

            _s.impactCateItemName2 = "Moderate"
            this.dis2 = {html: new UntypedFormControl("", Validators.required)};
            let a2: UntypedFormGroup = new UntypedFormGroup(this.dis2);
            this.form2 = a2;

            _s.impactCateItemName3 = "Significant"
            this.dis3 = {html: new UntypedFormControl("", Validators.required)};
            let a3: UntypedFormGroup = new UntypedFormGroup(this.dis3);
            this.form3 = a3;

            _s.impactCateItemName4 = "Serious"
            this.dis4 = {html: new UntypedFormControl("", Validators.required)};
            let a4: UntypedFormGroup = new UntypedFormGroup(this.dis4);
            this.form4 = a4;

            _s.impactCateItemName5 = "Critical"
            this.dis5 = {html: new UntypedFormControl("", Validators.required)};
            let a5: UntypedFormGroup = new UntypedFormGroup(this.dis5);
            this.form5 = a5;

            _s.Alltable = [
                {
                    impactCateItemName: "Critical",
                    form: this.form5,
                    InputImpactCateItemName: ""
                },
                {
                    impactCateItemName: "Serious",
                    form: this.form4,
                    InputImpactCateItemName: ""
                },
                {
                    impactCateItemName: "Significant",
                    form: this.form3,
                    InputImpactCateItemName: ""
                },
                {
                    impactCateItemName: "Moderate",
                    form: this.form2,
                    InputImpactCateItemName: ""
                },
                {
                    impactCateItemName: "Minor",
                    form: this.form1,
                    InputImpactCateItemName: ""
                }
            ]
        }else{
            _s.Module = 'update'
            
            let tmptable = [];

            _s.status = (item.delFlag === '0') ? true : false;
            for (let item_sResImpactCateItem of item.sResImpactCateItem){
                let arr = {
                    impactCateItemID:"",
                    impactCateItemCode:"",
                    impactCateItemName:"",
                    description:"",
                    delFlag:"",
                    form:""
                }
                arr.impactCateItemID = item_sResImpactCateItem.impactCateItemID;
                arr.impactCateItemCode = item_sResImpactCateItem.impactCateItemCode;
                arr.impactCateItemName = item_sResImpactCateItem.impactCateItemName;
                arr.description = item_sResImpactCateItem.description;
                arr.delFlag = item_sResImpactCateItem.delFlag;
                arr.form = "";

                tmptable.push(arr);
            }

            this.dis1 = {html: new UntypedFormControl(tmptable[0].description, Validators.required)};
            let a1: UntypedFormGroup = new UntypedFormGroup(this.dis1);
            this.form1 = a1;

            this.dis2 = {html: new UntypedFormControl(tmptable[1].description, Validators.required)};
            let a2: UntypedFormGroup = new UntypedFormGroup(this.dis2);
            this.form2 = a2;

            this.dis3 = {html: new UntypedFormControl(tmptable[2].description, Validators.required)};
            let a3: UntypedFormGroup = new UntypedFormGroup(this.dis3);
            this.form3 = a3;

            this.dis4 = {html: new UntypedFormControl(tmptable[3].description, Validators.required)};
            let a4: UntypedFormGroup = new UntypedFormGroup(this.dis4);
            this.form4 = a4;

            this.dis5 = {html: new UntypedFormControl(tmptable[4].description, Validators.required)};
            let a5: UntypedFormGroup = new UntypedFormGroup(this.dis5);
            this.form5 = a5;

            tmptable[0].form = this.form1;
            tmptable[1].form = this.form2;
            tmptable[2].form = this.form3;
            tmptable[3].form = this.form4;
            tmptable[4].form = this.form5;

            _s.Alltable = tmptable;
            //console.log("Alltable",_s.Alltable)
        }
        $('#modaladdlikelihood').modal('show')
    }

    func_savebtn() {
        let _s = this;
    
        //check require field
        if (!_s.ImpactCateName) {
            _s.alertflow = 'Please fill-in Impact Category Name'
            $('#modalAlertImpactCate').modal('show');
            $('#modalAlertImpactCate').on('show.bs.modal', function () {
                // เมื่อ modal ที่สองถูกเปิด
                $('#modaladdlikelihood').attr('aria-hidden', 'true'); // ปิดการเข้าถึง modal แรก
            });

        } else if(_s.Module === 'insert' && (_s.Alltable.some(item => !item.InputImpactCateItemName || item.InputImpactCateItemName.trim() === ''))) {
            _s.alertflow = 'Please fill-in Impact Category Item Name'
            $('#modalAlertImpactCate').modal('show');
            $('#modalAlertImpactCate').on('show.bs.modal', function () {
                // เมื่อ modal ที่สองถูกเปิด
                $('#modaladdlikelihood').attr('aria-hidden', 'true'); // ปิดการเข้าถึง modal แรก
            });
        } else if(_s.Module === 'update' && (_s.Alltable.some(item => !item.impactCateItemName || item.impactCateItemName.trim() === ''))) {
            _s.alertflow = 'Please fill-in Impact Category Item Name'
            $('#modalAlertImpactCate').modal('show');
            $('#modalAlertImpactCate').on('show.bs.modal', function () {
                // เมื่อ modal ที่สองถูกเปิด
                $('#modaladdlikelihood').attr('aria-hidden', 'true'); // ปิดการเข้าถึง modal แรก
            });
        }
        //start process save button
        else {
            _s.loading = true;
            let LikelihoodItem = [];
    
            if(_s.Module === 'insert'){
                //sort value by index for insert to database
                for (let i = 0; i < _s.Alltable.length; i++) {
                    LikelihoodItem[_s.Alltable.length - 1 - i] = {
                        ImpactCateItemID: _s.Alltable[i].impactCateItemID,
                        ImpactCateItemCode: _s.Alltable[i].impactCateItemCode,
                        ImpactCateItemName: _s.Alltable[i].InputImpactCateItemName,
                        Description: _s.Alltable[i].description,
                        DelFlag: _s.Alltable[i].delFlag,
                    }
                }
            } else {
                for (let i = 0; i < _s.Alltable.length; i++) {
                    LikelihoodItem[i] = {
                        ImpactCateItemID: _s.Alltable[i].impactCateItemID,
                        ImpactCateItemCode: _s.Alltable[i].impactCateItemCode,
                        ImpactCateItemName: _s.Alltable[i].impactCateItemName,
                        Description: _s.Alltable[i].description,
                        DelFlag: _s.Alltable[i].delFlag
                    }
                }
            }
    
            LikelihoodItem[0].Description = _s.form1.get('html').value;
            LikelihoodItem[1].Description = _s.form2.get('html').value;
            LikelihoodItem[2].Description = _s.form3.get('html').value;
            LikelihoodItem[3].Description = _s.form4.get('html').value;
            LikelihoodItem[4].Description = _s.form5.get('html').value;
            
            //console.log('LikelihoodItem',LikelihoodItem)
    
            let tempdelflag;
            tempdelflag = (_s.status === true) ? '0' : '1';
            let datavalue = {
                SessionEmpID:this.SessionEmpID,
                Module:_s.Module,
                body:{
                    LikelihoodID:_s.ImpactCateID,
                    ImpactCateID:_s.ImpactCateID,
                    QuarterID:"1",
                    ImpactCateCode:_s.ImpactCateCode,
                    ImpactCateName:_s.ImpactCateName,
                    DelFlag:tempdelflag,
                    sReqImpactCateItem:LikelihoodItem
                }
            }
            
            //console.log('datavalue',datavalue)
    
            _s.mserve.GetImpactCategory(datavalue).then((response) => {
                if(response.data.status === true){
                    //console.log('data',_s.tableimpactCriteria)
                    _s.func_Getlist();
                }else{
                    //console.log('false',response)
                    alert(response.data.errorMessage);
                }
            }).catch((e) => {
                this.authserviceToken.CheckTokenExpire(e.response.status)
                });
    
            $('#modaladdlikelihood').modal('hide')

        } 
    }

    func_closebtn() {
        $('#modaladdlikelihood').modal('hide')
    }

    closeModalAlertImpactCate() {
        $('#modalAlertImpactCate').modal('hide');

        $('.modal').on("hidden.bs.modal", function (e) {
            if ($('.modal:visible').length) {
                $('body').addClass('modal-open');
            }
        });
        
    }
}