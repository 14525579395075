import { Injectable } from '@angular/core';
import { Configmodel } from '../model/configmodel';
import Axios from 'axios';
import { AppSettingService } from '../app-setting-service';
import { PublicClientApplicationService } from './publicClientApplication.service';


const config = {
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': 'my-authorized-proxy-or-domain',
    'Access-Control-Allow-Methods': 'POST, GET',
    'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
    'X-Frame-Options': 'DENY',
    'Content-Security-Policy': 'frame-ancestors \'none\''
  },
  emulateJSON: true
}

@Injectable()
export class AuthenService {
  constructor(
    public appSettingService: AppSettingService,
    public publicClientService: PublicClientApplicationService
  ) { }
  configData: Configmodel;
  getData() {
    this.configData = this.appSettingService.read();
    return this.configData.api_url;
  }
  BASE_URL = this.getData();
  token: string;

  setTokenStoreg(tokenApiData) {
    this.token = tokenApiData;
  }
  
  getTokenStoreg() {
    return this.token;
  }

  CheckTokenExpire(status: number) {
    //console.log('status', status);
  }
 
  clearStorageLogout() {
    let msalservice = this.publicClientService.getpublicClientData();
    let Azureuserinfo = msalservice.getAllAccounts();
    localStorage.clear();
    sessionStorage.clear();
    msalservice.logoutRedirect({ account: Azureuserinfo[0] }).then(() => {
      location.reload();
    });
  }

  getUserLogin(accessToken?) {
    let getUserLoginUrl: string = this.BASE_URL + '/api/GetUserlogin';
    let secureCode: string = '1234'
    let param = {
      Email: '',
      SecurityCode: secureCode,
      AccessToken: accessToken
    };
    return Axios.post(getUserLoginUrl, param);
  }

  getRefreshToken(accessToken, refreshToken) {
    let getRefreshUrl = this.BASE_URL + '/api/Authentication/RefreshToken';
    let param = {
      AccessToken: accessToken,
      RefreshToken: refreshToken,
    };
    return Axios.post(getRefreshUrl, param);
  }
}
