import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenService } from 'src/app/services/authenservice.service';
import { Masterserve } from 'src/app/services/master.service';
declare let $;
@Component({
  selector: 'app-MasterAssumptionCategory',
  templateUrl: './master-assumption-category.component.html',
  styleUrls: ['./master-assumption-category.component.css']
})
export class MasterAssumptionCategoryComponent implements OnInit {
  title='Alert !';
  message='';
   status='';
   tableCategory= [];
  public form: FormGroup;
  SessionEmpID;
  Data_SessionStorage;
  constructor(private _fb: FormBuilder,public authserviceToken: AuthenService,public mserve: Masterserve
    ) { 
      this.form = this._fb.group({
        assumptionCategory_ID: new FormControl('', Validators.required),
        category_Name: new FormControl('',[ Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]),
        active_Flag: new FormControl(true, Validators.required),
        delete_Flag: new FormControl(0, Validators.required)
      })
    }

  ngOnInit(): void {
    const _s = this;
    _s.Data_SessionStorage = JSON.parse(sessionStorage.datainfo);
    _s.SessionEmpID = this.Data_SessionStorage[0].employeE_ID;
    this.getData()
  }
AssumptionCategory (formData ,mode) {
   
    this.mserve.getAssumptionCategory(formData).then((response) => {
        if (response.data.status ){
              if(mode=='getlist'){
                this.tableCategory = response.data.body;
              } if(mode=='update'){
                $('#modaladdcategory').modal('hide');
                this.message='Update Success'
                $('#modalSuccess').modal('show');
                this.getData()
              }if(mode=='insert'){
                $('#modaladdcategory').modal('hide');
                this.message='Add Success'
                $('#modalSuccess').modal('show');
                this.getData()
              }

          } else {
            alert(response.data.errorMessage);
          }
    }).catch((e) => {
        this.authserviceToken.CheckTokenExpire(e.response.status)
        });
}
getData () {
  let formData = {
    SessionEmpID :this.SessionEmpID,
    Module :"getlist",
    body :{
      assumptionCategory_ID :"",
      category_Name :"",
      active_Flag :"",
      delete_Flag :"",
    }
 }
this.AssumptionCategory(formData,'getlist');
}
setData(data: any) {
    this.form.patchValue(data);
 }

  func_btnAdd(event,item?) {
    if(item){
      this.setData(item)
    }else{
      this.form.reset({
        assumptionCategory_ID:  undefined,
        category_Name: undefined,
        active_Flag: true,
        delete_Flag:0
      });
    }
    this.status=event;
    $('#modaladdcategory').modal('show');
  }
  func_Close () {
    $('#modaladdcategory').modal('hide');
  }
  func_change (item,event) {
    item.active_Flag=(event.target.value==='true'?1:0);
    let formData = {
      SessionEmpID :this.SessionEmpID,
      Module :'update',
      body : item
  }
  this.AssumptionCategory(formData,'checngestatus');
}
  func_save (value) {
    if(this.form.controls.category_Name.valid){
      this.form.value.active_Flag=(this.form.value.active_Flag===true || this.form.value.active_Flag==="true" || this.form.value.active_Flag===1?1:0);
      this.form.value.delete_Flag=0;
      let formData = {
        SessionEmpID :this.SessionEmpID,
        Module :value,
        body :this.form.value
    }
     this.AssumptionCategory(formData,value);
    }

  }

  get fb() {  return this.form.controls; }
}
