import { Component, OnInit } from "@angular/core";
import { Masterserve } from '../../../services/master.service';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
declare let $;

// AUTOCOMPLETE 
import {UntypedFormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { AuthenService } from "src/app/services/authenservice.service";
// -------------------------------------------

@Component({
  selector: 'app-ConfigTopMenu',
  templateUrl: './configtopmenu.html',
  styleUrls: ['./configtopmenu.css'],
  providers: [Masterserve, NgbModalConfig, NgbModal]
})


export class ConfigTopMenu implements OnInit {
  Data_SessionStorage;
  ParentsDD;

  arrPermissionGroup;
  tempBU;
  tablemianmanu;
  statustopmenu;
  valuecheckSelect = false;

  SessionEmpID;
  Module;
  MenuID;
  MenuCode;
  MenuName;
  Parents;
  Status;
  Link;
  Icon;
  FlagTag;
  OrderBy;
  Permission;
  PerGroupmyControl = new UntypedFormControl();
  PerGroupDD = [];
  PerGroupfilteredOptions: Observable<string[]>;
  public selectAll: boolean;

  constructor(config: NgbModalConfig, private modalService: NgbModal,public authserviceToken: AuthenService,
    public mserve: Masterserve) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    let _s = this;

    _s.Data_SessionStorage = JSON.parse(sessionStorage.datainfo);
    //console.log('Data_SessionStorage => ',this.Data_SessionStorage);
    _s.SessionEmpID = this.Data_SessionStorage[0].employeE_ID;

    _s.tempBU = [];
    let datavalue = {
      SessionEmpID: _s.SessionEmpID,
      Module: "getlist",
      body: {
        MenuID: "",
        MenuCode: "TM001",
        MenuName: "TopMenuTest0001",
        Parents: "1",
        Link: "",
        OrderBy: "1",
      }
    }

    _s.mserve.GetTopMenu(datavalue).then((response) => {
      if (response.data.status ){
        _s.tablemianmanu = response.data.body;
        for (let item_tablemianmanu of  _s.tablemianmanu) {
          let aa = item_tablemianmanu.flagTag == "1" ? true : false;
          item_tablemianmanu.flagTag = aa
        }
        //console.log('tablemianmanu1:', _s.tablemianmanu)
      } else {
        //console.log('false', response)
        alert(response.data.errorMessage);
      }
    }).catch((e) => {
      this.authserviceToken.CheckTokenExpire(e.response.status)
      });

    _s.statustopmenu = [
      { value:"0", title: "Active" },
      { value:"1", title: "InActive" },
    ]

    _s.loadDDL();
  }

  loadDDL(){
    let _s = this;

    _s.mserve.Master_DDL({ Module:"TopMenuDD" }).then((response) => {
        _s.ParentsDD = response.data.body
    })

    _s.mserve.Master_DDL({ Module:"PerGroupDD" }).then((response) => {
        //console.log('PerGroupDD', response.data.body)
        _s.PerGroupDD = response.data.body
        this.PerGroupfilteredOptions = this.PerGroupmyControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value))
        );
    })

  }

  AddFocal () {
    
    if (!this.Permission.replace(/\s/g, '').length) {
      alert('Please fill Business Unit')
    }
    else if (this.Permission === '')  {
      alert('Please fill Business Unit')
    }
    else {
        this.tempBU.push(this.Permission)
        this.Permission = ''
        //console.log('Data in tempBU : ',this.tempBU);
    }
  }

  RemoveFocal (value) {
    for (let i = 0; i < this.tempBU.length; i++) {
        if (this.tempBU[i] === value) {
            this.tempBU.splice(i, 1);
        }
    }
  }

  func_addmenu(item, mode) {
    let _s = this;
    
    let arrPer = [];
    if(item.arrPermissionGroup != null){
      arrPer = item.arrPermissionGroup
    }
    if (mode === 'Add') {
      _s.Module = 'insert'
      _s.MenuID = ""
      _s.MenuCode = ""
      _s.MenuName = ""
      _s.Parents = ""
      _s.Status = "0"
      _s.Icon = ""
      _s.Link = ""
      _s.FlagTag = false
      _s.OrderBy = ""
      _s.tempBU = [];
      _s.Permission = ""
      $('#modaladdmenu').modal('show')
    } else if(mode === 'Edit') {

      _s.Module = 'update'
      _s.MenuID = item.menuID
      _s.MenuCode = item.menuCode
      _s.MenuName = item.menuName
      _s.Parents = item.parents
      _s.Status = item.delFlag
      _s.Icon = item.menuIcon
      _s.Link = item.link
      _s.FlagTag = item.flagTag
      _s.OrderBy = item.orderBy
      _s.tempBU =  arrPer
      //console.log('_s.FlagTag',_s.FlagTag)
      $('#modaladdmenu').modal('show')
    } else {
      let r = confirm("Confirm Delete!");
      if (r) {
        _s.Module = 'delete'
        _s.MenuID = item.menuID
        _s.func_savebtn()
      }
    }
  }

  func_savebtn() {
    let _s = this;

    let datavalue = {
      SessionEmpID: _s.SessionEmpID,
      Module: _s.Module,
      body: {
        MenuID: _s.MenuID,
        MenuName: _s.MenuName,
        MenuCode: _s.MenuCode,
        Parents: _s.Parents,
        Link: _s.Link,
        FlagTag: _s.FlagTag ? "1" : "0",
        OrderBy: _s.OrderBy,
        PermissionGroup: _s.tempBU,
        MenuIcon: _s.Icon,
        DelFlag:_s.Status
      }
    }
    //console.log('savebtn',datavalue)
    _s.mserve.GetTopMenu(datavalue).then((response) => {
      if (response.data.status ){
        //console.log("true", response.data.body)
        _s.ngOnInit();
        _s.Parents = '';
        _s.Status = '';
      } else {
        //console.log('false', response)
        alert(response.data.errorMessage);
      }
    }).catch((e) => {
      this.authserviceToken.CheckTokenExpire(e.response.status)
      });

    $('#modaladdmenu').modal('hide')
  }

  func_closebtn() {
    $('#modaladdmenu').modal('hide')
  }

  private _filter(value: string) {
    const filterValue = value?.toLowerCase();
    if (filterValue !== '') {
            return this.PerGroupDD.filter(option => option.text?.toLowerCase().includes(filterValue));
    } else {
        return this.PerGroupDD
    }
}



}