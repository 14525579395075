import { Component, OnInit, Injectable, Inject } from '@angular/core';
import { NgbModal, NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
declare let $: any;
import { Router} from '@angular/router';
import { Masterserve } from '../../services/master.service';
import { RiskMap } from '../../services/riskmap.service';


import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AuthenService } from 'src/app/services/authenservice.service';
import { MainProcessComponent } from '../main-process/main-process.component';
import { ShareService } from 'src/app/services/share.service';
import DOMPurify from 'dompurify';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
    constructor(private appServicecomp: MainProcessComponent) { super() }
    readonly DELIMITER = '/';
    appService= this.appServicecomp;

    parse(value: string): NgbDateStruct | null {
        if (value) {
            let date = value.split(this.DELIMITER);
            return {
                day: (date[0], 10),
                month: (date[1], 10),
                year: (date[2], 10)
            };
        }
        return null;
    }


    format(date: NgbDateStruct | null): string {
        let currentDate = new Date;
        let pre_year = currentDate.getFullYear().toString();
        let pre_month = (currentDate.getMonth() + 1).toString();
        let pre_day = currentDate.getDate().toString();

        if (pre_month.length < 2) {
            pre_month = "0" + pre_month
        }
        if (pre_day.length < 2) {
            pre_day = "0" + pre_day
        }
        let string_currentDate = pre_year + "-" + pre_month + "-" + pre_day


        if (date !== null) {
            let str_year = date.year.toString();
            let str_mon = date.month.toString();
            let str_day = date.day.toString();

            if (str_day.length < 2) {
                str_day = "0" + str_day
            }
            if (str_mon.length < 2) {
                str_mon = "0" + str_mon
            }
            let datestring = str_year + '-' + str_mon + '-' + str_day

            this.format_SetCheckEditDate(date,datestring,string_currentDate)
        }
        else {
            return '';
        }
    }

    format_SetCheckEditDate(date:NgbDateStruct,datestring:string,string_currentDate:string)
    {
        if (this.appService.tmpdate === '' || this.appService.tmpdate === null) {
            this.appService.chkeditdate = false;
        }
        if (datestring < string_currentDate && this.appService.chkeditdate === false) {
            $('#modalAlert5').modal('show');
            return '';
        }
        else {
            let day = date.day.toString()
            let month = date.month.toString()
            if (day.length < 2) {
                day = '0' + day
            }
            if (month.length < 2) {
                month = '0' + month
            }
            this.appService.chkeditdate = false;
            let newday = day + this.DELIMITER + month + this.DELIMITER + date.year;
            return newday;
        }
    }
}

export interface DialogData { }
export interface SaveStatusParams {
    value: any;
    chkasses: any;
    _s: any;
    chkreqrootarr: any[];
    checkreqroot: any;
    chkreqImpactarr: any[];
    checkreqImpact: any;
    statusSavenoCheck: any;
}



@Component({
    selector: 'app-NewAssessment',
    templateUrl: './page.NewAssessment.html',
    styleUrls: ['./page.NewAssessment.css'],

    providers: [
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }, Masterserve, NgbModal, RiskMap
    ]

})

export class NewAssessment implements OnInit {
    Data_SessionStorage;

    Quarter;
    Quarter_Getlist;
    alertflow = '';
    alertdate = 'Please do not select the previous date of the current date';
    tempcon_id = '';
    tempDeletevalue = '';
    checkstatusupdate;

    minDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };

    loading;
    loading2;
    valueadd = false;
    something = false;
    addsecond = false;
    displayprogress = true;
    statusbtn = false;

    Tempdata;
    tempmodule;
    Seformat_Date;
    idedit = '';
    tempTargetObjective = '';

    Register_Data;
    Register_Rootcause;
    Register_Impact;

    Edit_Impact;
    Edit_Rootcause;
    Assert_RootCauseTable = [];
    Assert_ImpactTable = [];
    temptable;
    MitigationtypeRootcause;
    rootrange;
    temp_mitigationRoottable;
    temptableImpact;
    MitigationTypeImpact;

    select_impactmitigationtype = '';
    myControl = new UntypedFormControl();
    options = [];
    filteredOptions: Observable<string[]>;

    myControl2 = new UntypedFormControl();
    filteredOptions2: Observable<string[]>;

    Financial_NI_NPV_EM;

    checkDDstatus;
    StatusIden = [
        { value: '1', text: 'Active' },
        { value: '2', text: 'Close (Turn to problem)' },
        { value: '3', text: 'Close (Mitigation Completed)' },
        { value: '4', text: 'Close (Other)' }
    ];

    tempDisplayFile = [];
    tempfileID;
    likelyhoodLevel;
    likelyhoodLevelAfter;
    ImpactLevel;
    ImpactLevelAfter;
    RiskCategory;
    ImpactCategory;
    Financial;
    CorpTargetHead;
    CorpTargetItem;
    Instruction;

    data_risk_table;
    list = {
        risk: {
            impactcolor: [],
            impact: [],
            risktable: [
                {
                    row: 1,
                    col: [
                        { col: 1, class: 'medium', item: [] },
                        { col: 2, class: 'medium', item: [] },
                        { col: 3, class: 'high', item: [] },
                        { col: 4, class: 'high', item: [] },
                        { col: 5, class: 'high', item: [] },
                    ]
                },
                {
                    row: 2,
                    col: [
                        { col: 1, class: 'medium', item: [] },
                        { col: 2, class: 'medium', item: [] },
                        { col: 3, class: 'medium', item: [] },
                        { col: 4, class: 'high', item: [] },
                        { col: 5, class: 'high', item: [] },
                    ]
                },
                {
                    row: 3,
                    col: [
                        { col: 1, class: 'low', item: [] },
                        { col: 2, class: 'medium', item: [] },
                        { col: 3, class: 'medium', item: [] },
                        { col: 4, class: 'medium', item: [] },
                        { col: 5, class: 'high', item: [] },
                    ]
                },
                {
                    row: 4,
                    col: [
                        { col: 1, class: 'low', item: [] },
                        { col: 2, class: 'low', item: [] },
                        { col: 3, class: 'medium', item: [] },
                        { col: 4, class: 'medium', item: [] },
                        { col: 5, class: 'medium', item: [] },
                    ]
                },
                {
                    row: 5,
                    col: [
                        { col: 1, class: 'low', item: [] },
                        { col: 2, class: 'low', item: [] },
                        { col: 3, class: 'low', item: [] },
                        { col: 4, class: 'medium', item: [] },
                        { col: 5, class: 'medium', item: [] },
                    ]
                }

            ],
            riskitems: []
        },
        riskprofile: {
            year: [],
            quarter: []
        },
        businessUnit: {
            items: []
        },
        field: {
            Mitigate: 1,
            riskprofileYear: '',
            riskprofileQuarter: '',
            businessUnit: '',
        }
    }

    QuarterDD;
    tableriskrating;
    ShowWPB = false;
    tablewpb = [];
    SelectWPB = '1';
    WBPID = null;
    myControlBUDD = new UntypedFormControl();
    BusinessNameDD = [];
    filteredOptionBUDD: Observable<string[]>;
    tempAssetBU = [];
    tempChildOrg = [];
    tempERMInfo = [];
    tempOrg = [];

    Preliminarylikelyhood;
    PreliminaryImpactLevel;

    chkbackmenu = 'go';
    tempselectbu = '';
    tempChkRoleBUDD = [];
    alert = '';
    TempQuarterID = '';

    constructor(
        public authserviceToken: AuthenService, 
        public dialog: MatDialog, 
        public mserve: Masterserve, 
        private router: Router,
        public Mapserve: RiskMap,private appServicecomp: MainProcessComponent,
        private dataService:ShareService
        ) { }
        appService= this.appServicecomp;
    ngOnInit(): void {
        $('.modal').on("hidden.bs.modal", function (e) {
            if ($('.modal:visible').length) {
                $('body').addClass('modal-open');
            }
        });

        if (sessionStorage.getItem('statusflow') === 'edit') {
            this.checkstatusupdate = 'edit'
        } else {
            this.checkstatusupdate = 'new'
        }

        this.loading = true;
        this.func_GETMASTER_Instruction()

        this.initData();

        this.Data_SessionStorage = JSON.parse(sessionStorage.datainfo);
        //console.log('Data_SessionStorage => ', this.Data_SessionStorage);
        this.Register_Data.Regis_Modified_By = this.Data_SessionStorage[0].employeE_ID;
        this.Register_Data.Regis_Role = sessionStorage.getItem('TranSecRole');
        this.loadDDL();

        this.initDataByRole();

        this.initDropdownListItems();

        this.filteredOptionBUDD = this.myControlBUDD.valueChanges.pipe(
            startWith(''),
            map(value => this._filterBUDD(value))
        );
        const _s = this;
        this.initModalBU5(_s,this.dataService);
        this.loading = false
    }

    private initDropdownListItems() {
        for (let itemtempAssetBU of this.tempAssetBU) {
            this.BusinessNameDD.push({ value: itemtempAssetBU.asset_Code, text: itemtempAssetBU.asset_Short, fullname: itemtempAssetBU.asset_Name, state: 'Asset', role: itemtempAssetBU.asset_Coordinators });
        }

        for (let itemtempOrg of this.tempOrg) {
            this.BusinessNameDD.push({ value: itemtempOrg.organizatioN_ID, text: itemtempOrg.abbreviation, fullname: itemtempOrg.organizatioN_NANE, state: 'Org', role: itemtempOrg.coordinator_Employee_Id });
        }

        for (let itemtempChildOrg of this.tempChildOrg) {
            this.BusinessNameDD.push({ value: itemtempChildOrg.organizatioN_ID, text: itemtempChildOrg.abbreviation, fullname: itemtempChildOrg.name, state: 'Org', role: '' });
        }

        for (let itemtempERMInfo of this.tempERMInfo) {
            this.BusinessNameDD.push({ value: itemtempERMInfo.organizatioN_ID, text: itemtempERMInfo.abbreviation, fullname: itemtempERMInfo.organizatioN_NANE, state: 'CORP', role: 'Co' });
        }
    }

    private initModalBU5(_s: any,dataService:any) {
        $('#modalBU5').on('hidden.bs.modal', function (e) {
            if (_s.chkbackmenu === 'go') {
                let tempBU = [];
                if (_s.tempselectbu !== '') {
                    _s.appService.showBUName = _s.tempselectbu;
                    _s.Register_Data.Regis_BusinessName = _s.tempselectbu;
                    sessionStorage.setItem('BUNameWork', _s.tempselectbu);
                    dataService.sendData( _s.tempselectbu);
                    for (let item_sBusinessNameDD of _s.BusinessNameDD) {
                        this.SetDropdownSessionStorage(_s, item_sBusinessNameDD, tempBU);
                    }
                    this.initModalBU5_setSessionStorage(tempBU, _s);
                    _s.Register_Data.Regis_BusinessUnit = tempBU[0].BuCode;
                    sessionStorage.setItem('BUValueWork', tempBU[0].BuCode);
                }
                else {
                    _s.alert = 'Please select Business Name';
                    $('#modalAlertBU').modal('show');
                }
            } else {
                _s.appService.status = 1;
                _s.appService.checkrole();
                _s.router.navigate(['/']);
            }
        });
    }

    SetDropdownSessionStorage(_s: this, item_sBusinessNameDD: any, tempBU: any[]) {
        if (_s.tempselectbu === item_sBusinessNameDD.text) {
            tempBU.push({ BuCode: item_sBusinessNameDD.value, State: item_sBusinessNameDD.state });

            for (let item_stempChkRoleBUDD of _s.tempChkRoleBUDD) {
                if ((item_stempChkRoleBUDD.text == _s.tempselectbu)
                    && (item_stempChkRoleBUDD.role == _s.Register_Data.Regis_Register_By 
                    || item_stempChkRoleBUDD.role == 'Co')) 
                {
                    _s.Register_Data.Regis_Role = 'Co';
                    sessionStorage.setItem("TranSecRole", 'Co');
                    break;
                }
                else if ((item_stempChkRoleBUDD.text == _s.tempselectbu)
                    && (item_stempChkRoleBUDD.role != _s.Register_Data.Regis_Register_By)) {
                    _s.Register_Data.Regis_Role = 'Staff';
                    sessionStorage.setItem("TranSecRole", 'Staff');
                }
            }
            //console.log('_s.Register_Data.Regis_Role : ', _s.Register_Data.Regis_Role);
        }
    }

    initModalBU5_setSessionStorage(tempBU: any[], _s: this) {
        if (tempBU[0].State === 'Asset') {
            _s.Register_Data.Regis_RiskType = 'Asset';
            sessionStorage.setItem('BUType', _s.Register_Data.Regis_RiskType);
        } else if (tempBU[0].State === 'Org') {
            _s.Register_Data.Regis_RiskType = 'Organization';
            sessionStorage.setItem('BUType', _s.Register_Data.Regis_RiskType);
        } else {
            _s.Register_Data.Regis_RiskType = 'Corporate';
            sessionStorage.setItem('BUType', _s.Register_Data.Regis_RiskType);
        }
    }

    private initDataByRole() {
        let sessionRoleStaff = (sessionStorage.Role === 'Staff');
        let sessionRoleOwner = (sessionStorage.Role === 'Owner');

        if (sessionRoleStaff 
            && sessionRoleOwner) {
            this.initDataByRole_SetDataStaffAndOwner();
        }
        else if (sessionStorage.Role === 'Co' 
                && (this.Data_SessionStorage[0].s_PERMISSSION_LEVEL.includes("OWNER") 
                || this.Data_SessionStorage[0].s_PERMISSSION_LEVEL.includes("Owner")
                )) {
            this.initDataByRole_SetCoOwnerData();
        }
        else if (sessionStorage.Role === 'Co') {
            this.initDataByRole_SetCoData();
        }
        else if (sessionStorage.Role === 'ERM') {
            this.initDataByRole_SetERMData();
        }
    }

    private initDataByRole_SetDataStaffAndOwner() {
        this.tempAssetBU = (this.Data_SessionStorage[0].assetInfo !== null) ? this.Data_SessionStorage[0].assetInfo : [];
        this.tempChildOrg = (this.Data_SessionStorage[0].childOrganizationInfo !== null) ? this.Data_SessionStorage[0].childOrganizationInfo : [];
    }

    private initDataByRole_SetCoOwnerData() {
        this.tempAssetBU = (this.Data_SessionStorage[0].assetInfo !== null) ? this.Data_SessionStorage[0].assetInfo : [];
        this.tempOrg = (this.Data_SessionStorage[0].coInfo !== null) ? this.Data_SessionStorage[0].coInfo : [];
    }

    private initDataByRole_SetCoData() {
        this.tempAssetBU = (this.Data_SessionStorage[0].assetInfo !== null) ? this.Data_SessionStorage[0].assetInfo : [];
        this.tempOrg = (this.Data_SessionStorage[0].coInfo !== null) ? this.Data_SessionStorage[0].coInfo : [];
        this.tempChildOrg = (this.Data_SessionStorage[0].childOrganizationInfo !== null) ? this.Data_SessionStorage[0].childOrganizationInfo : [];
    }

    private initDataByRole_SetERMData() {
        this.tempOrg = (this.Data_SessionStorage[0].coInfo !== null) ? this.Data_SessionStorage[0].coInfo : [];
        this.tempERMInfo = (this.Data_SessionStorage[0].ermInfo !== null) ? this.Data_SessionStorage[0].ermInfo : [];
        this.tempChildOrg = (this.Data_SessionStorage[0].childOrganizationInfo !== null) ? this.Data_SessionStorage[0].childOrganizationInfo : [];
    }

    private initData() {
        this.Register_Data = {
            Regis_RiskNo: '',
            Regis_RiskID: '',
            Regis_Riskname: '',
            Regis_Likelyhood: '',
            Regis_Impact: '',
            Regis_Status: '',
            Regis_Category: '',
            Regis_ObjectiveHead: '',
            Regis_Objective: '',
            Regis_KPI: '',
            Regis_Context: '',
            Regis_BusinessName: '',
            Regis_BusinessUnit: '',
            Regis_Register_By: '',
            Regis_Modified_By: '',
            Regis_Status_Workflow: '',
            Regis_Role: '',
            tempQuarter: '',
            tempYear: ''
        };

        this.Register_Rootcause = {
            RootCause_Id: '',
            Root_RootCause: '',
            Root_RootLikelyhood: '',
            Root_RootMitigationType: '',
            Root_AfterMitigated: '',
            Root_KRIName: '',
            Root_KRIThreshold_Green: '',
            Root_KRIThreshold_Red: '',
            Root_KRIStatus: '',
            Root_Justification: '',
            RootCause_Description: '',
            Root_Mitigation: [
                {
                    RootCauseMitigation_Name: '',
                    RootCauseMitigation_Owner: '',
                    RootCauseMitigation_DueDate: '',
                    RootCauseMitigation_Progress: 0,
                    RootCauseMitigation_Justification: '',
                    RootCauseMitigation_OwmerText: ''
                }
            ]
        };

        this.Register_Impact = {
            Impact_Id: '',
            Impact_Category: '',
            Impact_NPTEMV: '',
            Impact_Total_Amont: '',
            Impact_Description: '',
            Impact_Level: '',
            Impact_Migation_Type: '',
            Impact_After_Mitigated: '',
            Impact_Rating: '',
            Impact_Mitigation: [
                {
                    Risk_Id: '',
                    Impact_Id: '',
                    Impact_Mitigation_Id: '',
                    Impact_Mitigation_Name: '',
                    Impact_Mitigation_Owner: '',
                    Impact_Mitigation_DueDate: '',
                    Impact_Mitigation_Progress: 0,
                    Impact_Mitigation_Justification: '',
                    Impact_Mitigation_OwnerText: '',
                }
            ]
        };

        this.Edit_Impact = {
            ImpactPlan: '',
            ImpactOwner: '',
            ImpactDate: '',
            ImpactProgress: '',
            ImpactJustification: '',
            ImpactOwnertext: ''
        };
        this.Edit_Rootcause = {
            RootPlan: '',
            RootOwner: '',
            RootDate: '',
            RootProgress: '',
            RootJustification: '',
            RootOwnertext: ''
        };

        this.temptable = [];
        this.MitigationtypeRootcause = [
            { title: 'Take' },
            { title: 'Treat' }
        ];
        this.temp_mitigationRoottable = [];

        this.temptableImpact = [];
        this.MitigationTypeImpact = [
            { title: 'Take' },
            { title: 'Treat' },
            { title: 'Transfer' }
        ];
    }

    loadDDL() {
        const _s = this;
        this.loading = true;
        let d = new Date();
        let currentMonth = d.getMonth() + 1;
        _s.mserve.Master_DDL({ Module: "QuarterDD", TextSearch1: null }).then((response) => {
            _s.QuarterDD = response.data.body
            _s.Quarter = response.data.body
            //console.log("QuarterDD : ", _s.QuarterDD)
            this.func_calQuarter(currentMonth);

            this.func_GETDATA('Getmaster')
        })
    }
    func_calQuarter(month) {
        const _s = this;
        _s.list.field.riskprofileQuarter = ''
        if (month >= 1 && month <= 3) {
            _s.list.field.riskprofileQuarter = this.QuarterDD[0].value;
            _s.TempQuarterID = this.QuarterDD[0].value;
        } else if (month >= 4 && month <= 6) {
            _s.list.field.riskprofileQuarter = this.QuarterDD[1].value;
            _s.TempQuarterID = this.QuarterDD[1].value;
        } else if (month >= 7 && month <= 9) {
            _s.list.field.riskprofileQuarter = this.QuarterDD[2].value;
            _s.TempQuarterID = this.QuarterDD[2].value;
        } else if (month >= 10 && month <= 12) {
            _s.list.field.riskprofileQuarter = this.QuarterDD[3].value;
            _s.TempQuarterID = this.QuarterDD[3].value;
        }
        this.func_GETDATA_RiskRating();
    }
    func_GETDATA_RiskRating() {
        const _s = this;
        let formdata = {
            SessionEmpID: 1,
            Module: "getlist",
            body: {
                RiskRatingID: "",
                QuarterID: _s.list.field.riskprofileQuarter,
                RiskRatingCode: "Test0002",
                Likelihood: "2",
                Impact: "3",
                RiskRating: "",
                EscalationLevel: "1",
                DelFlag: "0",
                LikelihoodAndImpact: ""
            }
        }
        this.mserve.GetRiskRating(formdata).then((response) => {
            //console.log("RiskRating Data :", response.data.body)
            if (response.data.status ){
                this.tableriskrating = response.data.body;
                this.func_calExculationLevel();
            } else {
                //console.log('false', response)
                alert(response.data.errorMessage);
            }
        }).catch((e) => {
            this.authserviceToken.CheckTokenExpire(e.response.status)
           
        });
    }
    func_calExculationLevel() {
        const _s = this;
        let tempMedEsc = []
        _s.tableriskrating.forEach(function (ItemVal, ItemIndex) {
            if (ItemVal.riskRating === "Medium" && ItemVal.escalationLevel === "1") {
                tempMedEsc.push(ItemVal)
            }
        });
        //console.log('tempMedium : ', tempMedEsc)

        //console.log('_s.list.risk.risktable : ', _s.list.risk.risktable)

        for (let item_tempMedEsc of tempMedEsc) {
            if(item_tempMedEsc.impact === "Minor")
            {
                this.func_calExculationLevel_SetMinor(item_tempMedEsc, _s);
            }
            else if(item_tempMedEsc.impact === "Moderate")    
            {
                this.func_calExculationLevel_SetModerate(item_tempMedEsc, _s);
            }        

            else if(item_tempMedEsc.impact === "Significant" )
            {
                this.func_calExculationLevel_SetSignificant(item_tempMedEsc, _s);
            }          
            if(item_tempMedEsc.impact === "Serious")
            {
                this.func_calExculationLevel_SetSerious(item_tempMedEsc, _s);
            }            

            if(item_tempMedEsc.impact === "Critical")
            {
                this.func_calExculationLevel_SetCritical(item_tempMedEsc, _s);
            }            
        }

        this.func_GetWPB();

        this.func_GETMASTER_PreliminaryLikelihood();
        //console.log('_s.list.risk.risktable with esclevel : ', _s.list.risk.risktable)

    }
    private func_calExculationLevel_SetMinor(item_tempMedEsc: any, _s: this) {
        if (item_tempMedEsc.likelihood === "Likely") {
            _s.list.risk.risktable[4].col[4].class = "Escalation";
        }
        else if (item_tempMedEsc.likelihood === "Almost Certain") {
            _s.list.risk.risktable[4].col[5].class = "Escalation";
        }
    }

    private func_calExculationLevel_SetSignificant(item_tempMedEsc: any, _s: this) {
        if (item_tempMedEsc.likelihood === "Unlikely") {
            _s.list.risk.risktable[2].col[2].class = "Escalation";
        }
        else if (item_tempMedEsc.likelihood === "Possible") {
            _s.list.risk.risktable[2].col[3].class = "Escalation";
        }
        else if (item_tempMedEsc.likelihood === "Likely") {
            _s.list.risk.risktable[2].col[4].class = "Escalation";
        }
    }

    private func_calExculationLevel_SetSerious(item_tempMedEsc: any, _s: this) {
        if (item_tempMedEsc.likelihood === "Rare") {
            _s.list.risk.risktable[1].col[1].class = "Escalation";
        }
        else if (item_tempMedEsc.likelihood === "Unlikely") {
            _s.list.risk.risktable[1].col[2].class = "Escalation";
        }
        else if (item_tempMedEsc.likelihood === "Possible") {
            _s.list.risk.risktable[1].col[3].class = "Escalation";
        }
    }

    private func_calExculationLevel_SetCritical(item_tempMedEsc: any, _s: this) {
        if (item_tempMedEsc.likelihood === "Rare") {
            _s.list.risk.risktable[0].col[1].class = "Escalation";
        }
        else if (item_tempMedEsc.likelihood === "Unlikely") {
            _s.list.risk.risktable[0].col[2].class = "Escalation";
        }
    }

    private func_calExculationLevel_SetModerate(item_tempMedEsc: any, _s: this) {
        if (item_tempMedEsc.likelihood === "Possible") {
            _s.list.risk.risktable[3].col[3].class = "Escalation";
        }
        else if (item_tempMedEsc.likelihood === "Likely") {
            _s.list.risk.risktable[3].col[4].class = "Escalation";
        }
        else if (item_tempMedEsc.likelihood === "Almost Certain") {
            _s.list.risk.risktable[3].col[5].class = "Escalation";
        }
    }

    func_GetWPB() {
        let formData = {
            SessionEmpID: "1",
            Module: "getlist",
            body: {
                Year: "",
                StartDate: "",
                EndDate: "",
            }
        }
        this.mserve.GetWPB(formData).then((response) => {
            this.func_GetWPB_ProcessResponse(response);
        }).catch((e) => {
            this.authserviceToken.CheckTokenExpire(e.response.status)
           
        });
    }
    private func_GetWPB_ProcessResponse(response) {
        if (response.data.status) {
            //console.log("Get Data WPB : ", response.data.body);
            this.tablewpb = response.data.body;
            let d = new Date();
            let day = (d.getDate());
            let month = (d.getMonth() + 1);
            let year = (d.getFullYear());

            let date = year + '-' + month + '-' + day;
            //console.log("Date : ", date);

            for (let item_tablewpb of this.tablewpb) {
                let tempStartday = null, tempStartmonth = null, tempStartyear = null;
                let tempEndday = null, tempEndmonth = null, tempEndyear = null;

                ({ tempStartyear, tempStartmonth, tempStartday } = this.func_GetWPB_ProcessResponse_PrepareStartYearVariable(item_tablewpb));

                ({ tempEndyear, tempEndmonth, tempEndday } = this.func_GetWPB_ProcessResponse_PrepareEndYearVariable(item_tablewpb));

                this.func_GetWPB_ProcessResponse_CheckStartYear(year, tempStartyear, month, tempStartmonth, day, tempStartday, item_tablewpb);


                this.func_GetWPB_ProcessResponse_CheckEndYear(year, tempEndyear, month, tempEndmonth, day, tempEndday, item_tablewpb);

            }

     
            let businessNameDD = (this.BusinessNameDD.length !== 1);
            if ( businessNameDD) {
                setTimeout(function () { $('#modalBU5').modal('show'); }, 800);
            }
            this.loading = false;
        }
        else {
            //console.log('false', response);
            alert(response.data.errorMessage);
        }
    }

    private func_GetWPB_ProcessResponse_PrepareStartYearVariable(item_tablewpb: any) {
        let tempStrStartyear = item_tablewpb.startDate.substr(0, 4);
        let tempStrStartmonth = item_tablewpb.startDate.substr(5, 2);
        let tempStrStartday = item_tablewpb.startDate.substr(8, 2);
        let tempStartyear = parseInt(tempStrStartyear);
        let tempStartmonth = parseInt(tempStrStartmonth);
        let tempStartday = parseInt(tempStrStartday);
        return { tempStartyear, tempStartmonth, tempStartday };
    }

    private func_GetWPB_ProcessResponse_PrepareEndYearVariable(item_tablewpb: any) {
        let strEndyear = item_tablewpb.endDate.substr(0, 4);
        let strEndmonth = item_tablewpb.endDate.substr(5, 2);
        let strEndday = item_tablewpb.endDate.substr(8, 2);
        let tempEndyear = parseInt(strEndyear);
        let tempEndmonth = parseInt(strEndmonth);
        let tempEndday = parseInt(strEndday);
        return { tempEndyear, tempEndmonth, tempEndday };
    }

    private func_GetWPB_ProcessResponse_CheckEndYear(year: number, tempEndyear: any, month: number, tempEndmonth: any, day: number, tempEndday: any, item_tablewpb: any) {
        if (year == tempEndyear) {
            if (month == tempEndmonth) {
                if (day <= tempEndday) {
                    this.ShowWPB = true;
                    this.SelectWPB  =  '2';
                    this.WBPID = item_tablewpb.wpbId;
                } else {
                    this.ShowWPB = false;
                }
            }
        }
        else if (year <= tempEndyear) {
            this.ShowWPB = true;
            this.SelectWPB  =  '2';
            this.WBPID = item_tablewpb.wpbId;
        }
        else {
            this.ShowWPB = false;
        }
    }

    private func_GetWPB_ProcessResponse_CheckStartYear(year: number, tempStartyear: any, month: number, tempStartmonth: any, day: number, tempStartday: any, item_tablewpb: any) {
        if (year == tempStartyear) {
            if (month == tempStartmonth) {
                if (day >= tempStartday) {
                    this.ShowWPB = true;
                    this.SelectWPB  =  '2';
                    this.WBPID = item_tablewpb.wpbId;
                }
                else {
                    this.ShowWPB = false;
                }
            }
        }
        else if (year >= tempStartyear) {
            this.ShowWPB = true;
            this.SelectWPB  =  '2';
            this.WBPID = item_tablewpb.wpbId;
        }
        else {
            this.ShowWPB = false;
        }
    }
//     goHome(){
 

//         var path   = sessionStorage.getItem('mainUrl');
//         window.location.replace(path);
    
//   }
    goHome() {

        let cleanPath = DOMPurify.sanitize(sessionStorage.getItem('mainUrl'));
        window.location.replace(cleanPath);

    }
    func_GETMASTER_PreliminaryLikelihood() {
        let tempQuarter = (this.Register_Data.tempQuarter == '') ? this.list.field.riskprofileQuarter : this.Register_Data.tempQuarter;

        let formData = {
            Module: "PrelimiLikelihoodDD",
            TextSearch7: tempQuarter
        }
        this.mserve.Master_DDL(formData).then((response) => {
            //console.log("dataPreliminaryLikelihood_DD:", response.data.body)
            if (response.data.status ){
                this.Preliminarylikelyhood = response.data.body
                this.func_GETMASTER_PreliminaryImpact()
            } else {
                //console.log('false', response)
            }
        });
    }
    func_GETMASTER_PreliminaryImpact() {
        let tempQuarter = (this.Register_Data.tempQuarter == '') ? this.list.field.riskprofileQuarter : this.Register_Data.tempQuarter;

        let formData = {
            Module: "PrelimiImpactDD",
            TextSearch7: tempQuarter
        }
        this.mserve.Master_DDL(formData).then((response) => {
            //console.log("dataPreliminaryImpactLevel_DD:", response.data.body)
            if (response.data.status ){
                this.PreliminaryImpactLevel = response.data.body;
                this.loading = false;
            } else {
                //console.log('false', response)
            }
        });
    }
    func_GETDATA(value) {
        const _s = this;
        this.Register_Data.tempQuarter = this.TempQuarterID;

        for (let item_Quarter of this.Quarter) {
            if (this.Register_Data.tempQuarter == item_Quarter.value) {
                this.Register_Data.tempYear = item_Quarter.text2;
                break;
            }
        }

        this.func_GETMASTER_Financial()
        if (value === 'Getmaster') {
            this.func_GETMASTER_ImpactCategory()
            this.func_GETMASTER_Likelihood()
            this.func_GETMASTER_RiskCategory()
            this.func_GETMASTER_ImpactLevel()
            this.func_GETMASTER_CorptargetHead()
            this.func_GetImpactRiskMap()
            this.func_GetDDL_BusinessName()

        }
    }

    func_UPDATEDATA(value) {
        const _s = this;
        let statusSavenochk;
        let chkasses, chkreqroot, chkreqrootarr = [], chkreqImpact, chkreqImpactarr = [];
        chkasses = this.func_UPDATEDATA_CheckValidateAndShowModal(value, chkasses);

        chkreqroot = this.func_UPDATEDATA_ProcessRootcauseData(chkasses, value, _s, chkreqrootarr, chkreqroot);

        chkreqImpact = this.func_UPDATEDATA_CheckRequestImpact(chkasses, chkreqroot, value, _s, chkreqImpactarr, chkreqImpact);

        ({ chkreqroot, chkreqImpact, statusSavenochk } = this.func_UPDATEDATA_CheckSaveStatus({
            value: value,
            chkasses: chkasses,
            _s: _s,
            chkreqrootarr: chkreqrootarr,
            checkreqroot: chkreqroot,
            chkreqImpactarr: chkreqImpactarr,
            checkreqImpact: chkreqImpact,
            statusSavenoCheck: statusSavenochk,
          }));



        if ((chkreqroot === true && chkreqImpact === true) || value === false) {
            let statusflow;
            if (value === false) {
                statusflow = statusSavenochk;
            } else {
                statusflow = '0'
            }

            this.loading = true
            let formData = {
                SessionEmpID: "0001",
                Module: "UPDATE",
                body: {
                    Risk_Id: this.Register_Data.Regis_RiskID,
                    Risk_Name: this.Register_Data.Regis_Riskname,
                    Risk_Likelihood: this.Register_Data.Regis_Likelyhood,
                    Risk_Impact: this.Register_Data.Regis_Impact,
                    Risk_Status: this.Register_Data.Regis_Status,
                    Risk_Category: this.Register_Data.Regis_Category,
                    Risk_Objective_Parent: this.Register_Data.Regis_ObjectiveHead,
                    Risk_Objective: this.Register_Data.Regis_Objective,
                    Risk_Unit_KPI: this.Register_Data.Regis_KPI,
                    Risk_Context: this.Register_Data.Regis_Context,
                    Risk_Business_Unit_Abbreviation: this.Register_Data.Regis_BusinessName,
                    Risk_Business_Unit: this.Register_Data.Regis_BusinessUnit,
                    Risk_Modified_By: this.Register_Data.Regis_Modified_By,
                    Risk_Status_Workflow: statusflow,
                    Role: this.Register_Data.Regis_Role

                }
            }
            this.mserve.RegisterRisk(formData).then((response) => {
                //console.log("data:", response.data.body)
                if (response.data.status ){
                    setTimeout(function () { _s.loading = false }, 300)
                    //console.log("true", response.data.body)
                    if (value === true) {
                        this.router.navigate(['/ReviewPage']);
                    }
                } else {
                    this.alertflow = response.data.errorMessage
                    $('#modalAlertdanger5').modal('show');
                    //console.log('false', response)
                }
            }).catch((e) => {
                this.authserviceToken.CheckTokenExpire(e.response.status)
           
            });
        }
    }
    
    private func_UPDATEDATA_CheckSaveStatus(params: SaveStatusParams) {
        let chkreqroot = params.checkreqroot;
        let chkreqImpact = params.checkreqImpact;
        let statusSavenochk = params.statusSavenoCheck;

        if (params.value === false) {
            chkreqroot = this.func_UPDATEDATA_CheckSaveStatus_RootCauseCheck1(
                params.chkasses,
                params.value,
                params._s,
                params.chkreqrootarr,
                chkreqroot
            );

            if (
                this.Tempdata.impact.length !== 0 &&
                params.chkasses === true &&
                chkreqroot === true &&
                params.value === false
            ) {
                for (let item_impact_I4 of this.Tempdata.impact) {
                    if (item_impact_I4.impact_Mitigation_Type !== 'Take') {
                        chkreqImpact = this.func_UPDATEDATA_CheckSaveStatus_RootCauseCheck2(
                            params._s,
                            params.chkreqImpactarr,
                            chkreqImpact
                        );
                    } else if (chkreqImpact !== false) {
                        chkreqImpact = true;
                    }
                }
            } else {
                chkreqImpact = false;
            }

            statusSavenochk = this.func_UPDATEDATA_CheckSaveStatus_SetStatusSavenochk(chkreqroot, chkreqImpact, statusSavenochk);
    }

    return { chkreqroot, chkreqImpact, statusSavenochk };
    }

    private func_UPDATEDATA_CheckSaveStatus_SetStatusSavenochk(chkreqroot: any, chkreqImpact: any, statusSavenochk: any) {
        if (chkreqroot === true && chkreqImpact === true) {
            statusSavenochk = '0';
        } else {
            statusSavenochk = '23';
        }
        return statusSavenochk;
    }

    private func_UPDATEDATA_CheckSaveStatus_RootCauseCheck2(_s: this, chkreqImpactarr: any[], checkreqImpact: any) {
        let chkreqImpact=checkreqImpact
        if (_s.Tempdata.impact_Mitigation.length !== 0) {
            this.func_UPDATEDATA_CheckSaveStatus_ProcessImpactArrayValue(_s, chkreqImpactarr);
            this.func_UPDATEDATA_CheckSaveStatus_CheckImpactDuplicate(chkreqImpactarr);
            chkreqImpact = this.func_UPDATEDATA_CheckSaveStatus_CheckHasFalseValue(chkreqImpactarr, chkreqImpact);
            for (let item_impact_I3 of _s.Tempdata.impact) {
                if ((item_impact_I3.impact_After_Mitigated === null || item_impact_I3.impact_After_Mitigated === '') && chkreqImpact === true && item_impact_I3.impact_Mitigation_Type !== 'Take') {
                    chkreqImpact = false;
                }
            }
        } else {
            chkreqImpact = false;
        }
        return chkreqImpact;
    }

    private func_UPDATEDATA_CheckSaveStatus_CheckHasFalseValue(chkreqImpactarr: any[], checkreqImpact: any) {
        let chkreqImpact=checkreqImpact;
        for (let item_chkreqImpactarr of chkreqImpactarr) {
            if (item_chkreqImpactarr.value === false) {
                chkreqImpact = false;
                break;
            } else {
                chkreqImpact = true;
            }
        }
        return chkreqImpact;
    }

    private func_UPDATEDATA_CheckSaveStatus_CheckImpactDuplicate(chkreqImpactarr: any[]) {
        for (let i = 0; i < chkreqImpactarr.length; i++) {
            if (i !== 0) {
                if ((chkreqImpactarr[i].id === chkreqImpactarr[i - 1].id)) {
                    let temid = chkreqImpactarr[i].id;
                    let tempval = chkreqImpactarr[i].value;

                    for (let ii = 0; ii < i; ii++) {
                        if (chkreqImpactarr[ii].id === temid) {
                            chkreqImpactarr[ii].id = temid;
                            chkreqImpactarr[ii].value = tempval;
                        }
                    }

                }
            }
        }
    }

    private func_UPDATEDATA_CheckSaveStatus_ProcessImpactArrayValue(_s: this, chkreqImpactarr: any[]) {
        for (let item_impact of _s.Tempdata.impact) {
            for (let item_impact_Mitigation_I2 of _s.Tempdata.impact_Mitigation) {
                if ((item_impact.impact_Id.includes(item_impact_Mitigation_I2.impact_Id)) 
                && item_impact.impact_Mitigation_Type !== 'Take') {
                    chkreqImpactarr.push({ id: item_impact.impact_Id, value: true });
                    break;
                }
                else if (item_impact.impact_Mitigation_Type !== 'Take') {
                    chkreqImpactarr.push({ id: item_impact.impact_Id, value: false });
                }
            }
        }
    }

    private func_UPDATEDATA_CheckSaveStatus_RootCauseCheck1(chkasses: any, value: any, _s: this, chkreqrootarr: any[], checkreqroot: any) {
        let chkreqroot=checkreqroot;
        if ((this.Tempdata.rootCause.length !== 0 && chkasses === true) && value === false) {

            for (let itemTempdata_rootCauseof of this.Tempdata.rootCause) {
                if (itemTempdata_rootCauseof.rootCause_Mitigation_Type !== 'Take') {

                    if (_s.Tempdata.rootCause_Mitigation.length !== 0) {

                        this.func_UPDATEDATA_CheckSaveStatus_RootCauseMitigation1(_s, chkreqrootarr);

                        this.func_UPDATEDATA_CheckSaveStatus_CheckRootArr1(chkreqrootarr);
                        chkreqroot = this.func_UPDATEDATA_CheckSaveStatus_CheckRootArr2(chkreqrootarr, chkreqroot);
                        chkreqroot = this.func_UPDATEDATA_CheckSaveStatus_CheckRootMitigation3(_s, chkreqroot);
                    } else {
                        chkreqroot = false;
                    }

                }
                else if (chkreqroot !== false) {
                    chkreqroot = true;
                }
            }

        } else {
            chkreqroot = false;
        }
        return chkreqroot;
    }

    private func_UPDATEDATA_CheckSaveStatus_CheckRootMitigation3(_s: this, checkreqroot: any) {
        let chkreqroot=checkreqroot;
        for (let item_rootCause of _s.Tempdata.rootCause) {
            if ((item_rootCause.rootCause_After_Mitigated === null || item_rootCause.rootCause_After_Mitigated === '') && chkreqroot === true && item_rootCause.rootCause_Mitigation_Type !== 'Take') {
                chkreqroot = false;
            }
        }
        return chkreqroot;
    }

    private func_UPDATEDATA_CheckSaveStatus_CheckRootArr2(chkreqrootarr: any[], checkreqroot: any) {
        let chkreqroot=checkreqroot;
        for (let item_chkreqrootarr of chkreqrootarr) {
            if (item_chkreqrootarr.value === false) {
                chkreqroot = false;
                break;
            } else {
                chkreqroot = true;
            }
        }
        return chkreqroot;
    }

    private func_UPDATEDATA_CheckSaveStatus_CheckRootArr1(chkreqrootarr: any[]) {
        for (let [index, itemchkreqrootarr] of chkreqrootarr.entries()) {
            if (index !== 0) {
                if ((itemchkreqrootarr.id === chkreqrootarr[index - 1].id)) {
                    let temid = itemchkreqrootarr.id;
                    let tempval = itemchkreqrootarr.value;

                    for (let ii = 0; ii < index; ii++) {
                        if (chkreqrootarr[ii].id === temid) {
                            chkreqrootarr[ii].id = temid;
                            chkreqrootarr[ii].value = tempval;
                        }
                    }

                }
            }
        }
    }

    private func_UPDATEDATA_CheckSaveStatus_RootCauseMitigation1(_s: this, chkreqrootarr: any[]) {
        for (let item_sTempdata_rootCauseof of _s.Tempdata.rootCause) {
            for (let item_sTempdata_rootCauseofrootCause_Mitigation of _s.Tempdata.rootCause_Mitigation) {
                if ((item_sTempdata_rootCauseof.rootCause_Id === item_sTempdata_rootCauseofrootCause_Mitigation.rootCause_Id)
                    && item_sTempdata_rootCauseof.rootCause_Mitigation_Type !== 'Take') {
                    chkreqrootarr.push({ id: item_sTempdata_rootCauseof.rootCause_Id, value: true });
                    break;
                }
                else if (item_sTempdata_rootCauseof.rootCause_Mitigation_Type !== 'Take') {
                    chkreqrootarr.push({ id: item_sTempdata_rootCauseof.rootCause_Id, value: false });
                }
            }
        }
    }

    private func_UPDATEDATA_CheckRequestImpact(chkasses: any, chkreqroot: any, value: any, _s: this, chkreqImpactarr: any[], checkreqImpact: any) {
        let chkreqImpact=checkreqImpact;
        if ((this.Tempdata.impact.length !== 0 && chkasses === true && chkreqroot === true) && value === true) {
            for (let itemTempdataimpact of this.Tempdata.impact) {
                chkreqImpact = this.func_UPDATEDATA_CheckRequestImpact_ProcessMitigationType(itemTempdataimpact, _s, chkreqImpactarr, chkreqImpact);
            }

        }
        return chkreqImpact;
    }

    private func_UPDATEDATA_CheckRequestImpact_ProcessMitigationType(itemTempdataimpact: any, _s: this, chkreqImpactarr: any[], chkreqImpact: any) {
        if (itemTempdataimpact.impact_Mitigation_Type !== 'Take') {

            if (_s.Tempdata.impact_Mitigation.length !== 0) {

                this.func_UPDATEDATA_CheckRequestImpact_Loop1(_s, chkreqImpactarr);

                this.func_UPDATEDATA_CheckSaveStatus_CheckImpactDuplicate(chkreqImpactarr);

                chkreqImpact = this.func_UPDATEDATA_CheckSaveStatus_CheckHasFalseValue(chkreqImpactarr, chkreqImpact);

                if (chkreqImpact === false) {
                    _s.alertflow = 'Please add all Impact Mitigation Plan in Impact';
                    $('#modalAlertdanger5').modal('show');
                }

                chkreqImpact = this.func_UPDATEDATA_CheckRequestImpact_Loop4(_s, chkreqImpact);
            } else {
                _s.alertflow = 'Please add all Impact Mitigation Plan in Impact';
                $('#modalAlertdanger5').modal('show');
                chkreqImpact = false;
            }

        }
        else if (chkreqImpact !== false) {
            chkreqImpact = true;
        }
        return chkreqImpact;
    }

    private func_UPDATEDATA_CheckRequestImpact_Loop4(_s: this, checkreqImpact: any) {
        let chkreqImpact=checkreqImpact;
        for (let item_s_Tempdata_impact of _s.Tempdata.impact) {
            if ((item_s_Tempdata_impact.impact_After_Mitigated === null || item_s_Tempdata_impact.impact_After_Mitigated === '') && chkreqImpact === true && item_s_Tempdata_impact.impact_Mitigation_Type !== 'Take') {
                this.alertflow = 'Please select Impact Level after Mitigated';
                $('#modalAlertdanger5').modal('show');
                chkreqImpact = false;
            }
        }
        return chkreqImpact;
    }





    private func_UPDATEDATA_CheckRequestImpact_Loop1(_s: this, chkreqImpactarr: any[]) {
        for (let item_sTempdataimpact of _s.Tempdata.impact) {

            for (let item_sTempdataimpact_Mitigation of _s.Tempdata.impact_Mitigation) {
                if ((item_sTempdataimpact.impact_Id.includes(item_sTempdataimpact_Mitigation.impact_Id)) && item_sTempdataimpact.impact_Mitigation_Type !== 'Take') {
                    chkreqImpactarr.push({ id: item_sTempdataimpact.impact_Id, value: true });
                    break;
                }
                else if (item_sTempdataimpact.impact_Mitigation_Type !== 'Take') {
                    chkreqImpactarr.push({ id: item_sTempdataimpact.impact_Id, value: false });
                }
            }
        }
    }

    private func_UPDATEDATA_ProcessRootcauseData(chkasses: any, value: any, _s: this, chkreqrootarr: any[], checkreqroot: any) {
        let chkreqroot=checkreqroot;
        if ((this.Tempdata.rootCause.length !== 0 && chkasses === true) && value === true) {
            for (let item_rootCause_I4 of this.Tempdata.rootCause) {
                chkreqroot = this.func_UPDATEDATA_ProcessRootcauseData_ProcessMitigationType(item_rootCause_I4, _s, chkreqroot, chkreqrootarr);
            }

        }
        return chkreqroot;
    }

    private func_UPDATEDATA_ProcessRootcauseData_ProcessMitigationType(item_rootCause_I4: any, _s: this, chkreqroot: any, chkreqrootarr: any[]) {
        if (item_rootCause_I4.rootCause_Mitigation_Type !== 'Take') {
            if (_s.Tempdata.rootCause_Mitigation.length !== 0) {
                chkreqroot = this.func_UPDATEDATA_ProcessRootcauseMitigation(_s, chkreqrootarr, chkreqroot);
            } else {
                _s.alertflow = 'Please add all Root Cause Mitigation Plan in Root Cause';
                $('#modalAlertdanger5').modal('show');
                chkreqroot = false;
            }

        }
        else if (chkreqroot !== false) {
            chkreqroot = true;
        }
        return chkreqroot;
    }

    private func_UPDATEDATA_ProcessRootcauseMitigation(_s: this, chkreqrootarr: any[], chkreqroot: any) {
        this.func_UPDATEDATA_ProcessRootcauseMitigationArr1(_s, chkreqrootarr);

        this.func_UPDATEDATA_ProcessRootcauseMitigation_SetReqArrID(chkreqrootarr);

        chkreqroot = this.func_UPDATEDATA_CheckSaveStatus_CheckRootArr2(chkreqrootarr, chkreqroot);

        if (chkreqroot === false) {
            _s.alertflow = 'Please add all Root Cause Mitigation Plan in Root Cause';
            $('#modalAlertdanger5').modal('show');
        }

        chkreqroot = this.func_UPDATEDATA_ProcessRootcauseMitigation_CheckNullLiklihood(_s, chkreqroot);
        return chkreqroot;
    }

    private func_UPDATEDATA_ProcessRootcauseMitigation_CheckNullLiklihood(_s: this, checkreqroot: any) {
        let chkreqroot=checkreqroot
        for (let itemTempdata of _s.Tempdata.rootCause) {
            if ((itemTempdata.rootCause_After_Mitigated === null || itemTempdata.rootCause_After_Mitigated === '') && chkreqroot === true && itemTempdata.rootCause_Mitigation_Type !== 'Take') {
                this.alertflow = 'Please select Likelihood after Mitigated';
                $('#modalAlertdanger5').modal('show');
                chkreqroot = false;
            }
        }
        return chkreqroot;
    }



    private func_UPDATEDATA_ProcessRootcauseMitigation_SetReqArrID(chkreqrootarr: any[]) {
        for (let i = 0; i < chkreqrootarr.length; i++) {
            if (i !== 0) {
                if ((chkreqrootarr[i].id === chkreqrootarr[i - 1].id)) {
                    let temid = chkreqrootarr[i].id;
                    let tempval = chkreqrootarr[i].value;

                    for (let ii = 0; ii < i; ii++) {
                        if (chkreqrootarr[ii].id === temid) {
                            chkreqrootarr[ii].id = temid;
                            chkreqrootarr[ii].value = tempval;
                        }
                    }

                }
            }
        }
    }

    private func_UPDATEDATA_ProcessRootcauseMitigationArr1(_s: this, chkreqrootarr: any[]) {
        for (let item_rootCause of _s.Tempdata.rootCause) {
            for (let item_Tempdata_rootCause_Mitigation of _s.Tempdata.rootCause_Mitigation) {
                if ((item_rootCause.rootCause_Id === item_Tempdata_rootCause_Mitigation.rootCause_Id) && item_rootCause.rootCause_Mitigation_Type !== 'Take') {
                    chkreqrootarr.push({ id: item_rootCause.rootCause_Id, value: true });
                    break;
                }
                else if (item_rootCause.rootCause_Mitigation_Type !== 'Take') {
                    chkreqrootarr.push({ id: item_rootCause.rootCause_Id, value: false });
                }
            }
        }
    }

    private func_UPDATEDATA_CheckValidateAndShowModal(value: any, checkasses: any) {

        if (this.Register_Data.Regis_Category === '' && value === true) {
            this.alertflow = 'Please select Risk Category';
            checkasses = false;
            $('#modalAlertdanger5').modal('show');
        } else if ((this.Register_Data.Regis_ObjectiveHead === '' || this.Register_Data.Regis_Objective === '') && value === true) {
            this.alertflow = 'Please select all Corporate Target/Objective';
            $('#modalAlertdanger5').modal('show');
            checkasses = false;
        } else if (this.Tempdata.rootCause.length === 0 && value === true) {
            this.alertflow = 'Please add Root Cause';
            $('#modalAlertdanger5').modal('show');
            checkasses = false;
        } else if (this.Tempdata.impact.length === 0 && value === true) {
            this.alertflow = 'Please add Impact';
            $('#modalAlertdanger5').modal('show');
            checkasses = false;
        } else {
            checkasses = true;
        }
        return checkasses;
    }

    func_GETMASTER_Likelihood() {
        let formData = {
            Module: "LikelihoodLevelDD",
            TextSearch7: this.Register_Data.tempQuarter
        }
        this.mserve.Master_DDL(formData).then((response) => {
            //console.log("dataLikelihood_DD:", response.data.body)
            if (response.data.status ){
                this.likelyhoodLevel = response.data.body
                this.likelyhoodLevelAfter = response.data.body
            } else {
                //console.log('false', response)
            }
        });
    }
    func_GETMASTER_ImpactCategory() {
        let formData = {
            Module: "ImpactCateDD",
            TextSearch7: this.Register_Data.tempQuarter
        }
        this.mserve.Master_DDL(formData).then((response) => {
            //console.log("dataImpactCategory_DD:", response.data.body)
            if (response.data.status ){
                this.ImpactCategory = response.data.body

            } else {
                //console.log('false', response)
            }
        });
    }
    func_GETMASTER_RiskCategory() {
        let chkDDper;
        chkDDper = (sessionStorage.Role === 'ERM') ? '' : '1';

        let formData = {
            Module: "RiskCateDD",
            TextSearch1: chkDDper,
            TextSearch7: this.Register_Data.tempQuarter
        }
        this.mserve.Master_DDL(formData).then((response) => {
            //console.log("dataRiskCate_DD:", response.data.body)
            if (response.data.status ){
                this.RiskCategory = response.data.body
            } else {
                //console.log('false', response)
            }
        });
    }
    func_GETMASTER_ImpactLevel() {
        let formData = {
            Module: "ImpactLevelDD",
            TextSearch1: this.Register_Data.Regis_BusinessUnit,
            TextSearch7: this.Register_Data.tempQuarter
        }
        this.mserve.Master_DDL(formData).then((response) => {
            //console.log("dataImpactLevel_DD:", response.data.body)
            if (response.data.status ){
                this.ImpactLevel = response.data.body
                this.ImpactLevelAfter = response.data.body
            } else {
                //console.log('false', response)
            }
        }).catch((error) => {
            //console.log(error);
        });
    }
    func_GETMASTER_Financial() {
        let formData = {
            Module: "ImpactType",
        }
        this.mserve.Master_DDL(formData).then((response) => {
            //console.log("dataImpactfinancial_DD:", response.data.body)
            if (response.data.status ){
                this.Financial = response.data.body;
                if (this.Tempdata.impact.length !== 0) {
                    this.Assert_ImpactTable = [];
                    let show_Category = [];
                    let maxImpct = Math.max(...this.Tempdata.impact.map(o => o.impact_Level));
                    for (let item_impact of this.Tempdata.impact){
                        this.func_GETMASTER_Financial_SetShowCategory(item_impact, maxImpct, show_Category);
                    }

                    this.Assert_ImpactTable = show_Category
                } else {
                    this.Assert_ImpactTable = [];
                }

            } else {
                //console.log('false', response)
            }
        }).catch((error) => {
            //console.log(error);
        });
    }
    private func_GETMASTER_Financial_SetShowCategory(item_impact: any, maxImpct: number, show_Category: any[]) {
        let isMaxImpct = 'N';
        if (item_impact.impact_Level == maxImpct) {
            isMaxImpct = 'Y';
        }
        if (item_impact.impact_NPT_EMV !== null) {
            for (let item_Financial of this.Financial) {
                if (item_impact.impact_NPT_EMV === item_Financial.value) {
                    show_Category.push({
                        id: item_impact.impact_Id,
                        title: item_Financial.text,
                        highlight: isMaxImpct
                    });
                    break;
                }
            }
        }
        else {
            show_Category.push({
                id: item_impact.impact_Id,
                title: item_impact.impact_Description,
                highlight: isMaxImpct
            });
        }
    }

    func_GETMASTER_CorptargetHead() {
        let formData = {
            Module: "CorpTargetHeadDD",
            TextSearch1: this.Register_Data.tempYear
        }
        this.mserve.Master_DDL(formData).then((response) => {
            //console.log("dataCorpTargetHead_DD:", response.data.body)
            if (response.data.status ){
                this.CorpTargetHead = response.data.body;
                if (this.Register_Data.Regis_ObjectiveHead !== '') {
                    this.func_selectObjective(this.Register_Data.Regis_ObjectiveHead, 'getdata')
                }
            } else {
                //console.log('false', response)
            }
        });
    }
    func_GETMASTER_Instruction() {
        const _s = this;

        let formData = {
            SessionEmpID: 1,
            Module: "getlist",
            body: {
                InstructionID: "",
                Area: "1",
                InstructionName: "Test0002",
            }
        }
        _s.mserve.GetInstruction(formData).then((response) => {
            if (response.data.status ){
                //console.log("dataInstruction_DD:", response.data.body)
                this.Instruction = response.data.body;
            } else {
                //console.log('false', response)
            }
        }).catch((e) => {
            this.authserviceToken.CheckTokenExpire(e.response.status)
           
        });
    }
    func_GetImpactRiskMap() {
        let formData = {
            BusinessCode: this.Register_Data.Regis_BusinessUnit,
            QuarterID: this.Register_Data.tempQuarter
        }
        this.Mapserve.getImpact2(formData).then((response) => {
            if (response.data.status ){
                //console.log('DataCalculateImpact', response.data)
                this.Financial_NI_NPV_EM = response.data.body;
            } else {
                //console.log('false', response)
            }
        }).catch((e) => { this.authserviceToken.CheckTokenExpire(e.response.status)
            });
    }
    func_GetDDL_BusinessName() {
        let formData = {
            Module: 'DepartmentDD'
        }
        this.mserve.Master_DDL(formData).then((response) => {
            //console.log("DDLPosition :", response.data.body)
            if (response.data.status ){

                this.options = response.data.body
                this.filteredOptions = this.myControl.valueChanges.pipe(
                    startWith(''),
                    map(value => this._filter(value))
                );

                this.filteredOptions2 = this.myControl2.valueChanges.pipe(
                    startWith(''),
                    map(value => this._filter(value))
                );
            } else {
                //console.log('false', response)
            }
        }).catch((error) => {
            //console.log(error);
        });
    }
    func_save_RootCause() {
        const _s = this;
        let formData;
        let registerRootMitigationType = (this.Register_Rootcause.Root_RootMitigationType !== 'Take' && this.temptable.length === 0 && (this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Name !== '' || this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Owner !== ''));
        let statusbtn = (this.statusbtn === true);
        if (this.Register_Rootcause.Root_RootCause === '') {
            this.alertflow = 'Please fill-in Root Cause'
            $('#modalAlertdanger5').modal('show');
        } else if (this.Register_Rootcause.Root_RootLikelyhood === '' || this.Register_Rootcause.Root_RootLikelyhood === null) {
            this.alertflow = 'Please select Likelihood'
            $('#modalAlertdanger5').modal('show');
        } else if (this.Register_Rootcause.Root_RootMitigationType === '' || this.Register_Rootcause.Root_RootMitigationType === null) {
            this.alertflow = 'Please select Mitigation type'
            $('#modalAlertdanger5').modal('show');
        }
        else if (registerRootMitigationType && statusbtn ) {
            this.alertflow = 'Please press the save button in Root Cause Mitigation Plan every time after adding or editing an item before saving Root Cause'
            $('#modalAlertdanger5').modal('show');
        }  
        else {
            this.loading2 = true;
            this.loading = true;
            if (this.Register_Rootcause.Root_RootMitigationType === 'Take') {
                formData = {
                    SessionEmpID: "0001",
                    Module: this.tempmodule,
                    body: {
                        Risk_Id: this.Register_Data.Regis_RiskID,
                        RootCause_Id: this.Register_Rootcause.RootCause_Id,
                        RootCause_Category: this.Register_Rootcause.Root_RootCause,
                        RootCause_Likelihood: this.Register_Rootcause.Root_RootLikelyhood,
                        RootCause_Mitigation_Type: this.Register_Rootcause.Root_RootMitigationType,
                        RootCause_After_Mitigated: this.Register_Rootcause.Root_AfterMitigated,
                        RootCause_KRI_Name: this.Register_Rootcause.Root_KRIName,
                        RootCause_KRI_Threshold_Green: this.Register_Rootcause.Root_KRIThreshold_Green,
                        RootCause_KRI_Threshold_Red: this.Register_Rootcause.Root_KRIThreshold_Red,
                        RootCause_KRI_Status: this.Register_Rootcause.Root_KRIStatus,
                        RootCause_KRI_Justification: this.Register_Rootcause.Root_Justification,
                        RootCause_Description: this.Register_Rootcause.RootCause_Description,
                        Role: this.Register_Data.Regis_Role
                    }
                }
            } else {
                formData = {
                    SessionEmpID: "0001",
                    Module: this.tempmodule,
                    body: {
                        Risk_Id: this.Register_Data.Regis_RiskID,
                        RootCause_Id: this.Register_Rootcause.RootCause_Id,
                        RootCause_Category: this.Register_Rootcause.Root_RootCause,
                        RootCause_Likelihood: this.Register_Rootcause.Root_RootLikelyhood,
                        RootCause_Mitigation_Type: this.Register_Rootcause.Root_RootMitigationType,
                        RootCause_After_Mitigated: this.Register_Rootcause.Root_AfterMitigated,
                        RootCause_KRI_Name: this.Register_Rootcause.Root_KRIName,
                        RootCause_KRI_Threshold_Green: this.Register_Rootcause.Root_KRIThreshold_Green,
                        RootCause_KRI_Threshold_Red: this.Register_Rootcause.Root_KRIThreshold_Red,
                        RootCause_KRI_Status: this.Register_Rootcause.Root_KRIStatus,
                        RootCause_KRI_Justification: this.Register_Rootcause.Root_Justification,
                        RootCause_Description: this.Register_Rootcause.RootCause_Description,
                        RootCause_Mitigation: [],
                        Role: this.Register_Data.Regis_Role
                    }
                }
                this.func_save_RootCause_ProcessRootCauseMitigation(formData);
            }
            //console.log(JSON.stringify(formData));
            this.mserve.RegisterRisk_Roocause(formData).then((response) => {
                //console.log("data:", response.data.body)
                this.RegisterRisk_Roocause_CheckResponse(response);
            }).catch((e) => {
                this.authserviceToken.CheckTokenExpire(e.response.status)
           
            });
        }
    }

    private RegisterRisk_Roocause_CheckResponse(response) {
        if (response.data.status) {
            //console.log("true", response.data.body);
            this.loading2 = false;
            $('#modalrootcasue').modal('hide');
            this.func_clearData();
            this.func_GETDATA('NoGetmaster');
            this.func_PlotRiskMap();
        } else {
            //console.log('false', response);
            this.loading2 = false;
            this.loading = false;
            this.alertflow = response.data.errorMessage;
            $('#modalAlertdanger5').modal('show');
        }
    }

    private func_save_RootCause_ProcessRootCauseMitigation(formData: any) {
        for (let item_temptable of this.temptable) {
            formData.body.RootCause_Mitigation.push({
                Risk_Id: this.Register_Data.Regis_RiskID,
                RootCause_Id: this.Register_Rootcause.RootCause_Id,
                Mitigation_Id: "",
                RootCause_Mitigation_Name: item_temptable.plan,
                RootCause_Mitigation_Owner: item_temptable.owner,
                RootCause_Mitigation_DueDate: item_temptable.Duedate,
                RootCause_Mitigation_Progress: item_temptable.Progess,
                RootCause_Mitigation_Justification: item_temptable.justification
            });
        }
    }

    func_save_Impact() {
        const _s = this;
        let registerImpact_Migation_Type = (this.Register_Impact.Impact_Migation_Type !== 'Take' && this.temptableImpact.length === 0 && (this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Name !== '' || this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Owner !== ''));
        let statusbtn = (this.statusbtn === true);
        if (this.Register_Impact.Impact_Category === '') {
            this.alertflow = 'Please select Impact Category'
            $('#modalAlertdanger5').modal('show');
        } else if (this.Register_Impact.Impact_Category === '1' 
                    && (this.Register_Impact.Impact_NPTEMV === '' || this.Register_Impact.Impact_Total_Amont === '' || this.Register_Impact.Impact_Total_Amont === null)) {
            this.alertflow = 'Please fill-in all Financial information'
            $('#modalAlertdanger5').modal('show');
        } else if (this.Register_Impact.Impact_Category !== '1'
                && this.Register_Impact.Impact_Description === '') {
            this.alertflow = 'Please fill-in Impact Description'
            $('#modalAlertdanger5').modal('show');
        } else if (this.Register_Impact.Impact_Level === '') {
            this.alertflow = 'Please select Impact Level'
            $('#modalAlertdanger5').modal('show');
        } else if (this.Register_Impact.Impact_Migation_Type === '') {
            this.alertflow = 'Please select Mitigation Type'
            $('#modalAlertdanger5').modal('show');
        }
        else if (registerImpact_Migation_Type 
                 && statusbtn) {
            this.alertflow = 'Please press the save button in Impact Mitigation Plan every time after adding or editing an item before saving Impact'
            $('#modalAlertdanger5').modal('show');

        }
        else {
            this.func_save_Impact_StartSaving();
        }
    }

    private func_save_Impact_StartSaving() {
        this.loading2 = true;
        this.loading = true;
        let formData;
        if (this.Register_Impact.Impact_Migation_Type === 'Take') {
            formData = this.func_save_Impact_StartSaving_PrepareDataMitigationTypeTake();
        } else {
            formData = this.func_save_Impact_StartSaving_PrepareElseFormData();
            this.func_save_Impact_ProcessImpactMitigation(formData);
        }
        //console.log(JSON.stringify(formData));
        this.mserve.RegisterRisk_Impact(formData).then((response) => {
            //console.log("data:", response.data.body);
            this.func_save_Impact_StartSaving_ProcessRegisterRiskImpactResponse(response);
        }).catch((e) => {
            this.authserviceToken.CheckTokenExpire(e.response.status)
           ;
        });
    }

    private func_save_Impact_StartSaving_ProcessRegisterRiskImpactResponse(response) {
        if (response.data.status) {
            //console.log("true", response.data.body);
            this.loading2 = false;
            $('#modalimpact').modal('hide');
            this.func_clearData();
            this.func_GETDATA('NoGetmaster');
            this.func_PlotRiskMap();

        } else {

            //console.log('false', response);
            this.loading2 = false;
            this.loading = false;
            this.alertflow = response.data.errorMessage;
            $('#modalAlertdanger5').modal('show');
        }
    }

    private func_save_Impact_StartSaving_PrepareElseFormData() {
        let formData = {
            SessionEmpID: "0001",
            Module: this.tempmodule,
            body: {
                Risk_Id: this.Register_Data.Regis_RiskID,
                Impact_Id: this.Register_Impact.Impact_Id,
                Impact_Category: this.Register_Impact.Impact_Category,
                Impact_NPT_EMV: this.Register_Impact.Impact_NPTEMV,
                Impact_Total_Amont: this.Register_Impact.Impact_Total_Amont,
                Impact_Description: this.Register_Impact.Impact_Description,
                Impact_Level: this.Register_Impact.Impact_Level,
                Impact_Rating: "",
                Impact_Mitigation_Type: this.Register_Impact.Impact_Migation_Type,
                Impact_After_Mitigated: this.Register_Impact.Impact_After_Mitigated,
                Impact_Mitigation: [],
                Role: this.Register_Data.Regis_Role
            }
        };
        return formData;
    }

    private func_save_Impact_StartSaving_PrepareDataMitigationTypeTake() {
        let formData = {
            SessionEmpID: "0001",
            Module: this.tempmodule,
            body: {
                Risk_Id: this.Register_Data.Regis_RiskID,
                Impact_Id: this.Register_Impact.Impact_Id,
                Impact_Category: this.Register_Impact.Impact_Category,
                Impact_NPT_EMV: this.Register_Impact.Impact_NPTEMV,
                Impact_Total_Amont: this.Register_Impact.Impact_Total_Amont,
                Impact_Description: this.Register_Impact.Impact_Description,
                Impact_Level: this.Register_Impact.Impact_Level,
                Impact_Rating: "",
                Impact_Mitigation_Type: this.Register_Impact.Impact_Migation_Type,
                Impact_After_Mitigated: this.Register_Impact.Impact_After_Mitigated,
                Role: this.Register_Data.Regis_Role
            }
        };
        return formData;
    }

    private func_save_Impact_ProcessImpactMitigation(formData: any) {
        for (let item_temptableImpact of this.temptableImpact) {
            formData.body.Impact_Mitigation.push({
                Risk_Id: this.Register_Data.Regis_RiskID,
                Impact_Id: this.Register_Impact.Impact_Id,
                Impact_Mitigation_Id: "",
                Impact_Mitigation_Name: item_temptableImpact.mitigation,
                Impact_Mitigation_Owner: item_temptableImpact.owner,
                Impact_Mitigation_DueDate: item_temptableImpact.Duedate,
                Impact_Mitigation_Progress: item_temptableImpact.Progess,
                Impact_Mitigation_Justification: item_temptableImpact.justification
            });
        }
    }

    selectedRaged(value) {
        let slider;
        let output
        if (value !== 'new') {
            slider = document.getElementById("myRangeroot");
            output = document.getElementById("root");
            output.innerHTML = slider.value;
            this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Progress = slider.value

            slider.oninput = function () {
                output.innerHTML = this.value;
            }
        } else {
            slider = document.getElementById("myRangeroot");
            output = document.getElementById("root");
            if (slider !== null && output !== null) {
                output.innerHTML = 0;
                this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Progress = 0

                slider.value = 0
                slider.oninput = function () {
                    output.innerHTML = this.value;
                }
            }
        }

        //console.log(this.temptable);
    }
    selectedEditRaged() {
        this.displayprogress = false;
        let slider;
        let output
        slider = document.getElementById("myRangerootedit");
        output = document.getElementById("rootedit");
        output.innerHTML = slider.value;
        this.Edit_Rootcause.RootProgress = slider.value

        slider.oninput = function () {
            output.innerHTML = this.value;
        }
    }
    selectedRagedImpact(value) {
        let slider;
        let output
        if (value !== 'new') {
            slider = document.getElementById("myRangeImpact");
            output = document.getElementById("demoImpact");
            output.innerHTML = slider.value;
            this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Progress = slider.value

            slider.oninput = function () {
                output.innerHTML = this.value;
            }
        } else {
            slider = document.getElementById("myRangeImpact");
            output = document.getElementById("demoImpact");
            if (slider !== null && output !== null) {
                output.innerHTML = 0
                this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Progress = 0

                slider.value = 0
                slider.oninput = function () {
                    output.innerHTML = this.value;
                }
            }
        }

    }
    selectedEditRagedImpact(value) {
        this.displayprogress = false;
        let slider;
        let output
        slider = document.getElementById("myRangeImpactedit");
        output = document.getElementById("demoImpactedit");
        output.innerHTML = slider.value;
        this.Edit_Impact.ImpactProgress = slider.value

        slider.oninput = function () {
            output.innerHTML = this.value;
        }

    }
    selectedradio(value) {
        if (value == 0 || value == 'green') {
            let element = document.getElementById("rdo1");
            element.classList.add("selected1");
            let element2 = document.getElementById("rdo2");
            element2.classList.remove("selected2");
            let element3 = document.getElementById("rdo3");
            element3.classList.remove("selected3");
        } else if (value == 1 || value == 'yellow') {
            let element = document.getElementById("rdo1");
            element.classList.remove("selected1");
            let element2 = document.getElementById("rdo2");
            element2.classList.add("selected2");
            let element3 = document.getElementById("rdo3");
            element3.classList.remove("selected3");
        } else if (value == 2 || value == 'red') {
            let element = document.getElementById("rdo1");
            element.classList.remove("selected1");
            let element2 = document.getElementById("rdo2");
            element2.classList.remove("selected2");
            let element3 = document.getElementById("rdo3");
            element3.classList.add("selected3");
        } else {
            let element = document.getElementById("rdo1");
            element.classList.remove("selected1");
            let element2 = document.getElementById("rdo2");
            element2.classList.remove("selected2");
            let element3 = document.getElementById("rdo3");
            element3.classList.remove("selected3");
        }
    }
    func_back() {
        this.router.navigate(['/Identification', 'edit']);
    }
    func_selectObjective(value, status) {

        let tempcodevalue;
        for(let item_CorpTargetHead of this.CorpTargetHead){
            if (value ==item_CorpTargetHead.value) {
                tempcodevalue = item_CorpTargetHead.text1;
                break;
            }
        }

        let formData = {
            Module: "CorpTargetItemdDD",
            TextSearch1: tempcodevalue
        }
        this.mserve.Master_DDL(formData).then((response) => {
            //console.log("dataCorpTargetItem_DD:", response.data.body)
            if (response.data.status ){
                this.CorpTargetItem = response.data.body
                if (status !== 'getdata') {
                    this.Register_Data.Regis_Objective = ''
                }
            } else {
                //console.log('false', response)
            }
        }).catch((error) => {
            //console.log(error);
        });
    }
    myFunction() {
        this.valueadd = true;
        for(let item_temptableImpact of this.temptableImpact){
            item_temptableImpact.display = true
            if (typeof item_temptableImpact.Duedate !== 'string') {
                item_temptableImpact.Duedate = this.func_formatdate(item_temptableImpact.Duedate)
            }
        }
        for(let item_temptable of this.temptable){
            item_temptable.display = true
            if (typeof item_temptable.Duedate !== 'string') {
                item_temptable.Duedate = this.func_formatdate(item_temptable.Duedate)
            }
        }
    }
    myFunctionoff() {
        this.valueadd = false;
        this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Name = ''
        this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Owner = ''
        this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_DueDate = ''
        this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Progress = ''
        this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Justification = ''
        this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_OwmerText = ''

        this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Name = ''
        this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Owner = ''
        this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_DueDate = ''
        this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Progress = ''
        this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Justification = ''
        this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_OwnerText = ''
        this.selectedRaged('new');
        this.selectedRagedImpact('new');
    }
    myFunction_addMitigation() {
        this.something = true;
    }
    func_formatdate(value) {
        let day = value.day.toString();
        let month = value.month.toString();
        let year = value.year.toString();
        if (day.length < 2) {
            day = '0' + day
        }
        if (month.length < 2) {
            month = '0' + month
        }
        let formatedate = day + '/' + month + '/' + year
        return formatedate
    }
    func_formatdateto_ngdate(value) {
        let editday = parseInt(value.substr(0, 2));
        let editmonth = parseInt(value.substr(3, 2));
        let edityear = parseInt(value.substr(6, 4));

        return {
            day: (editday),
            month: (editmonth),
            year: (edityear)
        };

    }
    func_clearData() {
        this.Register_Rootcause.Root_RootCause = ''
        this.Register_Rootcause.Root_RootLikelyhood = ''
        this.Register_Rootcause.Root_RootMitigationType = ''
        this.Register_Rootcause.Root_AfterMitigated = ''
        this.Register_Rootcause.Root_KRIName = ''
        this.Register_Rootcause.Root_KRIThreshold_Green = ''
        this.Register_Rootcause.Root_KRIThreshold_Red = ''
        this.Register_Rootcause.Root_KRIStatus = ''
        this.Register_Rootcause.Root_Justification = ''
        this.Register_Rootcause.RootCause_Description = ''

        this.Register_Impact.Impact_Category = ''
        this.Register_Impact.Impact_NPTEMV = ''
        this.Register_Impact.Impact_Total_Amont = ''
        this.Register_Impact.Impact_Description = ''
        this.Register_Impact.Impact_Level = ''
        this.Register_Impact.Impact_Migation_Type = ''
        this.Register_Impact.Impact_After_Mitigated = ''
        this.Register_Impact.Impact_Rating = ''
    }
    chkstatus() {
        if (this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Name === '' && this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Owner === '') {
            this.statusbtn = false;
        } else {
            this.statusbtn = true;
        }
    }
    chkstatusimpact() {
        if (this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Name === '' && this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Owner === '') {
            this.statusbtn = false;
        } else {
            this.statusbtn = true;
        }
    }
    func_rootcause() {
        
        this.func_UPDATEDATA(false);
        $('#modalrootcasue').modal('show')
        this.myFunctionoff();
        this.selectedradio(10);
        this.valueadd = true;
        this.selectedRaged('new');

        this.temptable = []
        this.Register_Rootcause.RootCause_Id = ''
        this.Register_Rootcause.Root_RootMitigationType = ''
        this.tempmodule = 'insert'
        this.Register_Rootcause.Root_RootCause = ''
        this.Register_Rootcause.Root_RootLikelyhood = ''
        this.Register_Rootcause.Root_RootMitigationType = ''
        this.Register_Rootcause.Root_AfterMitigated = ''
        this.Register_Rootcause.Root_KRIName = ''
        this.Register_Rootcause.Root_KRIThreshold_Green = ''
        this.Register_Rootcause.Root_KRIThreshold_Red = ''
        this.Register_Rootcause.Root_KRIStatus = ''
        this.Register_Rootcause.Root_Justification = ''
        this.Register_Rootcause.RootCause_Description = ''

        this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Name = ''
        this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Owner = ''
        this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_DueDate = ''
        this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Progress = 0
        this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Justification = ''
        this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_OwmerText = ''
    }
    func_editrootcause(id) {
        this.statusbtn = false;
        this.func_UPDATEDATA(false);
        this.temptable = []
        this.myFunctionoff();
        this.valueadd = true;
        this.selectedRaged('new');
        for(let item_Tempdata of this.Tempdata.rootCause){
            if (item_Tempdata.rootCause_Id === id) {
                this.editRootcause(id, item_Tempdata);
            }
        }

  
        this.editTemptableRootcause(id);

        this.selectedradio(this.Register_Rootcause.Root_KRIStatus)
        $('#modalrootcasue').modal('show')
        this.tempmodule = 'update'
    }
    private editTemptableRootcause(id: any) {
        for (let item_rootCause_Mitigation of this.Tempdata.rootCause_Mitigation) {
            if (item_rootCause_Mitigation.rootCause_Id === id) {
                for (let item_options of this.options) {
                    if (item_rootCause_Mitigation.rootCause_Mitigation_Owner === item_options.value) {
                        item_rootCause_Mitigation.rootCause_Mitigation_Ownertext = item_options.text;
                    }
                }


                this.temptable.push({
                    id: item_rootCause_Mitigation.rootCause_Mitigation_Id,
                    plan: item_rootCause_Mitigation.rootCause_Mitigation_Name,
                    owner: item_rootCause_Mitigation.rootCause_Mitigation_Owner,
                    Duedate: item_rootCause_Mitigation.rootCause_Mitigation_DueDate,
                    Progess: item_rootCause_Mitigation.rootCause_Mitigation_Progress,
                    justification: item_rootCause_Mitigation.rootCause_Mitigation_Justification,
                    display: true,
                    tempid: this.temptable.length,
                    ownertext: item_rootCause_Mitigation.rootCause_Mitigation_Ownertext
                });
            }
        }
    }

    private editRootcause(id: any, item_Tempdata: any) {
        this.Register_Rootcause.RootCause_Id = id;
        this.Register_Rootcause.Root_RootCause = item_Tempdata.rootCause_Category;
        this.Register_Rootcause.Root_RootLikelyhood = item_Tempdata.rootCause_Likelihood;
        this.Register_Rootcause.Root_RootMitigationType = item_Tempdata.rootCause_Mitigation_Type;
        this.Register_Rootcause.Root_AfterMitigated = (item_Tempdata.rootCause_After_Mitigated === null) ? '' : item_Tempdata.rootCause_After_Mitigated;
        this.Register_Rootcause.Root_KRIName = item_Tempdata.rootCause_KRI_Name;
        this.Register_Rootcause.Root_KRIThreshold_Green = item_Tempdata.rootCause_KRI_Threshold_Green;
        this.Register_Rootcause.Root_KRIThreshold_Red = item_Tempdata.rootCause_KRI_Threshold_Red;
        this.Register_Rootcause.Root_KRIStatus = item_Tempdata.rootCause_KRI_Status;
        this.Register_Rootcause.Root_Justification = item_Tempdata.rootCause_KRI_Justification;
        this.Register_Rootcause.RootCause_Description = item_Tempdata.rootCause_Description;
    }

    func_addtablerootcause() {
        if (this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_DueDate !== '' && this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_DueDate !== null) {
            this.Seformat_Date = this.func_formatdate(this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_DueDate)
        } else {
            this.Seformat_Date = '';
        }

        if (this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Name === '') {
            this.alertflow = 'Please fill-in Mitigation Plan'
            $('#modalAlertdanger5').modal('show');
        } else if (this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_OwmerText === '') {
            this.alertflow = 'Please fill-in Mitigation Owner'
            $('#modalAlertdanger5').modal('show');
        } else if (this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Name !== '' && this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_OwmerText !== '') {
            for (let item_options of this.options){
                if (this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_OwmerText ===item_options.text) {
                    this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Owner =item_options.value
                }
            }

            this.temptable.push({
                plan: this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Name,
                owner: this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Owner,
                Duedate: this.Seformat_Date,
                Progess: this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Progress,
                justification: this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Justification,
                display: true,
                tempid: this.temptable.length,
                ownertext: this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_OwmerText
            })

            this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Name = ''
            this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Owner = ''
            this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Justification = ''
            this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_DueDate = ''
            this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_Progress = ''
            this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_OwmerText = ''
            this.myFunctionoff();
            this.selectedRaged('new');
            this.statusbtn = false;

        }
    }
    func_delAssessRootCause(id) {
        const _s = this;
        let formData = {
            SessionEmpID: '0001',
            Module: 'Delete',
            body: {
                Risk_Id: this.Register_Data.Regis_RiskID,
                RootCause_Id: id,
                RootCause_Category: '',
                RootCause_Likelihood: '',
                RootCause_Mitigation_Type: '',
                RootCause_After_Mitigated: '',
                RootCause_KRI_Name: '',
                RootCause_KRI_Threshold: '',
                RootCause_KRI_Status: '',
                RootCause_KRI_Justification: '',
                RootCause_Description: '',
                Role: this.Register_Data.Regis_Role,
                DeleteFag: 'ROOTCAUSE',
                Delete_RootCause_Id: id,
                Delete_RootCause_Mitigation_Id: ''
            }
        }
        this.mserve.RegisterRisk_Roocause(formData).then((response) => {
            if (response.data.status ){
                //console.log("true", response.data.body)
                $('#modalConfirm').modal('hide');
                this.func_GETDATA('NoGetmaster');
                this.func_PlotRiskMap();
            } else {
                //console.log('false', response)
            }
        }).catch((e) => {
            this.authserviceToken.CheckTokenExpire(e.response.status)
           
        });
    }

    func_impact() {
        this.func_UPDATEDATA(false);
        $('#modalimpact').modal('show')
        this.tempmodule = 'insert'
        this.temptableImpact = []
        this.valueadd = true;
        this.selectedRagedImpact('new');

        this.Register_Impact.Impact_Id = ''
        this.Register_Impact.Impact_Category = ''
        this.Register_Impact.Impact_NPTEMV = ''
        this.Register_Impact.Impact_Total_Amont = ''
        this.Register_Impact.Impact_Description = ''
        this.Register_Impact.Impact_Level = ''
        this.Register_Impact.Impact_Migation_Type = ''
        this.Register_Impact.Impact_After_Mitigated = ''
        this.Register_Impact.Impact_Rating = ''

        this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Name = ''
        this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Owner = ''
        this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_DueDate = ''
        this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Progress = 0
        this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Justification = ''
        this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_OwnerText = ''

    }
    func_editimpact(id) {
        this.statusbtn = false;
        this.func_UPDATEDATA(false);
        this.temptableImpact = []
        this.myFunctionoff();
        this.valueadd = true;
        this.selectedRagedImpact('new');
        for (let item_impact of this.Tempdata.impact) {
            if (item_impact.impact_Id === id) {
                this.editImpact(id, item_impact);
                break
            }
        }
        for (let item_impact_Mitigation of this.Tempdata.impact_Mitigation){
            this.setEditTempTableImpact(item_impact_Mitigation, id);
        }
        $('#modalimpact').modal('show')
        this.tempmodule = 'UPDATE'
    }
    private setEditTempTableImpact(item_impact_Mitigation: any, id: any) {
        if (item_impact_Mitigation.impact_Id === id) {
            for (let item_options of this.options) {
                //console.log('OwnerID : ', item_impact_Mitigation.Impact_Mitigation_Owner);
                if (item_impact_Mitigation.impact_Mitigation_Owner === item_options.value) {
                    item_impact_Mitigation.impact_Mitigation_OwnerText = item_options.text;
                }
            }

            this.temptableImpact.push({
                id: item_impact_Mitigation.impact_Mitigation_Id,
                mitigation: item_impact_Mitigation.impact_Mitigation_Name,
                owner: item_impact_Mitigation.impact_Mitigation_Owner,
                Duedate: item_impact_Mitigation.impact_Mitigation_DueDate,
                Progess: item_impact_Mitigation.impact_Mitigation_Progress,
                justification: item_impact_Mitigation.impact_Mitigation_Justification,
                display: true,
                tempid: this.temptableImpact.length,
                ownertext: item_impact_Mitigation.impact_Mitigation_OwnerText
            });
        }
    }

    private editImpact(id: any, item_impact: any) {
        this.Register_Impact.Impact_Id = id;
        this.Register_Impact.Impact_Category = (item_impact.impact_Category === null) ? '' : item_impact.impact_Category;
        this.Register_Impact.Impact_NPTEMV = item_impact.impact_NPT_EMV;
        this.Register_Impact.Impact_Total_Amont = item_impact.impact_Total_Amont;
        this.Register_Impact.Impact_Description = item_impact.impact_Description;
        this.Register_Impact.Impact_Level = (item_impact.impact_Level === null) ? '' : item_impact.impact_Level;
        this.Register_Impact.Impact_Migation_Type = item_impact.impact_Mitigation_Type;
        this.Register_Impact.Impact_After_Mitigated = (item_impact.impact_After_Mitigated === null) ? '' : item_impact.impact_After_Mitigated;
        this.Register_Impact.Impact_Rating = item_impact.impact_Rating;
    }

    func_addtableimpact() {
        if (this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_DueDate !== '' && this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_DueDate !== null) {
            this.Seformat_Date = this.func_formatdate(this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_DueDate)
        } else {
            this.Seformat_Date = '';
        }

        if (this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Name === '') {
            this.alertflow = 'Please fill-in Mitigation Plan'
            $('#modalAlertdanger5').modal('show');
        } else if (this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_OwnerText === '') {
            this.alertflow = 'Please fill-in Mitigation Owner'
            $('#modalAlertdanger5').modal('show');
        } else if (this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Name !== '' && this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_OwmerText !== '') {
            for (let item_options of this.options){
                if (this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_OwnerText === item_options.text) {
                    this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Owner = item_options.value
                }
            }

            this.temptableImpact.push({
                mitigation: this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Name,
                owner: this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Owner,
                Duedate: this.Seformat_Date,
                Progess: this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Progress,
                justification: this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Justification,
                display: true,
                tempid: this.temptableImpact.length,
                ownertext: this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_OwnerText
            })

            this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Name = ''
            this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Owner = ''
            this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Justification = ''
            this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_DueDate = ''
            this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_Progress = ''
            this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_OwnerText = ''
            this.myFunctionoff();
            this.selectedRagedImpact('new');
            this.statusbtn = false;
        }
    }
    func_delAssessImpact(id) {
        const _s = this;
        let formData = {
            SessionEmpID: '0001',
            Module: 'Delete',
            body: {
                Risk_Id: this.Register_Data.Regis_RiskID,
                Impact_Id: id,
                Impact_Category: '',
                Impact_Likelihood: '',
                Impact_Mitigation_Type: '',
                Impact_After_Mitigated: '',
                Impact_KRI_Name: '',
                Impact_KRI_Threshold: '',
                Impact_KRI_Status: '',
                Impact_KRI_Justification: '',
                Role: this.Register_Data.Regis_Role,
                DeleteFag: 'Impact',
                Delete_Impact_Id: id,
                Delete_Impact_Mitigation_Id: ''
            }
        }
        this.mserve.RegisterRisk_Impact(formData).then((response) => {
            if (response.data.status ){
                //console.log("true", response.data.body)
                $('#modalConfirm').modal('hide');
                this.func_GETDATA('NoGetmaster');
                this.func_PlotRiskMap();
            } else {
                //console.log('false', response)
            }
        }).catch((e) => {
            this.authserviceToken.CheckTokenExpire(e.response.status)
           
        });
    }
    function_editRootcause(value, tempid) {
        this.appService.chkeditdate = true;

        this.function_editRootcause_SetTempDate(tempid);

        this.idedit = value
        this.displayprogress = true;
        this.statusbtn = true;
        for (let item_temptable of this.temptable){
            item_temptable.display = true
        }
        this.function_editRootcause_validate_date_format(tempid);
    }
    private function_editRootcause_validate_date_format(tempid: any) {
        for (let item_temptable of this.temptable) {
            if (typeof item_temptable.Duedate !== 'string' && item_temptable.Duedate !== null) {
                item_temptable.Duedate = this.func_formatdate(item_temptable.Duedate);
            } else if (item_temptable.Duedate === null) {
                item_temptable.Duedate = '';
            }
            if (item_temptable.tempid === tempid) {

                item_temptable.Duedate = (item_temptable.Duedate !== '') ? this.func_formatdateto_ngdate(item_temptable.Duedate) : '';

                item_temptable.display = false;
                this.editrootcaustemId(item_temptable);
            }
        }
    }

    private function_editRootcause_SetTempDate(tempid: any) {
        if (this.temptable.length !== 0) {
            for (let item_temptable of this.temptable) {
                if (item_temptable.tempid === tempid) {
                    this.appService.tmpdate = item_temptable.Duedate;
                }
            }
        }
        else {
            this.appService.tmpdate = this.Edit_Rootcause.RootDate;
        }
    }

    private editrootcaustemId(item_temptable: any) {
        this.Edit_Rootcause.RootPlan = item_temptable.plan;
        this.Edit_Rootcause.RootOwner = item_temptable.owner;
        this.Edit_Rootcause.RootDate = item_temptable.Duedate;
        this.Edit_Rootcause.RootProgress = item_temptable.Progess;
        this.Edit_Rootcause.RootJustification = item_temptable.justification;
        this.Edit_Rootcause.RootOwnertext = item_temptable.ownertext;
    }

    function_addeditRootcause(value, tempid) {
        if (typeof this.Edit_Rootcause.RootDate !== 'string' && this.Edit_Rootcause.RootDate !== undefined && this.Edit_Rootcause.RootDate !== null) {
            this.Edit_Rootcause.RootDate = this.func_formatdate(this.Edit_Rootcause.RootDate);
        } else {
            this.Edit_Rootcause.RootDate = '';
        }
        for (let item_options of this.options){
            if (this.Edit_Rootcause.RootOwnertext === item_options.text) {
                this.Edit_Rootcause.RootOwner = item_options.value
            }
        }
        for (let item_temptable of this.temptable){
            if (item_temptable.tempid === tempid) {
                item_temptable.plan = this.Edit_Rootcause.RootPlan;
                item_temptable.owner = this.Edit_Rootcause.RootOwner;
                item_temptable.Duedate = this.Edit_Rootcause.RootDate;
                item_temptable.Progess = this.Edit_Rootcause.RootProgress;
                item_temptable.justification = this.Edit_Rootcause.RootJustification;
                item_temptable.ownertext = this.Edit_Rootcause.RootOwnertext;
                item_temptable.display = true;
            }
        }
        this.statusbtn = false;
    }
    func_deleterootcauseMitiplan(value, tempid) {
        for (let i = 0; i < this.temptable.length; i++) {
            if (this.temptable[i].tempid === tempid) {
                this.temptable.splice(i, 1);
                break;
            }
        }
        this.statusbtn = false;
    }
    func_Changeroottype() {
        this.Register_Rootcause.Root_AfterMitigated = ''
    }

    function_editImpct(value, tempid) {
        this.appService.chkeditdate = true;

        this.function_editImpct_SetTempDate(tempid);

        this.idedit = value
        this.displayprogress = true;
        this.statusbtn = true;
        for (let item_temptableImpact of this.temptableImpact){
            item_temptableImpact.display = true
        }
        this.function_editImpct_ProcessTableImpact(tempid);
    }
    private function_editImpct_ProcessTableImpact(tempid: any) {
        for (let item_temptableImpact of this.temptableImpact) {
            if (typeof item_temptableImpact.Duedate !== 'string' && item_temptableImpact.Duedate !== null) {
                item_temptableImpact.Duedate = this.func_formatdate(item_temptableImpact.Duedate);
            } else if (item_temptableImpact.Duedate === null) {
                item_temptableImpact.Duedate = '';
            }


            if (item_temptableImpact.tempid === tempid) {

                item_temptableImpact.Duedate = (item_temptableImpact.Duedate !== '') ? this.func_formatdateto_ngdate(item_temptableImpact.Duedate) : '';

                item_temptableImpact.display = false;
                this.Edit_Impact.ImpactPlan = item_temptableImpact.mitigation;
                this.Edit_Impact.ImpactOwner = item_temptableImpact.owner;
                this.Edit_Impact.ImpactDate = item_temptableImpact.Duedate;
                this.Edit_Impact.ImpactProgress = item_temptableImpact.Progess;
                this.Edit_Impact.ImpactJustification = item_temptableImpact.justification;
                this.Edit_Impact.ImpactOwnertext = item_temptableImpact.ownertext;
            }
        }
    }

    private function_editImpct_SetTempDate(tempid: any) {
        if (this.temptableImpact.length !== 0) {
            for (let item_temptableImpact of this.temptableImpact) {
                if (item_temptableImpact.tempid === tempid) {
                    this.appService.tmpdate = item_temptableImpact.Duedate;
                }
            }
        }
        else {
            this.appService.tmpdate = this.Edit_Impact.ImpactDate;
        }
    }

    function_addeditImpact(value, tempid) {
        if (typeof this.Edit_Impact.ImpactDate !== 'string' && this.Edit_Impact.ImpactDate !== undefined && this.Edit_Impact.ImpactDate !== null) {
            this.Edit_Impact.ImpactDate = this.func_formatdate(this.Edit_Impact.ImpactDate);
        } else {
            this.Edit_Impact.ImpactDate = '';
        }
        for (let item_options of this.options) {
            if (this.Edit_Impact.ImpactOwnertext === item_options.text) {
                this.Edit_Impact.ImpactOwner = item_options.value
            }
        }
        for (let item_temptableImpact of this.temptableImpact){
            if (item_temptableImpact.tempid === tempid) {
                item_temptableImpact.mitigation = this.Edit_Impact.ImpactPlan;
                item_temptableImpact.owner = this.Edit_Impact.ImpactOwner;
                item_temptableImpact.Duedate = this.Edit_Impact.ImpactDate;
                item_temptableImpact.Progess = this.Edit_Impact.ImpactProgress;
                item_temptableImpact.justification = this.Edit_Impact.ImpactJustification;
                item_temptableImpact.ownertext = this.Edit_Impact.ImpactOwnertext;
                item_temptableImpact.display = true;
            }
        }
        this.statusbtn = false;
    }
    func_deleteimpactMitiplan(value, tempid) {
        for (let i = 0; i < this.temptableImpact.length; i++) {
            if (this.temptableImpact[i].tempid === tempid) {
                this.temptableImpact.splice(i, 1);
                break;
            }
        }
        this.statusbtn = false;
    }
    func_selectImpactCate(value) {
        this.Register_Impact.Impact_NPTEMV = ''
        this.Register_Impact.Impact_Total_Amont = ''
        this.Register_Impact.Impact_Level = ''
        this.Register_Impact.Impact_Description = ''
        this.Register_Impact.Impact_Level = ''
        this.Register_Impact.Impact_After_Mitigated = ''
    }
    func_ChageNI_NPV() {
        this.Register_Impact.Impact_Total_Amont = ''
        this.Register_Impact.Impact_Level = ''
        this.Register_Impact.Impact_After_Mitigated = ''
    }
    func_Calculate() {
        const impactCategory = this.getImpactCategory();

        if (this.Register_Impact.Impact_Total_Amont === null || this.Register_Impact.Impact_Total_Amont === '') {
            this.Register_Impact.Impact_Level = '';
        } else if (this.Register_Impact.Impact_Total_Amont <= this.Financial_NI_NPV_EM[impactCategory].lowerLimit) {
            this.Register_Impact.Impact_Level = this.Financial_NI_NPV_EM[impactCategory].ImpactCateItemCode;
        } else if (this.Register_Impact.Impact_Total_Amont >= this.Financial_NI_NPV_EM[impactCategory].upperLimit) {
            this.Register_Impact.Impact_Level = this.Financial_NI_NPV_EM[impactCategory].ImpactCateItemCode;
        }

        this.Register_Impact.Impact_After_Mitigated = '';
    }
    private getImpactCategory(): number {
        if (this.Register_Impact.Impact_NPTEMV === '1') {
            return this.Financial_NI_NPV_EM.findIndex(item => this.Register_Impact.Impact_Total_Amont <= item.NI_t);
        } else {
            return this.Financial_NI_NPV_EM.findIndex(item => this.Register_Impact.Impact_Total_Amont <= item.NPV_t);
        }
    }
    func_ChangeImpactType() {
        this.Register_Impact.Impact_After_Mitigated = ''
    }

    private _filter(value: string): string[] {
        const filterValue = value?.toLowerCase();
        if (filterValue !== '') {
            return this.options.filter(option => option.text?.toLowerCase().includes(filterValue));
        } else {
            return this.options
        }
    }

    funcDeleteConfirm(id, value) {
        this.tempcon_id = id;
        this.tempDeletevalue = value;
        $('#modalConfirm').modal('show');
    }
    funcYes() {
        if (this.tempDeletevalue === 'rootcause') {
            this.func_delAssessRootCause(this.tempcon_id)
        } else {
            this.func_delAssessImpact(this.tempcon_id)
        }
    }
    funcNo() {
        $('#modalConfirm').modal('hide');
    }
    closealertdate() {

        this.Edit_Rootcause.RootDate = ''
        this.Register_Rootcause.Root_Mitigation[0].RootCauseMitigation_DueDate = ''
        this.Edit_Impact.ImpactDate = ''
        this.Register_Impact.Impact_Mitigation[0].Impact_Mitigation_DueDate = ''
        $('#modalAlert5').modal('hide');
    }



    changelihood() {
        this.Register_Rootcause.Root_AfterMitigated = ''
    }
    changeImpact() {
        this.Register_Impact.Impact_After_Mitigated = ''
    }

    func_closeroot() {
        let tempdataclose = [];
        if (this.Register_Rootcause.RootCause_Id !== '') {
            for (let item_Tempdata of this.Tempdata.rootCause){
                if (this.Register_Rootcause.RootCause_Id === item_Tempdata.rootCause_Id) {
                    tempdataclose.push(item_Tempdata);
                }
            }
            this.func_closeroot_setModalConfirm(tempdataclose);
        }
        else {
            $('#modalConfirmClose').modal('hide');
            $('#modalrootcasue').modal('hide');
        }
    }

    private func_closeroot_setModalConfirm(tempdataclose: any[]) {
        const fieldsToCheck = [
            'rootCause_Category',
            'rootCause_Likelihood',
            'rootCause_Mitigation_Type',
            'rootCause_After_Mitigated',
            'rootCause_KRI_Name',
            'rootCause_KRI_Status',
            'rootCause_KRI_Justification',
            'rootCause_Description',
        ];
    
        let showModal = false;
    
        for (const field of fieldsToCheck) {
            const tempValue = tempdataclose[0][field];
            const registerValue = this.Register_Rootcause['Root_' + field];
    
            if (tempValue !== registerValue && (tempValue !== null || registerValue !== null)) {
                showModal = true;
                break;
            }
        }
    
        if (showModal) {
            $('#modalConfirmClose').modal('show');
        } else {
            $('#modalConfirmClose').modal('hide');
            $('#modalrootcasue').modal('hide');
        }
    }

    func_closeImpact() {

        let tempdataclose = [];
        if (this.Register_Impact.Impact_Id !== '') {
            for (let item_Tempdata of this.Tempdata.impact){
                if (this.Register_Impact.Impact_Id === item_Tempdata.impact_Id) {
                    tempdataclose.push(item_Tempdata);
                }
            }

            this.func_closeImpact_setModalConfirmClose(tempdataclose);
        }
        else {
            $('#modalConfirmClose').modal('hide');
            $('#modalimpact').modal('hide');
        }

    }

    private func_closeImpact_setModalConfirmClose(tempdataclose: any[]) {
        if (tempdataclose[0].impact_Category !== this.Register_Impact.Impact_Category) {
            $('#modalConfirmClose').modal('show');
        } else if (tempdataclose[0].impact_NPT_EMV !== this.Register_Impact.Impact_NPTEMV) {
            $('#modalConfirmClose').modal('show');
        } else if (tempdataclose[0].impact_Total_Amont !== this.Register_Impact.Impact_Total_Amont) {
            $('#modalConfirmClose').modal('show');
        } else if (tempdataclose[0].impact_Description !== this.Register_Impact.Impact_Description) {
            $('#modalConfirmClose').modal('show');
        } else if (tempdataclose[0].impact_Level !== this.Register_Impact.Impact_Level) {
            $('#modalConfirmClose').modal('show');
        } else if (tempdataclose[0].impact_Mitigation_Type !== this.Register_Impact.Impact_Migation_Type) {
            $('#modalConfirmClose').modal('show');
        } else if (tempdataclose[0].impact_After_Mitigated !== this.Register_Impact.Impact_After_Mitigated && tempdataclose[0].impact_After_Mitigated !== null) {
            $('#modalConfirmClose').modal('show');
        }
        else {
            $('#modalConfirmClose').modal('hide');
            $('#modalimpact').modal('hide');
        }
    }

    funcYesClose() {
        $('#modalConfirmClose').modal('hide');
        $('#modalrootcasue').modal('hide');
        $('#modalimpact').modal('hide');
    }

    funcNoClose() {
        $('#modalConfirmClose').modal('hide');
    }

    View_Cirtiria(value) {
        sessionStorage.setItem('pagecritiria', value)
        this.router.navigate([]).then(result => { window.open('/RiskCriteria', '_blank'); });
    }

    closeflow() {
        $('#modalAlertdanger5').modal('hide');
    }

    ngAfterViewInit(): void {
        const _s = this
        scroll(0, 390);
    }

    ngAfterContentChecked(): void {
        this.autogrow();
    }

    autogrow() {
        let textArea = document.getElementById("RegisContext")
        textArea.style.overflow = 'hidden';
        textArea.style.height = 'auto';
        textArea.style.height = textArea.scrollHeight + 'px';
    }

    autoGrowTextZone(e) {
        e.target.style.overflow = 'hidden';
        e.target.style.height = "0px";
        e.target.style.height = (e.target.scrollHeight) + "px";
    }

    func_PlotRiskMap() {
        //console.log('Data tablemap :', this.list.risk.risktable)
        //console.log('func_PlotRiskMap Data Tempdata :', this.Tempdata)

        for (let i = 0; i < 5; i++) {
            for (let ii = 0; ii <= 4; ii++) {
                this.list.risk.risktable[i].col[ii].item = []
            }
        }
        let Likelyhood = parseInt(this.Register_Data.Regis_Likelyhood);
        let Impact = parseInt(this.Register_Data.Regis_Impact);

        if (this.Tempdata.impact.length > 0 && this.Tempdata.rootCause.length > 0) {
            Likelyhood = Math.max(...this.Tempdata.rootCause.map(o => o.rootCause_Likelihood));
            Impact = Math.max(...this.Tempdata.impact.map(o => o.impact_Level));
        }

        for (let ii = 0; ii <= 4; ii++) {
            for (let i = 0; i <= 4; i++) {
                if ((Likelyhood - 1) === i && Impact === (ii + 1)) {
                    this.list.risk.risktable[(4 - ii)].col[i].item.push({
                        title: '1',
                        bgcolor: '#000',
                        color: '#fff'
                    })
                }
            }
        }
    }

    func_AddFile(event) {
        
        if (event.target.files && event.target.files[0]) {
            for (let item_targetFile of event.target.files){
                let fileSize = item_targetFile.size / 1024 / 1024;
                if (fileSize > 50) {
                    //console.log("> 50 MB");
                }
                else {
                    this.tempDisplayFile.push(item_targetFile)
                }
            }
        }
        //console.log(this.tempDisplayFile);
    }

    RemoveFile(item) {
        this.tempfileID = item;
        $('#modalConfirmDeleteFile').modal('show');
    }

    funcYesDeleteFile() {
        for (let i = 0; i < this.tempDisplayFile.length; i++) {
            if (this.tempDisplayFile[i].attachFileID === this.tempfileID.attachFileID) {
                this.tempDisplayFile.splice(i, 1);
                break;
            }
        }
        this.tempfileID = '';
        $('#modalConfirmDeleteFile').modal('hide');
    }
    funcNoDeleteFile() {
        $('#modalConfirmDeleteFile').modal('hide');
    }

    func_CheckStatus() {
        const _s = this;
        let chkrootpro = true;

        _s.checkDDstatus = false
        let chkroot = false, chkimpact = false
        chkroot = this.func_CheckStatus_CheckRootCause(_s, chkroot);
        chkimpact = this.func_CheckStatus_CheckImpact(_s, chkimpact);

        if (chkroot && chkimpact) {
            _s.checkDDstatus = true;
        } else {
            _s.checkDDstatus = false;
        }

        //console.log('checkDDstatus : ', _s.checkDDstatus)
    }

    private func_CheckStatus_CheckImpact(_s: this, chkimpact: boolean) {
        _s.Tempdata.impact.forEach(function (itemsImpact, ItemIndex) {
            if (itemsImpact.impact_Mitigation_Type == "Treat" || itemsImpact.impact_Mitigation_Type == "Transfer") {
                let tempFileter = _s.Tempdata.impact_Mitigation.filter(x => x.impact_Id == itemsImpact.impact_Id && x.impact_Mitigation_Progress == "100");
                if (tempFileter.length !== 0) {
                    chkimpact = true;
                    return;
                }
            }

            else
                chkimpact = true;
        });
        return chkimpact;
    }

    private func_CheckStatus_CheckRootCause(_s: this, chkroot: boolean) {
        _s.Tempdata.rootCause.forEach(function (itemsRootcause, ItemIndex) {
            if (itemsRootcause.rootCause_Mitigation_Type == "Treat"
                || itemsRootcause.rootCause_Mitigation_Type == "Transfer") {
                let tempFileter = _s.Tempdata.rootCause_Mitigation.filter(x => x.rootCause_Id == itemsRootcause.rootCause_Id && x.rootCause_Mitigation_Progress == "100");
                if (tempFileter.length !== 0) {
                    chkroot = true;
                    return;
                }
            }

            else
                chkroot = true;
        });
        return chkroot;
    }

    func_OkBU() {
        this.chkbackmenu = 'go'
        this.dataService.sendData(this.tempselectbu);
        $('#modalBU5').modal('hide');
    }
    BacktoMenu() {
        this.chkbackmenu = 'back'
        $('#modalBU5').modal('hide');
    }
    close() {
        $('#modalBU5').modal('show');
        $('#modalAlertBU').modal('hide');
    }


    private _filterBUDD(value: string): string[] {
        let tempFileter;
        const filterValue = value?.toLowerCase();
        if (filterValue !== '') {
            tempFileter = this.BusinessNameDD.filter(option => option.text?.toLowerCase().includes(filterValue));
            if (tempFileter.length !== 0) {
                return tempFileter;
            }
        } else {
            return this.BusinessNameDD
        }
    }

}

@Component({
    selector: 'app-dialog-elements',
    templateUrl: '../_comp/dialog-elements.html',
})
export class DialogElements {
    constructor(public dialogRef: MatDialogRef<DialogElements>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

    onNoClick() {
        this.dialogRef.close();
    }
}