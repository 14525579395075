import { Component, OnInit, Injectable } from "@angular/core";
import { Masterserve } from '../../../services/master.service';
import { NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
declare let $;


import {UntypedFormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import { AuthenService } from "src/app/services/authenservice.service";

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
    constructor(private HeadService: ConfigWPB){super()}
    readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day : parseInt(date[0], 10),
        month : parseInt(date[1], 10),
        year : parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    if (date !== null) {
        let str_year = date.year.toString();
        let str_mon = date.month.toString();
        let str_day = date.day.toString();

        if (str_mon.length < 2) {
            str_mon = "0" + str_mon
        }
        if (str_day.length < 2) {
            str_day = "0" + str_day
        }
        let day    =   str_day + this.DELIMITER + str_mon + this.DELIMITER + str_year 
        return day ;

    }
    else {
        return '' ;
    }
  }
}

@Component({
    selector: 'app-ConfigWPB',
    templateUrl: './ConfigWPB.html',
    styleUrls: ['./ConfigWPB.css'],
    providers: [
        {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
    ]
})
export class ConfigWPB implements OnInit {
    RegisAssetUnit;
    status;
    ParentOrgLevelDD;
    tablewpb = [];
    selorgname;
    selEmp;
    StatusDD = [];

    WpbId;
    StartDate;
    EndDate;
    Year
    minDate;
    maxDate;
    minDate2;
    maxDate2;
    YearDD = [];
    tempchkyear = null;
    currentyear = null;
    statusEdit;

    SessionEmpID;
    Data_SessionStorage;

    myControlOrgNameDD = new UntypedFormControl();
    myControlEmployeeID = new UntypedFormControl();
    OrgNameDD = [];
    EmployeeDD = [];
    filteredOptionsOrgNameDD: Observable<string[]>;
    filteredOptionsEmployeeIDDD: Observable<string[]>;

    constructor(public authserviceToken: AuthenService,public mserve: Masterserve) {}
    ngOnInit(): void {
        const _s = this;
        let date = new Date();
        this.currentyear = date.getFullYear();
        this.tempchkyear = date.getFullYear();

        _s.Data_SessionStorage = JSON.parse(sessionStorage.datainfo);
        //console.log('Data_SessionStorage => ',this.Data_SessionStorage);
        _s.SessionEmpID = this.Data_SessionStorage[0].employeE_ID;

        setInterval(function() {
            if ( _s.StartDate !== undefined) {
                _s.minDate2 = { year: _s.StartDate.year, month: _s.StartDate.month, day: _s.StartDate.day };
                _s.maxDate2 = { year: _s.StartDate.year, month: 12, day: 31 }
            }
        }, 300);


        this.StatusDD = [
            { value:'0', text:'Active' },
            { value:'1', text:'Inactive' }
        ]

        this.loadDDL();
        this.func_GetData();
    }

    func_GetData () {
        let formData = {
            SessionEmpID :this.SessionEmpID,
            Module :"getlist",
            body :{
               Year :"",
               StartDate :"",
               EndDate :"",
            }
        }
        this.mserve.GetWPB(formData).then((response) => {
            if (response.data.status ){
                //console.log("Get Data WPB : ",response.data.body)
                this.tablewpb = response.data.body;
              } else {
                //console.log('false',response)
                alert(response.data.errorMessage);
              }
        }).catch((e) => {
            this.authserviceToken.CheckTokenExpire(e.response.status)
            });
    }

    loadDDL(){
        let _s = this;

        this.mserve.Master_DDL({ Module:"YearDD" }).then((response) => {
            this.YearDD = response.data.body
            //console.log("YearDD : ",this.YearDD)
        });
    }

    func_btnAddAssetUnit () {
        let _s = this;
        _s.status = 'insert';
        
        _s.Year = '';
        _s.StartDate = '';
        _s.EndDate = '';
        _s.minDate = { year: new Date().getFullYear(), month: 6, day: 1 };

        $('#modaladdAssetUnit').modal('show');
    }

    func_edit (item) {
        let _s = this;
        _s.status = 'edit';
        _s.WpbId = item.wpbId
        _s.Year = item.year;
        _s.StartDate = _s.func_formatdateto_ngdate(item.startDateText);
        _s.EndDate = _s.func_formatdateto_ngdate(item.endDateText);

        _s.minDate = { year: parseInt(item.year), month: 1, day: 1 };
        _s.maxDate = { year: parseInt(item.year), month: 12, day: 31 };
       
        $('#modaladdAssetUnit').modal('show');
    }

    func_Close () {
        $('#modaladdAssetUnit').modal('hide');
    }

    func_save (value) {
        let _s = this;
        let formData = {
            SessionEmpID :this.SessionEmpID,
            Module :value,
            body :{
                WpbId :_s.WpbId,
                Year :_s.Year,
                StartDate :_s.func_formatdate(_s.StartDate),
                EndDate :_s.func_formatdate(_s.EndDate)
            }
        }
        this.mserve.GetWPB(formData).then((response) => {
            if (response.data.status ){
                $('#modaladdAssetUnit').modal('hide');
                this.ngOnInit();
            } else {
            //console.log('false',response)
            }
        }).catch((e) => {
            this.authserviceToken.CheckTokenExpire(e.response.status)
            });
    }

    func_formatdate(date){
        let sdate = date.year + '-' + date.month + '-' + date.day
        return sdate;
    }

    func_formatdateto_ngdate (value) {
        let editday = parseInt(value.substr(0, 2));
        let editmonth = parseInt(value.substr(3, 2));
        let edityear = parseInt(value.substr(6, 4));

        return {
            day : (editday),
            month : (editmonth),
            year : (edityear)
          };
    }

    SelectYear () {
        this.minDate = { year: parseInt(this.Year), month: 1, day: 1 };
        this.maxDate = { year: parseInt(this.Year), month: 12, day: 31 };
    }

}