<div class="container-fluid appform my-3">
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">

            <div class="col-12 col-md-12 col-lg-12 col-xl-12 mt-5">
                <div class="card mt-4 py-4">

                    <div class="col-12 mb-4">
                        <div class="col-8 col-xl-3 rounded Transferrisk" style="text-align: center;">
                            <strong>Delegate Risk Profile</strong>
                        </div>

                        <div class="col-12 mt-3 mb-3">
                            <div class="row col-12 mx-0 px-0 d-flex justify-content-around">
                                <div class="row col-12 col-xl-4">
                                    <div class="col-12 mb-2 px-0">
                                        <strong class="formtext">Type</strong>
                                    </div>
                                    <div class="col-12 px-0">
                                        <select class="form-control" [(ngModel)]="Search.Type" (change)="_Fucn_AllItem().func_changetype()">
                                            <option value="1">Co (Create)</option>
                                            <option value="2">Co (Submit)</option>
                                            <option value="3">Owner (Approve)</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row col-12 col-xl-4"></div>
                                <div class="row col-12 col-xl-4"></div>
                            </div>
                        </div>

                        <div class="col-12 mb-3">
                            <div class="row col-12 mx-0 px-0 d-flex justify-content-around">
                                <div class="row col-12 col-xl-4">
                                    <div class="col-12 mb-2 px-0">
                                        <strong class="formtext">Risk by Category</strong>
                                    </div>
                                    <div class="col-12 px-0">
                                        <select class="form-control" [(ngModel)]="Search.RiskCate">
                                            <option value="">Please Select</option>
                                            <option *ngFor="let data of Risk_ByCategory" [value]="data.value"> {{ data.text }} </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row col-12 col-xl-4">
                                    <div class="col-12 mb-2 px-0">
                                        <strong class="formtext">Risk Status</strong>
                                    </div>
                                    <div class="col-12 px-0">
                                        <select class="form-control" [(ngModel)]="Search.RiskStatus">
                                            <option value="">Please Select</option>
                                            <option value="1">Active</option>
                                            <option value="2">Close (Turn to problem)</option>
                                            <option value="3">Close (Mitigation Completed)</option>
                                            <option value="4">Close (Other)</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row col-12 col-xl-4">
                                    <div class="col-12 mb-2 px-0">
                                        <strong class="formtext">Business Unit Level</strong>
                                    </div>
                                    <div class="col-12 px-0">
                                        <select class="form-control" [(ngModel)]="Search.WorkflowStatus" [disabled]="Search.Type === '1'">
                                            <option value="">Please Select</option>
                                            <option value="1">Asset</option>
                                            <option value="2">Department</option>
                                            <option value="3">Division</option>
                                            <option value="4">FG</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mb-3">
                            <div class="row col-12 mx-0 px-0 d-flex justify-content-around">
                                <div class="row col-12 col-xl-4">
                                    <div class="col-12 mb-2 px-0">
                                        <strong class="formtext">Risk ID</strong>
                                    </div>
                                    <div class="col-12 px-0">
                                        <input type="text" class="form-control" [(ngModel)]="Search.RiskID">
                                    </div>
                                </div>
                                <div class="row col-12 col-xl-4">
                                    <div class="col-12 mb-2 px-0">
                                        <strong class="formtext">Risk Name</strong>
                                    </div>
                                    <div class="col-12 px-0">
                                        <input type="text" class="form-control" name="valueRisk_Name" autocomplete="off" [(ngModel)]="Search.RiskName">
                                    </div>
                                </div>
                                <div class="row col-12 col-xl-4">
                                    <div class="col-12 mb-2 px-0">
                                        <strong class="formtext">Pending by</strong>
                                    </div>
                                    <div class="col-12 px-0" [hidden]="Search.Type === '2' || Search.Type === '3'">
                                        <input type="text" class="form-control m-0" aria-label="Number" matInput 
                                        [formControl]="myControlEmpSearch" 
                                        [matAutocomplete]="empsearch" 
                                        placeholder="Search Name" 
                                        [(ngModel)]="Search.Pendingby" 
                                        (keyup.enter)="_Fucn_AllItem().selectedEmpSearch()">
                                        <mat-autocomplete autoActiveFirstOption #empsearch="matAutocomplete">
                                            <mat-option *ngFor="let option of filteredOptionsEmpSearch | async" [value]="option.text" (click)="_Fucn_AllItem().selectedEmpSearch()">
                                            {{option.text}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </div>
                                    <div class="col-12 px-0" [hidden]="Search.Type === '1'">
                                        <input type="text" class="form-control m-0" aria-label="Number" disabled>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 mb-3">
                            <div class="row col-12 mx-0 px-0 d-flex justify-content-around">
                                <div class="row col-12 col-xl-4">
                                    <div class="col-12 mb-2 px-0">
                                        <strong class="formtext">Business Unit</strong>
                                    </div>
                                    <div class="col-12 px-0">
                                        <input type="text" class="form-control m-0" aria-label="Number" matInput [formControl]="myControlBU" [matAutocomplete]="autobu" [(ngModel)]="Search.BUName" (keyup.enter)="_Fucn_AllItem().func_SelectSearchBU()">
                                        <mat-autocomplete autoActiveFirstOption #autobu="matAutocomplete">
                                            <mat-option *ngFor="let option of filteredOptionsBU | async" [value]="option.text" (click)="_Fucn_AllItem().func_SelectSearchBU()">
                                            {{option.text}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </div>
                                </div>
                                <div class="row col-12 col-xl-4"></div>
                                <div class="row col-12 col-xl-4"></div>
                            </div>
                        </div>
                        <div class="col-12 mt-5 mb-3" style="text-align: center;">
                            <button type="button" class="btn mr-3 mt-1" style="background:#9eb0ba;color: white;font-size: 20px;width: 130px;" (click)="_Fucn_AllItem().ClearSearch()">Clear</button>
                            <button type="button" class="btn mr-3 mt-1" style="background:#023154;color: white;font-size: 20px;width: 130px;" (click)="_Fucn_AllItem().func_search()">Search</button>
                            <button type="button" class="btn btn-warning mt-1" style="color:white; font-size: 20px;width: 130px;" (click)="_Fucn_AllItem().func_Delegate()">Delegate</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="card p-3">
                    <div class="col-12 px-0 table-responsive">
                        <table class="table-bordered" style="width:100%;"> 
                            <caption style="display: none;" >This is a custom table</caption>
                            <thead style="text-align: center;background-color: #023154;color:white;font-size:22px;">
                                <tr>
                                    <th style="width: 20px;">Action</th>
                                    <th style="width: 150px;">Risk ID</th>
                                    <th style="width: 250px;">Risk Name</th>
                                    <th style="width: 50px;">Risk Business</th>
                                    <th style="width: 50px;">Workflow Status</th>
                                </tr>
                            </thead>
                            <tbody style="background-color: white;font-size:20px;vertical-align: top;">
                                <tr *ngFor="let item of DataRiskDelegate | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize index as index ">
                                    <td style="text-align: center;">
                                        <input type="checkbox" name="selected{{ index + 1 }}" (change)="_Fucn_AllItem().selectedRisk(item, index+1)" style="zoom:1.5;" class="mr-3">
                                        <button type="button" class="btn" (click)="_Fucn_AllItem().func_ReveiewPage(item)">
                                            <em class="fas fa-search" style="font-size:20px;color:#6c757d"></em>
                                        </button>
                                    </td>
                                   <td>
                                        <span *ngIf="item.risk_Co_Id !== null; else elseBlock1">
                                            {{ item.risk_Co_Id }}
                                        </span>
                                        <ng-template #elseBlock1>
                                            <span>{{ item.risk_Staff_Id }}</span>
                                        </ng-template>
                                   </td>
                                   <td>{{ item.risk_Name }}</td>
                                   <td>{{ item.businessUnit }}</td>
                                   <td>{{ item.risk_Status_Workflow }}</td>
                            </tbody>
                        </table>
                    </div>

                    <div class="pagination mt-4">
                        <ngb-pagination 
                        [collectionSize]="DataRiskDelegate.length" 
                        [(page)]="page"
                        [pageSize]="pageSize"
                        [maxSize]="5" 
                        [rotate]="true"
                        [ellipses]="false" 
                        [boundaryLinks]="true">
                        <ng-template ngbPaginationPrevious>Prev</ng-template>
                        <ng-template ngbPaginationNext>Next</ng-template>
                        </ngb-pagination>
                    </div>

                </div>
            </div>

            <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" id="modalDelegate">
                <div class="modal-dialog modal-xl modal-dialog-centered">
                    <div class="modal-content">
                        <div class="col-12 col-md-12 col-lg-12 col-xl-12 p-2">
                            <div class="container col-12 col-md-12 col-lg-12 col-xl-12 pb-0 mt-0 mb-0 ">
                                
                                <div class="col-12 rounded modalEmail">
                                    <div class="col-0 pt-2">
                                        <em class="fas fa-bars icon mr-2"></em>Delegate Risk Profile
                                    </div>
                                </div>

                                <div class="card mt-4">
                                    <div class="row col-12 col-xl-12 mb-4 px-0 mx-0">

                                        <div class="col-12 table-responsive">
                                            <table class="table-bordered" style="width:100%;">
                                                <caption style="display: none;" >This is a custom table</caption>
                                                <thead style="text-align: center;background-color: #023154;color:white;font-size:18px;">
                                                    <tr>
                                                        <th style="width: 150px;">Risk ID</th>
                                                        <th style="width: 250px;">Risk Name</th>
                                                        <th style="width: 50px;">Risk Business</th>
                                                        <th style="width: 50px;">Workflow Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody style="background-color: white;font-size:20px;">
                                                    <tr *ngFor="let item of tempSelected index as index ">
                                                        <td>{{ item.RiskIDName }}</td>
                                                        <td>{{ item.RiskName }}</td>
                                                        <td>{{ item.RiskBU }}</td>
                                                        <td>{{ item.RiskStatusWorkflow }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div class="col-12 mt-5">
                                            <div class="row">
                                                <div class="col-12 col-xl-4 mt-2">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <strong class="formtext">To (Owner or Coordinator)</strong>
                                                        </div>
                                                        <div class="col-12" *ngIf="Search.Type === '3'">
                                                            <input type="text" class="form-control m-0" aria-label="Number" matInput [formControl]="myControlEmp" [matAutocomplete]="autobu2" placeholder="Search Name" [(ngModel)]="Delegate.EmpName" (keyup.enter)="_Func_Modal().func_SelectBU()">
                                                            <mat-autocomplete autoActiveFirstOption #autobu2="matAutocomplete">
                                                                <mat-option *ngFor="let option of filteredOptionsEmp | async" [value]="option.emp_FullName" (click)="_Func_Modal().func_SelectBU()">
                                                                {{option.emp_FullName}}
                                                                </mat-option>
                                                            </mat-autocomplete>
                                                        </div>
                                                        <div class="col-12" *ngIf="Search.Type === '1' || Search.Type === '2'">
                                                            <input type="text" class="form-control m-0" aria-label="Number" matInput [formControl]="myControlEmp" [matAutocomplete]="autobu2" placeholder="Search Name" [(ngModel)]="Delegate.EmpName" (keyup.enter)="_Func_Modal().func_SelectBU()">
                                                            <mat-autocomplete autoActiveFirstOption #autobu2="matAutocomplete">
                                                                <mat-option *ngFor="let option of filteredOptionsEmp | async" [value]="option.FullName" (click)="_Func_Modal().func_SelectBU()">
                                                                {{option.FullName}}
                                                                </mat-option>
                                                            </mat-autocomplete>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-xl-4 mt-2">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <strong class="formtext">E-mail</strong>
                                                        </div>
                                                        <div class="col-12">
                                                            <input type="text" class="form-control" [(ngModel)]="Delegate.Email" disabled>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-xl-4 mt-2">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <strong class="formtext">Business Unit ID</strong>
                                                        </div>
                                                        <div class="col-12">
                                                            <input type="text" class="form-control" [(ngModel)]="Delegate.BUID" disabled>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-12 mt-2">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <strong class="formtext">Remark</strong>
                                                        </div>
                                                        <div class="col-12">
                                                            <textarea class="form-control" rows="3" [(ngModel)]="Delegate.Remark"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-12 mb-3" style="text-align: center;">
                                    <button type="button" class="btn btn-secondary mr-3" style="width:100px;" (click)="_Func_Modal().Close_Modal()">Close</button>
                                    <button type="button" class="btn btn-primary" style="width:100px;" [disabled]="Delegate.Email === ''" (click)="_Func_Modal().Confirm_delegate()">OK</button>
                                </div>          

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalAlertDelegate">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <span class="bg-danger">&nbsp;</span>
                        <div class="modal-header">
                            <div class="row col-12 text-danger">
                                <div class="col-2">
                                    <em class="far fa-times-circle" style="font-size:50px;"></em>
                                </div>
                                <div class="col-10 pt-2">
                                    <h4 class="modal-title">Alert !</h4>
                                </div>
                            </div>
                        </div>
                        <div class="modal-body text-body border-0">
                            <div class="col-12" style="text-align: left;">
                                <span style="font-size:18px;">{{ alertflow }}</span>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger w-25" data-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalAlertsuccessDelegate">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <span class="bg-success">&nbsp;</span>
                        <div class="modal-header">
                            <div class="row col-12 text-success">
                                <div class="col-2">
                                    <em class="far fa-check-circle" style="font-size:50px;"></em>
                                </div>
                                <div class="col-10 pt-2">
                                    <h4 class="modal-title">Alert !</h4>
                                </div>
                            </div>
                        </div>
                        <div class="modal-body text-body border-0">
                            <div class="col-12" style="text-align: left;">
                                <span style="font-size:18px;">{{ alertflow }}</span>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-success w-25" (click)="_Func_Modal().closeflowsuccess()">OK</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading [loading]='loading'></app-loading>