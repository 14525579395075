import { Component, Input } from '@angular/core';
import { StrategyModel } from '../../model/StrategyModel';

@Component({
  selector: 'app-strategy-table',
  templateUrl: './strategy-table.component.html',
  styleUrls: ['./strategy-table.component.css']
})

export class StrategyTableComponent{
  private _datas: StrategyModel[];
  public _height: string = '100%' ;
  public _width: string = '100%' ;
  @Input() set datas(value: StrategyModel[]){
    this._datas = value;
    this.render();
  }

  @Input() set width(value: string){
    this._width = value;
  }

  @Input() set height(value: string){
    this._height = value;
  }

  dataTable = [
    [[],[],[],[],[]],
    [[],[],[],[],[]],
    [[],[],[],[],[]],
    [[],[],[],[],[]],
    [[],[],[],[],[]]
  ]



  render():void
  {
    this.clearTable();
    this.plotTable();
  }

  plotTable()
  {
    for(let i = 0; i< this._datas.length; i++)
    {  
      if(this._datas[i].likelihoodInherent && this._datas[i].impactInherent)
      {
        this.dataTable[Number(this._datas[i].likelihoodInherent)-1][Number(this._datas[i].impactInherent)-1].push({value: 1, number: i+1 });
      }
      if(this._datas[i].likelihoodIfMitigated && this._datas[i].impactIfMitigated)
      {
        this.dataTable[Number(this._datas[i].likelihoodIfMitigated)-1][Number(this._datas[i].impactIfMitigated)-1].push({value: 2, number: i+1 });
      }
    }
  }

  clearTable() {
    this.dataTable = [
      [[],[],[],[],[]],
      [[],[],[],[],[]],
      [[],[],[],[],[]],
      [[],[],[],[],[]],
      [[],[],[],[],[]]
    ]
  }

}
