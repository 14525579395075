<div class="container-fluid appform my-3">
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1"  >
            <div class="col-12 col-md-12 col-lg-12 col-xl-12 pb-5 mt-5 mb-5">
                <div class="card col-12 mt-4 mx-0 p-3">

                    <div class="col-6 col-md-4 col-xl-2 pt-2 banner rounded">
                        <em class="fas fa-bars icon"></em>&nbsp;&nbsp;Corporate Target
                    </div>

                    <button type="button" class="btn btn-lg btn-banner-primary mb-3 mt-4" style="width: 200px;color:white;background:orange;" (click)="func_addCorporate()">
                        <em class="fas fa-plus icon"></em>&nbsp;&nbsp;&nbsp;Add Corporate Target
                    </button>
                    <div class="table-banner">
                        <table class="table-bordered" style="width:100%;">
                            <caption style="display: none;" >This is a custom table</caption>
                            <thead style="text-align: center;background-color:#033153;color:white;font-size:18px;">
                                <tr>
                                    <th style="width: 10px;vertical-align: middle;">No</th>
                                    <th style="width: 100px;vertical-align: middle;">Corporate Name</th>
                                    <th style="width: 100px;vertical-align: middle;">Corporate Year</th>
                                    <th style="width: 250px;vertical-align: middle;">Corporate Item</th>
                                    <th style="width: 10px;vertical-align: middle;">Edit</th>
                                </tr>
                            </thead>
                            <tbody style="background-color: white;font-size:16px;">
                                <tr *ngFor='let item of temptableCorporate index as index1'>
                                    <td style="text-align: center;">{{ index1 + 1 }}</td>
                                    <td style="text-align: center;">{{item.corpTargetName}}</td>
                                    <td style="text-align: center;">{{item.corpTargetYear}}</td>
                                    <td>
                                        <span *ngFor="let item2 of item.sResCorpTargetItem"> {{item2.corpTargetItemName}} </span>
                                    </td>
                                    <td style="text-align: center;">
                                        <button type="button" class="btn" (click)="func_edit(item.corpTargetID)">
                                            <em class="fas fa-edit" style="color:orange;font-size:16px;"></em>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>

        </div>
    </div>
    <div class="modal fade bd-Mianmenu" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" id="modaladdHeaderCorporate">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="container-fluid appform my-3">
                    <div class="row">
                        <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">
                            <div class="container col-12 col-md-12 col-lg-12 col-xl-12 pb-0 mt-0 mb-0 ">
                                <div class="container">
                                    <div class="col-8 col-md-7 col-lg-6 col-xl-3 pt-2 rounded modalbanner">
                                        <em class="fas fa-bars icon"></em>
                                        <span *ngIf="statusheader === 'insert'">&nbsp;&nbsp;Add Corporate Target</span>
                                        <span *ngIf="statusheader === 'edit'">&nbsp;&nbsp;Edit Corporate Target</span>
                                    </div>
                                    <div class="card mt-4 py-3">

                                        <div class="row col-12 mb-4 mx-0 px-0">

                                            <div class="row col-12 col-xl-4 mx-0 px-0">
                                                <div class="col-12 mb-2">
                                                    <strong class="formtext">Header Target Name</strong>
                                                </div>
                                                <div class="col-12">
                                                    <input type="text" class="form-control" [(ngModel)]="DataCorporate.CorporHeaderName">
                                                </div>
                                            </div>

                                            <div class="row col-12 col-xl-3 mx-0 px-0">
                                                <div class="col-12 mb-2">
                                                    <strong class="formtext">Year</strong>
                                                </div>
                                                <div class="col-12">
                                                    <select class="form-control" [(ngModel)]="DataCorporate.CorporYear" [disabled]=' statusheader == "edit" '>
                                                        <option value="">Please Select</option>
                                                        <option *ngFor="let item of yearDD_insert" [disabled]=' item.text < currentYear '>{{ item.text }}</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="row col-12 col-xl-3 mx-0 px-0" >
                                                <div class="col-12">
                                                    <input id="chksta" type="checkbox" [(ngModel)]="DataCorporate.StatusHead">&nbsp;&nbsp;&nbsp;
                                                    <label for="chksta"><strong class="formtext">Active</strong></label>
                                                </div>
                                            </div>
                                            
                                        </div>

                                        <div class="col-12 mb-2 px-4" style="text-align: right;">
                                            <button type="button" class="btn" style="color:white;background:orange;" (click)="func_addItemCorporate('insert')" *ngIf="statusheader === 'insert'">
                                                <em class="fas fa-plus icon"></em>&nbsp;&nbsp;&nbsp;Add Item
                                            </button>
                                            <button type="button" class="btn" style="color:white;background:orange;" (click)="func_addItemCorporate('editinsert')" *ngIf="statusheader === 'edit'">
                                                <em class="fas fa-plus icon"></em>&nbsp;&nbsp;&nbsp;Add Item
                                            </button>
                                        </div>

                                        <div class="col-12 px-3">
                                            <table class="table-bordered" style="width:100%;">
                                                <caption style="display: none;" >This is a custom table</caption>
                                                <thead style="text-align: center;background-color:#033153;color:white;">
                                                    <tr>
                                                        <th style="width: 10px;vertical-align: middle;">No</th>
                                                        <th style="width: 300px;vertical-align: middle;">Corporate Item Name</th>
                                                        <th style="width: 50px;vertical-align: middle;">Edit</th>
                                                    </tr>
                                                </thead>
                                                <tbody style="background-color: white;">
                                                    <tr *ngFor="let item of tempItemtableCorporate index as index">
                                                        <td style="text-align: center;">{{ index + 1 }}</td>
                                                        <td>{{ item.CorpTargetItemName }}</td>
                                                        <td style="text-align: center;">
                                                            <button type="button" class="btn" (click)="func_edititem(item.CorpTargetItemID)">
                                                                <em class="fas fa-edit" style="color:orange;font-size:20px;"></em>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                    <div class="col-12 my-3 mx-0 text-center">
                                        <button type="button" class="btn mr-3" style="width: 100px;background-color:#9eb0ba;color:white;" (click)="func_closebtn()">Close</button>
                                        <button type="button" class="btn" style="width: 100px;background-color:#033153;color:white;" (click)="func_save('insert')" *ngIf="statusheader === 'insert'">Save</button>
                                        <button type="button" class="btn" style="width: 100px;background-color:#033153;color:white;" (click)="func_save('update')" *ngIf="statusheader === 'edit'">Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" id="modaladdItemCorporate">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="container-fluid appform my-3">
                    <div class="row">
                        <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">
                            <div class="container col-12 col-md-12 col-lg-12 col-xl-12 pb-0 mt-0 mb-0 ">
                                <div class="container">
                                    <div class="col-6 col-md-5 col-lg-4 col-xl-3 pt-2 rounded modalbanner">
                                        <em class="fas fa-bars icon"></em><span>&nbsp;&nbsp;Add Item</span>
                                    </div>
                                    <div class="card mt-4 py-3">

                                        <div class="row col-12 mx-0 px-0">
                                            <div class="row col-12 col-xl-10 mx-0 px-0">
                                                <div class="col-12 mb-2 px-3">
                                                    <strong class="formtext">Corporate Item Target Name</strong>
                                                </div>
                                                <div class="col-12">
                                                    <input type="text" class="form-control" [(ngModel)]="DataCorporate.CorporItemName">
                                                </div>
                                            </div>
                                            <div class="col-12 col-xl-2">
                                                <div class="col-12">
                                                    <input id="chkstaitem" type="checkbox" [(ngModel)]="DataCorporate.StatusItem">&nbsp;&nbsp;&nbsp;
                                                    <label for="chkstaitem"><strong class="formtext">Active</strong></label>  
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-12 my-3 mx-0 text-center">
                                        <button type="button" class="btn mr-3" style="width: 100px;background-color:#9eb0ba;color:white;" (click)="func_closebtnItem()">Back</button>
                                        <button type="button" class="btn" style="width: 100px;background-color:#033153;color:white;" (click)="func_additem('insert')" *ngIf="statusitem === 'add'">Add</button>
                                        <button type="button" class="btn" style="width: 100px;background-color:#033153;color:white;" (click)="func_additem('editinsert')" *ngIf="statusitem === 'addedit'">Add</button>
                                        <button type="button" class="btn" style="width: 100px;background-color:#033153;color:white;" (click)="func_additem('update')" *ngIf="statusitem === 'edit'">Update</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-loading [loading]='loading'></app-loading>