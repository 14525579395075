import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Masterserve } from '../../../services/master.service';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { RiskMap } from '../../../services/riskmap.service';
import * as XLSX from 'xlsx';
import { ExcelService2 } from '../../../services/excel2.service';
declare let $;
import { UntypedFormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AuthenService } from "src/app/services/authenservice.service";


@Component({
    selector: 'app-MasterFinancial',
    templateUrl: './masterfinancial.html',
    styleUrls: ['./masterfinancial.css'],
    providers: [Masterserve, NgbModalConfig, NgbModal, RiskMap]

})

export class MasterFinancial implements OnInit {

    myControlEmployeeID = new UntypedFormControl();
    EmployeeDD_auto = [];
    filteredOptionsEmployeeIDDD: Observable<string[]>;

    myControlBUDD = new UntypedFormControl();
    BUDD_auto = [];
    filteredOptionsBUDD: Observable<string[]>;

    Data_SessionStorage

    loading = false;

    TempdataEmployee = [];
    DataFG = [];
    DatainFG = [
        {
            FG: 'SBD',
            CO: 'User1',
            div: [
                {
                    DIV: 'SCS',
                    CO: 'User2',
                    dept: [
                        {
                            DEPT: 'SEP',
                            CO: 'User3'
                        }
                    ]
                }
            ]
        }
    ]
    OptimizedData = []
    TempdataAPICR = [];
    TempSelectFG = [];
    TempindexSelectFG = null;
    Type_financialScale = '';
    Quarter = [];
    BusinessName = [];
    tablefinancial;
    quarterCategory;
    Quarter_Getlist;

    SessionEmpID;
    Module;
    model;
    search;

    BusinessID = "";
    QuarterID;
    BusinessCode;
    BusinessUnit;
    NI;
    NPV_EMV;

    YearDD;
    QuarterDD;
    QuarterDD2;
    tempdepart = '';

    TempdataExcel;
    ExJson = [];
    keys: string[];
    dataSheet = new Subject();
    @ViewChild('inputFile') inputFile: ElementRef;
    Msgvalidate = '';

    Alltable;
    tableimpactCriteria;

    financialScale = [];
    alertflow = '';

    constructor(config: NgbModalConfig, private modalService: NgbModal, public mserve: Masterserve, public riskser: RiskMap, private excelService2: ExcelService2, public authserviceToken: AuthenService) {
        config.backdrop = 'static';
        config.keyboard = false;
    }

    currentyear;
    tempchkyear;

    ngOnInit(): void {
        let _s = this;
        this.Data_SessionStorage = JSON.parse(sessionStorage.datainfo);

        this.myControlBUDD.disable()

        let date = new Date();
        _s.currentyear = date.getFullYear();
        _s.tempchkyear = date.getFullYear();

        _s.model = {
            Year: "",
            Quarter: ""
        }

        _s.search = {
            Year: "",
            BusinessId: "",
            Quarter: ""
        }

        _s.func_GetDDL_BusinessName();
        _s.loadDDL();
        this.loading = false;
    }

    func_GetDDL_BusinessName() {
        let formData = {
            Module: 'DepartmentDD'
        }
        this.mserve.Master_DDL(formData).then((response) => {
            //console.log("DDLPosition :", response.data.body)
            if (response.data.status) {
                this.BusinessName = response.data.body;

            } else {
                //console.log('false', response)
            }
        });
    }

    loadDDL() {
        let _s = this;

        _s.mserve.Master_DDL({ Module: "YearDD" }).then((response) => {
            _s.YearDD = response.data.body
            //console.log("YearDD : ", _s.YearDD)
        })

        let d = new Date();
        _s.search.Year = d.getFullYear();
        let currentMonth = d.getMonth() + 1;
        _s.mserve.Master_DDL({ Module: "QuarterDD", TextSearch1: _s.search.Year }).then((response) => {
            _s.QuarterDD = response.data.body
            //console.log("QuarterDD : ", _s.QuarterDD)
            this.func_calQuarter(currentMonth);
        })

        _s.mserve.Master_DDL({ Module: "DepartmentDD" }).then((response) => {
            _s.BUDD_auto = response.data.body;
            //console.log('DepartmentDD', _s.BUDD_auto)

            this.filteredOptionsBUDD = this.myControlBUDD.valueChanges.pipe(
                startWith(''),
                map(value => this._filterBUDD(value))
            );

        })
    }

    func_calQuarter(month) {
        const _s = this;
        _s.search.Quarter = ''
        if (month >= 1 && month <= 3) {
            _s.search.Quarter = this.QuarterDD[0].value;
        } else if (month >= 4 && month <= 6) {
            _s.search.Quarter = this.QuarterDD[1].value;
        } else if (month >= 7 && month <= 9) {
            _s.search.Quarter = this.QuarterDD[2].value;
        } else if (month >= 10 && month <= 12) {
            _s.search.Quarter = this.QuarterDD[3].value;
        }
        _s.GetDataFG('1');
        _s.func_Getlist();
    }

    onFileChange(evt: any) {
        const target: DataTransfer = <DataTransfer>(evt.target);
        if (target.files.length !== 1) throw new Error('Cannot use multiple files');
        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
            const bstr: string = e.target.result;
            const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

            const wsname: string = wb.SheetNames[0];
            //console.log(wsname)
            const ws: XLSX.WorkSheet = wb.Sheets[wsname];
            //console.log(ws)

            let data = XLSX.utils.sheet_to_json(ws);
            let NewJson = XLSX.utils.sheet_to_json(ws, { header: 1 });
            reader.onloadend = (e) => {

                this.keys = Object.keys(data[0]);
                this.dataSheet.next(data)
            }
            let arrdataEx = [];
            for (let i = 1; i < NewJson.length; i++) {
                let dataEx = {
                    BusinessCode: '',
                    BusinessUnit: '',
                    NI: '',
                    NPV_EMV: '',
                };
                dataEx.BusinessCode = NewJson[i][0] == undefined ? '' : NewJson[i][0]
                dataEx.BusinessUnit = NewJson[i][1] == undefined ? '' : NewJson[i][1]
                dataEx.NI = NewJson[i][2] == undefined ? '' : NewJson[i][2]
                dataEx.NPV_EMV = NewJson[i][3] == undefined ? '' : NewJson[i][3]
                arrdataEx.push(dataEx);
            }
            //console.log('arrdataEx', arrdataEx)
            this.ExJson = arrdataEx;

        };
        reader.readAsBinaryString(target.files[0]);
    }

    removeData() {
        this.inputFile.nativeElement.value = '';
        this.dataSheet.next(null);
        this.keys = null;
    }

    importexcel() {
        let _s = this;
        if (_s.validate()) {
            let datavalue = {
                SessionEmpID: this.Data_SessionStorage[0].employeE_ID,
                Module: "importExcel",
                body: {
                    QuarterID: _s.model.Quarter,
                    sFinancialEx: _s.ExJson
                }
            }
            //console.log('datavalue', datavalue)
            _s.mserve.GetFinancialImpact(datavalue).then((response) => {
                if (response.data.status === true) {
                    _s.model.Year = ''
                    _s.model.Quarter = ''
                    _s.inputFile.nativeElement.value = '';
                    _s.func_Getdata();
                    $('#modalimportexcel').modal('hide');
                } else {
                    //console.log('false', response)
                    alert(response.data.errorMessage);
                }
            }).catch((e) => {
                if (e.response) {
                    this.authserviceToken.CheckTokenExpire(e.response.status)
                } else {
                    //console.log(e)
                }
            });
        }
        else {
            alert(_s.Msgvalidate);
        }
    }

    SelectedYear(value) {

        let _s = this;
        _s.search.Quarter = '';
        _s.mserve.Master_DDL({ Module: "QuarterDD", TextSearch1: _s.search.Year }).then((response) => {
            _s.QuarterDD = response.data.body
            //console.log("QuarterDD : ", _s.QuarterDD);
        })
    }

    InertEditSelectedYear(value) {

        let _s = this;
        _s.mserve.Master_DDL({ Module: "QuarterDD", TextSearch1: _s.model.Year }).then((response) => {
            _s.QuarterDD2 = response.data.body
            //console.log("QuarterDD2 : ", _s.QuarterDD2)

            if (value !== '') {
                for (let item_QuarterDD2 of _s.QuarterDD2) {
                    if (value === item_QuarterDD2.value) {
                        _s.model.Quarter = item_QuarterDD2.value
                    }
                }
            } else {
                _s.model.Quarter = '';
            }
        })
    }

    func_Getdata() {

        let _s = this;
        let datavalue = {
            SessionEmpID: this.Data_SessionStorage[0].employeE_ID,
            Module: "getlist",
            body: {
                BusinessID: "",
                QuarterID: _s.search.Quarter,
                BusinessCode: "",
                BusinessUnit: _s.search.BusinessId,
                NI: "",
                NPV_EMV: "",
            }
        }

        _s.mserve.GetFinancialImpact(datavalue).then((response) => {
            if (response.data.status) {
                //console.log("Data Financial : ", response.data.body)
                _s.tablefinancial = response.data.body;
            } else {
                //console.log('false', response)
                alert(response.data.errorMessage);
            }
        }).catch((e) => {
            if (e.response) {
                this.authserviceToken.CheckTokenExpire(e.response.status)
            } else {
                //console.log(e)
            }
        });
    }

    validate() {
        let _s = this;
        let s = true;

        if (_s.ExJson.length == 0) {
            _s.Msgvalidate = 'No File chosen';
            s = false;
        }
        return s
    }


    func_addfinancial(item, mode) {

        let _s = this;
        let tempBUUnit = null;
        if (item.FG !== undefined) {
            tempBUUnit = item.FG
        } else if (item.DIV !== undefined) {
            tempBUUnit = item.DIV
        } else if (item.DEPT !== undefined) {
            tempBUUnit = item.DEPT
        } else if (item.asset_Name !== undefined) {
            tempBUUnit = item.asset_Name
        }

        if (mode === 'Add') {
            _s.Module = 'insert'
            _s.BusinessID = ''
            _s.model.Year = _s.currentyear;
            _s.model.Quarter = _s.search.Quarter;
            _s.QuarterID = ''
            _s.BusinessCode = ''
            _s.BusinessUnit = tempBUUnit;
            _s.func_selectCoorBU(_s.BusinessUnit);
            _s.NI = ''
            _s.NPV_EMV = ''
        } else {
            _s.Module = 'update'
            _s.model.Year = _s.currentyear
            _s.model.Quarter = '';
            _s.InertEditSelectedYear(_s.search.Quarter)
            _s.BusinessID = item.businessID
            _s.BusinessUnit = tempBUUnit
            _s.func_selectCoorBU(_s.BusinessUnit)
            _s.NI = item.ni
            _s.NPV_EMV = item.npV_EMV
        }

        $('#modaladdfinancial').modal('show')
    }

    func_savebtn() {
        let _s = this;
        let chk = true;
        if (_s.model.Quarter === '') {
            alert("Please select Quarter");
            chk = false;
        } else if (_s.BusinessUnit === '') {
            alert("Please select Business");
            chk = false;
        } else if (_s.NI === '') {
            alert("Please fill NI");
            chk = false;
        } else if (_s.NPV_EMV === '') {
            alert("Please fill NPV/EMV");
            chk = false;
        }

        if (chk === true) {
            let datavalue = {
                SessionEmpID: this.Data_SessionStorage[0].employeE_ID,
                Module: _s.Module,
                body: {
                    BusinessID: _s.BusinessID,
                    QuarterID: _s.model.Quarter,
                    BusinessCode: _s.BusinessCode,
                    BusinessUnit: _s.BusinessUnit,
                    NI: _s.NI,
                    NPV_EMV: _s.NPV_EMV,
                    DelFlag: '0'
                }
            }
            _s.mserve.GetFinancialImpact(datavalue).then((response) => {
                if (response.data.status === true) {
                    //console.log("true", response.data.body);
                    $('#modaladdfinancial').modal('hide');
                    _s.GetDataFG('2');
                } else {
                    //console.log('false', response)
                    alert(response.data.ErrorMessage);
                }
            }).catch((e) => {
                if (e.response) {
                    this.authserviceToken.CheckTokenExpire(e.response.status)
                } else {
                    //console.log(e)
                }
            });

        }

    }

    func_closebtn() {
        const _s = this;
        _s.model.Year = '';
        _s.model.Quarter = '';
        $('#modaladdfinancial').modal('hide')
    }

    func_importEx() {
        let _s = this;
        $('#modalimportexcel').modal('show');
    }

    func_closeImportEx() {
        $('#modalimportexcel').modal('hide');
    }

    func_selectCoorBU(value) {
        const _s = this;
        for (let item_BUDD_auto of this.BUDD_auto) {
            if (item_BUDD_auto.text === value) {
                _s.tempdepart = item_BUDD_auto.value;
                _s.BusinessCode = item_BUDD_auto.text1;
                break;
            }
        }
    }

    GetDataFG(chk) {
        const _s = this;
        this.loading = true;

        if (chk == 1) {
            _s.TempSelectFG = [];
        }

        let formdata = {
            SessionEmpID: this.Data_SessionStorage[0].employeE_ID,
            SecurityCode: "1234",
            Email: this.Data_SessionStorage[0].emaiL_ID,
            body: {
                QuarterID: _s.search.Quarter,
            }
        }
        _s.mserve.Get_Menu_BU_Config(formdata).then((response) => {
            if (response.data.status) {
                //console.log('DATA GET : ', response.data.body)
                this.TempdataAPICR = response.data.body;
                this.loading = false;
                this.tempchkyear = this.search.Year
                this.GetHeaderBU();
            }
            else {
                alert(response.data.errorMessage);
                //console.log('false', response)
            }
        }).catch((e) => {
            this.loading = false;
            if (e.response) {
                this.authserviceToken.CheckTokenExpire(e.response.status)
            } else {
                //console.log(e)
            }
        });
    }

    GetHeaderBU() {
        const _s = this;
        _s.DataFG = [];

        if (_s.TempdataAPICR[0].division_Level !== null) {
            _s.TempdataAPICR[0].division_Level.forEach(function (ItemVal, ItemIndex) {
                ItemVal.org_Menu.forEach(function (ItemVal2, ItemIndex2) {
                    _s.DataFG.push({
                        title: ItemVal2.abbreviation,
                        value: ItemVal2.organization_Code,
                        data: ItemVal2
                    })
                });
            });
        }

        if (_s.TempdataAPICR[0].groupDivision_Level !== null) {
            _s.TempdataAPICR[0].groupDivision_Level.forEach(function (ItemVal, ItemIndex) {
                ItemVal.org_Menu.forEach(function (ItemVal2, ItemIndex2) {
                    _s.DataFG.push({
                        title: ItemVal2.abbreviation,
                        value: ItemVal2.organization_Code,
                        data: ItemVal2
                    })
                });
            });
        }


        //console.log('DataFG : ', _s.DataFG);
        if (this.TempSelectFG.length === 0) {
            _s.SelectFG(_s.DataFG[0], '')
        } else {
            _s.SelectFG(_s.TempSelectFG[0], (this.TempindexSelectFG - 1))
        }
    }

    SelectFG(item, indexsel) {
        const _s = this;

        _s.OptimizedData = [];
        this.TempSelectFG = [];
        this.TempSelectFG.push({ title: item.title, value: item.value });

        _s.DataFG.forEach(function (ItemVal, ItemIndex) {
            if (item.value === ItemVal.value) {

                if (ItemVal.data.organization_Level === 'Division') {
                    dvisionProcess(_s, ItemVal);
                }

                if (ItemVal.data.organization_Level === 'Group') {
                    GroupProcess(ItemVal);
                }
            }
        });

        //console.log('OptimizedData', _s.OptimizedData);

        $(document).ready(function () {
            let e1 = document.getElementById("BUCO1");
            e1.classList.add("active");
            if (_s.TempindexSelectFG !== null) {
                e1.classList.remove("active");
                let e2 = document.getElementById("BUCO" + _s.TempindexSelectFG);
                e2.classList.add("active");
            }
        });
        this.TempindexSelectFG = null;
        this.TempindexSelectFG = (indexsel + 1);

        function GroupProcess(ItemVal: any) {
            _s.OptimizedData.push({
                FG: ItemVal.data.abbreviation,
                CO: []
            });


            GroupProcess_I_ItemValco(ItemVal, _s);

            //GroupProcess_I_itemvalfi
            GroupProcess_I_itemvalfi(ItemVal);

            if (ItemVal.data.asset_Level.length !== 0) {
                //GroupProcess_I_ItemValass
                GroupProcess_I_ItemValass(ItemVal);
            }

            if (ItemVal.data.division_Level.length !== 0) {
                //GroupProcess_I_ItemVal1
                GroupProcess_I_ItemVal1(ItemVal);
            }
        }
        function dvisionProcess(_s: any, ItemVal: any) {

            _s.OptimizedData.push({
                DIV: ItemVal.data.abbreviation,
                CO: []
            });
            GroupProcess_I_ItemValco(ItemVal, _s);
            dvi_Valfi(ItemVal, _s);

            if (ItemVal.data.asset_Level.length !== 0) {
                ItemVal.data.asset_Level.forEach(function (ItemValass, ItemIndexass) {
                    _s.OptimizedData.push({
                        Parent: ItemVal.data.abbreviation,
                        Asses: ItemValass.abbreviation,
                        CO: []
                    });
                    this.GroupProcess_II_ItemValcoass(ItemValass, _s);
                    this.dvi_itemvalassfi(ItemValass, _s);
                });
            }


            if (ItemVal.data.department_Level.length !== 0) {
                ItemVal.data.department_Level.forEach(function (ItemVal1, ItemIndex1) {
                    _s.OptimizedData.push({
                        DEPT: ItemVal1.abbreviation,
                        CO: []
                    });
                    ItemVal1.coInfo.forEach(function (ItemValco1, ItemIndexco1) {
                        _s.OptimizedData[_s.OptimizedData.length - 1].CO.push({
                            coname: ItemValco1.coordinator_EName,
                            coid: ItemValco1.coordinatorId,
                        });
                        if (ItemIndexco1 === 0) {
                            _s.OptimizedData[_s.OptimizedData.length - 1].quarterID = ItemValco1.quarterID;
                            _s.OptimizedData[_s.OptimizedData.length - 1].quarterYear = ItemValco1.quarterYear;
                        }
                    });
                    ItemVal1.financial.forEach(function (itemvalfi1, itemindexfi1) {
                        _s.OptimizedData[_s.OptimizedData.length - 1].ni = itemvalfi1.ni;
                        _s.OptimizedData[_s.OptimizedData.length - 1].npV_EMV = itemvalfi1.npV_EMV;
                        _s.OptimizedData[_s.OptimizedData.length - 1].businessID = itemvalfi1.businessID;
                    });

                    if (ItemVal1.asset_Level.length !== 0) {
                        ItemVal1.asset_Level.forEach(function (ItemValass1, ItemIndexass1) {
                            _s.OptimizedData.push({
                                Parent: ItemVal1.abbreviation,
                                Asses: ItemValass1.abbreviation,
                                CO: []
                            });
                            ItemValass1.coInfo.forEach(function (ItemValcoass1, ItemIndexcoass1) {
                                _s.OptimizedData[_s.OptimizedData.length - 1].CO.push({
                                    coname: ItemValcoass1.asset_Coordinators_EName,
                                    coid: ItemValcoass1.asset_Id,
                                });
                                if (ItemIndexcoass1 === 0) {
                                    _s.OptimizedData[_s.OptimizedData.length - 1].asset_Name = ItemValcoass1.asset_Name;
                                    _s.OptimizedData[_s.OptimizedData.length - 1].asset_Short = ItemValcoass1.asset_Short;
                                    _s.OptimizedData[_s.OptimizedData.length - 1].asset_Level = ItemValcoass1.asset_Level;
                                    _s.OptimizedData[_s.OptimizedData.length - 1].asset_Code = ItemValcoass1.asset_Code;
                                    _s.OptimizedData[_s.OptimizedData.length - 1].quarterID = ItemValcoass1.quarterID;
                                    _s.OptimizedData[_s.OptimizedData.length - 1].quarterYear = ItemValcoass1.quarterYear;
                                    _s.OptimizedData[_s.OptimizedData.length - 1].delFlag = ItemValcoass1.delFlag;
                                }
                            });
                            ItemValass1.financial.forEach(function (itemvalassfi1, itemindexassfi1) {
                                _s.OptimizedData[_s.OptimizedData.length - 1].ni = itemvalassfi1.ni;
                                _s.OptimizedData[_s.OptimizedData.length - 1].npV_EMV = itemvalassfi1.npV_EMV;
                                _s.OptimizedData[_s.OptimizedData.length - 1].businessID = itemvalassfi1.businessID;
                            });
                        });
                    }

                });
            }
        }
        function GroupProcess_I_ItemVal1(ItemVal: any) {
            ItemVal.data.division_Level.forEach(function (ItemVal1, ItemIndex1) {
                //GroupProcess_II_Division
                GroupProcess_II_Division(ItemVal1);
                //GroupProcess_II_Department                
                GroupProcess_II_Department(ItemVal1);
            });
        }

        function GroupProcess_II_Department(ItemVal1: any) {
            if (ItemVal1.organization_Level == "Department") {
                _s.OptimizedData.push({
                    DEPT: ItemVal1.abbreviation,
                    CO: []
                });

                //GroupProcess_III_forEachItemValco1
                GroupProcess_III_forEachItemValco1(ItemVal1);

                //GroupProcess_III_forEachitemvalfi1           
                GroupProcess_III_forEachitemvalfi1(ItemVal1);

                //GroupProcess_III_chkAsset_Level     
                GroupProcess_III_chkAsset_Level(ItemVal1);

                //GroupProcess_III_chkDepartment_Level   
                GroupProcess_III_chkDepartment_Level(ItemVal1);
            }
        }

        function GroupProcess_III_forEachitemvalfi1(ItemVal1: any) {
            ItemVal1.financial.forEach(function (itemvalfi1, itemindexfi1) {
                _s.OptimizedData[_s.OptimizedData.length - 1].ni = itemvalfi1.ni;
                _s.OptimizedData[_s.OptimizedData.length - 1].npV_EMV = itemvalfi1.npV_EMV;
                _s.OptimizedData[_s.OptimizedData.length - 1].businessID = itemvalfi1.businessID;
            });
        }

        function GroupProcess_III_forEachItemValco1(ItemVal1: any) {
            ItemVal1.coInfo.forEach(function (ItemValco1, ItemIndexco1) {
                _s.OptimizedData[_s.OptimizedData.length - 1].CO.push({
                    coname: ItemValco1.coordinator_EName,
                    coid: ItemValco1.coordinatorId,
                });
                if (ItemIndexco1 === 0) {
                    _s.OptimizedData[_s.OptimizedData.length - 1].quarterID = ItemValco1.quarterID;
                    _s.OptimizedData[_s.OptimizedData.length - 1].quarterYear = ItemValco1.quarterYear;
                }
            });
        }

        function GroupProcess_II_Division(ItemVal1: any) {
            if (ItemVal1.organization_Level == "Division") {
                _s.OptimizedData.push({
                    DIV: ItemVal1.abbreviation,
                    CO: []
                });

                //GroupProcess_III_forEachItemValco1
                GroupProcess_III_forEachItemValco1(ItemVal1);

                //GroupProcess_III_forEachitemvalfi1
                GroupProcess_III_forEachitemvalfi1(ItemVal1);

                //GroupProcess_III_chkAsset_Level    
                GroupProcess_III_chkAsset_Level(ItemVal1);

                //GroupProcess_III_chkDepartment_Level
                GroupProcess_III_chkDepartment_Level(ItemVal1);
            }
        }
        function GroupProcess_III_chkDepartment_Level(ItemVal1: any) {
            if (ItemVal1.department_Level.length !== 0) {
                //GroupProcess_V
                ItemVal1.department_Level.forEach(function (ItemVal2, ItemIndex2) {
                    _s.OptimizedData.push({
                        DEPT: ItemVal2.abbreviation,
                        CO: []
                    });

                    //GroupProcess_VI
                    ItemVal2.coInfo.forEach(function (ItemValco2, ItemIndexco2) {
                        _s.OptimizedData[_s.OptimizedData.length - 1].CO.push({
                            coname: ItemValco2.coordinator_EName,
                            coid: ItemValco2.coordinatorId,
                        });
                        if (ItemIndexco2 === 0) {
                            _s.OptimizedData[_s.OptimizedData.length - 1].quarterID = ItemValco2.quarterID;
                            _s.OptimizedData[_s.OptimizedData.length - 1].quarterYear = ItemValco2.quarterYear;
                        }
                    });

                    //GroupProcess_VI
                    ItemVal2.financial.forEach(function (itemvalfi2, itemindexfi2) {
                        _s.OptimizedData[_s.OptimizedData.length - 1].ni = itemvalfi2.ni;
                        _s.OptimizedData[_s.OptimizedData.length - 1].npV_EMV = itemvalfi2.npV_EMV;
                        _s.OptimizedData[_s.OptimizedData.length - 1].businessID = itemvalfi2.businessID;
                    });

                    //GroupProcess_VI
                    if (ItemVal2.asset_Level.length !== 0) {

                        //GroupProcess_VII
                        ItemVal2.asset_Level.forEach(function (ItemValass2, ItemIndexass2) {
                            _s.OptimizedData.push({
                                Parent: ItemVal2.abbreviation,
                                Asses: ItemValass2.abbreviation,
                                CO: []
                            });

                            //GroupProcess_VII
                            ItemValass2.coInfo.forEach(function (ItemValcoass2, ItemIndexcoass2) {
                                //GroupProcess_VIII
                                _s.OptimizedData[_s.OptimizedData.length - 1].CO.push({
                                    coname: ItemValcoass2.asset_Coordinators_EName,
                                    coid: ItemValcoass2.asset_Id,
                                });
                                //GroupProcess_VIII
                                if (ItemIndexcoass2 === 0) {
                                    _s.OptimizedData[_s.OptimizedData.length - 1].asset_Name = ItemValcoass2.asset_Name;
                                    _s.OptimizedData[_s.OptimizedData.length - 1].asset_Short = ItemValcoass2.asset_Short;
                                    _s.OptimizedData[_s.OptimizedData.length - 1].asset_Level = ItemValcoass2.asset_Level;
                                    _s.OptimizedData[_s.OptimizedData.length - 1].asset_Code = ItemValcoass2.asset_Code;
                                    _s.OptimizedData[_s.OptimizedData.length - 1].quarterID = ItemValcoass2.quarterID;
                                    _s.OptimizedData[_s.OptimizedData.length - 1].quarterYear = ItemValcoass2.quarterYear;
                                    _s.OptimizedData[_s.OptimizedData.length - 1].delFlag = ItemValcoass2.delFlag;
                                }
                            });

                            //GroupProcess_VII
                            ItemValass2.financial.forEach(function (itemvalassfi2, itemindexassfi2) {
                                _s.OptimizedData[_s.OptimizedData.length - 1].ni = itemvalassfi2.ni;
                                _s.OptimizedData[_s.OptimizedData.length - 1].npV_EMV = itemvalassfi2.npV_EMV;
                                _s.OptimizedData[_s.OptimizedData.length - 1].businessID = itemvalassfi2.businessID;
                            });
                        });
                    }

                });
            }
        }

        function GroupProcess_III_chkAsset_Level(ItemVal1: any) {
            if (ItemVal1.asset_Level.length !== 0) {
                ItemVal1.asset_Level.forEach(function (ItemValass1, ItemIndexass1) {

                    //GroupProcess_V 
                    _s.OptimizedData.push({
                        Parent: ItemVal1.abbreviation,
                        Asses: ItemValass1.abbreviation,
                        CO: []
                    });

                    //GroupProcess_V 
                    ItemValass1.coInfo.forEach(function (ItemValcoass1, ItemIndexcoass1) {
                        _s.OptimizedData[_s.OptimizedData.length - 1].CO.push({
                            coname: ItemValcoass1.asset_Coordinators_EName,
                            coid: ItemValcoass1.asset_Id,
                        });

                        //GroupProcess_VI 
                        if (ItemIndexcoass1 === 0) {
                            _s.OptimizedData[_s.OptimizedData.length - 1].asset_Name = ItemValcoass1.asset_Name;
                            _s.OptimizedData[_s.OptimizedData.length - 1].asset_Short = ItemValcoass1.asset_Short;
                            _s.OptimizedData[_s.OptimizedData.length - 1].asset_Level = ItemValcoass1.asset_Level;
                            _s.OptimizedData[_s.OptimizedData.length - 1].asset_Code = ItemValcoass1.asset_Code;
                            _s.OptimizedData[_s.OptimizedData.length - 1].quarterID = ItemValcoass1.quarterID;
                            _s.OptimizedData[_s.OptimizedData.length - 1].quarterYear = ItemValcoass1.quarterYear;
                            _s.OptimizedData[_s.OptimizedData.length - 1].delFlag = ItemValcoass1.delFlag;
                        }
                    });

                    //GroupProcess_V 
                    ItemValass1.financial.forEach(function (itemvalassfi1, itemindexassfi1) {
                        _s.OptimizedData[_s.OptimizedData.length - 1].ni = itemvalassfi1.ni;
                        _s.OptimizedData[_s.OptimizedData.length - 1].npV_EMV = itemvalassfi1.npV_EMV;
                        _s.OptimizedData[_s.OptimizedData.length - 1].businessID = itemvalassfi1.businessID;
                    });
                });
            }
        }

        function GroupProcess_I_ItemValass(ItemVal: any) {
            ItemVal.data.asset_Level.forEach(function (ItemValass, ItemIndexass) {
                //GroupProcess_II_OptimizedData
                GroupProcess_II_OptimizedData(ItemVal, ItemValass);

                //GroupProcess_II_ItemValcoass
                GroupProcess_II_ItemValcoass(ItemValass);

                //GroupProcess_II_itemvalassfi     
                GroupProcess_II_itemvalassfi(ItemValass);
            });
        }

        function GroupProcess_II_OptimizedData(ItemVal: any, ItemValass: any) {
            _s.OptimizedData.push({
                Parent: ItemVal.data.abbreviation,
                Asses: ItemValass.abbreviation,
                CO: []
            });
        }

        function GroupProcess_II_ItemValcoass(ItemValass: any) {
            ItemValass.coInfo.forEach(function (ItemValcoass, ItemIndexcoass) {
                _s.OptimizedData[_s.OptimizedData.length - 1].CO.push({
                    coname: ItemValcoass.asset_Coordinators_EName,
                    coid: ItemValcoass.asset_Id,
                });
                if (ItemIndexcoass === 0) {
                    _s.OptimizedData[_s.OptimizedData.length - 1].asset_Name = ItemValcoass.asset_Name;
                    _s.OptimizedData[_s.OptimizedData.length - 1].asset_Short = ItemValcoass.asset_Short;
                    _s.OptimizedData[_s.OptimizedData.length - 1].asset_Level = ItemValcoass.asset_Level;
                    _s.OptimizedData[_s.OptimizedData.length - 1].asset_Code = ItemValcoass.asset_Code;
                    _s.OptimizedData[_s.OptimizedData.length - 1].quarterID = ItemValcoass.quarterID;
                    _s.OptimizedData[_s.OptimizedData.length - 1].quarterYear = ItemValcoass.quarterYear;
                    _s.OptimizedData[_s.OptimizedData.length - 1].delFlag = ItemValcoass.delFlag;
                }
            });
        }

        function GroupProcess_II_itemvalassfi(ItemValass: any) {
            ItemValass.financial.forEach(function (itemvalassfi, itemindexassfi) {
                _s.OptimizedData[_s.OptimizedData.length - 1].ni = itemvalassfi.ni;
                _s.OptimizedData[_s.OptimizedData.length - 1].npV_EMV = itemvalassfi.npV_EMV;
                _s.OptimizedData[_s.OptimizedData.length - 1].businessID = itemvalassfi.businessID;
            });
        }
        function GroupProcess_I_ItemValco(ItemVal: any, _s: any) {
            ItemVal.data.coInfo.forEach(function (ItemValco, ItemIndexco) {
                _s.OptimizedData[_s.OptimizedData.length - 1].CO.push({
                    coname: ItemValco.coordinator_EName,
                    coid: ItemValco.coordinatorId,
                });
                if (ItemIndexco === 0) {
                    _s.OptimizedData[_s.OptimizedData.length - 1].quarterID = ItemValco.quarterID;
                    _s.OptimizedData[_s.OptimizedData.length - 1].quarterYear = ItemValco.quarterYear;
                }
            });
        }
        function dvi_Valfi(ItemVal: any, _s: any) {
            ItemVal.data.financial.forEach(function (itemvalfi) {
                _s.OptimizedData[_s.OptimizedData.length - 1].ni = itemvalfi.ni;
                _s.OptimizedData[_s.OptimizedData.length - 1].npV_EMV = itemvalfi.npV_EMV;
                _s.OptimizedData[_s.OptimizedData.length - 1].businessID = itemvalfi.businessID;
            });
        }
        function GroupProcess_I_itemvalfi(ItemVal: any) {
            ItemVal.data.financial.forEach(function (itemvalfi, itemindexfi) {
                _s.OptimizedData[_s.OptimizedData.length - 1].ni = itemvalfi.ni;
                _s.OptimizedData[_s.OptimizedData.length - 1].npV_EMV = itemvalfi.npV_EMV;
                _s.OptimizedData[_s.OptimizedData.length - 1].businessID = itemvalfi.businessID;
            });
        }

    }



    private dvi_itemvalassfi(ItemValass: any, _s: this) {
        ItemValass.financial.forEach(function (itemvalassfi) {
            _s.OptimizedData[_s.OptimizedData.length - 1].ni = itemvalassfi.ni;
            _s.OptimizedData[_s.OptimizedData.length - 1].npV_EMV = itemvalassfi.npV_EMV;
            _s.OptimizedData[_s.OptimizedData.length - 1].businessID = itemvalassfi.businessID;
        });
    }




    func_getdataExport() {

        const _s = this;
        let formdata = {
            SessionEmpID: this.Data_SessionStorage[0].employeE_ID,
            Module: "exportexcel",
            body: {
                QuarterID: '',
                EmpID: '',
                EmpName: '',
                DeptID: '',
                Level: '',
                CoorBU: '',
                BULevel: ''
            }
        }
        _s.mserve.GetFinancialExport(formdata).then((response) => {
            if (response.data.status === true) {
                //console.log('data', response.data.body);
                this.TempdataExcel = response.data.body;
                this.excelService2.generateExcel_Financial(this.TempdataExcel);

            } else {
                //console.log('false', response)
                alert(response.data.errorMessage);
            }
        }).catch((e) => {
            if (e.response) {
                this.authserviceToken.CheckTokenExpire(e.response.status)
            } else {
                //console.log(e)
            }
        });
    }

    private _filterBUDD(value: string): string[] {

        let tempFileter;
        if (value !== null) {
            const filterValue = value?.toLowerCase();
            if (filterValue !== '') {
                tempFileter = this.BUDD_auto.filter(option1 => option1.text?.toLowerCase().includes(filterValue));
                if (tempFileter.length !== 0) {
                    return tempFileter;
                }
            } else {
                return this.BUDD_auto
            }
        }
        else {
            return this.BUDD_auto
        }
    }

    func_Getlist() {
        const _s = this;
        let tempQuarter = _s.search.Quarter;
        //_s.loading = true;
        let datavalue = {
            SessionEmpID: this.SessionEmpID,
            Module: "getlist",
            body: {
                ImpactCateID: "",
                QuarterID: tempQuarter,
                ImpactCateCode: "Test0002",
                ImpactCateName: "GetFinancialImpact1",
                DelFlag: "0",
                sReqImpactCateItem: {
                    ImpactCateItemID: "",
                    ImpactCateItemCode: "00066",
                    ImpactCateItemName: "GetFinancialImpactItem1",
                    Description: "",
                    DelFlag: "0"
                }
            }
        }

        this.mserve.GetImpactCategory(datavalue).then((response) => {
            if (response.data.status === true) {
                // //console.log()
                _s.tableimpactCriteria = response.data.body;
                //console.log('dataresponse.data', response.data.body);
                _s.tableimpactCriteria = _s.tableimpactCriteria.filter(x => x.impactCateCode == "1");
                //console.log('data', _s.tableimpactCriteria);
                //_s.loading = false;
            } else {
                //console.log('false', response)
                alert(response.data.errorMessage);
            }
        }).catch((e) => {
            if (e.response) {
                this.authserviceToken.CheckTokenExpire(e.response.status)
            } else {
                //console.log(e)
            }
        });
    }


    func_OpenEditFinancialScale(type) {
        let _s = this;
        _s.model.Year = _s.currentyear
        _s.model.Quarter = '';
        _s.model.type = type;
        this.Type_financialScale = type;
        _s.InertEditSelectedYear(_s.search.Quarter)
        _s.financialScale = [];
        if (_s.tableimpactCriteria.length > 0) {
            _s.tableimpactCriteria[0].sResImpactCateItem.forEach(function (item, index) {
                const nextItem = _s.tableimpactCriteria[0].sResImpactCateItem[index - 1];
                _s.financialScale.push({
                    ImpactCateItemID: item.impactCateItemID,
                    ImpactCateItemName: item.impactCateItemName,

                    ValEditScreenPercent1: type === "NI" ? item.decimalCalPercent1 : item.decimalcalPercentNPV1,
                    ValEditScreenPercent2 : nextItem ? (type === "NI" ? nextItem.decimalCalPercent1 : nextItem.decimalcalPercentNPV1) : null,
                    financialScaleTypeEditScreen: type,

                    DecimalCalPercent1: item.decimalCalPercent1,
                    DecimalCalPercent2: item.decimalCalPercent2,
                    DecimalcalPercentNPV1: item.decimalcalPercentNPV1,
                    DecimalcalPercentNPV2: item.decimalcalPercentNPV2

                });
            })

            _s.financialScale[_s.financialScale.length -1].ValEditScreenPercent2 = null;


            $('#modalFinancialScale').modal('show');
        }
        else {
            //console.log('Error Financial item not found.');
        }
    }

    func_closeEditFinancialScale() {
        $('#modalFinancialScale').modal('hide');
    }

    func_EditFinancialScale() {
        let _s = this;
        //console.log("financialScale is :", _s.financialScale)

        _s.financialScale.forEach(function (item, index) {
           
            if (item.ValEditScreenPercent1 != null) {
                let fCalPercent1 = parseFloat(item.ValEditScreenPercent1);
                if (Number.isNaN(fCalPercent1)) {
                    this.alertflow = 'Financial scale must be a number.'
                    $('#modalAlertdanger5').modal('show');
                    return false;
                }
            }
            if (item.ValEditScreenPercent2 != null) {
                let fCalPercent2 = parseFloat(item.ValEditScreenPercent2);
                if (Number.isNaN(fCalPercent2)) {
                    this.alertflow = 'Financial scale must be a number.'
                    $('#modalAlertdanger5').modal('show');
                    return false;
                }
            }

            const prevItem = index > 0 ? _s.financialScale[index - 1] : null;
            if(item.financialScaleTypeEditScreen ==='NI'){
                item.DecimalCalPercent1 = parseFloat(item.ValEditScreenPercent1);
                item.DecimalCalPercent2 = prevItem ? prevItem.ValEditScreenPercent1 :null;
            }
            else if(item.financialScaleTypeEditScreen ==='NPV')
            {
                item.DecimalcalPercentNPV1 = parseFloat(item.ValEditScreenPercent1);
                item.DecimalcalPercentNPV2 = prevItem ? prevItem.ValEditScreenPercent1  :null;
            }

        });

        _s.financialScale[_s.financialScale.length-1].DecimalCalPercent2 = null;
        _s.financialScale[_s.financialScale.length-1].DecimalcalPercentNPV2 = null;

        _s.financialScale[_s.financialScale.length-1].DecimalCalPercent1 = _s.financialScale[_s.financialScale.length-2].DecimalCalPercent1;
        _s.financialScale[_s.financialScale.length-1].DecimalcalPercentNPV1 = _s.financialScale[_s.financialScale.length-2].DecimalcalPercentNPV1;

        let formData = {
            SessionEmpID: this.SessionEmpID,
            Module: 'updatescale',
            body: {
                sReqImpactCateItem: _s.financialScale
            }
        }
        //console.log('formData', formData)

        _s.mserve.GetImpactCategory(formData).then((response) => {
            if (response.data.status === true) {
                //console.log('data', _s.tableimpactCriteria)
                _s.func_Getlist();
            } else {
                //console.log('false', response)
            }
        }).catch((e) => {
             if (e.response) {
                        this.authserviceToken.CheckTokenExpire(e.response.status)
                           } else {
                        //console.log(e)
                    }
        });

        $('#modalFinancialScale').modal('hide')
    }
    closeAlert() {
        $('#modalAlertdanger5').modal('hide');
    }

    numberOnly(event) {

        let text = event.target.value;

        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57) && (charCode != 46 || text.indexOf('.') != -1)) {
            return false;
        }
        return true;

    }

}