import { Component, OnInit } from "@angular/core";
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Masterserve } from '../../../services/master.service';
declare let $;

import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AuthenService } from "src/app/services/authenservice.service";


@Component({
    selector: 'app-MasterPermission',
    templateUrl: './masterpermission.html',
    styleUrls: ['./masterpermission.css'],
    providers: [Masterserve, NgbModalConfig, NgbModal]
})
export class MasterPermission implements OnInit {

    myControlEmployeeID = new UntypedFormControl();
    EmployeeDD_auto = [];
    filteredOptionsEmployeeIDDD: Observable<string[]>;

    constructor(public authserviceToken: AuthenService, config: NgbModalConfig, private modalService: NgbModal,
        public mserve: Masterserve) {
        config.backdrop = 'static';
        config.keyboard = false;
    }

    SessionEmpID;
    Data_SessionStorage;
    EmployeeDD;

    Title;
    model;
    search;

    equipment;
    equipmentarray;

    tablegroup;
    tablecoordinator;
    AddData;
    DelData;
    valuecheckSelect = false;
    valuecheckSelectAll = false;

    ngOnInit(): void {
        let _s = this;

        this.Data_SessionStorage = JSON.parse(sessionStorage.datainfo);
        //console.log('Data_SessionStorage => ', this.Data_SessionStorage);
        this.SessionEmpID = this.Data_SessionStorage[0].employeE_ID;

        _s.model = {
            GroupID: "",
            Year: "",
            QuarterID: "",
            EmpID: "",
            EmpName: "",
            DeptID: "",
            Level: "",
            Email: ""
        }

        _s.search = {
            searchBox: "",
            Resmsg: ""
        }

        _s.DelData = []

        _s.func_getlist();
        _s.loadDDL();
    }
    SelectAll(value) {
        let items;
        let chk = value.target.checked
        items = (document.getElementsByName("selectedAll"));
        for (let item_items of items) {
            if (chk  && !item_items.checked ) {
                item_items.checked = true;
            } else if (!chk  && item_items.checked) {
                item_items.checked = false;
            }
        }
        if (chk) {
            for (let item_tablecoordinator of this.tablecoordinator) {
                this.DelData.push(item_tablecoordinator)
            }
        }
    }

    onChangeCheckBox(e, item) {
        
        let chk = e.target.checked
        if (chk) {
            this.DelData.push(item);
        } else {
            for (let i = 0; i < this.DelData.length; i++) {
                if (this.DelData[i].groupItemID == item.groupItemID) {
                    this.DelData.splice(i, 1);
                }
            }
        }
    }

    func_getlist() {
        let _s = this;
        let datavalue = {
            SessionEmpID: this.SessionEmpID,
            Module: "getlist",
            body: {
                GroupID: _s.model.GroupID,
            }
        }
        _s.mserve.GetPerManagement(datavalue).then((response) => {
            if (response.data.status ){
                _s.tablegroup = response.data.body;
                let ss = _s.tablegroup.find(x => x.groupID === _s.model.GroupID)
                _s.tablecoordinator = ss.sResPerManagementItem
            } else {
                //console.log('false', response)
                alert(response.data.errorMessage);
            }
        }).catch((e) => {
            this.authserviceToken.CheckTokenExpire(e.response.status)
        });
    }

    func_SearchBox() {
        let _s = this;
        
        let datavalue = {
            SessionEmpID: this.SessionEmpID,
            Module: "SearchBox",
            body: {
                SearchBox: _s.search.searchBox,
            }
        }
        //console.log('SearchBox', datavalue)
        _s.mserve.GetPerManagement(datavalue).then((response) => {
            if (response.data.status ){
                let res = response.data.body
                if (res.length > 0) {
                    let gname = '';
                    for (let i = 0; i < res.length; i++) {
                        if (res.length > i) {
                            gname += res[i].groupName + ','
                        } else {
                            gname += res[i].groupName
                        }

                    }
                    //console.log('res', res)
                    _s.search.Resmsg = 'Name: ' + res[0].empName + ' Employee Code:' + res[0].empCode + ' Email: ' + res[0].email + ' Group: ' + gname;
                    //console.log('Resmsg', _s.search.Resmsg)
                }
                else {
                    _s.search.Resmsg = '';
                }

            } else {
                //console.log('false', response)
                alert(response.data.errorMessage);
            }
        }).catch((e) => {
            this.authserviceToken.CheckTokenExpire(e.response.status)
        });
    }

    loadDDL() {
        let _s = this;

        _s.mserve.Master_DDL({ Module: "EmployeeDD" }).then((response) => {
            _s.EmployeeDD = response.data.body
            _s.EmployeeDD_auto = response.data.body
            //console.log("show auto data : ", _s.EmployeeDD_auto)

            this.filteredOptionsEmployeeIDDD = this.myControlEmployeeID.valueChanges.pipe(
                startWith(''),
                map(value => this._filterEmployeeIDDD(value))
            );

        })
    }

    func_selectEmp(data) {
        let _s = this;
        for (let item_EmployeeDD of _s.EmployeeDD) {
            if (data === item_EmployeeDD.text) {
                data = item_EmployeeDD.value
                break;
            }
        }

        if (data != '0') {
            let found = _s.EmployeeDD.find(x => x.value === data);
            _s.model.EmpID = found.value
            _s.model.EmpName = found.text
            _s.model.DeptID = found.text1
            _s.model.Level = found.text2
            _s.model.Email = found.text3
        } else {
            _s.model.EmpID = ""
            _s.model.EmpName = ""
            _s.model.DeptID = ""
            _s.model.Level = ""
            _s.model.Email = ""
        }
    }

    Addemp() {
        let _s = this;
        $('#modaladdpermission1').modal('hide');
        let temparray = [];
        let temparrayAll = [];

        let temp = _s.model;
        temparrayAll = _s.tablecoordinator;
        temparrayAll.push({ empCode: temp.EmpID, empName: temp.EmpName, email: temp.Email });
        temparray.push({ empCode: temp.EmpID, empName: temp.EmpName, email: temp.Email });
        _s.tablecoordinator = temparrayAll;
        _s.AddData = temparray;
        _s.model.EmpID = "";
        _s.model.EmpName = "";
        _s.model.Email = "";
        _s.func_savebtn()

    }

    func_addpermission(item, data) {
        let _s = this;
        _s.Title = data.groupName
        _s.model.GroupID = data.groupID
        _s.tablecoordinator = _s.tablegroup[item].sResPerManagementItem;
        $('#modaladdpermission').modal('show')
    }

    func_addemployee() {
        $('#modaladdpermission').modal('hide')
        $('#modaladdpermission1').modal('show')
    }

    func_delemployee() {
        let _s = this;
        if (_s.DelData.length > 0) {
            let r = confirm("Confirm Delete!");
            if (r) {
                let datavalue = {
                    SessionEmpID: this.SessionEmpID,
                    Module: "delete",
                    body: {
                        GroupID: _s.model.GroupID,
                        sReqPerManagementItem: _s.DelData
                    }
                }
                //console.log('tablecoordinator:', this.tablecoordinator)
                _s.mserve.GetPerManagement(datavalue).then((response) => {
                    if (response.data.status ){
                        _s.func_getlist();
                        alert("Delete Completed.");
                    } else {
                        //console.log('false', response)
                        alert(response.data.errorMessage);
                    }
                }).catch((e) => {
                    this.authserviceToken.CheckTokenExpire(e.response.status)
                });
            }
        }
        else {
            alert("No Data Delete!");
        }

    }

    func_savebtn() {
        let _s = this;

        let datavalue = {
            SessionEmpID: this.SessionEmpID,
            Module: "insert",
            body: {
                GroupID: _s.model.GroupID,
                sReqPerManagementItem: _s.AddData
            }
        }
        //console.log('datavalue:', datavalue)
        _s.mserve.GetPerManagement(datavalue).then((response) => {
            if (response.data.status ){
                _s.func_getlist();
                alert("Completed.");
                $('#modaladdpermission').modal('show');
            } else {
                //console.log('false', response)
                alert(response.data.errorMessage);
            }
        }).catch((e) => {
            this.authserviceToken.CheckTokenExpire(e.response.status)
        });
    }
    func_empclose() {
        $('#modaladdpermission1').modal('hide');
        $('#modaladdpermission').modal('show')
    }
    func_closebtn() {
        $('#modaladdpermission').modal('hide');
    }

    private _filterEmployeeIDDD(value: string): string[] {
        let tempFileter;
        const filterValue = value?.toLowerCase();
        if (filterValue !== '') {
            tempFileter = this.EmployeeDD_auto.filter(option1 => option1.text?.toLowerCase().includes(filterValue));
            if (tempFileter.length !== 0) {
                return tempFileter;
            }
        } else {
            return this.EmployeeDD_auto
        }
    }

}