<div class="container-fluid appform my-3">
    <form>
        <fieldset class="card pb-5">
            <legend class="w-auto px-2"></legend>
            <div class="row ">
                <div class="col-7 col-md-7 col-lg-7 col-xl-7 pb-0 mt-0 mb-0">
                    <h1 class=" mt-4 mx-0 p-3">Strategic Fit</h1>
                    <div class=" col-12 mt-4 mx-0 p-3">
                        <div class="col-12 pt-2 banner rounded">
                            <em class="fas fa-bars icon"></em>&nbsp;&nbsp;Strategic Alignment
                        </div>
                        <div class="table-banner" [formGroup]="form1">
                            <table class="table-bordered" style="width:100%;">
                                <caption style="display: none;">This is a custom table</caption>
                                <thead style="text-align: center;background-color:#033153;color:white;font-size:18px;">
                                    <tr>
                                        <th style="vertical-align: middle;">Strategic Direction</th>
                                        <th style="vertical-align: middle;">Financial </th>
                                        <th style="vertical-align: middle;">Zero Tolerance</th>
                                        <th style="vertical-align: middle;">Lowest Level</th>
                                        <th style="vertical-align: middle;" *ngIf="isCloseButtonEdit == true && eventForm1=='form1add'">Save</th>
                                        <th style="vertical-align: middle;"
                                            *ngIf="isCloseButtonEdit == true && (eventForm1=='form1edit' || eventForm1=='')">Edit</th>
                                    </tr>
                                    <tr>

                                    </tr>
                                </thead>
                                <tbody style="background-color: white;font-size:16px;">
                                    <tr *ngIf="eventForm1=='form1add'">
                                        <td style="text-align: center;">
                                            <select class="form-control" formControlName="strategicDirection"
                                                (change)="PositionValueTab1()">
                                                <option *ngFor="let item of selectValues " [ngValue]="item.value"
                                                    [selected]="item.value== fb1.strategicDirection">{{item.name}}
                                                </option>
                                            </select>
                                        </td>
                                        <td style="text-align: center;">
                                            <select class="form-control" formControlName="financial"
                                                (change)="PositionValueTab1()">
                                                <option *ngFor="let item of selectValues" [ngValue]="item.value"
                                                    [selected]="item.value == fb1.financial">{{item.name}}</option>
                                            </select>
                                        </td>
                                        <td style="text-align: center;">
                                            <select class="form-control" formControlName="zeroTolerance"
                                                (change)="PositionValueTab1()">
                                                <option *ngFor="let item of selectValues" [ngValue]="item.value"
                                                    [selected]="item.value == fb1.zeroTolerance">{{item.name}}</option>
                                            </select>
                                        </td>
                                        <td style="text-align: center;">
                                            <input type="text" class="form-control"  formControlName="lowestScore" disabled />
                                        </td>
                                        <td style="text-align: center;"  *ngIf="isCloseButtonEdit == true">
                                            <button type="button" class="btn" (click)="PositionValueTab1('Strategic Fit')"
                                                (click)="eventForm1 ='form1edit' ">
                                                <em class="far fa-save icon-in-table text-success"
                                                    style="color:orange;font-size:16px;"> </em>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr *ngIf="eventForm1=='form1edit' || eventForm1==''">
                                        <td style="text-align: center;" title="{{fb1.strategicDirectionRationale}}"
                                            *ngFor="let item of filterByid(selectValues,fb1.strategicDirection)">
                                            {{item.name}}</td>
                                        <td style="text-align: center;" title="{{fb1.financialRationale}}"
                                            *ngFor="let item of filterByid(selectValues,fb1.financial)">{{item.name}}
                                        </td>
                                        <td style="text-align: center;" title="{{fb1.zeroToleranceRationale}}"
                                            *ngFor="let item of filterByid(selectValues,fb1.zeroTolerance)">
                                            {{item.name}}</td>
                                        <td style="text-align: center;">{{fb1.lowestScore}}</td>
                                        <td style="text-align: center;" *ngIf="isCloseButtonEdit == true">
                                            <div>
                                                <button type="button" class="btn" (click)=" eventForm1='form1add'">
                                                    <em class="fas fa-edit" style="color:orange;font-size:16px;"></em>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                    <div class=" col-12 mt-4 mx-0 p-3">
                        <div class="col-12 pt-2 banner rounded">
                            <em class="fas fa-bars icon"></em>&nbsp;&nbsp;Strategic Contribution
                        </div>

                        <div class="table-banner" [formGroup]="form2">
                            <table class="table-bordered" style="width:100%;">
                                <caption style="display: none;">This is a custom table</caption>
                                <thead style="text-align: center;background-color:#033153;color:white;font-size:18px;">
                                    <tr>
                                        <th style="vertical-align: middle;">Value Size / Profit Margin</th>
                                        <th style="vertical-align: middle;">Timing of Cash Inflow</th>
                                        <th style="vertical-align: middle;">Growth Opportunity / Sustainability Target
                                        </th>
                                        <th style="vertical-align: middle;">Lowest Level</th>
                                        <th style="vertical-align: middle;" *ngIf="isCloseButtonEdit == true && eventForm2=='form2add'">Save</th>
                                        <th style="vertical-align: middle;"
                                            *ngIf="isCloseButtonEdit == true && (eventForm2=='form2edit' || eventForm2=='')">Edit</th>
                                    </tr>
                                </thead>
                                <tbody style="background-color: white;font-size:16px;">
                                    <tr *ngIf="eventForm2=='form2add'">
                                        <td style="text-align: center;">
                                            <select class="form-control" formControlName="valueProfit"
                                                (change)="PositionValueTab1()">
                                                <option *ngFor="let item of selectValues" [ngValue]="item.value"
                                                    [selected]="item.value == fb2.valueProfit">{{item.name}}</option>
                                            </select>
                                        </td>
                                        <td style="text-align: center;">
                                            <select class="form-control" formControlName="timingCashInfow"
                                                (change)="PositionValueTab1()">
                                                <option *ngFor="let item of selectValues" [ngValue]="item.value"
                                                    [selected]="item.value == fb2.timingCashInfow">{{item.name}}
                                                </option>
                                            </select>
                                        </td>
                                        <td style="text-align: center;">
                                            <select class="form-control" formControlName="growthSustainability"
                                                (change)="PositionValueTab1()">
                                                <option *ngFor="let item of selectValues" [ngValue]="item.value"
                                                    [selected]="item.value == fb2.growthSustainability">{{item.name}}
                                                </option>
                                            </select>
                                        </td>
                                        <td style="text-align: center;">
                                            <input type="text" class="form-control"  formControlName="lowestScore" disabled />
                                        </td>
                                        <td style="text-align: center;" *ngIf="isCloseButtonEdit == true">
                                            <div>
                                                <button type="button" class="btn" (click)="PositionValueTab1('Strategic Fit')"
                                                    (click)=" eventForm2 ='form2edit'">
                                                    <em class="far fa-save icon-in-table text-success"
                                                        style="color:orange;font-size:16px;"> </em>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="eventForm2=='form2edit' || eventForm2==''">
                                        <td style="text-align: center;" title="{{fb2.valueProfitRationale}}"
                                            *ngFor="let item of filterByid(selectValues,fb2.valueProfit)">{{item.name}}
                                        </td>
                                        <td style="text-align: center;" title="{{fb2.timingCashInfowRationale}}"
                                            *ngFor="let item of filterByid(selectValues,fb2.timingCashInfow)">
                                            {{item.name}}</td>
                                        <td style="text-align: center;" title="{{fb2.growthSustainabilityRationale}}"
                                            *ngFor="let item of filterByid(selectValues,fb2.growthSustainability)">
                                            {{item.name}}</td>
                                        <td style="text-align: center;">{{fb2.lowestScore}}</td>
                                        <td style="text-align: center;" *ngIf="isCloseButtonEdit == true">
                                            <div>
                                                <button type="button" class="btn" (click)=" eventForm2 ='form2add'">
                                                    <em class="fas fa-edit" style="color:orange;font-size:16px;"></em>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
                <div class="col-5 col-md-5 col-lg-5 col-xl-5 pb-0 mt-0 mb-0 pr-5" style="height: fit-content;">
                    <app-strategic-table [datas]="positionSetValue1" [mode]="'single'" [tabSelect]="'1'">
                    </app-strategic-table>
                </div>
            </div>
        </fieldset>
    </form>
    <form>
        <fieldset class="card pb-5">
            <legend class="w-auto px-2"></legend>
            <div class="row">
                <div class="col-7 col-md-7 col-lg-7 col-xl-7 pb-0 mt-0 mb-0">
                    <h1 class=" mt-4 mx-0 p-3">Execution Perspective</h1>
                    <div class="col-12 mt-4 mx-0 p-3">
                        <div class="col-12 pt-2 banner rounded">
                            <em class="fas fa-bars icon"></em>&nbsp;&nbsp;Manageable Challenge
                        </div>

                        <div class="table-banner" [formGroup]="form3">
                            <table class="table-bordered" style="width:100%;">
                                <caption style="display: none;">This is a custom table</caption>
                                <thead style="text-align: center;background-color:#033153;color:white;font-size:18px;">
                                    <tr>
                                        <th style="vertical-align: middle;">Capability Development</th>
                                        <th style="vertical-align: middle;">Resources Allocation</th>
                                        <th style="vertical-align: middle;">Lowest Level</th>
                                        <th style="vertical-align: middle;" *ngIf="isCloseButtonEdit == true && eventForm3=='form3add'">Save</th>
                                        <th style="vertical-align: middle;"
                                            *ngIf="isCloseButtonEdit == true && (eventForm3=='form3edit' || eventForm3=='')">Edit</th>
                                    </tr>
                                    <tr>

                                    </tr>
                                </thead>
                                <tbody style="background-color: white;font-size:16px;">
                                    <tr *ngIf="eventForm3=='form3add'">
                                        <td style="text-align: center;">
                                            <select class="form-control" formControlName="capability"
                                                (change)="PositionValueTab2()">
                                                <option *ngFor="let item of selectValues" [ngValue]="item.value"
                                                    [selected]="item.value== fb3.capability">{{item.name}}</option>
                                            </select>
                                        </td>
                                        <td style="text-align: center;">
                                            <select class="form-control" formControlName="resources"
                                                (change)="PositionValueTab2()">
                                                <option *ngFor="let item of selectValues" [ngValue]="item.value"
                                                    [selected]="item.value == fb3.resources">{{item.name}}</option>
                                            </select>
                                        </td>
                                        <td style="text-align: center;">
                                            <input type="text"  class="form-control" formControlName="lowestScore" disabled />
                                        </td>
                                        <td style="text-align: center;" *ngIf="isCloseButtonEdit == true">
                                            <div>
                                                <button type="button" class="btn" (click)="PositionValueTab2('Execution Perspective')"
                                                    (click)=" eventForm3='form3edit'">
                                                    <em class="far fa-save icon-in-table text-success"
                                                        style="color:orange;font-size:16px;"></em>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="eventForm3=='form3edit' || eventForm3==''">
                                        <td style="text-align: center;" title="{{fb3.capabilityRationale}}"
                                            *ngFor="let item of filterByid(selectValues,fb3.capability)">{{item.name}}
                                        </td>
                                        <td style="text-align: center;" title="{{fb3.resourcesRationale}}"
                                            *ngFor="let item of filterByid(selectValues,fb3.resources)">{{item.name}}
                                        </td>
                                        <td style="text-align: center;">{{fb3.lowestScore}}</td>
                                        <td style="text-align: center;" *ngIf="isCloseButtonEdit == true">
                                            <div>
                                                <button type="button" class="btn" (click)=" eventForm3 ='form3add'">
                                                    <em class="fas fa-edit" style="color:orange;font-size:16px;"></em>
                                                </button>
                                            </div>

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                    <div class=" col-12 mt-4 mx-0 p-3">
                        <div class="col-12 pt-2 banner rounded">
                            <em class="fas fa-bars icon"></em>&nbsp;&nbsp;Manageable Portfolio Risk
                        </div>

                        <div class="table-banner" [formGroup]="form4">
                            <table class="table-bordered" style="width:100%;">
                                <caption style="display: none;">This is a custom table</caption>
                                <thead style="text-align: center;background-color:#033153;color:white;font-size:18px;">
                                    <tr>
                                        <th style="vertical-align: middle;">Joint Investment </th>
                                        <th style="vertical-align: middle;">Quick Exit to Limit Loss</th>
                                        <th style="vertical-align: middle;">Lowest Level</th>
                                        <th style="vertical-align: middle;" *ngIf="isCloseButtonEdit == true && eventForm4=='form4add'">Save</th>
                                        <th style="vertical-align: middle;"
                                            *ngIf="isCloseButtonEdit == true && (eventForm4=='form4edit' || eventForm4=='')">Edit</th>
                                    </tr>
                                    <tr>

                                    </tr>
                                </thead>
                                <tbody style="background-color: white;font-size:16px;">
                                    <tr *ngIf="eventForm4=='form4add'">
                                        <td style="text-align: center;">
                                            <select class="form-control" formControlName="jointInvestment"
                                                (change)="PositionValueTab2()">
                                                <option *ngFor="let item of selectValues" [ngValue]="item.value"
                                                    [selected]="item.value == fb4.jointInvestment">{{item.name}}
                                                </option>
                                            </select>
                                        </td>
                                        <td style="text-align: center;">
                                            <select class="form-control" formControlName="quickExit"
                                                (change)="PositionValueTab2()">
                                                <option *ngFor="let item of selectValues" [ngValue]="item.value"
                                                    [selected]="item.value == fb4.quickExit">{{item.name}}</option>
                                            </select>
                                        </td>
                                        <td style="text-align: center;">
                                            <input type="text" class="form-control"  formControlName="lowestScore" disabled />
                                        </td>
                                        <td style="text-align: center;" *ngIf="isCloseButtonEdit == true">
                                            <div>
                                                <button type="button" class="btn" (click)="PositionValueTab2('Execution Perspective')"
                                                    (click)="eventForm4 ='form4edit'">
                                                    <em class="far fa-save icon-in-table text-success"
                                                        style="color:orange;font-size:16px;"> </em>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="eventForm4=='form4edit' || eventForm4==''">
                                        <td style="text-align: center;" title="{{fb4.jointInvestmentRationale}}"
                                            *ngFor="let item of filterByid(selectValues,fb4.jointInvestment)">
                                            {{item.name}}</td>
                                        <td style="text-align: center;" title="{{fb4.quickExitRationale}}"
                                            *ngFor="let item of filterByid(selectValues,fb4.quickExit)">{{item.name}}
                                        </td>
                                        <td style="text-align: center;">{{fb4.lowestScore}}</td>
                                        <td style="text-align: center;" *ngIf="isCloseButtonEdit == true">
                                            <div>
                                                <button type="button" class="btn" (click)=" eventForm4 ='form4add'">
                                                    <em class="fas fa-edit" style="color:orange;font-size:16px;"></em>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
                <div class="col-5 col-md-5 col-lg-5 col-xl-5 pb-0 mt-0 mb-0 pr-5" style="height: fit-content;">
                    <app-strategic-table [datas]="positionSetValue2" [mode]="'single'" [tabSelect]="'2'">
                    </app-strategic-table>
                </div>

            </div>
        </fieldset>
    </form>