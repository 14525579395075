<div class="container-fluid appform my-3">
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size:14px;">
            <div class="col-12 col-md-12 col-lg-12 col-xl-12 pb-5 mt-5 mb-5">
                <div class="px-0 pb-2">
                    <div class="card col-12 mt-4 mx-0 p-3">
                        <div class="likelihoodcriteria col-12 mb-3 rounded">
                            <div class="col-0 pt-2">
                                <em class="fas fa-bars icon"></em>&nbsp;&nbsp;Likelihood Criteria
                            </div>
                        </div>
                        <div class="table-likelihood">
                            <table class="table-bordered" style="width:100%;">
                                <caption style="display: none;" >This is a custom table</caption>
                                <thead style="text-align: center;background-color:#033153;color:white;font-size:18px;">
                                    <tr *ngFor='let data of tablelikeLihoodCriteria'>
                                        <th style="width: 150px;" rowspan="1">Rating</th>
                                        <th style="width: 150px;" *ngFor='let item of data.sResLikelihoodItem'>
                                            {{item.likelihoodItemName}}({{item.likelihoodItemCode}})
                                        </th>
                                    </tr>
                                </thead>
                                <tbody style="background-color: white;font-size:16px;">
                                    <tr *ngFor='let data of tablelikeLihoodCriteria'>
                                        <td>{{data.likelihoodName}}</td>
                                        <td *ngFor='let item of data.sResLikelihoodItem'>{{item.description}}</td>
                                    </tr>
                                    <tr *ngFor='let data of tablelikeLihoodCriteria'>
                                        <td style="text-align: center;">Edit</td>
                                        <td style="text-align: center;" *ngFor='let item of data.sResLikelihoodItem'>
                                            <button type="button" class="btn btn-sm" (click)="func_addlikelihood(item,'Edit')" *ngIf="Year_Getlist == currentYear">
                                                <em class="fas fa-edit" style="color:orange;font-size:16px;"></em>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="modal fade bd-Likelihood" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" id="modaladdlikelihood">
                    <div class="modal-dialog modal-xl">
                        <div class="modal-content">
                            <div class="container-fluid appform my-3">
                                <div class="row">
                                    <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">
                                        <div class="container col-12 col-md-12 col-lg-12 col-xl-12 pb-0 mt-0 mb-0 ">
                                            <div class="container">
                                                <div class="container px-0 pb-2">
                                                    <div class="card col-12 mt-4 mx-0 px-3">
                                                        <div class="likelihood col-12 mb-3 rounded">
                                                            <div class="col-0 pt-2">
                                                                <em class="fas fa-bars icon"></em>&nbsp;&nbsp;Likelihood
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-xl-12 mb-4">
                                                            <div class="row col-12 col-xl-12 mt-3 mb-3 mx-0 px-0 d-flex justify-content-around">
                                                                <div class="row col-12 col-xl-3">
                                                                    <div class="col-12 mb-2 px-0">
                                                                        <strong class="formtext">Likelihood:</strong>
                                                                    </div>
                                                                    <div class="col-12 px-0">
                                                                        <input type="text" class="form-control" name="" [(ngModel)]="LikelihoodItemName" disabled>
                                                                    </div>
                                                                </div>
                                                                <div class="row col-12 col-xl-6">
                                                                    <div class="col-12 mb-2 px-0">
                                                                        <strong class="formtext">Description:</strong>
                                                                    </div>
                                                                    <div class="col-12 px-0">
                                                                        <textarea class="form-control"
                                                                            name="" [(ngModel)]="Description"></textarea>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 my-3 mx-0 text-center">
                                                            <button type="button" class="btn mr-3" style="width: 100px;background-color:#9eb0ba;color:white;" (click)="func_closebtn()">Close</button>
                                                            <button type="button" class="btn"style="width: 100px;background-color:#033153;color:white;" (click)="func_savebtn()">Save</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-loading [loading]='loading'></app-loading>