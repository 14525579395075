import { Component, OnInit } from "@angular/core";
import { Masterserve } from '../../../services/master.service';
import { AuthenService } from "src/app/services/authenservice.service";
declare let $;


@Component({
    selector: 'app-CorporateTarget',
    templateUrl: './CorporateTarget.html',
    styleUrls: ['./CorporateTarget.css'],
})
export class CorporateTarget implements OnInit {
    temptableCorporate;
    tempItemtableCorporate : any = [];
    DataCorporate;
    statusheader;
    statusitem;
    tempitemid;
    yearDD_insert : any = [];
    loading = true;
    YearDD;
    QuarterDD;
    currentYear;

    SessionEmpID;
    Data_SessionStorage;

    Search = {
        Year :null,
        Quarter :''
    }

    constructor(public authserviceToken: AuthenService,public mserve: Masterserve) {}
    ngOnInit(): void {
        let date = new Date();
        this.currentYear = date.getFullYear();
        this.Search.Year = date.getFullYear();

        $('.modal').on("hidden.bs.modal", function (e) { 
            if ($('.modal:visible').length) { 
                $('body').addClass('modal-open');
            }
        });

        this.Data_SessionStorage = JSON.parse(sessionStorage.datainfo);
        //console.log('Data_SessionStorage => ',this.Data_SessionStorage);
        this.SessionEmpID = this.Data_SessionStorage[0].employeE_ID;

        this.DataCorporate = {
            CorporHeaderID :'',
            CorporHeaderName :'',
            CorporItemName :true,
            CorporYear :'',
            StatusHead :false,
            StatusItem :false
        }

        this.loadDDL();
    }

    loadDDL () {
        const _s = this;
        _s.mserve.Master_DDL({ Module:"YearDD" }).then((response) => {
            _s.YearDD = response.data.body
            _s.yearDD_insert = response.data.body
            _s.yearDD_insert.push({ value:(this.currentYear + 1).toString() , text:(this.currentYear + 1).toString() })
            //console.log("YearDD : ",_s.YearDD);
            _s.func_GetQuarter();
        });
    }

    func_GetQuarter () {
        
        const _s = this;
        let d = new Date();
        let currentMonth = d.getMonth() + 1;
        _s.mserve.Master_DDL({ Module:"QuarterDD",TextSearch1: this.Search.Year }).then((response) => {
            _s.QuarterDD = response.data.body
            //console.log("QuarterDD : ",_s.QuarterDD)
            this.func_calQuarter(currentMonth);
        })
    }

    func_calQuarter (month) {
        
        this.Search.Quarter = ''
        if (month >= 1 && month <= 3) {
            this.Search.Quarter = this.QuarterDD[0].value;
        } else if (month >= 4 && month <= 6) {
            this.Search.Quarter = this.QuarterDD[1].value;
        } else if (month >= 7 && month <= 9) {
            this.Search.Quarter = this.QuarterDD[2].value;
        } else if (month >= 10 && month <= 12) {
            this.Search.Quarter = this.QuarterDD[3].value;
        }
        this.func_GetData();
    }

    func_GetData () {
        
        let formData = {
            SessionEmpID :this.SessionEmpID,
            Module :'getlist',
            body:{
                CorpTargetYear :this.Search.Year
            }
        }
        this.mserve.Get_Corporate(formData).then((response) => {
            //console.log("Corporate Data :",response.data.body)
            if (response.data.status ){
                 this.temptableCorporate = response.data.body;
                 this.loading = false;
            } else {
                //console.log('false',response);
                alert(response.data.errorMessage);
            }
          }).catch((e) => {
            this.authserviceToken.CheckTokenExpire(e.response.status)
            });
    }
    
    func_save (value) {
        
        if (this.DataCorporate.CorporHeaderName === '') {
            alert('กรุณากรอก Header Target Name')
        }
        else if (this.DataCorporate.CorporYear === '') {
            alert('กรุณาเลือก Year')
        }
        else if (this.tempItemtableCorporate.length === 0) {
            alert('กรุณาเพิ่ม Corporate Item')
        } 
        else {
            this.loading = true;
            let tempdel = (this.DataCorporate.StatusHead === true) ? 0 : 1;

            let tempModule = value;
            let formData = {
                SessionEmpID:this.SessionEmpID,
                Module:tempModule,
                body:{
                CorpTargetID:this.DataCorporate.CorporHeaderID,
                CorpTargetCode:'',
                CorpTargetName:this.DataCorporate.CorporHeaderName,
                CorpTargetYear:this.DataCorporate.CorporYear,
                DelFlag:tempdel,
                sReqCorpTargetItem:this.tempItemtableCorporate
                }
            }
            this.mserve.Get_Corporate(formData).then((response) => {
                if (response.data.status ){
                    this.func_GetData()
                    this.func_closebtn()
                } else {
                    //console.log('false',response)
                }
            }).catch((e) => {
                this.authserviceToken.CheckTokenExpire(e.response.status)
                });
        }
    }

    func_addCorporate () {
        this.statusheader = 'insert';
        this.DataCorporate.CorporHeaderName = '';
        this.DataCorporate.CorporYear = this.currentYear + 1;
        this.DataCorporate.CorporHeaderID = ''
        this.DataCorporate.StatusHead = true
        this.tempItemtableCorporate = [];

        $('#modaladdHeaderCorporate').modal('show');
    }    
    func_closebtn () { 
        $('#modaladdHeaderCorporate').modal('hide');
    }
    func_additem (value) {
        
        if (this.DataCorporate.CorporItemName === '') {
            alert('กรุณากรอกข้อมูล')
        } else {
            let delFlag: number;
            if (value === 'insert') {
              delFlag = this.DataCorporate.StatusItem ? 0 : 1;
              this.tempItemtableCorporate.push({ 
                CorpTargetItemID: '',
                CorpTargetItemCode: '',
                CorpTargetItemName: this.DataCorporate.CorporItemName,
                DelFlag: delFlag
              });
              this.DataCorporate.CorporItemName = '';
            } else if (value === 'update') {
              delFlag = this.DataCorporate.StatusItem ? 0 : 1;
              this.updateItemCor(delFlag);
            } else if (value === 'editinsert') {
              const delItemFlag = this.DataCorporate.StatusItem ? 0 : 1;
              const tempData = [];
              this.pushAllData(delItemFlag, tempData);
            }
            
              
              this.func_closebtnItem();
              
          
        }
    }
    private pushAllData(tempdelitem: any, tempData: any[]) {
        this.tempItemtableCorporate.push({
            CorpTargetItemID: '',
            CorpTargetItemCode: '',
            CorpTargetItemName: this.DataCorporate.CorporItemName,
            DelFlag: tempdelitem
        });

        tempData.push({
            CorpTargetItemID: '',
            CorpTargetItemCode: '',
            CorpTargetItemName: this.DataCorporate.CorporItemName,
            DelFlag: tempdelitem
        });
    }

    private updateItemCor(tempdelitem: any) {
        for (let item_tempItemtableCorporate of this.tempItemtableCorporate) {
            if (item_tempItemtableCorporate.CorpTargetItemID === this.tempitemid) {
                item_tempItemtableCorporate.CorpTargetItemName = this.DataCorporate.CorporItemName;
                item_tempItemtableCorporate.DelFlag = tempdelitem;
            }
        }
    }

    func_addItemCorporate (value) {
        if (value === 'insert') {
            this.statusitem = 'add'
        } else {
            this.statusitem = 'addedit'
        }

        this.DataCorporate.CorporItemName = '';
        $('#modaladdHeaderCorporate').modal('hide');
        $('#modaladdItemCorporate').modal('show');
    }
    func_closebtnItem () {
        $('#modaladdHeaderCorporate').modal('show');
        $('#modaladdItemCorporate').modal('hide');
    }

    func_edit (id) {
        this.statusheader = 'edit';
        this.tempItemtableCorporate = [];
        for (let item_temptableCorporate of this.temptableCorporate) {
            if (item_temptableCorporate.corpTargetID === id) {
                this.DataCorporate.CorporHeaderName = item_temptableCorporate.corpTargetName;
                this.DataCorporate.CorporYear = item_temptableCorporate.corpTargetYear;
                this.DataCorporate.CorporHeaderID = item_temptableCorporate.corpTargetID;
                this.DataCorporate.StatusHead = (item_temptableCorporate.delFlag === '0') ? true : false;

                for (let item_temptableCorporate_I2 of item_temptableCorporate.sResCorpTargetItem){
                    this.tempItemtableCorporate.push({
                        CorpTargetItemID:item_temptableCorporate_I2.corpTargetItemID,
                        CorpTargetItemCode:'',
                        CorpTargetItemName:item_temptableCorporate_I2.corpTargetItemName,
                        DelFlag:item_temptableCorporate_I2.delFlag
                    })
                }
            }
        }
        $('#modaladdHeaderCorporate').modal('show');
    }
    func_edititem (itemid) {
        
        this.statusitem = 'edit';
        for (let item_tempItemtableCorporate of this.tempItemtableCorporate) {
            if (item_tempItemtableCorporate.CorpTargetItemID === itemid) {
                this.tempitemid = itemid
                this.DataCorporate.CorporItemName = item_tempItemtableCorporate.CorpTargetItemName;
                this.DataCorporate.StatusItem = (item_tempItemtableCorporate.DelFlag == '0') ? true : false;
            }
        }
        $('#modaladdHeaderCorporate').modal('hide');
        $('#modaladdItemCorporate').modal('show');
    }
    func_deleteitem (itemid) {
        for (let i = 0; i < this.tempItemtableCorporate.length; i++) {
            if (this.tempItemtableCorporate[i].CorpTargetItemID ===  itemid) {
                this.tempItemtableCorporate.splice(i , 1);
            }
        }
    }

}