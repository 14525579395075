<div class="card col-12 mx-0 p-3">
    <h3 class=" mt-4 mx-0 p-3">Action Log</h3>

    <div class="table-banner">
        <table class="table-bordered" style="width:100%;">
            <caption style="display: none;">This is a custom table</caption>
            <thead style="text-align: center;background-color:#033153;color:white;font-size:18px;">
                <tr>
                    <th class = "col-2" style="vertical-align: middle;">Date and Time</th>
                    <th [ngClass]="{'col-2': _isShowStrategyName == true, 'col-3': _isShowStrategyName == false}" style="vertical-align: middle;">Name</th>
                    <th class = "col-2" style="vertical-align: middle;">Bussiness Unit</th>
                    <th [ngClass]="{'col-2': _isShowStrategyName == true}" style="vertical-align: middle;" *ngIf="_isShowStrategyName == true" >Strategy</th>
                    <th [ngClass]="{'col-2': _isShowStrategyName == true, 'col-3': _isShowStrategyName == false}" style="vertical-align: middle;">Section</th>
                    <th class = "col-2" style="vertical-align: middle;">Action</th>
                </tr>
                <tr>

                </tr>
            </thead>
            <tbody style="text-align: center;background-color: white;font-size:16px;">
                <tr *ngFor="let item of actionLogDataList | slice: (usePagination ? (page-1) * pageSize : 0) : (usePagination ? (page-1) * pageSize + pageSize : undefined) index as index">
                <td>
                    {{item.createDate}}
                </td>

                <td>
                    {{item.eFirstName}}&nbsp;{{item.eLastName}}
                </td>

                <td>
                    {{item.abbreviation}}
                </td>

                <td *ngIf="_isShowStrategyName == true">
                    {{item.riskName}}
                </td>

                <td>
                    {{item.section}}
                </td>

                <td>
                    {{item.action}}
                </td>
                </tr>
            </tbody>
        </table>
        <div class="pagination pt-5">
            <ngb-pagination [collectionSize]="actionLogDataList.length" [(page)]="page"
              [pageSize]="pageSize" [maxSize]="10" [rotate]="true" [ellipses]="false"
              [boundaryLinks]="true">
              <ng-template ngbPaginationPrevious>Prev</ng-template>
              <ng-template ngbPaginationNext>Next</ng-template>
            </ngb-pagination>
        </div>
    </div>
    <!-- <div class="col-12 pt-2 ">
        <div *ngIf="isCloseButtonEdit == true">
            <button type="button" class="btn btn-lg btn-banner-primary mb-3"
                style="width:200px;color:white;background:orange;" (click)="addRiskAssumptionFormGroup()">
                <em class="fas fa-plus icon"></em>&nbsp;&nbsp;&nbsp;Add Next Assumption
            </button>
        </div>
    </div> -->
</div>