<div >
    <div class="row"   >
        <div class="col-12 col-md-12 col-lg-12 col-xl-12 ">
           <div class="col-12 pb-3">
            <div class="col-12 my-3 mx-2 text-center">
                <button *ngIf="isCloseButtonEdit == true" type="button" (click)="Fun_Confirm()"  class="btn mr-3" style="width:350px;background-color:green;color:white;">Revise this Strategy</button>
                <button type="button" (click)="Fun_Back()"  class="btn mr-3" style="width:350px;background-color:#16191b;color:white;" >Back to Monitor & Review Page</button>
            </div>
            </div>
        </div>
     </div>
</div>
<app-modal-alert [title]="title"  [message]="message" [isRefresh]="isRefresh" (confirmed)="Confirm()"></app-modal-alert>
