import { OnInit, Component } from "@angular/core";
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Masterserve } from '../../services/master.service';
import { AuthenService } from "src/app/services/authenservice.service";
declare let $;

@Component({
    selector: 'app-RiskCriteria',
    templateUrl: './page.RiskCriteria.html',
    styleUrls: ['./page.RiskCriteria.css'],
    providers: [Masterserve, NgbModalConfig, NgbModal]
})
export class RiskCriteria implements OnInit {

    constructor(public authserviceToken: AuthenService,config: NgbModalConfig, private modalService: NgbModal,public mserve: Masterserve) {
         
    }

    tableimpactCriteria;
    Alltable;
    SessionEmpID;
    Module;

    LikelihoodID;
    QuarterID;
    LikelihoodCode;
    LikelihoodName;

    LikelihoodItemID;
    LikelihoodItemCode;
    LikelihoodItemName;
    Description;

    DelFlag;
    page = '1'
    tablelikeLihoodCriteria;
    
    YearDD
    QuarterDD;
    Quarter_Getlist;
    Year_Getlist = null;
    currentYear;
    loading = false;


    ngOnInit(): void {
        if (sessionStorage.pagecritiria !== undefined) {
            this.ClickTab(sessionStorage.pagecritiria)
        }

        let date = new Date();
        this.currentYear = date.getFullYear();
        this.Year_Getlist = date.getFullYear();


        this.loadDDL();
    }

    loadDDL () {
        const _s = this;
        _s.mserve.Master_DDL({ Module:"YearDD" }).then((response) => {
            _s.YearDD = response.data.body
            //console.log("YearDD : ",_s.YearDD);
            _s.func_GetQuarter();
        });
    }

    func_GetQuarter () {
        
        const _s = this;
        let d = new Date();
        let currentMonth = d.getMonth() + 1;
        _s.mserve.Master_DDL({ Module:"QuarterDD",TextSearch1: this.Year_Getlist }).then((response) => {
            _s.QuarterDD = response.data.body
            //console.log("QuarterDD : ",_s.QuarterDD)
            this.func_calQuarter(currentMonth);
        })
    }

    func_calQuarter (month) {

        let quater   = '';
        
        this.Quarter_Getlist = ''
        if (month >= 1 && month <= 3) {
            quater   = 'Q1';
        } else if (month >= 4 && month <= 6) {
            quater   = 'Q2';
        } else if (month >= 7 && month <= 9) {
            quater   = 'Q3';
        } else if (month >= 10 && month <= 12) {
            quater   = 'Q4';
        }
        let data  = this.QuarterDD.filter(x=>x.text === quater)[0] ?  this.QuarterDD.filter(x=>x.text === quater)[0]:''
        if(data!=''){
            this.Quarter_Getlist = data.value;
        }
        this.func_GetlistCriteria();
        this.func_GetlistLikelihood();
    }

    func_GetlistCriteria () {
        
        let _s = this;
        let tempQuarter = (this.Year_Getlist == this.currentYear) ? this.Quarter_Getlist : this.QuarterDD[3].value;

        this.loading = true;
        let datavalue = {
            SessionEmpID:1,
            Module:"getlist",
            body:{
                ImpactCateID:"",
                QuarterID:tempQuarter,
                ImpactCateCode:"Test0002",
                ImpactCateName:"GetFinancialImpact1",
                DelFlag:"0",
                sReqImpactCateItem:{
                    ImpactCateItemID:"",
                    ImpactCateItemCode:"00066",
                    ImpactCateItemName:"GetFinancialImpactItem1",
                    Description:"",
                    DelFlag:"0"
                }
            }
        }

        this.mserve.GetImpactCategory(datavalue).then((response) => {
            if(response.data.status ){
                _s.tableimpactCriteria = response.data.body;
                //console.log('data_Criteria : ',_s.tableimpactCriteria)
                
                if(_s.tableimpactCriteria && _s.tableimpactCriteria.length > 0)
                {
                    _s.tableimpactCriteria = _s.tableimpactCriteria.filter(x => x.delFlag == 0);
                }
             
                let Alltable = []; // An array to store the resulting objects

                for (let i = 0; i < 5; i++) {
                  let tmptable = {}; // A temporary object to store data for each iteration
                
                  this.func_GetlistCriteriaII(_s, tmptable, i);
                
                  Alltable.push(tmptable); // Add the temporary object to the result array
                }
                
                this.Alltable = Alltable
                this.loading = false;
            }else{
                //console.log('false',response)
            }
        }).catch((e) => {
            this.authserviceToken.CheckTokenExpire(e.response.status)
            });
    }

    private func_GetlistCriteriaII(_s: this, tmptable: {}, i: number) {
        for (let y = 0; y < _s.tableimpactCriteria.length; y++) {
            if (y === 0) {
                tmptable["rat" + y] = _s.tableimpactCriteria[y].sResImpactCateItem[i].impactCateItemName;
            }
            tmptable["desc" + y] = _s.tableimpactCriteria[y].sResImpactCateItem[i].description;
        }
    }

    func_GetlistLikelihood () {
        const _s = this;
        let tempQuarter = (this.Year_Getlist == this.currentYear) ? this.Quarter_Getlist : this.QuarterDD[3].value;

        this.loading = true;
        let datavalue = {
            SessionEmpID:"1",
            Module:"getlist",
            body:{
                LikelihoodID:"",
                QuarterID :tempQuarter,
                LikelihoodCode:"Test0002",
                LikelihoodName:"GetFinancialImpact1",
                DelFlag:"0",
                sReqLikelihoodItem:{
                    LikelihoodItemID:"",
                    LikelihoodItemCode:"",
                    LikelihoodItemName:"",
                    Description:"",
                    DelFlag:""
                }
            }
        }

        _s.mserve.GetLikelihood(datavalue).then((response) => {
            if(response.data.status === true){
                _s.tablelikeLihoodCriteria = response.data.body;
                //console.log('data',_s.tablelikeLihoodCriteria);
                this.loading = false;
            }else{
                //console.log('false',response)
                alert(response.data.errorMessage);
            }
        }).catch((e) => {
            this.authserviceToken.CheckTokenExpire(e.response.status)
            });
    }

    fus_aaa(data,index){
        return data['desc'+ index]
    }

    ClickTab (chkpage) {
        
        if (chkpage === '1') {
            let element = document.getElementById("Tab1");
            element.classList.add("active");
            let element2 = document.getElementById("Tab2");
            element2.classList.remove("active");
            this.page = '1'
        }
        else {
            let element = document.getElementById("Tab1");
            element.classList.remove("active");
            let element2 = document.getElementById("Tab2");
            element2.classList.add("active");
            this.page = '2'
        }
    }

    ngAfterViewInit(): void{
        const _s = this;
        
    }
}