import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { AppSettingService } from './app/app-setting-service';

let production :any;

platformBrowserDynamic().bootstrapModule(AppModule)
  .then(appModuleRef => {
    const appSettingService = appModuleRef.injector.get(AppSettingService); 

    appSettingService.load().then(config => {
      let configuration = appSettingService.read(); 
      production   = configuration.production
      if (production) {
        enableProdMode();
      }
    }).catch(error => {
      console.error('Error loading configuration:', error);
    });
  })
  .catch(err => console.error(err));