import { OnInit, Component, Injectable } from "@angular/core";
import {  NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Masterserve } from '../../services/master.service';

declare let $;
import { Router } from '@angular/router';

import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AuthenService } from "src/app/services/authenservice.service";
import { MainProcessComponent } from "../main-process/main-process.component";

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

    readonly DELIMITER = '/';

    parse(value: string): NgbDateStruct | null {
        if (value) {
            let date = value.split(this.DELIMITER);
            return {
                day: (date[0], 10),
                month: (date[1], 10),
                year: (date[2], 10)
            };
        }
        return null;
    }

    format(date: NgbDateStruct | null): string {
        if (date !== null) {
            let day = date.day.toString()
            let month = date.month.toString()
            if (day.length < 2) {
                day = '0' + day
            }
            if (month.length < 2) {
                month = '0' + month
            }
            let newdate = day + this.DELIMITER + month + this.DELIMITER + date.year;
            return newdate;

        } else {

            return '';
        }
    }
}



@Component({
    selector: 'app-ConsolidateRisk',
    templateUrl: './page.consolidaterisk.html',
    styleUrls: ['./page.consolidaterisk.css'],
    providers: [
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
    ]
    //------------------------------------------------------------------------------------------
})


export class ConsolidateRisk implements OnInit {
    alertflow = '';
    chkroleuser;

    page = 1;
    pageSize = 15;

    table_ConsolidateRisk;
    valuecheckSelectAll = false;
    Risk_ByCategory;
    valueRisk_ByCategory: string;
    public selectAll: boolean;
    tempSelected: any = [];
    tempSelected2: any = [];
    tempdataselected: any = [];
    Risk_Level;

    ConsolidateSearch;
    Data_SessionStorage;
    EmpID;
    loading;
    tempBU;
    tempEmp;
    DataSearch;
    BU_Unit = '';
    DDLQuarterfrom;
    DDLQuarterto;

    list = {
        risk: {
            impactcolor: [],
            impact: [],
            risktable: [
                {
                    row: 1,
                    col: [
                        {
                            col: 0, class: 'impact-x', item: [
                                { title: 'Critical', bgcolor: '#1b190f', color: '#edf0f7', index: '', RiskId: '' },
                                { title: '&nbsp;', color: '#edf0f7' },
                                { title: '-', bgcolor: '#0e223c', color: '#edf0f7' },
                                { title: '&nbsp;', color: '#edf0f7' },
                                { title: '-', bgcolor: '#5c2322', color: '#edf0f7' }
                            ]
                        },
                        { col: 1, class: 'medium', item: [] },
                        { col: 2, class: 'medium', item: [] },
                        { col: 3, class: 'high', item: [] },
                        { col: 4, class: 'high', item: [] },
                        { col: 5, class: 'high', item: [] },
                    ]
                },
                {
                    row: 2,
                    col: [
                        {
                            col: 0, class: 'impact-x', item: [
                                { title: 'Serious', bgcolor: '#444128', color: '#edf0f7' },
                                { title: '&nbsp;', color: '#edf0f7' },
                                { title: '-', bgcolor: '#32598d', color: '#edf0f7' },
                                { title: '&nbsp;', color: '#edf0f7' },
                                { title: '-', bgcolor: '#aa504e', color: '#edf0f7' }
                            ]
                        },
                        { col: 1, class: 'medium', item: [] },
                        { col: 2, class: 'medium', item: [] },
                        { col: 3, class: 'medium', item: [] },
                        { col: 4, class: 'high', item: [] },
                        { col: 5, class: 'high', item: [] },
                    ]
                },
                {
                    row: 3,
                    col: [
                        {
                            col: 0, class: 'impact-x', item: [
                                { title: 'Significant', bgcolor: '#8b8352', color: 'black' },
                                { title: 'NPV/EMV (MMUSD)', color: '#4649f7' },
                                { title: '-', bgcolor: '#4d85ce', color: 'black' },
                                { title: 'NI (MMUSD)', color: '#ba2527' },
                                { title: '-', bgcolor: '#cb8d8f', color: 'black' }
                            ]
                        },
                        { col: 1, class: 'low', item: [] },
                        { col: 2, class: 'medium', item: [] },
                        { col: 3, class: 'medium', item: [] },
                        { col: 4, class: 'medium', item: [] },
                        { col: 5, class: 'high', item: [] },
                    ]
                },
                {
                    row: 4,
                    col: [
                        {
                            col: 0, class: 'impact-x', item: [
                                { title: 'Moderate', bgcolor: '#b9b696', color: 'black' },
                                { title: '&nbsp;', color: '#edf0f7' },
                                { title: '-', bgcolor: '#83a9db', color: 'black' },
                                { title: '&nbsp;', color: '#edf0f7' },
                                { title: '-', bgcolor: '#d6adb1', color: 'black' }
                            ]
                        },
                        { col: 1, class: 'low', item: [] },
                        { col: 2, class: 'low', item: [] },
                        { col: 3, class: 'medium', item: [] },
                        { col: 4, class: 'medium', item: [] },
                        { col: 5, class: 'medium', item: [] },
                    ]
                },
                {
                    row: 5,
                    col: [
                        {
                            col: 0, class: 'impact-x', item: [
                                { title: 'Minor', bgcolor: '#cdccbe', color: 'black' },
                                { title: '&nbsp;', color: '#edf0f7' },
                                { title: '-', bgcolor: '#aad1e1', color: 'black' },
                                { title: '&nbsp;', color: '#edf0f7' },
                                { title: '-', bgcolor: '#e1cfd4', color: 'black' }]
                        },
                        { col: 1, class: 'low', item: [] },
                        { col: 2, class: 'low', item: [] },
                        { col: 3, class: 'low', item: [] },
                        { col: 4, class: 'medium', item: [] },
                        { col: 5, class: 'medium', item: [] },
                    ]
                },
                {
                    row: 0,
                    col: [
                        { col: 0, class: 'impact-y', item: [] },
                        { col: 1, class: 'impact-y', item: [{ title: 'Rare', bgcolor: '#abd1e1', color: 'black' }] },
                        { col: 2, class: 'impact-y', item: [{ title: 'Unlikely', bgcolor: '#83a9db', color: 'black' }] },
                        { col: 3, class: 'impact-y', item: [{ title: 'Possible', bgcolor: '#85aadb', color: 'black' }] },
                        { col: 4, class: 'impact-y', item: [{ title: 'Likely', bgcolor: '#325a8d', color: 'white' }] },
                        { col: 5, class: 'impact-y', item: [{ title: 'Almost Certain', bgcolor: '#0d213b', color: 'white' }] },
                    ]
                }
            ],
            riskitems: []
        },
        riskprofile: {
            year: [],
            quarter: []
        },
        businessUnit: {
            items: []
        },
        field: {
            Mitigate: '1',
            riskprofileYear: '',
            riskprofileQuarter: '1',
            riskprofileQuartervalue: '1',
            businessUnit: '',
        }
    }
    myControl = new UntypedFormControl();
    options = [];
    filteredOptions: Observable<string[]>;

    myControlEmployeeID = new UntypedFormControl();
    EmployeeDD_auto = [];
    filteredOptionsEmployeeIDDD: Observable<string[]>;

    Version = null;
    VersionYear = '';
    VersionQuarter = '';
    DDLYear = [];
    DDLQuarter = [];
    tempCurrentQuarterID = null;
    tempCurrentQuarterIDValue = null;

    tempConsolidateSelectd = null;
   
    constructor(public authserviceToken: AuthenService, public mserve: Masterserve, 
        private appServicecomp: MainProcessComponent,  private router: Router) { } 
        appService= this.appServicecomp;
    ngOnInit(): void {
        this.appService    = this.appServicecomp;
        const _s = this;
        sessionStorage.setItem("RoleReview", '');
        
        const pathnameURL = window.location.pathname;
        let temp = pathnameURL.indexOf("/", pathnameURL.indexOf("/") + 1);
        if (temp !== -1) {
            this.Version = pathnameURL.slice(temp, temp + 2);
        }

        if (this.Version === '/q') {
            $('#modalVersioning').modal('show');
        }

        this.Risk_ByCategory = []
        this.table_ConsolidateRisk = []
        this.ConsolidateSearch = {
            SearchRiskCategoryby: '',
            SearchStatus: '',
            SearchBusinessUnit: '',
            SearchRiskname: '',
            SearchRequester: '',
            SearchHighrisk: '',
            SearchDatefrom: '',
            SearchDateto: '',
            SearchRiskID: '',
            SearchEscaLevel: false
        }

        this.DataSearch = {
            Yearfrom: null,
            Yearto: '',
            Quarterfrom: '',
            Quarterto: ''
        }

        this.Data_SessionStorage = JSON.parse(sessionStorage.datainfo);
        //console.log('Data_SessionStorage => ', this.Data_SessionStorage);
        this.EmpID = this.Data_SessionStorage[0].employeE_ID;
        this.chkroleuser = sessionStorage.Role
        this.GETDDL_YEAR();
        this.func_GETDDL_EMP();

        $('#modalAlertsuccess5').on('hidden.bs.modal', function (e) {
            _s.closeflow();
        })
    }
 
    GETDDL_YEAR() {
        const _s = this;
        this.loading = true;
        _s.mserve.Master_DDL({ 'Module': 'YearDD' }).then((response) => {
            if (response.data.status ){
                //console.log('YearDD : true', response)
                this.DDLYear = response.data.body;

                if (this.Version === null) {
                    let d = new Date();
                    this.DataSearch.Yearfrom = d.getFullYear();
                    let currentMonth = d.getMonth() + 1;
                    _s.mserve.Master_DDL({ Module: "QuarterDD", TextSearch1: this.DataSearch.Yearfrom }).then((response) => {
                        _s.DDLQuarter = response.data.body
                        //console.log("Quarter : ", _s.DDLQuarter);
                        this.func_calQuarter(currentMonth);
                    });
                } else {
                    this.func_RiskCategoryDDL();
                }

            } else {
                //console.log('YearDD : false', response)
            }
        })
    }

    func_calQuarter(month) {
        
        this.VersionQuarter = ''
        this.DataSearch.Quarterfrom = ''
        if (month >= 1 && month <= 3) {
            this.VersionQuarter = this.DDLQuarter[0].value;
            this.DataSearch.Quarterfrom = this.DDLQuarter[0].text;
        } else if (month >= 4 && month <= 6) {
            this.VersionQuarter = this.DDLQuarter[1].value;
            this.DataSearch.Quarterfrom = this.DDLQuarter[1].text;
        } else if (month >= 7 && month <= 9) {
            this.VersionQuarter = this.DDLQuarter[2].value;
            this.DataSearch.Quarterfrom = this.DDLQuarter[2].text;
        } else if (month >= 10 && month <= 12) {
            this.VersionQuarter = this.DDLQuarter[3].value;
            this.DataSearch.Quarterfrom = this.DDLQuarter[3].text;
        }
        this.tempCurrentQuarterID = this.DataSearch.Quarterfrom
        this.func_RiskCategoryDDL();
    }

    func_RiskCategoryDDL() {
        let chkDDper;
        chkDDper = (sessionStorage.Role === 'ERM') ? '' : '1';

        let formData = {
            Module: "RiskCateDD",
            TextSearch1: chkDDper,
            TextSearch7: this.VersionQuarter
        }
        this.mserve.Master_DDL(formData).then((response) => {
            if (response.data.status ){
                //console.log('RiskCateDDL : ', response.data.body)
                this.Risk_ByCategory = response.data.body;
                this.func_GetDDL_BusinessName();
            } else {
                //console.log('false', response)
            }
        });
    }

    func_GetDDL_BusinessName() {
        let formData = {
            Module: 'DepartmentDD'
        }
        this.mserve.Master_DDL(formData).then((response) => {
            //console.log("DDLPosition :", response.data.body)
            if (response.data.status ){
                for(let item_responseBody of response.data.body){
                    this.options.push({ text: item_responseBody.text, value: item_responseBody.value })
                }
                this.filteredOptions = this.myControl.valueChanges.pipe(
                    startWith(''),
                    map(value => this._filter(value))
                );
                this.func_GETDDL_RiskLevel();
            } else {
                //console.log('false', response)
            }
        });
    }

    func_GETDDL_RiskLevel() {
        this.mserve.Master_DDL({ Module: "RatingDD" }).then((response) => {
            if (response.data.status ){
                //console.log('RatingDD : ', response.data.body)
                this.Risk_Level = response.data.body;
                if (this.Version === null) {
                    this.func_GetData();
                }
            } else {
                //console.log('false', response)
            }
        });
    }

    func_GETDDL_EMP() {
        const _s = this;
        _s.mserve.Master_DDL({ Module: "EmployeeDD" }).then((response) => {
            _s.EmployeeDD_auto = response.data.body;
            //console.log('_s.EmployeeDD', _s.EmployeeDD_auto)
            this.filteredOptionsEmployeeIDDD = this.myControlEmployeeID.valueChanges.pipe(
                startWith(''),
                map(value => this._filterEmployeeIDDD(value))
            );
        })
    }

    func_getQuarterVersion() {
        const _s = this;
        _s.mserve.Master_DDL({ Module: "QuarterDD", TextSearch1: this.VersionYear }).then((response) => {
            _s.DDLQuarter = response.data.body
            //console.log("Quarter : ", _s.DDLQuarter);
        });
    }
    func_GetData() {
        const _s = this;
        let tempdatefrom = '', tempdateto = '', chkdate = false

        if (this.ConsolidateSearch.SearchDatefrom !== '' && this.ConsolidateSearch.SearchDateto === '') {
            this.alertflow = 'Please select Quarter To';
            $('#modalAlertdanger5').modal('show');
            chkdate = true
        } else if (this.ConsolidateSearch.SearchDatefrom === '' && this.ConsolidateSearch.SearchDateto !== '') {
            this.alertflow = 'Please select Quarter From';
            $('#modalAlertdanger5').modal('show');
            chkdate = true
        } else if (this.ConsolidateSearch.SearchDatefrom !== '' && this.ConsolidateSearch.SearchDateto !== '') {
            if (this.ConsolidateSearch.SearchDatefrom !== '') {
                tempdatefrom = this.func_formatdate(this.ConsolidateSearch.SearchDatefrom)
            }
            if (this.ConsolidateSearch.SearchDateto !== '') {
                tempdateto = this.func_formatdate(this.ConsolidateSearch.SearchDateto)
            }
        }

        this.func_GetData_II_CheckDate(chkdate, _s, tempdatefrom, tempdateto);
    }

    private func_GetData_II_CheckDate(chkdate: boolean, _s: this, tempdatefrom: string, tempdateto: string) {
        if (chkdate === false) {
            this.loading = true;
            this.ConsolidateSearch.SearchBusinessUnit = (this.tempBU === '') ? '' : this.ConsolidateSearch.SearchBusinessUnit;
            let d = new Date();
            let cur_year = d.getFullYear();

            let tempQuarterID = null, tempWPBID = null, tempQuarterMaster = null;
            if (cur_year === parseInt(_s.DataSearch.Yearfrom)) {
                ({ tempQuarterID, tempQuarterMaster, tempWPBID } = this.func_GetData_III_CheckCurrentYear(_s, tempQuarterID, tempQuarterMaster, tempWPBID));
            }
            else {
                ({ tempQuarterID, tempQuarterMaster, tempWPBID } = this.func_GetData_III_CheckNonCurrentYear(_s, tempQuarterID, tempQuarterMaster, tempWPBID));
            }

            let formData = this.func_GetData_III_formData(tempdatefrom, tempdateto, tempQuarterID, tempWPBID, tempQuarterMaster);
            this.tempCurrentQuarterIDValue = (tempQuarterID != null) ? tempQuarterID : tempWPBID;
            
            this.mserve.Get_Consolidate(formData).then((response) => {
                this.func_GetData_III_Get_Consolidate(response, _s, func_GetData_III_CheckRisk_Level);
            }).catch((e) => {
                this.authserviceToken.CheckTokenExpire(e.response.status);
            });
        }

        function func_GetData_III_CheckRisk_Level(ItemVal: any) {
            for (let item_Risk_Level of _s.Risk_Level) {
                if (ItemVal.Risk_Rating === item_Risk_Level.value) {
                    ItemVal.Risk_Rating = item_Risk_Level.text;
                    break;
                }
            }
        }
    }

    private func_GetData_III_formData(tempdatefrom: string, tempdateto: string, tempQuarterID: any, tempWPBID: any, tempQuarterMaster: any) {
        return {
            SessionEmpID: this.EmpID,
            Module: 'SEARCH',
            body: {
                Risk_Category : this.ConsolidateSearch.SearchRiskCategoryby,
                Risk_Status: this.ConsolidateSearch.SearchStatus,
                Risk_Business_Unit: this.ConsolidateSearch.SearchBusinessUnit,
                Risk_Name: this.ConsolidateSearch.SearchRiskname,
                Risk_Register_By: this.ConsolidateSearch.SearchRequester,
                Risk_Register_Date_From: tempdatefrom,
                Risk_Register_Date_To: tempdateto,
                Risk_Running: this.ConsolidateSearch.SearchRiskID,
                Risk_Rating: this.ConsolidateSearch.SearchHighrisk,
                Risk_Escalation: (this.ConsolidateSearch.SearchEscaLevel === true) ? '1' : '0',
                Consolidate_By: this.EmpID,
                IdCollection: '',
                QuarterID: (this.VersionQuarter != '') ? this.VersionQuarter : tempQuarterID,
                WPBID: tempWPBID,
                QuaterMaster: tempQuarterMaster
            }
        };
    }

    private func_GetData_III_Get_Consolidate(response, _s: this, func_GetData_III_CheckRisk_Level: (ItemVal: any) => void) {
        if (response.data.status) {
            //console.log('Get Data : ', response.data.body);
            this.table_ConsolidateRisk = response.data.body;
            _s.list.risk.riskitems = response.data.body;
            this.func_PlotRiskMap();
            this.table_ConsolidateRisk.forEach(function (ItemVal, ItemIndexff) {
                func_GetData_III_CheckRisk_Level(ItemVal);
            });
            this.valuecheckSelectAll = false;
            this.tempSelected = [];
            this.tempSelected2 = [];
            this.loading = false;
        } else {
            this.loading = false;
            this.table_ConsolidateRisk = [];
            this.alertflow = response.data.errorMessage;
            $('#modalAlertdanger5').modal('show');
            //console.log('false', response);
        }
    }

    private func_GetData_III_CheckNonCurrentYear(_s: this, tempQuarterID: any, tempQuarterMaster: any, tempWPBID: any) {
        if (_s.DataSearch.Quarterfrom.includes("WPB") === false) {
            for (let item_Quarterfrom of this.DDLQuarter) {
                if (_s.DataSearch.Quarterfrom === item_Quarterfrom.text) {
                    tempQuarterID = item_Quarterfrom.value;
                    tempQuarterMaster = item_Quarterfrom.value;
                    break;
                }
            }
        }
        else {
            for (let item_Quarterfrom of this.DDLQuarter) {
                if (_s.DataSearch.Quarterfrom === item_Quarterfrom.text) {
                    tempWPBID = item_Quarterfrom.value;
                    break;
                }
            }
            tempQuarterMaster = this.DDLQuarter[3].value;
        }
        return { tempQuarterID, tempQuarterMaster, tempWPBID };
    }

    private func_GetData_III_CheckCurrentYear(_s: this, tempQuarterID: any, tempQuarterMaster: any, tempWPBID: any) {
        if (_s.DataSearch.Quarterfrom.includes("WPB") === false) {
            ({ tempQuarterID, tempQuarterMaster } = this.func_GetData_IV_for1(_s, tempQuarterID, tempQuarterMaster));
        }
        else {
            tempWPBID = this.func_GetData_IV_for2(_s, tempWPBID);
            tempQuarterMaster = this.func_GetData_IV_for3(_s, tempQuarterMaster);
        }
        return { tempQuarterID, tempQuarterMaster, tempWPBID };
    }

    private func_GetData_IV_for3(_s: this, tmpQuarterMaster: any) {
        let tempQuarterMaster   = tmpQuarterMaster;
        for (let item_DDLQuarter of this.DDLQuarter) {
            if (_s.tempCurrentQuarterID === item_DDLQuarter.text) {
                tempQuarterMaster = item_DDLQuarter.value;
                break;
            }
        }
        return tempQuarterMaster;
    }

    private func_GetData_IV_for2(_s: this, tmpWPBID: any) {
        let tempWPBID   = tmpWPBID;
      
        for (let item_DDLQuarter of this.DDLQuarter) {
            if (_s.DataSearch.Quarterfrom === item_DDLQuarter.text) {
                tempWPBID = item_DDLQuarter.value;
                break;
            }
        }
        return tempWPBID;
    }

    private func_GetData_IV_for1(_s: this, tmpQuarterID: any, tmpQuarterMaster: any) {
        let tempQuarterMaster   = tmpQuarterMaster;
        let tempQuarterID   = tmpQuarterID;
        for (let item_DDLQuarter of this.DDLQuarter) {
            if (_s.DataSearch.Quarterfrom === item_DDLQuarter.text) {
                tempQuarterID = item_DDLQuarter.value;
                tempQuarterMaster = item_DDLQuarter.value;
                break;
            }
        }
        return { tempQuarterID, tempQuarterMaster };
    }

    func_PlotRiskMap() {
        const _s = this;
        //console.log('Data tablemap :', _s.list.risk.risktable)

        this.func_PlotRiskMap_II_SetRisktable(_s);

        if (_s.list.risk.riskitems !== null) {
            _s.list.risk.riskitems.forEach(function (ItemVal, ItemIndex) {
                if (_s.list.risk.risktable[(5 - ItemVal.Risk_Impact)].col[0].item.length !== 0) {
                    if (ItemVal.impact_Category === '1') {
                        func_PlotRiskMap_II_impact_Category(ItemVal, ItemIndex);
                    }
                    else {
                        func_PlotRiskMap_II_Noneimpact_Category(ItemVal, ItemIndex);
                    }
                }
            })
        }

        function func_PlotRiskMap_II_Noneimpact_Category(ItemVal: any, ItemIndex: number) {
            const bgColor = _s.list.risk.risktable[(5 - ItemVal.Risk_Impact)].col[0].item[0].bgcolor;
            const Color = _s.list.risk.risktable[(5 - ItemVal.Risk_Impact)].col[0].item[0].color;

            _s.list.risk.risktable[(5 - ItemVal.Risk_Impact)].col[parseInt(ItemVal.Risk_Likelihood)].item.push({
                index: (ItemIndex + 1).toString(),
                title: _s.list.risk.riskitems[ItemIndex].risk_Name,
                bgcolor: bgColor,
                color: Color,
                RiskId: ItemVal.Risk_Id
            });
        }

        function func_PlotRiskMap_II_impact_Category(ItemVal: any, ItemIndex: number) {
            const bgColor = _s.list.risk.risktable[(5 - ItemVal.Risk_Impact)].col[0].item[(ItemVal.impact_NPT_EMV === '1') ? 4 : 2].bgcolor;
            const Color = _s.list.risk.risktable[(5 - ItemVal.Risk_Impact)].col[0].item[(ItemVal.impact_NPT_EMV === '1') ? 4 : 2].color;
            _s.list.risk.risktable[(5 - ItemVal.Risk_Impact)].col[parseInt(ItemVal.Risk_Likelihood)].item.push({
                index: (ItemIndex + 1).toString(),
                title: _s.list.risk.riskitems[ItemIndex].risk_Name,
                bgcolor: bgColor,
                color: Color,
                RiskId: ItemVal.Risk_Id
            });
        }
    }

    private func_PlotRiskMap_II_SetRisktable(_s: this) {
        for (let i = 0; i < 5; i++) {
            for (let ii = 0; ii <= 5; ii++) {
                if (i !== 5 && ii !== 0) {
                    _s.list.risk.risktable[i].col[ii].item = [];
                }
            }
        }
    }

    func_Consolidation() {
        this.BU_Unit = sessionStorage.BUNameWork;

        //console.log("Bu Unit : ", this.BU_Unit);
        if (this.tempSelected.length === 0 && this.tempSelected2.length === 0) {
            this.alertflow = 'Please select item for Consolidate'
            $('#modalAlertdanger5').modal('show');
        } else {
            let formData = {
                SessionEmpID: this.EmpID,
                Module: 'CONSOLIDATE',
                body: {
                    Risk_Category : '',
                    Risk_Status: '',
                    Risk_Business_Unit: this.BU_Unit,
                    Risk_Name: '',
                    Risk_Register_By: '',
                    Risk_Register_Date_From: '',
                    Risk_Register_Date_To: '',
                    Risk_Running: '',
                    Consolidate_By: this.EmpID,
                    Consolidate_Staff: this.tempSelected,
                    Consolidate_Transection: this.tempSelected2
                }
            }
            this.mserve.Get_Consolidate(formData).then((response) => {
                if (response.data.status ){
                    this.tempSelected = [];
                    this.tempSelected2 = [];
                    this.tempConsolidateSelectd = null;
                    this.func_GetData();
                    this.alertflow = 'Consolidate Success'
                    $('#modalAlertsuccess5').modal('show');
                } else {
                    this.alertflow = response.data.errorMessage
                    $('#modalAlertdanger5').modal('show');
                    //console.log('Console false', response)
                }
            }).catch((e) => {
                this.authserviceToken.CheckTokenExpire(e.response.status)
            });
        }
    }

    func_Consolidation_RiskMap(RiskId, selectnum) {
        
        this.tempConsolidateSelectd = RiskId
        $('#modalConfirmWarning').modal('show');
    }

    ConfirmConsolidate() {
        $('#modalConfirmWarning').modal('hide');
        const _s = this;
        this.tempSelected = [];
        this.tempSelected2 = [];

        let item = _s.table_ConsolidateRisk.filter(function (ConsolidateRisk) {
            return ConsolidateRisk.Risk_Id == _s.tempConsolidateSelectd;
        })[0];

        if (item.Table_Type === "Staff") {
            this.tempSelected.push({
                Risk_Id: item.Risk_Id,
                Risk_Business_Unit: item.Risk_Business_Unit,
                Risk_Business_Unit_WF: item.Risk_Business_Unit_WF,
                Risk_Status_Workflow: item.Risk_Status_Workflow,
                QuarterID:item.quarterID,
                Risk_Type: item.Risk_Type,
                Risk_AssignTo: ""
            });
        }
        else if (item.Table_Type === "Transection") {
            this.tempSelected2.push({
                Risk_Id: item.Risk_Id,
                Risk_Business_Unit: item.Risk_Business_Unit,
                Risk_Business_Unit_WF: item.Risk_Business_Unit_WF,
                Risk_Status_Workflow: item.Risk_Status_Workflow,
                QuarterID:item.QuarterID,
                Risk_Type: item.Risk_Type,
                Risk_AssignTo: ""
            });
        }

        //console.log("tempSelected : ", this.tempSelected);
        //console.log("tempSelected2 : ", this.tempSelected2);

        this.func_Consolidation();
    }
    SelectAll(value) {
        for(let i = 0; i < this.table_ConsolidateRisk.length; i++) {
            if (this.table_ConsolidateRisk[i].Risk_ViewMode !== 'Y') {
                let items;
                items = (document.getElementsByName("selected" + (i + 1)));
                if (value && !items[0].checked) {
                    items[0].checked = true;
                    if (this.table_ConsolidateRisk[i].Table_Type === "Staff") {
                        this.tempSelected.push({
                            Risk_Id: this.table_ConsolidateRisk[i].Risk_Id,
                            Risk_Business_Unit: this.table_ConsolidateRisk[i].Risk_Business_Unit,
                            Risk_Business_Unit_WF: this.table_ConsolidateRisk[i].Risk_Business_Unit_WF,
                            Risk_Status_Workflow: this.table_ConsolidateRisk[i].Risk_Status_Workflow,
                            Risk_Type: this.table_ConsolidateRisk[i].Risk_Type,
                            QuarterID:this.table_ConsolidateRisk[i].quarterID,
                            Risk_AssignTo: ""
                        });
                    }
                    else if (this.table_ConsolidateRisk[i].Table_Type === "Transection") {
                        this.tempSelected2.push({
                            Risk_Id: this.table_ConsolidateRisk[i].Risk_Id,
                            Risk_Business_Unit: this.table_ConsolidateRisk[i].Risk_Business_Unit,
                            Risk_Business_Unit_WF: this.table_ConsolidateRisk[i].Risk_Business_Unit_WF,
                            Risk_Status_Workflow: this.table_ConsolidateRisk[i].Risk_Status_Workflow,
                            Risk_Type: this.table_ConsolidateRisk[i].Risk_Type,
                            QuarterID:this.table_ConsolidateRisk[i].quarterID,
                            Risk_AssignTo: ""
                        });
                    }
                }
                else if (!value  && items[0].checked) {
                    items[0].checked = false;
                    this.tempSelected = [];
                    this.tempSelected2 = [];
                }
            }
        }
        //console.log('Data Selected Staff : ', this.tempSelected)
        //console.log('Data Selected Transection : ', this.tempSelected2)
    }

    Selected(id, selectnum) {
        //console.log(id, selectnum);
        let items;
        items = (document.getElementsByName("selected" + selectnum));
        if (items[0].checked) {        
            for(let item_table_ConsolidateRisk of this.table_ConsolidateRisk){
                if (item_table_ConsolidateRisk.Risk_Id === id && item_table_ConsolidateRisk.Table_Type === "Staff") {
                    this.tempSelected.push({
                        Risk_Id: item_table_ConsolidateRisk.Risk_Id,
                        Risk_Business_Unit: item_table_ConsolidateRisk.Risk_Business_Unit,
                        Risk_Business_Unit_WF: item_table_ConsolidateRisk.Risk_Business_Unit_WF,
                        Risk_Status_Workflow: item_table_ConsolidateRisk.Risk_Status_Workflow,
                        Risk_Type: item_table_ConsolidateRisk.Risk_Type,
                        QuarterID:item_table_ConsolidateRisk.quarterID,
                        Risk_AssignTo: ""
                    });
                }
                else if (item_table_ConsolidateRisk.Risk_Id === id && item_table_ConsolidateRisk.Table_Type === "Transection") {
                    this.tempSelected2.push({
                        Risk_Id: item_table_ConsolidateRisk.Risk_Id,
                        Risk_Business_Unit: item_table_ConsolidateRisk.Risk_Business_Unit,
                        Risk_Business_Unit_WF: item_table_ConsolidateRisk.Risk_Business_Unit_WF,
                        Risk_Status_Workflow: item_table_ConsolidateRisk.Risk_Status_Workflow,
                        Risk_Type: item_table_ConsolidateRisk.Risk_Type,
                        QuarterID:item_table_ConsolidateRisk.quarterID,
                        Risk_AssignTo: ""
                    });
                }
            }
        }
        else {  

            this.Selected_II_LooptempSelected(id);
            this.Selected_II_LooptempSelected2(id);
        }
        //console.log('Data Selected Staff : ', this.tempSelected)
        //console.log('Data Selected Transection : ', this.tempSelected2)
    }

    private Selected_II_LooptempSelected2(id: any) {
        for (let i = 0; i < this.tempSelected2.length; i++) {
            if (this.tempSelected2[i].Risk_Id === id) {
                this.tempSelected2.splice(i, 1);
            }
        }
    }

    private Selected_II_LooptempSelected(id: any) {
        for (let i = 0; i < this.tempSelected.length; i++) {
            if (this.tempSelected[i].Risk_Id === id) {
                this.tempSelected.splice(i, 1);
            }
        }
    }

    func_clear() {
        this.valuecheckSelectAll = false
        this.ConsolidateSearch.SearchRiskCategoryby = '';
        this.ConsolidateSearch.SearchStatus = '';
        this.ConsolidateSearch.SearchBusinessUnit = '';
        this.ConsolidateSearch.SearchRiskname = '';
        this.ConsolidateSearch.SearchRequester = '';
        this.ConsolidateSearch.SearchHighrisk = '';
        this.ConsolidateSearch.SearchDatefrom = '';
        this.ConsolidateSearch.SearchDateto = '';
        this.ConsolidateSearch.SearchRiskID = '';
        this.ConsolidateSearch.SearchEscaLevel = '';
        this.tempBU = '';
        this.tempEmp = '';

        this.DataSearch.Yearfrom = '';
        this.DataSearch.Yearto = '';
        this.DataSearch.Quarterfrom = '';
        this.DataSearch.Quarterto = '';

        this.tempSelected = [];
        this.tempSelected2 = [];
        this.tempConsolidateSelectd = null;

        this.GETDDL_YEAR();
    }

    func_formatdate(value) {
        
        let day = value.day.toString();
        let month = value.month.toString();
        let year = value.year.toString();
        if (day.length < 2) {
            day = '0' + day
        }
        if (month.length < 2) {
            month = '0' + month
        }
        let formatedate = year + '-' + month + '-' + day
        return formatedate
    }

    closealertdanger() {
        let _s = this
        $('#modalAlertdanger5').modal('hide');
        _s.func_GetData()
    }

    closeconsolidate() {
        $('#modalConfirmWarning').modal('hide');
    }

    closeflow() {
        const _s = this;
        $('#modalAlertsuccess5').modal('hide');
        setTimeout(function () { _s.router.navigate(['/Approval/2']); }, 500)
    }

    func_selectBU() {
        
        const _s = this;
        
        _s.options.forEach(function(val, Index) {
            if (val.text === _s.tempBU) {
                _s.ConsolidateSearch.SearchBusinessUnit = val.value
            }
        });
    }

    func_selectEmp() {
        
        const _s = this;
        _s.EmployeeDD_auto.forEach(function (val, Index) {
            if (val.text === _s.tempEmp) {
                _s.ConsolidateSearch.SearchRequester = val.value
            }
        });
    }

    func_ReveiewPage(riskid, filter) {
        if (filter === 'Staff') {
            sessionStorage.setItem("RoleReview", 'Staff');
        } else {
            sessionStorage.setItem("RoleReview", 'Co');
        }
        sessionStorage.setItem("RiskID", riskid);
        this.router.navigate(['/ReviewPage', 'Reviewfromconsol']);
    }


    func_selFromyear() {
        
        const _s = this;
        _s.DataSearch.Quarterfrom = ''
        _s.VersionQuarter = '';
        _s.mserve.Master_DDL({ Module: "QuarterDD", TextSearch1: _s.DataSearch.Yearfrom }).then((response) => {
            _s.DDLQuarter = response.data.body
            //console.log("DDLQuarter : ", _s.DDLQuarter);
        });
    }
    func_selToyear() {
        
        const _s = this;
        _s.DataSearch.Quarterto = ''
        _s.mserve.Master_DDL({ Module: "QuarterDD", TextSearch1: _s.DataSearch.Yearto }).then((response) => {
            _s.DDLQuarterto = response.data.body
            //console.log("QuarterTo : ", _s.DDLQuarterto);
        });
    }

    changeQuarter() {
        
        const _s = this;
        _s.VersionQuarter = '';
        let chkDDper;
        chkDDper = (sessionStorage.Role === 'ERM') ? '' : '1';

        let formData = {
            Module: "RiskCateDD",
            TextSearch1: chkDDper,
            TextSearch7: this.tempCurrentQuarterIDValue 
        }
        this.mserve.Master_DDL(formData).then((response) => {
            if (response.data.status ){
                //console.log('RiskCateDDL : ', response.data.body)
                this.Risk_ByCategory = response.data.body;
            } else {
                //console.log('false', response)
            }
        });
    }

    func_okversion() {
        
        const _s = this;
        $('#modalVersioning').modal('hide');
        setTimeout(function () { _s.func_GetData(); }, 500)
    }

    private _filter(value: string): string[] {
        const filterValue = value?.toLowerCase();
        if (filterValue !== '') {
            return this.options.filter(option => option.text?.toLowerCase().includes(filterValue));
        } else {
            return this.options
        }
    }

    private _filterEmployeeIDDD(value: string): string[] {

        let tempFileter;
        const filterValue = value?.toLowerCase();
        if (filterValue !== '') {
            tempFileter = this.EmployeeDD_auto.filter(option1 => option1.text?.toLowerCase().includes(filterValue));
            if (tempFileter.length !== 0) {
                return tempFileter;
            }
        } else {
            return this.EmployeeDD_auto
        }
    }

} 