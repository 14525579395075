import { Component, OnInit} from "@angular/core";
import { Masterserve } from '../../../services/master.service';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
declare let $;
import {UntypedFormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { AppSettingService } from "src/app/app-setting-service";
import { AuthenService } from "src/app/services/authenservice.service";





@Component({
    selector: 'app-MasterBanner',
    templateUrl: './masterbanner.html',
    styleUrls: ['./masterbanner.css'],
    providers: [Masterserve, NgbModalConfig, NgbModal]
})
export class MasterBanner implements OnInit {
    
    constructor(config: NgbModalConfig, private modalService: NgbModal,
        public authserviceToken: AuthenService,
        public mserve: Masterserve,public appSettingService:AppSettingService) {
        config.backdrop = 'static';
        config.keyboard = false;
    }
    configdata= this.appSettingService.read();
    BASE_URL =this.configdata.api_url;
    SessionEmpID;
    Module;
    BannerId;
    BusinessCode;
    BusinessUnit;
    DelFlag;
    Status;
   
    Data_SessionStorage;

    tablebanner;
    tempdepart = '';
    loading = true;

    tempDisplayImage = [];
    BannerImage = [];
    BannerFileImage = [];
    tempImageID = [];
    url = null;

    myControlBUDD = new UntypedFormControl();
    BUDD_auto = [];
    filteredOptionsBUDD: Observable<string[]>;
         
    
    path = this.BASE_URL + '/app/backend/Resources/FileUpload';
     
    ngOnInit(): void {

        
        let _s = this;
 
        _s.Data_SessionStorage = JSON.parse(sessionStorage.datainfo);
        //console.log('Data_SessionStorage => ',this.Data_SessionStorage);


        _s.SessionEmpID = this.Data_SessionStorage[0].employeE_ID;
        
        _s.LoadDDL();
    }

    func_Getlist () {
        const _s = this;
        let datavalue = {
            SessionEmpID: this.SessionEmpID,
            Module: "getlist",
            body: {
                BannerName :"",
                BusinessId :"",
                DelFlag :"",
            }
        }
        _s.mserve.GetBanner(datavalue).then((response) => {
            if (response.data.status ){
                //console.log('Data : ',response.data.body);
                _s.tablebanner = response.data.body;
                _s.loading = false;
            } else {
                this.loading = false
                //console.log('false', response)
                alert(response.data.errorMessage);
            }
        }).catch((e) => {
            this.authserviceToken.CheckTokenExpire(e.response.status)
            });
    }

    onSelectFile(event) {
        
        this.tempDisplayImage = [];
        this.BannerFileImage = [];

        if (event.target.files && event.target.files[0]) {
            this.BannerImage = event.target.files[0].name
            this.BannerFileImage.push(event.target.files[0])
            this.tempDisplayImage.push(event.target.files[0])
            let reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]); 
            reader.onload = (event) => { 
                this.url = event.target.result;
            }
        }
    }

    func_addbanner(mode,item) {
        
        let _s = this;
        if(mode == 'Add'){
            _s.Module = 'insert';
            _s.tempDisplayImage = [];
            _s.url = null;
            _s.BusinessCode = '';
            _s.BusinessUnit = '';
            _s.Status = true;
            $('#modaladdbanner').modal('show')
        }
        else if(mode == 'Edit')
        {
            _s.Module = 'update';
            _s.BannerId = item.bannerId;
            _s.BusinessCode = item.businessId;
            _s.BusinessUnit = item.businessName;
            _s.Status =  (item.delFlag == '0') ? true : false;

            _s.BannerFileImage = [];
            _s.tempDisplayImage = [];
            _s.tempImageID = [];
            _s.url = null;

            if (item.itemAttSeq1.length !== 0) {
                item.itemAttSeq1.forEach(function(ItemVal, ItemIndex) {
                    _s.tempDisplayImage.push({
                        attachFileID: ItemVal.attachFileID,
                        name :ItemVal.fileName,
                        form: ItemVal.form,
                        pathFile :ItemVal.pathFile,
                        reqId: ItemVal.reqId,
                        seqNo: ItemVal.seqNo,
                        rootPath: ItemVal.rootPath
                    })
                    _s.tempImageID.push({
                        attachFileID: ItemVal.attachFileID,
                        name :ItemVal.fileName,
                        form: ItemVal.form,
                        pathFile :ItemVal.pathFile,
                        reqId: ItemVal.reqId,
                        seqNo: ItemVal.seqNo,
                        rootPath: ItemVal.rootPath
                    })
                });
            }

            if (_s.tempDisplayImage.length !== 0) {
                this.BannerImage = _s.tempDisplayImage[0].name
                this.url = this.path + '/' + _s.tempDisplayImage[0].pathFile
            }


            $('#modaladdbanner').modal('show')
        }
        //console.log('tablebanner',_s.tablebanner)
    }

    func_closebtn() { 
        $('#modaladdbanner').modal('hide')
    }

    func_savebtn() {
        let _s = this;
        //console.log(" _s: ", _s);
        const foundObject = this.tablebanner.find(item => item.businessId === _s.BusinessCode);

        if (foundObject) {
            alert("Business Unit is duplicate.");

        } else {
            let datavalue = {
                SessionEmpID: this.SessionEmpID,
                Module: _s.Module,
                body: {
                    BannerId: _s.BannerId,
                    BusinessId: _s.BusinessCode,
                    DelFlag: _s.Status ? '0' : '1',
                }
            }

            _s.mserve.GetBanner(datavalue).then((response) => {
                if (response.data.status) {

                    //console.log('Data : ', response.data.body);
                    let formDataImg = {
                        AttachFileID: "",
                        Form: "3",
                        SeqNo: "1",
                        ReqId: response.data.reqId,
                        DelFlag: ""
                    }
                    let formDataIMG = new FormData();
                    let arListimg = this.BannerFileImage

                    this.checkFile(formDataIMG, formDataImg, arListimg, _s);
                    $('#modaladdbanner').modal('hide')
                    _s.loading = false;
                    _s.func_Getlist();
                }
                else {
                    //console.log('false', response)
                    alert(response.data.errorMessage);
                }
            }).catch((e) => {
                this.authserviceToken.CheckTokenExpire(e.response.status)
            });
        }
    }

    private checkFile(formDataIMG: FormData, formDataImg: { AttachFileID: string; Form: string; SeqNo: string; ReqId: any; DelFlag: string; }, arListimg: any[], _s: this) {
        if (this.BannerFileImage.length !== 0) {

            this.func_DelImage(this.tempImageID);

            formDataIMG.append('formData', JSON.stringify(formDataImg));
            for (let item_arListimg of arListimg) {
                formDataIMG.append('files', item_arListimg, item_arListimg.name);
            }
            this.mserve.UploadFileMultiple(formDataIMG).then((response) => {
                if (response.data.status) {
                    $('#modaladdbanner').modal('hide');
                    _s.loading = false;
                    _s.func_Getlist();
                } else {
                    //console.log('false', response);
                }
            });
        }
    }

    func_selectCoorBU (value) {
        const _s = this;
        for (let item_BUDD_auto of this.BUDD_auto){
            if (item_BUDD_auto.text === value) {
                _s.tempdepart = item_BUDD_auto.value;
                _s.BusinessCode = item_BUDD_auto.text1;
                break;
            }
        }
    }
    LoadDDL(){
        let _s = this;

        _s.mserve.Master_DDL({ Module:"DeptByBannerDD"}).then((response) => {
            _s.BUDD_auto = response.data.body;
            //console.log('DeptByBannerDD',_s.BUDD_auto)

            this.filteredOptionsBUDD = this.myControlBUDD.valueChanges.pipe(
                startWith(''),
                map(value => this._filterBUDD(value))
            );

        })
        _s.func_Getlist ()
    }

    private _filterBUDD(value: string): string[] {
        
        let tempFileter;
        if (value !== null) {
            const filterValue = value?.toLowerCase();
            if (filterValue !== '') {
                tempFileter = this.BUDD_auto.filter(option1 => option1.text?.toLowerCase().includes(filterValue));
                if (tempFileter.length !== 0) {
                    return tempFileter;
                }
            } else {
                return this.BUDD_auto
            }
        }
        else {
            return this.BUDD_auto
        }
    }

    func_DelImage (item) {
        let formData = item[0]
        this.mserve.DeleteFile(formData).then((response) => {
        }).catch((e) => {
            this.authserviceToken.CheckTokenExpire(e.response.status)
            });
    }

}