 <div>
    <div class="col-12">
        <h2>Alert</h2>
        <hr>
    </div>
    <div class="row col-12 mx-0 px-0">
        <div class="col-12 mb-2" style="font-size: 18px;">
            {{ data }}
            <hr>
        </div>
        <div class="col-12" style="text-align: right;">
            <button class="btn btn-primary" (click)="onNoClick()" style="font-size: 18px;">OK</button>
        </div>
    </div>
</div>
