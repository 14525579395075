import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Masterserve } from '../../../services/master.service';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AuthenService } from 'src/app/services/authenservice.service';
declare let $;

@Component({
  selector: 'app-MasterEmail',
  templateUrl: './MasterEmail.html',
  styleUrls: ['./MasterEmail.css'],
  providers: [Masterserve, NgbModalConfig, NgbModal],
})
export class MasterEmail implements OnInit {
  constructor(
    public authserviceToken: AuthenService,
    config: NgbModalConfig,
    private modalService: NgbModal,
    public mserve: Masterserve
  ) {}
  loading = false;
  DataEmail = [];
  selectedRisk = '1';
  TemplateEmail = {
    ID: '',
    emailID: '',
    subject: '',
    description: '',
    body1: '',
    body2: '',
    body3: '',
  };

  Alltable;

  dis1 = { html: new UntypedFormControl('', Validators.required) };
  form1: UntypedFormGroup = new UntypedFormGroup(this.dis1);
  dis2 = { html: new UntypedFormControl('', Validators.required) };
  form2: UntypedFormGroup = new UntypedFormGroup(this.dis2);
  dis3 = { html: new UntypedFormControl('', Validators.required) };
  form3: UntypedFormGroup = new UntypedFormGroup(this.dis3);

  configNote;
  config: any = {
    airMode: false,
    tabDisable: true,
    popover: {
      table: [
        ['add', ['addRowDown', 'addRowUp', 'addColLeft', 'addColRight']],
        ['delete', ['deleteRow', 'deleteCol', 'deleteTable']],
      ],
      image: [
        ['image', ['resizeFull', 'resizeHalf', 'resizeQuarter', 'resizeNone']],
        ['float', ['floatLeft', 'floatRight', 'floatNone']],
        ['remove', ['removeMedia']],
      ],
      link: [['link', ['linkDialogShow', 'unlink']]],
      air: [
        [
          'font',
          [
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'superscript',
            'subscript',
            'clear',
          ],
        ],
      ],
    },
    height: '200px',

    toolbar: [
      ['fontsize', ['fontname', 'fontsize', 'color', 'forecolor']],
      ['para', ['style0', 'ul', 'ol', 'paragraph', 'height']],
      ['insert', ['table', 'link']],
    ],
    codeviewFilter: true,
    codeviewIframeFilter: true,
  };
  popupMessageAlert = '';
  popupTitleSuccess = 'Alert !';

  ngOnInit(): void {
    this.func_GetData();
  }

  func_GetData() {
    this.loading = true;
    let formData = {
      SessionEmpID: 1,
      body: {
        Module: 'getlist',
        Status: 1,
        Subject: '',
        Body1: '',
        Body2: '',
        Body3: '',
        Description: '',
        Type: this.selectedRisk,
      },
    };
    this.mserve
      .GetTemplateEmail(formData)
      .then((response) => {
        if (response.data.status) {
          //console.log('Data get : ', response.data.body);
          this.DataEmail = response.data.body;
          this.loading = false;
        } else {
          //console.log('false', response);
          alert(response.data.errorMessage);
          this.loading = false;
        }
      })
      .catch((e) => {
        this.authserviceToken.CheckTokenExpire(e.response.status);
      });
  }

  func_save() {
    this.popupMessageAlert = '';

    const _s = this;
    this.loading = true;
    let temp = [{ body1: '' }, { body2: '' }, { body3: '' }];
    temp[0].body1 = _s.form1.get('html').value;
    temp[0].body2 = _s.form2.get('html').value;
    temp[0].body3 = _s.form3.get('html').value;
    //console.log('temp', temp);

    let formData = {
      SessionEmpID: 1,
      body: {
        Module: 'update',
        Status: this.TemplateEmail.ID,
        Subject: this.TemplateEmail.subject,
        Body1: temp[0].body1,
        Body2: temp[0].body2,
        Body3: temp[0].body3,
        Description: this.TemplateEmail.description,
      },
    };
    //console.log('formData', formData);
    this.mserve.GetTemplateEmail(formData).then((response) => {
      if (response.data.status) {
        this.popupMessageAlert = 'Update Success';
        $('#modaleditemail').modal('hide');
        $('#modalSuccess').modal('show');
        this.func_GetData();
      } else {
        this.popupMessageAlert = 'error';
        //console.log('false', response);
        alert(response.data.errorMessage);
        this.loading = false;
      }
    });
  }

  func_editEmail(item) {
    const _s = this;
    this.TemplateEmail.ID = item.Status;
    this.TemplateEmail.emailID = 'M-R' + item.Status;
    this.TemplateEmail.subject = item.Subject;
    this.TemplateEmail.description = item.Description;
    let tmptable = [];
    let arr = {
      body1: '',
      body2: '',
      body3: '',
      form1: '',
      form2: '',
      form3: '',
    };
    arr.body1 = item.Body1;
    arr.body2 = item.Body2;
    arr.body3 = item.Body3;
    arr.form1 = '';
    arr.form2 = '';
    arr.form3 = '';

    tmptable.push(arr);

    this.dis1 = {
      html: new UntypedFormControl(tmptable[0].body1, Validators.required),
    };
    let a1: UntypedFormGroup = new UntypedFormGroup(this.dis1);
    this.form1 = a1;

    this.dis2 = {
      html: new UntypedFormControl(tmptable[0].body2, Validators.required),
    };
    let a2: UntypedFormGroup = new UntypedFormGroup(this.dis2);
    this.form2 = a2;

    this.dis3 = {
      html: new UntypedFormControl(tmptable[0].body3, Validators.required),
    };
    let a3: UntypedFormGroup = new UntypedFormGroup(this.dis3);
    this.form3 = a3;

    tmptable[0].form1 = this.form1;
    tmptable[0].form2 = this.form2;
    tmptable[0].form3 = this.form3;

    _s.Alltable = tmptable;
    //console.log('Alltable', _s.Alltable);

    $('#modaleditemail').modal('show');
  }

  func_search() {
    this.loading = true;
    let formData = {
      SessionEmpID: 1,
      body: {
        Module: 'getlist',
        Status: 1,
        Subject: '',
        Body1: '',
        Body2: '',
        Body3: '',
        Description: '',
        Type: this.selectedRisk,
      },
    };
    this.mserve
      .GetTemplateEmail(formData)
      .then((response) => {
        if (response.data.status) {
          //console.log('Data get : ', response.data.body);
          this.DataEmail = response.data.body;
          this.loading = false;
        } else {
          //console.log('false', response);
          alert(response.data.errorMessage);
          this.loading = false;
        }
      })
      .catch((e) => {
        this.authserviceToken.CheckTokenExpire(e.response.status);
      });
  }

  Close_Modal() {
    $('#modaleditemail').modal('hide');
  }

  fuc_HTML(value) {
    return value;
  }

  ngAfterViewInit(): void {
    const _s = this;
  }

  changeType(val) {
    this.selectedRisk = val;
    this.func_GetData();
  }
}
