import { Component,OnInit,Injectable } from "@angular/core";
import { NgbModal, NgbModalConfig, NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Masterserve } from '../../services/master.service';
import { Router } from '@angular/router';
declare let $;
import {UntypedFormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { AuthenService } from "src/app/services/authenservice.service";

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day : (date[0], 10),
        month : (date[1], 10),
        year : (date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    if (date !== null) {
        let day = date.day.toString()
        let month = date.month.toString()
        if (day.length < 2) {
            day = '0' + day
        }
        if (month.length < 2) {
            month = '0' + month
        }
        let newdate  = day + this.DELIMITER + month + this.DELIMITER + date.year ;
        return  newdate;
    } else { 
       return '';
    }
}
}
//---------------------------------------------------------------------------------------------------------------------

@Component({
    selector: 'app-TransferRisk',
    templateUrl: './TransferRisk.html',
    styleUrls: ['./TransferRisk.css'],
    providers: [Masterserve, NgbModalConfig, NgbModal, { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }]
})

export class TransferRisk implements OnInit {
   

    constructor(public authserviceToken: AuthenService,config: NgbModalConfig, private modalService: NgbModal,public mserve: Masterserve,private router: Router) {}
    alertflow = null;
    page = 1;
    pageSize = 20;
    Search = {
        BUName :'',
        BUID :'',
        RiskCate :'',
        RiskStatus :'',
        RiskName :'',
        LevelRisk :'',
        Datefrom :'',
        Dateto :'',
        RiskID :'',
        Escalation :false,
        tempQuarter :''
    }

    DataSearch = {
        Yearfrom:null,
        Yearto:'',
        Quarterfrom:'',
        Quarterto:''
    }

    Transfer = {
        RiskID :'',
        RiskIDName :'',
        RiskName :'',
        RiskBUName :'',
        RiskLevel :'',
        BUName :'',
        BUID :'',
        EmpName :'',
        EmpID :''
    }

    YearDD = [];
    QuarterDD = [];
    Risk_ByCategory = [];
    Risk_Level = [];
    
    DDLQuarterfrom = [];
    DDLQuarterto = [];

    tempSelected = [];
    DataRiskTransfer = [];
    loading = false;
    Data_SessionStorage;

    BUDD = [];
    myControlBU = new UntypedFormControl();
    filteredOptionsBU: Observable<string[]>;

    BUDD2 = [];
    myControlBU2 = new UntypedFormControl();
    filteredOptionsBU2: Observable<string[]>;

    EmpDD = [];
    myControlEmp = new UntypedFormControl();
    filteredOptionsEmp: Observable<string[]>;

    EmpSel ={
        id: ''
    };

    ngOnInit(): void {
        const _s = this;
        this.Data_SessionStorage = JSON.parse(sessionStorage.datainfo);
        //console.log('Data_SessionStorage => ',this.Data_SessionStorage)
        _s.loadDDL();
    }

    loadDDL () {
        const _s = this;
        this.loading = true;
        _s.mserve.Master_DDL({ Module:"YearDD" }).then((response) => {
          _s.YearDD = response.data.body
          //console.log("YearDD : ",_s.YearDD)

          let d = new Date();
            this.DataSearch.Yearfrom = d.getFullYear();
            let currentMonth = d.getMonth() + 1;
            _s.mserve.Master_DDL({ Module:"QuarterDD",TextSearch1: this.DataSearch.Yearfrom }).then((response) => {
                _s.QuarterDD = response.data.body
                //console.log("QuarterDD : ",_s.QuarterDD)
                this.func_calQuarter(currentMonth);
            })
        })
    }
    func_calQuarter (month) {
        
        this.DataSearch.Quarterfrom = ''
        if (month >= 1 && month <= 3) {
            this.DataSearch.Quarterfrom = this.QuarterDD[0].value;
        } else if (month >= 4 && month <= 6) {
            this.DataSearch.Quarterfrom = this.QuarterDD[1].value;
        } else if (month >= 7 && month <= 9) {
            this.DataSearch.Quarterfrom = this.QuarterDD[2].value;
        } else if (month >= 10 && month <= 12) {
            this.DataSearch.Quarterfrom = this.QuarterDD[3].value;
        }
        this.func_GetDDL_BusinessName();
    }
    func_GetDDL_BusinessName () {
        let formData = {
            Module :'DepartmentDD'
        }
        this.mserve.Master_DDL(formData).then((response) => {
            if (response.data.status ){
                //console.log("DDLPosition :",response.data.body)
                this.BUDD = response.data.body;
                this.BUDD2 = response.data.body;

                this.filteredOptionsBU = this.myControlBU.valueChanges.pipe(
                    startWith(''),
                    map(value => this._filter(value))
                );

                this.filteredOptionsBU2 = this.myControlBU2.valueChanges.pipe(
                    startWith(''),
                    map(value => this._filter2(value))
                );

                this.func_RiskCategoryDDL();
            } else {
                alert(response.data.errorMessage);
                //console.log('false',response)
            }
        });
    }
    
  closeflowsuccess() {
    $('#modalAlertTransger').modal('hide');
    $('#modalAlertsuccess5').modal('hide');
  }
    func_RiskCategoryDDL () {
        let chkDDper;
        chkDDper = (sessionStorage.Role === 'ERM') ? '' : '1';

        let formData = {
            Module:"RiskCateDD",
            TextSearch1:chkDDper,
            TextSearch7:this.DataSearch.Quarterfrom
        }
        this.mserve.Master_DDL(formData).then((response) => {
            if (response.data.status ){
                //console.log('RiskCateDDL : ',response.data.body)
                this.Risk_ByCategory = response.data.body;
                this.func_GETDDL_RiskLevel();
            } else {
                //console.log('false',response)
            }
        });
    }
    func_GETDDL_RiskLevel () {
        this.mserve.Master_DDL({ Module:"RatingDD" }).then((response) => {
            if (response.data.status ){
                //console.log('RatingDD : ',response.data.body)
                this.Risk_Level = response.data.body;
                this.func_search();
            } else {
                //console.log('false',response)
            }
        });
    }
 
    func_search (isAfterTrans = false) {

        if(this.Search.BUName == '')
        {
            this.Search.BUID = '';
        }

        let formData = {
            Module :"GetListTransfer",
            body :{
                Risk_Business_Unit :this.Search.BUID, 
                Risk_Category : this.Search.RiskCate,
                Risk_Status : this.Search.RiskStatus,
                Risk_Name : this.Search.RiskName.trim(),
                Risk_Rating : this.Search.LevelRisk,
                Risk_Running : this.Search.RiskID,
                Risk_Escalation : (this.Search.Escalation === true) ? '1' : '0',
                QuarterID : this.DataSearch.Quarterfrom
            }  
        }
        this.loading = true;
        this.mserve.Risk_Transfer(formData).then((response) => {
            
            if (response.data.status ){
                if (response.data.body.length !== 0) {
                    //console.log('Data : ',response.data.body);
                    this.DataRiskTransfer = response.data.body;
                    this.loading = false;
                } else {
                    this.DataRiskTransfer = response.data.body;
                    this.loading = false;
                    if(isAfterTrans == false)
                    {
                        this.alertflow = "No data found"
                        $('#modalAlertTransger').modal('show');
                    }
                }
            }
            else {
                alert(response.data.errorMessage);
                //console.log('false',response);
                this.loading = false;
            }
        }).catch((e) => {
            this.authserviceToken.CheckTokenExpire(e.response.status)
            });
    }
    func_SelectSearchBU () {
        for (let item_BUDD of this.BUDD){
            if (this.Search.BUName === item_BUDD.text) {
                this.Search.BUID = item_BUDD.value
                break;
            }
        }
    }
    func_Transfer () {
        
        if (this.tempSelected.length == 0) {
            this.alertflow = 'Please select Risk to Transfer'
            $('#modalAlertTransger').modal('show');
        } else {
            this.Transfer.BUName = '';
            this.Transfer.BUID = '';
            this.Transfer.EmpName = '';
            this.Transfer.EmpID = '';
            $('#modalTransfer').modal('show');   
        }
    }
    func_SelectBU () {
        if(this.Transfer.BUName != '')
        {
            this.Transfer.BUID ='';
            for (let item_BUDD2 of this.BUDD2){
                if (this.Transfer.BUName === item_BUDD2.text) {
                    this.Transfer.BUID = item_BUDD2.value
                    break;
                }
            }
            
    
            if(this.Transfer.BUID == null || this.Transfer.BUID == '')
            {
                this.Transfer.BUID = '';
                // this.alertflow = "Business unit not found"
                // $('#modalAlertTransger').modal('show');
            }
            else
            {
                let formData = {
                    Module :"GetCoByDept",
                    body :{
                        Risk_Id :"",
                        Risk_Business_Unit :this.Transfer.BUID,
                        Risk_Register_By :""
                    }
                }
                
                this.mserve.Risk_Transfer(formData).then((response) => {
                    if (response.data.status ){
                        this.EmpDD = response.data.body;
                        if(this.EmpDD.length !== 0) {
                            this.EmpSel.id = this.EmpDD[0].Coordinator_Employee_Id;
                        } else {
                            this.EmpSel.id = '';
                        }
                        this.filteredOptionsEmp = this.myControlEmp.valueChanges.pipe(
                            startWith(''),
                            map(value => this._filterEmp(value))
                        );
        
                    } else {
                        //console.log('false',response)
                    }
                }).catch((e) => {
                    this.authserviceToken.CheckTokenExpire(e.response.status)
                });
            }
        }
    }
    func_SelectEMP () {
        for (let item_EmpDD of this.EmpDD){
            if (this.Transfer.EmpName === item_EmpDD.FullName) {
                this.Transfer.EmpID = item_EmpDD.Coordinator_Employee_Id
                break;
            }
        }
    }
    Confirm_transfer () {
        if(this.Transfer.BUID)
        {
            this.loading = true;
            let formData = {
                SessionEmpID :this.Data_SessionStorage[0].employeE_ID,
                Module :"Update_Risk_Transfer",
                body :{
                    Risk_Id :'', 
                    Risk_Business_Unit :this.Transfer.BUID, 
                    Risk_Register_By :this.EmpSel.id
                }  
            }
    
            for (let i=0; i < this.tempSelected.length; i++) {
                if (i === 0) {
                    formData.body.Risk_Id = this.tempSelected[i].RiskID 
                } else {
                    formData.body.Risk_Id = formData.body.Risk_Id + ',' + this.tempSelected[i].RiskID 
                }
            }
    
            this.mserve.Risk_Transfer(formData).then((response) => {
                if (response.data.status ){
                    $('#modalTransfer').modal('hide');
                    this.tempSelected = [];
                    this.EmpSel.id = '';
                    this.loading = false;
                    this.alertflow = 'Transfer Success !';
                    $('#modalAlertsuccess5').modal('show');
                    this.func_search(true);
                }
                else {
                    this.loading = false;
                    this.alertflow = response.data.errorMessage
                    $('#modalAlertTransger').modal('show');
                    //console.log('false',response);
                }
            }).catch((e) => {
                this.loading = false;
                this.authserviceToken.CheckTokenExpire(e.response.status)
                });
        }
        else
        {
            this.alertflow = "Business unit not found"
            $('#modalAlertTransger').modal('show');
        }
    }

    func_ReveiewPage (item) {
        
        sessionStorage.setItem("RiskID", item.Risk_Id);
        sessionStorage.setItem("menubackto","/TransferRisk");
        if (item.Table_Type === "Staff") {
          sessionStorage.setItem("TranSecRole", 'Staff');
        } else {
          sessionStorage.setItem("TranSecRole", 'Co');
        }
  
        this.router.navigate(['/ReviewPage','ReviewfromTransfer']);  
    }
    Close_Modal () {
        $('#modalTransfer').modal('hide');
    }
    ClearSearch () {
        this.Search.BUName = '';
        this.Search.BUID = '';
        this.Search.RiskCate = '';
        this.Search.RiskStatus = '';
        this.Search.RiskName = '';
        this.Search.LevelRisk = '';
        this.Search.Datefrom = '';
        this.Search.Dateto = '';
        this.Search.RiskID = '';
        this.Search.Escalation = false;
        this.tempSelected = [];

        this.DataSearch.Yearfrom = '';
        this.DataSearch.Yearto = '';
        this.DataSearch.Quarterfrom = '';
        this.DataSearch.Quarterto = '';
        this.loadDDL();
    }
    selectedRisk (item, index) {
        
        const _s = this;
        let e1;
        e1 = (document.getElementsByName( "selected" + index ));

        if (e1[0].checked === true) {
            this.tempSelected.push({
                RiskID :item.Risk_Id,
                RiskIDName :(item.Risk_Co_Id !== null) ? item.Risk_Co_Id : item.Risk_Staff_Id,
                RiskName :item.Risk_Name,
                RiskBU :item.Risk_BusinessUnit_Name,
                RiskLevel :item.Risk_Level
            });
        }
        else {
            for (let i = 0; i < this.tempSelected.length; i++) {
                if (this.tempSelected[i].RiskID ===  item.Risk_Id) {
                    this.tempSelected.splice(i , 1);
                    break;
                }
            }
        }

        //console.log('tempSelected',_s.tempSelected)
    }


    func_selFromyear () {
        
        const _s = this;
        _s.DataSearch.Quarterfrom = ''
        _s.mserve.Master_DDL({ Module:"QuarterDD",TextSearch1: _s.DataSearch.Yearfrom }).then((response) => {
            _s.QuarterDD = response.data.body
            //console.log("QuarterDD : ",_s.QuarterDD);
        });
    }
    func_selToyear () {
        
        const _s = this;
        _s.DataSearch.Quarterto = ''
        _s.mserve.Master_DDL({ Module:"QuarterDD",TextSearch1: _s.DataSearch.Yearto }).then((response) => {
            _s.DDLQuarterto = response.data.body
            //console.log("QuarterTo : ",_s.DDLQuarterto);
        });
    }
    private _filter(value: string): string[] {
        const filterValue = value?.toLowerCase();
        if (filterValue !== '') {
            return this.BUDD.filter(option => option.text?.toLowerCase().includes(filterValue));
        } else {
            return this.BUDD
        }
    }
    private _filter2(value: string): string[] {
        const filterValue = value?.toLowerCase();
        if (filterValue !== '') {
            return this.BUDD2.filter(option => option.text?.toLowerCase().includes(filterValue));
        } else {
            return this.BUDD2
        }
    }

    private _filterEmp(value: string): string[] {
        const filterValue = value?.toLowerCase();
        if (filterValue !== '') {
            return this.EmpDD.filter(option => option.FullName?.toLowerCase().includes(filterValue));
        } else {
            return this.EmpDD
        }
    }
    ngAfterViewInit(): void{
        
        const _s = this;
    }
}