import { Component, OnInit, Input } from '@angular/core';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';


const PrimaryColor = '#023154';
const SecondaryGrey = '#ccc';
const PrimaryRed = '#dd0031';
const SecondaryBlue = '#006ddd';

@Component({
  selector: 'app-loading',
  template: `
  <div class="loading">
    <ngx-loading [show]="loading" [config]="{animationType: ngxLoadingAnimationTypes.circleSwish, primaryColour: primaryColour}"></ngx-loading>
  </div>
  `,
  styles: [``],
  providers: [ NgbModal ]
})

export class LoadingComponent implements OnInit {
  
    @Input('loading') loading: boolean;


    public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
    public primaryColour = PrimaryColor;
    public secondaryColour = SecondaryGrey;
    public coloursEnabled = false;

    ngOnInit() {

    }
  
  }
