import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StrategicTableComponent } from './components/strategic-table/strategic-table.component';
import { StrategyTableComponent } from './components/strategy-table/strategy-table.component';
import { AcceptTableComponent } from './components/accept-table/accept-table.component';
import { LowestScoreDirective } from './directives/LowestScore.directive';
import { ModalAlertComponent } from './components/modal-alert/modal-alert.component';
import { ModalSelectbuComponent } from './components/modal-selectbu/modal-selectbu.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RiskfromStrategyComponent } from './components/riskfrom-strategy/riskfrom-strategy.component';
import { StrategicAssumptionComponent } from './components/strategic-assumption/strategic-assumption.component';
import { StrategyDirective } from './directives/Strategy.directive';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ActionLogComponent } from './components/action-log/action-log.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
@NgModule({
  declarations: [
    StrategicTableComponent,
    StrategyTableComponent,
    AcceptTableComponent,
    LowestScoreDirective,
    ModalAlertComponent,
    ModalSelectbuComponent,
    RiskfromStrategyComponent,
    StrategicAssumptionComponent,
    StrategyDirective,
    ActionLogComponent
  ],
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatInputModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule
  ],
  exports:[
    StrategicTableComponent,
    StrategyTableComponent,
    AcceptTableComponent,
    LowestScoreDirective,
    ModalAlertComponent,
    ModalSelectbuComponent,
    RiskfromStrategyComponent,
    StrategicAssumptionComponent,
    StrategyDirective,
    ActionLogComponent
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ]
})
export class StrategicRisksSharedModule { }
