import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { AppSettingService } from "../app-setting-service";
import { Injectable } from "@angular/core";
import { Configmodel } from "../model/configmodel";

@Injectable()
export class PublicClientApplicationService {
    constructor(public appSettingService:AppSettingService) {}
    configData :Configmodel;
    getData(){
        
         this.configData= this.appSettingService.read();
        return this.configData
    }
 
    config   = this.getData();
    BASE_URL = this.config.api_url;

    configMsalNew = {
        auth: {
          clientId: this.config.clientId,
          authority: this.config.authority,
          redirectUri: this.config.redirect_url,
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: true,
        },
      };
    
      interactionConfig = {
        interactionType: InteractionType.Popup, 
        authRequest: {
          scopes: ['user.read'],
        },
        loginFailedRoute: '/login-failed',
      };
    
      interceptorConfig = {
        interactionType: InteractionType.Popup, 
        protectedResourceMap: new Map<string, Array<string> | null>([
          ['https://graph.microsoft.com/v1.0/me', ['user.read']],
        ]),
      };
      msalInstanceData:any;
      msalConfig = {
        ...this.configMsalNew,
        ...this.interactionConfig,
        ...this.interceptorConfig,
      };
    configdata: any;
    API_URL = {
    getToken_URL: this.BASE_URL + '/api/createToken',
    };

  setNew(){
    this.msalInstanceData = new PublicClientApplication(this.msalConfig); 
    }
    getpublicClientData() {
      return this.msalInstanceData
    }
}
