<div class="container-fluid appform my-3">
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">

            <div class="col-12 col-md-12 col-lg-12 col-xl-12 pb-5 mt-5 mb-5">

                <div class="card mt-4 py-4">

                    <div class="col-12 mb-4">
                        <div class="col-8 col-xl-3 rounded searcrisk" style="text-align: center;">
                            <strong>Search Consolidate</strong>
                        </div>
                        <div class="row col-12 col-xl-12 mt-3 mb-3 mx-0 px-0 d-flex justify-content-around">
                            <div class="row col-12 col-xl-6">
                                <div class="col-12 mb-2 px-0">
                                    <strong class="formtext">Risks by Category</strong>
                                </div>
                                <div class="col-12 px-0">
                                    <select class="form-control" [(ngModel)]="ConsolidateSearch.SearchRiskCategoryby">
                                        <option value="">Please Select</option>
                                        <option *ngFor="let data of Risk_ByCategory" [value]="data.value">{{data.text}} </option>
                                    </select>
                                </div>
                            </div>
                            <div class="row col-12 col-xl-3">
                                <div class="col-12 mb-2 px-0">
                                    <strong class="formtext">Risks Status</strong>
                                </div>
                                <div class="col-12 px-0">
                                    <select class="form-control" [(ngModel)]="ConsolidateSearch.SearchStatus">
                                        <option value="">Please Select</option>
                                        <option value="1">Active</option>
                                        <option value="2">Close (Turn to problem)</option>
                                        <option value="3">Close (Mitigation Completed)</option>
                                        <option value="4">Close (Other)</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row col-12 col-xl-3">
                                <div class="col-12 mb-2 px-0">
                                    <strong class="formtext">Business Unit</strong>
                                </div>
                                <div class="col-12 px-0">
                                    <input type="text" class="form-control m-0" aria-label="Number" matInput [formControl]="myControl" [matAutocomplete]="auto" [(ngModel)]="tempBU" placeholder="Please Select" (keyup.enter)="func_selectBU()">
                                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option.text" (click)="func_selectBU()">
                                        {{option.text}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 mb-4">
                        <div class="row col-12 col-xl-12 mt-3 mb-3 mx-0 px-0 d-flex justify-content-around">
                            <div class="row col-12 col-xl-6">
                                <div class="col-12 mb-2 px-0">
                                    <strong class="formtext">Risk Name</strong>
                                </div>
                                <div class="col-12 px-0">
                                    <input type="text" class="form-control" name="valueRisk_Name" [(ngModel)]="ConsolidateSearch.SearchRiskname">
                                </div>
                            </div>
                            <div class="row col-12 col-xl-3">
                                <div class="col-12 mb-2 px-0">
                                    <strong class="formtext">Requester</strong>
                                </div>
                                <div class="col-12 px-0">
                                    <input type="text" class="form-control m-0" aria-label="Number" matInput [formControl]="myControlEmployeeID" [matAutocomplete]="autoEmp" placeholder="Please Select" [(ngModel)]="tempEmp" (keyup.enter)="func_selectEmp()">
                                    <mat-autocomplete autoActiveFirstOption #autoEmp="matAutocomplete">
                                        <mat-option *ngFor="let option of filteredOptionsEmployeeIDDD | async" [value]="option.text" (click)="func_selectEmp()">
                                        {{option.text}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                            </div>
                            <div class="row col-12 col-xl-3">
                                <div class="col-12 mb-2 px-0">
                                    <strong class="formtext">Risk Level</strong>
                                </div>
                                <div class="col-12 px-0">
                                    <select class="form-control" [(ngModel)]="ConsolidateSearch.SearchHighrisk">
                                        <option value="">Please Select</option>
                                        <option *ngFor="let item of Risk_Level" value="{{ item.value }}">{{ item.text }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 mb-4">
                        <div class="row col-12 col-xl-12 mt-3 mb-3 mx-0 px-0 d-flex justify-content-around">

                            <div class="row col-12 col-xl-6">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12 px-0">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-6 row mx-0 px-0">
                                                        <div class="col-12 mb-2">
                                                            <strong class="formtext">Year</strong>
                                                        </div>
                                                        <div class="col-12 pl-0">
                                                            <select class="form-control" [(ngModel)]="DataSearch.Yearfrom" (change)="func_selFromyear()" disabled>
                                                                <option value="">Please Select</option>
                                                                <option *ngFor="let item of DDLYear" value="{{ item.value }}">{{ item.text }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-6 row mx-0 px-0">
                                                        <div class="col-12 mb-2">
                                                            <strong class="formtext">Quarter</strong>
                                                        </div>
                                                        <div class="col-12 pr-0">
                                                            <select class="form-control" [(ngModel)]="DataSearch.Quarterfrom" (change)="changeQuarter()" disabled>
                                                                <option value="">Please Select</option>
                                                                <option *ngFor="let item of DDLQuarter" value="{{ item.text }}">{{ item.text }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row col-12 col-xl-3">
                                <div class="col-12 mb-2 px-0">
                                    <strong class="formtext">Risks ID</strong>
                                </div>
                                <div class="col-12 px-0">
                                    <input type="text" class="form-control" [(ngModel)]="ConsolidateSearch.SearchRiskID">
                                </div>
                            </div>
                            <div class="row col-12 col-xl-3">
                                <div class="col-12 mb-3 px-0"></div>
                                <div class="col-12 px-0">
                                    <input type="checkbox" name="level" id="chk_Escalationlevel" [(ngModel)]="ConsolidateSearch.SearchEscaLevel">&nbsp;
                                    <label class="formtext" for="chk_Escalationlevel">Escalation level</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" style="text-align: center;">
                        <button type="button" class="btn mr-2" (click)="func_clear()" style="background: #9eb0ba;color: white;font-size: 20px;width: 100px;">Clear</button>
                        <button type="button" class="btn" (click)="func_GetData()" style="background:#023154;color: white;font-size: 20px;width: 100px;">Search</button>
                    </div>
                </div>
                <div class="card mt-4 py-4">
                    <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1">
                        <div class="col-9 rotated" style="text-align: center;">
                        <span>Qualitative (People, Property damage, Project cost & schedule,<br> Legal/Compliance, Enviroment, Image/Reputation)</span>
                        </div>
                        <div class="row col-12 mx-0 px-0">
                        <div class="col-1"></div>
                        <div class="col-7 pr-0" style="text-align: right;" >
                            <div class="mr-2" style="display: inline;">
                            <span style="font-size: 16px;font-weight: 600;">Risk Level :</span>
                            </div>
                            <div class="custom-control custom-checkboxs col-form-label custom-control-inline py-0">
                            <input type="checkbox" id="Low" class="custom-control-input green" checked disabled>
                            <h6 for="Low" class="custom-control-label">Low</h6>
                            </div>
                            <div class="custom-control custom-checkboxs col-form-label custom-control-inline py-0">
                            <input type="checkbox" id="Low" class="custom-control-input yellow" checked disabled>
                            <h6 for="Low" class="custom-control-label">Medium</h6>
                            </div>
                            <div class="custom-control custom-checkboxs col-form-label custom-control-inline py-0">
                            <input type="checkbox" id="Low" class="custom-control-input red" checked disabled>
                            <h6 for="Low" class="custom-control-label">High</h6>
                            </div>
                        </div>
                        <div class="col-4"></div>
                        </div>
                        <div class="row col-12 mx-0 px-0">
                        <div class="col-1 col-xl-1 px-0"></div>
                        <div class="col-7 col-xl-7 pl-0 pr-1">
                            <table class="custom" id="test2">
                                <caption style="display: none;" >This is a custom table</caption>
                                <tr>
                                    <th></th>
                                  </tr>
                            <tr *ngFor="let item1 of  list.risk.risktable; index as index1;">
                                <td *ngFor="let item2 of  item1.col; index as index2;" class="box">
                                <div class="content text-center" class="{{item2.class}}">
                                    <ng-container *ngFor="let item3 of item2.item; index as index3;">            
                                    <span *ngIf="item2.class === 'high'">                                  
                                        <div class="btn-group dropup">
                                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">                                                    
                                            <span class="badge badge-pill badge-light txt-title" (click)="func_Consolidation_RiskMap(item3.RiskId, item3.index)" [ngStyle] = "{'background-color': item3.bgcolor, 'color': item3.color}" ><strong>{{item3.index}}</strong></span>
                                        </div>
                                        </div>
                                    </span>
                                    <span *ngIf="item2.class === 'medium'">                                       
                                        <div class="btn-group dropup">
                                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <span class="badge badge-pill badge-light txt-title" (click)="func_Consolidation_RiskMap(item3.RiskId, item3.index)" [ngStyle] = "{'background-color': item3.bgcolor, 'color': item3.color}"><strong>{{item3.index}}</strong></span>
                                        </div>
                                        </div>
                                    </span>
                                    <span *ngIf="item2.class === 'low'">                                       
                                        <div class="btn-group dropup">
                                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <span class="badge badge-pill badge-light txt-title" (click)="func_Consolidation_RiskMap(item3.RiskId, item3.index)" [ngStyle] = "{'background-color': item3.bgcolor, 'color': item3.color}"><strong>{{item3.index}}</strong></span>
                                        </div>
                                        </div>
                                    </span>
                                    <span *ngIf="item2.class === 'Escalation'">
                                        <div class="btn-group dropup">
                                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <span class="badge badge-pill badge-light txt-title" [ngStyle] = "{'background-color': item3.bgcolor, 'color': item3.color}"><strong>{{item3.index}}</strong></span>
                                        </div>
                                        </div>
                                    </span>
                                    
                                    <div [ngStyle] = "{'background-color': item3.bgcolor , color:item3.color }"  *ngIf="item2.class === 'impact-y'" class="p-2">
                                        <span class="font-size">{{item3.title}}</span>
                                    </div>                                  
                                    <div [ngStyle] = "{'background-color': item3.bgcolor , color:item3.color}" class="p-0" *ngIf="item2.class === 'impact-x'" >
                                        <span>{{item3.title}}</span>
                                    </div>
                                    </ng-container>
                                </div>
                                </td>
                            <tr>
                            </table>
                        </div>
                        <div class="col-4 col-xl-4 pl-0 pr-2 key-risk">
                            <div class="card-body row m-0 p-1 rounded" style="background-color: #3a5f92;">
                            <div class="col-12">
                                <h3 class="pl-2 pt-2" style="color: white;">KEY RISKS</h3>
                            </div>
                            <div class="col-12 scrollbar-key">
                                <ul class="list-group w-100">
                                <li class="border-0 rounded-0 p-2" style="font-size: 18px;color: white;" *ngFor="let item of list.risk.riskitems; index as index">
                                    <div class="row px-0 mx-0 list-item">
                                    <div class="col-1 px-0" (click)="func_ReveiewPage(item.Risk_Id , item.Table_Type)" style="cursor:pointer !important;">
                                        {{ (index+1) + '.' }}
                                    </div> 
                                    <div class="col-11 px-0" (click)="func_ReveiewPage(item.Risk_Id , item.Table_Type)" style="cursor:pointer !important;">
                                        {{ item.BusinessUnit + ': ' + item.Risk_Name }}
                                    </div>
                                    </div>
                                </li>
                                </ul>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>

                <div class="card col-12 mt-4 mx-0 px-3">
                    <div class="col-12 px-0 mb-3">
                        <button type="button" class="btn" style="background:#00adef;color:white;font-size:20px;" (click)="func_Consolidation()"> 
                            <em class="fas fa-paper-plane"></em>&nbsp;&nbsp;Consolidate
                        </button>
                    </div>
                    <div class="col-12 px-0 table-responsive">
                        <table class="table-bordered table-hover" style="width:100%;">
                            <caption style="display: none;" >This is a custom table</caption>
                            <thead style="text-align: center;background-color: #023154;color:white;font-size: 22px;">
                                <tr>
                                    <th style="width: 10px;vertical-align: middle;">View</th>
                                    <th style="width: 50px;">
                                        <label for="chkselectall"><strong>Select All</strong></label><br>
                                        <input type="checkbox" id="chkselectall" [(ngModel)]="valuecheckSelectAll" (change)="SelectAll(valuecheckSelectAll)" style="zoom:1.5;">
                                    </th>
                                    <th style="width: 120px;vertical-align: middle;">Risk ID</th>
                                    <th style="width: 250px;vertical-align: middle;">Risk Name</th>
                                    <th style="width: 120px;vertical-align: middle;">Risk Level</th>
                                    <th style="width: 120px;vertical-align: middle;">Risk Type</th>
                                    <th style="width: 120px;vertical-align: middle;">Business Unit</th>
                                </tr>
                            </thead>
                            <tbody style="background-color: white;font-size: 20px;vertical-align: top;">
                                <tr *ngFor="let data of table_ConsolidateRisk | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize index as index ">
                                    <td style="width: 10px;" style="text-align: center;">
                                        <button type="button" class="btn btn-sm" (click)="func_ReveiewPage(data.Risk_Id , data.Table_Type)">
                                            <em class="fas fa-search" style="color:#6c757d"></em>
                                        </button>
                                    </td>
                                    <td style="text-align: center;">
                                        <input type="checkbox" name="selected{{ index+1 }}" (change)="Selected(data.Risk_Id , index+1)" style="zoom:1.5;" [disabled]="data.Risk_ViewMode === 'Y'">
                                    </td>
                                    <td style="width: 120px;">
                                        <span *ngIf="data.Table_Type === 'Staff'; else elseBlock">{{ data.Risk_Staff_Id }}</span>
                                        <ng-template #elseBlock>{{ data.Risk_Co_Id }}</ng-template>
                                    </td>
                                    <td style="width: 250px;">{{ data.Risk_Name }}</td>
                                    <td style="width: 120px;" style="text-align: center;">{{ data.Risk_Rating }}</td>
                                    <td style="width: 120px;" >
                                        <span *ngIf="data.WPBID !== null; else elseBlock3">WPB</span>
                                        <ng-template #elseBlock3>Normal</ng-template>
                                    </td>
                                    <td style="width: 120px;">{{ data.Abbreviation }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="pagination mt-4">
                        <ngb-pagination 
                            [collectionSize]="table_ConsolidateRisk.length" 
                            [(page)]="page" 
                            [pageSize]="pageSize"
                            [maxSize]="5"
                            [rotate]="true"
                            [ellipses]="false" 
                            [boundaryLinks]="true">
                            <ng-template ngbPaginationPrevious>Prev</ng-template>
                            <ng-template ngbPaginationNext>Next</ng-template>
                        </ngb-pagination>
                    </div>
                </div>

            </div>
            <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalConfirmWarning">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <span class="bg-warning">&nbsp;</span>
                        <div class="modal-header">
                            <div class="row col-12 text-warning">
                                <div class="col-2">
                                <em class="fas fa-exclamation-triangle" style="font-size:50px;"></em>
                                </div>
                                <div class="col-10 pt-2">
                                    <h4 class="modal-title">Alert !</h4>
                                </div>
                            </div>
                        </div>
                        <div class="modal-body text-body border-0">
                            <div class="col-12" style="text-align: left;">
                                <span style="font-size:18px;">Are you sure you want to consolidate this item?</span>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-warning w-25 text-white" (click)="ConfirmConsolidate()">Yes</button>
                            <button type="button" class="btn btn-secondary w-25" (click)="closeconsolidate()">No</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalAlertdanger5">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <span class="bg-danger">&nbsp;</span>
                        <div class="modal-header">
                            <div class="row col-12 text-danger">
                                <div class="col-2">
                                    <em class="far fa-times-circle" style="font-size:50px;"></em>
                                </div>
                                <div class="col-10 pt-2">
                                    <h4 class="modal-title">Alert !</h4>
                                </div>
                            </div>
                        </div>
                        <div class="modal-body text-body border-0">
                            <div class="col-12" style="text-align: left;">
                                <span style="font-size:16px;">{{ alertflow }}</span>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger w-25" (click)="closealertdanger()">OK</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalAlertsuccess5">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <span class="bg-success">&nbsp;</span>
                        <div class="modal-header">
                            <div class="row col-12 text-success">
                                <div class="col-2">
                                    <em class="far fa-check-circle" style="font-size:50px;"></em>
                                </div>
                                <div class="col-10 pt-2">
                                    <h4 class="modal-title">Alert !</h4>
                                </div>
                            </div>
                        </div>
                        <div class="modal-body text-body border-0">
                            <div class="col-12" style="text-align: left;">
                                <span style="font-size:16px;">{{ alertflow }}</span>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-success w-25" (click)="closeflow()">OK</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalVersioning">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <span class="bg-info">&nbsp;</span>
                        <div class="modal-header">
                            <div class="row col-12 text-info">
                                <div class="col-10 pt-2">
                                    <h4 class="modal-title">Version</h4>
                                </div>
                            </div>
                        </div>
                        <div class="modal-body text-body border-0">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-4 pt-2">
                                    <strong style="font-size:18px;">Year</strong>
                                    </div>
                                    <div class="col-8 px-0">
                                    <select class="form-control" [(ngModel)]="VersionYear" (change)="func_getQuarterVersion()">
                                        <option value="">Please Select</option>
                                        <option *ngFor="let item of DDLYear" value="{{ item.value }}">{{ item.text }}</option>
                                    </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mt-2">
                            <div class="row">
                                <div class="col-4 pt-2">
                                    <strong style="font-size:18px;">Quarter</strong>
                                </div>
                                <div class="col-8 px-0">
                                    <select class="form-control" [(ngModel)]="VersionQuarter" [disabled]="VersionYear == ''">
                                    <option value="">Please Select</option>
                                    <option *ngFor="let item of DDLQuarter" value="{{ item.value }}">{{ item.text }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info w-25 text-white" (click)="func_okversion()">Ok</button>
                            <button type="button" class="btn btn-secondary w-25" data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading [loading]='loading'></app-loading>