<div class="container-fluid appform my-3">
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">
            <div class="col-12 col-md-12 col-lg-12 col-xl-12 pb-5 mt-5 mb-5">
                <div class="px-0 pb-2">
                    <div class="card col-12 mt-4 mx-0 px-3">

                        <div class="coordinator col-12 mb-3 rounded">
                            <div class="col-0 pt-2">
                                <em class="fas fa-bars icon"></em>&nbsp;&nbsp;Risk Coordinators
                            </div>
                        </div>                      
                        <div class="col-12 mb-4" style="text-align: right;">
                            <button type="button" class="btn btn-lg" style="color:white;background:green;" (click)="func_importEx()">
                                <em class="fas fa-plus icon"></em>&nbsp;&nbsp;&nbsp;Import Excel
                            </button>
                           
                        </div>                     
                        <div class="col-12 px-0 mb-3">                           
                            <ul class="nav nav-pills mb-1" id="pills-tab" role="tablist">
                                <li class="nav-item" style="margin: 1px 1px 1px 0px;" *ngFor="let item of DataFG index as index1">
                                    <a class="nav-link" data-toggle="pill" id="BUCO{{ index1 + 1 }}" href="#" role="tab" (click)="SelectFG(item , index1)" *ngIf="index1 === 0">{{ item.title }}</a>
                                    <a class="nav-link" data-toggle="pill" id="BUCO{{ index1 + 1 }}" href="#" role="tab" (click)="SelectFG(item , index1)" *ngIf="index1 !== 0">{{ item.title }}</a>
                                </li>
                            </ul>                      
                         
                            <table class="table-bordered" style="width:100%;">
                                <caption style="display: none;">This is a custom table1</caption>
                                <thead style="text-align:center; background-color:#033153; color:white;">
                                    <tr>
                                        <th style="width: 80px;vertical-align: middle;">FG</th>
                                        <th style="width: 80px;vertical-align: middle;">DIV</th>
                                        <th style="width: 80px;vertical-align: middle;">DEPT</th>
                                        <th style="width: 200px;vertical-align: middle;">Risk Coordinator</th>
                                        <th style="width: 10px;vertical-align: middle;">Edit</th>
                                    </tr>
                                </thead>
                                <tbody style="background-color:white;">
                                    <tr *ngFor="let item of OptimizedData index as index1">
                                        <td style="text-align: center;">{{ item.FG }}</td>
                                        <td style="text-align: center;">{{ item.DIV }}</td>
                                        <td style="text-align: center;">{{ item.DEPT }}</td>
                                        <td style="text-align: center;">
                                            <span *ngFor="let item2 of item.CO">{{ item2.coname }}<br></span>
                                        </td>
                                        <td style="text-align: center;">
                                            <button type="button" class="btn" (click)="func_addbuco(item,'Edit')" *ngIf="tempchkyear == currentyear">
                                                <em class="fas fa-edit" style="color:orange;font-size:18px;"></em>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="modal fade bd-Risk Coordinators" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" id="modaladdcoordinator">
                    <div class="modal-dialog modal-xl">
                        <div class="modal-content">
                            <div class="container-fluid appform my-3">
                                <div class="row">
                                    <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">
                                        <div class="container col-12 col-md-12 col-lg-12 col-xl-12 pb-0 mt-0 mb-0 ">
                                            <div class="container">
                                                <div class="col-12 mb-3 rounded madalcoordinator">
                                                    <div class="col-0 pt-2">
                                                        <em class="fas fa-bars icon"></em>&nbsp;&nbsp;Business Coordinators1
                                                    </div>
                                                </div>
                                                <div class="card mt-4">
                                                    <div class="row col-12 col-xl-12 mb-4 px-0 mx-0">
                                                        <div class="row col-12 mb-4 mx-0">
                                                            <div class="row col-12 col-xl-4 mx-0">
                                                                <div class="col-12 mb-2">
                                                                    <strong class="formtext">Year</strong>
                                                                </div>
                                                                <div class="col-12">
                                                                    <select class="form-control" [(ngModel)]="model.Year" (change)="SelectedYear('')" disabled>
                                                                        <option value="">Please Select</option>
                                                                        <option *ngFor="let data of YearDD" [value]="data.text" [disabled]="data.text != currentyear">{{data.text}}</option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div class="row col-12 col-xl-4 mx-0">
                                                                <div class="col-12 mb-2">
                                                                    <strong class="formtext">Quarter</strong>
                                                                </div>
                                                                <div class="col-12">
                                                                    <select class="form-control" [(ngModel)]="model.Quarter" disabled>
                                                                        <option value="">Please Select</option>
                                                                        <option *ngFor="let data of AddCo_QuarterDD" [value]="data.value">{{data.text}}</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row col-12 mb-4 mx-0">
                                                            
                                                            <div class="row col-12 col-xl-4 mx-0">
                                                                <div class="col-12 mb-2">
                                                                    <strong class="formtext">Business Unit ID</strong>
                                                                </div>
                                                                <div class="col-12">
                                                                    <input type="text" class="form-control m-0" [(ngModel)]="model.CoorBUID" disabled>
                                                                </div>
                                                            </div>

                                                            <div class="row col-12 col-xl-4 mx-0">
                                                                <div class="col-12 mb-2">
                                                                    <strong class="formtext">Business Unit Name</strong>
                                                                </div>
                                                                <div class="col-12">
                                                                    <input type="text" class="form-control m-0" aria-label="Number" matInput [formControl]="myControlBUDD" [matAutocomplete]="auto2" [(ngModel)]="model.CoorBU" (keyup.enter)="func_selectCoorBU(model.CoorBU)">
                                                                    <mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete">
                                                                        <mat-option *ngFor="let option of filteredOptionsBUDD | async" [value]="option.text" (click)="func_selectCoorBU(option.text)">
                                                                        {{option.text}}
                                                                        </mat-option>
                                                                    </mat-autocomplete>
                                                                </div>
                                                            </div>

                                                            <div class="row col-12 col-xl-4 mx-0">
                                                                <div class="col-12 mb-2">
                                                                    <strong class="formtext">Business Unit Level</strong>
                                                                </div>
                                                                <div class="col-12">
                                                                    <input type="text" class="form-control m-0" [(ngModel)]="model.CoorBULevel" disabled>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        
                                                        <div class="col-12 px-1 table-responsive">
                                                            <table class="table-bordered" style="width:100%;">
                                                                <caption style="display: none;">This is a custom table2</caption>
                                                                <thead style="text-align:center; background-color:#033153; color:white;">
                                                                    <tr>
                                                                        <th style="width: 80px;vertical-align: middle;">No.</th>
                                                                        <th style="width: 80px;vertical-align: middle;">Employee ID</th>
                                                                        <th style="width: 80px;vertical-align: middle;">Name</th>
                                                                        <th style="width: 200px;vertical-align: middle;">Email</th>
                                                                        <th style="width: 10px;vertical-align: middle;">Business Unit</th>
                                                                        <th style="width: 10px;vertical-align: middle;">Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody style="background-color:white;">
                                                                    <tr *ngFor="let item of TempdataEmployee index as index1">
                                                                        <td style="text-align: center;">
                                                                            <label>{{ index1 + 1 }}</label>
                                                                        </td>
                                                                        <td>
                                                                            <label>{{ item.Emp_id }}</label>
                                                                        </td>
                                                                        <td>
                                                                            <label>{{ item.Emp_name }}</label>
                                                                        </td>
                                                                        <td style="text-align: center;">
                                                                            <label>{{ item.Emp_email }}</label>
                                                                        </td>
                                                                        <td style="text-align: center;">
                                                                            <label>{{ item.Emp_bu }}</label>
                                                                        </td>
                                                                        <td style="text-align: center;">
                                                                            <button type="button" class="btn" (click)="Remove_emp(item)">
                                                                                <em class="far fa-trash-alt" style="color:red;font-size:18px;"></em>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                               
                                                                    <tr>
                                                                        <td style="text-align: center;"></td>
                                                                        <td>
                                                                            <input type="text" class="form-control" name="" [(ngModel)]="model.EmpID" disabled>
                                                                        </td>
                                                                        <td>
                                                                            <input type="text" class="form-control m-0" aria-label="Number" matInput [formControl]="myControlEmployeeID" [matAutocomplete]="auto1" [(ngModel)]="model.EmpName" (keyup.enter)="func_selectEmp(model.EmpName)">
                                                                            <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete">
                                                                                <mat-option *ngFor="let option of filteredOptionsEmployeeIDDD | async" [value]="option.text" (click)="func_selectEmp(option.text)">
                                                                                {{option.text}}
                                                                                </mat-option>
                                                                            </mat-autocomplete>
                                                                        </td>
                                                                        <td style="text-align: center;">
                                                                            <input type="text" class="form-control" name="" [(ngModel)]="model.EmpEmail" disabled>
                                                                        </td>
                                                                        <td style="text-align: center;">
                                                                            <input type="text" class="form-control" name="" [(ngModel)]="model.EmpBU" disabled>
                                                                        </td>
                                                                        <td style="text-align: center;">
                                                                   
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                      

                                                    </div>
                                                </div>
                                               
                                                <div class="col-12 my-3 text-center">
                                                    <button type="button" class="btn mr-3" style="width:100px;background-color:#9eb0ba;color:white;" (click)="func_closebtn()">Close</button>
                                                    <button type="button" class="btn" style="width:100px;background-color:#033153;color:white;" (click)="func_savebtn()">Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade bd-Risk Coordinators" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" id="modalimportexcel">
                    <div class="modal-dialog modal-xl">
                        <div class="modal-content">
                            <div class="container-fluid appform my-3">
                                <div class="row">
                                    <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">
                                        <div class="container col-12 col-md-12 col-lg-12 col-xl-12 pb-0 mt-0 mb-0 ">
                                            <div class="container">
                                                <div class="col-12 mb-3 rounded madalcoordinator">
                                                    <div class="col-0 pt-2">
                                                        <em class="fas fa-bars icon"></em>&nbsp;&nbsp;
                                                        Business Coordinators2
                                                    </div>
                                                </div>
                                            
                                                <div class="card mt-4">
                                                    <div class="col-12 col-xl-12 mb-4 px-0">
                                                       
                                                         <div class="row col-12 mb-4">
                                                            <div class="row col-12 col-xl-12">
                                                                <div class="col-5">
                                                                    <input type="file" (change)="onFileChange($event)" #inputFile />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row col-12 mb-4">
                                                            <div class="row col-12 col-xl-12">
                                                                <div class="col-5">
                                                               
                                                                    <button type="button" class="btn mr-3" style="color:white;background:green;" (click)="func_getdataExport()">Template Excel</button>
                                                                    <button type="button" class="btn btn-danger" (click)="removeData()">Remove Data</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                          
                                                <div class="table-coordinator">
                                                    <table class="table table-bordered">
                                                        <caption style="display: none;">This is a custom table3</caption>
                                                        <thead style="text-align: center;">
                                                            <tr style="background-color:#033153;color:white;">
                                                                <th *ngFor="let key of keys">
                                                                    {{key}}
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody style="background-color: white;">
                                                         
                                                            <tr *ngFor="let item of ExJson">
                                                                <td>
                                                                    {{item.BU_ID}}
                                                                </td>
                                                                <td>
                                                                    {{item.BU_NAME}}
                                                                </td>
                                                                <td>
                                                                    {{item.CO_EMAIL}}
                                                                </td>
                                                                <td>
                                                                    {{item.CO_NAME}}
                                                                </td>
                                                            </tr> 
                                                        </tbody>
                                                    </table>
                                                </div>
                                               
                                                <div class="col-12 my-3 text-center">
                                                    <button type="button" class="btn mr-3" style="width:100px;background-color:#9eb0ba;color:white;" (click)="func_closeImportEx()">Close</button>
                                                    <button type="button" class="btn" style="width:100px;background-color:#033153;color:white;" (click)="importexcel()">Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade bd-Risk Coordinators" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" id="modalDeleteCo">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="container-fluid appform my-3">
                                <div class="row">
                                    <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">
                                        <div class="container col-12 col-md-12 col-lg-12 col-xl-12 pb-0 mt-0 mb-0 ">
                                            <div class="container">
                                                <div class="col-12 mb-3 rounded madalcoordinator">
                                                    <div class="col-0 pt-2">
                                                        <em class="fas fa-bars icon"></em>&nbsp;&nbsp;Replace Coordinators
                                                    </div>
                                                </div>
                                                
                                                <div class="card mt-4">
                                                    <div class="col-12 col-xl-12 mb-4">
                                                        <div class="row">
                                                           
                                                            <div class="col-12 col-xl-6 mb-2">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <strong class="formtext">Employee ID</strong>
                                                                    </div>
                                                                    <div class="col-12">
                                                                        <input type="text" class="form-control" [(ngModel)]="ReplaceEmp.EmpID" disabled>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                           
                                                            <div class="col-12 col-xl-6 mb-2">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <strong class="formtext">Name</strong>
                                                                    </div>
                                                                    <div class="col-12">
                                                                        <input type="text" class="form-control m-0" aria-label="Number" matInput [formControl]="myControlEmployeeReplace" [matAutocomplete]="auto4" [(ngModel)]="ReplaceEmp.EmpName" (keyup.enter)="func_selectReplaceEmp(ReplaceEmp.EmpName)">
                                                                        <mat-autocomplete autoActiveFirstOption #auto4="matAutocomplete">
                                                                            <mat-option *ngFor="let option of filteredOptionsEmployeeIDDDReplace | async" [value]="option.Emp_name" (click)="func_selectReplaceEmp(option.Emp_name)">
                                                                            {{option.Emp_name}}
                                                                            </mat-option>
                                                                        </mat-autocomplete>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                           
                                                            <div class="col-12 col-xl-6">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <strong class="formtext">Email</strong>
                                                                    </div>
                                                                    <div class="col-12">
                                                                        <input type="text" class="form-control" [(ngModel)]="ReplaceEmp.EmpEmail" disabled>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                            <div class="col-12 col-xl-6">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <strong class="formtext">Business Unit</strong>
                                                                    </div>
                                                                    <div class="col-12">
                                                                        <input type="text" class="form-control" [(ngModel)]="ReplaceEmp.EmpBU" disabled>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                               
                                                <div class="col-12 my-3 text-center">
                                                    <button type="button" class="btn mr-3" style="width:100px;background-color:#9eb0ba;color:white;" (click)="close_remove_emp()">Back</button>
                                                    <button type="button" class="btn" style="width:100px;background-color:#033153;color:white;" (click)="confirm_replace()">Confirm</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-loading [loading]='loading'></app-loading>