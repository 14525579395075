import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { MonitorAndReviewService } from 'src/app/StrategicRisks/services/monitorandreview.service';
import { StrategicRiskService } from 'src/app/StrategicRisks/services/strategicrisk.service';
import { RiskfromStrategyComponent } from 'src/app/StrategicRisks/shared/components/riskfrom-strategy/riskfrom-strategy.component';
import { StrategicAssumptionComponent } from 'src/app/StrategicRisks/shared/components/strategic-assumption/strategic-assumption.component';
import { ActionLogModel } from 'src/app/StrategicRisks/shared/model/ActionLogModel';
declare const $;
@Component({
  selector: 'app-register-summary',
  templateUrl: './register-summary.component.html',
  styleUrls: ['./register-summary.component.css']
})
export class RegisterSummaryComponent implements OnInit {
  @ViewChild(RiskfromStrategyComponent)  RiskfromStrategy: RiskfromStrategyComponent;
  @ViewChild(StrategicAssumptionComponent) StrategicAssumption: StrategicAssumptionComponent;

  Data_SessionStorage: any;
  empid;
  riskId:string = '';
  isLoadingAll: boolean;
  data;
  checkform:boolean=false;
  titleAlert: string = '';
  alertflow: string = '';
  module: string = '';
  getDataBU: string = '';
  actionLogLst: ActionLogModel[] = [];
  
  constructor(public strategicService: StrategicRiskService,
    private router: Router,
    public monitorService: MonitorAndReviewService,
    private routerParam: ActivatedRoute
    ) { }

  ngOnInit(): void {
    this.Data_SessionStorage = JSON.parse(sessionStorage.datainfo);
    this.empid = this.Data_SessionStorage[0].employeE_ID;
    this.getDataBU = sessionStorage.getItem('buCode');
    this.riskId = this.routerParam.snapshot.paramMap.get('Id');
    this.getReview(this.riskId);
    this.actionLogLst = [];
  }

  mainForm = new FormGroup({
    riskId: new FormControl(null),
    riskName: new FormControl(null, Validators.required),
    riskStartDate: new FormControl(null, Validators.required),
    riskEndDate: new FormControl(null, Validators.required),
    noEndDate: new FormControl(false)
  })


  getReview(param){
    this.isLoadingAll = true;
    this.monitorService.GetReviewById(param).then((response) => {
      if (response.data.status ){
         this.data=response.data.body;
         this.mainForm.patchValue(response.data.body);
         this.mainForm.controls.noEndDate.setValue(response.data.body.no_End_Date)

         if(this.mainForm.controls.noEndDate.getRawValue())
         {
           this.mainForm.controls.riskEndDate.setValue(null);
           this.mainForm.controls.riskEndDate.disable();
         }
         this.isLoadingAll = false;

        } else {
          this.isLoadingAll = false;
          alert(response.data.errorMessage);
          
        }
  }).catch((e) => {
    this.isLoadingAll = false;
    });
  }

  onNoEndDateChange(event)
  {
    if(event.target.checked)
    {
      this.mainForm.controls.riskEndDate.setValue(null);
      this.mainForm.controls.riskEndDate.disable();
    }
    else
    {
      this.mainForm.controls.riskEndDate.enable();
    }
  }

  confirmRegister()
  {
    this.save('Completed Assessment', '/Monitor-Review');
  }

  backRegister()
  {
    this.save('Draft', '/Register-Assessment');
  }

  backMonitor()
  {
    this.router.navigate(['/Monitor-Review']);
  }

  buildActionLog(section){
    let logData = new ActionLogModel;
    logData.riskId = Number(this.mainForm.controls.riskId.getRawValue()) ?? null;
    logData.createDate = (new Date()).toLocaleString('en-US', { timeZone: 'Asia/Bangkok' });
    
    logData.createBy = this.empid;
    if(logData.riskId)
    {
      if(section == 'Register')
      {
        logData.action = section;
      }
      else
      {
        logData.section = section;
        logData.action = 'Update';
      }
    }
    return logData
  }

  childAddLogEvent(event) {
    let childLog = this.buildActionLog(event);
    let indexlog = this.actionLogLst.findIndex(w => w.section == event);
    if(indexlog != -1)
    {
      this.actionLogLst[indexlog].createDate = (new Date()).toLocaleString('en-US', { timeZone: 'Asia/Bangkok' });
    }
    else
    {
      this.actionLogLst.push(childLog);
    }
  }


  save(status, redirectURL){
    let item1:{status:boolean,message:string} =this.RiskfromStrategy.getform();
    let item2:{status:boolean,message:string} =this.StrategicAssumption.getform();
    if(item1.status && item2.status)
    {
      this.isLoadingAll = true;
      let startDate = new Date(this.mainForm.controls.riskStartDate.getRawValue())
      
      this.data=this.RiskfromStrategy.getData(this.data);
      this.data=this.StrategicAssumption.getData(this.data);
      this.data.riskName = this.mainForm.controls.riskName.getRawValue();
      this.data.riskStartDate = moment(startDate).format("YYYY-MM-DD");
      this.data.riskEndDate = null;
      this.data.no_End_Date = this.mainForm.controls.noEndDate.getRawValue();
      this.data.riskStatus = status;
      
      if(this.mainForm.controls.riskEndDate.getRawValue())
      {
        let endDate = new Date(this.mainForm.controls.riskEndDate.getRawValue())
        this.data.riskEndDate = moment(endDate).format("YYYY-MM-DD");
      }

      if(status == "Completed Assessment")
      {
        this.actionLogLst.push(this.buildActionLog("Register"));
      }

      this.data.strategicRiskLogList = this.actionLogLst;
      
      this.monitorService.ConfirmReview(this.data).then((response) => {
        if (response.data.status ){
          if(redirectURL.includes('Register'))
          {
            this.isLoadingAll = false;
            this.router.navigate(['/Register-Assessment', this.riskId]);
          }
          else if(this.module == 'ConfirmRegister' && redirectURL.includes('Monitor'))
          {
            this.isLoadingAll = false;
            this.alertflow = 'Register Success';
            $('#modalSuccess').modal('show');
          }
          else if(this.module == 'BacktoMonitorReview' && redirectURL.includes('Monitor'))
          {
            this.isLoadingAll = false;
            this.router.navigate(['/Monitor-Review']);
          }
        } else {
          this.isLoadingAll = false;
          alert(response.data.errorMessage);
        }
      }).catch((e) => {
        this.isLoadingAll = false;
        //console.log("error: ", e)
      });
    }
    else
    {
      let itemMessage ='';
            if(!item1.status){
              itemMessage= item1.message;
            }
            else if(!item2.status){
              itemMessage= item2.message;
            }
              this.titleAlert = 'Alert!'
              this.alertflow = itemMessage;
              $('#modalAlert').modal('show');   
    }
  
  }

  alertFunc(value) {
      this.module = value;
      if(value == 'BacktoMonitorReview')
      {
        this.titleAlert = 'Alert!'
        this.alertflow = 'All enter or edit information will be lost. Are you sure you want to close ?'
        $('#modalConfirm').modal('show');
      }
      else 
      {
        let item1:{status:boolean,message:string} =this.RiskfromStrategy.getform();
        let item2:{status:boolean,message:string} =this.StrategicAssumption.getform();
        if(item1.status && item2.status){
          if(value == 'ConfirmRegister')
          {
            this.titleAlert = 'Alert!'
            this.alertflow = 'Confirm to Register this Strategy ?'
            $('#modalConfirm').modal('show');
          }
          else
          {
            this.titleAlert = 'Alert!'
            this.alertflow = 'Do you want to save before Back to Register ?'
            $('#modalConfirm').modal('show');
          }

        }else{
          let itemMessage ='';
            if(!item1.status){
              itemMessage= item1.message;
            }
            else if(!item2.status){
              itemMessage= item2.message;
            }
              this.titleAlert = 'Alert!'
              this.alertflow = itemMessage;
              $('#modalAlert').modal('show');   
            }
      }


  }

  confirmedFunc()
  {

    if(this.module == 'ConfirmRegister')
    {
      this.confirmRegister();
    }
    else if(this.module == 'BacktoRegister')
    {
      this.backRegister();
    }
    else if(this.module == 'BacktoMonitorReview')
    {
      this.backMonitor();
    }

  }

  successFunc()
  {
    this.router.navigate(['/Monitor-Review']);
  }



}
