<div class="container-fluid appform my-3 px-0">
  <div style="min-width: 1880px;">
    <div class="col-12 py-3">
      <div class="card col-12 mb-0 mt-2 py-3">
        <div class="row col-12">
          <div class="row col-12 mx-0 px-0">
            <div class="row col-3 mx-0 px-0 rounded Compareheader">
              <div class="col-2">
                <em class="fas fa-chart-line" style="font-size:30px;padding-top: 10px;"></em>
              </div>
              <div class="col-10" style="padding-top: 7px;">
                <strong>Compare Criteria</strong>
              </div>
            </div>
            <div class="col-9"></div>
          </div>
          <div class="row col-12 mx-0 px-0 mt-4">
            <div class="row col-3">
              <div class="col-12">
                <strong class="filterName">Business Unit :</strong>
              </div>
              <div class="col-12">
                <input type="text" class="form-control m-0" aria-label="Number" matInput [formControl]="myControl"
                  [matAutocomplete]="auto" placeholder="Please Select" [(ngModel)]="tempbu"
                  (keyup.enter)="func_selBU()">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                  <mat-option *ngFor="let option of filteredOptions | async" [value]="option.text"
                    (click)="func_selBU()">
                    {{option.text}}
                  </mat-option>
                </mat-autocomplete>
              </div>
            </div>
            <div class="row col-3">
              <div class="col-12">
                <strong class="filterName">Risk Quarterly :</strong>
              </div>
              <div class="col-6">
                <select class="form-control" [(ngModel)]="Search.YearHQ1" (change)="func_GetQuarter()">
                  <option value="">Please Select</option>
                  <option *ngFor="let item of YearDD" value="{{ item.value }}">{{ item.text }}</option>
                </select>
              </div>
              <div class="col-6">
                <select class="form-control" [(ngModel)]="Search.QuarterHQ1" [disabled]="Search.YearHQ1 === ''">
                  <option value="">Please Select</option>
                  <option *ngFor="let item of QuarterDD" value="{{ item.text }}">{{ item.text }}</option>
                </select>
              </div>
            </div>

            <div class="row col-4">
              <div class="col-12">&nbsp;</div>
              <div class="col-3">
                <strong class="filterName">Compare :</strong>
              </div>
              <div class="col-4">
                <select class="form-control" [(ngModel)]="Search.YearHQ2" (change)="func_GetQuarter2()">
                  <option value="">Please Select</option>
                  <option *ngFor="let item of YearDD" value="{{ item.value }}">{{ item.text }}</option>
                </select>
              </div>
              <div class="col-4">
                <select class="form-control" [(ngModel)]="Search.QuarterHQ2" [disabled]="Search.YearHQ2 === ''">
                  <option value="">Please Select</option>
                  <option *ngFor="let item of QuarterDD2" value="{{ item.text }}">{{ item.text }}</option>
                </select>
              </div>
            </div>

            <div class="row col-2">
              <div class="col-12">
                <strong class="filterName">Risk Level :</strong>
              </div>
              <div class="col-12">
                <select class="form-control" [(ngModel)]="Search.RiskLevel">
                  <option value="">Please Select</option>
                  <option *ngFor="let item of Risk_Level" value="{{ item.value }}">{{ item.text }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row col-12 mx-0 px-0 mt-4 justify-content-end">
            <div class="row col-3">
              <div class="col-1 pt-2">
                <input type="checkbox" id="Escalation" [(ngModel)]="Search.Escalation">
              </div>
              <div class="col-10">
                <label for="Escalation" class="filterName"><strong>Escalation Level</strong></label>
              </div>
            </div>

          </div>

        </div>

        <div class="col-12 mt-5 text-center">
          <button type="button" class="btn mr-2 filterName" style="background:#9eb0ba;color: white;width:120px;"
            (click)="func_Clear()">Clear</button>
          <button type="button" class="btn filterName" style="background:#023154;color: white;width:120px;"
            (click)="func_GETDATA_RiskRating('Search')">Compare</button>
        </div>

      </div>

    </div>

    <div class="card rounded-0">
      <div class="row col-12 mx-0 px-0">
        <div class="col-3 mt-2 px-1">
          <div class="col-12 pt-2" style="background-color:#033153;color:white;height:50px;">
            <strong style="font-size:22px;">Quarter: {{ Search.YearHQ1 }} - {{ Search.QuarterHQ1 }}</strong>
          </div>
          <div class="col-12" style="background-color: #3a5f92;">
            <div class="card-body row m-0 p-1">
              <div class="col-12">
                <h3 class="pl-2 pt-2" style="color: white;">KEY RISKS</h3>
              </div>
              <div class="col-12 px-0 scrollbar-key">
                <ul class="list-group w-100">
                  <li class="border-0 rounded-0 p-2" style="font-size: 18px;color: white;"
                    *ngFor="let item of list.risk.riskitems; index as index">
                    <div class="row px-0 mx-0 list-item">
                      <div class="col-1 px-0">
                        <input type="checkbox" id="chk{{ (index + 1) }}" checked
                          (click)="func_changeCompare((index + 1),  item.Risk_Id)">
                      </div>
                      <div class="col-1 px-0">
                        {{ (index+1) + '.' }}
                      </div>
                      <div class="col-10 px-0">
                        <label (click)="Reviewpage(item)" style="cursor:pointer;">{{ item.Risk_BusinessUnit_Name + ': '
                          + item.Risk_Name + " " + item.Risk_Co_Id }}</label>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 mt-2 px-1">
          <div class="col-12 pt-2 rounded" style="background-color:#033153;color:white;height:50px;">
            <strong style="font-size:22px;">Residual Risk map</strong>
          </div>

          <div class="col-12" style="text-align: right;">
            <div class="mr-2" style="display: inline;">
              <span style="font-size: 16px;font-weight: 600;">Risk Level :</span>
            </div>
            <div class="custom-control custom-checkboxs col-form-label custom-control-inline py-0">
              <input type="checkbox" id="Low" class="custom-control-input green" checked disabled>
              <h6 for="Low" class="custom-control-label">Low</h6>
            </div>
            <div class="custom-control custom-checkboxs col-form-label custom-control-inline py-0">
              <input type="checkbox" id="Low" class="custom-control-input yellow" checked disabled>
              <h6 for="Low" class="custom-control-label">Medium</h6>
            </div>
            <div class="custom-control custom-checkboxs col-form-label custom-control-inline py-0">
              <input type="checkbox" id="Low" class="custom-control-input red" checked disabled>
              <h6 for="Low" class="custom-control-label">High</h6>
            </div>
          </div>

          <div class="row col-12 mx-0 px-0">
            <div class="col-12 rotated" style="text-align: center;">
              <span>Qualitative (People, Property damage, Project cost & schedule,<br> Legal/Compliance, Enviroment,
                Image/Reputation)</span>
            </div>
            <div class="col-1"></div>
            <div class="col-11">
              <table class="custom" id="test2">
                <caption style="display: none;" >This is a custom table</caption>
                <tr>
                  <th></th>
                </tr>
                <tr *ngFor="let item1 of  list.risk.risktable; index as index1;">
                  <td *ngFor="let item2 of  item1.col; index as index2;" class="box">
                    <div class="content text-center" class="{{item2.class}}">
                      <ng-container *ngFor="let item3 of item2.item; index as index3;">

                        <span *ngIf="item2.class === 'high' && item3.show === true">                      
                          <div class="btn-group dropup">
                            <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <span class="badge badge-pill badge-light txt-title"
                                [ngClass]="{'IsStripes': item3.IsStripes === true}"
                                [ngStyle]="{'background-color': item3.bgcolor, 'color': item3.color}"
                                data-toggle="tooltip" data-placement="top"><strong>{{item3.index}}</strong></span>
                              <div class="dropdown-menu dropdown-menu-top speech-bubble">
                                <h6 class="m-0" style="text-align: center;">{{item3.title}}</h6>
                              </div>
                            </div>
                          </div>
                        </span>
                        <span *ngIf="item2.class === 'medium' && item3.show === true">                      
                          <div class="btn-group dropup">
                            <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <span class="badge badge-pill badge-light txt-title"
                                [ngClass]="{'IsStripes': item3.IsStripes === true}"
                                [ngStyle]="{'background-color': item3.bgcolor, 'color': item3.color}"
                                data-toggle="tooltip" data-placement="top"><strong>{{item3.index}}</strong></span>
                              <div class="dropdown-menu dropdown-menu-top speech-bubble">
                                <h6 class="m-0" style="text-align: center;">{{item3.title}}</h6>
                              </div>
                            </div>
                          </div>
                        </span>
                        <span *ngIf="item2.class === 'low' && item3.show === true">                      
                          <div class="btn-group dropup">
                            <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <span class="badge badge-pill badge-light txt-title"
                                [ngClass]="{'IsStripes': item3.IsStripes === true}"
                                [ngStyle]="{'background-color': item3.bgcolor, 'color': item3.color}"
                                data-toggle="tooltip" data-placement="top"><strong>{{item3.index}}</strong></span>
                              <div class="dropdown-menu dropdown-menu-top speech-bubble">
                                <h6 class="m-0" style="text-align: center;">{{item3.title}}</h6>
                              </div>
                            </div>
                          </div>
                        </span>
                        <span *ngIf="item2.class === 'Escalation' && item3.show === true">                       
                          <div class="btn-group dropup">
                            <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <span class="badge badge-pill badge-light txt-title"
                                [ngStyle]="{'background-color': item3.bgcolor, 'color': item3.color}"
                                data-toggle="tooltip" data-placement="top"><strong>{{item3.index}}</strong></span>
                              <div class="dropdown-menu dropdown-menu-top speech-bubble">
                                <h6 class="m-0" style="text-align: center;">{{item3.title}}</h6>
                              </div>
                            </div>
                          </div>
                        </span>

                        <div [ngStyle]="{'background-color': item3.bgcolor , color:item3.color }"
                          *ngIf="item2.class === 'impact-y'" class="p-2">
                          <span class="font-size">{{item3.title}}</span>
                        </div>
                        <div [ngStyle]="{'background-color': item3.bgcolor , color:item3.color}" class="p-0"
                          *ngIf="item2.class === 'impact-x'">
                          <span>{{item3.title}}</span>
                        </div>
                      </ng-container>
                    </div>
                  </td>
                <tr>
              </table>
            </div>
          </div>
        </div>
        <div class="col-3 mt-2 px-1">
          <div class="col-12 pt-2" style="background-color:#033153;color:white;height:50px;">
            <strong style="font-size:22px;">Quarter: {{ Search.YearHQ2 }} - {{ Search.QuarterHQ2 }}</strong>
          </div>
          <div class="col-12" style="background-color: #3a5f92;">
            <div class="card-body row m-0 p-1 rounded">
              <div class="col-12">
                <h3 class="pl-2 pt-2" style="color: white;">KEY RISKS</h3>
              </div>
              <div class="col-12 px-0 scrollbar-key">
                <ul class="list-group w-100">
                  <li class="border-0 rounded-0 p-2" style="font-size: 18px;color: white;"
                    *ngFor="let item of list.risk.riskitems2; index as index">
                    <div class="row px-0 mx-0 list-item">
                      <div class="col-1 px-0">
                        <input type="checkbox" id="chk2{{ (index + 1) }}" checked
                          (click)="func_changeCompare((index + 1),  item.Risk_Id)">
                      </div>
                      <div class="col-1 px-0">
                        {{ (index+1) + '.' }}
                      </div>
                      <div class="col-10 px-0">
                        <label (click)="Reviewpage(item)" style="cursor:pointer;">{{ item.Risk_BusinessUnit_Name + ': '
                          + item.Risk_Name + ': ' + item.Risk_Co_Id }}</label>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-loading [loading]='loading'></app-loading>