<!-- 
<div *ngIf="this.isLoadingAll == true" class="WindowsInactive">
    <div class="loading-container">
      <span>Loading</span>
      <mat-spinner></mat-spinner>
     
    </div>
  </div> -->
  
  <app-loading [loading]='this.isLoadingAll'></app-loading>


  <nav class="navbar navbar-expand-lg navbar-light bg-light navbar-fixed-top position-absolute">

    <div class="navbar-brand">
      <a (click)="changemenuHome(1 , '')">
        <img alt=""  src="../assets/logo/PNG_Standard_Logo.png" alt="PTTEP" class="img-responsive logo">
      </a>
      <img alt=""  src="../assets/logo/Risk logo_2020.png" alt="PTTEP" style="padding-top:7%;">
    </div>
  
    <button class="navbar-toggler border-0" type="button" data-toggle="collapse" data-target="#basicExampleNav"
      aria-controls="basicExampleNav" aria-expanded="false" aria-label="Toggle navigation">
      <em class="lnr lnr-menu fa fa-bars" style="color:#023154;"></em>
    </button>


    <div class="collapse navbar-collapse justify-content-end" id="basicExampleNav">


      <ul class="navbar-nav mr-auto">
      
        <li><a  class="icon-menu nav-link" (click)="changemenuHome(1 , '')"><em class="fas fa-home"></em></a>
        </li>

  
        <li class="nav-item dropdown" *ngFor="let menu of tablemianmanu index as index1">
          <a class="nav-link dropdown-toggle icon-menu" id="index1" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false" style="cursor:pointer;">
            <em class="{{menu.menuIcon}}"></em>&nbsp;&nbsp;&nbsp;<span class="nav-text">{{menu.menuName}}</span>&nbsp;&nbsp;&nbsp;<em class="fas fa-angle-down"></em>
          </a>

          <div class="dropdown-menu dropdown-primary" [attr.aria-label]="index1">
            <h6 class="dropdown-header px-0" style="font-size: 18px;">{{menu.menuName}}</h6>
        <li *ngFor="let imenu of menu.sResTopMenuItem" class="hover-menu rounded" style="font-size: 18px;">
          <h6 *ngIf= "imenu.link == 'Header' && imenu.delFlag !== '1' && imenu.flagTag != '1'"class="nohover dropdown-header px-0" style="font-size: 18px;">{{imenu.menuName}}</h6>
          <span *ngIf="imenu.link === '/Identification/new'; else elseBlock">
            <a href="#" (click)="this.SelectMethodology()" class="hover-menu-text p-1 dropdown-item"
              *ngIf="imenu.delFlag !== '1' && imenu.flagTag != '1'">
              <em class="{{imenu.menuIcon}}"></em>&nbsp;&nbsp;&nbsp;<span>{{imenu.menuName}}</span>
            </a>
          </span>
          <ng-template #elseBlock>
            <a (click)="changemenu(2 , imenu.link)" [routerLink]="[imenu.link]" class="hover-menu-text p-1 dropdown-item pointter"
              *ngIf="imenu.delFlag !== '1' && imenu.flagTag != '1' && imenu.link != 'Header'">
              <em class="{{imenu.menuIcon}}"></em>&nbsp;&nbsp;&nbsp;<span>{{imenu.menuName}}</span>
            </a>
            <a  target="_blank" (click)="changemenu(2 , imenu.link)"  [routerLink]="[imenu.link]"
              class="hover-menu-text p-1 dropdown-item pointter" *ngIf="imenu.delFlag !== '1' && imenu.flagTag == '1' && imenu.link != 'Header'">
              <em class="{{imenu.menuIcon}}"></em>&nbsp;&nbsp;&nbsp;<span>{{imenu.menuName}}</span>
            </a>
          </ng-template>
        </li>
    </div>
    </li>

    <li>
  
      <a class="nav-link icon-menu" *ngIf="!loggedIn" (click)="login()" style="cursor: pointer;">
        <span class="nav-text">{{ btnName }}</span>&nbsp;<em class="fas fa-sign-in-alt"></em>
      </a>

    <li>
      <a class="nav-link icon-menu" *ngIf="loggedIn">
        <em class="fas fa-user-circle"></em>&nbsp;&nbsp;<span class="nav-text">{{ showname }}</span>&nbsp;&nbsp;
        <em class="fas fa-sign-out-alt text-danger" (click)="logout()" style="cursor:pointer;" title="LOGOUT"></em>
      </a>
    </li>

    </ul>


</div>


</nav>

<ng-template #modalTemplate>
  <div class="modal">

    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Please Select Register Risk Profile Methodology</h5>
      </div>
      <div class="modal-body">
      </div>
    </div>
    <h1>Authentication Modal</h1>
    Hi
  </div>
</ng-template>



<!-- <div class="container-fluid appform my-3" *ngIf="status === 1">
  <div class="row">
    <img alt=""  src="../../../assets/bg/bg-homepage.gif" class="responsive">
    <div class="card-img-overlay text-center d-flex">
      <div class="col-12 layout-control">
        <div class="row px-0"> -->
          
<div class="row justify-content-center align-items-center" *ngIf="status === 1">
  <div class="row">
  <img alt="" src="../../../assets/bg/homepage.png" class="full-screen-bg"  style="background-color:#E4E8FE">
  <div class="card-img-overlay col-12 text-center d-flex">
    <div class="col-12 layout-control" style="padding-top:60px;">
      <div class="row px-0" style="margin:30px">

          <!-- <div class="col-12 col-xl-4 px-0 layout-button animated fadeIn slow" style="text-align: left;"
            [hidden]="this.loginRole === 'Owner'">
            <div class="row mx-0 px-0">
              <div class="col-12 pl-0">
             
                <button type="button" class="btn px-0 button-border-left" (click)="SelectMethodology()">
                  <div class="row col-12">
                    <div class="col-2 col-xl-2 pt-1 arrow-left">
                      <em class="fas fa-arrow-circle-left img-btn"></em>
                    </div>
                    <div class="col-8 col-xl-8 px-0 css-pos" style="text-align: right;">
                      <strong class="form-text-button">REGISTER NEW RISK</strong>
                    </div>
                    <div class="col-2 col-xl-1">
                      <em class="fas fa-user-circle img-btn"></em>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>

          <div class="col-12 col-xl-4 px-0 layout-button animated fadeIn slow" style="text-align: left;"
            [hidden]="this.loginRole !== 'Owner'">
            <div class="row mx-0 px-0">
              <div class="col-12 pl-0">
                <button type="button" class="btn px-0 button-border-left" [routerLink]="['/Approval', 1]"
                  (click)="changemenu(2 , '/Approval/1')">
                  <div class="row col-12 ">
                    <div class="col-2 col-xl-2 pt-1 arrow-left">
                      <em class="fas fa-arrow-circle-left img-btn"></em>
                    </div>
                    <div class="col-8 col-xl-8 px-0 css-pos" style="text-align: right;">
                      <strong class="form-text-button">TO DO List\My Risk</strong>
                    </div>
                    <div class="col-2 col-xl-1">
                      <em class="fas fa-user-circle img-btn"></em>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>


          <div class="col-12 col-xl-4 px-0 layout-button-middle animated fadeIn slower" style="text-align: center;">
            <div class="col-12">
      
              <button type="button" class="btn button-border-middle" style="border-radius: 50%;"
                *ngIf="this.loginRole === 'Staff' || this.loginRole === 'Owner'">
                <img alt=""  src="../assets/logo/Risk-icon3.png" class="img-button-center">
              </button>
          
              <button type="button" class="btn button-border-middle" style="border-radius: 50%;"
                *ngIf="this.loginRole === 'Co' || this.loginRole === 'ERM'" [routerLink]="['/ConsolidateRisk']"
                (click)="changemenu(2 , '')">
                <img alt=""  src="../assets/logo/Risk-icon4update.png" class="img-button-center">
              </button>
            </div>
          </div>

          <div class="col-12 col-xl-4 px-0 layout-button animated fadeIn slow" style="text-align: right;">
            <div class="row mr-0 layout-layer">
              <div class="col-12 px-0">
                <button type="button" class="btn px-0 button-border-right" [routerLink]="['/Approval', 1]"
                  (click)="changemenu(2 , '/Approval/1')">
                  <div class="row col-12 mx-0 px-0">
                    <div class="col-2 col-xl-2 pr-0 pl-4 arrow-left">
                      <img alt=""  src="../assets/logo/speed-64.png" class="img-right">
                    </div>
                    <div class="col-8 col-xl-8 pr-0 pl-4 css-pos" style="text-align: left;">
                      <strong class="form-text-button">UPDATE ACTIVE RISKS</strong>
                    </div>
                    <div class="col-2 pt-1">
                      <em class="fas fa-arrow-circle-right img-btn"></em>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div> -->
          <div class="card animated fadeIn slow card-menu" style="width:100%;text-align: -webkit-center; background-color:white"> 
            <div class="row col-12" style="height: 35%">
              <div class="col-xl-6 col-6 h-100">
                <img alt="" src="../../../assets/logo/MenuPic_1.jpg" style="width: 100%; height: 100%; object-fit: scale-down;">
              </div>
              <div class="col-xl-6 col-6 h-100">
                <img alt="" src="../../../assets/logo/MenuPic_2.jpg" style="width: 100%; height: 100%; object-fit: scale-down;">
              </div>
            </div>
            <div class="row col-12" style="height: 65%; padding-left:30px; padding-right:30px">
              <div class="card col-12" style="background-color:#D9F9FF;">
                <div class="row pb-4">
                  <div class="col-xl-6 col-12">
                    <span style="color:#001D6A;font-size: xx-large;font-weight: 500;">STRATEGIC RISKS</span>

                    <div style="padding-top:20px">
                      <button type="button" class="btn px-0 button-menu" [routerLink]="['/Register-Assessment']"
                      (click)="changemenu(2 , '/Register-Assessment')">
                        <div class="row col-12">
                          <div class="col-2 offset-1 col-xl-2 pt-1">
                            <img alt="" src="../../../assets/logo/Register_Icon.jpg" style="width: 100%; height: 100%; object-fit: scale-down;">
                          </div>
                          <div class="col-9 col-xl-8 px-0 d-flex align-items-center" style="text-align: left;">
                            <strong class="" style="font-size: x-large;text-wrap: nowrap;">REGISTER & ASSESSMENT</strong>
                          </div>
                        </div>
                      </button>
                    </div>

                    <div style="padding-top:20px">
                      <button type="button" class="btn px-0 button-menu" [routerLink]="['/Monitor-Review']"
                      (click)="changemenu(2 , '/Monitor-Review')">
                        <div class="row col-12">
                          <div class="col-2 offset-1 col-xl-2 pt-1">
                            <img alt="" src="../../../assets/logo/Monitor_Review_Icon.jpg" style="width: 100%; height: 100%; object-fit: scale-down;">
                          </div>
                          <div class="col-9 col-xl-8 px-0 d-flex align-items-center" style="text-align: left;">
                            <strong class="" style="font-size: x-large">MONITOR & REVIEW</strong>
                          </div>
                        </div>
                      </button>
                    </div>

                    <div style="padding-top:20px">
                      <button type="button" class="btn px-0 button-menu" [routerLink]="['/Summary-Share']"
                      (click)="changemenu(2 , '/Summary-Share')">
                        <div class="row col-12">
                          <div class="col-2 offset-1 col-xl-2 pt-1">
                            <img alt="" src="../../../assets/logo/Summary_Share_Icon.jpg" style="width: 100%; height: 100%; object-fit: scale-down;">
                          </div>
                          <div class="col-9 col-xl-8 px-0 d-flex align-items-center" style="text-align: left;">
                            <strong class="" style="font-size: x-large">SUMMARY & SHARE</strong>
                          </div>
                        </div>
                      </button>
                    </div>
                    
                  </div>
                  <div class="col-xl-6 col-12">
                    <span style="color:#001D6A;font-size: xx-large;font-weight: 500;">PROCESS RISKS/EVENT RISKS</span>

                    <div *ngIf="this.loginRole != 'Owner'" style="padding-top:20px">
                      <button type="button" class="btn px-0 button-menu" (click)="SelectMethodology()">
                        <div class="row col-12">
                          <div class="col-2 offset-1 col-xl-2 pt-1">
                            <img alt="" src="../../../assets/logo/Register_Icon.jpg" style="width: 100%; height: 100%; object-fit: scale-down;">
                          </div>
                          <div class="col-8 col-xl-8 px-0 d-flex align-items-center" style="text-align: left;">
                            <strong class="" style="font-size: x-large">REGISTER NEW RISK</strong>
                          </div>
                        </div>
                      </button>
                    </div>

                    <div *ngIf="this.loginRole == 'Owner'" style="padding-top:20px">
                      <div class="row mx-0 px-0">
                        <div class="col-12 pl-0 pr-0">
                          <button type="button" class="btn px-0 button-menu" [routerLink]="['/Approval', 1]"
                          (click)="changemenu(2 , '/Approval/1')">
                            <div class="row col-12">
                              <div class="col-2 offset-1 col-xl-2 pt-1">
                                <img alt="" src="../../../assets/logo/Register_Icon.jpg" style="width: 100%; height: 100%; object-fit: scale-down;">
                              </div>
                              <div class="col-8 col-xl-8 px-0 d-flex align-items-center" style="text-align: left;">
                                <strong class="" style="font-size: x-large">TO DO LIST\MY RISK</strong>
                              </div>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                    

                    <div style="padding-top:20px">
                      <button type="button" class="btn px-0 button-menu" [routerLink]="['/Approval', 1]" (click)="changemenu(2 , '/Approval/1')">
                        <div class="row col-12">
                          <div class="col-2 offset-1 col-xl-2 pt-1">
                            <img alt="" src="../../../assets/logo/Update_Active_Risk_Icon.jpg" style="width: 100%; height: 100%; object-fit: scale-down;">
                          </div>
                          <div class="col-8 col-xl-8 px-0 d-flex align-items-center" style="text-align: left;">
                            <strong class="" style="font-size: x-large">UPDATE ACTIVE RISK</strong>
                          </div>
                        </div>
                      </button>
                    </div>

                    <div *ngIf="this.loginRole != 'Owner' && this.loginRole != 'Staff'" style="padding-top:20px">
                      <button type="button" class="btn px-0 button-menu" [routerLink]="['/ConsolidateRisk']"
                      (click)="changemenu(2 , '')">
                        <div class="row col-12">
                          <div class="col-2 offset-1 col-xl-2 pt-1">
                            <img alt="" src="../../../assets/logo/Consolidate_Icon.jpg" style="transform: rotate(90deg);width: 100%; height: 100%; object-fit: scale-down;">
                          </div>
                          <div class="col-8 col-xl-8 px-0 d-flex align-items-center" style="text-align: left;">
                            <strong class="" style="font-size: x-large">CONSOLIDATE</strong>
                          </div>
                        </div>
                      </button>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="main_menu" *ngIf="status === 2">
  <div class="container custom-container">
    <!-- <div class="card bg-dark text-white rounded-0 m-0">
      <img alt=""  src="../assets/bg/bbg1.png" class="card-img img-fluid" *ngIf="imgbanner === null">
      <img alt=""  src="{{ pathbanner }}/{{ imgbanner.itemAttSeq1[0]?.pathFile }}" class="card-img" *ngIf="imgbanner !== null"
        style="max-height: 390px;">
      <div class="card-img-overlay text-center d-flex justify-content-center" style="align-items: center;">
       
      </div>
    </div> -->
  </div>
</div>

<div class="main_menu mx-2" [hidden]="status !== 2">

  <div class="mx-2">
    <div class="row">

      <div class="col-6 mb-4">
        <div class="row p-0 m-0">
          <div class="col-6 col-xl-4 p-0 m-0 pr-1">
            <div class="badge-block active d-flex justify-content-center" style="align-items: center;">
              <span class="h5" style="font-size: 30px;">{{ showLoginBUName }}</span>
            </div>
          </div>
          <div class="col-6 col-xl-5 p-0 m-0">
            <div class="badge-block d-flex justify-content-center" style="align-items: center;">
              <span class="h5" style="font-size: 30px;">{{ showBUName }}</span>
             
            </div>
          </div>
        </div>
      </div>

      <div class="col-6" [hidden]="path === false">

        <ul id="progressbar" class="d-flex justify-content-end m-0">
  
          <li class="active" id="step1"><strong>Risk Identification</strong></li>
          <li id="step2"><strong>Risk Assessment</strong></li>
          <li id="step3"><strong>Review Page</strong></li>
        </ul>
        <em class="fp-prev"></em>
      </div>

    </div>
  </div>
</div>

<div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalSelectMethodology">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Please Select Register Risk Profile Methodology</h5>
      </div>
      <div class="modal-body">
        <div class="row col-12 mx-0 px-0">
          <div class="col-4 pt-2">
            <strong style="font-size:18px;">Register Methodology</strong>
          </div>
          <div class="col-8 px-0 pt-2">
            <button type="button" class="btn mr-3 Preliminary"
              (click)="func_btn_RegisterNewRisk_BG(2,'new')">Preliminary</button>
            <button type="button" class="btn FullAnalysis" (click)="func_btn_FullAnalysisRisk_BG(2,'new')">Full
              Analysis</button>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="col-12" style="text-align: center;">
          <button type="button" style="width:100px;" class="btn btn-secondary" (click)="close()">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalAlertLogin">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <span class="bg-danger">&nbsp;</span>
      <div class="modal-header">
        <div class="row col-12 text-danger">
          <div class="col-2">
            <em class="far fa-times-circle" style="font-size:50px;"></em>
          </div>
          <div class="col-10 pt-2">
            <h4 class="modal-title">Alert !</h4>
          </div>
        </div>
      </div>
      <div class="modal-body text-body border-0">
        <div class="col-12" style="text-align: left;">
          <span style="font-size:16px;">{{ alertflow }}</span>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger w-25" (click)="closeflow()">OK</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalTimeout">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <span class="bg-warning">&nbsp;</span>
      <div class="modal-header">
        <div class="row col-12 text-warning">
          <div class="col-2">
            <em class="far fa-times-circle" style="font-size:50px;"></em>
          </div>
          <div class="col-10 pt-2">
            <h4 class="modal-title">Alert !</h4>
          </div>
        </div>
      </div>
      <div class="modal-body text-body border-0">
        <div class="col-12" style="text-align: left;">
          <span style="font-size:16px;" id="time" *ngIf="time !== 0">You have been idle for 15 minute.<br>You will be
            logout in <strong>{{ time }}</strong> seconds</span>
        </div>
      
      </div>
      
    </div>
  </div>
</div>








