<div class="container-fluid appform my-3">
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-12 pb-0 mt-0 mb-0">
            <form>
                <fieldset class="card ">
                    <legend class="w-auto px-2"></legend>
                    <div class=" col-12 mt-4 mx-0 p-3">
                        <h1 class=" mt-4 mx-0 p-3">Risk from Strategy</h1>

                        <div class="table-banner" [formGroup]="form1">
                            <table class="table-bordered" style="width:100%;">
                                <caption style="display: none;">This is a custom table</caption>
                                <thead style="text-align: center;background-color:#033153;color:white;font-size:18px;">
                                    <tr>
                                        <th style="vertical-align: middle;">No.</th>
                                        <th style="vertical-align: middle;">Risk Name / Description</th>
                                        <th style="vertical-align: middle;">(Residual) Likelihood</th>
                                        <th style="vertical-align: middle;">(Residual) Impact</th>
                                        <th style="vertical-align: middle;">(Residual) Risk Level</th>
                                        <th style="vertical-align: middle;">(If Mitigated) Likelihood</th>
                                        <th style="vertical-align: middle;">(If Mitigated) Impact</th>
                                        <th style="vertical-align: middle;">(If Mitigated) Risk Level</th>
                                        <th style="vertical-align: middle;">Mitigatable</th>
                                        <th style="vertical-align: middle;">Acceptable</th>
                                        <th style="vertical-align: middle;" *ngIf="isCloseButtonEdit == true">Delete</th>
                                        <th style="vertical-align: middle;" *ngIf="isCloseButtonEdit == true">{{titleButton}}</th>
                                    </tr>
                                    <tr>

                                    </tr>
                                </thead>
                                <tbody style="background-color: white;font-size:16px;" formArrayName="riskStrategy">
                                    <tr *ngFor="let item of riskStrategyArrayTemp; let index = index"
                                        [formGroupName]="index">
                                        <td style="text-align: center;vertical-align: top; ">{{index+1}}</td>
                                        <td style="text-align: center;vertical-align: top; ">
                                            <label *ngIf="item.check==false">{{item.riskName}}</label>
                                            <textarea rows="1"  (input)="auto_height($event)" type="text" class="form-control" formControlName="riskName"
                                                *ngIf="item.check==true"
                                                [ngClass]="{ 'is-invalid': riskStrategyArray.controls[index].get('riskName').valid ==false  && riskStrategyArray.controls[index].get('riskName').touched==true}"></textarea>
                                        </td>
                                        <td style="text-align: center;vertical-align: top; ">
                                            <label *ngIf="item.check==false"><span
                                                    *ngFor="let itemfilter of filterByid(likelihoodValues,item.likelihoodInherent)">{{itemfilter.name}}</span></label>
                                            <select *ngIf="item.check==true" class="form-control"
                                                formControlName="likelihoodInherent" (change)="onChangeData(index)" 
                                                [ngClass]="{ 'is-invalid': riskStrategyArray.controls[index].get('likelihoodInherent').valid ==false  && riskStrategyArray.controls[index].get('likelihoodInherent').touched==true}">
                                                <option *ngFor="let itemOption of likelihoodValues"
                                                    [ngValue]="itemOption.value"
                                                    [selected]="itemOption.value == item.likelihoodInherent">
                                                    {{itemOption.name}}</option>
                                            </select>
                                        </td>
                                        <td style="text-align: center;vertical-align: top; ">
                                            <label *ngIf="item.check==false"><span
                                                    *ngFor="let itemfilter of filterByid(impactValues,item.impactInherent)">{{itemfilter.name}}</span></label>
                                            <select *ngIf="item.check==true" class="form-control"
                                                formControlName="impactInherent" (change)="onChangeData(index)"
                                                [ngClass]="{ 'is-invalid': riskStrategyArray.controls[index].get('impactInherent').valid ==false  && riskStrategyArray.controls[index].get('impactInherent').touched==true}">
                                                <option *ngFor="let itemOption of impactValues"
                                                    [ngValue]="itemOption.value"
                                                    [selected]="itemOption.value== item.impactInherent">
                                                    {{itemOption.name}}</option>
                                            </select>
                                        </td>
                                        <td style="text-align: center;vertical-align: top; "
                                            [ngClass]="{'low': item.riskLevelInherent == 1,'medium': item.riskLevelInherent == 0.5, 'high': item.riskLevelInherent == 0 , 'null': item.riskLevelInherent == null}">
                                            <input type="hidden" formControlName="riskLevelInherent"
                                                [ngModel]="item.riskLevelInherent">
                                        </td>
                                        <td style="text-align: center;vertical-align: top; ">
                                            <label *ngIf="item.check==false"><span
                                                    *ngFor="let itemfilter of filterByid(likelihoodValues,item.likelihoodIfMitigated)">{{itemfilter.name}}</span></label>
                                            <select *ngIf="item.check==true" class="form-control"
                                                formControlName="likelihoodIfMitigated" (change)="onChangeData(index)" 
                                                [ngClass]="{ 'is-invalid': riskStrategyArray.controls[index].get('likelihoodIfMitigated').valid ==false  && riskStrategyArray.controls[index].get('likelihoodIfMitigated').touched==true}">
                                                <option *ngFor="let itemOption of likelihoodValues"
                                                    [ngValue]="itemOption.value"
                                                    [selected]="itemOption.value== item.likelihoodIfMitigated">
                                                    {{itemOption.name}}</option>
                                            </select>
                                        </td>
                                        <td style="text-align: center;vertical-align: top; ">
                                            <label *ngIf="item.check==false"><span
                                                    *ngFor="let itemfilter of filterByid(impactValues,item.impactIfMitigated)">{{itemfilter.name}}</span></label>
                                            <select *ngIf="item.check==true" class="form-control"
                                                formControlName="impactIfMitigated" (change)="onChangeData(index)" 
                                                [ngClass]="{ 'is-invalid': riskStrategyArray.controls[index].get('impactIfMitigated').valid ==false  && riskStrategyArray.controls[index].get('impactIfMitigated').touched==true}">
                                                <option *ngFor="let itemOption of impactValues"
                                                    [ngValue]="itemOption.value"
                                                    [selected]="itemOption.value == item.impactIfMitigated">
                                                    {{itemOption.name}}</option>
                                            </select>
                                        </td>
                                        <td style="text-align: center;vertical-align: top; "
                                            [ngClass]="{'low': item.riskLevelIfMitigated == 1,'medium': item.riskLevelIfMitigated == 0.5, 'high': item.riskLevelIfMitigated == 0, 'null': item.riskLevelIfMitigated == null}">
                                            <input type="hidden" formControlName="riskLevelIfMitigated"
                                                [ngModel]="item.riskLevelIfMitigated">
                                        </td>
                                        <td style="text-align: center;vertical-align: top; ">
                                            <label>{{item.mitigatable=='1'?'YES': item.mitigatable==null ? '' : 'NO'}}</label>
                                        </td>
                                        <td style="text-align: center;vertical-align: top; ">
                                            <label *ngIf="item.check==false">{{item.acceptable=='1'?'YES':'NO'}}</label>
                                            <select *ngIf="item.check==true" class="form-control"
                                                formControlName="acceptable"
                                                [ngClass]="{ 'is-invalid': riskStrategyArray.controls[index].get('acceptable').valid ==false  && riskStrategyArray.controls[index].get('acceptable').touched==true}">
                                                <option *ngFor="let itemOption of acceptableValues"
                                                    [ngValue]="itemOption.value"
                                                    [selected]="itemOption.value== item.acceptable">{{itemOption.name}}
                                                </option>
                                            </select>
                                        </td>
                                        <td style="text-align: center;vertical-align: top; "  *ngIf="isCloseButtonEdit == true">
                                            <div>
                                                <button type="button" class="btn" (click)="removeRiskStrategy(index)">
                                                    <em class="fas fa-trash-alt" style="color:red;font-size:16px;"></em>
                                                </button>
                                            </div>
                                        </td>
                                        <td style="text-align: center;vertical-align: top; "  *ngIf="isCloseButtonEdit == true">
                                            <div>
                                                <button *ngIf="item.check==false" type="button" class="btn"
                                                    (click)="fun_Riskfromtrategy(index,'edit')">
                                                    <em class="fas fa-edit" style="color:orange;font-size:16px;"></em>
                                                </button>
                                                <button *ngIf="item.check==true" type="button" class="btn"
                                                    (click)="fun_Riskfromtrategy(index,'add')">
                                                    <em class="far fa-save icon-in-table text-success"
                                                        style="color:orange;font-size:16px;"></em>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-12 pt-2 ">
                            <div *ngIf="isCloseButtonEdit == true">
                                <button type="button" class="btn btn-lg btn-banner-primary mb-3"
                                    style="width:200px;color:white;background:orange;" (click)="addRiskStrategyFormGroup()">
                                    <em class="fas fa-plus icon"></em>&nbsp;&nbsp;&nbsp;Add Next Risk
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="card col-12  p-3 ">
                        <div class="row justify-content-md-center" style="height: fit-content;">
                            <div class=" col-6">
                                <app-strategy-table [datas]="dataStrategyList">
                                </app-strategy-table>
                            </div>
                            <div class=" col-6">
                                <app-accept-table [datas]="dataStrategyList">
                                </app-accept-table>
                            </div>
                        </div>
                    </div>

                </fieldset>
            </form>
            <div class="card col-12 mt-4 mx-0 p-3">
                <h1 class=" mt-4 mx-0 p-3">Strategic Assumptions</h1>

                <div class="table-banner" [formGroup]="form2">
                    <table class="table-bordered" style="width:100%;">
                        <caption style="display: none;">This is a custom table</caption>
                        <thead style="text-align: center;background-color:#033153;color:white;font-size:18px;">
                            <tr>
                                <th style="vertical-align: middle;">Assumption No.</th>
                                <th style="vertical-align: middle;">Category</th>
                                <th style="vertical-align: middle;">Name / Description</th>
                                <th style="vertical-align: middle;">Favorable Status (GREEN)</th>
                                <th style="vertical-align: middle;">Unfavorable Status (RED)</th>
                                <th style="vertical-align: middle;">Action If RED</th>
                                <th style="vertical-align: middle;" *ngIf="isCloseButtonEdit == true">Delete</th>
                                <th style="vertical-align: middle;" *ngIf="isCloseButtonEdit == true">{{titleButton2}}</th>
                            </tr>
                            <tr>

                            </tr>
                        </thead>
                        <tbody style="background-color: white;font-size:16px;" formArrayName="riskAssumption">
                            <tr *ngFor="let item of riskAssumptionArrayTemp; let index = index;"
                                [formGroupName]="index">
                                <td style="text-align: center;vertical-align: top; "> <label >{{"Assumption "}}{{index+1}}</label> </td>
                                <td style="text-align: center;vertical-align: top; ">
                                    <label *ngIf="item.check==false"><span
                                            *ngFor="let assumptions of filterAssumptions(item.categoryId) ">{{assumptions.category_Name}}</span></label>
                                    <select class="form-control " formControlName="categoryId" *ngIf="item.check==true"
                                        [ngClass]="{ 'is-invalid': riskAssumptionArray.controls[index].get('categoryId').valid ==false  && riskAssumptionArray.controls[index].get('categoryId').touched==true}">
                                        <option *ngFor="let itemOption of assumptionsValue"
                                            [ngValue]="itemOption.assumptionCategory_ID"
                                            [selected]="itemOption.assumptionCategory_ID== item.categoryId">
                                            {{itemOption.category_Name}}</option>
                                    </select>
                                </td>
                                <td style="text-align: center;vertical-align: top; ">
                                    <label *ngIf="item.check==false">{{item.assumptionDescription}}</label>
                                    <textarea rows="1"   (input)="auto_height($event)" class="form-control" formControlName="assumptionDescription"
                                        *ngIf="item.check==true"
                                        [ngClass]="{ 'is-invalid': riskAssumptionArray.controls[index].get('assumptionDescription').valid ==false  && riskAssumptionArray.controls[index].get('assumptionDescription').touched==true}"></textarea>
                                </td>
                                <td style="text-align: center;vertical-align: top; ">
                                    <label *ngIf="item.check==false">{{item.favorableStatus}}</label>
                                    <textarea rows="1"   (input)="auto_height($event)" class="form-control" formControlName="favorableStatus"
                                        *ngIf="item.check==true"
                                        [ngClass]="{ 'is-invalid': riskAssumptionArray.controls[index].get('favorableStatus').valid ==false  && riskAssumptionArray.controls[index].get('favorableStatus').touched==true}"></textarea>
                                </td>
                                <td style="text-align: center;vertical-align: top; ">
                                    <label *ngIf="item.check==false">{{item.unfavorableStatus}}</label>
                                    <textarea rows="1"   (input)="auto_height($event)" class="form-control" formControlName="unfavorableStatus"
                                        *ngIf="item.check==true"
                                        [ngClass]="{ 'is-invalid': riskAssumptionArray.controls[index].get('unfavorableStatus').valid ==false  && riskAssumptionArray.controls[index].get('unfavorableStatus').touched==true}"></textarea>
                                </td>
                                <td style="text-align: center;vertical-align: top; ">
                                    <label *ngIf="item.check==false">{{item.actionRed}}</label>
                                    <textarea rows="1"   (input)="auto_height($event)" class="form-control" formControlName="actionRed"
                                        *ngIf="item.check==true"
                                        [ngClass]="{ 'is-invalid': riskAssumptionArray.controls[index].get('actionRed').valid ==false  && riskAssumptionArray.controls[index].get('actionRed').touched==true}"></textarea>
                                </td>

                                <td style="text-align: center;vertical-align: top;" *ngIf="isCloseButtonEdit == true">
                                    <div>
                                        <button type="button" class="btn" (click)="removeRiskAssumption(index)">
                                            <em class="fas fa-trash-alt" style="color:red;font-size:16px;"></em>
                                        </button>
                                    </div>
                                </td>
                                <td style="text-align: center;vertical-align: top;"  *ngIf="isCloseButtonEdit == true">
                                    <div>
                                        <button *ngIf="item.check==false" type="button" class="btn"
                                            (click)="fun_FormAssumptions(index,'edit')">
                                            <em class="fas fa-edit" style="color:orange;font-size:16px;"></em>
                                        </button>
                                        <button *ngIf="item.check==true" type="button" class="btn"
                                            (click)="fun_FormAssumptions(index,'add')">
                                            <em class="far fa-save icon-in-table text-success"
                                                style="color:orange;font-size:16px;"></em>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-12 pt-2 ">
                    <div *ngIf="isCloseButtonEdit == true">
                        <button type="button" class="btn btn-lg btn-banner-primary mb-3"
                            style="width:200px;color:white;background:orange;" (click)="addRiskAssumptionFormGroup()">
                            <em class="fas fa-plus icon"></em>&nbsp;&nbsp;&nbsp;Add Next Assumption
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>