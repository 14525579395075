import { Component, Input } from '@angular/core';
import { bindingStrategicTableModel } from '../../model/StrategicRiskModel';

@Component({
  selector: 'app-strategic-table',
  templateUrl: './strategic-table.component.html',
  styleUrls: ['./strategic-table.component.css']
})
export class StrategicTableComponent{
  public _tabSelect: string;
  public _mode: string;
  private _datas: bindingStrategicTableModel[];
  public _height: string = '100%' ;
  public _width: string = '100%' ;
  @Input() set tabSelect(value: string){
    this._tabSelect = value;
    this.newTab();
  }

  @Input() set datas(value: bindingStrategicTableModel[]){
    this._datas = value;
    this.render();
  }

  @Input() set mode(value: string){
    this._mode = value;
  }

  @Input() set width(value: string){
    this._width = value;
  }

  @Input() set height(value: string){
    this._height = value;
  }

  //example datas
  mockDataStrategic: bindingStrategicTableModel[] = [{xPosition: 1, yPosition: 1},{xPosition: 1, yPosition: 1}];

  dataTable:any = [
    [[],[],[]],
    [[],[],[]],
    [[],[],[]]
   ];

  render() {
    this.clearTable();
    this.plotTable();
  }
 

  plotTable()
  {
    if(this._datas)
    {
      for(let i = 0; i < this._datas.length; i++)
      {
        this.dataTable[Number(this._datas[i].xPosition)-1][Number(this._datas[i].yPosition)-1].push({value: 1, number: i+1 });
      }
    }

  }

  newTab()
  {
    this.clearTable();
    this.plotTable();
  }

  clearTable()
  {
    this.dataTable = [
      [[],[],[]],
      [[],[],[]],
      [[],[],[]]
    ];
  }


}
