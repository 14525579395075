<div class="container-fluid appform my-3" >
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1"  >


            <div class="col-12 col-md-12 col-lg-12 col-xl-12 pb-5 mt-5 mb-5">
                <div class="card col-12 mt-4 mx-0 p-3">

                    <div class="col-12 pt-2 banner rounded">
                        <em class="fas fa-bars icon"></em>&nbsp;&nbsp;Assumption Category
                    </div>
                    <div class="col-12 px-0" style="text-align: right;">
                        <button type="button" class="btn btn-lg btn-banner-primary mb-3" style="width:250px;color:white;background:orange;" (click)="func_btnAdd('insert')">
                            <em class="fas fa-plus icon"></em>&nbsp;&nbsp;&nbsp;Add Assumption Category
                        </button>
                    </div>

                    <div class="table-banner">
                        <table class="table-bordered" style="width:100%;">
                            <caption style="display: none;" >This is a custom table</caption>
                            <thead style="text-align: center;background-color:#033153;color:white;font-size:18px;">
                                <tr>
                                    <th style="vertical-align: middle;">No</th> 
                                    <th style="vertical-align: middle;">Assumption Category</th> 
                                    <th style="vertical-align: middle;">Active</th>
                                    <th style="vertical-align: middle;">Edit</th>
                                </tr>
                            </thead>
                            <tbody style="background-color: white;font-size:16px;">
                                <tr   *ngFor="let item of tableCategory index as index">
                                    <td class="col-1" style="text-align: center;">{{index+1}}</td>
                                    <td style="text-align: center;">{{item.category_Name}}</td>
                                    <td class="col-1" style="text-align: center;">
                                        {{item.active_Flag==true?'Yes':'No'}}
                                    </td>
                                    <td class="col-1" style="text-align: center;">
                                        <button type="button" class="btn" (click)="func_btnAdd('edit',item)">
                                            <em class="fas fa-edit" style="color:orange;font-size:16px;"></em>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>

        </div>
    </div>
    <div class="modal fade bd-Mianmenu" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" id="modaladdcategory">
        <form [formGroup]="form">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="container-fluid appform my-3">
                    <div class="row">
                        <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">
                            <div class="container col-12 col-md-12 col-lg-12 col-xl-12 pb-0 mt-0 mb-0 ">

                                <div class="container" >
                                    <div class="col-5 pt-2 rounded modalbanner">
                                        <em class="fas fa-bars icon"></em>
                                        <span *ngIf="status === 'insert'">&nbsp;&nbsp;Add Category Management</span>
                                        <span *ngIf="status === 'edit'">&nbsp;&nbsp;Edit Category Management</span>
                                    </div>
                                    <div class="card mt-4 py-3">
                                        <div class="row col-12 mb-4 px-0">
                                            <div class="row col-12 mx-0">
                                                <div class="col-4 mb-2" style="text-align: right;">
                                                    <strong class="formtext">Assumption Category</strong>
                                                </div>
                                                <div class="col-8">
                                                    <input  class="form-control"   formControlName="category_Name"  >
                                                    <div  style="color: red;"  *ngIf="fb.category_Name.errors">Assumption Category  is reqiure !</div>
                                                </div>
                                                <div class="col-3 mb-0 mb-0 px-0 p-3"  style="text-align: right;">
                                                    <strong class="formtext">Active</strong>
                                                    <input type="checkbox" formControlName="active_Flag"  style=" display: table-cell; vertical-align: middle;   margin-left: 10px ">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 my-3 mx-0 text-center">
                                        <button type="button" class="btn mr-3" style="width:100px;background-color:#9eb0ba;color:white;" (click)="func_Close()">Close</button>
                                        <button type="button" class="btn" style="width:100px;background-color:#033153;color:white;" (click)="func_save('insert')" *ngIf="status === 'insert'">Save</button>
                                        <button type="button" class="btn" style="width:100px;background-color:#033153;color:white;" (click)="func_save('update')" *ngIf="status === 'edit'">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    </div>
</div>
<app-modal-alert [title]="title"  [message]="message" ></app-modal-alert>