<ng-container [formGroup]="mainForm">
    <div class="row ml-2 mr-2">
        <div class="col-3 riskbox" style="background-color: #4198C5; color: white;">
            Strategy Name or Description
        </div>
        <input type="text" formControlName="riskName" class="col" style="font-size: 1vw;"
        [ngClass]="{'input-error': (this.mainForm.controls.riskName.invalid && this.mainForm.controls.riskName.touched)}">
        
    </div>
</ng-container>
<div class="container-fluid appform my-3 card-body" style="padding: 80px; padding-top: 0px; height: fit-content;">
    <ng-container [formGroup]="mainForm">
    <div class="card mt-3 py-4" style="background-color:#ECF0F6;">
        <div class="row mt-2">
            <span class="text pl-5">
                Implementation Period
            </span>
            <div class="ml-5" style="display: flex; margin-top: 15px">
                <input matInput class="form-control" style="background-color:white;"  [matDatepicker]="picker" autocomplete="off" [readonly]="true"  [max]="mainForm.controls.riskEndDate.value" formControlName="riskStartDate"
                [ngClass]="{'input-error': (this.mainForm.controls.riskStartDate.invalid && this.mainForm.controls.riskStartDate.touched)}">
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </div>
            <div class="ml-5"  style="display: flex; margin-top: 15px">
                <input matInput class="form-control" style="background-color:white;"  [matDatepicker]="picker1" autocomplete="off" [readonly]="true"  [min]="mainForm.controls.riskStartDate.value" formControlName="riskEndDate"
                [ngClass]="{'input-error': (this.mainForm.controls.riskEndDate.invalid && this.mainForm.controls.riskEndDate.touched) && this.mainForm.controls.noEndDate.value == false}">
                <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
            </div>

            <div>
                <div class="form-check ml-5" style="display: flex; margin-top: 18px">
                    <input class="form-check-input" type="checkbox" value="noEndDate" (change)="onNoEndDateChange($event)" formControlName="noEndDate" id="noEndDateCheckBox">
                    <label class="form-check-label mt-2" for="noEndDateCheckBox">
                        No End Date
                    </label>
                  </div>
            </div>
        </div>
        <div class="row col-12 p-0">
            <span class="col-6 text pl-5 pt-3 ">
                Summary Page
            </span>
            <div class = "col-6 pl-0 pt-3" style="text-align: end; ">
                <button type="button" class="btn btn-lg" 
                style="color:white;background:rgb(0, 0, 0);margin: 5px;" (click)="alertFunc('BacktoRegister')">Back to Register
                </button>
            </div>
        </div>
    </div>
        
    </ng-container>

    <app-riskfrom-strategy [data]="data" [isCloseButtonEdit]="true" (addLog)="childAddLogEvent($event)"></app-riskfrom-strategy>
    <app-strategic-assumption [data]="data" [showHeading]="true" [isCloseButtonEdit]="true" (addLog)="childAddLogEvent($event)"></app-strategic-assumption>

    <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-12 ">
           <div class="col-12 ">
            <div class="col-12 my-3 mx-2 text-center">
                <button type="button" (click)="alertFunc('ConfirmRegister')"  class="btn mr-3" style="width:300px;background-color:green;color:white;">Register this Strategy</button>
                <button type="button" (click)="alertFunc('BacktoMonitorReview')" class="btn mr-3" style="width:300px;background-color:black;color:white;" >Back to Monitor & Review Page</button>
            </div>
            </div>
        </div>
     </div>

     <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-12 " style="padding: 30px">
            <app-action-log [riskId]="this.riskId" [isShowStrategyName] = "false"></app-action-log>
        </div>
     </div>


</div>

<app-modal-alert (confirmed)="confirmedFunc()" (success)="successFunc()" [title]="titleAlert" [message]="alertflow"></app-modal-alert>
<app-loading [loading]='this.isLoadingAll'></app-loading>