import { Injectable } from '@angular/core';
import Axios from 'axios';
import * as qs from 'qs'
import { AppSettingService } from '../app-setting-service';
import { AxiosInterceptorService } from './axios-interceptor.service';

@Injectable()
export class RiskMap {
  constructor(public appSettingService:AppSettingService, public axiosInterceptorService: AxiosInterceptorService) {
  } 
  axiosInstance = this.axiosInterceptorService.getAxiosInstance();
  configdata= this.appSettingService.read();
   BASE_URL =this.configdata.api_url;

    bearerToken = sessionStorage.getItem('apiToken');
  serviceAPI = {
  URL: {
    getImpactRiskmap : this.BASE_URL + '/api/Get_Impact_Riskmap_BU',
    getImpactRiskmap2 : this.BASE_URL + '/api/Get_Impact_Riskmap_Unit',
    getImpactRiskitem : this.BASE_URL + '/api/Cal_RiskMap_BU',
    getRiskTODO : this.BASE_URL + '/api/Cal_RiskMap_Unit',
    getDDL : this.BASE_URL + '/api/Master/GetDDL',
    RiskApprove : this.BASE_URL + '/api/Risk_Workflow_Approve',
    RiskReject : this.BASE_URL + '/api/Risk_Workflow_Reject',
    RiskSubmit :  this.BASE_URL + '/api/Risk_Workflow_Submit',
    GetRiskUnit : this.BASE_URL + '/api/Get_Risk_Unit',
    Get_Menu_RiskMap : this.BASE_URL + '/api/Get_Menu_RiskMap',
    ReportDashboardCategory : this.BASE_URL + '/api/ReportDashboardCategory',
    ReportDashboardStatus : this.BASE_URL + '/api/ReportDashboardStatus',
    Risk_ReConsolidate : this.BASE_URL + '/api/Risk_ReConsolidate',
    SetRiskMapSorting : this.BASE_URL + '/api/Set_RiskMap_Sorting'
  },
  
  CONFIG: {
    GET: {
      headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;',
          'Accept': 'q=0.8;application/json;q=0.9',
          'Authorization':"bearer "+this.bearerToken,
          'Access-Control-Allow-Origin': 'https://err-dev.pttep.com/',
          'Access-Control-Allow-Methods': 'POST, GET',
          'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
      },
      emulateJSON: true
    }
  }
};
  getImpact(paramData){
    let data = qs.stringify(paramData)
    return this.axiosInstance.post(this.serviceAPI.URL.getImpactRiskmap, data, this.serviceAPI.CONFIG.GET);
  }
  getImpact2(paramData){
    let data = qs.stringify(paramData)
    return this.axiosInstance.post(this.serviceAPI.URL.getImpactRiskmap2, data, this.serviceAPI.CONFIG.GET);
  }
  getRiskitem(paramData){
    let data = qs.stringify(paramData)
    return this.axiosInstance.post(this.serviceAPI.URL.getImpactRiskitem, data, this.serviceAPI.CONFIG.GET);
  }
  getRisktodo(paramData){
    let data = qs.stringify(paramData)
    return this.axiosInstance.post(this.serviceAPI.URL.getRiskTODO, data, this.serviceAPI.CONFIG.GET);
  }
  getQuarterYearDD(paramData){
    let data = qs.stringify(paramData)
    return this.axiosInstance.post(this.serviceAPI.URL.getDDL, data, this.serviceAPI.CONFIG.GET);
  }
  getQuarterDD(paramData){
    let data = qs.stringify(paramData)
    return this.axiosInstance.post(this.serviceAPI.URL.getDDL, data, this.serviceAPI.CONFIG.GET);
  }
  RiskApprove(paramData){
    let data = qs.stringify(paramData)
    return this.axiosInstance.post(this.serviceAPI.URL.RiskApprove, data, this.serviceAPI.CONFIG.GET);
  }
  RiskReject(paramData){
    let data = qs.stringify(paramData)
    return this.axiosInstance.post(this.serviceAPI.URL.RiskReject, data, this.serviceAPI.CONFIG.GET);
  }
  RiskSubmit(paramData){
    let data = qs.stringify(paramData)
    return this.axiosInstance.post(this.serviceAPI.URL.RiskSubmit, data, this.serviceAPI.CONFIG.GET);
  }
  GetRiskUnit(paramData){
    let data = qs.stringify(paramData)
    return this.axiosInstance.post(this.serviceAPI.URL.GetRiskUnit, data, this.serviceAPI.CONFIG.GET);
  }
  GetMenuRiskMap(paramData){
    let data = qs.stringify(paramData)
    return this.axiosInstance.post(this.serviceAPI.URL.Get_Menu_RiskMap, data, this.serviceAPI.CONFIG.GET);
  }
  ReportDashboardCategory(paramData){
    let data = qs.stringify(paramData)
    return this.axiosInstance.post(this.serviceAPI.URL.ReportDashboardCategory, data, this.serviceAPI.CONFIG.GET);
  }
  ReportDashboardStatus(paramData){
    let data = qs.stringify(paramData)
    return this.axiosInstance.post(this.serviceAPI.URL.ReportDashboardStatus, data, this.serviceAPI.CONFIG.GET);
  }
  RiskReConsolidate(paramData){
    let data = qs.stringify(paramData)
    return this.axiosInstance.post(this.serviceAPI.URL.Risk_ReConsolidate, data, this.serviceAPI.CONFIG.GET);
  }
  setRiskMapSorting(paramData){
    let data = qs.stringify(paramData)
    return this.axiosInstance.post(this.serviceAPI.URL.SetRiskMapSorting, data, this.serviceAPI.CONFIG.GET);
  }
}
