import { Component, OnInit } from "@angular/core";
import { Masterserve } from '../../../services/master.service';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthenService } from "src/app/services/authenservice.service";
declare let $;

@Component({
    selector: 'app-MasterInstruction',
    templateUrl: './masterinstruction.html',
    styleUrls: ['./masterinstruction.css'],
    providers: [Masterserve, NgbModalConfig, NgbModal]

})
export class MasterInstruction implements OnInit {

    tableinstruction;
    statusinstruction;
    quarterCategory ;
    Data_SessionStorage;

    SessionEmpID;
    Module;

    Page;
    Field;
    InstructionID;
    Area;
    InstructionName;
    status = '';
    loading = true;

    constructor(config: NgbModalConfig, private modalService: NgbModal,public mserve: Masterserve,public authserviceToken: AuthenService) {}

    ngOnInit(): void {
        let _s = this;

        _s.statusinstruction = [
            { value:'0' , title: "Active" },
            { value:'1' , title: "InActive" }
        ]

        this.Data_SessionStorage = JSON.parse(sessionStorage.datainfo);
        //console.log('Data_SessionStorage => ',this.Data_SessionStorage);
        this.SessionEmpID = this.Data_SessionStorage[0].employeE_ID;

        this.func_Getlist();
    }

    func_Getlist () {
        const _s = this;
        let datavalue = {
            SessionEmpID: this.SessionEmpID,
            Module: "getlist",
            body: {
                InstructionID :"",
                InstructionName :"",
                Area :"",
            }
        }

        _s.mserve.GetInstruction(datavalue).then((response) => {
            if (response.data.status ){
                //console.log('Data : ',response.data.body);
                _s.tableinstruction = response.data.body;
                _s.loading = false;
            } else {
                //console.log('false', response)
                alert(response.data.errorMessage);
            }
        }).catch((e) => {
            this.authserviceToken.CheckTokenExpire(e.response.status)
            });
    }

    func_addinstruction(item, mode) {
        let _s = this;
        if (mode === 'Add') {
            _s.Module = 'insert'
            _s.Area = '';
            _s.Page = '';
            _s.Field = '';
            _s.InstructionName = '';
            _s.status = '0';
        } else {
         
            _s.Module = 'update'
            _s.InstructionID = item.instructionID;
            _s.Area = item.area;
            _s.Page = item.page;
            _s.Field = item.field;
            _s.InstructionName = item.instructionName;
            _s.status = item.delFlag;
        }
        $('#modaladdinstruction').modal('show')
    }
    func_deleteinstruction(item) {
   var   _s  = this
     _s.Module = 'update'
     _s.InstructionID = item.instructionID;
     _s.Area = item.area;
     _s.Page = item.page;
     _s.Field = item.field;
     _s.InstructionName = item.instructionName;
     _s.status = '1';    
     
        $('#modalAlertwarningDeleteItem').modal('show')
    }
    confirmDelete(){
     $('#modalAlertwarningDeleteItem').modal('hide')
     this.func_savebtn();
    
    }
    cancleDelete(){
           
        $('#modalAlertwarningDeleteItem').modal('hide')
    
    }
    func_savebtn() {
        
        let _s = this;
        let chkReq = true;

        if (_s.Page == '' || _s.Page == null) {
            alert('Please fill Page');
            chkReq = false;
        } 
        else if (_s.Field == '' || _s.Field == null) {
            alert('Please fill Field');
            chkReq = false;
        }
        else if (_s.InstructionName == '' || _s.InstructionName == null ) {
            alert('Please fill Instruction');
            chkReq = false;
        }

        if (chkReq === true) {
            _s.loading = true;
            let datavalue = {
                SessionEmpID:this.SessionEmpID,
                Module:_s.Module,
                    body:{
                        InstructionID:_s.InstructionID,
                        Area:_s.Area,
                        Page:_s.Page,
                        Field:_s.Field,
                        InstructionName:_s.InstructionName,
                        DelFlag :_s.status
                    }
            }
            _s.mserve.GetInstruction(datavalue).then((response) => {
                if(response.data.status === true){
                    //console.log("true",response.data.body);
                    _s.status = '';
                    _s.func_Getlist();
                    $('#modaladdinstruction').modal('hide')
                }else{
                    //console.log('false',response)
                    alert(response.data.ErrorMessage);
                }
            }).catch((e) => {
                this.authserviceToken.CheckTokenExpire(e.response.status)
                });
        }

    }

    func_closebtn() {
        $('#modaladdinstruction').modal('hide')
    }
  
}