<div class="container-fluid appform my-3">
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">


            <div class="col-12 col-md-12 col-lg-12 col-xl-12 pb-5 px-0" id="showdisplay">
                <div class="px-0 pb-2 min-width">
                    <div class="row col-12 col-xl-12 px-0 mx-0" style="border:1.5px solid #93b8c5">
                        <div class="row col-11 col-xl-11 px-0 mx-0" style="border:1.5px solid #93b8c5">
                            <div class="col-3 col-xl-3 p-4" style="background: #077ab4;color: white;"
                                style="text-align: center;">
                                <h4 class="HeaderRiskname m-0">Risk Name/Risk ID</h4>
                                <h4 class="HeaderRiskname m-0">{{ Register_Data.Regis_RiskNo }}</h4>
                            </div>
                            <div class="col-9 px-0">
                                <input type="text" class="form-control border-0 HeaderRiskname-input"
                                    style="font-size:30px;" [(ngModel)]="Register_Data.Regis_Riskname" disabled>
                            </div>
                        </div>
                        <div class="row col-1 col-xl-1 px-0 mx-0" style="border:1.5px solid #93b8c5;">
                            <div class="row col-2 px-0"></div>
                            <div class="row col-10 px-0" style="margin-left: 16px;">
                                <div class="col-12 px-0 tablerisk-card">
                                    <div class="card-body row m-0 p-1">
                                        <div class="col-12 p-0">
                                            <table class="custom" id="test2">
                                                <caption style="display: none;">This is a custom table</caption>
                                                <tr>
                                                    <th></th>
                                                </tr>
                                                <!-- list.risk.risktable ,data_risk_table -->
                                                <tr *ngFor="let item1 of  list.risk.risktable; index as index1;">
                                                    <td *ngFor="let item2 of item1.col; index as index2;" class="box">
                                                        <div class="content text-center" class="{{item2.class}}">
                                                            <ng-container
                                                                *ngFor="let item3 of item2.item; index as index3;">
                                                                <span *ngIf="item2.class === 'high'">
                                                                    <span class="badge badge-pill badge-light txt-title"
                                                                        [ngStyle]="{'background-color': item3.bgcolor, 'color': item3.color}"
                                                                        data-toggle="tooltip"
                                                                        data-placement="top"><strong>{{item3.title}}</strong></span>
                                                                </span>
                                                                <span *ngIf="item2.class === 'medium'">
                                                                    <span class="badge badge-pill badge-light txt-title"
                                                                        [ngStyle]="{'background-color': item3.bgcolor, 'color': item3.color}"
                                                                        data-toggle="tooltip"
                                                                        data-placement="top"><strong>{{item3.title}}</strong></span>
                                                                </span>
                                                                <span *ngIf="item2.class === 'low'">
                                                                    <span class="badge badge-pill badge-light txt-title"
                                                                        [ngStyle]="{'background-color': item3.bgcolor, 'color': item3.color}"
                                                                        data-toggle="tooltip"
                                                                        data-placement="top"><strong>{{item3.title}}</strong></span>
                                                                </span>
                                                                <span *ngIf="item2.class === 'Escalation'">
                                                                    <span class="badge badge-pill badge-light txt-title"
                                                                        [ngStyle]="{'background-color': item3.bgcolor, 'color': item3.color}"
                                                                        data-toggle="tooltip"
                                                                        data-placement="top"><strong>{{item3.title}}</strong></span>
                                                                </span>
                                                            </ng-container>
                                                        </div>
                                                    </td>
                                                <tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mt-4 pt-4">

                        <div class="col-12 col-xl-12 mb-4">
                            <div class="col-12 col-xl-12">
                                <h5><img alt="" src="../../../assets/logo/Vector-Smart-Object2.gif" height="30px"
                                        width="40px">&nbsp;&nbsp;&nbsp;<span class="header-top">Risk Status</span></h5>
                            </div>
                            <div class="row col-12 col-xl-12 mt-3 mb-3 mx-0 px-3 d-flex">
                                <div class="row col-12 col-xl-3">
                                    <div class="col-12 mb-2 px-0">
                                        <strong class="headerin">Status</strong>
                                        <select class="form-control mt-2" [(ngModel)]="Register_Data.Regis_Status" disabled>
                                            <option value="">Please Select</option>
                                            <option *ngFor="let item of StatusIden" value="{{ item.value }}">{{
                                                item.text }}</option>
                                        </select>
                                    </div>
                                    <!-- <div class="col-12 px-0">
                                        <select class="form-control" [(ngModel)]="Register_Data.Regis_Status" disabled>
                                            <option value="">Please Select</option>
                                            <option *ngFor="let item of StatusIden" value="{{ item.value }}">{{
                                                item.text }}</option>
                                        </select>
                                    </div> -->
                                </div>
                                <div class="row col-12 col-xl-5 ml-4" *ngIf="Register_Data.Regis_Status === '4' || Register_Data.Regis_Status === '2'">
                                    <div class="col-12 px-0">
                                        <strong class="headerin">Reason/Detail(s)<label style="color:red;">*</label></strong>
                                    </div>
                                    <div class="col-12 px-0">
                                        <!-- <input type="text" class="form-control" style="height: 50px;"> -->
                                        <textarea class="form-control" autosize id="CloseComment" rows="3"
                                            [(ngModel)]="Risk_Close_Reason" [disabled]="true"></textarea>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-xl-12 mb-4">
                            <div class="col-12 col-xl-12">
                                <h5><img alt="" src="../../../assets/logo/Vector-Smart-Object2.gif" height="30px"
                                        width="40px">&nbsp;&nbsp;&nbsp;<span class="header-top">Connected to</span></h5>
                            </div>
                            <div class="row col-12 col-xl-12 mt-3 mb-3 mx-0 px-0 d-flex justify-content-around">
                                <div class="row col-3 col-xl-3">
                                    <div class="col-12 mb-2 px-0">
                                        <strong class="headerin">Risk Category</strong>
                                    </div>
                                    <div class="col-12 px-0">
                                        <select class="form-control" [(ngModel)]="Register_Data.Regis_Category"
                                            disabled>
                                            <option *ngFor="let data of RiskCategory" value="{{ data.value }}">{{
                                                data.text }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row col-5 col-xl-5">
                                    <div class="col-12 mb-2 px-0">
                                        <strong class="headerin">Corporate Target\Objective</strong>
                                    </div>
                                    <div class="col-4 pl-0">
                                        <select class="form-control" [(ngModel)]="Register_Data.Regis_ObjectiveHead"
                                            disabled>
                                            <option *ngFor="let item of CorpTargetHead" value="{{ item.value }}">{{
                                                item.text }}</option>
                                        </select>
                                    </div>
                                    <div class="col-8">
                                        <select class="form-control" [(ngModel)]="Register_Data.Regis_Objective"
                                            disabled>
                                            <option *ngFor="let item of CorpTargetItem" value="{{ item.value }}">{{
                                                item.text }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row col-4 col-xl-4">
                                    <div class="col-12 mb-2 px-0">
                                        <strong class="headerin">Business Unit KPI</strong>
                                    </div>
                                    <div class="col-12 px-0">
                                        <input type="text" class="form-control" [(ngModel)]="Register_Data.Regis_KPI"
                                            disabled>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 mb-4 mt-5">
                            <div class="col-12 mb-3">
                                <h5><img alt="" src="../../../assets/logo/Vector-Smart-Object.gif" height="35"
                                        width="35">&nbsp;&nbsp;&nbsp;<span class="header-top">To Assess, Mitigate</span>
                                </h5>
                            </div>
                            <div class="row col-12 col-xl-12 mx-0 px-0">
                                <div class="col-4 col-xl-4">
                                    <table class="table-bordered" style="width: 100%;">
                                        <caption style="display: none;">This is a custom table</caption>
                                        <thead class="rootcause">
                                            <th class="th-header">
                                                <div class="row col-12 mx-0 px-0">
                                                    <div class="col-8 px-0 py-1">
                                                        <span style="font-size: 22px;">2.1 Root Cause</span>
                                                    </div>
                                                </div>
                                            </th>
                                        </thead>
                                        <tbody class="rootcausein">
                                            <tr *ngFor="let data of Assert_RootCauseTable index as index">
                                                <td (click)="func_editrootcause(data.id)" style="cursor: pointer;">
                                                    <div class="row col-12 mx-0 px-0">
                                                        <div class="col-1 px-0"
                                                            [ngClass]="{'highlight': data.highlight == 'Y'}"
                                                            style="text-align: center;">
                                                            {{ index+1 }}.
                                                        </div>
                                                        <div class="col-11 px-0"
                                                            [ngClass]="{'highlight': data.highlight == 'Y'}">
                                                            {{ data.title }}
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-1 col-xl-1 pt-4" style="text-align: center;" style="color:#00adef">
                                    <em class="far fa-arrow-alt-circle-right" style="font-size: 60px;"></em>
                                </div>
                                <div class="col-2 col-xl-2 px-0" style="text-align: center;">

                                    <table style="text-align: center; width: 100%;">
                                        <caption style="display: none;">This is a custom table</caption>
                                        <thead>
                                            <tr class="riskname">
                                                <th class="th-header"><span style="font-size: 22px;">Risk Name</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody class="rootcausein">
                                            <td>
                                                <label class="p-1">{{ Register_Data.Regis_Riskname }}</label>
                                            </td>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-1 col-xl-1 pt-4" style="text-align: center;" style="color:#bd9e5e">
                                    <em class="far fa-arrow-alt-circle-right" style="font-size: 60px;"></em>
                                </div>
                                <div class="col-4 col-xl-4">
                                    <table class="table-bordered" style="width: 100%;">
                                        <caption style="display: none;">This is a custom table</caption>
                                        <thead class="impact">
                                            <th colspan="2" class="th-header">
                                                <div class="row col-12 mx-0 px-0">
                                                    <div class="col-8 px-0 py-1">
                                                        <span style="font-size: 22px;">2.2
                                                            Impact&nbsp;&nbsp;&nbsp;</span>
                                                    </div>
                                                </div>
                                            </th>
                                        </thead>
                                        <tbody class="rootcausein">
                                            <tr *ngFor="let data of Assert_ImpactTable index as index">
                                                <td (click)="func_editimpact(data.id)" style="cursor: pointer;">
                                                    <div class="row col-12 mx-0 px-0">
                                                        <div class="col-1 px-0"
                                                            [ngClass]="{'highlight': data.highlight == 'Y'}"
                                                            style="text-align: center;">
                                                            {{ index+1 }}.
                                                        </div>
                                                        <div class="col-11 px-0"
                                                            [ngClass]="{'highlight': data.highlight == 'Y'}">
                                                            {{ data.title }}
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-4 mx-0 px-3 pb-4" *ngIf="temprootkriFlag == true">
                        <strong style="font-size: 24px;">RootCause KRI:</strong>
                        <div class="mt-2" style="text-align: center;">
                            <table class="table-bordered form-table" style="width: 100%;table-layout: fixed;">
                                <caption style="display: none;">This is a custom table</caption>
                                <thead style="text-align: center;font-size: 22px;">
                                    <tr>
                                        <th style="width: 45px;background-color: #9eb0ba;">No.</th>
                                        <th style="width: 125px;background-color: #00aeef;">Root Cause</th>
                                        <th style="width: 300px;background-color: #00aeef;">KRI Name</th>
                                        <th style="width: 120px;background-color: #72b704;">KRI Green Threshold</th>
                                        <th style="width: 100px;background-color: #dc3545;">KRI Red Threshold</th>
                                        <th style="width: 150px;background-color: #023154;">KRI Status</th>
                                        <th style="width: 200px;background-color: #023154;">KRI Status/KRI Justification
                                        </th>
                                    </tr>
                                </thead>
                                <tbody style="font-size: 20px;">
                                    <tr *ngFor="let data of temprootkri index as index">
                                        <td style="text-align: center;">{{ (index + 1) }}</td>
                                        <td>{{ data.rootcause }}</td>
                                        <td>{{ data.rootCause_KRI_Name }}</td>
                                        <td>{{ data.rootCause_KRI_Threshold_Green }}</td>
                                        <td>{{ data.rootCause_KRI_Threshold_Red }}</td>
                                        <td
                                            [ngStyle]="{'background-color': (data.rootCause_KRI_Status == '0' || data.rootCause_KRI_Status == 'green') ? '#72b704' 
                                                        : (data.rootCause_KRI_Status == '1' || data.rootCause_KRI_Status == 'yellow') ? '#ffff00'
                                                        : (data.rootCause_KRI_Status == '2' || data.rootCause_KRI_Status == 'red') ? '#dc3545' : null}">
                                        </td>
                                        <td>{{ data.rootCause_KRI_Justification }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-12 mt-4 mx-0 px-3 pb-4">
                        <strong style="font-size: 24px;">Root Cause Mitigation Plan:</strong>
                        <div class="mt-2" style="text-align: center;">
                            <table class="table-bordered form-table" style="width: 100%;table-layout: fixed;">
                                <caption style="display: none;">This is a custom table</caption>
                                <thead style="text-align: center;font-size: 22px;">
                                    <tr>
                                        <th style="width: 45px;background-color: #9eb0ba;">No.</th>
                                        <th style="width: 125px;background-color: #00aeef;">Root Cause</th>
                                        <th style="width: 300px;background-color: #00aeef;">Mitigation Plan</th>
                                        <th style="width: 120px;background-color: #023154;">Mitigation Owner</th>
                                        <th style="width: 100px;background-color: #023154;">Due Date</th>
                                        <th style="width: 150px;background-color: #023154;">Mitigation Progress</th>
                                        <th style="width: 200px;background-color: #023154;">Mitigation
                                            Status/Justification</th>
                                    </tr>
                                </thead>
                                <tbody style="font-size: 20px;">
                                    <tr *ngFor="let data of temprootmitagation index as index">
                                        <td style="text-align: center;" *ngIf="data.samehead === '1'"
                                            [attr.rowspan]="data.numcol">{{ data.number }}</td>
                                        <td *ngIf="data.samehead === '1'" [attr.rowspan]="data.numcol">{{ data.header }}
                                        </td>
                                        <td>{{ data.plan }}</td>
                                        <td style="text-align: center;">{{ data.owner }}</td>
                                        <td style="text-align: center;">{{ data.date }}</td>
                                        <td style="text-align: center;">
                                            <strong><span>{{ data.progress }}</span>%</strong><br>
                                            <div class="slidecontainer">
                                                <input type="range" min="0" max="100" value="{{ data.progress }}"
                                                    class="slider" disabled>
                                            </div>
                                        </td>
                                        <td>{{ data.justification }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-12 mt-4 mx-0 px-3 pb-4">
                        <strong style="font-size: 24px;">Impact Mitigation Plan:</strong>
                        <div class="mt-2" style="text-align: center;">
                            <table class="table-bordered form-table" style="width: 100%;table-layout: fixed;">
                                <caption style="display: none;">This is a custom table</caption>
                                <thead style="text-align: center;font-size: 22px;">
                                    <tr>
                                        <th style="width: 45px;background-color: #9eb0ba;">No.</th>
                                        <th style="width: 125px;background-color: #00aeef;">Impact</th>
                                        <th style="width: 300px;background-color: #00aeef;">Mitigation Plan</th>
                                        <th style="width: 120px;background-color: #023154;">Mitigation Owner</th>
                                        <th style="width: 100px;background-color: #023154;">Due Date</th>
                                        <th style="width: 150px;background-color: #023154;">Mitigation Progress</th>
                                        <th style="width: 200px;background-color: #023154;">Mitigation
                                            Status/Justification</th>
                                    </tr>
                                </thead>
                                <tbody style="font-size:20px">
                                    <tr *ngFor="let data of tempImpactmitagation index as index">
                                        <td style="text-align: center;" *ngIf="data.samehead === '1'"
                                            [attr.rowspan]="data.numcol">{{ data.number }}</td>
                                        <td *ngIf="data.samehead === '1'" [attr.rowspan]="data.numcol">{{ data.header }}
                                        </td>
                                        <td>{{ data.plan }}</td>
                                        <td style="text-align: center;">{{ data.owner }}</td>
                                        <td style="text-align: center;">{{ data.date }}</td>
                                        <td style="text-align: center;">
                                            <strong><span>{{ data.progress }}</span>%</strong><br>
                                            <div class="slidecontainer">
                                                <input type="range" min="0" max="100" value="{{ data.progress }}"
                                                    class="slider" disabled>
                                            </div>
                                        </td>
                                        <td>{{ data.justification }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-6 mt-4 mx-0 px-3 pb-4" *ngIf="Register_Data.AttachFiles.length != 0">
                        <strong style="font-size: 24px;">File Attachment:</strong>
                        <div class="mt-2" style="text-align: center;">
                            <table class="table-bordered form-table" style="width: 100%;table-layout: fixed;">
                                <caption style="display: none;">This is a custom table</caption>
                                <thead style="text-align: center;font-size: 22px;">
                                    <tr>
                                        <th style="width: 10%;background-color: #9eb0ba;">No.</th>
                                        <th style="width: 90%;background-color: #00aeef;">Name</th>
                                    </tr>
                                </thead>
                                <tbody style="font-size:20px">
                                    <tr *ngFor="let data of Register_Data.AttachFiles index as index">
                                        <td style="text-align: center;">{{ (index + 1) }}</td>
                                        <td>
                                            <span style="cursor: pointer; color:#017BFE" (click)="downloadfile(data.pathFile)">{{ data.fileName }}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="mt-4">
                        <div class="col-12 px-0">
                            <div class="row col-12 col-xl-12 px-0 mx-0 mb-3">
                                <strong style="font-size: 24px;">Scope, Context, Criteria</strong>
                            </div>
                            <div class="row col-12 mx-0 px-0 d-flex justify-content-around">
                                <div class="col-12 col-xl-12 px-0">
                                    <textarea class="form-control" (keyup)="autoGrowTextZone($event)"
                                        (click)="autogrow()" id="RegisContext" rows="5"
                                        [(ngModel)]="Register_Data.Regis_Context" disabled></textarea>
                                </div>
                                <div class="row col-12 mt-3 mx-0 px-0"
                                    *ngIf="statuspage !== 'Review' && statuspage !== 'Reviewfromdashboard' && statuspage !== 'Reviewfromsearch' && statuspage !== 'Reviewfromconsol' && statuspage !== 'ReviewfromTodo' && statuspage !== 'ReviewfromTransfer' && statuspage !== 'ReviewfromComparedashboard' ">
                                    <div class="col-6 pr-0" style="text-align: right;">
                                        <button type="button" class="btn btn-back" (click)="func_Back()"><strong>
                                                < BACK</strong></button>
                                    </div>
                                    <div class="col-6 pr-0">
                                        <button type="button" class="btn btn-next border-0"
                                            (click)="func_todolist()"><strong>NEXT ></strong></button>
                                    </div>
                                </div>
                                <div class="row col-12 col-xl-12 mt-3 mx-0 px-0" style="text-align: center;"
                                    *ngIf="statuspage === 'ReviewfromTodo'">
                                    <div class="col-12 pr-0">
                                        <button type="button" class="btn btn-back mr-2"
                                            [routerLink]="['/Approval', 1]"><strong>BACK</strong></button>
                                        <button type="button" class="btn btn-sub-myrisk mr-2"
                                            (click)="func_ApproveFromReview()"
                                            *ngIf="Register_Data.Regis_Role ==='Owner'"><strong>Approve</strong></button>
                                        <button type="button" class="btn btn-sub-reject"
                                            (click)="func_RejectFromReview()"
                                            *ngIf="Register_Data.Regis_Role ==='Owner'"><strong>Reject</strong></button>
                                    </div>
                                </div>
                                <div class="row col-12 col-xl-12 mt-3 mx-0 px-0" style="text-align: center;"
                                    *ngIf="statuspage === 'Review'">
                                    <div class="col-12 pr-0">
                                        <button type="button" class="btn btn-back"
                                            [routerLink]="['/Approval', 2]"><strong>BACK</strong></button>
                                    </div>
                                </div>
                                <div class="row col-12 col-xl-12 mt-3 mx-0 px-0" style="text-align: center;"
                                    *ngIf="statuspage === 'Reviewfromdashboard'">
                                    <div class="col-12 pr-0">
                                        <button type="button" class="btn btn-back"
                                            (click)="BackDashboard()"><strong>BACK</strong></button>
                                    </div>
                                </div>
                                <div class="row col-12 col-xl-12 mt-3 mx-0 px-0" style="text-align: center;"
                                    *ngIf="statuspage === 'Reviewfromsearch'">
                                    <div class="col-12 pr-0">
                                        <button type="button" class="btn btn-back"
                                            [routerLink]="['/SearchRisk']"><strong>BACK</strong></button>
                                    </div>
                                </div>
                                <div class="row col-12 col-xl-12 mt-3 mx-0 px-0" style="text-align: center;"
                                    *ngIf="statuspage === 'Reviewfromconsol'">
                                    <div class="col-12 pr-0">
                                        <button type="button" class="btn btn-back"
                                            [routerLink]="['/ConsolidateRisk']"><strong>BACK</strong></button>
                                    </div>
                                </div>
                                <div class="row col-12 col-xl-12 mt-3 mx-0 px-0" style="text-align: center;"
                                    *ngIf="statuspage === 'ReviewfromTransfer'">
                                    <div class="col-12 pr-0">
                                        <button type="button" class="btn btn-back"
                                            (click)="checkBack()"><strong>BACK</strong></button>
                                    </div>
                                </div>
                                <div class="row col-12 col-xl-12 mt-3 mx-0 px-0" style="text-align: center;"
                                    *ngIf="statuspage === 'ReviewfromComparedashboard'">
                                    <div class="col-12 pr-0">
                                        <button type="button" class="btn btn-back"
                                            (click)="BackCompareDashboard()"><strong>BACK</strong></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade hide bd-Rootcause px-0" tabindex="-1" role="dialog" aria-hidden="true"
            id="modalrootcasue">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">

                    <div class="modal-body container-fluid appform my-3">
                        <div class="row" style="min-width:1100px;">
                            <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">
                                <div class="col-12 col-md-12 col-lg-12 col-xl-12 pb-5 mt-5 mb-5 ">
                                    <div class="col-12 mt-5" style="text-align: center;">
                                        <h2 style="text-align: left;color: #0176ac;">2.1 Root Cause</h2>
                                        <hr style=" border-top: 6px solid #0176ac; ">
                                    </div>

                                    <div class="card mt-4">
                                        <div class="row col-12 mx-0 px-0 d-flex justify-content-around">

                                            <div class="row col-12 col-md-12 col-xl-6 form-group px-0">
                                                <div class="col-12">
                                                    <strong class="headerin">Root Cause :<label
                                                            style="color: red;">*</label></strong>
                                                </div>
                                                <div class="col-12">
                                                    <input type="text" class="form-control"
                                                        [(ngModel)]="Register_Rootcause.Root_RootCause" disabled>
                                                </div>
                                            </div>
                                            <div class="row col-12 col-md-6 col-xl-3 form-group px-0">
                                                <div class="col-12">
                                                    <strong class="headerin">Likelihood :<label
                                                            style="color: red;">*</label></strong>
                                                </div>
                                                <div class="col-12">
                                                    <select class="form-control"
                                                        [(ngModel)]="Register_Rootcause.Root_RootLikelyhood" disabled>
                                                        <option value="">Please Select</option>
                                                        <option *ngFor="let data of likelyhoodLevel"
                                                            value="{{ data.value }}">{{ data.text }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="row col-12 col-md-6 col-xl-3 form-group px-0">
                                                <div class="col-12">
                                                    <strong class="headerin">Mitigation type :<label
                                                            style="color: red;">*</label></strong>
                                                </div>
                                                <div class="col-12">
                                                    <select class="form-control"
                                                        [(ngModel)]="Register_Rootcause.Root_RootMitigationType"
                                                        disabled>
                                                        <option value="">Please Select</option>
                                                        <option *ngFor="let data of MitigationtypeRootcause"
                                                            value="{{ data.title }}">{{ data.title }}</option>
                                                    </select>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="card col-12 mt-4 mx-0 px-3 pb-4">
                                        <strong class="headerin">Root Cause Mitigation Plan:<label
                                                style="color: red;">*</label></strong>
                                        <div class="mt-2" style="text-align: center;">
                                            <table class="table-bordered form-table" style="width: 100%;">
                                                <caption style="display: none;">This is a custom table</caption>
                                                <thead style="text-align: center; font-size: 22px;">
                                                    <tr style="background-color: #00aeef;color: #00338D;">
                                                        <th style="width: 10px;background-color: #9eb0ba;">No.</th>
                                                        <th style="width: 500px;background-color: #00aeef;">Mitigation
                                                            Plan</th>
                                                        <th style="width: 50px;background-color: #023154;">Mitigation
                                                            Owner</th>
                                                        <th style="width: 180px;background-color: #023154;">Due Date
                                                        </th>
                                                        <th style="width: 50px;background-color: #023154;">Mitigation
                                                            Progress</th>
                                                        <th style="width: 200px;background-color: #023154;">Mitigation
                                                            Status/Justification</th>
                                                    </tr>
                                                </thead>
                                                <tbody style="font-size: 20px;">
                                                    <tr *ngFor="let data of temptable index as index">
                                                        <td style="text-align: center;">
                                                            <div>
                                                                {{ index+1 }}.
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {{ data.plan }}
                                                        </td>
                                                        <td style="text-align: center;" style="width:50px;">
                                                            {{ data.owner }}
                                                        </td>
                                                        <td style="text-align: center;">
                                                            {{ data.Duedate }}
                                                        </td>
                                                        <td style="text-align: center;">
                                                            <strong><span>{{ data.Progess }}</span>%</strong><br>
                                                            <div class="slidecontainer">
                                                                <input type="range" min="0" max="100"
                                                                    value="{{ data.Progess }}" class="slider" disabled>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {{ data.justification }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="card mt-4">
                                        <div class="row col-12 mx-0">

                                            <div class="row col-12 col-md-12 col-xl-4 form-group px-0">
                                                <div class="col-12">
                                                    <strong class="headerin">Likelihood after Mitigated:<label
                                                            style="color: red;">*</label></strong>
                                                </div>
                                                <div class="col-12 mt-2">
                                                    <select class="form-control"
                                                        [(ngModel)]="Register_Rootcause.Root_AfterMitigated" disabled>
                                                        <option value="">Please Select</option>
                                                        <option *ngFor="let data of likelyhoodLevelAfter"
                                                            value="{{ data.value }}">{{ data.text }}</option>
                                                    </select>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="mt-4">
                                            <div class="row col-12 mx-0">

                                                <div class="row col-12 col-md-12 col-xl-6 form-group px-0">
                                                    <div class="col-12">
                                                        <strong class="headerin">KRI Name:</strong>
                                                    </div>
                                                    <div class="col-12 mt-2">
                                                        <input type="text" class="form-control"
                                                            [(ngModel)]="Register_Rootcause.Root_KRIName" disabled>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="mt-4">
                                            <div class="row col-12 mx-0 d-flex justify-content-around px-0">
                                                <div class="col-12 mb-3">
                                                    <strong class="headerin">KRI Threshold:</strong>
                                                </div>

                                                <div class="row col-12 col-md-12 col-xl-6 form-group px-4">
                                                    <div class="col-12" style="background: #72b704;">
                                                        <strong style="color: white;font-size: 20px;">Green</strong>
                                                    </div>
                                                    <div class="col-12 px-0" style="border: 1.5px solid #72b704;">
                                                        <input type="text" class="form-control"
                                                            [(ngModel)]="Register_Rootcause.Root_KRIThreshold_Green"
                                                            disabled>
                                                    </div>
                                                </div>

                                                <div class="row col-12 col-md-12 col-xl-6 form-group px-4">
                                                    <div class="col-12 bg-red">
                                                        <strong style="font-size: 20px;">Red</strong>
                                                    </div>
                                                    <div class="col-12 px-0" style="border: 1.5px solid #dc3545;">
                                                        <input type="text" class="form-control"
                                                            [(ngModel)]="Register_Rootcause.Root_KRIThreshold_Red"
                                                            disabled>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="mt-4">
                                            <div class="row col-12 mx-0">
                                                <div class="col-12 mb-3 px-0">
                                                    <strong class="headerin">KRI Status:</strong>
                                                </div>

                                                <div class="row col-12 col-md-12 col-xl-6 form-group px-4">
                                                    <div class="col-4">
                                                        <label class="container_radio hover_radio1">
                                                            <div class="rounded active" style="text-align: center;"
                                                                style="border:1px solid #72b704;" id="rdo1">&nbsp;</div>
                                                            <input type="radio" name="radio" (click)="selectedradio(1)"
                                                                value="green"
                                                                [(ngModel)]="Register_Rootcause.Root_KRIStatus"
                                                                disabled>
                                                            <span class="checkmark chk1 mt-1"></span>
                                                        </label>
                                                    </div>
                                                    <div class="col-4">
                                                        <label class="container_radio hover_radio2">
                                                            <div class="rounded active2" style="text-align: center;"
                                                                style="border:1px solid #ffff00;" id="rdo2">&nbsp;</div>
                                                            <input type="radio" name="radio" (click)="selectedradio(2)"
                                                                value="yellow"
                                                                [(ngModel)]="Register_Rootcause.Root_KRIStatus"
                                                                disabled>
                                                            <span class="checkmark chk2 mt-1"></span>
                                                        </label>
                                                    </div>
                                                    <div class="col-4">
                                                        <label class="container_radio hover_radio3">
                                                            <div class="rounded active3" style="text-align: center;"
                                                                style="border:1px solid red;" id="rdo3">&nbsp;</div>
                                                            <input type="radio" name="radio" (click)="selectedradio(3)"
                                                                value="red"
                                                                [(ngModel)]="Register_Rootcause.Root_KRIStatus"
                                                                disabled>
                                                            <span class="checkmark chk3 mt-1"></span>
                                                        </label>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="mt-4">
                                            <div class="row col-12 mx-0">
                                                <div class="col-12 mb-3 px-0">
                                                    <strong class="headerin">KRI Status/KRI Justification:</strong>
                                                </div>

                                                <div class="row col-12 col-md-12 col-xl-8 form-group px-0">
                                                    <div class="col-12">
                                                        <textarea class="form-control" rows="3"
                                                            [(ngModel)]="Register_Rootcause.Root_Justification"
                                                            disabled></textarea>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12 my-3 mx-0 text-center">
                                    <button type="button" class="btn btn-back mr-3" (click)="func_close()">
                                        <em class="far fa-window-close"
                                            style="font-size:26px;"></em><strong>&nbsp;&nbsp;Close</strong>
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="modal fade hide bd-Impact px-0" tabindex="-1" role="dialog" aria-hidden="true" id="modalimpact">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">

                    <div class="modal-body container-fluid appform my-3">
                        <div class="row" style="min-width:1100px;">
                            <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">

                                <div class=" col-12 col-md-12 col-lg-12 col-xl-12 pb-5 mt-5 mb-5">

                                    <div class="col-12 mt-5" style="text-align: center;">
                                        <h2 style="text-align: left;color: #0176ac;">2.2 Impact</h2>
                                        <hr style=" border-top: 6px solid #0176ac; ">
                                    </div>
                                    <div class="card mt-4">
                                        <div class="row col-12 mx-0 px-0 d-flex justify-content-around">
                                            <div class="row col-12 col-md-12 col-xl-12 form-group px-0">
                                                <div class="col-4">
                                                    <strong class="headerin">Impact Category :<label
                                                            style="color: red;">*</label></strong>
                                                </div>
                                                <!-- <div class="col-4"
                                                    [hidden]="Register_Impact.Impact_Category === '1' ||  Register_Impact.Impact_Category === '' ">
                                                    <strong class="headerin">Impact Description :<label
                                                            style="color: red;">*</label></strong>
                                                </div> -->
                                            </div>

                                            <div class="col-12 col-md-12 col-xl-4 form-group px-0">
                                                <div class="col-12">
                                                    <select class="form-control"
                                                        [(ngModel)]="Register_Impact.Impact_Category" disabled>
                                                        <option value="">Please Select</option>
                                                        <option *ngFor="let data of ImpactCategory index as index"
                                                            value="{{ data.value }}">{{ data.text }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-xl-2 form-group"
                                                [hidden]="Register_Impact.Impact_Category !== '1'">
                                                <select class="form-control" [(ngModel)]="Register_Impact.Impact_NPTEMV"
                                                    disabled>
                                                    <option value="">Please Select</option>
                                                    <option *ngFor="let data of Financial" value="{{ data.value }}">{{
                                                        data.text }}</option>
                                                </select>
                                            </div>
                                            <div class="col-12 col-md-6 col-xl-2 form-group"
                                                [hidden]="Register_Impact.Impact_Category !== '1'">
                                                <input type="number" class="form-control" style="text-align: right;"
                                                    [(ngModel)]="Register_Impact.Impact_Total_Amont" disabled>
                                            </div>
                                            <div class="col-12 col-md-6 col-xl-4 form-group pt-1"
                                                [hidden]="Register_Impact.Impact_Category !== '1'">
                                                <label>(MM USD)</label>
                                            </div>

                                            <div class="col-12 col-md-6 col-xl-8 form-group"
                                                [hidden]="Register_Impact.Impact_Category === '1'  ||  Register_Impact.Impact_Category === ''  ">
                                                <!-- <input type="text" class="form-control"
                                                    [(ngModel)]="Register_Impact.Impact_Description" disabled> -->
                                            </div>
                                        </div>
                                        <div class="row col-12 mx-0 px-0 d-flex justify-content-around">
                                            <div class="row col-12 col-md-12 col-xl-4 form-group px-0">
                                                <div class="col-12">
                                                    <strong class="headerin">Impact Level :<label
                                                            style="color: red;">*</label></strong>
                                                </div>
                                                <div class="col-12">
                                                    <select class="form-control" [(ngModel)]="Register_Impact.Impact_Level" disabled>
                                                        <option *ngFor="let data of ImpactLevel" value="{{ data.value }}">{{ data.text }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="row col-12 col-md-12 col-xl-4 form-group px-0">
                                                <div class="formtext col-12">
                                                    <strong class="headerin">Mitigation Type :<label
                                                            style="color: red;">*</label></strong>
                                                </div>
                                                <div class="col-12">
                                                    <select class="form-control"
                                                        [(ngModel)]="Register_Impact.Impact_Migation_Type" disabled>
                                                        <option value="">Please Select</option>
                                                        <option *ngFor="let data of MitigationTypeImpact"
                                                            value="{{ data.title }}">{{ data.title }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="row col-12 col-md-12 col-xl-4 form-group px-0"></div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="row col-12 mx-0 my-4">
                                            <div class="col-12 mb-3 px-0">
                                                <strong class="headerin"><img alt=""
                                                        src="../../../assets/logo/Vector-Smart-Object.gif" height="35"
                                                        width="35">
                                                    &nbsp;&nbsp;&nbsp;Impact Description:<span class="text-danger"
                                                        [hidden]="Register_Impact.Impact_Category === '1'">*</span></strong>
                                            </div>
                                            <div class="col-12">
                                                <textarea class="form-control" rows="3"
                                                    (blur)="autoGrowTextZone($event)"
                                                    [(ngModel)]="Register_Impact.Impact_Description"
                                                    disabled></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card col-12 mt-4 mx-0 px-3 pb-4">
                                        <strong class="headerin">Impact Mitigation Plan:<label
                                                style="color: red;">*</label></strong>
                                        <div class="mt-2" style="text-align: center;">
                                            <table class="table-bordered form-table" style="width: 100%;">
                                                <caption style="display: none;">This is a custom table</caption>
                                                <thead style="text-align: center; font-size: 22px;">
                                                    <tr style="background-color: #00aeef;">
                                                        <th style="width: 10px;background-color: #9eb0ba;">No.</th>
                                                        <th style="width: 500px;background-color: #00aeef;">Mitigation
                                                            Plan</th>
                                                        <th style="width: 50px;background-color: #023154;">Mitigation
                                                            Owner</th>
                                                        <th style="width: 180px;background-color: #023154;">Due Date
                                                        </th>
                                                        <th style="width: 50px;background-color: #023154;">Mitigation
                                                            Progress</th>
                                                        <th style="width: 200px;background-color: #023154;">Mitigation
                                                            Status/Justification</th>
                                                    </tr>
                                                </thead>
                                                <tbody style="font-size: 20px;">
                                                    <tr *ngFor="let data of temptableImpact index as index">
                                                        <td style="text-align: center;">
                                                            {{ index+1 }}.
                                                        </td>
                                                        <td>
                                                            {{ data.mitigation }}
                                                        </td>
                                                        <td style="text-align: center;" style="width:50px;">
                                                            {{ data.owner }}
                                                        </td>
                                                        <td style="text-align: center;">
                                                            {{ data.Duedate }}
                                                        </td>
                                                        <td style="text-align: center;">
                                                            <strong><span>{{ data.Progess }}</span>%</strong><br>
                                                            <div class="slidecontainer">
                                                                <input type="range" min="0" max="100"
                                                                    value="{{ data.Progess }}" class="slider" disabled>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {{ data.justification }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="card mt-4">
                                        <div class="row col-12 mx-0">
                                        <!-- ROW 2 -->
                                        <div class="row col-12 mx-0 px-0 d-flex justify-content-around">
                                            <div class="row col-12 col-md-12 col-xl-4 form-group px-0">
                                                <div class="col-12">
                                                    <strong class="headerin">Impact Level after Mitigated:<label style="color: red;">*</label></strong>
                                                    <div class="btn-group dropup helper ml-1">
                                                        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><em class="fas fa-info-circle"></em>
                                                            <div class="dropdown-menu dropdown-menu-left custom-width" style="background-color: rgba(0, 156, 217, 1);">
                                                                <div class="px-1" style="width:100%;color:white;" >
                                                                    (MM USD)
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <select class="form-control"
                                                        id="AfterMitigated"  [(ngModel)]="Register_Impact.Impact_After_Mitigated" disabled>
                                                        <option *ngFor="let data of ImpactLevelAfter"
                                                            value="{{ data.value }}">{{ data.text }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="row col-12 col-md-12 col-xl-4 form-group px-0">
                                                <div class="formtext col-12">
                                                    <strong class="headerin" style="visibility: hidden;">Mitigation Type :<label style="color: red;">*</label></strong>
                                                </div>
                                                <div class="col-12 col-md-6 col-xl-4 mt-2 form-group" [hidden]="Register_Impact.Impact_Category !== '1'">
                                                    <input type="number" [disabled]="true" id="AfterMitigatedValue" class="form-control" style="text-align: right;" min="0" [(ngModel)]="Impact_After_Total_Amont">
                                                </div>
                                                <div class="col-12 col-md-6 col-xl-4 mt-2 form-group pt-1" 
                                                 [hidden]="Register_Impact.Impact_Category !== '1'">
                                                    <label >(MM USD)</label>
                                                </div>
                                            </div>
                                            <div class="row col-12 col-md-12 col-xl-4 form-group px-0"></div>
                                        </div>
                                        <!----------->
                    
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12 my-3 mx-0 text-center">
                                    <button type="button" class="btn btn-back mr-3" (click)="func_close()">
                                        <em class="far fa-window-close"
                                            style="font-size:26px;"></em><strong>&nbsp;&nbsp;Close</strong>
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalAlertConfirmReivew">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <span class="bg-success">&nbsp;</span>
                    <div class="modal-header">
                        <div class="row col-12 text-success">
                            <div class="col-2">
                                <em class="far fa-check-circle" style="font-size:50px;"></em>
                            </div>
                            <div class="col-10 pt-2">
                                <h4 class="modal-title">Alert !</h4>
                            </div>
                        </div>
                    </div>
                    <div class="modal-body text-body border-0">
                        <div class="col-12" style="text-align: left;">
                            <span style="font-size:18px;">Confirm success</span>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-success w-25" (click)="ConfirmOK()">OK</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalAlertReivew">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <span class="bg-danger">&nbsp;</span>
                    <div class="modal-header">
                        <div class="row col-12 text-danger">
                            <div class="col-2">
                                <em class="far fa-times-circle" style="font-size:50px;"></em>
                            </div>
                            <div class="col-10 pt-2">
                                <h4 class="modal-title">Alert !</h4>
                            </div>
                        </div>
                    </div>
                    <div class="modal-body text-body border-0">
                        <div class="col-12" style="text-align: left;">
                            <span style="font-size:18px;">Comment cannot be blank</span>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger w-25" (click)="ConfirmOKAlert()">OK</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalComment">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <span class="bg-info">&nbsp;</span>
                    <div class="modal-header">
                        <div class="row col-12 text-info">
                            <div class="col-2">
                                <em class="fas fa-info-circle" style="font-size:50px;"></em>
                            </div>
                            <div class="col-10 pt-2">
                                <h4 class="modal-title">Info Require</h4>
                            </div>
                        </div>
                    </div>
                    <div class="modal-body text-body border-0">
                        <div class="row col-12 mx-0 px-0">
                            <div class="col-12 mb-2">
                                <strong style="font-size:18px;">Fill in the Comment</strong>
                            </div>
                            <div class="col-12">
                                <input type="text" class="form-control" style="font-size:18px;"
                                    [(ngModel)]="commentReject">
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-info w-25" (click)="ConfirmComment()"
                            [disabled]="commentReject === ''">OK</button>
                        <button type="button" class="btn btn-secondary w-25" (click)="CloseComment()">Close</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalAlertdanger5">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <span class="bg-danger">&nbsp;</span>
                    <div class="modal-header">
                        <div class="row col-12 text-danger">
                            <div class="col-2">
                                <em class="far fa-times-circle" style="font-size:50px;"></em>
                            </div>
                            <div class="col-10 pt-2">
                                <h4 class="modal-title">Alert !</h4>
                            </div>
                        </div>
                    </div>
                    <div class="modal-body text-body border-0">
                        <div class="col-12" style="text-align: left;">
                            <span style="font-size:18px;">{{ alertflow }}</span>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger w-25" (click)="closeflow()">OK</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" id="showdisplay2" *ngIf="loading === false">
        <div class="col-12 px-0 pt-3 pb-3 mb-3" style="font-size: 14px;background-color: #edf0f7;">
            <div class="min-width">
                <div class="col-12 px-3">
                    <strong style="font-size: 24px;">Action History</strong>
                    <div class="mt-2">
                        <table class="table-bordered form-table" style="width: 100%;">
                            <caption style="display: none;">This is a custom table</caption>
                            <thead style="text-align: center;font-size: 22px;">
                                <tr style="background-color: #00aeef;color: #00338D;">
                                    <th style="width:15%;background-color: #023154;">Date and Time</th>
                                    <th style="width:15%;background-color: #00aeef;">Name</th>
                                    <th style="width:10%;background-color: #bd9e5e;">Department</th>
                                    <th style="width:10%;background-color: #023154;">Action</th>
                                    <th style="width:45%;background-color: #023154;">Comments</th>
                                </tr>
                            </thead>
                            <tbody class="font-size-his">
                                <tr *ngFor="let data of temptableActionHistory index as index"
                                    style="background-color: #dce2f0;">
                                    <td style="text-align: left;">{{ data.createDate }}</td>
                                    <td style="text-align: left;">{{ data.actionBy }}</td>
                                    <td style="text-align: left;">{{ data.business_Name }}</td>
                                    <td style="text-align: left;">{{ data.action }}</td>
                                    <td style="text-align: left;">{{ data.comment }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>