<div class="container-fluid appform my-3">
    <div class="text-center">
        <div class="spinner-grow loading" role="status" id="loader" [hidden]="loading === false">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div class="row animated fadeIn slow" id="showdisplay" [hidden]="loading === true">
      <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">


        <div class="col-12 col-md-12 col-lg-12 col-xl-12 pb-5 mt-5 mb-5">

                <div class="col-12">
                    <h2 style="color: #023154;">Contact Us : Risk Focal Point</h2>
                </div>
         
                <div class="row col-md-12 col-xl-12 mx-0 mt-5">
                    <div class="col-md-12 col-xl-5 px-0">
                        <div class="input-group rounded" style="border:1px solid rgb(199, 199, 199)">
                            <input type="text" class="form-control border-0 m-0" autocomplete="off" placeholder="Search..." ng-model="searchText" [(ngModel)]="SearchKey" id="myInput" (keyup.enter)="funcSearch()">
                            <div class="input-group-append">
                              <button class="btn btn-sm btn-light" type="button" (click)="funcSearch()"><em class="fa fa-search"></em></button>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-2"></div>
                    <div class="col-md-12 col-xl-5 px-0">
                        <div class="row col-md-12 col-xl-12 px-0 mx-0">
                            <div class="col-md-12 col-xl-4 pt-2 px-0">
                                <strong style="font-size: 22px;">Business Name</strong>
                            </div>
                            <div class="col-md-12 col-xl-8 px-0">
                                <select class="form-control" [(ngModel)]="SearchKeyBU" (change)="funcSearch()">
                                    <option value="">All</option>
                                    <option *ngFor="let data of DDL_BusinessName">{{ data.text }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row col-xl-12 mt-5 mx-0"> 
                    <div class="col-12 col-sm-6 col-md-6 col-xl-4" *ngFor="let data of temp index as index" >
                        <div class="card member-card filterdata" style="background-color:#023154;">
                            
                            <div class="row col-12 mt-4 mx-0 px-0">
                                <!-- <div class="col-6 mr-2">
                                    <img  alt="" src="{{ path }}/{{ data2.pathFile }}" class="rounded-circle img-responsive" *ngFor="let data2 of data.itemAttSeq1">
                                </div> -->
                                
                                <div class="row col-12 mt-4 pl-5" style="text-align: left;">
                                    <div class="col-12 px-0">
                                        <h5 class="m-t-10 text-light">{{ data.contactName }}</h5>
                                    </div>
                                    <div class="col-12 px-0">
                                        <strong class="m-0"  style="color: #F59A23;">Position</strong><br>
                                        <strong style="color:#1b91c7;">{{ data.position }}</strong>
                                    </div>
                                </div>
                            </div>
                            <div class="body px-0 mt-4 pb-0">
                                <div class="col-12 px-0">
                                    <div class="row col-12 px-0 mx-0 pt-2" style="background-color: #00aeef;color: white;height: 40px;">
                                        <div class="col-4 pl-4" style="text-align: left;">
                                            <p class="mb-0"><strong><em class="fas fa-envelope" style="color:#023154;font-size: 22px;"></em></strong></p>
                                        </div>
                                        <div class="col-8" style="text-align: right;">
                                            <p class="mb-0">{{ data.email }}</p>
                                        </div>
                                    </div>
                                    <div class="row col-12 px-0 mx-0 pt-2" style="background-color: #365f92;color: white;height: 40px;">
                                        <div class="col-4 pl-4" style="text-align: left;">
                                            <p class="mb-0"><strong><em class="fas fa-phone-alt" style="color:#023154;font-size: 22px;"></em></strong></p>
                                        </div>
                                        <div class="col-8" style="text-align: right;">
                                            <p class="mb-0">{{ data.phone }}</p>
                                        </div>
                                    </div>
                                    <div class="collapse" id="collapseExample{{ index+1 }}">
                                        <div class="col-12 mt-1"style="text-align: left; color:white;">
                                            <span *ngFor="let item of data.arrGroupMap">&nbsp;&nbsp;{{ item }}&nbsp;&nbsp;</span>
                                        </div>
                                    </div>
                                    <div class="col-12 mt-1" style="height:40px;cursor:pointer;">
                                        <p style="color: #F59A23;" style="text-align: center;" class="pt-2" data-toggle="collapse"
                                        attr.data-target="#collapseExample{{ index+1 }}" aria-expanded="false" attr.aria-controls="collapseExample{{ index+1 }}">
                                        <strong style="color: #F59A23;" > Focal Point BU</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

        </div>

      </div>
    </div>
  </div>
  
  