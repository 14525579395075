import { Component, OnInit } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { Masterserve } from '../../services/master.service';

import {UntypedFormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { AppSettingService } from 'src/app/app-setting-service';
import { AuthenService } from 'src/app/services/authenservice.service';




@Component({
  selector: 'app-ContactHelp',
  templateUrl: './page.ContactHelp.html',
  styleUrls: ['./page.ContactHelp.css'],
})

export class ContactHelp implements OnInit {
    loading = true;
    BusinessName;
    ContactDetail;
    searchText;
    showResult = [];
    temp = [];
    SearchKey = '';
    SearchKeyBU = '';

    DDL_BusinessName = [];
    filteredOptionsBuname : Observable<string[]>;
    myControlBuname = new UntypedFormControl();
   

  
    constructor(public authserviceToken: AuthenService,private modalService: NgbModal,public mserve: Masterserve,public appSettingService:AppSettingService) {} 
    configdata= this.appSettingService.read();
    BASE_URL =this.configdata.api_url;  
    path = this.BASE_URL + '/app/backend/Resources/FileUpload';

    ngOnInit(): void {
        
        this.func_GetDDL_BusinessName();
    }

    func_GetDDL_BusinessName () {
        const _s = this;
        let formData = {
            Module :'DepartmentDD'
        }
        this.mserve.Master_DDL(formData).then((response) => {
            //console.log("DDLPosition :",response.data.body)
            if (response.data.status ){
                this.DDL_BusinessName = response.data.body;

                this.func_GETMASTER_ContactUs();

                this.filteredOptionsBuname = this.myControlBuname.valueChanges.pipe(
                    startWith(''),
                    map(value => this._filterBUName(value))
                );
            } else {
                //console.log('false',response)
            }
        });
    }

    func_GETMASTER_ContactUs () {
        const _s = this;
        let formData = {
            SessionEmpID :"1",
            Module :"getlist",
            body :{
                ContactName :"TM001",
                Position :"1"
            }
        }
        this.mserve.Get_ContactUs(formData).then((response) => {
            //console.log("ContactUs Data :",response.data.body)
            
            if (response.data.status) {
                this.ContactDetail = response.data.body
                for(let item_ContactDetail of this.ContactDetail){
                    const avatarNumber = 1; // กำหนดตัวแปรเก็บเลขรูปภาพ
                    item_ContactDetail.pic = `../../../assets/lg/avatar${avatarNumber}.jpg`;
                }

                _s.ContactDetail.forEach(function (val1,index1) {
                    if (val1.arrGroupMap){
                        val1.arrGroupMap.forEach((val2, index2) => {
                            const matchedItem = _s.DDL_BusinessName.find(item_DDL_BusinessName => val2 === item_DDL_BusinessName.value);
                            if (matchedItem) {
                              _s.ContactDetail[index1].arrGroupMap[index2] = matchedItem.text;
                            }
                          });
                          
                    }
                });

                this.ContactDetail.forEach(function(ItemVal, ItemIndex) {
                   
                    if (ItemVal.delFlag !== '1') _s.temp.push(ItemVal)
                    
                });

            }
        }).catch((e) => {
            this.authserviceToken.CheckTokenExpire(e.response.status)
            });
    }

    funcSearch () {
        
        const _s = this;
        let filter = this.SearchKey?.toUpperCase();
        let filterBu = this.SearchKeyBU?.toUpperCase();
        this.showResult = []
        
        if ((filter === '') && (filterBu === '')) {
            this.showResult = this.ContactDetail.filter(ItemVal => ItemVal.delFlag !== '1');
            this.temp = this.showResult
          } else {
            for(let item_ContactDetail of  this.ContactDetail){
                if ((item_ContactDetail.contactName?.toUpperCase().indexOf(filter) > -1 || 
                    item_ContactDetail.position?.toUpperCase().indexOf(filter) > -1 || 
                    item_ContactDetail.email?.toUpperCase().indexOf(filter) > -1 ||
                    item_ContactDetail.phone?.toUpperCase().indexOf(filter) > -1) && item_ContactDetail.delFlag !== '1')
                {
                    this.showResult.push(item_ContactDetail)
                }
            }
            this.temp = this.showResult
            if (this.SearchKeyBU !== '') {
                this.funcSearchBU(filterBu);
            } 
        }
        
        
    }


    funcSearchBU (filter:any) {
   
        this.showResult = []
        this.Fn_Search_Bu2(filter);
        this.temp = this.showResult
    }



    // funcSearchBU (_s:this ,filter:any) {
   
    //     this.showResult = []

    //     if (filter.trim() === '') {
    //         this.funcSearchBU_filterEmpty(_s);
    //     } else {
    //         this.funcSearchBU_filterNotEmpty(filter);
    //     }
    //     this.temp = this.showResult
    // }

    // private funcSearchBU_filterNotEmpty(filter: any) {
    //     if (this.SearchKey === '') {
    //         this.Fn_Search_Bu1(filter);
    //     } else {
    //         this.Fn_Search_Bu2(filter);
    //     }
    // }

    // private funcSearchBU_filterEmpty(_s: this) {
    //     if (this.SearchKey === '') {
    //         this.ContactDetail.forEach(function (ItemVal, ItemIndex) {
    //             _s.showResult = (ItemVal.delFlag !== '1') ? [..._s.showResult, (ItemVal)] : _s.showResult;
    //         });
    //     } else {
    //         this.funcSearch();
    //     }
    // }

    private Fn_Search_Bu2(filter: any) {
        for (let item_temp of this.temp) {
            if (item_temp.arrGroupMap !== null) {
                for (let item_arrGroupMap of item_temp.arrGroupMap) {
                    if (item_arrGroupMap?.toUpperCase().indexOf(filter) > -1) {
                        this.showResult.push(item_temp);
                    }
                }
            }
        }
    }

    private Fn_Search_Bu1(filter: any) {
        for (let item_ContactDetail of this.ContactDetail) {
            if (item_ContactDetail.arrGroupMap !== null && item_ContactDetail.delFlag !== '1') {
                for (let item_arrGroupMap of item_ContactDetail.arrGroupMap) {
                    if (item_arrGroupMap?.toUpperCase().indexOf(filter) > -1) {
                        this.showResult.push(item_ContactDetail);
                    }
                }
            }
        }
    }

    private _filterBUName(value: string) {
        const filterValue = value?.toLowerCase();
        if (filterValue !== '') {
                return this.DDL_BusinessName.filter(option => option.text?.toLowerCase().includes(filterValue));
        } else {
            return this.DDL_BusinessName
        }
    }

   
    ngAfterViewInit(): void{
        const _s = this;
        scroll(0,390)
        setTimeout(function () { _s.loading = false} ,800)
    }
}
