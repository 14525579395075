import { Component,OnInit,Injectable } from "@angular/core";
import { NgbModal, NgbModalConfig, NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Masterserve } from '../../services/master.service';
import { Router } from '@angular/router';
declare let $;

import {UntypedFormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day : (date[0], 10),
        month : (date[1], 10),
        year : (date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
        if (date !== null) {
            let day = date.day.toString()
            let month = date.month.toString()
            if (day.length < 2) {
                day = '0' + day
            }
            if (month.length < 2) {
                month = '0' + month
            }
            let newdate  = day + this.DELIMITER + month + this.DELIMITER + date.year ;
            return  newdate;
        } else {
           return '';
        }
    }
    
}
//---------------------------------------------------------------------------------------------------------------------

@Component({
    selector: 'app-Delegate',
    templateUrl: './page.Delegate.html',
    styleUrls: ['./page.Delegate.css'],
    providers: [{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }]
})

export class Delegate implements OnInit {
    something;
    constructor(config: NgbModalConfig, private modalService: NgbModal,public mserve: Masterserve,private router: Router) {}
    alertflow = null;
    page = 1;
    pageSize = 20;

    Search = {
        Type: '1',
        RiskCate :'',
        RiskStatus :'',
        WorkflowStatus :'',
        RiskID :'',
        RiskName :'',
        Pendingby :'',
        PendingbyID :'',
        BUName :'',
        BUID :'',
    }

    DataSearch = {
        Yearfrom:null,
        Yearto:'',
        Quarterfrom:'',
        Quarterto:''
    }

    Delegate = {
        RiskID :'',
        RiskIDName :'',
        RiskName :'',
        RiskBUName :'',
        RiskLevel :'',
        BUName :'',
        BUID :'',
        EmpName :'',
        EmpID :'',
        Email: '',
        Remark: ''
    }

    YearDD = [];
    QuarterDD = [];
    Risk_ByCategory = [];
    Risk_Level = [];
    
    DDLQuarterfrom = [];
    DDLQuarterto = [];

    tempSelected = [];
    DataRiskDelegate = [];
    loading = false;
    Data_SessionStorage;

    BUDD = [];
    myControlBU = new UntypedFormControl();
    filteredOptionsBU: Observable<string[]>;

    BUDD2 = [];
    myControlBU2 = new UntypedFormControl();
    filteredOptionsBU2: Observable<string[]>;

    EmpDD = [];
    myControlEmp = new UntypedFormControl();
    filteredOptionsEmp: Observable<string[]>;

    EmpSearchDD = [];
    myControlEmpSearch = new UntypedFormControl();
    filteredOptionsEmpSearch: Observable<string[]>;

    ngOnInit(): void {
        const _s = this;
        _s.Data_SessionStorage = JSON.parse(sessionStorage.datainfo);
        _s.loadDDL();
    }

    loadDDL () {
        const _s = this;
        _s.loading = true;
        _s.mserve.Master_DDL({ Module:"YearDD" }).then((response) => {
            _s.YearDD = response.data.body

            let d = new Date();
            _s.DataSearch.Yearfrom = d.getFullYear();
            let currentMonth = d.getMonth() + 1;
            _s.mserve.Master_DDL({ Module:"QuarterDD",TextSearch1: _s.DataSearch.Yearfrom }).then((response) => {
                _s.QuarterDD = response.data.body
                _s.func_calQuarter(currentMonth);
            })
        })
    }
    func_calQuarter (month) {
        this.DataSearch.Quarterfrom = ''
        if (month >= 1 && month <= 3) {
            this.DataSearch.Quarterfrom = this.QuarterDD[0].value;
        } else if (month >= 4 && month <= 6) {
            this.DataSearch.Quarterfrom = this.QuarterDD[1].value;
        } else if (month >= 7 && month <= 9) {
            this.DataSearch.Quarterfrom = this.QuarterDD[2].value;
        } else if (month >= 10 && month <= 12) {
            this.DataSearch.Quarterfrom = this.QuarterDD[3].value;
        }
        this.func_GetDDL_BusinessName();
    }
    func_GetDDL_BusinessName () {
        let formData = {
            Module :'DepartmentDD'
        }
        this.mserve.Master_DDL(formData).then((response) => {
            if (response.data.status ){
                this.BUDD = response.data.body;
                this.BUDD2 = response.data.body;

                this.filteredOptionsBU = this.myControlBU.valueChanges.pipe(
                    startWith(''),
                    map(value => this._filter(value))
                );

                this.filteredOptionsBU2 = this.myControlBU2.valueChanges.pipe(
                    startWith(''),
                    map(value => this._filter2(value))
                );
                this.func_RiskCategoryDDL();
            } else {
                alert(response.data.errorMessage);
            }
        });
    }
    func_RiskCategoryDDL () {
        let chkDDper;
        chkDDper = (sessionStorage.Role === 'ERM') ? '' : '1';

        let formData = {
            Module:"RiskCateDD",
            TextSearch1:chkDDper,
            TextSearch7:this.DataSearch.Quarterfrom
        }
        this.mserve.Master_DDL(formData).then((response) => {
            if (response.data.status ){
                this.Risk_ByCategory = response.data.body;
                this.func_GETDDL_EMPLOYEE();
            } else {
            }
        });
    }
    func_GETDDL_EMPLOYEE () {
        const _s = this;
        _s.mserve.Master_DDL({ Module:"EmployeeDD" }).then((response) => {
            _s.EmpSearchDD = response.data.body;

            this.func_GETDDL_RiskLevel();

            this.filteredOptionsEmpSearch = this.myControlEmpSearch.valueChanges.pipe(
                startWith(''),
                map(value => this._filteredOptionsEmpSearch(value))
            );

        })
    }
    func_GETDDL_RiskLevel () {
        this.mserve.Master_DDL({ Module:"RatingDD" }).then((response) => {
            if (response.data.status ){
                this.Risk_Level = response.data.body;
                this.loading = false;
            } else {
            }
        });
    }

    _Fucn_AllItem() {
        let _s = this
        return {
            func_search () {
                if(_s.Search.Type === '1' && _s.Search.Pendingby === '') {
                    _s.alertflow = "Please Require Pending  by"
                    $('#modalAlertDelegate').modal('show');
                }
                else if((_s.Search.Type === '2' || _s.Search.Type === '3') && _s.Search.WorkflowStatus === ''){
                    _s.alertflow = "Please Require Business Unit Level"
                    $('#modalAlertDelegate').modal('show');
                }
                else{
                    let formData = {
                        SessionEmpID: _s.Data_SessionStorage[0].employeE_ID,
                        SecurityCode: "1234",
                        Email: sessionStorage.loginmail,
                        body: {
                            Delegate_Type: _s.Search.Type,
                            Delegate_To: _s.Search.PendingbyID,
                            Delegate_Status: _s.Search.WorkflowStatus,
                            Risk_Category: _s.Search.RiskCate,
                            Risk_Status: _s.Search.RiskStatus,
                            Risk_Business_Unit: _s.Search.BUID,
                            Risk_Name: _s.Search.RiskName,
                            Risk_Running: _s.Search.RiskID,
                            QuarterID: _s.DataSearch.Quarterfrom,
                            WPBID: "",
                            QuaterMaster: _s.DataSearch.Quarterfrom
                        }
                    }
                    _s.loading = true;
                    _s.mserve.Delegate_RiskSeacrh(formData).then((response) => {
                        if (response.data.status ){
                            if (response.data.body.length !== 0) {
                                _s.DataRiskDelegate = response.data.body;
                                _s.tempSelected = [];
                                _s.loading = false;
                            } else {
                                _s.DataRiskDelegate = response.data.body;
                                _s.loading = false;
                                _s.alertflow = "No data found";
                                _s.tempSelected = [];
                                $('#modalAlertDelegate').modal('show');
                            }
                        }
                        else {
                            _s.loading = false;
                            _s.DataRiskDelegate = []
                            _s.tempSelected = []
                            _s.alertflow = response.data.errorMessage
                            $('#modalAlertDelegate').modal('show');
                        }
                    }).catch((e) => {
                        this.authserviceToken.CheckTokenExpire(e.response.status)
                        });
                }
            },
            ClearSearch () {
                _s.Search.Type            = '1';
                _s.Search.BUName          = '';
                _s.Search.BUID            = '';
                _s.Search.RiskCate        = '';
                _s.Search.RiskStatus      = '';
                _s.Search.RiskName        = '';
                _s.Search.RiskID          = '';
                _s.Search.WorkflowStatus  = '';
                _s.Search.Pendingby       = '';
                _s.Search.PendingbyID     = '';

                _s.tempSelected           = [];
                _s.DataRiskDelegate       = [];
                for(let i=0; i<_s.DataRiskDelegate.length; i++) {
                    let e1 
                    e1 = (document.getElementsByName( "selected" + (i+1) ));
                    e1[0].checked = false
                    e1[0].disabled = false
                }

            },
            func_Delegate () {
                _s.Delegate.BUName = '';
                _s.Delegate.BUID = '';
                _s.Delegate.EmpName = '';
                _s.Delegate.EmpID = '';
                _s.Delegate.Email = '';
                _s.Delegate.Remark = '';

                if (_s.tempSelected.length == 0) {
                    _s.alertflow = 'Please select Risk to Delegate'
                    $('#modalAlertDelegate').modal('show');
                    
                }
                else if (_s.Search.Type === '3') {
                    let formDataOwner = {
                        SessionEmpID: _s.Data_SessionStorage[0].employeE_ID,
                        SecurityCode: "1234",
                        Email: sessionStorage.loginmail,
                        body: {
                            Dept_Id: _s.tempSelected[0].RiskBUCode
                        }
                    }
                    _s.mserve.GetOwner(formDataOwner).then((response) => {
                        if (response.data.status ){
                            _s.EmpDD = response.data.body;  
                            _s.filteredOptionsEmp = _s.myControlEmp.valueChanges.pipe(
                                startWith(''),
                                map(value => _s._filterEmp(value))
                            );
                        } else {

                        }
                    }).catch((e) => {
                        this.authserviceToken.CheckTokenExpire(e.response.status)
                        });

                    $('#modalDelegate').modal('show');  
                }
                else {
                    let formData = {
                        Module :"GetCoByDept",
                        body :{
                            Risk_Id :"",
                            Risk_Business_Unit :_s.tempSelected[0].RiskBUCode,
                            Risk_Register_By :""
                        }
                    }
                    _s.mserve.Risk_Transfer(formData).then((response) => {
                        if (response.data.status ){
                            _s.EmpDD = response.data.body;
                            _s.filteredOptionsEmp = _s.myControlEmp.valueChanges.pipe(
                                startWith(''),
                                map(value => _s._filterEmp(value))
                            );
                        } else {
                        }
                    }).catch((e) => {
                        this.authserviceToken.CheckTokenExpire(e.response.status)
                        });
                    
                    $('#modalDelegate').modal('show');   
                }
            },
            func_SelectSearchBU () {
                
                for(let item_sBUDD of  _s.BUDD ) {
                    if (_s.Search.BUName === item_sBUDD.text) {
                        _s.Search.BUID = item_sBUDD.value
                        break;
                    }
                }
            },
            func_ReveiewPage (item) {
                sessionStorage.setItem("RiskID", item.risk_Id);
          
                if (item.table_Type === "Staff") {
                  sessionStorage.setItem("TranSecRole", 'Staff');
                } else {
                  sessionStorage.setItem("TranSecRole", 'Co');
                }
          
                _s.router.navigate(['/ReviewPage','ReviewfromTransfer']);  
            },
            selectedRisk_ISTempSelectedEmpty(_s, item: any) {
                _s.tempSelected.push({
                    RiskID: item.risk_Id,
                    RiskIDName: (item.risk_Co_Id !== null) ? item.risk_Co_Id : item.risk_Staff_Id,
                    RiskName: item.risk_Name,
                    RiskBU: item.businessUnit,
                    RiskBUCode: item.risk_Business_Unit,
                    RiskStatusWorkflow: item.risk_Status_Workflow
                });
        
                for (let i = 0; i < _s.DataRiskDelegate.length; i++) {
                    if (_s.DataRiskDelegate[i].businessUnit !== _s.tempSelected[0].RiskBU) {
                        let e2;
                        e2 = (document.getElementsByName("selected" + (i + 1)));
                        e2[0].disabled = true;
                    }
                }
            },
            selectedRisk (item, index) {
                let e1;
                e1 = (document.getElementsByName( "selected" + index ));
        
                if (e1[0].checked) {
                    if(_s.tempSelected.length === 0){
                        this.selectedRisk_ISTempSelectedEmpty(_s, item);
                    }
                    else{
                        let chk = _s.tempSelected[ (_s.tempSelected.length - 1) ].RiskBU
                        if(chk === item.businessUnit){
                            _s.tempSelected.push({
                                RiskID :item.risk_Id,
                                RiskIDName :(item.risk_Co_Id !== null) ? item.risk_Co_Id : item.risk_Staff_Id,
                                RiskName :item.risk_Name,
                                RiskBU :item.businessUnit,
                                RiskBUCode :item.risk_Business_Unit,
                                RiskStatusWorkflow :item.risk_Status_Workflow
                            })
                        }
                        else {
                            e1[0].checked = false
                            _s.alertflow = "Cannot select different BU"
                            $('#modalAlertDelegate').modal('show');
                        }
                    }
                }
                else {
                    for (let i = 0; i < _s.tempSelected.length; i++) {
                        if (_s.tempSelected[i].RiskID === item.risk_Id) {
                            _s.tempSelected.splice(i, 1);
                            break;
                        }
                    }
                    if (_s.tempSelected.length === 0) {
                        for (let i = 0; i < _s.DataRiskDelegate.length; i++) {
                            let e2;
                            e2 = (document.getElementsByName("selected" + (i + 1)));
                            e2[0].disabled = false;
                        }
                    }
                }
        
            },
            
            selectedEmpSearch () {
                
                for(let item_EmpSearchDD of _s.EmpSearchDD) {
                    if (_s.Search.Pendingby === item_EmpSearchDD.text) {
                        _s.Search.PendingbyID = item_EmpSearchDD.value
                        break;
                    }
                }
            },
            func_changetype () {
                _s.Search.Pendingby = '' 
                _s.Search.PendingbyID = ''
                _s.Search.WorkflowStatus = ''
            },
           
        }
        
    }

    _Func_Modal () {
        let _s = this
        return {
            func_SelectBU () {
          
                for(let item_EmpDD of _s.EmpDD){
                    if(_s.Search.Type === '1' || _s.Search.Type === '2'){
                        if(_s.Delegate.EmpName === item_EmpDD.FullName){
                            _s.Delegate.Email = item_EmpDD.Email
                            _s.Delegate.BUID  = item_EmpDD.Coordinator_Department_Id
                            _s.Delegate.EmpID = item_EmpDD.Coordinator_Employee_Id
                            break;
                        }
                    }
                    else {
                        if(_s.Delegate.EmpName === item_EmpDD.emp_FullName){
                            _s.Delegate.Email = item_EmpDD.emp_Email
                            _s.Delegate.BUID  = item_EmpDD.dept_Id
                            _s.Delegate.EmpID = item_EmpDD.emp_Id
                            break;
                        }
                    }
                }
            },
            Close_Modal () {
                $('#modalDelegate').modal('hide');
            },
            Confirm_delegate () {
                let formData = {
                    SessionEmpID: _s.Data_SessionStorage[0].employeE_ID,
                    SecurityCode: "1234",
                    Email: sessionStorage.loginmail,
                    body: {
                        Delegate_Transection: []
                    }
                }
                _s.tempSelected.forEach( (val,int) => {
                    formData.body.Delegate_Transection.push({
                        "Risk_Id" : val.RiskID,
                        "Delegate_Type" : _s.Search.Type,
                        "Risk_Business_Unit" : val.RiskBUCode,
                        "Risk_Status_Workflow" : "",
                        "Risk_DelegateTo" : _s.Delegate.EmpID,
                        "Remark" : _s.Delegate.Remark
                    })
                })

                _s.loading = true;
                _s.mserve.Delegate(formData).then((response) => {
                    if (response.data.status ){
                        _s.loading = false;
                        $('#modalDelegate').modal('hide');
                        setTimeout(() =>{ 
                            _s.alertflow = "Delegate Complete"
                            $('#modalAlertsuccessDelegate').modal('show');
                        }, 300);
                        _s._Fucn_AllItem().ClearSearch();
                    } else {
                        _s.loading = false;
                        _s.alertflow = response.data.errorMessage
                        $('#modalAlertDelegate').modal('show');

                    }
                }).catch((e) => {
                    this.authserviceToken.CheckTokenExpire(e.response.status)
                    });
            },
            closeflowsuccess (){
                $('#modalAlertsuccessDelegate').modal('hide');
            }
        }
    }

    func_SelectEMP () {
       
        for(let item_EmpDD of this.EmpDD) {
            if (this.Delegate.EmpName === item_EmpDD.FullName) {
                this.Delegate.EmpID = item_EmpDD.Coordinator_Employee_Id
                break;
            }
        }
    }
    func_selFromyear () {
        const _s = this;
        _s.DataSearch.Quarterfrom = ''
        _s.mserve.Master_DDL({ Module:"QuarterDD",TextSearch1: _s.DataSearch.Yearfrom }).then((response) => {
            _s.QuarterDD = response.data.body

        });
    }
    func_selToyear () {
        const _s = this;
        _s.DataSearch.Quarterto = ''
        _s.mserve.Master_DDL({ Module:"QuarterDD",TextSearch1: _s.DataSearch.Yearto }).then((response) => {
            _s.DDLQuarterto = response.data.body

        });
    }

    private _filter(value: string): string[] {
        const filterValue = value?.toLowerCase();
        if (filterValue !== '') {
            return this.BUDD.filter(option => option.text?.toLowerCase().includes(filterValue));
        } else {
            this.Search.BUID = ''
            return this.BUDD
        }
    }
    private _filter2(value: string): string[] {
        const filterValue = value?.toLowerCase();
        if (filterValue !== '') {
            return this.BUDD2.filter(option => option.text?.toLowerCase().includes(filterValue));
        } else {
            return this.BUDD2
        }
    }
    private _filterEmp(value: string): string[] {
        let _s = this
        const filterValue = value?.toLowerCase();
        if (filterValue !== '' && (_s.Search.Type === '1' || _s.Search.Type === '2')) {
            return this.EmpDD.filter(option => option.FullName?.toLowerCase().includes(filterValue));
        }
        else if (filterValue !== '' && _s.Search.Type === '3') {
            return this.EmpDD.filter(option => option.emp_FullName?.toLowerCase().includes(filterValue));
        }
        else {
            return this.EmpDD
        }
    }
    private _filteredOptionsEmpSearch(value: string): string[] {
        const filterValue = value?.toLowerCase();
        if (filterValue !== '') {
            return this.EmpSearchDD.filter(option => option.text?.toLowerCase().includes(filterValue));
        } else {
            return this.EmpSearchDD
        }
    }
    ngAfterViewInit(): void{
        const _s = this;
    }
}