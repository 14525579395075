<div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="modalBU5" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Please Select Business Unit for {{title}}</h5>
            </div>
            <div class="modal-body">
                <div class="row col-12 mx-0 px-0">
                    <div class="col-4 pt-2">
                        <strong style="font-size:18px;">Business Unit Name</strong>
                    </div>
                    <div class="col-8 px-0 pt-2">

                        <input type="text" class="form-control m-0" aria-label="Number" matInput [formControl]="myControlBUDD" [matAutocomplete]="auto1" [(ngModel)]="tempselectbu"  style="font-size:16px;">
                        <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete">
                            <mat-option *ngFor="let option of filteredOptionBUDD | async" [value]="option.text">
                            {{option.text}}: {{option.fullname}}
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="col-12" style="text-align: center;">
                    <button type="button" class="btn btn-primary mr-2 btn-width" (click)="func_OkBU()">OK</button>
                    <button type="button" class="btn btn-secondary btn-width" (click)="goHome()">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>