<table class="table table-register" id="table1" [style.Width]="_width">
<caption style="display: none;">This is a custom table</caption>
    <thead>
     
    </thead>
    <tbody>
        <tr>
            <th scope="row">
                <div class="rotate" style="position:absolute;"
                [ngClass]="{'text-rotate-register': this._mode == 'register' || this._mode == 'summaryshare' || this._mode == 'review', 'text-rotate-summary':  this._mode == 'summary'}">
                    Acceptable
                </div>
                <div class ="col rotate-text" style="text-align: end;">
                        YES
                </div>
            </th>
            <td style="background-color:yellow;">
                <div class="box-dot">
                    <ng-container *ngFor="let item of dataTable[0][1]">
                        <span class="dot badge badge-pill badge-light txt-title"
                        data-toggle="tooltip" data-placement="top"><strong>{{item.number}}</strong>
                        </span>
                    </ng-container>
                </div>
            </td>
            <td style="background-color:green;">
                <div class="box-dot">
                    <ng-container *ngFor="let item of dataTable[1][1]">
                        <span class="dot badge badge-pill badge-light txt-title"
                        data-toggle="tooltip" data-placement="top"><strong>{{item.number}}</strong>
                        </span>
                    </ng-container>
                </div>
            </td>   
          </tr>
      <tr>
        <th scope="row">
            <div class ="col rotate-text" style="text-align: end;">
                    NO
            </div>
        </th>
        <td style="background-color:red;">
            <div class="box-dot">
                <ng-container *ngFor="let item of dataTable[0][0]">
                    <span class="dot badge badge-pill badge-light txt-title"
                    data-toggle="tooltip" data-placement="top"><strong>{{item.number}}</strong>
                    </span>
                </ng-container>
            </div>
        </td>
        <td style="background-color:yellow;">
            <div class="box-dot">
                <ng-container *ngFor="let item of dataTable[1][0]">
                <span class="dot badge badge-pill badge-light txt-title"
                data-toggle="tooltip" data-placement="top"><strong>{{item.number}}</strong>
                </span>
            </ng-container>
            </div>
        </td>
      </tr>
      <tr>
        <th scope="row"></th>
        <th class="column-text-table" style="padding: 0rem!important; vertical-align:top;">
            <div >
                NO
            </div>
            <div style="font-size: 1vW; position: absolute;"
            [ngClass]="{'text-register': this._mode == 'register', 'text-summary':  this._mode == 'summary', 'text-summaryshare':  this._mode == 'summaryshare'}">
                Mitigatable
            </div>
        </th>
        <th class="column-text-table" style="padding: 0rem!important; vertical-align:top;">
            <div class ="">
                YES
            </div>
        </th>
      </tr>
    </tbody>
</table>