import { Component, OnInit, Inject, Injector, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Masterserve } from '../../services/master.service';
import { Router, ActivatedRoute } from '@angular/router';
import { RiskMap } from '../../services/riskmap.service';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
declare let $;

import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AuthenService } from 'src/app/services/authenservice.service';
import { MainProcessComponent } from '../main-process/main-process.component';
import { ShareService } from 'src/app/services/share.service';
import DOMPurify from 'dompurify';
// -------------------------------------------

export interface DialogData {
}

@Component({
  selector: 'app-Identification',
  templateUrl: './page.Identification.html',
  styleUrls: ['./page.Identification.css'],
  providers: [Masterserve, NgbModal, RiskMap]
})

export class Identification implements OnInit {
  @Output() newItemEvent = new EventEmitter<string>();
  Data_SessionStorage;

  riskstatusfrom_myrisk = '';
  checkDDstatus;
  checkstatusupdate;
  alertflow = '';
  chkbackmenu = 'go';
  alert = '';
  tempselectbu = '';
  PreliminaryLikelihood;
  PreliminaryImpact;
  loading = false;
  data_risk_table;
  Tempuser;
  list = {
    risk: {
      impactcolor: [],
      impact: [],
      risktable: [
        {
          row: 1,
          col: [
            {
              col: 0, class: 'impact-x', item: [
                { title: 'Critical', bgcolor: '#1b190f', color: '#edf0f7' }
              ]
            },
            { col: 1, class: 'medium', item: [] },
            { col: 2, class: 'medium', item: [] },
            { col: 3, class: 'high', item: [] },
            { col: 4, class: 'high', item: [] },
            { col: 5, class: 'high', item: [] },
          ]
        },
        {
          row: 2,
          col: [
            {
              col: 0, class: 'impact-x', item: [
                { title: 'Serious', bgcolor: '#444128', color: '#edf0f7' }
              ]
            },
            { col: 1, class: 'medium', item: [] },
            { col: 2, class: 'medium', item: [] },
            { col: 3, class: 'medium', item: [] },
            { col: 4, class: 'high', item: [] },
            { col: 5, class: 'high', item: [] },
          ]
        },
        {
          row: 3,
          col: [
            {
              col: 0, class: 'impact-x', item: [
                { title: 'Significant', bgcolor: '#8b8352', color: 'black' },
              ]
            },
            { col: 1, class: 'low', item: [] },
            { col: 2, class: 'medium', item: [] },
            { col: 3, class: 'medium', item: [] },
            { col: 4, class: 'medium', item: [] },
            { col: 5, class: 'high', item: [] },
          ]
        },
        {
          row: 4,
          col: [
            {
              col: 0, class: 'impact-x', item: [
                { title: 'Moderate', bgcolor: '#b9b696', color: 'black' },
              ]
            },
            { col: 1, class: 'low', item: [] },
            { col: 2, class: 'low', item: [] },
            { col: 3, class: 'medium', item: [] },
            { col: 4, class: 'medium', item: [] },
            { col: 5, class: 'medium', item: [] },
          ]
        },
        {
          row: 5,
          col: [
            {
              col: 0, class: 'impact-x', item: [
                { title: 'Minor', bgcolor: '#cdccbe', color: 'black' },
              ]
            },
            { col: 1, class: 'low', item: [] },
            { col: 2, class: 'low', item: [] },
            { col: 3, class: 'low', item: [] },
            { col: 4, class: 'medium', item: [] },
            { col: 5, class: 'medium', item: [] },
          ]
        },
        {
          row: 0,
          col: [
            { col: 0, class: 'impact-y', item: [] },
            { col: 1, class: 'impact-y', item: [{ title: 'Rare', bgcolor: '#abd1e1', color: 'black' }] },
            { col: 2, class: 'impact-y', item: [{ title: 'Unlikely', bgcolor: '#83a9db', color: 'black' }] },
            { col: 3, class: 'impact-y', item: [{ title: 'Possible', bgcolor: '#85aadb', color: 'black' }] },
            { col: 4, class: 'impact-y', item: [{ title: 'Likely', bgcolor: '#325a8d', color: 'white' }] },
            { col: 5, class: 'impact-y', item: [{ title: 'Almost Certain', bgcolor: '#0d213b', color: 'white' }] },
          ]
        }
      ],
      riskitems: []
    },
    riskprofile: {
      year: [],
      quarter: []
    },
    businessUnit: {
      items: []
    },
    field: {
      Mitigate: 1,
      riskprofileYear: '',
      riskprofileQuarter: '',
      businessUnit: '',
    }
  }
  page;
  TempData;
  tmpid = '';
  tmpmodule = '';

  Register_Data;

  Preliminarylikelyhood;
  PreliminaryImpactLevel;
  RiskCategory;
  ImpactCategory;
  StatusIden = [
    { value: '1', text: 'Active' },
    { value: '2', text: 'Close (Turn to problem)' },
    { value: '3', text: 'Close (Mitigation Completed)' },
    { value: '4', text: 'Close (Other)' }
  ];

  tempAssetBU = [];
  tempChildOrg = [];
  tempERMInfo = [];
  tempOrg = [];
  tempImpactMitigation = [];
  tempRootCauseMitigation = [];
  tableriskrating;
  QuarterDD;
  tempChkBUDD = [];
  tempChkRoleBUDD = [];
  ReCall = null;

  ShowWPB = false;
  tablewpb = [];
  SelectWPB = '1';
  WBPID = null;

  myControlBUDD = new UntypedFormControl();
  BusinessNameDD = [];
  filteredOptionBUDD: Observable<string[]>;
  dialog: MatDialog;
 mserve: Masterserve;
 dataService:ShareService;
  constructor(
    public authserviceToken: AuthenService,
    private router: Router,
    public mserve2: RiskMap,
    private appServicecomp: MainProcessComponent,
    private _Activatedroute: ActivatedRoute,
    private route: ActivatedRoute,
    private injector : Injector
    ) { 
      this.dialog   = injector.get<MatDialog>(MatDialog);
      this.mserve   = injector.get<Masterserve>(Masterserve);
      this.dataService   = injector.get<ShareService>(ShareService);
    }
    appService= this.appServicecomp;
  ngOnInit(): void {
    this.setRegisterData();

    this.setDataRiskTable();

    this.Data_SessionStorage = JSON.parse(sessionStorage.datainfo);

    this._Activatedroute.paramMap.subscribe(params => {
      this.appService.status_pageIdentification = params.get('display');
    });

    this.Register_Data.Regis_RiskID = sessionStorage.RiskID
    this.Register_Data.Regis_Role = sessionStorage.Role
    this.func_GetDataUser();

    this.CheckStatusPageIdentification();
    let sessionRoleStaff = (sessionStorage.Role === 'Staff');
    let sessionRoleOwner = (sessionStorage.Role === 'Owner');

    this.CheckSesstionRole(sessionRoleStaff, sessionRoleOwner);

    this.SetBusinessDD();

    this.SetUniqueValue();


    this.BusinessNameDD = [];
    Array.prototype.push.apply(this.BusinessNameDD, this.tempChkBUDD);

    this.RounterSub();

    this.filteredOptionBUDD = this.myControlBUDD.valueChanges.pipe(
      startWith(''),
      map(value => this._filterBUDD(value))
    );

    const _s = this;
    this.moDALbU5Set(_s);

    this.modalAlert5Set(_s);

    this.modalAlertsuccess5Set(_s);

  }

  private modalAlertsuccess5Set(_s: any) {
    $('#modalAlertsuccess5').on('hidden.bs.modal', function (e) {
      _s.closeflowsuccess();
    });
  }

  private modalAlert5Set(_s: any) {
    $('#modalAlert5').on('hidden.bs.modal', function (e) {
      _s.close();
    });
  }

   moDALbU5Set(_s: any) {
    $('#modalBU5').on('hidden.bs.modal', function (e) {
      
      if (_s.chkbackmenu === 'go') {
        let tempBU = [];
        if (_s.tempselectbu !== '') {
          _s.appService.showBUName = _s.tempselectbu;
          _s.Register_Data.Regis_BusinessName = _s.tempselectbu;
          sessionStorage.setItem('BUNameWork', _s.tempselectbu);
          _s.dataService.sendData(_s.tempselectbu);
          RegisRole(_s, tempBU);
         
          checkState(tempBU, _s);
          _s.Register_Data.Regis_BusinessUnit = tempBU[0].BuCode;
          sessionStorage.setItem('BUValueWork', tempBU[0].BuCode);
        }
        else {
          $('#modalAlert5').modal('show');
          _s.alert = 'Please select Business Name';
        }
      } else {
        _s.appService.status = 1;
        _s.appService.checkrole();
        _s.router.navigate(['/']);
      }
    });
  }




  private RounterSub() {
    this.route.params.subscribe(val => {

      if (this.appService.status_pageIdentification === 'new') {
        this.checkstatusupdate = 'new';
        this.appService.showBUName = '';
        this.Register_Data.Regis_Riskname = '';
        
        if (this.BusinessNameDD.length == 1) {
          this.fn_RounterSub_BddIsTrue();
        }
      }
      else {
        sessionStorage.setItem('statusflow', 'edit');
        this.checkstatusupdate = 'edit';
        this.riskstatusfrom_myrisk = sessionStorage.getItem('RiskStatus');
      }
    });
  }

  private fn_RounterSub_BddIsTrue() {
    this.tempselectbu = this.BusinessNameDD[0].text;
    this.appService.showBUName = this.BusinessNameDD[0].text;
    this.Register_Data.Regis_BusinessName = this.BusinessNameDD[0].text;
    sessionStorage.setItem('BUNameWork', this.BusinessNameDD[0].text);
    this.dataService.sendData(this.BusinessNameDD[0].text);
    this.fn_RounterSub_BddIsTrue_check_State();
    

    for (let item_tempChkRoleBUDD of this.tempChkRoleBUDD) {
      if ((item_tempChkRoleBUDD.text == this.tempselectbu) && (item_tempChkRoleBUDD.role == this.Register_Data.Regis_Register_By)) {
        this.Register_Data.Regis_Role = 'Co';
        sessionStorage.setItem("TranSecRole", 'Co');
        break;
      }
      else if ((item_tempChkRoleBUDD.text == this.tempselectbu) && (item_tempChkRoleBUDD.role != this.Register_Data.Regis_Register_By)) {
        this.Register_Data.Regis_Role = 'Staff';
        sessionStorage.setItem("TranSecRole", 'Staff');
      }
    }

    this.Register_Data.Regis_BusinessUnit = this.BusinessNameDD[0].value;
    sessionStorage.setItem('BUValueWork', this.BusinessNameDD[0].value);
  }

  private fn_RounterSub_BddIsTrue_check_State() {
    if (this.BusinessNameDD[0].state === 'Asset') {
      this.Register_Data.Regis_RiskType = 'Asset';
      sessionStorage.setItem('BUType', this.Register_Data.Regis_RiskType);
    } else if (this.BusinessNameDD[0].state === 'Org') {
      this.Register_Data.Regis_RiskType = 'Organization';
      sessionStorage.setItem('BUType', this.Register_Data.Regis_RiskType);
    } else {
      this.Register_Data.Regis_RiskType = 'Corporate';
      sessionStorage.setItem('BUType', this.Register_Data.Regis_RiskType);
    }
  }

  private SetUniqueValue() {
    let uniq_values = [];

    for (let x of this.BusinessNameDD) {
      this.tempChkRoleBUDD.push(x);
      if (uniq_values.indexOf(x.value) == -1) {
        uniq_values.push(x.value);
        this.tempChkBUDD.push(x);
      }
    }
  }

  private SetBusinessDD() {
    for (let item_tempAssetBU of this.tempAssetBU) {
      this.BusinessNameDD.push({ value: item_tempAssetBU.asset_Code, text: item_tempAssetBU.asset_Short, fullname: item_tempAssetBU.asset_Name, state: 'Asset', role: item_tempAssetBU.asset_Coordinators });
    }
    for (let item_tempOrg of this.tempOrg) {
      this.BusinessNameDD.push({ value: item_tempOrg.organizatioN_ID, text: item_tempOrg.abbreviation, fullname: item_tempOrg.organizatioN_NANE, state: 'Org', role: item_tempOrg.coordinator_Employee_Id });
    }
    for (let item_tempChildOrg of this.tempChildOrg) {
      this.BusinessNameDD.push({ value: item_tempChildOrg.organizatioN_ID, text: item_tempChildOrg.abbreviation, fullname: item_tempChildOrg.name, state: 'Org', role: '' });
    }
    for (let item_tempERMInfo of this.tempERMInfo) {
      this.BusinessNameDD.push({ value: item_tempERMInfo.organizatioN_ID, text: item_tempERMInfo.abbreviation, fullname: item_tempERMInfo.organizatioN_NANE, state: 'CORP', role: 'Co' });
    }
  }

  private CheckSesstionRole(sessionRoleStaff: boolean, sessionRoleOwner: boolean) {
    if (sessionRoleStaff || sessionRoleOwner) {
      this.sessionStorageRoleOwnerAndStaff();
    }
    else if (sessionStorage.Role === 'Co' && (this.Data_SessionStorage[0].s_PERMISSSION_LEVEL.includes("OWNER") || this.Data_SessionStorage[0].s_PERMISSSION_LEVEL.includes("Owner"))) {
      this.sessionStorageRoleCoaNDoWNER();
    }
    else if (sessionStorage.Role === 'Co') {
      this.sessionStorageRoleCo();
    }
    else if (sessionStorage.Role === 'ERM') {
      this.sessionStorageRoleERM();
    }
  }

  private sessionStorageRoleERM() {
    this.tempOrg = (this.Data_SessionStorage[0].coInfo !== null) ? this.Data_SessionStorage[0].coInfo : [];
    this.tempERMInfo = (this.Data_SessionStorage[0].ermInfo !== null) ? this.Data_SessionStorage[0].ermInfo : [];
    this.tempChildOrg = (this.Data_SessionStorage[0].childOrganizationInfo !== null) ? this.Data_SessionStorage[0].childOrganizationInfo : [];
  }

  private sessionStorageRoleCo() {
    this.tempAssetBU = (this.Data_SessionStorage[0].assetInfo !== null) ? this.Data_SessionStorage[0].assetInfo : [];
    this.tempOrg = (this.Data_SessionStorage[0].coInfo !== null) ? this.Data_SessionStorage[0].coInfo : [];
    this.tempChildOrg = (this.Data_SessionStorage[0].childOrganizationInfo !== null) ? this.Data_SessionStorage[0].childOrganizationInfo : [];
  }

  private sessionStorageRoleOwnerAndStaff() {
    this.tempAssetBU = (this.Data_SessionStorage[0].assetInfo !== null) ? this.Data_SessionStorage[0].assetInfo : [];
    this.tempChildOrg = (this.Data_SessionStorage[0].childOrganizationInfo !== null) ? this.Data_SessionStorage[0].childOrganizationInfo : [];
  }

  private sessionStorageRoleCoaNDoWNER() {
    this.tempAssetBU = (this.Data_SessionStorage[0].assetInfo !== null) ? this.Data_SessionStorage[0].assetInfo : [];
    this.tempOrg = (this.Data_SessionStorage[0].coInfo !== null) ? this.Data_SessionStorage[0].coInfo : [];
  }

  private CheckStatusPageIdentification() {
    if (this.appService.status_pageIdentification !== 'new') {
      this.Register_Data.Regis_Role = sessionStorage.getItem('TranSecRole');
      this.func_GetData();
    } else {
      this.loadDDL();
    }
  }

  private setDataRiskTable() {
    this.data_risk_table = [
      {
        row: 1,
        col: [
          { col: 1, class: 'medium', item: [''] },
          { col: 2, class: 'medium', item: [] },
          { col: 3, class: 'high', item: [{ title: '12', bgcolor: '#873531', color: 'white' }] },
          { col: 4, class: 'high', item: [{ title: '11', bgcolor: '#873531', color: 'white' }] },
          { col: 5, class: 'high', item: [] },
        ]
      },
      {
        row: 2,
        col: [
          { col: 1, class: 'medium', item: [] },
          { col: 2, class: 'medium', item: [{ title: '2', bgcolor: '#204375', color: 'white' }] },
          { col: 3, class: 'medium', item: [{ title: '1', bgcolor: '#204375', color: 'white' }, { title: '4', bgcolor: '#204375', color: 'white' }, { title: '7', bgcolor: '#453e25', color: 'white' }] },
          { col: 4, class: 'high', item: [] },
          { col: 5, class: 'high', item: [] },
        ]
      },
      {
        row: 3,
        col: [
          { col: 1, class: 'low', item: [{ title: '5', bgcolor: '#204375', color: 'white' }] },
          { col: 2, class: 'medium', item: [{ title: '3', bgcolor: '#204375', color: 'white' }, { title: '9', bgcolor: '#453e25', color: 'white' }] },
          { col: 3, class: 'medium', item: [{ title: '8', bgcolor: '#453e25', color: 'white' }, { title: '10', bgcolor: '#873531', color: 'white' }] },
          { col: 4, class: 'medium', item: [{ title: '6', bgcolor: '#453e25', color: 'white' }] },
          { col: 5, class: 'high', item: [] },
        ]
      },
      {
        row: 4,
        col: [
          { col: 1, class: 'low', item: [] },
          { col: 2, class: 'low', item: [] },
          { col: 3, class: 'medium', item: [] },
          { col: 4, class: 'medium', item: [] },
          { col: 5, class: 'medium', item: [] },
        ]
      },
      {
        row: 5,
        col: [
          { col: 1, class: 'low', item: [] },
          { col: 2, class: 'low', item: [] },
          { col: 3, class: 'low', item: [] },
          { col: 4, class: 'medium', item: [] },
          { col: 5, class: 'medium', item: [] },
        ]
      },
    ];
  }

  private setRegisterData() {
    this.Register_Data = {
      Regis_RiskNo: '',
      Regis_RiskID: '',
      Regis_Riskname: '',
      Regis_Likelyhood: '',
      Regis_Impact: '',
      Regis_Status: '1',
      Regis_Category: '',
      Regis_ObjectiveHead: '',
      Regis_Objective: '',
      Regis_KPI: '',
      Regis_Context: '',
      Regis_BusinessName: '',
      Regis_BusinessUnit: '',
      Regis_Register_By: '',
      Regis_Modified_By: '',
      Regis_Status_Workflow: '',
      Regis_Role: '',
      Regis_RiskType: '',
      tempQuarter: '',
    };
  }

  func_GetDataUser() {
    this.Register_Data.Regis_Register_By = this.Data_SessionStorage[0].employeE_ID;
    this.Register_Data.Regis_Role = sessionStorage.getItem('Role');
  }


  loadDDL() {
    const _s = this;
    this.loading = true;
    let d = new Date();
    let currentYear = d.getFullYear();
    let currentMonth = d.getMonth() + 1;
    _s.mserve.Master_DDL({ Module: "QuarterDD", TextSearch1: currentYear }).then((response) => {
      _s.QuarterDD = response.data.body
      this.func_calQuarter(currentMonth);
    })
  }
  func_calQuarter(month) {
    const _s = this;
    _s.list.field.riskprofileQuarter = ''
   let quater   = '';
    if (month >= 1 && month <= 3) {
      quater  = 'Q1'
    } else if (month >= 4 && month <= 6) {
      quater  = 'Q2'
    } else if (month >= 7 && month <= 9) {
      quater  = 'Q3'
    } else if (month >= 10 && month <= 12) {
      quater  = 'Q4'
    }

    let quaterData  = this.QuarterDD.filter(x=>x.text === quater)[0]  ? this.QuarterDD.filter(x=>x.text === quater)[0].value : null;
    _s.list.field.riskprofileQuarter  = quaterData ? quaterData: _s.list.field.riskprofileQuarter;
    this.func_GETDATA_RiskRating();
  }
  func_GETDATA_RiskRating() {
    const _s = this;
    let formdata = {
      SessionEmpID: 1,
      Module: "getlist",
      body: {
        RiskRatingID: "",
        QuarterID: _s.list.field.riskprofileQuarter,
        RiskRatingCode: "Test0002",
        Likelihood: "2",
        Impact: "3",
        RiskRating: "",
        EscalationLevel: "1",
        DelFlag: "0",
        LikelihoodAndImpact: ""
      }
    }
    this.mserve.GetRiskRating(formdata).then((response) => {
      if (response.data.status ){
        this.tableriskrating = response.data.body;
        this.func_calExculationLevel();
      } else {
        alert(response.data.errorMessage);
      }
    }).catch((e) => {
            if (e.response) {
                this.authserviceToken.CheckTokenExpire(e.response.status)
            } else {
                //console.log(e)
            }
        });
  }
  func_calExculationLevel() {
    const _s = this;
    let tempMedEsc = []
    this.calex_getTableriskrating(_s, tempMedEsc);


    for (let item_tempMedEsc of tempMedEsc) {

      this.checkTempMedImpact(item_tempMedEsc, _s);


    }

    if (this.appService.status_pageIdentification === 'new') {
      this.func_GetWPB();
    }
    this.func_GETMASTER_PreliminaryLikelihood();

  }
  private checkTempMedImpact(item_tempMedEsc: any, _s: any) {
    if (item_tempMedEsc.impact === "Minor") {
      this.minorCondition(item_tempMedEsc, _s);
    }
    else if (item_tempMedEsc.impact === "Moderate") {
      this.moderateCondition(item_tempMedEsc, _s);
    }
    else if (item_tempMedEsc.impact === "Significant") {
      this.dignificantCondition(item_tempMedEsc, _s);
    }
    else if (item_tempMedEsc.impact === "Serious") {
      this.seriousCondition(item_tempMedEsc, _s);
    }
    else if (item_tempMedEsc.impact === "Critical") {
      this.criticalCondition(item_tempMedEsc, _s);
    }
  }

  private criticalCondition(item_tempMedEsc: any, _s: any) {
    if (item_tempMedEsc.likelihood === "Rare") {
      _s.list.risk.risktable[0].col[1].class = "Escalation";
    }
    else if (item_tempMedEsc.likelihood === "Unlikely") {
      _s.list.risk.risktable[0].col[2].class = "Escalation";
    }
  }

  private seriousCondition(item_tempMedEsc: any, _s: any) {
    if (item_tempMedEsc.likelihood === "Rare") {
      _s.list.risk.risktable[1].col[1].class = "Escalation";
    }
    else if (item_tempMedEsc.likelihood === "Unlikely") {
      _s.list.risk.risktable[1].col[2].class = "Escalation";
    }
    else if (item_tempMedEsc.likelihood === "Possible") {
      _s.list.risk.risktable[1].col[3].class = "Escalation";
    }
  }

  private dignificantCondition(item_tempMedEsc: any, _s: any) {
    if (item_tempMedEsc.likelihood === "Unlikely") {
      _s.list.risk.risktable[2].col[2].class = "Escalation";
    }
    else if (item_tempMedEsc.likelihood === "Possible") {
      _s.list.risk.risktable[2].col[3].class = "Escalation";
    }
    else if (item_tempMedEsc.likelihood === "Likely") {
      _s.list.risk.risktable[2].col[4].class = "Escalation";
    }
  }

  private moderateCondition(item_tempMedEsc: any, _s: any) {
    if (item_tempMedEsc.likelihood === "Possible") {
      _s.list.risk.risktable[3].col[3].class = "Escalation";
    }
    else if (item_tempMedEsc.likelihood === "Likely") {
      _s.list.risk.risktable[3].col[4].class = "Escalation";
    }
    else if (item_tempMedEsc.likelihood === "Almost Certain") {
      _s.list.risk.risktable[3].col[5].class = "Escalation";
    }
  }

  private minorCondition(item_tempMedEsc: any, _s: any) {
    if (item_tempMedEsc.likelihood === "Likely") {
      _s.list.risk.risktable[4].col[4].class = "Escalation";
    }
    else if (item_tempMedEsc.likelihood === "Almost Certain") {
      _s.list.risk.risktable[4].col[5].class = "Escalation";
    }
  }

  private calex_getTableriskrating(_s: any, tempMedEsc: any[]) {
    _s.tableriskrating.forEach(function (ItemVal, ItemIndex) {
      if (ItemVal.riskRating === "Medium" && ItemVal.escalationLevel === "1") {
        tempMedEsc.push(ItemVal);
      }
    });
  }

  func_GetWPB() {
    let formData = {
      SessionEmpID: "1",
      Module: "getlist",
      body: {
        Year: "",
        StartDate: "",
        EndDate: "",
      }
    }

    this.mserve.GetWPB(formData).then((response) => {
     
      if (response.data.status ){
        //console.log("Get Data WPB : ",response.data.body)
        this.tablewpb = response.data.body;

        let d = new Date();
        let day = (d.getDate());
        let month = (d.getMonth() + 1);
        let year = (d.getFullYear());

        this.Get_item_tablewpb(year, month, day);
      
        let BusinessNameDD = (this.BusinessNameDD.length !== 1);
                if (BusinessNameDD || this.ShowWPB) {
                    setTimeout(function () { $('#modalBU5').modal('show') }, 500);
                }
        this.loading = false;
      }
      else {
        alert(response.data.errorMessage);
      }
    }).catch((e) => {
            if (e.response) {
                this.authserviceToken.CheckTokenExpire(e.response.status)
            } else {
                //console.log(e)
            }
        });
  }
  private Get_item_tablewpb(year: number, month: number, day: number) {
    for (let item_tablewpb of this.tablewpb) {
      let tempStartday = null, tempStartmonth = null, tempStartyear = null;
      let tempEndday = null, tempEndmonth = null, tempEndyear = null;

      tempStartyear = item_tablewpb.startDate.substr(0, 4);
      tempStartmonth = item_tablewpb.startDate.substr(5, 2);
      tempStartday = item_tablewpb.startDate.substr(8, 2);
      tempStartyear = parseInt(tempStartyear);
      tempStartmonth = parseInt(tempStartmonth);
      tempStartday = parseInt(tempStartday);
  
      tempEndyear = item_tablewpb.endDate.substr(0, 4);
      tempEndmonth = item_tablewpb.endDate.substr(5, 2);
      tempEndday = item_tablewpb.endDate.substr(8, 2);
      tempEndyear = parseInt(tempEndyear);
      tempEndmonth = parseInt(tempEndmonth);
      tempEndday = parseInt(tempEndday);

      this.GetWPB_YearCheck(year,month,day,tempStartyear,tempEndyear, tempStartmonth,tempEndmonth,tempStartday,tempEndday,item_tablewpb)
    }
  }

  private GetWPB_YearCheck(year: number, month: number, day: number,tempStartyear: any,tempEndyear: any, tempStartmonth: any,tempEndmonth: any,tempStartday: any,tempEndday: any, itemttablewpb: any)
  { 
      this.ShowWPB = false;
      if((year>=tempStartyear) && (year <= tempEndyear)){
          if((month>=tempStartmonth) && (month <= tempEndmonth)){
              if((day>=tempStartday) && (day <= tempEndday)){
                  this.ShowWPB = true;
                  this.SelectWPB = '2';
                  this.WBPID = itemttablewpb.wpbId;
              }
          }
      }
  }


//   goHome(){
 

//     var path   = sessionStorage.getItem('mainUrl');
//     window.location.replace(path);

// }
  goHome() {

    let cleanPath = DOMPurify.sanitize(sessionStorage.getItem('mainUrl'));
    window.location.replace(cleanPath);

  }

  func_GETMASTER_PreliminaryLikelihood() {
    let tempQuarter = (this.Register_Data.tempQuarter == '') ? this.list.field.riskprofileQuarter : this.Register_Data.tempQuarter;

    let formData = {
      Module: "PrelimiLikelihoodDD",
      TextSearch7: tempQuarter
    }
    this.mserve.Master_DDL(formData).then((response) => {
      if (response.data.status ){
        this.Preliminarylikelyhood = response.data.body
        this.func_GETMASTER_PreliminaryImpact()
      } else {
        //console.log('false',response)
      }
    });
  }
  func_GETMASTER_PreliminaryImpact() {
    let tempQuarter = (this.Register_Data.tempQuarter == '') ? this.list.field.riskprofileQuarter : this.Register_Data.tempQuarter;

    let formData = {
      Module: "PrelimiImpactDD",
      TextSearch7: tempQuarter
    }
    this.mserve.Master_DDL(formData).then((response) => {
      if (response.data.status ){
        this.PreliminaryImpactLevel = response.data.body;
        this.loading = false;
      } else {
        //console.log('false',response)
      }
    });
  }

  func_GetData() {
    const _s = this
    _s.loading = true;
    let formData = {
      SessionEmpID: "0001",
      Module: "GET",
      body: {
        Risk_Id: this.Register_Data.Regis_RiskID,
        Risk_Name: "",
        Risk_Business_Unit: "",
        Risk_Status: "",
        Risk_Likelihood: "",
        Risk_Impact: "",
        Risk_Category: "",
        Risk_Objective: "",
        Risk_Unit_KPI: "",
        Risk_Context: "",
        Role: this.Register_Data.Regis_Role,
        Risk_Type: 'Organization'
      }
    }
    this.mserve.RegisterRisk(formData).then((response) => {
      if (response.data.status ){
        this.TempData = response.data.body[0];
        this.appService.showBUName = this.TempData.abbreviation;
        this.fn_SetRegister();

        sessionStorage.setItem('BUNameWork', this.TempData.abbreviation);
        sessionStorage.setItem('BUType', this.TempData.risk_Type);
        sessionStorage.setItem('BUValueWork', this.TempData.risk_Business_Unit);
        this.dataService.sendData(this.TempData.abbreviation);
        this.TempData.rootCause_Mitigation.forEach(function (ItemVal, ItemIndex) {
          _s.tempRootCauseMitigation.push(ItemVal.rootCause_Mitigation_Progress)
        });
        this.TempData.impact_Mitigation.forEach(function (ItemVal, ItemIndex) {
          _s.tempImpactMitigation.push(ItemVal.impact_Mitigation_Progress)
        });

        this.loadDDL();
        this.func_PlotRiskMap()
        this.func_CheckStatus();
      } else {
        alert(response.data.errorMessage);
      }
    }).catch((e) => {
            if (e.response) {
                this.authserviceToken.CheckTokenExpire(e.response.status)
            } else {
                //console.log(e)
            }
        });
  }

  private fn_SetRegister() {
    this.Register_Data.Regis_RiskNo = (this.TempData.risk_Co_Id !== null) ? this.TempData.risk_Co_Id : this.TempData.risk_Staff_Id;
    this.Register_Data.Regis_Riskname = this.TempData.risk_Name;
    this.Register_Data.Regis_Likelyhood = (this.TempData.risk_Likelihood === null) ? '' : this.TempData.risk_Likelihood;
    this.Register_Data.Regis_Impact = (this.TempData.risk_Impact === null) ? '' : this.TempData.risk_Impact;
    this.Register_Data.Regis_Status = (this.TempData.risk_Status === null) ? '' : this.TempData.risk_Status;
    this.Register_Data.Regis_Category = (this.TempData.risk_Category === null) ? '' : this.TempData.risk_Category;
    this.Register_Data.Regis_ObjectiveHead = (this.TempData.risk_Objective_Parent === null) ? '' : this.TempData.risk_Objective_Parent;
    this.Register_Data.Regis_Objective = (this.TempData.risk_Objective === null) ? '' : this.TempData.risk_Objective;
    this.Register_Data.Regis_KPI = this.TempData.risk_Unit_KPI;
    this.Register_Data.Regis_Context = this.TempData.risk_Context;
    this.Register_Data.Regis_Status_Workflow = this.TempData.risk_Status_Workflow;
    this.Register_Data.Regis_BusinessName = (this.TempData.abbreviation === null) ? '' : this.TempData.abbreviation;
    this.Register_Data.Regis_BusinessUnit = (this.TempData.risk_Business_Unit === null) ? '' : this.TempData.risk_Business_Unit;
    this.Register_Data.tempQuarter = this.TempData.quarterID;
  }

  func_SaveRegis() {
    let tmpmodule, tmpid
    if (this.appService.status_pageIdentification === 'new') {
      tmpmodule = 'Insert'
      tmpid = ''
    } else if (this.appService.status_pageIdentification === 'edit') {
      tmpmodule = 'UPDATE'
      tmpid = this.Register_Data.Regis_RiskID
    }

    if (this.Register_Data.Regis_Riskname === '') {
      this.alertflow = 'Please fill-in Risk Name'
      $('#modalAlertdanger5').modal('show');
    } else if (this.Register_Data.Regis_Likelyhood === '') {
      this.alertflow = 'Please select Preliminary Likelihood'
      $('#modalAlertdanger5').modal('show');
    } else if (this.Register_Data.Regis_Impact === '') {
      this.alertflow = 'Please select Preliminary Impact'
      $('#modalAlertdanger5').modal('show');
    } else if (tmpmodule === 'UPDATE' && this.Register_Data.Regis_Status_Workflow !== '0' && this.riskstatusfrom_myrisk !== '16' && this.Register_Data.Regis_Status_Workflow !== '23') {
      $('#modalAlertwarning5').modal('show');
      this.tmpid = tmpid;
      this.tmpmodule = tmpmodule;
    }
    else if (tmpmodule === 'UPDATE' && this.Register_Data.Regis_Status_Workflow !== '0' && (this.riskstatusfrom_myrisk === '16' || this.riskstatusfrom_myrisk === '23' || this.Register_Data.Regis_Status_Workflow === '23')) {
      this.func_runSaveRegis(tmpid, tmpmodule)
    }
    else if (tmpmodule === 'UPDATE' && this.Register_Data.Regis_Status_Workflow === '0') {
      this.func_runSaveRegis(tmpid, tmpmodule)
    }
    else if (tmpmodule !== 'UPDATE') {
      this.func_runSaveRegis(tmpid, tmpmodule)
    }
  }
  func_runSaveRegis(tmpid, tmpmodule) {
    let statusflow;
    statusflow    = '23'
  
    let formData = {
      SessionEmpID: "0001",
      Module: tmpmodule,
      body: {
        Risk_Id: tmpid,
        Risk_Name: this.Register_Data.Regis_Riskname,
        Risk_Business_Unit_Abbreviation: this.Register_Data.Regis_BusinessName,
        Risk_Business_Unit: this.Register_Data.Regis_BusinessUnit,
        Risk_Status: this.Register_Data.Regis_Status,
        Risk_Likelihood: this.Register_Data.Regis_Likelyhood,
        Risk_Impact: this.Register_Data.Regis_Impact,
        Risk_Category: this.Register_Data.Regis_Category,
        Risk_Objective: this.Register_Data.Regis_Objective,
        Risk_Objective_Parent: this.Register_Data.Regis_ObjectiveHead,
        Risk_Unit_KPI: this.Register_Data.Regis_KPI,
        Risk_Context: this.Register_Data.Regis_Context,
        Risk_Register_By: this.Register_Data.Regis_Register_By,
        Risk_Status_Workflow: statusflow,
        Role: this.Register_Data.Regis_Role,
        Risk_Type: this.Register_Data.Regis_RiskType,
        QuarterID: this.list.field.riskprofileQuarter,
        WPBID: (this.SelectWPB === '2') ? this.WBPID : '',//ERR-95
        ReCall: (this.ReCall == 1) ? this.ReCall : ''
      }
    }
    this.mserve.RegisterRisk(formData).then((response) => {
      if (response.data.status ){
        this.Register_Data.Regis_RiskID = response.data.return_Id;
        sessionStorage.setItem('RiskID', JSON.stringify(response.data.return_Id));
        this.router.navigate(['/Assessment']);
      } else {
        this.alertflow = response.data.errorMessage
        $('#modalAlertdanger5').modal('show');
      }
    }).catch((e) => {
            if (e.response) {
                this.authserviceToken.CheckTokenExpire(e.response.status)
            } else {
                //console.log(e)
            }
        });
  }

  func_RegisFirst() {
    let tmpmodule, tmpid;
    if (this.appService.status_pageIdentification === 'new') {
      tmpmodule = 'Insert'
      tmpid = ''
    } else if (this.appService.status_pageIdentification === 'edit') {
      tmpmodule = 'UPDATE'
      tmpid = this.Register_Data.Regis_RiskID
    }
    if (this.Register_Data.Regis_Riskname === '') {
      this.alertflow = 'Please fill-in Risk Name'
      $('#modalAlertdanger5').modal('show');
    } else if (this.Register_Data.Regis_Likelyhood === '') {
      this.alertflow = 'Please select Preliminary Likelihood'
      $('#modalAlertdanger5').modal('show');
    } else if (this.Register_Data.Regis_Impact === '') {
      this.alertflow = 'Please select Preliminary Impact'
      $('#modalAlertdanger5').modal('show');
    } else if (tmpmodule === 'UPDATE' && this.Register_Data.Regis_Status_Workflow !== '0' && this.riskstatusfrom_myrisk !== '16' && this.Register_Data.Regis_Status_Workflow !== '23') {
      $('#modalAlertwarning52').modal('show');
      this.tmpid = tmpid;
      this.tmpmodule = tmpmodule;
    }
    else if (tmpmodule === 'UPDATE' && this.Register_Data.Regis_Status_Workflow !== '0' && (this.riskstatusfrom_myrisk === '16' || this.riskstatusfrom_myrisk === '23' || this.Register_Data.Regis_Status_Workflow === '23')) {
      this.func_runRegisFirst(tmpid, tmpmodule)
    }
    else if (tmpmodule === 'UPDATE' && this.Register_Data.Regis_Status_Workflow === '0') {
      this.func_runRegisFirst(tmpid, tmpmodule)
    }
    else if (tmpmodule !== 'UPDATE') {
      this.func_runRegisFirst(tmpid, tmpmodule)
    }
  }

  func_runRegisFirst(tmpid, tmpmodule) {
    let statusflow;
    if (tmpmodule === 'UPDATE') {
      if (this.TempData.rootCause.length === 0 && this.TempData.impact.length === 0) {
        statusflow = '23'
      }
      else {
        statusflow = (tmpmodule !== 'UPDATE') ? '23' : '0'
      }
    } else {
      statusflow = '23';
    }

    let formData = this.func_runRegisFirst_SetFormData(tmpmodule, tmpid, statusflow)

    this.mserve.RegisterRisk(formData).then((response) => {
      if (response.data.status) {
        this.Register_Data.Regis_RiskID = response.data.return_Id;
        this.alertflow = (tmpmodule === 'Insert') ? 'Register First success' : 'Update Preliminary Success'
        $('#modalAlertsuccess5').modal('show');
      } else {
        this.alertflow = response.data.errorMessage
        $('#modalAlertdanger5').modal('show');
      }
    }).catch((e) => {
            if (e.response) {
                this.authserviceToken.CheckTokenExpire(e.response.status)
            } else {
                //console.log(e)
            }
        });
  }

  private func_runRegisFirst_SetFormData(tmpmodule: any, tmpid: any, statusflow: any) {
    return {
      SessionEmpID: "0001",
      Module: tmpmodule,
      body: {
        Risk_Id: tmpid,
        Risk_Name: this.Register_Data.Regis_Riskname,
        Risk_Business_Unit_Abbreviation: this.Register_Data.Regis_BusinessName,
        Risk_Business_Unit: this.Register_Data.Regis_BusinessUnit,
        Risk_Status: this.Register_Data.Regis_Status,
        Risk_Likelihood: this.Register_Data.Regis_Likelyhood,
        Risk_Impact: this.Register_Data.Regis_Impact,
        Risk_Category: this.Register_Data.Regis_Category,
        Risk_Objective: this.Register_Data.Regis_Objective,
        Risk_Objective_Parent: this.Register_Data.Regis_ObjectiveHead,
        Risk_Unit_KPI: this.Register_Data.Regis_KPI,
        Risk_Context: this.Register_Data.Regis_Context,
        Risk_Register_By: this.Register_Data.Regis_Register_By,
        Risk_Status_Workflow: statusflow,
        Role: this.Register_Data.Regis_Role,
        Risk_Type: this.Register_Data.Regis_RiskType,
        QuarterID: this.list.field.riskprofileQuarter,
        WPBID: (this.SelectWPB === '2') ? this.WBPID : '',//ERR-95 
        ReCall: (this.ReCall == 1) ? this.ReCall : ''
      }
    };
  }

  func_PlotRiskMap() {
    for (let i = 0; i < 5; i++) {
      for (let ii = 1; ii <= 5; ii++) {
        this.list.risk.risktable[i].col[ii].item = []
      }
    }

    for (let ii = 0; ii <= 5; ii++) {
      for (let i = 1; i <= 5; i++) {
        if (parseInt(this.Register_Data.Regis_Likelyhood) === i && parseInt(this.Register_Data.Regis_Impact) === (ii + 1)) {
          this.list.risk.risktable[(4 - ii)].col[i].item.push({
            title: '1',
            bgcolor: this.list.risk.risktable[(4 - ii)].col[0].item[0].bgcolor,
            color: this.list.risk.risktable[(4 - ii)].col[0].item[0].color
          })
        }
      }
    }
  }

  func_CheckStatus() {
    
    const _s = this;
    let chkrootpro = true;

    _s.checkDDstatus = false
    let chkroot = false, chkimpact = false
    chkroot = this.func_CheckStatus_get_Root(_s, chkroot);

    chkimpact = this.func_CheckStatus_get_impact(_s, chkimpact);
    _s.checkDDstatus = (chkroot && chkimpact)


  }

  private func_CheckStatus_get_impact(_s: any, chkimpact: boolean) {
    _s.TempData.impact.forEach(function (itemsImpact, ItemIndex) {
      if (itemsImpact.impact_Mitigation_Type == "Treat" || itemsImpact.impact_Mitigation_Type == "Transfer") {
        let tempFileter = _s.TempData.impact_Mitigation.filter(x => x.impact_Id == itemsImpact.impact_Id && x.impact_Mitigation_Progress == "100");
        if (tempFileter.length !== 0) {
          chkimpact = true;
          return;
        }
      }

      else
        chkimpact = true;
    });
    return chkimpact;
  }

  private func_CheckStatus_get_Root(_s: any, chkroot: boolean) {
    _s.TempData.rootCause.forEach(function (itemsRootcause, ItemIndex) {
      if (itemsRootcause.rootCause_Mitigation_Type == "Treat" || itemsRootcause.rootCause_Mitigation_Type == "Transfer") {
        let tempFileter = _s.TempData.rootCause_Mitigation.filter(x => x.rootCause_Id == itemsRootcause.rootCause_Id && x.rootCause_Mitigation_Progress == "100");
        if (tempFileter.length !== 0) {
          chkroot = true;
          return;
        }
      }

      else
        chkroot = true;
    });
    return chkroot;
  }

  func_OkBU() {
    this.chkbackmenu = 'go'
    this.dataService.sendData(this.tempselectbu);
    $('#modalBU5').modal('hide');
  }
  close() {
    $('#modalBU5').modal('show');
    $('#modalAlert5').modal('hide');
  }
  BacktoMenu() {
    this.chkbackmenu = 'back'
    $('#modalBU5').modal('hide');
  }
  closeflow() {
    $('#modalAlertdanger5').modal('hide');
  }
  closeflowsuccess() {
    const _s = this;
    $('#modalAlertsuccess5').modal('hide');
    sessionStorage.setItem('ChkBTNNextFromReview', 'ok')
    setTimeout(function () { _s.router.navigate(['/Approval', 2]); }, 500)
  }

  Yeswarning() {
    const _s = this;
    $('#modalAlertwarning5').modal('hide');
    setTimeout(function () {
      _s.ReCall = 1;
      _s.func_runSaveRegis(_s.tmpid, _s.tmpmodule)
    }, 500)
  }
  closewarning() {
    $('#modalAlertwarning5').modal('hide');
  }

  Yeswarning2() {
    const _s = this;
    $('#modalAlertwarning52').modal('hide');
    setTimeout(function () {
      _s.ReCall = 1;
      _s.func_runRegisFirst(_s.tmpid, _s.tmpmodule)
    }, 500)
  }
  closewarning2() {
    $('#modalAlertwarning52').modal('hide');
  }

  private _filterBUDD(value: string): string[] {
    let tempFileter;
    const filterValue = value?.toLowerCase();
    if (filterValue !== '') {
      tempFileter = this.BusinessNameDD.filter(option => option.text?.toLowerCase().includes(filterValue));
      if (tempFileter.length !== 0) {
        return tempFileter;
      }
    } else {
      return this.BusinessNameDD
    }
  }

  ngAfterViewInit(): void {
    const _s = this
    scroll(0, 390)
  }

}

export interface DialogData { }
@Component({
  selector: 'app-dialog-elements',
  templateUrl: '../_comp/dialog-elements.html',
})
export class Dialogelements {
  constructor(public dialogRef: MatDialogRef<Dialogelements>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  onNoClick() {
    this.dialogRef.close();
  }
}
function  checkState(tempBU: any[], _s: any) {
  if (tempBU[0].State === 'Asset') {
    _s.Register_Data.Regis_RiskType = 'Asset';
    sessionStorage.setItem('BUType', _s.Register_Data.Regis_RiskType);
  } else if (tempBU[0].State === 'Org') {
    _s.Register_Data.Regis_RiskType = 'Organization';
    sessionStorage.setItem('BUType', _s.Register_Data.Regis_RiskType);
  } else {
    _s.Register_Data.Regis_RiskType = 'Corporate';
    sessionStorage.setItem('BUType', _s.Register_Data.Regis_RiskType);
  }
}

function   RegisRole(_s: any, tempBU: any[]) {
  for (let item_BusinessNameDD of _s.BusinessNameDD) {
    if (_s.tempselectbu === item_BusinessNameDD.text) {
      tempBU.push({ BuCode: item_BusinessNameDD.value, State: item_BusinessNameDD.state });
      for (let item_tempChkRoleBUDD of _s.tempChkRoleBUDD) {
        if ((item_tempChkRoleBUDD.text == _s.tempselectbu) && (item_tempChkRoleBUDD.role == _s.Register_Data.Regis_Register_By || item_tempChkRoleBUDD.role == 'Co')) {
          _s.Register_Data.Regis_Role = 'Co';
          sessionStorage.setItem("TranSecRole", 'Co');
          break;
        }
        else if ((item_tempChkRoleBUDD.text == _s.tempselectbu) && (item_tempChkRoleBUDD.role != _s.Register_Data.Regis_Register_By)) {
          _s.Register_Data.Regis_Role = 'Staff';
          sessionStorage.setItem("TranSecRole", 'Staff');
        }
      }
      

    }
  }
}

