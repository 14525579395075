<div class="container-fluid appform my-3">
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1"  >


            <div class="col-12 col-md-12 col-lg-12 col-xl-12 pb-5 mt-5 mb-5">
                <div class="card col-12 mt-4 mx-0 p-3">

                    <div class="col-12 pt-2 banner rounded">
                        <em class="fas fa-bars icon"></em>&nbsp;&nbsp;Work Program Budget
                    </div>
                    <div class="col-12 px-0" style="text-align: right;">
                        <button type="button" class="btn btn-lg btn-banner-primary mb-3" style="width:200px;color:white;background:orange;" (click)="func_btnAddAssetUnit()">
                            <em class="fas fa-plus icon"></em>&nbsp;&nbsp;&nbsp;Add WPB
                        </button>
                    </div>

                    <div class="table-banner">
                        <table class="table-bordered" style="width:100%;">
                            <caption style="display: none;" >This is a custom table</caption>
                            <thead style="text-align: center;background-color:#033153;color:white;font-size:18px;">
                                <tr>
                                    <th style="vertical-align: middle;">No</th>
                                    <th style="vertical-align: middle;">year</th>
                                    <th style="vertical-align: middle;">Start Date</th>
                                    <th style="vertical-align: middle;">End Date</th>
                                    <th style="vertical-align: middle;">Action</th>
                                </tr>
                            </thead>
                            <tbody style="background-color: white;font-size:16px;">
                                <tr *ngFor="let item of tablewpb index as index">
                                    <td style="text-align: center;">{{ index + 1}}</td>
                                    <td>{{ item.year }}</td>
                                    <td>{{ item.startDateText }}</td>
                                    <td>{{ item.endDateText }}</td>
                                    <td style="text-align: center;">
                                        <button type="button" class="btn" (click)="func_edit(item)">
                                            <em class="fas fa-edit" style="color:orange;font-size:16px;"></em>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>

        </div>
    </div>
    <div class="modal fade bd-Mianmenu" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" id="modaladdAssetUnit">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="container-fluid appform my-3">
                    <div class="row">
                        <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">
                            <div class="container col-12 col-md-12 col-lg-12 col-xl-12 pb-0 mt-0 mb-0 ">

                                <div class="container">
                                    <div class="col-3 pt-2 rounded modalbanner">
                                        <em class="fas fa-bars icon"></em>
                                        <span *ngIf="status === 'insert'">&nbsp;&nbsp;Add WPB</span>
                                        <span *ngIf="status === 'edit'">&nbsp;&nbsp;Edit WPB</span>
                                    </div>
                                    <div class="card mt-4 py-3">
                                        <div class="row col-12 mb-4 px-0">
                                            <div class="row col-5 mx-0">
                                                <div class="col-12 mb-2">
                                                    <strong class="formtext">Year</strong>
                                                </div>
                                                <div class="col-12">
                                                    <select class="form-control" [(ngModel)]="Year" [disabled]=' status == "edit" ' (change)="SelectYear()" >
                                                        <option value="">Please Select</option>
                                                        <option *ngFor="let data of YearDD" [value]="data.text" [disabled]=' data.text != currentyear '>{{data.text}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row col-12 mb-4">
                                            <div class="row col-5 mx-0">
                                                <div class="col-12 mb-2 px-0">
                                                    <strong class="formtext">Start:</strong>
                                                </div>
                                                <div class="co1-12">
                                                    <input id="quaterfrom" class="form-control" autocomplete="off"
                                                    name="quaterfrom" [(ngModel)]="StartDate" [minDate]="minDate" [maxDate]="maxDate"
                                                    ngbDatepicker
                                                    #quaterfrom="ngbDatepicker"
                                                    style="width: 200px;"
                                                    [disabled]=' Year == "" '>
                                                </div>
                                                <button class="btn btn-sm calendar py-0"
                                                    (click)="quaterfrom.toggle()" type="button"
                                                    style="color: #00aeef;font-size: 22px;">
                                                    <em class="far fa-calendar-alt icon_calendar"></em>
                                                </button>
                                            </div>
                                            <div class="row col-5 mx-0 px-0">
                                                <div class="col-12 mb-2 px-0">
                                                    <strong class="formtext">End:</strong>
                                                </div>
                                                <div class="co1-12 px-0">

                                                    <input id="quaterto" class="form-control" autocomplete="off"
                                                    name="quaterto" [(ngModel)]="EndDate" [minDate]="minDate2" [maxDate]="maxDate2"
                                                    ngbDatepicker
                                                    #quaterto="ngbDatepicker"
                                                    style="width: 200px;"
                                                    [disabled]=' StartDate == "" '>
                                                </div>
                                                <button class="btn btn-sm calendar py-0"
                                                    (click)="quaterto.toggle()" type="button"
                                                    style="color: #00aeef;font-size: 22px;">
                                                    <em class="far fa-calendar-alt icon_calendar"></em>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 my-3 mx-0 text-center">
                                        <button type="button" class="btn mr-3" style="width:100px;background-color:#9eb0ba;color:white;" (click)="func_Close()">Close</button>
                                        <button type="button" class="btn" style="width:100px;background-color:#033153;color:white;" (click)="func_save('insert')" *ngIf="status === 'insert'">Save</button>
                                        <button type="button" class="btn" style="width:100px;background-color:#033153;color:white;" (click)="func_save('update')" *ngIf="status === 'edit'">Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>