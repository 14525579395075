import { Component, OnInit } from '@angular/core';
import { AuthenService } from 'src/app/services/authenservice.service';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import {
  AssumptionRedAlertModel,
  StrategyModel,
} from '../../../model/MonitorReviewModel';
import { MonitorAndReviewService } from '../../../services/monitorandreview.service';
import { ActionLogModel } from 'src/app/StrategicRisks/shared/model/ActionLogModel';
declare let $;

@Component({
  selector: 'app-monitor',
  templateUrl: './monitor.component.html',
  styleUrls: ['./monitor.component.css'],
  providers: [NgbModalConfig, NgbModal],
})
export class MonitorComponent implements OnInit {
  constructor(
    public authserviceToken: AuthenService,
    config: NgbModalConfig,
    private modalService: NgbModal,
    public monitorAndReviewService: MonitorAndReviewService,
    private router: Router,
    private routerParam: ActivatedRoute
  ) {}

  // @Input() dataSelect: StrategyModel;
  // @Input() dataListAll: StrategyModel[];

  Data_SessionStorage;

  loading = false;
  page = 1;
  pageSize = 5;
  strategyNumber = '';
  strategyItem = {};
  strategyIndex = 0;
  strategyPage = 0;
  strategyLength = 0;
  selectedCurrentStatus = '';

  popupTitleAlert = '';
  popupMessageAlert = '';
  deleteIdStrategy = 0;

  DataMonitorReview: AssumptionRedAlertModel[] = [];
  DataStrategy: StrategyModel[] = [];
  DataSelectStrategy: StrategyModel[] = [];
  selectStrategyIndex = 0;
  selectStrategyId = '';

  DataViewsMonitorReview = [];

  isOpenPageMonitor = false;

  empid = '';
  empRole = '';
  createBy = '';
  isRefresh = false;

  getDataBU = '';

  percentProgress = '';

  actionLogLst: ActionLogModel[] = [];

  ngOnInit(): void {
    this.Data_SessionStorage = JSON.parse(sessionStorage.datainfo);
    this.empid = this.Data_SessionStorage[0].employeE_ID;
    this.empRole = sessionStorage.getItem('buRole');
    this.getDataBU = sessionStorage.getItem('buCode');
    this.selectStrategyId = this.routerParam.snapshot.paramMap.get('Id');
    this.DataSelectStrategy.push(new StrategyModel);
    this.func_GetData();
  }

  func_GetData() {
    let data = {
      Bu: this.getDataBU,
    };
    this.monitorAndReviewService
      .func_getDataListMonitorReview(data)
      .then((response) => {
        if (response.data.status) {
          this.DataMonitorReview = response.data.body.assumptionRedAlertList;
          this.DataStrategy = response.data.body.strategyList;
          this.DataSelectStrategy = this.DataStrategy.filter(
            (f) => f.riskId == this.selectStrategyId
          );
          this.DataSelectStrategy[0].assumptionList =
            this.DataSelectStrategy[0].assumptionList.map((item) => {
              return { ...item, EditStatus: false };
            });
          this.DataViewsMonitorReview = this.DataSelectStrategy[0].assumptionList;
          this.createBy = this.DataSelectStrategy[0].riskRegisterBy;
          this.strategyNumber = this.DataSelectStrategy[0].riskNo + ' : ' + this.DataSelectStrategy[0].riskName;
          this.strategyLength = this.DataStrategy.length - 1;
          this.strategyPage = this.DataStrategy.findIndex(
            (f) => f.riskNo == this.DataSelectStrategy[0].riskNo
          );

          this.loading = false;
        } else {
          alert(response.data.errorMessage);
          this.loading = false;
        }
      })
      .catch((e) => {
        this.authserviceToken.CheckTokenExpire(e.response.status);
      });
  }

  func_review() {
    this.DataSelectStrategy[0] = this.DataStrategy[this.strategyPage];
    this.router.navigate([
      '/Monitor-Review/Review/' + this.DataSelectStrategy[0].riskId,
    ]);
  }

  func_nextAssumption() {
    this.strategyPage = this.strategyPage + 1;
    this.DataSelectStrategy[0] = this.DataStrategy[this.strategyPage];
    this.DataSelectStrategy[0].assumptionList =
      this.DataSelectStrategy[0].assumptionList.map((item) => {
        return { ...item, EditStatus: false };
      });
    this.DataViewsMonitorReview = this.DataSelectStrategy[0].assumptionList;
    this.createBy = this.DataSelectStrategy[0].riskRegisterBy;
    this.strategyNumber = this.DataSelectStrategy[0].riskNo  + ' : ' + this.DataSelectStrategy[0].riskName;
    this.strategyLength = this.DataStrategy.length - 1;
    this.strategyPage = this.DataStrategy.findIndex(
      (f) => f.riskNo == this.DataSelectStrategy[0].riskNo
    );
    this.router.navigate([
      '/Monitor-Review/Monitor/' + this.DataSelectStrategy[0].riskId,
    ]);
  }

  func_PreviousAssumption() {
    this.strategyPage = this.strategyPage - 1;
    this.DataSelectStrategy[0] = this.DataStrategy[this.strategyPage];
    this.DataSelectStrategy[0].assumptionList =
      this.DataSelectStrategy[0].assumptionList.map((item) => {
        return { ...item, EditStatus: false };
      });
    this.DataViewsMonitorReview = this.DataSelectStrategy[0].assumptionList;
    this.createBy = this.DataSelectStrategy[0].riskRegisterBy;
    this.strategyNumber = this.DataSelectStrategy[0].riskNo  + ' : ' + this.DataSelectStrategy[0].riskName;
    this.strategyLength = this.DataStrategy.length - 1;
    this.strategyPage = this.DataStrategy.findIndex(
      (f) => f.riskNo == this.DataSelectStrategy[0].riskNo
    );
    this.router.navigate([
      '/Monitor-Review/Monitor/' + this.DataSelectStrategy[0].riskId,
    ]);
  }

  func_editstatus(item) {
    let checkAllStatusEdit = this.DataViewsMonitorReview.filter(
      (f) => f.EditStatus
    );

    if (checkAllStatusEdit.length == 0) {
      this.strategyIndex = this.DataViewsMonitorReview.findIndex(
        (f) =>
          f.assumptionId == item.assumptionId && f.categoryId == item.categoryId
      );
      this.DataViewsMonitorReview[this.strategyIndex].EditStatus = true;
      this.percentProgress =
        this.DataViewsMonitorReview[this.strategyIndex].percentProgress;
    }
  }

  func_saveUpdateAssumption(item) {

    let strategyIndex = this.DataViewsMonitorReview.findIndex(
      (f) => f.assumptionId == item.assumptionId
    );
    let curItem = this.DataViewsMonitorReview[strategyIndex];
    curItem.currentStatusInvalid =
      item.currentStatus == null || item.currentStatus == '';
    curItem.percentProgressInvalid =
      this.percentProgress == null || this.percentProgress == '';
    curItem.rationaleInvalid = item.rationale == null || item.rationale == '';
    if (
      curItem.currentStatusInvalid ||
      curItem.percentProgressInvalid ||
      curItem.rationaleInvalid
    ) {
      return;
    }

    this.loading = true;
    this.actionLogLst = [];
    this.actionLogLst.push(this.buildActionLog("Strategic Assumption Monitoring"));
    let dataUpdate = {
      CurrentStatus: item.currentStatus,
      Rationale: item.rationale,
      PercentProgress: this.percentProgress,
      RiskModifiedBy: this.empid,
      StrategicRiskLogList: this.actionLogLst
    };
   
    this.monitorAndReviewService
      .func_updateAssumption(item.assumptionId, dataUpdate)
      .then((response) => {
        if (response.data.status) {
          this.isRefresh = true;
          this.popupTitleAlert = 'Alert !';
          this.popupMessageAlert = 'Update Success';
          $('#modalSuccess').modal('show');
          this.loading = false;
        } else {
          //console.log('false', response);
          this.popupMessageAlert = 'error';
          this.isRefresh = true;
          $('#modalAlert').modal('show');
          this.loading = false;
        }
      })
      .catch((e) => {
        this.authserviceToken.CheckTokenExpire(e.response.status);
      });
  }

  Close_Modal() {
    this.router.navigate(['/Monitor-Review']);
  }

  validateInput(event: any) {
    let inputValue = event.target.value;
    let newPercentProgress = null;
    if (inputValue) {
      const numericValue = parseInt(inputValue, 10);
      const clampedValue = Math.min(100, Math.max(0, numericValue || 0));
      newPercentProgress = clampedValue.toString();
    }

    this.percentProgress = '';
    this.percentProgress = newPercentProgress;
  }

  modelChanged(event) {
    this.percentProgress = '';
    setTimeout(() => {
      if (event || event == 0) {
        const val = event;
        const numericValue = parseInt(val, 10);
        const clampedValue = Math.min(100, Math.max(0, numericValue || 0));
        this.percentProgress = clampedValue.toString();
      }
    }, 0);
  }

  buildActionLog(section){
    let logData = new ActionLogModel;
    logData.riskId = Number(this.DataSelectStrategy[0].riskId) ?? null;
    logData.createDate = (new Date()).toLocaleString('en-US', { timeZone: 'Asia/Bangkok' });
    
    logData.createBy = this.empid;
    if(logData.riskId)
    {
        logData.section = section;
        logData.action = 'Update';
    }
    return logData
  }

}
