<div class="container-fluid appform my-3">
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1"  >

            <div class="col-12 col-md-12 col-lg-12 col-xl-12 pb-5 mt-5 mb-5">
                <div class="card col-12 mt-4 mx-0 p-3">

                    <div class="col-12 pt-2 banner rounded">
                        <em class="fas fa-bars icon"></em>&nbsp;&nbsp;Config Asset Unit
                    </div>
               
                    <div class="col-12 " style="text-align: right;">
                        <button type="button" class="btn btn-lg mb-3" style="width:200px;color:white;background:orange;" (click)="func_btnAddAssetUnit()">
                            <em class="fas fa-plus icon"></em>&nbsp;&nbsp;&nbsp;Add Asset Unit
                        </button>
                    </div>
                   
                    <div class="col-12">
                        <ul class="nav nav-pills mb-1" id="pills-tab" role="tablist">
                            <li class="nav-item" style="margin: 1px 1px 1px 0px;" *ngFor="let item of DataFG index as index1">
                                <a class="nav-link active" data-toggle="pill" id="BUCO{{ index1 + 1 }}" href="#" role="tab" (click)="SelectFG(item, index1)" *ngIf="index1 === 0">{{ item.title }}</a>
                                <a class="nav-link" data-toggle="pill" id="BUCO{{ index1 + 1 }}" href="#" role="tab" (click)="SelectFG(item, index1)" *ngIf="index1 !== 0">{{ item.title }}</a>
                            </li>
                        </ul>

                            <table class="table-bordered table-hover" style="width:100%;">
                                <caption style="display: none;" >This is a custom table</caption>
                                <thead style="text-align:center; background-color:#033153; color:white;">
                                    <tr>
                                        <th style="width: 80px;">FG</th>
                                        <th style="width: 80px;">DIV</th>
                                        <th style="width: 80px;">DEPT</th>
                                        <th style="width: 80px;">Asset Name</th>
                                        <th style="width: 200px;">Risk Coordinator</th>
                                        <th style="width: 10px;">Edit</th>
                                    </tr>
                                </thead>
                                <tbody style="background-color:white;">
                                    <tr *ngFor="let item of OptimizedData index as index1">
                                        <td style="text-align: center;">{{ item.FG }}</td>
                                        <td style="text-align: center;">{{ item.DIV }}</td>
                                        <td style="text-align: center;">{{ item.DEPT }}</td>
                                        <td style="text-align: center;">{{ item.Asses }}</td>
                                        <td style="text-align: center;">
                                            <div *ngFor="let item2 of item.CO">
                                                <span *ngIf="item.Asses !== undefined">{{ item2.coname }}<br></span>
                                            </div>
                                        </td>
                                        <td style="text-align: center;">
                                            <button type="button" class="btn" (click)="func_edit(item)" *ngIf="item.Asses !== undefined && tempchkyear == currentyear">
                                                <em class="fas fa-edit" style="color:orange;font-size:18px;"></em>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                    </div>

                </div>
            </div>

        </div>
    </div>

    <div class="modal fade bd-Mianmenu" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" id="modaladdAssetUnit">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="container-fluid appform my-3">
                    <div class="row">
                        <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">
                            <div class="container col-12 col-md-12 col-lg-12 col-xl-12 pb-0 mt-0 mb-0 ">

                                <div class="container">
                                    <div class="col-6 col-md-4 col-xl-3 pt-2 rounded modalbanner">
                                        <em class="fas fa-bars icon"></em>
                                        <span *ngIf="status === 'insert'">&nbsp;&nbsp;Add Asset Unit</span>
                                        <span *ngIf="status === 'edit'">&nbsp;&nbsp;Edit Asset Unit</span>
                                    </div>

                                    <div class="card mt-4 py-3">
                                         <div class="row col-12 mb-4 mx-0 px-0">

                                            <div class="row col-12 col-xl-4 mx-0 px-0">
                                                <div class="col-12 mb-2">
                                                    <strong class="formtext">Year</strong>
                                                </div>
                                                <div class="col-12">
                                                    <select class="form-control" [(ngModel)]="RegisAssetUnit.RegisYear" (change)="SelectedYear('')" [disabled]=' status == "edit" '>
                                                        <option value="">Please Select</option>
                                                        <option *ngFor="let data of YearDD" [value]="data.text" [disabled]="data.text != currentyear">{{data.text}}</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="row col-12 col-xl-4 mx-0 px-0">
                                                <div class="col-12 mb-2">
                                                    <strong class="formtext">Quarter</strong>
                                                </div>
                                                <div class="col-12">
                                                    <select class="form-control" [(ngModel)]="RegisAssetUnit.RegisQuarter" [disabled]='RegisAssetUnit.RegisYear === "" || status == "edit" '>
                                                        <option value="">Please Select</option>
                                                        <option *ngFor="let data of AddCo_QuarterDD" [value]="data.value">{{data.text}}</option>
                                                    </select>
                                                </div>
                                            </div>

                                        </div>

                                        <div class="row col-12 mb-4 mx-0 px-0">
                                            <div class="row col-12 col-xl-4 mx-0 px-0">
                                                <div class="col-12 mb-2">
                                                    <strong class="formtext">Asset Name</strong>
                                                </div>
                                                <div class="col-12">
                                                    <input type="text" class="form-control" [(ngModel)]="RegisAssetUnit.RegisAssetName">
                                                </div>
                                            </div>
                                            <div class="row col-12 col-xl-4 mx-0 px-0">
                                                <div class="col-12 mb-2">
                                                    <strong class="formtext">Asset Initials Name</strong>
                                                </div>
                                                <div class="col-12">
                                                    <input type="text" class="form-control" [(ngModel)]="RegisAssetUnit.RegisAssetinitialsName">
                                                </div>
                                            </div>
                                            <div class="row col-12 col-xl-3 mx-0 px-0">
                                                <div class="col-12 mb-2">
                                                    <strong class="formtext">Asset Code</strong>
                                                </div>
                                                <div class="col-12">
                                                    <input type="text" class="form-control" [(ngModel)]="RegisAssetUnit.ShowassesCode" disabled>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row col-12 mb-4 mx-0 px-0">
                                            <div class="row col-12 col-xl-4 mx-0 px-0">
                                                <div class="col-12 mb-2">
                                                    <strong class="formtext">Parent Org Level</strong>
                                                </div>
                                                <div class="col-12">
                                                    <select class="form-control" [(ngModel)]="RegisAssetUnit.RegisParentOrg" (change)="onParentChange($event.target.value)">
                                                        <option value="">Please Select</option>
                                                        <option *ngFor="let item of ParentOrgLevelDD" value="{{ item.text }}">{{ item.text }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="row col-12 col-xl-4 mx-0 px-0">
                                                <div class="col-12 mb-2">
                                                    <strong class="formtext">Org Name</strong>
                                                </div>
                                                <div class="col-12">
                                                    <input type="text" class="form-control m-0" aria-label="Number" matInput [formControl]="myControlOrgNameDD" [matAutocomplete]="auto1" [(ngModel)]="selorgname" (keyup.enter)="selectOrg()" [hidden]="RegisAssetUnit.RegisParentOrg === ''">
                                                    <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete">
                                                        <mat-option *ngFor="let option of filteredOptionsOrgNameDD | async" [value]="option.text" (click)="selectOrg()">
                                                        {{option.text}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                    <input type="text" class="form-control m-0" disabled [hidden]="RegisAssetUnit.RegisParentOrg !== ''">
                                                </div>
                                            </div>
                                            <div class="row col-12 col-xl-2 mx-0 px-0">
                                                <div class="col-12 mb-2">
                                                    <strong class="formtext">Status</strong>
                                                </div>
                                                <div class="col-12">
                                                    <select class="form-control" [(ngModel)]="RegisAssetUnit.RegisStatus">
                                                        <option value="">Please Select</option>
                                                        <option *ngFor="let item of StatusDD" value="{{ item.value }}">{{ item.text }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
 
                                    <div class="col-12 px-1 table-responsive">
                                        <table class="table-bordered" style="width:100%;">
                                            <caption style="display: none;" >This is a custom table</caption>
                                            <thead style="text-align:center; background-color:#033153; color:white;">
                                                <tr>
                                                    <th style="width: 80px;vertical-align: middle;">No.</th>
                                                    <th style="width: 80px;vertical-align: middle;">Employee ID</th>
                                                    <th style="width: 80px;vertical-align: middle;">Name</th>
                                                    <th style="width: 200px;vertical-align: middle;">Email</th>
                                                    <th style="width: 10px;vertical-align: middle;">Business Unit</th>
                                                    <th style="width: 10px;vertical-align: middle;">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody style="background-color:white;">
                                                <tr *ngFor="let item of TempdataEmployee index as index1">
                                                    <td style="text-align: center;">
                                                        <label>{{ index1 + 1 }}</label>
                                                    </td>
                                                    <td>
                                                        <label>{{ item.Emp_id }}</label>
                                                    </td>
                                                    <td>
                                                        <label>{{ item.Emp_name }}</label>
                                                    </td>
                                                    <td style="text-align: center;">
                                                        <label>{{ item.Emp_email }}</label>
                                                    </td>
                                                    <td style="text-align: center;">
                                                        <label>{{ item.Emp_bu }}</label>
                                                    </td>
                                                    <td style="text-align: center;">
                                                        <button type="button" class="btn" (click)="Remove_emp(item)">
                                                            <em class="far fa-trash-alt" style="color:red;font-size:18px;"></em>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="text-align: center;"></td>
                                                    <td>
                                                        <input type="text" class="form-control" name="" [(ngModel)]="RegisAssetUnit.RegisEmpID" disabled>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control m-0" aria-label="Number" matInput [formControl]="myControlEmployeeID" [matAutocomplete]="autoEMP" [(ngModel)]="RegisAssetUnit.RegisEmpName" (keyup.enter)="selectEmp(RegisAssetUnit.RegisEmpName)">
                                                        <mat-autocomplete autoActiveFirstOption #autoEMP="matAutocomplete">
                                                            <mat-option *ngFor="let option of filteredOptionsEmployeeIDDD | async" [value]="option.text" (click)="selectEmp(option.text)">
                                                            {{option.text}}
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                    </td>
                                                    <td style="text-align: center;">
                                                        <input type="text" class="form-control" name="" [(ngModel)]="RegisAssetUnit.RegisEmpEmail" disabled>
                                                    </td>
                                                    <td style="text-align: center;">
                                                        <input type="text" class="form-control" name="" [(ngModel)]="RegisAssetUnit.RegisEmpBU" disabled>
                                                    </td>
                                                    <td style="text-align: center;">
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-12 my-3 mx-0 text-center">
                                        <button type="button" class="btn mr-3" style="width:100px;background-color:#9eb0ba;color:white;" (click)="func_Close()">Close</button>
                                        <button type="button" class="btn" style="width:100px;background-color:#033153;color:white;" (click)="func_save('insert')" *ngIf="status === 'insert'">Save</button>
                                        <button type="button" class="btn" style="width:100px;background-color:#033153;color:white;" (click)="func_save('update')" *ngIf="status === 'edit'">Update</button>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
     <div class="modal fade bd-Risk Coordinators" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" id="modalDeleteCoAsset">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="container-fluid appform my-3">
                    <div class="row">
                        <div class="col-12 col-md-12 col-lg-12 col-xl-12 px-1" style="font-size: 14px;">
                            <div class="container col-12 col-md-12 col-lg-12 col-xl-12 pb-0 mt-0 mb-0 ">
                                <div class="container">
                                    <div class="col-12 col-md-8 col-xl-6 pt-2 rounded modalbanner">
                                        <em class="fas fa-bars icon"></em>
                                        <span>&nbsp;&nbsp;Replace Coordinators Asset</span>
                                    </div>
                                    <div class="card mt-4">
                                        <div class="col-12 col-xl-12 mb-4">
                                            <div class="row">
                                                <div class="col-12 col-xl-6 mb-2">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <strong class="formtext">Employee ID</strong>
                                                        </div>
                                                        <div class="col-12">
                                                            <input type="text" class="form-control" [(ngModel)]="ReplaceEmp.EmpID" disabled>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-xl-6 mb-2">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <strong class="formtext">Name</strong>
                                                        </div>
                                                        <div class="col-12">
                                                            <input type="text" class="form-control m-0" aria-label="Number" matInput [formControl]="myControlEmployeeReplace" [matAutocomplete]="auto4" [(ngModel)]="ReplaceEmp.EmpName" (keyup.enter)="func_selectReplaceEmp(ReplaceEmp.EmpName)">
                                                            <mat-autocomplete autoActiveFirstOption #auto4="matAutocomplete">
                                                                <mat-option *ngFor="let option of filteredOptionsEmployeeIDDDReplace | async" [value]="option.Emp_name" (click)="func_selectReplaceEmp(option.Emp_name)">
                                                                {{option.Emp_name}}
                                                                </mat-option>
                                                            </mat-autocomplete>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-xl-6">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <strong class="formtext">Email</strong>
                                                        </div>
                                                        <div class="col-12">
                                                            <input type="text" class="form-control" [(ngModel)]="ReplaceEmp.EmpEmail" disabled>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-xl-6">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <strong class="formtext">Business Unit</strong>
                                                        </div>
                                                        <div class="col-12">
                                                            <input type="text" class="form-control" [(ngModel)]="ReplaceEmp.EmpBU" disabled>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 my-3 text-center">
                                        <button type="button" class="btn mr-3" style="width:100px;background-color:#9eb0ba;color:white;" (click)="close_remove_emp()">Back</button>
                                        <button type="button" class="btn" style="width:100px;background-color:#033153;color:white;" (click)="confirm_replace()">Confirm</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading [loading]='loading'></app-loading>